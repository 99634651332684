﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'dataflowService', 'utilService', 'cmsService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function fileShuffleDefinitionsList($q, $scope, $timeout, $injector, dataflowService, utilService, cmsService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, dataflowService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return fileShuffleDefinitionSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, dataflowService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        ribbon.addTab(new ribbon.Tab("ActionPanelTabManage", [ribbon.GetSchedulingSection()]));
        vm.ribbonDatacontext = ribbon;

        var fileShuffleDefinitionSelected = null;

        ribbon.setRibbonStatusButton(ribbon.Buttons.RunScheduling, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.New, false);
        ribbon.refreshStatusButtons();
        

        vm.currentFileShuffleDefinitions = [];

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('fileshuffledefinition', null);
        nbsGridDatacontext.viewCommonBar();
        nbsGridDatacontext.setGridIsRenderedInlineBlock();
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        loadData(true);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getFileShuffleDefinitions(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.currentFileShuffleDefinitions = results[0];

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.currentFileShuffleDefinitions
                    }
                });
        }

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.fileshuffledefinitionftc", { 'fileShuffleDefinitionId': args.data.FileShuffleDefinitionId });
        };

        vm.refreshAction = function () {
            loadData(true);
        };

        vm.runAction = function () {
            if (!fileShuffleDefinitionSelected) {
                return;
            }

            vm.openRunModal();
        };

        vm.openRunModal = function () {
            vm.fileShuffleRequest = dataflowService.dataContext.fileShuffleRequests.add({
                FileShuffleDefinitionId: fileShuffleDefinitionSelected.FileShuffleDefinitionId,
                FileShuffleRequestName: fileShuffleDefinitionSelected.FileShuffleDefinitionName
            });

            vm.modalInstance = navigationService.modal().open(vm.modalOptions({
                windowClass: vm.NBS_MODAL_CLASS_FORM_MD,
                templateUrl: 'fileShuffleDefinitionRunTemplate',
                scope: $scope
            }));
            vm.modalInstance.result.then(function () {
                vm.submitAction()
                    .then(function (saveResult) {
                        //var test = saveResult;
                        //BATCH BOTTOM TEST
                        if (vm.fileShuffleRequest.IsBatch) {
                            contextService.addBatchRequest({
                                requestId: vm.fileShuffleRequest.BatchRequestId,
                                requestName: vm.fileShuffleRequest.BatchRequestName,
                                requestStatus: 'inserted'
                            });
                        }
                    })
                    .catch(function (error) {
                        vm.setErrorMsg(error);
                    });
            }, function () {
                //dismiss
                dataflowService.rejectChanges();
            });
        };

        vm.modalRunOk = function () {
            vm.modalInstance.close();
        };

        vm.modalRunCancel = function () {
            vm.modalInstance.dismiss();
        };

        vm.gridSelection = function (args) {
            $timeout(() => {
                fileShuffleDefinitionSelected = args.data;

                if (fileShuffleDefinitionSelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.RunScheduling, true);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.AddScheduledTask, true);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.RunScheduling, false);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.AddScheduledTask, false);
                }

                ribbon.refreshStatusButtons();
            });
        };

        function getFileShuffleDefinitions(forceReload) {
            var filter = {};
            var defer = $q.defer();

            dataflowService.getFileShuffleDefinitions(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        vm.scheduledTaskAction = function () {
            if (!fileShuffleDefinitionSelected) {
                return;
            }

            var services = { navigationService: navigationService, utilService: utilService, cmsService: cmsService };
            var wzNbsManager = new NbsWizardManager($q, vm.translate, services, NbsGuidManager);
            var config = new wzNbsManager.WzConfig_AddScheduledTask('fileshufflerequest');
            config.defaultData.Code = fileShuffleDefinitionSelected.FileShuffleDefinitionCode;
            config.defaultData.Name = fileShuffleDefinitionSelected.FileShuffleDefinitionName;
            wzNbsManager.setWzConfig(config);

            wzNbsManager.open()
                .then(function (result) {
                    var scheduledTaskResult = result;
                    var fileShuffleRequest = dataflowService.dataContext.fileShuffleRequests.add(fileShuffleDefinitionSelected);
                    fileShuffleRequest.FileShuffleRequestName = scheduledTaskResult.Name;
                    var dateForUtcTime = moment(Nembus.Common.Library.converter.timespan.jsonToDate(scheduledTaskResult.Time)).add(-moment().utcOffset(), 'minutes').toDate();
                    fileShuffleRequest.TaskTime = Nembus.Common.Library.converter.timespan.dateToJson(dateForUtcTime); //SET UTC TIME //scheduledTaskResult.Time; 
                    fileShuffleRequest.TaskCronExpression = scheduledTaskResult.CronExpression;
                    fileShuffleRequest.IsScheduledTask = true;

                    vm.submitAction()
                        .then(function (result) {
                            // success
                        }).catch(function (error) {
                            // error
                            vm.setErrorMsg(error);
                            dataflowService.rejectChanges();
                        });
                });
        };
    }

    fileShuffleDefinitionsList.$inject = injectParams;
    fileShuffleDefinitionsList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('fileShuffleDefinitionsList', fileShuffleDefinitionsList);
});