﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'metricService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function metricKpiDefinitionsList($q, $scope, $timeout, $injector, metricService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, metricService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, metricService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        ribbon.setRibbonStatusButton(ribbon.Buttons.OpenDetail, false);
        ribbon.setRibbonStatusButton(ribbon.Buttons.New, false);
        ribbon.refreshStatusButtons();

        vm.currentMetricKpis = [];

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('metrickpidefinition', null);
        nbsGridDatacontext.viewCustomBar();
        nbsGridDatacontext.setDatasource([], true); // fake datasource, only to create grid
        vm.nbsGridDatacontext = nbsGridDatacontext;

        // hook from gridbar
        var ejDataManager = null;
        var metricKpiDefinitionFilter = null;
        vm.metricGridBarOnChanges = function (result) {
            metricKpiDefinitionFilter = result.metricKpiDefinitionFilter;

            if (ejDataManager === null) {
                var supportData = { translate: vm.translate, contextService: contextService };
                ejDataManager = metricService.getMetricKpiDefinitionManager(supportData, metricKpiDefinitionFilter, ejDataManager);
                nbsGridDatacontext.setDatasource(ejDataManager, true);
                refreshGridDatasource(metricKpiDefinitionFilter);
            } else {
                refreshGridDatasource(metricKpiDefinitionFilter)
            }
        };

        function refreshGridDatasource(metricKpiDefinitionFilter) {
            $timeout(() => {
                vm.forceDatamanagerFilter = {
                    refreshKey: new Date().getTime(),
                    filter: metricKpiDefinitionFilter
                }
            });
        }

        vm.refreshAction = function () {
            $timeout(() => {
                vm.forceGridRefresh = new Date().getTime();
            });
        };

        vm.createGridEvent = function (result) {
            vm.metricGridBarDataContext = {
                commonBarDatasource: result.barDatasource,
                uiMode: 'definition',
                defaultKeys: {
                    columnsKey: null, sortingKey: null
                }
            };
        };

        vm.gridUiOnChanges = function (result) {
            vm.refreshGridUiComponent = {
                refreshKey: new Date().getTime(),
                uiData: result
            }
        }
    }

    metricKpiDefinitionsList.$inject = injectParams;
    metricKpiDefinitionsList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('metricKpiDefinitionsList', metricKpiDefinitionsList);
});