﻿'use strict';
//modal params: 
//cronstring: string 
//modal result
//CronObj   ///  cronstring
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$injector', '$timeout', 'navigationService', 'contextService', 'workflowService', 'actService'];
    function actWorkflowChangeStatusDetail($q, $injector, $timeout, navigationService, contextService, workflowService, actService) {

        var vm = this;
        nbsVm.call(vm, null, navigationService, contextService, $q, $injector, $timeout);
        
        var pParams = navigationService.stateParamsModal().parentParams;
        var actId = pParams.ActId;          // history mode
        var workflowStatusId = pParams.WorkflowStatusId;
        var workflowStatusName = pParams.WorkflowStatusName;
        var workflowStatusKindParsedEnum = Nembus.Common.Library.util.parseEnum(workflowService.dataContext.enumTypes.WorkflowStatusKind, 'WorkflowStatus', vm.translate);
        var workflowStatusKindEnumObj = workflowStatusKindParsedEnum.obj.normal.name_value;
        var workflowStatusKindEnumTranslatedObj = workflowStatusKindParsedEnum.obj.translated.value_name;

        vm.canManageWkfExecutionDate = Nembus.Common.Library.server.getServerVarBool('ssv_core_act_workflow_executiondate_manage');
        
        pParams = pParams ? pParams : navigationService.stateParams();

        vm.viewKindConst = { EXECUTE: 'execute', MODIFY: 'modify', INFO: 'info', HISTORY: 'history' };
        vm.viewKind = pParams.viewKind;        // execute mode or changestatus mode
        vm.isExecuting = false;

        // modalResult
        vm.modalWorkFlow = {
            SourceParams: pParams,
            WorkflowStatusId: null,
            ExecutionNote: null,
            ExecutionDate: new Date()
        };

        if (workflowStatusId) {
            vm.modalWorkFlow.WorkflowStatusId = workflowStatusId;
        }

        switch (vm.viewKind) {
            case vm.viewKindConst.MODIFY:
            case vm.viewKindConst.EXECUTE:
                getActWorkflowActiveStatuses(true)
                    .then(function (wkfStatuses) {
                        vm.workflowStatuses = [];
                        if (vm.viewKind === vm.viewKindConst.MODIFY) {
                            vm.workflowStatuses = wkfStatuses;
                        }
                        if (vm.viewKind === vm.viewKindConst.EXECUTE) {
                            vm.workflowStatuses = Lazy(wkfStatuses).filter((wkf) => { return wkf.WorkflowStatusKindId === workflowStatusKindEnumObj.Executed; }).toArray();
                            if (Lazy(vm.workflowStatuses).some()) {
                                vm.modalWorkFlow.WorkflowStatusId = Lazy(vm.workflowStatuses).first().WorkflowStatusId;
                                vm.isExecuting = true;
                            }
                        }
                    });
                break;
            case vm.viewKindConst.INFO:
                vm.currentWorkFlowInfo = { WorkflowStatusName: workflowStatusName };
                break;

            case vm.viewKindConst.HISTORY:
                if (!actId) {
                    alert('error actid');
                    return;
                }

                var forceReload = true;
                var funcsPromises = [];
                funcsPromises.push(getActChangeHistoriesDto(forceReload, actId));

                $q.all(funcsPromises)
                    .then(function (results) {
                        var histories = results[0];

                        Lazy(histories)
                            .each((item) => {
                                item.ActWorkflowStatusKindName = workflowStatusKindEnumTranslatedObj[item.ActWorkflowStatusKindId];
                                item.ActExecutionDateUI = Nembus.Common.Library.converter.date.formatDate(item.ActExecutionDate, contextService.virtualContext.cultureInfo, false);
                                item.CreationDateUI = Nembus.Common.Library.converter.date.formatDateTime(item.CreationDate, contextService.virtualContext.cultureInfo, false);
                            });

                        var nbsGridDatacontext = new NbsGrid(vm.translate);
                        nbsGridDatacontext.configureGrid('actchangehistory', null);
                        nbsGridDatacontext.setModalMode();
                        nbsGridDatacontext.setDatasource(histories, false);
                        nbsGridDatacontext.allowPaging = false;
                        nbsGridDatacontext.allowSearching = false;
                        vm.nbsGridDatacontext = nbsGridDatacontext;
                    });
                break;
        }

        vm.modalOk = function () {
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalOk);
            var hasErrors = false;

            if (!hasErrors) {
                navigationService.closeCurrentModal(modalResult);
            } else {
                vm.alertConfig.isModal = true;
                vm.alertConfig.isReadonly = true;
                vm.removeAlertFromList(true);
                vm.setErrorMsg({ isMessageVisible: true, message: vm.currentCron.errorMessage() });
            }
        };

        vm.modalCancel = function () {
            navigationService.closeCurrentModal();
        };

        function getModalResult(dialogResult) {
            var modalResult = new navigationService.modalResult();
            modalResult.data = {
                ViewKind: pParams.viewKind,
                WorkflowStatusId: vm.modalWorkFlow.WorkflowStatusId,
                ExecutionNote: vm.modalWorkFlow.ExecutionNote,
                ExecutionDate: vm.modalWorkFlow.ExecutionDate
            };

            modalResult.sourceParams = pParams;
            modalResult.dialogResult = dialogResult;
            return modalResult;
        }

        vm.workflowStatusOnChange = function () {
            var selectedWorkflowStatus = Lazy(vm.workflowStatuses).findWhere({ WorkflowStatusId: Number(vm.modalWorkFlow.WorkflowStatusId) });
            if (selectedWorkflowStatus && selectedWorkflowStatus.WorkflowStatusKindId === workflowStatusKindEnumObj.Executed) {
                vm.isExecuting = true;
                vm.modalWorkFlow.ExecutionDate = new Date();
            } else {
                vm.isExecuting = false;
                vm.modalWorkFlow.ExecutionDate = null;
            }
        };

        function getActChangeHistoriesDto(forceReload, actId) {
            var defer = $q.defer();

            actService.getActChangeHistoriesDto({ ActId: actId }, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getActWorkflowActiveStatuses(forceReload) {
            var defer = $q.defer();

            workflowService.getActWorkflowActiveStatuses({}, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }
    }

    actWorkflowChangeStatusDetail.$inject = injectParams;
    actWorkflowChangeStatusDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('actWorkflowChangeStatusDetail', actWorkflowChangeStatusDetail);
});