﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'navigationService', 'contextService', 'formSummaryService'];
    var companyFtc = function ($q, $scope, $timeout, $injector, foundationService, navigationService, contextService, formSummaryService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        
        var referral = 'company';
        var cmsView = 'companyftc-_';

        var companyId = navigationService.stateParams().companyId;
        var hrLegalGeneralityId = null;
        var hrContactsId = null;

        var state = { generality: 'generality', legalGenerality: 'legalGenerality', contacts: 'contacts', addresses: 'addresses' };

        var fastTab = new NbsAccordion();
        fastTab.AddFastTab(state.generality, "General");
        fastTab.AddFastTab(state.legalGenerality, "LegalGenerality");
        fastTab.AddFastTab(state.contacts, "Contacts");
        fastTab.AddFastTab(state.addresses, "Addresses");
        vm.fastTabDatacontext = fastTab;

        vm.ftcOnClick = function (event) {
            navigateTo(event.id);
        }

        // raised from detail view
        vm.ftcOnAddEntity = function (entity) {
            $timeout(() => {
                companyId = entity.CompanyId;
                hrLegalGeneralityId = entity.HrLegalGeneralityId;
                hrContactsId = entity.HrContactsId;

                vm.fastTabsAreDisabled = false;                
            });
        }

        if (companyId) {
            foundationService.getCompany({ CompanyId: companyId }, false)
                .then(function (data) {
                    var company = data.results[0];
                    hrLegalGeneralityId = company.HrLegalGeneralityId;
                    hrContactsId = company.HrContactsId;

                    $timeout(() => {
                        vm.fastTabsAreDisabled = false;
                    });

                    // configure form summary items
                    formSummaryService.clearDatasource();
                    formSummaryService.push({ key: vm.translateCmsView(cmsView, 'CompanyName', 'Company'), value: company.CompanyName });
                    formSummaryService.push({ key: vm.translateCmsView(cmsView, 'CompanyCode', 'Company'), value: company.CompanyCode });

                    goToDefaultFastTab();
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });
        } else {
            goToDefaultFastTab();

            $timeout(() => {
                vm.fastTabsAreDisabled = true;
            });
        }

        function navigateTo(accordionId) {
            if (!accordionId) {
                return;
            }

            var navigationState = null;
            var navigationStateParams = null;
            switch (accordionId) {
                case state.generality:
                    navigationState = 'app.nav.companyftc.companydetail';
                    navigationStateParams = { 'companyId': companyId };
                    break;
                case state.legalGenerality:
                    navigationState = 'app.nav.companyftc.hrlegalgeneralitydetail';
                    navigationStateParams = { 'hrLegalGeneralityId': hrLegalGeneralityId, 'referral': referral };
                    break;
                case state.contacts:
                    navigationState = 'app.nav.companyftc.hrcontactdetail';
                    navigationStateParams = { 'hrContactsId': hrContactsId, 'referral': referral };
                    break;
                case state.addresses:
                    navigationState = 'app.nav.companyftc.hraddressdetail';
                    navigationStateParams = { 'hrContactsId': hrContactsId, 'referral': referral };
                    break;
            }

            // call navigation state
            if (navigationState && navigationStateParams)
                navigationService.navigateTo(navigationState, navigationStateParams);
        };

        function goToDefaultFastTab() {
            // go to default page only if is called ftc directly
            if (navigationService.currentState().endsWith('.companyftc')) {
                navigateTo(state.generality);
            }
        }
    };

    companyFtc.$inject = injectParams;
    companyFtc.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('companyFtc', companyFtc);
});