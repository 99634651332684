﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'activityService', 'utilService', 'bomService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function activitiesList($q, $scope, $timeout, $injector, activityService, utilService, bomService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        var viewKind = navigationService.stateParams().viewKind;
        vm.isBom = viewKind === 'Bom';
        vm.notIsBom = !vm.isBom;
        var service = vm.isBom ? bomService : activityService;
        nbsVm.call(vm, service, navigationService, contextService, $q, $injector, $timeout);

        checkStateChangeService.checkFormOnStateChange($scope, service);
        vm.setItemSelectedFn.call(vm, () => { return activitySelected; });

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var activitySelected = null;

        var activityKindEnum = Nembus.Common.Library.util.parseEnum(activityService.dataContext.enumTypes.ActivityKind, null, vm.translate).obj.normal.name_value;
        var activityKindId = activityKindEnum[viewKind];
        var appStoreCode = navigationService.stateParams().appStoreCode;
        var vhrEventTypeId = navigationService.stateParams().vhrEventTypeId ? navigationService.stateParams().vhrEventTypeId : null;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var effortUnitEnumParsed = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.EffortUnit, null, vm.translate);

        var nbsGridDatacontext = null;
        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            $q.all(funcsPromises)
                .then(function () {
                    var filter = { AppStoreBw: appStoreBw, ActivityKindId: activityKindId };
                    var supportData = { effortUnitEnumParsed: effortUnitEnumParsed };
                    var ejDataManager = service.getActivityDataManager(supportData, filter, ejDataManager);

                    if (vm.isBom) {
                        nbsGridDatacontext = new NbsGrid(vm.translate);
                        nbsGridDatacontext.configureGrid('activity', 'bom');
                        nbsGridDatacontext.viewCommonBar();
                        nbsGridDatacontext.setAdaptToPage();
                        nbsGridDatacontext.setDatasource(ejDataManager, true);
                        vm.nbsGridDatacontext = nbsGridDatacontext;
                    } else {
                        nbsGridDatacontext = new NbsGrid(vm.translate);
                        nbsGridDatacontext.configureGrid('activity', 'subbom');
                        nbsGridDatacontext.viewCommonBar();
                        nbsGridDatacontext.setAdaptToPage();
                        nbsGridDatacontext.setDatasource(ejDataManager, true);
                        vm.nbsGridDatacontext = nbsGridDatacontext;
                    }
                });
        }

        vm.gridSelection = function (args) {
            $timeout(() => {
                activitySelected = args.data;

                if (activitySelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, true);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, true);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, false);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                }

                ribbon.refreshStatusButtons();
            });
        };


        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.activitydetail-" + vm.viewMode, {
                'activityId': args.data.ActivityId,
                'appStoreCode': appStoreCode,
                'viewKind': viewKind,
                'vhrEventTypeId': vhrEventTypeId
            });
        };

        vm.newAction = function () {
            navigationService.navigateTo("app.nav.activitydetail-" + vm.viewMode, {
                'activityId': null,
                'appStoreCode': appStoreCode,
                'viewKind': viewKind,
                'vhrEventTypeId': vhrEventTypeId
            });
        };

        vm.refreshAction = function () {
            $timeout(() => {
                vm.forceGridRefresh = new Date().getTime();
            });
        };

        vm.deleteAction = function () {
            if (!activitySelected) {
                return;
            }

            service.deleteEntity(activitySelected);
            vm.submitAction()
                .then(function (saveResult) {
                    vm.refreshAction();
                });
        };

        vm.copyAction = function () {
            if (!activitySelected) {
                return;
            }

            // reset alerts
            vm.removeAlertFromList(true);

            vm.modalTitle = vm.isBom ? vm.translate('Boms') : vm.translate('Activity.Activity');

            vm.activityClientData = service.dataContext.ActivityClientData.add({
                ActivityId: activitySelected.ActivityId,
                ActivityKindId: activitySelected.ActivityKindId,
                ActivityCode: null,
                ActivityName: activitySelected.ActivityName
            });

            vm.modalInstance = navigationService.modal().open(vm.modalOptions({ templateUrl: 'addActivityTemplate', scope: $scope }));
            vm.modalInstance.result.then(function () {
                // ok
                service.dataContext.ActivityClientData.clearCache();
            }, function () {
                // detach of the client data attached only for client validation
                service.dataContext.ActivityClientData.clearCache();
            });
            vm.modalInstance.ok = function () {
                //force validation on entity (manual metadata only client)
                vm.activityClientData.entityAspect.validateEntity();

                if (!vm.activityClientData.entityAspect.hasValidationErrors) {

                    var activityClientData = {
                        ActivityId: vm.activityClientData.ActivityId,
                        ActivityKindId: vm.activityClientData.ActivityKindId,
                        ActivityCode: vm.activityClientData.ActivityCode,
                        ActivityName: vm.activityClientData.ActivityName
                    };

                    service.cloneActivity(activityClientData)
                        .then(function (result) {
                            vm.modalInstance.close();
                            vm.refreshAction();
                        })
                        .catch(function (error) {
                            vm.alertConfig.isModal = true;
                            vm.alertConfig.isReadonly = true;
                            vm.removeAlertFromList(true);
                            vm.managePostMessage(error);
                            vm.setErrorMsg(error);
                        });
                }
            };

            vm.modalInstance.cancel = function () {
                vm.modalInstance.dismiss();
            };
        };
    }

    activitiesList.$inject = injectParams;
    activitiesList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('activitiesList', activitiesList);
});