﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'workflowService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function workflowDetail($q, $scope, $timeout, $injector, workflowService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, workflowService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, workflowService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var workflowId = navigationService.stateParams().workflowId;
        var workflowTypeEnumParsed = Nembus.Common.Library.util.parseEnum(workflowService.dataContext.enumTypes.WorkflowType, 'Workflow', vm.translate);
        var workflowStatusKindEnumParsed = Nembus.Common.Library.util.parseEnum(workflowService.dataContext.enumTypes.WorkflowStatusKind, 'WorkflowStatus', vm.translate);
        var workflowStatusKindObj = workflowStatusKindEnumParsed.obj.translated.value_name;
        var workflowTypeObj = workflowTypeEnumParsed.obj.normal.name_value;
        var action = {
            add: 1,
            update: 2
        };

        ribbon.setRibbonStatusButton(ribbon.Buttons.New,  false);
        ribbon.refreshStatusButtons();

        vm.workflowTypes = workflowTypeEnumParsed.list.translated;
        vm.workflowStatusKinds = workflowStatusKindEnumParsed.list.translated;
        vm.viewWorkflowStatuses = false;

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('workflowstatus', null);
        nbsGridDatacontext.allowPaging = false;
        nbsGridDatacontext.allowSearching = false;
        nbsGridDatacontext.allowGrouping = false;
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        var currentWorkflowStatuses = [];

        vm.moduleTargets = Nembus.Rds.Library.Tools.ModuleTargetContext({ ModuleTargetCodes: ['MTAUD', 'MTACT'] }, contextService);

        if (workflowId) {
            vm.setIsViewing();
            loadDataUpdate(true);
        } else {
            vm.setIsAdding();
            loadDataInsert(true);
        }

        function loadDataUpdate(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getWorkflow(forceReload, workflowId));
            funcsPromises.push(getWorkflowStatuses(forceReload, workflowId));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.currentWorkflow = results[0];
                    vm.viewWorkflowStatuses = true;
                });
        }

        function loadDataInsert(forceReload) {
            var funcsPromises = [];

            $q.all(funcsPromises)
                .then(function () {
                    vm.currentWorkflow = workflowService.dataContext.workflows.add({
                        VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId,
                        WorkflowTypeId: workflowTypeObj.Static,
                        IsSystem: false,
                        IsActive: false,
                        ModuleTargetId: null
                    });
                });
        }

        vm.newAction = function () {
            vm.workflowStatusSelected = workflowService.dataContext.workflowStatuses.add({
                WorkflowId: vm.currentWorkflow.WorkflowId,
                WorkflowStatusKindId: null
            });

            openModalWorkflowStatusDetail(vm.workflowStatusSelected, action.add);
        };

        // get reference of modifyAction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parentm = vm.modifyAction;
        vm.modifyAction = function () {
            parentm().then(
                //enable disable button new & delete because is custom behavior
                //generally saveResult eneble or disable only save button 
                function (saveResult) {
                    if (vm.viewContext.isEditingState) {
                        ribbon.setRibbonStatusButton(ribbon.Buttons.New,  true);
                    }
                    else {
                        ribbon.setRibbonStatusButton(ribbon.Buttons.New,  false);
                    }

                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, true);
                    ribbon.refreshStatusButtons();
                }
            );
        };

        vm.deleteAction = function () {
            if (!vm.workflowStatusSelected) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            var countOfKind = Lazy(currentWorkflowStatuses)
                .where({ WorkflowStatusKindId: vm.workflowStatusSelected.WorkflowStatusKindId })
                .toArray()
                .length;

            if (countOfKind <= 1) {
                navigationService.customMessageModal('WorkflowNotComplete');
                return;
            }

            workflowService.deleteEntity(vm.workflowStatusSelected);

            vm.submitAction()
                .then(function (saveResult) {
                    getWorkflowStatuses(true, vm.currentWorkflow.WorkflowId);
                });
        };

        vm.gridSelection = function (args) {
            vm.workflowStatusSelected = args.data;
        };

        vm.rowDblClick = function (args) {
            openModalWorkflowStatusDetail(vm.workflowStatusSelected, action.update);
        };

        // get reference of submitaction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parents = vm.submitAction;
        vm.submitAction = function () {
            var defer = $q.defer();

            parents()
                .then(function (saveResult) {
                    if (saveResult.entities.length > 0) {
                        vm.viewWorkflowStatuses = true;

                        ribbon.setRibbonStatusButton(ribbon.Buttons.New, true);
                        ribbon.refreshStatusButtons();
                    }

                    defer.resolve(true);
                });

            return defer.promise;
        };

        function openModalWorkflowStatusDetail(workflowStatus, currentAction) {
            vm.modal = { instanceName: 'modalInstance', title: vm.translate('WorkflowStatus.WorkflowStatus') };
            vm.modalInstance = navigationService.modal().open(vm.modalOptions({
                windowClass: vm.NBS_MODAL_CLASS_FORM_MD,
                templateUrl: 'workflowStatusTemplate',
                scope: $scope
            }));
            vm.modalInstance.result.then(function () {
            }, function () {
                //dismiss
                vm.workflowStatusSelected.entityAspect.rejectChanges();
            });
            vm.modalInstance.ok = function () {
                vm.workflowStatusSelected.entityAspect.validateEntity();
                if (!vm.workflowStatusSelected.entityAspect.hasValidationErrors) {
                    vm.modalInstance.close();

                    vm.submitAction()
                        .then(function (saveResult) {
                            getWorkflowStatuses(true, vm.currentWorkflow.WorkflowId);
                        });
                }
            };
            vm.modalInstance.cancel = function () {
                vm.modalInstance.dismiss();
            };
        }

        function getWorkflow(forceReload, workflowId) {
            var filter = { WorkflowId: workflowId };
            var defer = $q.defer();

            workflowService.getWorkflow(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results[0]);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getWorkflowStatuses(forceReload, workflowId) {
            var filter = { WorkflowId: workflowId };
            var defer = $q.defer();

            workflowService.getWorkflowStatuses(filter, forceReload)
                .then(function (data) {
                    Lazy(data.results)
                        .each((status) => {
                            status.WorkflowStatusKindName = workflowStatusKindObj[status.WorkflowStatusKindId];
                        });

                    currentWorkflowStatuses = data.results;

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: currentWorkflowStatuses
                    }

                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }
    }

    workflowDetail.$inject = injectParams;
    workflowDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('workflowDetail', workflowDetail);
});