﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {
    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'vhreventService', 'utilService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function vhrEventsBoardLifeStatus($q, $scope, $timeout, $injector, vhreventService, utilService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, vhreventService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, vhreventService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'board');
        ribbon.addHomeTab();
        ribbon.tabIndexSelected = 2;
        ribbon.addTab(ribbon.GetVhrEventViewsTab());
        vm.ribbonDatacontext = ribbon;

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var vhrEventTypeCode = navigationService.stateParams().vhrEventTypeCode;
        var vhrEventFixedTypeCode = navigationService.stateParams().vhrEventFixedTypeCode;
        var vhrEventKindEnum = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventKind, null, vm.translate).obj.normal.name_value;
        var vhrEventKindId = vhrEventKindEnum.Data;
        var vhrEventLifeStatusEnumParsed = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventLifeStatus, 'VhrEvent', vm.translate);
        var viewKind = navigationService.stateParams().viewKind;
        var wzVhrEntityType = navigationService.stateParams().wzVhrEntityType;          //asset, avatar, bucket, ...
        var vhrEventLifeStatusId = navigationService.stateParams().vhrEventLifeStatusId;

        // parameters
        var nCols = 4; // parametrized
        var columnFilters = [];
        Lazy(vhrEventLifeStatusEnumParsed.list.translated)
            .each((lifeStatus) => {
                var filterParams = { filter: null, field: 'VhrEventLifeStatusIds', text: lifeStatus.name, value: [lifeStatus.value], isDueDate: false, defaultValue: null };
                columnFilters.push([filterParams]);
            });
        var boardParams = {
            dueDateFilters: [],
            columnFilters: columnFilters,
            nbsGuidManager: NbsGuidManager,
            localAsUtcFn: Nembus.Common.Library.converter.date.localAsUtc
        };

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('vhrevent', null, appStoreCode);
        nbsGridDatacontext.setBoardMode();
        nbsGridDatacontext.allowSearching = false;
        nbsGridDatacontext.setDatasource([], false); // fake datasource, only to create grid

        vm.board = new NbsBoard(nCols, boardParams);
        vm.board.setRefreshScopeFn(function () { $timeout(() => { }); });
        vm.board.setGridDatacontext(nbsGridDatacontext);
        vm.board.onBoardReady = function () {
            setBarDatacontext();
        };
        vm.board.onDropAction = function (items, indexColSrc, indexColDest) {
            dropAction(items, indexColSrc, indexColDest);
        };

        // hook from gridbar
        var ejDataManager = null;
        var currentBoardBarFilter = null;
        vm.vhrEventBarOnChanges = function (result) {
            // save in view current filter passed by survey bar
            currentBoardBarFilter = result;
            currentBoardBarFilter.vhrEventFilter.AppStoreBw = appStoreBw;
            currentBoardBarFilter.vhrEventFilter.VhrEventTypeCode = vhrEventTypeCode;
            currentBoardBarFilter.vhrEventFilter.VhrEventFixedTypeCode = vhrEventFixedTypeCode;
            currentBoardBarFilter.vhrEventFilter.VhrEventKind = vhrEventKindId;
            currentBoardBarFilter.vhrEventFilter.ModuleTargetId = moduleTargetId;

            if (ejDataManager === null) {
                utilService.getGenericElements({ GenericElementCode: 'Configurations.VhrEvents.LifeStatusColors' }, false)
                    .then((data) => {
                        var ge = data.results[0].PairValuesArray;
                        var statusColorObj = Nembus.Common.Library.parser.objFromList(ge, 'key', 'value');

                        var supportData = { searchMode: 'vhrevents', translate: vm.translate, contextService: contextService, statusColorObj: statusColorObj };
                        ejDataManager = vhreventService.getVhrEventDataManager(supportData, currentBoardBarFilter.vhrEventFilter, ejDataManager);
                        nbsGridDatacontext.setDatasource(ejDataManager, true);

                        vm.board.setBoardFilter(currentBoardBarFilter.vhrEventFilter);
                    });
            } else {
                vm.board.setBoardFilter(currentBoardBarFilter.vhrEventFilter);
            }
        };

        //#region set ribbon fn navigations
        var navParams = {
            'appStoreCode': appStoreCode,
            'vhrEventTypeCode': vhrEventTypeCode,
            'vhrEventFixedTypeCode': vhrEventFixedTypeCode,
            'viewKind': viewKind,
            'wzVhrEntityType': wzVhrEntityType,
            'vhrEventLifeStatusId': vhrEventLifeStatusId
        };
        vm.onRibbonGrid = () => { ribbon.onRibbonGrid_VhrEvent(navParams); };
        vm.onRibbonBoardStatus = () => { ribbon.onRibbonBoardStatus_VhrEvent(navParams); };
        vm.onRibbonStream = () => { ribbon.onRibbonStream_VhrEvent(navParams); };
        vm.onRibbonBoard = () => { ribbon.onRibbonBoard_VhrEvent(navParams); };
        vm.onRibbonBoardApproval = () => { ribbon.onRibbonBoardApproval_VhrEvent(navParams); };
        //#endregion set ribbon fn navigations

        //#region set ribbon enabled buttons
        ribbon.setRibbonStatusButton(ribbon.Buttons.ViewStream, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.Board, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.BoardApproval, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.ViewList, true);
        ribbon.refreshStatusButtons();
        //#endregion set ribbon enabled buttons

        vm.refreshAction = function () {
            vm.board.refreshGridDatasources();
        };

        function setBarDatacontext() {
            vm.vhrEventBarDataContext = {
                commonBarDatasource: { modeUI: 'board' },
                appStoreBw: appStoreBw,
                appStoreCode: appStoreCode,
                defaultKeys: {
                    columnsKey: null, sortingKey: null
                }
            };
        }

        vm.gridUiOnChanges = function (result) {
            vm.refreshGridUiComponent = {
                refreshKey: new Date().getTime(),
                uiData: result
            }
        }

        function dropAction(events, indexColSrc, indexColDest) {
            var vhrEventsId = Lazy(events).pluck('VhrEventId').toArray();
            var srcCol = vm.board.columns[indexColSrc];
            var destCol = vm.board.columns[indexColDest];
            var newStatus = vm.board.getColFilter(destCol).VhrEventLifeStatusIds[0];
            if (!newStatus) {
                return;
            }

            var filter = { VhrEventsId: vhrEventsId, ModuleTargetId: moduleTargetId };
            vhreventService.getChangeTargetVhrEventsLifeStatus(filter)
                .then(function (data) {
                    if (!Lazy(data.results).contains(Number(newStatus))) {
                        navigationService.operationNotPermittedModal();
                        return;
                    }

                    vm.removeAlerts();

                    var vhrEventShuffleActionObj = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventShuffleAction, null, vm.translate).obj.normal.name_value;
                    var vhreventShuffleDto = vhreventService.dataContext.vhrEventShuffleDto.add();
                    vhreventShuffleDto.VhrEventShuffleAction = vhrEventShuffleActionObj.ChangeLifeStatus;
                    vhreventShuffleDto.VhrEventLifeStatus = newStatus;
                    vhreventShuffleDto.VhrEventLifeStatusChangeNote = null;
                    vhreventShuffleDto.VhrEventBillingStatus = 0;
                    vhreventShuffleDto.ModuleTargetId = moduleTargetId;
                    vhreventShuffleDto.VhrEventsIdSerialized = JSON.stringify(vhrEventsId);

                    vhreventService.saveChanges()
                        .then(function () {
                            var resultErrors = JSON.parse(Nembus.Common.Library.converter.decodeHtml(vhreventShuffleDto.ResultErrorsSerialized));
                            if (Lazy(Object.keys(resultErrors)).some()) {
                                vm.setErrorMsg(({ isMessageVisible: true, message: vm.translate('ChangeStatusNotPermitted') }));
                                return;
                            }

                            vm.board.refreshGridDatasource(srcCol);
                            vm.board.refreshGridDatasource(destCol);
                        });                    
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });
        }
    }

    vhrEventsBoardLifeStatus.$inject = injectParams;
    vhrEventsBoardLifeStatus.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('vhrEventsBoardLifeStatus', vhrEventsBoardLifeStatus);
});