﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {


    var injectParams = ['$scope', '$http', '$window', '$q', '$location', '$uibModal', 'localStorageService', 'contextService', 'authenticationService', 'rbacService', 'drbacService', 'navigationService', 'checkStateChangeService', 'bootService'];
    var myAccount = function ($scope, $http, $window, $q, $location, $uibModal, localStorageService, contextService, authenticationService, rbacService, drbacService, navigationService, checkStateChangeService, bootService) {

        var vm = this;
        nbsVm.call(vm, rbacService, navigationService, contextService, $q, null, null);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        ribbon.setRibbonStatusButton(ribbon.Buttons.Modify, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.Save, false);
        ribbon.refreshStatusButtons();

        vm.virtualContext = contextService.virtualContext;

        vm.currentLaguages = [];

        vm.LastAccess = {
            LastLogonDate: Nembus.Common.Library.converter.date.formatDateTime(contextService.virtualContext.LastLogonDate, contextService.virtualContext.cultureInfo, true),
            LastUserAgent: contextService.virtualContext.LastUserAgent
        };

        loadData(true);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getLanguages(forceReload));
            funcsPromises.push(getVirtualCompaniesForUser(forceReload, contextService.virtualContext.XCentralUserId));
            funcsPromises.push(getDrbacGlobalAppUserSettings(forceReload, contextService.virtualContext.XCentralUserId));

            $q.all(funcsPromises)
                .then(function (results) {
                    // Languages
                    vm.currentLaguages = results[0];
                    Lazy(vm.currentLaguages)
                        .each((language) => {
                            language.LanguageName = vm.translate(language.LanguageName);
                        });
                    vm.currentLanguageId = vm.virtualContext.CurrentLanguageId;

                    // virtual companies
                    vm.currentVirtualCompaniesForUser = results[1];

                    // drbac global user settings
                    if (results[2][0]) {
                        vm.currentDefaultVirtualCompanyId = results[2][0].DefaultVirtualCompanyId;
                    }

                    vm.myAccountCpDtoClientData = rbacService.dataContext.myAccountCpDtoClientDatas.add();

                });
        }

        function getLanguages(forceReload) {
            var filter = {};
            var defer = $q.defer();

            rbacService.getLanguages(forceReload, filter)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getDrbacGlobalAppUserSettings(forceReload, xCentralUserId) {
            var filter = { XCentralUserId: xCentralUserId };
            var defer = $q.defer();

            drbacService.getDrbacGlobalAppUserSettings(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVirtualCompaniesForUser(forceReload, xCentralUserId) {
            var filter = { XCentralUserId: xCentralUserId };
            var defer = $q.defer();

            rbacService.getVirtualCompaniesForUser(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        // change language and reload UI
        vm.languageOnChange = function () {
            if (vm.currentLanguageId !== vm.virtualContext.CurrentLanguageId) {
                authenticationService
                    .changeVirtualContext(contextService.virtualContext.CurrentVirtualCompanyId, vm.currentLanguageId)
                    .then(function (response) {
                        bootService.checkContext(true)
                            .then(function (result) {
                                $window.location.reload();
                            })
                            .catch(function (error) {
                                //$window.location.reload();
                            });


                    })
                    .catch(function (err, status, headers, config) {
                        err.isMessageVisible = true;
                        err.message = err.error;
                        vm.setErrorMsg(err);
                    });
                /*	
            })
            .catch(function (err, status, headers, config) {
                err.isMessageVisible = true;
                err.message = err.error;
                vm.setErrorMsg(err);
            });
            */
            }
        };

        // change default virtualcompanyid
        vm.setDefaultVirtualCompany = function () {
            var filter = {
                XCentralUserId: contextService.virtualContext.XCentralUserId,
                DefaultVirtualCompanyId: vm.currentDefaultVirtualCompanyId
            };

            drbacService.setDefaultVirtualCompany(filter);
        };

        // change password button onclick
        vm.changePassword = function () {
            vm.removeAlertFromList(true);

            vm.myAccountCpDtoClientData.entityAspect.validateEntity();
            if (!vm.myAccountCpDtoClientData.entityAspect.hasValidationErrors) {

                rbacService.changeMyPassword(vm.myAccountCpDtoClientData.NewPassword, vm.myAccountCpDtoClientData.CurrentPassword)
                    .then(function (result) {
                        if (result.results[0] === '1') {
                            vm.myAccountCpDtoClientData = null;
                            authenticationService.user.changePasswordNextLogin = false;
                            authenticationService.changeVirtualContext(contextService.virtualContext.CurrentVirtualCompanyId).then(function () {
                                navigationService.customMessageModal(vm.translate('PasswordUpdatedWithoutError'));
                            });
                        }
                        else {
                            vm.alertConfig.isReadonly = true;
                            vm.removeAlertFromList(true);
                            vm.setErrorMsg({ isMessageVisible: true, message: vm.translate('UnknownErrorWhileSubmitting') });
                        }
                    });
            }
        };

        //SSO PASSWORD
        vm.ssoChangePassword = function () {
            if (!contextService.virtualContext.UserName)
                return;

            vm.removeAlerts();

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        rbacService.resetMySsoPassword({ XCentralUserId: contextService.virtualContext.XCentralUserId })
                            .then(function (result) {
                                navigationService.customMessageModal('EmailSendOk');
                            })
                            .catch(function (error) {
                                vm.setErrorMsg(error);
                                navigationService.customMessageModal('EmailSendError');
                            });
                    }
                });
        }


        // renew secret code button onclick
        vm.renewSecretCodeDto = { Password: null };
        vm.renewSecretCode = function () {
            if (vm.renewSecretCodeDto.Password) {
                rbacService.renewMyTwoFactorSecretCode(vm.renewSecretCodeDto)
                    .then(function (result) {
                        if (result.results[0] === '1') {
                            //authenticationService.user.changePasswordNextLogin = false;
                            ////have to refresh token info (change virtualcontext with same context)
                            //authenticationService.changeVirtualContext(contextService.virtualContext.CurrentVirtualCompanyId).then(function () {

                            //    navigationService.navToHome();
                            //});
                        }
                        else {
                            navigationService.customMessageModal(vm.translate('UnknownErrorWhileSubmitting'));
                        }
                    });
            }
        };

        // used only to denied save button enabled
        var parentm = vm.modifyAction;
        vm.modifyAction = function () {
            var saveChanges = false;
            parentm(saveChanges).then(
                function (saveResult) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Save, false);
                    ribbon.refreshStatusButtons();
                }
            );
        };

        //remove changes to avoid leave page warning
        checkStateChangeService.setOnPageExit({
            //stateName: $state.current.name,
            stateName: 'any',
            fn: function () {
                rbacService.rejectChanges();
            }
        });

    };

    myAccount.$inject = injectParams;
    myAccount.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rbac.module').controller('myAccount', myAccount);
});