﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'integrationService', 'fileTransferService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var integrationRequestDetail = function ($q, $scope, $timeout, $injector, integrationService, fileTransferService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, integrationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, integrationService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        ribbon.addHomeSection(ribbon.GetExportExcelIntegrationSection());
        vm.ribbonDatacontext = ribbon;

        var viewKind = navigationService.stateParams().viewKind;
        var integrationRequestId = navigationService.stateParams().integrationRequestId;
        var integrationTemplateFormat = 'Excel';//navigationService.stateParams().integrationTemplateFormat;
        var integrationDefinitionType = 'IntegrationDataManual'; //navigationService.stateParams().integrationDefinitionType;
        var integrationRequestStatusEnumParsed = Nembus.Common.Library.util.parseEnum(integrationService.dataContext.enumTypes.IntegrationRequestStatus, 'IntegrationRequest', vm.translate);
        var integrationRequestStatusEnumObj = integrationRequestStatusEnumParsed.obj.normal.name_value;
        var integrationRequestResultEnumParsed = Nembus.Common.Library.util.parseEnum(integrationService.dataContext.enumTypes.IntegrationRequestResult, 'IntegrationRequest', vm.translate);
        var integrationRequestResultEnumObj = integrationRequestResultEnumParsed.obj.normal.name_value;
        var integrationRequestDirectionEnumParsed = Nembus.Common.Library.util.parseEnum(integrationService.dataContext.enumTypes.IntegrationRequestDirection, 'IntegrationRequest', vm.translate);
        var integrationRequestDirectionEnumObj = integrationRequestDirectionEnumParsed.obj.normal.name_value;
        var integrationDirectionId = viewKind === 'import' ? integrationRequestDirectionEnumObj.In : integrationRequestDirectionEnumObj.Out;
        var useTemplateDefinition = navigationService.stateParams().useTemplateDefinition;

        if (useTemplateDefinition.toLowerCase() === 'true') {
            useTemplateDefinition = true;
        } else {
            useTemplateDefinition = false;
        }

        vm.isImport = integrationDirectionId === integrationRequestDirectionEnumObj.In;
        vm.showDropZone = integrationDirectionId === integrationRequestDirectionEnumObj.In && !integrationRequestId;
        vm.integrationRequest = null;
        vm.integrationDefinition = null;
        vm.integrationRequestHasError = false;

        vm.viewButtonDownload = false;
        vm.files = [];
        vm.percent = 0;

        ribbon.setRibbonStatusButton(ribbon.Buttons.Modify,false);
        ribbon.setRibbonStatusButton(ribbon.Buttons.ExportIntegrationExcel, false);
        ribbon.setRibbonStatusButton(ribbon.Buttons.Save, false);
        
        if (vm.isImport) {
            ribbon.setRibbonStatusButton(ribbon.Buttons.Save, true);
        } else {
            ribbon.setRibbonStatusButton(ribbon.Buttons.ExportIntegrationExcel, true);
        }
        ribbon.refreshStatusButtons();

        if (integrationRequestId) {
            ribbon.setRibbonStatusButton(ribbon.Buttons.ExportIntegrationExcel, false);
            ribbon.setRibbonStatusButton(ribbon.Buttons.Save, false);
            ribbon.refreshStatusButtons();

            getIntegrationRequest(false)
                .then(function (request) {
                    vm.integrationRequest = request;

                    vm.integrationRequestHasError = vm.integrationRequest.IntegrationRequestResultId !== 1;
                    vm.viewButtonDownload = !vm.integrationRequestHasError;

                    getIntegrationDefinition(vm.integrationRequest.IntegrationDefinitionId);
                });
        }
        else {
            //getIntegrationDefinitionDtos(false);

            vm.integrationRequest = integrationService.dataContext.integrationRequests.add({
                VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId,
                IntegrationRequestId: breeze.core.getUuid(),
                IntegrationRequestDirectionId: integrationDirectionId,
                IntegrationRequestResultId: integrationRequestResultEnumObj.None,
                IntegrationRequestStatusId: integrationRequestStatusEnumObj.Inserted,
                //IntegrationFlowId: Lazy(integrationService.dataContext.enumTypes.IntegrationFlow).findWhere({ name: 'ManualIntegration' }).value,
                IntegrationDefinitionId: null,
                IsComparedByCode: true,
                UseTemplateDefinition: !useTemplateDefinition ? false : useTemplateDefinition
            });

            var currentIntegrationDefinitionId = navigationService.stateParams().integrationDefinitionId;
            if (currentIntegrationDefinitionId) {
                //Export
                getIntegrationDefinition(currentIntegrationDefinitionId).then(function () {
                    vm.integrationRequest.IntegrationDefinitionId = vm.integrationDefinition.IntegrationDefinitionId;
                    vm.integrationRequest.IntegrationRequestName = vm.integrationDefinition.IntegrationDefinitionName;
                    vm.integrationRequest.IntegrationTemplateFormatId = vm.integrationDefinition.IntegrationTemplateFormatId;
                    vm.integrationRequest.EtlRequired = vm.integrationDefinition.EtlRequired;
                });
            }

            vm.uploadfileData = {};
        }

        // get reference of submitaction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parents = vm.submitAction;
        vm.exportExcelAction = function () {
            vm.submitAction();
        };

        vm.submitAction = function () {
            $timeout(() => {
                if (integrationDirectionId === integrationRequestDirectionEnumObj.In) {
                    //only validation error not managed in UI is IntegrationDefinitionId
                    //IntegrationDefinitionId is set after file uploaded
                    if (vm.integrationRequest.entityAspect.hasValidationErrors) {
                        vm.setErrorMsg(vm.translate("UploadFileRequired"), true);
                        return;
                    }
                }

                parents(false).then(
                    function (saveResult) {
                        vm.showDropZone = false;
                        if (vm.integrationRequest.IsBatch) {
                            contextService.addBatchRequest({
                                requestId: vm.integrationRequest.BatchRequestId,
                                requestName: vm.integrationRequest.BatchRequestName,
                                requestStatus: 'inserted'
                            });

                            vm.viewButtonDownload = false;

                            vm.setIsViewing();

                            ribbon.setRibbonStatusButton(ribbon.Buttons.ExportIntegrationExcel, false);
                            ribbon.refreshStatusButtons();
                        }
                        else {
                            integrationRequestId = vm.integrationRequest.IntegrationRequestId;
                            vm.integrationRequestHasError = vm.integrationRequest.IntegrationRequestResultId !== 1;
                            vm.viewButtonDownload = !vm.integrationRequestHasError;

                            vm.setIsViewing();

                            ribbon.setRibbonStatusButton(ribbon.Buttons.ExportIntegrationExcel, false);
                            ribbon.refreshStatusButtons();

                            if (!vm.isImport && vm.viewButtonDownload) {
                                //DOWNLOAD BOTTOM TEST
                                contextService.addDownloadFile({
                                    fileId: vm.integrationRequest.IntegrationFileId,
                                    fileName: vm.integrationRequest.IntegrationRequestName,
                                    fileType: vm.integrationRequest.IntegrationTemplateFormatId === Lazy(integrationService.dataContext.enumTypes.IntegrationTemplateFormat).findWhere({ name: 'Excel' }).value ? 'Excel' : 'Json',//'Excel',
                                    downloadData: {
                                        entityGuid: vm.integrationRequest.IntegrationFileId,
                                        asAttach: false,
                                        downloadType: "byGuid"
                                    }
                                });
                            }
                        }
                    }
                );
            });            
        };

        vm.downloadFile = function () {
            fileTransferService.downloadRemoteFileByGuid({
                entityGuid: vm.integrationRequest.IntegrationFileId,
                asAttach: false
            });
        };

        vm.onFileUploaded = function (file, res) {
            vm.removeAlerts();
            var dData = {
                fileId: file.id,
                displayFileName: file.name
            };
            vm.uploadfileData.SourceFileName = file.name;
            vm.integrationRequest.TemporaryFileName = fileTransferService.temporaryFileNameByFileId(dData);
            if (!useTemplateDefinition) {
                getIntegrationDefinitionFromFile(vm.integrationRequest.TemporaryFileName);
            }
        };

        function getIntegrationRequest(forceReload) {
            var defer = $q.defer();
            var filter = { IntegrationRequestId: integrationRequestId };

            integrationService.getIntegrationRequests(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results[0]);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getIntegrationDefinitionFromFile(tempoararyFileName) {
            var filter = { TemporaryFileName: tempoararyFileName };
            var defer = $q.defer();

            integrationService.getIntegrationDefinitionFromFile(filter)
                .then(function (data) {
                    if (data.results[0]) {
                        vm.integrationDefinition = data.results[0];
                        vm.integrationRequest.IntegrationDefinitionId = vm.integrationDefinition.IntegrationDefinitionId;
                        vm.integrationRequest.IntegrationRequestName = vm.integrationDefinition.IntegrationDefinitionName;
                        vm.integrationRequest.DeleteDataBeforeImport = vm.integrationDefinition.CanDeleteDataBeforeImport;
                        vm.integrationRequest.UseTemplateDefinition = vm.integrationDefinition.UseTemplateDefinition;

                        if (vm.integrationDefinition.DeleteQueryFilter) {

                            var deleteQueryFilter = Nembus.Common.Library.converter.JsonFieldParse(vm.integrationDefinition.DeleteQueryFilter);
                            Lazy(Object.keys(navigationService.stateParams())).each((pNav) => {
                                Lazy(Object.keys(deleteQueryFilter)).each((pQuery) => {
                                    if (pQuery.toLowerCase() === pNav.toLowerCase()) {
                                        deleteQueryFilter[pQuery] = navigationService.stateParams()[pNav];
                                    }
                                });
                            });
                            //                PostedData: JSON.stringify(navigationService.stateParams()),
                            vm.integrationRequest.DeleteQueryFilter = JSON.stringify(deleteQueryFilter);
                        }
                    }
                    else {
                        vm.uploadfileData.SourceFileName = "";
                        vm.setErrorMsg(vm.translate("TemplateError"), true);
                    }

                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getIntegrationDefinition(integrationDefinitionId) {
            var filter = { IntegrationDefinitionId: integrationDefinitionId };
            var defer = $q.defer();

            integrationService.getIntegrationDefinitions(filter, false)
                .then(function (data) {
                    if (data.results[0]) {
                        vm.integrationDefinition = data.results[0];
                    }
                    else {
                        vm.uploadfileData.SourceFileName = "";
                        vm.setErrorMsg(vm.translate("TemplateError"), true);
                    }

                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getIntegrationDefinitionDtos(forceReload) {
            var defer = $q.defer();
            var filter = {
                IntegrationTemplateFormat: integrationTemplateFormat,
                IntegrationDefinitionType: integrationDefinitionType
            };
            //if (useTemplateDefinition) {
            //    filter.UseTemplateDefinition = useTemplateDefinition;
            //}

            integrationService.getIntegrationDefinitionDtos(filter, forceReload)
                .then(function (data) {
                    /*
                    var directionBw = viewKind === 'import' ? 1 : 2;
                    var defs = Lazy(data.results).filter((i) => { return (i.DirectionBw & directionBw) > 0 }).toArray();
                    defs.forEach(function (def) {
                        def.IntegrationTemplateFormatName = integrationTemplateFormatObjForBind[def.IntegrationTemplateFormatId];
                    });
                    */

                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    integrationRequestDetail.$inject = injectParams;
    integrationRequestDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('integrationRequestDetail', integrationRequestDetail);
});