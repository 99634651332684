﻿/*
* Rds library - tool function
*/
var Nembus = Nembus || {};
Nembus.Common = Nembus.Common || {};
Nembus.Common.Library = Nembus.Common.Library || {};
Nembus.Common.Library.auth = Nembus.Common.Library.auth || {};
Nembus.Common.Library.auth.Permission = Nembus.Common.Library.auth.Permission || {};

if (!Nembus.Rds) {
    Nembus.Rds = {};
    Nembus.Rds.Library = {};
    Nembus.Rds.Library.auth = {};
    Nembus.Rds.Library.Tools = {};

    Nembus.Common.Library.auth.Permission['FMDPRIVATE'] = { value: 15, code: 'FMD_PRIVATE' };
    Nembus.Common.Library.auth.Permission['FMDPUBLIC'] = { value: 15, code: 'FMD_PUBLIC' };

    //#region Act permissions
    Nembus.Common.Library.auth.Permission['ADDACT'] = { value: 1, code: 'ACT_ACTION_C' }; // Create Action
    Nembus.Common.Library.auth.Permission['DELETEACT'] = { value: 8, code: 'ACT_ACTION_D' }; // Delete Action
    Nembus.Common.Library.auth.Permission['COMPLETEACT'] = { value: 16, code: 'ACT_ACTION_COMPLETE' }; // Complete Action

    Nembus.Common.Library.auth.Permission['UPDATEACTEXECUTED'] = { value: 16, code: 'ACT_ACTION_UPDATE' }; // Complete Action
    Nembus.Common.Library.auth.Permission['MANAGEACT'] = { value: 16, code: 'ACT_MANAGE_ALL' }; //  Can manage not assigned

    // Description
    Nembus.Common.Library.auth.Permission['UPDACTDESCRIPTION'] = { value: 16, code: 'ACT_ACTION_UPDDESCRIPTION' }; // Update action with acttypeid != predefined, acttypeid != postdefined
    Nembus.Common.Library.auth.Permission['UPDACTDESCRIPTIONPRE'] = { value: 32, code: 'ACT_ACTION_UPDDESCRIPTION' }; // Update action with acttypeid == predefined
    Nembus.Common.Library.auth.Permission['UPDACTDESCRIPTIONPOST'] = { value: 64, code: 'ACT_ACTION_UPDDESCRIPTION' }; // Update action with acttypeid == postdefined
    // Category
    Nembus.Common.Library.auth.Permission['UPDACTCATEGORY'] = { value: 16, code: 'ACT_ACTION_UPDCATEGORY' }; // Update action with acttypeid != predefined, acttypeid != postdefined
    Nembus.Common.Library.auth.Permission['UPDACTCATEGORYPRE'] = { value: 32, code: 'ACT_ACTION_UPDCATEGORY' }; // Update action with acttypeid == predefined
    Nembus.Common.Library.auth.Permission['UPDACTCATEGORYPOST'] = { value: 64, code: 'ACT_ACTION_UPDCATEGORY' }; // Update action with acttypeid == postdefined
    // Priority
    Nembus.Common.Library.auth.Permission['UPDACTPRIORITY'] = { value: 16, code: 'ACT_ACTION_UPDPRIORITY' }; // Update action with acttypeid != predefined, acttypeid != postdefined
    Nembus.Common.Library.auth.Permission['UPDACTPRIORITYPRE'] = { value: 32, code: 'ACT_ACTION_UPDPRIORITY' }; // Update action with acttypeid == predefined
    Nembus.Common.Library.auth.Permission['UPDACTPRIORITYPOST'] = { value: 64, code: 'ACT_ACTION_UPDPRIORITY' }; // Update action with acttypeid == postdefined
    // Expire data
    Nembus.Common.Library.auth.Permission['UPDACTEXPIRE'] = { value: 16, code: 'ACT_ACTION_UPDEXPIRE' }; // Update action with acttypeid != predefined, acttypeid != postdefined
    Nembus.Common.Library.auth.Permission['UPDACTEXPIREPRE'] = { value: 32, code: 'ACT_ACTION_UPDEXPIRE' }; // Update action with acttypeid == predefined
    Nembus.Common.Library.auth.Permission['UPDACTEXPIREPOST'] = { value: 64, code: 'ACT_ACTION_UPDEXPIRE' }; // Update action with acttypeid == postdefined
    // workflow status (used )
    Nembus.Common.Library.auth.Permission['UPDACTWKFSTATUS'] = { value: 16, code: 'ACT_ACTION_UPDWKFSTATUS' };// Update action with acttypeid != predefined, acttypeid != postdefined
    Nembus.Common.Library.auth.Permission['UPDACTWKFSTATUSPRE'] = { value: 32, code: 'ACT_ACTION_UPDWKFSTATUS' }; // Update action with acttypeid == predefined
    Nembus.Common.Library.auth.Permission['UPDACTWKFSTATUSPOST'] = { value: 64, code: 'ACT_ACTION_UPDWKFSTATUS' }; // Update action with acttypeid == postdefined
    // Assignment
    Nembus.Common.Library.auth.Permission['UPDACTASSIGNMENT'] = { value: 16, code: 'ACT_ACTION_UPDASSIGNMENT' };// Update action with acttypeid != predefined, acttypeid != postdefined
    Nembus.Common.Library.auth.Permission['UPDACTASSIGNMENTPRE'] = { value: 32, code: 'ACT_ACTION_UPDASSIGNMENT' }; // Update action with acttypeid == predefined
    Nembus.Common.Library.auth.Permission['UPDACTASSIGNMENTPOST'] = { value: 64, code: 'ACT_ACTION_UPDASSIGNMENT' }; // Update action with acttypeid == postdefined
    // Act
    Nembus.Common.Library.auth.Permission['ACT_MENU_ACTION_PLAN'] = { value: 15, code: 'ACT_MENU_ACTION_PLAN' };// Update action with acttypeid != predefined, acttypeid != postdefined
    Nembus.Common.Library.auth.Permission['ACT_MENU_ACTION_MYACTION'] = { value: 15, code: 'ACT_MENU_ACTION_MYACTION' }; // Update action with acttypeid == predefined

    Nembus.Common.Library.auth.Permission['ATS_SETUP_VIEW_CUSDATA_GENERAL'] = { value: 15, code: 'ATS_SETUP_VIEW_CUSDATA_GENERAL' };// Update action with acttypeid != predefined, acttypeid != postdefined
    Nembus.Common.Library.auth.Permission['ATS_SETUP_VIEW_CUSDATA_OTHERS'] = { value: 15, code: 'ATS_SETUP_VIEW_CUSDATA_OTHERS' }; // Update action with acttypeid == predefined
    //#endregion

    Nembus.Rds.Library.CanUpdateUser = function () {
    };

    Nembus.Rds.Library.CanManageUser = function (contextService) {
        return contextService.virtualContext.IsSa || contextService.virtualContext.IsUa;
    };

    Nembus.Rds.Library.CanResetPassword = function (contextService) {
        return true;
    };

    Nembus.Rds.Library.CanInvite = function (contextService) {
        return true;
    };

    Nembus.Rds.Library.CanRenewSecretCode = function (contextService) {
        return true;
    };

    Nembus.Rds.Library.CanResetProgrammaticAccessKey = function (contextService) {
        return true;
    };

    Nembus.Rds.Library.ParseActValidationSchemaValidation = function (validationSchema) {
        validationSchema.Validation.Category = validationSchema.Validation.Category && validationSchema.Enabling.Category && validationSchema.Visibility.Category;
        validationSchema.Validation.Description = validationSchema.Validation.Description && validationSchema.Enabling.Description && validationSchema.Visibility.Description;
        validationSchema.Validation.UserAssigned = validationSchema.Validation.UserAssigned && validationSchema.Enabling.UserAssigned && validationSchema.Visibility.UserAssigned;
        validationSchema.Validation.UserGroupAssigned = validationSchema.Validation.UserGroupAssigned && validationSchema.Enabling.UserGroupAssigned && validationSchema.Visibility.UserGroupAssigned;
        validationSchema.Validation.ExpireDate = validationSchema.Validation.ExpireDate && validationSchema.Enabling.ExpireDate && validationSchema.Visibility.ExpireDate;
    };

    Nembus.Rds.Library.Tools.ModuleTargetContext = function (filter, contextService) {
        var moduleTargets = Lazy(contextService.virtualContext.ModuleTargets).toArray();
        if (!Lazy(moduleTargets).some()) {
            return [];
        }

        if (filter.ModuleTargetId) {
            moduleTargets = Lazy(moduleTargets).where({ ModuleTargetId: filter.ModuleTargetId }).toArray();
        }
        if (filter.ModuleTargetTypeCode) {
            moduleTargets = Lazy(moduleTargets).where({ ModuleTargetTypeCode: filter.ModuleTargetTypeCode }).toArray();
        }
        if (filter.ModuleTargetCode) {
            moduleTargets = Lazy(moduleTargets).where({ ModuleTargetCode: filter.ModuleTargetCode }).toArray();
        }
        if (filter.ModuleTargetCodes) {
            moduleTargets = Lazy(moduleTargets).filter((mt) => { return Lazy(filter.ModuleTargetCodes).contains(mt.ModuleTargetCode) }).toArray();
        }
        if (filter.AppStoreBw) {
            moduleTargets = Lazy(moduleTargets).filter((item) => { return (filter.AppStoreBw & item.AppStoreBw) > 0; }).toArray();
        }

        return moduleTargets;
    };

    Nembus.Rds.Library.SetArrayOrder = function (dataList, propertOrder) {
        var i = 0;
        Lazy(dataList)
            .each((data) => {
                i++;
                data[propertOrder] = i;
            });
    };

    Nembus.Rds.Library.GetLastArrayOrderIndex = function (dataList, propertOrder) {
        if (!dataList || !Lazy(dataList).some()) {
            return 1;
        } else {
            var maxPropertyOrder = Lazy(dataList).pluck(propertOrder).max();
            return maxPropertyOrder + 1;
        }
    };

    Nembus.Rds.Library.SetBindingM2MEntitiesInContext = function (contextEntity, masterKeyName, masterKeyValues, dependantKeyName, dependantKeyValues, optionalKeyName, optionalValue) {
        // contextEntity => E.g. actService.dataContext.actM2MCategoryDtos
        // propertyMasterName => E.g. ActId
        // propertyMasterValues => [1,2,3]
        // propertyDependantName => CategoryId
        // propertyDependantValues => [1,2,3]
        if (!Array.isArray(masterKeyValues)
            || !masterKeyName
            || !Lazy(masterKeyValues).some()
            || !dependantKeyName
        ) {
            console.error('parameters error');
            return;
        }

        var result = {
            added: [],
            deleted: []
        };

        // at moment M2M are managed only of type int
        var masterValues = [];
        var dependantValues = [];
        Lazy(masterKeyValues).each((value) => {
            masterValues.push(value);
        });
        Lazy(dependantKeyValues).each((value) => {
            dependantValues.push(value);
        });

        var currentCacheEntities = Lazy(contextEntity.get())
            .filter((entity) => { return Lazy(masterValues).contains(entity[masterKeyName]); })
            .toArray();

        Lazy(masterValues)
            .each((propertyMasterValue) => {
                var currentM2Mentities = Lazy(currentCacheEntities).filter((currentEntity) => { return currentEntity[masterKeyName] === propertyMasterValue; }).toArray();

                Lazy(currentM2Mentities)
                    .each((M2Mentity) => {
                        if (!Lazy(dependantValues).contains(M2Mentity[dependantKeyName])) {
                            if (!M2Mentity.entityAspect.entityState.isDeleted()) {
                                M2Mentity.entityAspect.setDeleted();
                                result.deleted.push(M2Mentity);
                            }
                        }
                    });

                Lazy(dependantValues)
                    .each((currentDependantValue) => {
                        var existentM2M = Lazy(currentM2Mentities)
                            .filter((M2Mentity) => { return M2Mentity[masterKeyName] === propertyMasterValue && M2Mentity[dependantKeyName] === currentDependantValue; })
                            .first();

                        if (!existentM2M) {
                            var newEntity = {};
                            newEntity[masterKeyName] = propertyMasterValue;
                            newEntity[dependantKeyName] = currentDependantValue;

                            if (optionalKeyName && optionalValue) {
                                newEntity[optionalKeyName] = optionalValue;
                            }

                            // add to current context if not exists
                            contextEntity.add(newEntity);
                            result.added.push(newEntity);
                        }
                    });
            });

        return result;
    };

    Nembus.Rds.Library.GetActPriorityIdByServerVariable = function (enumActPriority) {
        var actPriorityId;
        var acts_priority = Nembus.Common.Library.server.getServerVar('ssv_act_priority_level_default');
        switch (acts_priority) {
            case 'priority_low':
                actPriorityId = enumActPriority.Low;
                break;

            case 'priority_medium':
                actPriorityId = enumActPriority.Medium;
                break;

            case 'priority_high':
                actPriorityId = enumActPriority.High;
                break;

            default:
                actPriorityId = null;
        }

        return actPriorityId;
    };
}

