﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'utilService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var parametersList = function ($q, $scope, $timeout, $injector, utilService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, utilService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return parameterSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, utilService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var parameterSelected = null;
        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);

        ribbon.setRibbonStatusButton(ribbon.Buttons.New,  false);
        ribbon.refreshStatusButtons();

        vm.currentServerSystemVariables = [];

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getServerSystemVariables(forceReload, appStoreBw));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.currentServerSystemVariables = results[0];

                    var nbsGridDatacontext = new NbsGrid(vm.translate);
                    nbsGridDatacontext.configureGrid('serversystemvariable', null);
                    nbsGridDatacontext.viewCommonBar();
                    nbsGridDatacontext.setDatasource(vm.currentServerSystemVariables, false);
                    vm.nbsGridDatacontext = nbsGridDatacontext;
                });
        }

        //Go to detail of parameter
        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.parameterdetail-" + vm.viewMode, { 'appStoreCode': appStoreCode, 'variableCode': args.data.VariableCode });
        };

        vm.refreshAction = function () {
            getServerSystemVariables(true, appStoreBw)
                .then((results) => {
                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: results
                    }
                });
        };

        vm.gridSelection = function (args) {
            parameterSelected = args.data;
        };

        function getServerSystemVariables(forceReload, appStoreBw) {
            var filter = {
                IsVisible: true,
                AppStoreBw: appStoreBw
            };
            var defer = $q.defer();

            utilService.getServerSystemVariables(filter, forceReload)
                .then(function (data) {
                    Lazy(data.results).each((systemServerVariable) => {
                        utilService.parseSystemServerVariable(vm.translate, systemServerVariable);                        
                    });

                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    parametersList.$inject = injectParams;
    parametersList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('parametersList', parametersList);
});
