﻿//same of changeMyPassword + cancel button
'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {


    var injectParams = ['$scope', '$http', '$q', '$location', '$uibModal', 'localStorageService', 'contextService', 'authenticationService', 'rbacService', 'navigationService'];
    var changeMyPassword = function ($scope, $http, $q, $location, $uibModal, localStorageService, contextService, authenticationService, rbacService, navigationService) {

        var vm = this;
        nbsVm.call(vm, rbacService, navigationService, contextService, $q, null, null);

        $scope.changePassword = rbacService.dataContext.changePasswords.add();

        vm.modalInstance = $uibModal.open({
            templateUrl: 'changeMyPasswordTemplate',
            scope: $scope,
            backdrop: 'static',
            keyboard: false
        });

        vm.modalCancel = function () {
            vm.modalInstance.dismiss();
        };

        vm.modalOk = function () {
            //modalInstance.dismiss();
            $scope.changePassword.entityAspect.validateEntity();

            if (!$scope.changePassword || $scope.changePassword.entityAspect.hasValidationErrors) {
                $scope.modalErrorMessage = $scope.changePassword.entityAspect.getValidationErrors()[0].errorMessage;
                return;
            }

            else {
                $scope.modalErrorMessage = '';
                rbacService.changeMyPassword($scope.changePassword.newPassword, $scope.changePassword.currentPassword)
                    .then(function (result) {
                        if (result.results[0] === '1') {
                            authenticationService.user.changePasswordNextLogin = false;
                            //have to refresh token info (change virtualcontext with same context)
                            authenticationService.changeVirtualContext(contextService.virtualContext.CurrentVirtualCompanyId).then(function () {

                                navigationService.navToHome();
                            });

                        }
                        else {
                            $scope.modalErrorMessage = 'error';
                        }
                    });
            }
        };
    };

    changeMyPassword.$inject = injectParams;
    changeMyPassword.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rbac.module').controller('changeMyPassword', changeMyPassword);
});