﻿/*
*user authentication based on Oauth2.
*perform POST on /token 
*if credentials  are verified, save token in localstorage
*/

'use strict';
define([], function () {

    var injectParams = ['$q', 'localStorageService', '$location', '$window', '$injector', 'breezeDataLayer'];

    function authenticationService($q, localStorageService, $location, $window, $injector, breezeDataLayer) {
        window.localAuthData = {};
        var dataLayer = breezeDataLayer.Initialize('rbac', true);
        var manager = dataLayer.GetManager();


        var dataLayerVerified = breezeDataLayer.Initialize('verified', true);
        var managerVerified = dataLayerVerified.GetManager();

        var user = {
            isAuthenticated: false,
            changePasswordNextLogin: false
        };

        //var redirAfterLogin = null;
        //var redirAfterLoginParams = null;
        var $http;
        var $state;
        var $uibModal;
        var publicService;

        var authData = getAuthData();

        if (authData) {
            user.isAuthenticated = true;
            user.changePasswordNextLogin = authData.changePasswordNextLogin === '1' ? true : false;
            user.userName = authData.userName;
            user.virtualContextId = authData.virtualContextId;
            user.authMode = authData.authMode;
        }
        var alreadyRedirectedToLogin = false;
        var externalVc = -1;
        var service = {
            verifyOidcCode: verifyOidcCode,
            login: login,
            sendTwoFactorCode: sendTwoFactorCode,
            logout: logout,
            refreshToken: refreshToken,
            changeVirtualContext: changeVirtualContext,
            //changeUserProfile: changeUserProfile,
            user: user,
            redirectToLogin: redirectToLogin,
            redirectToApp: redirectToApp,
            redirectToStepTwo: redirectToStepTwo,
            getAuthData: getAuthData,
            canRefreshToken: canRefreshToken,
            haveToRefreshToken: haveToRefreshToken,
            redirectToRootLocation: redirectToRootLocation,
            redirectToSsoRequest: redirectToSsoRequest,
            redirectToSsoSupportRequest: redirectToSsoSupportRequest,
            renewMyTwoFactorSecretCodeFromAuth: renewMyTwoFactorSecretCodeFromAuth,
            testAuthentication: testAuthentication,
            testVerified: testVerified,
            sessionExpired: sessionExpired,
            setExternalVc: setExternalVc,
            getExternalVc: getExternalVc,
            checkVirtualCompanySwitched: checkVirtualCompanySwitched,
            ssoLogout: ssoLogout,

        };
        return service;

        function getAuthData() {
            var authdata = localStorageService.get('authorizationData');
            if (window.localAuthData === null) {
                window.localAuthData = authData;
            }
            return authdata;
        }

        function redirectToLogin() {
            $state = $state || $injector.get('$state');
            localStorageService.remove('authorizationData');
            user.isAuthenticated = false;
            $state.go('auth.login');
        }

        function sessionExpired() {
            var message = $TG('SessionExpired');
            var modalHeader = '<div class="modal-header" draggable><h3 class="modal-title">' + $TG('ModalWarning') + '</h3></div>';
            var modalHtml = modalHeader + '<div class="modal-body">' + message + '</div>';
            modalHtml += '<div class="modal-footer"><button class="btn btn-primary" ng-click="sessionExpiredOk()">' + $TG('ModalButtonOk') + '</button>';

            var ModalInstanceCtrl = function ($scope, $uibModalInstance) {
                $scope.sessionExpiredOk = function () {
                    $uibModalInstance.close();
                };
            };
            $uibModal = $uibModal || $injector.get('$uibModal');
            var modalInstance = $uibModal.open({
                template: modalHtml,
                controller: ModalInstanceCtrl
            });

            modalInstance.result.then(function () {
                redirectToRootLocation();
                return;
            }, function () {

            });
        }

        function redirectToRootLocation() {
            location.href = '/main/redir';
        }

        function redirectToSsoRequest() {
            //todo: check if ssoConfig is oidc
            location.href = '/main/oidcrequest';
        }

        function redirectToSsoSupportRequest() {
            //todo: check if ssoConfig is oidc
            location.href = '/main/oidcsupportrequest';
        }

        function redirectToApp() {
            $state = $state || $injector.get('$state');
            $state.go('app.nav.appHome');
        }

        function redirectToStepTwo() {
            $state = $state || $injector.get('$state');
            $state.go('auth.steptwo');
        }


        //data = {
        //    username: "",
        //    password: "",
        //    otp: "",
        //    remember: false,
        //    otp: null,
        //};
        function login(data, ssoConfig) {
            $http = $http || $injector.get('$http');

            var rememberme = 0;
            if (data.remember) {
                rememberme = 1;
            }
            var defer = $q.defer();

            var queryStringData = "grant_type=password&username=" + encodeURIComponent(data.username) + "&password=" + encodeURIComponent(data.password) + "&remember=" + rememberme;

            var lastVc = -1;
            if (externalVc > 0) {
                lastVc = externalVc;
            }
            else {

                //add here ssoconfig login last vc behavior
                if (!ssoConfig || (ssoConfig && ssoConfig.VcBehavior === 'last')) {
                    lastVc = localStorageService.get('lastvc-' + data.username.toLowerCase());
                }
                else {
                    lastVc = 0;
                }

            }
            if (lastVc && lastVc > 0) {
                queryStringData += '&vc=' + encodeURIComponent(lastVc.toString());
            }
            $http({
                method: 'POST',
                url: '/Token',
                data: queryStringData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(function (response) {

                //localStorageService.set('authorizationData',
                //                    {
                //                        access_token: response.access_token,
                //                        userName: response.userName,
                //                        profileId: response.profileId,
                //                        virtualContextId: response.virtualContextId,
                //                        refresh_token: response.refresh_token,
                //                        //client_id: response.client_id,
                //                        expires_in: response.expires_in,
                //                        changePasswordNextLogin: response.changePasswordNextLogin
                //                    });
                response = response.data;
                storeAuthData(response);
                //user.isAuthenticated = true;
                user.changePasswordNextLogin = response.changePasswordNextLogin === '1' ? true : false;
                user.userName = response.userName;
                user.virtualContextId = response.virtualContextId;
                user.authMode = response.authMode;
                localStorageService.set('lastvc-' + response.userName.toLowerCase(), response.virtualContextId);
                if (response.twoFactorAuthenticationEnabled === '1') {
                    user.isVerified = true;
                    user.isAuthenticated = false;
                    user.twoFactorAuthenticationEnabled = true;
                    storeAuthData(response);
                }
                else {
                    user.isVerified = true;
                    user.isAuthenticated = true;
                    user.twoFactorAuthenticationEnabled = false;

                    storeAuthData(response);
                }
                defer.resolve(user);
            })
                .catch(function (data, status, headers, config) {
                    user.isAuthenticated = false;
                    defer.reject();
                });
            return defer.promise;
        }

        function verifyOidcCode(data) {
            $http = $http || $injector.get('$http');

            var rememberme = 0;
            if (data.remember) {
                rememberme = 1;
            }
            var defer = $q.defer();

            var queryStringData = "grant_type=oidccode&code=" + encodeURIComponent(data.code) + "&session_state=" + encodeURIComponent(data.session_state);

            var lastVc = -1;
            //if (externalVc > 0) {
            //    lastVc = externalVc;
            //}
            //else {
            //    lastVc = localStorageService.get('lastvc-' + data.username.toLowerCase());
            //}
            //if (lastVc && lastVc > 0) {
            //    queryStringData += '&vc=' + encodeURIComponent(lastVc.toString());
            //}
            $http({
                method: 'POST',
                url: '/Token',
                data: queryStringData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(function (response) {
                response = response.data;
                //storeAuthData(response);
                user.changePasswordNextLogin = response.changePasswordNextLogin === '1' ? true : false;
                user.userName = response.userName;
                user.virtualContextId = response.virtualContextId;
                user.authMode = response.authMode;
                localStorageService.set('lastvc-' + response.userName.toLowerCase(), response.virtualContextId);
                user.isVerified = true;
                user.isAuthenticated = true;
                user.twoFactorAuthenticationEnabled = false;
                storeAuthData(response);
                defer.resolve(user);
            })
                .catch(function (data, status, headers, config) {
                    user.isAuthenticated = false;
                    defer.reject();
                });
            return defer.promise;
        }


        function storeAuthData(response) {
            var obj = {
                access_token: response.access_token,
                userName: response.userName,
                profileId: response.profileId,
                virtualContextId: response.virtualContextId,
                refresh_token: response.refresh_token,
                expires_in: response.expires_in,
                changePasswordNextLogin: response.changePasswordNextLogin,
                twoFactorAuthenticationEnabled: response.twoFactorAuthenticationEnabled,
                twoFactorProvider: response.twoFactorProvider,
                issued: new Date(Date.parse(response['.issued'])),
                expires: new Date(Date.parse(response['.expires'])),
                tokenId: response.tokenId,
                authMode: response.authMode,
            };
            localStorageService.set('authorizationData', obj);
        }

        function sendTwoFactorCode(data) {
            var defer = $q.defer();
            $http = $http || $injector.get('$http');
            var queryStringData2ndStep = "grant_type=twofactor_authenticator&code=" + encodeURIComponent(data.otp);
            $http({
                method: 'POST',
                url: '/Token',
                data: queryStringData2ndStep,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(function (response) {
                response = response.data;
                user.isAuthenticated = true;
                storeAuthData(response);
                defer.resolve(user);
            })
                .catch(function (data, status, headers, config) {
                    user.isAuthenticated = false;
                    localStorageService.remove('authorizationData');
                    defer.reject();
                });
            return defer.promise;
        }

        function logout() {
            var defer = $q.defer();
            var tmpData = localStorageService.get('authorizationData');
            localStorageService.remove('authorizationData');
            user.isAuthenticated = false;
            $http = $http || $injector.get('$http');

            var refresh_token = 'nop';
            if (tmpData !== null && tmpData.refresh_token !== null) {
                refresh_token = tmpData.refresh_token;
            }
            $http({
                method: 'POST',
                url: '/rbac/authentication/logout',
                data: "viewmode=_&refresh_token=" + encodeURIComponent(refresh_token),
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(function (response) {
                //authMode: 'idp', 'owc'
                if (tmpData) {
                    defer.resolve({ authMode: tmpData.authMode });
                }
                else {
                    defer.resolve({ authMode: 'nan' });
                }

            }).catch(function (data, status, headers, config) {
                user.isAuthenticated = false;
                defer.reject();
            });
            return defer.promise;
        }

        function ssoLogout(ssoConfig) {

            var logouturi = ssoConfig.LogoutUri.replace('[clientId]', ssoConfig.ClientId).replace('[returnTo]', ssoConfig.LogoutPostBackUri);
            while (logouturi.indexOf("&amp;") >= 0) {
                logouturi = logouturi.replace('&amp;', '&');
            }
            location.href = logouturi;
        }



        function canRefreshToken() {
            var authData = getAuthData();
            if (authData && authData.refresh_token) {
                return true;
            }
            return false;
        }

        function checkVirtualCompanySwitched() {
            var authData = getAuthData();
            if (window.localAuthData == null || window.localAuthData.virtualContextId == null) {
                window.localAuthData = authData;
                return false;
            };
            return authData == null || window.localAuthData.virtualContextId != authData.virtualContextId;
        }

        function haveToRefreshToken() {
            var authData = getAuthData();
            if (authData) {
                var expire_diff = ((new Date(Date.parse(authData.expires)).getTime() - new Date().getTime()) / 60000);
                //console.log('expires:' + authData.expires);
                //console.log('expires getTime:' + new Date(Date.parse(authData.expires)).getTime());
                //console.log('expire diff:' + expire_diff);
                var haveTo = expire_diff < Nembus.Common.Library.auth.refreshTokenMargin;

                return haveTo;
            }
            return true;
        }

        function refreshToken() {
            var requestHeaders = { 'Content-Type': 'application/x-www-form-urlencoded' };
            return refreshAuthToken(requestHeaders);
        }

        function changeVirtualContext(targetVirtualContextId, targetLanguageId) {
            var requestHeaders = { 'Content-Type': 'application/x-www-form-urlencoded' };
            requestHeaders.custom_action = 'change_virtualcontext';
            requestHeaders.target_virtual_context_id = targetVirtualContextId;
            requestHeaders.target_language_id = targetLanguageId;
            return refreshAuthToken(requestHeaders);
        }

        var refreshrunning = false;
        //refresh token is also used to change virtual context or change user profile
        function refreshAuthToken(requestHeaders) {
            var deferred = $q.defer();

            var authData = localStorageService.get('authorizationData');//getAuthData(); // always get from localstorage because browser multiple window
            if (refreshrunning) {
                deferred.resolve(authData);
                return deferred.promise;
            }
            $http = $http || $injector.get('$http');

            $http({
                method: 'POST',
                url: '/Token',
                data: "grant_type=refresh_token&refresh_token=" + encodeURIComponent(authData.refresh_token),
                headers: requestHeaders
            }).then(function (response) {
                response = response.data;
                storeAuthData(response);
                user.isAuthenticated = true;
                user.changePasswordNextLogin = response.changePasswordNextLogin === '1' ? true : false;
                user.userName = response.userName;
                user.virtualContextId = response.virtualContextId;
                user.authMode = response.authMode;
                localStorageService.set('lastvc-' + response.userName.toLowerCase(), response.virtualContextId);
                refreshrunning = false;
                deferred.resolve(response);
            })
                .catch(function (err, status, headers, config) {
                    refreshrunning = false;
                    user.isAuthenticated = false;
                    deferred.reject(err);
                });
            return deferred.promise;

        }

        function testAuthentication(username) {
            var query = dataLayer.InvokeQuery("TestAuthentication",
                { Username: username });
            return manager.executeQuery(query);
        }

        function testVerified(username) {
            var query = dataLayerVerified.InvokeQuery("TestVerified",
                { Username: username });
            return managerVerified.executeQuery(query);
        }

        function renewMyTwoFactorSecretCodeFromAuth(currentUserDto) {
            //call renew to only api that enable "verified" user
            //all other api require "authenticated"
            var query = dataLayerVerified.InvokeQuery("RenewMyTwoFactorSecretCode", currentUserDto);
            return managerVerified.executeQuery(query);
        }

        function setExternalVc(vc) {
            externalVc = Number(vc)
        }

        function getExternalVc() {
            return externalVc;
        }
    }

    authenticationService.$inject = injectParams;
    angular.module('nbs.shared.module').service('authenticationService', authenticationService);

});