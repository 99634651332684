// @ts-ignore
var Lazy = require('lazy.js');
import { Directive, ElementRef, Injector } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { NbsVm2 } from '../../common/controllers/nbs-vm2';

class AppHomeController extends NbsVm2 {
    constructor($injector) {
        super(null, $injector)
    }
}

let AppHomeComponent = {
    selector: "appHome",
    templateUrl: 'shell/navigation/appHome/_',
    controller: AppHomeController,
    controllerAs: 'vm'
};

// @ts-ignore
angular.module('nbs.shell.module').component(AppHomeComponent.selector, AppHomeComponent);


@Directive({
    selector: 'app-home'
})
export class AppHomeDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef, injector: Injector) {
        super('appHome', elementRef, injector);
    }
}

