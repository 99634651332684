﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function vhrGroupsList($q, $scope, $timeout, $injector, foundationService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return vhrGroupSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, foundationService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var vhrGroupSelected = null;
        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.vhrgroupdetail-" + vm.viewMode, { 'vhrGroupId': args.data.VhrGroupId, 'appStoreCode': appStoreCode });
        };

        vm.newAction = function () {
            navigationService.navigateTo("app.nav.vhrgroupdetail-" + vm.viewMode, { 'vhrGroupId': null, 'appStoreCode': appStoreCode });
        };

        vm.refreshAction = function () {
            loadData(true);
        };

        vm.deleteAction = function () {
            if (!vhrGroupSelected) {
                return;
            }            

            foundationService.deleteEntity(vhrGroupSelected);
            vm.submitAction()
                .then(function (saveResult) {
                    vm.refreshAction();
                });
        };

        vm.gridSelection = function (args) {
            $timeout(() => {
                vhrGroupSelected = args.data;

                if (vhrGroupSelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, !vhrGroupSelected.IsSystem);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                }

                ribbon.refreshStatusButtons();
            });            
        };

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('vhrgroup', null);
        nbsGridDatacontext.viewCommonBar();
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getVhrGroups(forceReload, appStoreBw));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: results[0]
                    }
                });
        }

        function getVhrGroups(forceReload, appStoreBw) {
            var filter = { AppStoreBw: appStoreBw };
            var defer = $q.defer();

            foundationService.getVhrGroups(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    }

    vhrGroupsList.$inject = injectParams;
    vhrGroupsList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('vhrGroupsList', vhrGroupsList);
});