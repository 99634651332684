﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'drbacService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var drbacConfigDetail = function ($q, $scope, $timeout, $injector, drbacService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, drbacService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, drbacService);

        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        vm.setIsViewing();

        loadData(true);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getDrbacSchemas(forceReload, virtualCompanyId));
            funcsPromises.push(getDrbacProcessGlobals(forceReload, virtualCompanyId));
            funcsPromises.push(getDrbacConfig(forceReload, virtualCompanyId));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.drbacSchemas = results[0];
                    vm.drbacGlobalProcesses = results[1];
                    vm.drbacConfig = results[2][0];

                    Lazy(vm.drbacSchemas)
                        .each((item) => {
                            item.DrbacSchemaNameUI = item.DrbacSchemaName;
                            if (item.IsSystem) {
                                item.DrbacSchemaNameUI += ' (' + vm.translate('DrbacConfigDetail.IsSystem') + ')'
                            }
                        });

                    Lazy(vm.drbacGlobalProcesses)
                        .each((item) => {
                            item.DrbacProcessNameUI = item.DrbacProcessName;
                            if (item.IsSystem) {
                                item.DrbacProcessNameUI += ' (' + vm.translate('DrbacConfigDetail.IsSystem') + ')'
                            }
                        });
                });
        }

        function getDrbacSchemas(forceReload, virtualCompanyId) {
            var filter = { VirtualCompanyId: virtualCompanyId };
            var defer = $q.defer();

            drbacService.getDrbacSchemas(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        // only one process global for virtual company
        function getDrbacProcessGlobals(forceReload) {
            var defer = $q.defer();

            drbacService.getDrbacProcessGlobals({}, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getDrbacConfig(forceReload, virtualCompanyId) {
            var filter = {
                VirtualCompanyId: virtualCompanyId
            };
            var defer = $q.defer();

            drbacService.getDrbacConfig(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    drbacConfigDetail.$inject = injectParams;
    drbacConfigDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rbac.module').controller('drbacConfigDetail', drbacConfigDetail);
});