﻿
'use strict';
define(['../../common/services/nbs-service'], function (nbsService) {

    var injectParams = ['$http', '$q', '$timeout', 'breezeDataLayer'];

    var bomService = function ($http, $q, $timeout, breezeDataLayer) {

        var dataLayer = breezeDataLayer.Initialize('activity', true);
        var manager = dataLayer.GetManager();
        var ejDataManager = dataLayer.GetEjDataManager();
        var entityTypes = {
            Activity: 'Activity',
            DependentActivityPathDto: 'DependentActivityPathDto',
            SchedulingGroup: 'SchedulingGroup',
            ActivityClientData: 'ActivityClientData'
        };
        var me = this;
        nbsService.call(this, manager, $q, $timeout);

        //breeze client type
        //client validation
        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.ActivityClientData,
                dataProperties: {
                    ActivityClientDataId: { type: breeze.DataType.Guid },
                    ActivityId: { type: breeze.DataType.Int32 },
                    ActivityKindId: { type: breeze.DataType.Int32 },
                    ActivityCode: { type: breeze.DataType.String },
                    ActivityName: { type: breeze.DataType.String }
                }
            },
            'Nembus.Domain'
        );

        var activityClientData = manager.metadataStore.getEntityType('ActivityClientData');
        activityClientData.getProperty("ActivityCode").validators.push(new breeze.Validator("codeValidator", Nembus.Common.Library.validators.requiredFieldValidator));
        activityClientData.getProperty("ActivityName").validators.push(new breeze.Validator("nameValidator", Nembus.Common.Library.validators.requiredFieldValidator));

        // ADD Custom validator for activity property (only in bom service)
        var activityType = manager.metadataStore.getEntityType(entityTypes.Activity);
        var activityProperty = activityType.getProperty("SchedulingGroupId");
        activityProperty.validators.push(breeze.Validator.required());

        // add client validation valid only for Bom (Distinta)
        this.addClientValidation = function () {
            var activity = manager.metadataStore.getEntityType('Activity');
            activity.getProperty("SpanValidityQuota").validators.push(new breeze.Validator("spanValidityQuotaValidator", Nembus.Common.Library.validators.requiredFieldValidator));
            activity.getProperty("SpanValidityUnitId").validators.push(new breeze.Validator("spanValidityUnitIdValidator", Nembus.Common.Library.validators.requiredFieldValidator));
        };

        // remove client validation valid only for Bom (Distinta)
        this.removeClientValidation = function () {
            // remove validators added in activity-service
            var activity = manager.metadataStore.getEntityType('Activity');
            activity.getProperty("ActivityEffortQuota").validators = Lazy(activity.getProperty("ActivityEffortQuota").validators)
                .filter((validator) => { return validator.name !== "activityEffortQuotaValidator"; })
                .toArray();

            activity.getProperty("ActivityEffortUnitId").validators = Lazy(activity.getProperty("ActivityEffortUnitId").validators)
                .filter((validator) => { return validator.name !== "activityEffortUnitIdValidator"; })
                .toArray();
        };

        this.dataContext = {
            ActivityClientData: dataLayer.setContextMethods(manager, nbsService, entityTypes.ActivityClientData),
            boms: dataLayer.setContextMethods(manager, nbsService, entityTypes.Activity),
            activities: dataLayer.setContextMethods(manager, nbsService, entityTypes.Activity),
            schedulingGroups: dataLayer.setContextMethods(manager, nbsService, entityTypes.SchedulingGroup),
            dependActivities: dataLayer.setContextMethods(manager, nbsService, entityTypes.DependentActivityPathDto),

            enumTypes: {
                ActivityKind: dataLayer.GetEnumType('ActivityKind'),
                //ActivityInvoice: dataLayer.GetEnumType('ActivityInvoice'),
                RouteSchedulingType: dataLayer.GetEnumType('RouteSchedulingType'),
                BomHierarchyMode: dataLayer.GetEnumType('BomHierarchyMode'),
                MagicBucketAction: dataLayer.GetEnumType('MagicBucketAction')
            }
        };

        this.createQuery = function (entityName) {
            return dataLayer.CreateQuery(entityName);
        };

        this.getActivities = function (filter, forceReload) {
            var params = { entityType: entityTypes.Activity, cache: this.dataContext.boms.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "Boms");
        };
        this.getActivity = function (filter, forceReload) {
            var params = { key: filter.ActivityId, dataContext: this.dataContext.boms };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "Boms");
        };
        
        this.getSchedulingGroups = function (filter, forceReload) {
            var params = { entityType: entityTypes.SchedulingGroup, cache: this.dataContext.schedulingGroups.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "SchedulingGroups");
        };

        this.getSchedulingGroup = function (filter, forceReload) {
            var params = { key: filter.SchedulingGroupId, dataContext: this.dataContext.schedulingGroups };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "SchedulingGroups");
        };

        // Activities paged
        this.getActivitiesPagedDm = function () {
            return ejDataManager.getEjManager('BomDtosPaged');
        };
        this.getActivitiesPagedDmQuery = function () {
            var dmObj = {};
            dmObj.dm = ejDataManager.getEjManager('');
            dmObj.query = ej.Query().from('BomDtosPaged');
            return dmObj;
        };

        this.getDependentActivityPaths = function (filter, forceReload) {
            // this clear cache and force reload from db
            forceReload = true;

            var params = { entityType: entityTypes.DependentActivityPathDto, cache: this.dataContext.dependActivities.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "DependentActivityPaths");
        };
        this.getDependentActivityPath = function (filter, forceReload) {
            var params = { key: filter.ActivityId, dataContext: this.dataContext.dependActivities };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "DependentActivityPaths");
        };

        //Http Post
        this.cloneActivity = function (activityClientData) {
            var query = dataLayer.InvokeQuery("CloneBom", activityClientData);
            return manager.executeQuery(query);
        };

        //#region DataManager
        this.getActivityDataManager = function (supportData, filter, dmObj) {
            if (dmObj) {
                dmObj.complexParams = [{ filter: filter }];
                return dmObj;
            }

            dmObj = this.getActivitiesPagedDmQuery();
            dmObj.dmgrid = this.getActivitiesPagedDm();
            var ejDataManager = dmObj.dmgrid;

            ejDataManager.complexParams.push({ filter: filter });
            ejDataManager.fieldNameReplacer = {
                ActivityEffortUnitName: 'ActivityEffortUnitId',
                ActivityEffortQuotaUI: 'ActivityEffortQuota'
            };
            // properties inserte are calculated before query is launched in breezeDataLayer service
            ejDataManager.onWhereCustomPredicate = {
                ActivityEffortUnitName: {
                    FieldType: this.FilterMenu.FieldType.Enum,
                    ReplaceFieldNameWith: 'ActivityEffortUnitId',
                    Datasource: supportData.effortUnitEnumParsed.list.translated
                }
            };
            ejDataManager.processResponseData = function (data) {
                if (data) {
                    dmObj.dm.currentViewChildGridData = [];

                    data.results = me.getAttachedEntities(data.results);
                    Lazy(data.results)
                        .each((item) => {
                            item.ActivityEffortUnitName = supportData.effortUnitEnumParsed.obj.translated.value_name[item.ActivityEffortUnitId];
                        });

                    return data.results;
                }
            };
            ejDataManager.freeSearchFields = ['ActivityCode', 'ActivityName', 'VhrEventTypeName', 'VhrEventGroupName', 'SchedulingGroupName', 'ModuleTargetName'];
            ejDataManager.freeSearchFieldsNumber = ['ActivityId'];

            dmObj.dm.onWhereCustomPredicate = ejDataManager.onWhereCustomPredicate;
            dmObj.dm.fieldNameReplacer = ejDataManager.fieldNameReplacer;
            dmObj.dm.complexParams = ejDataManager.complexParams;
            dmObj.dm.processResponseData = ejDataManager.processResponseData;
            return dmObj;
        }
        //#endregion
    };

    bomService.$inject = injectParams;
    bomService.prototype = Object.create(nbsService.prototype);
    angular.module('nbs.rds.module').service('bomService', bomService);
});