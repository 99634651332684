﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'audService', 'reportService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var surveyQuestionOptionAnalysisReportForm = function ($q, $scope, $timeout, $injector, audService, reportService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, audService, navigationService, contextService, $q, $injector, $timeout);
        var checkStateChangeParams = { dirtyFlagIsDisabled: vm.isDirtyDisabled };
        checkStateChangeService.checkFormOnStateChange($scope, audService, checkStateChangeParams);

        var surveyLifeStatusEnumObj = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyLifeStatus, null, vm.translate).obj.normal.name_value;

        var appStoreCode = 'aud';
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);

        // all form items are enabled
        vm.setIsEditingReport();

        var selectionParams = { stateTo: 'app.nav.surveyslistmodal', destModalKey: 'survey', isMultiselect: true, dataKey: 'surveycompleted', filter: { VhrEventLifeStatusIds: [surveyLifeStatusEnumObj.ReportedAsFinished] } };
        vm.nbsSelectionGridWithModalSurvey1 = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalSurvey1.viewId = true;

        vm.ngNumberMatrixDatasource = new NbsNumberMatrix();
        vm.ngNumberMatrixDatasource.addCol(vm.translate('Section'), vm.translate('All2')); // index 0
        vm.ngNumberMatrixDatasource.addCol(vm.translate('Question'), vm.translate('All2')); // index 1

        vm.surveyFilter = {
            SurveyIds: null
        };
        vm.surveySectionFilter = {
            SurveySectionOrders: null
        };
        vm.surveyQuestionFilter = {
            SurveyQuestionOrders: null
        };
        vm.vhrEventFilterFilter = {
            ModuleTargetId: moduleTargetId
        };
        vm.surveyQuestionOptionFilter = {
            IsChecked: true,
            IsImportant: null
        };

        loadData();
        function loadData() {
            var funcsPromises = [];

            $q.all(funcsPromises)
                .then(function () {
                    reportService.currentReport.isCustomValidatedCallback = isCustomValidatedCallback;
                    reportService.currentReport.prepareReportFilterCallback = setReportArgs;
                });
        }

        function isCustomValidatedCallback() {
            vm.removeAlertFromList(false);

            var validated = true;

            if (!Lazy(vm.nbsSelectionGridWithModalSurvey1.selectedIds).some() || Lazy(vm.nbsSelectionGridWithModalSurvey1.selectedIds).length() > 3
                //&& !Lazy(vm.nbsSelectionGridWithModalSurvey2.selectedIds).some()
                //&& !Lazy(vm.nbsSelectionGridWithModalSurvey3.selectedIds).some()
            ) {
                vm.setErrorMsg({ isMessageVisible: true, message: vm.translate('SurveyQuestionOptionAnalysisReportForm.SurveyQuestionOptionAnalysisReportFormAuditValidationMessage') });
                $timeout(() => { /* implicit scope apply; */ });
                validated = false;
                return;
            }

            return validated;
        }

        function setReportArgs() {
            var reportFilterData = new NbsReportFilterData(vm.translate, contextService.virtualContext.cultureInfo, Nembus.Common.Library.converter.date.formatDate);

            vm.queryOptions = {};

            reportFilterData.setDataBySelectionModal('Audit1', 'SurveyQuestionOptionAnalysisReportForm.SurveyQuestionOptionAnalysisReportFormAudit', vm.nbsSelectionGridWithModalSurvey1);
            //reportFilterData.setDataBySelectionModal('Audit2', 'SurveyQuestionOptionAnalysisReportForm.SurveyQuestionOptionAnalysisReportFormAudit', vm.nbsSelectionGridWithModalSurvey2);
            //reportFilterData.setDataBySelectionModal('Audit3', 'SurveyQuestionOptionAnalysisReportForm.SurveyQuestionOptionAnalysisReportFormAudit', vm.nbsSelectionGridWithModalSurvey3);
            reportFilterData.setDataByNumberMatrix('NumberMatrix', 'SurveyQuestionOptionAnalysisReportForm.SurveyQuestionOptionAnalysisReportFormSectionQuestion', vm.ngNumberMatrixDatasource);

            var surveyIds = [];
            if (Lazy(vm.nbsSelectionGridWithModalSurvey1.selectedIds).some()) {
                //surveyIds.push(Lazy(vm.nbsSelectionGridWithModalSurvey1.selectedIds).first());
                surveyIds = vm.nbsSelectionGridWithModalSurvey1.selectedIds;
            }
            //if (Lazy(vm.nbsSelectionGridWithModalSurvey2.selectedIds).some()) {
            //    surveyIds.push(Lazy(vm.nbsSelectionGridWithModalSurvey2.selectedIds).first());
            //}
            //if (Lazy(vm.nbsSelectionGridWithModalSurvey3.selectedIds).some()) {
            //    surveyIds.push(Lazy(vm.nbsSelectionGridWithModalSurvey3.selectedIds).first());
            //}

            if (Lazy(surveyIds).some()) {
                vm.surveyFilter.SurveyIds = Lazy(surveyIds).uniq().toArray();
            } else {
                vm.surveyFilter.SurveyIds = null;
            }

            var sectionOrders = vm.ngNumberMatrixDatasource.getValuesByCol(0, true);
            var questionOrders = vm.ngNumberMatrixDatasource.getValuesByCol(1, true);

            if (Lazy(sectionOrders).some() && !Lazy(sectionOrders).contains(null)) {
                vm.surveySectionFilter.SurveySectionOrders = sectionOrders;
            } else {
                vm.surveySectionFilter.SurveySectionOrders = null;
            }
            if (Lazy(questionOrders).some() && !Lazy(questionOrders).contains(null)) {
                vm.surveyQuestionFilter.SurveyQuestionOrders = questionOrders;
            } else {
                vm.surveyQuestionFilter.SurveyQuestionOrders = null;
            }

            reportService.currentReport.reportArgs.Ds1_Arg1 = vm.surveyFilter;
            reportService.currentReport.reportArgs.Ds1_Arg2 = vm.vhrEventFilterFilter;
            reportService.currentReport.reportArgs.Ds1_Arg3 = vm.surveySectionFilter;
            reportService.currentReport.reportArgs.Ds1_Arg4 = vm.surveyQuestionFilter;
            reportService.currentReport.reportArgs.Ds1_Arg5 = vm.surveyQuestionOptionFilter;
            reportService.currentReport.reportArgs.Ds1_Arg6 = vm.queryOptions;
            reportService.currentReport.reportArgs.Ds1_Arg7 = { Datas: reportFilterData.Datas };
        }        
    };

    surveyQuestionOptionAnalysisReportForm.$inject = injectParams;
    surveyQuestionOptionAnalysisReportForm.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('surveyQuestionOptionAnalysisReportForm', surveyQuestionOptionAnalysisReportForm);
});
