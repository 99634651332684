﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'hrbucketService', 'navigationService', 'checkStateChangeService', 'contextService', 'formSummaryService'];
    function vhrReferencesDetail($q, $scope, $timeout, $injector, foundationService, hrbucketService, navigationService, checkStateChangeService, contextService, formSummaryService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, foundationService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var virtualHumanResourceId = navigationService.stateParams().virtualHumanResourceId;
        var vhrType = navigationService.stateParams().vhrType;
        
        ribbon.setRibbonStatusButton(ribbon.Buttons.Modify, false);
        ribbon.refreshStatusButtons();

        // get array items for form summary
        vm.formSummary = formSummaryService.getDatasource();

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];

            switch (vhrType) {
                case 'asset':
                    funcsPromises.push(getVirtualEquipmentDto(forceReload, virtualHumanResourceId));
                    break
                case 'bucket':
                    funcsPromises.push(getHrBuckets(forceReload, virtualHumanResourceId));
                    break

            }

            $q.all(funcsPromises)
                .then(function (results) {
                    var nbsQrCode = new NbsQrCode();
                    switch (vhrType) {
                        case 'asset':                            
                            vm.qrCodeDatasource = nbsQrCode.assetDs(contextService.virtualContext.UriQrCodeService, results[0], 200, 200);
                            break
                        case 'bucket':
                            vm.qrCodeDatasource = nbsQrCode.bucketDs(contextService.virtualContext.UriQrCodeService, results[0], 200, 200);
                            break

                    }        
                });
        }

        function getVirtualEquipmentDto(forceReload, virtualHumanResourceId) {
            var filter = { VirtualHumanResourceId: virtualHumanResourceId };
            var defer = $q.defer();

            foundationService.getVirtualEquipmentDto(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results[0]);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getHrBuckets(forceReload, virtualHumanResourceId) {
            var filter = { VirtualHumanResourceId: virtualHumanResourceId };
            var defer = $q.defer();

            hrbucketService.getHrBuckets(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results[0]);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    }

    vhrReferencesDetail.$inject = injectParams;
    vhrReferencesDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('vhrReferencesDetail', vhrReferencesDetail);
});