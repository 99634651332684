﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$injector', '$scope', '$timeout', 'vhreventService', 'audService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function mySurveySchedule($q, $injector, $scope, $timeout, vhreventService, audService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, audService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, audService);

        var ribbon = new NbsAudRibbon(navigationService, contextService.virtualContext, 'schedule');
        ribbon.addHomeTab();
        ribbon.addTab(ribbon.GetAuditViewsTab());
        ribbon.setUnderBreakpointSmall();
        ribbon.tabIndexSelected = 2;
        vm.ribbonDatacontext = ribbon;

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var vhrEventTypeCode = navigationService.stateParams().vhrEventTypeCode;
        var vhrEventFixedTypeCode = navigationService.stateParams().vhrEventFixedTypeCode;
        var vhrEventKindEnum = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventKind, null, vm.translate).obj.normal.name_value;
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var surveyCreationPatternObj = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyCreationPattern, null, vm.translate).obj.normal.name_value;
        var vhrEventLifeStatusKey = navigationService.stateParams().vhrEventLifeStatusKey;
        var viewKind = navigationService.stateParams().viewKind;

        var currentSurveyBarFilter = null;
        var currentSurveyBarScheduleParams = null;
        var currentViewSelected = null;
        var currentScheduleOrientation = null;

        //#region set ribbon fn navigations
        var navParams = { 'appStoreCode': appStoreCode, 'vhrEventLifeStatusKey': vhrEventLifeStatusKey, 'viewKind': viewKind, 'vhrEventFixedTypeCode': vhrEventFixedTypeCode };
        vm.onRibbonGrid = () => { ribbon.onRibbonGrid_MySurvey(navParams); };
        vm.onRibbonCalendar = () => { ribbon.onRibbonCalendar_MySurvey(navParams); };
        vm.onRibbonStream = () => { ribbon.onRibbonStream_MySurvey(navParams); };
        vm.onRibbonBoard = () => { ribbon.onRibbonBoard_MySurvey(navParams); };
        vm.onRibbonTimeline = () => { ribbon.onRibbonTimeline_MySurvey(navParams); };
        vm.onRibbonTimelineTarget = () => { ribbon.onRibbonTimelineTarget_MySurvey(navParams); };
        vm.onRibbonTimelineAssignment = () => { ribbon.onRibbonTimelineTargetAssignment_MySurvey(navParams); };
        //#endregion set ribbon fn navigations

        //#region set ribbon enabled buttons
        ribbon.setRibbonStatusButton(ribbon.Buttons.ViewStream, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.Board, vhrEventLifeStatusKey !== 'ondemand');
        ribbon.setRibbonStatusButton(ribbon.Buttons.ViewList, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.ViewCalendar, vhrEventLifeStatusKey !== 'ondemand');
        ribbon.setRibbonStatusButton(ribbon.Buttons.Timeline, vhrEventLifeStatusKey !== 'ondemand');
        ribbon.setRibbonStatusButton(ribbon.Buttons.TimelineTarget, vhrEventLifeStatusKey !== 'ondemand');
        ribbon.setRibbonStatusButton(ribbon.Buttons.TimelineAssignment, vhrEventLifeStatusKey !== 'ondemand');
        switch (viewKind) {
            case 'calendar':
                ribbon.setRibbonStatusButton(ribbon.Buttons.ViewCalendar, false);
                viewSchedule('calendar');
                break;
            case 'timeline':
                ribbon.setRibbonStatusButton(ribbon.Buttons.Timeline, false);
                viewSchedule('timeline');
                break;
            case 'timelinevirtualequipment':
                ribbon.setRibbonStatusButton(ribbon.Buttons.TimelineTarget, false);
                viewSchedule('timelinevirtualequipment');
                break;
            case 'timelineassignment':
                ribbon.setRibbonStatusButton(ribbon.Buttons.TimelineAssignment, false);
                viewSchedule('timelineassignment');
                break;
        }
        ribbon.refreshStatusButtons();
        //#endregion set ribbon enabled buttons



        // hook from gridbar
        vm.surveyBarOnChanges = function (result, scheduleParams) {
            // save in view current filter passed by survey bar
            currentSurveyBarFilter = result;
            currentSurveyBarScheduleParams = scheduleParams;

            // refresh of filter parsed            
            refreshCalendarDatasource();
        };

        vm.modalOk = function (modalInstance) {
            vm[modalInstance].close();
        };

        vm.modalCancel = function (modalInstance) {
            vm[modalInstance].dismiss();
        };

        vm.onSurveyScheduleCreated = function (id) {
            ribbon.setRibbonStatusButton(ribbon.Buttons.Modify, true);
            ribbon.refreshStatusButtons();

            vm.surveyBarDataContext = {
                vhrEventLifeStatusKey: vhrEventLifeStatusKey,
                commonBarDatasource: { modeUI: 'schedule' },
                appStoreBw: appStoreBw,
                moduleTargetId: moduleTargetId,
                groupingKey: viewKind,
                defaultKeys: {
                    columnsKey: vhrEventLifeStatusKey, sortingKey: null
                }
            };
        };

        vm.gridUiOnChanges = function (result) {
            vm.refreshGridUiComponent = {
                refreshKey: new Date().getTime(),
                uiData: result
            }
        }

        function viewSchedule(viewSelected) {
            currentViewSelected = viewSelected;

            currentScheduleOrientation = ej.Schedule.Orientation.Horizontal;
            if (currentViewSelected === 'calendar') {
                currentScheduleOrientation = ej.Schedule.Orientation.Vertical;
            }

            vm.visualizationKey = currentViewSelected;

            // create schedule
            $timeout(function () {
                vm.surveyScheduleDataInit = {
                    scheduleId: "nbsschedule" + NbsGuidManager.newGuidReplaced(),
                    orientation: currentScheduleOrientation
                };
            });
        }

        function refreshCalendarDatasource() {
            $timeout(function () {
                var filter = parseFromBarFilter(currentSurveyBarFilter);

                vm.surveyScheduleDataRefresh = {
                    dataFilter: filter,
                    groupingKeys: Lazy(currentSurveyBarScheduleParams.selectedGroupings).pluck('optionValue').toArray()
                };
            });
        }

        vm.refreshAction = function () {
            refreshCalendarDatasource();
        };

        // open add new auditrequest view
        vm.newAction = function () {
            navigationService.navigateTo("app.nav.surveycreationrequestdetail", { 'surveyId': null, 'appStoreCode': appStoreCode, 'viewKind': viewKind });
        };

        //#region Filters for datasources
        function parseFromBarFilter(surveyBarFilter) {
            // surveyFilter
            surveyBarFilter.surveyFilter.IsArchived = vhrEventLifeStatusKey === 'archived' ? true : false;
            switch (vhrEventLifeStatusKey) {
                case 'tocompile':
                    surveyBarFilter.surveyFilter.IsCompleted = false;
                    break;
                case 'compiled':
                    surveyBarFilter.surveyFilter.IsCompleted = true;
                    break;
                default:
            }
            // surveyVhrEventFilter
            if (vhrEventLifeStatusKey === 'ondemand') {
                surveyBarFilter.surveyVhrEventFilter.SurveyCreationPatternId = surveyCreationPatternObj.OnDemand;
            } else {
                surveyBarFilter.surveyVhrEventFilter.ExcludeOnDemand = true;
            }
            // vhrEventFilter
            surveyBarFilter.vhrEventFilter.AppStoreBw = appStoreBw;
            surveyBarFilter.vhrEventFilter.VhrEventTypeCode = vhrEventTypeCode;
            surveyBarFilter.vhrEventFilter.VhrEventFixedTypeCode = vhrEventFixedTypeCode;
            surveyBarFilter.vhrEventFilter.VhrEventKind = vhrEventKindEnum.Data;
            surveyBarFilter.vhrEventFilter.ModuleTargetId = moduleTargetId;

            return surveyBarFilter;
        }
        //#endregion
    }

    mySurveySchedule.$inject = injectParams;
    mySurveySchedule.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('mySurveySchedule', mySurveySchedule);
});