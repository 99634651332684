﻿
'use strict';
define(['../../common/services/nbs-service'], function (nbsService) {

    var injectParams = ['$http', '$q', '$timeout', 'breezeDataLayer'];

    var integrationService = function ($http, $q, $timeout, breezeDataLayer) {

        var dataLayer = breezeDataLayer.Initialize('integration', true);
        var ejDataManager = dataLayer.GetEjDataManager();
        var manager = dataLayer.GetManager();
        var entityTypes = {
            IntegrationRequest: 'IntegrationRequest',
            IntegrationDefinition: 'IntegrationDefinition',
            IntegrationDefinitionDto: 'IntegrationDefinitionDto',
            ReportRequestRenderResult: 'ReportRequestRenderResult',
            IntegrationFileObserver: 'IntegrationFileObserver',
            IntegrationFileObserverJournal: 'IntegrationFileObserverJournal'
        };
        var me = this;

        nbsService.call(this, manager, $q, $timeout);

        this.dataContext = {
            integrationRequests: dataLayer.setContextMethods(manager, nbsService, entityTypes.IntegrationRequest),
            integrationFileObservers: dataLayer.setContextMethods(manager, nbsService, entityTypes.IntegrationFileObserver),
            integrationFileObserverJournals: dataLayer.setContextMethods(manager, nbsService, entityTypes.IntegrationFileObserverJournal),
            reportRequestRenderResults: dataLayer.setContextMethods(manager, nbsService, entityTypes.ReportRequestRenderResult),
            integrationDefinitions: dataLayer.setContextMethods(manager, nbsService, entityTypes.IntegrationDefinition),
            integrationDefinitionDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.IntegrationDefinitionDto),

            enumTypes: {
                IntegrationRequestDirection: dataLayer.GetEnumType('IntegrationRequestDirection'),
                IntegrationRequestStatus: dataLayer.GetEnumType('IntegrationRequestStatus'),
                IntegrationRequestResult: dataLayer.GetEnumType('IntegrationRequestResult'),
                IntegrationFlow: dataLayer.GetEnumType('IntegrationFlow'),
                IntegrationTemplateFormat: dataLayer.GetEnumType('IntegrationTemplateFormat')
            },
            complexTypes: {
                reportRequestData: function () { return { ReportDefinitionId: null, ReportDefinitionCode: null, ReportDefinitionName: null, OutputFormat: null, DueDate: null, Filters: null }; }
            }
        };

        this.createQuery = function (entityName) {
            return dataLayer.CreateQuery(entityName);
        };

        this.getIntegrationRequests = function (filter, forceReload) {
            var params = { entityType: entityTypes.IntegrationRequest, cache: this.dataContext.integrationRequests.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "IntegrationRequests");
        };

        this.getIntegrationFileObservers = function (filter, forceReload) {
            var params = { entityType: entityTypes.IntegrationFileObserver, cache: this.dataContext.integrationFileObservers.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "IntegrationFileObservers");
        };

        this.getIntegrationFileObserversPagedDm = function () {
            return ejDataManager.getEjManager('IntegrationFileObserversPaged');
        };
        this.getIntegrationFileObserversPagedDmQuery = function () {
            var dmObj = {};
            dmObj.dm = ejDataManager.getEjManager('');
            dmObj.query = ej.Query().from('IntegrationFileObserversPaged');
            return dmObj;
        };

        this.getIntegrationFileObserverJournalsPagedDm = function () {
            return ejDataManager.getEjManager('IntegrationFileObserverJournalsPaged');
        };
        this.getIntegrationFileObserverJournalsPagedDmQuery = function () {
            var dmObj = {};
            dmObj.dm = ejDataManager.getEjManager('');
            dmObj.query = ej.Query().from('IntegrationFileObserverJournalsPaged');
            return dmObj;
        };

        this.getIntegrationRequestsPagedDm = function () {
            return ejDataManager.getEjManager('IntegrationRequestsPaged');
        };
        this.getIntegrationRequestsPagedDmQuery = function () {
            var dmObj = {};
            dmObj.dm = ejDataManager.getEjManager('');
            dmObj.query = ej.Query().from('IntegrationRequestsPaged');
            return dmObj;
        };

        this.getIntegrationFileObserverJournals = function (filter, forceReload) {
            var params = { entityType: entityTypes.IntegrationFileObserverJournal, cache: this.dataContext.integrationFileObserverJournals.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "IntegrationFileObserverJournals");
        };

        this.getIntegrationDefinitions = function (filter, forceReload) {
            var params = { entityType: entityTypes.IntegrationDefinition, cache: this.dataContext.integrationDefinitions.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "IntegrationDefinitions");
        };

        this.getIntegrationDefinitionDtos = function (filter, forceReload) {
            var params = { entityType: entityTypes.IntegrationDefinitionDto, cache: this.dataContext.integrationDefinitionDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "IntegrationDefinitionDtos");
        };

        this.getIntegrationDefinitionFromFile = function (filter) {
            var params = { entityType: entityTypes.IntegrationDefinition, cache: this.dataContext.integrationDefinitions.get() };
            return dataLayer.GetDataListPromise(params, filter, true, "IntegrationDefinitionFromFile");
        };

        this.getIntegrationDefinitionFromCode = function (filter) {
            var params = { entityType: entityTypes.IntegrationDefinition, cache: this.dataContext.integrationDefinitions.get() };
            return dataLayer.GetDataListPromise(params, filter, true, "IntegrationDefinitionFromCode");
        };

        this.getIntegrationRequest = function (filter, forceReload) {
            var params = { key: filter.IntegrationRequestId, dataContext: this.dataContext.integrationRequests };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "IntegrationRequest");
        };

        this.getReportRequestRenderResult = function (filter, forceReload) {
            var params = { key: filter.ReportRequestId, dataContext: this.dataContext.reportRequestRenderResults };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "ReportRequestRenderResult");
        };

        this.genericReportingRequest = function (filter) {
            var query = dataLayer.InvokeQuery("GenericReportingRequest", filter);
            return manager.executeQuery(query);
        };

        this.manageIntegrationDefinition = function (contextService, definitionId, forceReload, queryFilter) {
            if (!definitionId) {
                return;
            }

            this.getIntegrationDefinitions({ IntegrationDefinitionId: definitionId }, forceReload)
                .then((data) => {
                    if (data.results.length > 1) {
                        console.error('integrationservice manageIntegrationDefinition error');
                        return;
                    }

                    var integrationDefinition = data.results[0];
                    var filename = integrationDefinition.IntegrationDefinitionName;
                    
                    var currentIntegrationRequest = this.dataContext.integrationRequests.add({
                        VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId,
                        IntegrationRequestId: breeze.core.getUuid(),
                        IntegrationRequestDirectionId: Lazy(this.dataContext.enumTypes.IntegrationRequestDirection).findWhere({ name: 'Out' }).value,
                        IntegrationRequestResultId: Lazy(this.dataContext.enumTypes.IntegrationRequestResult).findWhere({ name: 'None' }).value,
                        IntegrationRequestStatusId: Lazy(this.dataContext.enumTypes.IntegrationRequestStatus).findWhere({ name: 'Inserted' }).value,
                        IntegrationDefinitionId: integrationDefinition.IntegrationDefinitionId,
                        IsComparedByCode: false,
                        UseTemplateDefinition: false,
                        IntegrationRequestName: filename,
                        IntegrationTemplateFormatId: integrationDefinition.IntegrationTemplateFormatId,
                        EtlRequired: integrationDefinition.EtlRequired,
                        ForceRealTime: true,
                        QueryFilter: JSON.stringify(queryFilter)
                    });

                    this.saveChanges()
                        .then((saveResult) => {

                            contextService.addDownloadFile({
                                fileId: currentIntegrationRequest.IntegrationFileId,
                                fileName: filename,
                                fileType: 'Excel',
                                downloadData: {
                                    entityGuid: currentIntegrationRequest.IntegrationFileId,
                                    asAttach: false,
                                    downloadType: "byGuid"
                                }
                            });
                        });
                });
        }

        //#region DataManager
        this.getIntegrationRequestDataManager = function (supportData, filter, dmObj) {
            if (dmObj) {
                dmObj.complexParams = [{ filter: filter }];
                return dmObj;
            }

            var integrationRequestStatusRev = Nembus.Common.Library.util.parseEnum(me.dataContext.enumTypes.IntegrationRequestStatus, 'IntegrationRequest', supportData.translate).obj.translated.value_name;
            var integrationRequestResultRev = Nembus.Common.Library.util.parseEnum(me.dataContext.enumTypes.IntegrationRequestResult, 'IntegrationRequest', supportData.translate).obj.translated.value_name;

            dmObj = me.getIntegrationRequestsPagedDmQuery();
            dmObj.dmgrid = me.getIntegrationRequestsPagedDm();

            var ejDataManager = dmObj.dmgrid;

            ejDataManager.complexParams.push({ filter: filter });
            ejDataManager.fieldNameReplacer = {};
            // properties inserte are calculated before query is launched in breezeDataLayer service
            ejDataManager.onWhereCustomPredicate = {};

            ejDataManager.processResponseData = function (data) {
                if (data) {
                    data.results = me.getAttachedEntities(data.results);

                    Lazy(data.results).each((item) => {
                        item.IntegrationRequestStatusName = integrationRequestStatusRev[item.IntegrationRequestStatusId];
                        item.IntegrationRequestResultName = integrationRequestResultRev[item.IntegrationRequestResultId];
                    });

                    return data.results;
                }
            };

            ejDataManager.freeSearchFields = ['IntegrationRequestName'];

            dmObj.dm.onWhereCustomPredicate = ejDataManager.onWhereCustomPredicate;
            dmObj.dm.fieldNameReplacer = ejDataManager.fieldNameReplacer;
            dmObj.dm.complexParams = ejDataManager.complexParams;
            dmObj.dm.processResponseData = ejDataManager.processResponseData;
            return dmObj;
        }

        this.getFileObserverDataManager = function (filter, dmObj) {
            if (dmObj) {
                dmObj.complexParams = [{ filter: filter }];
                return dmObj;
            }

            dmObj = me.getIntegrationFileObserversPagedDmQuery();
            dmObj.dmgrid = me.getIntegrationFileObserversPagedDm();

            var ejDataManager = dmObj.dmgrid;

            ejDataManager.complexParams.push({ filter: filter });
            ejDataManager.fieldNameReplacer = {};
            // properties inserte are calculated before query is launched in breezeDataLayer service
            ejDataManager.onWhereCustomPredicate = {};

            ejDataManager.processResponseData = function (data) {
                if (data) {
                    data.results = me.getAttachedEntities(data.results);
                    return data.results;
                }
            };

            ejDataManager.freeSearchFields = ['FileNameMatcher'];
            
            dmObj.dm.onWhereCustomPredicate = ejDataManager.onWhereCustomPredicate;
            dmObj.dm.fieldNameReplacer = ejDataManager.fieldNameReplacer;
            dmObj.dm.complexParams = ejDataManager.complexParams;
            dmObj.dm.processResponseData = ejDataManager.processResponseData;
            return dmObj;
        }

        this.getFileObserverJournalDataManager = function (filter, dmObj) {
            if (dmObj) {
                dmObj.complexParams = [{ filter: filter }];
                return dmObj;
            }

            dmObj = me.getIntegrationFileObserverJournalsPagedDmQuery();
            dmObj.dmgrid = me.getIntegrationFileObserverJournalsPagedDm();

            var ejDataManager = dmObj.dmgrid;

            ejDataManager.complexParams.push({ filter: filter });
            ejDataManager.fieldNameReplacer = {};
            // properties inserte are calculated before query is launched in breezeDataLayer service
            ejDataManager.onWhereCustomPredicate = {};

            ejDataManager.processResponseData = function (data) {
                if (data) {
                    data.results = me.getAttachedEntities(data.results);
                    return data.results;
                }
            };

            ejDataManager.freeSearchFields = ['WarningMessage'];            

            dmObj.dm.onWhereCustomPredicate = ejDataManager.onWhereCustomPredicate;
            dmObj.dm.fieldNameReplacer = ejDataManager.fieldNameReplacer;
            dmObj.dm.complexParams = ejDataManager.complexParams;
            dmObj.dm.processResponseData = ejDataManager.processResponseData;
            return dmObj;
        }
        //#endregion
    };

    integrationService.$inject = injectParams;
    integrationService.prototype = Object.create(nbsService.prototype);
    angular.module('nbs.shared.module').service('integrationService', integrationService);
});