﻿'use strict';
define([], function () {
    var injectParams = ['$scope', 'navigationService' ,'contextService', 'NgMap'];
    var nbsGoogleMapComponent = function ($scope, navigationService, contextService, NgMap) {
        var vm = this;
        var currentPosition = null;
        var currentDatacontext = null;

        vm.$onInit = function () {
            
        }

        vm.$onChanges = function (changes) {
            if (changes.nbsDatacontext && changes.nbsDatacontext.currentValue) {
                vm.miniatureMap = new NbsPositionMap(NgMap, contextService.virtualContext.UriGoogleStaticMap);
                vm.miniatureMap.setMapStaticOnClick(openModal);

                currentDatacontext = changes.nbsDatacontext.currentValue;
                currentPosition = currentDatacontext.positionSerialized;

                if (changes.nbsDatacontext.currentValue.positionSerialized) {
                    vm.miniatureMap.setPositionDataSerialized(currentPosition);
                }                
            }
            if (changes.isReadonly) {
                if (changes.isReadonly.currentValue === true) {
                    vm.isReadonly = true;
                } else if (changes.isReadonly.currentValue === false) {
                    vm.isReadonly = false;
                }
            }
        };

        vm.deleteMiniatureMapPosition = function () {
            currentPosition = null;
            vm.miniatureMap.setPositionDataSerialized(currentPosition);
            raisePositionChanged();
        };

        vm.setCurrentPosition = function () {
            // localize position on map is possible only of transmission protocol is on https
            var isHttpProtocol = window.location.href.toLowerCase().startsWith('https://') ? false : true;
            if (isHttpProtocol) {
                navigationService.customMessageModal(vm.uiContext.translate('LocalizationNotPermitted'));
                return;
            }

            vm.modalMap.setCurrentPositionOnMap();
        };

        vm.initMap = function (mapId) {
            vm.modalMap = new NbsPositionMap(NgMap, contextService.virtualContext.UriGoogleStaticMap);
            vm.modalMap.setPositionDataSerialized(currentPosition, true);
        };

        vm.searchPlaceIsChanged = function () {
            var currentPlace = this.getPlace();
            vm.modalMap.setCenter(currentPlace.geometry.location);
            vm.modalMap.setMarkerOnMap(currentPlace.geometry.location, '');
        }

        function openModal() {
            vm.modalInstance = navigationService.modal().open(currentDatacontext.modalOptions({ templateUrl: 'positionModalTemplate', scope: $scope }));
            vm.modalInstance.ok = function () {
                currentPosition = vm.modalMap.getDataSerialized();
                vm.miniatureMap.setPositionDataSerialized(currentPosition);

                raisePositionChanged();

                vm.modalInstance.close();
            };
            vm.modalInstance.cancel = function () {
                vm.modalInstance.dismiss();
            };
            vm.modalInstance.result.then(function () {
                // onClose
            }, function () {
                
            });
        }

        function raisePositionChanged() {
            vm.onPositionChangeEvent({
                args: {
                    positionSerialized: currentPosition
                }
            });
        }

        class NbsPositionMap {
            // USED Google API reference v3.35
            // https://developers.google.com/maps/documentation/javascript/reference/map
            constructor(ngMap, uriGoogleStaticMap) {
                if (!ngMap) { console.error("ngMap is mandatory"); }
                if (!uriGoogleStaticMap) { console.error("uriGoogleStaticMap is mandatory"); }

                this.NbsPositionMapData = class {
                    constructor(map) {
                        // map.getCenter() => "{"lat":37.443944382437,"lng":-122.1606110900879}"
                        this.center = map.getCenter().toJSON();
                        this.tilt = map.getTilt();
                        this.zoom = map.getZoom();
                        this.mapTypeId = map.getMapTypeId();
                        // map.getBounds() => "{"south":37.19329416166607,"west":-132.62230533335253,"north":37.5926221868305,"east":-130.33852237436815}"
                        this.bounds = map.getBounds().toJSON();
                        // map.getProjection() => "{"j":{"x":128,"y":128},"m":0.7111111111111111,"A":40.74366543152521,"l":true}"
                        //this.projecton= map.getProjection();
                    }
                }

                this.NbsPositionMarkerData = class {
                    constructor(marker) {
                        // {lat: 45.066145773082816, lng: 7.638951410230561}
                        this.position = marker.getPosition().toJSON();
                        // true, false
                        this.clickable = marker.getClickable();
                        // alt description on marker on map
                        this.title = marker.getTitle();
                    }
                }

                this.isOnlyOneMarker = true;
                this.mapId = 'nbs-position-map-selection';
                this.map = null;
                this.positionDataSerialized = null;
                this.uriGoogleStaticMap = uriGoogleStaticMap;
                this.markers = [];
                this.currentMarker = null;
                this.callbackFn = null;
                this.mapStaticUrl = null;
                this.ngMap = ngMap;
                this.positionIsSaved = false;
            }

            setPositionDataSerialized(positionDataSerialized, initMap) {
                this.positionDataSerialized = positionDataSerialized;

                if (initMap) {
                    this.setMap(false);
                }

                this.setStaticMapUrl(true);
            };

            setMapStaticOnClick(fn) {
                this.callbackFn = fn;
            };

            mapStaticOnClick() {
                if (this.callbackFn) {
                    this.callbackFn();
                }
            };

            getDataSerialized() {
                var result = {
                    map: new this.NbsPositionMapData(this.map),
                    markers: []
                };

                Lazy(this.markers)
                    .each((marker) => {
                        result.markers.push(new this.NbsPositionMarkerData(marker));
                    });

                return JSON.stringify(result);
            };

            setCenter(pos) {
                this.map.setCenter(pos);
            };

            setCurrentPositionOnMap() {
                // getCurrentPosition() and watchPosition() no longer work on insecure origins. To use this feature, you should consider switching your application to a secure origin, such as HTTPS. See https://goo.gl/rStTGz for more details. 
                // var infoWindow = new google.maps.InfoWindow;

                // Try HTML5 geolocation.
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(function (position) {
                        var currentPosition = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        };

                        // add marker on map and local object
                        this.setMarkerOnMap(currentPosition, '');

                        // center on map
                        this.map.setCenter(currentPosition);
                    }, function () {
                        //handleLocationError(true, infoWindow, this.map.getCenter());
                    });
                } else {
                    // Browser doesn't support Geolocation
                    //handleLocationError(false, infoWindow, this.map.getCenter());
                }
            };

            //function handleLocationError(browserHasGeolocation, infoWindow, position) {
            //    infoWindow.setPosition(position);
            //    infoWindow.setContent(browserHasGeolocation ? 'Error: The Geolocation service failed.' : 'Error: Your browser doesn\'t support geolocation.');
            //    infoWindow.open(map);
            //}
            setStaticMapUrl(centerMarker) {
                // Documentation https://developers.google.com/maps/documentation/maps-static/dev-guide
                // markers=37.400465,-122.073003,midreda|37.437328,-122.159928,midreda|37.369110,-122.096034,sma
                var positionData = this.getDeserializePosition();
                if (!positionData) {
                    this.positionIsSaved = false;
                    return;
                }

                this.positionIsSaved = true;

                var staticUrl = this.uriGoogleStaticMap;
                var staticUrlParams = "&size=[size]&center=[center]&zoom=[zoom]&maptype=[maptype]&markers=[markers]&format=[format]";
                var sizeParam = "200x200";
                var centerParam = positionData.map.center.lat + ',' + positionData.map.center.lng;
                var zoomParam = positionData.map.zoom;
                var mapTypeParam = positionData.map.mapTypeId;
                var formatParam = '';

                var marketParams = "";
                for (var i = 0; i < positionData.markers.length; i++) {
                    if (i > 0) {
                        marketParams += "|";
                    }

                    marketParams += positionData.markers[i].position.lat + ',' + positionData.markers[i].position.lng;
                }

                // if exists any marker, then use the first to center map
                if (centerMarker === true) {
                    if (positionData.markers && positionData.markers.length > 0) {
                        centerParam = positionData.markers[0].position.lat + ',' + positionData.markers[0].position.lng;
                    }
                }

                staticUrlParams = staticUrlParams.replace("[size]", sizeParam);
                staticUrlParams = staticUrlParams.replace("[center]", centerParam);
                staticUrlParams = staticUrlParams.replace("[zoom]", zoomParam);
                staticUrlParams = staticUrlParams.replace("[maptype]", mapTypeParam);
                staticUrlParams = staticUrlParams.replace("[markers]", marketParams);
                staticUrlParams = staticUrlParams.replace("[format]", formatParam);

                // url result to get static map
                this.mapStaticUrl = staticUrl + staticUrlParams;
            }

            setMap(centerMarker) {
                var that = this;
                if ($("#" + that.mapId).get(0)) {
                    that.map = that.ngMap.initMap(that.mapId);

                    // add event
                    // on every click a marker is added to map
                    that.map.addListener('click', function (e) {
                        that.setMarkerOnMap(e.latLng, '');
                    });

                    var positionData = that.getDeserializePosition();
                    if (!positionData) {
                        that.positionIsSaved = false;
                        that.map.setZoom(2);
                        return;
                    }

                    that.positionIsSaved = true;

                    if (positionData.map) {
                        that.map.setCenter(positionData.map.center);
                        that.map.setTilt(positionData.map.tilt);
                        that.map.setZoom(positionData.map.zoom);
                        that.map.mapTypeId = positionData.map.mapTypeId;
                    }

                    Lazy(positionData.markers)
                        .each((marker) => {
                            that.setMarkerOnMap(marker.position, marker.title);
                        });

                    // if exists any marker, then use the first to center map
                    if (centerMarker === true) {
                        if (positionData.markers && positionData.markers.length > 0) {
                            that.map.setCenter(positionData.markers[0].position);
                        }
                    }
                }
            }

            setMarkerOnMap(position, title) {
                if (this.currentMarker && this.isOnlyOneMarker) {
                    this.currentMarker.setMap(null);
                }
                this.currentMarker = new google.maps.Marker({
                    position: position,
                    map: this.map,
                    title: title
                });

                if (this.isOnlyOneMarker) {
                    this.markers = [];
                }

                this.markers.push(this.currentMarker);
            }

            getDeserializePosition() {
                var positionData = null;

                try {
                    positionData = JSON.parse(Nembus.Common.Library.converter.decodeHtml(this.positionDataSerialized));
                } catch (e) { }

                return positionData;
            }
        }
    };

    nbsGoogleMapComponent.$inject = injectParams;
    angular.module('nbs.rds.module').component('nbsGoogleMap', {
        templateUrl: '/rds/common/components/nbsGoogleMapComponent/_?c=' + window.codeCache(),
        controller: nbsGoogleMapComponent,
        controllerAs: 'vm',
        bindings: {
            nbsDatacontext: "<",
            isReadonly: "<",
            uiContext: "<",

            onPositionChangeEvent: "&"
        }
    });
});

