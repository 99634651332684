﻿'use strict';
define([], function () {

    var injectParams = [];
    var nbsEjTreeViewComponent = function () {

        var vm = this;
        var pDatasource = null;
        var ID = breeze.core.getUuid();
        var pEjTreeView = null;

        vm.$onInit = function () {

        };

        vm.$onChanges = function (changes) {
            if (changes.nbsDatasource && changes.nbsDatasource.currentValue) {
                pDatasource = changes.nbsDatasource.currentValue;

                createTreeView();
            }
            if (changes.nbsRefresh && changes.nbsRefresh.currentValue) {
                if (pEjTreeView) {
                    pEjTreeView.refresh();
                }
            }
        };

        function createTreeView() {
            var allowSorting = Boolean(vm.allowSorting);
            var showCheckbox = Boolean(vm.showCheckbox);
            var autoCheck = Boolean(vm.autoCheck);

            var treeView = {
                allowDragAndDrop: false,
                fields: {
                    dataSource: pDatasource,
                    id: vm.fieldId,
                    parentId: vm.fieldParentId,
                    text: vm.fieldText,
                    expanded: vm.fieldExpanded,
                    //selected: vm.isSelected                    
                },
                sortSettings: {
                    allowSorting: allowSorting,
                    sortOrder: ej.sortOrder.Ascending
                },
                autoCheck: autoCheck,
                showCheckbox: showCheckbox,
                template: vm.templateId ? vm.templateId : null,
                nodeSelect: function (args) {
                    // node selection only if treeview is NOT multiselect
                    if (!showCheckbox) {
                        vm.nodeSelect({ args: args });
                    }
                },
                nodeDragStart: function (args) {
                    vm.nodeDragStart({ args: args });
                },
                nodeDragStop: function (args) {
                    vm.nodeDragStop({ args: args });
                },
                nodeCheck: function (args) {
                    vm.nodeCheck({ args: args });
                },
                nodeUncheck: function (args) {
                    vm.nodeUncheck({ args: args });
                },
                nodeClick: function (args) {
                    vm.nodeClick({ args: args });
                },
            };

            var container = $("#ejTreeviewTag");
            $(container).attr('id', ID);
            $("#" + ID).ejTreeView(treeView);
            pEjTreeView = $("#" + ID).data('ejTreeView');
        }
    };

    nbsEjTreeViewComponent.$inject = injectParams;
    angular.module('nbs.rds.module').component('nbsEjTreeView', {
        templateUrl: '/rds/common/components/nbsEjTreeViewComponent/_?c=' + window.codeCache(),
        controller: nbsEjTreeViewComponent,
        controllerAs: 'vm',
        bindings: {
            nbsDatasource: "<",
            nbsRefresh: "<",

            fieldId: "@",
            fieldParentId: "@",
            fieldText: "@",
            fieldExpanded: "@",

            showCheckbox: "<",
            autoCheck: "<",
            allowSorting: "<",

            templateId: "@",

            // events
            nodeSelect: '&',
            nodeDragStart: '&',
            nodeDragStop: '&',
            nodeCheck: '&',
            nodeUncheck: '&',
            nodeClick: '&',

            uiContext: "<"
        }
    });
});

