﻿'use strict';
define([], function () {

    var injectParams = ['$q', '$window', '$rootScope', '$injector', 'watchdogService'];

    function navigationService($q, $window, $rootScope, $injector, watchdogService) {

        var $http;
        var $state;
        var $stateParams;
        var $uibModal;
        var $location;
        var lastNavIsBack = false;
        var navigationActionTypes = {
            modalOk: 1,
            modalCancel: 2,
            modalPrevious: 3,
            modalNext: 4,
            modalCronOnclick: 5,
            modalEnd: 6
        };
        var redirAfterLogin = null;
        var redirAfterLoginParams = null;

        var externalRedir = {};
        externalRedir.vc = -1;
        externalRedir.nextPath = '';
        // Object returned on modal close action
        var modalResult = function ModalResult() {
            var me = this;
            me.dialogResult = navigationActionTypes.modalCancel;
            me.data = {};
            me.sourceParams = null;
            me.hasError = false;
            me.error = { message: null };
        };
        var notifyFn = {};
        var service = {
            navigateTo: navigateTo,
            navigateToNewWindow: navigateToNewWindow,
            navigateToAssetTreeDetail: navigateToAssetTreeDetail,
            navigateToVhrEventDetail: navigateToVhrEventDetail,
            navigateToFileBoxDetail: navigateToFileBoxDetail,
            navigateToActDetail: navigateToActDetail,
            navigateToAddAct: navigateToAddAct,
            navigateToUrl: navigateToUrl,
            stateParams: stateParams,
            stateParamsModal: stateParamsModal,
            modal: modal,
            currentCmsViewCode: currentCmsViewCode,
            currentCmsViewTitle: currentCmsViewTitle,
            currentViewMode: currentViewMode,
            modalTo: modalTo,
            modalToWz: modalToWz,                               // used only in wizard steps
            navigateBack: navigateBack,
            modalToDefault: modalToDefault,
            navToCurrentModalDoc: navToCurrentModalDoc,
            setCurrentModal: setCurrentModal,
            closeCurrentModal: closeCurrentModal,
            navigateParent: navigateParent,
            navToCurrentModalDocElementDetail: navToCurrentModalDocElementDetail,
            navToUnauthorized: navToUnauthorized,
            navToMaintenance: navToMaintenance,
            navToChangeMyPassword: navToChangeMyPassword,
            navToForceChangeMyPassword: navToForceChangeMyPassword,
            navToRenewMyTwoFactorSecretCode: navToRenewMyTwoFactorSecretCode,
            navToMyAccount: navToMyAccount,
            navToMyNotification: navToMyNotification,
            navToMyTickets: navToMyTickets,
            navToHome: navToHome,
            navAfterLogin: navAfterLogin,
            navPublicConfigMobile: navPublicConfigMobile,
            navToModal: navToModal,
            navToGenericListModal: navToGenericListModal,

            // workflow view navigations ACT
            navToActWorkflowExecuteMode: navToActWorkflowExecuteMode,
            navToActWorkflowModifyMode: navToActWorkflowModifyMode,
            navToActWorkflowHistoryMode: navToActWorkflowHistoryMode,

            // workflow view navigations VHREVENT
            navToVhrEventWorkflowModifyMode: navToVhrEventWorkflowModifyMode,
            navToVhrEventWorkflowHistoryMode: navToVhrEventWorkflowHistoryMode,

            navToJobRequestChangeStartDate: navToJobRequestChangeStartDate,

            navToAssignmentModal: navToAssignmentModal,
            navToSendNotificationModal: navToSendNotificationModal,

            // vhrevent ribbon assignment
            navToVhrEventAssignmentExecute: navToVhrEventAssignmentExecute,
            navToVhrEventAssignmentModify: navToVhrEventAssignmentModify,
            navToVhrEventAssignmentInfo: navToVhrEventAssignmentInfo,

            // vhrevent ribbon execution
            navToVhrEventExecute: navToVhrEventExecute,
            navToVhrEventChangeExecution: navToVhrEventChangeExecution,
            navToVhrEventInfoExecution: navToVhrEventInfoExecution,

            // vhrevent ribbon approval
            navToVhrEventApprove: navToVhrEventApprove,
            navToVhrEventChangeApproval: navToVhrEventChangeApproval,
            navToVhrEventInfoApproval: navToVhrEventInfoApproval,

            // vhrevent ribbon customer approval
            navToVhrEventCustomerApprove: navToVhrEventCustomerApprove,
            navToVhrEventChangeCustomerApproval: navToVhrEventChangeCustomerApproval,
            navToVhrEventInfoCustomerApproval: navToVhrEventInfoCustomerApproval,

            navToVhrEventChangeStartDate: navToVhrEventChangeStartDate,

            // modal used to manage journals and attachments
            navToActJournalsModal: navToActJournalsModal,
            getJournalAttachmentsModalParams: getJournalAttachmentsModalParams,

            getModalData: getModalData,
            currentState: currentState,
            currentPath: currentPath,
            watchdogService: watchdogService,
            exportGridData: exportGridData, //export is here because at moment navigationService is injected into nbsVm
            exportData: exportData,
            confirmModal: confirmModal,
            confirmModalNoQuestion: confirmModalNoQuestion,
            copyTextMessageModal: copyTextMessageModal,
            confirmExitWizard: confirmExitWizard,
            confirmModalCustomLabels: confirmModalCustomLabels,
            operationNotPermittedModal: operationNotPermittedModal,
            customMessageModal: customMessageModal,
            userNotAuthorizedMessageModal: userNotAuthorizedMessageModal,
            selectOneItemMessageModal: selectOneItemMessageModal,
            selectOnlyOneItemMessageModal: selectOnlyOneItemMessageModal,
            selectAtLeastOneItemMessageModal: selectAtLeastOneItemMessageModal,
            getUIRouterStateModal: getUIRouterStateModal,
            setRedirAfterLogin: setRedirAfterLogin,

            navigationActionTypes: navigationActionTypes,
            modalResult: modalResult,
            isMobileTemplate: isMobileTemplate,
            screenWidth: screenWidth,
            isJustRefreshed: true,

            /*
            * controllersBehaviour: Object used for communication cross controller
            * title: view title setted from child controllers
            * actions: functions executed from parent controllers (setted by 'setParentControllerActions')
            */
            controllersBehaviour: {
                title: [],
                actions: {}
            },
            setControllerTitle: setControllerTitle,
            execControllerAction: execControllerAction,
            setParentControllerActions: setParentControllerActions,

            notifyStateChange: notifyStateChange,

            setNextState: setNextState,
            navToNextState: navToNextState,
            getExternalNextUrl: getExternalNextUrl,
            oidcNoAuth: oidcNoAuth,
            offlineModal: offlineModal
        };


        var self = this;
        self.isModal = false;
        self.navigationHistory = [];
        self.navigationModalHistory = [];
        self.backIndex = 0;
        self.currentModalData = {};

        return service;

        function setNextState(state, toParams) {
            self.nextState = state;
            self.toParams = toParams;
        }

        function setRedirAfterLogin(state, toParams) {
            if (state.name.toLowerCase().indexOf('resetpassword') > 0
                || state.name.indexOf('maintenance') > 0
            ) {
                redirAfterLogin = {};
                redirAfterLoginParams = {};
                return;
            }
            redirAfterLogin = state;
            redirAfterLoginParams = toParams;
        }

        function navToNextState() {
            $state = $state || $injector.get('$state');
            if (self.nextState && self.nextState.name !== 'app.nav.appHome') {
                if (self.nextState.name.substring(0, 4) === 'app.') {
                    $state.go(self.nextState, self.toParams);
                }
                else {
                    this.navToHome();
                }
            }
            else {
                //$state.go('app.nav.appHome');
                if (externalRedir.nextPath != '' && externalRedir.nextPath.substring(0, 5) === '/app/') {
                    $location = $location || $injector.get('$location');
                    $location.path(externalRedir.nextPath);
                }
                else {
                    this.navToHome();
                }
            }
        }

        ///e.g. vc=1/app/surveycompile/592/aud 
        //where vc=1 define a target virtualContext 0 1
        function getExternalNextUrl(redirpar) {
            if (!redirpar) {
                return;
            }
            try {
                var redirparam = atob(redirpar);
                if (redirparam.indexOf('vc=') >= 0) {
                    externalRedir.vc = Number(redirparam.substr(redirparam.indexOf('vc='), redirparam.indexOf('/')).split('=')[1]);
                }
                externalRedir.nextPath = redirparam.substr(redirparam.indexOf('/'), redirparam.length - redirparam.indexOf('/'));
                return externalRedir;
            } catch (e) {
                return {};
            }


        }

        //Function used to set title of parent view (used in VhrEvent detail)
        function setControllerTitle() {
            service.controllersBehaviour.title = [{ label: Nembus.Common.Library.cms.viewTitle(service.currentCmsViewCode()) }];
        }

        //Function used to override action functions of parent controller (used in VhrEvent detail)
        function setParentControllerActions(vm) {
            // HOME TAB
            service.controllersBehaviour.actions.newAction = vm.newAction;
            service.controllersBehaviour.actions.newFolderAction = vm.newFolderAction;
            service.controllersBehaviour.actions.modifyAction = vm.modifyAction;
            service.controllersBehaviour.actions.deleteAction = vm.deleteAction;
            service.controllersBehaviour.actions.submitAction = vm.submitAction;
            service.controllersBehaviour.actions.refreshAction = vm.refreshAction;

            // MANAGE TAB
            // VhrEvent life status section
            service.controllersBehaviour.actions.vhrEventLifeStatusApproveAction = vm.vhrEventLifeStatusApproveAction;
            service.controllersBehaviour.actions.vhrEventLifeStatusUpdateAction = vm.vhrEventLifeStatusUpdateAction;
            service.controllersBehaviour.actions.vhrEventLifeStatusCompleteAction = vm.vhrEventLifeStatusCompleteAction;
            service.controllersBehaviour.actions.vhrEventLifeStatusInfoAction = vm.vhrEventLifeStatusInfoAction;
            // VhrEvent execution section
            service.controllersBehaviour.actions.vhrEventExecutionApproveAction = vm.vhrEventExecutionApproveAction;
            service.controllersBehaviour.actions.vhrEventExecutionModifyAction = vm.vhrEventExecutionModifyAction;
            service.controllersBehaviour.actions.vhrEventExecutionInfoAction = vm.vhrEventExecutionInfoAction;
            // VhrEvent approval section
            service.controllersBehaviour.actions.vhrEventApprovalAuthorizeAction = vm.vhrEventApprovalAuthorizeAction;
            service.controllersBehaviour.actions.vhrEventApprovalModifyAction = vm.vhrEventApprovalModifyAction;
            service.controllersBehaviour.actions.vhrEventApprovalInfoAction = vm.vhrEventApprovalInfoAction;
            // VhrEvent customer approval section
            service.controllersBehaviour.actions.vhrEventCustomerApprovalAuthorizeAction = vm.vhrEventCustomerApprovalAuthorizeAction;
            service.controllersBehaviour.actions.vhrEventCustomerApprovalModifyAction = vm.vhrEventCustomerApprovalModifyAction;
            service.controllersBehaviour.actions.vhrEventCustomerApprovalInfoAction = vm.vhrEventCustomerApprovalInfoAction;
            // Billing status section
            service.controllersBehaviour.actions.billingStatusUpdateAction = vm.billingStatusUpdateAction;
            service.controllersBehaviour.actions.billingStatusInfoAction = vm.billingStatusInfoAction;
            // Change date section
            service.controllersBehaviour.actions.setDateAction = vm.setDateAction;
            // Warning (label) section
            service.controllersBehaviour.actions.newLabelAction = vm.newLabelAction;
            service.controllersBehaviour.actions.updateLabelAction = vm.updateLabelAction;
            service.controllersBehaviour.actions.infoLabelAction = vm.infoLabelAction;
            service.controllersBehaviour.actions.deleteLabelAction = vm.deleteLabelAction;
            // Send communication section
            service.controllersBehaviour.actions.sendMailAction = vm.sendMailAction;
            // Print section
            service.controllersBehaviour.actions.exportReportToPdfAction = vm.exportReportToPdfAction;
            service.controllersBehaviour.actions.exportReportToWordAction = vm.exportReportToWordAction;
            service.controllersBehaviour.actions.exportReportToXmlAction = vm.exportReportToXmlAction;
            service.controllersBehaviour.actions.exportReportToExcelAction = vm.exportReportToExcelAction;
            service.controllersBehaviour.actions.exportReportToHtmlAction = vm.exportReportToHtmlAction;
            // Share attachments
            service.controllersBehaviour.actions.shareAttachmentAction = vm.shareAttachmentAction;
        }
        function isMobileTemplate() {
            var w = screenWidth();
            if (w < 800) {
                return true;
            }
            return false;
        }

        function screenWidth() {
            return $injector.get('$window').innerWidth;
        }
        /*
        * Execute function setted by setParentControllerActions (child controller vhrevent)
        */
        function execControllerAction(action) {
            if (action) {
                var fn = service.controllersBehaviour.actions[action];
                if (Object.prototype.toString.call(fn) === "[object Function]") {
                    fn();
                }
            }
        }

        function exportGridData(filter) {
            var defer = $q.defer();
            $http = $http || $injector.get('$http');
            $http({
                method: 'POST',
                url: 'breeze/foundation/ExportGrid',
                data: JSON.stringify(filter),
                contentType: "application/json",
                responseType: 'arraybuffer'
            }).then(function (response) {
                var res = {};
                res.buffer = response;
                defer.resolve(res);
            })
                .catch(function (data, status, headers, config) {
                    return $q.reject(data);
                });
            return defer.promise;
        }

        function exportData(api, filter) {
            var defer = $q.defer();
            $http = $http || $injector.get('$http');
            $http({
                method: 'POST',
                url: api,
                data: JSON.stringify(filter),
                contentType: "application/json",
                responseType: 'arraybuffer'
            }).then(function (response) {
                var res = {};
                res.buffer = response;
                defer.resolve(res);
            })
                .catch(function (data, status, headers, config) {
                    return $q.reject(data);
                });
            return defer.promise;
        }

        function navigateToUrl(url) {
            $location = $location || $injector.get('$location');
            $location.path(url);
        }

        function navigateTo(stateTo, params, canNavigateFn) {
            if (!canNavigateFn) {
                nav(stateTo, params);
            }
            else {
                canNavigateFn()
                    .then(function () { nav(stateTo, params); })
                    .catch(function () { console.log("navigation to state not authorized: ", stateTo); });
            }
        }

        function navigateToNewWindow(stateTo, params, canNavigateFn) {
            // navigate to new window, by default ignore history
            var ignoreHistory = true;
            var openNewWindow = true;

            if (!canNavigateFn) {
                return nav(stateTo, params, ignoreHistory, openNewWindow);
            }
            else {
                canNavigateFn()
                    .then(function () { nav(stateTo, params, ignoreHistory, true); })
                    .catch(function () { console.log("navigation to state not authorized: ", stateTo); });
            }
        }

        ////navigate from menu
        //function panelNavigateTo(stateTo, params) {
        //    nav(stateTo, params, true);
        //}

        function nav(stateTo, params, bypassHystory, openNewWindow) {
            if (!openNewWindow) {
                openNewWindow = false;
            }

            //if (isBackState) {
            //    self.backState = {
            //        stateTo: stateTo,
            //        params: params
            //    };
            //}
            lastNavIsBack = false;
            $state = $state || $injector.get('$state');

            var nextStateObj = {
                stateTo: stateTo,
                params: params
            };
            var stateSplitted = stateTo.split('.');
            if ($state.current.routeParams && $state.current.routeParams.ignoreHistory) {
                self.navigationHistory = Lazy(self.navigationHistory).reject(function (n) {
                    return n.stateTo === $state.current.name;
                }).toArray();
            }

            if (!bypassHystory) {
                if (stateSplitted[stateSplitted.length - 1].indexOf('ftc') < 0) {
                    //app.nav.supplierftc-customer => no
                    //app.nav.supplierftc-customer.suppliertocategoriesdetail-customer => push
                    self.navigationHistory.push(nextStateObj);
                    self.backIndex = 0;
                    if (self.navigationHistory.length > 30) {//circular buffer 30 elements
                        self.navigationHistory.shift();
                    }
                }
            }
            //add view static params
            var sTo = Lazy($state.get()).findWhere({ name: stateTo });
            if (sTo && sTo.staticParam) {
                var staticParam = JSON.parse(sTo.staticParam);
                if (staticParam) {
                    var staticParamsKeys = Object.keys(staticParam);
                    for (var k = 0; k < staticParamsKeys.length; k++) {
                        params[staticParamsKeys[k]] = staticParam[staticParamsKeys[k]];
                    }
                }
            }

            // add extra param to all api call views (templateUrl)
            if (sTo && sTo.srvExtraParam) {
                var regions = Object.keys(sTo.views);
                Lazy(regions)
                    .each((region) => {
                        if (!sTo.views[region].templateUrlLast) {
                            sTo.views[region].templateUrlLast = sTo.views[region].templateUrl;
                        }

                        sTo.views[region].templateUrl = sTo.views[region].templateUrlLast + '/' + params[sTo.srvExtraParam];
                    });
            }

            if (openNewWindow) {
                return $window.open($state.href(sTo.name, params, { absolute: true }), '_blank');
            } else {
                return $state.go(stateTo, params);
            }
        }

        function b64EncodeUnicode(str) {
            return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
                return String.fromCharCode('0x' + p1);
            }));
        }

        function navigateToFileBoxDetail(navigationParams) {
            var params = {};
            var route = '';

            params.fileBoxId = navigationParams.FileBoxId;
            params.appStoreCode = navigationParams.AppStoreCode;
            params.viewKind = navigationParams.ViewKind;
            params.fileBoxTag = navigationParams.FileBoxTag;
            params.viewMode = navigationParams.ViewMode;
            params.fileBoxType = navigationParams.FileBoxType;
            params.target = navigationParams.Target;

            if (!params.fileBoxId) {
                // new action
                route = "app.nav.fileboxftc.fileboxdetail-" + params.viewMode;
            } else {
                if (params.target === 'manage') {
                    route = "app.nav.fileboxftc.fileboxdetail-" + params.viewMode;
                } else {
                    if (params.fileBoxType === 'ImportData') {
                        route = 'app.nav.' + params.viewKind.toLowerCase() + 'detail';
                    } else {
                        route = "app.nav.fileboxftc.fileboxviewerdetail-" + params.viewMode;
                    }
                }
            }

            nav(route, params);
        }

        function navigateToActDetail(navigationParams) {
            var actId = navigationParams.act.ActId;
            var appStoreCode = navigationParams.appStoreCode;
            var viewMode = navigationParams.viewMode;
            var prefixPage = navigationParams.act.ActTemplateCode ? navigationParams.act.ActTemplateCode : '';
            var params = {};
            var route = 'app.nav.';
            if (prefixPage) {
                route += prefixPage + 'actdetail';
            } else {
                route += 'actdetail';
            }
            if (viewMode && viewMode !== '_') {
                route += '-' + viewMode;
            }

            params.actId = actId;
            params.appStoreCode = appStoreCode;
            nav(route, params);
        }

        function navigateToAddAct(navigationParams) {
            var actTemplateCode = navigationParams.actTemplateCode ? navigationParams.actTemplateCode : '';
            var actFixedCode = navigationParams.actFixedCode;
            var appStoreCode = navigationParams.appStoreCode;

            var params = {};
            params.virtualHumanResourceId = navigationParams.virtualHumanResourceId;
            params.actFixedCode = actFixedCode;
            params.appStoreCode = appStoreCode;

            var route = 'app.nav.' + actTemplateCode + 'actcreationrequestdetail';
            nav(route, params);
        }

        function getRouteParamObjByTemplateCode(vhrEventTemplateCode) {
            // VhrEventTemplateCode example: "Tec.Asset._.TecCleanData.TecCleanGeneralData"
            // [0] Tec:             appStoreCode
            // [1] Asset:           ftc name without suffix ftc
            // [2] _:               viewmode           (OPTIONAL, if empty = default: '')
            // [3] TecCleanData:    cshtml page without detail suffix
            var templateCodeParams = vhrEventTemplateCode.split('.');
            var appStoreCode = templateCodeParams[0].toLowerCase();
            var ftcName = null; // optional
            if (templateCodeParams[1]) {
                ftcName = templateCodeParams[1].toLowerCase() + 'ftc';
            }
            var viewMode = null;
            var pageName = null;

            if (templateCodeParams.length === 3) {
                viewMode = '';
                pageName = templateCodeParams[2].toLowerCase() + 'detail';
            } else {
                viewMode = templateCodeParams[2]
                    ? templateCodeParams[2] === '_' ? '' : '-' + templateCodeParams[2].toLowerCase()
                    : '_';
                pageName = templateCodeParams[3].toLowerCase() + 'detail';
            }

            // TODO: remove this condition and mange this in template builder
            // Used only to TEST
            if (ftcName === 'shehrftc') {
                ftcName = 'avatarftc';
            }

            return {
                AppStoreCode: appStoreCode,
                FtcName: ftcName,
                ViewMode: viewMode,
                PageName: pageName
            };
        }

        function navigateBack() {

            $state = $state || $injector.get('$state');
            $stateParams = $stateParams || $injector.get('$stateParams');
            if (self.navigationHistory.length > 0) {
                if (lastNavIsBack) {
                    self.backIndex--;
                }
                else {
                    self.backIndex = self.navigationHistory.length - 2;
                }
                if (self.backIndex < 0) { self.backIndex = 0; }
                var backstate = self.navigationHistory[self.backIndex];
                self.navigationHistory.slice(0, self.backIndex + 1);
                if (backstate) {
                    $state.go(backstate.stateTo, backstate.params);
                    self.navigationHistory.push(backstate);
                }
            }

            lastNavIsBack = true;
        }



        function navigateParent() {
            $state = $state || $injector.get('$state');
            $state.go('^');
        }

        function navToMaintenance() {
            $state = $state || $injector.get('$state');
            $state.go('auth.maintenance');
        }

        function navToUnauthorized(toState) {
            $state = $state || $injector.get('$state');
            $state.go('app.nav.authorizationdenied', { 'toState': toState });
        }

        function navToChangeMyPassword() {
            $state = $state || $injector.get('$state');
            $state.go('app.nav.changemypassword');
        }

        function navToRenewMyTwoFactorSecretCode() {
            $state = $state || $injector.get('$state');
            $state.go('app.nav.renewmytwofactorsecretcode');
        }

        function navToMyAccount() {
            $state = $state || $injector.get('$state');
            $state.go('app.nav.myaccount');
        }

        function navToMyNotification() {
            $state = $state || $injector.get('$state');
            $state.go('app.nav.mynotificationslist');
        }

        function navToMyTickets() {
            return nav('app.nav.fdticketslist', null, false, false);
        }

        function navToForceChangeMyPassword() {
            $state = $state || $injector.get('$state');
            $state.go('app.nav.forcechangemypassword');
        }

        function navToHome() {
            $state = $state || $injector.get('$state');
            //var redirurl = Nembus.Common.Library.server.getServerVar("ssv_cms_homepage_redirect");
            //redirurl not work when user is auth and try to navigate /#/auth/login
            //redirurl = ''; is a fix .. evaluate home page by user 
            //todo: set ssv_cms_homepage_redirect the redir state, not url e.g. redirRoute
            var redirRoute = Nembus.Common.Library.server.getServerVar("ssv_cms_homepage_redirect");
            redirRoute = '';
            if (redirRoute !== '') {
                //navigateToUrl(redirurl);
                $state.go(redirRoute, {});
            }
            else {
                $state.go("app.nav.landingpage", {});
                //navigationService.navigateToUrl('/app/dashboard/dashboardHome');
            }
            //$state.go('app.nav.appHome');
        }

        function navAfterLogin() {
            $state = $state || $injector.get('$state');
            if (redirAfterLogin) {
                var redirUrl = redirAfterLogin;
                redirAfterLogin = null;
                //$location.path(redirUrl)
                $state.go(redirUrl, redirAfterLoginParams);
            }
            else {
                if (externalRedir.nextPath != '') {
                    $location = $location || $injector.get('$location');
                    $location.path(externalRedir.nextPath);
                }
                else {
                    this.navToHome();
                }
            }
        }

        function navPublicConfigMobile() {
            $state = $state || $injector.get('$state');
            $state.go('auth.publicmobileappconfig', {});
        }

        function setCurrentModal(modalInstance) {
            self.isModal = true;
            self.currentModalInstance = modalInstance;
        }

        //modalResult = {
        //    dialogResult: 1,
        //    data: result.data,
        //    hasError: false,
        //    error: { messages: result.errorMessage }
        //}
        function closeCurrentModal(result) {
            if (!result) {
                result = new modalResult();
            }

            self.isModal = false;
            if (self.currentModalInstance) {
                self.currentModalInstance.close();
                self.currentModalInstance = null;
                if (self.modalDefer) {
                    self.modalDefer.resolve(result);
                }
            }
        }



        function navToCurrentModalDoc() {
            self.modalCallBack = null;
            $state = $state || $injector.get('$state');
            var codes = $state.$current.name.split('.');

            codes.splice(0, 2);
            var viewPageCode = codes[codes.length - 1];

            navToModalController(() => { }, 'app.nav.viewpageelementslist', { 'cmsViewCode': viewPageCode })
                .then(function (result) {
                    defer.resolve(result);
                });

            self.modalDefer = $q.defer();

            return self.modalDefer.promise;
        }

        function navToCurrentModalDocElementDetail(viewPageElementId) {
            $state = $state || $injector.get('$state');
            var stateTo = $state.$current.name + 'detail';
            $state.go(stateTo, { 'viewPageElementId': viewPageElementId });
        }

        function modalTo(shortState, params, modalCallBack) {
            $state = $state || $injector.get('$state');
            self.parentState = $state.current.name;
            self.modalCallBack = modalCallBack;

            if (!shortState) {
                shortState = "modal";
            }

            var stateTo = $state.current.name + shortState;
            $state.go(stateTo, params);
            //nav(stateTo, params);
            var defer = $q.defer();
            self.modalDefer = $q.defer();
            return self.modalDefer.promise;
        }

        function modalToWz(navParams, modalCallBack) {
            var baseObj = { parentParams: btoa(JSON.stringify(navParams.params)) };
            var modalkey = navParams.modalkey ? navParams.modalkey : 'modal';

            $state = $state || $injector.get('$state');
            //self.modalCallBack = modalCallBack;

            var stateTo = navParams.routeName;
            $state.go(stateTo, baseObj);

            var defer = $q.defer();
            self.modalDefer = $q.defer();
            return self.modalDefer.promise;
        }

        function getUIRouterStateModal(paramObj) {
            return {
                ngUrl: !paramObj.viewMode
                    ? '/' + paramObj.modalKey
                    : '/' + paramObj.modalKey + '-' + paramObj.viewMode,
                ngParamsName: '/{parentParams}',
                baseName: paramObj.baseName,
                //services: paramObj.services ? paramObj.services : '',
                //components: paramObj.components ? paramObj.components : '',
                isModalContainer: true,
                parentState: paramObj.parentState,
                viewMode: !paramObj.viewMode
                    ? '_'
                    : paramObj.viewMode,
                ignoreDirtyFlag: true
            };
        }

        //navParam = {
        //params: 
        //modalkey:  default 'modal'
        //}
        function modalToDefault(navParam, modalCallBacks) {
            var baseObj = { parentParams: btoa(JSON.stringify(navParam.params)) };
            var modalkey = navParam.modalkey ? navParam.modalkey : 'modal';
            return modalTo(modalkey, baseObj, modalCallBacks);
        }
        function modal() {
            $uibModal = $uibModal || $injector.get('$uibModal');
            return $uibModal;
        }
        function stateParams() {
            $stateParams = $stateParams || $injector.get('$stateParams');
            return $stateParams;
        }
        function stateParamsModal() {
            $stateParams = $stateParams || $injector.get('$stateParams');
            var parentParams = $stateParams.parentParams ? JSON.parse(atob($stateParams.parentParams)) : null;
            return { parentParams: parentParams };
        }
        function currentState() {
            $state = $state || $injector.get('$state');
            return $state.current.name;
        }
        function currentPath() {
            $location = $location || $injector.get('$location');
            return $location.path();
        }
        function currentCmsViewCode() {
            //var currentState = $state.$current.parent.parent.name;
            //if (self.isModal) {
            //    //
            //    var currentState = $state.$current.parent.parent.name;
            //}
            //else {
            //    var currentState = $state.current.name;
            //}

            //var viewCode = currentState.indexOf('-') > 0 ? currentState.substring(8, currentState.length) : currentState.substring(8, currentState.length) + '-_';
            //clean state e.g. companyftc.hrcontactdetail-company -> hrcontactdetail-company
            $state = $state || $injector.get('$state');
            var viewCode = $state.current.routeParams.cmsViewCode;
            return Nembus.Common.Library.cms.parseCmsViewCode(viewCode);
        }
        function currentCmsViewTitle() {
            $state = $state || $injector.get('$state');

            var baseName = $state.current.routeParams.baseName;
            if (!baseName) {
                baseName = '[controllerName]';
            }

            var titleKey = Lazy(baseName.split('/')).last() + '.title';
            var result = titleKey.charAt(0).toUpperCase() + titleKey.slice(1);

            return result;
        }
        function currentViewMode() {
            var viewCode = currentCmsViewCode();
            if (viewCode.indexOf('-') > 0) {
                return viewCode.substring(viewCode.indexOf('-') + 1, viewCode.length);
            }
            return '_';
        }

        //{
        //title:
        //message:
        //cancelLabel:
        //okLabel:
        //}
        function confirmModalCustomLabels(modalData) {
            if (!modalData.viewOkBtn) {
                modalData.viewOkBtn = false;
            }
            if (!modalData.viewCancelBtn) {
                modalData.viewCancelBtn = false;
            }
            if (!modalData.message) {
                modalData.message = '';
            }
            if (!modalData.cancelLabel) {
                modalData.cancelLabel = $TG('ModalButtonCancel');
            }
            if (!modalData.okLabel) {
                modalData.okLabel = $TG('ModalButtonOk');
            }
            if (!modalData.title) {
                modalData.title = $TG('ModalWarning');
            }
            if (!modalData.messageIsQuestion) {
                modalData.messageIsQuestion = false;
            }
            var questionMark = modalData.messageIsQuestion ? ' ?' : '';

            $uibModal = $uibModal || $injector.get('$uibModal');
            var modalHtml = getModalHeaderHtml(modalData.title);
            modalHtml += getModalBodyHtml(modalData.message + questionMark);
            modalHtml += getModalFooterHtml(modalData.viewOkBtn, modalData.viewCancelBtn, modalData.okLabel, modalData.cancelLabel);

            var defer = $q.defer();
            var ModalInstanceCtrl = function ($scope, $uibModalInstance) {
                $scope.ok = function () {
                    $uibModalInstance.close();
                };

                $scope.cancel = function () {
                    $uibModalInstance.dismiss();
                };
            };
            var modalInstance = $uibModal.open({
                template: modalHtml,
                controller: ModalInstanceCtrl,
                backdrop: 'static'
            });
            modalInstance.result.then(function () {
                defer.resolve(true);
            }, function (error) {
                defer.resolve(false);
            });
            return defer.promise;
        }


        function navToActJournalsModal(suspendDirty, journalAttachManager) {
            var defer = $q.defer();

            storeJournalAttachmentsModalParams(journalAttachManager);

            var stateTo = 'app.nav.actjournalsmodal-manage';
            navToModalController(suspendDirty, stateTo, {})
                .then(function (result) {
                    defer.resolve(result);
                });

            return defer.promise;
        }

        function navToGenericListModal(suspendDirty, genericModalParams, modalDs) {
            // store datasource for modal in navigationservice (use getModalData to use in modal)
            var stateTo = 'app.nav.genericlistmodal';
            var defer = $q.defer();

            storeModalData(stateTo, modalDs, genericModalParams);

            navToModalController(suspendDirty, stateTo, genericModalParams)
                .then(function (result) {
                    defer.resolve(result);
                });

            return defer.promise;
        }

        function navigateToVhrEventDetail(navigationParams) {
            if (!navigationParams.VhrEventId) {
                throw new Error('VhrEventId is mandatory');
            }

            var params = {};
            if (navigationParams.VhrEventId) { params.vhrEventId = navigationParams.VhrEventId; }
            if (navigationParams.ViewKind) { params.viewKind = navigationParams.ViewKind; }
            if (navigationParams.ViewMode) { params.viewMode = navigationParams.ViewMode; }
            if (navigationParams.VirtualHumanResourceId) { params.virtualHumanResourceId = navigationParams.VirtualHumanResourceId; }
            if (navigationParams.vhrEventFixedTypeCode) { params.vhrEventFixedTypeCode = navigationParams.vhrEventFixedTypeCode; }

            var routeParamObj = getRouteParamObjByTemplateCode(navigationParams.VhrEventTemplateCode);
            if (routeParamObj.AppStoreCode) {
                params.appStoreCode = routeParamObj.AppStoreCode;
                params.appStoreCodeFtc = routeParamObj.AppStoreCode;
            }

            // if AppStoreCode is passed in navigationParams then overwrite AppStoreCode get by VhrEventTemplateCode
            if (navigationParams.AppStoreCode) {
                params.appStoreCode = navigationParams.AppStoreCode;
                params.appStoreCodeFtc = navigationParams.AppStoreCode;
            }

            //if ViewMode is passed in navigationParams then overwrite ViewMode get by VhrEventTemplateCode
            var viewMode = routeParamObj.ViewMode;
            if (navigationParams.ViewMode) {
                viewMode = '-' + navigationParams.ViewMode;
            }

            var route = [];
            if (routeParamObj.FtcName) {
                route = ['app.nav', routeParamObj.FtcName, routeParamObj.PageName + viewMode].join('.');
            } else {
                route = ['app.nav', routeParamObj.PageName + viewMode].join('.');
            }

            nav(route, params);
        }
        function navigateToAssetTreeDetail(assetTree, params) {
            if (!assetTree) {
                throw new Error('AssetTree is mandatory');
            }

            var viewMode = params.viewMode ? params.viewMode : '_';
            var viewKind = params.viewKind ? params.viewKind : '_';

            if (params.appStoreCode === 'tre') {
                switch (assetTree.AssetTreeEntityType) {
                    case 'VirtualEquipment':
                        navigateTo("app.nav.treassetftc.treassetdetail-tre", { 'virtualHumanResourceId': assetTree.VirtualHumanResourceId, 'appStoreCode': params.appStoreCode, 'viewKind': viewKind });
                        break;
                    case 'HrBucket':
                        navigateTo("app.nav.bucketftc.bucketdetail-dace", { 'virtualHumanResourceId': assetTree.VirtualHumanResourceId, 'appStoreCodeFtc': 'all', 'viewMode': 'dace', 'resourceType': 'DataCenter', 'bucketClassification': 'DataCenter' });
                        break;
                    default:
                        throw new Error('AssetTreeEntityType not managed');
                }
            } else {
                switch (assetTree.AssetTreeEntityType) {
                    case 'VirtualEquipment':
                        navigateTo("app.nav.assetftc", { 'virtualHumanResourceId': assetTree.VirtualHumanResourceId, 'appStoreCodeFtc': params.appStoreCode, 'viewKindFtc': viewKind, 'viewMode': viewMode, 'referral': 'virtualequipment' });
                        break;
                    case 'HrBucket':
                        navigateTo("app.nav.bucketftc", { 'virtualHumanResourceId': assetTree.VirtualHumanResourceId, 'appStoreCodeFtc': 'all', 'viewMode': 'plnt', 'resourceType': 'Plant', 'bucketClassification': 'Plant' });
                        break;
                    default:
                        throw new Error('AssetTreeEntityType not managed');
                }
            }
        }

        //#region execution
        function navToVhrEventExecute(suspendDirty, navParams) {
            if (!navParams) {
                navParams = {};
            }
            navParams.modalKey = 'executeexecution';

            var defer = $q.defer();
            navToModal(suspendDirty, 'app.nav.vhreventchangeexecution-execute', navParams)
                .then(function (result) {
                    defer.resolve(result);
                });

            return defer.promise;
        }

        function navToVhrEventChangeExecution(suspendDirty, navParams) {
            if (!navParams) {
                navParams = {};
            }
            navParams.modalKey = 'changeexecution';

            var defer = $q.defer();
            navToModal(suspendDirty, 'app.nav.vhreventchangeexecution-modify', navParams)
                .then(function (result) {
                    defer.resolve(result);
                });

            return defer.promise;
        }

        function navToVhrEventInfoExecution(suspendDirty, navParams) {
            if (!navParams) {
                navParams = {};
            }
            navParams.modalKey = 'historyexecution';

            var defer = $q.defer();
            navToModal(suspendDirty, 'app.nav.vhreventchangeexecution-history', navParams)
                .then(function (result) {
                    defer.resolve(result);
                });

            return defer.promise;
        }
        //#endregion

        //#region assignment
        function navToVhrEventAssignmentExecute(suspendDirty, navParams) {
            if (!navParams) {
                navParams = {};
            }
            navParams.modalKey = 'executeassignment';

            var defer = $q.defer();
            navToModal(suspendDirty, 'app.nav.vhreventchangeassignment-execute', navParams)
                .then(function (result) {
                    defer.resolve(result);
                });

            return defer.promise;
        }

        function navToVhrEventAssignmentModify(suspendDirty, navParams) {
            if (!navParams) {
                navParams = {};
            }
            navParams.modalKey = 'changeassignment';

            var defer = $q.defer();
            navToModal(suspendDirty, 'app.nav.vhreventchangeassignment-modify', navParams)
                .then(function (result) {
                    defer.resolve(result);
                });

            return defer.promise;
        }

        function navToVhrEventAssignmentInfo(suspendDirty, navParams) {
            if (!navParams) {
                navParams = {};
            }
            navParams.modalKey = 'historyassignment';

            var defer = $q.defer();
            navToModal(suspendDirty, 'app.nav.vhreventchangeassignment-history', navParams)
                .then(function (result) {
                    defer.resolve(result);
                });

            return defer.promise;
        }
        //#endregion

        //#region approval
        function navToVhrEventApprove(suspendDirty, navParams) {
            if (!navParams) {
                navParams = {};
            }
            navParams.modalKey = 'authorizeapproval';

            var defer = $q.defer();
            navToModal(suspendDirty, 'app.nav.vhreventchangeapproval-authorize', navParams)
                .then(function (result) {
                    defer.resolve(result);
                });

            return defer.promise;
        }

        function navToVhrEventChangeApproval(suspendDirty, navParams) {
            if (!navParams) {
                navParams = {};
            }
            navParams.modalKey = 'changeapproval';

            var defer = $q.defer();
            navToModal(suspendDirty, 'app.nav.vhreventchangeapproval-modify', navParams)
                .then(function (result) {
                    defer.resolve(result);
                });

            return defer.promise;
        }

        function navToVhrEventInfoApproval(suspendDirty, navParams) {
            if (!navParams) {
                navParams = {};
            }
            navParams.modalKey = 'historyapproval';

            var defer = $q.defer();
            navToModal(suspendDirty, 'app.nav.vhreventchangeapproval-history', navParams)
                .then(function (result) {
                    defer.resolve(result);
                });

            return defer.promise;
        }
        //#endregion

        //#region approval
        function navToVhrEventCustomerApprove(suspendDirty, navParams) {
            if (!navParams) {
                navParams = {};
            }
            navParams.modalKey = 'authorizecustomerapproval';

            var defer = $q.defer();
            navToModal(suspendDirty, 'app.nav.vhreventchangecustomerapproval-authorize', navParams)
                .then(function (result) {
                    defer.resolve(result);
                });

            return defer.promise;
        }

        function navToVhrEventChangeCustomerApproval(suspendDirty, navParams) {
            if (!navParams) {
                navParams = {};
            }
            navParams.modalKey = 'changecustomerapproval';

            var defer = $q.defer();
            navToModal(suspendDirty, 'app.nav.vhreventchangecustomerapproval-modify', navParams)
                .then(function (result) {
                    defer.resolve(result);
                });

            return defer.promise;
        }

        function navToVhrEventInfoCustomerApproval(suspendDirty, navParams) {
            if (!navParams) {
                navParams = {};
            }
            navParams.modalKey = 'historycustomerapproval';

            var defer = $q.defer();
            navToModal(suspendDirty, 'app.nav.vhreventchangecustomerapproval-history', navParams)
                .then(function (result) {
                    defer.resolve(result);
                });

            return defer.promise;
        }
        //#endregion

        function navToVhrEventChangeStartDate(suspendDirty, navParams) {
            navParams.modalKey = 'eventchangestartdate';

            var defer = $q.defer();
            navToModal(suspendDirty, 'app.nav.vhreventchangestartdate', navParams)
                .then(function (result) {
                    defer.resolve(result);
                });

            return defer.promise;
        }

        function navToJobRequestChangeStartDate(suspendDirty, navParams) {
            navParams.modalKey = 'jobchangestartdate';

            var defer = $q.defer();
            navToModal(suspendDirty, 'app.nav.jobrequestchangestartdate', navParams)
                .then(function (result) {
                    defer.resolve(result);
                });

            return defer.promise;
        }


        function navToSendNotificationModal(suspendDirty, navParams) {
            navParams.modalKey = 'sendnotification';

            var defer = $q.defer();
            navToModal(suspendDirty, 'app.nav.sendnotificationmodal', navParams, null)
                .then(function (result) {
                    defer.resolve(result);
                });

            return defer.promise;
        }

        function navToAssignmentModal(suspendDirty, navParams, ds) {
            navParams.modalKey = 'assigneeselection';

            var defer = $q.defer();
            navToModal(suspendDirty, 'app.nav.drbacassignmentdetail', navParams, ds)
                .then(function (result) {
                    defer.resolve(result);
                });

            return defer.promise;
        }

        //#region workflow navigations ACT
        function navToActWorkflowExecuteMode(suspendDirty) {
            var stateTo = 'app.nav.actworkflowchangestatusdetail-execute';
            var params = { viewKind: 'execute', modalKey: 'execute' };

            var defer = $q.defer();
            navToModal(suspendDirty, stateTo, params)
                .then(function (result) {
                    defer.resolve(result);
                });

            return defer.promise;
        }

        function navToActWorkflowModifyMode(suspendDirty) {
            var stateTo = 'app.nav.actworkflowchangestatusdetail-modify';
            var params = { viewKind: 'modify', modalKey: 'modify' };

            var defer = $q.defer();
            navToModal(suspendDirty, stateTo, params)
                .then(function (result) {
                    defer.resolve(result);
                });

            return defer.promise;
        }

        function navToActWorkflowHistoryMode(suspendDirty, actId) {
            var stateTo = 'app.nav.actworkflowchangestatusdetail-history';
            var params = { viewKind: 'history', modalKey: 'history', ActId: actId };

            var defer = $q.defer();
            navToModal(suspendDirty, stateTo, params)
                .then(function (result) {
                    defer.resolve(result);
                });

            return defer.promise;
        }
        //#endregion

        //#region workflow navigations VHREVENT
        function navToVhrEventWorkflowModifyMode(suspendDirty, params) {
            var stateTo = 'app.nav.vhreventworkflowchangestatusdetail-modify';
            if (!params) {
                params = {};
            }
            params.viewKind = 'modify';
            params.modalKey = 'modify';

            var defer = $q.defer();
            navToModal(suspendDirty, stateTo, params)
                .then(function (result) {
                    defer.resolve(result);
                });

            return defer.promise;
        }

        function navToVhrEventWorkflowHistoryMode(suspendDirty, params) {
            var stateTo = 'app.nav.vhreventworkflowchangestatusdetail-history';
            if (!params) {
                params = {};
            }
            params.viewKind = 'history';
            params.modalKey = 'history';

            var defer = $q.defer();
            navToModal(suspendDirty, stateTo, params)
                .then(function (result) {
                    defer.resolve(result);
                });

            return defer.promise;
        }
        //#endregion

        function navToModal(suspendDirty, stateTo, modalParams, modalDs) {
            storeModalData(stateTo, modalDs, modalParams);

            var defer = $q.defer();
            navToModalController(suspendDirty, stateTo, modalParams)
                .then(function (result) {
                    defer.resolve(result);
                });

            return defer.promise;
        }

        function getModalData(view, viewmode) {
            if (!view) {
                view = '';
            }
            if (!viewmode || viewmode === '_') {
                viewmode = '';
            } else {
                viewmode = '-' + viewmode;
            }
            view = view.toLowerCase();
            viewmode = viewmode.toLowerCase();

            var key = view + viewmode;

            return self.currentModalData[key];
        }

        function storeModalData(stateTo, ds, params) {
            if (!stateTo) {
                stateTo = '';
            }
            stateTo = stateTo.replace('app.nav.', '');
            stateTo = stateTo.toLowerCase();
            var key = stateTo;

            if (!self.currentModalData[key]) {
                self.currentModalData[key] = {};
            }

            self.currentModalData[key].ds = ds ? ds : [];
            self.currentModalData[key].params = params;
        }

        function storeJournalAttachmentsModalParams(params) {
            self.currentJournalAttachmentsModalParams = params;
        }

        function getJournalAttachmentsModalParams() {
            return self.currentJournalAttachmentsModalParams;
        }

        function navToModalController(suspendDirty, stateTo, modalParams) {
            var cmsService = $injector.get('cmsService');
            var modalKey = modalParams.modalMode ? modalParams.modalMode : 'mkey';
            if (modalParams.modalKey) {
                modalKey += modalParams.modalKey.toLowerCase();
            }

            var parentState = currentState();
            var routeName = parentState + modalKey;
            var stateProvider = $state || $injector.get('$state');

            if (!stateTo) {
                stateTo = '';
            } else {
                stateTo = stateTo.toLowerCase();
            }

            var stateToNav = stateProvider.get(stateTo);
            var viewMode = stateTo.indexOf('-') >= 0 ? stateTo.split('-')[1] : '';
            var defer = $q.defer();
            var state = getUIRouterStateModal({
                modalKey: modalKey,
                parentState: parentState,
                baseName: stateToNav.routeParams.baseName,
                viewMode: viewMode,
                //services: stateToNav.routeParams.services,
                //components: stateToNav.routeParams.components ? stateToNav.routeParams.components : null
            });

            // add router UI route at runtime
            cmsService.addRouterUIStateResolve(routeName, state);

            //disable dirty flag
            suspendDirty(true);
            var navParams = {
                params: modalParams,
                modalkey: modalKey
            };

            modalToDefault(navParams)
                .then(function (modalResult) {
                    //enable dirty flag
                    suspendDirty(false);

                    if (modalResult.dialogResult === navigationActionTypes.modalOk) {
                        defer.resolve(modalResult);
                    }
                });

            return defer.promise;
        }

        function confirmModal(message) {
            var defer = $q.defer();

            confirmModalCustomLabels({
                title: $TG('ModalWarning'),
                message: message,
                messageIsQuestion: true,
                viewOkBtn: true,
                viewCancelBtn: true
            })
                .then(function (result) {
                    defer.resolve(result);
                });

            return defer.promise;
        }

        function confirmModalNoQuestion(message) {
            var defer = $q.defer();

            confirmModalCustomLabels({
                title: $TG('ModalWarning'),
                message: message,
                messageIsQuestion: false,
                viewOkBtn: true,
                viewCancelBtn: true
            })
                .then(function (result) {
                    defer.resolve(result);
                });

            return defer.promise;
        }

        function confirmExitWizard() {
            var defer = $q.defer();

            confirmModalCustomLabels({
                title: $TG('ModalWarning'),
                message: $TG('DoYouWishAbandonWizard'),
                messageIsQuestion: true,
                viewOkBtn: true,
                viewCancelBtn: true
            })
                .then(function (result) {
                    defer.resolve(result);
                });

            return defer.promise;
        }

        function operationNotPermittedModal() {
            var defer = $q.defer();

            confirmModalCustomLabels({
                title: $TG('ModalWarning'),
                message: $TG('OperationNotPermitted'),
                viewOkBtn: true,
                viewCancelBtn: false
            })
                .then(function (result) {
                    defer.resolve(result);
                });

            return defer.promise;
        }


        function copyTextMessageModal(message, title) {
            var defer = $q.defer();

            if (!message) { message = ''; }
            if (!title) { title = ''; }

            $uibModal = $uibModal || $injector.get('$uibModal');
            var modalHtml = getModalHeaderHtml(title);
            modalHtml += '<div class="modal-body">' + message + '</span></div>';

            var copyBtnHtml = '<button class="btn btn-primary" ng-click="copy()">' + $TG('Copy') + '</button>';
            var cancelBtnHtml = '<button class="btn" ng-click="cancel()">' + $TG('ModalButtonOk') + '</button>';
            var copyMessageContainer = '<span id="copyMessageId" style="color:green;margin-right: 30px;font-weight: 700;"></span>'
            modalHtml += '<div class="modal-footer">' + copyMessageContainer + copyBtnHtml + cancelBtnHtml + '</div>';

            var defer = $q.defer();
            var ModalInstanceCtrl = function ($scope, $uibModalInstance) {
                $scope.copy = function () {
                    // copy message text to clipboard
                    Nembus.Common.Library.util.copyTextToClipboard(message);

                    copyMessageContainer = $('#copyMessageId');
                    copyMessageContainer.text($TG('CopiedInClipboard'));
                };

                $scope.cancel = function () {
                    $uibModalInstance.dismiss();
                };
            };
            var modalInstance = $uibModal.open({
                template: modalHtml,
                controller: ModalInstanceCtrl,
                backdrop: 'static'
            });
            modalInstance.result.then(function () {
                defer.resolve(true);
            }, function (error) {
                defer.resolve(false);
            });
            return defer.promise;
        }

        function customMessageModal(message) {
            var defer = $q.defer();

            confirmModalCustomLabels({
                title: $TG('ModalWarning'),
                message: $TG(message),
                viewOkBtn: true,
                viewCancelBtn: false
            })
                .then(function (result) {
                    defer.resolve(result);
                });

            return defer.promise;
        }

        function selectOneItemMessageModal() {
            var defer = $q.defer();

            confirmModalCustomLabels({
                title: $TG('ModalWarning'),
                message: $TG('SelectOne'),
                viewOkBtn: true,
                viewCancelBtn: false
            })
                .then(function (result) {
                    defer.resolve(result);
                });

            return defer.promise;
        }

        function selectOnlyOneItemMessageModal() {
            var defer = $q.defer();

            confirmModalCustomLabels({
                title: $TG('ModalWarning'),
                message: $TG('SelectOnlyOne'),
                viewOkBtn: true,
                viewCancelBtn: false
            })
                .then(function (result) {
                    defer.resolve(result);
                });

            return defer.promise;
        }

        function selectAtLeastOneItemMessageModal() {
            var defer = $q.defer();

            confirmModalCustomLabels({
                title: $TG('ModalWarning'),
                message: $TG('SelectAtLeastOne'),
                viewOkBtn: true,
                viewCancelBtn: false
            })
                .then(function (result) {
                    defer.resolve(result);
                });

            return defer.promise;
        }

        function userNotAuthorizedMessageModal() {
            var defer = $q.defer();

            confirmModalCustomLabels({
                title: $TG('ModalWarning'),
                message: $TG('UserNotPermitted'),
                viewOkBtn: true,
                viewCancelBtn: false
            })
                .then(function (result) {
                    defer.resolve(result);
                });

            return defer.promise;
        }

        // START function that returns html for modal template
        function getModalHeaderHtml(title) {
            return '<div class="modal-header" draggable><h3 class="modal-title">' + title + '</h3></div>';
        }
        function getModalBodyHtml(message) {
            return '<div class="modal-body">' + message + '</div>';
        }
        function getModalFooterHtml(withOkBtn, withCancelBtn, okBtnTitle, cancelBtnTitle) {
            var okBtnHtml = withOkBtn
                ? '<button class="btn btn-primary" ng-click="ok()">' + okBtnTitle + '</button>'
                : '';

            var cancelBtnHtml = withCancelBtn
                ? '<button class="btn" ng-click="cancel()">' + cancelBtnTitle + '</button>'
                : '';

            return '<div class="modal-footer">' + okBtnHtml + cancelBtnHtml + '</div>';
        }


        function notifyStateChange(toState, cmsViewCode) { //called in app.js
            var fns = Object.keys(notifyFn);
            for (var i = 0; i < fns.length; i++) {
                var fn = notifyFn[fns[i]];//invoke fn
                if (fn) {
                    fn(toState, cmsViewCode);
                }
            }
        }

        function oidcNoAuth() {
            $state = $state || $injector.get('$state');
            $state.go("auth.publicnoauth", {});
        }
        function offlineModal() {
            var modalData = {

            };
            var defer = $q.defer();
            var $uibModal = $injector.get('$uibModal');
            return Nembus.Common.Library.ui.modalOffline(modalData, $uibModal, defer);
        }
    }

    navigationService.$inject = injectParams;
    angular.module('nbs.shared.module').service('navigationService', navigationService);

});