﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'audService', 'reportService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var surveyReportForm = function ($q, $scope, $timeout, $injector, audService, reportService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, audService, navigationService, contextService, $q, $injector, $timeout);
        var checkStateChangeParams = { dirtyFlagIsDisabled: vm.isDirtyDisabled };
        checkStateChangeService.checkFormOnStateChange($scope, audService, checkStateChangeParams);

        var appStoreCode = 'aud';
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var surveyLifeStatusEnumObj = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyLifeStatus, null, vm.translate).obj.normal.name_value;

        // all form items are enabled
        vm.setIsEditingReport();


        // posted filter
        vm.currentReportFilter = {};

        // entity to validate
        vm.surveyReportFormClientData = audService.dataContext.surveyReportFormClientDatas.add({
            Id: NbsGuidManager.newGuid()
        });

        // set default visibility
        vm.viewSurvey = false;
        vm.viewSurveyTemplate = false;
        vm.viewKpiDropDown = false;
        vm.viewIsChecked = false;
        vm.viewIsImportant = false;

        loadData(true);

        var vhrEventFilter = {};
        var dataKey = null;
        switch (vm.viewMode) {
            case 'surveyexport':
            case 'surveyexportaction':
            case 'surveyconfiguration':
                vm.viewSurvey = true;
                vm.viewIsChecked = true;
                vm.viewIsImportant = true;
                break;
            case 'surveyoptiongrouping':
                vm.viewSurvey = true;
                vm.viewIsChecked = false;
                vm.viewIsImportant = true;
                break;
            case 'surveykpisection':
                vhrEventFilter.VhrEventLifeStatusIds = [surveyLifeStatusEnumObj.ReportedAsFinished];
                dataKey = 'surveycompleted';
                vm.viewSurvey = true;
                vm.viewKpiDropDown = true;
                vm.viewIsChecked = true;
                vm.viewIsImportant = true;
                break;
            case 'surveyexportactionanalysys':
            case 'surveyexportanalysys':
                vhrEventFilter.VhrEventLifeStatusIds = [surveyLifeStatusEnumObj.ReportedAsFinished];
                dataKey = 'surveycompleted';
                vm.viewSurvey = true;
                break;
            case 'surveytemplateexport':
            case 'surveytemplateconfiguration':
                vm.viewSurveyTemplate = true;
                break;
        }

        //#region Multiselect with grid selection
        var selectionParams = { stateTo: 'app.nav.surveyslistmodal', destModalKey: 'survey', isMultiselect: false, dataKey: dataKey, filter: vhrEventFilter };
        vm.nbsSelectionGridWithModalSurvey = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        selectionParams = { stateTo: 'app.nav.surveytemplateslistmodal', destModalKey: 'surveyTemplate', isMultiselect: false };
        vm.nbsSelectionGridWithModalSurveyTemplate = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        //#endregion

        // set title of current controller in report definition detail view
        navigationService.setControllerTitle();

        function loadData(forceReload) {
            var funcsPromises = [];
            if (vm.viewMode === 'surveykpisection') {
                funcsPromises.push(getSurveyAnalysisDefinitions(forceReload));
            }

            $q.all(funcsPromises)
                .then(function () {
                    reportService.currentReport.formEntityToValidate = vm.surveyReportFormClientData;
                    reportService.currentReport.prepareReportFilterCallback = setReportArgs;
                });
        }

        function getSurveyAnalysisDefinitions(forceReload) {
            // 1) SurveyQuestionOption_CompletionPercentRequired => Indice completamento
            // 2) SurveyQuestionOption_ActiveSumWeight => Somma pesi risposte attive
            // 3) SurveyQuestionOption_ActiveSumValue => Somma gravità risposte attive
            // 4) SurveyQuestionOption_ActiveWeightedSumWeightValue => Somma dei valori ponderati Pesi x Gravità
            // 5) SurveyQuestionOption_NormalizedIndexSumWeight => Indice normalizzato della sommatoria dei Pesi 
            // 6) SurveyQuestionOption_NormalizedIndexSumValue => Indice normalizzato della sommatoria delle Gravità
            // 7) SurveyQuestionOption_NormalizedIndexSumWeightXValue => Indice normalizzato della sommatoria ponderata Pesi x Gravità
            // 8) SurveyQuestionOption_ActiveAverageSumWeight => Media aritmetica dei Pesi 
            // 9) SurveyQuestionOption_ActiveAverageSumValue =>Media aritmetica delle Gravità
            // 10) SurveyQuestionOption_ActiveAverageSumWeightXValue => Media aritmetica dei Pesi x Gravità
            // 11) SurveyQuestionOption_ActiveWeightedAverageSumWeightXValue => Media ponderata dei Pesi x Gravità
            var filter = {
                SurveyAnalysisDefinitionIds: [
                    'SurveyQuestionOption_CompletionPercentRequired',
                    'SurveyQuestionOption_ActiveSumWeight',
                    'SurveyQuestionOption_ActiveSumValue',
                    'SurveyQuestionOption_ActiveWeightedSumWeightValue',
                    'SurveyQuestionOption_NormalizedIndexSumWeight',
                    'SurveyQuestionOption_NormalizedIndexSumValue',
                    'SurveyQuestionOption_NormalizedIndexSumWeightXValue',
                    'SurveyQuestionOption_ActiveAverageSumWeight',
                    'SurveyQuestionOption_ActiveAverageSumValue',
                    'SurveyQuestionOption_ActiveAverageSumWeightXValue',
                    'SurveyQuestionOption_ActiveWeightedAverageSumWeightXValue'
                ]
            };
            var defer = $q.defer();

            audService.getSurveyAnalysisDefinitions(filter, forceReload)
                .then(function (data) {
                    vm.surveyAnalysisDefinitions = Lazy(data.results).sortBy((item) => { return item.SurveyAnalysisDefinitionName }).toArray();
                    vm.currentReportFilter.SurveyAnalysisDefinitionId = Lazy(vm.surveyAnalysisDefinitions).first().SurveyAnalysisDefinitionId;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function setReportArgs() {
            var surveyId = Lazy(vm.nbsSelectionGridWithModalSurvey.selectedIds).some()
                ? Lazy(vm.nbsSelectionGridWithModalSurvey.selectedIds).first()
                : null;

            var surveyReportArgs = {
                Ds1_Arg1: { //SurveyFilter
                    SurveyId: surveyId
                },
                Ds1_Arg2: { //SurveyVhrEventFilter
                },
                Ds1_Arg3: { //VhrEventFilter
                    ModuleTargetId: moduleTargetId,
                    VhrEventLifeStatusIds: null
                },
                Ds1_Arg4: { //SurveySectionFilter
                },
                Ds1_Arg5: { //SurveyQuestionFilter
                },
                Ds1_Arg6: { //SurveyQuestionOptionFilter
                    IsChecked: vm.currentReportFilter.IsChecked === true && vm.viewIsChecked ? vm.currentReportFilter.IsChecked : null,
                    IsImportant: vm.currentReportFilter.IsImportant === true && vm.viewIsImportant ? vm.currentReportFilter.IsImportant : null
                },
                Ds1_Arg7: { //VirtualHrFilter
                },
                Ds1_Arg8: false, //isConfiguration
                Ds1_Arg9: { //surveyAnalysisFilter
                    SurveyId: surveyId,
                    SurveyAnalysisDefinitionId: vm.currentReportFilter.SurveyAnalysisDefinitionId
                },
            };

            var surveyTemplateReportArgs = {
                Ds1_Arg1: {                     //SurveyFilter
                    SurveyId: Lazy(vm.nbsSelectionGridWithModalSurveyTemplate.selectedIds).some()
                        ? Lazy(vm.nbsSelectionGridWithModalSurveyTemplate.selectedIds).first()
                        : null
                },
                Ds1_Arg2: false                 //isConfiguration
            };

            switch (vm.viewMode) {
                case 'surveyexport':
                case 'surveyexportaction':
                case 'surveyexportanalysys':
                case 'surveyexportactionanalysys':
                case 'surveyoptiongrouping':
                case 'surveykpisection':
                    surveyReportArgs.Ds1_Arg8 = false;
                    reportService.currentReport.reportArgs = surveyReportArgs;
                    break;
                case 'surveyconfiguration':
                    surveyReportArgs.Ds1_Arg8 = true;
                    reportService.currentReport.reportArgs = surveyReportArgs;
                    break;
                case 'surveytemplateexport':
                    surveyTemplateReportArgs.Ds1_Arg2 = false;
                    reportService.currentReport.reportArgs = surveyTemplateReportArgs;
                    break;
                case 'surveytemplateconfiguration':
                    surveyTemplateReportArgs.Ds1_Arg2 = true;
                    reportService.currentReport.reportArgs = surveyTemplateReportArgs;
                    break;
            }

            // set to validate entity
            vm.surveyReportFormClientData.SurveyId = reportService.currentReport.reportArgs.Ds1_Arg1.SurveyId;
        }
    };

    surveyReportForm.$inject = injectParams;
    surveyReportForm.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('surveyReportForm', surveyReportForm);
});
