﻿
'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {


    var injectParams = ['$scope', '$http', '$q', '$location', '$uibModal', 'localStorageService', 'contextService', 'authenticationService', 'rbacService', 'navigationService'];
    var forceChangeMyPassword = function ($scope, $http, $q, $location, $uibModal, localStorageService, contextService, authenticationService, rbacService, navigationService) {

        var vm = this;
        nbsVm.call(vm, rbacService, navigationService, contextService, $q, null, null);

        vm.changePassword = rbacService.dataContext.changePasswords.add();

        var modalInstance = $uibModal.open({
            templateUrl: 'forceChangeMyPasswordTemplate',
            scope: $scope,
            backdrop: 'static',
            keyboard: false,
            size: 'lg'
        });

        vm.logout = function () {
            contextService.clearContext();
            authenticationService.logout()
                .then(function (response) {
                    authenticationService.redirectToRootLocation();
                });
        };

        vm.modalOk = function () {
            //modalInstance.dismiss();
            vm.changePassword.entityAspect.validateEntity();

            if (!vm.changePassword || vm.changePassword.entityAspect.hasValidationErrors) {
                var validations = Lazy(vm.changePassword.entityAspect.getValidationErrors())
                    .filter((validation) => {
                        var returnValidation = true;
                        if (validation.key.indexOf('appUserDtoPasswordRegExpressionValidator') >= 0) { returnValidation = false; }
                        if (validation.key.indexOf('changePasswordCurrentPasswordValidator') >= 0) { returnValidation = false; }
                        if (validation.key.indexOf('changePasswordNewPasswordValidator') >= 0) { returnValidation = false; }
                        if (validation.key.indexOf('changePasswordConfirmNewPasswordValidator') >= 0) { returnValidation = false; }

                        return returnValidation;
                    }).toArray();

                if (validations.length > 0) {
                    vm.modalErrorMessage = validations[0].errorMessage;
                    return;
                } else {
                    vm.modalErrorMessage = '';
                }
            } else {
                vm.modalErrorMessage = '';
                rbacService.changeMyPassword(vm.changePassword.newPassword, vm.changePassword.currentPassword)
                    .then(function (result) {
                        if (result.results[0] === '1') {
                            authenticationService.user.changePasswordNextLogin = false;
                            //have to refresh token info (change virtualcontext with same context)
                            authenticationService.changeVirtualContext(contextService.virtualContext.CurrentVirtualCompanyId).then(function () {
                                // only ok button
                                navigationService.customMessageModal(vm.translate("PasswordUpdatedWithoutError"))
                                    .then(function (result) {
                                        if (result) {
                                            // redirect to home 
                                            navigationService.navToHome();
                                        }
                                    });
                            });
                        }
                        else {
                            if (result.results[0] === '5') {
                                // print custom error when OldPassword = NewPassword 
                                vm.modalErrorMessage = vm.translate('OldPasswordCannotBeEqualNewPassword');
                            } else {
                                vm.modalErrorMessage = vm.translate('UnknownErrorWhileSubmitting');
                            }                            
                        }
                    });
            }
        };
    };

    forceChangeMyPassword.$inject = injectParams;
    forceChangeMyPassword.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rbac.module').controller('forceChangeMyPassword', forceChangeMyPassword);
});