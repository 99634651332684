﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'hrbucketService', 'navigationService', 'checkStateChangeService', 'contextService', 'formSummaryService'];
    var bucketToHierarchiesDetail = function ($q, $scope, $timeout, $injector, foundationService, hrbucketService, navigationService, checkStateChangeService, contextService, formSummaryService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, foundationService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        ribbon.setRibbonStatusButton(ribbon.Buttons.Refresh, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.Modify,false);
        ribbon.setRibbonStatusButton(ribbon.Buttons.Save, false);
        ribbon.refreshStatusButtons();

        // get array items for form summary
        vm.formSummary = formSummaryService.getDatasource();
        
        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var virtualHumanResourceId = navigationService.stateParams().virtualHumanResourceId;

        vm.refreshAction = function () {
            loadData(true);
        };

        if (virtualHumanResourceId) {
            loadData(false);
        }

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('hrhierarchy', 'ftc');
        nbsGridDatacontext.allowPaging = false;
        nbsGridDatacontext.allowSearching = false;
        nbsGridDatacontext.allowGrouping = false;
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getHrHierarchies(forceReload, virtualHumanResourceId, appStoreBw));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: results[0]
                    }
                });
        }

        function getHrHierarchies(forceReload, virtualHumanResourceId, appStoreBw) {
            var filter = { AppStoreBw: appStoreBw, HrBucketId: virtualHumanResourceId };
            var defer = $q.defer();

            hrbucketService.getHrHierarchies(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    bucketToHierarchiesDetail.$inject = injectParams;
    bucketToHierarchiesDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('bucketToHierarchiesDetail', bucketToHierarchiesDetail);
});