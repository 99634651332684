﻿'use strict';
define([], function () {
    var injectParams = ['$injector', '$uibModal', '$timeout'];
    var nbsRibbonComponent = function ($injector, $uibModal, $timeout) {
        var vm = this;
        var pRibbon = null;
        var pRibbonId = '#nembus-ribbon';

        vm.$onChanges = function (changes) {
            if (changes.nbsRibbonDatacontext && changes.nbsRibbonDatacontext.currentValue) {
                pRibbon = changes.nbsRibbonDatacontext.currentValue;
                createRibbon(pRibbon);
            }

            if (changes.nbsRibbonStatusButtons && changes.nbsRibbonStatusButtons.currentValue) {
                if (!pRibbon) {
                    return;
                }

                // actionButtons
                var pButtons = changes.nbsRibbonStatusButtons.currentValue;
                setStatusButtons(pButtons);
            }

            if (changes.nbsViewcontext && changes.nbsViewcontext.currentValue) {
                if (!pRibbon) {
                    return;
                }

                if (pRibbon.viewContextIsLocal === true) {
                    return;
                }

                setButtonsByViewContext(changes.nbsViewcontext.currentValue);
            }
        };

        function setButtonsByViewContext(context) {
            var buttons = [];

            if (context.isAddingState === true) {
                buttons.push({ key: pRibbon.Buttons.Save.Id, isEnable: true });
                buttons.push({ key: pRibbon.Buttons.Save2.Id, isEnable: true });
                buttons.push({ key: pRibbon.Buttons.Modify.Id, isEnable: false });
                buttons.push({ key: pRibbon.Buttons.Modify2.Id, isEnable: false });
            } else if (context.isEditingState === true) {
                buttons.push({ key: pRibbon.Buttons.Save.Id, isEnable: true });
                buttons.push({ key: pRibbon.Buttons.Save2.Id, isEnable: true });
            } else if (context.isViewingState === true) {
                buttons.push({ key: pRibbon.Buttons.Save.Id, isEnable: false });
                buttons.push({ key: pRibbon.Buttons.Save2.Id, isEnable: false });
            }

            // isLocked
            var btnsModify = Lazy($.find(".e-tool_pencil-img")).union($.find(".e-tool_pencil_hot-img")).toArray();
            Lazy(btnsModify)
                .each((btnModify) => {
                    if (context.isEditingState) {
                        $(btnModify).removeClass('e-tool_pencil-img');
                        $(btnModify).addClass('e-tool_pencil_hot-img');
                    } else {
                        $(btnModify).removeClass('e-tool_pencil_hot-img');
                        $(btnModify).addClass('e-tool_pencil-img');
                    }
                });

            setStatusButtons(buttons);
        }

        function setStatusButtons(actionButtons) {
            if (Lazy(actionButtons).some()) {
                Lazy(actionButtons)
                    .each((actionButton) => {
                        var btn = $("#" + 'nembus-ribbon_' + actionButton.key);

                        // enable/disable button
                        if (actionButton.isEnable) {
                            btn.removeClass("e-disable");

                        } else {
                            btn.addClass("e-disable");

                            if (actionButton.key === pRibbon.Buttons.Modify.Id
                                || actionButton.key === pRibbon.Buttons.Modify2.Id) {

                                var eSpanClass = $(btn).find('span.e-btn-span span:first-child');
                                $(eSpanClass).removeClass("e-tool_pencil_hot-img");
                                $(eSpanClass).addClass("e-tool_pencil-img");
                            }
                        }
                    });
            }
        }

        function createRibbon(pRibbon) {
            var defButtonsEnabled = [pRibbon.Buttons.Back, pRibbon.Buttons.Doc, pRibbon.Buttons.Print];

            if (pRibbon.buttonsMode === 'list') {
                defButtonsEnabled = Lazy(defButtonsEnabled)
                    .union([pRibbon.Buttons.New, pRibbon.Buttons.Refresh, pRibbon.Buttons.ExportExcel, pRibbon.Buttons.ExportWord, pRibbon.Buttons.OpenDetail])
                    .toArray();
            } else if (pRibbon.buttonsMode === 'form') {
                defButtonsEnabled = Lazy(defButtonsEnabled)
                    .union([pRibbon.Buttons.Modify, pRibbon.Buttons.Modify2])
                    .toArray();
            } else if (pRibbon.buttonsMode === 'stream') {
                defButtonsEnabled = Lazy(defButtonsEnabled)
                    .union([pRibbon.Buttons.Refresh, pRibbon.Buttons.OpenDetail])
                    .toArray();
            } else if (pRibbon.buttonsMode === 'treeview') {
                defButtonsEnabled = Lazy(defButtonsEnabled)
                    .union([pRibbon.Buttons.Refresh])
                    .toArray();
            } else if (pRibbon.buttonsMode === 'schedule') {
                defButtonsEnabled = Lazy(defButtonsEnabled)
                    .union([pRibbon.Buttons.New, pRibbon.Buttons.Refresh])
                    .toArray();
            } else if (pRibbon.buttonsMode === 'massive') {
                defButtonsEnabled = Lazy(defButtonsEnabled)
                    .union([pRibbon.Buttons.Refresh, pRibbon.Buttons.ExportExcel, pRibbon.Buttons.ExportWord])
                    .toArray();
            } else if (pRibbon.buttonsMode === 'search') {
                defButtonsEnabled = Lazy(defButtonsEnabled)
                    .union([pRibbon.Buttons.Search, pRibbon.Buttons.AddFilter, pRibbon.Buttons.RemoveFilter, pRibbon.Buttons.ResetFilter, pRibbon.Buttons.Refresh, pRibbon.Buttons.ExportExcel, pRibbon.Buttons.ExportWord])
                    .toArray();
            }

            if (pRibbon.hasPrintTab) {
                defButtonsEnabled = Lazy(defButtonsEnabled).union([pRibbon.Buttons.PrintReportPdf, pRibbon.Buttons.PrintReportHtml, pRibbon.Buttons.PrintReport, pRibbon.Buttons.PrintReportWord, pRibbon.Buttons.PrintReportExcel, pRibbon.Buttons.PrintReportXml]).toArray();
            }

            var ejRibbonObjCreator = { tabs: [] };

            if (pRibbon.isHidden) {
                return;
            }

            if (pRibbon.isUnderFTC) {
                var divMainRegion = $(document).find("div[ui-view='mainRegion'");
                if (divMainRegion) {
                    $(window).on('windowResize', function () {
                        $(pRibbonId).width(getRibbonWidth());
                        var ribbon = $(pRibbonId).data('ejRibbon');
                        if (ribbon) {
                            ribbon._ribbonWindowResize();
                        }
                    });

                    //ejRibbonObjCreator.width = divMainRegion.width();
                } else {
                    $(window).on('windowResize', function () { });
                }
            }

            var allButtons = [];
            Lazy(pRibbon.tabs).sortBy((item) => { return item.Order; }, false)
                .each((tab) => {

                    var parsedTab = { id: tab.Id, text: vm.uiContext.translate(tab.Text), groups: [] };

                    Lazy(tab.Sections)
                        .each((section) => {
                            var parsedGroup = {
                                text: vm.uiContext.translate(section.Text),
                                alignType: section.AlignType,
                                content: [],
                            };

                            Lazy(section.Groups)
                                .each((group) => {
                                    var content = { groups: [], defaults: {} }

                                    var height = group.IsBigButton ? group.Height : 23;
                                    content.defaults.height = height;
                                    content.defaults.width = group.Width;
                                    content.defaults.type = ej.Ribbon.Type.Button;
                                    content.defaults.isBig = group.IsBigButton;

                                    Lazy(group.Buttons)
                                        .each((button) => {
                                            var prefixLittleIcon = !group.IsBigButton ? "e-icon e-ribbon" : "";
                                            var prefixIcon = prefixLittleIcon + ' ' + button.PrefixIcon;
                                            var imagePosition = group.IsBigButton ? ej.ImagePosition.ImageTop : ej.ImagePosition.ImageLeft;

                                            var parsedButton = {
                                                id: button.Id,
                                                key: button.Id,
                                                text: vm.uiContext.translate(button.Text),
                                                alignType: button.AlignType,
                                                isEnable: Lazy(defButtonsEnabled).where({ Id: button.Id }).some(),
                                                buttonSettings: {
                                                    contentType: button.ContentType,
                                                    prefixIcon: prefixIcon,
                                                    click: () => { raiseOnAction(button.Action) },
                                                    imagePosition: imagePosition
                                                }
                                            };

                                            content.groups.push(parsedButton);
                                            allButtons.push(parsedButton);
                                        });

                                    parsedGroup.content.push(content);
                                });

                            parsedTab.groups.push(parsedGroup);
                        });

                    ejRibbonObjCreator.tabs.push(parsedTab);
                });

            $(pRibbonId).ejRibbon(ejRibbonObjCreator);

            if (pRibbon.tabIndexSelected > 0) {
                var dataRibbon = $(pRibbonId).data("ejRibbon");
                if (dataRibbon.model.tabs.length > 0) {
                    dataRibbon.option({ selectedItemIndex: pRibbon.tabIndexSelected });
                }
            }

            setStatusButtons(allButtons);
        }

        function raiseOnAction(method) {
            if (method === 'deleteAction') {
                // DELETE action
                $uibModal = $uibModal || $injector.get('$uibModal');
                var modalHtml = '<div class="modal-header" draggable><h3 class="modal-title">' + $TG('ModalWarning') + '</h3></div><div class="modal-body" > ' + $TG("DoYouWishDeleteItem") + ' ?</div ><div class="modal-footer"><button class="btn btn-primary" ng-click="ok()">' + $TG('ModalButtonOk') + '</button><button class="btn" ng-click="cancel()">' + $TG('ModalButtonCancel') + '</button></div>';

                var ModalInstanceCtrl = function ($scope, $uibModalInstance) {
                    $scope.ok = () => { $uibModalInstance.close(); };
                    $scope.cancel = () => { $uibModalInstance.dismiss(); };
                };
                var modalInstance = $uibModal.open({ template: modalHtml, controller: ModalInstanceCtrl, backdrop: 'static' });
                modalInstance.result.then(() => {
                    vm.nbsOnAction({ args: { method: method } });
                }, (error) => { });
            } else {
                if (method === 'modifyAction') {
                    if (pRibbon.viewContextIsLocal) {
                        refreshButtonsLocally();
                    }
                }

                vm.nbsOnAction({ args: { method: method } });
            }
        }

        function refreshButtonsLocally() {
            // context generally managed by view context are Modify, Modify2, Save
            var btn = $("#" + 'nembus-ribbon_' + pRibbon.Buttons.Modify.Id);
            var buttons = [];
            var eSpanClass = $(btn).find('span.e-btn-span span:first-child');
            if ($(btn).find('.e-tool_pencil_hot-img').length > 0) {
                $(eSpanClass).removeClass("e-tool_pencil_hot-img");
                $(eSpanClass).addClass("e-tool_pencil-img");

                buttons.push({ key: pRibbon.Buttons.Save.Id, isEnable: false });
            } else {
                $(eSpanClass).removeClass("e-tool_pencil-img");
                $(eSpanClass).addClass("e-tool_pencil_hot-img");

                buttons.push({ key: pRibbon.Buttons.Save.Id, isEnable: true });
            }

            setStatusButtons(buttons);
        }
    };

    nbsRibbonComponent.$inject = injectParams;
    angular.module('nbs.rds.module').component('nbsRibbon', {
        templateUrl: '/rds/common/components/nbsRibbonComponent/_?c=' + window.codeCache(),
        controller: nbsRibbonComponent,
        controllerAs: 'vm',
        bindings: {
            nbsRibbonDatacontext: '<',
            nbsRibbonStatusButtons: '<',
            nbsViewcontext: '<',
            uiContext: "<",

            nbsOnAction: '&',
        }
    });
});

