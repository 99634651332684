﻿'use strict';
define([], function () {

    var injectParams = ['$injector', 'contextService'];

    function fileTransferService($injector, contextService) {
        var $http;
        var service = {
            downloadRemoteFileHiddenByPath: downloadRemoteFileHiddenByPath,
            downloadRemoteFileHiddenByGuid: downloadRemoteFileHiddenByGuid,
            downloadCmsClientDataFile: downloadCmsClientDataFile,
            downloadRemoteFileByPath: downloadRemoteFileByPath,
            downloadRemoteFile: downloadRemoteFile,
            downloadRemoteFileByGuid: downloadRemoteFileByGuid,
            downloadRemoteTemporary: downloadRemoteTemporary,
            uriByPath: uriByPath,
            uriByGuid: uriByGuid,
            uriUpload: uriUpload,
            uriTemporaryByFileName: uriTemporaryByFileName,
            temporaryFileNameByFileId: temporaryFileNameByFileId
        };
        return service;

        //cms data file (thesaurus etc..)
        function downloadCmsClientDataFile() {
            return downloadFileHidden(composeDownloadDataForCms());
        }

        //hidden by path
        function downloadRemoteFileHiddenByPath(downloadData) {
            return downloadFileHidden(composeDownloadDataForFileByPath(downloadData));
        }

        function downloadRemoteFileHiddenByGuid(downloadData) {
            return downloadFileHidden(composeDownloadDataForFileByGuid(downloadData));
        }

        //temporary by filename
        function downloadRemoteTemporary(downloadData) {
            if (downloadData.asAttach) {
                downloadFile(composeDownloadDataForFileTemporary(downloadData), true);
            }
            else {
                downloadFile(composeDownloadDataForFileTemporary(downloadData), false);
            }
        }

        //inline/asattach by path
        function downloadRemoteFileByPath(downloadData) {
            if (downloadData.asAttach) {
                downloadFile(composeDownloadDataForFileByPath(downloadData), true);
            }
            else {
                downloadFile(composeDownloadDataForFileByPath(downloadData), false);
            }
        }

        function downloadRemoteFile(downloadData) {
            if (downloadData.downloadType === "byGuid") {
                downloadRemoteFileByGuid(downloadData, downloadData.asAttach);
            }
            else if (downloadData.downloadType === "byPath") {
                downloadRemoteFileByPath(downloadData, downloadData.asAttach);
            }
        }

        //inline/asattach by Guid
        function downloadRemoteFileByGuid(downloadData) {
            if (downloadData.asAttach) {
                downloadFile(composeDownloadDataForFileByGuid(downloadData), true);
            }
            else {
                downloadFile(composeDownloadDataForFileByGuid(downloadData), false);
            }
        }

        function uriByPath(downloadData) {
            return '/FileDownload.ashx?d=' + b64EncodeUnicode(composeDownloadDataForFileByPath(downloadData) + '&FilesTransferType=Inline');
        }
        function uriByGuid(downloadData) {
            return '/FileDownload.ashx?d=' + b64EncodeUnicode(composeDownloadDataForFileByGuid(downloadData) + '&FilesTransferType=Inline');
        }

        function uriTemporaryByFileName(downloadData) {
            return '/FileDownload.ashx?d=' + b64EncodeUnicode(composeDownloadDataForFileTemporary(downloadData) + '&FilesTransferType=Inline');
        }

        function uriUpload(uploadData) {
            //AppUserId = appUserId;
            //CustomerCode = customerCode;
            //FilesRepositoryServiceType = FilesRepositoryServiceType.TemporaryFiles;
            //TemporaryFileName = temporaryFileName;
            var uploadStr = 'CustomerCode=' + contextService.virtualContext.CurrentCustomerCode
                + '&CustomerAppCode=' + contextService.virtualContext.CurrentCustomerAppCode
                + '&UserGuid=' + contextService.virtualContext.UserGuid
                + '&FilesRepositoryServiceType=TemporaryFiles';

            //*****
            uploadStr = uploadStr + '&TemporaryFileName=tmp.jpg';


            if (uploadData && uploadData.temporaryFileName) {
                uploadStr = uploadStr + '&TemporaryFileName=' + uploadData.temporaryFileName;
            }
            if (uploadData && uploadData.resize) {
                uploadStr = uploadStr + '&Resize=' + uploadData.resize.width + 'x' + uploadData.resize.height;
            }
            return '/FileUpload.ashx?u=' + b64EncodeUnicode(uploadStr);
        }
        function downloadFileHidden(querystring) {
            $http = $http || $injector.get('$http');
            return $http.get('/FileDownload.ashx?d=' + b64EncodeUnicode(querystring + '&FilesTransferType=Hidden'));
        }

        function downloadFile(querystring, asAttach) {
            if (asAttach) {
                return window.location.assign('/FileDownload.ashx?d=' + b64EncodeUnicode(querystring + '&FilesTransferType=AsAttach'));
            }
            else {
                return window.open('/FileDownload.ashx?d=' + b64EncodeUnicode(querystring + '&FilesTransferType=Inline', '', 'resizable,scrollbars,status'));
            }
        }

        function composeDownloadDataForCms() {

            return 'CustomerCode=' + contextService.virtualContext.CurrentCustomerCode
                + '&CustomerAppCode=' + contextService.virtualContext.CurrentCustomerAppCode
                + '&UserGuid=' + contextService.virtualContext.UserGuid
                + '&FilesRepositoryServiceType=CmsClientData'
                + '&ts=' + new Date().getTime();
        }

        function composeDownloadDataForFileByPath(downloadData) {
            var imgW = downloadData.imageMaxWidth ? downloadData.imageMaxWidth + '' : '';
            var imgH = downloadData.ImageMaxHeight ? downloadData.imageMaxHeight + '' : '';
            return 'CustomerCode=' + contextService.virtualContext.CurrentCustomerCode
                + '&CustomerAppCode=' + contextService.virtualContext.CurrentCustomerAppCode
                + '&UserGuid=' + contextService.virtualContext.UserGuid
                + '&FilesRepositoryServiceType=FilesWithPath'
                + '&FileNameFullMetaPath=' + downloadData.filePath
                + '&DisplayFileName=' + downloadData.displayFileName
                + '&ImageMaxWidth=' + imgW
                + '&ImageMaxHeight=' + imgH
                + '&ts=' + new Date().getTime();
        }

        function composeDownloadDataForFileByGuid(downloadData) {
            var imgW = downloadData.imageMaxWidth ? downloadData.imageMaxWidth + '' : '';
            var imgH = downloadData.ImageMaxHeight ? downloadData.imageMaxHeight + '' : '';
            return 'CustomerCode=' + contextService.virtualContext.CurrentCustomerCode
                + '&CustomerAppCode=' + contextService.virtualContext.CurrentCustomerAppCode
                + '&UserGuid=' + contextService.virtualContext.UserGuid
                + '&FilesRepositoryServiceType=FilesRepository'
                + '&EntityGuid=' + downloadData.entityGuid
                + '&ImageMaxWidth=' + imgW
                + '&ImageMaxHeight=' + imgH
                + '&ts=' + new Date().getTime();
        }

        function composeDownloadDataForFileTemporary(downloadData) {
            var fName = '';
            if (downloadData.temporaryFileName) {
                fName = downloadData.temporaryFileName;
            }
            else {
                fName = temporaryFileNameByFileId(downloadData);
            }


            return 'CustomerCode=' + contextService.virtualContext.CurrentCustomerCode
                + '&CustomerAppCode=' + contextService.virtualContext.CurrentCustomerAppCode
                + '&UserGuid=' + contextService.virtualContext.UserGuid
                + '&FilesRepositoryServiceType=TemporaryFiles'
                + '&TemporaryFileName=' + fName;
        }

        function temporaryFileNameByFileId(downloadData) {
            return downloadData.fileId + downloadData.displayFileName.substring(downloadData.displayFileName.lastIndexOf('.'), downloadData.displayFileName.length);
        }

        function b64EncodeUnicode(str) {
            return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
                return String.fromCharCode('0x' + p1);
            }));
        }

    }

    fileTransferService.$inject = injectParams;
    angular.module('nbs.shared.module').service('fileTransferService', fileTransferService);

});