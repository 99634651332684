﻿'use strict';
define([], function () {

    var injectParams = [];
    var nbsWzBeardCrumbComponent = function () {

        var vm = this;
        vm.datacontext = [];

        vm.$onChanges = function (changes) {
            if (changes.nbsDatacontext && changes.nbsDatacontext.currentValue) {
                vm.datacontext = changes.nbsDatacontext.currentValue;
            }            
        };
    };

    nbsWzBeardCrumbComponent.$inject = injectParams;
    angular.module('nbs.rds.module').component('nbsWzBeardCrumb', {
        templateUrl: '/rds/common/components/nbsWzBeardCrumbComponent/_?c=' + window.codeCache(),
        controller: nbsWzBeardCrumbComponent,
        controllerAs: 'vm',
        bindings: {
            nbsDatacontext: "<",
            uiContext: "<"
        }
    });
});

