﻿'use strict';
define([], function () {

    var injectParams = ['$scope', '$http', '$state', '$timeout', '$location', '$templateCache', '$window'];
    function emptyPage($scope, $http, $state, $timeout, $location, $templateCache, $window) {

        var vm = this;

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext);
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

    };
    emptyPage.$inject = injectParams;
    angular.module('app').controller('emptyPage', emptyPage);


});