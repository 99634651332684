﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {
    /// R-245-120-1
    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'workflowService', 'audService', 'actService', 'drbacService', 'reportService', 'navigationService', 'checkStateChangeService', 'contextService', 'utilService'];
    var htmlToPdfSurveyDetailReportForm = function ($q, $scope, $timeout, $injector, foundationService, workflowService, audService, actService, drbacService, reportService, navigationService, checkStateChangeService, contextService, utilService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        var checkStateChangeParams = { dirtyFlagIsDisabled: vm.isDirtyDisabled };
        checkStateChangeService.checkFormOnStateChange($scope, foundationService, checkStateChangeParams);

        // all form items are enabled
        vm.setIsEditingReport();

        // entity to validate
        vm.surveyReportFormClientData = audService.dataContext.surveyReportFormClientDatas.add({
            Id: NbsGuidManager.newGuid()
        });

        var appStoreCode = 'aud';
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
        var surveyLifeStatusEnumObj = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyLifeStatus, null, vm.translate).obj.normal.name_value;
        var workflowStatusKindEnumParsed = Nembus.Common.Library.util.parseEnum(workflowService.dataContext.enumTypes.WorkflowStatusKind, 'WorkflowStatus', vm.translate);
        var workflowStatusKindEnumObj = workflowStatusKindEnumParsed.obj.normal.name_value;
        var workflowStatusKindEnumObjTranslated = workflowStatusKindEnumParsed.obj.translated.value_name;
        var actPriorityEnumParsed = Nembus.Common.Library.util.parseEnum(actService.dataContext.enumTypes.ActPriority, 'Act', vm.translate);
        var priorityObjTranslated = actPriorityEnumParsed.obj.translated.value_name;
        var priorityObj = Nembus.Common.Library.util.parseEnum(actService.dataContext.enumTypes.ActPriority, null, vm.translate).obj.normal.name_value;
        var addEmptyActionAssignment = false;
        var stepsToRender = [];

        // REFERS to 'reportDefinitionHtmlToDocDetail'
        vm.parentScope = $scope.$parent.vm;
        vm.parentScope.ribbonDatacontext.setRibbonStatusButton(vm.parentScope.ribbonDatacontext.Buttons.PrintReportPdf, true);
        vm.parentScope.ribbonDatacontext.refreshStatusButtons();
        vm.parentScope.initReportToDocHeader({ onSearchActionHtmlToDocFn: null, onCreateHtmlFn: createHtmlReport });

        var survey = {};
        var process = {};
        var actions = [];

        //#region Multiselect with grid selection
        var selectionParams = { stateTo: 'app.nav.surveyslistmodal', destModalKey: 'survey', isMultiselect: false, dataKey: 'surveycompleted', filter: { VhrEventLifeStatusIds: [surveyLifeStatusEnumObj.ReportedAsFinished] } };
        vm.nbsSelectionGridWithModalSurvey = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalSurvey.setOnSelectFnCallback(function () {
            if (vm.nbsSelectionGridWithModalSurvey.selectedIds) {
                vm.surveyReportFormClientData.SurveyId = vm.nbsSelectionGridWithModalSurvey.selectedIds[0];
            } else {
                vm.surveyReportFormClientData.SurveyId = null;
            }
        });

        // set title of current controller in report definition detail view
        navigationService.setControllerTitle();

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getGenericElementsActPrioritysColor(forceReload));
            funcsPromises.push(getGenericElementsWorkflowStatusColor(forceReload));

            $q.all(funcsPromises)
                .then(function () {
                });
        }

        vm.htmlSteps = {
            ACTION_GROUPED: 'actiongrouped-render',
            ACTION_ASSIGNMENT: 'actionassignment-render',
            AUDITORS_USERGROUP: 'auditorsusergroup-render',
            AUDITORS_USER: 'auditorsuser-render'
        };
        function createHtmlReport() {
            vm.surveyReportFormClientData.entityAspect.validateEntity();
            if (vm.surveyReportFormClientData.entityAspect.hasValidationErrors) {
                $timeout(() => { /* implicit scope apply; */ });
                return;
            }

            var forceReload = true;

            survey = vm.nbsSelectionGridWithModalSurvey.selectedItems[0].item;
            survey.SurveyExpireDateFormatted = Nembus.Common.Library.converter.date.formatDate(survey.SurveyExpireDate, contextService.virtualContext.cultureInfo, true);
            survey.SurveyCompileDateFormatted = Nembus.Common.Library.converter.date.formatDateTime(survey.SurveyCompileDateSystem, contextService.virtualContext.cultureInfo, true);

            var funcsPromises = [];
            funcsPromises.push(searchActDtos(forceReload, survey.SurveyId));
            funcsPromises.push(getSurveyVhrEventM2MAppUserDto(forceReload, survey.VhrEventId, survey.ModuleTargetId));
            funcsPromises.push(getSurveyVhrEventM2MDrbacUserGroupDto(forceReload, survey.VhrEventId, survey.ModuleTargetId));

            $q.all(funcsPromises)
                .then(function () {
                    getProcessForEvent()
                        .then(function (data) {
                            parseReportHtmlDatasource(survey, actions);
                        });
                });
        }

        // prepare datasource for report in UI (to prepare Html)
        function parseReportHtmlDatasource(survey, actions) {
            stepsToRender = [];
            var countActions = actions.length;
            var assignmentTypes = {
                User: 'User',
                UserGroup: 'UserGroup',
                NoAssignment: 'NoAssignment'
            };

            // object binded on UI
            vm.bindObject = {
                survey: survey,
                auditors: {
                    userGroups: [],
                    users: []
                },
                process: process,
                workflow: {
                    toExecutePercent: 0,
                    executedPercent: 0,
                    toExecuteTranslation: workflowStatusKindEnumObjTranslated[workflowStatusKindEnumObj.ToExecute],
                    executedTranslation: workflowStatusKindEnumObjTranslated[workflowStatusKindEnumObj.Executed]
                },
                priority: {
                    veryHigh: 0,
                    high: 0,
                    medium: 0,
                    low: 0,
                    veryHighTranslation: priorityObjTranslated[priorityObj.VeryHigh],
                    highTranslation: priorityObjTranslated[priorityObj.High],
                    mediumTranslation: priorityObjTranslated[priorityObj.Medium],
                    lowTranslation: priorityObjTranslated[priorityObj.Low]
                },
                actions: {
                    assignments: [],
                    grouped: []
                }
            };

            //user groups auditors
            var allUserGroups = [];
            Lazy(vm.surveyVhrEventM2MDrbacUserGroups)
                .each((userGroupM2M) => {
                    allUserGroups.push({
                        Value: userGroupM2M.DrbacUserGroupId,
                        Name: userGroupM2M.DrbacUserGroupName
                    });
                });


            //user auditors
            var allUsers = [];
            Lazy(vm.surveyVhrEventM2MAppUsers)
                .each((appUserM2M) => {
                    allUsers.push({
                        Value: appUserM2M.AppUserId,
                        Name: appUserM2M.DisplayName
                    });
                });

            // workflow status
            vm.bindObject.workflow.toExecuteCount = Lazy(actions).where({ ActWorkflowStatusKindId: workflowStatusKindEnumObj.ToExecute }).toArray().length;
            vm.bindObject.workflow.executedCount = Lazy(actions).where({ ActWorkflowStatusKindId: workflowStatusKindEnumObj.Executed }).toArray().length;
            vm.bindObject.workflow.toExecutePercent = Nembus.Common.Library.util.getPercent(vm.bindObject.workflow.toExecuteCount, countActions);
            vm.bindObject.workflow.executedPercent = Nembus.Common.Library.util.getPercent(vm.bindObject.workflow.executedCount, countActions);

            // priority
            vm.bindObject.priority.veryHighCount = Lazy(actions).where({ ActPriorityId: priorityObj.VeryHigh }).toArray().length;
            vm.bindObject.priority.highCount = Lazy(actions).where({ ActPriorityId: priorityObj.High }).toArray().length;
            vm.bindObject.priority.mediumCount = Lazy(actions).where({ ActPriorityId: priorityObj.Medium }).toArray().length;
            vm.bindObject.priority.lowCount = Lazy(actions).where({ ActPriorityId: priorityObj.Low }).toArray().length;
            vm.bindObject.priority.veryHighPercent = Nembus.Common.Library.util.getPercent(vm.bindObject.priority.veryHighCount, countActions);
            vm.bindObject.priority.highPercent = Nembus.Common.Library.util.getPercent(vm.bindObject.priority.highCount, countActions);
            vm.bindObject.priority.mediumPercent = Nembus.Common.Library.util.getPercent(vm.bindObject.priority.mediumCount, countActions);
            vm.bindObject.priority.lowPercent = Nembus.Common.Library.util.getPercent(vm.bindObject.priority.lowCount, countActions);

            // assignemnt actions
            var allAssignmentActions = [];
            Lazy(actions)
                .each((action) => {
                    var userGroups = JSON.parse(Nembus.Common.Library.converter.decodeHtml(action.UserGroupsAssignedSerialized));
                    var users = JSON.parse(Nembus.Common.Library.converter.decodeHtml(action.UsersAssignedSerialized));

                    if (Lazy(userGroups).some() || Lazy(users).some()) {
                        Lazy(userGroups)
                            .each((userGroup) => {
                                userGroup.Kind = assignmentTypes.UserGroup;

                                var existingUserGroup = Lazy(allAssignmentActions).findWhere({ Value: userGroup.Value, Kind: userGroup.Kind });
                                if (!existingUserGroup) {
                                    userGroup.Count = 1;
                                    userGroup.Percent = Nembus.Common.Library.util.getPercent(userGroup.Count, countActions);

                                    allAssignmentActions.push(userGroup);
                                } else {
                                    // no insert , update
                                    existingUserGroup.Count += 1;
                                    existingUserGroup.Percent = Nembus.Common.Library.util.getPercent(existingUserGroup.Count, countActions);
                                }
                            });

                        Lazy(users)
                            .each((user) => {
                                user.Kind = assignmentTypes.User;

                                var existingUser = Lazy(allAssignmentActions).findWhere({ Value: user.Value, Kind: user.Kind });
                                if (!existingUser) {
                                    user.Count = 1;
                                    user.Percent = Nembus.Common.Library.util.getPercent(user.Count, countActions);

                                    allAssignmentActions.push(user);
                                } else {
                                    // no insert , update
                                    existingUser.Count += 1;
                                    existingUser.Percent = Nembus.Common.Library.util.getPercent(existingUser.Count, countActions);
                                }
                            });
                    } else {
                        if (addEmptyActionAssignment) {
                            var existingNoAssigned = Lazy(allAssignmentActions).findWhere({ Kind: assignmentTypes.NoAssignment });
                            if (!existingNoAssigned) {
                                var noAssigned = {
                                    Name: ' - ',
                                    Kind: assignmentTypes.NoAssignment,
                                    Count: 1,
                                    Percent: Nembus.Common.Library.util.getPercent(1, countActions)
                                };

                                allAssignmentActions.push(noAssigned);
                            } else {
                                // no insert , update
                                existingNoAssigned.Count += 1;
                                existingNoAssigned.Percent = Nembus.Common.Library.util.getPercent(existingNoAssigned.Count, countActions);
                            }
                        }
                    }
                });

            // grouped actions
            var allGroupedActions = [];
            var tempActionsForGrouping = [];
            Lazy(actions)
                .each((action) => {
                    var actionCategories = JSON.parse(Nembus.Common.Library.converter.decodeHtml(action.CategoriesSerialized));
                    if (Lazy(actionCategories).some()) {
                        Lazy(actionCategories)
                            .each((actionCategory) => {
                                tempActionsForGrouping.push({
                                    ActWorkflowStatusKindId: action.ActWorkflowStatusKindId,
                                    CategoryIdForGrouping: actionCategory.Value,
                                    CategoryNameForGrouping: actionCategory.Name
                                });
                            });
                    } else {
                        action.CategoryIdForGrouping = 0;
                        action.CategoryNameForGrouping = '-';
                        tempActionsForGrouping.push({
                            ActWorkflowStatusKindId: null,
                            CategoryIdForGrouping: 0,
                            CategoryNameForGrouping: '-'
                        });
                    }
                });

            var actionGrouped = Lazy(tempActionsForGrouping).groupBy('CategoryIdForGrouping').toArray();
            Lazy(actionGrouped)
                .each((item) => {
                    var groupedAction = {
                        Name: Lazy(item[1]).first().CategoryNameForGrouping,
                        Count: item[1].length,
                        Percent: Nembus.Common.Library.util.getPercent(item[1].length, countActions)
                    };

                    allGroupedActions.push(groupedAction);
                });

            // bind to UI
            if (allGroupedActions.length > 0) { stepsToRender.push(vm.htmlSteps.ACTION_GROUPED); }
            if (allAssignmentActions.length > 0) { stepsToRender.push(vm.htmlSteps.ACTION_ASSIGNMENT); }
            if (allUserGroups.length > 0) { stepsToRender.push(vm.htmlSteps.AUDITORS_USERGROUP); }
            if (allUsers.length > 0) { stepsToRender.push(vm.htmlSteps.AUDITORS_USER); }

            vm.bindObject.actions.grouped = Lazy(allGroupedActions).sortBy((el) => { return el.Count; }, true).toArray();
            vm.bindObject.actions.assignments = Lazy(allAssignmentActions).sortBy((el) => { return el.Count; }, true).toArray();
            vm.bindObject.auditors.userGroups = Lazy(allUserGroups).sortBy((el) => { return el.Name; }, false).toArray();
            vm.bindObject.auditors.users = Lazy(allUsers).sortBy((el) => { return el.Name; }, false).toArray();

            // set steps to render before html retrieve from directive
            vm.parentScope.htmlToDocManager.setHtmlStepsToRender(stepsToRender);

            if (!vm.parentScope.htmlToDocManager.hasHtmlStepsToRender()) {
                vm.parentScope.htmlToDocManager.htmlIsRendered();
            }
        }

        function getProcessForEvent(forceReload) {
            var filter = {
                ModuleTargetId: moduleTargetId,
                VhrEventTypeId: survey.VhrEventTypeId,
                VhrEventGroupId: survey.VhrEventGroupId,
                VirtualCompanyId: virtualCompanyId
            };
            var defer = $q.defer();

            // current process
            drbacService.getProcessForEventDto(filter, forceReload)
                .then(function (data) {
                    process = data.results[0];
                    defer.resolve(process);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function searchActDtos(forceReload, surveyId) {
            var filter = { // FilterAudActClientData
                SurveyActionFilter: {
                    SurveyId: surveyId
                }
            };
            var defer = $q.defer();

            audService.searchActDtos(filter, forceReload)
                .then(function (data) {
                    actions = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getSurveyVhrEventM2MAppUserDto(forceReload, vhrEventId, moduleTargetId) {
            var filter = {
                VhrEventId: vhrEventId,
                ModuleTargetId: moduleTargetId
            };
            var defer = $q.defer();

            audService.getSurveyVhrEventM2MAppUserDto(filter, forceReload)
                .then(function (data) {
                    vm.surveyVhrEventM2MAppUsers = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getSurveyVhrEventM2MDrbacUserGroupDto(forceReload, vhrEventId, moduleTargetId) {
            var filter = {
                VhrEventId: vhrEventId,
                ModuleTargetId: moduleTargetId
            };
            var defer = $q.defer();

            audService.getSurveyVhrEventM2MDrbacUserGroupDto(filter, forceReload)
                .then(function (data) {
                    vm.surveyVhrEventM2MDrbacUserGroups = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getGenericElementsActPrioritysColor(forceReload) {
            var filter = { GenericElementCode: 'Configurations.Acts.PriorityColors' };
            var defer = $q.defer();

            utilService.getGenericElements(filter, forceReload)
                .then(function (data) {
                    if (!data.results[0]) {
                        vm.actPriorityColors = [];
                    }
                    else {
                        vm.actPriorityColors = data.results[0].PairValuesArray;
                        vm.actPriorityColorsObj = data.results[0].PairValuesObject;
                    }

                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
        function getGenericElementsWorkflowStatusColor(forceReload) {
            var filter = { GenericElementCode: 'Configurations.Workflows.LifeStatusColors' };
            var defer = $q.defer();

            utilService.getGenericElements(filter, forceReload)
                .then(function (data) {
                    if (!data.results[0]) {
                        vm.workflowStatusColors = [];
                    }
                    else {
                        vm.workflowStatusColors = data.results[0].PairValuesArray;
                        vm.workflowStatusColorsObj = data.results[0].PairValuesObject;
                    }

                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    htmlToPdfSurveyDetailReportForm.$inject = injectParams;
    htmlToPdfSurveyDetailReportForm.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('htmlToPdfSurveyDetailReportForm', htmlToPdfSurveyDetailReportForm);
});
