﻿
'use strict';

define([], function () {

    var injectParams = ['$q', '$location', '$timeout', '$injector'];

    function nbsStorageService($q, $location, $timeout, $injector) {
        //https://developers.google.com/web/ilt/pwa/working-with-indexeddb
        var that = this;
        that.ver = 23;
        that.dbNameCms = 'nbs-cms';
        that.dbNameUi = 'nbs-ui';
  
        function verifyUiDataDb(){
            var defer = $q.defer();
            let dbUiData = idb.open(that.dbNameUi, that.ver, 
                //upgrade function
                upgradeDB => {
                    if (upgradeDB.oldVersion > 0 && upgradeDB.oldVersion != that.ver) {
                        //have to delete old database
                        idb.delete(that.dbNameUi)
                            .then(res => {
                                var store = upgradeDB.createObjectStore('uidata', { keyPath: "uiDataId" });
                                store.createIndex("uiDataCode", "uiDataCode", { unique: false });

                                var storepreference = upgradeDB.createObjectStore('uidatapreference', { keyPath: "uiDataCode" });
                            });
                    }
                    if (upgradeDB.oldVersion == 0) {
                        if (!upgradeDB.objectStoreNames.contains('uidata')) {
                            var store = upgradeDB.createObjectStore('uidata', { keyPath: "uiDataId" });
                            store.createIndex("uiDataCode", "uiDataCode", { unique: false });
                        }
                        if (!upgradeDB.objectStoreNames.contains('uidatapreference')) {
                            var storepreference = upgradeDB.createObjectStore('uidatapreference', { keyPath: "uiDataCode" });
                        }
                    }
                }
            )
            .then(successDb => {
                var xx = successDb;
                if (!successDb.objectStoreNames.contains('uidata') || !successDb.objectStoreNames.contains('uidatapreference')) {
                    idb.delete(that.dbNameUi)
                        .then(res => {
                            var store = upgradeDB.createObjectStore('uidata', { keyPath: "uiDataId" });
                            store.createIndex("uiDataCode", "uiDataCode", { unique: false });

                            var storepreference = upgradeDB.createObjectStore('uidatapreference', { keyPath: "uiDataCode" });
                        });
                }
                defer.resolve(successDb);
            })
            .catch(reason => {
                var error = reason;
                idb.delete(that.dbNameUi)
                    .then(res => {
                        var store = upgradeDB.createObjectStore('uidata', { keyPath: "uiDataId" });
                        store.createIndex("uiDataCode", "uiDataCode", { unique: false });

                        var storepreference = upgradeDB.createObjectStore('uidatapreference', { keyPath: "uiDataCode" });
                        defer.reject(error);
                    });
            }); 
            return defer.promise;
        }

        function verifyCmsDb(){
            var defer = $q.defer();
            let dbUiData = idb.open(that.dbNameCms, that.ver, 
                //upgrade function
                upgradeDB => {
                    if (upgradeDB.oldVersion > 0 && upgradeDB.oldVersion != that.ver) {
                        //have to delete old database
                        idb.delete(that.dbNameCms)
							.then(res => {
							    if (!upgradeDB.objectStoreNames.contains('ctx')) {
							        let store = upgradeDB.createObjectStore('ctx', { keyPath: 'code' });
							    }
							});
                    }
                    if (upgradeDB.oldVersion == 0) {
                        if (!upgradeDB.objectStoreNames.contains('ctx')) {
                            let store = upgradeDB.createObjectStore('ctx', { keyPath: 'code' });
                        }
                    }
                }
            )
            .then(successDb => {
                var xx = successDb;
                if (!successDb.objectStoreNames.contains('ctx')) {
                    idb.delete(that.dbNameCms)
                        .then(res => {
                            let store = upgradeDB.createObjectStore('ctx', { keyPath: 'code' });
                        });
                }
                defer.resolve(successDb);
            })
            .catch(reason => {
                var error = reason;
                idb.delete(that.dbNameCms)
                    .then(res => {
                        let store = upgradeDB.createObjectStore('ctx', { keyPath: 'code' });
                    });
            }); 
            return defer.promise;
        }

        async function putContextData(objKey, value) {
            if (!objKey.vc) {
                objKey.vc = 'x';
            }
            if (!objKey.lang) {
                objKey.lang = 'l';
            }
            var code = objKey.datacode + '-' + objKey.usercode + '-' + objKey.lang + '-' + objKey.vc;
            let db = await verifyCmsDb();
            let tx = db.transaction('ctx', 'readwrite');
            let store = tx.objectStore('ctx');
            await store.put({
                code: code,
                cacheCode: objKey.cacheCode,
                appver: objKey.appver,
                value: value
            });
            await tx.complete;
            db.close();
        }

        async function getContextData(objKey) {
            if (!objKey.vc) {
                objKey.vc = 'x';
            }
            if (!objKey.lang) {
                objKey.lang = 'l';
            }
            var code = objKey.datacode + '-' + objKey.usercode + '-' + objKey.lang + '-' + objKey.vc;
            let db = await verifyCmsDb();
            let tx = db.transaction('ctx', 'readonly');
            let store = tx.objectStore('ctx');

            // add, clear, count, delete, get, getAll, getAllKeys, getKey, put
            let item = await store.get(code);
            if (objKey.datacode !== 'clientcontext') {
                if (item && item.cacheCode !== objKey.cacheCode) {
                    item = null;
                }
            }
            //console.log(allSavedItems)
            db.close();
            return item;
        }

        async function putUiData(data) {
            //let db = await verifyUiDataDb();
            let db = await verifyUiDataDb();

            let tx = db.transaction('uidata', 'readwrite');
            let store = tx.objectStore('uidata');

            //var item = {};
            if (!data.uiDataId) {
                data.uiDataId = NbsGuidManager.newGuid();
            }
            //else{
            //}

            let item = await store.put(data);
            await tx.complete;
            db.close();
            return item;
        }

        async function getUiDataAll(code) {
            let db = await verifyUiDataDb();

            let tx = db.transaction('uidata', 'readwrite');
            let store = tx.objectStore('uidata');
            var index = store.index("uiDataCode");
            var keyRng = IDBKeyRange.only(code);

            let result = await index.getAll(keyRng);
            await tx.complete;
            db.close();
            return result;
        }

        async function getUiDataSingle(uiDataId) {
            let db = await verifyUiDataDb();

            let tx = db.transaction('uidata', 'readwrite');
            let store = tx.objectStore('uidata');

            let result = await store.get(uiDataId);
            await tx.complete;
            db.close();
            return result;
        }

        async function deleteUiData(data) {
            let db = await verifyUiDataDb();

            let tx = db.transaction('uidata', 'readwrite');
            let store = tx.objectStore('uidata');

            let result = await store.delete(data.uiDataId);
            await tx.complete;
            db.close();
            return result;
        }

        //{
        // uiDataId:'',
        // code:'' //uidata.code
        //}
        async function putUiDataPreference(data) {
            let db = await verifyUiDataDb();

            let tx = db.transaction('uidatapreference', 'readwrite');
            let store = tx.objectStore('uidatapreference');

            //var item = {};
            //if(data.uiDataCode){
            let item = await store.put(data);
            //}
            //else{
            //guid have to exists!!!!

            //data.uiDataId= NbsGuidManager.newGuid();
            //item = await store.put(data);
            //}
            await tx.complete;
            db.close();
            return item;
        }

        async function getUiDataPreference(uiDataCode) {
            let db = await verifyUiDataDb();

            let tx = db.transaction('uidatapreference', 'readwrite');
            let store = tx.objectStore('uidatapreference');

            let result = await store.get(uiDataCode);
            await tx.complete;
            db.close();
            return result;
        }

        async function deleteUiDataPreference(data) {
            let db = await verifyUiDataDb();

            let tx = db.transaction('uidatapreference', 'readwrite');
            let store = tx.objectStore('uidatapreference');

            let result = await store.delete(data.uiDataCode);
            await tx.complete;
            db.close();
            return result;
        }

        var service = {
            getContextData: getContextData,
            putContextData: putContextData,
            putUiData: putUiData,
            getUiDataAll: getUiDataAll,
            getUiDataSingle: getUiDataSingle,
            deleteUiData: deleteUiData,
            putUiDataPreference: putUiDataPreference,
            getUiDataPreference: getUiDataPreference,
            deleteUiDataPreference: deleteUiDataPreference
        };
        return service;
    }

    nbsStorageService.$inject = injectParams;

    angular.module('nbs.shared.module').service('nbsStorageService', nbsStorageService);

});
