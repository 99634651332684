﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$injector', '$scope', '$timeout', 'notificationService', 'rbacService', 'drbacService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function notificationInstanceDetail($q, $injector, $scope, $timeout, notificationService, rbacService, drbacService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, notificationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, notificationService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        ribbon.addTab(new ribbon.Tab("ActionPanelTabManage", [ribbon.GetPlaceholderSection()]));
        vm.ribbonDatacontext = ribbon;

        var notificationInstanceId = navigationService.stateParams().notificationInstanceId;
        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;

        vm.setIsViewing();
        loadData(true);

        vm.ejRTE = new NbsRichTextEditor(NbsGuidManager);
        vm.languages = [];

        var controllerTemplates = [];
        var controllerTargetEntities = [];
        var placeholdersSelectedList = [];

        // used to manage datasource and selection of tab languages
        vm.tabManagerObj = {};

        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getNotificationTargetEntities(forceReload));
            funcsPromises.push(getNotificationInstance(forceReload, notificationInstanceId));
            funcsPromises.push(getNotificationTemplates(forceReload, notificationInstanceId));

            $q.all(funcsPromises)
                .then(function () {
                    var funcsPromiseSubs = [];
                    funcsPromiseSubs.push(getLanguages(forceReload));
                    funcsPromiseSubs.push(getNotificationDefinition(forceReload, vm.notificationInstance.NotificationDefinitionId));
                    funcsPromiseSubs.push(getNotificationSchemaInstances(forceReload, vm.notificationInstance.NotificationInstanceId));
                    funcsPromiseSubs.push(getNotificationSchemas(forceReload));
                    funcsPromiseSubs.push(getDrbacProcesses(forceReload, virtualCompanyId));

                    $q.all(funcsPromiseSubs)
                        .then(function () {
                            getNotificationDefinitionGroup(forceReload, vm.notificationDefinition.NotificationDefinitionGroupId);

                            // getNotificationSchemaInstances, getNotificationSchemas, getDrbacProcesses are mandatory
                            setSchemasAndProcessListDs(vm.notificationSchemaInstances, vm.notificationSchemas, vm.drbacProcesses);

                            ribbon.setRibbonStatusButton(ribbon.Buttons.Modify, !vm.notificationInstance.IsSystem || vm.notificationDefinition.IsSingleInstance);
                            ribbon.setRibbonStatusButton(ribbon.Buttons.PlaceHolder, !vm.notificationInstance.IsSystem);
                            ribbon.refreshStatusButtons();
                        });
                });
        }

        function setSchemasAndProcessListDs(notificationSchemaInstances, notificationSchemas, drbacProcesses) {
            // notificationSchemaInstances used to filter notificationSchemas and drbacProcesses
            vm.drbacProcessesLabelListDs = new NbsLabelList(); // ProcessNotificationSchemaId
            vm.notificationSchemasLabelListDs = new NbsLabelList();

            var defIds = Lazy(notificationSchemaInstances).pluck('NotificationSchemaId').uniq().toArray();
            var notificationSchemasFiltered = Lazy(notificationSchemas)
                .filter((schemadef) => { return Lazy(defIds).contains(schemadef.NotificationSchemaId); })
                .toArray();
            var drbacProcessesFiltered = Lazy(drbacProcesses)
                .filter((schemadef) => { return Lazy(defIds).contains(schemadef.NotificationSchemaId); })
                .toArray();

            vm.notificationSchemasLabelListDs.addData(notificationSchemasFiltered, 'NotificationSchemaId', 'NotificationSchemaName');
            vm.drbacProcessesLabelListDs.addData(drbacProcessesFiltered, 'DrbacProcessId', 'DrbacProcessName');
        }

        function parseContentBodyHtml(notificationTemplate) {
            if (notificationTemplate) {
                notificationTemplate.NotificationTemplateContentHtml = Nembus.Common.Library.converter.decodeHtml(notificationTemplate.NotificationTemplateContentHtml);
                notificationTemplate.entityAspect.setUnchanged();
            }
        }

        vm.templatePreview = function () {
            vm.modalInstance = navigationService.modal().open(vm.modalOptions({ templateUrl: 'previewTemplateId', scope: $scope }));
            vm.modalInstance.title = vm.translate('Oreview');
            vm.modalInstance.datasource = parseContentBodyHtml(vm.currentTemplate);
            vm.modalInstance.ok = function () {
                //vm.ejRTE.setContentToPaste(vm.contentToPastRTE);
                vm.modalInstance.close();
            };
            vm.modalInstance.cancel = function () { vm.modalInstance.dismiss(); };
            vm.modalInstance.result.then(function () {
                // onClose
            }, function () {
                // onDismiss
            });
        };

        vm.templateReset = function () {
            navigationService.confirmModal(vm.translate("ResetNotificationTemplateWarning"))
                .then(function (result) {
                    if (result) {
                        vm.currentTemplate.NotificationTemplateContentHtml = vm.notificationDefinition.NotificationTemplateContentHtmlDefault;
                        parseContentBodyHtml(vm.currentTemplate);
                    }
                });
        };

        vm.addPlaceholderAction = function (language) {
            // reset selection of placeholders on modal
            initNotificationTargetEntityKeysObj(vm.notificationTargetEntityKeys);
            placeholdersSelectedList = [];

            // data for modal to select target keys (placeholders)
            getNotificationDefinitionM2MNotificationTargetDtos(false, vm.notificationInstance.NotificationDefinitionId)
                .then(function (targetsM2M) {
                    var notificationTargetIds = Lazy(targetsM2M).pluck('NotificationTargetId').toArray();

                    getNotificationTargetM2MNotificationTargetEntitiesDtos(false, notificationTargetIds)
                        .then(function (targetEntities) {
                            var notificationTargetEntityIds = Lazy(targetEntities).pluck('NotificationTargetEntityId').toArray();

                            getNotificationTargetEntityKeys(false, notificationTargetEntityIds);
                        });

                    vm.modalInstance = navigationService.modal().open(vm.modalOptions({ templateUrl: 'placeholderSelectionTemplate', scope: $scope }));
                    vm.modalInstance.title = vm.translate('Placeholder');
                    vm.modalInstance.datasource = vm.currentFileShuffleJournalsDm;
                    vm.modalInstance.ok = function () {
                        vm.ejRTE.setContentToPaste(vm.contentToPastRTE);
                        vm.modalInstance.close();
                    };
                    vm.modalInstance.cancel = function () { vm.modalInstance.dismiss(); };
                    vm.modalInstance.result.then(function () {
                        // onClose
                    }, function () {
                        // onDismiss
                    });
                });
        };

        vm.setSelectedPlaceHolders = function (placeholder) {
            if (!placeholdersSelectedList) {
                placeholdersSelectedList = [];
            }

            if (placeholder.selected) {
                // add code
                if (!Lazy(placeholdersSelectedList)
                    .where({ NotificationTargetEntityKeyCode: placeholder.NotificationTargetEntityKeyCode })
                    .some()) {

                    placeholdersSelectedList.push(placeholder);
                }
            } else {
                // remove code
                placeholdersSelectedList = Lazy(placeholdersSelectedList)
                    .reject({ NotificationTargetEntityKeyCode: placeholder.NotificationTargetEntityKeyCode })
                    .toArray();
            }

            vm.contentToPastRTE = Lazy(placeholdersSelectedList)
                .pluck('NotificationTargetEntityKeyCode')
                .toArray()
                .join(' ');
        };

        vm.setCurrentTemplate = function (language) {
            if (language) {
                vm.currentTemplate = Lazy(controllerTemplates).findWhere({ LanguageId: language.LanguageId });
                if (!vm.currentTemplate) {
                    vm.templateNotPresent = true;
                } else {
                    vm.templateNotPresent = false;
                }
            }
        };

        // get reference of submitaction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parents = vm.submitAction;
        vm.submitAction = function () {
            parents().then(
                function (saveResult) {
                    var notificationTemplate = Lazy(saveResult.entities)
                        .filter((e) => { return e.entityType.shortName === 'NotificationTemplate'; })
                        .first();

                    parseContentBodyHtml(notificationTemplate);
                }
            );
        };

        function getDrbacProcesses(forceReload, virtualCompanyId) {
            var filter = {
                VirtualCompanyId: virtualCompanyId,
                IsHidden: false,
                IsGlobalProcess: false
            };
            var defer = $q.defer();

            drbacService.getDrbacProcesses(filter, forceReload)
                .then(function (data) {
                    vm.drbacProcesses = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getNotificationSchemas(forceReload, notificationSchemaIds) {
            var filter = {
                NotificationSchemaIds: notificationSchemaIds
            };
            var defer = $q.defer();

            notificationService.getNotificationSchemas(filter, forceReload)
                .then(function (data) {
                    vm.notificationSchemas = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getLanguages(forceReload) {
            //language
            //vm.currentLaguages = [{ LanguageName: 'IT', LanguageId: 1 }, { LanguageName: 'EN', LanguageId: 2 }, ];
            var filter = {};
            var defer = $q.defer();

            rbacService.getLanguages(forceReload, filter)
                .then(function (data) {
                    Lazy(data.results)
                        .each((language) => {
                            language.LanguageNameTranslated = vm.translate(language.LanguageName);
                        });

                    vm.languages = data.results;
                    defer.resolve(true);
                });

            return defer.promise;
        }

        function getNotificationTemplates(forceReload, notificationInstanceId) {
            var filter = {
                NotificationInstanceId: notificationInstanceId
            };
            var defer = $q.defer();

            notificationService.getNotificationTemplates(filter, forceReload)
                .then(function (data) {
                    Lazy(data.results)
                        .each((notificationTemplate) => {
                            parseContentBodyHtml(notificationTemplate);
                        });

                    controllerTemplates = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getNotificationDefinition(forceReload, notificationDefinitionId) {
            var filter = {
                NotificationDefinitionId: notificationDefinitionId
            };
            var defer = $q.defer();

            notificationService.getNotificationDefinition(filter, forceReload)
                .then(function (data) {
                    vm.notificationDefinition = data.results[0];
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getNotificationSchemaInstances(forceReload, notificationInstanceId) {
            var filter = {
                NotificationInstanceId: notificationInstanceId
            };
            var defer = $q.defer();

            notificationService.getNotificationSchemaInstances(filter, forceReload)
                .then(function (data) {
                    vm.notificationSchemaInstances = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getNotificationDefinitionGroup(forceReload, notificationDefinitionGroupId) {
            var filter = {
                NotificationDefinitionGroupId: notificationDefinitionGroupId
            };
            var defer = $q.defer();

            notificationService.getNotificationDefinitionGroup(filter, forceReload)
                .then(function (data) {
                    vm.notificationDefinitionGroup = data.results[0];
                    var x = vm.notificationDefinition;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getNotificationInstance(forceReload, notificationInstanceId) {
            var filter = {
                NotificationInstanceId: notificationInstanceId
            };
            var defer = $q.defer();

            notificationService.getNotificationInstance(filter, forceReload)
                .then(function (data) {
                    vm.notificationInstance = data.results[0];
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getNotificationTargetEntities(forceReload) {
            var filter = {
                //
            };
            var defer = $q.defer();

            notificationService.getNotificationTargetEntities(filter, forceReload)
                .then(function (data) {
                    controllerTargetEntities = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function initNotificationTargetEntityKeysObj(targetEntityKeys) {
            vm.notificationTargetEntityKeysObj = {};

            Lazy(targetEntityKeys)
                .each((targetEntityKey) => {
                    vm.notificationTargetEntityKeysObj[targetEntityKey.NotificationTargetEntityId] =
                        Lazy(targetEntityKeys)
                            .where({ NotificationTargetEntityId: targetEntityKey.NotificationTargetEntityId })
                            .map((entityKey) => {
                                return {
                                    NotificationTargetEntityKeyId: entityKey.NotificationTargetEntityKeyId,
                                    NotificationTargetEntityKeyCode: entityKey.NotificationTargetEntityKeyCode,
                                    NotificationTargetEntityKeyName: entityKey.NotificationTargetEntityKeyName,
                                    selected: false
                                };
                            }).toArray();
                });
        }

        function getNotificationTargetEntityKeys(forceReload, notificationTargetEntityIds) {
            var filter = {
                NotificationTargetEntityIds: notificationTargetEntityIds
            };
            var defer = $q.defer();

            notificationService.getNotificationTargetEntityKeys(filter, forceReload)
                .then(function (data) {
                    vm.notificationTargetEntityKeys = data.results;

                    initNotificationTargetEntityKeysObj(vm.notificationTargetEntityKeys);

                    vm.notificationTargetEntities = [];
                    // view only related target entities
                    Lazy(controllerTargetEntities)
                        .each((targetEntity) => {
                            if (Lazy(vm.notificationTargetEntityKeys).where({ NotificationTargetEntityId: targetEntity.NotificationTargetEntityId }).some()) {
                                vm.notificationTargetEntities.push(targetEntity);
                            }
                        });

                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getNotificationDefinitionM2MNotificationTargetDtos(forceReload, notificationDefinitionId) {
            var filter = {
                NotificationDefinitionId: notificationDefinitionId
            };
            var defer = $q.defer();

            notificationService.getNotificationDefinitionM2MNotificationTargetDtos(filter, forceReload)
                .then(function (data) {
                    vm.notificationDefinitionM2MTargets = data.results;
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getNotificationTargetM2MNotificationTargetEntitiesDtos(forceReload, notificationTargetIds) {
            var filter = {
                NotificationTargetIds: notificationTargetIds
            };
            var defer = $q.defer();

            notificationService.getNotificationTargetM2MNotificationTargetEntitiesDtos(filter, forceReload)
                .then(function (data) {
                    vm.notificationTargetEntities = data.results;
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }
    }

    notificationInstanceDetail.$inject = injectParams;
    notificationInstanceDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('notificationInstanceDetail', notificationInstanceDetail);
});