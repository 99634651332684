﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'utilService', 'vhreventService', 'foundationService', 'audService', 'reportService', 'navigationService', 'checkStateChangeService', 'contextService', 'rbacService'];
    var htmlToPdfSurveyVhrTargetKpiReportForm = function ($q, $scope, $timeout, $injector, utilService, vhreventService, foundationService, audService, reportService, navigationService, checkStateChangeService, contextService, rbacService) {

        var vm = this;
        nbsVm.call(vm, audService, navigationService, contextService, $q, $injector, $timeout);
        var checkStateChangeParams = { dirtyFlagIsDisabled: vm.isDirtyDisabled };
        checkStateChangeService.checkFormOnStateChange($scope, audService, checkStateChangeParams);

        // all form items are enabled
        vm.setIsEditingReport();

        var appStoreCode = 'aud';
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var stepsToRender = [];
        var expressionTypeEnum = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.ExpressionType, null, vm.translate).obj.normal.name_value;

        vm.tabs = { form: 0, list: 1 };
        vm.activeTabIndex = 0;

        vm.tabOnSelect = function (tab) {
            switch (tab) {
                case vm.tabs.form:
                    vm.showForm = true;
                    vm.showList = false;
                    break;
                case vm.tabs.list:
                    vm.showForm = false;
                    vm.showList = true;
                    break;
            }

            vm.activeTabIndex = tab;
        };
        vm.tabOnSelect(vm.tabs.form);

        var dsCallback = function () { return vhreventService.getVhrEventTypes({ AppStoreBw: appStoreBw }, false); };
        var selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventType', viewTitle: vm.translate('SurveyDto.VhrEventTypeId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventType = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        dsCallback = function () { return vhreventService.getVhrEventGroups({ AppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventGroup', viewTitle: vm.translate('SurveyDto.VhrEventGroupId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventGroup = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        dsCallback = function () { return foundationService.getVhrTypes(false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrType', viewTitle: vm.translate('TargetDto.TargetTypeId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrType = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        dsCallback = function () { return foundationService.getVhrGroups(false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrGroup', viewTitle: vm.translate('TargetDto.TargetGroupId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrGroup = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        vm.nbsAuthorDrbacModalObj = new NbsAssigneeSelectorModal({
            translator: vm.translate,
            suspendDirty: vm.suspendDirty,
            navigationService: navigationService,
            contextUserForSelection: null,
            getServerVarFn: Nembus.Common.Library.server.getServerVar
        });
        vm.nbsAuthorDrbacModalObj.viewUsers();

        vm.currentForm = audService.dataContext.htmlToPdfSurveyVhrTargetKpiClientDatas.add({
            HtmlToPdfSurveyVhrTargetKpiClientDataId: NbsGuidManager.newGuid(),
            SurveyAnalysisDefinitionId: null,
            IsArchived: null
        });


        vm.parentScope = $scope.$parent.vm;
        vm.parentScope.ribbonDatacontext.setRibbonStatusButton(vm.parentScope.ribbonDatacontext.Buttons.Search, true);
        vm.parentScope.ribbonDatacontext.refreshStatusButtons();


        // REFERS to 'reportDefinitionHtmlToDocDetail'
        vm.parentScope.initReportToDocHeader({ onSearchActionHtmlToDocFn: onActionSearch, onCreateHtmlFn: createHtmlReport });


        //#region Multiselect with grid selection
        selectionParams = { destModalKey: 'vhr', entityMode: 'Target', translateKey: 'Target', isMultiselect: true, selectableEntities: ['VirtualEquipment', 'Target'] };
        vm.nbsSelectionGridWithModalTarget = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        //#endregion

        vm.arithmeticCalculations = [
            { value: 'mid', name: vm.translate('ArithmeticMidValue') },
            { value: 'min', name: vm.translate('ArithmeticMinValue') },
            { value: 'max', name: vm.translate('ArithmeticMaxValue') },
            { value: 'sum', name: vm.translate('ArithmeticSumValue') }
        ];
        vm.currentForm.arithmeticCalculationId = 'mid';

        // set title of current controller in report definition detail view
        navigationService.setControllerTitle();

        vm.gridSelection = function (args) {
            $timeout(() => {
                selectedSurveys = args.data;
                selectedSurveyIds = args.dataIds;

                setPrintButtonStatus();
            });
        }


        vm.currentSurveys = [];

        function searchSurveys(filter) {
            var defer = $q.defer();
            audService.searchSurveys(filter)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getSurveyAnalysisDefinitions(forceReload));

            $q.all(funcsPromises)
                .then(function () {
                });
        }

        function setPrintButtonStatus() {
            var selected = Lazy(selectedSurveys).some();
            vm.parentScope.ribbonDatacontext.setRibbonStatusButton(vm.parentScope.ribbonDatacontext.Buttons.PrintReportPdf, selected);
            vm.parentScope.ribbonDatacontext.refreshStatusButtons();
        }

        var selectedSurveyIds = [];
        var selectedSurveys = [];

        var nbsGridDatacontext = new NbsAudGrid(vm.translate);
        nbsGridDatacontext.configureGrid('survey', 'kpireport', null);
        nbsGridDatacontext.setGridIsUnderTab();
        nbsGridDatacontext.setIsMultiselect();
        vm.nbsGridDatacontext = nbsGridDatacontext;

        function onActionSearch() {
            var defer = $q.defer();

            selectedSurveyIds = [];
            selectedSurveys = [];
            vm.forceDatasourceLocal = {
                refreshkey: new Date().getTime(),
                dataSource: []
            }

            vm.removeAlertFromList(false);

            vm.currentForm.entityAspect.validateEntity();
            if (vm.currentForm.entityAspect.hasValidationErrors) {
                $timeout(() => { /* implicit scope apply; */ });
                return;
            }

            if (!vm.nbsSelectionGridWithModalTarget.selectedIds
                || vm.nbsSelectionGridWithModalTarget.selectedIds.length > 5) {

                vm.setErrorMsg({ isMessageVisible: true, message: vm.translate('SelectTargetRange_1_5') });
                $timeout(() => { /* implicit scope apply; */ });
                return;
            }

            var filter = {
                surveyFilter: {
                    IsArchived: vm.currentForm.IsArchived,
                    IsCompleted: true
                },
                surveySectionFilter: {},
                vhrEventFilter: {
                    ModuleTargetId: moduleTargetId,
                    VhrEventTypesId: vm.nbsSelectionGridWithModalVhrEventType.selectedIds,
                    VhrEventGroupsId: vm.nbsSelectionGridWithModalVhrEventGroup.selectedIds,
                    VirtualHumanResourcesId: vm.nbsSelectionGridWithModalTarget.selectedIds,
                    HaveToExpandVhrsWithAssetTree: true
                },
                surveyVhrEventFilter: {
                    CompilerUserPersonIds: vm.nbsAuthorDrbacModalObj.appUserAreSelected() ? vm.nbsAuthorDrbacModalObj.appUserMultiselectSelectedIds() : null,
                    SurveyCompileDateSystem1: vm.currentForm.SurveyCompileDateSystem1,
                    SurveyCompileDateSystem1Expression: expressionTypeEnum.GreaterThanOrEqual,
                    SurveyCompileDateSystem2: vm.currentForm.SurveyCompileDateSystem2,
                    SurveyCompileDateSystem2Expression: expressionTypeEnum.LessThanOrEqual
                },
                virtualHrFilter: {
                    VhrGroupsId: vm.nbsSelectionGridWithModalVhrGroup.selectedIds,
                    VhrTypesId: vm.nbsSelectionGridWithModalVhrType.selectedIds
                },
                surveyQuestionFilter: {},
                surveyQuestionOptionFilter: {}
            };

            // reset result search
            vm.currentSurveys = [];

            searchSurveys(filter)
                .then(function (surveys) {
                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: surveys
                    }

                    vm.currentSurveys = surveys;

                    setPrintButtonStatus();

                    vm.tabOnSelect(vm.tabs.list);

                    defer.resolve(null);
                });

            return defer.promise;
        }

        function createHtmlReport() {
            var surveyIds = selectedSurveyIds;
            var surveyAnalysisDefinitionId = vm.currentForm.SurveyAnalysisDefinitionId;
            var surveys = Lazy(vm.currentSurveys)
                .filter((survey) => {
                    return Lazy(surveyIds).contains(survey.SurveyId);
                }).toArray();

            var funcsPromises = [];
            funcsPromises.push(getSurveyAnalysis(true, surveyAnalysisDefinitionId, surveyIds));
            $q.all(funcsPromises)
                .then(function () {
                    parseReportHtmlDatasource(surveys, vm.surveyAnalysis);
                });
        }

        // prepare datasource for report in UI (to prepare Html)
        vm.htmlSteps = {
            SURVEYS: 'surveys-render'
        };
        function parseReportHtmlDatasource(surveys, surveyAnalysis) {
            setReportFilterData();

            vm.bindObject = {
                surveys: []
            };

            stepsToRender = [];

            // object binded on UI
            var groupName = '';
            var typeName = '';
            var kpiName = Lazy(vm.surveyAnalysisDefinitions)
                .findWhere({ SurveyAnalysisDefinitionId: vm.currentForm.SurveyAnalysisDefinitionId })
                .SurveyAnalysisDefinitionName;

            var group = Lazy(vm.vhrEventGroups).findWhere({ VhrEventGroupId: Number(vm.currentForm.VhrEventGroupId) });
            var type = Lazy(vm.vhrEventTypes).findWhere({ VhrEventTypeId: Number(vm.currentForm.VhrEventTypeId) });
            if (group) {
                groupName = group.VhrEventGroupName;
            }
            if (type) {
                typeName = type.VhrEventTypeName;
            }

            vm.bindObject = {
                surveys: []
            };

            // Prepare datasource for chart
            var series = [];
            var surveysGroupedTarget = Lazy(surveys).groupBy('TargetId').toArray();

            var parsedSurveys = [];
            Lazy(surveys)
                .each((survey) => {
                    var surveyAnalysisValue = 0;

                    var currentsurveyAnalysis = Lazy(surveyAnalysis).findWhere({ SurveyId: survey.SurveyId });
                    if (currentsurveyAnalysis) {
                        surveyAnalysisValue = currentsurveyAnalysis.SurveyAnalysisValue;
                    }

                    parsedSurveys.push({
                        SurveyId: survey.SurveyId,
                        SurveyCode: survey.SurveyCode,
                        SurveyName: survey.SurveyName,
                        SurveyCompileDateSystem: survey.SurveyCompileDateSystem,
                        SurveyCompileDateForUI: Nembus.Common.Library.converter.date.formatDate(survey.SurveyCompileDateSystem, contextService.virtualContext.cultureInfo, true),
                        TargetId: survey.TargetId,
                        TargetName: survey.TargetName,
                        SurveyAnalysisValue: surveyAnalysisValue
                    });
                });

            parsedSurveys = Lazy(parsedSurveys)
                .sortBy((item) => { return item.TargetName; })
                .toArray();

            var surveysGroupedDate = Lazy(parsedSurveys)
                .sortBy((item) => { return item.SurveyCompileDateSystem; })
                .groupBy('SurveyCompileDateForUI')
                .toArray();

            var allPoints = [];
            // create a serie for every target in results
            Lazy(surveysGroupedTarget)
                .each((groupedTarget) => {
                    var serie = {
                        points: [],
                        id: Number(groupedTarget[0]),
                        name: Lazy(groupedTarget[1]).first().TargetName
                    };

                    Lazy(surveysGroupedDate)
                        .each((groupedDate) => {
                            var xValue = Lazy(groupedDate[1]).first().SurveyCompileDateSystem;
                            var values = Lazy(parsedSurveys)
                                .where({ TargetId: serie.id, SurveyCompileDateForUI: groupedDate[0] })
                                .pluck('SurveyAnalysisValue')
                                .toArray();

                            var yValue = Nembus.Common.Library.util.getArithmeticResult(values, vm.currentForm.arithmeticCalculationId);

                            var point = {
                                x: xValue,
                                y: yValue
                            };

                            serie.points.push(point);

                            // used to get min/max
                            allPoints.push(point);
                        });

                    series.push(serie);
                });


            var rangeOptions = getChartRange(allPoints, 'y');

            vm.chartobj = {
                //Initializing Primary X Axis	
                primaryXAxis:
                {
                    range: { min: 2005, max: 2011, interval: 1 },
                    valueType: 'category'
                },

                //Initializing Primary Y Axis	
                primaryYAxis:
                {
                    labelFormat: "{value}",
                    range: { min: rangeOptions.minYRange, max: rangeOptions.maxYRange, interval: rangeOptions.intervalYRange }
                },

                //Initializing Common Properties for all the series
                commonSeriesOptions:
                {
                    type: 'line',
                    enableAnimation: false,
                    tooltip: {
                        visible: true, template: 'Tooltip'
                    },
                    marker:
                    {
                        shape: 'circle',
                        size:
                        {
                            height: 10, width: 10
                        },
                        visible: true
                    }
                },

                //Initializing Series				
                series: series,
                isResponsive: true,
                load: "loadTheme",
                //title: { text: '' },
                legend: { visible: true }
            };


            if (parsedSurveys.length > 0) {
                stepsToRender.push(vm.htmlSteps.SURVEYS);
            }

            vm.bindObject.surveys = parsedSurveys;

            // set steps to render before html retrieve from directive
            vm.parentScope.htmlToDocManager.setHtmlStepsToRender(stepsToRender);

            if (!vm.parentScope.htmlToDocManager.hasHtmlStepsToRender()) {
                vm.parentScope.htmlToDocManager.htmlIsRendered();
            }
        }

        function getChartRange(points, yPropertyName) {
            var result = {
                minYRange: 0,
                maxYRange: 0,
                intervalYRange: 0
            };

            var minY = Lazy(points).pluck(yPropertyName).min();
            var maxY = Lazy(points).pluck(yPropertyName).max();

            if (minY === maxY && maxY === 0) {
                maxY = 1;
            }

            var intervalY = 1;
            if (maxY > 0) {
                intervalY = parseInt(minY + maxY) / 2;
            }

            result.minYRange = minY;
            result.maxYRange = maxY;
            result.intervalYRange = intervalY;

            return result;
        }

        function getSurveyAnalysisDefinitions(forceReload) {
            // 1) SurveyQuestionOption_CompletionPercentRequired => Indice completamento
            // 2) SurveyQuestionOption_ActiveSumWeight => Somma pesi risposte attive
            // 3) SurveyQuestionOption_ActiveSumValue => Somma gravità risposte attive
            // 4) SurveyQuestionOption_ActiveWeightedSumWeightValue => Somma dei valori ponderati Pesi x Gravità
            // 5) SurveyQuestionOption_NormalizedIndexSumWeight => Indice normalizzato della sommatoria dei Pesi 
            // 6) SurveyQuestionOption_NormalizedIndexSumValue => Indice normalizzato della sommatoria delle Gravità
            // 7) SurveyQuestionOption_NormalizedIndexSumWeightXValue => Indice normalizzato della sommatoria ponderata Pesi x Gravità
            // 8) SurveyQuestionOption_ActiveAverageSumWeight => Media aritmetica dei Pesi 
            // 9) SurveyQuestionOption_ActiveAverageSumValue =>Media aritmetica delle Gravità
            // 10) SurveyQuestionOption_ActiveAverageSumWeightXValue => Media aritmetica dei Pesi x Gravità
            // 11) SurveyQuestionOption_ActiveWeightedAverageSumWeightXValue => Media ponderata dei Pesi x Gravità
            var filter = {
                SurveyAnalysisDefinitionIds: [
                    'SurveyQuestionOption_CompletionPercentRequired',
                    'SurveyQuestionOption_ActiveSumWeight',
                    'SurveyQuestionOption_ActiveSumValue',
                    'SurveyQuestionOption_ActiveWeightedSumWeightValue',
                    'SurveyQuestionOption_NormalizedIndexSumWeight',
                    'SurveyQuestionOption_NormalizedIndexSumValue',
                    'SurveyQuestionOption_NormalizedIndexSumWeightXValue',
                    'SurveyQuestionOption_ActiveAverageSumWeight',
                    'SurveyQuestionOption_ActiveAverageSumValue',
                    'SurveyQuestionOption_ActiveAverageSumWeightXValue',
                    'SurveyQuestionOption_ActiveWeightedAverageSumWeightXValue'
                ]
            };
            var defer = $q.defer();

            audService.getSurveyAnalysisDefinitions(filter, forceReload)
                .then(function (data) {
                    vm.surveyAnalysisDefinitions = Lazy(data.results).sortBy((item) => { return item.SurveyAnalysisDefinitionName }).toArray();
                    vm.currentForm.SurveyAnalysisDefinitionId = 'SurveyQuestionOption_CompletionPercentRequired';
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function setReportFilterData() {
            vm.reportFilterData = new NbsReportFilterData(vm.translate, contextService.virtualContext.cultureInfo, Nembus.Common.Library.converter.date.formatDate);

            // filter in report are visualized in current order 
            vm.reportFilterData.setDataByRangeDate('SurveyCompileDate', 'SurveyDto.SurveyCompileDate', vm.currentForm.SurveyCompileDateSystem1, vm.currentForm.SurveyCompileDateSystem2);
            vm.reportFilterData.setDataByObjectList('AuditGroup', 'SurveyDto.VhrEventGroupId', [vm.currentForm.VhrEventGroupId], vm.vhrEventGroups, 'VhrEventGroupId', ['VhrEventGroupName']);
            vm.reportFilterData.setDataByObjectList('AuditType', 'SurveyDto.VhrEventTypeId', [vm.currentForm.VhrEventTypeId], vm.vhrEventTypes, 'VhrEventTypeId', ['VhrEventTypeName']);
            vm.reportFilterData.setDataByObjectList('IsArchived', 'SurveyDto.IsArchived', [vm.currentForm.IsArchived], vm.booleanSelectionForDropDown, 'value', ['name']);
            vm.reportFilterData.setDataByDrbacAuthSelectorModal('CompilerUserPersonId', 'SurveyVhrEvent.CompilerUserPersonId', vm.nbsAuthorDrbacModalObj);
            vm.reportFilterData.setDataByObjectList('ArithmeticCalculationId', 'HtmlToPdfSurveyVhrTargetKpiReportForm.HtmlToPdfSurveyVhrTargetKpiReportFormChartMode', [vm.currentForm.arithmeticCalculationId], vm.arithmeticCalculations, 'value', ['name']);
            vm.reportFilterData.setDataByObjectList('SurveyAnalysisDefinitionId', 'KPI', [vm.currentForm.SurveyAnalysisDefinitionId], vm.surveyAnalysisDefinitions, 'SurveyAnalysisDefinitionId', ['SurveyAnalysisDefinitionName']);
        }

        function getSurveyAnalysis(forceReload, surveyAnalysisDefinitionId, surveyIds) {
            var filter = {
                SurveyAnalysisDefinitionId: surveyAnalysisDefinitionId,
                SurveyAnalysisGroupModeId: 1, // Survey
                SurveyIds: surveyIds
            };
            var defer = $q.defer();

            audService.getSurveyAnalysis(filter, forceReload)
                .then(function (data) {
                    vm.surveyAnalysis = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    htmlToPdfSurveyVhrTargetKpiReportForm.$inject = injectParams;
    htmlToPdfSurveyVhrTargetKpiReportForm.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('htmlToPdfSurveyVhrTargetKpiReportForm', htmlToPdfSurveyVhrTargetKpiReportForm);
});
