﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'vhreventService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var vhrEventTypeDetail = function ($q, $scope, $timeout, $injector, vhreventService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, vhreventService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, vhreventService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var currentVhrEventTypeId = navigationService.stateParams().vhrEventTypeId;
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        if (!moduleTargetId) {
            moduleTargetId = null;
        }

        vm.currentModuleTargets = Nembus.Rds.Library.Tools.ModuleTargetContext({ AppStoreBw: appStoreBw, ModuleTargetTypeCode: 'DEFAULT' }, contextService);

        if (currentVhrEventTypeId) {
            vm.setIsViewing();

            loadData(true);
        }
        else {
            vm.setIsAdding();
            vm.currentVhrEventType = vhreventService.dataContext.vhrEventTypes.add({
                VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId,
                ModuleTargetId: moduleTargetId
            });
        }

        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getVhrEventType(forceReload));

            $q.all(funcsPromises)
                .then(function () {
                });
        }

        function getVhrEventType(forceReload) {
            var filter = { VhrEventTypeId: currentVhrEventTypeId };
            var defer = $q.defer();

            vhreventService.getVhrEventType(filter, forceReload)
                .then(function (data) {
                    vm.currentVhrEventType = data.results[0];
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }        
    };

    vhrEventTypeDetail.$inject = injectParams;
    vhrEventTypeDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('vhrEventTypeDetail', vhrEventTypeDetail);
});