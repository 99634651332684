﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'activityService', 'bomService', 'vhreventService', 'reportService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var activitiesListReportForm = function ($q, $scope, $timeout, $injector, activityService, bomService, vhreventService, reportService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, activityService, navigationService, contextService, $q, $injector, $timeout);
        var checkStateChangeParams = { dirtyFlagIsDisabled: vm.isDirtyDisabled };
        checkStateChangeService.checkFormOnStateChange($scope, activityService, checkStateChangeParams);

        // all form items are enabled
        vm.setIsEditingReport();

        var viewKind = navigationService.stateParams().viewKind;
        var appStoreCode = navigationService.stateParams().appStoreCode.toLowerCase() === 'sfl'
            ? 'all'
            : navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var activityKindEnum = Nembus.Common.Library.util.parseEnum(activityService.dataContext.enumTypes.ActivityKind, null, vm.translate).obj.normal.name_value;
        var activityKindId = activityKindEnum[viewKind];

        vm.isBom = (activityKindId === activityKindEnum.Bom || !activityKindId);
        vm.notIsBom = !vm.isBom;
        vm.currentReportFilter = {};
        vm.currentActivity = {};

        vm.vhrEventGroups = [];
        vm.vhrEventTypes = [];
        vm.schedulingGroups = [];

        vm.moduleTargets = Nembus.Rds.Library.Tools.ModuleTargetContext({ AppStoreBw: appStoreBw, ModuleTargetTypeCode: 'DEFAULT' }, contextService);
        
        // set title of current controller in report definition detail view
        navigationService.setControllerTitle();

        loadData(true);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getVhrEventTypes(forceReload));
            funcsPromises.push(getVhrEventGroups(forceReload));
            funcsPromises.push(getSchedulingGroups(forceReload));

            $q.all(funcsPromises)
                .then(function () {
                    reportService.currentReport.reportArgs = {
                        Ds1_Arg1: {
                            AppStoreBw: appStoreBw,
                            ActivityKindId: activityKindId,
                            VhrEventTypeId: vm.currentActivity.VhrEventTypeId,
                            VhrEventGroupId: vm.currentActivity.VhrEventGroupId,
                            ModuleTargetId: vm.currentActivity.ModuleTargetId,
                            SchedulingGroupId: vm.currentActivity.SchedulingGroupId
                        }
                    };
                    
                    // bind report object to ui filter
                    vm.currentReportFilter = reportService.currentReport.reportArgs.Ds1_Arg1;
                });
        }

        function getVhrEventTypes(forceReload) {
            var filter = { AppStoreBw: appStoreBw, IsBomType: vm.isBom };
            var defer = $q.defer();

            vhreventService.getVhrEventTypes(filter, forceReload)
                .then(function (data) {
                    vm.vhrEventTypes = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrEventGroups(forceReload) {
            var filter = { AppStoreBw: appStoreBw, IsBomGroup: vm.isBom };
            var defer = $q.defer();

            vhreventService.getVhrEventGroups(filter, forceReload)
                .then(function (data) {
                    vm.vhrEventGroups = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getSchedulingGroups(forceReload) {
            var filter = { AppStoreBw: appStoreBw };
            var defer = $q.defer();

            bomService.getSchedulingGroups(filter, forceReload)
                .then(function (data) {
                    vm.schedulingGroups = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    activitiesListReportForm.$inject = injectParams;
    activitiesListReportForm.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('activitiesListReportForm', activitiesListReportForm);
});
