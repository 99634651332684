﻿'use strict';
define([], function () {

    var injectParams = [];
    var nbsTxtSearchComponent = function () {

        var vm = this;
        var ENTERKEYCODE = 13;
        var prevText = '';

        vm.localTxtSearchText = '';

        vm.$onChanges = function (changes) {
            if (changes.nbsDataContext) {
                if (changes.nbsDataContext.currentValue !== undefined && changes.nbsDataContext.currentValue !== null) {
                    vm.localTxtSearchText = changes.nbsDataContext.currentValue;
                    storePreviousText();
                }                
            }
        };

        vm.searchTimeout;
        vm.searchOnChange = function (event) {
            if (vm.localTxtSearchText === prevText && event.keyCode !== ENTERKEYCODE) {
                return;
            }

            storePreviousText();

            if (event.keyCode === ENTERKEYCODE || !vm.localTxtSearchText) {
                vm.searchEvent({ txtSearch: vm.localTxtSearchText });
            }
            else {
                clearTimeout(vm.searchTimeout);
                vm.searchTimeout = setTimeout(() => vm.searchEvent({ txtSearch: vm.localTxtSearchText }), 600);
            }
        };

        vm.onSearchEvent = function () {
            vm.searchEvent({ txtSearch: vm.localTxtSearchText });
        };

        function storePreviousText() {
            prevText = vm.localTxtSearchText ? Lazy(vm.localTxtSearchText).toArray().join('') : '';
        }
    };

    nbsTxtSearchComponent.$inject = injectParams;
    angular.module('nbs.rds.module').component('nbsTxtSearch', {
        templateUrl: '/rds/common/components/nbsTxtSearchComponent/_?c=' + window.codeCache(),
        controller: nbsTxtSearchComponent,
        controllerAs: 'vm',
        bindings: {
            searchEvent: '&',
            nbsDataContext: "<",
            nbsLabel: "<",
            isVisible: '<',
            watermark: '<',
            uiContext: "<"
        }
    });
});

