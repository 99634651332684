﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'notificationService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var notificationConfigDetail = function ($q, $scope, $timeout, $injector, notificationService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, notificationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, notificationService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        vm.notificationSchemas = [];

        vm.setIsViewing();
        loadData(true);

        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getNotificationSchemas(forceReload));
            funcsPromises.push(getNotificationConfig(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.notificationConfig = results[1];

                    Lazy(results[0])
                        .each((item) => {
                            if ((item.IsSystem === false && item.IsGlobalNotificationSchema === true)
                                || (item.NotificationSchemaId === vm.notificationConfig.SystemNotificationSchemaId)) {

                                item.NotificationSchemaNameUI = item.NotificationSchemaName;
                                if (item.IsSystem) {
                                    item.NotificationSchemaNameUI += ' (' + vm.translate('NotificationConfigDetail.IsSystem') + ')'
                                }

                                vm.notificationSchemas.push(item);
                            }
                        });
                });
        }

        function getNotificationSchemas(forceReload) {
            var filter = {
                IsGlobalNotificationSchema: true
            };
            var defer = $q.defer();

            notificationService.getNotificationSchemas(filter, forceReload)
                .then(function (data) {                    
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getNotificationConfig(forceReload) {
            var filter = { };
            var defer = $q.defer();

            notificationService.getNotificationConfig(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results[0]);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    notificationConfigDetail.$inject = injectParams;
    notificationConfigDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('notificationConfigDetail', notificationConfigDetail);
});