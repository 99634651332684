﻿//This page is only used like container of technical cleaning data

'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

	var injectParams = ['$q', '$scope', '$timeout', '$injector', 'cmstoolService', 'navigationService', 'checkStateChangeService', 'contextService', 'utilService'];
	var cmsStaticContentData = function ($q, $scope, $timeout, $injector, cmstoolService, navigationService, checkStateChangeService, contextService, utilService) {
		// IMPORTANT: 
		// The action in this controller are set from tec clean controller using shared navigation service

		var vm = this;
		nbsVm.call(vm, cmstoolService, navigationService, contextService, $q, $injector, $timeout);
		checkStateChangeService.checkFormOnStateChange($scope, cmstoolService);

		var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
		ribbon.addHomeTab();
		vm.ribbonDatacontext = ribbon;

		var cmsStaticContentCode = navigationService.stateParams().cmsStaticContentCode;

		// tab attachments is disabled by default
		// it is enabled if cmsstaticcontent detail exists
		vm.tabAttachmentsIsDisabled = true;

		if (cmsStaticContentCode) {
			getCmsStaticContent(cmsStaticContentCode, true);
		}

		// START actions set from tec clean controllers (TABS)
		vm.newAction = function () { navigationService.execControllerAction('newAction'); };
		vm.deleteAction = function () { navigationService.execControllerAction('deleteAction'); };
		vm.newFolderAction = function () { navigationService.execControllerAction('newFolderAction'); };
		vm.modifyAction = function () { navigationService.execControllerAction('modifyAction'); };
		vm.submitAction = function () { navigationService.execControllerAction('submitAction'); };
		vm.refreshAction = function () { navigationService.execControllerAction('refreshAction'); };
		// END actions set from tec clean controllers

		vm.tabs = {
			general: 1,
			attachments: 2
		};

		vm.tabOnSelect = function (tab) {
			var params = {
				'cmsStaticContentCode': null,
				'viewCompleteFileUrl': null
			};

			var controller = '';
			switch (tab) {
				case vm.tabs.general:
					controller = 'cmsstaticcontentdatadetail';
					break;
				case vm.tabs.attachments:
					controller = 'attachmentdetail';
					params.viewCompleteFileUrl = true;

                    var attachmentRootFolder = null;
					if (vm.currentCmsStaticContent.FsFolderId) {
						attachmentRootFolder = vm.currentCmsStaticContent.FsFolderId;
					}
					else 
                    {
						attachmentRootFolder = vm.rootKey;
					}
					params.fsFolderId = attachmentRootFolder;
					break;
			}

			if (controller) {
				params.cmsStaticContentCode = cmsStaticContentCode;
				
				navigationService.navigateTo('app.nav.cmsstaticcontentdata.' + controller, params);
			}
		};

		function getCmsStaticContent(cmsStaticContentCode, forceReload) {
			var filter = {
				CmsStaticContentCode: cmsStaticContentCode
			};
			var defer = $q.defer();

			cmstoolService.getCmsStaticContent(filter, forceReload)
				.then(function (data) {
					vm.currentCmsStaticContent = data.results[0];

					if (!vm.currentCmsStaticContent.FsFolderId) {
						//actio for root folder attachment
						vm.rootKey = NbsGuidManager.newGuid();
						utilService.setFsDtoRootAddFunction({
							entityService: cmstoolService,
							rootFolderKey: vm.rootKey,
							fnSetRootFolder: function (folderId) { vm.currentCmsStaticContent.FsFolderId = folderId; }
						});
					}

					defer.resolve(true);
				})
				.catch(function (error) {
					vm.setErrorMsg(error);
				});

			return defer.promise;
		}
	};

	cmsStaticContentData.$inject = injectParams;
	cmsStaticContentData.prototype = Object.create(nbsVm.prototype);
	angular.module('nbs.rds.module').controller('cmsStaticContentData', cmsStaticContentData);
});