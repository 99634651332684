﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    //this page is called from external link. Create a new survey based on survey on demand
    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'audService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function surveyCreationOnDemandOtat($q, $scope, $timeout, $injector, audService,  navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, audService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, audService, { dirtyFlagIsSuspended: vm.isDirtySuspended });

        var surveyId = navigationService.stateParams().surveyId;
        var appOtatId = navigationService.stateParams().appOtatId;

        loadData(true);
        function loadData(forceReload) {
            var funcsPromises = [];
            var filter = { SurveyId: surveyId, AppOtatId: appOtatId };
            audService.getSurveyOnDemandOtatClone(filter, true)
                .then(function (data) {
                    var surveyCloned = Lazy(data.results).first();
                    if (surveyCloned && surveyCloned.SurveyId) {
                        navigationService.navigateTo("app.navlight.surveycompile", { 'surveyId': surveyCloned.SurveyId, 'appStoreCode': 'aud' });
                    }
                    else {
                        //todo: error message
                    }
                });
        }

        
    }

    surveyCreationOnDemandOtat.$inject = injectParams;
    surveyCreationOnDemandOtat.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('surveyCreationOnDemandOtat', surveyCreationOnDemandOtat);
});