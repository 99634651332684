﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'drbacService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var drbacSchemaDetail = function ($q, $scope, $timeout, $injector, drbacService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, drbacService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, drbacService, { dirtyFlagIsSuspended: vm.isDirtySuspended });

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var drbacSchemaId = navigationService.stateParams().drbacSchemaId;

        if (drbacSchemaId) {
            vm.setIsViewing();

            loadData(true);
        }
        else {
            // DrbacSchema cannot be added, can be only copied by another
        }

        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getDrbacRoles(forceReload));
            funcsPromises.push(getDrbacPermissions(forceReload));
            funcsPromises.push(getDrbacSchemaPermissions(forceReload, drbacSchemaId));
            funcsPromises.push(getDrbacSchema(forceReload, drbacSchemaId));
            funcsPromises.push(getDrbacSchemaPermissionM2MDrbacRoleDtosBySchema(forceReload, drbacSchemaId));
            funcsPromises.push(getDrbacPermissionGroups(forceReload));

            $q.all(funcsPromises)
                .then(function () {
                    setSelectedRoles(vm.drbacPermissionGroups);
                });
        }

        function setSelectedRoles(drbacPermissionGroups) {
            vm.schemaPermissionDatasource = {};

            if (vm.drbacSchemaPermissions) {
                Lazy(vm.drbacSchemaPermissions)
                    .each((item) => {
                        item.rolesObj = new NbsAssigneeSelectorModal({
                            translator: vm.translate,
                            suspendDirty: vm.suspendDirty,
                            navigationService: navigationService,
                            contextUserForSelection: null,
                            getServerVarFn: Nembus.Common.Library.server.getServerVar
                        });
                        item.rolesObj.viewRoles();

                        //if (item.IsGrantedByRoleEnabled) {
                        item.rolesObj.drbacRoleMultiselectAddData(vm.drbacRoles, 'RoleId', 'RoleName');
                        var pRolesId = Lazy(vm.drbacSchemaPermissionM2MDrbacRoles).where({ DrbacSchemaPermissionId: item.DrbacSchemaPermissionId }).pluck('DrbacRoleId').toArray();
                        item.rolesObj.setDrbacRolesSelected(pRolesId);
                        //}

                        item.rolesObj.setOnSelectFnCallback(function () {
                            Nembus.Rds.Library.SetBindingM2MEntitiesInContext(drbacService.dataContext.drbacSchemaPermissionM2MDrbacRoleDtos, 'DrbacSchemaPermissionId', [item.DrbacSchemaPermissionId], 'DrbacRoleId', item.rolesObj.drbacRoleMultiselectSelectedIds());
                        });
                    });
            }

            Lazy(drbacPermissionGroups)
                .each((group) => {
                    vm.schemaPermissionDatasource[group.DrbacPermissionGroupId] = [];

                    vm.schemaPermissionDatasource[group.DrbacPermissionGroupId] = Lazy(vm.drbacSchemaPermissions)
                        .filter((item) => {
                            return item.DrbacPermission && item.DrbacPermission.DrbacPermissionGroupId === group.DrbacPermissionGroupId;
                        })
                        //.sortBy((item) => { return item.DrbacPermission.PermissionName; }, false)
                        .toArray();

                });
        }

        function getDrbacSchema(forceReload, drbacSchemaId) {
            var filter = {
                DrbacSchemaId: drbacSchemaId
            };
            var defer = $q.defer();

            drbacService.getDrbacSchema(filter, forceReload)
                .then(function (data) {
                    vm.drbacSchema = data.results[0];
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getDrbacSchemaPermissionM2MDrbacRoleDtosBySchema(forceReload, drbacSchemaId) {
            var filter = {
                DrbacSchemaId: drbacSchemaId
            };
            var defer = $q.defer();

            drbacService.getDrbacSchemaPermissionM2MDrbacRoleDtosBySchema(filter, forceReload)
                .then(function (data) {
                    vm.drbacSchemaPermissionM2MDrbacRoles = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getDrbacRoles(forceReload) {
            var filter = {
                // get all
            };
            var defer = $q.defer();

            drbacService.getDrbacRoles(filter, forceReload)
                .then(function (data) {
                    vm.drbacRoles = data.results;

                    vm.drbacRolesObj = Nembus.Common.Library.parser.objFromList(vm.drbacRoles, 'RoleId', 'RoleName');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getDrbacPermissions(forceReload) {
            var filter = {
                // get all
            };
            var defer = $q.defer();

            drbacService.getDrbacPermissions(filter, forceReload)
                .then(function (data) {
                    vm.drbacPermissions = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getDrbacPermissionGroups(forceReload) {
            var filter = {
                // get all
            };
            var defer = $q.defer();

            drbacService.getDrbacPermissionGroups(filter, forceReload)
                .then(function (data) {
                    vm.drbacPermissionGroups = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getDrbacSchemaPermissions(forceReload, drbacSchemaId) {
            var filter = {
                DrbacSchemaId: drbacSchemaId
            };
            var defer = $q.defer();

            drbacService.getDrbacSchemaPermissions(filter, forceReload)
                .then(function (data) {
                    vm.drbacSchemaPermissions = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    drbacSchemaDetail.$inject = injectParams;
    drbacSchemaDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rbac.module').controller('drbacSchemaDetail', drbacSchemaDetail);
});