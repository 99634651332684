﻿'use strict';
define([], function () {

    var injectParams = [];
    var nbsMsBoxComponent = function () {

        var vm = this;
        vm.localDataSource = [];
        vm.ddIconType = '';
        
        vm.$onChanges = function (changes) {
            if (changes.uiContext && changes.uiContext.currentValue) {
                var xx = changes.uiContext.currentValue;
            }
            if (changes.nbsDataContext) {
                if (changes.nbsDataContext.currentValue) {
                    vm.localDataSource = changes.nbsDataContext.currentValue;
                }
                else {
                    vm.localDataSource = [];
                }
            }
        };

        vm.onOptionDeleteEvent = function (sourceOption) {
            vm.localDataSource = Lazy(vm.localDataSource).reject(i => { return i.optionValue == sourceOption.optionValue }).toArray();
            vm.optionDeleteEvent({ option: sourceOption });
            vm.optionChangesEvent({ options: vm.localDataSource });
        };

        vm.onOptionChangesMsEvent = function (sourceOptions) {
            vm.localDataSource = sourceOptions;
            vm.optionChangesEvent({ options: sourceOption });
        };
    };

    nbsMsBoxComponent.$inject = injectParams;
    //nbsMsBoxComponent.prototype = Object.create(nbsVm.prototype);
    //angular.module('nbs.rds.module').controller('nbsMsBoxComponent', nbsMsBoxComponent);
    angular.module('nbs.rds.module').component('nbsMsBox', {
        templateUrl: '/rds/common/components/nbsMsBoxComponent/_?c=' + window.codeCache(),
        controller: nbsMsBoxComponent,
        controllerAs: 'vm',
        bindings: {
            optionChangesEvent: '&',
            optionDeleteEvent: '&',
            nbsDataContext: "<", //[uiOptions,uiOptions]
            uiContext: "<"
        }
    });
});

