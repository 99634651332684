// @ts-ignore
var Lazy = require('lazy.js');
import { Directive, ElementRef, Injector } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { NbsVm2 } from '../../common/controllers/nbs-vm2';

class AppLightPanelController extends NbsVm2 {
    constructor($injector) {
        super(null, $injector)
    }
}

let AppLightPanelComponent = {
    selector: "appLightPanel",
    templateUrl: 'shell/navigation/appLightPanel/_',
    controller: AppLightPanelController,
    controllerAs: 'vm'
};

// @ts-ignore
angular.module('nbs.shell.module').component(AppLightPanelComponent.selector, AppLightPanelComponent);


@Directive({
    selector: 'app-light-panel'
})
export class AppLightPanelDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef, injector: Injector) {
        super('appLightPanel', elementRef, injector);
    }
}

