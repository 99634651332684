﻿'use strict';
define(['../../common/services/nbs-service'], function (nbsService) {

    var injectParams = ['$q', '$timeout', 'breezeDataLayer', 'nbsStorageService', 'localStorageService', 'authenticationService'];
    var contextSvc = function ($q, $timeout, breezeDataLayer, nbsStorageService, localStorageService, authenticationService) {

        var dataLayer = breezeDataLayer.Initialize('rbac', true);
        var manager = dataLayer.GetManager();
        var dataLayerFoundation = breezeDataLayer.Initialize('foundation', true);
        var managerFoundation = dataLayerFoundation.GetManager();

        var self = this;

        nbsService.call(this, manager, $q, $timeout);

        self.virtualContext = {};

        self.downloadFiles = {};
        self.downloadFiles.show = false;
        self.downloadFiles.files = [];
        self.batchRequests = {};
        self.batchRequests.show = false;
        self.batchRequests.requests = [];
        self.pollingBatchStarted = false;

        //self.loadingContext = false;

        //files downloads 
        this.addDownloadFile = function (filedata) {
            self.downloadFiles.files.push({
                fileId: filedata.fileId,
                fileName: filedata.fileName,
                fileType: filedata.fileType,
                downloadData: filedata.downloadData,
                hasError: filedata.hasError
            });
            self.downloadFiles.show = true;
        };

        //{
        //result:
        //concatName:
        //}
        this.addDownloadReportFile = function (rData) {
            var fname = null;
            if (rData.result.HasError) {
                var fileToDownload = {
                    //fileId: rData.result.ReportRequestId,
                    fileName: 'error',
                    fileType: '.error',
                    downloadData: {
                        hasError: true,
                        entityGuid: null,
                        asAttach: true,
                        downloadType: "",
                        filePath: rData.result.FileFullMetaPath,
                        displayFileName: "error"
                    }
                };
                //if rData.result.IsRealTime
                this.addDownloadFile(fileToDownload);
                return;
            }
            if (rData.replaceName && rData.replaceName !== '') {
                fname = rData.replaceName + '.' + rData.result.ReportName.substr(rData.result.ReportName.lastIndexOf('.') + 1);
            }
            else if (rData.concatName && rData.concatName !== '') {
                fname = rData.concatName + '-' + rData.result.ReportName;
            }
            else {
                fname = rData.result.ReportName;
            }
            fname = fname.replace(/[\|&;\$%@"<>\(\)\//+,]/g, "-");
            var fileToDownload = {
                fileId: rData.result.ReportRequestId,
                fileName: fname,
                fileType: rData.result.OutputFileType,
                downloadData: {
                    entityGuid: null,
                    asAttach: true,
                    downloadType: "byPath",
                    filePath: rData.result.FileFullMetaPath,
                    displayFileName: fname
                }
            };
            //if rData.result.IsRealTime
            this.addDownloadFile(fileToDownload);
        };

        this.addDownloadReportHtmlToDocFile = function (rData) {
            var fname = null;
            var fextension = null;
            if (rData.replaceName) {
                fname = rData.replaceName;
            } else if (rData.concatName) {
                fname = rData.concatName + '-' + rData.result.ReportDefinitionName;
            } else {
                fname = rData.result.ReportDefinitionName;
            }
            fname = fname.replace(/[\|&;\$%@"<>\(\)\//+,]/g, "-");
            fextension = rData.result.FileFullMetaPath.substr(rData.result.FileFullMetaPath.lastIndexOf("."), rData.result.FileFullMetaPath.length);

            fname = fname + fextension;

            var fileToDownload = {
                fileId: rData.result.ReportHtmlRequestId,
                fileName: fname,
                fileType: rData.result.OutputFileType,
                downloadData: {
                    entityGuid: null,
                    asAttach: true,
                    downloadType: "byPath",
                    filePath: rData.result.FileFullMetaPath,
                    displayFileName: fname
                }
            };
            //if rData.result.IsRealTime
            this.addDownloadFile(fileToDownload);
        };
        //reports request result downloads 


        this.clearDownloadFiles = function () {
            self.downloadFiles.files = [];
            self.downloadFiles.show = false;
        };

        this.addBatchRequest = function (request) {
            var currentReq = {
                requestId: request.requestId,
                requestName: request.requestName,
                requestStatus: request.requestStatus,
                detailAction: request.detailAction,
                onCompletedAutoAction: request.onCompletedAutoAction
            };

            self.batchRequests.requests.push(currentReq);
            self.batchRequests.show = true;
            if (self.controllerBatchPollingFunction && !self.pollingBatchStarted) {
                self.pollingBatchStarted = true;
                self.controllerBatchPollingFunction();
            }
            /*
            self.getBatchRequestsQueue(request)
                .then(function (res) {
                    currentReq.queue = [];
                    if (res.results && res.results.length > 0) {
                        Lazy(res.results).each((queueItem) => {
                            currentReq.queue.push({
                                requestId: queueItem.BatchRequestId,
                                requestName: queueItem.BatchRequestName,
                                requestStatus: self.getLocalBatchStatus(queueItem),
                                requestStartDate: queueItem.ProcessedDate ? ' - ' + Nembus.Common.Library.converter.date.formatTime(queueItem.ProcessedDate) : null
                            });
                        });

                    }
                    if (self.controllerBatchPollingFunction && !self.pollingBatchStarted) {
                        self.pollingBatchStarted = true;
                        self.controllerBatchPollingFunction();
                    }
                });
                */
        };


        this.clearBatchRequests = function () {
            self.batchRequests.requests = [];
            self.batchRequests.show = false;
            self.pollingBatchStarted = false;
        };

        this.controllerBatchPollingFunction = null;

        var entityTypes = {
            BatchRequest: 'BatchRequest'
        };
        var dataContext = {
            virtualContexts: {
                get: function () { return manager.getEntities('VirtualContextDto'); }
            },
            batchRequest: {
                get: function () { return managerFoundation.getEntities(entityTypes.BatchRequest); }
            }
        };

        self.updateUserSettingsLanguage = function (languageId, languageName) {
            self.virtualContext.CurrentLanguageId = languageId;
            self.virtualContext.CurrentLanguageName = languageName;
            self.virtualContext.cultureInfo.language = languageName;
            setClientContextLocal(self.virtualContext);
            var query = dataLayer.InvokeQuery("UpdateUserSettingsLanguage", { LanguageId: languageId });
            return manager.executeQuery(query);
        };

        self.getCurrentVirtualContext = function (forceReload) {
            var that = self;
            //that.loadingContext = true;
            var defer = $q.defer();
            if (!forceReload && that.virtualContext && that.virtualContext.CurrentVirtualCompanyId) {
                defer.resolve(that.virtualContext);
                return defer.promise;
            }
            if (!forceReload) {
                getClientContextLocal()
                    .then(function (contextLocal) {
                        if (!contextLocal) {
                            var query = dataLayer.CreateQuery("CurrentVirtualContext");
                            manager.executeQuery(query)
                                .then(function (data) {
                                    setContext(data)
                                        .then(function () {
                                            defer.resolve(that.virtualContext);
                                        })
                                        .catch(function (error) {
                                            //todo: manage error ?? how??
                                            defer.reject(error);
                                        });
                                })
                                .catch(function (error) {
                                    defer.reject(error);
                                });
                        }
                        else {
                            that.virtualContext = contextLocal;
                            defer.resolve(contextLocal);
                        }
                    })
                    .catch(function (error) {
                        defer.reject(error);
                    });

            }
            else {
                var query = dataLayer.CreateQuery("CurrentVirtualContext");
                manager.executeQuery(query)
                    .then(function (data) {

                        setContext(data)
                            .then(function () {
                                defer.resolve(that.virtualContext);
                            })
                            .catch(function (error) {
                                //todo: manage error ?? how??
                                defer.reject(error);
                            });
                    })
                    .catch(function (error) {
                        defer.reject(error);
                    });
            }
            return defer.promise;
        };

        //self.loadingVirtualContext = function () {
        //	return self.loadingContext;
        //}

        self.isVirtualContextAlreadySet = function () {
            if (self.virtualContext.CurrentVirtualCompanyId) {
                return true;
            }
            return false;
        };

        self.clearContext = function () {
            self.virtualContext = {};
        };


        function clearCacheKeys() {
            var keys = localStorageService.keys();
            for (var i = 0; i < keys.length; i++) {
                if (keys[i] !== 'authorizationData'
                    && keys[i].substring(0, 10) !== 'no-delete-') {
                    localStorageService.remove(keys[i]);
                }
            }
        }

        function setContext(data) {
            self.virtualContext.ClientCacheLastKey = data.results[0].ClientCacheLastKey;
            self.virtualContext.CurrentVirtualCompanyId = data.results[0].CurrentVirtualCompanyId;
            self.virtualContext.CurrentVirtualCompanyName = data.results[0].CurrentVirtualCompanyName;
            self.virtualContext.CurrentCustomerCode = data.results[0].CurrentCustomerCode;
            self.virtualContext.CurrentCustomerAppCode = data.results[0].CurrentCustomerAppCode;
            self.virtualContext.UserName = data.results[0].UserName;
            self.virtualContext.UserGuid = data.results[0].UserGuid;
            self.virtualContext.XCentralUserId = data.results[0].XCentralUserId;
            self.virtualContext.CurrentUserProfileName = data.results[0].CurrentUserProfileName;
            self.virtualContext.CurrentUserDisplayName = data.results[0].CurrentUserDisplayName;

            self.virtualContext.IsUa = data.results[0].IsUa;
            self.virtualContext.IsSa = data.results[0].IsSa;
            self.virtualContext.IsMa = data.results[0].IsMa;
            self.virtualContext.IsManageAllTargetCategories = data.results[0].IsManageAllTargetCategories;
            self.virtualContext.HaveToManageIdpUsers = data.results[0].HaveToManageIdpUsers;

            self.virtualContext.ProcessPermissions = JSON.parse(Nembus.Common.Library.converter.decodeHtml(data.results[0].ProcessPermissions));
            self.virtualContext.GlobalProcessPermissions = JSON.parse(Nembus.Common.Library.converter.decodeHtml(data.results[0].GlobalProcessPermissions));
            self.virtualContext.TargetModuleWithProcessEnabled = data.results[0].TargetModuleWithProcessEnabled ? JSON.parse(data.results[0].TargetModuleWithProcessEnabled) : [];

            self.virtualContext.IsLicenseValid = data.results[0].IsLicenseValid;

            self.virtualContext.CurrentAuthActionCodes = Nembus.Common.Library.converter.JsonFieldParse(data.results[0].CurrentAuthActionCodesSerialized);
            self.virtualContext.TargetCategories = Nembus.Common.Library.converter.JsonFieldParse(data.results[0].TargetCategoriesSerialized);
            self.virtualContext.CurrentLanguageId = data.results[0].CurrentLanguageId;
            self.virtualContext.CurrentLanguageName = data.results[0].CurrentLanguageName;

            self.virtualContext.AppStoresSerialized = Nembus.Common.Library.converter.decodeHtml(data.results[0].AppStoresSerialized);
            self.virtualContext.AppStores = Nembus.Common.Library.converter.JsonFieldParse(data.results[0].AppStoresSerialized);//JSON.parse(data.results[0].AppStoresSerialized)

            self.virtualContext.ModuleTargetsSerialized = Nembus.Common.Library.converter.decodeHtml(data.results[0].ModuleTargetsSerialized);
            self.virtualContext.ModuleTargets = Nembus.Common.Library.converter.JsonFieldParse(data.results[0].ModuleTargetsSerialized);//JSON.parse(data.results[0].ModuleTargetsSerialized)

            self.virtualContext.HrHierarchyConfigsSerialized = Nembus.Common.Library.converter.decodeHtml(data.results[0].HrHierarchyConfigsSerialized);
            self.virtualContext.HrHierarchyConfigs = Nembus.Common.Library.converter.JsonFieldParse(data.results[0].HrHierarchyConfigsSerialized);//JSON.parse(data.results[0].HrHierarchyConfigsSerialized)

            self.virtualContext.UriMan = data.results[0].UriMan;
            self.virtualContext.UriAdminMan = data.results[0].UriAdminMan;
            self.virtualContext.UriGoogleStaticMap = data.results[0].UriGoogleStaticMap;
            self.virtualContext.UriQrCodeService = data.results[0].UriQrCodeService;

            //self.virtualContext.UriStreamDownload = data.results[0].UriStreamDownload;
            //self.virtualContext.UriStreamUpload = data.results[0].UriStreamUpload;
            self.virtualContext.UriSupport = data.results[0].UriSupport;
            self.virtualContext.LastUserAgent = data.results[0].LastUserAgent;
            self.virtualContext.LastLogonDate = data.results[0].LastLogonDate;
            self.virtualContext.AuthSessionId = data.results[0].AuthSessionId;

            //cultureinfo
            self.virtualContext.cultureInfo = {
                moment: {}
            };
            self.virtualContext.cultureInfo.language = data.results[0].CurrentLanguageName; //manage standard language key...
            self.virtualContext.cultureInfo.moment.formatDate = "DD/MM/YYYY"; //manage based on language...
            self.virtualContext.cultureInfo.moment.formatDateTime = "DD/MM/YYYY HH:mm:ss"; //manage based on language...

            self.virtualContext.cultureInfo.formatTime = "HH:mm"; //manage based on language...
            self.virtualContext.cultureInfo.formatFullTime = "HH:mm:ss"; //manage based on language...
            self.virtualContext.cultureInfo.formatDate = "dd/MM/yyyy"; //manage based on language...
            self.virtualContext.cultureInfo.formatDateTime = "dd/MM/yyyy HH:mm:ss"; //manage based on language...

            //self.loadingContext = false;

            ////behaviors
            self.virtualContext.behaviors = Nembus.Common.Library.converter.JsonFieldParse(data.results[0].Behaviors);
            self.virtualContext.servervariables = data.results[0].ServerSystemVariablesSerialized;
            self.virtualContext.drbacusergroupids = Nembus.Common.Library.converter.JsonFieldParse(data.results[0].DrbacUserGroupIdsSerialized);
            self.virtualContext.appuserid = data.results[0].AppUserId;



            ////server vars
            Nembus.Common.Library.server.variables = Nembus.Common.Library.converter.JsonFieldParse(self.virtualContext.servervariables);
            setUiControlsDictionary(self.virtualContext.cultureInfo.language);

            var defer = $q.defer();
            setClientContextLocal(self.virtualContext)
                .then(function () { defer.resolve(); });
            return defer.promise;
        }

        function setClientContextLocal(clientcontext) {
            var defer = $q.defer();
            nbsStorageService.putContextData(
                {
                    datacode: 'clientcontext',
                    usercode: authenticationService.user.userName,
                    cacheCode: clientcontext.ClientCacheLastKey,
                    vc: authenticationService.user.virtualContextId
                }
                , clientcontext)
                .then(function () {
                    defer.resolve();

                })
                .catch(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getClientContextLocal() {
            var defer = $q.defer();
            nbsStorageService.getContextData(
                {
                    datacode: 'clientcontext',
                    usercode: authenticationService.user.userName,
                    cacheCode: '',
                    vc: authenticationService.user.virtualContextId
                })
                .then(function (res) {
                    if (res) {
                        Nembus.Common.Library.server.variables = Nembus.Common.Library.converter.JsonFieldParse(res.value.servervariables);
                        setUiControlsDictionary(res.value.cultureInfo.language);
                        defer.resolve(res.value);
                    }
                    else {
                        defer.resolve();
                    }
                })
                .catch(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        }

        //self.setNavNodesLocal = function (navNodes) {
        //    var defer = $q.defer();
        //    nbsStorageService.putContextData(
        //        {
        //            datacode: 'navnode',
        //            usercode: self.virtualContext.UserGuid,
        //            cacheCode: self.virtualContext.ClientCacheLastKey,
        //            vc: self.virtualContext.CurrentVirtualCompanyId,
        //            lang: self.virtualContext.CurrentLanguageName
        //        }
        //        , navNodes)
        //        .then(function () {
        //            defer.resolve();

        //        })
        //        .catch(function (error) {
        //            defer.reject(error);
        //        });

        //    return defer.promise;
        //};

        //self.getNavNodesLocal = function () {
        //    var defer = $q.defer();
        //    var that = self;
        //    nbsStorageService.getContextData(
        //        {
        //            datacode: 'navnode',
        //            usercode: that.virtualContext.UserGuid,
        //            cacheCode: that.virtualContext.ClientCacheLastKey,
        //            vc: that.virtualContext.CurrentVirtualCompanyId,
        //            lang: that.virtualContext.CurrentLanguageName
        //        })
        //        .then(function (res) {
        //            if (res) {
        //                defer.resolve(res.value);
        //            }
        //            else {
        //                defer.resolve();
        //            }
        //        })
        //        .catch(function (error) {
        //            defer.reject(error);
        //        });

        //    return defer.promise;
        //};

        self.setCmsClientDataLocal = function (cmsClientData) {
            var defer = $q.defer();
            var that = self;
            nbsStorageService.putContextData(
                {
                    datacode: 'cmsclientdata',
                    usercode: that.virtualContext.UserGuid,
                    cacheCode: that.virtualContext.ClientCacheLastKey,
                    lang: that.virtualContext.CurrentLanguageName
                    //vc: self.virtualContext.CurrentVirtualCompanyId
                }
                , cmsClientData)
                .then(function () {
                    defer.resolve();
                })
                .catch(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        };

        self.getCmsClientDataLocal = function () {
            var defer = $q.defer();
            var that = self;
            nbsStorageService.getContextData(
                {
                    datacode: 'cmsclientdata',
                    usercode: that.virtualContext.UserGuid,
                    cacheCode: that.virtualContext.ClientCacheLastKey,
                    lang: that.virtualContext.CurrentLanguageName
                    //vc: self.virtualContext.CurrentVirtualCompanyId
                })
                .then(function (res) {
                    if (res) {
                        defer.resolve(res.value);
                    }
                    else {
                        defer.resolve();
                    }
                })
                .catch(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        };

        self.setAppCmsClientData = function (localCmsClientData) {
            Nembus.Common.Library.AppModel.cmsDataCompleteCodeObject = {};
            Nembus.Common.Library.AppModel.cmsDataShortCodeObject = {};
            Nembus.Common.Library.AppModel.globalThesaurusObject = {};

            if (localCmsClientData) {
                Lazy(Object.keys(localCmsClientData))
                    .each((prop) => {
                        Nembus.Common.Library.AppModel.cmsDataCompleteCodeObject[prop] = localCmsClientData[prop];
                        Nembus.Common.Library.AppModel.cmsDataShortCodeObject[Nembus.Common.Library.AppModel.cmsDataCompleteCodeObject[prop].CmsViewShortCode] = localCmsClientData[prop];
                    });

                if (Nembus.Common.Library.AppModel.cmsDataCompleteCodeObject && Nembus.Common.Library.AppModel.cmsDataCompleteCodeObject.Global) {
                    Nembus.Common.Library.AppModel.globalThesaurusObject = Nembus.Common.Library.AppModel.cmsDataCompleteCodeObject.Global.CmsViewThesaurus;
                }
            }
            //Nembus.Common.Library.AppModel.cmsData = localCmsClientData;
            if (self.virtualContext && self.virtualContext.cultureInfo) {
                setUiControlsDictionary(self.virtualContext.cultureInfo.language);
            }
        };

        function setUiControlsDictionary(cultureCode) {
            ej.globalize.setCulture(cultureCode);
            ej.setCulture(cultureCode);
        }

        this.getBatchRequestsIdToCheck = function (requests) {
            var reqs = [];
            Lazy(requests)
                .each((item) => {
                    if (!(item.requestStatus === 'completed' || item.requestStatus === 'error')) {
                        if (!Lazy(reqs).contains(item.requestId)) {
                            reqs.push(item.requestId);
                        }
                        /*
                        if (item.queue) {
                            Lazy(item.queue).each((queueItem) => {
                                if (!Lazy(reqs).contains(queueItem.requestId)) {
                                    reqs.push(queueItem.requestId);
                                }
    
                            });
                            
                        }*/
                    }
                });
            return reqs;
        };


        this.checkBatchRequests = function (batchRequestsIdToCheck) {
            if (batchRequestsIdToCheck.length > 0) {
                var filter = { BatchRequestsIdStr: batchRequestsIdToCheck, sxb: 'x' };
                var params = { entityType: entityTypes.BatchRequest, cache: dataContext.batchRequest.get() };
                return dataLayerFoundation.GetDataListPromise(params, filter, true, "BatchRequests");
            }
            else {
                var defer = $q.defer();
                defer.resolve([]);
                return defer.promise;
            }
        };

        this.getBatchRequestsQueue = function (request) {
            var reqs = [];
            reqs.push(request.requestId);
            var filter = { BatchRequestsIdStr: reqs, sxb: 'x' };

            var params = { entityType: entityTypes.BatchRequest, cache: dataContext.batchRequest.get() };
            return dataLayerFoundation.GetDataListPromise(params, filter, true, "BatchRequestsQueue");
        };

        // Inserted = 2,
        // InProgress = 3,
        // Solved = 4,
        // Started = 5,
        this.updateBatchRequestStatus = function (requests) {
            var nl = [];
            Lazy(self.batchRequests.requests)
                .each((localItem) => {
                    var reqFromServer = Lazy(requests).find({ BatchRequestId: localItem.requestId });
                    if (reqFromServer) {

                        if (reqFromServer.BatchRequestTypeId == 128) {
                            localItem.requestId = reqFromServer.BatchRequestDescription.substr(reqFromServer.BatchRequestDescription.length - 36, 36); //get new batchrequestid!!
                        }
                        else {
                            localItem.requestStatus = self.getLocalBatchStatus(reqFromServer);
                        }
                    }
                    nl.push(localItem);
                });

            self.batchRequests.requests = nl;
        };

        this.getLocalBatchStatus = function (reqFromServer) {
            if (reqFromServer.BatchRequestStatusId === 4) {
                if (reqFromServer.HasError) {
                    return 'error';
                }
                else {
                    return 'completed';
                }


            }
            if (reqFromServer.BatchRequestStatusId === 3) {
                return 'inprogress';
            }
            if (reqFromServer.BatchRequestStatusId === 2) {
                return 'inserted';
            }
            if (reqFromServer.BatchRequestStatusId === 5) {
                return 'inprogress';
            }
        };

        this.switchLanguage = function (data) {
            var nl = [];
            for (var i = 0; i < self.batchRequests.requests.length; i++) {
                var currentReq = self.batchRequests.requests[i];
                var reqFromServer = Lazy(requests).findWhere({
                    BatchRequestId: currentReq.requestId
                });
                if (reqFromServer) {
                    if (reqFromServer.BatchRequestStatusId === 4) {
                        currentReq.requestStatus = 'completed';
                    }
                }
                nl.push(currentReq);
            }
            self.batchRequests.requests = nl;
        };

        this.pollingIsActive = function () {
            var isActive = Lazy(self.batchRequests.requests).some(function (r) { return r.requestStatus !== 'completed'; }) && self.batchRequests.show;
            if (!isActive) {
                self.pollingBatchStarted = false;
            }
            return isActive;
        };
    };

    contextSvc.$inject = injectParams;
    contextSvc.prototype = Object.create(nbsService.prototype);
    angular.module('nbs.shared.module').service('contextService', contextSvc);

});