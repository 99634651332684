﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$locale', '$scope', '$timeout', '$injector', 'utilService', 'vhreventService', 'foundationService', 'audService', 'reportService', 'navigationService', 'checkStateChangeService', 'contextService', 'rbacService'];
    var htmlToPdfSurveyQuestionOptionDistributionTemporalyReportForm = function ($q, $locale, $scope, $timeout, $injector, utilService, vhreventService, foundationService, audService, reportService, navigationService, checkStateChangeService, contextService, rbacService) {

        var vm = this;
        nbsVm.call(vm, audService, navigationService, contextService, $q, $injector, $timeout);
        var checkStateChangeParams = { dirtyFlagIsDisabled: vm.isDirtyDisabled };
        checkStateChangeService.checkFormOnStateChange($scope, audService, checkStateChangeParams);

        // all form items are enabled
        vm.setIsEditingReport();

        var appStoreCode = 'aud';
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var stepsToRender = [];
        var surveyLifeStatusEnumObj = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyLifeStatus, null, vm.translate).obj.normal.name_value;
        var expressionTypeEnum = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.ExpressionType, null, vm.translate).obj.normal.name_value;
        var pFilter = {};
        var sections = [];
        var questions = [];
        var questionOptions = [];

        vm.tabs = { form: 0, list: 1 };
        vm.activeTabIndex = 0;

        vm.tabOnSelect = function (tab) {
            switch (tab) {
                case vm.tabs.form:
                    vm.showForm = true;
                    vm.showList = false;
                    break;
                case vm.tabs.list:
                    vm.showForm = false;
                    vm.showList = true;
                    break;
            }

            vm.activeTabIndex = tab;
        };
        vm.tabOnSelect(vm.tabs.form);

        var dsCallback = function () { return vhreventService.getVhrEventTypes({ AppStoreBw: appStoreBw }, false); };
        var selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventType', viewTitle: vm.translate('SurveyDto.VhrEventTypeId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventType = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        dsCallback = function () { return vhreventService.getVhrEventGroups({ AppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventGroup', viewTitle: vm.translate('SurveyDto.VhrEventGroupId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventGroup = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        dsCallback = function () { return foundationService.getVhrTypes(false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrType', viewTitle: vm.translate('TargetDto.TargetTypeId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrType = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        dsCallback = function () { return foundationService.getVhrGroups(false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrGroup', viewTitle: vm.translate('TargetDto.TargetGroupId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrGroup = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        vm.nbsSurveyDrbacModalObj = new NbsAssigneeSelectorModal({
            translator: vm.translate,
            suspendDirty: vm.suspendDirty,
            navigationService: navigationService,
            contextUserForSelection: null,
            getServerVarFn: Nembus.Common.Library.server.getServerVar
        });
        vm.nbsSurveyDrbacModalObj.setVisibilityServerVar('ssv_audit_survey_assignee_visibility_selection');

        vm.nbsAuthorDrbacModalObj = new NbsAssigneeSelectorModal({
            translator: vm.translate,
            suspendDirty: vm.suspendDirty,
            navigationService: navigationService,
            contextUserForSelection: null,
            getServerVarFn: Nembus.Common.Library.server.getServerVar
        });
        vm.nbsAuthorDrbacModalObj.viewUsers();

        vm.bindObject = {
            surveys: [],
            filters: {}   // filters binded on UI form
        };

        vm.refreshBindFilters = function () {
            vm.bindObject.filters.VhrTargetsName = Lazy(vm.nbsSelectionGridWithModalTarget.selectedItems).pluck('nbs_name').join(',');
            vm.bindObject.filters.SurveysName = Lazy(vm.nbsSelectionGridWithModalSurvey.selectedItems).pluck('nbs_name').join(',');
        };


        vm.currentForm = audService.dataContext.htmlToPdfSurveyQuestionOptionDistributionTemporalyClientDatas.add({
            Id: NbsGuidManager.newGuid(),
            IsArchived: null
        });


        
        vm.parentScope = $scope.$parent.vm;
        vm.parentScope.ribbonDatacontext.setRibbonStatusButton(vm.parentScope.ribbonDatacontext.Buttons.Search, true);
        vm.parentScope.ribbonDatacontext.refreshStatusButtons();


         // REFERS to 'reportDefinitionHtmlToDocDetail'
        vm.parentScope.initReportToDocHeader({ onSearchActionHtmlToDocFn: onActionSearch, onCreateHtmlFn: createHtmlReport });


        selectionParams = { destModalKey: 'vhr', entityMode: 'Target', translateKey: 'Target', isMultiselect: true };
        vm.nbsSelectionGridWithModalTarget = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalTarget.setOnSelectFnCallback(vm.refreshBindFilters);

        selectionParams = { stateTo: 'app.nav.surveyslistmodal', destModalKey: 'survey', dataKey: 'surveycompleted', filter: { VhrEventLifeStatusIds: [surveyLifeStatusEnumObj.ReportedAsFinished] } };
        vm.nbsSelectionGridWithModalSurvey = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalSurvey.setOnSelectFnCallback(vm.refreshBindFilters);
        
        // set title of current controller in report definition detail view
        navigationService.setControllerTitle();

        vm.gridSelection = function (args) {
            $timeout(() => {
                selectedSurveys = args.data;
                selectedSurveyIds = args.dataIds;

                setPrintButtonStatus();
            });
        }


        vm.surveys = [];

        function setPrintButtonStatus() {
            var selected = Lazy(selectedSurveys).some();
            vm.parentScope.ribbonDatacontext.setRibbonStatusButton(vm.parentScope.ribbonDatacontext.Buttons.PrintReportPdf, selected);
            vm.parentScope.ribbonDatacontext.refreshStatusButtons();
        }

        var selectedSurveyIds = [];
        var selectedSurveys = [];

        var nbsGridDatacontext = new NbsAudGrid(vm.translate);
        nbsGridDatacontext.configureGrid('survey', 'kpireport', null);
        nbsGridDatacontext.setGridIsUnderTab();
        nbsGridDatacontext.setIsMultiselect();
        vm.nbsGridDatacontext = nbsGridDatacontext;

        function onActionSearch() {
            vm.currentForm.entityAspect.validateEntity();
            vm.forceDatasourceLocal = {
                refreshkey: new Date().getTime(),
                dataSource: []
            }

            var defer = $q.defer();
            if (vm.currentForm.entityAspect.hasValidationErrors) {
                $timeout(() => { /* implicit scope apply; */ });
                return defer.promise;
            }

            selectedSurveyIds = [];
            selectedSurveys = [];


            pFilter = {
                surveyFilter: {
                    SurveyCode: vm.currentForm.SurveyCode,
                    IsArchived: vm.currentForm.IsArchived,
                    IsCompleted: true
                },
                surveySectionFilter: {},
                vhrEventFilter: {
                    ModuleTargetId: moduleTargetId,
                    VhrEventTypesId: vm.nbsSelectionGridWithModalVhrEventType.selectedIds,
                    VhrEventGroupsId: vm.nbsSelectionGridWithModalVhrEventGroup.selectedIds,
                    VirtualHumanResourcesId: vm.nbsSelectionGridWithModalTarget.selectedIds,
                    HaveToExpandVhrsWithAssetTree: true,
                    AppUserAssignedIds: vm.nbsSurveyDrbacModalObj.appUserAreSelected() ? vm.nbsSurveyDrbacModalObj.appUserMultiselectSelectedIds() : null,
                    UserGroupAssignedIds: vm.nbsSurveyDrbacModalObj.drbacUserGroupAreSelected() ? vm.nbsSurveyDrbacModalObj.drbacUserGroupMultiselectSelectedIds() : null
                },
                surveyVhrEventFilter: {
                    CompilerUserPersonIds: vm.nbsAuthorDrbacModalObj.appUserAreSelected() ? vm.nbsAuthorDrbacModalObj.appUserMultiselectSelectedIds() : null,
                    SurveyIds: vm.nbsSelectionGridWithModalSurvey.selectedIds,
                    SurveyCompileDateSystem1: vm.currentForm.SurveyCompileDateSystem1,
                    SurveyCompileDateSystem1Expression: expressionTypeEnum.GreaterThanOrEqual,
                    SurveyCompileDateSystem2: vm.currentForm.SurveyCompileDateSystem2,
                    SurveyCompileDateSystem2Expression: expressionTypeEnum.LessThanOrEqual,

                },
                virtualHrFilter: {
                    VhrGroupsId: vm.nbsSelectionGridWithModalVhrGroup.selectedIds,
                    VhrTypesId: vm.nbsSelectionGridWithModalVhrType.selectedIds
                },
                surveyQuestionFilter: {
                },
                surveyQuestionOptionFilter: {
                }
            };

            // reset result search
            vm.surveys = [];

            searchSurveys(pFilter)
                .then(function (surveys) {
                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: surveys
                    }

                    vm.surveys = surveys;

                    setPrintButtonStatus();

                    vm.tabOnSelect(vm.tabs.list);

                    defer.resolve(null);
                });

            return defer.promise;
        }

        function searchSurveys(filter) {
            var defer = $q.defer();
            audService.searchSurveys(filter)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        loadData(true);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(setLocalDropDowns());
            
            $q.all(funcsPromises)
                .then(function () {
                    vm.currentForm.IsImportant = false;
                    vm.currentForm.GroupingId = 'week';
                    vm.currentForm.SortFieldId = 'questionnumber';
                    vm.currentForm.SortTypeId = 'Ascending';
                    vm.refreshBindFilters();
                });
        }

        function createHtmlReport() {
            var funcsPromises = [];
            // used post method for querystring length error
            funcsPromises.push(getSurveySectionsPostMethod(selectedSurveyIds));
            funcsPromises.push(getSurveyQuestionsPostMethod(selectedSurveyIds));
            funcsPromises.push(getSurveyQuestionOptionsPostMethod(selectedSurveyIds));

            $q.all(funcsPromises)
                .then(function () {
                    parseReportHtmlDatasource(selectedSurveys, sections, questions, questionOptions);
                });
        }

        // prepare datasource for report in UI (to prepare Html)
        vm.htmlSteps = {
            TABLEDATAS: 'tabledatas-render'
        };

        function parseReportHtmlDatasource(surveys, sections, questions, questionOptions) {
            setReportFilterData();

            stepsToRender = [];

            // group sorting
            var descending = false;
            if (vm.currentForm.SortTypeId) {
                descending = vm.currentForm.SortTypeId === 'Descending';
            }

            var ds = Nembus.Aud.Library.HtmlToDocReport.SurveyQuestionOptiondistributionDatasource($locale, surveys, sections, questions, questionOptions, vm.currentForm.IsImportant, vm.currentForm.GroupingId, vm.currentForm.SortFieldId, descending);
            createColumnChart(ds.chartPoints);
            createParetoChart(ds.paretoChartPoints);


            // bind data to table (grouping options)
            vm.bindObject.tableDatas = ds.tableDatas;

            if (ds.tableDatas.length > 0) {
                stepsToRender.push(vm.htmlSteps.TABLEDATAS);
            }

            // set steps to render before html retrieve from directive
            vm.parentScope.htmlToDocManager.setHtmlStepsToRender(stepsToRender);

            if (!vm.parentScope.htmlToDocManager.hasHtmlStepsToRender()) {
                vm.parentScope.htmlToDocManager.htmlIsRendered();
            }
        }

        function setReportFilterData() {
            vm.reportFilterData = new NbsReportFilterData(vm.translate, contextService.virtualContext.cultureInfo, Nembus.Common.Library.converter.date.formatDate);

            // filter in report are visualized in current order 
            //Audit
            vm.reportFilterData.setDataByRangeDate('SurveyCompileDateSystem', 'SurveyDto.SurveyCompileDate', vm.currentForm.SurveyCompileDateSystem1, vm.currentForm.SurveyCompileDateSystem2);
            vm.reportFilterData.setDataByString('SurveyCode', 'SurveyDto.SurveyCode', vm.currentForm.SurveyCode);
            vm.reportFilterData.setDataByObjectList('VhrEventGroupId', 'SurveyDto.VhrEventGroupId', [vm.currentForm.VhrEventGroupId], vm.vhrEventGroups, 'VhrEventGroupId', ['VhrEventGroupName']);
            vm.reportFilterData.setDataByObjectList('VhrEventTypeId', 'SurveyDto.VhrEventTypeId', [vm.currentForm.VhrEventTypeId], vm.vhrEventTypes, 'VhrEventTypeId', ['VhrEventTypeName']);
            vm.reportFilterData.setDataByObjectList('TargetGroupId', 'TargetGroup', [vm.currentForm.VhrGroupId], vm.vhrGroups, 'VhrGroupId', ['VhrGroupName']);
            vm.reportFilterData.setDataByObjectList('TargetTypeId', 'TargetType', [vm.currentForm.VhrTypeId], vm.vhrTypes, 'VhrTypeId', ['VhrTypeName']);
            vm.reportFilterData.setDataByBoolean('IsArchived', 'SurveyDto.IsArchived', vm.currentForm.IsArchived);
            vm.reportFilterData.setDataByDrbacAuthSelectorModal('CompilerUserPersonId', 'SurveyVhrEvent.CompilerUserPersonId', vm.nbsAuthorDrbacModalObj);
            vm.reportFilterData.setDataBySelectionModal('TargetId', 'SurveyDto.TargetId', vm.nbsSelectionGridWithModalTarget);
            vm.reportFilterData.setDataByDrbacAuthSelectorModal('AudAssignee', 'AudAssignee', vm.nbsSurveyDrbacModalObj);

            //Report
            vm.reportFilterData.setDataByObjectList('Grouping', 'Grouping', [vm.currentForm.GroupingId], vm.groupings, 'value', ['name']);
            vm.reportFilterData.setDataByBoolean('IsImportant', 'HtmlToPdfSurveyQuestionOptionDistributionTemporalyReportForm.HtmlToPdfSurveyQuestionOptionDistributionTemporalyReportFormQuestionOptionActive', vm.currentForm.IsImportant);
            vm.reportFilterData.setDataByObjectList('Sorting', 'Sorting', [vm.currentForm.SortFieldId], vm.sortFields, 'value', ['name']);
            vm.reportFilterData.setDataByObjectList('SortingTypeId', 'SortingType', [vm.currentForm.SortTypeId], vm.sortTypes, 'value', ['name']);
        }

        function createColumnChart(points) {
            var maxPoint = Lazy(points).pluck('y').max();

            vm.chartobj = {
                //Initializing Primary X Axis	
                primaryXAxis: {
                    title: {
                        text: vm.bindObject.filters.GroupingName
                    },
                    valueType: 'category'
                },

                //Initializing Primary Y Axis	
                primaryYAxis: {
                    title: {
                        text: vm.translate('Number')
                    },
                    range: { min: 0, max: maxPoint }
                },

                //Initializing Common Properties for all the series
                commonSeriesOptions:
                {
                    type: 'column',
                    enableAnimation: false,
                    marker: { dataLabel: { visible: true, showEdgeLabels: true } }
                },

                //Initializing Series
                series:
                    [
                        {
                            points: points,
                            fill: "#0288D1"
                        }
                    ],
                load: "loadTheme",
                isResponsive: true,
                title: { text: '' },
                legend: { visible: false }
            };
        }

        function createParetoChart(points) {
            var maxPoint = Lazy(points.linePoints).pluck('y').max();

            vm.paretoChartObj = {
                //Initializing Primary X Axis	
                primaryXAxis: {
                    title: {
                        text: 'Indice',
                        interval: 1
                    },
                    range: { interval: 1 },
                    valueType: 'category'
                },

                //Initializing Primary Y Axis	
                primaryYAxis: {
                    title: {
                        text: vm.translate('Number')
                    },
                    range: { min: 0, max: maxPoint }
                },

                //Initializing Common Properties for all the series
                commonSeriesOptions:
                {
                    type: 'column',
                    enableAnimation: false,
                    marker: { dataLabel: { visible: true, showEdgeLabels: true } }
                },

                //Initializing Series
                series:
                    [
                        {
                            points: points.columnPoints,
                            fill: "#0288D1",
                            type: 'column'
                        },
                        {
                            points: points.linePoints,
                            fill: "red",
                            type: 'line'
                        }
                    ],
                load: "loadTheme",
                isResponsive: true,
                title: { text: '' },
                legend: { visible: false }
            };
        }

        function setLocalDropDowns() {
            var defer = $q.defer();

            vm.sortFields = [];
            vm.sortFields.push({ value: 'questionnumber', name: 'Numero domanda' });
            vm.sortFields.push({ value: 'question', name: 'Domanda' });
            vm.sortFields.push({ value: 'questionoption', name: vm.translate('QuestionOption') });
            vm.sortFields.push({ value: 'value', name: vm.translate('Value') });

            vm.sortTypes = [];
            vm.sortTypes.push({ value: 'Ascending', name: vm.translate('Ascending') });
            vm.sortTypes.push({ value: 'Descending', name: vm.translate('Descending') });

            vm.groupings = [];
            vm.groupings.push({ value: 'week', name: vm.translate('Week') });
            vm.groupings.push({ value: 'month', name: vm.translate('Month') });
            vm.groupings.push({ value: 'year', name: vm.translate('Year') });

            defer.resolve(true);
        }

        function getSurveySectionsPostMethod(surveysId) {
            var filter = { surveyFilter: { SurveysId: surveysId }, vhrEventFilter: { ModuleTargetId: moduleTargetId } };
            var defer = $q.defer();

            audService.getSurveySectionsPostMethod(filter)
                .then(function (data) {
                    sections = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getSurveyQuestionsPostMethod(surveysId) {
            var filter = { surveyFilter: { SurveysId: surveysId }, vhrEventFilter: { ModuleTargetId: moduleTargetId } };
            var defer = $q.defer();

            audService.getSurveyQuestionsPostMethod(filter)
                .then(function (data) {
                    questions = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getSurveyQuestionOptionsPostMethod(surveysId) {
            var filter = { surveyFilter: { SurveysId: surveysId }, vhrEventFilter: { ModuleTargetId: moduleTargetId } };

            //if (vm.currentForm.IsImportant !== null && vm.currentForm.IsImportant !== undefined) {
            //    filter.surveyQuestionOptionFilter = { IsImportant: vm.currentForm.IsImportant };
            //}
            var defer = $q.defer();

            audService.getSurveyQuestionOptionsPostMethod(filter)
                .then(function (data) {
                    questionOptions = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }
    };

    htmlToPdfSurveyQuestionOptionDistributionTemporalyReportForm.$inject = injectParams;
    htmlToPdfSurveyQuestionOptionDistributionTemporalyReportForm.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('htmlToPdfSurveyQuestionOptionDistributionTemporalyReportForm', htmlToPdfSurveyQuestionOptionDistributionTemporalyReportForm);
});
