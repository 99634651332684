﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'drbacService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function drbacSchemaList($q, $scope, $timeout, $injector, drbacService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, drbacService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return drbacSchemaSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, drbacService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
        var drbacSchemaSelected = null;

        ribbon.setRibbonStatusButton(ribbon.Buttons.New, false);
        ribbon.refreshStatusButtons();

        vm.drbacSchemas = [];

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('drbacschema', null);
        nbsGridDatacontext.viewCommonBar();
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getDrbacConfig(forceReload, virtualCompanyId));
            funcsPromises.push(getDrbacSchemas(forceReload, virtualCompanyId));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.drbacConfig = results[0];

                    Lazy(results[1])
                        .each((drbacSchema) => {
                            drbacSchema.IsActive = drbacSchema.DrbacSchemaId === vm.drbacConfig.ActiveDrbacSchemaId;
                        });

                    vm.drbacSchemas = results[1];

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.drbacSchemas
                    }
                });
        }

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.drbacschemadetail", { 'drbacSchemaId': args.data.DrbacSchemaId });
        };

        vm.refreshAction = function () {
            loadData(true, virtualCompanyId);
        };

        vm.deleteAction = function () {
            if (!drbacSchemaSelected)
                return;

            drbacService.deleteEntity(drbacSchemaSelected);
            vm.submitAction()
                .then(function (saveResult) {
                    vm.refreshAction();
                });
        };

        vm.gridSelection = function (args) {
            $timeout(() => {
                drbacSchemaSelected = args.data;

                if (drbacSchemaSelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, !drbacSchemaSelected.IsSystem);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, true);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, false);
                }

                ribbon.refreshStatusButtons();
            });
        };

        vm.copyAction = function () {
            if (!drbacSchemaSelected) {
                return;
            }

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        var filter = {
                            DrbacSchemaId: drbacSchemaSelected.DrbacSchemaId,
                            VirtualCompanyId: virtualCompanyId
                        };

                        drbacService.cloneDrbacSchema(filter)
                            .then(function (result) {
                                vm.refreshAction();
                                drbacSchemaSelected = null;
                            });
                    }
                });
        };

        function getDrbacConfig(forceReload, virtualCompanyId) {
            var filter = {
                VirtualCompanyId: virtualCompanyId
            };
            var defer = $q.defer();

            drbacService.getDrbacConfig(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results[0]);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getDrbacSchemas(forceReload, virtualCompanyId) {
            var filter = {
                VirtualCompanyId: virtualCompanyId
            };
            var defer = $q.defer();

            drbacService.getDrbacSchemas(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    }

    drbacSchemaList.$inject = injectParams;
    drbacSchemaList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rbac.module').controller('drbacSchemaList', drbacSchemaList);
});