﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'drbacService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function drbacProcessConfigList($q, $scope, $timeout, $injector, drbacService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, drbacService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return groupSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, drbacService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var groupSelected = null;

        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
        ribbon.setRibbonStatusButton(ribbon.Buttons.New, false);
        ribbon.refreshStatusButtons();

        vm.drbacProcessConfigs = [];

        // moduletargets
        vm.moduleTargetsObj = Nembus.Common.Library.parser.objFromList(contextService.virtualContext.ModuleTargets, 'ModuleTargetId', 'ModuleTargetName');

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getDrbacProcessConfigs(false, virtualCompanyId));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.drbacProcessConfigs = results[0];

                    var nbsGridDatacontext = new NbsGrid(vm.translate);
                    nbsGridDatacontext.configureGrid('drbacprocessconfig', null);
                    nbsGridDatacontext.viewCommonBar();
                    nbsGridDatacontext.setDatasource(vm.drbacProcessConfigs, false);
                    vm.nbsGridDatacontext = nbsGridDatacontext;
                });
        }

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.drbacprocessconfigdetail", { 'drbacProcessConfigId': args.data.DrbacProcessConfigId });
        };

        vm.refreshAction = function () {
            getDrbacProcessConfigs(true, virtualCompanyId)
                .then((results) => {
                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: results
                    }
                });
        };

        vm.gridSelection = function (args) {
            groupSelected = args.data;
        };

        function getDrbacProcessConfigs(forceReload, virtualCompanyId) {
            var filter = {
                VirtualCompanyId: virtualCompanyId,
                IsGlobalProcessConfig: false
            };
            var defer = $q.defer();

            drbacService.getDrbacProcessConfigs(filter, forceReload)
                .then(function (data) {
                    Lazy(data.results)
                        .each((item) => {
                            item.ModuleTargetName = vm.moduleTargetsObj[item.ModuleTargetId];
                        });
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    }

    drbacProcessConfigList.$inject = injectParams;
    drbacProcessConfigList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rbac.module').controller('drbacProcessConfigList', drbacProcessConfigList);
});