﻿'use strict';
//check authorization client side
define(['../../common/services/nbs-service'], function (nbsService) {

	var injectParams = ['$q', '$timeout', 'breezeDataLayer', '$injector'];
    var watchdogService = function ($q, $timeout, breezeDataLayer, $injector) {
        var self = this;
        var dataLayer = breezeDataLayer.Initialize('rbac', true);
        var manager = dataLayer.GetManager();
        nbsService.call(this, manager, $q, $timeout);
        var contextService;
        contextService = contextService || $injector.get('contextService');


        self.hasViewAuth = function (toState) {
            if (toState.routeParams) {
                //todo: add in route resolver flag to bypass auth for this states
                if (toState.name.indexOf('authorizationdenied') > 0
                    || toState.name.indexOf('login') > 0
                    || toState.name.indexOf('loginnormal') > 0
                    || toState.name.indexOf('testidp') > 0
                    || toState.name.indexOf('publicmobileappconfig') > 0
                    || toState.name.indexOf('oidcresponse') > 0
                    || toState.name.indexOf('modulehome') > 0
                    || toState.name.indexOf('changemypassword') > 0
                    || toState.name.indexOf('myaccount') > 0
                    || toState.name.indexOf('renewmytwofactorsecretcode') > 0
                    || toState.name.indexOf('dashboard') > 0
                    || toState.name.indexOf('maintenance') > 0
                    || toState.name.indexOf('publicnoauth') > 0
                    || toState.name.indexOf('redir') > 0
                    || toState.name.indexOf('resetpassword') > 0
                ) {
                    return true;
                }

                var viewData = $CmsViewData(toState.routeParams.cmsViewCode);
                if (!viewData) {
                    return true;
                }
                var authResult = Nembus.Common.Library.auth.checkViewAuth(viewData, contextService.virtualContext);
                if (authResult.permissionCodeNotSet) {
                    console.log("permission not set for view " + toState.routeParams.cmsViewCode);
                }
                return authResult.verified;
            }
        };

        self.authAction = function (entity, authView) {
            var auth = {
                name: "",
                namespace: "",
                create: false,
                read: false,
                update: false,
                delete: false,
                actionsBw: 0
            };
            var actBw;

            if (!entity) {
                console.log("auth entity not found");
                actBw = 0xFFFFFFFF;
                if (authView.IgnoreAuth) {
                    actBw = 0xFFFFFFFF;
                }
                else if (contextService.virtualContext.IsAuthShaped) {
                    actBw = contextService.virtualContext.ShapingValueBw;
                }

                auth.actionsBw = actBw;
                auth.create = (actBw & 1) > 0;
                auth.read = (actBw & 2) > 0;
                auth.update = (actBw & 4) > 0;
                auth.delete = (actBw & 8) > 0;
                auth.actionsBw = actBw;

                return auth;
            }
            auth.name = entity.shortName.toLowerCase();
            auth.namespace = entity.namespace.toLowerCase();

            if (contextService.virtualContext.IsAuthShaped) {
                actBw = contextService.virtualContext.ShapingValueBw;
            }
            else if (contextService.virtualContext.IsAdministrator) {
                actBw = 0xFFFFFFFF;

            }
            else if (contextService.virtualContext.CurrentPermissions.hasOwnProperty(auth.name)) {
                actBw = contextService.virtualContext.CurrentPermissions[auth.name];
            }
            else {
                actBw = 0;
            }
            auth.create = (actBw & 1) > 0;
            auth.read = (actBw & 2) > 0;
            auth.update = (actBw & 4) > 0;
            auth.delete = (actBw & 8) > 0;
            auth.actionsBw = actBw;

            return auth;
        };
        self.authNotFound = function (authView) {
            var actBw;
            if (authView && authView.IgnoreAuth) {
                actBw = 0xFFFFFFFF;
            }
            else if (contextService.virtualContext.IsAuthShaped) {
                actBw = contextService.virtualContext.ShapingValueBw;
            }
            else if (contextService.virtualContext.IsAdministrator) {
                actBw = 0xFFFFFFFF;

            }
            else {
                actBw = 0xFFFFFFFF;
            }

            return {
                name: "NOT FOUND",
                namespace: "NOT FOUND",
                create: (actBw & 1) > 0,
                read: (actBw & 2) > 0,
                update: (actBw & 4) > 0,
                delete: (actBw & 8) > 0,
                actionsBw: actBw
            };
        };
    };

	//at moment when auth not found is all permitted
	//set all to false
	//self.dafeultAuth = function () {

	//    return {
	//        name: "",
	//        namespace: "",
	//        create: false,
	//        read: false,
	//        update: false,
	//        delete: false,
	//        actionsBw: 0
	//    };
	//}


	watchdogService.$inject = injectParams;
	watchdogService.prototype = Object.create(nbsService.prototype);
    angular.module('nbs.shared.module').service('watchdogService', watchdogService);

});