﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'metricService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function metricSpecializationsList($q, $scope, $timeout, $injector, metricService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, metricService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return metricSpecializationSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, metricService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var metricSpecializationSelected = null;
        
        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('metricspecialization', null);
        nbsGridDatacontext.viewCustomBar();
        nbsGridDatacontext.setDatasource([], true); // fake datasource, only to create grid
        vm.nbsGridDatacontext = nbsGridDatacontext;

        ribbon.setRibbonStatusButton(ribbon.Buttons.New, false);
        ribbon.refreshStatusButtons();

        // hook from gridbar
        var ejDataManager = null;
        var metricSpecializationFilter = null;
        vm.metricGridBarOnChanges = function (result) {
            metricSpecializationFilter = result.metricSpecializationFilter;

            if (ejDataManager === null) {
                var supportData = { translate: vm.translate, contextService: contextService };
                ejDataManager = metricService.getMetricSpecializationDtoManager(supportData, metricSpecializationFilter, ejDataManager);
                nbsGridDatacontext.setDatasource(ejDataManager, true);
                refreshGridDatasource(metricSpecializationFilter);
            } else {
                refreshGridDatasource(metricSpecializationFilter)
            }
        };

        function refreshGridDatasource(metricKpiDefinitionFilter) {
            $timeout(() => {
                vm.forceDatamanagerFilter = {
                    refreshKey: new Date().getTime(),
                    filter: metricKpiDefinitionFilter
                }
            });
        }

        vm.refreshAction = function () {
            $timeout(() => {
                vm.forceGridRefresh = new Date().getTime();
            });
        };

        vm.createGridEvent = function (result) {
            vm.metricGridBarDataContext = {
                commonBarDatasource: result.barDatasource,
                uiMode: 'specialization',
                defaultKeys: {
                    columnsKey: null, sortingKey: null
                }
            };
        };

        vm.gridUiOnChanges = function (result) {
            vm.refreshGridUiComponent = {
                refreshKey: new Date().getTime(),
                uiData: result
            }
        }

        vm.rowDblClick = function (args) {
            var viewKey = args.data.MetricKpiDefinitionTargetKey ? args.data.MetricKpiDefinitionTargetKey : '';
            navigationService.navigateTo("app.nav." + viewKey + "metricspecializationdetail", { 'metricSpecializationId': args.data.MetricSpecializationId });
        };

        //vm.newAction = function () {
        //    navigationService.navigateTo("app.nav." + viewKey + "metricspecializationdetail", { 'metricSpecializationId': null });
        //};

        vm.copyAction = function () {
            if (!metricSpecializationSelected) {
                return;
            }            

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        var filter = {
                            MetricSpecializationId: metricSpecializationSelected.MetricSpecializationId
                        };

                        metricService.cloneMetricSpecialization(filter)
                            .then(function (result) {
                                vm.refreshAction();
                            });
                    }
                });
        };

        vm.gridSelection = function (args) {
            metricSpecializationSelected = args.data;
            
            $timeout(() => {
                if (metricSpecializationSelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, !metricSpecializationSelected.IsSystem);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, metricSpecializationSelected.IsCustomizable);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, false);
                }

                ribbon.refreshStatusButtons();
            });            
        };

        vm.deleteAction = function () {
            if (!metricSpecializationSelected) {
                return;
            }

            metricService.deleteEntity(metricSpecializationSelected);
            vm.submitAction()
                .then(function (saveResult) {
                    vm.refreshAction();
                });
        };        
    }

    metricSpecializationsList.$inject = injectParams;
    metricSpecializationsList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('metricSpecializationsList', metricSpecializationsList);
});