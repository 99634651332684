﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$injector', '$timeout', 'foundationService', 'currentSessionService', 'navigationService', 'contextService'];
    function categoriesSelection($q, $injector, $timeout, foundationService, currentSessionService, navigationService, contextService) {
        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);

        // modal parameters
        var pParams = navigationService.stateParamsModal().parentParams;
        pParams = pParams ? pParams : navigationService.stateParams();

        var systemCategoryCode = pParams.systemCategoryCode;
        var categoryType = pParams.categoryType;
        var expandTree = pParams.expandTree === undefined || pParams.expandTree === null
            ? true : pParams.expandTree;


        vm.treeviewIsMultiselect = pParams.isMultiselect;
        if (vm.treeviewIsMultiselect === null || vm.treeviewIsMultiselect === undefined) {
            vm.treeviewIsMultiselect = true;
        }

        vm.viewTitle = pParams.viewTitle ? pParams.viewTitle : vm.translate('Categories');

        var viewKind = pParams.viewKind;

        if (pParams.isAutocheck) {
            vm.treeviewIsAutocheck = 'true';
        } else {
            vm.treeviewIsAutocheck = false;
        }


        loadData(true);
        function loadData(forceReload) {
            var funcsPromises = [];

            $q.all(funcsPromises)
                .then(function () {
                    if (categoryType === 'user') {
                        getCurrentUserCategoriesTree(forceReload, systemCategoryCode);
                    } else {
                        getCategories(forceReload, systemCategoryCode);
                    }
                });
        }

        function getCurrentUserCategoriesTree(forceReload, systemCategoryCode) {
            var filter = { SystemCategoryCode: systemCategoryCode };
            var defer = $q.defer();

            currentSessionService.getCurrentUserCategoriesTree(filter, forceReload)
                .then(function (data) {
                    if (viewKind === 'folder') {
                        vm.categories = Lazy(data.results).reject({ CategoryKindId: 2 }).toArray();
                    } else {
                        vm.categories = data.results;
                    }

                    parseCategoryTreeview(vm.categories);
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getCategories(forceReload, systemCategoryCode) {
            var filter = { SystemCategoryCode: systemCategoryCode };
            var defer = $q.defer();

            foundationService.getCategories(filter, forceReload)
                .then(function (data) {
                    if (viewKind === 'folder') {
                        vm.categories = Lazy(data.results).reject({ CategoryKindId: 2 }).toArray();
                    } else {
                        vm.categories = data.results;
                    }

                    parseCategoryTreeview(vm.categories);
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        vm.categoriesTreeview = [];
        function parseCategoryTreeview(categories) {
            vm.categoriesTreeview = Lazy(categories)
                .map((item) => {
                    return {
                        FieldId: item.CategoryId,
                        FieldParentId: item.ParentCategoryId,
                        FieldCode: item.CategoryCode,
                        FieldName: item.CategoryName,
                        FieldKindId: item.CategoryKindId,
                        FieldIsExpanded: expandTree
                    };
                }).toArray();
        }

        vm.modalOk = function () {
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalOk);
            navigationService.closeCurrentModal(modalResult);
        };

        vm.modalCancel = function () {
            navigationService.closeCurrentModal();
        };

        var currentSelected = [];
        vm.onNodeCheck = function (args) {
            var selectableCategories = [];
            if (viewKind === 'all') {
                selectableCategories = Lazy(vm.categories).toArray();
            } else {
                selectableCategories = Lazy(vm.categories).where({ CategoryKindId: 2 }).toArray();
            }

            var selectedCategoriesId = Lazy(args.currentCheckedNodes).pluck('id').toArray();

            var selectedCategories = Lazy(selectableCategories)
                .filter((category) => {
                    return Lazy(selectedCategoriesId).contains(category.CategoryId.toString());
                })
                .toArray();

            Lazy(selectedCategories)
                .each((category) => {
                    if (!Lazy(currentSelected).where({ CategoryId: category.CategoryId }).some()) {
                        currentSelected.push(category);
                    }
                });
        };
        vm.onNodeUncheck = function (args) {
            var uncheckedIds = Lazy(args.currentUncheckedNodes).pluck('id').toArray();
            currentSelected = Lazy(currentSelected)
                .reject(function (category) {
                    return Lazy(uncheckedIds)
                        .contains(category.CategoryId.toString());
                }).toArray();
        };

        vm.onNodeSelect = function (args) {
            currentSelected = Lazy(vm.categories).where({ CategoryId: Number(args.id) }).toArray();
        };

        function getModalResult(dialogResult) {
            Lazy(currentSelected)
                .each((item) => {
                    item.nbs_id = item.CategoryId;
                    item.nbs_code = item.CategoryCode;
                    item.nbs_name = item.CategoryName;
                });

            var modalResult = new navigationService.modalResult();
            modalResult.data = currentSelected;
            modalResult.selectedValues = Lazy(currentSelected).pluck('CategoryId').toArray();
            modalResult.hasSelection = Lazy(currentSelected).some();
            //modalResult.sourceParams = urlParams;
            modalResult.dialogResult = dialogResult;
            return modalResult;
        }
    }

    categoriesSelection.$inject = injectParams;
    categoriesSelection.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('categoriesSelection', categoriesSelection);
});
