'use strict';
var entrymodule = angular.module('nbs.entry.module');

define(['./landingPage'], function () {
    entrymodule.config(['$stateProvider',

        function ($stateProvider) {
            $stateProvider.state('app.nav.landingpage', routeResolver.routeObj.resolve({
                ngUrl: '/landingpage',
                baseName: './app/entry/landingPage.html'
            }));
        }]);
    return entrymodule;
});
