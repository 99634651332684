﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'hrbucketService', 'activityService', 'navigationService', 'checkStateChangeService', 'contextService', 'formSummaryService'];
    var hrSingleToBucketDetail = function ($q, $scope, $timeout, $injector, foundationService, hrbucketService, activityService, navigationService, checkStateChangeService, contextService, formSummaryService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, foundationService, { dirtyFlagIsSuspended: vm.isDirtySuspended });

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        ribbon.addTab(new ribbon.Tab("ActionPanelTabManage", [ribbon.GetAssociationStatusSection()]));
        vm.ribbonDatacontext = ribbon;

        var hrMetadataId = navigationService.stateParams().hrMetadataId;
        var virtualHumanResourceId = navigationService.stateParams().virtualHumanResourceId;
        var appStoreCode = navigationService.stateParams().appStoreCode;
        var viewKind = navigationService.stateParams().viewKind;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var resourceTypeId = navigationService.stateParams().resourceTypeId;
        var bucketClassificationId = navigationService.stateParams().bucketClassificationId;
        var magicBucketActionEnumParsed = Nembus.Common.Library.util.parseEnum(hrbucketService.dataContext.enumTypes.MagicBucketAction, null, vm.translate);
        var bucketClassificationEnumParsed = Nembus.Common.Library.util.parseEnum(hrbucketService.dataContext.enumTypes.BucketClassification, 'HrBucket', vm.translate);
        var activityXVhrStatusEnumParsed = Nembus.Common.Library.util.parseEnum(activityService.dataContext.enumTypes.ActivityXVhrStatus, 'HrSingleXHrBucket', vm.translate);
        var activityXVhrStatusList = activityXVhrStatusEnumParsed.list.translated;
        var activityXVhrStatusObj = activityXVhrStatusEnumParsed.obj.translated.value_name;
        var isSingleModeSelection = viewKind === 'singleMode';
        var allHrBuckets = [];

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('hrsingleXhrbucket', null);
        nbsGridDatacontext.allowSearching = false;
        nbsGridDatacontext.allowGrouping = false;
        nbsGridDatacontext.setDatasource([], false); // fake datasource, only to create grid
        vm.nbsGridDatacontext = nbsGridDatacontext;

        var activityXVhrModeEnumParsed = Nembus.Common.Library.util.parseEnum(activityService.dataContext.enumTypes.ActivityXVhrMode, 'HrSingleXHrBucket', vm.translate);
        vm.activityXVhrModeList = activityXVhrModeEnumParsed.list.translated;

        ribbon.setRibbonStatusButton(ribbon.Buttons.Modify, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.Refresh, true);
        ribbon.refreshStatusButtons();


        // get array items for form summary
        vm.formSummary = formSummaryService.getDatasource();

        vm.currentHrSingleToBuckets = [];
        vm.currentMagicBucketRequest = null; //have to add new Request each submit 

        if (virtualHumanResourceId) {
            loadData(false);
        }

        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getHrSingleXHrBuckets(forceReload, bucketClassificationId));

            $q.all(funcsPromises)
                .then(function (results) {
                    Lazy(results[0])
                        .each((item) => {
                            item.SingleXBucketModeUI = vm.translate(item.SingleXBucketMode, 'HrSingleXHrBucket');
                            item.SingleXBucketStatusUI = vm.translate(item.SingleXBucketStatus, 'HrSingleXHrBucket');
                        });

                    vm.currentHrSingleToBuckets = results[0];

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.currentHrSingleToBuckets
                    }
                });
        }

        // get reference of modifyAction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parentModifyAction = vm.modifyAction;
        vm.modifyAction = function () {
            parentModifyAction().then(
                //enable disable button new & delete because is custom behavior
                //generally saveResult eneble or disable only save button 
                function (saveResult) {
                    if (vm.viewContext.isEditingState) {
                        ribbon.setRibbonStatusButton(ribbon.Buttons.New, true);
                        ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, true);
                        ribbon.setRibbonStatusButton(ribbon.Buttons.AssociationStatusUpdate, true);
                        ribbon.setRibbonStatusButton(ribbon.Buttons.AssociationStatusInfo, true);
                    } else {
                        ribbon.setRibbonStatusButton(ribbon.Buttons.New, false);
                        ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                        ribbon.setRibbonStatusButton(ribbon.Buttons.AssociationStatusUpdate, false);
                        ribbon.setRibbonStatusButton(ribbon.Buttons.AssociationStatusInfo, false);

                        vm.refreshAction();
                    }

                    ribbon.refreshStatusButtons();
                });
        };

        vm.newAction = function () {
            getHrBuckets(false, appStoreBw, resourceTypeId, bucketClassificationId)
                .then(function (results) {
                    allHrBuckets = results;

                    var title = bucketClassificationEnumParsed.obj.translated.value_name[bucketClassificationId];
                    var isMultiselect = !isSingleModeSelection;
                    var params = new NbsGenericListModalParamObject('HrBucket', title);
                    params.isMultiselect = isMultiselect;
                    navigationService.navToGenericListModal(vm.suspendDirty, params, results)
                        .then(function (result) {
                            if (result.hasSelection) {
                                setHrSingleBuckets(result.data, isSingleModeSelection);
                            }
                        });
                });
        };

        vm.refreshAction = function () {
            loadData(true);
        };


        vm.associationStatusUpdateAction = function () {
            openModalChangeAssociationState();
        };

        vm.associationStatusInfoAction = function () {
            openModalInfoAssociationState();
        };

        // get reference of submitaction present into nbs-vm
        // this allow to manage response and do more in current controller
        vm.parentSubmitAction = vm.submitAction;
        vm.submitAction = function () {
            vm.parentSubmitAction()
                .then(
                    function (saveResult) {
                        if (vm.currentMagicBucketRequest) {
                            setBatchContext(vm.currentMagicBucketRequest);
                            vm.currentMagicBucketRequest = null; //have to add new Request each submit                         
                        }

                        vm.refreshAction();
                    }
                );
        };

        vm.deleteAction = function () {
            if (!vm.singleXHrBucketSelected) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            removeSingleXHrBucket(vm.singleXHrBucketSelected);

            saveAndRefresh();
        };

        vm.gridSelection = function (args) {
            $timeout(() => {
                vm.singleXHrBucketSelected = args.data;

                if (vm.singleXHrBucketSelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.AssociationStatusUpdate, true);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.AssociationStatusInfo, true);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.AssociationStatusUpdate, false);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.AssociationStatusInfo, false);
                }

                ribbon.refreshStatusButtons();
            });
        };

        function saveAndRefresh() {
            foundationService.saveChanges()
                .then(() => {
                    vm.refreshAction();
                });
        }

        function setHrSingleBuckets(bucketsFromModal, isSingleMode) {
            // remove from selected all buckets just related to asset
            var hrSingleToBucketIds = Lazy(vm.currentHrSingleToBuckets).pluck("HrBucketId").toArray();
            var bucketsToManage = [];
            Lazy(bucketsFromModal)
                .each((bucket) => {
                    if (!Lazy(hrSingleToBucketIds).contains(bucket.Id)) {
                        bucketsToManage.push(bucket);
                    }
                });

            if (!Lazy(bucketsToManage).some()) {
                return;
            }

            if (isSingleMode) {
                navigationService.confirmModal(vm.translate("HrBucketSingleSelectionWarning"))
                    .then(function (result) {
                        if (result) {
                            // remove existing 
                            Lazy(vm.currentHrSingleToBuckets)
                                .each((singleXHrBucket) => {
                                    removeSingleXHrBucket(singleXHrBucket);
                                });

                            setHrSingleBuckets_sub(bucketsToManage);
                        }
                    });
            } else {
                setHrSingleBuckets_sub(bucketsToManage);
            }
        }

        function setHrSingleBuckets_sub(bucketsToManage) {
            var itemToInsert;

            Lazy(bucketsToManage)
                .each((bucket) => {
                    if (!Lazy(vm.currentHrSingleToBuckets).where({ HrBucketId: bucket.Id }).some()) {
                        itemToInsert = Lazy(allHrBuckets).findWhere({ VirtualHumanResourceId: bucket.VirtualHumanResourceId });
                        if (itemToInsert) {
                            vm.currentHrSingleToBuckets.push(mapSingleXHrBuckets(itemToInsert));

                            addMagicBucketRequest(); //request for fire bucket/hr/activity binding
                        }
                    }
                });

            saveAndRefresh();
        }

        function mapSingleXHrBuckets(itemToInsert) {
            return foundationService.dataContext.hrSingleXHrBucketDtos.add({
                HrSingleXHrBucketDtoId: NbsGuidManager.newGuid(),
                HrBucketId: itemToInsert.VirtualHumanResourceId,
                HrBucketCode: itemToInsert.VirtualCode,
                HrBucketName: itemToInsert.VirtualName,
                HrMetadataId: hrMetadataId,
                SingleXBucketMode: "Manually",
                SingleXBucketStatus: "Associated"
            });
        }

        function removeSingleXHrBucket(singleXHrBucket) {
            addMagicBucketRequest(); //request for fire bucket/hr/activity binding

            // set SingleXHrBucket deleted
            foundationService.deleteEntity(singleXHrBucket);

            // update mode description for layout with Cancelled translation
            singleXHrBucket.SingleXBucketStatusUI = activityXVhrStatusObj[20];
        }

        // Datasource for modal selection
        function getHrBuckets(forceReload, appStoreBw, resourceTypeId, bucketClassificationId) {
            var filter = {
                //ResourceTypeId: resourceTypeId,
                BucketClassificationId: bucketClassificationId,
                OwnerAppStoreBw: appStoreBw
            };
            var defer = $q.defer();

            hrbucketService.getHrBuckets(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getHrSingleXHrBuckets(forceReload, bucketClassificationId) {
            // Do not add other filters, use only HrMetadataId filter
            // AppstoreBw must be 'all', do not change
            var appStoreCodeHrBucket = 'all';
            var appStoreBwHrBucket = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCodeHrBucket);
            var filter = {
                HrMetadataId: hrMetadataId,
                BucketClassificationId: bucketClassificationId,
                OwnerAppStoreBw: appStoreBwHrBucket
            };
            var defer = $q.defer();

            foundationService.getHrSingleXHrBuckets(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function addMagicBucketRequest() {
            var bucketAction = Lazy(foundationService.dataContext.enumTypes.MagicBucketAction).findWhere({ name: 'SingleXBuckets' });

            if (!vm.currentMagicBucketRequest) {
                vm.currentMagicBucketRequest = foundationService.dataContext.magicBucketRequests.add({
                    MagicBucketActionId: bucketAction.value,
                    SingleId: virtualHumanResourceId,
                    VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId,
                    ModuleTargetId: moduleTargetId
                });
            }
        }

        function setBatchContext(magicBucketRequest) {
            if (magicBucketRequest.IsBatch) {
                var actionType = Lazy(magicBucketActionEnumParsed.list.normal).findWhere({ value: magicBucketRequest.MagicBucketActionId });
                var actionTypeName = actionType ? actionType.name : '';

                contextService.addBatchRequest({
                    requestId: magicBucketRequest.BatchRequestId,
                    requestName: vm.translate('MagicBucketRequest' + actionTypeName + 'BatchName'),
                    requestStatus: 'inserted',
                    onCompletedAutoAction: null,    // is a function called on completed batch that must return a promise (with jobResult) managed in appPanel
                    detailAction: null              // is a function called from appPanel.cshml that links to batch request detail
                });
            }
        }

        function openModalChangeAssociationState() {
            if (!vm.singleXHrBucketSelected) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            var params = new NbsGenericListModalParamObject('VhrEventLifeStatus', vm.translate('ActionPanelVhrEventLifeStatusInfo'));
            navigationService.navToGenericListModal(vm.suspendDirty, params, activityXVhrStatusList)
                .then(function (result) {
                    if (result.hasSelection) {
                        var activityXVhrStatusSelected = Lazy(result.data).first();
                        var activityXVhrModeSelected = Lazy(vm.activityXVhrModeList).findWhere({ name: 'Manually' });
                        if (activityXVhrStatusSelected && vm.singleXHrBucketSelected) {
                            addMagicBucketRequest(); //request for fire bucket/hr/activity binding
                            vm.singleXHrBucketSelected.SingleXBucketStatusId = activityXVhrStatusSelected.value;
                            vm.singleXHrBucketSelected.SingleXBucketStatus = activityXVhrStatusSelected.name;
                            vm.singleXHrBucketSelected.SingleXBucketStatusUI = activityXVhrStatusSelected.name;
                            vm.singleXHrBucketSelected.SingleXBucketModeId = activityXVhrModeSelected.value;
                            vm.singleXHrBucketSelected.SingleXBucketMode = activityXVhrModeSelected.name;
                        }
                    }
                });
        }

        function openModalInfoAssociationState() {
            if (!vm.singleXHrBucketSelected) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            var singleXBucketStatusId = 0;
            var singleXBucketStatusSelected = Lazy(activityXVhrStatusList).findWhere({ name: vm.singleXHrBucketSelected.SingleXBucketStatus });
            if (singleXBucketStatusSelected) {
                singleXBucketStatusId = singleXBucketStatusSelected.value;
            }

            var params = new NbsGenericListModalParamObject('VhrEventLifeStatus', vm.translate('ActionPanelVhrEventLifeStatusInfo'));
            params.readonly = true;
            params.selectedIds = [singleXBucketStatusId];
            navigationService.navToGenericListModal(vm.suspendDirty, params, activityXVhrStatusList);
        }
    };

    hrSingleToBucketDetail.$inject = injectParams;
    hrSingleToBucketDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('hrSingleToBucketDetail', hrSingleToBucketDetail);
});