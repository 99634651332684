﻿'use strict';
define([], function () {

    var injectParams = ['$timeout', 'fileTransferService', 'navigationService'];
    var nbsImageComponent = function ($timeout, fileTransferService, navigationService) {

        var vm = this;
        var pDataContext = null;
        var pPhotoImageId = null;
        var pTemporaryFileName = null;

        vm.defaultPhotoSrc = 'Images/BlankImage.jpg';
        setImageSrc(vm.defaultPhotoSrc)
        vm.isReadonly = true;

        //*************
        vm.files = [];
        vm.percent = 0;
        //***********

        vm.$onInit = function () {
            // on img src change, toolbar width will be refreshed
            $('.nbs-image-component img').on('load', function () {
                var width = $('.nbs-image-component img').width() + 1;
                $('.nbs-image-component span.edit-toolbar').width(width);
            });
        };

        vm.$onChanges = function (changes) {
            if (changes.nbsDatacontext && changes.nbsDatacontext.currentValue) {
                pDataContext = changes.nbsDatacontext.currentValue;
                pPhotoImageId = pDataContext.photoImageId;

                setImageSize(pDataContext.resizeMode);

                if (pPhotoImageId) {
                    setImageSrc(fileTransferService.uriByGuid({ entityGuid: pPhotoImageId }))
                }
            }

            if (changes.isReadonly) {
                if (changes.isReadonly.currentValue === true) {
                    vm.isReadonly = true;
                    $('.nbs-image-component img').addClass('nbs-field-disabled');
                } else {
                    vm.isReadonly = false;
                    $('.nbs-image-component img').removeClass('nbs-field-disabled');
                }
            }
        };

        vm.onFileUploaded = function (file, res) {
            var dData = {
                fileId: file.id,
                displayFileName: file.name
            };
            setImageSrc(fileTransferService.uriTemporaryByFileName(dData))
            pTemporaryFileName = fileTransferService.temporaryFileNameByFileId(dData);
            raiseChangeEvent('upload');
        };

        vm.deletePhoto = function () {
            navigationService.confirmModal(vm.uiContext.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        setImageSrc(vm.defaultPhotoSrc)
                        raiseChangeEvent('delete');
                    }
                });
        };

        vm.downloadPhoto = function (asAttach) {
            if (!pTemporaryFileName) {
                fileTransferService.downloadRemoteFileByGuid({
                    entityGuid: pPhotoImageId,
                    asAttach: asAttach
                });
            } else {
                fileTransferService.downloadRemoteTemporary({
                    temporaryFileName: pTemporaryFileName,
                    asAttach: asAttach
                });
            }
        };

        function setImageSrc(src) {
            $timeout(() => {
                vm.imageSrc = src;
            });
        }

        function setImageSize(resizeMode) {
            vm.imgStyle = { 'min-width': '100px', 'min-height': '100px', 'max-height': ' 400px', 'max-width': '600px' };

            if (resizeMode === 'avatar') {
                vm.imgStyle = { 'min-width': null, 'min-height': null, 'max-height': ' 100px', 'max-width': '100px' };
            } else if (resizeMode === 'no-resize') {
                vm.imgStyle = { 'min-width': null, 'min-height': null, 'max-height': null, 'max-width': null };
            }
        }

        function raiseChangeEvent(action) {
            if (action === 'delete') {
                pPhotoImageId = null;
                pTemporaryFileName = null;
            }
            if (action === 'upload') {
                pPhotoImageId = null;
            }

            $timeout(function () {
                vm.onChangeEvent({
                    result: {
                        action: action,
                        fileId: pPhotoImageId,
                        temporaryFileName: pTemporaryFileName
                    }
                });
            });
        }
    };

    nbsImageComponent.$inject = injectParams;
    angular.module('nbs.rds.module').component('nbsImage', {
        templateUrl: '/rds/common/components/nbsImageComponent/_?c=' + window.codeCache(),
        controller: nbsImageComponent,
        controllerAs: 'vm',
        bindings: {
            onChangeEvent: '&',
            nbsDatacontext: '<',
            uiContext: '<',
            isReadonly: '<'
        }
    });
});

