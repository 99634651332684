﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'navigationService', 'checkStateChangeService', 'contextService', 'formSummaryService'];
    var hrContactDetail = function ($q, $scope, $timeout, $injector, foundationService, navigationService, checkStateChangeService, contextService, formSummaryService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, foundationService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        ribbon.setRibbonStatusButton(ribbon.Buttons.Refresh, true);
        ribbon.refreshStatusButtons();
        
        // get array items for form summary
        vm.formSummary = formSummaryService.getDatasource();

        var contactKindEnumParsed = Nembus.Common.Library.util.parseEnum(foundationService.dataContext.enumTypes.ContactKind, 'Contact', vm.translate);
        var contactKindObj = contactKindEnumParsed.obj.normal.name_value;
        var contactKindObjForBind = contactKindEnumParsed.obj.translated.value_name;

        var contactKindProcessed = [];
        var hrContactsId = navigationService.stateParams().hrContactsId;

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('contact', null);
        nbsGridDatacontext.setDatasource([], false);
        nbsGridDatacontext.allowPaging = false;
        nbsGridDatacontext.allowSearching = false;
        nbsGridDatacontext.allowGrouping = false;
        vm.nbsGridDatacontext = nbsGridDatacontext;

        loadContactKind();
        if (hrContactsId) {
            vm.contactKind = contactKindProcessed;

            loadData(false);
        }

        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getHrContact(false));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.currentHrContactContacts = results[0];

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.currentHrContactContacts
                    }
                });
        }

        function loadContactKind() {
            contactKindProcessed = Lazy(contactKindEnumParsed.list.translated)
                .filter(function (item) {
                    return item.value === contactKindObj.MainEmail
                        || item.value === contactKindObj.SecondaryEmail
                        || item.value === contactKindObj.Phone
                        || item.value === contactKindObj.Mobile
                        || item.value === contactKindObj.Im
                        || item.value === contactKindObj.WebSite
                        || item.value === contactKindObj.CustomContactType;
                }).toArray();
        }

        vm.currentContact = null;
        vm.currentHrContactContacts = [];


        vm.refreshAction = function () {
            loadData(true);
        };

        // get reference of modifyAction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parentm = vm.modifyAction;
        vm.modifyAction = function () {
            parentm().then(
                //enable disable button new & delete because is custom behavior
                //generally saveResult eneble or disable only save button 
                function (saveResult) {
                    if (vm.viewContext.isEditingState) {
                        ribbon.setRibbonStatusButton(ribbon.Buttons.New,  true);
                        ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, true);
                    } else {
                        ribbon.setRibbonStatusButton(ribbon.Buttons.New,  false);
                        ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                    }

                    ribbon.refreshStatusButtons();
                }
            );
        };

        vm.modalShow = function (action) {
            vm.modalInstance = navigationService.modal().open(vm.modalOptions({
                windowClass: vm.NBS_MODAL_CLASS_FORM_MD,
                templateUrl: 'contactTemplate',
                scope: $scope
            }));
            vm.modalInstance.result.then(function () {
                vm.currentContact.ContactKindName = contactKindObjForBind[vm.currentContact.ContactKindId];
                vm.currentHrContactContacts.push(vm.currentContact);

                vm.forceDatasourceLocal = {
                    refreshkey: new Date().getTime(),
                    dataSource: vm.currentHrContactContacts
                }
            }, function () {
                //dismiss
                foundationService.rejectChanges();
            });
            vm.modalInstance.ok = function () {
                vm.submitAction()
                    .then(function (data) {
                        vm.refreshAction();
                        vm.modalInstance.close();
                    });
            };
            vm.modalInstance.cancel = function () {
                vm.modalInstance.dismiss();
            };
        };

        vm.newAction = function () {
            vm.currentContact = foundationService.dataContext.contacts.add({
                HrContactsId: hrContactsId,
                ContactKindId: null
            });

            vm.modalShow();
        };

        vm.deleteAction = function () {
            if (!vm.currentContact) {
                return;
            }

            //set entity as deleted
            foundationService.deleteEntity(vm.currentContact);

            vm.submitAction()
                .then(function (data) {
                    vm.refreshAction();
                    vm.modalInstance.close();
                });
        };

        vm.rowDblClick = function (args) {
            vm.modalShow();
        };

        vm.gridSelection = function (args) {
            vm.currentContact = args.data;
        };

        function getHrContact(forceReload) {
            var filter = { ContactId: hrContactsId };
            var defer = $q.defer();

            foundationService.getHrContact(filter, forceReload)
                .then(function (data) {
                    Lazy(data.results[0].Contacts).each((item) => {
                        item.ContactKindName = contactKindObjForBind[item.ContactKindId];
                    });

                    defer.resolve(data.results[0].Contacts);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    hrContactDetail.$inject = injectParams;
    hrContactDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('hrContactDetail', hrContactDetail);
});