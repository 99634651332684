﻿'use strict';
//modal params: 
//cronstring: string 
//modal result
//CronObj   ///  cronstring
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$timeout', '$injector', 'navigationService', 'contextService'];
    function cronExpressionDetail($q, $timeout, $injector, navigationService, contextService) {

        var vm = this;
        nbsVm.call(vm, null, navigationService, contextService, $q, $injector, $timeout);

        //e.g.{cronexpression:"0 0 0 ? * FRI/3 *"}
        var pParams = navigationService.stateParamsModal().parentParams;
        pParams = pParams ? pParams : navigationService.stateParams();

        // beardcrumb information (only when view is called in wizard)
        if (pParams.isWizardMode) {
            var beardCrumbInfo = JSON.parse(pParams.beardCrumbInfo);
            Lazy(beardCrumbInfo.items).each((item) => { item.title = vm.translate(item.title); });
            vm.beardCrumbInfo = beardCrumbInfo;

            // navigation buttons visibility
            vm.setWizardButtonVisibility(pParams);
        } else {
            vm.beardCrumbInfo = {};
            vm.beardCrumbInfo.items = [];
            vm.beardCrumbInfo.isVisible = false;

            // navigation buttons visibility
            vm.setWizardButtonVisibility(pParams, false);
            vm.okBtnIsVisible = true;
            vm.cancelBtnIsVisible = true;
        }


        vm.cronParser = new Nembus.Common.Library.parser.Cron(vm.translate);
        vm.currentCron = vm.cronParser.getCronObject(pParams.cronstring);

        vm.modalOk = function () {
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalOk);

            if (!vm.currentCron.hasError()) {
                navigationService.closeCurrentModal(modalResult);
            } else {
                vm.alertConfig.isModal = true;
                vm.alertConfig.isReadonly = true;
                vm.removeAlertFromList(true);
                vm.setErrorMsg({ isMessageVisible: true, message: vm.currentCron.errorMessage() });
            }
        };

        vm.modalNext = function () {
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalNext);

            if (!vm.currentCron.hasError()) {
                navigationService.closeCurrentModal(modalResult);
            } else {
                vm.alertConfig.isModal = true;
                vm.alertConfig.isReadonly = true;
                vm.removeAlertFromList(true);
                vm.setErrorMsg({ isMessageVisible: true, message: vm.currentCron.errorMessage() });
            }
        };

        vm.modalPrevious = function () {
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalPrevious);
            navigationService.closeCurrentModal(modalResult);
        };

        vm.modalCancel = function () {
            if (pParams.isWizardMode) {
                navigationService.confirmExitWizard()
                    .then(function (result) {
                        if (result) {
                            navigationService.closeCurrentModal();
                        }
                    });
            } else {
                navigationService.closeCurrentModal();
            }
        };

        //Function used to manage checkbox selection
        vm.checkboxOnChange = function (currentPropertyName) {
            Lazy(Object.keys(vm.currentCron))
                .each((p) => {
                    if (p.indexOf('IsEvery') === 0) {
                        if (p !== currentPropertyName) {
                            vm.currentCron[p] = false;
                        }
                    }
                });

            // remove 'Is' characters from property name
            var prefixFields = currentPropertyName.substring(2);
            Lazy(Object.keys(vm.currentCron))
                .each((p) => {
                    if (p.indexOf('Monthly_') === 0) {
                        vm.currentCron[p] = 0;
                    }
                });
        };

        function getModalResult(dialogResult) {
            var modalResult = new navigationService.modalResult();
            modalResult.data = vm.currentCron;
            modalResult.sourceParams = pParams;
            modalResult.dialogResult = dialogResult;
            return modalResult;
        }
    }

    cronExpressionDetail.$inject = injectParams;
    cronExpressionDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('cronExpressionDetail', cronExpressionDetail);
});