﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$injector', '$scope', '$timeout', 'foundationService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function assetsStream($q, $injector, $scope, $timeout, foundationService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return assetsSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, foundationService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'stream');
        ribbon.addHomeTab();
        ribbon.addTab(ribbon.GetAssetViewsTab());
        ribbon.tabIndexSelected = 2;
        vm.ribbonDatacontext = ribbon;

        var viewKind = navigationService.stateParams().viewKind;
        var bucketClassificationId = navigationService.stateParams().BucketClassificationId;
        var assetTreeEntityType = navigationService.stateParams().AssetTreeEntityType;
        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var vhrStatusEnum = Nembus.Common.Library.util.parseEnum(foundationService.dataContext.enumTypes.VhrStatus, null, vm.translate).obj.normal.name_value;
        var sourceEntityNavCode = navigationService.stateParams().sourceEntityNavCode;
        var assetsSelected = null;

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('assettree', 'list');
        nbsGridDatacontext.setStreamMode();
        nbsGridDatacontext.setDatasource([], false); // fake datasource, only to create grid
        vm.nbsGridDatacontext = nbsGridDatacontext;

        //#region set ribbon fn navigations
        var navParams = { 'viewKind': viewKind, 'appStoreCode': appStoreCode, 'sourceEntityNavCode': sourceEntityNavCode };
        vm.onRibbonGrid = () => { ribbon.onRibbonGrid_Asset(navParams); };
        vm.onRibbonTree = () => { ribbon.onRibbonTree_Asset(navParams); };
        //#endregion set ribbon fn navigations

        //#region set ribbon enabled buttons
        ribbon.setRibbonStatusButton(ribbon.Buttons.ViewList, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.ViewTree, true);
        ribbon.refreshStatusButtons();
        //#endregion set ribbon enabled buttons

        vm.rowDblClick = function (args) {
            navigationService.navigateToAssetTreeDetail(args.data, { appStoreCode: appStoreCode, viewKind: viewKind, viewMode: vm.viewMode });
        };

        vm.refreshAction = function () {
            $timeout(() => {
                vm.forceGridRefresh = new Date().getTime();
            });
        };

        vm.gridSelection = function (args) {
            assetsSelected = args.data;
        };

        var ejDataManager = null;
        var currentAssetTreeFilter = null;
        vm.assettreeGridBarOnChanges = function (result) {
            currentAssetTreeFilter = {
                assetTreeFilter: result.assetTreeFilter
            }

            currentAssetTreeFilter.assetTreeFilter.AppStoreBw = appStoreBw;
            currentAssetTreeFilter.assetTreeFilter.VhrStatusId = vhrStatusEnum.Enabled;
            currentAssetTreeFilter.assetTreeFilter.AssetTreeEntityType = assetTreeEntityType
            currentAssetTreeFilter.assetTreeFilter.BucketClassificationId = bucketClassificationId

            if (ejDataManager === null) {
                var supportData = { translate: vm.translate };
                ejDataManager = foundationService.getAssetTreeDataManager(supportData, currentAssetTreeFilter, ejDataManager);
                nbsGridDatacontext.setDatasource(ejDataManager, true);
                refreshGridDatasource(currentAssetTreeFilter);
            } else {
                refreshGridDatasource(currentAssetTreeFilter)
            }
        };
        
        function refreshGridDatasource(currentGridFilter) {
            $timeout(() => {
                vm.forceDatamanagerFilter = {
                    refreshKey: new Date().getTime(),
                    filter: currentGridFilter
                }
            });
        }

        vm.createGridEvent = function (result) {
            vm.assettreeGridBarDataContext = {
                commonBarDatasource: result.barDatasource,
                defaultKeys: {
                    columnsKey: null, sortingKey: null
                }
            };
        };

        vm.gridUiOnChanges = function (result) {
            vm.refreshGridUiComponent = {
                refreshKey: new Date().getTime(),
                uiData: result
            }
        }
    }

    assetsStream.$inject = injectParams;
    assetsStream.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('assetsStream', assetsStream);
});