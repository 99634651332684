﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {


    var injectParams = ['$scope', '$http', '$window', '$q', '$location', '$uibModal', 'localStorageService', 'contextService', 'authenticationService', 'navigationService', 'checkStateChangeService', 'bootService'];
    var mobileAppConfig = function ($scope, $http, $window, $q, $location, $uibModal, localStorageService, contextService, authenticationService, navigationService, checkStateChangeService, bootService) {
        var vm = this;
        nbsVm.call(vm, null, navigationService, contextService, $q, null, null);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext);
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        vm.auth_gotologinbutton = 'Torna alla login';

        vm.navToLogin = function () {
            navigationService.navigateTo('auth.login', {});
        };

        vm.openQrCodeModal = function (key) {
            vm.qrCodeKey = key;


            vm.modalInstance = navigationService.modal().open(vm.modalOptions({ templateUrl: 'qrCodeTemplate', scope: $scope }));
            switch (key) {
                case 'appClientCode':
                    vm.modalInstance.title = 'Codice cliente'
                    break;
                case 'appDownloadUri':
                    vm.modalInstance.title = 'Download Url'
                    break;
            }

            vm.modalInstance.ok = function () {
                vm.modalInstance.close();
            };
            vm.modalInstance.cancel = function () {
                vm.modalInstance.dismiss();
            };
            vm.modalInstance.result.then(function () {
                // onClose
            }, function () {
                // onDismiss
            });
        }

        

    };

    mobileAppConfig.$inject = injectParams;
    mobileAppConfig.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rbac.module').controller('mobileAppConfig', mobileAppConfig);
});