﻿define(function () {
    function nbsService(manager, $q, $timeout) {
        var self = this;
        this.manager = manager;
        this.$q = $q;
        this.$timeout = $timeout;
        this.FilterMenu = { FieldType: { Enum: 'Enum', Date: 'Date' } };
        this.fsType = { folder: 1, file: 2 };

        this.entityEncodeHtml = function (sourceEntity) {
            if (!sourceEntity) {
                return;
            }

            if (!sourceEntity.entityType) {
                return;
            }

            var properties = sourceEntity.entityType.getProperties();
            Lazy(properties)
                .each((property) => {
                    if (property.dataType) {
                        if (property.dataType.name === breeze.DataType.String.name) {
                            sourceEntity[property.name] = Nembus.Common.Library.converter.decodeHtml(sourceEntity[property.name]);
                        } else if (property.dataType.isComplexType) {
                            Lazy(property.dataType.dataProperties)
                                .each((complexProperty) => {
                                    sourceEntity[property.name][complexProperty.name] = Nembus.Common.Library.converter.decodeHtml(sourceEntity[property.name][complexProperty.name]);
                                });
                        }
                    } else {
                        // is Navigation property
                    }
                });
        };

        this.saveChanges = function () {
            //html decode because xss 
            var deferred = $q.defer();
            var allchanges = this.manager.getChanges();
            var chIndex = 0;
            while (allchanges[chIndex + '']) {
                var currentEntity = allchanges[chIndex + ''];
                this.entityEncodeHtml(currentEntity);
                chIndex++;
            }

            this.manager.saveChanges()
                .then(saveResult => {
                    deferred.resolve(saveResult);
                })
                .catch(saveError => {
                    var xx = saveError;
                    deferred.reject(saveError);
                });
            return deferred.promise;
            /*
            return this.manager.saveChanges()
                .then(saveSucceeded)
                .catch(saveFailed);

            function saveSucceeded(saveResult) {
                return saveResult;
            }

            function saveFailed(error) {
                //manager.rejectChanges()
                return self.$q.reject(error);
            }

            function removePropertyChangeHandler(handler) {
                // Actually removes any 'entityChanged' event handler
                return this.manager.entityChanged.unsubscribe(handler);
            }
            */
        };


    }



    nbsService.prototype.rejectChanges = function () {
        return this.manager.rejectChanges();
    };

    nbsService.prototype.hasChanges = function () {
        return this.manager.hasChanges();
    };

    nbsService.prototype.createEntity = function (entityType, entityObj) {
        // copy options, changing only 'validateOnAttach'
        var valOpts = this.manager.validationOptions.using({ validateOnAttach: false });

        // reset manager's options
        this.manager.setProperties({ validationOptions: valOpts });

        // add entity to EntityManager
        //var newEntity = manager.createEntity(entityType, entityObj, entityState)
        var newEntity = this.manager.createEntity(entityType, entityObj, null);

        return this.manager.addEntity(newEntity);
    };

    nbsService.prototype.deleteEntity = function (nbsentity) {
        // entity will be setted detached only if isn't just detached
        if (nbsentity && nbsentity.entityAspect && nbsentity.entityAspect.entityState !== breeze.EntityState.Detached) {
            nbsentity.entityAspect.setDeleted();
        }
    };

    nbsService.prototype.createEntityStatic = function (entityType, entityObj, manager) {
        // copy options, changing only 'validateOnAttach'
        var valOpts = manager.validationOptions.using({ validateOnAttach: false });

        // reset manager's options
        manager.setProperties({ validationOptions: valOpts });

        // add entity to EntityManager
        //var newEntity = manager.createEntity(entityType, entityObj, entityState)
        var newEntity = manager.createEntity(entityType, entityObj, null);
        if (newEntity.CreationDate) {
            var minDate = new Date(Date.UTC(2010, 0, 1, 0, 0, 0));
            minDate.setFullYear(1);
            newEntity.CreationDate = minDate;
        }
        return manager.addEntity(newEntity);
    };

    nbsService.prototype.detachEntity = function (entity) {
        this.manager.detachEntity(entity);
    };

    nbsService.prototype.getEntityType = function (typeName) {
        return this.manager.metadataStore.getEntityType(typeName);
    };

    //used when source object is plain javascript with $type property (e.g. parsed from breeze json api response)
    //e.g. ejgrid datasource from breeze api
    nbsService.prototype.getAttachedEntities = function (entities) {
        var attachedEntities = [];
        if (entities && entities.length > 0 && entities[0].$type && entities[0].$type.indexOf(',') > 0) {
            var entityType = Lazy(entities[0].$type.split(',')[0].split('.')).last();
        }
        else {
            return attachedEntities;
        }
        var that = this;
        Lazy(entities)
            .each((cEntity) => {
                var entityAttached = that.manager.createEntity(entityType, cEntity, breeze.EntityState.Unchanged, breeze.MergeStrategy.PreserveChanges);
                attachedEntities.push(entityAttached);
            });

        return attachedEntities;
    };

    //used when source object is plain javascript with NO $type property
    nbsService.prototype.getEntitiesAttachedExplicitType = function (entities, entityType) {
        var attachedEntities = [];
        var that = this;
        Lazy(entities)
            .each((cEntity) => {
                var entityAttached = that.manager.createEntity(entityType, cEntity, breeze.EntityState.Unchanged, breeze.MergeStrategy.PreserveChanges);
                attachedEntities.push(entityAttached);
            });

        return attachedEntities;
    };

    return nbsService;
});