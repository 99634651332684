import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';
import { foundationServiceProvider } from './rds/services/ajs-upgraded.providers';

import { FormsModule } from '@angular/forms';
import { NamingConvention } from 'breeze-client';
import { DataServiceWebApiAdapter } from 'breeze-client/adapter-data-service-webapi';
import { ModelLibraryBackingStoreAdapter } from 'breeze-client/adapter-model-library-backing-store';
import { UriBuilderJsonAdapter } from 'breeze-client/adapter-uri-builder-json';
import { AjaxHttpClientAdapter } from 'breeze-client/adapter-ajax-httpclient';
import { AuthHttpInterceptor } from './shared/interceptors/auth-http.interceptor';
import { authenticationServiceProvider } from './shared/services/ajs-upgraded.providers'; // TODO MOVE TO SHARED MODULE
import { BusyService } from './shared/services/busy.service';

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        UpgradeModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true
        },
        foundationServiceProvider,
        authenticationServiceProvider, // TODO MOVE TO SHARED MODULE
        BusyService //TODO MOVE TO SHARED MODULE
    ]
})

export class AppModule {
    // Override Angular bootstrap so it doesn't do anything
    ngDoBootstrap() {
    }

    constructor(http: HttpClient) {
        // Configure Breeze adapters
        ModelLibraryBackingStoreAdapter.register();
        UriBuilderJsonAdapter.register();
        AjaxHttpClientAdapter.register(http);
        DataServiceWebApiAdapter.register();
        //NamingConvention.camelCase.setAsDefault();
    }

}