﻿'use strict';
define([], function () {

    var injectParams = ['$q', '$timeout', 'audService'];
    var nbsSurveyTemplateBarComponent = function ($q, $timeout, audService) {
        var vm = this;
        var privateDataContext = null;
        var privateUIData = null;
        var defaultKeys;
        var nbsAudUiOptionParser = null;

        vm.privateComponentsStatus = {};
        vm.nbsDataPrivate = {};

        vm.$onInit = function () {

        };

        vm.$onChanges = function (changes) {
            if (changes.nbsDataContext && changes.nbsDataContext.currentValue) {
                nbsAudUiOptionParser = new NbsAudUiOptionParser(vm.uiContext.translate);

                privateDataContext = changes.nbsDataContext.currentValue;

                // set default visible columns list in grid
                defaultKeys = privateDataContext.defaultKeys;

                // Start of execution workflow of load data bar components 
                initViewTitle();
            }
        };

        function initViewTitle() {
            var cols = [];
            switch (defaultKeys.columnsKey) {
                case 'all':
                    cols = ['SurveyId', 'SurveyCode', 'SurveyName', 'SurveyTemplateGroupName', 'SurveyTemplateTypeName'];
                    break;
                case 'archived':
                    cols = ['SurveyId', 'SurveyCode', 'SurveyName', 'SurveyTemplateGroupName', 'SurveyTemplateTypeName', 'IsArchived'];
                    break;
            }
            var defaultStartData = new NbsUiData();
            defaultStartData.setColumns(cols);

            if (!defaultKeys.sortingKey) {
                defaultStartData.setSortingField('SurveyId', true);
            }

            defaultStartData.setFilterList(getFilterList());

            vm.initViewTitle = defaultStartData.startData;
        }

        //#region hook from components
        vm.onViewTitleSelectionEvent = function (result) {
            privateUIData = JSON.parse(JSON.stringify(result.uiData));

            setComponents(privateUIData);

            setBarDatasources()
                .then(function () {
                    barOnChanges(privateUIData);
                });
        };

        vm.onUiChanges = function (result) {
            privateUIData.columns = result.uiData.columns;
            privateUIData.grouping = result.uiData.grouping;
            privateUIData.sorting = result.uiData.sorting;
            privateUIData.filters.freeText = result.uiData.filters.freeText;

            refreshViewTitleDataContext(privateUIData);

            vm.onGridUiChangesEvent({ result: privateUIData});
        };
        vm.onFilterChanges = function (result) {
            privateUIData.filterList = result.filters.filterList;            

            refreshViewTitleDataContext(privateUIData);
            setStatusComponents(privateUIData.filterList);

            setBarDatasources()
                .then(function () {
                    barOnChanges(privateUIData);
                });
        };
        vm.onDdMsSurveyTemplateGroups = function (options) {
            privateUIData.filters.surveytemplategroups = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdMsSurveyTemplateTypes = function (options) {
            privateUIData.filters.surveytemplatetypes = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        //#endregion

        // refresh current preference uiData
        function refreshViewTitleDataContext(uiDataParam) {
            vm.viewTitleDataContext = Nembus.Common.Library.component.getDataContextViewTitle(uiDataParam);
        }

        function setBarDatasources() {
            var defer = $q.defer();
            var funcsPromises = [];

            if (vm.privateComponentsStatus.surveytemplategroups.isVisible) {
                funcsPromises.push(getSurveyTemplateGroups(false));
            }
            if (vm.privateComponentsStatus.surveytemplatetypes.isVisible) {
                funcsPromises.push(getSurveyTemplateTypes(false));
            }

            $q.all(funcsPromises)
                .then(function () {
                    defer.resolve(true);
                });

            return defer.promise;
        }

        function getFilterList() {
            var uiDataFilters = [];
            uiDataFilters.push(new NbsUiDataFilter({ code: 'surveytemplatetypes', name: 'SurveyTemplate.SurveyTemplateType' }));
            uiDataFilters.push(new NbsUiDataFilter({ code: 'surveytemplategroups', name: 'SurveyTemplate.SurveyTemplateGroup' }));
            return uiDataFilters;
        }

        function getSurveyTemplateGroups(forceReload) {
            var filter = {};
            var defer = $q.defer();

            audService.getSurveyTemplateGroups(filter, forceReload)
                .then(function (data) {
                    vm.nbsDataPrivate.surveytemplategroups = nbsAudUiOptionParser.getUiOptionsByKey(data.results, 'SurveyTemplateGroup');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getSurveyTemplateTypes(forceReload) {
            var filter = {};
            var defer = $q.defer();

            audService.getSurveyTemplateTypes(filter, forceReload)
                .then(function (data) {
                    vm.nbsDataPrivate.surveytemplatetypes = nbsAudUiOptionParser.getUiOptionsByKey(data.results, 'SurveyTemplate');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function setComponents(uiDataParam) {
            setCommonGridBarDataContext(uiDataParam);
            setSelectionComponents(uiDataParam);
            setStatusComponents(uiDataParam.filterList);
            vm.onGridUiChangesEvent({ result: privateUIData });
        }

        function setCommonGridBarDataContext(uiDataParam) {
            vm.commonGridBarDataContext = Nembus.Common.Library.component.getDataContextCommonGridBar(uiDataParam);
            vm.commonBarDatasource = privateDataContext.commonBarDatasource
        }

        function setSelectionComponents(uiDataParam) {
            vm.nbsDataPrivate.surveytemplategroupsselected = uiDataParam.filters.surveytemplategroups ? uiDataParam.filters.surveytemplategroups : [];
            vm.nbsDataPrivate.surveytemplatetypesselected = uiDataParam.filters.surveytemplatetypes ? uiDataParam.filters.surveytemplatetypes : [];
        }

        function setStatusComponents(filterList) {
            vm.privateComponentsStatus.surveytemplategroups = Nembus.Common.Library.component.getStatus('surveytemplategroups', filterList);
            vm.privateComponentsStatus.surveytemplatetypes = Nembus.Common.Library.component.getStatus('surveytemplatetypes', filterList);
        }

        function barOnChanges(uiDataParam) {
            var selectedSurveyTemplateGroupsId = vm.privateComponentsStatus.surveytemplategroups.isVisible
                ? Lazy(uiDataParam.filters.surveytemplategroups).pluck('optionValue').toArray()
                : [];

            var selectedSurveyTemplateTypesId = vm.privateComponentsStatus.surveytemplatetypes.isVisible
                ? Lazy(uiDataParam.filters.surveytemplatetypes).pluck('optionValue').toArray()
                : [];

            $timeout(() => {
                vm.onBarChanges({
                    result: {
                        surveyFilter: {
                            SurveyTemplateGroupsId: selectedSurveyTemplateGroupsId,
                            SurveyTemplateTypesId: selectedSurveyTemplateTypesId
                        }
                    }
                });
            });
        }
        //#endregion 
    };

    nbsSurveyTemplateBarComponent.$inject = injectParams;
    angular.module('nbs.aud.module').component('nbsSurveyTemplateBar', {
        templateUrl: '/aud/surveys/components/nbsSurveyTemplateBarComponent/_?c=' + window.codeCache(),
        controller: nbsSurveyTemplateBarComponent,
        controllerAs: 'vm',
        bindings: {
            onBarChanges: '&',
            onGridUiChangesEvent: '&',

            nbsDataContext: "<",
            uiContext: "<"
        }
    });
});

