﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'cmsDocService', 'navigationService', 'checkStateChangeService', 'contextService', 'bootService'];
    function viewPageElementsList($q, $scope, $timeout, $injector, cmsDocService, navigationService, checkStateChangeService, contextService, bootService) {
        var vm = this;
        nbsVm.call(vm, cmsDocService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, cmsDocService);
        var atobParams = atob(navigationService.stateParams().parentParams);
        var parentParams = JSON.parse(atobParams);
        var viewPageCode = parentParams.cmsViewCode;
        vm.isDetail = false;

        vm.setIsEditing();

        //#region Toolbar
        var toolbar = new NbsToolbar(vm.translate);
        toolbar.setDocumentationToolbar({
            backOnClick: () => { back() },
            saveOnClick: () => { vm.submitAction(); },
            deleteOnClick: () => { },
            cloneOnClick: () => { },
            refreshOnClick: () => { vm.refreshAction(); },
            publishOnClick: () => { vm.writeAction(); },
            downloadOnClick: () => { vm.getDocumentationCmsViewXmlData(); },
        });
        vm.toolbarDatacontext = toolbar;
        //#endregion

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('viewpageelementdto', null);
        nbsGridDatacontext.allowSearching = true;
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        var viewPageElementType = Nembus.Common.Library.util.parseEnum(cmsDocService.dataContext.enumTypes.ViewPageElementType, 'ViewPageElementDto', vm.translate);
        vm.viewPageElementTypeForBind = viewPageElementType.obj.translated.value_name;

        getViewPageElements(viewPageCode, true);

        vm.rowDblClick = function (args) {
            getViewPageElement(args.data.ViewPageElementId, true)
                .then(() => {
                    vm.isDetail = true;
                });
        };

        vm.refreshAction = function () {
            getViewPageElements(viewPageCode, true);
        };

        function back() {
            if (vm.isDetail) {
                vm.submitAction()
                    .then(() => {
                        vm.isDetail = false;
                        vm.forceGridRefresh = new Date().getTime();
                    });

            } else {
                history.back()
            }
        }

        function getViewPageElements(viewPageCode, forceReload) {
            var pageName = null;
            var viewMode = null;
            var defer = $q.defer();


            if (viewPageCode.indexOf('-') > 0) {
                pageName = viewPageCode.split('-')[0];
                viewMode = viewPageCode.split('-')[1];
            }
            else {
                pageName = viewPageCode;
                viewMode = '_';
            }

            var filter = {
                ViewPageName: pageName,
                CmsViewMode: viewMode,
                LanguageId: contextService.virtualContext.CurrentLanguageId
            };

            cmsDocService.getViewPageElements(filter, forceReload)
                .then(function (data) {
                    Lazy(data.results)
                        .each((item) => {
                            item.ViewPageElementTypeName = vm.viewPageElementTypeForBind[item.ViewPageElementTypeId];
                            item.DocWordValueCut = item.DocWordValue ? item.DocWordValue.substring(0, 15) : '';
                        });

                    var currentElements = Lazy(data.results).sortBy((el) => { return el.ElementDisplayOrder; }).toArray();

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: currentElements
                    }

                    defer.resolve(vm.currentElements);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getViewPageElement(viewPageElementId, forceReload) {
            var filter = {
                ViewPageElementId: viewPageElementId,
                LanguageId: contextService.virtualContext.CurrentLanguageId,
            };
            var defer = $q.defer();

            cmsDocService.getViewPageElement(filter, forceReload)
                .then(function (data) {
                    vm.currentElement = data.results[0];
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        vm.modalCancel = function () {
            cmsDocService.rejectChanges();
            navigationService.closeCurrentModal();
        };

        vm.writeAction = function () {
            cmsDocService.generateDocumentationFiles(contextService.virtualContext.CurrentLanguageId)
                .then((data) => {
                    bootService.checkContext(true)
                        .then(function (result) {

                        })
                        .catch(function (error) {

                        });
                })
                .catch(function (error) {
                    console.log(error);
                });
            ;
        };

        vm.getDocumentationCmsViewXmlData = function () {
            var pageName = null;
            var viewMode = null;

            if (viewPageCode.indexOf('-') > 0) {
                pageName = viewPageCode.split('-')[0];
                viewMode = viewPageCode.split('-')[1];
            }
            else {
                pageName = viewPageCode;
                viewMode = '_';
            }

            var filter = {
                ViewPageName: pageName,
                CmsViewMode: viewMode,
                LanguageId: contextService.virtualContext.CurrentLanguageId
            };
            cmsDocService.getDocumentationCmsViewXmlData(filter)
                .then((response) => {
                    var pgTitle = pageName + '-' + viewMode;
                    Nembus.Common.Library.converter.streamToDownload(pgTitle + '.dita', response.buffer.data);
                })
                .catch(function (error) {
                    var x = error;
                });
        }
    }

    viewPageElementsList.$inject = injectParams;
    viewPageElementsList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('viewPageElementsList', viewPageElementsList);
});