﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'rbacService', 'drbacService', 'utilService', 'foundationService', 'vhreventService', 'audService', 'reportService', 'navigationService', 'checkStateChangeService', 'contextService', 'workflowService'];
    var surveysExportReportForm = function ($q, $scope, $timeout, $injector, rbacService, drbacService, utilService, foundationService, vhreventService, audService, reportService, navigationService, checkStateChangeService, contextService, workflowService) {

        var vm = this;
        nbsVm.call(vm, reportService, navigationService, contextService, $q, $injector, $timeout);
        var checkStateChangeParams = { dirtyFlagIsDisabled: vm.isDirtyDisabled };
        checkStateChangeService.checkFormOnStateChange($scope, reportService, checkStateChangeParams);

        // all form items are enabled
        vm.setIsEditingReport();

        var appStoreCode = 'aud';
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var exportCode = navigationService.stateParams().exportCode;
        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
        var expressionTypeEnum = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.ExpressionType, null, vm.translate).obj.normal.name_value;
        var parentScope = $scope.$parent.vm;
        var surveyLifeStatusEnumParsed = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyLifeStatus, 'SurveyDto', vm.translate);

        var dsCallback = function () { return vhreventService.getVhrEventTypes({ AppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventType', viewTitle: vm.translate('SurveyDto.VhrEventTypeId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventType = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        
        dsCallback = function () { return vhreventService.getVhrEventGroups({ AppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventGroup', viewTitle: vm.translate('SurveyDto.VhrEventGroupId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventGroup = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        dsCallback = function () { return foundationService.getVhrTypes(false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrType', viewTitle: vm.translate('TargetDto.TargetTypeId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrType = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        dsCallback = function () { return foundationService.getVhrGroups(false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrGroup', viewTitle: vm.translate('TargetDto.TargetGroupId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrGroup = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        vm.nbsActionDrbacModalObj = new NbsAssigneeSelectorModal({
            translator: vm.translate,
            suspendDirty: vm.suspendDirty,
            navigationService: navigationService,
            contextUserForSelection: null,
            getServerVarFn: Nembus.Common.Library.server.getServerVar
        });
        vm.nbsActionDrbacModalObj.setVisibilityServerVar('ssv_audit_survey_assignee_visibility_selection');

        vm.vhrEventLifeStatus = surveyLifeStatusEnumParsed.list.translated;

        vm.isProcessEnabled = Nembus.Common.Library.auth.isProcessEnabled(contextService.virtualContext, moduleTargetId);

        // entity used for client validation
        vm.surveysExportReportFormClientData = audService.dataContext.surveyReportClientDatas.add({
            IsProcessEnabled: vm.isProcessEnabled,
            DrbacProcessId: null
        });

        vm.workflowStatusList = [];

        //#region Multiselect with grid selection
        var selectionParams = { destModalKey: 'vhr', entityMode: 'Target', translateKey: 'Target', isMultiselect: true };
        vm.nbsSelectionGridWithModalTarget = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        // set title of current controller in report definition detail view
        navigationService.setControllerTitle();

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];

            funcsPromises.push(getWorkflowActiveStatuses(forceReload, moduleTargetId));

            if (vm.isProcessEnabled) {
                funcsPromises.push(getDrbacProcesses(forceReload, virtualCompanyId));
            }
            
            $q.all(funcsPromises)
                .then(function (results) {
                    vm.workflowStatusList = results[0];

                    reportService.currentReport.formEntityToValidate = vm.surveysExportReportFormClientData;
                    reportService.currentReport.prepareReportFilterCallback = setQueryFilter;
                });
        }

        function setQueryFilter() {
            var queryFilter = { // SurveyExportFilter
                surveyFilter: {
                    SurveyId: null,
                    IsArchived: null
                },
                vhrEventFilter: {
                    ModuleTargetId: moduleTargetId,
                    StartDate1Expression: expressionTypeEnum.GreaterThanOrEqual,
                    StartDate2Expression: expressionTypeEnum.LessThanOrEqual,
                    HaveToExpandVhrsWithAssetTree: true
                },
                surveyVhrEventFilter: {
                    SurveyCompileDate1Expression: expressionTypeEnum.GreaterThanOrEqual,
                    SurveyCompileDate2Expression: expressionTypeEnum.LessThanOrEqual,
                    ExcludeOnDemand: true
                },
                virtualHrFilter: {
                },
                exportCode: exportCode
            };

            queryFilter.virtualHrFilter.VhrTypesId = vm.nbsSelectionGridWithModalVhrType.selectedIds;
            queryFilter.virtualHrFilter.VhrGroupsId = vm.nbsSelectionGridWithModalVhrGroup.selectedIds;


            queryFilter.vhrEventFilter.VirtualHumanResourcesId = vm.nbsSelectionGridWithModalTarget.selectedIds;
            queryFilter.vhrEventFilter.StartDate1 = vm.surveysExportReportFormClientData.SurveyExpireDate1;
            queryFilter.vhrEventFilter.StartDate2 = vm.surveysExportReportFormClientData.SurveyExpireDate2;
            queryFilter.vhrEventFilter.VhrEventTypesId = vm.nbsSelectionGridWithModalVhrEventType.selectedIds;
            queryFilter.vhrEventFilter.VhrEventGroupsId = vm.nbsSelectionGridWithModalVhrEventGroup.selectedIds;
            queryFilter.vhrEventFilter.VhrEventLifeStatusExpression = expressionTypeEnum.Equal;
            queryFilter.vhrEventFilter.VhrEventLifeStatus = surveyLifeStatusEnumParsed.obj.normal.value_name[vm.surveysExportReportFormClientData.VhrEventLifeStatusId];
            queryFilter.vhrEventFilter.WorkflowStatusId = vm.surveysExportReportFormClientData.WorkflowStatusId;

            queryFilter.surveyVhrEventFilter.SurveyCompileDate1 = vm.surveysExportReportFormClientData.SurveyCompileDate1;
            queryFilter.surveyVhrEventFilter.SurveyCompileDate2 = vm.surveysExportReportFormClientData.SurveyCompileDate2;
            queryFilter.surveyVhrEventFilter.AppUserAssignedIds = vm.nbsActionDrbacModalObj.appUserAreSelected()
                ? vm.nbsActionDrbacModalObj.appUserMultiselectSelectedIds()
                : null;
            queryFilter.surveyVhrEventFilter.UserGroupAssignedIds = vm.nbsActionDrbacModalObj.drbacUserGroupAreSelected()
                ? vm.nbsActionDrbacModalObj.drbacUserGroupMultiselectSelectedIds()
                : null;

            queryFilter.surveyFilter.IsArchived = vm.surveysExportReportFormClientData.IsArchived;

            parentScope.setIntegrationQueryFilter(queryFilter);
        }

        vm.drbacProcessOnChange = function () {
            reportService.currentReport.reportArgs.Ds1_Arg2.DrbacProcessId = vm.surveysExportReportFormClientData.DrbacProcessId;

            if (!vm.surveysExportReportFormClientData.DrbacProcessId) {
                vm.vhrEventTypesAreVisible = false;
                vm.vhrEventGroupsAreVisible = false;
                return;
            }

            // a process is selected
            var forceReload = true;
            vm.vhrEventTypesAreVisible = true;
            vm.vhrEventGroupsAreVisible = true;

            var processSelected = Lazy(vm.drbacProcesses).findWhere({ DrbacProcessId: Number(vm.surveysExportReportFormClientData.DrbacProcessId) });
            var vhrEventMatcherId = processSelected.VhrEventMatcherId;

            var funcsPromises = [];
            funcsPromises.push(getVhrEventMatcherTargetActivities_VhrEventTypes(forceReload, vhrEventMatcherId));
            funcsPromises.push(getVhrEventMatcherTargetActivities_VhrEventGroups(forceReload, vhrEventMatcherId));

            $q.all(funcsPromises)
                .then(function () {
                    //var selectedIds = [];

                    //// set types datasource and select ids
                    //vm.vhrEventTypesAcDs = new NbsMultiselect(vm.translate);
                    //vm.vhrEventTypesAcDs.addData(vm.currentVhrEventTypes, 'VhrEventTypeId', 'VhrEventTypeName');
                    //selectedIds = Lazy(vm.currentVhrEventTypes).pluck('VhrEventTypeId').toArray();
                    //vm.vhrEventTypesAcDs.setSelected(selectedIds);

                    //// set groups datasource and select ids
                    //vm.vhrEventGroupsAcDs = new NbsMultiselect(vm.translate);
                    //vm.vhrEventGroupsAcDs.addData(vm.currentVhrEventGroups, 'VhrEventGroupId', 'VhrEventGroupName');
                    //selectedIds = Lazy(vm.currentVhrEventGroups).pluck('VhrEventGroupId').toArray();
                    //vm.vhrEventGroupsAcDs.setSelected(selectedIds);
                });

        };

        //target vhreventstypes at moment binded to hr group
        function getVhrEventMatcherTargetActivities_VhrEventTypes(forceReload, vhrEventMatcherId) {
            var defer = $q.defer();

            vhreventService.getVhrEventMatchersTargetActivities_VhrEventTypes({ VhrEventMatcherId: vhrEventMatcherId }, forceReload)
                .then(function (data) {
                    vm.currentVhrEventTypes = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        //target vhreventgroups at moment binded to hr group
        function getVhrEventMatcherTargetActivities_VhrEventGroups(forceReload, vhrEventMatcherId) {
            var defer = $q.defer();

            vhreventService.getVhrEventMatchersTargetActivities_VhrEventGroups({ VhrEventMatcherId: vhrEventMatcherId }, forceReload)
                .then(function (data) {
                    vm.currentVhrEventGroups = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getDrbacProcesses(forceReload, virtualCompanyId) {
            var filter = {
                VirtualCompanyId: virtualCompanyId,
                IsHidden: false,
                IsGlobalProcess: false,
                IsEnabled: true
            };
            var defer = $q.defer();

            drbacService.getDrbacProcesses(filter, forceReload)
                .then(function (data) {
                    vm.drbacProcesses = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getWorkflowActiveStatuses(forceReload, moduleTargetId) {
            var defer = $q.defer();

            workflowService.getWorkflowActiveStatuses({ ModuleTargetId: moduleTargetId }, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }
    };

    surveysExportReportForm.$inject = injectParams;
    surveysExportReportForm.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('surveysExportReportForm', surveysExportReportForm);
});
