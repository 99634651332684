﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'navigationService', 'contextService', 'formSummaryService'];
    var virtualCompanyFtc = function ($q, $scope, $timeout, $injector, foundationService, navigationService, contextService, formSummaryService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        
        var referral = 'virtualcompany';
        var virtualCompanyId = navigationService.stateParams().virtualCompanyId;
        var cmsView = 'virtualcompanyftc-' + vm.viewMode;
        var virtualCompany = null;
        var state = { generality: 'generality', legalgenerality: 'legalgenerality', contacts: 'contacts', addresses: 'addresses' };

        var fastTab = new NbsAccordion();
        fastTab.AddFastTab(state.generality, "General");
        fastTab.AddFastTab(state.contacts, "Contacts");
        fastTab.AddFastTab(state.addresses, "Addresses");
        vm.fastTabDatacontext = fastTab;

        vm.ftcOnClick = function (event) {
            navigateTo(event.id);
        }

        // raised from detail view
        vm.ftcOnAddEntity = function (entity) {
            $timeout(() => {
                // in this case is not called
            });
        }

        if (virtualCompanyId) {
            foundationService.getVirtualCompany({ VirtualCompanyId: virtualCompanyId }, false)
                .then(function (data) {
                    virtualCompany = data.results[0];

                    $timeout(() => {
                        vm.fastTabsAreDisabled = false;
                    });

                    // configure form summary items
                    formSummaryService.clearDatasource();
                    formSummaryService.push({ key: vm.translateCmsView(cmsView, 'VirtualCompanyName', 'VirtualCompany'), value: virtualCompany.VirtualCompanyName });
                    formSummaryService.push({ key: vm.translateCmsView(cmsView, 'VirtualCompanyCode', 'VirtualCompany'), value: virtualCompany.VirtualCompanyCode });

                    goToDefaultFastTab();
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });
        } else {
            goToDefaultFastTab();

            $timeout(() => {
                vm.fastTabsAreDisabled = true;
            });
        }

        function navigateTo (accordionId) {
            if (!accordionId) {
                return;
            }

            var navigationState = null;
            var navigationStateParams = null;
            switch (accordionId) {
                case state.generality:
                    navigationState = 'app.nav.virtualcompanyftc.virtualcompanydetail';
                    navigationStateParams = { 'virtualCompanyId': virtualCompanyId };
                    break;
                case state.legalgenerality:
                    navigationState = 'app.nav.virtualcompanyftc.hrlegalgeneralitydetail';
                    navigationStateParams = { 'hrLegalGeneralityId': virtualCompany.HrLegalGeneralityId, 'referral': referral };
                    break;
                case state.contacts:
                    navigationState = 'app.nav.virtualcompanyftc.hrcontactdetail';
                    navigationStateParams = { 'hrContactsId': virtualCompany.HrContactsId, 'referral': referral };
                    break;
                case state.addresses:
                    navigationState = 'app.nav.virtualcompanyftc.hraddressdetail';
                    navigationStateParams = { 'hrContactsId': virtualCompany.HrContactsId, 'referral': referral };
                    break;
            }

            // call navigation state
            if (navigationState && navigationStateParams) {
                navigationService.navigateTo(navigationState, navigationStateParams);
            }
        };

        function goToDefaultFastTab() {
            // go to default page only if is called ftc directly
            if (navigationService.currentState().endsWith('.virtualcompanyftc')) {
                navigateTo(state.generality);
            }
        }
    };

    virtualCompanyFtc.$inject = injectParams;
    virtualCompanyFtc.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('virtualCompanyFtc', virtualCompanyFtc);
});