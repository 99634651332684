﻿'use strict';
define([], function () {

    var injectParams = [];
    var nbsMsItemComponent = function () {

        var vm = this;

        vm.$onInit = function () {
        };

        vm.$onChanges = function (changes) {
        };

        //raise event to source caller
        vm.onOptionDeleteEvent = function (item) {
            vm.optionDeleteEvent(item);
        };
    };

    nbsMsItemComponent.$inject = injectParams;
    //nbsMsItemComponent.prototype = Object.create(nbsVm.prototype);
    //angular.module('nbs.rds.module').controller('nbsMsItemComponent', nbsMsItemComponent);
    angular.module('nbs.rds.module').component('nbsMsItem', {
        templateUrl: '/rds/common/components/nbsMsItemComponent/_?c=' + window.codeCache(),
        controller: nbsMsItemComponent,
        controllerAs: 'vm',
        bindings: {
            optionDeleteEvent: '&',
            uiContext: "<",
            nbsDataContext: "<"
        }
    });
});

