﻿'use strict';
define([], function () {
    var injectParams = ['$http', '$state'];

    function formSummaryService($http, $state) {

        var datasource = [];

        this.clearDatasourceByReferral = function (referral) {
            datasource = Lazy(datasource).reject({ referral: referral }).toArray();
        };

        this.push = function (item) {
            if (!item.value) {
                item.value = '';
            }

            if (!item.referral) {
                item.referral = '';
            }

            item.value = Nembus.Common.Library.converter.decodeHtml(item.value);

            datasource.push(item);
        };

        this.getDatasourceByReferral = function (referral) {
            return Lazy(datasource).filter({ referral: referral }).toArray();
        };

        this.getDatasource = function () {
            return datasource;
        };

        this.clearDatasource = function () {
            datasource = [];
        };
    }

    formSummaryService.$inject = injectParams;
    angular.module('app').service('formSummaryService', formSummaryService);
});