﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var companyDetail = function ($q, $scope, $timeout, $injector, foundationService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, foundationService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var companyId = navigationService.stateParams().companyId;
        if (companyId) {
            vm.setIsViewing();
            getCompany(true);
        }
        else {
            vm.setIsAdding();
            vm.currentCompany = foundationService.dataContext.companies.add({
                VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId
            });

            // manage of passport photo
            vm.photoDatacontext = {
                photoImageId: null
            };
        }

        // get reference of submitaction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parents = vm.submitAction;
        var ftcScope = $scope.$parent.vm;
        vm.submitAction = function () {
            parents().then(
                function (saveResult) {
                    if (saveResult.entities.length > 0 && vm.viewContext.isAddingState && ftcScope.ftcOnAddEntity) {
                        ftcScope.ftcOnAddEntity(vm.currentCompany);
                    }
                }
            );
        };

        vm.onImageChange = function (result) {
            if (result.action === 'upload') {
                vm.currentCompany.TemporaryFileName = result.temporaryFileName;
                vm.currentCompany.TemporaryFileNameAction = 1; // ImageLogoAction.Upload
            }
            if (result.action === 'delete') {
                vm.currentCompany.TemporaryFileName = null;
                vm.currentCompany.TemporaryFileNameAction = 2; // ImageLogoAction.Delete
            }
        };

        function getCompany(forceReload) {
            var filter = { CompanyId: companyId };
            var defer = $q.defer();

            foundationService.getCompany(filter, forceReload)
                .then(function (data) {
                    vm.currentCompany = data.results[0];

                    // manage of passport photo
                    vm.photoDatacontext = {
                        resizeMode: 'no-resize',
                        photoImageId: vm.currentCompany.CompanyImageId
                    };

                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    companyDetail.$inject = injectParams;
    companyDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('companyDetail', companyDetail);
});