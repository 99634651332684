﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'drbacService', 'navigationService', 'contextService', 'formSummaryService'];
    var drbacUserGroupFtc = function ($q, $scope, $timeout, $injector, drbacService, navigationService, contextService, formSummaryService) {

        var vm = this;
        nbsVm.call(vm, drbacService, navigationService, contextService, $q, $injector, $timeout);

        var referral = 'drbacusergroup';
        var appStoreCode = navigationService.stateParams().appStoreCode;
        appStoreCode = appStoreCode ? appStoreCode : "";
        var drbacUserGroupId = navigationService.stateParams().drbacUserGroupId;
        var cmsView = 'drbacusergroupftc-' + vm.viewMode;
        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;

        var state = { drbacUserGroupFtcUserGroup: 'drbacUserGroupFtcUserGroup', drbacUserGroupFtcUsers: 'drbacUserGroupFtcUsers' };

        var fastTab = new NbsAccordion();
        fastTab.AddFastTab(state.drbacUserGroupFtcUserGroup, "UserGroup");
        fastTab.AddFastTab(state.drbacUserGroupFtcUsers, "UsersInGroup");
        vm.fastTabDatacontext = fastTab;

        vm.ftcOnClick = function (event) {
            navigateTo(event.id);
        }

        // raised from detail view
        vm.ftcOnAddEntity = function (entity) {
            $timeout(() => {
                drbacUserGroupId = entity.DrbacUserGroupId;

                vm.fastTabsAreDisabled = false;
            });
        }

        if (drbacUserGroupId) {
            drbacService.getDrbacUserGroup({ DrbacUserGroupId: drbacUserGroupId, VirtualCompanyId: virtualCompanyId }, false)
                .then(function (data) {
                    var drbacUserGroup = data.results[0];

                    $timeout(() => {
                        vm.fastTabsAreDisabled = false;
                    });

                    // configure form summary items
                    formSummaryService.clearDatasource();
                    formSummaryService.push({ key: vm.translateCmsView(cmsView, 'DrbacUserGroupCode', 'DrbacUserGroup'), value: drbacUserGroup.DrbacUserGroupCode });
                    formSummaryService.push({ key: vm.translateCmsView(cmsView, 'DrbacUserGroupName', 'DrbacUserGroup'), value: drbacUserGroup.DrbacUserGroupName });

                    goToDefaultFastTab();
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });
        } else {
            goToDefaultFastTab();

            $timeout(() => {
                vm.fastTabsAreDisabled = true;
            });
        }

        function navigateTo(accordionId) {
            if (!accordionId) {
                return;
            }

            var navigationState = null;
            var navigationStateParams = null;
            switch (accordionId) {
                case state.drbacUserGroupFtcUserGroup:
                    navigationState = 'app.nav.drbacusergroupftc.drbacusergroupdetail';
                    navigationStateParams = { 'drbacUserGroupId': drbacUserGroupId, 'appStoreCode': appStoreCode };
                    break;
                case state.drbacUserGroupFtcUsers:
                    navigationState = 'app.nav.drbacusergroupftc.drbacusertousergroups';
                    navigationStateParams = { 'drbacUserGroupId': drbacUserGroupId, 'referral': referral };
                    break;
            }

            // call navigation state
            if (navigationState && navigationStateParams) {
                navigationService.navigateTo(navigationState, navigationStateParams);
            }
        };

        function goToDefaultFastTab() {
            // go to default page only if is called ftc directly
            if (navigationService.currentState().endsWith('.drbacusergroupftc')) {
                navigateTo(state.drbacUserGroupFtcUserGroup);
            }
        }
    };

    drbacUserGroupFtc.$inject = injectParams;
    drbacUserGroupFtc.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rbac.module').controller('drbacUserGroupFtc', drbacUserGroupFtc);
});