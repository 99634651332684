﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'vhreventService', 'hrbucketService', 'utilService', 'activityService', 'productionService', 'foundationService', 'navigationService', 'checkStateChangeService', 'contextService', 'cmsService'];
    function assetsSearch($q, $scope, $timeout, $injector, vhreventService, hrbucketService, utilService, activityService, productionService, foundationService, navigationService, checkStateChangeService, contextService, cmsService) {
        var vm = this;
        nbsVm.call(vm, vhreventService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, vhreventService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'search');
        ribbon.addHomeTab();
        ribbon.addSearchTab();
        ribbon.tabIndexSelected = 2;
        vm.ribbonDatacontext = ribbon;

        vm.setIsEditingSearch();
        ribbon.setRibbonStatusButton(ribbon.Buttons.New, true);
        ribbon.refreshStatusButtons();


        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var activityKindEnum = Nembus.Common.Library.util.parseEnum(activityService.dataContext.enumTypes.ActivityKind, null, vm.translate).obj.normal.name_value;
        var expressionTypeEnum = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.ExpressionType, null, vm.translate).obj.normal.name_value;
        var vhrEventKindEnum = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventKind, null, vm.translate).obj.normal.name_value;
        var bucketClassificationEnum = Nembus.Common.Library.util.parseEnum(hrbucketService.dataContext.enumTypes.BucketClassification, null, vm.translate).obj.normal.name_value;
        var activityKindId = activityKindEnum.Bom;
        var wzVhrEntityType = navigationService.stateParams().wzVhrEntityType;          //asset, avatar, bucket, ...        
        var vhrEventCreationArchetype = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventCreationArchetype, null, vm.translate).obj.normal.name_value;
        var vhrEventManualRequestType = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventManualRequestType, null, vm.translate).obj.normal.name_value;

        var filtersToSkip = [];
        var nbsSearchForm = new NbsSearchForm(vm.translate);
        var searchFormData = nbsSearchForm.SearchAssetTreesFormData();
        try { filtersToSkip = contextService.virtualContext.behaviors.search.asset[appStoreCode].skipFilters; } catch (e) { }
        searchFormData.init(vm, filtersToSkip);

        var vhrEventFixedTypeCode = navigationService.stateParams().vhrEventFixedTypeCode;
        var searchVirtualEquipmentClientData = {};

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('assettree', 'list');
        nbsGridDatacontext.setGridIsRenderedInlineBlock();
        nbsGridDatacontext.setIsMultiselect();
        nbsGridDatacontext.allowSearching = false;
        nbsGridDatacontext.allowGrouping = false;
        nbsGridDatacontext.setDatasource([], false); // fake datasource, only to create grid
        vm.nbsGridDatacontext = nbsGridDatacontext;

        var selectionParams = { destModalKey: 'activity', appStoreBw: null, activityKindId: activityKindId };
        vm.nbsSelectionGridWithModalActivity = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalActivity.setOnSelectFnCallback(function () {
            vm.currentActivityVhrCoreFilter.ActivityIds = vm.nbsSelectionGridWithModalActivity.selectedIds;
        });

        selectionParams = { destModalKey: 'category', systemCategoryCode: 'ALB_IMP', categoryType: 'user', isAutocheck: false, viewKind: 'all', expandTree: false };
        vm.nbsSelectionTreeviewWithModalCategory = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategory.setOnSelectFnCallback(function () {
            vm.currentVirtualHrFilter.CategoriesId = vm.nbsSelectionTreeviewWithModalCategory.selectedIds;
        });

        selectionParams = { destModalKey: 'category', systemCategoryCode: 'ALB_IMP', categoryType: 'user', isAutocheck: false, viewKind: 'all', expandTree: false };
        vm.nbsSelectionTreeviewWithModalCategoryHazards = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategoryHazards.setOnSelectFnCallback(function () {
            vm.currentVirtualHrFilter.InheritedCategoriesId = vm.nbsSelectionTreeviewWithModalCategoryHazards.selectedIds;
        });

        var dsCallback = function () { return vhreventService.getVhrEventTypes({ AppStoreBw: appStoreBw, IsBomType: false }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventType', viewTitle: vm.translate('VhrEvent.VhrEventType'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventType = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalVhrEventType.setOnSelectFnCallback(function () {
            vm.currentVhrEventFilter.VhrEventTypesId = vm.nbsSelectionGridWithModalVhrEventType.selectedIds;
        });

        dsCallback = function () { return vhreventService.getVhrEventGroups({ AppStoreBw: appStoreBw, IsBomGroup: false }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventGroup', viewTitle: vm.translate('VhrEvent.VhrEventGroup'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventGroup = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalVhrEventGroup.setOnSelectFnCallback(function () {
            vm.currentVhrEventFilter.VhrEventGroupsId = vm.nbsSelectionGridWithModalVhrEventGroup.selectedIds;
        });

        dsCallback = function () { return productionService.getVirtualWorkCenters({ AppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'WorkCenter', viewTitle: vm.translate('WorkCenter'), ds: dsCallback };
        vm.nbsSelectionGridWithModalWorkCenter = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalWorkCenter.setOnSelectFnCallback(function () {
            vm.currentVhrEventFilter.WorkCentersId = vm.nbsSelectionGridWithModalWorkCenter.selectedIds;
        });

        dsCallback = function () { return hrbucketService.getHrBuckets({ BucketClassificationId: bucketClassificationEnum.GenericLegalEntity, OwnerAppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'HrBucket', viewTitle: vm.translate('LegalEntities'), ds: dsCallback };
        vm.nbsSelectionGridWithModalLegalEntities = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalLegalEntities.setOnSelectFnCallback(function () {
            vm.currentVirtualHrFilter.LegalEntityIds = vm.nbsSelectionGridWithModalLegalEntities.selectedIds;
        });

        dsCallback = function () { return hrbucketService.getHrBuckets({ BucketClassificationId: bucketClassificationEnum.CostCenter, OwnerAppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'HrBucket', viewTitle: vm.translate('CostCenters'), ds: dsCallback };
        vm.nbsSelectionGridWithModalCostCenters = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalCostCenters.setOnSelectFnCallback(function () {
            vm.currentVirtualHrFilter.CostCenterIds = vm.nbsSelectionGridWithModalCostCenters.selectedIds;
        });

        dsCallback = function () { return hrbucketService.getHrBuckets({ BucketClassificationId: bucketClassificationEnum.BusinessUnit, OwnerAppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'HrBucket', viewTitle: vm.translate('BusinessUnits'), ds: dsCallback };
        vm.nbsSelectionGridWithModalBusinessUnits = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalBusinessUnits.setOnSelectFnCallback(function () {
            vm.currentVirtualHrFilter.BusinessUnitIds = vm.nbsSelectionGridWithModalBusinessUnits.selectedIds;
        });

        dsCallback = function () { return hrbucketService.getHrBuckets({ BucketClassificationId: bucketClassificationEnum.Sector, OwnerAppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'HrBucket', viewTitle: vm.translate('Sectors'), ds: dsCallback };
        vm.nbsSelectionGridWithModalSectors = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalSectors.setOnSelectFnCallback(function () {
            vm.currentVirtualHrFilter.SectorIds = vm.nbsSelectionGridWithModalSectors.selectedIds;
        });

        dsCallback = function () { return hrbucketService.getHrBuckets({ BucketClassificationId: bucketClassificationEnum.Plant, OwnerAppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'HrBucket', viewTitle: vm.translate('Plants'), ds: dsCallback };
        vm.nbsSelectionGridWithModalPlants = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalPlants.setOnSelectFnCallback(function () {
            vm.currentVirtualHrFilter.PlantIds = vm.nbsSelectionGridWithModalPlants.selectedIds;
        });

        dsCallback = function () { return vhreventService.getVhrEventLabels({ AppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventLabel', viewTitle: vm.translate('VhrEvent.VhrEventLabels'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventLabel = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalVhrEventLabel.setOnSelectFnCallback(function () {
            vm.currentVhrEventFilter.VhrEventLabelsId = vm.nbsSelectionGridWithModalVhrEventLabel.selectedIds;
        });

        selectionParams = { destModalKey: 'vhr', entityMode: 'AssetTree', translateKey: 'VirtualEquipment', isMultiselect: true };
        vm.nbsSelectionGridWithModalAssetTree = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalAssetTree.setOnSelectFnCallback(function () {
            // onchange imsasset
            if (!vm.currentAssetTreeFilter) {
                vm.currentAssetTreeFilter = {};
            }
            vm.currentAssetTreeFilter.VirtualHumanResourcesId = [];
            if (vm.nbsSelectionGridWithModalAssetTree.selectedIds) {
                vm.currentAssetTreeFilter.VirtualHumanResourcesId = vm.nbsSelectionGridWithModalAssetTree.selectedIds;
            }
        });

        vm.searchTabIndex = 0;

        vm.gridSelection = function (args) {
            vm.wzVirtualHumanResources = Lazy(args.data)
                .map((asset) => {
                    return {
                        VirtualHumanResourceId: asset.VirtualHumanResourceId,
                        VirtualName: asset.EquipmentName
                    };
                }).toArray();
        };

        function getVhrEventManualRequest(forceReload, vhrEventManualRequestId) {
            var filter = { VhrEventManualRequestId: vhrEventManualRequestId };
            var defer = $q.defer();

            vhreventService.getVhrEventManualRequest(filter, forceReload)
                .then(function (data) {
                    vm.vhrEventManualRequest = data.results[0];
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        // open wizard to add new manual event
        vm.newAction = function () {
            if (!Lazy(vm.wzVirtualHumanResources).some()) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            var services = { navigationService: navigationService, vhreventService: vhreventService, cmsService: cmsService }
            var wzNbsManager = new NbsWizardManager($q, vm.translate, services, NbsGuidManager);
            var config = new wzNbsManager.WzConfig_AddVhrEventManual(null);
            config.supportData = { vhrEventCreationArchetype: vhrEventCreationArchetype };
            config.vhrEntityType = wzVhrEntityType;
            config.defaultData.VirtualHumanResources = vm.wzVirtualHumanResources;
            config.stepsToSkip.push('vhrEventsManualStepVhr');
            config.defaultData.VhrEventManualRequestTypeId = vhrEventManualRequestType.New;
            wzNbsManager.setWzConfig(config);

            wzNbsManager.open()
                .then(function (result) {
                    // result contains VhrEventManualRequest (not in context breeze)
                    var vhrEventManualRequestResult = result;
                    vhrEventManualRequestResult.VirtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
                    vhrEventManualRequestResult.VhrEventFixedTypeCode = vhrEventFixedTypeCode;
                    var vhrEventManualRequest = vhreventService.dataContext.vhrEventManualRequests.add(vhrEventManualRequestResult);

                    vm.submitAction()
                        .then(function (result) {
                            if (vhrEventManualRequest.IsBatch) {
                                contextService.addBatchRequest({
                                    requestId: vhrEventManualRequest.BatchRequestId,               //only one entity will be submitted
                                    requestName: vhrEventManualRequest.BatchRequestName,
                                    requestStatus: 'inserted',
                                    onCompletedAutoAction: function (request) {
                                        var defer = $q.defer();
                                        getVhrEventManualRequest(true, vhrEventManualRequest.VhrEventManualRequestId)
                                            .then(function () {
                                                var jobResult = vm.getJobStatus();
                                                //public enum VhrEventManualRequestStatus {
                                                //    None = 0,
                                                //    Inserted = 1,
                                                //    SuccessfullyDone = 2,
                                                //    SomeErrors = 3,
                                                //    SomeWarnings = 4,
                                                //    SomeConflict = 5,
                                                //    }
                                                switch (vm.vhrEventManualRequest.VhrEventManualRequestStatusId) {
                                                    case 2: jobResult.jobStatus = 'done'; break;     // SuccessfullyDone
                                                    case 3: jobResult.jobStatus = 'error'; break;     // SomeErrors
                                                    case 4: jobResult.jobStatus = 'warning'; break;     // SomeWarnings
                                                    case 5: jobResult.jobStatus = 'conflict'; break;     // SomeConflict
                                                }
                                                //jobResult.jobMessage = '';  // message before requestName in toolbar item
                                                //jobResult.jobDetailActionLabel = '';// Label of detail item in dropdown

                                                // used in detailAction
                                                request.VhrEventManualRequestId = vm.vhrEventManualRequest.VhrEventManualRequestId;

                                                defer.resolve(jobResult);
                                            });

                                        return defer.promise;
                                    },
                                    detailAction: function (request) {
                                        navigationService.navigateTo("app.nav.vhreventmanualrequestdetail", {
                                            'vhrEventManualRequestId': request.VhrEventManualRequestId,
                                            'appStoreCode': appStoreCode
                                        });
                                    }
                                });
                            }

                            vm.refreshAction();
                        })
                        .catch(function (error) {
                            // error
                            vm.setErrorMsg(error);
                            vhreventService.rejectChanges();
                        });
                });
        };

        vm.addFilterAction = function () {
            vm.searchTabIndex = 0;
            searchFormData.addFilters(navigationService, vm.translate, $scope);
        };

        vm.searchAction = function () {
            // start search
            searchVirtualEquipments();
        };

        vm.deleteFilterAction = function () {
            $timeout(() => {
                vm.searchTabIndex = 0;
                vm.resetAction();
                searchFormData.removeFilters(filtersToSkip);
            });
        };

        vm.resetAction = function () {
            $timeout(() => {
                vm.searchTabIndex = 0;
                searchFormData.resetFilters();

                vm.nbsSelectionGridWithModalActivity.clear();
                vm.nbsSelectionTreeviewWithModalCategory.clear();
                vm.nbsSelectionTreeviewWithModalCategoryHazards.clear();
                vm.nbsSelectionGridWithModalVhrEventType.clear();
                vm.nbsSelectionGridWithModalVhrEventGroup.clear();
                vm.nbsSelectionGridWithModalWorkCenter.clear();
                vm.nbsSelectionGridWithModalLegalEntities.clear();
                vm.nbsSelectionGridWithModalCostCenters.clear();
                vm.nbsSelectionGridWithModalBusinessUnits.clear();
                vm.nbsSelectionGridWithModalSectors.clear();
                vm.nbsSelectionGridWithModalPlants.clear();
                vm.nbsSelectionGridWithModalAssetTree.clear();
            });
        };

        //vhrevent life status drop down
        var vhrEventLifeStatusEnumParsed = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventLifeStatus, 'VhrEvent', vm.translate);
        vm.vhrEventLifeStatusList = Lazy(vhrEventLifeStatusEnumParsed.list.translated).filter(filterVhrEventLifeStatus).toArray();
        function filterVhrEventLifeStatus(lifeStatus) {
            return lifeStatus.value === vhrEventLifeStatusEnumParsed.obj.normal.name_value.None
                || lifeStatus.value === vhrEventLifeStatusEnumParsed.obj.normal.name_value.Approved
                || lifeStatus.value === vhrEventLifeStatusEnumParsed.obj.normal.name_value.Closed
                || lifeStatus.value === vhrEventLifeStatusEnumParsed.obj.normal.name_value.FinishedAndChecked
                || lifeStatus.value === vhrEventLifeStatusEnumParsed.obj.normal.name_value.ReportedAsFinished
                || lifeStatus.value === vhrEventLifeStatusEnumParsed.obj.normal.name_value.Running;
        }

        //vhrevent billing status drop down
        var vhrEventBillingStatusEnumParsed = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventBillingStatus, 'VhrEvent', vm.translate);
        vm.billingStatusList = Lazy(vhrEventBillingStatusEnumParsed.list.translated).filter(filterBillingStatus).toArray();
        function filterBillingStatus(billingStatus) {
            return billingStatus.value === vhrEventBillingStatusEnumParsed.obj.normal.name_value.None
                || billingStatus.value === vhrEventBillingStatusEnumParsed.obj.normal.name_value.NotApplicable
                || billingStatus.value === vhrEventBillingStatusEnumParsed.obj.normal.name_value.Waiting
                || billingStatus.value === vhrEventBillingStatusEnumParsed.obj.normal.name_value.Billable
                || billingStatus.value === vhrEventBillingStatusEnumParsed.obj.normal.name_value.NotBillable
                || billingStatus.value === vhrEventBillingStatusEnumParsed.obj.normal.name_value.Turnover
                || billingStatus.value === vhrEventBillingStatusEnumParsed.obj.normal.name_value.Suspended;
        }

        var ejDataManager = null;
        function searchVirtualEquipments() {
            searchVirtualEquipmentClientData.vhrEventFilter = nbsSearchForm.ParseVhrEventFilter(vm.currentVhrEventFilter, appStoreBw, vhrEventKindEnum, expressionTypeEnum);
            searchVirtualEquipmentClientData.vhrEventFilter.AppStoreBw = null;
            searchVirtualEquipmentClientData.vhrEventFilter.VhrEventKind = null;
            searchVirtualEquipmentClientData.vhrEventFilter.ModuleTargetId = moduleTargetId;
            searchVirtualEquipmentClientData.assetTreeFilter = nbsSearchForm.ParseAssetTreeFilter(vm.currentAssetTreeFilter, vm.currentVirtualHrFilter, appStoreBw);
            searchVirtualEquipmentClientData.activityVhrCoreFilter = nbsSearchForm.ParseActivityVhrCoreFilter(vm.currentActivityVhrCoreFilter, appStoreBw);

            var gridfilter = { filter: searchVirtualEquipmentClientData };

            if (ejDataManager === null) {
                var supportData = { translate: vm.translate };
                ejDataManager = foundationService.getAssetTreeDataManager(supportData, gridfilter, ejDataManager);
                nbsGridDatacontext.setDatasource(ejDataManager, true);
                refreshGridDatasource(gridfilter);
            } else {
                refreshGridDatasource(gridfilter);
            }
        }

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.assetftc", { 'virtualHumanResourceId': args.data.VirtualHumanResourceId, 'appStoreCodeFtc': appStoreCode, 'viewKindFtc': 'full', 'viewMode': 'ers', 'referral': 'virtualequipment' });
        };

        function refreshGridDatasource(currentGridFilter) {
            $timeout(() => {
                vm.searchTabIndex = 1;

                vm.forceDatamanagerFilter = {
                    refreshKey: new Date().getTime(),
                    filter: currentGridFilter
                }
            });
        }

        vm.refreshAction = function () {
            $timeout(() => {
                vm.searchTabIndex = 1;
                vm.wzVirtualHumanResources = [];
                vm.forceGridRefresh = new Date().getTime();
            });
        };
    }

    assetsSearch.$inject = injectParams;
    assetsSearch.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('assetsSearch', assetsSearch);
});