﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$injector', '$scope', '$timeout', 'notificationService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function notificationSchemasList($q, $injector, $scope, $timeout, notificationService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, notificationService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return notificationSchemaSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, notificationService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        ribbon.setRibbonStatusButton(ribbon.Buttons.New,  false);
        ribbon.refreshStatusButtons();

        vm.notificationSchemas = [];
        var notificationSchemaSelected = null;


        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('notificationschema', null);
        nbsGridDatacontext.viewCommonBar();
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getNotificationConfig(forceReload));
            funcsPromises.push(getNotificationSchemas(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.notificationConfig = results[0];
                    vm.notificationSchemas = results[1];

                    Lazy(vm.notificationSchemas)
                        .each((schema) => {
                            schema.IsActive = schema.NotificationSchemaId === vm.notificationConfig.ActiveNotificationSchemaId;
                        });                    

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.notificationSchemas
                    }
                });
        }

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.notificationschemadetail", { 'notificationSchemaId': args.data.NotificationSchemaId });
        };

        vm.refreshAction = function () {
            loadData(true);
        };

        vm.gridSelection = function (args) {
            $timeout(() => {
                notificationSchemaSelected = args.data;

                if (args.data) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, !args.data.IsSystem);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, true);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, false);
                }

                ribbon.refreshStatusButtons();
            });            
        };

        vm.deleteAction = function () {
            if (!notificationSchemaSelected) {
                return;
            }            

            notificationService.deleteEntity(notificationSchemaSelected);
            vm.submitAction()
                .then(function (saveResult) {
                    vm.refreshAction(true);
                });
        };

        vm.copyAction = function () {
            if (!notificationSchemaSelected)
                return;

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        var filter = {
                            NotificationSchemaId: notificationSchemaSelected.NotificationSchemaId
                        };

                        notificationService.cloneNotificationSchema(filter)
                            .then(function (result) {
                                vm.refreshAction();
                            });
                    }
                });
        };

        function getNotificationSchemas(forceReload) {
            var filter = {};
            var defer = $q.defer();

            notificationService.getNotificationSchemas(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getNotificationConfig(forceReload) {
            var filter = {};
            var defer = $q.defer();

            notificationService.getNotificationConfig(filter, forceReload)
                .then(function (data) {                    
                    defer.resolve(data.results[0]);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    }

    notificationSchemasList.$inject = injectParams;
    notificationSchemasList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('notificationSchemasList', notificationSchemasList);
});