﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$timeout', '$injector', 'navigationService', 'contextService', 'fdsupportService'];
    function fdTicketsList($q, $timeout, $injector, navigationService, contextService, fdsupportService) {

        var vm = this;
        nbsVm.call(vm, fdsupportService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return ticketSelected; });

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var ticketSelected = null;


        vm.tickets = [];

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('fdticket', null);
        nbsGridDatacontext.viewCommonBar();
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        loadData();
        function loadData() {
            var funcsPromises = [];
            funcsPromises.push(getTickets());

            $q.all(funcsPromises)
                .then(function (results) {
                    Lazy(results[0])
                        .each(i => {
                            i.statusName = vm.translate('FdTicket.status_' + i.status);
                        });

                    vm.tickets = data.results;

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.tickets
                    }
                });
        }

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.fdticketdetail", { 'id': args.data.id });
        };

        vm.newAction = function () {
            navigationService.navigateTo("app.nav.fdticketdetail");
        };

        vm.refreshAction = function () {
            loadData();
        };

        vm.gridSelection = function (args) {
            ticketSelected = args.data;
        };

        function getTickets() {
            var filter = { };
            var defer = $q.defer();

            fdsupportService.getTickets(filter, true)
                .then(data => {
                    defer.resolve(data.results);
                });

            return defer.promise;
        }
    }

    fdTicketsList.$inject = injectParams;
    fdTicketsList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('fdTicketsList', fdTicketsList);
});