﻿// TECCLEANGENERALDATA data is the event of type data viewed in events list

'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'cmstoolService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var cmsStaticContentDataDetail = function ($q, $scope, $timeout, $injector, cmstoolService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, cmstoolService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, cmstoolService);

        var currentCmsStaticContentCode = navigationService.stateParams().cmsStaticContentCode;


        vm.ejRTE = new NbsRichTextEditor(NbsGuidManager);
        vm.ejRTE.enableToolbarImages(true);

        //In this controller is used parent scope to link button of ribbon in parent scope
        var parentScope = $scope.$parent.vm;
        parentScope.viewContext = this.viewContext;


        if (currentCmsStaticContentCode) {
            parentScope.setIsViewing();
            parentScope.tabAttachmentsIsDisabled = false;
            getCmsStaticContent(currentCmsStaticContentCode, false);
        } else {
            parentScope.setIsAdding();
            vm.currentCmsStaticContent = cmstoolService.dataContext.cmsStaticContents.add({
                CmsStaticContentId: NbsGuidManager.newGuid(),
                VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId
            });
        }

        function getCmsStaticContent(cmsStaticContentCode, forceReload) {
            var filter = {
                CmsStaticContentCode: cmsStaticContentCode
            };
            var defer = $q.defer();

            cmstoolService.getCmsStaticContent(filter, forceReload)
                .then(function (data) {
                    vm.currentCmsStaticContent = data.results[0];
                    parseContentBodyHtml(vm.currentCmsStaticContent);

                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function parseContentBodyHtml(cmsStaticContent) {
            if (cmsStaticContent) {
                cmsStaticContent.ContentBodyHtmlEmbedded = Nembus.Common.Library.converter.decodeHtml(vm.currentCmsStaticContent.ContentBodyHtmlEmbedded);
                cmsStaticContent.entityAspect.setUnchanged();
            }
        }

        parentScope.ribbonDatacontext.disableAllRibbonStatusButtons();
        if (currentCmsStaticContentCode) {
            parentScope.ribbonDatacontext.setRibbonStatusButtonByName('Modify', true);
        } else {
            parentScope.ribbonDatacontext.setRibbonStatusButtonByName('Save', true);
        }
        parentScope.ribbonDatacontext.refreshStatusButtons();

        // get reference of submitaction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parents = vm.submitAction;
        vm.submitAction = function () {
            parents().then(
                function (saveResult) {
                    var ent = Lazy(saveResult.entities)
                        .filter((e) => { return e.entityType.shortName === 'CmsStaticContent'; })
                        .first();

                    if (saveResult.entities.length > 0 && parentScope.viewContext.isAddingState) {
                        if (ent) {
                            navigationService.navigateTo("app.nav.cmsstaticcontentdata.cmsstaticcontentdatadetail", { 'cmsStaticContentCode': ent.CmsStaticContentCode, 'appStoreCode': appStoreCode });
                        }
                    } else {
                        parseContentBodyHtml(ent);
                    }
                }
            );
        };

        //Set action of current controller to use them in parent controller
        navigationService.setParentControllerActions(vm);

    };

    cmsStaticContentDataDetail.$inject = injectParams;
    cmsStaticContentDataDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('cmsStaticContentDataDetail', cmsStaticContentDataDetail);
});