﻿/*
* Aud library - tool function
*/
var Nembus = Nembus || {};
Nembus.Common = Nembus.Common || {};
Nembus.Common.Library = Nembus.Common.Library || {};
Nembus.Common.Library.auth = Nembus.Common.Library.auth || {};
Nembus.Common.Library.auth.Permission = Nembus.Common.Library.auth.Permission || {};


if (!Nembus.Aud) {
    Nembus.Aud = {};
    Nembus.Aud.Library = {};
    Nembus.Aud.Library.HtmlToDocReport = {}; 
    Nembus.Aud.Library.UI = {};
    Nembus.Aud.Library.SSV = {};

    

    Nembus.Common.Library.auth.Permission['COMPILE'] = { value: 16, code: 'AUD_AUDIT_COMPILE' };
    Nembus.Common.Library.auth.Permission['COMPILEASSIGNED'] = { value: 1 << 30, code: 'AUD_AUDIT_COMPILE' };
    Nembus.Common.Library.auth.Permission['AUD_AUDIT_CREATE_ALL'] = { value: 16, code: 'AUD_AUDIT_CREATE' }; // Permission to create audits
    Nembus.Common.Library.auth.Permission['AUD_AUDIT_DELETE_ALL'] = { value: 16, code: 'AUD_AUDIT_DELETE' }; // Permission to delete survey in every state
    Nembus.Common.Library.auth.Permission['AUD_AUDIT_UPDATE_ALL'] = { value: 16, code: 'AUD_AUDIT_UPDATE' }; // Permission to update survey in every state
    Nembus.Common.Library.auth.Permission['AUD_AUDIT_MANAGE_LIFESTATUS_EXTENDED'] = { value: 16, code: 'AUD_AUDIT_MANAGE_LIFESTATUS_EXTENDED' }; // Permission to update survey in every state

    //#region target (action) permissions
    Nembus.Common.Library.auth.Permission['AUDTARGET_READ'] = { value: 2, code: 'AUD_AUDTARGET_TARGET' }; // View Target
    Nembus.Common.Library.auth.Permission['AUDTARGET_MANAGE'] = { value: 13, code: 'AUD_AUDTARGET_TARGET' }; // Add,Update,Delete target
    //#endregion

    //#region target (view) permissions
    Nembus.Common.Library.auth.Permission['AUD_AUDTARGET_TARGET_VIEW_LIST'] = { value: 15, code: 'AUD_AUDTARGET_TARGET_VIEW_LIST' };
    Nembus.Common.Library.auth.Permission['AUD_AUDTARGET_TARGET_VIEW_GENERAL'] = { value: 15, code: 'AUD_AUDTARGET_TARGET_VIEW_GENERAL' };
    Nembus.Common.Library.auth.Permission['AUD_AUDTARGET_TARGET_VIEW_ATTACHMENT'] = { value: 15, code: 'AUD_AUDTARGET_TARGET_VIEW_ATTACHMENT' };
    Nembus.Common.Library.auth.Permission['AUD_AUDTARGET_TARGET_VIEW_CATEGORIES'] = { value: 15, code: 'AUD_AUDTARGET_TARGET_VIEW_CATEGORIES' };
    Nembus.Common.Library.auth.Permission['AUD_AUDTARGET_TARGET_VIEW_AUDCOMPILED'] = { value: 15, code: 'AUD_AUDTARGET_TARGET_VIEW_AUDCOMPILED' };
    Nembus.Common.Library.auth.Permission['AUD_AUDTARGET_TARGET_VIEW_AUDTOCOMPILE'] = { value: 15, code: 'AUD_AUDTARGET_TARGET_VIEW_AUDTOCOMPILE' };
    Nembus.Common.Library.auth.Permission['AUD_AUDTARGET_TARGET_VIEW_AUDONDEMAND'] = { value: 15, code: 'AUD_AUDTARGET_TARGET_VIEW_AUDONDEMAND' };
    Nembus.Common.Library.auth.Permission['AUD_AUDTARGET_TARGET_VIEW_MY_AUDCOMPILED'] = { value: 15, code: 'AUD_AUDTARGET_TARGET_VIEW_MY_AUDCOMPILED' };
    Nembus.Common.Library.auth.Permission['AUD_AUDTARGET_TARGET_VIEW_MY_AUDTOCOMPILE'] = { value: 15, code: 'AUD_AUDTARGET_TARGET_VIEW_MY_AUDTOCOMPILE' };
    Nembus.Common.Library.auth.Permission['AUD_AUDTARGET_TARGET_VIEW_MY_AUDONDEMAND'] = { value: 15, code: 'AUD_AUDTARGET_TARGET_VIEW_MY_AUDONDEMAND' };
    // view tab

    //#region AudAct permissions
    // QuestionOptions
    Nembus.Common.Library.auth.Permission['UPDAUDACTQUESTIONOPTIONPRE'] = { value: 32, code: 'AUD_ACT_ACTION_UPDQUESTIONOPTION_PRE' }; // Update action with acttypeid == predefined
    Nembus.Common.Library.auth.Permission['UPDAUDACTQUESTIONOPTIONPOST'] = { value: 64, code: 'AUD_ACT_ACTION_UPDQUESTIONOPTION_POST' }; // Update action with acttypeid == postdefined
    //#endregion

    //#region Workflow
    Nembus.Common.Library.auth.Permission['AUD_AUDIT_WORKFLOW_CHANGESTATUS'] = { value: 16, code: 'AUD_AUDIT_WORKFLOW' }; // Update action with acttypeid == postdefined
    //#endregion

    Nembus.Common.Library.auth.checkAuthCompile = function (virtualContext, moduleTargetId, typeId, groupId) {
        return Nembus.Common.Library.auth.checkProcessPermission(Nembus.Common.Library.auth, virtualContext, 'COMPILE', moduleTargetId, typeId, groupId);
    };

    Nembus.Common.Library.auth.checkAuthCompileAssigned = function (virtualContext, moduleTargetId, typeId, groupId) {
        return Nembus.Common.Library.auth.checkProcessPermission(Nembus.Common.Library.auth, virtualContext, 'COMPILEASSIGNED', moduleTargetId, typeId, groupId);
    };

    Nembus.Aud.Library.SSV.IsAuditSchedulingScenarioDetailed = function () {
        var ssv_auditSchedulingScenario = Nembus.Common.Library.server.getServerVar('ssv_audit_survey_scheduling_scenario');
        return ssv_auditSchedulingScenario === 'scheduling-detailed';
    }

    Nembus.Aud.Library.SSV.CanManageSurveyVhrEventLifeStatus = function () {
        return Nembus.Common.Library.server.getServerVarBool('ssv_audit_survey_lifestatus_enable_extended');
    }

    Nembus.Aud.Library.SSV.CanManageSurveyWorkflow = function () {
        return Nembus.Common.Library.server.getServerVarBool('ssv_audit_survey_workflow_manage');
    }

    Nembus.Aud.Library.CanCompileSurvey = function (contextService, audService, survey) {
        if (!survey) {
            return false;
        }

        return Nembus.Common.Library.auth.checkAuthCompile(contextService.virtualContext, survey.ModuleTargetId, survey.VhrEventTypeId, survey.VhrEventGroupId)
            && (Lazy(Nembus.Aud.Library.GetSurveyLifeStatusListIds(audService, false)).contains(survey.SurveyLifeStatusId));
    }

    Nembus.Aud.Library.CanViewSurvey = function (survey, audService) {
        if (!survey) {
            return false;
        }

        return Lazy(Nembus.Aud.Library.GetSurveyLifeStatusListIds(audService, true)).contains(survey.SurveyLifeStatusId);
    }

    Nembus.Aud.Library.GetChangesSurveyWeightsAndValues = function (survey, surveyQuestions, surveyQuestionOptions, surveyInputTypeObj, values, weights) {
        if (!survey.IsOptionsWeightEnabled && !survey.IsOptionsValueEnabled) {
            return;
        }

        var changes = [];
        var minValue = Lazy(values).pluck('value').min();
        var minWeight = Lazy(weights).pluck('value').min();

        Lazy(surveyQuestions)
            .each((question) => {
                if (question.SurveyInputTypeId !== surveyInputTypeObj.SurveyFreeText) {
                    var currOptions = Lazy(surveyQuestionOptions)
                        .where({ SurveyQuestionId: question.SurveyQuestionId })
                        .toArray();

                    Lazy(currOptions)
                        .each((option) => {
                            if (survey.IsOptionsWeightEnabled) {
                                if (option.OptionWeight === null
                                    || option.OptionWeight === undefined
                                    || option.OptionWeight === 0
                                    || !Lazy(weights).pluck('value').contains(option.OptionWeight)) {

                                    option.OptionWeight = minWeight;
                                    changes.push({ questionName: question.SurveyQuestionName, optionName: option.SurveyQuestionOptionName });
                                }
                            }
                            if (survey.IsOptionsValueEnabled) {
                                if (option.OptionValue === null
                                    || option.OptionValue === undefined
                                    || option.OptionValue === 0
                                    || !Lazy(values).pluck('value').contains(option.OptionValue)) {

                                    option.OptionValue = minValue;
                                    changes.push({ questionName: question.SurveyQuestionName, optionName: option.SurveyQuestionOptionName });
                                }
                            }
                        });
                }
            });

        return changes;
    }

    Nembus.Aud.Library.GetSurveyLifeStatusListIds = function (audService, completed) {
        var surveyLifeStatusEnum = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyLifeStatus, null, null).obj.normal.name_value;

        if (completed === true) {
            return [
                surveyLifeStatusEnum.ReportedAsFinished,
                surveyLifeStatusEnum.FinishedAndChecked,
                surveyLifeStatusEnum.Cancelled,
                surveyLifeStatusEnum.Approved
            ];
        } else {
            return [
                surveyLifeStatusEnum.Created,
                surveyLifeStatusEnum.Running
            ];
        }
    }

    Nembus.Aud.Library.HtmlToDocReport.SurveyQuestionOptiondistributionDatasource = function (locale, surveys, sections, questions, questionOptions, importantOptions, groupKey, sortBy, sortMode) {
        var result = {
            paretoChartPoints: {
                linePoints: [],
                columnPoints: []
            },
            chartPoints: [],
            tableDatas: []
        };

        var resultTable = [];

        // set properties to group surveys based on SurveyCompileDateSystem
        Lazy(surveys).each((survey) => {
            switch (groupKey) {
                case 'week':
                    survey.groupKey = moment(survey.SurveyCompileDateSystem).week();
                    survey.sortKey = survey.groupKey;
                    break;
                case 'month':
                    survey.groupKey = locale.DATETIME_FORMATS.SHORTMONTH[moment(survey.SurveyCompileDateSystem).month()];
                    survey.sortKey = moment(survey.SurveyCompileDateSystem).month();
                    break;
                case 'year':
                    survey.groupKey = moment(survey.SurveyCompileDateSystem).year();
                    survey.sortKey = survey.groupKey;
                    break;
            }
        });

        // create a group sorted of surveys
        var surveysPeriodGrouped = Lazy(surveys)
            .sortBy((survey) => { return survey.sortKey; }, false)
            .groupBy('groupKey')
            .toArray();

        Lazy(surveysPeriodGrouped)
            .each((surveysPeriodGroup) => {
                // surveysPeriodGroup[0] => key (week/month/year)
                // surveysPeriodGroup[1] => surveys
                var currentPeriodKey = surveysPeriodGroup[0];

                var periodSurveys = surveysPeriodGroup[1];
                var periodSurveyIds = Lazy(periodSurveys).pluck('SurveyId').toArray();

                var periodSections = Lazy(sections).filter(item => { return Lazy(periodSurveyIds).contains(item.SurveyId); }).toArray();
                var periodSectionIds = Lazy(periodSections).pluck('SurveySectionId').toArray();

                var periodQuestions = Lazy(questions).filter(item => { return Lazy(periodSectionIds).contains(item.SurveySectionId); }).toArray();
                var periodQuestionds = Lazy(periodQuestions).pluck('SurveyQuestionId').toArray();

                var periodQuestionOptions = Lazy(questionOptions).filter(item => { return Lazy(periodQuestionds).contains(item.SurveyQuestionId); }).toArray();

                var periodGroupTableData = pGetPeriodTableData(currentPeriodKey, periodSurveys, periodSections, periodQuestions, periodQuestionOptions, importantOptions, sortBy, sortMode);

                var chartPoint = { x: currentPeriodKey.toString(), y: Lazy(periodGroupTableData).pluck('CheckedCount').sum() };
                result.chartPoints.push(chartPoint);
                resultTable = Lazy(resultTable).union(periodGroupTableData).toArray();
            });

        var index = 0;
        Lazy(resultTable)
            .each((item) => {
                index++;
                item.RecordId = index.toString();

                result.paretoChartPoints.columnPoints.push({ x: item.RecordId, y: item.CheckedCount });
            });


        // descending
        result.paretoChartPoints.columnPoints = Lazy(result.paretoChartPoints.columnPoints).sortBy((point) => { return point.y; }, true).toArray();

        // ascending
        var paretoLineValue = 0;
        Lazy(result.paretoChartPoints.columnPoints)
            .each((columnPoint) => {
                var paretoLinePoint = { x: null, y: null };
                paretoLineValue += columnPoint.y;

                paretoLinePoint.x = columnPoint.x;
                paretoLinePoint.y = paretoLineValue;

                result.paretoChartPoints.linePoints.push(paretoLinePoint);
            });


        result.tableDatas = resultTable;

        function pGetPeriodTableData(periodKey, cPeriodSurveys, cPeriodSections, cPeriodQuestions, cPeriodQuestionOptions, importantOptions, sortBy, sortMode) {
            var result = [];
            var items = [];

            Lazy(cPeriodSurveys)
                .each((survey) => {
                    var cSurveySections = Lazy(cPeriodSections).where({ SurveyId: survey.SurveyId }).toArray();
                    var cSurveySectionIds = Lazy(cSurveySections).pluck('SurveySectionId').toArray();
                    var cSurveyQuestions = Lazy(cPeriodQuestions).filter(item => { return Lazy(cSurveySectionIds).contains(item.SurveySectionId); }).toArray();
                    var cSurveyQuestiondIds = Lazy(cSurveyQuestions).pluck('SurveyQuestionId').toArray();
                    var cSurveyQuestionOptions = Lazy(cPeriodQuestionOptions).filter(item => { return Lazy(cSurveyQuestiondIds).contains(item.SurveyQuestionId); }).toArray();

                    // survey with related sections, questions, options
                    survey.nbsdata = { sections: cSurveySections, questions: cSurveyQuestions, options: cSurveyQuestionOptions };
                });

            Lazy(cPeriodSurveys)
                .each((survey) => {
                    Lazy(survey.nbsdata.questions)
                        .each((question) => {
                            Lazy(survey.nbsdata.options)
                                .where({ SurveyQuestionId: question.SurveyQuestionId })
                                .each((option) => {
                                    var cSurveyQuestionName = question.SurveyQuestionName.trim();
                                    var cSurveyQuestionOptionName = option.SurveyQuestionOptionName.trim();
                                    var cGroupingCode = cSurveyQuestionName + ' - ' + cSurveyQuestionOptionName;

                                    items.push({
                                        SurveyId: survey.SurveyId,
                                        SurveyQuestionId: question.SurveyQuestionId,
                                        SurveyQuestionName: cSurveyQuestionName,
                                        SurveyQuestionOptionId: option.SurveyQuestionOptionId,
                                        SurveyQuestionOptionName: cSurveyQuestionOptionName,
                                        SurveyQuestionOrder: question.SurveyQuestionOrder,

                                        IsImportant: option.IsImportant,
                                        IsChecked: option.IsChecked,

                                        // grouping code question + option
                                        SurveyQuestionOptionGroupingCode: cGroupingCode,

                                        SurveyCountInPeriod: Lazy(cPeriodSurveys).toArray().length,
                                        SurveyQuestionCountInPeriod: Lazy(cPeriodQuestions).where({ SurveyQuestionName: cSurveyQuestionName }).toArray().length
                                    });
                                });
                        });
                });

            var questionOptionGrouped = Lazy(items).groupBy('SurveyQuestionOptionGroupingCode');
            Lazy(questionOptionGrouped)
                .toArray()
                .map((group) => {
                    var key = group[0];
                    var data = group[1];
                    var firstRecInGroup = Lazy(group[1]).first();

                    var surveyQuestionCountInPeriod = firstRecInGroup.SurveyQuestionCountInPeriod;
                    var questionName = firstRecInGroup.SurveyQuestionName;
                    var optionName = firstRecInGroup.SurveyQuestionOptionName;
                    var optionNumber = firstRecInGroup.SurveyQuestionOrder;

                    var checkedCount = 0;
                    var totalCount = 0;

                    if (importantOptions) {
                        checkedCount = Lazy(data).where({ IsChecked: true, IsImportant: true }).toArray().length;
                        totalCount = surveyQuestionCountInPeriod;
                    } else {
                        checkedCount = Lazy(data).where({ IsChecked: true }).toArray().length;
                        totalCount = surveyQuestionCountInPeriod;
                    }

                    var checkedPercent = 0;
                    if (totalCount > 0) {
                        checkedPercent = checkedCount / totalCount * 100;
                    }

                    checkedPercent = Number(checkedPercent.toFixed(0));

                    if (checkedCount > 0) {
                        result.push({
                            groupKey: key,
                            periodKey: periodKey,
                            SurveyQuestionOrder: optionNumber,
                            SurveyQuestionName: questionName,
                            SurveyQuestionOptionName: optionName,
                            CheckedCount: Lazy(data).where({ IsChecked: true }).toArray().length,
                            TotalCount: totalCount,
                            CheckedPercent: checkedPercent
                        });
                    }
                });

            if (sortBy) {
                switch (sortBy) {
                    case 'questionnumber':
                        result = Lazy(result).sortBy((item) => { return item.SurveyQuestionOrder; }, sortMode).toArray();
                        break;
                    case 'question':
                        result = Lazy(result).sortBy((item) => { return item.SurveyQuestionName; }, sortMode).toArray();
                        break;
                    case 'questionoption':
                        result = Lazy(result).sortBy((item) => { return item.SurveyQuestionOptionName; }, sortMode).toArray();
                        break;
                    case 'value':
                        result = Lazy(result).sortBy((item) => { return item.CheckedPercent; }, sortMode).toArray();
                        break;
                }
            }

            return result;
        }

        return result;
    };

    Nembus.Aud.Library.GetNewSurveyQuestionOption = function (surveyQuestionId) {
        if (!surveyQuestionId) {
            throw new Error('GetNewSurveyQuestionOption: surveyQuestionId is mandatory');
        }

        return {
            SurveyQuestionOptionOrder: 1,
            SurveyQuestionId: surveyQuestionId,
            OptionWeight: 0,
            OptionValue: 0,
            OptionAggravating: 0
        };
    };

    Nembus.Aud.Library.SurveyActionTemplateDs = function (translator, havePermissionFn, contextService) {
        var self = this;

        var pActions = [];
        var pFnFilter = null;
        var _surveyId = null;
        var _surveyQuestionId = null;
        var _priorities = null;
        var _priorityObj = null;
        var _dateIntervalObj = null;
        var _surveyActionTypeObj = null;
        var _actTypeEnumObj = null;
        var _appStoreBw = null;
        var _virtualCompanyId = null;
        var _categories = null;
        var _workflowStatuses = null;
        var _actActionGroups = null;
        var _actActionGroupDefaultId = null;
        var _actActionTypes = null;
        var _actActionTypeDefaultId = null;
        var _questionOptions = null;
        var _questionOptionsCheckedIds = null;
        var _drbacUserGroups = null;
        var _appUsers = null;
        var _actService = null;
        var _audService = null;
        var _utilService = null;
        var _navigationService = null;
        var _workflowService = null;
        var _workflowStatusKindObj = null;
        var _propertyKey = 'GroupCode';
        var _contextService = contextService;
        var _translator = translator;
        var _havePermissionFn = havePermissionFn;
        var _actPermissionManager = null;
        var _actPriorityEnumParsed = null;
        var _validationSchema = null;

        self.actions = [];                                          // surveyActions used to bind to UxHelper
        self.actionTypeId = null;                                   // binded in UxHelper to view 'Number of days' or 'Expire date'
        self.actService = null;
        self.ssv = {
            joinActionToQuestionOption: Nembus.Common.Library.server.getServerVarBool('ssv_audit_surveyquestionoption_act_selection_rule'),
            manageWorkflowInAction: Nembus.Common.Library.server.getServerVarBool('ssv_audit_workkflow_act_selection_rule')
        };

        self.actFireIsOptionableVisible = false;

        self.canAddAction = _havePermissionFn(Nembus.Common.Library.auth.Permission['ADDACT'], _contextService.virtualContext);

        self.disabled = false;

        self.useAddPermissionToModifyPostDefined = false;
        self.assignmentControlBypassed = false;
        self.isCompilingMode = false;
        self.currentQuestion = null;    // used at moment in compiling to associate actions to modal question

        self.setCurrentQuestion = function (question) {
            self.currentQuestion = question;
        };

        self.setDisabled = function () {
            self.disabled = true;
        };

        self.useAddPermissionForPostDefined = function () {
            self.useAddPermissionToModifyPostDefined = true;

            _actPermissionManager.useAddPermissionForPostDefined();
        };

        self.byPassAssignmentControl = function () {
            self.assignmentControlBypassed = true;

            _actPermissionManager.byPassAssignmentControl();
        };

        // on adding action, action will be inserted as PreDefined type
        self.setActionPreDefined = function () {
            self.actionTypeId = _actTypeEnumObj.PreDefined;
        };

        // on adding action, action will be inserted as PostDefined type
        self.setActionPostDefined = function () {
            self.actionTypeId = _actTypeEnumObj.PostDefined;
        };

        self.setIsCompilingMode = function () {
            self.isCompilingMode = true;
        };

        self.refreshIsActionFiredIcon = function (action) {
            setActionIsFired(action);
        };

        self.init = function (params, datasources) {
            setParams(params);
            setActionDatasources(datasources);
        };

        function setParams(params) {
            if (!params.surveyId) { throw new Error('surveyId is mandatory'); }
            if (!params.appStoreBw || params.appStoreBw === 0) { throw new Error('appStoreBw is mandatory'); }
            if (!params.virtualCompanyId || params.virtualCompanyId === 0) { throw new Error('virtualCompanyId is mandatory'); }
            if (!params.actService) { throw new Error('actService is mandatory'); }
            if (!params.audService) { throw new Error('audService is mandatory'); }
            if (!params.utilService) { throw new Error('utilService is mandatory'); }
            if (!params.navigationService) { throw new Error('navigationService is mandatory'); }
            if (!params.workflowService) { throw new Error('workflowService is mandatory'); }

            _actService = params.actService;
            _audService = params.audService;
            _utilService = params.utilService;
            _navigationService = params.navigationService;
            _workflowService = params.workflowService;

            _virtualCompanyId = params.virtualCompanyId;
            _appStoreBw = params.appStoreBw;
            _surveyId = params.surveyId;
            _surveyQuestionId = params.surveyQuestionId;

            _actPriorityEnumParsed = Nembus.Common.Library.util.parseEnum(_actService.dataContext.enumTypes.ActPriority, 'Act', _translator);
            _actTypeEnumObj = Nembus.Common.Library.util.parseEnum(_actService.dataContext.enumTypes.ActType, null, _translator).obj.normal.name_value;
            _priorities = _actPriorityEnumParsed.list.translated;
            _priorityObj = _actPriorityEnumParsed.obj.normal.name_value;
            _dateIntervalObj = Nembus.Common.Library.util.parseEnum(_utilService.dataContext.enumTypes.DateInterval_dateMode, null, _translator).obj.normal.name_value;
            _surveyActionTypeObj = Nembus.Common.Library.util.parseEnum(_audService.dataContext.enumTypes.SurveyActionType, null, _translator).obj.normal.name_value;
            _workflowStatusKindObj = Nembus.Common.Library.util.parseEnum(_workflowService.dataContext.enumTypes.WorkflowStatusKind, null, _translator).obj.normal.name_value;
        }

        function setActionDatasources(datasources) {
            // datasources
            if (!datasources.categories) { datasources.categories = []; }
            if (!datasources.questionOptions) { datasources.questionOptions = []; }
            if (!datasources.drbacUserGroups) { datasources.drbacUserGroups = []; }
            if (!datasources.appUsers) { datasources.appUsers = []; }
            if (!datasources.workflowStatuses) { datasources.workflowStatuses = []; }
            if (!datasources.actActionGroups) { datasources.actActionGroups = []; }
            if (!datasources.actActionTypes) { datasources.actActionTypes = []; }
            if (!datasources.validationSchema) { datasources.validationSchema = []; }

            _categories = datasources.categories;
            _questionOptions = datasources.questionOptions;
            _questionOptionsCheckedIds = Lazy(_questionOptions).where({ IsChecked: true }).pluck('SurveyQuestionOptionId').toArray();
            _drbacUserGroups = datasources.drbacUserGroups;
            _appUsers = datasources.appUsers;
            _workflowStatuses = datasources.workflowStatuses;
            _actActionGroups = datasources.actActionGroups;
            _actActionTypes = datasources.actActionTypes;
            _actActionGroupDefaultId = Lazy(datasources.actActionGroups).where({ IsSystem: true }).first().ActActionGroupId;
            _actActionTypeDefaultId = Lazy(datasources.actActionTypes).where({ IsSystem: true }).first().ActActionTypeId;

            _validationSchema = datasources.validationSchema;
            _audService.customizeSurveyActionDtoValidation(_validationSchema, self.ssv.joinActionToQuestionOption);

            _actPermissionManager = new NbsActPermissionManager({
                translator: _translator,
                virtualContext: _contextService.virtualContext,
                actServiceEnumTypes: _actService.dataContext.enumTypes,
                havePermissionFn: _havePermissionFn,
                validationSchema: _validationSchema,
                permissionObj: Nembus.Common.Library.auth.Permission,
                parseEnumFn: Nembus.Common.Library.util.parseEnum
            });
        }

        self.isAddingActionToSurvey = false;
        self.setIsAddingActionToSurvey = function (value) {
            if (!value) {
                value = false;
            } else {
                value = true;
            }

            self.isAddingActionToSurvey = value;
        };

        self.setActionDsFnFilter = function (actionDsFnFilter) {
            pFnFilter = actionDsFnFilter;
        };

        // Set actions datasource using actions passed like parameter
        // 
        self.setActions = function (actions) {
            pActions = [];

            Lazy(actions)
                .each((action) => {
                    var filterKey = {};
                    filterKey[_propertyKey] = action[_propertyKey];

                    setActionData(action);

                    if (!Lazy(pActions).where(filterKey).some()) {
                        pActions.push(action);
                    }
                });
        };

        self.refreshActionQuestionOptionSerialized = function (questionOptions) {
            var newQuestionOptionsIds = Lazy(questionOptions).pluck('SurveyQuestionOptionId').toArray();
            var deletedQuestionOptionIds = [];
            Lazy(_questionOptions)
                .each((cQuestionOption) => {
                    if (!Lazy(newQuestionOptionsIds).contains(cQuestionOption.SurveyQuestionOptionId)) {
                        deletedQuestionOptionIds.push(cQuestionOption.SurveyQuestionOptionId);
                    }
                });

            _questionOptions = questionOptions;

            Lazy(self.actions)
                .each((action) => {
                    var cActionOptionIds = JSON.parse(action.SurveyQuestionOptionsIdSerialized);
                    Lazy(deletedQuestionOptionIds)
                        .each((deletedQuestionOptionId) => {
                            cActionOptionIds = Lazy(cActionOptionIds).filter((cActionOptionId) => { return cActionOptionId !== deletedQuestionOptionId; }).toArray();
                        });

                    action.SurveyQuestionOptionsIdSerialized = JSON.stringify(cActionOptionIds);

                    setQuestionOptionDataToAction(action);
                });
        };

        // prepare actions property binded to UxHelper
        self.refreshActionDs = function () {
            if (pFnFilter !== null) {
                self.actions = Lazy(pActions).filter(pFnFilter).toArray();
            } else {
                self.actions = pActions;
            }
        };

        self.addAction = function () {
            if (!self.canAddAction) {
                _navigationService.userNotAuthorizedMessageModal();
                return;
            }

            if (!self.actionTypeId) { throw new Error('actionTypeId is mandatory'); }

            var action = null;
            if (self.isAddingActionToSurvey) {
                action = _audService.dataContext.surveyActionDtos.add({
                    GroupCode: NbsGuidManager.newGuid(),
                    SurveyActionTypeId: _surveyActionTypeObj.Survey,
                    ActActionTypeId: _actActionTypeDefaultId,
                    ActActionGroupId: _actActionGroupDefaultId
                });
            } else {
                if (!_surveyQuestionId) { throw new Error('surveyQuestionId is mandatory'); }

                if (self.ssv.joinActionToQuestionOption) {
                    action = _audService.dataContext.surveyActionDtos.add({
                        GroupCode: NbsGuidManager.newGuid(),
                        SurveyActionTypeId: _surveyActionTypeObj.SurveyQuestionOption,
                        SurveyQuestionId: _surveyQuestionId,
                        SurveyQuestionOptionsIdSerialized: JSON.stringify(_questionOptionsCheckedIds),   // default checked options
                        ActActionTypeId: _actActionTypeDefaultId,
                        ActActionGroupId: _actActionGroupDefaultId
                    });
                }
                else {
                    action = _audService.dataContext.surveyActionDtos.add({
                        GroupCode: NbsGuidManager.newGuid(),
                        SurveyActionTypeId: _surveyActionTypeObj.SurveyQuestion,
                        SurveyQuestionId: _surveyQuestionId,
                        ActActionTypeId: _actActionTypeDefaultId,
                        ActActionGroupId: _actActionGroupDefaultId
                    });
                }
            }

            // common data to all SurveyActionTypes        
            action.ActPriorityId = Nembus.Rds.Library.GetActPriorityIdByServerVariable(_priorityObj);
            action.ActCause = '-';  // ActCause is filled server side ('-' only to pass validation)
            action.ActExpireDateUnitId = _dateIntervalObj.Day;
            action.SurveyId = _surveyId;
            action.AppStoreBw = _appStoreBw;
            action.ActTypeId = self.actionTypeId;
            action.VirtualCompanyId = _virtualCompanyId;

            // default workflow only on add action 
            var wkfToExecute = Lazy(_workflowStatuses).where({ WorkflowStatusKindId: _workflowStatusKindObj.ToExecute }).first();
            if (wkfToExecute) {
                action.ActWorkflowStatusId = wkfToExecute.WorkflowStatusId;
            }


            setActionData(action);

            pActions.push(action);
            self.refreshActionDs();
        };

        self.deleteAction = function (action) {
            if (!action.permissions.canDeleteAct) {
                _navigationService.userNotAuthorizedMessageModal();
                return;
            }

            if (action) {
                _navigationService.confirmModal(_translator('DeleteAct'))
                    .then(function (result) {
                        if (result) {
                            // remove from DB
                            action.entityAspect.setDeleted();
                            self.refreshActionDs();
                        }
                    });
            }
        };

        function setActionData(action) {
            action.supportUI = {};

            // drop down workflow statuses
            var currentActionWorkflowDs = _workflowStatuses;
            // add eventually selected workflow in action (maybe not in active workflow statuses)
            if (action.ActWorkflowStatusId) {
                if (!Lazy(currentActionWorkflowDs).where({ WorkflowStatusId: action.ActWorkflowStatusId }).some()) {
                    currentActionWorkflowDs.push({
                        WorkflowStatusId: action.ActWorkflowStatusId,
                        WorkflowStatusName: action.ActWorkflowStatusName
                    });
                }
            }

            // set enable/disable fields on every action based on acttype (pre, post, manually, ..)
            action.permissions = _actPermissionManager.getPermissionByAct(action);

            if (self.disabled) {
                action.permissions.canModifyActActionGroup = false;
                action.permissions.canModifyActActionType = false;
                action.permissions.canModifyActDescription = false;
                action.permissions.canModifyActExpireDate = false;
                action.permissions.canModifyActPriority = false;
                action.permissions.canModifyActCategory = false;
                action.permissions.canModifyActAssignmentUserGroups = false;
                action.permissions.canModifyActAssignmentUsers = false;
            }

            action.supportUI.prioritiesDs = _priorities;
            action.supportUI.actActionGroupsDs = _actActionGroups;
            action.supportUI.actActionTypesDs = _actActionTypes;
            action.supportUI.workflowStatusesDs = currentActionWorkflowDs;

            // section used to manage selection of usergroups and appuser (multiselection)
            action.supportUI.nbsDrbacAuthSelector = new NbsAssigneeSelector(_translator);
            action.supportUI.nbsDrbacAuthSelector.viewUserGroups = action.permissions.canViewActAssignmentUserGroups;
            action.supportUI.nbsDrbacAuthSelector.viewUsers = action.permissions.canViewActAssignmentUsers;
            action.supportUI.nbsDrbacAuthSelector.userGroupDisabled = !action.permissions.canModifyActAssignmentUserGroups;
            action.supportUI.nbsDrbacAuthSelector.userDisabled = !action.permissions.canModifyActAssignmentUsers;

            action.supportUI.nbsDrbacAuthSelector.setLabelsOnTop();

            // usergroups        
            action.supportUI.nbsDrbacAuthSelector.drbacUserGroupMultiselect.addData(_drbacUserGroups, 'DrbacUserGroupId', 'DrbacUserGroupName');
            action.supportUI.nbsDrbacAuthSelector.setDrbacUserGroupsSelected(JSON.parse(action.DrbacUserGroupIdSerialized));
            action.supportUI.nbsDrbacAuthSelector.drbacUserGroupMultiselect.setOnchangeFnCallback(function () {
                action.DrbacUserGroupIdSerialized = action.supportUI.nbsDrbacAuthSelector.drbacUserGroupMultiselect
                    ? JSON.stringify(action.supportUI.nbsDrbacAuthSelector.drbacUserGroupMultiselect.selectedIds)
                    : '[]';
            });

            // appuser
            action.supportUI.nbsDrbacAuthSelector.appUserMultiselect.addData(_appUsers, 'AppUserId', 'DisplayNameUI');
            action.supportUI.nbsDrbacAuthSelector.setAppUsersSelected(JSON.parse(action.AppUserIdSerialized));
            action.supportUI.nbsDrbacAuthSelector.appUserMultiselect.setOnchangeFnCallback(function () {
                action.AppUserIdSerialized = action.supportUI.nbsDrbacAuthSelector.appUserMultiselect
                    ? JSON.stringify(action.supportUI.nbsDrbacAuthSelector.appUserMultiselect.selectedIds)
                    : '[]';
            });

            // multiselection categories
            action.supportUI.categoriesMultiselectDs = new NbsMultiselect(_translator);
            action.supportUI.categoriesMultiselectDs.addData(Nembus.Common.Library.util.parseCategoryDtoForMultiSelectNew(_categories));
            action.supportUI.categoriesMultiselectDs.setSelected(JSON.parse(action.CategoriesIdsSerialized));
            action.supportUI.categoriesMultiselectDs.setOnchangeFnCallback(function () {
                action.CategoriesIdsSerialized = action.supportUI.categoriesMultiselectDs
                    ? JSON.stringify(action.supportUI.categoriesMultiselectDs.selectedIds)
                    : '[]';
            });

            // multiselection question options 
            setQuestionOptionDataToAction(action);
        }

        function setActionIsFired(action) {

            if (action.SurveyActionTypeId === _surveyActionTypeObj.SurveyQuestionOption) {
                action.supportUI.nbsIsActionFiredOnCompile = false;

                var cActionOptionIds = JSON.parse(action.SurveyQuestionOptionsIdSerialized);
                Lazy(cActionOptionIds)
                    .each((id) => {
                        if (Lazy(_questionOptionsCheckedIds).contains(id)) {
                            action.supportUI.nbsIsActionFiredOnCompile = true;
                        } else {
                            // SurveyFreeText
                            if (self.currentQuestion && self.currentQuestion.SurveyInputTypeId === 3) {
                                var questionOption = Lazy(self.currentQuestion.questionOptions).first();
                                if (!questionOption.SurveyQuestionOptionFreeText) {
                                    action.supportUI.nbsIsActionFiredOnCompile = false;
                                } else {
                                    action.supportUI.nbsIsActionFiredOnCompile = true;
                                }
                            }
                        }
                    });
            } else {
                action.supportUI.nbsIsActionFiredOnCompile = true;
            }

            if (action.ActFireIsOptional && !action.ActIsFiredOptionally) {
                action.supportUI.nbsIsActionFiredOnCompile = false;
            }
        }

        function setQuestionOptionDataToAction(action) {
            setActionIsFired(action);

            action.supportUI.questionOptionsMultiselectDs = new NbsMultiselect(_translator);
            action.supportUI.questionOptionsMultiselectDs.addData(_questionOptions, 'SurveyQuestionOptionId', 'SurveyQuestionOptionName', 'SurveyQuestionOptionOrder');
            action.supportUI.questionOptionsMultiselectDs.setSelected(JSON.parse(action.SurveyQuestionOptionsIdSerialized));
            action.supportUI.questionOptionsMultiselectDs.setOnchangeFnCallback(function () {
                action.SurveyQuestionOptionsIdSerialized = action.supportUI.questionOptionsMultiselectDs
                    ? JSON.stringify(action.supportUI.questionOptionsMultiselectDs.selectedIds)
                    : '[]';

                setActionIsFired(action);
            });
        }
    };

    Nembus.Aud.Library.ValidateSurveySections = function (sections) {
        var validationResult = new NbsAudValidationResult();

        Lazy(sections)
            .each((section) => {
                section.entityAspect.validateEntity();
                if (section.entityAspect.hasValidationErrors) {
                    validationResult.setValidationError('NbsEntitiesNotValidated');
                }
            });

        return validationResult;
    };

    // function called to validate SurveyQuestion (add or update)
    Nembus.Aud.Library.ValidateSurveyQuestion = function (question, questionOptions, actionCardDatasource, isEditMode, supportData) {
        var validationResult = new NbsAudValidationResult();

        question.entityAspect.validateEntity();
        if (question.entityAspect.hasValidationErrors) {
            validationResult.setValidationError('NbsQuestionNotValidated');
        }

        if (validationResult.hasError) {
            return validationResult;
        }

        Lazy(questionOptions)
            .each((questionOption) => {
                questionOption.entityAspect.validateEntity();
                if (questionOption.entityAspect.hasValidationErrors) {
                    validationResult.setValidationError('NbsQuestionOptionNotValidated');
                }
            });

        if (validationResult.hasError) {
            return validationResult;
        }

        Lazy(actionCardDatasource.actions)
            .each((action) => {
                action.entityAspect.validateEntity();
                if (action.entityAspect.hasValidationErrors) {
                    validationResult.setValidationError('NbsActNotValidated');
                }
            });

        if (validationResult.hasError) {
            return validationResult;
        }

        // starts for custom validations
        validationResult = new NbsAudValidationResult();

        if (supportData) {
            var surveyOptionChoiceGroupSelected = supportData.surveyOptionChoiceGroupSelected;
            if (!surveyOptionChoiceGroupSelected) {
                surveyOptionChoiceGroupSelected = {};
                surveyOptionChoiceGroupSelected.SurveyOptionChoiceGroupId = 0;
            }
        }


        if (!isEditMode) {
            if (!surveyOptionChoiceGroupSelected) {
                validationResult.setCustomError('CreateNewAnswerOrSelectDefault');
            } else {
                if (supportData) {
                    if (surveyOptionChoiceGroupSelected.SurveyOptionChoiceGroupId === 0) {
                        // if freetext surveyquestionoption then there aren't options
                        if (question.SurveyInputTypeId !== supportData.surveyInputTypeObj.SurveyFreeText) {
                            if (questionOptions.length === 0) {
                                validationResult.setCustomError('AtLeastOneAnswerIsRequired');
                            }
                        }
                    }
                }
            }
        }

        if (supportData) {
            if (question.SurveyInputTypeId !== supportData.surveyInputTypeObj.SurveyFreeText
                && surveyOptionChoiceGroupSelected.SurveyOptionChoiceGroupId === 0) {

                if (questionOptions.length === 0) {
                    validationResult.setCustomError('AtLeastOneAnswerIsRequired');
                }
            }
        }

        return validationResult;
    };

    Nembus.Aud.Library.UI.GetSurveyExpireDateParsedString = function (survey, virtualContext, translator) {
        var surveyCreationPattern = {
            Single: 1,
            Recurrent: 2,
            OnDemand: 3,
            InstanceOnDemand: 4
        };

        if (survey.SurveyCreationPatternId === surveyCreationPattern.InstanceOnDemand || survey.SurveyCreationPatternId === surveyCreationPattern.OnDemand) {
            return translator ? translator('OnCall') : 'OnCall';
        } else {
            if (Nembus.Aud.Library.SSV.IsAuditSchedulingScenarioDetailed()) {
                return Nembus.Common.Library.converter.date.formatDateTime(survey.SurveyExpireDate, virtualContext.cultureInfo, true, "DD/MM/YYYY HH:mm");
            } else {
                return Nembus.Common.Library.converter.date.formatDate(survey.SurveyExpireDate, virtualContext.cultureInfo, true, "DD/MM/YYYY HH:mm");
            }
        }
    }
}

//#region classes
class NbsAudValidationResult {
    constructor() {
        this.errorTypes = {
            None: 'None',
            Validation: 'Validation',
            Custom: 'Custom'
        };

        // defaults
        this.errorType = this.errorTypes.None;
        this.hasError = false;
        this.errorMessageKey = null;
    }

    setValidationError(messageKey) {
        this.errorType = this.errorTypes.Validation;
        this.hasError = true;
        this.errorMessageKey = messageKey;
    };

    setCustomError(messageKey) {
        this.errorType = this.errorTypes.Custom;
        this.hasError = true;
        this.errorMessageKey = messageKey;
    };
}

class NbsAudGrid extends NbsGrid {
    constructor(translator) {
        super(translator);

        // append properties to BASE field object templates
        var tempTemplateObject = {
            Survey_GridTemplate: `<div class="container-fluid nbs-grid-card">
                                        <div class="row nbs-card-row">
                                            <div class="nbs-card-cell-color" style="background-color: {{:VhrEventLifeStatusColor}};">
                                            </div>
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div class="row">
                                                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell">{{:SurveyId}}</div>
                                                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 nbs-card-cell" title="{{:SurveyCode}}">{{:SurveyCode}}</div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell" title="{{:cardSupport.SurveyExpireDate}}">{{:cardSupport.SurveyExpireDate}}</div>
                                                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 nbs-card-cell" title="{{:SurveyName}}">{{:SurveyName}}</div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell" title=""></div>
                                                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 nbs-card-cell nbs-card-text-color" title="{{:TargetName}}">{{:TargetName}}</div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell nbs-card-title">{{:cardSupport.translations.group}}</div>
                                                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell nbs-card-title">{{:cardSupport.translations.type}}</div>
                                                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell nbs-card-title">{{:cardSupport.translations.status}}</div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell" title="{{:VhrEventGroupName}}">{{:VhrEventGroupName}}</div>
                                                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell" title="{{:VhrEventTypeName}}">{{:VhrEventTypeName}}</div>
                                                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell" title="{{:VhrEventLifeStatusName}}">{{:VhrEventLifeStatusName}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>`,
            Survey_BoardTemplate: 'use-grid-template',
            Survey_StreamTemplate: `<div class="container-fluid nbs-grid-card">
                                    <div class="row nbs-card-row">
                                        <div class="nbs-card-cell-color" style="background-color: {{:VhrEventLifeStatusColor}};">
                                        </div>
                                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <div class="row">
                                                <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 nbs-card-cell nbs-card-title">Scadenza</div>
                                                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 nbs-card-cell">{{:cardSupport.SurveyExpireDate}}</div>
                                                <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 nbs-card-cell nbs-card-title">Titolo</div>
                                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell nbs-card-marked">{{:SurveyName}}</div>
                                                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 nbs-card-cell nbs-card-title">Stato</div>
                                                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 nbs-card-cell nbs-card-marked">{{:VhrEventLifeStatusName}}</div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 nbs-card-cell nbs-card-title">Id</div>
                                                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 nbs-card-cell">{{:SurveyId}}</div>
                                                <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 nbs-card-cell nbs-card-title">Descrizione</div>
                                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell">{{:SurveyDescription}}</div>
                                                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 nbs-card-cell nbs-card-title">Autore</div>
                                                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 nbs-card-cell">{{:SurveyAuthorName}}</div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 nbs-card-cell nbs-card-title">Codice</div>
                                                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 nbs-card-cell">{{:SurveyCode}}</div>
                                                <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 nbs-card-cell"></div>
                                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell"></div>
                                                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 nbs-card-cell nbs-card-title">Data compilazione</div>
                                                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 nbs-card-cell">{{:cardSupport.SurveyCompileDateSystem}}</div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 nbs-card-cell nbs-card-title">Gruppo</div>
                                                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 nbs-card-cell">{{:VhrEventGroupName}}</div>
                                                <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 nbs-card-cell nbs-card-title">Target</div>
                                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell nbs-card-text-color">{{:TargetName}}</div>
                                                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 nbs-card-cell nbs-card-title">Avviso</div>
                                                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 nbs-card-cell">{{:VhrEventLabelName}}</div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 nbs-card-cell nbs-card-title">Tipo</div>
                                                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 nbs-card-cell">{{:VhrEventTypeName}}</div>
                                                <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 nbs-card-cell"></div>
                                                <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5 nbs-card-cell"></div>
                                                <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 nbs-card-cell"></div>
                                                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 nbs-card-cell"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>`
        }

        // updated properties to lowercase
        this.parseTemplateObject(tempTemplateObject);
    }

    configureGrid(entity, mode, appStoreCode) {
        super.configureGrid(entity, mode, appStoreCode);

        // TypeScript cna override base
        switch (entity) {
            case 'survey': this.configSurvey(mode); break;
            case 'surveytemplate': this.configSurveyTemplate(mode); break;
            case 'surveytemplategroup': this.configSurveyTemplateGroup(mode); break;
            case 'surveytemplatetype': this.configSurveyTemplateType(mode); break;
        }
    }

    configSurvey(mode) {
        this.setFieldId('SurveyId');
        this.enableCardTemplateContents('Survey');

        this.nbs_queryCellInfo_mapping_formatter = {
            SurveyCompileDate: 'formatDateTime',
            SurveyCompileDateSystem: 'formatDateTime',
            IsArchived: 'formatBoolean'
        }

        this.nbs_queryCellInfo_mapping_custom_method_formatter = {
            VhrEventLifeStatusName: function (args) {
                if (args.data.VhrEventLifeStatusColor) {
                    args.cell.bgColor = args.data.VhrEventLifeStatusColor;
                }
            },
            WorkflowStatusName: function (args) {
                if (args.data.WorkflowStatusColor) {
                    args.cell.bgColor = args.data.WorkflowStatusColor;
                }
            }
        }

        switch (mode) {
            case 'surveykpireportform':
                this.setSortingAsc('SurveyCode');
                this.allowGrouping = false;
                this.addColumnId('SurveyId');
                this.addColumnString('SurveyCode', 'SurveyDto.SurveyCode');
                this.addColumnString('SurveyName', 'SurveyDto.SurveyName');
                this.addColumnString('VhrEventGroupName', 'SurveyDto.VhrEventGroupId');
                this.addColumnString('VhrEventTypeName', 'SurveyDto.VhrEventTypeId');
                this.addColumnString('SurveyExpireDate_display', 'Expiration');
                this.addColumnString('TargetName', 'SurveyDto.TargetId');
                this.addColumnString('VhrEventLifeStatusName', 'SurveyDto.SurveyLifeStatusId');
                this.addColumnString('SurveyAuthorName', 'SurveyDto.SurveyAuthorName');
                this.addColumnDate('SurveyCompileDateSystem', 'SurveyDto.SurveyCompileDate');
                break;
            case 'kpireport':
                this.setSortingAsc('SurveyCode');
                this.allowGrouping = false;
                this.addColumnId('SurveyId');
                this.addColumnString('SurveyCode', 'SurveyDto.SurveyCode');
                this.addColumnString('SurveyName', 'SurveyDto.SurveyName');
                this.addColumnString('SurveyDescription', 'SurveyDto.SurveyDescription');
                this.addColumnString('VhrEventGroupName', 'SurveyDto.VhrEventGroupId');
                this.addColumnString('VhrEventTypeName', 'SurveyDto.VhrEventTypeId');
                this.addColumnString('SurveyExpireDate_display', 'Expiration');
                this.addColumnString('TargetName', 'SurveyDto.TargetId');
                this.addColumnString('VhrEventLifeStatusName', 'SurveyDto.SurveyLifeStatusId');
                this.addColumnString('WorkflowStatusName', 'SurveyDto.WorkflowStatusKindId');
                this.addColumnString('SurveyAuthorName', 'SurveyDto.SurveyAuthorName');
                this.addColumnDate('SurveyCompileDateSystem', 'SurveyDto.SurveyCompileDate');
                this.addColumnDisplayAsCheckbox('IsArchived', 'SurveyDto.IsArchived');
                break;
            case 'list':
                this.setSortingDesc('SurveyId');
                this.addColumnId('SurveyId');
                this.addColumnString('SurveyCode', 'SurveyDto.SurveyCode');
                this.addColumnString('SurveyName', 'SurveyDto.SurveyName');
                this.addColumnString('SurveyDescription', 'SurveyDto.SurveyDescription');
                this.addColumnString('VhrEventGroupName', 'SurveyDto.VhrEventGroupId');
                this.addColumnString('VhrEventTypeName', 'SurveyDto.VhrEventTypeId');
                this.addColumnString('SurveyExpireDate_display', 'Expiration');
                this.addColumnString('TargetName', 'SurveyDto.TargetId');
                this.addColumnString('VhrEventLifeStatusName', 'SurveyDto.SurveyLifeStatusId');
                this.addColumnString('WorkflowStatusName', 'SurveyDto.WorkflowStatusKindId');
                this.addColumnDate('SurveyCompileDateSystem', 'SurveyDto.SurveyCompileDate');
                this.addColumnString('SurveyAuthorName', 'SurveyDto.SurveyAuthorName');
                this.addColumnTemplateContent('Assignee', 'AudAssignee', this.getTemplateContent('Assignee'));
                this.addColumnDisplayAsCheckbox('IsArchived', 'SurveyDto.IsArchived');
                this.addColumnTemplateContent('VhrEventLabels', 'SurveyDto.VhrEventLabels', this.getTemplateContent('VhrEventLabels'));
                break;
            case 'ondemand':
                this.addColumnId('SurveyId');
                this.addColumnString('SurveyCode', 'SurveyDto.SurveyCode');
                this.addColumnString('SurveyName', 'SurveyDto.SurveyName');
                this.addColumnString('SurveyDescription', 'SurveyDto.SurveyDescription');
                this.addColumnString('VhrEventGroupName', 'SurveyDto.VhrEventGroupId');
                this.addColumnString('VhrEventTypeName', 'SurveyDto.VhrEventTypeId');
                this.addColumnString('SurveyExpireDate_display', 'Expiration');
                this.addColumnString('TargetName', 'SurveyDto.TargetId');
                this.addColumnTemplateContent('Assignee', 'AudAssignee', this.getTemplateContent('Assignee'));
                this.addColumnDisplayAsCheckbox('IsArchived', 'SurveyDto.IsArchived');
                this.addColumnTemplateContent('VhrEventLabels', 'SurveyDto.VhrEventLabels', this.getTemplateContent('VhrEventLabels'));
                break;
            case 'vhr':
                this.addColumnId('SurveyId');
                this.addColumnString('SurveyCode', 'SurveyDto.SurveyCode');
                this.addColumnString('SurveyName', 'SurveyDto.SurveyName');
                this.addColumnString('SurveyDescription', 'SurveyDto.SurveyDescription');
                this.addColumnString('VhrEventGroupName', 'SurveyDto.VhrEventGroupId');
                this.addColumnString('VhrEventTypeName', 'SurveyDto.VhrEventTypeId');
                this.addColumnString('SurveyExpireDate_display', 'Expiration');
                this.addColumnString('TargetName', 'SurveyDto.TargetId');
                this.addColumnString('VhrEventLifeStatusName', 'SurveyDto.SurveyLifeStatusId');
                this.addColumnString('WorkflowStatusName', 'SurveyDto.WorkflowStatusKindId');
                break;
            case 'vhrcompiled':
                this.addColumnId('SurveyId');
                this.addColumnString('SurveyCode', 'SurveyDto.SurveyCode');
                this.addColumnString('SurveyName', 'SurveyDto.SurveyName');
                this.addColumnString('SurveyDescription', 'SurveyDto.SurveyDescription');
                this.addColumnString('VhrEventGroupName', 'SurveyDto.VhrEventGroupId');
                this.addColumnString('VhrEventTypeName', 'SurveyDto.VhrEventTypeId');
                this.addColumnString('SurveyExpireDate_display', 'Expiration');
                this.addColumnString('TargetName', 'SurveyDto.TargetId');
                this.addColumnString('VhrEventLifeStatusName', 'SurveyDto.SurveyLifeStatusId');
                this.addColumnString('WorkflowStatusName', 'SurveyDto.WorkflowStatusKindId');
                this.addColumnDate('SurveyCompileDateSystem', 'SurveyDto.SurveyCompileDate');
                break;
            case 'modal':
                this.setSortingAsc('SurveyCode');
                this.addColumnId('SurveyId');
                this.addColumnString('SurveyCode', 'SurveyDto.SurveyCode');
                this.addColumnString('SurveyName', 'SurveyDto.SurveyName');
                this.addColumnString('TargetName', 'SurveyDto.TargetId');
                this.addColumnString('SurveyExpireDate_display', 'Expiration');
                this.addColumnDate('SurveyCompileDateSystem', 'SurveyDto.SurveyCompileDate');
                break;
            case 'modalrecurrence':
                this.setSortingDesc('SurveyId');
                this.addColumnId('SurveyId');
                this.addColumnString('SurveyCode', 'SurveyDto.SurveyCode');
                this.addColumnString('SurveyName', 'SurveyDto.SurveyName');
                this.addColumnString('SurveyExpireDate_display', 'Expiration');
                this.addColumnString('VhrEventLifeStatusName', 'SurveyDto.SurveyLifeStatusId');
                this.addColumnString('TargetName', 'SurveyDto.TargetId');
                this.addColumnString('SurveyAuthorName', 'SurveyDto.SurveyAuthorName');
                break;
        }
    }
    configSurveyTemplate(mode) {
        this.setFieldId('SurveyId');
        this.setSortingDesc('SurveyId');

        this.nbs_queryCellInfo_mapping_formatter = {
            IsArchived: 'formatBoolean'
        }

        switch (mode) {
            case 'search':
                this.setSortingAsc('SurveyCode');
                this.allowGrouping = false;
                this.addColumnId('SurveyId');
                this.addColumnString('SurveyCode', 'SurveyTemplate.SurveyCode');
                this.addColumnString('SurveyName', 'SurveyTemplate.SurveyName');
                this.addColumnString('SurveyDescription', 'SurveyTemplate.SurveyDescription');
                this.addColumnDisplayAsCheckbox('IsArchived', 'SurveyTemplate.IsArchived');
                break;
            case 'list':
                this.setSortingAsc('SurveyCode');
                this.allowGrouping = false;
                this.addColumnId('SurveyId');
                this.addColumnString('SurveyCode', 'SurveyTemplate.SurveyCode');
                this.addColumnString('SurveyName', 'SurveyTemplate.SurveyName');
                this.addColumnString('SurveyDescription', 'SurveyTemplate.SurveyDescription');
                this.addColumnString('SurveyTemplateGroupName', 'SurveyTemplate.SurveyTemplateGroup');
                this.addColumnString('SurveyTemplateTypeName', 'SurveyTemplate.SurveyTemplateType');
                this.addColumnDisplayAsCheckbox('IsArchived', 'SurveyTemplate.IsArchived');
                break;
            case 'modal':
                this.setSortingAsc('SurveyCode');
                this.allowGrouping = false;
                this.addColumnId('SurveyId');
                this.addColumnString('SurveyCode', 'SurveyTemplate.SurveyCode');
                this.addColumnString('SurveyName', 'SurveyTemplate.SurveyName');
                break;
        }
    }
    configSurveyTemplateGroup(mode) {
        this.setFieldId('SurveyTemplateGroupId');
        this.setSortingAsc('SurveyTemplateGroupCode');
        this.addColumnId('SurveyTemplateGroupId');
        this.addColumnString('SurveyTemplateGroupCode', 'SurveyTemplateGroup.SurveyTemplateGroupCode');
        this.addColumnString('SurveyTemplateGroupName', 'SurveyTemplateGroup.SurveyTemplateGroupName');
    }
    configSurveyTemplateType(mode) {
        this.setFieldId('SurveyTemplateTypeId');
        this.setSortingAsc('SurveyTemplateTypeCode');
        this.addColumnId('SurveyTemplateTypeId');
        this.addColumnString('SurveyTemplateTypeCode', 'SurveyTemplateType.SurveyTemplateTypeCode');
        this.addColumnString('SurveyTemplateTypeName', 'SurveyTemplateType.SurveyTemplateTypeName');
    }
}

class NbsAudRibbon extends NbsRibbon {
    constructor(navigationService, virtualContext, buttonsMode) {
        super(navigationService, virtualContext, buttonsMode);

        this.Buttons['NewAudit'] = new this.Button('new-audit', 'ActionPanelNewAudit', 'e-document-new-img', 'newAction');
        this.Buttons['Compile'] = new this.Button('compile', 'ActionPanelCompile', 'e-compile-img', 'compileAction');
        this.Buttons['TimelineAuditAction'] = new this.Button('views-timelineaudact', 'ActionPanelViewsTimelineAudAct', null, 'onRibbonTimelineAudActModeSelect');
        this.Buttons['NewQuestion'] = new this.Button('new-audit-survey-question', 'Question', 'e-new-audit-survey-question-img', 'newAuditSurveyQuestion');
        this.Buttons['NewSection'] = new this.Button('new-audit-survey-section', 'Section', 'e-new-audit-survey-section-img', 'newAuditSurveySection');
        this.Buttons['ModifySection'] = new this.Button('modify-audit-survey-section', 'ActionPanelModifySection', 'e-folder_open-edit-img', 'modifyAuditSurveySection');
    }

    //#region ribbon view navigation (views buttons)
    onRibbonGrid_Survey = function (navParams) {
        var state = this.getAudViewsState('Grid', false);
        this.navigationService.navigateTo(state, navParams);
    };
    onRibbonCalendar_Survey = function (navParams) {
        var state = this.getAudViewsState('Calendar', false);
        navParams.viewKind = 'calendar';
        this.navigationService.navigateTo(state, navParams);
    };
    onRibbonStream_Survey = function (navParams) {
        var state = this.getAudViewsState('Stream', false);
        this.navigationService.navigateTo(state, navParams);
    };
    onRibbonBoard_Survey = function (navParams) {
        var state = this.getAudViewsState('Board', false);
        this.navigationService.navigateTo(state, navParams);
    };
    onRibbonTimeline_Survey = function (navParams) {
        var state = this.getAudViewsState('Timeline', false);
        navParams.viewKind = 'timeline';
        this.navigationService.navigateTo(state, navParams);
    };
    onRibbonTimelineTarget_Survey = function (navParams) {
        var state = this.getAudViewsState('TimelineTarget', false);
        navParams.viewKind = 'timelinevirtualequipment';
        this.navigationService.navigateTo(state, navParams);
    };
    onRibbonTimelineTargetAssignment_Survey = function (navParams) {
        var state = this.getAudViewsState('TimelineAssignment', false);
        navParams.viewKind = 'timelineassignment';
        this.navigationService.navigateTo(state, navParams);
    };
    onRibbonGrid_MySurvey = function (navParams) {
        var state = this.getAudViewsState('Grid', true);
        this.navigationService.navigateTo(state, navParams);
    };
    onRibbonCalendar_MySurvey = function (navParams) {
        var state = this.getAudViewsState('Calendar', true);
        navParams.viewKind = 'calendar';
        this.navigationService.navigateTo(state, navParams);
    };
    onRibbonStream_MySurvey = function (navParams) {
        var state = this.getAudViewsState('Stream', true);
        this.navigationService.navigateTo(state, navParams);
    };
    onRibbonBoard_MySurvey = function (navParams) {
        var state = this.getAudViewsState('Board', true);
        this.navigationService.navigateTo(state, navParams);
    };
    onRibbonTimeline_MySurvey = function (navParams) {
        var state = this.getAudViewsState('Timeline', true);
        navParams.viewKind = 'timeline';
        this.navigationService.navigateTo(state, navParams);
    };
    onRibbonTimelineTarget_MySurvey = function (navParams) {
        var state = this.getAudViewsState('TimelineTarget', true);
        navParams.viewKind = 'timelinevirtualequipment';
        this.navigationService.navigateTo(state, navParams);
    };
    onRibbonTimelineTargetAssignment_MySurvey = function (navParams) {
        var state = this.getAudViewsState('TimelineAssignment', true);
        navParams.viewKind = 'timelineassignment';
        this.navigationService.navigateTo(state, navParams);
    };

    getAudViewsState(dest, onlyAssigned) {
        var state = '';
        if (onlyAssigned) {
            state = 'app.nav.my';
        } else {
            state = 'app.nav.';
        }

        switch (dest) {
            case 'Stream': state += 'surveystream'; break;
            case 'Board': state += 'surveyboardduedate'; break;
            case 'Grid': state += 'surveyslist'; break;
            case 'Calendar': state += 'surveyschedule'; break;
            case 'Timeline': state += 'surveyschedule'; break;
            case 'TimelineTarget': state += 'surveyschedule'; break;
            case 'TimelineAssignment': state += 'surveyschedule'; break;
        }

        switch (dest) {
            case 'Calendar': state += '-' + this.viewMode.split('_')[0] + '_calendar'; break;
            case 'Timeline': state += '-' + this.viewMode.split('_')[0] + '_timeline'; break;
            case 'TimelineTarget': state += '-' + this.viewMode.split('_')[0] + '_timelinevirtualequipment'; break;
            case 'TimelineAssignment': state += '-' + this.viewMode.split('_')[0] + '_timelineassignment'; break;
            default:
                var viewmodesplit = this.viewMode.split('_')[0];
                if (viewmodesplit) {
                    viewmodesplit = '-' + viewmodesplit;
                }
                state += viewmodesplit; break;
        }

        return state;
    }
    //#endregion

    //#region TABS
    GetMyAuditManageTab() { return new this.Tab("ActionPanelTabManage", [this.GetVhrEventWorkflowsSection()]); }
    GetAuditManageTab() { return new this.Tab("ActionPanelTabManage", [this.GetSendNotificationSection(), this.GetWarningsSection(), this.GetSurveysLifeStatusSection(), this.GetVhrEventWorkflowsSection()]); }
    GetAuditSurveyDetailManageTab() { return new this.Tab("ActionPanelTabManage", [this.GetAuditSurveyNewSection(), this.GetAuditSurveyManageSection()]); }
    GetAuditViewsTab() { return new this.Tab("ActionPanelTabViews", [this.GetViewsModeSection(), this.GetAuditTimelineSection(), this.GeSurveyBoardSection()]); }
    //#endregion

    //#region SECTIONS
    GetAuditNewSection() {
        var section = new this.Section("ActionPanelGroupNew", null, 1);
        section.AlignType = ej.Ribbon.AlignType.Rows;
        section.Groups[0].Buttons = [this.Buttons.NewAudit, this.Buttons.Compile];
        return section;
    }
    GetAuditDetailNewSection() {
        var section = new this.Section("ActionPanelGroupNew", null, 1);
        section.AlignType = ej.Ribbon.AlignType.Rows;
        section.Groups[0].Buttons = [this.Buttons.NewAudit, this.Buttons.NewFolder];
        return section;
    }
    GetAuditSurveyNewSection() {
        var section = new this.Section("ActionPanelGroupNew", null, 2);
        section.Groups[0].Buttons = [this.Buttons.NewQuestion];
        section.Groups[0].Width = 65;
        section.Groups[1].Buttons = [this.Buttons.NewSection];
        return section;
    }
    GetAuditSurveyManageSection() {
        var section = new this.Section("ActionPanelGroupManage", null, 3);
        section.Groups[0].Buttons = [this.Buttons.ModifySection];
        section.Groups[1].Buttons = [this.Buttons.Modify2];
        section.Groups[2].Buttons = [this.Buttons.Save2];
        section.Groups[2].IsBigButton = false;
        section.Groups[2].Width = 120;
        return section;
    }
    GetTemplateNewSection() {
        var section = new this.Section("ActionPanelGroupNew", null, 1);
        section.AlignType = ej.Ribbon.AlignType.Rows;
        section.Groups[0].Buttons = [this.Buttons.NewTemplate, this.Buttons.NewFolder];
        return section;
    }
    GetAuditTargetNewSection() {
        var section = new this.Section("ActionPanelGroupNew", null, 1);
        section.AlignType = ej.Ribbon.AlignType.Rows;
        section.Groups[0].Buttons = [this.Buttons.NewTarget, this.Buttons.Compile];
        return section;
    }
    GetSurveysLifeStatusSection() {
        var section = new this.Section("ActionPanelGroupVhrEventsLifeStatus", null, 3);
        section.Groups[0].Buttons = [this.Buttons.VhrEventLifeStatusApprove];
        section.Groups[1].Buttons = [this.Buttons.VhrEventLifeStatusModify];
        section.Groups[2].Buttons = [this.Buttons.VhrEventLifeStatusInfo];
        section.Groups[2].IsBigButton = false;
        section.Groups[2].Width = 120;
        return section;
    }
    GetAuditTimelineSection() {
        var section = new this.Section("ActionPanelGroupTimeline", null, 2);
        section.Groups[0].Buttons = [this.Buttons.Timeline];
        section.Groups[1].Buttons = [this.Buttons.TimelineTarget, this.Buttons.TimelineAssignment, this.Buttons.TimelineAuditAction];
        section.Groups[1].IsBigButton = false;
        section.Groups[1].Width = 156;
        return section;
    }
    GetAuditManageSection() {
        var section = new this.Section("ActionPanelGroupManage", null, 4);
        section.Groups[0].Buttons = [this.Buttons.View];
        section.Groups[1].Buttons = [this.Buttons.Modify];
        section.Groups[2].Buttons = [this.Buttons.Delete];
        section.Groups[3].Buttons = [this.Buttons.OpenDetail, this.Buttons.Copy, this.Buttons.Save];
        section.Groups[3].IsBigButton = false;
        section.Groups[3].Width = 120;
        return section;
    }
    GeSurveyBoardSection() {
        var section = new this.Section("ActionPanelGroupBoard", null, 2);
        section.Groups[0].Buttons = [this.Buttons.Board];
        section.Groups[1].Buttons = [this.Buttons.BoardStatus];
        section.Groups[1].IsBigButton = false;
        section.Groups[1].Width = 120;
        return section;
    }
    //#endregion
}

class NbsAudUiOptionParser extends NbsUiOptionParser {
    constructor(translator) {
        super(translator);

        // add properties to base
        this.optionkey.SurveyTemplate = {
            optionValue: 'SurveyTemplateTypeId',
            optionCode: 'SurveyTemplateTypeCode',
            optionName: 'SurveyTemplateTypeName',
            optionDescription: 'SurveyTemplateTypeDescription',
            optionDisplayName: 'SurveyTemplateTypeName'
        };
        this.optionkey.SurveyTemplateGroup = {
            optionValue: 'SurveyTemplateGroupId',
            optionCode: 'SurveyTemplateGroupCode',
            optionName: 'SurveyTemplateGroupName',
            optionDescription: 'SurveyTemplateGroupDescription',
            optionDisplayName: 'SurveyTemplateGroupName'
        };

    }
}

class NbsAudSearchForm extends NbsSearchForm {
    constructor(translator) {
        super(translator);

        this._searchType = { SURVEY: 'SURVEY', SURVEYTEMPLATE: 'SURVEYTEMPLATE', AUDACT: 'AUDACT' };
        this._nodes = { ROOT: 'ROOT', SURVEY: 'SURVEY', SURVEYTEMPLATE: 'SURVEYTEMPLATE', SURVEYSECTION: 'SURVEYSECTION', SURVEYQUESTION: 'SURVEYQUESTION', SURVEYQUESTIONOPTION: 'SURVEYQUESTIONOPTION', AUDACT: 'AUDACT', SURVEYVHREVENT: 'SURVEYVHREVENT', VHREVENT: 'VHREVENT' };
        this._filters = {
            AUDACT: "currentActFilter",
            SURVEY: "currentSurveyFilter",
            SURVEYVHREVENT: "currentSurveyVhrEventFilter",
            VHREVENT: "currentVhrEventFilter",
            SURVEYTEMPLATE: "currentSurveyTemplateFilter",
            SURVEYSECTION: "currentSurveySectionFilter",
            SURVEYQUESTION: "currentSurveyQuestionFilter",
            SURVEYQUESTIONOPTION: "currentSurveyQuestionOptionFilter"
        };
        this._forms = {
            AUDACT: "formAct",
            VHREVENT: "formVhrEvent",
            SURVEYVHREVENT: "formSurveyVhrEvent",
            SURVEY: "formSurvey",
            SURVEYTEMPLATE: "formSurveyTemplate",
            SURVEYSECTION: "formSurveySection",
            SURVEYQUESTION: "formSurveyQuestion",
            SURVEYQUESTIONOPTION: "formSurveyQuestionOption"
        };
        this._fIdAudAct = {
            AUDACT_ActStartDate: this._filters.AUDACT + '.ActStartDate',
            AUDACT_ActExpireDate: this._filters.AUDACT + '.ActExpireDate',
            AUDACT_ActExecutionDate: this._filters.AUDACT + '.ActExecutionDate',
            AUDACT_ActWorkflowStatusKindId: this._filters.AUDACT + '.ActWorkflowStatusKindId',
            AUDACT_ActPriorityId: this._filters.AUDACT + '.ActPriorityId',
            AUDACT_ActTargetIds: this._filters.AUDACT + '.ActTargetIds',
            AUDACT_CategoriesId: this._filters.AUDACT + '.CategoriesId',
            AUDACT_DrbacAuthIds: this._filters.AUDACT + '.DrbacAuthIds',
            AUDACT_ActAuthorId: this._filters.AUDACT + '.ActAuthorId',
            AUDACT_VhrGroupIds: this._filters.AUDACT + '.VhrGroupIds',
            AUDACT_VhrTypeIds: this._filters.AUDACT + '.VhrTypeIds',
            AUDACT_ActSourceObjectId: this._filters.AUDACT + '.ActSourceObjectId',
            AUDACT_ActSourceObjectCode: this._filters.AUDACT + '.ActSourceObjectCode'
        };
        this._fIdSurvey = {
            SURVEY_SurveyCode: this._filters.SURVEY + '.SurveyCode',
            SURVEY_SurveyName: this._filters.SURVEY + '.SurveyName',
            SURVEY_SurveyDescription: this._filters.SURVEY + '.SurveyDescription',
            SURVEY_IsArchived: this._filters.SURVEY + '.IsArchived',
            SURVEY_SurveyIds: this._filters.SURVEY + '.SurveyIds'
        };
        this._fIdSurveyVhrEvent = {
            SURVEYVHREVENT_SurveyCompileDateSystem: this._filters.SURVEYVHREVENT + '.SurveyCompileDateSystem',
            SURVEYVHREVENT_SurveyTemplatesId: this._filters.SURVEYVHREVENT + '.SurveyTemplatesId',
            SURVEYVHREVENT_SurveyCreationPatternId: this._filters.SURVEYVHREVENT + '.SurveyCreationPatternId'
        };
        this._fIdVhrEvent = {
            VHREVENT_VhrEventLifeStatus: this._filters.VHREVENT + '.VhrEventLifeStatus',
            VHREVENT_WorkflowStatusId: this._filters.VHREVENT + '.WorkflowStatusId',
            VHREVENT_VhrEventGroupsId: this._filters.VHREVENT + '.VhrEventGroupsId',
            VHREVENT_VhrEventTypesId: this._filters.VHREVENT + '.VhrEventTypesId',
            VHREVENT_VhrTargetsId: this._filters.VHREVENT + '.VirtualHumanResourcesId',
            VHREVENT_StartDate: this._filters.VHREVENT + '.StartDate',
            VHREVENT_VhrEventLabelsId: this._filters.VHREVENT + '.VhrEventLabelsId'
        };
        this._fIdSurveyTemplate = {
            SURVEYTEMPLATE_SurveyCode: this._filters.SURVEYTEMPLATE + '.SurveyCode',
            SURVEYTEMPLATE_SurveyName: this._filters.SURVEYTEMPLATE + '.SurveyName',
            SURVEYTEMPLATE_SurveyDescription: this._filters.SURVEYTEMPLATE + '.SurveyDescription',
            SURVEYTEMPLATE_SurveyTemplateTypesId: this._filters.SURVEYTEMPLATE + '.SurveyTemplateTypesId',
            SURVEYTEMPLATE_SurveyTemplateGroupsId: this._filters.SURVEYTEMPLATE + '.SurveyTemplateGroupsId'
        };
        this._fIdSurveySection = {
            SURVEYSECTION_SurveySectionTitle: this._filters.SURVEYSECTION + '.SurveySectionTitle'
        };
        this._fIdSurveyQuestion = {
            SURVEYQUESTION_SurveyQuestionName: this._filters.SURVEYQUESTION + '.SurveyQuestionName'
        };
        this._fIdSurveyQuestionOption = {
            SURVEYQUESTIONOPTION_SurveyQuestionOptionName: this._filters.SURVEYQUESTIONOPTION + '.SurveyQuestionOptionName'
        };

        this._filterNodesAudAct = [
            { FieldId: this._nodes.AUDACT, FieldKindId: this._kind.FOLDER, FieldText: 'Act.Act', FieldParentId: this._nodes.ROOT },
            { FieldId: this._fIdAudAct.AUDACT_ActStartDate, FieldKindId: this._kind.DATA, FieldText: 'Act.ActStartDate', FieldParentId: this._nodes.AUDACT, FormOwner: this._forms.AUDACT },
            { FieldId: this._fIdAudAct.AUDACT_ActExpireDate, FieldKindId: this._kind.DATA, FieldText: 'Act.ActExpireDate', FieldParentId: this._nodes.AUDACT, FormOwner: this._forms.AUDACT },
            { FieldId: this._fIdAudAct.AUDACT_ActExecutionDate, FieldKindId: this._kind.DATA, FieldText: 'Act.ActExecutionDate', FieldParentId: this._nodes.AUDACT, FormOwner: this._forms.AUDACT },
            { FieldId: this._fIdAudAct.AUDACT_ActWorkflowStatusKindId, FieldKindId: this._kind.DATA, FieldText: 'Act.ActWorkflowStatusKindId', FieldParentId: this._nodes.AUDACT, FormOwner: this._forms.AUDACT },
            { FieldId: this._fIdAudAct.AUDACT_ActPriorityId, FieldKindId: this._kind.DATA, FieldText: 'Act.ActPriorityId', FieldParentId: this._nodes.AUDACT, FormOwner: this._forms.AUDACT },
            { FieldId: this._fIdAudAct.AUDACT_ActTargetIds, FieldKindId: this._kind.DATA, FieldText: 'Act.ActTarget', FieldParentId: this._nodes.AUDACT, FormOwner: this._forms.AUDACT },
            { FieldId: this._fIdAudAct.AUDACT_ActAuthorId, FieldKindId: this._kind.DATA, FieldText: 'Act.ActAuthor', FieldParentId: this._nodes.AUDACT, FormOwner: this._forms.AUDACT },
            { FieldId: this._fIdAudAct.AUDACT_CategoriesId, FieldKindId: this._kind.DATA, FieldText: 'Act.Categories', FieldParentId: this._nodes.AUDACT, FormOwner: this._forms.AUDACT },
            { FieldId: this._fIdAudAct.AUDACT_DrbacAuthIds, FieldKindId: this._kind.DATA, FieldText: 'Assignee', FieldParentId: this._nodes.AUDACT, FormOwner: this._forms.AUDACT },
            { FieldId: this._fIdAudAct.AUDACT_VhrGroupIds, FieldKindId: this._kind.DATA, FieldText: 'TargetDto.TargetGroupId', FieldParentId: this._nodes.AUDACT, FormOwner: this._forms.AUDACT },
            { FieldId: this._fIdAudAct.AUDACT_VhrTypeIds, FieldKindId: this._kind.DATA, FieldText: 'TargetDto.TargetTypeId', FieldParentId: this._nodes.AUDACT, FormOwner: this._forms.AUDACT },
            { FieldId: this._fIdAudAct.AUDACT_ActSourceObjectId, FieldKindId: this._kind.DATA, FieldText: 'Act.ActSourceObjectId', FieldParentId: this._nodes.AUDACT, FormOwner: this._forms.AUDACT },
            { FieldId: this._fIdAudAct.AUDACT_ActSourceObjectCode, FieldKindId: this._kind.DATA, FieldText: 'Act.ActSourceObjectCode', FieldParentId: this._nodes.AUDACT, FormOwner: this._forms.AUDACT }
        ];
        this._filterNodesSurvey = [
            { FieldId: this._nodes.SURVEY, FieldKindId: this._kind.FOLDER, FieldText: 'SurveyDto.SurveyDto', FieldParentId: this._nodes.ROOT },
            { FieldId: this._fIdSurvey.SURVEY_SurveyCode, FieldKindId: this._kind.DATA, FieldText: 'SurveyDto.SurveyCode', FieldParentId: this._nodes.SURVEY, FormOwner: this._forms.SURVEY },
            { FieldId: this._fIdSurvey.SURVEY_SurveyName, FieldKindId: this._kind.DATA, FieldText: 'SurveyDto.SurveyName', FieldParentId: this._nodes.SURVEY, FormOwner: this._forms.SURVEY },
            { FieldId: this._fIdSurvey.SURVEY_SurveyDescription, FieldKindId: this._kind.DATA, FieldText: 'SurveyDto.SurveyDescription', FieldParentId: this._nodes.SURVEY, FormOwner: this._forms.SURVEY },
            { FieldId: this._fIdSurvey.SURVEY_IsArchived, FieldKindId: this._kind.DATA, FieldText: 'SurveyDto.IsArchived', FieldParentId: this._nodes.SURVEY, FormOwner: this._forms.SURVEY },
            { FieldId: this._fIdSurvey.SURVEY_SurveyIds, FieldKindId: this._kind.DATA, FieldText: 'SurveyDto.SurveyDto', FieldParentId: this._nodes.SURVEY, FormOwner: this._forms.SURVEY },
            { FieldId: this._fIdSurveyVhrEvent.SURVEYVHREVENT_SurveyCompileDateSystem, FieldKindId: this._kind.DATA, FieldText: 'SurveyDto.SurveyCompileDate', FieldParentId: this._nodes.SURVEY, FormOwner: this._forms.SURVEY },
            { FieldId: this._fIdVhrEvent.VHREVENT_VhrEventLifeStatus, FieldKindId: this._kind.DATA, FieldText: 'SurveyDto.SurveyLifeStatusId', FieldParentId: this._nodes.SURVEY, FormOwner: this._forms.SURVEY },
            { FieldId: this._fIdVhrEvent.VHREVENT_WorkflowStatusId, FieldKindId: this._kind.DATA, FieldText: 'SurveyDto.WorkflowStatusKindId', FieldParentId: this._nodes.SURVEY, FormOwner: this._forms.SURVEY },
            { FieldId: this._fIdVhrEvent.VHREVENT_VhrEventTypesId, FieldKindId: this._kind.DATA, FieldText: 'SurveyDto.VhrEventTypeId', FieldParentId: this._nodes.SURVEY, FormOwner: this._forms.SURVEY },
            { FieldId: this._fIdVhrEvent.VHREVENT_VhrEventGroupsId, FieldKindId: this._kind.DATA, FieldText: 'SurveyDto.VhrEventGroupId', FieldParentId: this._nodes.SURVEY, FormOwner: this._forms.SURVEY },
            { FieldId: this._fIdSurveyVhrEvent.SURVEYVHREVENT_SurveyTemplatesId, FieldKindId: this._kind.DATA, FieldText: 'SurveyDto.SurveyTemplateId', FieldParentId: this._nodes.SURVEY, FormOwner: this._forms.SURVEY },
            { FieldId: this._fIdSurveyVhrEvent.SURVEYVHREVENT_SurveyCreationPatternId, FieldKindId: this._kind.DATA, FieldText: 'SurveyDto.SurveyCreationPatternId', FieldParentId: this._nodes.SURVEY, FormOwner: this._forms.SURVEY },

            { FieldId: this._fIdVhrEvent.VHREVENT_VhrTargetsId, FieldKindId: this._kind.DATA, FieldText: 'SurveyDto.TargetId', FieldParentId: this._nodes.SURVEY, FormOwner: this._forms.SURVEY },
            { FieldId: this._fIdVhrEvent.VHREVENT_StartDate, FieldKindId: this._kind.DATA, FieldText: 'SurveyDto.SurveyExpireDate', FieldParentId: this._nodes.SURVEY, FormOwner: this._forms.SURVEY },
            { FieldId: this._fIdVhrEvent.VHREVENT_VhrEventLabelsId, FieldKindId: this._kind.DATA, FieldText: 'SurveyDto.VhrEventLabels', FieldParentId: this._nodes.SURVEY, FormOwner: this._forms.SURVEY }
        ];
        this._filterNodesSurveyTemplate = [
            { FieldId: this._nodes.SURVEYTEMPLATE, FieldKindId: this._kind.FOLDER, FieldText: 'SurveyTemplate.SurveyTemplate', FieldParentId: this._nodes.ROOT },
            { FieldId: this._fIdSurveyTemplate.SURVEYTEMPLATE_SurveyCode, FieldKindId: this._kind.DATA, FieldText: 'SurveyTemplate.SurveyCode', FieldParentId: this._nodes.SURVEYTEMPLATE, FormOwner: this._forms.SURVEYTEMPLATE },
            { FieldId: this._fIdSurveyTemplate.SURVEYTEMPLATE_SurveyName, FieldKindId: this._kind.DATA, FieldText: 'SurveyTemplate.SurveyName', FieldParentId: this._nodes.SURVEYTEMPLATE, FormOwner: this._forms.SURVEYTEMPLATE },
            { FieldId: this._fIdSurveyTemplate.SURVEYTEMPLATE_SurveyDescription, FieldKindId: this._kind.DATA, FieldText: 'SurveyTemplate.SurveyDescription', FieldParentId: this._nodes.SURVEYTEMPLATE, FormOwner: this._forms.SURVEYTEMPLATE },
            { FieldId: this._fIdSurveyTemplate.SURVEYTEMPLATE_SurveyTemplateTypesId, FieldKindId: this._kind.DATA, FieldText: 'SurveyTemplate.SurveyTemplateType', FieldParentId: this._nodes.SURVEYTEMPLATE, FormOwner: this._forms.SURVEYTEMPLATE },
            { FieldId: this._fIdSurveyTemplate.SURVEYTEMPLATE_SurveyTemplateGroupsId, FieldKindId: this._kind.DATA, FieldText: 'SurveyTemplate.SurveyTemplateGroup', FieldParentId: this._nodes.SURVEYTEMPLATE, FormOwner: this._forms.SURVEYTEMPLATE }
        ];
        this._filterNodesSurveySection = [
            { FieldId: this._nodes.SURVEYSECTION, FieldKindId: this._kind.FOLDER, FieldText: 'SurveySection.SurveySection', FieldParentId: this._nodes.ROOT },
            { FieldId: this._fIdSurveySection.SURVEYSECTION_SurveySectionTitle, FieldKindId: this._kind.DATA, FieldText: 'SurveySection.SurveySectionTitle', FieldParentId: this._nodes.SURVEYSECTION, FormOwner: this._forms.SURVEYSECTION }
        ];
        this._filterNodesSurveyQuestion = [
            { FieldId: this._nodes.SURVEYQUESTION, FieldKindId: this._kind.FOLDER, FieldText: 'SurveyQuestion.SurveyQuestion', FieldParentId: this._nodes.ROOT },
            { FieldId: this._fIdSurveyQuestion.SURVEYQUESTION_SurveyQuestionName, FieldKindId: this._kind.DATA, FieldText: 'SurveyQuestion.SurveyQuestionName', FieldParentId: this._nodes.SURVEYQUESTION, FormOwner: this._forms.SURVEYQUESTION }
        ];
        this._filterNodesSurveyQuestionOption = [
            { FieldId: this._nodes.SURVEYQUESTIONOPTION, FieldKindId: this._kind.FOLDER, FieldText: 'SurveyQuestionOption.SurveyQuestionOption', FieldParentId: this._nodes.ROOT },
            { FieldId: this._fIdSurveyQuestionOption.SURVEYQUESTIONOPTION_SurveyQuestionOptionName, FieldKindId: this._kind.DATA, FieldText: 'SurveyQuestionOption.SurveyQuestionOptionName', FieldParentId: this._nodes.SURVEYQUESTIONOPTION, FormOwner: this._forms.SURVEYQUESTIONOPTION }
        ];
    }

    getNodesObj(searchType) {
        var values = [];
        switch (searchType) {
            case this._searchType.AUDACT:
                values = [this._nodes.ROOT, this._nodes.AUDACT, this._nodes.SURVEY, this._nodes.SURVEYVHREVENT, this._nodes.VHREVENT];
                break;
            case this._searchType.SURVEY:
                values = [this._nodes.ROOT, this._nodes.SURVEY, this._nodes.SURVEYSECTION, this._nodes.SURVEYQUESTION, this._nodes.SURVEYQUESTIONOPTION];
                break;
            case this._searchType.SURVEYTEMPLATE:
                values = [this._nodes.ROOT, this._nodes.SURVEYTEMPLATE, this._nodes.SURVEYSECTION, this._nodes.SURVEYQUESTION, this._nodes.SURVEYQUESTIONOPTION];
                break;
        }

        return this.getObjByConstantValues(this._nodes, values);
    }

    getFiltersObj(searchType) {
        var values = [];
        switch (searchType) {
            case this._searchType.AUDACT:
                values = [this._filters.AUDACT, this._filters.SURVEY, this._filters.SURVEYVHREVENT, this._filters.VHREVENT];
                break;
            case this._searchType.SURVEY:
                values = [this._filters.SURVEY, this._filters.VHREVENT, this._filters.SURVEYVHREVENT, this._filters.SURVEYSECTION, this._filters.SURVEYQUESTION, this._filters.SURVEYQUESTIONOPTION];
                break;
            case this._searchType.SURVEYTEMPLATE:
                values = [this._filters.SURVEYTEMPLATE, this._filters.SURVEYSECTION, this._filters.SURVEYQUESTION, this._filters.SURVEYQUESTIONOPTION];
                break;
        }

        return this.getObjByConstantValues(this._filters, values);
    }

    getFormsObj(searchType) {
        var values = [];
        switch (searchType) {
            case this._searchType.AUDACT:
                values = [this._forms.AUDACT, this._forms.SURVEY, this._forms.SURVEYVHREVENT, this._forms.VHREVENT];
                break;
            case this._searchType.SURVEY:
                values = [this._forms.SURVEY, this._forms.SURVEYSECTION, this._forms.SURVEYQUESTION, this._forms.SURVEYQUESTIONOPTION];
                break;
            case this._searchType.SURVEYTEMPLATE:
                values = [this._forms.SURVEYTEMPLATE, this._forms.SURVEYSECTION, this._forms.SURVEYQUESTION, this._forms.SURVEYQUESTIONOPTION];
                break;
        }

        return this.getObjByConstantValues(this._forms, values);
    }

    getFieldIdsObj(searchType) {
        var result = {};
        switch (searchType) {
            case this._searchType.AUDACT:
                this.getMergedObject(result, this._fIdAudAct);
                break;
            case this._searchType.SURVEY:
                this.getMergedObject(result, this._fIdSurvey);
                this.getMergedObject(result, this._fIdVhrEvent);
                this.getMergedObject(result, this._fIdSurveyVhrEvent);
                this.getMergedObject(result, this._fIdSurveySection);
                this.getMergedObject(result, this._fIdSurveyQuestion);
                this.getMergedObject(result, this._fIdSurveyQuestionOption);
                break;
            case this._searchType.SURVEYTEMPLATE:
                this.getMergedObject(result, this._fIdSurveyTemplate);
                this.getMergedObject(result, this._fIdSurveySection);
                this.getMergedObject(result, this._fIdSurveyQuestion);
                this.getMergedObject(result, this._fIdSurveyQuestionOption);
                break;
        }

        return result;
    }

    getVisibleFieldArray(searchType) {
        var result = [];
        switch (searchType) {
            case this._searchType.AUDACT:
                result = [
                    this._fIdAudAct.AUDACT_ActStartDate,
                    this._fIdAudAct.AUDACT_ActExpireDate,
                    this._fIdAudAct.AUDACT_ActExecutionDate,
                    this._fIdSurvey.SURVEY_SurveyCode
                ];
                break;
            case this._searchType.SURVEY:
                result = [
                    this._fIdSurveyVhrEvent.SURVEYVHREVENT_SurveyCompileDateSystem,
                    this._fIdVhrEvent.VHREVENT_VhrEventLifeStatus,
                    this._fIdSurvey.SURVEY_SurveyCode,
                    this._fIdSurveyVhrEvent.SURVEYVHREVENT_SurveyCreationPatternId
                ];
                break;
            case this._searchType.SURVEYTEMPLATE:
                result = [
                    this._fIdSurveyTemplate.SURVEYTEMPLATE_SurveyCode
                ];
                break;
        }

        return result;
    }

    getFilterNodesArray(searchType) {
        var result = this._filterNodesRoot;

        switch (searchType) {
            case this._searchType.AUDACT:
                result = Lazy(result)
                    .union(this._filterNodesAudAct)
                    .union(this._filterNodesSurvey)
                    .toArray();
                break;
            case this._searchType.SURVEY:
                result = Lazy(result)
                    .union(this._filterNodesSurvey)
                    .union(this._filterNodesSurveySection)
                    .union(this._filterNodesSurveyQuestion)
                    .union(this._filterNodesSurveyQuestionOption)
                    .toArray();
                break;
            case this._searchType.SURVEYTEMPLATE:
                result = Lazy(result)
                    .union(this._filterNodesSurveyTemplate)
                    .union(this._filterNodesSurveySection)
                    .union(this._filterNodesSurveyQuestion)
                    .union(this._filterNodesSurveyQuestionOption)
                    .toArray();
                break;
        }
        return result;
    }

    ParseSurveyFilter(surveyFilter, appStoreBw) {
        if (!surveyFilter) surveyFilter = {};

        surveyFilter.SurveyTemplateGroupsId = Lazy(surveyFilter.SurveyTemplateGroupsId).toArray();
        surveyFilter.SurveyTemplateTypesId = Lazy(surveyFilter.SurveyTemplateTypesId).toArray();
        surveyFilter.AppStoreBw = appStoreBw;

        this.cleanAllFilterProperties(surveyFilter);
        return surveyFilter;
    }

    ParseVhrEventFilter(vhrEventFilter, appStoreBw, vhrEventKindEnum, expressionTypeEnum) {
        if (!vhrEventFilter) vhrEventFilter = {};

        vhrEventFilter.AppStoreBw = appStoreBw;
        vhrEventFilter.VhrEventKind = vhrEventKindEnum.Data; //exclude Container
        vhrEventFilter.StartDate1Expression = expressionTypeEnum.GreaterThanOrEqual;
        vhrEventFilter.StartDate2Expression = expressionTypeEnum.LessThanOrEqual;
        vhrEventFilter.VhrEventLifeStatusExpression = expressionTypeEnum.Equal;

        vhrEventFilter.VhrEventGroupsId = Lazy(vhrEventFilter.VhrEventGroupsId).toArray();
        vhrEventFilter.VhrEventTypesId = Lazy(vhrEventFilter.VhrEventTypesId).toArray();
        vhrEventFilter.WorkCentersId = Lazy(vhrEventFilter.WorkCentersId).toArray();
        vhrEventFilter.VirtualHumanResourcesId = Lazy(vhrEventFilter.VirtualHumanResourcesId).toArray();

        this.cleanAllFilterProperties(vhrEventFilter);

        return vhrEventFilter;
    }

    ParseSurveyVhrEventFilter(surveyVhrEventFilter, appStoreBw) {
        if (!surveyVhrEventFilter) surveyVhrEventFilter = {};

        surveyVhrEventFilter.SurveyTemplatesId = Lazy(surveyVhrEventFilter.SurveyTemplatesId).toArray();
        surveyVhrEventFilter.AppStoreBw = appStoreBw;

        this.cleanAllFilterProperties(surveyVhrEventFilter);
        return surveyVhrEventFilter;
    }

    ParseSurveySectionFilter(surveySectionFilter) {
        if (!surveySectionFilter) surveySectionFilter = {};
        this.cleanAllFilterProperties(surveySectionFilter);
        return surveySectionFilter;
    }

    ParseSurveyQuestionFilter(surveyQuestionFilter) {
        if (!surveyQuestionFilter) surveyQuestionFilter = {};
        this.cleanAllFilterProperties(surveyQuestionFilter);
        return surveyQuestionFilter;
    }

    ParseSurveyQuestionOptionFilter(surveyQuestionOptionFilter) {
        if (!surveyQuestionOptionFilter) surveyQuestionOptionFilter = {};
        this.cleanAllFilterProperties(surveyQuestionOptionFilter);
        return surveyQuestionOptionFilter;
    }

    ParseActFilter(actFilter) {
        if (!actFilter) actFilter = {};

        actFilter.ActTargetIds = Lazy(actFilter.ActTargetIds).toArray();
        actFilter.CategoriesId = Lazy(actFilter.CategoriesId).toArray();

        this.cleanAllFilterProperties(actFilter);
        return actFilter;
    }

    SearchSurveysFormData() {
        var data = this.pData(this._searchType.SURVEY);
        var searchFormDataBase = this.searchFormDataBase(data.Constants, data.FilterNodes, this.translator);
        return searchFormDataBase;
    }

    SearchSurveyTemplatesFormData() {
        var data = this.pData(this._searchType.SURVEYTEMPLATE);
        var searchFormDataBase = this.searchFormDataBase(data.Constants, data.FilterNodes, this.translator);
        return searchFormDataBase;
    }

    SearchAudActsFormData() {
        var data = this.pData(this._searchType.AUDACT);
        var searchFormDataBase = this.searchFormDataBase(data.Constants, data.FilterNodes, this.translator);
        return searchFormDataBase;
    }
};

class NbsAudSurveyOptionChoiceForUI {
    constructor(surveyOptionChoiceGroupId, surveyOptionChoicesParsed, className, surveyOptionChoiceGroup) {
        this.SurveyOptionChoiceGroup = surveyOptionChoiceGroup;
        this.SurveyOptionChoiceGroupId = surveyOptionChoiceGroupId;
        this.SurveyOptionChoicesParsed = surveyOptionChoicesParsed;
        this.ClassName = className;
    }
}

class NbsAudWizardManager extends NbsWizardManager {
    constructor($q, translator, services, nbsGuidManager) {
        super($q, translator, services, nbsGuidManager);
        
        // #region Wizard Collectors
        this.WzCollector_SurveyCreationWizard = class {
            constructor(wzConfig) {
                this.SurveyIdSerialized = wzConfig.defaultData.SurveyIdSerialized ? wzConfig.defaultData.SurveyIdSerialized : null;
                this.SurveyTemplateIdSerialized = wzConfig.defaultData.SurveyTemplateIdSerialized ? wzConfig.defaultData.SurveyTemplateIdSerialized : null;
                this.SurveyCreationRequestCode = wzConfig.defaultData.SurveyCreationRequestCode ? wzConfig.defaultData.SurveyCreationRequestCode : null;
                this.SurveyCreationRequestName = wzConfig.defaultData.SurveyCreationRequestName ? wzConfig.defaultData.SurveyCreationRequestName : null;
                this.SurveyCreationRequestDescription = wzConfig.defaultData.SurveyCreationRequestDescription ? wzConfig.defaultData.SurveyCreationRequestDescription : null;
                this.ModuleTargetId = wzConfig.defaultData.ModuleTargetId ? wzConfig.defaultData.ModuleTargetId : null;
                this.VhrEventTemplateId = wzConfig.defaultData.VhrEventTemplateId ? wzConfig.defaultData.VhrEventTemplateId : null;
                this.VhrEventTypeId = wzConfig.defaultData.VhrEventTypeId ? wzConfig.defaultData.VhrEventTypeId : null;
                this.VhrEventGroupId = wzConfig.defaultData.VhrEventGroupId ? wzConfig.defaultData.VhrEventGroupId : null;
                this.RecurrenceStartDate = wzConfig.defaultData.RecurrenceStartDate ? wzConfig.defaultData.RecurrenceStartDate : null;
                this.RecurrenceEndDate = wzConfig.defaultData.RecurrenceEndDate ? wzConfig.defaultData.RecurrenceEndDate : null;
                this.EventStartTime = wzConfig.defaultData.EventStartTime ? wzConfig.defaultData.EventStartTime : "PT0H0M";
                this.EventEndTime = wzConfig.defaultData.EventEndTime ? wzConfig.defaultData.EventEndTime : "PT0H0M";
                this.VhrEventTransactionCode = wzConfig.defaultData.VhrEventTransactionCode ? wzConfig.defaultData.VhrEventTransactionCode : null;
                this.VirtualHumanResources = wzConfig.defaultData.VirtualHumanResources ? wzConfig.defaultData.VirtualHumanResources : null;
                this.SurveyCreationRequestTypeId = wzConfig.defaultData.SurveyCreationRequestTypeId ? wzConfig.defaultData.SurveyCreationRequestTypeId : null;
                this.VirtualHumanResourceIds = wzConfig.defaultData.VirtualHumanResourceIds ? wzConfig.defaultData.VirtualHumanResourceIds : null;
                this.SurveyCreationRequestCompileTypeId = wzConfig.defaultData.SurveyCreationRequestCompileTypeId ? wzConfig.defaultData.SurveyCreationRequestCompileTypeId : null;
                this.CronExpression = wzConfig.defaultData.CronExpression ? wzConfig.defaultData.CronExpression : null;
                this.CopyOptionsCheckedFromSource = wzConfig.defaultData.CopyOptionsCheckedFromSource ? wzConfig.defaultData.CopyOptionsCheckedFromSource : false;
                this.CopyOptionValueFromSource = wzConfig.defaultData.CopyOptionValueFromSource ? wzConfig.defaultData.CopyOptionValueFromSource : false;
                this.CopyOptionWeightFromSource = wzConfig.defaultData.CopyOptionWeightFromSource ? wzConfig.defaultData.CopyOptionWeightFromSource : false;
                this.CopyOptionChoiceFromSource = wzConfig.defaultData.CopyOptionChoiceFromSource ? wzConfig.defaultData.CopyOptionChoiceFromSource : false;
                this.ValidationSchemaId = wzConfig.defaultData.ValidationSchemaId ? wzConfig.defaultData.ValidationSchemaId : null;
            }
        }
        // #endregion

        // #region Wiward Results
        this.WzResult_SurveyCreationRequest = class {
            constructor(services, nbsGuidManager) {
                this.SurveyCreationRequestId = nbsGuidManager.newGuid();
                this.SurveyCreationRequestCode = services.audService.surveyCreationCollector.SurveyCreationRequestCode;
                this.SurveyCreationRequestName = services.audService.surveyCreationCollector.SurveyCreationRequestName;
                this.SurveyCreationRequestDescription = services.audService.surveyCreationCollector.SurveyCreationRequestDescription;
                this.ModuleTargetId = services.audService.surveyCreationCollector.ModuleTargetId;
                this.SurveyIdSerialized = services.audService.surveyCreationCollector.SurveyIdSerialized;
                this.SurveyTemplateIdSerialized = services.audService.surveyCreationCollector.SurveyTemplateIdSerialized;
                this.VhrEventGroupId = services.audService.surveyCreationCollector.VhrEventGroupId;
                this.VhrEventTypeId = services.audService.surveyCreationCollector.VhrEventTypeId;
                this.CronExpression = services.audService.surveyCreationCollector.CronExpression;
                this.SurveyCreationRequestTypeId = services.audService.surveyCreationCollector.SurveyCreationRequestTypeId;
                this.RecurrenceStartDate = services.audService.surveyCreationCollector.RecurrenceStartDate;
                this.EventStartTime = services.audService.surveyCreationCollector.EventStartTime;
                this.RecurrenceEndDate = services.audService.surveyCreationCollector.RecurrenceEndDate;
                this.EventEndTime = services.audService.surveyCreationCollector.EventEndTime;
                this.VirtualHumanResourceIds = services.audService.surveyCreationCollector.VirtualHumanResourceIds;
                this.VirtualHumanResourcesSerialized = JSON.stringify(this.VirtualHumanResourceIds);
                this.CopyOptionWeightFromSource = services.audService.surveyCreationCollector.CopyOptionWeightFromSource;
                this.CopyOptionsCheckedFromSource = services.audService.surveyCreationCollector.CopyOptionsCheckedFromSource;
                this.CopyOptionValueFromSource = services.audService.surveyCreationCollector.CopyOptionValueFromSource;
                this.SurveyCreationRequestCompileTypeId = services.audService.surveyCreationCollector.SurveyCreationRequestCompileTypeId;
                this.CopyOptionChoiceFromSource = services.audService.surveyCreationCollector.CopyOptionChoiceFromSource;
                this.ValidationSchemaId = services.audService.surveyCreationCollector.ValidationSchemaId;
            }
        }
        // #endregion

        // #region Wiward Configs
        this.WzConfig_AddSurveyCreationRequest = class {
            constructor() {
                // in manual thesaurus add 'WzBeardcrumbStep_' + step name translations
                this.stepDetail = 'surveyCreationStepDetail';
                this.stepCron = 'cronExpressionDetail';
                this.stepCopyOptions = 'copySurveyOptionsDetail';
                this.stepSummary = 'surveyCreationStepSummary';

                // on layout step is disabled   
                this.stepsToSkip = [];

                // do not remove, used in this wizard to view virtual human resources list in stepVhr
                this.vhrEntityType = null;

                this.supportData = null;                                      // enums, ...

                // used to add default properties to use in wizard (DO NOT REMOVE)
                // managed in initCollectionData, to start with predefined properties
                this.defaultData = {};

                // mandatory: used to navigate wizard and to add route at runtime
                this.steps = [
                    { order: 1, name: this.stepDetail, isVisible: true, baseName: 'aud/surveyscreation/' + this.stepDetail, viewMode: '_', modalKey: this.stepDetail },
                    { order: 2, name: this.stepCron, isVisible: true, params: { cronstring: null }, baseName: 'rds/common/' + this.stepCron, viewMode: 'surveycreation', modalKey: this.stepCron },
                    { order: 3, name: this.stepCopyOptions, isVisible: true, params: { entityName: 'Survey' }, baseName: 'aud/audcommon/' + this.stepCopyOptions, viewMode: 'survey', modalKey: this.stepCopyOptions },
                    { order: 4, name: this.stepSummary, isVisible: true, baseName: 'aud/surveyscreation/' + this.stepSummary, viewMode: '_', modalKey: this.stepSummary }
                ];

                // used to eventually remove step before launch the wizard
                this.removeStep = function (stepName) {
                    this.steps = Lazy(this.steps).reject({ name: stepName });
                };
            }
        }
        // #endregion        
    }

    setWzConfig(wzConfig) {
        super.setWzConfig(wzConfig);

        if (this.wzConfig instanceof this.WzConfig_AddSurveyCreationRequest) {
            configAddSurveyCreationRequest(this)
        }

        function configAddSurveyCreationRequest(wzInstance) {
            wzInstance.collectDataFn = function (modalResult, currentStep) {
                if (!wzInstance.services.audService.surveyCreationCollector) {
                    wzInstance.services.audService.surveyCreationCollector = {};
                }

                switch (modalResult.sourceParams.modalKey) {
                    case 'surveyCreationStepDetail':
                        wzInstance.services.audService.surveyCreationCollector.SurveyCreationRequestCode = modalResult.data.SurveyCreationRequestCode;
                        wzInstance.services.audService.surveyCreationCollector.SurveyCreationRequestName = modalResult.data.SurveyCreationRequestName;
                        wzInstance.services.audService.surveyCreationCollector.SurveyCreationRequestDescription = modalResult.data.SurveyCreationRequestDescription;
                        wzInstance.services.audService.surveyCreationCollector.ModuleTargetId = modalResult.data.ModuleTargetId;
                        wzInstance.services.audService.surveyCreationCollector.ModuleTargetName = modalResult.data.ModuleTargetName;
                        wzInstance.services.audService.surveyCreationCollector.VhrEventGroupId = modalResult.data.VhrEventGroupId;
                        wzInstance.services.audService.surveyCreationCollector.VhrEventGroupName = modalResult.data.VhrEventGroupName;
                        wzInstance.services.audService.surveyCreationCollector.VhrEventTypeId = modalResult.data.VhrEventTypeId;
                        wzInstance.services.audService.surveyCreationCollector.VhrEventTypeName = modalResult.data.VhrEventTypeName;
                        wzInstance.services.audService.surveyCreationCollector.RecurrenceStartDate = modalResult.data.RecurrenceStartDate;
                        wzInstance.services.audService.surveyCreationCollector.EventStartTime = modalResult.data.EventStartTime;
                        wzInstance.services.audService.surveyCreationCollector.RecurrenceEndDate = modalResult.data.RecurrenceEndDate;
                        wzInstance.services.audService.surveyCreationCollector.EventEndTime = modalResult.data.EventEndTime;
                        wzInstance.services.audService.surveyCreationCollector.VirtualHumanResourceIds = modalResult.data.VirtualHumanResourceIds;
                        wzInstance.services.audService.surveyCreationCollector.SurveyTemplateId = modalResult.data.SurveyTemplateId;
                        wzInstance.services.audService.surveyCreationCollector.SurveyIdSerialized = modalResult.data.SurveyIdSerialized;
                        wzInstance.services.audService.surveyCreationCollector.SurveyCreationRequestCompileTypeId = modalResult.data.SurveyCreationRequestCompileTypeId;
                        break;

                    case 'cronExpressionDetail':
                        wzInstance.services.audService.surveyCreationCollector.CronExpression = modalResult.data.getCronExpression();
                        wzInstance.services.audService.surveyCreationCollector.CronExpressionLabel = modalResult.data.getCronLabel();

                        currentStep.params.cronstring = wzInstance.services.audService.surveyCreationCollector.CronExpression;
                        break;

                    case 'copySurveyOptionsDetail':
                        wzInstance.services.audService.surveyCreationCollector.CopyOptionsCheckedFromSource = modalResult.data.CopyOptionsCheckedFromSource;
                        wzInstance.services.audService.surveyCreationCollector.CopyOptionValueFromSource = modalResult.data.CopyOptionValueFromSource;
                        wzInstance.services.audService.surveyCreationCollector.CopyOptionWeightFromSource = modalResult.data.CopyOptionWeightFromSource;
                        wzInstance.services.audService.surveyCreationCollector.CopyOptionChoiceFromSource = modalResult.data.CopyOptionChoiceFromSource;
                        wzInstance.services.audService.surveyCreationCollector.ValidationSchemaId = modalResult.data.ValidationSchemaId;
                        wzInstance.services.audService.surveyCreationCollector.ValidationSchemaName = modalResult.data.ValidationSchemaName;

                        currentStep.params.CopyOptionsCheckedFromSource = wzInstance.services.audService.surveyCreationCollector.CopyOptionsCheckedFromSource;
                        currentStep.params.CopyOptionWeightFromSource = wzInstance.services.audService.surveyCreationCollector.CopyOptionWeightFromSource;
                        currentStep.params.CopyOptionValueFromSource = wzInstance.services.audService.surveyCreationCollector.CopyOptionValueFromSource;
                        currentStep.params.CopyOptionChoiceFromSource = wzInstance.services.audService.surveyCreationCollector.CopyOptionChoiceFromSource;
                        currentStep.params.ValidationSchemaId = wzInstance.services.audService.surveyCreationCollector.ValidationSchemaId;
                        break;

                    case 'surveyCreationStepSummary':
                        // nothing to store 
                        break;

                    default:
                        throw 'An error has occurred';
                }
            };

            wzInstance.getResultDataFn = function () {
                return new wzInstance.WzResult_SurveyCreationRequest(wzInstance.services, wzInstance.nbsGuidManager);
            };

            wzInstance.initCollectionDataFn = function () {
                wzInstance.services.audService.surveyCreationCollector = new wzInstance.WzCollector_SurveyCreationWizard(wzInstance.wzConfig);
            };
        }        
    }    
}
//#endregion