﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'actService', 'audService', 'foundationService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function audActCreationRequestDetail($q, $scope, $timeout, $injector, actService, audService, foundationService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, audService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, audService, { dirtyFlagIsSuspended: vm.isDirtySuspended });

        var ribbon = new NbsAudRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var virtualHumanResourceId = navigationService.stateParams().virtualHumanResourceId;
        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
        var actTypeEnumParsed = Nembus.Common.Library.util.parseEnum(actService.dataContext.enumTypes.ActType, 'Act', vm.translate);
        var actTypeEnumObj = actTypeEnumParsed.obj.normal.name_value;
        var actPriorityEnumParsed = Nembus.Common.Library.util.parseEnum(actService.dataContext.enumTypes.ActPriority, 'Act', vm.translate);
        var actPriorityEnumObj = actPriorityEnumParsed.obj.normal.name_value;
        var surveyLifeStatusEnumObj = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyLifeStatus, null, vm.translate).obj.normal.name_value;
        var canAddAct = vm.havePermission(Nembus.Common.Library.auth.Permission['ADDACT'], contextService.virtualContext);

        vm.actPrioritiesForDropDown = actPriorityEnumParsed.list.translated;

        vm.categoriesMultiselectDs = new NbsMultiselect(vm.translate);
        vm.nbsActionDrbacModalObj = new NbsAssigneeSelectorModal({
            translator: vm.translate,
            suspendDirty: vm.suspendDirty,
            navigationService: navigationService,
            contextUserForSelection: null,
            getServerVarFn: Nembus.Common.Library.server.getServerVar
        });

        ribbon.setRibbonStatusButton(ribbon.Buttons.Save, canAddAct);
        ribbon.setRibbonStatusButton(ribbon.Buttons.Modify, false);
        ribbon.refreshStatusButtons();

        var selectionParams = { stateTo: 'app.nav.surveyslistmodal', destModalKey: 'survey', isMultiselect: false, dataKey: 'surveycompleted', filter: { VhrEventLifeStatusIds: [surveyLifeStatusEnumObj.ReportedAsFinished] } };
        vm.nbsSelectionGridWithModalSurvey = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalSurvey.setOnSelectFnCallback(function () {
            vm.audActCreationRequest.SurveyId = null;
            if (vm.nbsSelectionGridWithModalSurvey.selectedIds) {
                vm.audActCreationRequest.SurveyId = Lazy(vm.nbsSelectionGridWithModalSurvey.selectedIds).first();
            }
        });

        selectionParams = { destModalKey: 'vhr', entityMode: 'Target', translateKey: 'Target', isMultiselect: false };
        vm.nbsSelectionGridWithModalTarget = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalTarget.setOnSelectFnCallback(function () {
            vm.audActCreationRequest.ActTargetId = null;

            if (Lazy(vm.nbsSelectionGridWithModalTarget.selectedIds).some()) {
                vm.audActCreationRequest.ActTargetId = Lazy(vm.nbsSelectionGridWithModalTarget.selectedIds).first();
            }
        });

        if (virtualHumanResourceId) {
            getVirtualHumanResourceDto(false, virtualHumanResourceId)
                .then((vhr) => {
                    vm.nbsSelectionGridWithModalTarget.setSelectedItems([{
                        nbs_id: vhr.VirtualHumanResourceId,
                        nbs_code: vhr.VirtualCode,
                        nbs_name: vhr.VirtualName
                    }]);
                });
        }

        vm.audActCreationRequest = audService.dataContext.audActCreationRequests.add({
            ActCreationRequestId: NbsGuidManager.newGuid(),
            VirtualCompanyId: virtualCompanyId,
            ActTypeId: actTypeEnumObj.Manually,
            ActPriorityId: Nembus.Rds.Library.GetActPriorityIdByServerVariable(actPriorityEnumObj),
            AppStoreBw: appStoreBw,
            ActTargetId: null,
            SurveyId: null,
            ActActionTypeId: null,
            ActActionGroupId: null,
            ValidationSchemaId: null
        });


        vm.setIsAdding();
        loadData(false);

        var parents = vm.submitAction;
        vm.submitAction = function () {
            vm.audActCreationRequest.CategoriesIdSerialized = JSON.stringify(vm.categoriesMultiselectDs.selectedIds);
            vm.audActCreationRequest.UsersAssignedIdSerialized = JSON.stringify(vm.nbsActionDrbacModalObj.appUserMultiselectSelectedIds());
            vm.audActCreationRequest.UserGroupsAssignedIdSerialized = JSON.stringify(vm.nbsActionDrbacModalObj.drbacUserGroupMultiselectSelectedIds());

            parents()
                .then(function () {
                    navigationService.navigateTo('app.nav.audactdetail', { actId: vm.audActCreationRequest.ActId });
                });
        };

        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getCategoriesSubTreeOutsideAuthorization(forceReload, 'ACT'));  // index 1
            funcsPromises.push(getActActionGroups(forceReload));
            funcsPromises.push(getActActionTypes(forceReload));
            funcsPromises.push(getActValidationSchemaConfig(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.userCategoriesTree = results[0];
                    vm.actActionGroups = results[1];
                    vm.actActionTypes = results[2];

                    var validationSchemaConfig = results[3];

                    getValidationSchemaInstanceDto(forceReload, validationSchemaConfig.ValidationSchemaDefaultId)
                        .then((valSchema) => {
                            vm.validationSchema = valSchema;

                            // Set permission in UI for current act
                            audService.customizeAudActCreationRequestValidation(vm.validationSchema);

                            // validationschema
                            vm.audActCreationRequest.ValidationSchemaId = vm.validationSchema.ValidationSchemaInstanceId;

                            vm.audActCreationRequest.ActActionGroupId = Lazy(vm.actActionGroups).where({ IsSystem: true }).first().ActActionGroupId;
                            vm.audActCreationRequest.ActActionTypeId = Lazy(vm.actActionTypes).where({ IsSystem: true }).first().ActActionTypeId;

                            var categoriesMultiselect = Nembus.Common.Library.util.parseCategoryDtoForMultiSelectNew(vm.userCategoriesTree);

                            vm.categoriesMultiselectDs.addData(categoriesMultiselect);
                            vm.categoriesMultiselectDs.setOnchangeFnCallback(function () {
                                vm.audActCreationRequest.CategoriesIdSerialized = JSON.stringify(vm.categoriesMultiselectDs.selectedIds);
                            });

                            vm.nbsActionDrbacModalObj.setVisibilityServerVar('ssv_core_act_assignee_visibility_selection');
                            vm.nbsActionDrbacModalObj.setOnSelectFnCallback(function () {
                                vm.audActCreationRequest.UsersAssignedIdSerialized = JSON.stringify(vm.nbsActionDrbacModalObj.appUserMultiselectSelectedIds());
                                vm.audActCreationRequest.UserGroupsAssignedIdSerialized = JSON.stringify(vm.nbsActionDrbacModalObj.drbacUserGroupMultiselectSelectedIds());
                            });

                            if (vm.validationSchema.Visibility.UserGroupAssigned) {
                                vm.nbsActionDrbacModalObj.viewUserGroups();
                            } else {
                                vm.nbsActionDrbacModalObj.hideUserGroups();
                            }
                            if (vm.validationSchema.Visibility.UserAssigned) {
                                vm.nbsActionDrbacModalObj.viewUsers();
                            } else {
                                vm.nbsActionDrbacModalObj.hideUsers();
                            }
                            if (!vm.validationSchema.Enabling.UserGroupAssigned) {
                                vm.nbsActionDrbacModalObj.disableUsers();
                            }
                            if (!vm.validationSchema.Enabling.UserAssigned) {
                                vm.nbsActionDrbacModalObj.disableUsers();
                            }
                        });
                });
        }

        function getValidationSchemaInstanceDto(forceReload, validationSchemaInstanceId) {
            var defer = $q.defer();

            foundationService.getValidationSchemaInstanceDto(validationSchemaInstanceId, forceReload)
                .then(function (data) {
                    defer.resolve(data);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getCategoriesSubTreeOutsideAuthorization(forceReload, systemCategoryCode) {
            var filter = {
                SystemCategoryCode: systemCategoryCode
            };
            var defer = $q.defer();

            foundationService.getCategoriesSubTreeOutsideAuthorization(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getVirtualHumanResourceDto(forceReload, virtualHumanResourceId) {
            var filter = { VirtualHumanResourceId: virtualHumanResourceId };
            var defer = $q.defer();

            foundationService.getVirtualHumanResourceDto(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results[0]);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getActValidationSchemaConfig(forceReload) {
            var filter = {};
            var defer = $q.defer();

            actService.getActValidationSchemaConfig(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results[0]);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getActActionTypes(forceReload) {
            var filter = {};
            var defer = $q.defer();

            actService.getActActionTypes(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getActActionGroups(forceReload) {
            var filter = {};
            var defer = $q.defer();

            actService.getActActionGroups(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    }

    audActCreationRequestDetail.$inject = injectParams;
    audActCreationRequestDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('audActCreationRequestDetail', audActCreationRequestDetail);
});