﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$injector', '$timeout', 'foundationService', 'hrbucketService', 'navigationService', 'contextService'];
    function hrBucketsModal($q, $injector, $timeout, foundationService, hrbucketService, navigationService, contextService) {
        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        
        // modal parameters
        var pParams = navigationService.getModalData('hrBucketsModal', vm.viewMode).params;
        pParams = pParams ? pParams : navigationService.stateParams();
        var pIsMultiselect = false;

        var appStoreCode = 'all';
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var bucketClassificationEnumParsed = Nembus.Common.Library.util.parseEnum(hrbucketService.dataContext.enumTypes.BucketClassification, 'HrBucket', vm.translate);
        var bucketFilter = {
            OwnerAppStoreBw: appStoreBw,
            BucketClassificationId: pParams.BucketClassificationId
        };

        // dynamic modal title 
        vm.viewTitle = bucketClassificationEnumParsed.obj.translated.value_name[pParams.BucketClassificationId];

        getHrBuckets(true)
            .then((hrBuckets) => {
                var nbsGridDatacontext = new NbsGrid(vm.translate);
                nbsGridDatacontext.configureGrid('hrbucket', null);
                nbsGridDatacontext.setModalMode();
                nbsGridDatacontext.setDatasource(hrBuckets, false);
                vm.nbsGridDatacontext = nbsGridDatacontext;
            });

        vm.modalOk = function () {
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalOk);
            navigationService.closeCurrentModal(modalResult);
        };

        vm.modalCancel = function () {
            navigationService.closeCurrentModal();
        };

        var currentSelected = [];
        vm.gridSelection = function (args) {
            if (pIsMultiselect) {
                currentSelected = args.data;
            } else {
                if (args.data) {
                    currentSelected = [args.data];
                } else {
                    currentSelected = [];
                }
            }
        }

        function getHrBuckets(forceReload) {
            var defer = $q.defer();

            hrbucketService.getHrBuckets(bucketFilter, forceReload)
                .then(function (data) {
                    var hrBuckets = [];
                    if (pParams.hideSelected === true && Lazy(pParams.selectedIds).some()) {
                        hrBuckets = Lazy(data.results)
                            .filter((item) => { return !Lazy(pParams.selectedIds).contains(item.VirtualHumanResourceId); })
                            .toArray();
                    } else {
                        hrBuckets = data.results;
                    }

                    Lazy(hrBuckets)
                        .each((hrBucket) => {
                            hrBucket.BucketClassificationName = bucketClassificationEnumParsed.obj.translated.value_name[hrBucket.BucketClassificationId];
                        });

                    defer.resolve(hrBuckets);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getModalResult(dialogResult) {
            var modalResult = new navigationService.modalResult();
            modalResult.data = currentSelected;
            modalResult.selectedValues = Lazy(currentSelected).pluck('VirtualHumanResourceId').toArray();
            modalResult.hasSelection = Lazy(currentSelected).some();
            //modalResult.sourceParams = urlParams;
            modalResult.dialogResult = dialogResult;
            return modalResult;
        }
    }

    hrBucketsModal.$inject = injectParams;
    hrBucketsModal.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('hrBucketsModal', hrBucketsModal);
});
