﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'utilService', 'vhreventService', 'audService', 'reportService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var htmlToPdfSurveyQuestionGroupingReportForm = function ($q, $scope, $timeout, $injector, utilService, vhreventService, audService, reportService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, audService, navigationService, contextService, $q, $injector, $timeout);
        var checkStateChangeParams = { dirtyFlagIsDisabled: vm.isDirtyDisabled };
        checkStateChangeService.checkFormOnStateChange($scope, audService, checkStateChangeParams);

        // all form items are enabled
        vm.setIsEditingReport();

        var appStoreCode = 'aud';
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var stepsToRender = [];
        var surveyLifeStatusEnumObj = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyLifeStatus, null, vm.translate).obj.normal.name_value;
        var expressionTypeEnum = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.ExpressionType, null, vm.translate).obj.normal.name_value;
        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;

        vm.tabs = { form: 0, list: 1 };
        vm.activeTabIndex = 0;

        vm.nbsAuthorDrbacModalObj = new NbsAssigneeSelectorModal({
            translator: vm.translate,
            suspendDirty: vm.suspendDirty,
            navigationService: navigationService,
            contextUserForSelection: null,
            getServerVarFn: Nembus.Common.Library.server.getServerVar
        });
        vm.nbsAuthorDrbacModalObj.viewUsers();

        vm.tabOnSelect = function (tab) {
            switch (tab) {
                case vm.tabs.form:
                    vm.showForm = true;
                    vm.showList = false;
                    break;
                case vm.tabs.list:
                    vm.showForm = false;
                    vm.showList = true;
                    break;
            }

            vm.activeTabIndex = tab;
        };
        vm.tabOnSelect(vm.tabs.form);

        vm.htmlToPdfSurveyKpiClientData = audService.dataContext.htmlToPdfSurveyKpiClientDatas.add({
            Id: NbsGuidManager.newGuid(),
            SurveyAnalysisDefinitionId: null
        });

        // REFERS to 'reportDefinitionHtmlToDocDetail'
        vm.parentScope = $scope.$parent.vm;
        vm.parentScope.ribbonDatacontext.setRibbonStatusButton(vm.parentScope.ribbonDatacontext.Buttons.Search, true);
        vm.parentScope.ribbonDatacontext.refreshStatusButtons();
        vm.parentScope.initReportToDocHeader({ onSearchActionHtmlToDocFn: onActionSearch, onCreateHtmlFn: createHtmlReport });

        //#region Multiselect with grid selection
        var selectionParams = { destModalKey: 'vhr', entityMode: 'Target', translateKey: 'Target', isMultiselect: true };
        vm.nbsSelectionGridWithModalTarget = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        selectionParams = { stateTo: 'app.nav.surveyslistmodal', destModalKey: 'survey', dataKey: 'surveycompleted', filter: { VhrEventLifeStatusIds: [surveyLifeStatusEnumObj.ReportedAsFinished] } };
        vm.nbsSelectionGridWithModalSurvey = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        //#endregion


        // set title of current controller in report definition detail view
        navigationService.setControllerTitle();

        vm.gridSelection = function (args) {
            $timeout(() => {
                selectedSurveys = args.data;
                selectedSurveyIds = args.dataIds;

                setPrintButtonStatus();
            });
        }

        vm.currentSurveys = [];

        function setPrintButtonStatus() {
            var selected = Lazy(selectedSurveys).some();
            vm.parentScope.ribbonDatacontext.setRibbonStatusButton(vm.parentScope.ribbonDatacontext.Buttons.PrintReportPdf, selected);
            vm.parentScope.ribbonDatacontext.refreshStatusButtons();
        }

        var selectedSurveyIds = [];
        var selectedSurveys = [];

        var nbsGridDatacontext = new NbsAudGrid(vm.translate);
        nbsGridDatacontext.configureGrid('survey', 'kpireport', null);
        nbsGridDatacontext.setGridIsUnderTab();
        nbsGridDatacontext.setIsMultiselect();
        vm.nbsGridDatacontext = nbsGridDatacontext;

        function onActionSearch() {
            var defer = $q.defer();
            selectedSurveyIds = [];
            selectedSurveys = [];
            vm.forceDatasourceLocal = {
                refreshkey: new Date().getTime(),
                dataSource: []
            }

            vm.htmlToPdfSurveyKpiClientData.entityAspect.validateEntity();

            if (vm.htmlToPdfSurveyKpiClientData.entityAspect.hasValidationErrors) {
                $timeout(() => { /* implicit scope apply; */ });
                return defer.promise;
            }

            var filter = {
                surveyFilter: {
                    IsCompleted: true
                },
                surveySectionFilter: {},
                vhrEventFilter: {
                    ModuleTargetId: moduleTargetId,
                    VhrEventTypeId: vm.htmlToPdfSurveyKpiClientData.VhrEventTypeId,
                    VhrEventGroupId: vm.htmlToPdfSurveyKpiClientData.VhrEventGroupId,
                    VirtualHumanResourcesId: vm.nbsSelectionGridWithModalTarget.selectedIds,
                    HaveToExpandVhrsWithAssetTree: true
                },
                surveyVhrEventFilter: {
                    CompilerUserPersonIds: vm.nbsAuthorDrbacModalObj.appUserAreSelected() ? vm.nbsAuthorDrbacModalObj.appUserMultiselectSelectedIds() : null,
                    SurveyIds: vm.nbsSelectionGridWithModalSurvey.selectedIds,
                    SurveyCompileDateSystem1: vm.htmlToPdfSurveyKpiClientData.SurveyCompileDateSystem1,
                    SurveyCompileDateSystem1Expression: expressionTypeEnum.GreaterThanOrEqual,
                    SurveyCompileDateSystem2: vm.htmlToPdfSurveyKpiClientData.SurveyCompileDateSystem2,
                    SurveyCompileDateSystem2Expression: expressionTypeEnum.LessThanOrEqual
                },
                surveyQuestionFilter: {},
                surveyQuestionOptionFilter: {}
            };

            // reset result search
            vm.currentSurveys = [];

            searchSurveys(filter)
                .then(function (surveys) {
                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: surveys
                    }

                    vm.currentSurveys = surveys;

                    setPrintButtonStatus();

                    vm.tabOnSelect(vm.tabs.list);

                    defer.resolve(null);
                });

            return defer.promise;
        }

        function searchSurveys(filter) {
            var defer = $q.defer();
            audService.searchSurveys(filter)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        loadData(true);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(setSortFields());
            funcsPromises.push(getSurveyAnalysisDefinitions(false));
            funcsPromises.push(getVhrEventTypes(false, appStoreBw));
            funcsPromises.push(getVhrEventGroups(false, appStoreBw));

            $q.all(funcsPromises)
                .then(function () {

                });
        }

        function createHtmlReport() {
            // filter i a list of surveys selected from results grid
            var surveyIds = selectedSurveyIds;
            var surveyAnalysisDefinitionId = vm.htmlToPdfSurveyKpiClientData.SurveyAnalysisDefinitionId;

            var funcsPromises = [];
            funcsPromises.push(getSurveyAnalysis(true, surveyAnalysisDefinitionId, surveyIds));
            $q.all(funcsPromises)
                .then(function () {
                    parseReportHtmlDatasource(vm.currentSurveys, vm.surveyAnalysis);
                });
        }

        // prepare datasource for report in UI (to prepare Html)
        vm.htmlSteps = {
            SURVEYS: 'surveys-render'
        };

        function parseReportHtmlDatasource(surveys, surveyAnalysis) {
            stepsToRender = [];

            // object binded on UI
            var sortField = Lazy(vm.sortFields).findWhere({ value: vm.htmlToPdfSurveyKpiClientData.SortFieldId });
            var sortType = Lazy(vm.sortTypes).findWhere({ value: vm.htmlToPdfSurveyKpiClientData.SortTypeId });
            var kpiName = Lazy(vm.surveyAnalysisDefinitions)
                .findWhere({ SurveyAnalysisDefinitionId: vm.htmlToPdfSurveyKpiClientData.SurveyAnalysisDefinitionId })
                .SurveyAnalysisDefinitionName;

            vm.bindObject = {
                surveys: [],
                filters: {
                    KpiName: kpiName,
                    DataRange: Nembus.Common.Library.util.getRangeString(contextService, vm.htmlToPdfSurveyKpiClientData.SurveyCompileDateSystem1, vm.htmlToPdfSurveyKpiClientData.SurveyCompileDateSystem2),
                    SortFieldName: sortField ? sortField.name : null,
                    SortFieldType: sortType ? sortType.name : null
                }
            };

            var maxSurveyAnalysisValue = Lazy(surveyAnalysis).pluck('SurveyAnalysisValue').max();

            var parsedSurveys = [];
            Lazy(selectedSurveys)
                .each((survey) => {
                    var surveyAnalysisValue = 0;
                    var surveyAnalysisPercent = 0;

                    var currentsurveyAnalysis = Lazy(surveyAnalysis).findWhere({ SurveyId: survey.SurveyId });
                    if (currentsurveyAnalysis) {
                        surveyAnalysisValue = currentsurveyAnalysis.SurveyAnalysisValue;
                    }

                    //x : value = maxSurveyAnalysisValue : 100
                    if (maxSurveyAnalysisValue > 0) {
                        surveyAnalysisPercent = (surveyAnalysisValue * 100) / maxSurveyAnalysisValue;
                    }


                    parsedSurveys.push({
                        SurveyId: survey.SurveyId,
                        SurveyCode: survey.SurveyCode,
                        SurveyName: survey.SurveyName,
                        SurveyCompileDateSystem: Nembus.Common.Library.converter.date.formatDate(survey.SurveyCompileDateSystem, contextService.virtualContext.cultureInfo, true),
                        TargetName: survey.TargetName,
                        SurveyAnalysisValue: surveyAnalysisValue,
                        SurveyAnalysisPercent: surveyAnalysisPercent
                    });
                });

            if (parsedSurveys.length > 0) {
                stepsToRender.push(vm.htmlSteps.SURVEYS);
            }

            vm.bindObject.surveys = getSurveyOrdered(parsedSurveys, sortField, sortType);

            // set steps to render before html retrieve from directive
            vm.parentScope.htmlToDocManager.setHtmlStepsToRender(stepsToRender);

            if (!vm.parentScope.htmlToDocManager.hasHtmlStepsToRender()) {
                vm.parentScope.htmlToDocManager.htmlIsRendered();
            }
        }

        function getSurveyOrdered(surveys, sortField, sortType) {
            var descending = false;
            if (sortType) {
                descending = sortType.value === 'Descending';
            }
            var result = Lazy(surveys);

            if (sortField) {
                switch (sortField.value) {
                    case 'kpi':
                        result = Lazy(surveys).sortBy((item) => { return item.SurveyAnalysisValue; }, descending);
                        break;
                    case 'date':
                        result = Lazy(surveys).sortBy((item) => { return item.SurveyCompileDateSystem; }, descending);
                        break;
                    case 'surveyname':
                        result = Lazy(surveys).sortBy((item) => { return item.SurveyName; }, descending);
                        break;
                }
            }

            return result.toArray();
        }

        function getVhrEventTypes(forceReload, appStoreBw) {
            var filter = { AppStoreBw: appStoreBw };
            var defer = $q.defer();

            vhreventService.getVhrEventTypes(filter, forceReload)
                .then(function (data) {
                    vm.vhrEventTypes = data.results;
                    vm.vhrEventTypeObjForBind = Nembus.Common.Library.parser.objFromList(vm.vhrEventTypes, 'VhrEventTypeId', 'VhrEventTypeName');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrEventGroups(forceReload, appStoreBw) {
            var filter = { AppStoreBw: appStoreBw };
            var defer = $q.defer();

            vhreventService.getVhrEventGroups(filter, forceReload)
                .then(function (data) {
                    vm.vhrEventGroups = data.results;
                    vm.vhrEventGroupObjForBind = Nembus.Common.Library.parser.objFromList(vm.vhrEventGroups, 'VhrEventGroupId', 'VhrEventGroupName');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function setSortFields() {
            vm.sortFields = [];
            vm.sortFields.push({ value: 'kpi', name: vm.translate('Kpi') });
            vm.sortFields.push({ value: 'date', name: vm.translate('SurveyCompileDate') });
            vm.sortFields.push({ value: 'surveyname', name: vm.translate('SurveyName') });

            vm.sortTypes = [];
            vm.sortTypes.push({ value: 'Ascending', name: vm.translate('Ascending') });
            vm.sortTypes.push({ value: 'Descending', name: vm.translate('Descending') });
        }

        function getSurveyAnalysisDefinitions(forceReload) {
            // 1) SurveyQuestionOption_CompletionPercentRequired => Indice completamento
            // 2) SurveyQuestionOption_ActiveSumWeight => Somma pesi risposte attive
            // 3) SurveyQuestionOption_ActiveSumValue => Somma gravità risposte attive
            // 4) SurveyQuestionOption_ActiveWeightedSumWeightValue => Somma dei valori ponderati Pesi x Gravità
            // 5) SurveyQuestionOption_NormalizedIndexSumWeight => Indice normalizzato della sommatoria dei Pesi 
            // 6) SurveyQuestionOption_NormalizedIndexSumValue => Indice normalizzato della sommatoria delle Gravità
            // 7) SurveyQuestionOption_NormalizedIndexSumWeightXValue => Indice normalizzato della sommatoria ponderata Pesi x Gravità
            // 8) SurveyQuestionOption_ActiveAverageSumWeight => Media aritmetica dei Pesi 
            // 9) SurveyQuestionOption_ActiveAverageSumValue =>Media aritmetica delle Gravità
            // 10) SurveyQuestionOption_ActiveAverageSumWeightXValue => Media aritmetica dei Pesi x Gravità
            // 11) SurveyQuestionOption_ActiveWeightedAverageSumWeightXValue => Media ponderata dei Pesi x Gravità
            var filter = {
                SurveyAnalysisDefinitionIds: [
                    'SurveyQuestionOption_CompletionPercentRequired',
                    'SurveyQuestionOption_ActiveSumWeight',
                    'SurveyQuestionOption_ActiveSumValue',
                    'SurveyQuestionOption_ActiveWeightedSumWeightValue',
                    'SurveyQuestionOption_NormalizedIndexSumWeight',
                    'SurveyQuestionOption_NormalizedIndexSumValue',
                    'SurveyQuestionOption_NormalizedIndexSumWeightXValue',
                    'SurveyQuestionOption_ActiveAverageSumWeight',
                    'SurveyQuestionOption_ActiveAverageSumValue',
                    'SurveyQuestionOption_ActiveAverageSumWeightXValue',
                    'SurveyQuestionOption_ActiveWeightedAverageSumWeightXValue'
                ]
            };
            var defer = $q.defer();

            audService.getSurveyAnalysisDefinitions(filter, forceReload)
                .then(function (data) {
                    vm.surveyAnalysisDefinitions = Lazy(data.results).sortBy((item) => { return item.SurveyAnalysisDefinitionName }).toArray();
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getSurveyAnalysis(forceReload, surveyAnalysisDefinitionId, surveyIds) {
            var filter = {
                SurveyAnalysisDefinitionId: surveyAnalysisDefinitionId,
                SurveyAnalysisGroupModeId: 1, // Survey
                SurveyIds: surveyIds
            };
            var defer = $q.defer();

            audService.getSurveyAnalysis(filter, forceReload)
                .then(function (data) {
                    vm.surveyAnalysis = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    htmlToPdfSurveyQuestionGroupingReportForm.$inject = injectParams;
    htmlToPdfSurveyQuestionGroupingReportForm.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('htmlToPdfSurveyQuestionGroupingReportForm', htmlToPdfSurveyQuestionGroupingReportForm);
});
