﻿angular.module('nbs.shared.module')
    .controller('ModalInstanceCtrl', ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
        $scope.ok = function () {
            $uibModalInstance.close();
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        }
    }])

    //check dirty flag on breeze data service
    .factory('checkStateChangeService', ['$rootScope', '$uibModal', '$state', function ($rootScope, $uibModal, $state) {
        var self = this;
        var addCheck = function ($scope, svc, params) {
            if (!params) {
                params = { };
            }

            var removeListener = $scope.$on('$stateChangeStart'
                , function (event, toState, toParams, fromState, fromParams) {

                    checkOnExitFn(self.exitFn, fromState.name);

                    if (params.dirtyFlagIsDisabled) {
                        if (svc.hasChanges()) {
                            svc.rejectChanges();
                        }
                        
                        return;
                    }
                    if (typeof nbsModalDirtyIsOpen !== "undefined" && nbsModalDirtyIsOpen) {
                        return;
                    }
                    ////ignore dirtyflag check when navigate to ignoreDirtyFlag 
                    ////or come back from ignoreDirtyFlag
                    ////because listener is called on each controller every $stateChangeStart event
                    ////e.i. when modal is open have 2 controller active => $stateChangeStart is fire both on modal controller and opener controller
                    ////best practices:
                    ////when modal not have to change context: avoid to define dirty flag check on modal controller e.g. checkStateChangeService.checkFormOnStateChange($scope, myService);
                    //if (
                    //    (typeof toState !== "undefined"
                    //    && typeof toState.routeParams !== "undefined"
                    //    && toState.routeParams.ignoreDirtyFlag)
                    //    ||
                    //    (typeof toState !== "undefined" //when come back from modal, fromState.parent == toState.name => ignore dirty flag check
                    //    && typeof fromState !== "undefined"
                    //    && toState.name
                    //    && fromState.parent
                    //    && toState.name == fromState.parent)
                    //    ) {
                    //    return;
                    //}
                    if (params.dirtyFlagIsSuspended && params.dirtyFlagIsSuspended()) {
                        return;
                    }
                    if (!svc || !svc.hasChanges()) {
                        return;
                    }
                    event.preventDefault();
                    nbsModalDirtyIsOpen = true;
                    //------------
                    var message = $TG('DoYouWishAbandonBeforeSaveData') + '?';
                    var modalHeader = '<div class="modal-header" draggable><h3 class="modal-title">' + $TG('ModalWarning') + '</h3></div>';
                    var modalHtml = modalHeader + '<div class="modal-body">' + message + '</div>';
                    modalHtml += '<div class="modal-footer"><button class="btn btn-primary" ng-click="ok()">' + $TG('ModalButtonOk') + '</button><button class="btn btn-void" ng-click="cancel()">' + $TG('ModalButtonCancel') + '</button></div>';

                   
                    var modalInstance = $uibModal.open({
                        template: modalHtml,
                        controller: 'ModalInstanceCtrl'
                    });

                    modalInstance.result.then(function () {
                        nbsModalDirtyIsOpen = false;
                        svc.rejectChanges();
                        checkOnExitFn(self.exitFn, fromState.name);
                        //if (self.exitFn) {
                        //    self.exitFn.fn();
                        //    self.exitFn = null;
                        //}
                        $state.go(toState, toParams); // Continue with the initial state change

                        return;
                    }, function () {
                        nbsModalDirtyIsOpen = false;
                        //Modal dismissed
                        //return event.preventDefault();
                    });

                    //--------------
                    //var canContinue = confirm("Abbandonare la pagina senza salvare?");
                    //if (canContinue) {
                    //    svc.rejectChanges();
                    //    return;
                    //}
                    //event.preventDefault(); 
                });

            $scope.$on("$destroy", removeListener);
        };

        //action to do before live page

        var setOnPageExit = function (exitFn) {
            if (!self.exitFn) {
                //workaround avoid multiple loading
                self.exitFn = exitFn;
            }
        };

        var getOnPageExit = function () {
            return self.exitFn;
        };

        //e.g. dashboard.js 
        var checkOnExitFn = function (exitFn, fromState) {
            if (exitFn) {
                if (exitFn.stateName === fromState || exitFn.stateName == 'any') {
                    exitFn.isLeaved = true;
                    exitFn.fn();
                    exitFn = null;
                }
            }
        };

        return {
            checkFormOnStateChange: addCheck,
            //onPageExit: onPageExit,
            setOnPageExit: setOnPageExit,
            getOnPageExit: getOnPageExit
        };
    }]);