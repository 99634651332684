﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function avatarsList($q, $scope, $timeout, $injector, foundationService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return avatarSelected; });

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        checkStateChangeService.checkFormOnStateChange($scope, foundationService);

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var avatarSelected = null;

        vm.currentAvatars = [];

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getAvatars(false, appStoreBw));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.currentAvatars  = results[0];

                    var nbsGridDatacontext = new NbsGrid(vm.translate);
                    nbsGridDatacontext.configureGrid('avatar', 'list');
                    nbsGridDatacontext.viewCommonBar();
                    nbsGridDatacontext.setDatasource(vm.currentAvatars, false);
                    vm.nbsGridDatacontext = nbsGridDatacontext;
                });
        }

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.avatarftc", { 'virtualHumanResourceId': args.data.VirtualHumanResourceId, 'appStoreCodeFtc': appStoreCode });
        };

        vm.newAction = function () {
            navigationService.navigateTo("app.nav.avatarftc", { 'virtualHumanResourceId': null, 'appStoreCodeFtc': appStoreCode });
        };

        vm.gridSelection = function (args) {
            avatarSelected = args.data;
        };

        vm.refreshAction = function () {
            getAvatars(true, appStoreBw)
                .then((results) => {
                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: results
                    }
                });
        };

        function getAvatars(forceReload, appStoreBw) {
            var filter = { AppStoreBw: appStoreBw };
            var defer = $q.defer();

            foundationService.getAvatars(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    }

    avatarsList.$inject = injectParams;
    avatarsList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('avatarsList', avatarsList);
});