﻿'use strict';

define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'vhreventService', 'activityService', 'utilService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function vhrEventsManualStepActivitySelection($q, $scope, $timeout, $injector, foundationService, vhreventService, activityService, utilService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, foundationService);

        var viewKind = 'SubBom';
        vm.isBom = viewKind === 'Bom';
        vm.notIsBom = !vm.isBom;
        var service = vm.isBom ? bomService : activityService;

        var pParams = navigationService.stateParamsModal().parentParams;
        pParams = pParams ? pParams : navigationService.stateParams();

        var activityKindEnum = Nembus.Common.Library.util.parseEnum(activityService.dataContext.enumTypes.ActivityKind, null, vm.translate).obj.normal.name_value;
        var activityKindId = activityKindEnum[viewKind];
        var appStoreCode = navigationService.stateParams().appStoreCode;
        var vhrEventTypeId = navigationService.stateParams().vhrEventTypeId ? navigationService.stateParams().vhrEventTypeId : null;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('activity', 'wizard2');
        nbsGridDatacontext.setModalMode();
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        var effortUnitEnumParsed = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.EffortUnit, null, vm.translate);
        vm.effortUnits = effortUnitEnumParsed.list.normal;
        vm.effortUnitObjForBind = effortUnitEnumParsed.obj.normal.value_name;

        // navigation buttons visibility
        vm.setWizardButtonVisibility(pParams);

        // beardcrumb information
        var beardCrumbInfo = JSON.parse(pParams.beardCrumbInfo);
        Lazy(beardCrumbInfo.items).each((item) => { item.title = vm.translate(item.title); });
        vm.beardCrumbInfo = beardCrumbInfo;

        // refill data form

        vm.vhrEventManualStepActivitySelectionClientData = vhreventService.dataContext.vhrEventManualStepActivitySelectionClientDatas.add({
            VhrEventManualStepActivitySelectionClientDataId: NbsGuidManager.newGuid()
        });

        if (vhreventService.vhrEventManualCollector) {
            vm.vhrEventManualStepActivitySelectionClientData.IsEventManualByActivity = vhreventService.vhrEventManualCollector.IsEventManualByActivity;

            if (vhreventService.vhrEventManualCollector.ActivityId) {
                vm.vhrEventManualStepActivitySelectionClientData.Activity = [];
                vm.vhrEventManualStepActivitySelectionClientData.Activity.push({
                    ActivityId: vhreventService.vhrEventManualCollector.ActivityId,
                    ActivityCode: vhreventService.vhrEventManualCollector.Code,
                    ActivityName: vhreventService.vhrEventManualCollector.Name
                });
                vm.vhrEventManualStepActivitySelectionClientData.ActivityId = vhreventService.vhrEventManualCollector.ActivityId;

                vm.activitySelected = {
                    Text: vhreventService.vhrEventManualCollector.Code + ' ' + vhreventService.vhrEventManualCollector.Name
                };
            }
        }

        vm.modalNext = function () {
            //force validation on entity (manual metadata only client)

            vm.vhrEventManualStepActivitySelectionClientData.entityAspect.validateEntity();
            if (!vm.vhrEventManualStepActivitySelectionClientData.entityAspect.hasValidationErrors) {
                vhreventService.rejectChanges();

                // close modal and returns client data object
                var modalResult = getModalResult(navigationService.navigationActionTypes.modalNext);
                navigationService.closeCurrentModal(modalResult);
            } else {
                vm.alertConfig.isModal = true;
                vm.alertConfig.isReadonly = true;
                vm.removeAlertFromList(true);
                vm.setErrorMsg({ isMessageVisible: true, message: vm.translate('SelectOne') });
            }
        };

        vm.modalPrevious = function () {
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalPrevious);
            vhreventService.rejectChanges();
            navigationService.closeCurrentModal(modalResult);
        };

        vm.modalCancel = function () {
            navigationService.confirmExitWizard()
                .then(function (result) {
                    if (result) {
                        vhreventService.rejectChanges();
                        var modalResult = getModalResult(navigationService.navigationActionTypes.modalCancel);
                        navigationService.closeCurrentModal(modalResult);
                    }
                });
        };

        vm.rowSelected = function (args) {
            $timeout(() => {
                vm.isActivitySelectedFromList = true;
                vm.removeAlertFromList(true);

                vm.vhrEventManualStepActivitySelectionClientData.Activity = [];
                vm.vhrEventManualStepActivitySelectionClientData.ActivityId = args.data.ActivityId;
                vm.vhrEventManualStepActivitySelectionClientData.Activity.push(args.data);

                vm.activitySelected = {
                    Text: args.data.ActivityCode + ' ' + args.data.ActivityName
                };
            });            
        };

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];

            $q.all(funcsPromises)
                .then(function () {
                    var supportData = { effortUnitEnumParsed: effortUnitEnumParsed };
                    var ejDataManager = service.getActivityDataManager(supportData, { AppStoreBw: appStoreBw, ActivityKindId: activityKindId }, ejDataManager);

                    //var selectedIds = [];
                    //if (activityService.wizardDependActivityCollector && Lazy(service.wizardDependActivityCollector.activityIds).some()) {
                    //    selectedIds = activityService.wizardDependActivityCollector.activityIds;
                    //}

                    nbsGridDatacontext.setDatasource(ejDataManager, true);
                });
        }

        function getModalResult(dialogResult) {
            var modalResult = new navigationService.modalResult();
            var resultData = null;

            if (vm.isActivitySelectedFromList) {
                var activity = vm.vhrEventManualStepActivitySelectionClientData.Activity[0];

                resultData = {
                    ActivityId: activity.ActivityId,
                    Code: activity.ActivityCode,
                    Name: activity.ActivityName,
                    Description: activity.ActivityDescription,
                    ExternalCode: activity.ActivityExternalCode,
                    ModuleTargetId: activity.ModuleTargetId,
                    VhrEventGroupId: activity.VhrEventGroupId,
                    VhrEventTypeId: activity.VhrEventTypeId,
                    VhrEventTemplateId: activity.VhrEventTemplateId
                };
            } else {
                resultData = {
                    ActivityId: vhreventService.vhrEventManualCollector.ActivityId,
                    Code: vhreventService.vhrEventManualCollector.Code,
                    Name: vhreventService.vhrEventManualCollector.Name,
                    Description: vhreventService.vhrEventManualCollector.Description,
                    ExternalCode: vhreventService.vhrEventManualCollector.ExternalCode,
                    ModuleTargetId: vhreventService.vhrEventManualCollector.ModuleTargetId,
                    VhrEventGroupId: vhreventService.vhrEventManualCollector.VhrEventGroupId,
                    VhrEventTypeId: vhreventService.vhrEventManualCollector.VhrEventTypeId,
                    VhrEventTemplateId: vhreventService.vhrEventManualCollector.VhrEventTemplateId
                };
            }

            modalResult.data = resultData;
            modalResult.sourceParams = pParams;
            modalResult.dialogResult = dialogResult;

            return modalResult;
        }
    }

    vhrEventsManualStepActivitySelection.$inject = injectParams;
    vhrEventsManualStepActivitySelection.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('vhrEventsManualStepActivitySelection', vhrEventsManualStepActivitySelection);
});