﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'rbacService', 'drbacService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var appUsersList = function ($q, $scope, $timeout, $injector, rbacService, drbacService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, rbacService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return appUserSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, rbacService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        if (contextService.virtualContext.HaveToManageIdpUsers === true) {
            ribbon.addTab(new ribbon.Tab("ActionPanelTabManage", [ribbon.GetLocalUserSection(), ribbon.GetIdpSection(), ribbon.GetSupportSection()]));
        } else {
            ribbon.addTab(new ribbon.Tab("ActionPanelTabManage", [ribbon.GetLocalUserSection()]));
        }
        vm.ribbonDatacontext = ribbon;


        var appUserSelected = [];
        var viewKind = navigationService.stateParams().viewKind;

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('appuserdto', '');
        nbsGridDatacontext.viewCustomBar();
        nbsGridDatacontext.setIsMultiselect();
        nbsGridDatacontext.setDatasource([], false); // fake datasource, only to create grid
        vm.nbsGridDatacontext = nbsGridDatacontext;

        ribbon.setRibbonStatusButton(ribbon.Buttons.New, Nembus.Rds.Library.CanManageUser(contextService));
        ribbon.refreshStatusButtons();

        // hook from gridbar
        var ejDataManager = null;
        var currentFilter = null;
        vm.appUserGridBarOnChanges = function (result) {
            if (viewKind === 'global') {
                currentFilter = result.globalUserFilter;
            } else if (viewKind === 'context') {
                currentFilter = result;
            }

            if (ejDataManager === null) {
                var supportData = { translate: vm.translate, contextService: contextService };
                if (viewKind === 'global') {
                    ejDataManager = rbacService.getGlobalUserDataManager(supportData, currentFilter, ejDataManager);
                } else if (viewKind === 'context') {
                    ejDataManager = rbacService.getContextUserDataManager(supportData, currentFilter, ejDataManager);
                }

                nbsGridDatacontext.setDatasource(ejDataManager, true);
                refreshGridDatasource(currentFilter);
            } else {
                refreshGridDatasource(currentFilter)
            }
        };

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.appuserdetail", { 'xCentralUserId': args.data.XCentralUserId });
        };

        vm.modalData = {};
        vm.newAction = function () {
            navigationService.navigateTo("app.nav.appuserdetail", { 'xCentralUserId': null });
        };

        vm.refreshAction = function () {
            $timeout(() => {
                vm.forceGridRefresh = new Date().getTime();
            });
        };

        vm.gridSelection = function (args) {
            $timeout(() => {
                appUserSelected = args.data;

                var countTotalSelected = Lazy(appUserSelected).toArray().length;
                var countIsProgrammaticAccess = Lazy(appUserSelected).where({ IsProgrammaticAccess: true }).toArray().length;
                var countNotIsProgrammaticAccess = Lazy(appUserSelected).where({ IsProgrammaticAccess: false }).toArray().length;

                var canResetPassword = countNotIsProgrammaticAccess === countTotalSelected && countTotalSelected > 0 && Nembus.Rds.Library.CanResetPassword();
                var canInvite = countNotIsProgrammaticAccess === countTotalSelected && countTotalSelected > 0 && Nembus.Rds.Library.CanInvite();
                var canRenewSecretCode = countNotIsProgrammaticAccess === countTotalSelected && countTotalSelected > 0 && Nembus.Rds.Library.CanRenewSecretCode();
                var canResetProgrammaticAccessKey = countIsProgrammaticAccess === countTotalSelected && countTotalSelected > 0 && Nembus.Rds.Library.CanResetProgrammaticAccessKey();

                ribbon.setRibbonStatusButton(ribbon.Buttons.ResetPasswordLocal, canResetPassword);
                ribbon.setRibbonStatusButton(ribbon.Buttons.NewAccountLocal, canInvite);
                ribbon.setRibbonStatusButton(ribbon.Buttons.RenewSecretCode, canRenewSecretCode);
                ribbon.setRibbonStatusButton(ribbon.Buttons.ProgrammaticAccess, canResetProgrammaticAccessKey);
                ribbon.refreshStatusButtons();
            });
        };

        //Http Post
        // reset password sending NBS-ADMIN-RESET-PASSWORD notification
        vm.resetPasswordAction = function () {
            if (!Lazy(appUserSelected).some()) {
                navigationService.selectAtLeastOneItemMessageModal();
                return;
            }

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        rbacService.adminResetPasswordBulk({ XCentralUserIds: Lazy(appUserSelected).pluck('XCentralUserId').toArray() })
                            .then(function (result) {
                                if (result.httpResponse.data === '1') {
                                    vm.setSaveSuccessMsg(false, false);

                                    navigationService.customMessageModal('EmailSendOk');
                                } else {
                                    navigationService.customMessageModal('EmailSendError');
                                }
                            })
                            .catch(function (error) {
                                vm.setErrorMsg(error);
                                navigationService.customMessageModal('EmailSendError');
                            });
                    }
                });
        };
        vm.programmaticAccessAction = function () {
            if (Lazy(appUserSelected).toArray().length !== 1) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        rbacService.adminResetProgrammaticAccess({ XCentralUserId: Lazy(appUserSelected).pluck('XCentralUserId').first() })
                            .then(function (data) {
                                var programmaticAccessKey = data.results[0] ? JSON.parse(data.results[0]) : null;
                                if (programmaticAccessKey) {
                                    navigationService.copyTextMessageModal(programmaticAccessKey, vm.translate('GenerateProgrammaticAccessKey'));
                                } else {
                                    navigationService.customMessageModal('RequestError');
                                }
                            })
                            .catch(function (error) {
                                vm.setErrorMsg(error);
                            });
                    }
                });
        }
        // reset password sending NBS-ADMIN-NEW-ACCOUNT notification
        vm.newAccountAction = function () {
            if (!Lazy(appUserSelected).some()) {
                navigationService.selectAtLeastOneItemMessageModal();
                return;
            }

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        rbacService.adminNewAccountResetPasswordBulk({ XCentralUserIds: Lazy(appUserSelected).pluck('XCentralUserId').toArray() })
                            .then(function (result) {
                                if (result.httpResponse.data === '1') {
                                    vm.setSaveSuccessMsg(false, false);

                                    navigationService.customMessageModal('EmailSendOk');
                                } else {
                                    navigationService.customMessageModal('EmailSendError');
                                }
                            })
                            .catch(function (error) {
                                vm.setErrorMsg(error);
                                navigationService.customMessageModal('EmailSendError');
                            });
                    }
                });
        };
        vm.ssoResetPasswordAction = function () {
            if (!Lazy(appUserSelected).some()) {
                navigationService.selectAtLeastOneItemMessageModal();
                return;
            }

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        rbacService.adminResetSsoPasswordBulk({ XCentralUserIds: Lazy(appUserSelected).pluck('XCentralUserId').toArray() })
                            .then(function (result) {
                                navigationService.customMessageModal('EmailSendOk');
                            })
                            .catch(function (error) {
                                vm.setErrorMsg(error);
                                navigationService.customMessageModal('EmailSendError');
                            });
                    }
                });
        };
        vm.ssoSyncroUser = function () {
            if (!Lazy(appUserSelected).some()) {
                navigationService.selectAtLeastOneItemMessageModal();
                return;
            }

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        rbacService.ssoVerifyUsersBulk({ XCentralUserIds: Lazy(appUserSelected).pluck('XCentralUserId').toArray() })
                            .then(function (result) {
                                navigationService.customMessageModal('RequestDone');
                            })
                            .catch(function (error) {
                                vm.setErrorMsg(error);
                                navigationService.customMessageModal('RequestError');
                            });

                    }
                });
        }
        vm.supportSyncroUser = function () {
            if (!Lazy(appUserSelected).some()) {
                navigationService.selectAtLeastOneItemMessageModal();
                return;
            }
            vm.removeAlerts();
            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    rbacService.supportVerifyUsersBulk({ XCentralUserIds: Lazy(appUserSelected).pluck('XCentralUserId').toArray() })
                        .then(function (result) {
                            navigationService.customMessageModal('RequestDone');
                        })
                        .catch(function (error) {
                            vm.setErrorMsg(error);
                            navigationService.customMessageModal('RequestError');
                        });
                });
        }

        vm.createGridEvent = function (result) {
            vm.appUserGridBarDataContext = {
                commonBarDatasource: result.barDatasource,
                defaultKeys: {
                    columnsKey: null, sortingKey: null
                },
                appUserType: viewKind,  // 'global' or 'context'
            };
        };

        vm.gridUiOnChanges = function (result) {
            vm.refreshGridUiComponent = {
                refreshKey: new Date().getTime(),
                uiData: result
            }
        }

        function refreshGridDatasource(currentFilter) {
            $timeout(() => {
                vm.forceDatamanagerFilter = {
                    refreshKey: new Date().getTime(),
                    filter: currentFilter
                }
            });
        }
    }

    appUsersList.$inject = injectParams;
    appUsersList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rbac.module').controller('appUsersList', appUsersList);
});
