﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'utilService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var parameterDetail = function ($q, $scope, $timeout, $injector, utilService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, utilService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, utilService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        ribbon.addTab(new ribbon.Tab("ActionPanelTabManage", [ribbon.GetSetDefaultSection()]));
        vm.ribbonDatacontext = ribbon;

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var variableCode = navigationService.stateParams().variableCode;

        vm.setIsViewing();
        ribbon.setRibbonStatusButton(ribbon.Buttons.Modify, false);
        ribbon.setRibbonStatusButton(ribbon.Buttons.SetDefault, true);
        ribbon.refreshStatusButtons();

        // used to set section visibility
        vm.ssvManagedType = utilService.ssvManagedType;

        // set default value to server system variable
        vm.setDefaultAction = function () {
            vm.currentServerSystemVariable.VariableValue = vm.currentServerSystemVariable.VariableDefault;
            vm.submitAction()
                .then(function (saveResult) {
                    loadData(true);
                });
        };

        vm.variableValueBooleanOnChange = function () {
            // entity state on breeze context changes only here
            vm.currentServerSystemVariable.VariableValue = vm.currentServerSystemVariable.VariableValueToBind.toString();
        };

        loadData(true);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getServerSystemVariable(forceReload, appStoreBw, variableCode));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.currentServerSystemVariable = results[0];
                    if (vm.currentServerSystemVariable.IsUaAdmin) {
                        ribbon.setRibbonStatusButton(ribbon.Buttons.Modify,contextService.virtualContext.IsUa);
                    } else {
                        ribbon.setRibbonStatusButton(ribbon.Buttons.Modify,true);
                    }

                    ribbon.refreshStatusButtons();

                    utilService.parseSystemServerVariable(vm.translate, vm.currentServerSystemVariable);
                });
        }

        function getServerSystemVariable(forceReload, appStoreBw, variableCode) {
            var filter = {
                IsVisible: true,
                AppStoreBw: appStoreBw,
                VariableCode: variableCode
            };
            var defer = $q.defer();

            utilService.getServerSystemVariable(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results[0]);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }        
    };

    parameterDetail.$inject = injectParams;
    parameterDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('parameterDetail', parameterDetail);
});
