﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'reportService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function reportDefinitionsList($q, $scope, $timeout, $injector, reportService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, reportService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return reportSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, reportService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var reportSelected = null;
        var reportDefinitionStatusesParsed = Nembus.Common.Library.util.parseEnum(reportService.dataContext.enumTypes.ReportDefinitionStatus, null, vm.translate);

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('reportdefinition', 'report');
        nbsGridDatacontext.viewCustomBar();
        nbsGridDatacontext.setGridIsRenderedInlineBlock();
        nbsGridDatacontext.setDatasource([], false); // fake datasource, only to create grid
        vm.nbsGridDatacontext = nbsGridDatacontext;

        ribbon.setRibbonStatusButton(ribbon.Buttons.New,  false);
        ribbon.refreshStatusButtons();

        // hook from gridbar
        var ejDataManager = null;
        var currentGridFilter = null;
        vm.reportBarOnChanges = function (result) {
            // save in view current filter passed by survey bar
            result.reportDefinitionFilter.ReportDefinitionStatusId = reportDefinitionStatusesParsed.obj.normal.name_value.Enabled;
            result.reportDefinitionFilter.AppStoreBw = appStoreBw;
            result.reportDefinitionFilter.IsCurrentContext = true;
            currentGridFilter = result.reportDefinitionFilter;

            if (ejDataManager === null) {
                var supportData = { translate: vm.translate, contextService: contextService };
                ejDataManager = reportService.getReportDataManager(supportData, currentGridFilter, ejDataManager);
                nbsGridDatacontext.setDatasource(ejDataManager, true);
                refreshGridDatasource(currentGridFilter);
            } else {
                refreshGridDatasource(currentGridFilter)
            }
        };

        vm.rowDblClick = function (args) {
            navigationService.navigateTo(args.data.CmsViewRenderNavigationState, {
                'appStoreCode': appStoreCode,
                'reportDefinitionId': args.data.ReportDefinitionId
            });
        };

        vm.refreshAction = function () {
            $timeout(() => {
                vm.forceGridRefresh = new Date().getTime();
            });
        };

        vm.gridSelection = function (args) {
            reportSelected = args.data;
        };

        vm.createGridEvent = function (result) {
            vm.reportBarDataContext = {
                commonBarDatasource: result.barDatasource,
                appStoreBw: appStoreBw,
                defaultKeys: {
                    columnsKey: null, sortingKey: null
                }
            };
        };

        vm.gridUiOnChanges = function (result) {
            vm.refreshGridUiComponent = {
                refreshKey: new Date().getTime(),
                uiData: result
            }
        }

        function refreshGridDatasource(currentGridFilter) {
            $timeout(() => {
                vm.forceDatamanagerFilter = {
                    refreshKey: new Date().getTime(),
                    filter: currentGridFilter
                }
            });
        }
    }

    reportDefinitionsList.$inject = injectParams;
    reportDefinitionsList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('reportDefinitionsList', reportDefinitionsList);
});