﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'drbacService', 'rbacService', 'vhreventService', 'notificationService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var drbacProcessDetail = function ($q, $scope, $timeout, $injector, drbacService, rbacService, vhreventService, notificationService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, drbacService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, drbacService, { dirtyFlagIsSuspended: vm.isDirtySuspended });

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var drbacProcessId = navigationService.stateParams().drbacProcessId;
        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;

        vm.vhrEventGroupsAcDs = new NbsMultiselect(vm.translate);
        vm.vhrEventTypesAcDs = new NbsMultiselect(vm.translate);

        // moduletargets
        vm.moduleTargets = contextService.virtualContext.ModuleTargets;

        if (drbacProcessId) {
            vm.setIsViewing();

            loadData(true);
        }
        else {
            // DrbacProcess cannot be added, can be only copied by another one
        }

        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getDrbacRoles(forceReload));
            funcsPromises.push(getDrbacProcess(forceReload, drbacProcessId, virtualCompanyId));
            funcsPromises.push(getNotificationSchemas(forceReload));
            funcsPromises.push(getDrbacProcessRoles(forceReload, drbacProcessId));

            funcsPromises.push(getDrbacProcessRoleM2MDrbacUserGroupDtosByProcess(forceReload, drbacProcessId, virtualCompanyId));
            funcsPromises.push(getDrbacProcessRoleM2MAppUserDtosByProcess(forceReload, drbacProcessId, virtualCompanyId));

            $q.all(funcsPromises)
                .then(function () {
                    var appStoreBw = vm.getAppStoreByModuleTargetId(contextService, vm.drbacProcess.ModuleTargetId);

                    setSelectedReceivers(vm.drbacProcessRoles);

                    if (vm.drbacProcess.IsGlobalProcess) {
                        //not have con getDrbacProcessConfig
                    }
                    else {
                        getDrbacProcessConfig(forceReload, vm.drbacProcess.DrbacProcessConfigId, virtualCompanyId)
                            .then(function (drbacProcessConfig) {
                                // filtered by DrbacProcess config moduletarget (appstorebw)
                                getVhrEventTypes(forceReload, appStoreBw);
                                getVhrEventGroups(forceReload, appStoreBw);

                                getVhrEventMatcher(forceReload, vm.drbacProcess.VhrEventMatcherId)
                                    .then(function (vhrEventMatcher) {
                                        //getVhrEventMatcherTargetActivities(forceReload, vhrEventMatcher.VhrEventMatcherId);
                                        getVhrEventMatcherTargetActivities_VhrEventTypes(forceReload, vhrEventMatcher.VhrEventMatcherId);
                                        getVhrEventMatcherTargetActivities_VhrEventGroups(forceReload, vhrEventMatcher.VhrEventMatcherId);
                                    });

                            });
                    }
                });
        }

        function setSelectedReceivers(processRoles) {
            vm.schemaNodificationDatasource = {};

            if (processRoles) {
                Lazy(processRoles)
                    .each((item) => {
                        item.assignmentObj = new NbsAssigneeSelectorModal({
                            translator: vm.translate,
                            suspendDirty: vm.suspendDirty,
                            navigationService: navigationService,
                            contextUserForSelection: null,
                            getServerVarFn: Nembus.Common.Library.server.getServerVar
                        });
                        item.assignmentObj.viewUsers();
                        item.assignmentObj.viewUserGroups();


                        var groupSel = Lazy(vm.drbacProcessRoleM2MDrbacUserGroups).where({ DrbacProcessRoleId: item.DrbacProcessRoleId }).toArray();
                        item.assignmentObj.setDrbacUserGroupItemsSelected(groupSel, 'DrbacUserGroupId', 'DrbacUserGroupName');

                        var userSel = Lazy(vm.drbacProcessRoleM2MAppUsers).where({ DrbacProcessRoleId: item.DrbacProcessRoleId }).toArray();
                        item.assignmentObj.setAppUserItemsSelected(userSel, 'AppUserId', 'DisplayName');


                        item.assignmentObj.setOnSelectFnCallback(function () {
                            Nembus.Rds.Library.SetBindingM2MEntitiesInContext(drbacService.dataContext.drbacProcessRoleM2MDrbacUserGroupDtos, 'DrbacProcessRoleId', [Number(item.DrbacProcessRoleId)], 'DrbacUserGroupId', item.assignmentObj.drbacUserGroupMultiselectSelectedIds());

                            Lazy(drbacService.dataContext.drbacProcessRoleM2MDrbacUserGroupDtos.get())
                                .each((groupM2M) => {
                                    if (groupM2M.VirtualCompanyId === 0 || !groupM2M.VirtualCompanyId) {
                                        groupM2M.VirtualCompanyId = virtualCompanyId;
                                    }
                                });

                            Nembus.Rds.Library.SetBindingM2MEntitiesInContext(drbacService.dataContext.drbacProcessRoleM2MAppUserDtos, 'DrbacProcessRoleId', [Number(item.DrbacProcessRoleId)], 'AppUserId', item.assignmentObj.appUserMultiselectSelectedIds());
                        });
                    });
            }
        }

        var parents = vm.submitAction;
        vm.submitAction = function () {
            setVhrEventMatcherM2M();

            parents().then(
                function (saveResult) {
                    vhreventService.saveChanges();
                }
            );
        };

        function setVhrEventMatcherM2M() {
            // VhrEventGroups
            Lazy(vm.vhrEventMatcherXVhrEventGroups).each((group) => {
                if (!Lazy(vm.vhrEventGroupsAcDs.selectedIds).contains(group.VhrEventGroupId)) {
                    group.entityAspect.setDeleted();
                }
            });
            Lazy(vm.vhrEventGroupsAcDs.selectedIds).each((vhrEventGroupId) => {
                var currentEntity = {
                    VhrEventGroupId: vhrEventGroupId,
                    VhrEventMatcherId: vm.vhrEventMatcher.VhrEventMatcherId
                };
                var currentM2M = Lazy(vm.vhrEventMatcherXVhrEventGroups).findWhere(currentEntity);
                if (currentM2M) {
                    if (currentM2M.entityAspect.entityState.isDeleted()) {
                        currentM2M.entityAspect.setUnchanged();
                    }
                } else {
                    var addedEntity = vhreventService.dataContext.vhrEventMatcherM2MTargetVhrEventGroupDto.add(currentEntity);
                    vm.vhrEventMatcherXVhrEventGroups.push(addedEntity);
                }
            });
            Lazy(vm.vhrEventMatcherXVhrEventGroups).each((m2m) => {
                if (m2m.entityAspect.entityState.isDeleted() || m2m.entityAspect.entityState.isDetached()) {
                    vm.vhrEventMatcherXVhrEventGroups = Lazy(vm.vhrEventMatcherXVhrEventGroups).reject(m2m).toArray();
                }
            });

            // VhrEventTypes
            Lazy(vm.vhrEventMatcherXVhrEventTypes).each((type) => {
                if (!Lazy(vm.vhrEventTypesAcDs.selectedIds).contains(type.VhrEventTypeId)) {
                    type.entityAspect.setDeleted();
                }
            });
            Lazy(vm.vhrEventTypesAcDs.selectedIds).each((typeId) => {
                var currentEntity = {
                    VhrEventTypeId: typeId,
                    VhrEventMatcherId: vm.vhrEventMatcher.VhrEventMatcherId
                };
                var currentM2M = Lazy(vm.vhrEventMatcherXVhrEventTypes).findWhere(currentEntity);
                if (currentM2M) {
                    if (currentM2M.entityAspect.entityState.isDeleted()) {
                        currentM2M.entityAspect.setUnchanged();
                    }
                } else {
                    var addedEntity = vhreventService.dataContext.vhrEventMatcherM2MTargetVhrEventTypeDto.add(currentEntity);
                    vm.vhrEventMatcherXVhrEventTypes.push(addedEntity);
                }
            });
            Lazy(vm.vhrEventMatcherXVhrEventTypes).each((m2m) => {
                if (m2m.entityAspect.entityState.isDeleted() || m2m.entityAspect.entityState.isDetached()) {
                    vm.vhrEventMatcherXVhrEventTypes = Lazy(vm.vhrEventMatcherXVhrEventTypes).reject(m2m).toArray();
                }
            });
        }

        function getVhrEventMatcher(forceReload, vhrEventMatcherId) {
            var defer = $q.defer();
            var filter = {
                VhrEventMatcherId: vhrEventMatcherId
            };

            vhreventService.getVhrEventMatcher(filter, forceReload)
                .then(function (data) {
                    vm.vhrEventMatcher = data.results[0];

                    defer.resolve(vm.vhrEventMatcher);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getDrbacProcess(forceReload, drbacProcessId, virtualCompanyId) {
            var filter = {
                DrbacProcessId: drbacProcessId,
                VirtualCompanyId: virtualCompanyId
            };
            var defer = $q.defer();

            drbacService.getDrbacProcess(filter, forceReload)
                .then(function (data) {
                    vm.drbacProcess = data.results[0];
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getDrbacProcessConfig(forceReload, drbacProcessConfigId, virtualCompanyId) {
            var defer = $q.defer();
            var filter = {
                DrbacProcessConfigId: drbacProcessConfigId,
                VirtualCompanyId: virtualCompanyId
            };

            drbacService.getDrbacProcessConfig(filter, forceReload)
                .then(function (data) {
                    vm.drbacProcessConfig = data.results[0];

                    defer.resolve(vm.drbacProcessConfig);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getDrbacProcessRoleM2MDrbacUserGroupDtosByProcess(forceReload, drbacProcessId, virtualCompanyId) {
            var filter = {
                VirtualCompanyId: virtualCompanyId,
                DrbacProcessId: drbacProcessId
            };
            var defer = $q.defer();

            drbacService.getDrbacProcessRoleM2MDrbacUserGroupDtosByProcess(filter, forceReload)
                .then(function (data) {
                    vm.drbacProcessRoleM2MDrbacUserGroups = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getDrbacProcessRoleM2MAppUserDtosByProcess(forceReload, drbacProcessId, virtualCompanyId) {
            var filter = {
                VirtualCompanyId: virtualCompanyId,
                DrbacProcessId: drbacProcessId
            };
            var defer = $q.defer();

            drbacService.getDrbacProcessRoleM2MAppUserDtosByProcess(filter, forceReload)
                .then(function (data) {
                    vm.drbacProcessRoleM2MAppUsers = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getDrbacRoles(forceReload) {
            var filter = {
                // get all
            };
            var defer = $q.defer();

            drbacService.getDrbacRoles(filter, forceReload)
                .then(function (data) {
                    vm.drbacRoles = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getDrbacProcessRoles(forceReload, drbacProcessId) {
            var filter = {
                DrbacProcessId: drbacProcessId
            };
            var defer = $q.defer();

            drbacService.getDrbacProcessRoles(filter, forceReload)
                .then(function (data) {
                    vm.drbacProcessRoles = Lazy(data.results).filter((item) => { return item.DrbacRole.IsProcessRole; }).toArray();
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrEventGroups(forceReload, appStoreBw) {
            var filter = {
                AppStoreBw: appStoreBw,
                IsBomGroup: false
            };
            var defer = $q.defer();

            vhreventService.getVhrEventGroups(filter, forceReload)
                .then(function (data) {
                    vm.vhrEventGroups = data.results;

                    vm.vhrEventGroupsAcDs.addData(vm.vhrEventGroups, 'VhrEventGroupId', 'VhrEventGroupName');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrEventTypes(forceReload, appStoreBw) {
            var filter = {
                AppStoreBw: appStoreBw,
                IsBomType: false
            };
            var defer = $q.defer();

            vhreventService.getVhrEventTypes(filter, forceReload)
                .then(function (data) {
                    vm.vhrEventTypes = data.results;

                    vm.vhrEventTypesAcDs.addData(vm.vhrEventTypes, 'VhrEventTypeId', 'VhrEventTypeName');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrEventMatcherTargetActivities_VhrEventGroups(forceReload, vhrEventMatcherId) {
            var defer = $q.defer();

            vhreventService.getVhrEventMatchersTargetActivities_VhrEventGroups({ VhrEventMatcherId: vhrEventMatcherId }, forceReload)
                .then(function (data) {
                    vm.vhrEventMatcherXVhrEventGroups = data.results;

                    var selectedVhrEventGroups = Lazy(vm.vhrEventMatcherXVhrEventGroups).pluck('VhrEventGroupId').toArray();
                    vm.vhrEventGroupsAcDs.setSelected(selectedVhrEventGroups);
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrEventMatcherTargetActivities_VhrEventTypes(forceReload, vhrEventMatcherId) {
            var defer = $q.defer();

            vhreventService.getVhrEventMatchersTargetActivities_VhrEventTypes({ VhrEventMatcherId: vhrEventMatcherId }, forceReload)
                .then(function (data) {
                    vm.vhrEventMatcherXVhrEventTypes = data.results;

                    var selectedVhrEventTypes = Lazy(vm.vhrEventMatcherXVhrEventTypes).pluck('VhrEventTypeId').toArray();
                    vm.vhrEventTypesAcDs.setSelected(selectedVhrEventTypes);

                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getNotificationSchemas(forceReload) {
            var filter = {
                IsGlobalNotificationSchema: false
            };
            var defer = $q.defer();

            notificationService.getNotificationSchemas(filter, forceReload)
                .then(function (data) {
                    vm.notificationSchemas = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    drbacProcessDetail.$inject = injectParams;
    drbacProcessDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rbac.module').controller('drbacProcessDetail', drbacProcessDetail);
});