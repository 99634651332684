﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'jobService', 'navigationService', 'checkStateChangeService', 'contextService', 'formSummaryService'];
    function scheduledTasksList($q, $scope, $timeout, $injector, jobService, navigationService, checkStateChangeService, contextService, formSummaryService) {

        var vm = this;
        nbsVm.call(vm, jobService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return scheduledTaskSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, jobService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        ribbon.addTab(ribbon.GetScheduledTaskTab());
        vm.ribbonDatacontext = ribbon;

        var viewFormSummary = navigationService.stateParams().viewFormSummary;
        var targetClassFullName = navigationService.stateParams().targetClassFullName;
        var targetEntityId = navigationService.stateParams().targetEntityId;
        var scheduledTaskStatus = Nembus.Common.Library.util.parseEnum(jobService.dataContext.enumTypes.ScheduledTaskStatus, 'ScheduledTask', vm.translate);
        var scheduledTaskStatusEnum = scheduledTaskStatus.obj.normal.name_value;
        var scheduledTaskStatusEnumForBind = scheduledTaskStatus.obj.translated.value_name;
        var scheduledTaskHistoryType = Nembus.Common.Library.util.parseEnum(jobService.dataContext.enumTypes.ScheduledTaskHistoryType, 'ScheduledTaskHistory', vm.translate);
        var scheduledTaskHistoryTypeEnum = scheduledTaskHistoryType.obj.normal.name_value;
        var scheduledTaskHistoryCommand = Nembus.Common.Library.util.parseEnum(jobService.dataContext.enumTypes.ScheduledTaskHistoryCommand, 'ScheduledTaskHistory', vm.translate);
        var scheduledTaskHistoryCommandEnum = scheduledTaskHistoryCommand.obj.normal.name_value;
        var scheduledTaskHistoryCommandEnumForBind = scheduledTaskHistoryCommand.obj.translated.value_name;
        var scheduledTaskSelected = null;

        ribbon.setRibbonStatusButton(ribbon.Buttons.New, false);
        ribbon.setRibbonStatusButton(ribbon.Buttons.Running, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.Suspend, true);
        ribbon.refreshStatusButtons();

        vm.statusEnum = Nembus.Common.Library.util.parseEnum(jobService.dataContext.enumTypes.ScheduledTaskStatus, null, vm.translate).obj.normal.name_value;
        vm.commandMonitor = "$:>";

        if (viewFormSummary == 'true') {
            vm.formSummaryIsVisible = viewFormSummary;

            // get array items for form summary
            vm.formSummary = formSummaryService.getDatasource();
        } else {
            vm.formSummaryIsVisible = 'false';
        }

        vm.currentScheduledTasks = [];

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('scheduledtask', null);
        nbsGridDatacontext.viewCommonBar();
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getScheduledTasks(false, targetClassFullName, targetEntityId));

            $q.all(funcsPromises)
                .then(function (results) {
                    Nembus.Common.Library.parser.AddCronExpressionLabelToList(results[0], 'CronExpression', vm.translate);
                    vm.currentScheduledTasks = results[0];

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.currentScheduledTasks
                    }
                });
        }

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.scheduledtaskdetail", { 'scheduledTaskId': args.data.ScheduledTaskId });
        };

        vm.refreshAction = function () {
            loadData(true);
        };

        vm.gridSelection = function (args) {
            $timeout(() => {
                scheduledTaskSelected = args.data;

                if (scheduledTaskSelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, true);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                }

                ribbon.refreshStatusButtons();
            });
        };

        function writeMonitor(line) {
            vm.commandMonitor += "<br>$:>" + line;
        }

        function clearMonitor() {
            vm.commandMonitor = "<br>$:>";
        }

        function getScheduledTasks(forceReload, targetClassFullName, targetEntityId) {
            var filter = {};
            var defer = $q.defer();

            jobService.getScheduledTasks(filter, forceReload)
                .then(function (data) {                    
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function submitScheduledTaskHistory(commandId) {
            var scheduledTaskHistory = jobService.dataContext.scheduledTaskHistories.add();
            scheduledTaskHistory.ScheduledTaskId = scheduledTaskSelected.ScheduledTaskId;
            scheduledTaskHistory.ScheduledTaskHistoryCommandId = commandId;
            scheduledTaskHistory.ScheduledTaskHistoryTypeId = scheduledTaskHistoryTypeEnum.TaskCommand;
            scheduledTaskHistory.ScheduledTaskHistoryCommandName = scheduledTaskHistoryCommandEnumForBind[commandId];

            return vm.submitAction()
                .then(
                    function (saveResult) {
                        clearMonitor();

                        vm.commandToCheck = {
                            taskHistoryCommand: scheduledTaskHistory,
                            numOfCheck: 1
                        };
                        vm.checkCommandResponse(scheduledTaskHistory.ScheduledTaskHistoryId);
                    }
                );
        }

        vm.checkCommandResponse = function (scheduledTaskHistoryId, t) {
            if (!t) {
                t = 1500;
            }
            $timeout(function () {
                var commandDescr = vm.commandToCheck.taskHistoryCommand.ScheduledTaskHistoryCommandName + ' (id: ' + vm.commandToCheck.taskHistoryCommand.ScheduledTaskId + ')';

                var maxCheck = 30;
                if (vm.commandToCheck.numOfCheck <= maxCheck
                    && !vm.commandToCheck.response) {

                    writeMonitor("Check command response. Attempt n: " + vm.commandToCheck.numOfCheck);
                    vm.commandToCheck.numOfCheck++;
                    jobService.getScheduledTaskHistory({ ScheduledTaskHistoryId: scheduledTaskHistoryId }, true)
                        .then(function (data) {
                            var response = data.results[0];
                            if (response.ScheduledTaskHistoryTypeId == scheduledTaskHistoryTypeEnum.TaskCommandExecuted) {
                                writeMonitor("Command '" + commandDescr + "' executed");
                                vm.refreshAction();
                            } else {
                                vm.checkCommandResponse(scheduledTaskHistoryId, t);
                            }

                        }).catch(function (error) {
                            vm.setErrorMsg(error);
                        });
                } else {
                    writeMonitor("Command '" + commandDescr + "' checking suspended");
                }
            }, t);
        };

        vm.enableAction = function () {
            if (!scheduledTaskSelected) {
                return;
            }

            submitScheduledTaskHistory(scheduledTaskHistoryCommandEnum.EnableTask);
        };

        vm.suspendAction = function () {
            if (!scheduledTaskSelected) {
                return;
            }

            submitScheduledTaskHistory(scheduledTaskHistoryCommandEnum.SuspendTask);
        };

        vm.deleteAction = function () {
            if (!scheduledTaskSelected) {
                return;
            }

            submitScheduledTaskHistory(scheduledTaskHistoryCommandEnum.DeleteTask);
        };

        // get reference of submitaction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parents = vm.submitAction;
        vm.submitAction = function () {
            return parents();
        };

        vm.queryCellInfo = function (args) {
            switch (args.column.field) {
                case "ScheduledTaskStatusId":
                    args.cell.innerText = scheduledTaskStatusEnumForBind[args.data.ScheduledTaskStatusId];

                    var color;
                    switch (args.data.ScheduledTaskStatusId) {
                        case scheduledTaskStatusEnum.TaskReady: color = '#95E495'; break;
                        case scheduledTaskStatusEnum.TaskStartProcessing: color = '#96BDE4'; break;
                        case scheduledTaskStatusEnum.TaskBusy: color = '#FFCC33'; break;
                        case scheduledTaskStatusEnum.TaskSuspended: color = '#FF8000'; break;
                    }

                    if (color) {
                        args.cell.bgColor = color;
                    }
                    break;

                case 'LastRunTime':
                case 'NextRunTime':
                    args.cell.innerHTML = Nembus.Common.Library.converter.date.formatDateTimeLocalToUtc(args.data[args.column.field], contextService.virtualContext.cultureInfo);
                    break;
            }
        };
    }

    scheduledTasksList.$inject = injectParams;
    scheduledTasksList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('scheduledTasksList', scheduledTasksList);
});