﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$timeout', '$injector', 'audService', 'navigationService', 'contextService'];
    function audDeveloperToolsDetail($q, $timeout, $injector, audService, navigationService, contextService) {

        var vm = this;
        nbsVm.call(vm, audService, navigationService, contextService, $q, $injector, $timeout);
        
        var ribbon = new NbsAudRibbon(navigationService, contextService.virtualContext);
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var appStoreCode = 'aud';
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var expressionTypeEnum = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.ExpressionType, null, vm.translate).obj.normal.name_value;
        var surveyAnalysisGroupModeObj = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyAnalysisGroupMode, null, vm.translate).obj.normal.name_value;

        vm.surveyAnalysisGroupModes = [
            { value: surveyAnalysisGroupModeObj.Survey, name: 'Analisi AUDIT' },
            { value: surveyAnalysisGroupModeObj.SurveySection, name: 'Analisi SEZIONE' },
            { value: null, name: 'Analisi AUDIT + SEZIONE' }
        ];

        vm.generateAnalysisFilter = {
            surveyFilter: {
                SurveyIds: [0] // no results
            },
            vhrEventFilter: {
                ModuleTargetId: moduleTargetId
            },
            surveyVhrEventFilter: {
                SurveyCompileDateSystem1Expression: expressionTypeEnum.GreaterThanOrEqual,
                SurveyCompileDateSystem1: null,
                SurveyCompileDateSystem2Expression: expressionTypeEnum.LessThanOrEqual,
                SurveyCompileDateSystem2: null
            },
            surveyAnalysisFilter: {
                SurveyAnalysisGroupModeId: null
            }
        };

        vm.synchroSurveyAnalysis = function () {
            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        if (vm.data && vm.data.SurveyIdsText) {
                            vm.generateAnalysisFilter.surveyFilter.SurveyIds = JSON.parse(vm.data.SurveyIdsText);
                        }

                        audService.synchroSurveyAnalysis(vm.generateAnalysisFilter);
                    }
                });
        };

        // generate actsourceobject for actions created not manually
        vm.syncroActSourceObjects = function () {
            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        audService.syncroActSourceObjects();
                    }
                });
        };

        vm.repairActionFilter = {
            StartDate1: new Date(),
            StartDate2: new Date(),
            ModuleTargetId: moduleTargetId
        };
        vm.repairActionGrouped = function () {
            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        audService.repairActionGrouped(vm.repairActionFilter);
                    }
                });
        };
    }

    audDeveloperToolsDetail.$inject = injectParams;
    audDeveloperToolsDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('audDeveloperToolsDetail', audDeveloperToolsDetail);
});