﻿'use strict';
function TriggerInstanceConditionManager(config) {
    if (!config.triggerService) throw new Error('TriggerService is mandatory');
    if (!config.triggerInstanceId) throw new Error('triggerInstanceId is mandatory');

    var me = this;
    var translator = config.translator;
    var triggerMatchTypeEnumParsed = Nembus.Common.Library.util.parseEnum(config.triggerService.dataContext.enumTypes.TriggerMatchType, 'TriggerInstanceMatch', translator);
    var triggerPropertyTypeEnumParsed = Nembus.Common.Library.util.parseEnum(config.triggerService.dataContext.enumTypes.TriggerPropertyType, 'TriggerDefinitionProperty', translator);
    var triggerMatchTypeObj = triggerMatchTypeEnumParsed.obj.normal.name_value;
    var triggerPropertyTypeObj = triggerPropertyTypeEnumParsed.obj.normal.name_value;
    var triggerPropertyTypeObj2 = triggerPropertyTypeEnumParsed.obj.translated.value_name;
    var triggerInstanceId = config.triggerInstanceId;
    var triggerDefinitionProperties = config.triggerDefinitionProperties;
    var userValues = config.userValues ? config.userValues : [];
    var userGroupValues = config.userGroupValues ? config.userGroupValues : [];
    var booleanValues = config.booleanValues ? config.booleanValues : [];
    var workflowStatusValues = config.workflowStatusValues ? config.workflowStatusValues : [];

    var triggerDefinitionCode = config.triggerDefinitionCode ? config.triggerDefinitionCode : '';

    var RESETFROM = {
        DEFINITIONPROPERTY: 1,
        MATCHTYPE: 2
    };

    Lazy(triggerDefinitionProperties)
        .each((dp) => {
            // add properties for dropdown visualization
            dp.name = getKeyTranslated(dp.PropertyName);
            dp.value = dp.TriggerDefinitionPropertyId;
        });

    me.sources = {
        valuesFromList: [],
        valuesToList: [],
        triggerDefinitionProperties: triggerDefinitionProperties,
        triggerInstanceMatchTypesDropDown: []
    };
    me.condition = {
        TriggerInstanceId: triggerInstanceId,
        IsOrMatch: null,
        PropertyName: null,
        TriggerDefinitionPropertyId: null,
        TriggerMatchTypeId: null,
        TriggerPropertyTypeId: null,
        Value: null,
        ValueFrom: null,
        ValueTo: null,
        ValueSpan: null,        // used to set E.g.: "{ months: 1 }"

        // property translated
        PropertyNameTranslated: null,
        TriggerPropertyTypeTranslated: null,
        TriggerMatchTypeTranslated: null,
        ValueFromTranslated: null,
        ValueToTranslated: null
    };

    me.visibility = {
        definitionPropertyIsVisible: true,
        matchInstanceTypeIsVisible: false,
        //valueFromIsVisible: false,
        //valueToIsVisible: false,
        valueListIsVisible: false,
        valueNumberIsVisible: false,
        valueTextIsVisible: false,
        valueDateSpanIsVisible: false,
        valueNumberUnitIsVisible: false
    };

    // events
    me.triggerDefinitionPropertyOnChange = function () {
        setTriggerDefinitionPropertyId(me.condition.TriggerDefinitionPropertyId);
    };

    me.triggerInstanceMatchTypeOnChange = function () {
        setTriggerMatchTypeId(me.condition.TriggerMatchTypeId, me.condition.TriggerDefinitionPropertyId);
    };
    //me.valueStatusFromOnChange = function () {
    //    setValueFrom(me.condition.ValueFrom);
    //};
    //me.valueStatusToOnChange = function () {
    //    setValueTo(me.condition.ValueTo);
    //};
    me.valueOnChange = function () {
        setValueTranslation(me.condition.Value);
    };
    me.valueSpanOnChange = function () {
        setValueSpan(me.condition.ValueSpan);
    };


    function getKeyTranslated(key, parentPropertyName) {
        var keyToTranslate = null;
        if (parentPropertyName === 'global') {
            return translator(key);
        } else if (parentPropertyName) {
            keyToTranslate = parentPropertyName + '.' + key;
        } else {
            keyToTranslate = triggerDefinitionCode + '.' + key;
        }

        if (config.thesaurus) {
            return Nembus.Common.Library.cms.getWordKeyFromThesaurus(config.thesaurus, keyToTranslate);
        } else {
            return translator(keyToTranslate);
        }
    }

    function setTriggerDefinitionPropertyId(triggerDefinitionPropertyId) {
        if (!triggerDefinitionPropertyId) throw new Error('setTriggerDefinitionPropertyId: triggerDefinitionPropertyId is null');

        var propertySelected = Lazy(me.sources.triggerDefinitionProperties).findWhere({ TriggerDefinitionPropertyId: triggerDefinitionPropertyId });
        if (propertySelected) {

            // store data
            me.condition.TriggerDefinitionPropertyId = triggerDefinitionPropertyId;
            me.condition.PropertyName = propertySelected.PropertyName;
            me.condition.PropertyNameTranslated = propertySelected.name;
            me.condition.TriggerPropertyTypeId = propertySelected.TriggerPropertyTypeId;
            me.condition.TriggerPropertyTypeTranslated = translator(triggerPropertyTypeObj2[propertySelected.TriggerPropertyTypeId]);


            /// create             |      StatusProperty                |   StatusTo
            /// update             |      StatusProperty                |   StatusTo,StatusFromTo
            /// create             |      NumberProperty                |   GreaterThan,GreaterThanOrEqual,LessThan,LessThanOrEqual,Equal,IsChanged
            /// update             |      NumberProperty                |   GreaterThan,GreaterThanOrEqual,LessThan,LessThanOrEqual,Equal,IsChanged,ThresholdFromLessToGreater,ThresholdFromGreaterToLess
            /// create,update      |      GenericValueProperty          |   IsChanged
            /// create,update      |      NavigationProperty            |   Contains
            /// create,update      |      UserIdProperty                |   Equal
            /// create,update      |      UserGroupIdProperty           |   Equal
            /// create,update      |      WorkflowStatusIdProperty      |   Equal


            setUILayout(RESETFROM.DEFINITIONPROPERTY);

            switch (propertySelected.TriggerPropertyTypeId) {
                // StatusProperty
                case Number(triggerPropertyTypeObj.StatusProperty):
                    Lazy(triggerMatchTypeEnumParsed.list.translated).each((mt) => {
                        if (mt.value === triggerMatchTypeObj.StatusTo
                            //|| mt.value === triggerMatchTypeObj.StatusFromTo
                        ) {
                            me.sources.triggerInstanceMatchTypesDropDown.push(mt);
                        }
                    });
                    break;

                // NumberProperty
                case Number(triggerPropertyTypeObj.NumberProperty):
                    Lazy(triggerMatchTypeEnumParsed.list.translated).each((mt) => {
                        if (mt.value === triggerMatchTypeObj.GreaterThan
                            || mt.value === triggerMatchTypeObj.GreaterThanOrEqual
                            || mt.value === triggerMatchTypeObj.LessThan
                            || mt.value === triggerMatchTypeObj.LessThanOrEqual
                            || mt.value === triggerMatchTypeObj.Equal
                            || mt.value === triggerMatchTypeObj.NotEqual
                            //|| mt.value === triggerMatchTypeObj.IsChanged
                            //|| mt.value === triggerMatchTypeObj.ThresholdFromLessToGreater
                            //|| mt.value === triggerMatchTypeObj.ThresholdFromGreaterToLess
                        ) {

                            me.sources.triggerInstanceMatchTypesDropDown.push(mt);
                        }
                    });
                    break;

                // GenericValueProperty
                case Number(triggerPropertyTypeObj.GenericValueProperty):
                    Lazy(triggerMatchTypeEnumParsed.list.translated).each((mt) => {
                        if (mt.value === triggerMatchTypeObj.IsChanged) {
                            //me.sources.triggerInstanceMatchTypesDropDown.push(mt);
                        }
                    });
                    break;

                // RelatedEntityIdProperty
                case Number(triggerPropertyTypeObj.RelatedEntityIdProperty):
                    Lazy(triggerMatchTypeEnumParsed.list.translated).each((mt) => {
                        if (mt.value === triggerMatchTypeObj.Contains || mt.value === triggerMatchTypeObj.NotEqual || mt.value === triggerMatchTypeObj.Equal) {

                            me.sources.triggerInstanceMatchTypesDropDown.push(mt);
                        }
                    });
                    break;

                // DatetimeProperty
                case Number(triggerPropertyTypeObj.DatetimeProperty):
                    Lazy(triggerMatchTypeEnumParsed.list.translated).each((mt) => {
                        if (mt.value === triggerMatchTypeObj.SpanBefore
                            || mt.value === triggerMatchTypeObj.SpanAfter) {

                            me.sources.triggerInstanceMatchTypesDropDown.push(mt);
                        }
                    });
                    break;

                // UserIdProperty
                case Number(triggerPropertyTypeObj.UserIdProperty):
                    Lazy(triggerMatchTypeEnumParsed.list.translated).each((mt) => {
                        if (mt.value === triggerMatchTypeObj.Equal) {
                            me.sources.triggerInstanceMatchTypesDropDown.push(mt);
                        }
                    });
                    break;

                // UserGroupIdProperty
                case Number(triggerPropertyTypeObj.UserGroupIdProperty):
                    Lazy(triggerMatchTypeEnumParsed.list.translated).each((mt) => {
                        if (mt.value === triggerMatchTypeObj.Equal) {
                            me.sources.triggerInstanceMatchTypesDropDown.push(mt);
                        }
                    });
                    break;

                // WorkflowStatusIdProperty
                case Number(triggerPropertyTypeObj.WorkflowStatusIdProperty):
                    Lazy(triggerMatchTypeEnumParsed.list.translated).each((mt) => {
                        if (mt.value === triggerMatchTypeObj.Equal) {
                            me.sources.triggerInstanceMatchTypesDropDown.push(mt);
                        }
                    });
                    break;

                // BooleanProperty
                case Number(triggerPropertyTypeObj.BooleanProperty):
                    Lazy(triggerMatchTypeEnumParsed.list.translated).each((mt) => {
                        if (mt.value === triggerMatchTypeObj.StatusTo
                            //|| mt.value === triggerMatchTypeObj.StatusFromTo
                        ) {
                            me.sources.triggerInstanceMatchTypesDropDown.push(mt);
                        }
                    });
                    break;
            }

            me.visibility.matchInstanceTypeIsVisible = true;
        }
    }

    function setTriggerMatchTypeId(triggerMatchTypeId, triggerDefinitionPropertyId) {
        if (!triggerMatchTypeId) throw new Error('setTriggerMatchTypeId: triggerMatchTypeId is null');

        //StatusTo:		Views a dropdown to select status destination
        //StatusFromTo:
        //Contains:
        //Equal:
        //GreaterThan:
        //GreaterThanOrEqual:
        //IsChanged:
        //LessThan:
        //LessThanOrEqual:
        //NotEqual:
        //ThresholdFromGreaterToLess:
        //ThresholdFromLessToGreater:
        //SpanBefore:
        //SpanAfter:

        setUILayout(RESETFROM.MATCHTYPE);

        // store data
        me.condition.TriggerMatchTypeId = triggerMatchTypeId;

        // set current condition match translation
        var currTriggerMatchType = Lazy(triggerMatchTypeEnumParsed.list.translated).findWhere({ value: triggerMatchTypeId });
        me.condition.TriggerMatchTypeTranslated = currTriggerMatchType ? currTriggerMatchType.name : null;

        var triggerDefinitionProperty = Lazy(me.sources.triggerDefinitionProperties).findWhere({ TriggerDefinitionPropertyId: triggerDefinitionPropertyId });
        if (triggerDefinitionProperty) {
            if (triggerDefinitionProperty.TriggerPropertyTypeId === Number(triggerPropertyTypeObj.UserIdProperty)) {
                // user
                me.sources.valuesList = userValues;
                me.visibility.valueListIsVisible = true;
                return;
            } else if (triggerDefinitionProperty.TriggerPropertyTypeId === Number(triggerPropertyTypeObj.UserGroupIdProperty)) {
                // usergroup
                me.sources.valuesList = userGroupValues;
                me.visibility.valueListIsVisible = true;
                return;
            } else if (triggerDefinitionProperty.TriggerPropertyTypeId === Number(triggerPropertyTypeObj.BooleanProperty)) {
                // boolean (true/false)
                me.sources.valuesList = booleanValues;
                me.visibility.valueListIsVisible = true;
                return;
            } else if (triggerDefinitionProperty.TriggerPropertyTypeId === Number(triggerPropertyTypeObj.WorkflowStatusIdProperty)) {
                // workflow statuses
                me.sources.valuesList = workflowStatusValues;
                me.visibility.valueListIsVisible = true;
                return;
            }

            var propertyValuesList = [];
            var propertyValuesObj = null;
            if (triggerDefinitionProperty.PropertyValues) {
                propertyValuesObj = JSON.parse(Nembus.Common.Library.converter.decodeHtml(triggerDefinitionProperty.PropertyValues));
                propertyValuesList = nameValueListFromObj(propertyValuesObj);
                propertyValuesList = Lazy(propertyValuesList)
                    .reject({ value: 0 })			// None
                    .toArray();

                Lazy(propertyValuesList).each((p) => {
                    p.name = getKeyTranslated(p.name, triggerDefinitionProperty.ParentPropertyName);
                });
            }

            switch (triggerMatchTypeId) {
                case triggerMatchTypeObj.StatusTo:
                    me.sources.valuesList = Lazy(propertyValuesList).toArray();
                    me.visibility.valueListIsVisible = true;
                    break;

                //case triggerMatchTypeObj.StatusFromTo:
                //    me.sources.valuesFromList = Lazy(propertyValuesList).toArray();
                //    me.sources.valuesToList = Lazy(propertyValuesList).toArray();
                //    me.visibility.valueFromIsVisible = true;
                //    me.visibility.valueToIsVisible = true;
                //    break;

                case triggerMatchTypeObj.GreaterThan:
                case triggerMatchTypeObj.GreaterThanOrEqual:
                case triggerMatchTypeObj.LessThan:
                case triggerMatchTypeObj.LessThanOrEqual:
                case triggerMatchTypeObj.Equal:
                case triggerMatchTypeObj.NotEqual:
                case triggerMatchTypeObj.ThresholdFromGreaterToLess:
                case triggerMatchTypeObj.ThresholdFromLessToGreater:
                    me.visibility.valueNumberIsVisible = true;
                    break;
                case triggerMatchTypeObj.IsChanged:
                    break;
                case triggerMatchTypeObj.Contains:
                    me.visibility.valueTextIsVisible = true;
                    break;
                case triggerMatchTypeObj.SpanAfter:
                case triggerMatchTypeObj.SpanBefore:
                    me.sources.valuesList = Lazy(propertyValuesList).toArray();
                    me.visibility.valueDateSpanIsVisible = true;
                    break;
            }
        }
    }

    function setUILayout(from) {
        if (from === RESETFROM.DEFINITIONPROPERTY) {
            // conditions
            me.condition.TriggerMatchTypeId = null;
            me.condition.TriggerMatchTypeTranslated = null;

            // sources
            //me.sources.valuesFromList = [];
            //me.sources.valuesToList = [];
            me.sources.triggerInstanceMatchTypesDropDown = [];

            // visibility
            me.visibility.matchInstanceTypeIsVisible = false;
            //me.visibility.valueFromIsVisible = false;
            //me.visibility.valueToIsVisible = false;
            me.visibility.valueListIsVisible = false;
            me.visibility.valueNumberIsVisible = false;
            me.visibility.valueTextIsVisible = false;
            me.visibility.valueDateSpanIsVisible = false;
            me.visibility.valueNumberUnitIsVisible = false;
        }

        if (from === RESETFROM.MATCHTYPE) {
            // conditions
            me.condition.Value = (me.condition.TriggerPropertyTypeId === Number(triggerPropertyTypeObj.NumberProperty) || me.condition.TriggerPropertyTypeId === Number(triggerPropertyTypeObj.DatetimeProperty))
                && me.condition.TriggerMatchTypeId !== triggerMatchTypeObj.IsChanged ? 0 : null;
            me.condition.ValueFrom = null;
            me.condition.ValueTo = null;
            me.condition.ValueSpan = null;
            me.condition.ValueFromTranslated = null;
            me.condition.ValueToTranslated = null;

            // sources
            me.sources.valuesFromList = [];
            me.sources.valuesToList = [];
            me.sources.valuesList = [];


            // visibility
            //me.visibility.valueFromIsVisible = false;
            //me.visibility.valueToIsVisible = false;
            me.visibility.valueListIsVisible = false;
            me.visibility.valueNumberIsVisible = false;
            me.visibility.valueTextIsVisible = false;
            me.visibility.valueDateSpanIsVisible = false;
            me.visibility.valueNumberUnitIsVisible = false;
        }
    }

    me.setIsOrMatch = function (isOrMatch) {
        me.condition.IsOrMatch = isOrMatch;
    };

    me.getError = function () {
        // TODO: manage error message with specifically message
        var hasError = false;

        if (!me.condition.TriggerInstanceId) {
            hasError = true;
        }

        if (!me.condition.PropertyName
            || !me.condition.TriggerDefinitionPropertyId
            || !me.condition.TriggerMatchTypeId
            || !me.condition.TriggerPropertyTypeId) {

            hasError = true;
        } else if (me.condition.TriggerPropertyTypeId === Number(triggerPropertyTypeObj.StatusProperty)) {
            if (me.condition.TriggerMatchTypeId === triggerMatchTypeObj.StatusTo
                && !me.condition.Value) {

                hasError = true;
            }
            //else if (me.condition.TriggerMatchTypeId === triggerMatchTypeObj.StatusFromTo
            //    && (!me.condition.ValueTo || !me.condition.ValueFrom)) {

            //    hasError = true;
            //} else if (me.condition.TriggerMatchTypeId === triggerMatchTypeObj.StatusFromTo
            //    && me.condition.ValueFrom === me.condition.ValueTo) {

            //    hasError = true;
            //}
        } else if (me.condition.TriggerPropertyTypeId === Number(triggerPropertyTypeObj.NumberProperty)) {
            if ((me.condition.TriggerMatchTypeId === triggerMatchTypeObj.GreaterThan
                || me.condition.TriggerMatchTypeId === triggerMatchTypeObj.GreaterThanOrEqual
                || me.condition.TriggerMatchTypeId === triggerMatchTypeObj.LessThan
                || me.condition.TriggerMatchTypeId === triggerMatchTypeObj.LessThanOrEqual
                || me.condition.TriggerMatchTypeId === triggerMatchTypeObj.Equal
                || me.condition.TriggerMatchTypeId === triggerMatchTypeObj.NotEqual
                || me.condition.TriggerMatchTypeId === triggerMatchTypeObj.ThresholdFromGreaterToLess
                || me.condition.TriggerMatchTypeId === triggerMatchTypeObj.ThresholdFromLessToGreater)
                && (me.condition.Value === undefined || me.condition.Value === null)) {

                hasError = true;
            }
        } else if (me.condition.TriggerPropertyTypeId === Number(triggerPropertyTypeObj.RelatedEntityIdProperty)
            && !me.condition.Value) {
            hasError = true;
        } else if (me.condition.TriggerPropertyTypeId === Number(triggerPropertyTypeObj.DatetimeProperty)) {
            if (me.condition.Value === undefined
                || me.condition.Value === null
                || !me.condition.ValueSpan) {

                hasError = true;
            }
        } else if (me.condition.TriggerPropertyTypeId === Number(triggerPropertyTypeObj.UserIdProperty)
            && !me.condition.Value) {

            hasError = true;
        } else if (me.condition.TriggerPropertyTypeId === Number(triggerPropertyTypeObj.UserGroupIdProperty)
            && !me.condition.Value) {

            hasError = true;
        } else if (me.condition.TriggerPropertyTypeId === Number(triggerPropertyTypeObj.WorkflowStatusIdProperty)
            && !me.condition.Value) {

            hasError = true;
        } else if (me.condition.TriggerPropertyTypeId === Number(triggerPropertyTypeObj.BooleanProperty)
            && (me.condition.Value === null || me.condition.Value === undefined)) {

            hasError = true;
        }

        return {
            hasError: hasError,
            message: hasError ? translator('ValidationErrorsHaveOccurred') : null
        };
    };

    function setValue(value) {
        me.condition.Value = value;
    }

    function setValueTranslation(value) {
        me.condition.Value = value;

        var valueStatus = null;
        // set translation
        if (me.condition.TriggerPropertyTypeId === Number(triggerPropertyTypeObj.BooleanProperty)) {
            if (me.condition.Value === true || me.condition.Value === false) {
                valueStatus = Lazy(me.sources.valuesList).findWhere({ value: value });
                me.condition.ValueTranslated = valueStatus ? valueStatus.name : null;
                me.condition.ValueDescription = me.condition.ValueTranslated;                
            }
        } else {
            if (me.condition.Value) {
                valueStatus = Lazy(me.sources.valuesList).findWhere({ value: Number(value) });
                me.condition.ValueTranslated = valueStatus ? valueStatus.name : null;

                if (me.condition.TriggerPropertyTypeId === Number(triggerPropertyTypeObj.UserIdProperty)
                    || me.condition.TriggerPropertyTypeId === Number(triggerPropertyTypeObj.UserGroupIdProperty)
                    || me.condition.TriggerPropertyTypeId === Number(triggerPropertyTypeObj.WorkflowStatusIdProperty)) {

                    me.condition.ValueDescription = me.condition.ValueTranslated;
                }
            }
        }
    }

    function setValueFrom(value) {
        me.condition.ValueFrom = value;

        // set translation
        if (me.condition.ValueFrom) {
            var valueFrom = Lazy(me.sources.valuesFromList).findWhere({ value: Number(value) });
            me.condition.ValueFromTranslated = valueFrom ? valueFrom.name : null;
        }
    }

    function setValueTo(value) {
        me.condition.ValueTo = value;

        // set translation
        if (me.condition.ValueTo) {
            var valueTo = Lazy(me.sources.valuesToList).findWhere({ value: Number(value) });
            me.condition.ValueToTranslated = valueTo ? valueTo.name : null;
        }
    }

    function setValueSpan(value) {
        me.condition.ValueSpan = value;

        // set translation
        if (me.condition.ValueSpan) {
            var valueSpan = Lazy(me.sources.valuesList).findWhere({ value: Number(value) });
            me.condition.ValueSpanTranslated = valueSpan ? valueSpan.name : null;
        }
    }

    function nameValueListFromObj (obj, fnTranslator, entityName) {
        var result = [];

        Lazy(Object.keys(obj)).each((property) => {
            var nameTranslated = null;
            var keytoTranslate;
            if (entityName) {
                keytoTranslate = entityName + '.' + property;
            }
            else {
                keytoTranslate = property;
            }
            if (fnTranslator) {
                nameTranslated = fnTranslator(keytoTranslate);
            }
            else {
                nameTranslated = keytoTranslate;
            }

            result.push(
                {
                    name: nameTranslated,
                    value: obj[property]
                }
            );
        });

        return result;
    };
}