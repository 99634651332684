﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'drbacService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var drbacProcessGlobalDetail = function ($q, $scope, $timeout, $injector, drbacService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, drbacService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, drbacService, { dirtyFlagIsSuspended: vm.isDirtySuspended });

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var drbacProcessId = navigationService.stateParams().drbacProcessId;
        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;

        if (drbacProcessId) {
            vm.setIsViewing();

            loadData(true);
        }
        else {
            // DrbacProcess cannot be added, can be only copied by another one
        }

        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getDrbacProcess(forceReload, drbacProcessId, virtualCompanyId));
            funcsPromises.push(getDrbacRoles(forceReload));
            funcsPromises.push(getDrbacProcessRoles(forceReload, drbacProcessId));
            funcsPromises.push(getDrbacProcessRoleM2MDrbacUserGroupDtosByProcess(forceReload, drbacProcessId, virtualCompanyId));
            funcsPromises.push(getDrbacProcessRoleM2MAppUserDtosByProcess(forceReload, drbacProcessId, virtualCompanyId));

            $q.all(funcsPromises)
                .then(function () {
                    setSelectedReceivers(vm.drbacProcessRoles);
                });
        }

        function setSelectedReceivers(processRoles) {
            vm.schemaNodificationDatasource = {};

            if (processRoles) {
                Lazy(processRoles)
                    .each((item) => {
                        item.assignmentObj = new NbsAssigneeSelectorModal({
                            translator: vm.translate,
                            suspendDirty: vm.suspendDirty,
                            navigationService: navigationService,
                            contextUserForSelection: null,
                            getServerVarFn: Nembus.Common.Library.server.getServerVar
                        });
                        item.assignmentObj.viewUsers();
                        item.assignmentObj.viewUserGroups();

                        var groupSel = Lazy(vm.drbacProcessRoleM2MDrbacUserGroups).where({ DrbacProcessRoleId: item.DrbacProcessRoleId }).toArray();
                        item.assignmentObj.setDrbacUserGroupItemsSelected(groupSel, 'DrbacUserGroupId', 'DrbacUserGroupName');

                        var userSel = Lazy(vm.drbacProcessRoleM2MAppUsers).where({ DrbacProcessRoleId: item.DrbacProcessRoleId }).toArray();
                        item.assignmentObj.setAppUserItemsSelected(userSel, 'AppUserId', 'DisplayName');

                        
                        item.assignmentObj.setOnSelectFnCallback(function () {
                            Nembus.Rds.Library.SetBindingM2MEntitiesInContext(drbacService.dataContext.drbacProcessRoleM2MDrbacUserGroupDtos, 'DrbacProcessRoleId', [Number(item.DrbacProcessRoleId)], 'DrbacUserGroupId', item.assignmentObj.drbacUserGroupMultiselectSelectedIds());

                            Lazy(drbacService.dataContext.drbacProcessRoleM2MDrbacUserGroupDtos.get())
                                .each((groupM2M) => {
                                    if (groupM2M.VirtualCompanyId === 0 || !groupM2M.VirtualCompanyId) {
                                        groupM2M.VirtualCompanyId = virtualCompanyId;
                                    }
                                });

                            Nembus.Rds.Library.SetBindingM2MEntitiesInContext(drbacService.dataContext.drbacProcessRoleM2MAppUserDtos, 'DrbacProcessRoleId', [Number(item.DrbacProcessRoleId)], 'AppUserId', item.assignmentObj.appUserMultiselectSelectedIds());
                        });
                    });
            }
        }

        function getDrbacProcess(forceReload, drbacProcessId, virtualCompanyId) {
            var filter = {
                DrbacProcessId: drbacProcessId,
                VirtualCompanyId: virtualCompanyId
            };
            var defer = $q.defer();

            drbacService.getDrbacProcess(filter, forceReload)
                .then(function (data) {
                    vm.drbacProcess = data.results[0];
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getDrbacProcessRoleM2MDrbacUserGroupDtosByProcess(forceReload, drbacProcessId, virtualCompanyId) {
            var filter = {
                VirtualCompanyId: virtualCompanyId,
                DrbacProcessId: drbacProcessId
            };
            var defer = $q.defer();

            drbacService.getDrbacProcessRoleM2MDrbacUserGroupDtosByProcess(filter, forceReload)
                .then(function (data) {
                    vm.drbacProcessRoleM2MDrbacUserGroups = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getDrbacProcessRoleM2MAppUserDtosByProcess(forceReload, drbacProcessId, virtualCompanyId) {
            var filter = {
                VirtualCompanyId: virtualCompanyId,
                DrbacProcessId: drbacProcessId
            };
            var defer = $q.defer();

            drbacService.getDrbacProcessRoleM2MAppUserDtosByProcess(filter, forceReload)
                .then(function (data) {
                    vm.drbacProcessRoleM2MAppUsers = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getDrbacRoles(forceReload) {
            var filter = {
                // get all
            };
            var defer = $q.defer();

            drbacService.getDrbacRoles(filter, forceReload)
                .then(function (data) {
                    vm.drbacRoles = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getDrbacProcessRoles(forceReload, drbacProcessId) {
            var filter = {
                DrbacProcessId: drbacProcessId
            };
            var defer = $q.defer();

            drbacService.getDrbacProcessRoles(filter, forceReload)
                .then(function (data) {
                    vm.drbacProcessRoles = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }        
    };

    drbacProcessGlobalDetail.$inject = injectParams;
    drbacProcessGlobalDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rbac.module').controller('drbacProcessGlobalDetail', drbacProcessGlobalDetail);
});