﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'activityService', 'utilService', 'bomService', 'productionService', 'vhreventService', 'navigationService', 'checkStateChangeService', 'contextService', 'cmsService'];
    var activityDetail = function ($q, $scope, $timeout, $injector, activityService, utilService, bomService, productionService, vhreventService, navigationService, checkStateChangeService, contextService, cmsService) {
        var vm = this;

        var viewKind = navigationService.stateParams().viewKind;
        vm.isBom = viewKind === 'Bom';
        vm.notIsBom = !vm.isBom;
        var service = vm.isBom ? bomService : activityService;
        nbsVm.call(vm, service, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, service, { dirtyFlagIsSuspended: vm.isDirtySuspended });

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var activityKindEnum = Nembus.Common.Library.util.parseEnum(activityService.dataContext.enumTypes.ActivityKind, null, vm.translate).obj.normal.name_value;
        var activityKindId = activityKindEnum[viewKind];
        var routeSchedulingTypeEnumParsed = Nembus.Common.Library.util.parseEnum(service.dataContext.enumTypes.RouteSchedulingType, null, vm.translate);
        var routeSchedulingTypeObj = routeSchedulingTypeEnumParsed.obj.normal.name_value;
        var currentActivityId = navigationService.stateParams().activityId;
        var vhrEventTypeId = navigationService.stateParams().vhrEventTypeId ? navigationService.stateParams().vhrEventTypeId : null;
        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var vhrEventKindEnum = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventKind, null, vm.translate).obj.normal.name_value;
        var vhrEventKindId = vm.isBom ? vhrEventKindEnum.Container : vhrEventKindEnum.Data;
        var dateIntervalsEnumParsed = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.DateInterval_dateMode, null, vm.translate);

        //translated list for dropdown
        var effortUnitParsedEnum = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.EffortUnit, null, vm.translate);
        vm.effortUnits = effortUnitParsedEnum.list.translated;
        vm.effortUnitObjForBind = effortUnitParsedEnum.obj.translated.value_name;

        vm.dateIntervals = dateIntervalsEnumParsed.list.translated;

        var bomHierarchyModeEnumParsed = Nembus.Common.Library.util.parseEnum(bomService.dataContext.enumTypes.BomHierarchyMode, 'Activity', vm.translate);
        var bomHierarchyModeEnumObj = bomHierarchyModeEnumParsed.obj.normal.name_value;
        vm.bomHierarchyModes = bomHierarchyModeEnumParsed.list.translated;

        var nbsGridDatacontext = null;
        if (vm.isBom) {
            nbsGridDatacontext = new NbsGrid(vm.translate);
            nbsGridDatacontext.configureGrid('dependentactivity', 'bom');
        } else {
            nbsGridDatacontext = new NbsGrid(vm.translate);
            nbsGridDatacontext.configureGrid('dependentactivity', 'subbom');
        }
        nbsGridDatacontext.allowPaging = false;
        nbsGridDatacontext.allowSearching = false;
        nbsGridDatacontext.allowGrouping = false;
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;


        vm.dependActivityAreVisible = false;
        vm.currentDependActivity = [];

        vm.vhrEventGroups = [];
        vm.vhrEventTypes = [];
        vm.schedulingGroups = [];


        // add/remove validation for Activity entity (bom/subbom) in current service
        service.addClientValidation();
        service.removeClientValidation();


        var moduleTargets = Nembus.Rds.Library.Tools.ModuleTargetContext({ AppStoreBw: appStoreBw, ModuleTargetTypeCode: 'DEFAULT' }, contextService);
        vm.moduleTargetsAll = Lazy(moduleTargets).reject({ ModuleTargetCode: 'MTSEM' }).toArray();

        if (currentActivityId) {
            vm.setIsViewing();

            loadData(true);
            vm.dependActivityAreVisible = true;
        } else {
            vm.setIsAdding();

            vm.currentActivity = service.dataContext.activities.add({
                ActivityKindId: activityKindId,
                BomHierarchyModeId: vm.isBom ? 1 : bomHierarchyModeEnumObj.DirectBom,
                VhrEventTypeId: null,
                VhrEventGroupId: null,
                ModuleTargetId: null,
                VhrEventTemplateId: null,
                ActivityEffortQuota: null,
                ActivityEffortUnitId: null,
                SpanValidityQuota: null,
                SpanValidityUnitId: null,
                IsPeriodical: true,
                VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId,
                ActivityTheoreticalTime: 0,
                ActivityTheoreticalExecTime: 0
            });

            if (vm.isBom) {
                getFrequencyCodes(true);
            }
        }

        function loadData(forceReload) {
            //NB:
            //model templates, groups, types and scheduling groups needs 
            //vm.currentActivity.ModuleTargetId for filtering then they needs
            //first load of activity detail

            var funcsPromises = [];
            funcsPromises.push(getActivity(forceReload, currentActivityId, activityKindId, vhrEventTypeId, appStoreBw));
            funcsPromises.push(getDependentActivityPaths(forceReload, currentActivityId, activityKindId, vhrEventTypeId, appStoreBw));
            if (vm.isBom) {
                funcsPromises.push(getFrequencyCodes(forceReload));
            }

            $q.all(funcsPromises)
                .then(function () {
                    loadModuleTargetDependentData(forceReload, vm.currentActivity.ModuleTargetId, appStoreBw, vm.isBom);
                });
        }

        vm.newAction = function () {
            var services = { navigationService: navigationService, activityService: activityService, cmsService: cmsService };
            var wzNbsManager = new NbsWizardManager($q, vm.translate, services, NbsGuidManager);
            var config = new wzNbsManager.WzConfig_AddDependentActivity(null);
            wzNbsManager.setWzConfig(config);

            wzNbsManager.open()
                .then(function (result) {
                    var wizardDependActivityCollector = result;
                    setCurrentDependentActivities(wizardDependActivityCollector);
                });
        };

        // get reference of modifyAction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parentm = vm.modifyAction;
        vm.modifyAction = function () {
            parentm().then(
                //enable disable button new & delete because is custom behavior
                //generally saveResult eneble or disable only save button 
                function (saveResult) {
                    if (vm.viewContext.isEditingState) {
                        ribbon.setRibbonStatusButton(ribbon.Buttons.New, true);
                        ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, true);
                    } else {
                        ribbon.setRibbonStatusButton(ribbon.Buttons.New, false);
                        ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                    }

                    ribbon.refreshStatusButtons();
                }
            );
        };

        vm.deleteAction = function () {
            if (!vm.dependentActivitySelected)
                return;

            service.deleteEntity(vm.dependentActivitySelected);
            vm.currentDependActivity = Lazy(vm.currentDependActivity)
                .reject({ DependentActivityId: vm.dependentActivitySelected.DependentActivityId })
                .toArray();

            vm.forceDatasourceLocal = {
                refreshkey: new Date().getTime(),
                dataSource: vm.currentDependActivity
            }
        };

        vm.gridSelection = function (args) {
            vm.dependentActivitySelected = args.data;
        };

        vm.rowDblClick = function (args) {
            var forceReload = false;
            var funcsPromises = [];
            funcsPromises.push(getWorkCenterGroups(forceReload));
            funcsPromises.push(getVirtualWorkCenters(forceReload));

            $q.all(funcsPromises)
                .then(function () {
                    vm.modal = {
                        instanceName: 'modalInstance',
                        title: vm.translate('Activities'),
                        datasource: vm.dependentActivitySelected
                    };

                    vm.modalInstance = navigationService.modal().open(vm.modalOptions({
                        windowClass: vm.NBS_MODAL_CLASS_FORM_MD,
                        templateUrl: 'dependentActivityTemplate',
                        scope: $scope
                    }));
                    vm.modalInstance.result.then(function () {
                        //ok
                        setDependentActivityProperties();
                    }, function () {
                        //dismiss
                        service.rejectChanges();
                    });
                    vm.modalInstance.ok = function () { vm.modalInstance.close(); };
                    vm.modalInstance.cancel = function () { vm.modalInstance.dismiss(); };
                });
        };

        vm.isPeriodicalOnChange = function () {
            vm.currentActivity.ActivityFrequencyCode = vm.currentActivity.IsPeriodical ? null : '-';
        };

        function getWorkCenterGroups(forceReload) {
            var filter = { WorkCenterGroupSystemCode: 'WKC' };
            var defer = $q.defer();

            productionService.getWorkCenterGroups(filter, forceReload)
                .then(function (data) {
                    vm.workCenterGroups = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVirtualWorkCenters(forceReload) {
            var filter = {};
            var defer = $q.defer();

            productionService.getVirtualWorkCenters(filter, forceReload)
                .then(function (data) {
                    vm.workCenters = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function setDependentActivityProperties() {
            //activityPathClassificationBw
            vm.dependentActivitySelected.ActivityPathClassificationBw = 0;
            if (vm.dependentActivitySelected.DependActivityIsSingle) {
                vm.dependentActivitySelected.ActivityPathClassificationBw += routeSchedulingTypeObj.Once;
            }
            if (vm.dependentActivitySelected.DependActivityIsPeriodical) {
                vm.dependentActivitySelected.ActivityPathClassificationBw += routeSchedulingTypeObj.Periodical;
            }

            //workCenterGroupName
            if (vm.dependentActivitySelected.WorkCenterGroupId) {
                vm.dependentActivitySelected.WorkCenterGroupName = Lazy(vm.workCenterGroups).findWhere({ WorkCenterGroupId: vm.dependentActivitySelected.WorkCenterGroupId }).WorkCenterGroupName;
            } else {
                vm.dependentActivitySelected.WorkCenterGroupName = null;
            }

            //workCenterName
            if (vm.dependentActivitySelected.VirtualWorkCenterId) {
                vm.dependentActivitySelected.VirtualWorkCenterName = Lazy(vm.workCenters).findWhere({ VirtualWorkCenterId: vm.dependentActivitySelected.VirtualWorkCenterId }).WorkCenterName;
            } else {
                vm.dependentActivitySelected.VirtualWorkCenterName = null;
            }

            vm.forceDatasourceLocal = {
                refreshkey: new Date().getTime(),
                dataSource: vm.currentDependActivity
            }
        };

        function setCurrentDependentActivities(wizardDependActivityCollector) {
            Lazy(wizardDependActivityCollector.activities)
                .each((activity) => {
                    var existDependentActivity = Lazy(vm.currentDependActivity).where({ DependentActivityId: activity.ActivityId }).some();
                    if (!existDependentActivity) {
                        var sortIndex = vm.currentDependActivity.length + 1;

                        var dependentActivity = service.dataContext.dependActivities.add({
                            DependentActivityId: activity.ActivityId,
                            DependentActivityName: activity.ActivityName,
                            DependentActivityCode: activity.ActivityCode,
                            DependentActivitySystemCode: activity.ActivitySystemCode,
                            DependentActivityExternalCode: activity.ActivityExternalCode,
                            ActivityEffortQuota: activity.ActivityEffortQuota,
                            ActivityEffortUnitId: activity.ActivityEffortUnitId,
                            ModuleTargetId: activity.ModuleTargetId,
                            VhrEventTypeId: activity.VhrEventTypeId,
                            VhrEventGroupId: activity.VhrEventGroupId,
                            ParentActivityId: currentActivityId,
                            ActivityPathClassificationBw: wizardDependActivityCollector.activityPathClassificationBw,
                            WorkCenterGroupId: wizardDependActivityCollector.workCenterGroupId,
                            VirtualWorkCenterId: wizardDependActivityCollector.workCenterId,
                            SortIndex: sortIndex
                        });
                        dependentActivity.ActivityEffortUnitName = vm.effortUnitObjForBind[activity.ActivityEffortUnitId];
                        dependentActivity.DependActivityIsSingle = wizardDependActivityCollector.dependActivityIsSingle;
                        dependentActivity.DependActivityIsPeriodical = wizardDependActivityCollector.dependActivityIsPeriodical;
                        dependentActivity.WorkCenterGroupName = wizardDependActivityCollector.workCenterGroupName;
                        dependentActivity.VirtualWorkCenterName = wizardDependActivityCollector.workCenterName;

                        vm.currentDependActivity.push(dependentActivity);
                    }
                });

            vm.forceDatasourceLocal = {
                refreshkey: new Date().getTime(),
                dataSource: vm.currentDependActivity
            }
        }

        vm.moduleTargetsOnChange = function () {
            var selectedModuleTargetId = vm.currentActivity.ModuleTargetId;
            loadModuleTargetDependentData(true, selectedModuleTargetId, appStoreBw, vm.isBom);
        };

        // get reference of submitaction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parents = vm.submitAction;
        vm.submitAction = function () {
            $timeout(() => {
                vm.currentActivity.entityAspect.validateEntity();

                if (vm.viewContext.state === vm.viewContext.states.isAdding) {
                    vm.currentActivity.ActivitySystemCode = vm.currentActivity.ActivityCode + '-' + contextService.virtualContext.CurrentVirtualCompanyId;//only for validation.. replaced server side
                    if (!vm.isBom) {
                        vm.currentActivity.ActivityFrequencyCode = '-';
                    }
                }

                if (vm.currentActivity.entityAspect.hasValidationErrors) {
                    return;
                }

                parents().then(
                    function (saveResult) {
                        currentActivityId = vm.currentActivity.ActivityId;
                        vm.dependActivityAreVisible = true;

                        ribbon.setRibbonStatusButton(ribbon.Buttons.New, true);
                        ribbon.refreshStatusButtons();
                    }
                );
            });
        };

        function loadModuleTargetDependentData(forceReload, moduleTargetId, appStoreBw, isBom) {
            var funcsPromises = [];
            funcsPromises.push(getVhrEventTemplates(forceReload, moduleTargetId, vhrEventKindId));
            funcsPromises.push(getVhrEventTypes(forceReload, moduleTargetId, isBom, appStoreBw));
            funcsPromises.push(getVhrEventGroups(forceReload, moduleTargetId, isBom, appStoreBw));

            if (isBom) {
                funcsPromises.push(getSchedulingGroups(forceReload, appStoreBw, moduleTargetId));
            }

            $q.all(funcsPromises)
                .then(function () {
                    selectDefaultData(isBom);
                });
        }

        function selectDefaultData(isBom) {
            if (isBom) {
                if (vm.vhrEventGroups.length === 1) {
                    vm.currentActivity.VhrEventGroupId = Lazy(vm.vhrEventGroups).first().VhrEventGroupId;
                } else {
                    vm.currentActivity.VhrEventGroupId = null;
                }
                if (vm.vhrEventTypes.length === 1) {
                    vm.currentActivity.VhrEventTypeId = Lazy(vm.vhrEventTypes).first().VhrEventTypeId;
                } else {
                    vm.currentActivity.VhrEventTypeId = null;
                }
                if (vm.vhrEventTemplates.length === 1) {
                    vm.currentActivity.VhrEventTemplateId = Lazy(vm.vhrEventTemplates).first().VhrEventTemplateId;
                } else {
                    vm.currentActivity.VhrEventTemplateId = null;
                }
            }
        }

        function getDependentActivityPaths(forceReload, currentActivityId, activityKindId, vhrEventTypeId, appStoreBw) {
            var filter = {
                ActivityId: currentActivityId,
                ActivityKindId: activityKindId,
                VhrEventTypeId: vhrEventTypeId,
                AppStoreBw: appStoreBw
            };
            var defer = $q.defer();

            service.getDependentActivityPaths(filter, forceReload)
                .then(function (data) {
                    Lazy(data.results)
                        .each((item) => {
                            item.ActivityEffortUnitName = vm.effortUnitObjForBind[item.ActivityEffortUnitId];
                            item.DependActivityIsSingle = (item.ActivityPathClassificationBw & routeSchedulingTypeObj.Once) > 0;
                            item.DependActivityIsPeriodical = (item.ActivityPathClassificationBw & routeSchedulingTypeObj.Periodical) > 0;
                        });

                    vm.currentDependActivity = data.results;

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.currentDependActivity
                    }

                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getFrequencyCodes(forceReload) {
            var defer = $q.defer();

            activityService.getFrequencyCodes({}, forceReload)
                .then(function (data) {
                    Nembus.Common.Library.parser.AddCronExpressionLabelToList(data.results, 'CronExpression', vm.translate);
                    vm.frequencyCodes = objListJoinProperties(data.results, 'CronExpressionLabelUI', 'FrequencyCodeCode,CronExpressionLabel', ' - ');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrEventTypes(forceReload, moduleTargetId, isBom, appStoreBw) {
            var filter = { AppStoreBw: appStoreBw, ModuleTargetId: moduleTargetId, IsBomType: isBom };
            var defer = $q.defer();

            vhreventService.getVhrEventTypes(filter, forceReload)
                .then(function (data) {
                    vm.vhrEventTypes = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrEventGroups(forceReload, moduleTargetId, isBom, appStoreBw) {
            var filter = { AppStoreBw: appStoreBw, ModuleTargetId: moduleTargetId, IsBomGroup: isBom };
            var defer = $q.defer();

            vhreventService.getVhrEventGroups(filter, forceReload)
                .then(function (data) {
                    vm.vhrEventGroups = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrEventTemplates(forceReload, moduleTargetId, vhrEventKindId) {
            var moduleTargetAppStoreBw = Lazy(vm.moduleTargetsAll).findWhere({ ModuleTargetId: moduleTargetId }).AppStoreBw;
            var filter = { AppStoreBw: moduleTargetAppStoreBw, VhrEventKindId: vhrEventKindId };
            var defer = $q.defer();

            vhreventService.getVhrEventTemplates(filter, forceReload)
                .then(function (data) {
                    vm.vhrEventTemplates = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getSchedulingGroups(forceReload, appStoreBw, moduleTargetId) {
            var filter = { AppStoreBw: appStoreBw, ModuleTargetId: moduleTargetId };
            var defer = $q.defer();

            service.getSchedulingGroups(filter, forceReload)
                .then(function (data) {
                    vm.schedulingGroups = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getActivity(forceReload, currentActivityId, activityKindId, vhrEventTypeId, appStoreBw) {
            var filter = {
                ModuleTargetId: null,
                ActivityId: currentActivityId,
                ActivityKindId: activityKindId,
                VhrEventTypeId: vhrEventTypeId,
                AppStoreBw: appStoreBw
            };
            var defer = $q.defer();

            service.getActivity(filter, forceReload)
                .then(function (data) {
                    vm.currentActivity = data.results[0];
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function objListJoinProperties(data, propertyDest, propertyNames, separator) {
            var properties = propertyNames.split(',');

            if (!separator) {
                separator = ' ';
            }

            if (!propertyDest) {
                return null;
            }

            Lazy(data)
                .each((item) => {
                    var propertiesParsed = Lazy(properties)
                        .map((p) => {
                            if (item[p] !== null && item[p] !== undefined) {
                                p = item[p];
                            } else {
                                p = '';
                            }
                            return p;
                        }).toArray();

                    item[propertyDest] = propertiesParsed.join(separator);
                });

            return data;
        };
    };

    activityDetail.$inject = injectParams;
    activityDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('activityDetail', activityDetail);
});