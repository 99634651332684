﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'vhreventService', 'hrbucketService', 'utilService', 'activityService', 'productionService', 'foundationService', 'navigationService', 'checkStateChangeService', 'contextService', 'cmsService'];
    function avatarsSearch($q, $scope, $timeout, $injector, vhreventService, hrbucketService, utilService, activityService, productionService, foundationService, navigationService, checkStateChangeService, contextService, cmsService) {
        var vm = this;
        nbsVm.call(vm, vhreventService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, vhreventService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'search');
        ribbon.addHomeTab();
        ribbon.addSearchTab();
        ribbon.tabIndexSelected = 2;
        vm.ribbonDatacontext = ribbon;

        vm.setIsEditingSearch();
        ribbon.setRibbonStatusButton(ribbon.Buttons.New, false);
        ribbon.refreshStatusButtons();

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        //var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        // at moment not managed
        // manage when were introduced searchs with moduletargetid (processes)
        var moduleTargetId = null;
        var activityKindEnum = Nembus.Common.Library.util.parseEnum(activityService.dataContext.enumTypes.ActivityKind, null, vm.translate).obj.normal.name_value;
        var expressionTypeEnum = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.ExpressionType, null, vm.translate).obj.normal.name_value;
        var vhrEventKindEnum = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventKind, null, vm.translate).obj.normal.name_value;
        var bucketClassificationEnum = Nembus.Common.Library.util.parseEnum(hrbucketService.dataContext.enumTypes.BucketClassification, null, vm.translate).obj.normal.name_value;
        var activityKindId = activityKindEnum.Bom;

        var filtersToSkip = [];
        var nbsSearchForm = new NbsSearchForm(vm.translate);
        var searchFormData = nbsSearchForm.SearchAvatarsFormData();
        try { filtersToSkip = contextService.virtualContext.behaviors.search.avatar[appStoreCode].skipFilters; } catch (e) { }
        searchFormData.init(vm, filtersToSkip);

        var selectionParams = { destModalKey: 'activity', appStoreBw: appStoreBw, activityKindId: activityKindId };
        vm.nbsSelectionGridWithModalActivity = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalActivity.setOnSelectFnCallback(function () {
            vm.currentActivityVhrCoreFilter.ActivityIds = vm.nbsSelectionGridWithModalActivity.selectedIds;
        });

        selectionParams = { destModalKey: 'category', systemCategoryCode: 'ALB_IMP', categoryType: 'user', isAutocheck: false, viewKind: 'all', expandTree: false };
        vm.nbsSelectionTreeviewWithModalCategory = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategory.setOnSelectFnCallback(function () {
            vm.currentVirtualHrFilter.CategoriesId = vm.nbsSelectionTreeviewWithModalCategory.selectedIds;
        });

        selectionParams = { destModalKey: 'category', systemCategoryCode: 'ALB_IMP', categoryType: 'user', isAutocheck: false, viewKind: 'all', expandTree: false };
        vm.nbsSelectionTreeviewWithModalCategoryHazards = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategoryHazards.setOnSelectFnCallback(function () {
            vm.currentVirtualHrFilter.InheritedCategoriesId = vm.nbsSelectionTreeviewWithModalCategoryHazards.selectedIds;
        });

        selectionParams = { destModalKey: 'category', systemCategoryCode: 'BSC.JOBROLES', categoryType: 'user', viewTitle: vm.translate('PersonJobData.JobRole') };
        vm.nbsSelectionTreeviewWithModalCategoryJobRoles = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategoryJobRoles.setOnSelectFnCallback(function () {
            vm.currentVirtualPersonFilter.JobRoleId = vm.nbsSelectionTreeviewWithModalCategoryJobRoles.selectedIds;
        });

        selectionParams = { destModalKey: 'category', systemCategoryCode: 'BSC.LEGALQUALIFICATIONS', categoryType: 'user', viewTitle: vm.translate('PersonJobData.LegalQualification') };
        vm.nbsSelectionTreeviewWithModalCategoryLegalQualifications = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategoryLegalQualifications.setOnSelectFnCallback(function () {
            vm.currentVirtualPersonFilter.LegalQualificationId = vm.nbsSelectionTreeviewWithModalCategoryLegalQualifications.selectedIds;
        });

        selectionParams = { destModalKey: 'category', systemCategoryCode: 'BSC.BUSINESSROLES', categoryType: 'user', viewTitle: vm.translate('PersonJobData.BusinessRole') };
        vm.nbsSelectionTreeviewWithModalCategoryBusinessRoles = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategoryBusinessRoles.setOnSelectFnCallback(function () {
            vm.currentVirtualPersonFilter.BusinessRoleId = vm.nbsSelectionTreeviewWithModalCategoryLegalQualifications.selectedIds;
        });

        selectionParams = { destModalKey: 'category', systemCategoryCode: 'BSC.SAFETYROLES', categoryType: 'user', viewTitle: vm.translate('PersonJobData.SafetyRole') };
        vm.nbsSelectionTreeviewWithModalCategorySafetyRoles = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategorySafetyRoles.setOnSelectFnCallback(function () {
            vm.currentVirtualPersonFilter.SafetyRoleId = vm.nbsSelectionTreeviewWithModalCategoryLegalQualifications.selectedIds;
        });

        selectionParams = { destModalKey: 'category', systemCategoryCode: 'BSC.PLACE', categoryType: 'user', viewTitle: vm.translate('PersonJobData.PlaceDefinedId') };
        vm.nbsSelectionTreeviewWithModalCategoryPlaceDefineds = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategoryPlaceDefineds.setOnSelectFnCallback(function () {
            vm.currentVirtualPersonFilter.PlaceDefinedId = vm.nbsSelectionTreeviewWithModalCategoryLegalQualifications.selectedIds;
        });

        selectionParams = { destModalKey: 'category', systemCategoryCode: 'BSC.PLACE', categoryType: 'user', viewTitle: vm.translate('PersonJobData.PlaceUsedId') };
        vm.nbsSelectionTreeviewWithModalCategoryPlaceUseds = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategoryPlaceUseds.setOnSelectFnCallback(function () {
            vm.currentVirtualPersonFilter.PlaceUsedId = vm.nbsSelectionTreeviewWithModalCategoryLegalQualifications.selectedIds;
        });

        selectionParams = { destModalKey: 'category', systemCategoryCode: 'BSC.CC', categoryType: 'user', viewTitle: vm.translate('PersonJobData.CcDefinedId') };
        vm.nbsSelectionTreeviewWithModalCategoryCcDefineds = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategoryCcDefineds.setOnSelectFnCallback(function () {
            vm.currentVirtualPersonFilter.CcDefinedId = vm.nbsSelectionTreeviewWithModalCategoryLegalQualifications.selectedIds;
        });

        selectionParams = { destModalKey: 'category', systemCategoryCode: 'BSC.CC', categoryType: 'user', viewTitle: vm.translate('PersonJobData.CcUsedId') };
        vm.nbsSelectionTreeviewWithModalCategoryCcUseds = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategoryCcUseds.setOnSelectFnCallback(function () {
            vm.currentVirtualPersonFilter.CcUsedId = vm.nbsSelectionTreeviewWithModalCategoryLegalQualifications.selectedIds;
        });

        selectionParams = { destModalKey: 'category', systemCategoryCode: 'BSC.BU', categoryType: 'user', viewTitle: vm.translate('PersonJobData.BuDefinedId') };
        vm.nbsSelectionTreeviewWithModalCategoryBuDefineds = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategoryBuDefineds.setOnSelectFnCallback(function () {
            vm.currentVirtualPersonFilter.BuDefinedId = vm.nbsSelectionTreeviewWithModalCategoryLegalQualifications.selectedIds;
        });

        selectionParams = { destModalKey: 'category', systemCategoryCode: 'BSC.BU', categoryType: 'user', viewTitle: vm.translate('PersonJobData.BuUsedId') };
        vm.nbsSelectionTreeviewWithModalCategoryBuUsed = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategoryBuUsed.setOnSelectFnCallback(function () {
            vm.currentVirtualPersonFilter.BuUsedId = vm.nbsSelectionTreeviewWithModalCategoryLegalQualifications.selectedIds;
        });

        var dsCallback = function () { return foundationService.getCompanies({}, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'Company', viewTitle: vm.translate('PersonJobData.CompanyUsedId'), ds: dsCallback };
        vm.nbsSelectionWithModalCompanyUseds = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionWithModalCompanyUseds.setOnSelectFnCallback(function () {
            vm.currentVirtualPersonFilter.CompaniesUsedId = vm.nbsSelectionTreeviewWithModalCategoryLegalQualifications.selectedIds;
        });

        dsCallback = function () { return vhreventService.getVhrEventTypes({ AppStoreBw: appStoreBw, IsBomType: false }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventType', viewTitle: vm.translate('VhrEvent.VhrEventType'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventType = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalVhrEventType.setOnSelectFnCallback(function () {
            vm.currentVhrEventFilter.VhrEventTypesId = vm.nbsSelectionGridWithModalVhrEventType.selectedIds;
        });

        dsCallback = function () { return vhreventService.getVhrEventGroups({ AppStoreBw: appStoreBw, IsBomGroup: false }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventGroup', viewTitle: vm.translate('VhrEvent.VhrEventGroup'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventGroup = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalVhrEventGroup.setOnSelectFnCallback(function () {
            vm.currentVhrEventFilter.VhrEventGroupsId = vm.nbsSelectionGridWithModalVhrEventGroup.selectedIds;
        });

        dsCallback = function () { return productionService.getVirtualWorkCenters({ AppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'WorkCenter', viewTitle: vm.translate('WorkCenter'), ds: dsCallback };
        vm.nbsSelectionGridWithModalWorkCenter = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalWorkCenter.setOnSelectFnCallback(function () {
            vm.currentVhrEventFilter.WorkCentersId = vm.nbsSelectionGridWithModalWorkCenter.selectedIds;
        });

        dsCallback = function () { return hrbucketService.getHrBuckets({ BucketClassificationId: bucketClassificationEnum.GenericLegalEntity, OwnerAppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'HrBucket', viewTitle: vm.translate('LegalEntities'), ds: dsCallback };
        vm.nbsSelectionGridWithModalLegalEntities = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalLegalEntities.setOnSelectFnCallback(function () {
            vm.currentVirtualHrFilter.LegalEntityIds = vm.nbsSelectionGridWithModalLegalEntities.selectedIds;
        });

        dsCallback = function () { return hrbucketService.getHrBuckets({ BucketClassificationId: bucketClassificationEnum.CostCenter, OwnerAppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'HrBucket', viewTitle: vm.translate('CostCenters'), ds: dsCallback };
        vm.nbsSelectionGridWithModalCostCenters = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalCostCenters.setOnSelectFnCallback(function () {
            vm.currentVirtualHrFilter.CostCenterIds = vm.nbsSelectionGridWithModalCostCenters.selectedIds;
        });

        dsCallback = function () { return hrbucketService.getHrBuckets({ BucketClassificationId: bucketClassificationEnum.BusinessUnit, OwnerAppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'HrBucket', viewTitle: vm.translate('BusinessUnits'), ds: dsCallback };
        vm.nbsSelectionGridWithModalBusinessUnits = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalBusinessUnits.setOnSelectFnCallback(function () {
            vm.currentVirtualHrFilter.BusinessUnitIds = vm.nbsSelectionGridWithModalBusinessUnits.selectedIds;
        });

        dsCallback = function () { return hrbucketService.getHrBuckets({ BucketClassificationId: bucketClassificationEnum.Sector, OwnerAppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'HrBucket', viewTitle: vm.translate('Sectors'), ds: dsCallback };
        vm.nbsSelectionGridWithModalSectors = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalSectors.setOnSelectFnCallback(function () {
            vm.currentVirtualHrFilter.SectorIds = vm.nbsSelectionGridWithModalSectors.selectedIds;
        });

        dsCallback = function () { return hrbucketService.getHrBuckets({ BucketClassificationId: bucketClassificationEnum.Plant, OwnerAppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'HrBucket', viewTitle: vm.translate('Plants'), ds: dsCallback };
        vm.nbsSelectionGridWithModalPlants = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalPlants.setOnSelectFnCallback(function () {
            vm.currentVirtualHrFilter.PlantIds = vm.nbsSelectionGridWithModalPlants.selectedIds;
        });

        vm.searchTabIndex = 0;

        vm.newAction = function () {
        };

        vm.addFilterAction = function () {
            vm.searchTabIndex = 0;
            searchFormData.addFilters(navigationService, vm.translate, $scope);
        };

        vm.searchAction = function () {
            // start search
            searchAvatars();
        };

        vm.deleteFilterAction = function () {
            $timeout(() => {
                vm.searchTabIndex = 0;
                vm.resetAction();
                searchFormData.removeFilters(filtersToSkip);
            });
        };

        vm.resetAction = function () {
            $timeout(() => {
                vm.searchTabIndex = 0;
                searchFormData.resetFilters();

                vm.nbsSelectionGridWithModalActivity.clear();
                vm.nbsSelectionTreeviewWithModalCategory.clear();
                vm.nbsSelectionTreeviewWithModalCategoryHazards.clear();
                vm.nbsSelectionTreeviewWithModalCategoryJobRoles.clear();
                vm.nbsSelectionTreeviewWithModalCategoryLegalQualifications.clear();
                vm.nbsSelectionTreeviewWithModalCategoryBusinessRoles.clear();
                vm.nbsSelectionTreeviewWithModalCategorySafetyRoles.clear();
                vm.nbsSelectionTreeviewWithModalCategoryPlaceDefineds.clear();
                vm.nbsSelectionTreeviewWithModalCategoryPlaceUseds.clear();
                vm.nbsSelectionTreeviewWithModalCategoryCcUseds.clear();
                vm.nbsSelectionTreeviewWithModalCategoryCcDefineds.clear();
                vm.nbsSelectionTreeviewWithModalCategoryBuUsed.clear();
                vm.nbsSelectionTreeviewWithModalCategoryBuDefineds.clear();
                vm.nbsSelectionWithModalCompanyUseds.clear();
                vm.nbsSelectionGridWithModalVhrEventType.clear();
                vm.nbsSelectionGridWithModalVhrEventGroup.clear();
                vm.nbsSelectionGridWithModalWorkCenter.clear();
                vm.nbsSelectionGridWithModalLegalEntities.clear();
                vm.nbsSelectionGridWithModalCostCenters.clear();
                vm.nbsSelectionGridWithModalBusinessUnits.clear();
                vm.nbsSelectionGridWithModalSectors.clear();
                vm.nbsSelectionGridWithModalPlants.clear();
            });
        };

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('avatar', 'search');
        nbsGridDatacontext.allowSearching = false;
        nbsGridDatacontext.allowGrouping = false;
        nbsGridDatacontext.setAdaptToPage();
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        function searchAvatars() {
            var searchAvatarClientData = {};
            searchAvatarClientData.vhrEventFilter = nbsSearchForm.ParseVhrEventFilter(vm.currentVhrEventFilter, appStoreBw, vhrEventKindEnum, expressionTypeEnum);
            searchAvatarClientData.vhrEventFilter.AppStoreBw = null;
            searchAvatarClientData.vhrEventFilter.VhrEventKind = null;
            searchAvatarClientData.vhrEventFilter.ModuleTargetId = moduleTargetId;
            searchAvatarClientData.virtualPersonFilter = nbsSearchForm.ParseVirtualPersonFilter(vm.currentVirtualPersonFilter, vm.currentVirtualHrFilter, appStoreBw);
            searchAvatarClientData.activityVhrCoreFilter = nbsSearchForm.ParseActivityVhrCoreFilter(vm.currentActivityVhrCoreFilter, appStoreBw);

            vm.currentAvatars = [];

            foundationService.searchAvatars(searchAvatarClientData)
                .then(function (data) {
                    vm.currentAvatars = data.results;

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.currentAvatars
                    }

                    vm.searchTabIndex = 1;
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });
        }

        vm.currentAvatars = [];

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.avatarftc", { 'virtualHumanResourceId': args.data.VirtualHumanResourceId, 'appStoreCodeFtc': appStoreCode });
        };

        vm.refreshAction = function () {
            vm.searchTabIndex = 1;

            searchAvatars(true);
        };
    }

    avatarsSearch.$inject = injectParams;
    avatarsSearch.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('avatarsSearch', avatarsSearch);
});