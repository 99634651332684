﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'productionService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function weekTemplatesList($q, $scope, $timeout, $injector, productionService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, productionService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return weekTemplateSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, productionService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var weekTemplateSelected = null;

        vm.currentWeekTemplates = [];

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('weektemplateescape', null);
        nbsGridDatacontext.viewCommonBar();
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getWeekTemplates(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.currentWeekTemplates = results[0];

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.currentWeekTemplates
                    }
                });
        }

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.weektemplatedetail", { 'wWeekTemplateId': args.data.WWeekTemplateId });
        };

        vm.newAction = function () {
            navigationService.navigateTo("app.nav.weektemplatedetail", { 'wWeekTemplateId': null });
        };

        vm.refreshAction = function () {
            loadData(true);
        };

        vm.deleteAction = function () {
            if (!weekTemplateSelected) {
                return;
            }            

            productionService.deleteEntity(weekTemplateSelected);
            vm.submitAction()
                .then(function (saveResult) {
                    vm.refreshAction();
                });
        };

        vm.gridSelection = function (args) {
            $timeout(() => {
                weekTemplateSelected = args.data;

                if (weekTemplateSelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, true);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                }

                ribbon.refreshStatusButtons();
            });
        };


        function getWeekTemplates(forceReload) {
            var defer = $q.defer();

            productionService.getWeekTemplates({}, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    }

    weekTemplatesList.$inject = injectParams;
    weekTemplatesList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('weekTemplatesList', weekTemplatesList);
});