﻿'use strict';
define([], function () {

    var injectParams = ['$timeout'];
    var nbsDdMsComponent = function ($timeout) {


        var vm = this;
        vm.localDataSourceMs = [];
        vm.localDataSourceDdSelected = [];
        vm.localDataSourceDd = [];
        vm.ddIconType = '';

        vm.$onChanges = function (changes) {
            if (changes.nbsDataContext && changes.nbsDataContext.currentValue) {
                vm.localDataSourceDd = changes.nbsDataContext.currentValue;
            }

            if (changes.nbsDataSelected) {
                $timeout(() => {
                    vm.localDataSourceMs = changes.nbsDataSelected.currentValue
                        ? changes.nbsDataSelected.currentValue
                        : null;

                    vm.localDataSourceDdSelected = vm.localDataSourceMs;
                });
            }            
        };

        vm.onOptionDeleteMsEvent = function (sourceOption) {
            //alert('box - component');
            vm.localDataSourceMs = Lazy(vm.localDataSourceMs).reject(i => { return i.optionValue == sourceOption.optionValue }).toArray();
            vm.localDataSourceDdSelected = vm.localDataSourceMs;
            vm.optionDeleteEvent(sourceOption);
            //todo: get all items
            vm.optionChangesEvent(vm.localDataSourceMs);
        };

        vm.onOptionChangesMsEvent = function (sourceOptions) {
            vm.localDataSourceMs = sourceOptions;
            vm.localDataSourceDdSelected = sourceOptions;
            vm.optionChangesEvent({ options: sourceOptions });
        };

        vm.onDdChanges = function (ddOptionsSelected) {
            vm.localDataSourceMs = ddOptionsSelected;
            vm.optionChangesEvent({ options: ddOptionsSelected });
        };
    };

    nbsDdMsComponent.$inject = injectParams;

    angular.module('nbs.rds.module').component('nbsDdMs', {
        templateUrl: '/rds/common/components/nbsDdMsComponent/_?c=' + window.codeCache(),
        controller: nbsDdMsComponent,
        controllerAs: 'vm',
        bindings: {
            optionChangesEvent: '&',
            optionDeleteEvent: '&',
            nbsDataContext: "<", //[uiOptions,uiOptions]
            nbsDataSelected: "<",
            isVisible: "<",
            uiContext: "<",
            ddIconType: "@",
            ddLabel: '<'
        }
    });
});

