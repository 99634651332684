﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'dataflowService', 'navigationService', 'contextService', 'formSummaryService'];
    var fileShuffleDefinitionFtc = function ($q, $scope, $timeout, $injector, dataflowService, navigationService, contextService, formSummaryService) {

        var vm = this;
        nbsVm.call(vm, dataflowService, navigationService, contextService, $q, $injector, $timeout);
        
        var referral = 'fileshuffledefinition';
        var fileShuffleDefinitionId = navigationService.stateParams().fileShuffleDefinitionId;
        var cmsView = 'fileshuffledefinitionftc-' + vm.viewMode;
        var fileShuffle = null;

        var state = { detail: 'detail', tasks: 'tasks' };

        var fastTab = new NbsAccordion();
        fastTab.AddFastTab(state.detail, "Detail");
        fastTab.AddFastTab(state.tasks, "ScheduledTasks");
        vm.fastTabDatacontext = fastTab;

        vm.ftcOnClick = function (event) {
            navigateTo(event.id);
        }

        // raised from detail view
        vm.ftcOnAddEntity = function (entity) {
            $timeout(() => {
                // not used
            });
        }

        if (fileShuffleDefinitionId) {
            dataflowService.getFileShuffleDefinitions({ FileShuffleDefinitionId: fileShuffleDefinitionId }, true)
                .then(function (data) {
                    fileShuffle = data.results[0];

                    $timeout(() => {
                        vm.fastTabsAreDisabled = false;
                    });

                    // configure form summary items
                    formSummaryService.clearDatasource();
                    formSummaryService.push({ key: vm.translateCmsView(cmsView, 'FileShuffleDefinitionCode', 'FileShuffleDefinition'), value: fileShuffle.FileShuffleDefinitionCode });
                    formSummaryService.push({ key: vm.translateCmsView(cmsView, 'FileShuffleDefinitionName', 'FileShuffleDefinition'), value: fileShuffle.FileShuffleDefinitionName });

                    goToDefaultFastTab();
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });
        } else {
            goToDefaultFastTab();

            $timeout(() => {
                vm.fastTabsAreDisabled = true;
            });
        }

        function navigateTo(accordionId) {
            if (!accordionId) {
                return;
            }

            var navigationState = null;
            var navigationStateParams = null;
            switch (accordionId) {
                case state.detail:
                    navigationState = 'app.nav.fileshuffledefinitionftc.fileshuffledefinitiondetail';
                    navigationStateParams = { 'FileShuffleDefinitionId': fileShuffleDefinitionId };
                    break;
                case state.tasks:
                    var classFullName = fileShuffle ? fileShuffle.entityType.namespace + '.' + fileShuffle.entityType.shortName : '';
                    navigationState = 'app.nav.fileshuffledefinitionftc.scheduledtaskslist-ftcview';
                    navigationStateParams = { 'targetClassFullName': classFullName, 'targetEntityId': fileShuffle.FileShuffleDefinitionId, 'viewFormSummary': true, 'referral': referral };
                    break;
            }

            // call navigation state
            if (navigationState && navigationStateParams) {
                navigationService.navigateTo(navigationState, navigationStateParams);
            }
        };

        function goToDefaultFastTab() {
            // go to default page only if is called ftc directly
            if (navigationService.currentState().endsWith('.fileshuffledefinitionftc')) {
                navigateTo(state.detail);
            }
        }
    };

    fileShuffleDefinitionFtc.$inject = injectParams;
    fileShuffleDefinitionFtc.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('fileShuffleDefinitionFtc', fileShuffleDefinitionFtc);
});