﻿'use strict';
define([], function () {

    var injectParams = ['$q', '$timeout', 'hrbucketService', 'foundationService'];
    var nbsAssetBarComponent = function ($q, $timeout, hrbucketService, foundationService) {
        var vm = this;
        var privateDataContext = null;
        var privateUIData = null;
        var defaultKeys = null;
        var bucketClassificationEnum = null;
        var nbsUiOptionParser = null;

        vm.privateComponentsStatus = {};
        vm.nbsDataPrivate = {};

        vm.$onInit = function () {
        };

        vm.$onChanges = function (changes) {
            if (changes.nbsDataContext && changes.nbsDataContext.currentValue) {
                nbsUiOptionParser = new NbsUiOptionParser(vm.uiContext.translate);

                privateDataContext = changes.nbsDataContext.currentValue;

                bucketClassificationEnum = Nembus.Common.Library.util.parseEnum(hrbucketService.dataContext.enumTypes.BucketClassification, null, vm.uiContext.translate).obj.normal.name_value;

                // set default visible columns list in grid
                defaultKeys = privateDataContext.defaultKeys;

                // Start of execution workflow of load data bar components 
                initViewTitle();
            }
        };

        function initViewTitle() {
            var cols = ['VirtualHumanResourceId', 'VirtualCode', 'VirtualName', 'VirtualEquipmentRegistrationNumber', 'VhrGroupName'];
            var defaultStartData = new NbsUiData();
            defaultStartData.setColumns(cols);
            defaultStartData.setFilterList(getFilterList());

            vm.initViewTitle = defaultStartData.startData;
        }

        //#region hook from components
        vm.onViewTitleSelectionEvent = function (result) {
            privateUIData = JSON.parse(JSON.stringify(result.uiData));
            setComponents(privateUIData);

            setBarDatasources()
                .then(function () {
                    barOnChanges(privateUIData);
                });
        };
        vm.onUiChanges = function (result) {
            privateUIData.columns = result.uiData.columns;
            privateUIData.grouping = result.uiData.grouping;
            privateUIData.sorting = result.uiData.sorting;
            privateUIData.filters.freeText = result.uiData.filters.freeText;

            refreshViewTitleDataContext(privateUIData);

            vm.onGridUiChangesEvent({ result: privateUIData });
        };
        vm.onFilterChanges = function (result) {
            privateUIData.filterList = result.filters.filterList;

            refreshViewTitleDataContext(privateUIData);
            setStatusComponents(privateUIData.filterList);

            setBarDatasources()
                .then(function () {
                    barOnChanges(privateUIData);
                });
        };
        vm.onDdMsVhrGroups = function (options) {
            privateUIData.filters.vhrgroups = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdMsVhrTypes = function (options) {
            privateUIData.filters.vhrtypes = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdMsPlants = function (options) {
            privateUIData.filters.plants = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onBooleanIsPlantAssociated = function (value) {
            privateUIData.filters.isplantassociated = value;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        //vm.onDdMsVhrLegalEntities = function (options) {
        //    privateUIData.filters.vhrlegalentities = options;
        //    refreshViewTitleDataContext(privateUIData);
        //    barOnChanges(privateUIData);
        //};
        //vm.onDdMsVhrCostCenters = function (options) {
        //    privateUIData.filters.vhrcostcenters = options;
        //    refreshViewTitleDataContext(privateUIData);
        //    barOnChanges(privateUIData);
        //};
        //vm.onDdMsVhrBusinessUnits = function (options) {
        //    privateUIData.filters.vhrbusinessunits = options;
        //    refreshViewTitleDataContext(privateUIData);
        //    barOnChanges(privateUIData);
        //};
        //vm.onDdMsVhrSectors = function (options) {
        //    privateUIData.filters.vhrsectors = options;
        //    refreshViewTitleDataContext(privateUIData);
        //    barOnChanges(privateUIData);
        //};
        //#endregion

        // refresh current preference uiData
        function refreshViewTitleDataContext(uiDataParam) {
            vm.viewTitleDataContext = Nembus.Common.Library.component.getDataContextViewTitle(uiDataParam);
        }

        function setBarDatasources() {
            var defer = $q.defer();
            var funcsPromises = [];

            if (vm.privateComponentsStatus.vhrgroups.isVisible) {
                funcsPromises.push(getVhrGroups(false));
            }
            if (vm.privateComponentsStatus.vhrtypes.isVisible) {
                funcsPromises.push(getVhrTypes(false));
            }
            if (vm.privateComponentsStatus.plants.isVisible) {
                funcsPromises.push(getHrBuckets(false));
            }

            //if (vm.privateComponentsStatus.vhrlegalentities.isVisible
            //    || vm.privateComponentsStatus.vhrbusinessunits.isVisible
            //    || vm.privateComponentsStatus.vhrcostcenters.isVisible
            //    || vm.privateComponentsStatus.vhrsectors.isVisible) {

            //    funcsPromises.push(getHrBuckets(false));
            //}

            $q.all(funcsPromises)
                .then(function () {
                    defer.resolve(true);
                });

            return defer.promise;
        }

        function getFilterList() {
            var uiDataFilters = [];
            uiDataFilters.push(new NbsUiDataFilter({ code: 'vhrtypes', name: 'AssetType' }));
            uiDataFilters.push(new NbsUiDataFilter({ code: 'vhrgroups', name: 'AssetGroup' }));
            uiDataFilters.push(new NbsUiDataFilter({ code: 'plants', name: 'Plants' }));
            uiDataFilters.push(new NbsUiDataFilter({ code: 'isplantassociated', name: 'PlantAssociation' }));
            return uiDataFilters;
        }

        function getVhrTypes(forceReload) {
            var filter = {};
            var defer = $q.defer();

            foundationService.getVhrTypes(filter, forceReload)
                .then(function (data) {
                    vm.nbsDataPrivate.vhrtypes = nbsUiOptionParser.getUiOptionsByKey(data.results, 'VhrType');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getHrBuckets(forceReload) {
            var filter = {
                AppStoreBw: null,
                //ResourceTypeId: resourceTypeId,
                BucketClassificationId: bucketClassificationEnum.Plant
            };
            var defer = $q.defer();

            hrbucketService.getHrBuckets(filter, forceReload)
                .then(function (data) {
                    vm.nbsDataPrivate.plants = nbsUiOptionParser.getUiOptionsByKey(data.results, 'Vhr');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        //function getHrBuckets(forceReload) {
        //    var filter = {};
        //    var defer = $q.defer();
        //    var bucketClassificationEnum = Nembus.Common.Library.util.parseEnum(hrbucketService.dataContext.enumTypes.BucketClassification, null, vm.uiContext.translate).obj.normal.name_value;

        //    hrbucketService.getHrBuckets(filter, forceReload)
        //        .then(function (data) {

        //            var legalEntities = Lazy(data.results).where({ BucketClassificationId: bucketClassificationEnum.GenericLegalEntity }).toArray();
        //            var costCenters = Lazy(data.results).where({ BucketClassificationId: bucketClassificationEnum.CostCenter }).toArray();
        //            var businessUnits = Lazy(data.results).where({ BucketClassificationId: bucketClassificationEnum.BusinessUnit }).toArray();
        //            var sectors = Lazy(data.results).where({ BucketClassificationId: bucketClassificationEnum.Sector }).toArray();

        //            vm.nbsDataPrivate.vhrlegalentities = nbsUiOptionParser.getUiOptionsByKey(legalEntities, 'Vhr');
        //            vm.nbsDataPrivate.vhrcostcenters = nbsUiOptionParser.getUiOptionsByKey(costCenters, 'Vhr');
        //            vm.nbsDataPrivate.vhrbusinessunits = nbsUiOptionParser.getUiOptionsByKey(businessUnits, 'Vhr');
        //            vm.nbsDataPrivate.vhrsectors = nbsUiOptionParser.getUiOptionsByKey(sectors, 'Vhr');

        //            defer.resolve(true);
        //        })
        //        .catch(function (error) {
        //            vm.setErrorMsg(error);
        //            defer.reject(error);
        //        });

        //    return defer.promise;
        //}

        function getVhrGroups(forceReload) {
            var filter = {};
            var defer = $q.defer();

            foundationService.getVhrGroups(filter, forceReload)
                .then(function (data) {
                    vm.nbsDataPrivate.vhrgroups = nbsUiOptionParser.getUiOptionsByKey(data.results, 'VhrGroup');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function setComponents(uiDataParam) {
            setCommonGridBarDataContext(uiDataParam);
            setSelectionComponents(uiDataParam);
            setStatusComponents(uiDataParam.filterList);
            vm.onGridUiChangesEvent({ result: privateUIData });
        }

        function setCommonGridBarDataContext(uiDataParam) {
            vm.commonGridBarDataContext = Nembus.Common.Library.component.getDataContextCommonGridBar(uiDataParam);
            vm.commonBarDatasource = privateDataContext.commonBarDatasource
        }

        function setSelectionComponents(uiDataParam) {
            vm.nbsDataPrivate.vhrgroupsselected = uiDataParam.filters.vhrgroups ? uiDataParam.filters.vhrgroups : [];
            vm.nbsDataPrivate.vhrtypesselected = uiDataParam.filters.vhrtypes ? uiDataParam.filters.vhrtypes : [];
            vm.nbsDataPrivate.plantsselected = uiDataParam.filters.plants ? uiDataParam.filters.plants : [];
            vm.nbsDataPrivate.isplantassociatedchecked = uiDataParam.filters.isplantassociated;

            //vm.nbsDataPrivate.vhrlegalentitiesselected = uiDataParam.filters.vhrlegalentities ? uiDataParam.filters.vhrlegalentities : [];
            //vm.nbsDataPrivate.vhrcostcentersselected = uiDataParam.filters.vhrcostcenters ? uiDataParam.filters.vhrcostcenters : [];
            //vm.nbsDataPrivate.vhrbusinessunitsselected = uiDataParam.filters.vhrbusinessunits ? uiDataParam.filters.vhrbusinessunits : [];
            //vm.nbsDataPrivate.vhrsectorsselected = uiDataParam.filters.vhrsectors ? uiDataParam.filters.vhrsectors : [];
        }

        function setStatusComponents(filterList) {
            vm.privateComponentsStatus.vhrgroups = Nembus.Common.Library.component.getStatus('vhrgroups', filterList);
            vm.privateComponentsStatus.vhrtypes = Nembus.Common.Library.component.getStatus('vhrtypes', filterList);
            vm.privateComponentsStatus.plants = Nembus.Common.Library.component.getStatus('plants', filterList);
            vm.privateComponentsStatus.isplantassociated = Nembus.Common.Library.component.getStatus('isplantassociated', filterList);

            //vm.privateComponentsStatus.vhrlegalentities = Nembus.Common.Library.component.getStatus('vhrlegalentities', filterList);
            //vm.privateComponentsStatus.vhrcostcenters = Nembus.Common.Library.component.getStatus('vhrcostcenters', filterList);
            //vm.privateComponentsStatus.vhrbusinessunits = Nembus.Common.Library.component.getStatus('vhrbusinessunits', filterList);
            //vm.privateComponentsStatus.vhrsectors = Nembus.Common.Library.component.getStatus('vhrsectors', filterList);
        }

        function barOnChanges(uiDataParam) {
            var selectedVhrGroupsId = vm.privateComponentsStatus.vhrgroups.isVisible
                ? Lazy(uiDataParam.filters.vhrgroups).pluck('optionValue').toArray()
                : [];

            var selectedVhrTypesId = vm.privateComponentsStatus.vhrtypes.isVisible
                ? Lazy(uiDataParam.filters.vhrtypes).pluck('optionValue').toArray()
                : [];

            var selectedPlantsId = [];
            var isPlantAssociated = null;

            if (vm.privateComponentsStatus.plants.isVisible) {
                selectedPlantsId = Lazy(uiDataParam.filters.plants).pluck('optionValue').toArray();
            }

            if (vm.privateComponentsStatus.isplantassociated.isVisible) {
                isPlantAssociated = uiDataParam.filters.isplantassociated;
            }

            //var arrayList = [];
            //if (vm.privateComponentsStatus.vhrlegalentities.isVisible) { arrayList.push(Lazy(uiDataParam.filters.vhrlegalentities).pluck('optionValue').toArray()); }
            //if (vm.privateComponentsStatus.vhrbusinessunits.isVisible) { arrayList.push(Lazy(uiDataParam.filters.vhrbusinessunits).pluck('optionValue').toArray()); }
            //if (vm.privateComponentsStatus.vhrcostcenters.isVisible) { arrayList.push(Lazy(uiDataParam.filters.vhrcostcenters).pluck('optionValue').toArray()); }
            //if (vm.privateComponentsStatus.vhrsectors.isVisible) { arrayList.push(Lazy(uiDataParam.filters.vhrsectors).pluck('optionValue').toArray()); }
            //var selectedHrBucketsId = Nembus.Common.Library.util.getJoinedArrayValues(arrayList);
            //if (!selectedHrBucketsId) { selectedHrBucketsId = []; }

            $timeout(() => {
                vm.onBarChanges({
                    result: {
                        assetTreeFilter: {
                            VhrGroupsId: selectedVhrGroupsId,
                            VhrTypesId: selectedVhrTypesId,
                            HaveToExpandVhrsWithAssetTree: Lazy(selectedPlantsId).some(),
                            VirtualHumanResourcesId: selectedPlantsId,
                            IsPlantAssociated: isPlantAssociated
                        }
                    }
                });
            });
        }
        //#endregion 
    };


    nbsAssetBarComponent.$inject = injectParams;
    angular.module('nbs.rds.module').component('nbsAssetBar', {
        templateUrl: '/rds/assets/components/nbsAssetBarComponent/_?c=' + window.codeCache(),
        controller: nbsAssetBarComponent,
        controllerAs: 'vm',
        bindings: {
            onBarChanges: '&',
            onGridUiChangesEvent: '&',

            nbsDataContext: "<",
            uiContext: "<"
        }
    });
});

