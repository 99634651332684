﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'rbacService', 'drbacService', 'foundationService', 'navigationService', 'checkStateChangeService', 'contextService', 'currentSessionService'];
    var appUserDetail = function ($q, $scope, $timeout, $injector, rbacService, drbacService, foundationService, navigationService, checkStateChangeService, contextService, currentSessionService) {

        var vm = this;
        nbsVm.call(vm, rbacService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, rbacService, { dirtyFlagIsSuspended: vm.isDirtySuspended });
        var appUserRequestTypeEnumParsed = Nembus.Common.Library.util.parseEnum(rbacService.dataContext.enumTypes.AppUserRequestType, null, vm.translate);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        if (contextService.virtualContext.HaveToManageIdpUsers === true) {
            ribbon.addTab(new ribbon.Tab("ActionPanelTabManage", [ribbon.GetLocalUserSection(), ribbon.GetIdpSection(), ribbon.GetSupportSection()]));
        } else {
            ribbon.addTab(new ribbon.Tab("ActionPanelTabManage", [ribbon.GetLocalUserSection()]));
        }
        vm.ribbonDatacontext = ribbon;

        var xCentralUserId = navigationService.stateParams().xCentralUserId;
        var isNewUser = navigationService.stateParams().isNewUser;

        if (!xCentralUserId) {
            vm.isModify = false;
            vm.setIsAdding();

            loadDataMandatory(true)
                .then(() => {
                    vm.appUserRequest = rbacService.dataContext.appUserRequests.add({
                        AppUserRequestId: NbsGuidManager.newGuid(),
                        AppUserRequestTypeId: appUserRequestTypeEnumParsed.obj.normal.name_value.Add,
                        IsEnabled: true,
                        IsProgrammaticAccess: false,
                        IsSa: false,
                        IsManageAllProcesses: false,
                        IsUserProfileRequired: false,
                        VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId
                    });
                });
        } else {
            vm.isModify = true;
            vm.setIsViewing();

            if (isNewUser === 'true') {
                vm.modifyAction();
            }

            loadDataMandatory(true)
                .then(() => {
                    loadDataUpdate(true);
                });
        }

        ribbon.setRibbonStatusButton(ribbon.Buttons.Modify, vm.isModify && Nembus.Rds.Library.CanManageUser(contextService));
        ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
        ribbon.setRibbonStatusButton(ribbon.Buttons.New, false);
        ribbon.refreshStatusButtons();

        vm.appUserRequestListVC = [];
        var currAppUserRequests = [];
        var virtualCompaniesObj = {};

        function loadDataMandatory(forceReload) {
            var defer = $q.defer();
            var funcsPromises = [];

            funcsPromises.push(getVirtualCompanies(forceReload));
            funcsPromises.push(getAvailableVirtualCompanies(forceReload));
            if (vm.isModify) {
                funcsPromises.push(getDrbacUserGroups(forceReload));
                funcsPromises.push(getUserProfilesCrossContext(forceReload));
                funcsPromises.push(getDrbacRoles(forceReload));
                funcsPromises.push(getDrbacProcessRoles(forceReload));
                funcsPromises.push(getDrbacProcessGlobalsCrossContext(forceReload));
            }

            $q.all(funcsPromises)
                .then(function (results) {
                    defer.resolve(true);
                });

            return defer.promise;
        }

        function loadDataUpdate(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getAppUserRequests(forceReload, xCentralUserId));

            $q.all(funcsPromises)
                .then(function (results) {
                    currAppUserRequests = results[0];

                    setDatasourceForRequestCommonData(currAppUserRequests);

                    var canResetPassword = !vm.appUserRequest.IsProgrammaticAccess && Nembus.Rds.Library.CanResetPassword();
                    var canInvite = !vm.appUserRequest.IsProgrammaticAccess && Nembus.Rds.Library.CanInvite();
                    var canRenewSecretCode = !vm.appUserRequest.IsProgrammaticAccess && Nembus.Rds.Library.CanRenewSecretCode();
                    var canResetProgrammaticAccessKey = vm.appUserRequest.IsProgrammaticAccess && Nembus.Rds.Library.CanResetProgrammaticAccessKey();

                    ribbon.setRibbonStatusButton(ribbon.Buttons.ResetPasswordLocal, canResetPassword);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.NewAccountLocal, canInvite);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.RenewSecretCode, canRenewSecretCode);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.ProgrammaticAccess, canResetProgrammaticAccessKey);
                    ribbon.refreshStatusButtons();

                    getLastAccessDto(forceReload, xCentralUserId);
                    setDatasourceForRequestVC(currAppUserRequests);
                    setVirtualCompanieForDefault(currAppUserRequests);
                });
        }

        var storedFirstName = null;
        var storedLastName = null;
        var storedContactValue = null;
        vm.appUserRequestIsProgrammaticUserOnChange = function () {
            if (vm.appUserRequest.IsProgrammaticAccess) {
                storedFirstName = vm.appUserRequest.FirstName;
                storedLastName = vm.appUserRequest.LastName;
                storedContactValue = vm.appUserRequest.ContactValue;

                vm.appUserRequest.FirstName = '-';  // fake, only to pass client validation
                vm.appUserRequest.LastName = '-';  // fake, only to pass client validation
                vm.appUserRequest.ContactValue = '-';  // fake, only to pass client validation
            } else {
                vm.appUserRequest.FirstName = storedFirstName;
                vm.appUserRequest.LastName = storedLastName;
                vm.appUserRequest.ContactValue = storedContactValue;
            }
        };

        vm.userIsEnabledOnChange = function () {
            // if user is disabled (central flag) then disable in all contexts
            if (vm.appUserRequest.IsEnabled === false) {
                Lazy(currAppUserRequests)
                    .each((userRequest) => {
                        userRequest.IsEnabled = false;
                    });
            }
        };

        vm.cannotModifyVirtualCompany = function (virtualCompanyId) {
            return !Lazy(vm.currentAvailableVirtualCompanies).where({ VirtualCompanyId: virtualCompanyId }).some() || vm.viewContext.isLocked;
        };

        vm.selectVirtualCompanyToBind = function () {
            var vcIdsJustBinded = Lazy(currAppUserRequests).pluck('VirtualCompanyId').toArray();
            var resultantVirtualCompanies = Lazy(vm.currentAvailableVirtualCompanies).filter((item) => {
                return !Lazy(vcIdsJustBinded).contains(item.VirtualCompanyId);
            }).toArray();

            var params = new NbsGenericListModalParamObject('VirtualCompany', vm.translate('VirtualContextSelectionTitle'));
            navigationService.navToGenericListModal(vm.suspendDirty, params, resultantVirtualCompanies)
                .then(function (result) {
                    if (result.hasSelection) {
                        var virtualCompanyId = Lazy(result.selectedValues).first();

                        rbacService.dataContext.appUserRequests.add({
                            AppUserRequestId: NbsGuidManager.newGuid(),
                            AppUserRequestTypeId: appUserRequestTypeEnumParsed.obj.normal.name_value.BindUser,
                            FirstName: '-',  // fake, only to pass client validation
                            LastName: '-',  // fake, only to pass client validation
                            ContactValue: '-',  // fake, only to pass client validation
                            Username: 'fakeusername',  // fake, only to pass client validation
                            AppUserId: 0,
                            XCentralUserId: xCentralUserId,
                            VirtualCompanyId: virtualCompanyId
                        });

                        //bind app user
                        parents()
                            .then(() => {
                                loadDataUpdate(true);
                            });
                    }
                });
        };

        var parents = vm.submitAction;
        vm.submitAction = function () {
            parents().then(
                function (saveResult) {
                    if (saveResult.entities.length > 0 && vm.viewContext.isAddingState) {
                        navigationService.navigateTo("app.nav.appuserdetail",
                            {
                                'xCentralUserId': vm.appUserRequest.XCentralUserId,
                                'isNewUser': true
                            });
                    }
                }
            );
        };

        var parentm = vm.modifyAction;
        vm.modifyAction = function () {
            parentm().then(
                //enable disable button new & delete because is custom behavior
                //generally saveResult eneble or disable only save button 
                function (saveResult) {

                }
            );
        };

        // reset password sending NBS-ADMIN-RESET-PASSWORD notification
        vm.resetPasswordAction = function () {
            if (!vm.appUserRequest.Username || vm.appUserRequest.IsProgrammaticAccess) {
                return;
            }

            vm.removeAlerts();

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        rbacService.adminResetPasswordBulk({ XCentralUserId: xCentralUserId })
                            .then(function (result) {
                                if (result.httpResponse.data === '1') {
                                    vm.setSaveSuccessMsg(false, false);

                                    navigationService.customMessageModal('EmailSendOk');
                                } else {
                                    navigationService.customMessageModal('EmailSendError');
                                }
                            })
                            .catch(function (error) {
                                vm.setErrorMsg(error);
                                navigationService.customMessageModal('EmailSendError');
                            });
                    }
                });
        };

        //Http Post
        // reset password sending RESET-NEWUSER-PASSWORD-LOCAL notification
        vm.newAccountAction = function () {
            if (!vm.appUserRequest.Username || vm.appUserRequest.IsProgrammaticAccess) {
                return;
            }

            vm.removeAlerts();

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        rbacService.adminNewAccountResetPasswordBulk({ XCentralUserId: xCentralUserId })
                            .then(function (result) {
                                if (result.httpResponse.data === '1') {
                                    vm.setSaveSuccessMsg(false, false);

                                    navigationService.customMessageModal('EmailSendOk');
                                } else {
                                    navigationService.customMessageModal('EmailSendError');
                                }
                            })
                            .catch(function (error) {
                                vm.setErrorMsg(error);
                                navigationService.customMessageModal('EmailSendError');
                            });
                    }
                });
        };

        //call idp for send email reset password
        vm.ssoResetPasswordAction = function () {
            if (!vm.appUserRequest.Username || vm.appUserRequest.IsProgrammaticAccess) {
                return;
            }

            vm.removeAlerts();

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        rbacService.adminResetSsoPasswordBulk({ XCentralUserId: xCentralUserId })
                            .then(function (result) {
                                navigationService.customMessageModal('EmailSendOk');
                            })
                            .catch(function (error) {
                                vm.setErrorMsg(error);
                                navigationService.customMessageModal('EmailSendError');
                            });
                    }
                });
        };

        vm.ssoSyncroUser = function () {
            if (!vm.appUserRequest.Username || vm.appUserRequest.IsProgrammaticAccess) {
                return;
            }

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        rbacService.ssoVerifyUsersBulk({ XCentralUserId: xCentralUserId })
                            .then(function (result) {
                                navigationService.customMessageModal('RequestDone');
                            })
                            .catch(function (error) {
                                vm.setErrorMsg(error);
                                navigationService.customMessageModal('RequestError');
                            });

                    }
                });
        }

        vm.programmaticAccessAction = function () {
            if (!vm.appUserRequest.IsProgrammaticAccess) {
                return;
            }

            vm.removeAlerts();

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        rbacService.adminResetProgrammaticAccess({ XCentralUserId: xCentralUserId })
                            .then(function (data) {
                                var programmaticAccessKey = data.results[0] ? JSON.parse(data.results[0]) : null;
                                if (programmaticAccessKey) {
                                    navigationService.copyTextMessageModal(programmaticAccessKey, vm.translate('GenerateProgrammaticAccessKey'));
                                } else {
                                    navigationService.customMessageModal('RequestError');
                                }
                            })
                            .catch(function (error) {
                                vm.setErrorMsg(error);
                            });
                    }
                });
        }

        vm.supportSyncroUser = function () {
            if (!vm.appUserRequest.Username || vm.appUserRequest.IsProgrammaticAccess) {
                return;
            }

            vm.removeAlerts();

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    rbacService.supportVerifyUsersBulk({ XCentralUserId: xCentralUserId })
                        .then(function (result) {
                            navigationService.customMessageModal('RequestDone');
                        })
                        .catch(function (error) {
                            vm.setErrorMsg(error);
                            navigationService.customMessageModal('RequestError');
                        });
                });
        }

        function setDatasourceForRequestCommonData(appUserRequests) {
            vm.appUserRequest = Lazy(appUserRequests).findWhere({ AppUserRequestTypeId: appUserRequestTypeEnumParsed.obj.normal.name_value.UpdateCommonData });
        }

        vm.setVirtualCompanieForDefault = function () {
            setVirtualCompanieForDefault(currAppUserRequests);
        }

        function setVirtualCompanieForDefault(appUserRequests) {
            vm.bindedVirtualCompanies = [];
            vm.bindedVirtualCompanies = Lazy(appUserRequests)
                .where({ IsEnabled: true, AppUserRequestTypeId: appUserRequestTypeEnumParsed.obj.normal.name_value.Update })
                .map((item) => {
                    return {
                        VirtualCompanyId: item.VirtualCompanyId,
                        VirtualCompanyName: virtualCompaniesObj[item.VirtualCompanyId]
                    }
                })
                .toArray();
        }

        function setDatasourceForRequestVC(appUserRequests) {
            var tmpAppUserRequests = Lazy(appUserRequests).where({ AppUserRequestTypeId: appUserRequestTypeEnumParsed.obj.normal.name_value.Update }).toArray();
            vm.appUserRequestListVC = [];
            vm.appUserRequestDataObj = {};

            Lazy(tmpAppUserRequests)
                .each((request) => {
                    var currVirtualCompanyId = request.VirtualCompanyId;
                    vm.appUserRequestDataObj[currVirtualCompanyId] = {};

                    request.VirtualCompanyName = virtualCompaniesObj[currVirtualCompanyId];


                    // drbacusergroups
                    var currGroupsMultiselect = new NbsMultiselect(vm.translate);
                    var currGroupsDatasource = Lazy(vm.drbacUserGroups).where({ VirtualCompanyId: currVirtualCompanyId }).toArray();
                    currGroupsMultiselect.addData(currGroupsDatasource, 'DrbacUserGroupId', 'DrbacUserGroupName');
                    currGroupsMultiselect.setOnchangeFnCallback(function () {
                        request.DrbacUserGroupsSerialized = Lazy(currGroupsMultiselect.selectedIds).some()
                            ? JSON.stringify(currGroupsMultiselect.selectedIds)
                            : '[]';
                    });
                    var groupsSelected = JSON.parse(request.SourceDrbacUserGroupsSerialized);
                    if (Lazy(groupsSelected).some()) {
                        currGroupsMultiselect.setSelected(groupsSelected);
                    }

                    // profiles
                    var currProfilesMultiselect = new NbsMultiselect(vm.translate);
                    var currProfilesDatasource = Lazy(vm.drbacProfiles).where({ VirtualCompanyId: currVirtualCompanyId }).toArray();
                    currProfilesMultiselect.addData(currProfilesDatasource, 'UserProfileId', 'UserProfileName');
                    currProfilesMultiselect.setOnchangeFnCallback(function () {
                        request.DrbacProfilesSerialized = Lazy(currProfilesMultiselect.selectedIds).some()
                            ? JSON.stringify(currProfilesMultiselect.selectedIds)
                            : '[]';
                    });
                    var profilesSelected = JSON.parse(request.SourceDrbacProfilesSerialized);
                    if (Lazy(profilesSelected).some()) {
                        currProfilesMultiselect.setSelected(profilesSelected);
                    }

                    // drbacProcessRoles 
                    var currRolesMultiselect = new NbsMultiselect(vm.translate);
                    var currProcessGlobal = Lazy(vm.drbacProcessGlobals).findWhere({ VirtualCompanyId: currVirtualCompanyId });
                    if (currProcessGlobal) {
                        var currRoles = Lazy(vm.drbacProcessRoles).where({ DrbacProcessId: currProcessGlobal.DrbacProcessId })
                            .map((processRole) => { return { DrbacProcessRoleId: processRole.DrbacProcessRoleId, RoleName: processRole.DrbacRole.RoleName }; })
                            .toArray();

                        currRolesMultiselect.addData(currRoles, 'DrbacProcessRoleId', 'RoleName');
                        currRolesMultiselect.setOnchangeFnCallback(function () {
                            request.DrbacProcessRolesSerialized = Lazy(currRolesMultiselect.selectedIds).some()
                                ? JSON.stringify(currRolesMultiselect.selectedIds)
                                : '[]';
                        });
                        var rolesSelected = JSON.parse(request.SourceDrbacProcessRolesSerialized);
                        if (Lazy(rolesSelected).some()) {
                            currRolesMultiselect.setSelected(rolesSelected);
                        }
                    } else {
                        console.error('GlobalProcess not found for VirtualCompanyId: ' + currVirtualCompanyId);
                    }

                    // object binded to UI for selection data for virtual companies
                    vm.appUserRequestDataObj[request.VirtualCompanyId].drbacUserGroups = currGroupsMultiselect;
                    vm.appUserRequestDataObj[request.VirtualCompanyId].drbacProfiles = currProfilesMultiselect;
                    vm.appUserRequestDataObj[request.VirtualCompanyId].drbacProcessRoles = currRolesMultiselect;
                });

            vm.appUserRequestListVC = Lazy(tmpAppUserRequests).toArray();
        }

        function getUserProfilesCrossContext(forceReload) {
            var filter = {};
            var defer = $q.defer();

            rbacService.getUserProfilesCrossContext(filter, forceReload)
                .then(function (data) {
                    vm.drbacProfiles = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getAppUserRequests(forceReload, xCentralUserId) {
            var filter = {
                XCentralUserId: xCentralUserId
            };
            var defer = $q.defer();

            rbacService.getAppUserRequests(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getDrbacRoles(forceReload) {
            var filter = {};
            var defer = $q.defer();

            drbacService.getDrbacRoles(filter, forceReload)
                .then(function (data) {
                    vm.drbacRoles = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getDrbacProcessRoles(forceReload) {
            var filter = {};
            var defer = $q.defer();

            drbacService.getDrbacProcessRoles(filter, forceReload)
                .then(function (data) {
                    vm.drbacProcessRoles = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getDrbacProcessGlobalsCrossContext(forceReload) {
            var defer = $q.defer();

            drbacService.getDrbacProcessGlobalsCrossContext({}, forceReload)
                .then(function (data) {
                    vm.drbacProcessGlobals = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getAvailableVirtualCompanies(forceReload) {
            var defer = $q.defer();

            var funcsPromises = [];
            $q.all(funcsPromises)
                .then(function (results) {
                    currentSessionService.getAvailableVirtualCompanies()
                        .then(function (data) {
                            vm.currentAvailableVirtualCompanies = data.results;
                            defer.resolve(true);
                        })
                        .catch(function (error) {
                            vm.setErrorMsg(error);
                        });
                });

            return defer.promise;
        }

        function getVirtualCompanies(forceReload) {
            var filter = {};
            var defer = $q.defer();

            foundationService.getVirtualCompanies(filter, forceReload)
                .then(function (data) {
                    virtualCompaniesObj = Nembus.Common.Library.parser.objFromList(data.results, 'VirtualCompanyId', 'VirtualCompanyName');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getLastAccessDto(forceReload, xCentralUserId) {
            var filter = { XCentralUserId: xCentralUserId };
            var defer = $q.defer();

            rbacService.getLastAccessDto(filter, forceReload)
                .then(function (data) {
                    var lastAccessDto = data.results[0];
                    vm.lastAccess = {
                        LastLogonDate: Nembus.Common.Library.converter.date.formatDateTime(lastAccessDto.LastLogonDate, contextService.virtualContext.cultureInfo, false),
                        LastUserAgent: lastAccessDto.LastUserAgent
                    }

                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getDrbacUserGroups(forceReload) {
            var filter = {};
            var defer = $q.defer();

            drbacService.getDrbacUserGroups(filter, forceReload)
                .then(function (data) {
                    vm.drbacUserGroups = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        ////secret code
        //vm.renewTwoFactorSecretCode = function (renewSecretCodeDto) {
        //    vm.removeAlerts();
        //    rbacService.renewTwoFactorSecretCode(renewSecretCodeDto)
        //        .then(function (result) {
        //            if (result.results[0] === '1') {
        //                vm.setSaveSuccessMsg(false, false);

        //                navigationService.customMessageModal('EmailSendOk');
        //            }
        //            else {
        //                navigationService.customMessageModal('EmailSendError');
        //            }
        //        });
        //};

        //vm.renewSecretCodeAction = function () {
        //    vm.renewSecretCodeDto.Username = null;
        //    vm.renewSecretCodeDto.Password = null;
        //    vm.modalTitle = vm.translate('YourPasswordRequired');

        //    vm.renewSecreCodeModalInstance = navigationService.modal().open(vm.modalOptions({
        //        windowClass: vm.NBS_MODAL_CLASS_FORM_MD,
        //        templateUrl: 'requirePasswordTemplate',
        //        scope: $scope
        //    }));

        //    vm.renewSecreCodeModalInstance.result.then(function (result) {
        //        vm.renewSecretCodeDto.Username = vm.appUserRequest.Username;
        //        vm.renewTwoFactorSecretCode(vm.currentUserDto);
        //    }, function () {
        //        //nothing to do                 
        //    });
        //};

        //vm.renewSecreteCodeModalOk = function () {
        //    vm.renewSecreCodeModalInstance.close();
        //};

        //vm.renewSecreteCodeModalCancel = function () {
        //    vm.renewSecreCodeModalInstance.dismiss();
        //};
    };

    appUserDetail.$inject = injectParams;
    appUserDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rbac.module').controller('appUserDetail', appUserDetail);
});