﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'navigationService', 'checkStateChangeService', 'vhreventService', 'contextService', 'rbacService', 'drbacService'];
    function vhrEventChangeExecution($q, $scope, $timeout, $injector, navigationService, checkStateChangeService, vhreventService, contextService, rbacService, drbacService) {

        var vm = this;
        nbsVm.call(vm, vhreventService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, vhreventService);

        var vhrEventEntityHistoryTypeEnum = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventEntityHistoryType, null, null).obj.normal.name_value;
        vm.vhrEventUserTypes = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventUserType, null, vm.translate).list.translated;
        var vhrEventUserTypeObj = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventUserType, null, vm.translate).obj.normal.name_value;
        var vhrEventMassivelyUpdateRequestType = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventMassivelyUpdateRequestType, null, vm.translate).obj.normal.name_value;
        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
        var forceReload = true;

        var pParams = navigationService.stateParamsModal().parentParams;
        pParams = pParams ? pParams : navigationService.stateParams();

        vm.isExecute = pParams.modalKey === 'executeexecution';
        vm.isModify = pParams.modalKey === 'changeexecution';
        vm.isHistory = pParams.modalKey === 'historyexecution';

        // entity used for validation
        vm.modalEntity = vhreventService.dataContext.vhrEventMassivelyUpdateRequestClientDatas.add({
            Id: NbsGuidManager.newGuid(),
            Flag: true,
            ExecutorId: null,
            ExecutorTypeId: null,
            ExecutorName: null,
            Percentage: 100
        });

        if (vm.isHistory) {
            var nbsGridDatacontext = new NbsGrid(vm.translate);
            nbsGridDatacontext.configureGrid('vhreventhistory', 'execution');
            nbsGridDatacontext.setDatasource([], false);
            nbsGridDatacontext.setModalMode();
            nbsGridDatacontext.allowSearching = false;
            vm.nbsGridDatacontext = nbsGridDatacontext;

            getVhrEventEntityHistories(forceReload, pParams.VhrEventId, vhrEventEntityHistoryTypeEnum.Execution)
                .then((history) => {
                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: history
                    }
                });
        }

        // submit and close modal
        vm.modalOk = function () {
            $timeout(() => {
                vm.modalEntity.entityAspect.validateEntity();
                if (!vm.modalEntity.entityAspect.hasValidationErrors) {
                    vm.modalEntity.entityAspect.rejectChanges();

                    var modalResult = getModalResult(navigationService.navigationActionTypes.modalOk);
                    navigationService.closeCurrentModal(modalResult);
                }
            });            
        };

        // close modal
        vm.modalCancel = function () {
            if (vm.modalEntity && vm.modalEntity.entityAspect) {
                vm.modalEntity.entityAspect.rejectChanges();
            }

            var modalResult = getModalResult(navigationService.navigationActionTypes.modalCancel);
            navigationService.closeCurrentModal(modalResult);
        };

        vm.assignToMe = function () {
            vm.modalEntity.ExecutorTypeId = vhrEventUserTypeObj.User;

            loadUserOrGroups()
                .then((result) => {
                    vm.modalEntity.ExecutorId = contextService.virtualContext.appuserid;
                });

        }

        vm.vhrEventUserTypeOnChange = function () {
            loadUserOrGroups();
        }

        function loadUserOrGroups() {
            var defer = $q.defer();
            vm.userorgroups = [];

            if (vm.modalEntity.ExecutorTypeId === vhrEventUserTypeObj.User) {
                getCurrentContextAppUsers(forceReload)
                    .then((users) => {
                        vm.userorgroups = Lazy(users).map((item) => { return { value: item.AppUserId, name: item.DisplayName }; }).toArray();

                        defer.resolve(true);
                    });
            } else if (vm.modalEntity.ExecutorTypeId === vhrEventUserTypeObj.UserGroup) {
                getDrbacUserGroups(forceReload, virtualCompanyId)
                    .then((userGroups) => {
                        vm.userorgroups = Lazy(userGroups).map((item) => { return { value: item.DrbacUserGroupId, name: item.DrbacUserGroupName }; }).toArray();

                        defer.resolve(true);
                    });
            } else {
                defer.resolve(false);
            }

            return defer.promise;
        }

        function getCurrentContextAppUsers(forceReload) {
            var filter = {};
            var defer = $q.defer();

            rbacService.getCurrentContextAppUsers(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getDrbacUserGroups(forceReload, virtualCompanyId) {
            var defer = $q.defer();
            var filter = {
                VirtualCompanyId: virtualCompanyId
            };

            drbacService.getDrbacUserGroups(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrEventEntityHistories(forceReload, vhrEventId, vhrEventEntityHistoryTypeId) {
            var defer = $q.defer();
            var filter = { VhrEventId: vhrEventId, VhrEventEntityHistoryTypeId: vhrEventEntityHistoryTypeId };

            vhreventService.getVhrEventEntityHistories(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getModalResult(dialogResult) {
            var modalResult = new navigationService.modalResult();

            if (dialogResult === navigationService.navigationActionTypes.modalOk) {
                if (vm.isExecute) {
                    modalResult.data = {
                        VhrEventMassivelyUpdateRequestTypeId: vhrEventMassivelyUpdateRequestType.UpdateExecution,
                        IsExecuted: vm.modalEntity.Flag,
                        ExecutorId: null,
                        ExecutorTypeId: null,
                        ExecutorName: null,
                        PercentageExecuted: vm.modalEntity.Percentage,
                        ExecutionNote: null,
                    };
                }

                if (vm.isModify) {
                    modalResult.data = {
                        VhrEventMassivelyUpdateRequestTypeId: vhrEventMassivelyUpdateRequestType.UpdateExecution,
                        IsExecuted: vm.modalEntity.Flag,
                        ExecutorId: vm.modalEntity.ExecutorId,
                        ExecutorTypeId: vm.modalEntity.ExecutorTypeId,
                        ExecutorName: vm.modalEntity.ExecutorName,
                        PercentageExecuted: vm.modalEntity.Percentage,
                        ExecutionNote: vm.modalEntity.ExecutionNote
                    };
                }
            }

            modalResult.sourceParams = pParams;
            modalResult.dialogResult = dialogResult;
            return modalResult;
        }
    }

    vhrEventChangeExecution.$inject = injectParams;
    vhrEventChangeExecution.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('vhrEventChangeExecution', vhrEventChangeExecution);
});