﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function companiesList($q, $scope, $timeout, $injector, foundationService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, foundationService);
        vm.setItemSelectedFn.call(vm, () => { return companySelected; });

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var companySelected = null;

        vm.currentCompanies = [];

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('company', null);
        nbsGridDatacontext.viewCommonBar();
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getCompanies(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.currentCompanies = results[0];

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.currentCompanies
                    }
                });
        }

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.companyftc", { 'companyId': args.data.CompanyId });
        };

        vm.newAction = function () {
            navigationService.navigateTo("app.nav.companyftc", { 'companyId': null }, vm.canCreate);
        };

        vm.refreshAction = function () {
            loadData(true);
        };
        
        vm.deleteAction = function () {
            if (!companySelected) {
                return;
            }

            foundationService.deleteEntity(companySelected);
            vm.submitAction()
                .then(function (saveResult) {
                    vm.refreshAction();
                });
        };

        vm.gridSelection = function (args) {
            $timeout(() => {
                companySelected = args.data;

                if (companySelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, true);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                }

                ribbon.refreshStatusButtons();
            });
        };

        function getCompanies(forceReload) {
            var defer = $q.defer();

            foundationService.getCompanies({}, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    }

    companiesList.$inject = injectParams;
    companiesList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('companiesList', companiesList);
});