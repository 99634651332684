﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var validationSchemaInstanceDetail = function ($q, $scope, $timeout, $injector, foundationService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, foundationService, { dirtyFlagIsSuspended: vm.isDirtySuspended });

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var validationSchemaFieldKindEnumParsed = Nembus.Common.Library.util.parseEnum(foundationService.dataContext.enumTypes.ValidationSchemaFieldKind, 'ValidationSchemaInstanceField', vm.translate);
        vm.validationSchemaFieldKindObj = validationSchemaFieldKindEnumParsed.obj.normal.name_value;

        ribbon.setRibbonStatusButton(ribbon.Buttons.Modify,false);
        ribbon.refreshStatusButtons();

        var validationSchemaInstanceId = navigationService.stateParams().validationSchemaInstanceId;
        if (validationSchemaInstanceId) {
            vm.setIsViewing();

            loadData(true);
        }
        else {
            // ValidationSchemaInstance cannot be added, can be only copied by another one
        }

        vm.fieldKindOnSelect = function (kindId) {
            vm.currentKindId = kindId;
        };

        vm.onBooleanChange = function (field) {
            field.PropertyValue = field.PropertyValueToBind.toString();
        };

        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getValidationSchemaDefinitions(forceReload));
            funcsPromises.push(getValidationSchemaInstanceFields(forceReload, validationSchemaInstanceId));
            funcsPromises.push(getValidationSchemaDefinitionGroups(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    var instanceFields = results[1];

                    getValidationSchemaInstances(forceReload, validationSchemaInstanceId)
                        .then((result) => {
                            // bind instance
                            vm.validationInstance = result;

                            // prepare fields
                            Lazy(instanceFields)
                                .each((field) => {
                                    if (field.PropertyType === 'System.Boolean') {
                                        field.PropertyValueIsBoolean = true;
                                        field.PropertyValueToBind = field.PropertyValue === 'true' ? true : false;
                                    } else {
                                        field.PropertyValueIsBoolean = false;
                                    }

                                    field.PropertyNameTranslated = vm.translate('ValidationSchemaInstanceDetail.' + vm.validationInstance.ValidationSchemaDefinition.ValidationSchemaDefinitionEntity + '_' + field.PropertyName);
                                });

                            // bind fields
                            vm.instanceFields = instanceFields;

                            // disable if issystem
                            ribbon.setRibbonStatusButton(ribbon.Buttons.Modify, !vm.validationInstance.IsSystem && !vm.validationInstance.IsPublish);
                            ribbon.refreshStatusButtons();
                        });
                });
        }

        function getValidationSchemaInstances(forceReload, validationSchemaInstanceId) {
            var filter = {
                ValidationSchemaInstanceId: validationSchemaInstanceId
            };
            var defer = $q.defer();

            foundationService.getValidationSchemaInstances(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results[0]);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getValidationSchemaDefinitions(forceReload) {
            var filter = {};
            var defer = $q.defer();

            foundationService.getValidationSchemaDefinitions(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getValidationSchemaInstanceFields(forceReload, validationSchemaInstanceId) {
            var filter = {
                ValidationSchemaInstanceId: validationSchemaInstanceId
            };
            var defer = $q.defer();

            foundationService.getValidationSchemaInstanceFields(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getValidationSchemaDefinitionGroups(forceReload) {
            var filter = {};
            var defer = $q.defer();

            foundationService.getValidationSchemaDefinitionGroups(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    validationSchemaInstanceDetail.$inject = injectParams;
    validationSchemaInstanceDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('validationSchemaInstanceDetail', validationSchemaInstanceDetail);
});