﻿'use strict';

define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'vhreventService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function vhrEventsManualStepSwitchFromActivity($q, $scope, $timeout, $injector, foundationService, vhreventService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, foundationService);

        var pParams = navigationService.stateParamsModal().parentParams;
        pParams = pParams ? pParams : navigationService.stateParams();

        var vhrEventManualRequestType = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventManualRequestType, null, vm.translate).obj.normal.name_value;

        // navigation buttons visibility
        vm.setWizardButtonVisibility(pParams);

        // beardcrumb information
        var beardCrumbInfo = JSON.parse(pParams.beardCrumbInfo);
        Lazy(beardCrumbInfo.items).each((item) => { item.title = vm.translate(item.title); })
        vm.beardCrumbInfo = beardCrumbInfo;

        // refill data form
        vm.vhrEventManualUpdateDateClientData = {};
        if (vhreventService.vhrEventManualCollector) {
        	vm.vhrEventManualUpdateDateClientData.IsEventManualByActivity = vhreventService.vhrEventManualCollector.IsEventManualByActivity;
        	if (!vm.vhrEventManualUpdateDateClientData.IsEventManualByActivity) {
        		vhreventService.vhrEventManualCollector.Activity = null;
        	}
        }

        vm.modalNext = function () {
            // close modal and returns client data object
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalNext);
            navigationService.closeCurrentModal(modalResult);
        };

        vm.modalCancel = function () {
            navigationService.confirmExitWizard()
                            .then(function (result) {
                                if (result) {
                                    vhreventService.rejectChanges();
                                    var modalResult = getModalResult(navigationService.navigationActionTypes.modalCancel);
                                    navigationService.closeCurrentModal(modalResult);
                                }
                            });
        };

        function getModalResult(dialogResult) {
            var modalResult = new navigationService.modalResult()
            var resultData = {
            	IsEventManualByActivity: vm.vhrEventManualUpdateDateClientData.IsEventManualByActivity,
            	VhrEventManualRequestTypeId: vm.vhrEventManualUpdateDateClientData.IsEventManualByActivity
												? vhrEventManualRequestType.NewByActivity
												: vhrEventManualRequestType.New,
            }

            modalResult.data = resultData;
            modalResult.sourceParams = pParams;

            // Important: permits to skip nexts steps (on next and previous)
            modalResult.stepsToSkip = [];
            if (!vm.vhrEventManualUpdateDateClientData.IsEventManualByActivity) {
            	modalResult.stepsToSkip.push('vhrEventsManualStepActivitySelection');            // skip cron expression
            }

        	modalResult.dialogResult = dialogResult;
            return modalResult;
        }
    };

    vhrEventsManualStepSwitchFromActivity.$inject = injectParams;
    vhrEventsManualStepSwitchFromActivity.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('vhrEventsManualStepSwitchFromActivity', vhrEventsManualStepSwitchFromActivity);
});