﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'hrbucketService', 'reportService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var virtualEquipmentQrCodeListReportForm = function ($q, $scope, $timeout, $injector, foundationService, hrbucketService, reportService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        
        var checkStateChangeParams = { dirtyFlagIsDisabled: vm.isDirtyDisabled };
        checkStateChangeService.checkFormOnStateChange($scope, foundationService, checkStateChangeParams);

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var currentSystemCategoryCode = 'ALB_IMP'; //navigationService.stateParams().systemCategoryCode;
        var viewMode = navigationService.currentViewMode();
        var bucketClassificationEnum = Nembus.Common.Library.util.parseEnum(hrbucketService.dataContext.enumTypes.BucketClassification, null, vm.translate).obj.normal.name_value;

        // all form items are enabled
        vm.setIsEditingReport();

        //#region Multiselect with grid selection
        var selectionParams = { destModalKey: 'category', isAutocheck: false, systemCategoryCode: currentSystemCategoryCode, viewKind: 'all' };
        vm.nbsSelectionTreeviewWithModalCategory = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        selectionParams = { destModalKey: 'vhr', entityMode: 'AssetTree', translateKey: 'VirtualEquipment', isMultiselect: true };
        vm.nbsSelectionGridWithModalAssetTree = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        var dsCallback = function () { return hrbucketService.getHrBuckets({ BucketClassificationId: bucketClassificationEnum.GenericLegalEntity, OwnerAppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'HrBucket', viewTitle: vm.translate('LegalEntities'), ds: dsCallback };
        vm.nbsSelectionGridWithModalLegalEntities = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        dsCallback = function () { return hrbucketService.getHrBuckets({ BucketClassificationId: bucketClassificationEnum.CostCenter, OwnerAppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'HrBucket', viewTitle: vm.translate('CostCenters'), ds: dsCallback };
        vm.nbsSelectionGridWithModalCostCenters = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        dsCallback = function () { return hrbucketService.getHrBuckets({ BucketClassificationId: bucketClassificationEnum.BusinessUnit, OwnerAppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'HrBucket', viewTitle: vm.translate('BusinessUnits'), ds: dsCallback };
        vm.nbsSelectionGridWithModalBusinessUnits = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        dsCallback = function () { return hrbucketService.getHrBuckets({ BucketClassificationId: bucketClassificationEnum.Sector, OwnerAppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'HrBucket', viewTitle: vm.translate('Sectors'), ds: dsCallback };
        vm.nbsSelectionGridWithModalSectors = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        dsCallback = function () { return hrbucketService.getHrBuckets({ BucketClassificationId: bucketClassificationEnum.Plant, OwnerAppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'HrBucket', viewTitle: vm.translate('Plants'), ds: dsCallback };
        vm.nbsSelectionGridWithModalPlants = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        //#endregion

        // posted filter
        vm.currentReportFilter = {};

        // set title of current controller in report definition detail view
        navigationService.setControllerTitle();

        var customKey = null;
        if (viewMode === 'a4_2x4') {
            customKey = 'qrcode_2_cols';
        } else if (viewMode === 'a4_3x8') {
            customKey = 'qrcode_3_cols';
        }

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getVhrTypes(forceReload));
            funcsPromises.push(getVhrGroups(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.vhrTypes = results[0];
                    vm.vhrGroups = results[1];

                    reportService.currentReport.concatName = '';
                    reportService.currentReport.prepareReportFilterCallback = setReportFilter;
                });
        }

        function setReportFilter() {
            var arrayList = [];
            if (vm.nbsSelectionGridWithModalLegalEntities) { arrayList.push(vm.nbsSelectionGridWithModalLegalEntities.selectedIds); }
            if (vm.nbsSelectionGridWithModalCostCenters) { arrayList.push(vm.nbsSelectionGridWithModalCostCenters.selectedIds); }
            if (vm.nbsSelectionGridWithModalBusinessUnits) { arrayList.push(vm.nbsSelectionGridWithModalBusinessUnits.selectedIds); }
            if (vm.nbsSelectionGridWithModalSectors) { arrayList.push(vm.nbsSelectionGridWithModalSectors.selectedIds); }
            if (vm.nbsSelectionGridWithModalPlants) { arrayList.push(vm.nbsSelectionGridWithModalPlants.selectedIds); }

            reportService.currentReport.reportArgs = {
                Ds1_Arg1: {//VirtualEquipmentFilter
                    HaveToExpandVhrsWithAssetTree: true,
                    VirtualHumanResourcesId: vm.nbsSelectionGridWithModalAssetTree.selectedIds,
                    EquipmentName: vm.currentReportFilter.EquipmentName,
                    EquipmentDescription: vm.currentReportFilter.EquipmentDescription,
                    SerialNumber: vm.currentReportFilter.SerialNumber,
                    RegistrationNumber: vm.currentReportFilter.RegistrationNumber,
                    CategoriesId: vm.nbsSelectionTreeviewWithModalCategory.selectedIds,
                    VhrGroupId: vm.currentReportFilter.VhrGroupId,
                    VhrTypeId: vm.currentReportFilter.VhrTypeId,
                    HrBucketIds: Nembus.Common.Library.util.getJoinedArrayValues(arrayList)
                },
                Ds1_Arg2: {//QueryOptions
                    CustomKey: customKey       // in this case, used to set number of columns in report to cycle
                }
            };
        }

        function getVhrTypes(forceReload) {
            var filter = {};
            var defer = $q.defer();

            foundationService.getVhrTypes(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getVhrGroups(forceReload) {
            var filter = {};
            var defer = $q.defer();

            foundationService.getVhrGroups(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }
    };

    virtualEquipmentQrCodeListReportForm.$inject = injectParams;
    virtualEquipmentQrCodeListReportForm.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('virtualEquipmentQrCodeListReportForm', virtualEquipmentQrCodeListReportForm);
});
