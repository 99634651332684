﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$injector', '$scope', '$timeout', 'notificationService', 'navigationService', 'contextService'];
    function myNotificationsList($q, $injector, $scope, $timeout, notificationService, navigationService, contextService) {
        var vm = this;
        nbsVm.call(vm, notificationService, navigationService, contextService, $q, $injector, $timeout);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var notificationStatusEnumParsed = Nembus.Common.Library.util.parseEnum(notificationService.dataContext.enumTypes.NotificationStatus, 'Notification', vm.translate);
        var notificationStatusEnumObj = notificationStatusEnumParsed.obj.normal.name_value;
        var notificationChannelKindEnumParsed = Nembus.Common.Library.util.parseEnum(notificationService.dataContext.enumTypes.NotificationChannelKind, 'Notification', vm.translate);
        var notificationChannelKindEnumObj = notificationChannelKindEnumParsed.obj.normal.name_value;

        ribbon.setRibbonStatusButton(ribbon.Buttons.New,  false);

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('notification', 'mynotifications');
        nbsGridDatacontext.viewCustomBar();
        nbsGridDatacontext.setAdaptToPage();
        nbsGridDatacontext.setDatasource([], false); // fake datasource, only to create grid
        vm.nbsGridDatacontext = nbsGridDatacontext;

        // hook from gridbar
        var ejDataManager = null;
        var currentGridFilter = null;
        vm.notificationBarOnChanges = function (result) {
            currentGridFilter = result.notificationFilter;
            currentGridFilter.NotificationChannelKind = notificationChannelKindEnumObj.ChannelWebApp;

            if (ejDataManager === null) {
                var supportData = { isMyNotifications: true, translate: vm.translate, contextService: contextService };
                ejDataManager = notificationService.getNotificationDataManager(supportData, currentGridFilter, ejDataManager);
                nbsGridDatacontext.setDatasource(ejDataManager, true);
                refreshGridDatasource(currentGridFilter);

            } else {
                refreshGridDatasource(currentGridFilter)
            }
        };

        vm.rowTemplateOnClick = function (element) {
            if (element) {
                var notificationId = Number(element.attributes['notificationId'].value);
                if (notificationId) {
                    notificationService.getMyNotification({ NotificationId: notificationId }, true)
                        .then(function (data) {
                            vm.currentNotification = data.results[0];

                            if (vm.currentNotification.NotificationChannelKindId !== notificationChannelKindEnumObj.ChannelWebApp) {
                                return;
                            }

                            vm.currentNotification.NotificationContentParsedUI = Nembus.Common.Library.converter.decodeHtml(vm.currentNotification.NotificationContent);
                            vm.currentNotification.entityAspect.setUnchanged();

                            vm.modalInstance = navigationService.modal().open(vm.modalOptions({ templateUrl: 'previewWebNotificationTemplate', scope: $scope }));
                            vm.modalInstance.title = vm.translate('Oreview');
                            vm.modalInstance.datasource = vm.currentTemplate;
                            vm.modalInstance.ok = function () {
                                vm.currentNotification.NotificationStatusId = notificationStatusEnumObj.NotificationArchived;

                                notificationService.saveChanges()
                                    .then(function (result) {
                                        // decrease notification from couter in apppanel
                                        notificationService.decreaseMyNotificationCounter();

                                        vm.modalInstance.close();

                                        vm.refreshAction();
                                    });

                            };
                            vm.modalInstance.cancel = function () { vm.modalInstance.dismiss(); };
                            vm.modalInstance.result.then(function () {
                                // onClose
                            }, function () {
                                // onDismiss
                            });
                        });
                }
            }
        };

        vm.createGridEvent = function (result) {
            vm.notificationBarDataContext = {
                commonBarDatasource: result.barDatasource,
                defaultKeys: {
                    columnsKey: null, sortingKey: null
                }
            };
        };

        vm.gridUiOnChanges = function (result) {
            vm.refreshGridUiComponent = {
                refreshKey: new Date().getTime(),
                uiData: result
            }
        }

        vm.refreshAction = function () {
            $timeout(() => {
                vm.forceGridRefresh = new Date().getTime();
            });
        };

        function refreshGridDatasource(currentGridFilter) {
            $timeout(() => {
                vm.forceDatamanagerFilter = {
                    refreshKey: new Date().getTime(),
                    filter: currentGridFilter
                }
            });
        }
    }

    myNotificationsList.$inject = injectParams;
    myNotificationsList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('myNotificationsList', myNotificationsList);
});