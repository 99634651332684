﻿'use strict';
define([], function () {

    var injectParams = ['$timeout'];
    var nbsDdSearchMsComponent = function ($timeout) {
        var vm = this;
        var pDropdownDS = null;
        var nCharToStartSearch = 2;
        var ddElement = null;
        var newContainerId = null;
        var idDD = null;
        var imIntoModal = false;
        var isEnabledBooleanFilterIsSetted = false;
        var isEnabledBooleanFilterMode = null;

        vm.msDS = [];
        vm.dropdownSelected = [];
        vm.dropdownDS = [];

        vm.ddIconType = '';
        vm.localMsBoxIsVisible = true;

        vm.$onInit = function () {
            var guid = NbsGuidManager.newGuid().replaceAll(" - ", "");
            newContainerId = "nbsDdSearchMsContainer_" + guid;
            $('#nbsDdSearchMsContainer').attr('id', newContainerId);
            $('#nbsDdSearchMsContainerText').attr('id', 'text_' + guid);

            idDD = newContainerId + '_DropDown';
            $('#' + newContainerId).find('nbs-dd').attr('id', idDD);
            ddElement = $('#' + newContainerId).find('#' + idDD);

            imIntoModal = $('#' + newContainerId).parents('.modal-content').length > 0;
        };

        vm.$onChanges = function (changes) {
            if (changes.nbsDataContext
                && changes.nbsDataContext.currentValue
                && Lazy(changes.nbsDataContext.currentValue).some()
            ) {
                pDropdownDS = changes.nbsDataContext.currentValue;

                if (Lazy(pDropdownDS).filter((item) => { return item.isEnabledFilter == true || item.isEnabledFilter == false }).some()) {
                    vm.isEnabledBooleanFilter = 'isenabled-boolean-filter';
                }
            }

            if (changes.nbsDataSelected
                && changes.nbsDataSelected.currentValue
                && Lazy(changes.nbsDataSelected.currentValue).some()
            ) {
                $timeout(() => {
                    // set datasource for multiselect
                    vm.msDS = changes.nbsDataSelected.currentValue;
                });
            }

            if (changes.msBoxIsVisible && changes.msBoxIsVisible.currentValue) {
                vm.localMsBoxIsVisible = vm.msBoxIsVisible === 'false' || !vm.msBoxIsVisible ? false : true;
            }
        };

        vm.onOptionChangesMsEvent = function (sourceOptions) {
            vm.msDS = sourceOptions;
            vm.optionChangesEvent({ options: sourceOptions });
        };

        // when option selected in dropdown
        vm.onDdChanges = function (options) {
            // on selection option is added if not exists
            //vm.localTxtSearch = '';
            vm.msDS = Lazy(pDropdownDS).where({ isChecked: true }).toArray();
            vm.optionChangesEvent({ options: Lazy(vm.msDS).toArray() });
        };

        vm.onDdActionChanges = function (result) {
            if (result.actionKind === 'isenabled-boolean-filter') {
                isEnabledBooleanFilterIsSetted = true;
                isEnabledBooleanFilterMode = null;

                var isEnabledAction = Lazy(result.actions).findWhere({ optionCode: 'enabled' });
                var isDisabledAction = Lazy(result.actions).findWhere({ optionCode: 'disabled' });

                if (isEnabledAction && isEnabledAction.isChecked === true) {
                    isEnabledBooleanFilterMode = 'enabled';
                }
                if (isDisabledAction && isDisabledAction.isChecked === true) {
                    if (!isEnabledBooleanFilterMode) {
                        isEnabledBooleanFilterMode = 'disabled';
                    } else {
                        isEnabledBooleanFilterMode = 'enabled+disabled';
                    }
                }

                refreshDropdownDS();
            }
        };

        vm.localTxtSearchOnChange = function () {
            if (!$(ddElement).find('.dropdown').hasClass('open')) {
                $(ddElement).find('.dropdown').addClass('open');
            }

            if (!vm.localTxtSearch) {
                vm.localTxtSearch = '';
            }

            refreshDropdownDS();
        };

        function multiselectHasOption(option) {
            return Lazy(vm.msDS).where({ optionValue: option.optionValue }).some();
        }

        function refreshDropdownDS() {
            var tmp = [];

            if (vm.localTxtSearch.length < nCharToStartSearch) {
                vm.dropdownDS = tmp;
            } else {
                Lazy(pDropdownDS)
                    .each((option) => {
                        option.isChecked = multiselectHasOption(option);

                        if (option.optionDisplayName && option.optionDisplayName.toLowerCase().indexOf(vm.localTxtSearch.toLowerCase()) >= 0) {
                            option.optionVisible = true;

                            if (isEnabledBooleanFilterIsSetted) {
                                switch (isEnabledBooleanFilterMode) {
                                    case 'enabled':
                                        if (option.isEnabledFilter) {
                                            tmp.push(option);
                                        }
                                        break
                                    case 'disabled':
                                        if (!option.isEnabledFilter) {
                                            tmp.push(option);
                                        }
                                        break
                                    case 'enabled+disabled':
                                        tmp.push(option);
                                        break
                                }
                            } else {
                                tmp.push(option);
                            }
                        }
                    });
            }

            $timeout(() => {
                vm.dropdownDS = tmp;
                
                if (imIntoModal) {
                    // force toggle dropdown because into modal doesn't works
                    if (vm.dropdownDS.length > 0) {
                        $('#' + newContainerId).find('.nbs-dd.dropdown').addClass('open');
                    } else {
                        $('#' + newContainerId).find('.nbs-dd.dropdown').removeClass('open');
                    }
                }
            });
        }
    };

    nbsDdSearchMsComponent.$inject = injectParams;
    angular.module('nbs.rds.module').component('nbsDdSearchMs', {
        templateUrl: '/rds/common/components/nbsDdSearchMsComponent/_?c=' + window.codeCache(),
        controller: nbsDdSearchMsComponent,
        controllerAs: 'vm',
        bindings: {
            optionChangesEvent: '&',
            nbsDataContext: "<", //[uiOptions,uiOptions]
            nbsDataSelected: "<",
            isVisible: "<",
            msBoxIsVisible: "<",
            uiContext: "<",
            ddIconType: "@",
            ddLabel: '<'
        }
    });
});
