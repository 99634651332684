﻿'use strict';

define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'vhreventService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function vhrEventsManualStepVhr($q, $scope, $timeout, $injector, foundationService, vhreventService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, foundationService);

        // entity shared by vhreventService: is validated on next click
        var vhrEventManualCollector = vhreventService.vhrEventManualCollector;
        // ims/tec asset uses tec appstorecode
        //var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreCode = 'tec';
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var pParams = navigationService.stateParamsModal().parentParams;
        pParams = pParams ? pParams : navigationService.stateParams();
        
        // beardcrumb information
        var beardCrumbInfo = JSON.parse(pParams.beardCrumbInfo);
        Lazy(beardCrumbInfo.items).each((item) => { item.title = vm.translate(item.title); });
        vm.beardCrumbInfo = beardCrumbInfo;

        // navigation buttons visibility
        vm.setWizardButtonVisibility(pParams);

        // before load data all grids are hidden
        vm.entityIsAsset = false;
        vm.entityIsAvatar = false;

        var nbsGridAvatarDatacontext = new NbsGrid(vm.translate);
        nbsGridAvatarDatacontext.configureGrid('avatar', 'wizard');
        nbsGridAvatarDatacontext.setModalMode();
        nbsGridAvatarDatacontext.setIsMultiselect();
        nbsGridAvatarDatacontext.setDatasource([], false);
        vm.nbsGridAvatarDatacontext = nbsGridAvatarDatacontext;

        var nbsGridEquipmentGeneralityDatacontext = new NbsGrid(vm.translate);
        nbsGridEquipmentGeneralityDatacontext.configureGrid('equipmentgenerality', null);
        nbsGridEquipmentGeneralityDatacontext.allowSearching = true;
        nbsGridEquipmentGeneralityDatacontext.setModalMode();
        nbsGridEquipmentGeneralityDatacontext.setIsMultiselect();
        nbsGridEquipmentGeneralityDatacontext.setDatasource([], false);
        vm.nbsGridEquipmentGeneralityDatacontext = nbsGridEquipmentGeneralityDatacontext;

        // virtual human resources must be resetted and reselected
        var virtualHumanResourcesSelected = [];

        loadData(true);
        function loadData(forceReload) {
            switch (vhrEventManualCollector.VhrEntityType) {
                case 'asset':
                    vm.entityIsAsset = true;
                    getVirtualEquipments(true, appStoreBw);
                    break;
                case 'avatar':
                    vm.entityIsAvatar = true;
                    getAvatars(true, appStoreBw);
                    break;
            }
        }

        //vm.queryCellInfo = function (args) {
        //    switch (args.column.field) {
        //        case 'PersonGenerality.Dob':
        //            args.cell.innerHTML = Nembus.Common.Library.converter.date.formatDate(args.data.PersonGenerality.Dob, contextService.virtualContext.cultureInfo, true);
        //            break
        //    }
        //};

        vm.gridSelection = function (args) {
            virtualHumanResourcesSelected = args.data;
        };

        vm.modalEnd = function () {
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalEnd);
            navigationService.closeCurrentModal(modalResult);
        };

        vm.modalPrevious = function () {
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalPrevious);
            navigationService.closeCurrentModal(modalResult);
        };

        vm.modalNext = function () {
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalNext);
            var errorMessage = "";
            switch (vhrEventManualCollector.VhrEntityType) {
                case 'asset':
                    errorMessage = vm.translate('SelectAsset');
                    break;
                case 'avatar':
                    errorMessage = vm.translate('SelectAvatar');
                    break;
            }
            if (virtualHumanResourcesSelected.length === 0) {
                vm.alertConfig.isModal = true;
                vm.alertConfig.isReadonly = true;
                vm.removeAlertFromList(true);
                vm.setErrorMsg({
                    message: errorMessage,
                    isMessageVisible: true
                });
            } else {
                navigationService.closeCurrentModal(modalResult);
            }
        };

        // close modal and exit wizard
        vm.modalCancel = function () {
            navigationService.confirmExitWizard()
                .then(function (result) {
                    if (result) {
                        var modalResult = getModalResult(navigationService.navigationActionTypes.modalCancel);
                        navigationService.closeCurrentModal(modalResult);
                    }
                });
        };

        function getAvatars(forceReload, appStoreBw) {
            var filter = { AppStoreBw: appStoreBw };
            var defer = $q.defer();

            foundationService.getAvatars(filter, forceReload)
                .then(function (data) {
                    vm.forceDatasourceAvatarLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: data.results
                    }

                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVirtualEquipments(forceReload, appStoreBw) {
            var filter = { AppStoreBw: appStoreBw };
            var defer = $q.defer();

            foundationService.getVirtualEquipments(filter, forceReload)
                .then(function (data) {
                    vm.forceDatasourceEquipmentGeneralityLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: data.results
                    }

                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getModalResult(dialogResult) {
            var modalResult = new navigationService.modalResult();
            virtualHumanResourcesSelected = Lazy(virtualHumanResourcesSelected)
                .map((vhr) => {
                    return {
                        VirtualHumanResourceId: vhr.VirtualHumanResourceId,
                        VirtualName: vhr.VirtualName
                    };
                }).toArray();

            var resultData = {
                VirtualHumanResources: virtualHumanResourcesSelected
            };
            modalResult.data = resultData;
            modalResult.sourceParams = pParams;
            modalResult.dialogResult = dialogResult;
            return modalResult;
        }
    }

    vhrEventsManualStepVhr.$inject = injectParams;
    vhrEventsManualStepVhr.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('vhrEventsManualStepVhr', vhrEventsManualStepVhr);
});