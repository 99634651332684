﻿'use strict';
define([], function () {

    var injectParams = ['$q', '$timeout', 'contextService', 'currentSessionService', 'drbacService', 'rbacService'];
    var nbsAppUserBarComponent = function ($q, $timeout, contextService, currentSessionService, drbacService, rbacService) {
        var vm = this;
        var privateDataContext = null;
        var pAvailableVirtualCompanies = [];
        var pDrbacUserGroups = [];
        var pDrbacRoles = [];
        var pUserProfiles = [];
        var privateUIData = null;
        var defaultKeys;
        var appUserType = null;         // 'global' or 'context'
        var nbsUiOptionParser = null;

        vm.privateComponentsStatus = {};
        vm.nbsDataPrivate = {};

        vm.$onInit = function () {
        };

        vm.$onChanges = function (changes) {
            if (changes.nbsDataContext && changes.nbsDataContext.currentValue) {
                nbsUiOptionParser = new NbsUiOptionParser(vm.uiContext.translate);

                privateDataContext = changes.nbsDataContext.currentValue;
                appUserType = privateDataContext.appUserType;

                // set default visible columns list in grid
                defaultKeys = changes.nbsDataContext.currentValue.defaultKeys;

                // Start of execution workflow of load data bar components 
                initViewTitle();
            }
        };

        function initViewTitle() {
            var cols = ['XCentralUserId', 'DisplayName', 'Username', 'IsEnabled', 'IsProgrammaticAccess', 'IsSa'];
            var defaultStartData = new NbsUiData();
            defaultStartData.setColumns(cols);
            defaultStartData.setFilterList(getFilterList());

            var sortField = 'Username';
            var sortDirection = false; // ascending
            //switch (defaultKeys.sortingKey) {

            //}
            defaultStartData.setSortingField(sortField, sortDirection);

            vm.initViewTitle = defaultStartData.startData;
        }

        //#region hook from components
        vm.onViewTitleSelectionEvent = function (result) {
            privateUIData = JSON.parse(JSON.stringify(result.uiData));
            setComponents(privateUIData);

            setBarDatasources()
                .then(function () {
                    barOnChanges(privateUIData);
                });
        };
        vm.onUiChanges = function (result) {
            privateUIData.columns = result.uiData.columns;
            privateUIData.grouping = result.uiData.grouping;
            privateUIData.sorting = result.uiData.sorting;
            privateUIData.filters.freeText = result.uiData.filters.freeText;

            refreshViewTitleDataContext(privateUIData);

            vm.onGridUiChangesEvent({ result: privateUIData });
        };
        vm.onFilterChanges = function (result) {
            privateUIData.filterList = result.filters.filterList;

            refreshViewTitleDataContext(privateUIData);
            setStatusComponents(privateUIData.filterList);

            setBarDatasources()
                .then(function () {
                    barOnChanges(privateUIData);
                });
        };
        vm.onBooleanAppUserIsProgrammaticAccess = function (value) {
            privateUIData.filters.appuserisprogrammaticaccess = value;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onBooleanAppUserIsEnabled = function (value) {
            privateUIData.filters.appuserisenabled = value;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onBooleanAppUserIsSa = function (value) {
            privateUIData.filters.appuserissa = value;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdMsVirtualCompanies = function (options) {
            privateUIData.filters.appuservirtualcompanies = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdMsDrbacUserGroups = function (options) {
            privateUIData.filters.appuserdrbacusergroups = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdMsDrbacRoles = function (options) {
            privateUIData.filters.appuserdrbacroles = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdMsUserProfiles = function (options) {
            privateUIData.filters.appuserdrbacuserprofiles = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        //#endregion

        // refresh current preference uiData
        function refreshViewTitleDataContext(uiDataParam) {
            vm.viewTitleDataContext = Nembus.Common.Library.component.getDataContextViewTitle(uiDataParam);
        }

        function setBarDatasources() {
            var defer = $q.defer();
            var funcsPromises = [];

            funcsPromises.push(getAvailableVirtualCompanies(false));
            funcsPromises.push(getDrbacUserGroups(false));
            funcsPromises.push(getDrbacRoles(false));
            funcsPromises.push(getUserProfiles(false));

            $q.all(funcsPromises)
                .then(function () {
                    vm.nbsDataPrivate.appuservirtualcompanies = nbsUiOptionParser.getUiOptionsByKey(pAvailableVirtualCompanies, 'VirtualCompany');
                    vm.nbsDataPrivate.appuserdrbacusergroups = nbsUiOptionParser.getUiOptionsByKey(pDrbacUserGroups, 'DrbacUserGroup');
                    vm.nbsDataPrivate.appuserdrbacroles = nbsUiOptionParser.getUiOptionsByKey(pDrbacRoles, 'DrbacRole');
                    vm.nbsDataPrivate.appuserdrbacuserprofiles = nbsUiOptionParser.getUiOptionsByKey(pUserProfiles, 'UserProfile');
                    defer.resolve(true);
                });

            return defer.promise;
        }

        function getAvailableVirtualCompanies(forceReload) {
            var defer = $q.defer();

            var funcsPromises = [];
            $q.all(funcsPromises)
                .then(function (results) {
                    currentSessionService.getAvailableVirtualCompanies()
                        .then(function (data) {
                            pAvailableVirtualCompanies = data.results;
                            defer.resolve(true);
                        })
                        .catch(function (error) {
                            vm.setErrorMsg(error);
                        });
                });

            return defer.promise;
        }

        function getDrbacUserGroups(forceReload) {
            var filter = {
                VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId
            };
            var defer = $q.defer();

            drbacService.getDrbacUserGroups(filter, forceReload)
                .then(function (data) {
                    pDrbacUserGroups = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getDrbacRoles(forceReload) {
            var filter = {};
            var defer = $q.defer();

            drbacService.getDrbacRoles(filter, forceReload)
                .then(function (data) {
                    pDrbacRoles = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getUserProfiles(forceReload) {
            var filter = {};
            var defer = $q.defer();

            rbacService.getUserProfiles(filter, forceReload)
                .then(function (data) {
                    pUserProfiles = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getFilterList() {
            var uiDataFilters = [];
            uiDataFilters.push(new NbsUiDataFilter({ code: 'appuserisprogrammaticaccess', name: 'ContextUserDto.IsProgrammaticAccess' }));
            uiDataFilters.push(new NbsUiDataFilter({ code: 'appuserisenabled', name: 'ContextUserDto.IsEnabled', visible: true }));
            uiDataFilters.push(new NbsUiDataFilter({ code: 'appuserissa', name: 'ContextUserDto.IsSa' }));
            
            if (appUserType === 'global') {
                uiDataFilters.push(new NbsUiDataFilter({ code: 'appuservirtualcompanies', name: 'VirtualCompany' }));

            } else if (appUserType === 'context') {
                uiDataFilters.push(new NbsUiDataFilter({ code: 'appuserdrbacusergroups', name: 'UserGroups', visible: true }));
                uiDataFilters.push(new NbsUiDataFilter({ code: 'appuserdrbacroles', name: 'DrbacRoles', visible: true }));
                uiDataFilters.push(new NbsUiDataFilter({ code: 'appuserdrbacuserprofiles', name: 'DrbacProfiles' }));
            }
            return uiDataFilters;
        }

        function setComponents(uiDataParam) {
            setCommonGridBarDataContext(uiDataParam);
            setSelectionComponents(uiDataParam);
            setStatusComponents(uiDataParam.filterList);
            vm.onGridUiChangesEvent({ result: privateUIData });
        }

        function setCommonGridBarDataContext(uiDataParam) {
            vm.commonGridBarDataContext = Nembus.Common.Library.component.getDataContextCommonGridBar(uiDataParam);
            vm.commonBarDatasource = privateDataContext.commonBarDatasource
        }

        function setSelectionComponents(uiDataParam) {
            vm.nbsDataPrivate.appuserisprogrammaticaccess = uiDataParam.filters.appuserisprogrammaticaccess;
            vm.nbsDataPrivate.appuserisenabled = uiDataParam.filters.appuserisenabled;
            vm.nbsDataPrivate.appuserissa = uiDataParam.filters.appuserissa;

            vm.nbsDataPrivate.appuservirtualcompaniesselected = uiDataParam.filters.appuservirtualcompanies ? uiDataParam.filters.appuservirtualcompanies : [];
            vm.nbsDataPrivate.appuserdrbacusergroupsselected = uiDataParam.filters.appuserdrbacusergroups ? uiDataParam.filters.appuserdrbacusergroups : [];
            vm.nbsDataPrivate.appuserdrbacrolesselected = uiDataParam.filters.appuserdrbacroles ? uiDataParam.filters.appuserdrbacroles : [];
            vm.nbsDataPrivate.appuserdrbacuserprofilesselected = uiDataParam.filters.appuserdrbacuserprofiles ? uiDataParam.filters.appuserdrbacuserprofiles : [];
        }

        function setStatusComponents(filterList) {
            vm.privateComponentsStatus.appuserisprogrammaticaccess = Nembus.Common.Library.component.getStatus('appuserisprogrammaticaccess', filterList);
            vm.privateComponentsStatus.appuserisenabled = Nembus.Common.Library.component.getStatus('appuserisenabled', filterList);
            vm.privateComponentsStatus.appuserissa = Nembus.Common.Library.component.getStatus('appuserissa', filterList);

            vm.privateComponentsStatus.appuservirtualcompanies = Nembus.Common.Library.component.getStatus('appuservirtualcompanies', filterList);
            vm.privateComponentsStatus.appuserdrbacusergroups = Nembus.Common.Library.component.getStatus('appuserdrbacusergroups', filterList);
            vm.privateComponentsStatus.appuserdrbacroles = Nembus.Common.Library.component.getStatus('appuserdrbacroles', filterList);
            vm.privateComponentsStatus.appuserdrbacuserprofiles = Nembus.Common.Library.component.getStatus('appuserdrbacuserprofiles', filterList);
        }

        function barOnChanges(uiDataParam) {
            var isProgrammaticaccess = null;
            var isEnabled = null;
            var isSa = null;
            var virtualCompaniesId = [];
            var drbacUserGroupIds = [];
            var drbacRoleIds = [];
            var userProfileIds = [];

            if (vm.privateComponentsStatus.appuserisprogrammaticaccess.isVisible) {
                isProgrammaticaccess = uiDataParam.filters.appuserisprogrammaticaccess;
            }
            if (vm.privateComponentsStatus.appuserisenabled.isVisible) {
                isEnabled = uiDataParam.filters.appuserisenabled;
            }
            if (vm.privateComponentsStatus.appuserissa.isVisible) {
                isSa = uiDataParam.filters.appuserissa;
            }
            if (vm.privateComponentsStatus.appuservirtualcompanies.isVisible) {
                virtualCompaniesId = Lazy(uiDataParam.filters.appuservirtualcompanies).pluck('optionValue').toArray();
            }
            //if (!Lazy(virtualCompaniesId).some()) {
            //    virtualCompaniesId = Lazy(pAvailableVirtualCompanies).pluck('VirtualCompanyId').toArray();
            //}

            if (vm.privateComponentsStatus.appuserdrbacusergroups.isVisible) {
                drbacUserGroupIds = Lazy(uiDataParam.filters.appuserdrbacusergroups).pluck('optionValue').toArray();
            }

            if (vm.privateComponentsStatus.appuserdrbacroles.isVisible) {
                drbacRoleIds = Lazy(uiDataParam.filters.appuserdrbacroles).pluck('optionValue').toArray();
            }

            if (vm.privateComponentsStatus.appuserdrbacuserprofiles.isVisible) {
                userProfileIds = Lazy(uiDataParam.filters.appuserdrbacuserprofiles).pluck('optionValue').toArray();
            }




            var filter = {};
            if (appUserType === 'global') {
                filter = {
                    globalUserFilter: {
                        IsProgrammaticAccess: isProgrammaticaccess,
                        IsEnabled: isEnabled,
                        IsSa: isSa,

                        AppUserFilter: {
                            VirtualCompaniesId: virtualCompaniesId
                        }
                    }
                }
            } else if (appUserType === 'context') {
                // ContextUserClientData
                filter = {
                    appUserFilter: {
                        XCentralUserId: null, // no remove
                        DrbacUserGroupIds: drbacUserGroupIds,
                        DrbacRoleIds: drbacRoleIds,
                        DrbacProfileIds: userProfileIds,
                    },
                    drbacGlobalAppUserSettingFilter: {
                        XCentralUserId: null, // no remove
                        IsProgrammaticAccess: isProgrammaticaccess,
                        IsEnabled: isEnabled,
                        IsSa: isSa,
                    }
                }
            }

            $timeout(() => {
                vm.onBarChanges({ result: filter });
            }, 0);
        }
        //#endregion 
    };

    nbsAppUserBarComponent.$inject = injectParams;
    angular.module('nbs.rbac.module').component('nbsAppUserBar', {
        templateUrl: '/rbac/users/components/nbsAppUserBarComponent/_?c=' + window.codeCache(),
        controller: nbsAppUserBarComponent,
        controllerAs: 'vm',
        bindings: {
            onBarChanges: '&',
            onGridUiChangesEvent: '&',

            nbsDataContext: "<",
            uiContext: "<"
        }
    });
});

