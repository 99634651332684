﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$injector', '$scope', '$timeout', 'workflowService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function workflowsList($q, $injector, $scope, $timeout, workflowService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, workflowService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return workflowSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, workflowService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        ribbon.addTab(new ribbon.Tab("ActionPanelTabManage", [ribbon.GetActiveSection()]));
        vm.ribbonDatacontext = ribbon;

        var workflowSelected = null;

        vm.currentWorkflows = [];

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('workflow', null);
        nbsGridDatacontext.viewCommonBar();
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        loadData(true);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getWorkflowDtos(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.currentWorkflows = results[0];

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.currentWorkflows
                    }
                });
        }

        vm.activeAction = function (args) {
            if (!workflowSelected) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        workflowService.activeWorkflow({ WorkflowId: workflowSelected.WorkflowId })
                            .then((data) => {
                                if (data.results[0] === '-1') {
                                    navigationService.customMessageModal('RequestError');
                                } if (data.results[0] === '-2') {
                                    navigationService.customMessageModal('WorkflowNotComplete');
                                } if (data.results[0] === '1') {
                                    vm.refreshAction();
                                }
                            });
                    }
                });
        };

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.workflowdetail-ats", { 'workflowId': args.data.WorkflowId });
        };

        vm.newAction = function () {
            navigationService.navigateTo("app.nav.workflowdetail-ats", { 'workflowId': null });
        };

        vm.refreshAction = function () {
            loadData(true);
        };

        vm.deleteAction = function () {
            if (!workflowSelected) {
                return;
            }

            workflowService.deleteEntity(workflowSelected);
            vm.submitAction()
                .then(function (saveResult) {
                    vm.refreshAction();
                });
        };

        vm.gridSelection = function (args) {
            $timeout(() => {
                workflowSelected = args.data;

                if (workflowSelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, !workflowSelected.IsSystem && !workflowSelected.IsActive);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Active, true);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Active, false);
                }

                ribbon.refreshStatusButtons();
            });
        };

        function getWorkflowDtos(forceReload) {
            var filter = {};
            var defer = $q.defer();

            workflowService.getWorkflowDtos(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    }

    workflowsList.$inject = injectParams;
    workflowsList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('workflowsList', workflowsList);
});