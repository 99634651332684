import { InjectionToken } from "@angular/core";


export const AuthenticationService = new InjectionToken("AuthenticationService");

export function authenticationServiceFactory(i: any) {
    return i.get("authenticationService");
}

export const authenticationServiceProvider = {
    provide: AuthenticationService,
    useFactory: authenticationServiceFactory,
    deps: ['$injector']
};
