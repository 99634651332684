﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function virtualCompaniesList($q, $scope, $timeout, $injector, foundationService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return virtualCompanySelected; });
        checkStateChangeService.checkFormOnStateChange($scope, foundationService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var virtualCompanySelected = null;

        vm.currentVirtualCompanies = [];

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('virtualcompany', 'list');
        nbsGridDatacontext.viewCommonBar();
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getVirtualCompanies(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.currentVirtualCompanies = results[0];

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.currentVirtualCompanies
                    }
                });
        }

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.virtualcompanyftc", { 'virtualCompanyId': args.data.VirtualCompanyId });
        };

        vm.gridSelection = function (args) {
            $timeout(() => {
                virtualCompanySelected = args.data;

                if (virtualCompanySelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, true);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                }

                ribbon.refreshStatusButtons();
            });
        };

        vm.newAction = function () {
            navigationService.navigateTo("app.nav.virtualcompanyftc", { 'virtualCompanyId': null });
        };

        vm.refreshAction = function () {
            loadData(true);
        };

        vm.deleteAction = function () {
            if (!virtualCompanySelected) {
                return;
            }

            foundationService.deleteEntity(virtualCompanySelected);
            vm.submitAction()
                .then(function (data) {
                    vm.refreshAction();
                });
        };

        function getVirtualCompanies(forceReload) {
            var defer = $q.defer();

            foundationService.getVirtualCompanies({}, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    }

    virtualCompaniesList.$inject = injectParams;
    virtualCompaniesList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('virtualCompaniesList', virtualCompaniesList);
});