﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {
    var injectParams = ['$q', '$injector', '$scope', '$timeout', 'utilService', 'actService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function actBoardDueDate($q, $injector, $scope, $timeout, utilService, actService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, actService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, actService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'board');
        ribbon.addHomeTab();
        ribbon.addTab(ribbon.GetActViewsTab());
        ribbon.tabIndexSelected = 2;
        vm.ribbonDatacontext = ribbon;

        var viewKind = navigationService.stateParams().viewKind;
        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var expressionTypeEnum = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.ExpressionType, null, vm.translate).obj.normal.name_value;
        var actFixedCode = navigationService.stateParams().actFixedCode;
        var workflowStatusKindId = navigationService.stateParams().workflowStatusKindId;

        var nCols = 4; // parametrized
        var columnFilters = [];
        for (var i = 0; i < nCols; i++) {
            var filterParams = { field: 'ActExpireDateIncludeNull', text: null, value: false, isDueDate: false, defaultValue: false };
            columnFilters.push([filterParams]);
        }
        var boardParams = {
            dueDateFilters: [
                { filter: null, field: 'ActExpireDate1', isDueDate: true },
                { filter: null, field: 'ActExpireDate2', isDueDate: true }
            ],
            columnFilters: columnFilters,
            nbsGuidManager: NbsGuidManager,
            localAsUtcFn: Nembus.Common.Library.converter.date.localAsUtc
        };

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('act', null);
        nbsGridDatacontext.setBoardMode();
        nbsGridDatacontext.allowSearching = false;
        nbsGridDatacontext.setDatasource([], false); // fake datasource, only to create grid

        vm.board = new NbsBoard(nCols, boardParams);
        vm.board.setRefreshScopeFn(function () { $timeout(() => { }); });
        vm.board.setGridDatacontext(nbsGridDatacontext);
        vm.board.onBoardReady = function () {
            setBarDatacontext();
        };
        vm.board.onDropAction = function (items, indexColSrc, indexColDest) {
            dropAction(items, indexColSrc, indexColDest);
        };

        //#region set ribbon fn navigations
        var navParams = { 'workflowStatusKindId': workflowStatusKindId, 'viewKind': viewKind, 'appStoreCode': appStoreCode };
        vm.onRibbonGrid = () => { ribbon.onRibbonGrid_Act(navParams); };
        vm.onRibbonStream = () => { ribbon.onRibbonStream_Act(navParams); };
        vm.onRibbonBoardWorkflow = () => { ribbon.onRibbonBoardWorkflow_Act(navParams); };
        vm.onRibbonBoard = () => { ribbon.onRibbonBoard_Act(navParams); };
        //#endregion set ribbon fn navigations

        //#region set ribbon enabled buttons
        ribbon.setRibbonStatusButton(ribbon.Buttons.ViewStream, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.BoardWorkflow, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.ViewList, true);
        ribbon.refreshStatusButtons();
        //#endregion set ribbon enabled buttons

        // hook from gridbar
        var ejDataManager = null;
        var currentBoardBarFilter = null;
        vm.actBarOnChanges = function (result) {
            // save in view current filter passed by survey bar
            currentBoardBarFilter = result;
            currentBoardBarFilter.actFilter.ActExpireDate1Expression = expressionTypeEnum.GreaterThanOrEqual;
            currentBoardBarFilter.actFilter.ActExpireDate2Expression = expressionTypeEnum.LessThanOrEqual;
            currentBoardBarFilter.actFilter.ActFixedCode = actFixedCode;

            if (ejDataManager === null) {
                var supportData = { isMyActs: false, translate: vm.translate, contextService: contextService };
                ejDataManager = actService.getActDataManager(supportData, currentBoardBarFilter.actFilter, ejDataManager);
                nbsGridDatacontext.setDatasource(ejDataManager, true);

                vm.board.setBoardFilter(currentBoardBarFilter.actFilter);
            } else {
                vm.board.setBoardFilter(currentBoardBarFilter.actFilter);
            }
        };

        vm.refreshAction = function () {
            vm.board.refreshGridDatasources();
        };

        vm.onChangeDate = function (date, col) {
            vm.board.setColsFilterValue(col, ['ActExpireDate1', 'ActExpireDate2'], date);
        };

        vm.onChangeActExpireDateIncludeNull = function (col) {
            vm.board.setColsFilterBoolValueToggle(col, ['ActExpireDateIncludeNull']);
        };

        function setBarDatacontext() {
            vm.actBarDataContext = {
                workflowStatusKindId: workflowStatusKindId,
                commonBarDatasource: { modeUI: 'board' },
                uiMode: viewKind,
                appStoreBw: appStoreBw,
                defaultKeys: {
                    columnsKey: viewKind, sortingKey: null
                }
            };
        }

        vm.gridUiOnChanges = function (result) {
            vm.refreshGridUiComponent = {
                refreshKey: new Date().getTime(),
                uiData: result
            }
        }

        function dropAction(acts, indexColSrc, indexColDest) {
            var actsId = Lazy(acts).pluck('ActId').toArray();
            var srcCol = vm.board.columns[indexColSrc];
            var destCol = vm.board.columns[indexColDest];
            var newDate = vm.board.getColFilter(destCol).ActExpireDate1;
            newDate.setHours(0);
            newDate.setMinutes(0);
            newDate.setSeconds(0);
            newDate = Nembus.Common.Library.converter.date.localAsUtc(newDate);

            actService.updateActExpireDateBulk({ ActIds: actsId, ActExpireDate: newDate })
                .then(function (result) {
                    vm.board.refreshGridDatasource(srcCol);
                    vm.board.refreshGridDatasource(destCol);
                })
                .catch(function (error) {
                    vm.removeAlertFromList(true);
                    vm.setErrorMsg(error);
                });
        }
    }

    actBoardDueDate.$inject = injectParams;
    actBoardDueDate.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('actBoardDueDate', actBoardDueDate);
});