﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'utilService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function avatarDetail($q, $scope, $timeout, $injector, foundationService, utilService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, foundationService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var appStoreCode = navigationService.stateParams().appStoreCodeFtc;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var resourceTypeEnum = Nembus.Common.Library.util.parseEnum(foundationService.dataContext.enumTypes.ResourceType, null, vm.translate).obj.normal.name_value;
        var vhrStatusEnum = Nembus.Common.Library.util.parseEnum(foundationService.dataContext.enumTypes.VhrStatus, null, vm.translate).obj.normal.name_value;
        var genericElementCode = 'Countries';
        var currentVirtualHumanResourceId = navigationService.stateParams().virtualHumanResourceId;

        vm.genders = [
            { value: 'M', name: vm.translate("Male") },
            { value: 'F', name: vm.translate("Female") }
        ];
        vm.countries = [];
        vm.companies = [];

        if (currentVirtualHumanResourceId) {
            vm.setIsViewing();

            loadData(true);
        }
        else {
            //TODO: add checkbox x module
            //TODO: add follow line in vm base
            vm.setIsAdding();
            vm.currentPersonGenerality = foundationService.dataContext.personGeneralities.add({});
            vm.currentAvatar = foundationService.dataContext.avatars.add({
                CompanyId: null,
                VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId,
                AppStoreBw: appStoreBw,
                VhrStatusId: vhrStatusEnum.Enabled
            });

            vm.currentAvatar.PersonGeneralityId = vm.currentPersonGenerality.PersonGeneralityId;
            vm.currentAvatar.ResourceTypeId = resourceTypeEnum.Human;

            // manage of passport photo
            vm.photoDatacontext = {
                resizeMode: 'avatar',
                photoImageId: null
            };

            //countries list
            getGenericElements(true, genericElementCode);
            getCompanies(true);
            getSystemVhrGroups(true);
            getSystemVhrTypes(true);
        }

        vm.onImageChange = function (result) {
            if (result.action === 'upload') {
                vm.currentPersonGenerality.TemporaryFileName = result.temporaryFileName;
                vm.currentPersonGenerality.TemporaryFileNameAction = 1; // ImageLogoAction.Upload
            }
            if (result.action === 'delete') {
                vm.currentPersonGenerality.TemporaryFileName = null;
                vm.currentPersonGenerality.TemporaryFileNameAction = 2; // ImageLogoAction.Delete
            }
        };

        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getGenericElements(false, genericElementCode));
            funcsPromises.push(getCompanies(false));

            $q.all(funcsPromises)
                .then(function () {
                    getAvatar(forceReload, currentVirtualHumanResourceId);
                });
        }

        function getSystemVhrGroups(forceReload) {
            var filter = { AppStoreBw: appStoreBw };
            var defer = $q.defer();

            foundationService.getSystemVhrGroups(filter, forceReload)
                .then(function (data) {
                    vm.vhrGroup = data.results[0];

                    vm.currentAvatar.VhrGroupId = vm.vhrGroup.VhrGroupId;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getSystemVhrTypes(forceReload) {
            var filter = { AppStoreBw: appStoreBw };
            var defer = $q.defer();

            foundationService.getVhrTypes(filter, forceReload)
                .then(function (data) {
                    vm.vhrType = data.results[0];

                    vm.currentAvatar.VhrTypeId = vm.vhrType.VhrTypeId;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getAvatar(forceReload, currentVirtualHumanResourceId) {
            var filter = { VirtualHumanResourceId: currentVirtualHumanResourceId };
            var defer = $q.defer();

            foundationService.getAvatar(filter, forceReload)
                .then(function (data) {
                    vm.currentAvatar = data.results[0];
                    vm.currentPersonGenerality = vm.currentAvatar.PersonGenerality;

                    // manage of passport photo
                    vm.photoDatacontext = {
                        resizeMode: 'avatar',
                        photoImageId: vm.currentPersonGenerality.PassportPhotoId
                    };

                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getGenericElements(forceReload, genericElementCode) {
            var filter = { GenericElementCode: genericElementCode };
            var defer = $q.defer();

            utilService.getGenericElements(filter, forceReload)
                .then(function (data) {
                    vm.countries = data.results[0].PairValuesDropDown;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getCompanies(forceReload) {
            var defer = $q.defer();

            foundationService.getCompanies({}, forceReload)
                .then(function (data) {
                    vm.companies = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        // get reference of submitaction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parents = vm.submitAction;
        var ftcScope = $scope.$parent.vm;
        vm.submitAction = function () {
            vm.currentAvatar.VirtualCode = vm.currentPersonGenerality.RegistrationNumber;
            vm.currentAvatar.VirtualName = vm.currentPersonGenerality.FirstName + ' ' + vm.currentPersonGenerality.LastName;
            if (vm.currentPersonGenerality.Dob) {
                vm.currentPersonGenerality.Dob.setHours(0, 0, 0, 0);
            }

            parents().then(
                function (saveResult) {
                    if (saveResult.entities.length > 0 && vm.viewContext.isAddingState && ftcScope.ftcOnAddEntity) {
                        ftcScope.ftcOnAddEntity(vm.currentAvatar);
                    }
                }
            );
        };
    }

    avatarDetail.$inject = injectParams;
    avatarDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('avatarDetail', avatarDetail);
});