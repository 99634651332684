﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'drbacService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var drbacRoleDetail = function ($q, $scope, $timeout, $injector, drbacService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, drbacService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, drbacService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var roleId = navigationService.stateParams().drbacRoleId;
        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;

        if (roleId) {
            vm.setIsViewing();

            loadData(true);
        }
        else {
            vm.setIsAdding();

            vm.drbacRole = drbacService.dataContext.drbacRoles.add({
                RoleId: NbsGuidManager.newGuid(),
                VirtualCompanyId: virtualCompanyId,
            });
        }

        function loadData(forceReload) {
            var funcsPromises = []
            $q.all(funcsPromises)
                .then(function () {
                    getDrbacRole(forceReload, roleId);
                });
        }

        function getDrbacRole(forceReload, roleId) {
            var filter = {
                RoleId: roleId,
            };
            var defer = $q.defer();

            drbacService.getDrbacRole(filter, forceReload)
                .then(function (data) {
                    vm.drbacRole = data.results[0];
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    drbacRoleDetail.$inject = injectParams;
    drbacRoleDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rbac.module').controller('drbacRoleDetail', drbacRoleDetail);
});