﻿'use strict';
define(['../../common/services/nbs-service'], function (nbsService) {

    var injectParams = ['$http', '$q', '$timeout', 'breezeDataLayer'];

    var currentSessionService = function ($http, $q, $timeout, breezeDataLayer) {

        var dataLayer = breezeDataLayer.Initialize('rbac', true);
        var manager = dataLayer.GetManager();

        var entityTypes = {
            CategoryDto: 'CategoryDto',
            VirtualCompany: 'VirtualCompany',
            Company: 'Company'
        };

        nbsService.call(this, manager, $q, $timeout);

        this.dataContext = {
            categories: dataLayer.setContextMethods(manager, nbsService, entityTypes.CategoryDto),
            companies: dataLayer.setContextMethods(manager, nbsService, entityTypes.Company)
        };

        this.getCurrentUserCategoriesTree = function (filter, forceReload) {
            var params = { entityType: entityTypes.CategoryDto, cache: this.dataContext.categories.get() };
            var defer = $q.defer();

            dataLayer.GetDataListPromise(params, filter, forceReload, "CurrentUserCategoriesTree")
                .then(function (data) {
                    //set parentId on relative root node to null for treeview render
                    //root is the only node with min Deep
                    // 
                    var relativeRoot = Lazy(data.results).min(function (n) { return n.Deep; });
                    if (relativeRoot) {
                        relativeRoot.ParentCategoryId = null;
                    }
                    defer.resolve(data);
                })
                .catch(function (error) {
                    return $q.reject(error);
                });

            return defer.promise;
        };

        this.getAvailableVirtualCompanies = function () {
            // get data from db
            dataLayer.ClearCachedEntityType(entityTypes.VirtualCompany);
            var query = dataLayer.CreateQuery("AvailableVirtualCompanies");
            return manager.executeQuery(query);
        };

        this.createQuery = function (entityName) {
            return dataLayer.CreateQuery(entityName);
        };
    };

    currentSessionService.$inject = injectParams;
    currentSessionService.prototype = Object.create(nbsService.prototype);
    angular.module('nbs.shared.module').service('currentSessionService', currentSessionService);

});