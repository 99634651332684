﻿'use strict';

define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$timeout', '$injector', 'navigationService', 'drbacService', 'rbacService', 'contextService'];
    function drbacAssignmentDetail($q, $timeout, $injector, navigationService, drbacService, rbacService, contextService) {

        var vm = this;
        nbsVm.call(vm, null, navigationService, contextService, $q, $injector, $timeout);
        
        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
        var modalData = navigationService.getModalData('drbacAssignmentDetail', vm.viewMode);
        var pParams = modalData.params;

        /*
            pDs : {
                appUsers: pLabelsList.getDatasource(pTypes.AppUser),
                drbacRoles: pLabelsList.getDatasource(pTypes.DrbacRole),
                drbacUserGroups: pLabelsList.getDatasource(pTypes.DrbacUserGroup),
            }
        */
        var pDs = modalData.ds;
        pParams = pParams ? pParams : navigationService.stateParams();

        var viewRoles = pParams.viewRoles ? true : false;
        var viewUserGroups = pParams.viewUserGroups ? true : false;
        var viewUsers = pParams.viewUsers ? true : false;
        var disableUserGroups = pParams.disableUserGroups === true ? true : false;
        var disableUsers = pParams.disableUsers === true ? true : false;
        var disableRoles = pParams.disableRoles === true ? true : false;
        var contextUserForSelection = pParams.contextUserForSelection === true ? true : false;

        vm.nbsDrbacAuthSelector = new NbsAssigneeSelector(vm.translate);
        vm.nbsDrbacAuthSelector.viewRoles = viewRoles;
        vm.nbsDrbacAuthSelector.viewUserGroups = viewUserGroups;
        vm.nbsDrbacAuthSelector.viewUsers = viewUsers;
        vm.nbsDrbacAuthSelector.userGroupDisabled = disableUserGroups;
        vm.nbsDrbacAuthSelector.userDisabled = disableUsers;
        vm.nbsDrbacAuthSelector.roleDisabled = disableRoles;

        vm.nbsDrbacAuthSelector.setLabelsOnRow();

        // object binded to UI only for description assignment
        vm.currentAssignment = {};

        vm.okBtnIsVisible = true;
        vm.cancelBtnIsVisible = true;

        vm.modalOk = function () {
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalOk);
            navigationService.closeCurrentModal(modalResult);
        };

        vm.modalCancel = function () {
            navigationService.closeCurrentModal();
        };

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            if (vm.nbsDrbacAuthSelector.viewRoles) {
                funcsPromises.push(getDrbacRoles(forceReload));
            }
            if (vm.nbsDrbacAuthSelector.viewUserGroups) {
                funcsPromises.push(getDrbacUserGroups(forceReload, virtualCompanyId));
            }
            if (vm.nbsDrbacAuthSelector.viewUsers) {
                funcsPromises.push(getCurrentContextAppUsers(forceReload));
            }

            $q.all(funcsPromises)
                .then(function (results) {
                });            
        }

        function getDrbacRoles(forceReload) {
            var filter = {};
            var defer = $q.defer();

            drbacService.getDrbacRoles(filter, forceReload)
                .then(function (data) {
                    vm.nbsDrbacAuthSelector.drbacRoleMultiselect.addData(data.results, 'RoleId', 'RoleName');
                    vm.nbsDrbacAuthSelector.setDrbacRolesSelected(pParams.selectedDrbacRoleIds);
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getDrbacUserGroups(forceReload, virtualCompanyId) {
            var filter = {
                VirtualCompanyId: virtualCompanyId
            };
            var defer = $q.defer();

            drbacService.getDrbacUserGroups(filter, forceReload)
                .then(function (data) {
                    vm.nbsDrbacAuthSelector.drbacUserGroupMultiselect.addData(data.results, 'DrbacUserGroupId', 'DrbacUserGroupName');
                    vm.nbsDrbacAuthSelector.setDrbacUserGroupsSelected(pParams.selectedDrbacUserGroupIds);
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getCurrentContextAppUsers(forceReload) {
            var filter = {};
            var defer = $q.defer();

            rbacService.getCurrentContextAppUsers(filter, forceReload)
                .then(function (data) {
                    vm.nbsDrbacAuthSelector.appUserMultiselect.addData(data.results, 'AppUserId', 'DisplayNameUI');
                    vm.nbsDrbacAuthSelector.setAppUsersSelected(pParams.selectedAppUserIds);
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getModalResult(dialogResult) {
            // on modal close is returned object result
            var objectResult = {
                assignmentDescription: vm.currentAssignment.assignmentDescription,
                selectedAppUsers: vm.nbsDrbacAuthSelector.appUserMultiselect.selected,
                selectedDrbacUserGroups: vm.nbsDrbacAuthSelector.drbacUserGroupMultiselect.selected,
                selectedDrbacRoles: vm.nbsDrbacAuthSelector.drbacRoleMultiselect.selected
            };

            var modalResult = new navigationService.modalResult();
            modalResult.data = objectResult;
            modalResult.sourceParams = pParams;
            modalResult.dialogResult = dialogResult;
            return modalResult;
        }
    }

    drbacAssignmentDetail.$inject = injectParams;
    drbacAssignmentDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rbac.module').controller('drbacAssignmentDetail', drbacAssignmentDetail);
});