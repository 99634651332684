﻿'use strict';
define([], function () {

    /// NEW VERSION OF COMMON BAR THAT DOESN'T HAVE REFERENCE TO ANY GRID
    var injectParams = ['$injector', '$scope', '$timeout'];
    var nbsCommonBarComponent = function ($injector, $scope, $timeout) {
        var vm = this;
        var pDataContext = null;
        var pDataContextIsReady = false;
        var pDatasource = null;
        var pDatasourceIsReady = false;
        var privateContextObj = {};
        var isGroupingChanged = false;
        var isVisibleColumnChanged = false;
        var isSortingChanged = false;
        var isVisibleFilterChanged = false;
        var uibModal = $injector.get('$uibModal');

        vm.$onInit = function () {
            vm.visibleDS = [];
            vm.groupingDS = [];
            vm.sortingDS = [];
            vm.sortingSelected = [];
            vm.sortingModeDS = [
                { value: 'ascending', name: vm.uiContext.translate('Ascending') },
                { value: 'descending', name: vm.uiContext.translate('Descending') }
            ];
            vm.sortingModeSelected = null;
            vm.filtersList = [];
        };

        vm.$onChanges = function (changes) {
            if (changes.nbsDataContext && changes.nbsDataContext.currentValue) {
                pDataContext = changes.nbsDataContext.currentValue;
                pDataContextIsReady = true;
            }
            if (changes.nbsDatasource && changes.nbsDatasource.currentValue) {
                pDatasource = changes.nbsDatasource.currentValue;
                pDatasourceIsReady = true;
            }            
            if (pDataContextIsReady && pDatasourceIsReady) {
                if (pDatasource.elementType === 'ejGrid') {
                    setCommonGridBar();
                } else if (pDatasource.elementType === 'ejSchedule') {
                    setCommonScheduleBar();
                } else {
                    setCommonDefaultBar();
                }

                if (Lazy(pDataContext.filters.filterList).some()) {
                    Lazy(pDataContext.filters.filterList)
                        .each((cFilter) => {
                            cFilter.name = vm.uiContext.translate(cFilter.name);
                        });

                    vm.filtersList = pDataContext.filters.filterList;
                }                

                setCommonBarElementsStatus();
            }
        };

        function setCommonBarElementsStatus() {
            vm.commonBarStatus = {
                showFreetext: pDatasource.modeUI === 'grid' || pDatasource.modeUI === 'stream' || pDatasource.modeUI === 'commongrid' || pDatasource.modeUI === 'custom' || !pDatasource.modeUI,
                showSorting: pDatasource.modeUI === 'grid' || pDatasource.modeUI === 'stream' || pDatasource.modeUI === 'commongrid' || pDatasource.modeUI === 'custom' || !pDatasource.modeUI,
                showColumns: pDatasource.modeUI === 'grid' || pDatasource.modeUI === 'commongrid' || pDatasource.modeUI === 'custom' || !pDatasource.modeUI,
                showGrouping: pDatasource.modeUI === 'grid' || pDatasource.modeUI === 'commongrid' || pDatasource.modeUI === 'custom' || !pDatasource.modeUI,
                showFilters: pDatasource.modeUI !== 'commongrid',

                tabColumnIsSelected: false,
                tabSortingIsSelected: false,
                tabGroupingIsSelected: false,
                tabFilterIsSelected: false
            };

            if (Nembus.Common.Library.ui.useListView()) {
                vm.commonBarStatus.showGrouping = false;
                vm.commonBarStatus.showColumns = false;
            }

            if (vm.commonBarStatus.showColumns) {
                vm.commonBarStatus.tabColumnIsSelected = true;
            } else if (vm.commonBarStatus.showSorting) {
                vm.commonBarStatus.tabSortingIsSelected = true;
            } else if (vm.commonBarStatus.showGrouping) {
                vm.commonBarStatus.tabGroupingIsSelected = true;
            } else if (vm.commonBarStatus.showFilters) {
                vm.commonBarStatus.tabFilterIsSelected = true;
            }
        }

        vm.openModal = function () {
            var visibleDS = JSON.parse(JSON.stringify(vm.visibleDS));
            var groupingDS = JSON.parse(JSON.stringify(vm.groupingDS));
            var sortingSelected = JSON.parse(JSON.stringify(vm.sortingSelected));
            var sortingModeSelected = JSON.parse(JSON.stringify(vm.sortingModeSelected));
            var filtersList = [];

            isGroupingChanged = false;
            isVisibleColumnChanged = false;
            isSortingChanged = false;
            isVisibleFilterChanged = false;

            if (vm.filtersList) {
                // copy filters removing reference
                filtersList = JSON.parse(JSON.stringify(vm.filtersList));
            }

            vm.modalFilterManage = uibModal.open({ templateUrl: 'modalTemplate', size: 'md', scope: $scope });
            vm.modalFilterManage.ok = function () { vm.modalFilterManage.close(); };
            vm.modalFilterManage.cancel = function () { vm.modalFilterManage.dismiss(); };
            vm.modalFilterManage.result.then(function () {
                // sorting
                privateContextObj.sorting.selected = Lazy(vm.sortingDS).where({ optionValue: vm.sortingSelected }).toArray();
                privateContextObj.sorting.direction = vm.sortingModeSelected;

                // grouping
                privateContextObj.grouping.selected = Lazy(vm.groupingDS).where({ isChecked: true }).toArray();

                // visualization
                privateContextObj.columns.selected = Lazy(vm.visibleDS).where({ isChecked: true }).toArray();

                //filtering
                privateContextObj.filters.filterList = vm.filtersList;

                if (isGroupingChanged || isVisibleColumnChanged || isSortingChanged) {
                    raiseUiChanges();
                }

                if (isVisibleFilterChanged) {
                    raiseFilterChanges();
                }
            }, function () {
                // restore data
                vm.visibleDS = visibleDS;
                vm.groupingDS = groupingDS;
                vm.sortingSelected = sortingSelected;
                vm.sortingModeSelected = sortingModeSelected;
                vm.filtersList = filtersList;
            });
        };

        //#region hook from nbs-dd component
        vm.onSearchEvent = function (freeText) {
            privateContextObj.filters.isFiltered = true;
            privateContextObj.filters.freeText.text = freeText;

            raiseUiChanges();
        };

        vm.sortingFieldOnchange = function () {
            isSortingChanged = true;
        }

        vm.sortingDirectionOnchange = function () {
            isSortingChanged = true;
        }

        vm.groupingOnchange = function () {
            isGroupingChanged = true;
        }

        vm.visibleColumnOnchange = function () {
            isVisibleColumnChanged = true;
        }

        vm.visibleFilterOnchange = function () {
            isVisibleFilterChanged = true;
        }
        //#endregion

        function setCommonGridBar() {
            // cols visualization
            vm.visibleDS = pDatasource.visibilityColumns;
            setVisibleColSelection(vm.visibleDS, pDataContext);

            // cols grouping
            vm.groupingDS = pDatasource.groupingColumns;
            setGroupedColSelection(vm.groupingDS, pDataContext);

            // cols sorting
            vm.sortingDS = pDatasource.sortingColumns;
            var sorting = getSortingOnStart(vm.sortingDS, pDataContext);
            if (Lazy(sorting.selected).first()) {
                vm.sortingSelected = Lazy(sorting.selected).first().optionValue;
            }
            if (sorting.direction) {
                vm.sortingModeSelected = sorting.direction;
            }

            privateContextObj = getPrivateContextObj();
            privateContextObj.sorting.selected = sorting.selected;
            privateContextObj.sorting.direction = sorting.direction;
            privateContextObj.grouping.selected = pDataContext.grouping.selected;
            privateContextObj.grouping.columns = Lazy(vm.groupingDS).where({ isChecked: true }).toArray();
            privateContextObj.columns.selected = Lazy(vm.visibleDS).where({ isChecked: true }).toArray();
            privateContextObj.filters.filterList = pDataContext.filters.filterList;
            privateContextObj.filters.freeText = pDataContext.filters.freeText;

            // only used to bind in UI
            vm.txtSearchDataContext = null;
            $timeout(function () {
                vm.txtSearchDataContext = privateContextObj.filters.freeText.text;
            }, 0);
        }

        function setCommonScheduleBar() {
            // cols grouping
            vm.groupingDS = pDataContext.grouping.columns;
            setGroupedColSelection(vm.groupingDS, pDataContext);

            privateContextObj = getPrivateContextObj();
            privateContextObj.grouping.columns = pDataContext.grouping.columns;
            privateContextObj.grouping.selected = pDataContext.grouping.selected;
            privateContextObj.filters.filterList = pDataContext.filters.filterList;
            privateContextObj.filters.freeText = pDataContext.filters.freeText;
        }

        function setCommonDefaultBar() {
            // localFilters is used like datacontext for related components
            privateContextObj = getPrivateContextObj();
            privateContextObj.filters.filterList = pDataContext.filters.filterList;
            privateContextObj.filters.freeText = pDataContext.filters.freeText;

            // only used to bind in UI
            vm.txtSearchDataContext = null;
            $timeout(function () {
                vm.txtSearchDataContext = privateContextObj.filters.freeText.text;
            }, 0);
        }

        function getPrivateContextObj() {
            return {
                sorting: {
                    columns: [],
                    selected: [],
                    direction: null
                },
                grouping: {
                    columns: [],
                    selected: []
                },
                columns: {
                    columns: [],
                    selected: []
                },
                filters: {
                    filterList: [],
                    freeText: []
                }
            };
        }

        function setVisibleColSelection(cols, dataContext) {
            var selectedOptions = dataContext.columns.selected ? dataContext.columns.selected : [];

            if (Lazy(selectedOptions).some()) {
                var selectedValues = Lazy(selectedOptions).pluck('optionValue').toArray();
                Lazy(cols).each((o) => { o.isChecked = Lazy(selectedValues).contains(o.optionValue); });
            }

            if (!Lazy(cols).where({ isChecked: true }).some()) {
                Lazy(cols).each((o) => { o.isChecked = true; });
            }
        }

        function setGroupedColSelection(cols, dataContext) {
            var result = [];
            var selectedOptions = dataContext.grouping.selected ? dataContext.grouping.selected : [];

            if (Lazy(selectedOptions).some()) {
                var selectedValues = Lazy(selectedOptions).pluck('optionValue').toArray();
                Lazy(cols).each((o) => { o.isChecked = Lazy(selectedValues).contains(o.optionValue); });
            }

            return result;
        }

        // returns columns for SORTING (list of visible fields) parsed with defaults
        function getSortingOnStart(sortColumns, dataContext) {
            // pDataContext.sorting.default, pDataContext.sorting.selected
            var result = {
                selected: [],
                direction: null
            };

            var selectedOptions = dataContext.sorting.selected ? dataContext.sorting.selected : [];

            if (Lazy(selectedOptions).some()) {
                var selectedValues = Lazy(selectedOptions).pluck('optionValue').toArray();
                result.selected = Lazy(sortColumns).filter((o) => { return Lazy(selectedValues).contains(o.optionValue); }).toArray();
                result.direction = dataContext.sorting.direction;
            }

            return result;
        }

        function raiseFilterChanges() {
            //raise event to source component caller (e.g. survey-grid-bar-component)
            $timeout(function () {
                vm.filterChangesEvent({ result: { filters: privateContextObj.filters } });                
            });
        }

        function raiseUiChanges(type, data) {
            //raise event to source component caller (e.g. survey-grid-bar-component)
            $timeout(function () {
                vm.uiChangesEvent({ result: { uiData: privateContextObj } });
            });            
        }
    };

    nbsCommonBarComponent.$inject = injectParams;
    angular.module('nbs.rds.module').component('nbsCommonBar', {
        templateUrl: '/rds/common/components/nbsCommonBarComponent/_?c=' + window.codeCache(),
        controller: nbsCommonBarComponent,
        controllerAs: 'vm',
        bindings: {
            nbsDataContext: "<",
            nbsDatasource: "<",
            uiContext: "<",
            filterChangesEvent: '&',
            uiChangesEvent: '&'
        }
    });
});

