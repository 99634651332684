﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'rbacService', 'foundationService', 'navigationService', 'checkStateChangeService', 'drbacService', 'contextService'];
    var userProfileDetail = function ($q, $scope, $timeout, $injector, rbacService, foundationService, navigationService, checkStateChangeService, drbacService, contextService) {

        var vm = this;
        nbsVm.call(vm, rbacService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, rbacService, { dirtyFlagIsSuspended: vm.isDirtySuspended });

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        // viewKind => assettree,target => used to view tab asset or tab target
        vm.viewKind = navigationService.stateParams().viewKind;
        var tabSelected = 0;
        var currentUserProfileId = navigationService.stateParams().userProfileId;
        var profileM2mcategorySelected;
        var profileM2muserSelected;
        var systemCategoryCode = 'BSC';
        var vhrStatusEnum = Nembus.Common.Library.util.parseEnum(foundationService.dataContext.enumTypes.VhrStatus, null, vm.translate).obj.normal.name_value;
        var categoryKindEnum = Nembus.Common.Library.util.parseEnum(foundationService.dataContext.enumTypes.CategoryKind, null, vm.translate).obj.translated.value_name;

        // datasource grid
        vm.currentCategoriesForProfile = [];
        vm.currentUsersForProfile = [];

        vm.tab = { detail: 0, category: 1, target: 2, assettree: 3, user: 4 };

        if (currentUserProfileId) {
            vm.setIsViewing();

            loadData(true);

            setStatusTabsDisabled(false);
        } else {
            vm.setIsAdding();
            vm.currentUserProfile = rbacService.dataContext.userProfiles.add({ VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId });

            setStatusTabsDisabled(true);
        }

        ribbon.setRibbonStatusButton(ribbon.Buttons.New, false);
        ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
        ribbon.setRibbonStatusButton(ribbon.Buttons.Refresh, false);
        ribbon.refreshStatusButtons();

        var nbsGridCategoryDatacontext = new NbsGrid(vm.translate);
        nbsGridCategoryDatacontext.configureGrid('category', 'userprofile');
        nbsGridCategoryDatacontext.allowPaging = false;
        nbsGridCategoryDatacontext.allowSearching = false;
        nbsGridCategoryDatacontext.allowGrouping = false;
        nbsGridCategoryDatacontext.setDatasource([], false);
        vm.nbsGridCategoryDatacontext = nbsGridCategoryDatacontext;

        var nbsGridTargetDatacontext = new NbsGrid(vm.translate);
        nbsGridTargetDatacontext.configureGrid('target', null);
        nbsGridTargetDatacontext.allowSearching = true;
        nbsGridTargetDatacontext.allowGrouping = false;
        nbsGridTargetDatacontext.setDatasource([], false);
        vm.nbsGridTargetDatacontext = nbsGridTargetDatacontext;

        var nbsGridAssetTreeDatacontext = new NbsGrid(vm.translate);
        nbsGridAssetTreeDatacontext.configureGrid('assettree', 'profile');
        nbsGridAssetTreeDatacontext.allowSearching = true;
        nbsGridAssetTreeDatacontext.allowGrouping = false;
        nbsGridAssetTreeDatacontext.setDatasource([], false);
        vm.nbsGridAssetTreeDatacontext = nbsGridAssetTreeDatacontext;

        var nbsGridUserDatacontext = new NbsGrid(vm.translate);
        nbsGridUserDatacontext.configureGrid('appuserdto', 'userprofile');
        nbsGridUserDatacontext.setAdaptToPage();
        nbsGridUserDatacontext.allowSearching = true;
        nbsGridUserDatacontext.allowGrouping = false;
        nbsGridUserDatacontext.setDatasource([], false);
        vm.nbsGridUserDatacontext = nbsGridUserDatacontext;

        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getUserProfile(forceReload, currentUserProfileId));
            funcsPromises.push(getDrbacProfileM2MCategoryDto(forceReload, currentUserProfileId));
            funcsPromises.push(getDrbacAppUserM2MDrbacProfileDtos(forceReload, currentUserProfileId));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.currentUserProfile = results[0];
                    vm.currentCategoriesForProfile = results[1];
                    vm.currentUsersForProfile = results[2];

                    vm.forceCategoryDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.currentCategoriesForProfile
                    }

                    vm.forceUserDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.currentUsersForProfile
                    }
                });
        }

        function setStatusTabsDisabled(isDisabled) {
            vm.tabIsDisabled = isDisabled;
        }

        vm.refreshAction = function () {
            if (tabSelected === vm.tab.category) {
                getDrbacProfileM2MCategoryDto(true, vm.currentUserProfile.UserProfileId)
                    .then((results) => {
                        vm.forceCategoryDatasourceLocal = {
                            refreshkey: new Date().getTime(),
                            dataSource: results
                        }
                    });
            } else if (tabSelected === vm.tab.target) {
                refreshTargetGridDatasource();
            } else if (tabSelected === vm.tab.user) {
                getDrbacAppUserM2MDrbacProfileDtos(true, vm.currentUserProfile.UserProfileId)
                    .then((results) => {
                        vm.forceUserDatasourceLocal = {
                            refreshkey: new Date().getTime(),
                            dataSource: results
                        }
                    });
            } else if (tabSelected === vm.tab.assettree) {
                refreshAssetTreeGridDatasource();
            }
        };

        function refreshRibbonStatus() {
            $timeout(() => {
                var btnNewIsEnabled = false;
                var btnDeleteIsEnabled = false;
                var btnRefreshIsEnabled = false;

                switch (tabSelected) {
                    case vm.tab.category:
                        if (!vm.currentUserProfile.IsManageAllTargetCategories) {
                            btnNewIsEnabled = !vm.viewContext.isLocked;
                            btnDeleteIsEnabled = !vm.viewContext.isLocked;
                            btnRefreshIsEnabled = true;
                        }
                        break;
                    case vm.tab.target:
                        btnRefreshIsEnabled = true;
                        break;
                    case vm.tab.assettree:
                        btnRefreshIsEnabled = true;
                        break;
                    case vm.tab.user:
                        btnNewIsEnabled = !vm.viewContext.isLocked;
                        btnDeleteIsEnabled = !vm.viewContext.isLocked;
                        btnRefreshIsEnabled = true;
                        break;
                }

                ribbon.setRibbonStatusButton(ribbon.Buttons.New, btnNewIsEnabled);
                ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, btnDeleteIsEnabled);
                ribbon.setRibbonStatusButton(ribbon.Buttons.Refresh, btnRefreshIsEnabled);
                ribbon.refreshStatusButtons();
            });
        }

        vm.onTabSelected = function (tabId) {
            tabSelected = tabId;
            profileM2mcategorySelected = null;
            profileM2muserSelected = null;
            refreshRibbonStatus();

            switch (tabId) {
                case vm.tab.target:
                    refreshTargetGridDatasource();
                    break;
                case vm.tab.assettree:
                    refreshAssetTreeGridDatasource();
                    break;
                //case vm.tab.user:
                //    vm.forceUserDatasourceLocal = {
                //        refreshkey: new Date().getTime(),
                //        dataSource: vm.currentUsersForProfile
                //    }
                //    break;
                //case vm.tab.category:
                //    vm.forceCategoryDatasourceLocal = {
                //        refreshkey: new Date().getTime(),
                //        dataSource: vm.currentCategoriesForProfile
                //    }
                //    break;
            }
        };

        vm.isManageAllTargetCategoriesOnChange = function () {
            refreshRibbonStatus();
        };

        // get reference of modifyAction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parentm = vm.modifyAction;
        vm.modifyAction = function () {
            parentm().then(
                function (saveResult) {
                    refreshRibbonStatus();
                }
            );
        };

        // get reference of submitaction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parents = vm.submitAction;
        vm.submitAction = function () {
            drbacService.saveChanges()
                .then(() => {
                    parents().then(
                        function (saveResult) {
                            if (vm.viewContext.isAddingState) {
                                setStatusTabsDisabled(false);
                            }
                            if (vm.currentUserProfile.UserProfileId > 0) {
                                getDrbacProfileM2MCategoryDto(true, vm.currentUserProfile.UserProfileId)
                                    .then((results) => {
                                        vm.forceCategoryDatasourceLocal = {
                                            refreshkey: new Date().getTime(),
                                            dataSource: results
                                        }
                                    });
                            }
                        }
                    );
                });
        };

        // used only to add category for profile
        vm.newAction = function () {
            if (tabSelected === vm.tab.category) {
                navigationService.navToModal(vm.suspendDirty, 'app.nav.categoriesselection', { systemCategoryCode: systemCategoryCode, viewKind: 'all', modalKey: 'categories' })
                    .then(function (result) {

                        var categoryIds = Lazy(vm.currentCategoriesForProfile).pluck("CategoryId").toArray();

                        Lazy(result.data)
                            .each((category) => {
                                if (!Lazy(categoryIds).contains(category.CategoryId)) {
                                    var m2m = rbacService.dataContext.drbacProfileM2MCategoryDtos.add(
                                        {
                                            UserProfileId: vm.currentUserProfile.UserProfileId,
                                            CategoryId: category.CategoryId,
                                            CategoryCode: category.CategoryCode,
                                            CategoryName: category.CategoryName,
                                            CategoryKindId: category.CategoryKindId
                                        }
                                    );
                                    vm.currentCategoriesForProfile.push(m2m);
                                }
                            });

                        vm.forceCategoryDatasourceLocal = {
                            refreshkey: new Date().getTime(),
                            dataSource: vm.currentCategoriesForProfile
                        }
                    });
            } else if (tabSelected === vm.tab.user) {
                navigationService.navToModal(vm.suspendDirty, 'app.nav.appuserslistmodal', { isMultiselect: true, modalKey: 'users' })
                    .then(function (result) {
                        Lazy(result.data)
                            .each((appUser) => {
                                var m2m = drbacService.dataContext.drbacAppUserM2MDrbacProfileDtos.add({
                                    UserProfileId: vm.currentUserProfile.UserProfileId,
                                    AppUserId: appUser.AppUserId,
                                    AppUserUsername: appUser.Username,
                                    AppUserFirstName: appUser.FirstName,
                                    AppUserLastName: appUser.LastName
                                });

                                vm.currentUsersForProfile.push(m2m);
                            });

                        vm.forceUserDatasourceLocal = {
                            refreshkey: new Date().getTime(),
                            dataSource: vm.currentUsersForProfile
                        }
                    });
            }
        };

        // used only to select current category for profile selected
        vm.gridCategorySelection = function (args) {
            profileM2mcategorySelected = args.data;
        };
        vm.gridUserSelection = function (args) {
            profileM2muserSelected = args.data;
        };

        // used only to remove selected category for profile
        vm.deleteAction = function () {
            if (tabSelected === vm.tab.category) {
                if (!profileM2mcategorySelected) {
                    return;
                }

                rbacService.deleteEntity(profileM2mcategorySelected);
                vm.currentCategoriesForProfile = Lazy(vm.currentCategoriesForProfile)
                    .reject({ CategoryId: profileM2mcategorySelected.CategoryId })
                    .toArray();

                vm.forceCategoryDatasourceLocal = {
                    refreshkey: new Date().getTime(),
                    dataSource: vm.currentCategoriesForProfile
                }
            } else if (tabSelected === vm.tab.user) {
                if (!profileM2muserSelected) {
                    return;
                }

                drbacService.deleteEntity(profileM2muserSelected);
                vm.currentUsersForProfile = Lazy(vm.currentUsersForProfile)
                    .reject({ AppUserId: profileM2muserSelected.AppUserId })
                    .toArray();

                vm.forceUserDatasourceLocal = {
                    refreshkey: new Date().getTime(),
                    dataSource: vm.currentUsersForProfile
                }
            }
        };

        vm.queryCellInfoCategory = function (args) {
            switch (args.column.field) {
                case "CategoryKindId":
                    args.cell.innerHTML = categoryKindEnum[args.data.CategoryKindId];
                    break;
            }
        }

        function getUserProfile(forceReload, userProfileId) {
            var defer = $q.defer();
            var filter = { UserProfileId: userProfileId };

            rbacService.getUserProfile(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results[0]);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
        function getDrbacProfileM2MCategoryDto(forceReload, userProfileId) {
            var filter = { UserProfileId: userProfileId };
            var defer = $q.defer();

            rbacService.getDrbacProfileM2MCategoryDto(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
        function getDrbacAppUserM2MDrbacProfileDtos(forceReload, userProfileId) {
            var filter = { DrbacProfileId: userProfileId };
            var defer = $q.defer();

            drbacService.getDrbacAppUserM2MDrbacProfileDtos(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        var ejDataManagerTarget = null;
        function refreshTargetGridDatasource() {
            $timeout(() => {
                var currentFilter = {
                    VhrStatusId: vhrStatusEnum.Enabled,
                    HaveCategories: true,
                    HaveToExpandCategories: true
                }

                if (!vm.currentUserProfile.IsManageAllTargetCategories) {
                    var catId = Lazy(vm.currentCategoriesForProfile).pluck('CategoryId').toArray();
                    if (Lazy(catId).some()) {
                        currentFilter.CategoriesId = catId;
                    } else {
                        currentFilter.CategoriesId = [0]; // no results
                    }
                }

                if (!ejDataManagerTarget) {
                    var supportData = { translate: vm.translate, contextService: contextService };
                    ejDataManagerTarget = foundationService.getTargetDataManager(supportData, currentFilter, ejDataManagerTarget);
                    nbsGridTargetDatacontext.setDatasource(ejDataManagerTarget, true);
                }

                vm.forceDatamanagerTargetFilter = {
                    refreshKey: new Date().getTime(),
                    filter: currentFilter
                }
            });
        }

        var ejDataManagerAssetTree = null;
        function refreshAssetTreeGridDatasource() {
            $timeout(() => {
                var currentFilter = {
                    assetTreeFilter: {
                        VhrStatusId: vhrStatusEnum.Enabled,
                        HaveCategories: true,
                        HaveToExpandCategories: true
                    }
                }

                if (!vm.currentUserProfile.IsManageAllTargetCategories) {
                    var catId = Lazy(vm.currentCategoriesForProfile).pluck('CategoryId').toArray();
                    if (Lazy(catId).some()) {
                        currentFilter.assetTreeFilter.CategoriesId = catId;
                    } else {
                        currentFilter.assetTreeFilter.CategoriesId = [0]; // no results
                    }
                }

                if (!ejDataManagerAssetTree) {
                    var supportData = { translate: vm.translate };
                    ejDataManagerAssetTree = foundationService.getAssetTreeDataManager(supportData, currentFilter, ejDataManagerAssetTree);
                    nbsGridAssetTreeDatacontext.setDatasource(ejDataManagerAssetTree, true);
                }

                vm.forceDatamanagerAssetTreeFilter = {
                    refreshKey: new Date().getTime(),
                    filter: currentFilter
                }
            });
        }

        //var ejDataManagerUser = null;
        //function refreshUserGridDatasource() {
        //    $timeout(() => {
        //        var currentFilter = {
        //            appUserFilter: {
        //                DrbacProfileIds: [vm.currentUserProfile.UserProfileId]
        //            },
        //            drbacGlobalAppUserSettingFilter: {
        //                IsHiddenUser: null
        //            }
        //        }

        //        if (!ejDataManagerUser) {
        //            var supportData = { translate: vm.translate, contextService: contextService };
        //            ejDataManagerUser = rbacService.getContextUserDataManager(supportData, currentFilter, ejDataManagerUser);
        //            nbsGridUserDatacontext.setDatasource(ejDataManagerUser, true);
        //        }

        //        vm.forceDatamanagerUserFilter = {
        //            refreshKey: new Date().getTime(),
        //            filter: currentFilter
        //        }
        //    });
        //}
    };

    userProfileDetail.$inject = injectParams;
    userProfileDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rbac.module').controller('userProfileDetail', userProfileDetail);
});