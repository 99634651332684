﻿'use strict';
//modal params: 
//cronstring: string 
//modal result
//CronObj   ///  cronstring
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'navigationService', 'checkStateChangeService', 'vhreventService', 'productionService', 'contextService'];
    function vhrEventChangeStartDate($q, $scope, $timeout, $injector, navigationService, checkStateChangeService, vhreventService, productionService, contextService) {

        var vm = this;
        nbsVm.call(vm, vhreventService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, vhreventService);

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var vhrEventShuffleActionObj = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventShuffleAction, null, vm.translate).obj.normal.name_value;

        // startDate
        // startTime
        // KeepSameTime
        // KeepSameWorkCenter
        // TargetWorkCenterId
        // VhrEventsIdSerialized
        var pParams = navigationService.stateParamsModal().parentParams;
        pParams = pParams ? pParams : navigationService.stateParams();

        // used to validate form and to return data to controller that opens modal
        vm.vhrEventShuffleDto = vhreventService.dataContext.vhrEventShuffleDto.add();
        vm.vhrEventShuffleDto.VhrEventShuffleAction = vhrEventShuffleActionObj.ChangeStartDate;
        vm.vhrEventShuffleDto.StartDate = pParams.startDate;
        vm.vhrEventShuffleDto.StartTime = pParams.startTime;
        vm.vhrEventShuffleDto.KeepSameTime = pParams.KeepSameTime;
        vm.vhrEventShuffleDto.KeepSameWorkCenter = pParams.KeepSameWorkCenter;
        vm.vhrEventShuffleDto.TargetWorkCenterId = pParams.TargetWorkCenterId;
        vm.vhrEventShuffleDto.VhrEventLifeStatus = 0;
        vm.vhrEventShuffleDto.VhrEventBillingStatus = 0;

        getVirtualWorkCenters(true, appStoreBw);

        // submit and close modal
        vm.modalOk = function () {
            vhreventService.rejectChanges();
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalOk);
            vm.vhrEventShuffleDto.entityAspect.validateEntity();

            if (!vm.vhrEventShuffleDto.entityAspect.hasValidationErrors) {
                navigationService.closeCurrentModal(modalResult);
            }
        };

        // close modal
        vm.modalCancel = function () {
            vhreventService.rejectChanges();
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalCancel);
            navigationService.closeCurrentModal(modalResult);
        };

        function getVirtualWorkCenters(forceReload, appStoreBw) {
            var filter = { AppStoreBw: appStoreBw };
            var defer = $q.defer();

            productionService.getVirtualWorkCenters(filter, forceReload)
                .then(function (data) {
                    vm.workCenters = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getModalResult(dialogResult) {
            var modalResult = new navigationService.modalResult()
            modalResult.data.startDate = vm.vhrEventShuffleDto.StartDate;
            modalResult.data.startTime = vm.vhrEventShuffleDto.StartTime;
            modalResult.data.keepSameTime = vm.vhrEventShuffleDto.KeepSameTime;
            modalResult.data.keepSameWorkCenter = vm.vhrEventShuffleDto.KeepSameWorkCenter;
            modalResult.data.targetWorkCenterId = vm.vhrEventShuffleDto.TargetWorkCenterId;
            modalResult.data.VhrEventShuffleAction = vm.vhrEventShuffleDto.VhrEventShuffleAction;

            modalResult.sourceParams = pParams;
            modalResult.dialogResult = dialogResult;
            return modalResult;
        }
    };

    vhrEventChangeStartDate.$inject = injectParams;
    vhrEventChangeStartDate.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('vhrEventChangeStartDate', vhrEventChangeStartDate);
});