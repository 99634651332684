﻿'use strict';
define([], function () {

    var injectParams = ['$q', '$timeout', 'notificationService', 'utilService'];
    var nbsNotificationBarComponent = function ($q, $timeout, notificationService, utilService) {
        var vm = this;
        var privateUIData = null;
        var defaultKeys;
        var privateDatacontext = null;
        var nbsUiOptionParser = null;

        vm.privateComponentsStatus = {};
        vm.nbsDataPrivate = {};

        vm.$onInit = function () {
            vm.expirationTranslate = vm.uiContext.translate('Notification.CreationDate');
        };

        vm.$onChanges = function (changes) {
            if (changes.nbsDataContext && changes.nbsDataContext.currentValue) {
                nbsUiOptionParser = new NbsUiOptionParser(vm.uiContext.translate);

                privateDatacontext = changes.nbsDataContext.currentValue;

                // set default selections
                defaultKeys = privateDatacontext.defaultKeys;

                // Start of execution workflow of load data bar components 
                initViewTitle();
            }
        };

        function initViewTitle() {
            var cols = ['NotificationId', 'NotificationSubject', 'AddressTo', 'NotificationStatusName', 'CreationDate', 'NotificationSourceCode', 'Result'];
            var defaultStartData = new NbsUiData();
            var defaultSelected = Nembus.Common.Library.component.bar.datarange.getTimelineDatarange('d-7');
            defaultStartData.setFilter('creationdatetimeline', defaultSelected);
            defaultStartData.setColumns(cols);
            defaultStartData.setSortingField('CreationDate', true);
            defaultStartData.setFilterList(getFilterList());

            var sortField = 'NotificationId';
            var sortDirection = true; // descending
            defaultStartData.setSortingField(sortField, sortDirection);

            vm.initViewTitle = defaultStartData.startData;
        }

        //#region hook from components
        vm.onViewTitleSelectionEvent = function (result) {
            privateUIData = JSON.parse(JSON.stringify(result.uiData));
            setComponents(privateUIData);

            setBarDatasources()
                .then(function () {
                    barOnChanges(privateUIData);
                });
        };
        vm.onUiChanges = function (result) {
            privateUIData.columns = result.uiData.columns;
            privateUIData.grouping = result.uiData.grouping;
            privateUIData.sorting = result.uiData.sorting;
            privateUIData.filters.freeText = result.uiData.filters.freeText;

            refreshViewTitleDataContext(privateUIData);

            vm.onGridUiChangesEvent({ result: privateUIData });
        };
        vm.onFilterChanges = function (result) {
            privateUIData.filterList = result.filters.filterList;

            refreshViewTitleDataContext(privateUIData);
            setStatusComponents(privateUIData.filterList);

            setBarDatasources()
                .then(function () {
                    barOnChanges(privateUIData);
                });
        };
        vm.onDatarangeSelectionEvent = function (timeline) {
            privateUIData.filters.creationdatetimeline = timeline;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdNotificationStatus = function (options) {
            privateUIData.filters.notificationstatus = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        //#endregion

        // refresh current preference uiData
        function refreshViewTitleDataContext(uiDataParam) {
            vm.viewTitleDataContext = Nembus.Common.Library.component.getDataContextViewTitle(uiDataParam);
        }

        function setBarDatasources() {
            var defer = $q.defer();
            var funcsPromises = [];

            if (vm.privateComponentsStatus.notificationstatus.isVisible) {
                funcsPromises.push(getNotificationStatuses());
            }

            $q.all(funcsPromises)
                .then(function () {
                    defer.resolve(true);
                });

            return defer.promise;
        }

        function getNotificationStatuses() {
            var defer = $q.defer();
            var notificationStatusEnumParsedObj = Nembus.Common.Library.util.parseEnum(notificationService.dataContext.enumTypes.NotificationStatus, 'Notification', vm.uiContext.translate);
            vm.nbsDataPrivate.notificationstatus = nbsUiOptionParser.getUiOptionsByEnum(notificationStatusEnumParsedObj.list.translated);
            defer.resolve(true);
            return defer.promise;
        }

        function getFilterList() {
            var uiDataFilters = [];
            uiDataFilters.push(new NbsUiDataFilter({ code: 'notificationstatus', name: 'Notification.NotificationStatus' }));
            uiDataFilters.push(new NbsUiDataFilter({ code: 'creationdatetimeline', name: 'Notification.CreationDate', canHide: false }));
            return uiDataFilters;
        }

        function setComponents(uiDataParam) {
            setCommonGridBarDataContext(uiDataParam);
            setSelectionComponents(uiDataParam);
            setStatusComponents(uiDataParam.filterList);
            vm.onGridUiChangesEvent({ result: privateUIData });
        }

        function setCommonGridBarDataContext(uiDataParam) {
            vm.commonGridBarDataContext = Nembus.Common.Library.component.getDataContextCommonGridBar(uiDataParam);
            vm.commonBarDatasource = privateDatacontext.commonBarDatasource
        }

        function setSelectionComponents(uiDataParam) {
            vm.nbsDataPrivate.notificationstatusselected = uiDataParam.filters.notificationstatus ? uiDataParam.filters.notificationstatus : [];
            vm.nbsDataPrivate.creationdatetimelineselected = uiDataParam.filters.creationdatetimeline;
        }

        function setStatusComponents(filterList) {
            vm.privateComponentsStatus.creationdatetimeline = Nembus.Common.Library.component.getStatus('creationdatetimeline', filterList);
            vm.privateComponentsStatus.notificationstatus = Nembus.Common.Library.component.getStatus('notificationstatus', filterList);
        }

        function barOnChanges(uiDataParam) {
            var expressionTypeEnum = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.ExpressionType, null, vm.uiContext.translate).obj.normal.name_value;
            var vhreventtimelinefrom = Nembus.Common.Library.component.bar.datarange.getDataRangeFromUiDataParam(uiDataParam.filters.creationdatetimeline).dateFrom;
            var vhreventtimelineto = Nembus.Common.Library.component.bar.datarange.getDataRangeFromUiDataParam(uiDataParam.filters.creationdatetimeline).dateTo;
            //var vhreventtimelinefrom = uiDataParam.filters.creationdatetimeline.dateFrom;
            //var vhreventtimelineto = uiDataParam.filters.creationdatetimeline.dateTo;
            var selectedNotificationStatus = vm.privateComponentsStatus.notificationstatus.isVisible
                ? Lazy(uiDataParam.filters.notificationstatus).pluck('optionValue').first()
                : null;

            $timeout(() => {
                vm.onBarChanges({
                    result: {
                        notificationFilter: {
                            NotificationStatus: selectedNotificationStatus,
                            CreationDate1Expression: expressionTypeEnum.GreaterThanOrEqual,
                            CreationDate1: vhreventtimelinefrom,
                            CreationDate2Expression: expressionTypeEnum.LessThanOrEqual,
                            CreationDate2: vhreventtimelineto
                        }
                    }
                });
            });
        }
        //#endregion 
    };

    nbsNotificationBarComponent.$inject = injectParams;
    angular.module('nbs.rds.module').component('nbsNotificationBar', {
        templateUrl: '/rds/notifications/components/nbsNotificationBarComponent/_?c=' + window.codeCache(),
        controller: nbsNotificationBarComponent,
        controllerAs: 'vm',
        bindings: {
            onBarChanges: '&',
            onGridUiChangesEvent: '&',

            nbsDataContext: "<", //e.g. {vhreventtypes:[NbsUiOption,NbsUiOption],vhreventgroups:[],status etc..}
            uiContext: "<"
        }
    });
});

