﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'navigationService', 'integrationService', 'checkStateChangeService', 'contextService'];
    function integrationFileObserversList($q, $scope, $timeout, $injector, navigationService, integrationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, integrationService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return fileObserverSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, integrationService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var fileObserverSelected = null;

        ribbon.setRibbonStatusButton(ribbon.Buttons.New, false);
        ribbon.refreshStatusButtons();

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
 
            $q.all(funcsPromises)
                .then(function () {
                    var ejDataManagerFileObserver = integrationService.getFileObserverDataManager({}, ejDataManagerFileObserver);
                    var nbsGridDatacontextFileObserver = new NbsGrid(vm.translate);
                    nbsGridDatacontextFileObserver.configureGrid('integrationfileobserver', null);
                    nbsGridDatacontextFileObserver.viewCommonBar();
                    nbsGridDatacontextFileObserver.setDatasource(ejDataManagerFileObserver, true);
                    vm.nbsGridFileObserverDatacontext = nbsGridDatacontextFileObserver;
                });
        }

        vm.rowFileObserverDblClick = function (args) {
            openJournalModal(args.data.IntegrationFileObserverId);
        };

        vm.gridFileObserverSelection = function (args) {
            fileObserverSelected = args.data;
        };

        vm.refreshAction = function () {
            $timeout(() => {
                vm.forceGridRefresh = new Date().getTime();
            });
        };

        function openJournalModal(integrationFileObserverId) {
            var ejDataManagerFileObserverJournal = integrationService.getFileObserverJournalDataManager({ IntegrationFileObserverId: integrationFileObserverId }, ejDataManagerFileObserverJournal);
            var nbsGridDatacontextFileObserverJournal = new NbsGrid(vm.translate);
            nbsGridDatacontextFileObserverJournal.configureGrid('integrationfileobserverjournal', null);
            nbsGridDatacontextFileObserverJournal.setModalMode();
            nbsGridDatacontextFileObserverJournal.setDatasource(ejDataManagerFileObserverJournal, true);
            vm.nbsGridFileObserverJournalDatacontext = nbsGridDatacontextFileObserverJournal;

            vm.modalInstance = navigationService.modal().open(vm.modalOptions({ templateUrl: 'fileObserverJournalTemplate', scope: $scope }));
            vm.modalInstance.title = vm.translate('IntegrationFileObserverJournal.IntegrationFileObserverJournal');
            vm.modalInstance.ok = function () { vm.modalInstance.close(); };
            vm.modalInstance.cancel = function () { vm.modalInstance.dismiss(); };
            vm.modalInstance.result.then(function () {
                // onClose
            }, function () {
                // onDismiss
            });
        }
    }

    integrationFileObserversList.$inject = injectParams;
    integrationFileObserversList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('integrationFileObserversList', integrationFileObserversList);
});