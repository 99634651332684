﻿'use strict';

define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$injector', '$scope', '$timeout', 'audService', 'navigationService', 'checkStateChangeService', 'contextService', 'cmsService'];
    function surveyTemplatesList($q, $injector, $scope, $timeout, audService, navigationService, checkStateChangeService, contextService, cmsService) {
        var vm = this;
        nbsVm.call(vm, audService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return surveyTemplateSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, audService);

        var ribbon = new NbsAudRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab({ showGroupArchive: true });
        ribbon.addPrintReportTab();
        ribbon.replaceSectionWith(ribbon.SECTIONKEY.home_new, ribbon.GetTemplateNewSection());
        vm.ribbonDatacontext = ribbon;

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var viewKind = navigationService.stateParams().viewKind;        // all, archived
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var surveyCreationRequestType = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyCreationRequestType, null, vm.translate).obj.normal.name_value;
        var surveyCreationRequestStatus = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyCreationRequestStatus, null, vm.translate).obj.normal.name_value;
        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
        var surveyTemplateSelected = null;

        ribbon.setRibbonStatusButton(ribbon.Buttons.NewTemplate, true);
        ribbon.refreshStatusButtons();

        var isArchived = false;
        if (viewKind === 'archived') {
            isArchived = true;
        }

        var nbsGridDatacontext = new NbsAudGrid(vm.translate);
        nbsGridDatacontext.configureGrid('surveytemplate', 'list', null);
        nbsGridDatacontext.viewCustomBar();
        nbsGridDatacontext.setGridIsRenderedInlineBlock();
        nbsGridDatacontext.setDatasource([], true); // fake datasource, only to create grid
        vm.nbsGridDatacontext = nbsGridDatacontext;

        vm.createGridEvent = function (result) {
            vm.surveyTemplateGridBarDataContext = {
                commonBarDatasource: result.barDatasource,
                defaultKeys: {
                    columnsKey: viewKind, sortingKey: null
                },
                defaultGridColsVisibleMode: viewKind,  // all, archived
            };
        };

        vm.gridUiOnChanges = function (result) {
            vm.refreshGridUiComponent = {
                refreshKey: new Date().getTime(),
                uiData: result
            }
        }

        function refreshGridDatasource(currentFilter) {
            $timeout(() => {
                vm.forceDatamanagerFilter = {
                    refreshKey: new Date().getTime(),
                    filter: currentFilter
                }
            });
        }

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.surveytemplatedetail", { 'surveyId': args.data.SurveyId, 'appStoreCode': appStoreCode });
        };

        vm.newAction = function () {
            navigationService.navigateTo("app.nav.surveytemplatedetail", { 'surveyId': null, 'appStoreCode': appStoreCode });
        };

        // hook from gridbar
        var ejDataManager = null;
        var currentSurveyBarFilter = null;
        var currentFilter = null;
        vm.surveyTemplateGridBarOnChanges = function (result) {
            currentSurveyBarFilter = result.surveyFilter;
            currentSurveyBarFilter.AppStoreBw = appStoreBw;
            currentSurveyBarFilter.IsArchived = isArchived;

            currentFilter = { filter: currentSurveyBarFilter };
            if (ejDataManager === null) {
                var supportData = { translate: vm.translate, contextService: contextService };
                ejDataManager = audService.getSurveyTemplateDataManager(supportData, currentFilter, ejDataManager);
                nbsGridDatacontext.setDatasource(ejDataManager, true);
                refreshGridDatasource(currentFilter);
            } else {
                refreshGridDatasource(currentFilter)
            }
        };

        vm.gridUiOnChanges = function (result) {
            vm.refreshGridUiComponent = {
                refreshKey: new Date().getTime(),
                uiData: result
            }
        }

        vm.refreshAction = function () {
            $timeout(() => {
                vm.forceGridRefresh = new Date().getTime();
            });
        };

        vm.gridSelection = function (args) {
            surveyTemplateSelected = args.data;

            $timeout(() => {
                if (surveyTemplateSelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, true);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, true);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Compile, true);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Archive, isArchived === false);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Extract, isArchived === true);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, false);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Compile, false);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Archive, false);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Extract, false);
                }

                ribbon.refreshStatusButtons();
            });
        };

        vm.copyAction = function () {
            if (!surveyTemplateSelected) {
                return;
            }

            audService.surveyCreationCollector.CopyOptionsCheckedFromSource = false;
            audService.surveyCreationCollector.CopyOptionValueFromSource = false;
            audService.surveyCreationCollector.CopyOptionWeightFromSource = false;
            audService.surveyCreationCollector.CopyOptionChoiceFromSource = false;
            audService.surveyCreationCollector.ValidationSchemaId = surveyTemplateSelected.ValidationSchemaId;

            //disable dirty flag
            vm.suspendDirty(true);

            navigationService
                .navToModal(vm.suspendDirty, 'app.nav.copysurveyoptionsdetail-surveytemplate', { entityName: 'SurveyTemplate' })
                .then(function (modalResult) {
                    //enable dirty flag
                    vm.suspendDirty(false);

                    if (modalResult.dialogResult === navigationService.navigationActionTypes.modalOk) {
                        var batchName = {};
                        batchName.BatchRequestName = vm.translate("Copy") + ' ' + vm.translate("SurveyTemplate.SurveyTemplate");
                        batchName.BatchRequestDescription = vm.translate("Copy") + ' ' + vm.translate("SurveyTemplate.SurveyTemplate");

                        var surveyCreationRequest = audService.dataContext.surveyCreationRequests.add({
                            SurveyCreationRequestId: NbsGuidManager.newGuid(),
                            SurveyCreationRequestCode: 'clone-survey-template',
                            SurveyCreationRequestName: 'clone-survey-template',
                            SurveyCreationRequestStatus: surveyCreationRequestStatus.Inserted,
                            VirtualCompanyId: virtualCompanyId,
                            SurveyCreationRequestTypeId: surveyCreationRequestType.CloneSurveyTemplate,
                            CopyOptionsCheckedFromSource: modalResult.data.CopyOptionsCheckedFromSource,
                            CopyOptionValueFromSource: modalResult.data.CopyOptionValueFromSource,
                            CopyOptionWeightFromSource: modalResult.data.CopyOptionWeightFromSource,
                            CopyOptionChoiceFromSource: modalResult.data.CopyOptionChoiceFromSource,
                            ValidationSchemaId: modalResult.data.ValidationSchemaId,
                            ModuleTargetId: moduleTargetId,
                            SurveyTemplateIdSerialized: JSON.stringify([surveyTemplateSelected.SurveyId]),
                            VirtualCompanyIdsSerialized: modalResult.data.VirtualCompanyIdsSerialized,
                            BatchRequestName: JSON.stringify(batchName)
                        });

                        audService.saveChanges()
                            .then(function (result) {
                                if (surveyCreationRequest.IsBatch) {
                                    contextService.addBatchRequest({
                                        requestId: surveyCreationRequest.BatchRequestId,               //only one entity will be submitted
                                        requestName: vm.translate('SurveyCreationRequestCloneSurveyTemplateBatchName'),
                                        requestStatus: 'inserted',
                                        detailAction: function (request) {
                                        }
                                    });
                                } else {
                                    vm.refreshAction();
                                }
                            });
                    }
                });
        };

        vm.deleteAction = function () {
            if (!surveyTemplateSelected) {
                return;
            }

            audService.deleteEntity(surveyTemplateSelected);
            vm.submitAction()
                .then(function (saveResult) {
                    vm.refreshAction();
                });
        };

        vm.archiveAction = function () {
            if (!surveyTemplateSelected) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        var surveysId = JSON.stringify([surveyTemplateSelected.SurveyId]);
                        vm.surveyCreationRequest = audService.dataContext.surveyCreationRequests.add({
                            SurveyCreationRequestId: NbsGuidManager.newGuid(),
                            SurveyCreationRequestCode: 'archive-survey-template',
                            SurveyCreationRequestName: 'archive-survey-template',
                            SurveyTemplateIdSerialized: surveysId,
                            ModuleTargetId: moduleTargetId,
                            SurveyCreationRequestTypeId: surveyCreationRequestType.ArchiveSurveyTemplate,
                            VirtualCompanyId: virtualCompanyId,
                            BatchRequestName: vm.translate('Archive') + ' - ' + surveyTemplateSelected.SurveyName
                        });

                        audService.saveChanges()
                            .then(function (result) {
                                if (vm.surveyCreationRequest.IsBatch) {
                                    contextService.addBatchRequest({
                                        requestId: vm.surveyCreationRequest.BatchRequestId,               //only one entity will be submitted
                                        requestName: vm.surveyCreationRequest.BatchRequestName,
                                        requestStatus: 'inserted',
                                        detailAction: function (request) {
                                        }
                                    });
                                } else {
                                    vm.refreshAction();
                                }
                            });
                    }
                });
        };

        vm.extractAction = function () {
            if (!surveyTemplateSelected) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        var surveysId = JSON.stringify([surveyTemplateSelected.SurveyId]);
                        vm.surveyCreationRequest = audService.dataContext.surveyCreationRequests.add({
                            SurveyCreationRequestId: NbsGuidManager.newGuid(),
                            SurveyCreationRequestCode: 'extract-survey-template',
                            SurveyCreationRequestName: 'extract-survey-template',
                            SurveyTemplateIdSerialized: surveysId,
                            ModuleTargetId: moduleTargetId,
                            SurveyCreationRequestTypeId: surveyCreationRequestType.ExtractSurveyTemplate,
                            VirtualCompanyId: virtualCompanyId,
                            BatchRequestName: vm.translate('Extract') + ' - ' + surveyTemplateSelected.SurveyName
                        });

                        audService.saveChanges()
                            .then(function (result) {
                                if (vm.surveyCreationRequest.IsBatch) {
                                    contextService.addBatchRequest({
                                        requestId: vm.surveyCreationRequest.BatchRequestId,               //only one entity will be submitted
                                        requestName: vm.surveyCreationRequest.BatchRequestName,
                                        requestStatus: 'inserted',
                                        detailAction: function (request) {
                                        }
                                    });
                                } else {
                                    vm.refreshAction();
                                }
                            });
                    }
                });
        };


        //#region Override of base methods to add verify on selection record
        function canPrintReport() {
            var result = false;
            if (!surveyTemplateSelected) {
                navigationService.selectOneItemMessageModal();
            } else {
                result = true;
            }

            return result;
        }

        vm.exportReportToPdfAction = function () { pExportReport('pdf'); };
        vm.exportReportToWordAction = function () { pExportReport('openword'); };
        vm.exportReportToXmlAction = function () { pExportReport('xml'); };
        vm.exportReportToExcelAction = function () { pExportReport('openexcel'); };
        vm.exportReportToHtmlAction = function () { pExportReport('html'); };

        function pExportReport(format) {
            if (canPrintReport()) {
                vm.getReportsForCmsView(cmsService)
                    .then(function () {
                        var cEntity = surveyTemplateSelected;
                        var currentReportDefinition = vm.currentReportData.reportDefinition;
                        var currentConcatName = cEntity.SurveyCode + ' - ' + cEntity.SurveyName;

                        if (currentReportDefinition) {
                            currentConcatName = currentReportDefinition.ReportDefinitionReferenceCode + ' - ' + currentConcatName;
                        }

                        var reportArgs = {
                            Ds1_Arg1: { SurveyId: cEntity.SurveyId },                 // SurveyFilter
                            Ds1_Arg2: false                                                             // IsConfigurationMode
                        };

                        vm.setReportData({ reportArgs: reportArgs, replaceName: currentConcatName });

                        vm.exportReport(format);
                    });
            }
        }
        //#endregion overload of base methods to add verify on selection record
    }

    surveyTemplatesList.$inject = injectParams;
    surveyTemplatesList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('surveyTemplatesList', surveyTemplatesList);
});