﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'navigationService', 'checkStateChangeService', 'contextService', 'currentSessionService'];
    var categoriesManage = function ($q, $scope, $timeout, $injector, foundationService, navigationService, checkStateChangeService, contextService, currentSessionService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, foundationService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        ribbon.addTab(new ribbon.Tab("ActionPanelTabManage", [ribbon.GetCategoryDefaultSection()]));
        vm.ribbonDatacontext = ribbon;

        var currentSystemCategoryCode = navigationService.stateParams().systemCategoryCode;
        var categoryRequestStatusEnum = Nembus.Common.Library.util.parseEnum(foundationService.dataContext.enumTypes.CategoryRequestStatus, null, vm.translate).obj.normal.name_value;
        var categoryRequestTypeEnum = Nembus.Common.Library.util.parseEnum(foundationService.dataContext.enumTypes.CategoryRequestType, null, vm.translate).obj.normal.name_value;
        var typeOperation = { Crud: 1, DragAndDrop: 2 };
        var action = { add: 1, modify: 2, delete: 3 };
        var currentAction = null;
        var categorySelected = null;

        vm.isEditing = false;
        ribbon.setRibbonStatusButton(ribbon.Buttons.Save, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.Modify, false);
        ribbon.refreshStatusButtons();
        
        vm.ejTreeViewDatacontext = {
            allowDragAndDrop: true,
            systemCategoryCode: currentSystemCategoryCode
        };

        vm.addDefaultAction = function () {
            if (!categorySelected) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            if (!canSetPathStart(categorySelected)) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            if (foundationService.hasChanges()) {
                navigationService.customMessageModal(vm.translate("CategoriesPendingChanges"));
                return;
            }

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        foundationService.setCategoryPathStart({ CategoryId: categorySelected.CategoryId })
                            .then(function (result) {
                                refreshTreeView();
                            });
                    }
                });
        };
        vm.removeDefaultAction = function () {
            if (!categorySelected) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            if (!canRemovePathStart(categorySelected)) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            if (foundationService.hasChanges()) {
                navigationService.customMessageModal(vm.translate("CategoriesPendingChanges"));
                return;
            }

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        foundationService.removeCategoryPathStart({ CategoryId: categorySelected.CategoryId })
                            .then(function (result) {
                                refreshTreeView();
                            });
                    }
                });
        };
        vm.modifyAction = function () {
            if (!categorySelected) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            if (!canModifyNode(categorySelected)) {
                navigationService.operationNotPermittedModal();
                return;
            }

            currentAction = action.modify;
            vm.isEditing = true;
            
            var key = categorySelected.CategoryKindId === 2 ? 'ModifyCategory' : 'ModifyFolder';
            openCrudModal(key);
        };
        vm.newAction = function () {
            if (!categorySelected) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            if (!canInsertNode(categorySelected)) {
                navigationService.operationNotPermittedModal();
                return;
            }

            currentAction = action.add;
            vm.isEditing = false;
            
            openCrudModal('NewCategory');
        };
        vm.newFolderAction = function () {
            if (!categorySelected) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            if (!canInsertNode(categorySelected)) {
                navigationService.operationNotPermittedModal();
                return;
            }

            currentAction = action.add;
            vm.isEditing = false;

            openCrudModal('NewFolder');
        };
        vm.deleteAction = function () {
            if (!categorySelected) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            if (!canDeleteNode(categorySelected)) {
                navigationService.operationNotPermittedModal();
                return;
            }

            currentAction = action.delete;

            foundationService.deleteEntity(categorySelected);
            
            submitAndReload(typeOperation.Crud);
        };

        //#region Events from component
        vm.onNodeDragStart = function (args, data) {
            if (data.hasError === true) {
                if (data.messageKey) {
                    navigationService.customMessageModal(vm.translate(data.messageKey));
                    args.cancel = true;
                    return;
                } else {
                    args.cancel = true;
                    return;
                }
            }
        };
        vm.onNodeDragStop = function (args, data) {
            if (data.hasError === true) {
                if (data.messageKey) {
                    navigationService.customMessageModal(vm.translate(data.messageKey));
                    args.cancel = true;
                    return;
                } else {
                    args.cancel = true;
                    return;
                }
            }

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        submitAndReload(typeOperation.DragAndDrop, data.idStart, data.idStop);
                    } else {
                        refreshTreeView();
                    }
                });
        };
        vm.onNodeSelect = function (args, node) {
            $timeout(function () {
                categorySelected = node;

                ribbon.setRibbonStatusButton(ribbon.Buttons.Modify, canModifyNode(categorySelected));
                ribbon.setRibbonStatusButton(ribbon.Buttons.NewFolder, canInsertNode(categorySelected));
                ribbon.setRibbonStatusButton(ribbon.Buttons.New,  canInsertNode(categorySelected));
                ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, canDeleteNode(categorySelected));
                ribbon.setRibbonStatusButton(ribbon.Buttons.AddDefault, canSetPathStart(categorySelected));
                ribbon.setRibbonStatusButton(ribbon.Buttons.RemoveDefault, canRemovePathStart(categorySelected));
                ribbon.refreshStatusButtons();
            });
        };
        //#endregion

        var parents = vm.submitAction;
        function submitAndReload(type, idStart, idStop) {
            var defer = $q.defer();

            vm.removeAlertFromList(true);
            vm.removeAlerts();

            if (type === typeOperation.Crud) {
                vm.alertConfig.isModal = true;
                vm.categoryRequest = getCrudRequest();
            } else {
                vm.alertConfig.isModal = false;
                vm.categoryRequest = getDragAndDropRequest(idStart, idStop);
            }
            vm.alertConfig.isReadonly = true;

            parents()
                .then(function (saveResult) {
                    if (saveResult.entities.length > 0) {
                        refreshTreeView();

                        // clear cache
                        // uset to make categories visible without refresh
                        foundationService.dataContext.categoryTextPathDtos.clearCache();
                        foundationService.dataContext.categories.clearCache();
                        currentSessionService.dataContext.categories.clearCache();
                    }

                    defer.resolve(true);
                })
                .catch(function (error) {
                    // will be returned a Dictionary<string, List<string>>
                    var errors = null;
                    var errorMessage = null;
                    try {
                        errors = JSON.parse(error.message);
                    } catch (e) {
                        errorMessage = error.message;
                    }

                    var message = null;
                    if (errorMessage) {
                        message = errorMessage;
                    } else {
                        var errorKeyMessage = Object.keys(errors)[0];
                        var errorCategoryCodes = errors[errorKeyMessage].join(',');

                        error.message = vm.translate(errorKeyMessage);
                        if (errorCategoryCodes) {
                            error.message += ':' + errorCategoryCodes;
                        }
                        message = error.message;
                    }

                    if (message) {
                        vm.setErrorMsg({ isMessageVisible: true, message: message });
                    }

                    if (type === typeOperation.DragAndDrop) {
                        refreshTreeView();
                        vm.categoryRequest.entityAspect.rejectChanges();
                    } else {
                        vm.categoryRequest.entityAspect.rejectChanges();
                    }
                });

            return defer.promise;
        }

        function openCrudModal(key) {
            if (key === 'ModifyCategory' || key === 'ModifyFolder') {
                vm.modalCategory = categorySelected;
            } if (key === 'NewCategory') {
                vm.modalCategory = getNewCategory();
            } if (key === 'NewFolder') {
                vm.modalCategory = getNewFolder();
            }
            
            vm.removeAlertFromList(true);

            vm.modalInstance = navigationService.modal().open(vm.modalOptions({ windowClass: vm.NBS_MODAL_CLASS_FORM_MD, templateUrl: 'categoriesTemplate', scope: $scope }));
            vm.modalInstance.title = vm.translate(key);
            vm.modalInstance.ok = function () {
                vm.modalCategory.entityAspect.validateEntity();
                if (!vm.modalCategory.entityAspect.hasValidationErrors) {
                    switch (currentAction) {
                        case action.add:
                        case action.modify:
                            submitAndReload(typeOperation.Crud)
                                .then(() => {
                                    vm.modalInstance.close();
                                });
                            break;
                    }
                }
            };
            vm.modalInstance.cancel = function () {
                vm.modalInstance.dismiss();
            };
            vm.modalInstance.result.then(function () {
                //ok
            }, function () {
                //dismiss
                vm.removeAlertFromList(true);
                vm.modalCategory.entityAspect.rejectChanges();
            });
        }
        function refreshTreeView() {
            vm.ejTreeViewRefresh = new Date().getTime();
        }
        function getDragAndDropRequest(categoryId, categoryParentTo) {
            foundationService.dataContext.categoryRequests.add({
                CategoryRequestId: NbsGuidManager.newGuid(),
                CategoryRequestStatusId: categoryRequestStatusEnum.Inserted,
                CategoryRequestTypeId: categoryRequestTypeEnum.DragAndDrop,
                CategoryId: categoryId,                 // what category move
                CategoryParentTo: categoryParentTo,     // where moved
                VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId
            });
        }
        function getCrudRequest() {
            return foundationService.dataContext.categoryRequests.add({
                CategoryRequestId: NbsGuidManager.newGuid(),
                CategoryRequestStatusId: categoryRequestStatusEnum.Inserted,
                CategoryRequestTypeId: categoryRequestTypeEnum.CrudOperations,
                VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId
            });
        }
        function getNewCategory() {
            return foundationService.dataContext.categories.add({
                Deep: categorySelected.Deep + 1,
                ParentCategoryId: categorySelected.CategoryId,
                ParentCategoryCode: categorySelected.CategoryCode,
                IsSpecialBind: categorySelected.IsSpecialBind,
                CategoryKindId: foundationService.dataContext.consts.CATEGORYKINDS.category,
                CategoryKind: foundationService.dataContext.consts.CATEGORYKINDS.category
            });
        }
        function getNewFolder() {
            return foundationService.dataContext.categories.add({
                Deep: categorySelected.Deep + 1,
                ParentCategoryId: categorySelected.CategoryId,
                ParentCategoryCode: categorySelected.CategoryCode,
                IsSpecialBind: categorySelected.IsSpecialBind,
                CategoryKindId: foundationService.dataContext.consts.CATEGORYKINDS.folder,
                CategoryKind: foundationService.dataContext.consts.CATEGORYKINDS.folder
            });
        }
        function canInsertNode(category) {
            if (!category) {
                return false;
            }

            return category.CategoryKindId !== foundationService.dataContext.consts.CATEGORYKINDS.category;
        }
        function canDeleteNode(category) {
            if (!category) {
                return false;
            }

            return !category.IsSystemCategory;
        }
        function canModifyNode(category) {
            if (!category) {
                return false;
            }

            return !category.IsSystemCategory;  // every node can be modified
        }
        function canSetPathStart(category) {
            if (!category) {
                return false;
            }

            return !category.IsPathStart;  // every node can be modified
        }
        function canRemovePathStart(category) {
            if (!category) {
                return false;
            }

            return category.IsPathStart;  // every node can be modified
        }
    };

    categoriesManage.$inject = injectParams;
    categoriesManage.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('categoriesManage', categoriesManage);
});
