﻿'use strict';
define([], function () {

    var injectParams = ['$timeout'];
    var nbsSearchAssigneeComponent = function ($timeout) {
        var vm = this;
        vm.dataContext = null;
        var pDataSelected = null;
        var OPTIONKINDS = {
            AppUser: 'AppUser',
            DrbacUserGroup: 'DrbacUserGroup',
            DrbacRole: 'DrbacRole'
        };
        var retrievedDs = false;
        var retrievedDsSelected = false;

        vm.msDs = [];
        vm.ddUserSelectedDs = [];
        vm.ddUserGroupSelectedDs = [];
        vm.ddRoleSelectedDs = [];
        vm.dropdownDS = [];

        // datasources
        vm.ddUserDs = [];
        vm.ddUserGroupDs = [];
        vm.ddRoleDs = [];

        vm.isChecked = false;


        vm.$onInit = function () {
        };

        vm.$onChanges = function (changes) {
            if (changes.nbsDataContext && changes.nbsDataContext.currentValue) {
                vm.dataContext = changes.nbsDataContext.currentValue;
                setDdDatasources();
                retrievedDs = true;
            }

            if (changes.nbsDataSelected && changes.nbsDataSelected.currentValue) {
                retrievedDsSelected = true;
                pDataSelected = changes.nbsDataSelected.currentValue;
            }

            if (changes.checkboxLabel) {
                if (changes.checkboxLabel.currentValue) {
                    vm.pCheckboxLabel = changes.checkboxLabel.currentValue;
                } else {
                    vm.pCheckboxLabel = vm.uiContext.translate('Assigned');
                }
            }

            if (retrievedDs && retrievedDsSelected) {
                setSelection();
            }
        };

        vm.onOptionChangesMsEvent = function (sourceOptions) {
            vm.msDs = sourceOptions;

            Lazy(vm.ddUserDs)
                .each((item) => {
                    item.isChecked = Lazy(sourceOptions).where({ optionKind: item.optionKind, optionValue: item.optionValue }).some();
                });
            Lazy(vm.ddUserGroupDs)
                .each((item) => {
                    item.isChecked = Lazy(sourceOptions).where({ optionKind: item.optionKind, optionValue: item.optionValue }).some();
                });
            Lazy(vm.ddRoleDs)
                .each((item) => {
                    item.isChecked = Lazy(sourceOptions).where({ optionKind: item.optionKind, optionValue: item.optionValue }).some();
                });

            raiseSelected();
        };

        // when option changes in dropdown
        vm.onDdUserChanges = function (options) {
            Lazy(vm.ddUserDs)
                .each((item) => {
                    item.isChecked = Lazy(options).where({ optionValue: item.optionValue }).some();
                });
            raiseSelected();
        };

        vm.onDdUserGroupChanges = function (options) {
            Lazy(vm.ddUserGroupDs)
                .each((item) => {
                    item.isChecked = Lazy(options).where({ optionValue: item.optionValue }).some();
                });
            raiseSelected();
        };

        vm.onDdRoleChanges = function (options) {
            Lazy(vm.ddRoleDs)
                .each((item) => {
                    item.isChecked = Lazy(options).where({ optionValue: item.optionValue }).some();
                });
            raiseSelected();
        };

        vm.checkboxOnClick = function (bool) {
            vm.isChecked = bool;
            raiseSelected();
        };

        function setSelection() {
            $timeout(() => {
                // set selection data
                Lazy(pDataSelected.appusers).each((item) => { vm.msDs.push(item); });
                Lazy(pDataSelected.drbacusergroups).each((item) => { vm.msDs.push(item); });
                Lazy(pDataSelected.drbacroles).each((item) => { vm.msDs.push(item); });

                vm.ddUserSelectedDs = Lazy(pDataSelected.appusers).toArray();
                vm.ddUserGroupSelectedDs = Lazy(pDataSelected.drbacusergroups).toArray();
                vm.ddRoleSelectedDs = Lazy(pDataSelected.drbacroles).toArray();

                vm.isChecked = !pDataSelected.isNotAssigned ? false : true;
            });
        }

        function setDdDatasources() {
            Lazy(vm.dataContext.appusers).each((item) => { item.optionKind = OPTIONKINDS.AppUser; item.optionIconCode = 'users'; });
            Lazy(vm.dataContext.drbacusergroups).each((item) => { item.optionKind = OPTIONKINDS.DrbacUserGroup; item.optionIconCode = 'usergroups'; });
            Lazy(vm.dataContext.drbacroles).each((item) => { item.optionKind = OPTIONKINDS.DrbacRole; item.optionIconCode = 'roles'; });

            vm.ddUserDs = vm.dataContext.appusers;
            vm.ddUserGroupDs = vm.dataContext.drbacusergroups;
            vm.ddRoleDs = vm.dataContext.drbacroles;
        }

        function raiseSelected() {
            var result = {
                isNotAssigned: vm.isChecked,
                appusers: vm.dataContext.viewAppUsers ? Lazy(vm.dataContext.appusers).where({ isChecked: true }).toArray() : [],
                drbacusergroups: vm.dataContext.viewDrbacUserGroups ? Lazy(vm.dataContext.drbacusergroups).where({ isChecked: true }).toArray() : [],
                drbacroles: vm.dataContext.viewDrbacRoles ? Lazy(vm.dataContext.drbacroles).where({ isChecked: true }).toArray() : []
            };
            vm.optionChangesEvent({ result: result });
        }
    };

    nbsSearchAssigneeComponent.$inject = injectParams;
    angular.module('nbs.rds.module').component('nbsSearchAssignee', {
        templateUrl: '/rds/common/components/nbsSearchAssigneeComponent/_?c=' + window.codeCache(),
        controller: nbsSearchAssigneeComponent,
        controllerAs: 'vm',
        bindings: {
            optionChangesEvent: '&',
            nbsDataContext: "<", //[uiOptions,uiOptions]
            nbsDataSelected: "<",
            isVisible: "<",
            isOnlyAssignedHidden: "<",
            uiContext: "<",
            ddIconType: "@",
            ddLabel: '<',
            checkboxLabel: '<'
        }
    });
});

