﻿
'use strict';
define(['../../common/services/nbs-service'], function (nbsService) {

    var injectParams = ['$http', '$q', '$timeout', 'breezeDataLayer'];

    var biService = function ($http, $q, $timeout, breezeDataLayer) {

        var dataLayer = breezeDataLayer.Initialize('bi', true);
        var manager = dataLayer.GetManager();

        var entityTypes = {
            BiDataDefinition: 'BiDataDefinition',
            BiDataDefinitionGroup: 'BiDataDefinitionGroup',
            BiDataRequest: 'BiDataRequest'
        };

        nbsService.call(this, manager, $q, $timeout);

        this.dataContext = {
            biDataDefinitions: dataLayer.setContextMethods(manager, nbsService, entityTypes.BiDataDefinition),
            biDataDefinitionGroups: dataLayer.setContextMethods(manager, nbsService, entityTypes.BiDataDefinitionGroup),
            biDataRequests: dataLayer.setContextMethods(manager, nbsService, entityTypes.BiDataRequest),

            enumTypes: {

            }
        };

        this.getBiDataDefinitions = function (filter, forceReload) {
            var params = { entityType: entityTypes.BiDataDefinition, cache: this.dataContext.biDataDefinitions.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "BiDataDefinitions");
        };

        this.getBiDataDefinitionGroups = function (filter, forceReload) {
            var params = { entityType: entityTypes.BiDataDefinitionGroup, cache: this.dataContext.biDataDefinitionGroups.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "BiDataDefinitionGroups");
        };
    };

    biService.$inject = injectParams;
    biService.prototype = Object.create(nbsService.prototype);
    angular.module('nbs.rds.module').service('biService', biService);
});