﻿'use strict';
define([], function () {

    var injectParams = [];
    var nbsToolbarComponent = function () {

        var vm = this;
        vm.privateDatacontext = null;
        
        vm.$onChanges = function (changes) {
            if (changes.nbsDatacontext && changes.nbsDatacontext.currentValue) {
                vm.privateDatacontext = changes.nbsDatacontext.currentValue;                
            }
        };

        vm.buttonOnClick = function (button) {
            if (button.OnClick) {
                button.OnClick();
            }
        }
    };

    nbsToolbarComponent.$inject = injectParams;
    angular.module('nbs.rds.module').component('nbsToolbar', {
        templateUrl: '/rds/common/components/nbsToolbarComponent/_?c=' + window.codeCache(),
        controller: nbsToolbarComponent,
        controllerAs: 'vm',
        bindings: {
            nbsDatacontext: '<',
            uiContext: '<'
        }
    });
});

