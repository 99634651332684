﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'hrbucketService', 'foundationService', 'navigationService', 'contextService', 'formSummaryService'];
    var assetFtc = function ($q, $scope, $timeout, $injector, hrbucketService, foundationService, navigationService, contextService, formSummaryService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        
        vm.ssv_core_hrbucket_productionunit = $ServerVarEnabled('ssv_core_hrbucket_productionunit');
        vm.ssv_core_hrbucket_generichrgroup = $ServerVarEnabled('ssv_core_hrbucket_generichrgroup');
        vm.ssv_core_hrbucket_legalentity = $ServerVarEnabled('ssv_core_hrbucket_legalentity');
        vm.ssv_core_hrbucket_productionunit_businessunit = $ServerVarEnabled('ssv_core_hrbucket_productionunit_businessunit');
        vm.ssv_core_hrbucket_productionunit_costcenter = $ServerVarEnabled('ssv_core_hrbucket_productionunit_costcenter');
        vm.ssv_core_hrbucket_productionunit_sector = $ServerVarEnabled('ssv_core_hrbucket_productionunit_sector');
        vm.ssv_core_hrbucket_plant = $ServerVarEnabled('ssv_core_hrbucket_plant');

        var referral = 'virtualequipment';
        var appStoreCodeFtc = navigationService.stateParams().appStoreCodeFtc;
        var viewKindFtc = navigationService.stateParams().viewKindFtc;
        var viewModeFtcPar = navigationService.stateParams().viewMode;
        if (!viewModeFtcPar) {
            viewModeFtcPar = '_';
        }
        appStoreCodeFtc = appStoreCodeFtc ? appStoreCodeFtc : "";
        var virtualHumanResourceId = navigationService.stateParams().virtualHumanResourceId;
        var cmsView = 'assetftc-_';
        var bucketClassificationEnum = Nembus.Common.Library.util.parseEnum(hrbucketService.dataContext.enumTypes.BucketClassification, null, vm.translate).obj.normal.name_value;
        var resourceTypeEnum = Nembus.Common.Library.util.parseEnum(hrbucketService.dataContext.enumTypes.ResourceType, null, vm.translate).obj.normal.name_value;
        var hrContactsId = null;
        var hrMetadataId = null;

        var state = {
            productionUnits: 'productionUnits', generality: 'generality', addresses: 'addresses', hrGroups: 'hrGroups', legalEntities: 'legalEntities',
            costCenters: 'costCenters', businessUnits: 'businessUnits', sectors: 'sectors', categories: 'categories', activities: 'activities', references: 'references',
            vhrEventsTec: 'vhrEventsTec', vhrEventsIms: 'vhrEventsIms', vhrEventFailures: 'vhrEventFailures', plants: 'plants', modules: 'modules', activities: 'activities',
            activitiesDirect: 'activitiesDirect', activitiesIndirect: 'activitiesIndirect', audits: 'audits', auditsToCompile: 'auditsToCompile', auditsCompiled: 'auditsCompiled',
            auditsOndemand: 'auditsOndemand', acts: 'acts', actsAll: 'actsAll', actsToExecute: 'actsToExecute', actsExecuted: 'actsExecuted'
        };

        var fastTab = new NbsAccordion();
        fastTab.AddFastTab(state.generality, "Generality");
        //fastTab.AddFastTab(state.addresses, "Addresses");
        fastTab.AddFastTab(state.productionUnits, "ProductiveUnits", vm.ssv_core_hrbucket_productionunit);
        fastTab.AddLink(state.legalEntities, state.productionUnits, "LegalEntities", vm.ssv_core_hrbucket_legalentity);
        fastTab.AddLink(state.costCenters, state.productionUnits, "CostCenters", vm.ssv_core_hrbucket_productionunit_costcenter);
        fastTab.AddLink(state.plants, state.productionUnits, "Plants", vm.ssv_core_hrbucket_plant);
        fastTab.AddLink(state.businessUnits, state.productionUnits, "BusinessUnits", vm.ssv_core_hrbucket_productionunit_businessunit);
        fastTab.AddLink(state.sectors, state.productionUnits, "Sectors", vm.ssv_core_hrbucket_productionunit_sector);
        fastTab.AddLink(state.hrGroups, state.productionUnits, "HomogeneousGroups", vm.ssv_core_hrbucket_generichrgroup);
        fastTab.AddFastTab(state.categories, "Categories");
        fastTab.AddFastTab(state.vhrEventsTec, "VhrEventsTec");
        fastTab.AddFastTab(state.vhrEventsIms, "VhrEventsIms");
        fastTab.AddFastTab(state.vhrEventFailures, "VhrEventFailures");
        fastTab.AddFastTab(state.audits, "Audits");
        fastTab.AddLink(state.auditsCompiled, state.audits, "AuditsCompiled");
        fastTab.AddLink(state.auditsToCompile, state.audits, "AuditsToCompile");
        fastTab.AddLink(state.auditsOndemand, state.audits, "AuditsOndemand");
        fastTab.AddFastTab(state.acts, "Actions");
        fastTab.AddLink(state.actsAll, state.acts, "ActionsAll");
        fastTab.AddLink(state.actsToExecute, state.acts, "ActionsToExecute");
        fastTab.AddLink(state.actsExecuted, state.acts, "ActionsExecuted");
        fastTab.AddFastTab(state.activities, "Boms");
        fastTab.AddLink(state.activitiesDirect, state.activities, "Directs");
        fastTab.AddLink(state.activitiesIndirect, state.activities, "Indirects");
        fastTab.AddFastTab(state.references, "AssetReferences");
        vm.fastTabDatacontext = fastTab;

        if (virtualHumanResourceId) {
            foundationService.getVirtualEquipmentDto({ VirtualHumanResourceId: virtualHumanResourceId }, false)
                .then((data) => {
                    var virtualEquipmentDto = data.results[0];
                    hrContactsId = virtualEquipmentDto.HrContactsId;
                    hrMetadataId = virtualEquipmentDto.HrMetadataId;

                    $timeout(() => {
                        vm.fastTabsAreDisabled = false;
                    });

                    // configure form summary items
                    formSummaryService.clearDatasource();
                    formSummaryService.push({ key: vm.translateCmsView(cmsView, 'RegistrationNumber', 'EquipmentGenerality'), value: virtualEquipmentDto.RegistrationNumber });
                    formSummaryService.push({ key: vm.translateCmsView(cmsView, 'SerialNumber', 'EquipmentGenerality'), value: virtualEquipmentDto.SerialNumber });
                    formSummaryService.push({ key: vm.translateCmsView(cmsView, 'Name', 'AssetFtc'), value: virtualEquipmentDto.EquipmentName });

                    goToDefaultFastTab();
                });
        } else {
            goToDefaultFastTab();

            $timeout(() => {
                vm.fastTabsAreDisabled = true;
            });
        }

        vm.ftcOnClick = function (event) {
            navigateTo(event.id);
        }

        // raised from detail view
        vm.ftcOnAddEntity = function (entity) {
            $timeout(() => {
                virtualHumanResourceId = entity.VirtualHumanResourceId;
                hrContactsId = entity.HrContactsId;
                hrMetadataId = entity.HrMetadataId;

                vm.fastTabsAreDisabled = false;
            });
        }

        function navigateTo(accordionId) {
            if (!accordionId) {
                return;
            }

            var navigationState = null;
            var navigationStateParams = null;
            switch (accordionId) {
                case state.generality:
                    navigationState = 'app.nav.assetftc.assetdetail-' + viewModeFtcPar;
                    navigationStateParams = { 'virtualHumanResourceId': virtualHumanResourceId, 'appStoreCode': appStoreCodeFtc, 'viewKind': viewKindFtc };
                    break;
                case state.addresses:
                    navigationState = 'app.nav.assetftc.hraddressdetail';
                    navigationStateParams = { 'hrContactsId': hrContactsId, 'appStoreCode': appStoreCodeFtc, 'referral': referral };
                    break;
                //case state.work:
                //    navigationState = 'app.nav.assetftc.hrpersonjobdatadetail-virtualequipment';
                //    navigationStateParams = { 'personJobDataId': virtualEquipmentDto.PersonJobDataId, 'appStoreCode': appStoreCodeFtc };
                //    break;
                case state.hrGroups:
                    navigationState = 'app.nav.assetftc.hrsingletobucketdetail';
                    navigationStateParams = { 'hrMetadataId': hrMetadataId, 'virtualHumanResourceId': virtualHumanResourceId, 'resourceTypeId': resourceTypeEnum.HrGroup, 'bucketClassificationId': bucketClassificationEnum.GenericHrGroup, 'appStoreCode': appStoreCodeFtc, 'viewKind': 'multiMode' };
                    break;
                case state.legalEntities:
                    navigationState = 'app.nav.assetftc.hrsingletobucketdetail-legalentity';
                    navigationStateParams = { 'hrMetadataId': hrMetadataId, 'virtualHumanResourceId': virtualHumanResourceId, 'resourceTypeId': resourceTypeEnum.LegalEntity, 'bucketClassificationId': bucketClassificationEnum.GenericLegalEntity, 'appStoreCode': appStoreCodeFtc, 'viewKind': 'singleMode' };
                    break;
                case state.costCenters:
                    navigationState = 'app.nav.assetftc.hrsingletobucketdetail-costcenter';
                    navigationStateParams = { 'hrMetadataId': hrMetadataId, 'virtualHumanResourceId': virtualHumanResourceId, 'resourceTypeId': resourceTypeEnum.ProductionUnit, 'bucketClassificationId': bucketClassificationEnum.CostCenter, 'appStoreCode': appStoreCodeFtc, 'viewKind': 'singleMode' };
                    break;
                case state.plants:
                    navigationState = 'app.nav.assetftc.hrsingletobucketdetail-plant';
                    navigationStateParams = { 'hrMetadataId': hrMetadataId, 'virtualHumanResourceId': virtualHumanResourceId, 'resourceTypeId': resourceTypeEnum.Plant, 'bucketClassificationId': bucketClassificationEnum.Plant, 'appStoreCode': appStoreCodeFtc, 'viewKind': 'singleMode' };
                    break;
                case state.businessUnits:
                    navigationState = 'app.nav.assetftc.hrsingletobucketdetail-businessunit';
                    navigationStateParams = { 'hrMetadataId': hrMetadataId, 'virtualHumanResourceId': virtualHumanResourceId, 'resourceTypeId': resourceTypeEnum.ProductionUnit, 'bucketClassificationId': bucketClassificationEnum.BusinessUnit, 'appStoreCode': appStoreCodeFtc, 'viewKind': 'singleMode' };
                    break;
                case state.sectors:
                    navigationState = 'app.nav.assetftc.hrsingletobucketdetail-sector';
                    navigationStateParams = { 'hrMetadataId': hrMetadataId, 'virtualHumanResourceId': virtualHumanResourceId, 'resourceTypeId': resourceTypeEnum.ProductionUnit, 'bucketClassificationId': bucketClassificationEnum.Sector, 'appStoreCode': appStoreCodeFtc, 'viewKind': 'singleMode' };
                    break;
                case state.categories:
                    navigationState = 'app.nav.assetftc.vhrtocategoriesdetail';
                    navigationStateParams = { 'hrMetadataId': hrMetadataId, 'virtualHumanResourceId': virtualHumanResourceId, 'appStoreCode': appStoreCodeFtc };
                    break;
                case state.references:
                    navigationState = 'app.nav.assetftc.vhrreferencesdetail-asset';
                    navigationStateParams = { 'virtualHumanResourceId': virtualHumanResourceId, 'appStoreCode': appStoreCodeFtc, 'vhrType': 'asset' };
                    break;
                case state.activitiesDirect:
                    navigationState = 'app.nav.assetftc.vhrtoactivities-tec';
                    navigationStateParams = { 'virtualHumanResourceId': virtualHumanResourceId, 'appStoreCode': appStoreCodeFtc };
                    break;
                case state.activitiesIndirect:
                    navigationState = 'app.nav.assetftc.vhrtoactivities-tec';
                    navigationStateParams = { 'virtualHumanResourceId': virtualHumanResourceId, 'appStoreCode': appStoreCodeFtc };
                    break;
                case state.vhrEventsTec:
                    navigationState = 'app.nav.assetftc.vhreventsvhrlist-tec';
                    navigationStateParams = { 'wzVhrEntityType': 'asset', 'vhrEventTypeCode': null, 'vhrEventFixedTypeCode': null, 'appStoreCode': 'tec', 'viewKind': viewKindFtc, 'virtualHumanResourceId': virtualHumanResourceId };
                    break;
                case state.vhrEventsIms:
                    navigationState = 'app.nav.assetftc.vhreventsvhrlist-ims';
                    navigationStateParams = { 'wzVhrEntityType': 'asset', 'vhrEventTypeCode': null, 'vhrEventFixedTypeCode': null, 'appStoreCode': 'ims', 'viewKind': viewKindFtc, 'virtualHumanResourceId': virtualHumanResourceId };
                    break;
                case state.vhrEventFailures:
                    navigationState = 'app.nav.assetftc.imsvhreventfailuresvhrlist-ims';
                    navigationStateParams = { 'appStoreCode': 'ims', 'viewKind': viewKindFtc, 'virtualHumanResourceId': virtualHumanResourceId };
                    break;
                case state.modules:
                    navigationState = 'app.nav.assetftc.vhrtomodules-tec';
                    navigationStateParams = { 'entityName': 'VirtualEquipment', 'VirtualHumanResourceId': virtualHumanResourceId, 'appStoreCode': appStoreCodeFtc };
                    break;
                case state.auditsToCompile:
                    navigationState = 'app.nav.assetftc.surveysvhrlist-tocompile';
                    navigationStateParams = { 'virtualHumanResourceId': virtualHumanResourceId, 'appStoreCode': 'aud', 'vhrEventLifeStatusKey': 'tocompile' };
                    break;
                case state.auditsCompiled:
                    navigationState = 'app.nav.assetftc.surveysvhrlist-compiled';
                    navigationStateParams = { 'virtualHumanResourceId': virtualHumanResourceId, 'appStoreCode': 'aud', 'vhrEventLifeStatusKey': 'compiled' };
                    break;
                case state.auditsOndemand:
                    navigationState = 'app.nav.assetftc.surveysvhrlist-ondemand';
                    navigationStateParams = { 'virtualHumanResourceId': virtualHumanResourceId, 'appStoreCode': 'aud', 'vhrEventLifeStatusKey': 'ondemand' };
                    break;
                case state.actsAll:
                    navigationState = 'app.nav.assetftc.actsvhrlist-all';
                    navigationStateParams = { 'viewKind': 'all', 'appStoreCode': appStoreCodeFtc, 'actTemplateCode': 'aud' };
                    break;
                case state.actsToExecute:
                    navigationState = 'app.nav.assetftc.actsvhrlist-toexecute';
                    navigationStateParams = { 'viewKind': 'toexecute', 'appStoreCode': appStoreCodeFtc, 'actTemplateCode': 'aud' };
                    break;
                case state.actsExecuted:
                    navigationState = 'app.nav.assetftc.actsvhrlist-executed';
                    navigationStateParams = { 'viewKind': 'executed', 'appStoreCode': appStoreCodeFtc, 'actTemplateCode': 'aud' };
                    break;
            }

            // call navigation state
            if (navigationState && navigationStateParams) {
                navigationService.navigateTo(navigationState, navigationStateParams);
            }
        };

        function goToDefaultFastTab() {
            // go to default page only if is called ftc directly
            if (navigationService.currentState().endsWith('.assetftc')) {
                navigateTo(state.generality);
            }
        }
    };

    assetFtc.$inject = injectParams;
    assetFtc.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('assetFtc', assetFtc);
});