﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'bomService', 'activityService', 'navigationService', 'checkStateChangeService', 'contextService', 'formSummaryService'];
    function bucketToActivitiesDetail($q, $scope, $timeout, $injector, bomService, activityService, navigationService, checkStateChangeService, contextService, formSummaryService) {

        var vm = this;
        nbsVm.call(vm, activityService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, activityService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);


        var virtualHumanResourceId = navigationService.stateParams().virtualHumanResourceId;
        var magicBucketActionEnum = bomService.dataContext.enumTypes.MagicBucketAction;
        var activityKindEnum = Nembus.Common.Library.util.parseEnum(activityService.dataContext.enumTypes.ActivityKind, null, vm.translate).obj.normal.name_value;
        var activityXVhrStatusEnumParsed = Nembus.Common.Library.util.parseEnum(activityService.dataContext.enumTypes.ActivityXVhrStatus, null, vm.translate);

        vm.activityXVhrStatusList = activityXVhrStatusEnumParsed.list.translated;

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('activity', 'vhr');
        nbsGridDatacontext.setDatasource([], false);
        nbsGridDatacontext.allowSearching = false;
        nbsGridDatacontext.allowGrouping = false;
        vm.nbsGridDatacontext = nbsGridDatacontext;

        ribbon.setRibbonStatusButton(ribbon.Buttons.New, false);
        ribbon.setRibbonStatusButton(ribbon.Buttons.Modify, true);
        ribbon.refreshStatusButtons();

        // get array items for form summary
        vm.formSummary = formSummaryService.getDatasource();

        vm.currentActivityVhrs = [];
        vm.currentMagicBucketRequest = null; //submit his entity when Bucket X Categories change 

        vm.deleteAction = function () {
            if (!vm.vhrActivitySelected)
                return;

            activityService.deleteEntity(vm.vhrActivitySelected);
            vm.currentActivityVhrs = Lazy(vm.currentActivityVhrs)
                .reject({ ActivityId: vm.vhrActivitySelected.ActivityId })
                .toArray();

            vm.forceDatasourceLocal = {
                refreshkey: new Date().getTime(),
                dataSource: vm.currentActivityVhrs
            }

            addMagicBucketRequest(); //request for fire bucket/hr/activity binding
        };

        // get reference of submitaction present into nbs-vm
        // this allow to manage response and do more in current controller
        vm.parentSubmitAction = vm.submitAction;
        vm.submitAction = function () {
            vm.parentSubmitAction()
                .then(
                    function (saveResult) {
                        setBatchContext(vm.currentMagicBucketRequest);
                        vm.currentMagicBucketRequest = null; //have to add new Request each submit 
                        vm.refreshAction();
                    }
                );
        };

        vm.rowVhrActivitySelected = function (args) {
            vm.vhrActivitySelected = args.data;
        };

        vm.newAction = function () {
            navigationService.navToModal(vm.suspendDirty, 'app.nav.activitieslistmodal',
                { isMultiselect: true, appStoreBw: appStoreBw, activityKindId: activityKindEnum.Bom }
            ).then(function (result) {
                setBoms(result.data);
            });
        };

        vm.refreshAction = function () {
            getActivityVhr(true, virtualHumanResourceId);
        };

        function setBoms(activities) {
            var targetBoms = Lazy(vm.currentActivityVhrs).pluck("ActivityId").toArray();

            Lazy(activities)
                .each((activity) => {
                    if (!Lazy(targetBoms).contains(activity.ActivityId)) {
                        var activityVhrDto = mapBomToVhrActivity(activity);
                        activityVhrDto.ActivityXVhrStatusTranslated = vm.translate("Associated");
                        activityVhrDto.ActivityXVhrModeTranslated = vm.translate("Manually");

                        vm.currentActivityVhrs.push(activityVhrDto);
                    }
                });

            vm.forceDatasourceLocal = {
                refreshkey: new Date().getTime(),
                dataSource: vm.currentActivityVhrs
            }

            addMagicBucketRequest(); //request for fire bucket/hr/activity binding
        }

        function mapBomToVhrActivity(itemToInsert) {

            return activityService.dataContext.activityVhrs.add({
                ActivityCode: itemToInsert.ActivityCode,
                ActivityId: itemToInsert.ActivityId,
                ActivityName: itemToInsert.ActivityName,
                ActivityXVhrStatusId: 10,
                ActivityXVhrStatus: "Associated",
                AppStoreBw: appStoreBw,
                ActivityXVhrModeId: 20,
                ActivityXVhrMode: "Manually",
                IsToAdd: false,
                VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId,
                //VirtualCode: ""
                VirtualHumanResourceId: virtualHumanResourceId
                //VirtualName: ""                
            });
        }

        loadData(true);
        function loadData(forceReload) {
            getActivityVhr(forceReload, virtualHumanResourceId);
        }

        function getActivityVhr(forceReload, virtualHumanResourceId) {
            var filter = { VirtualHumanResourceId: virtualHumanResourceId };
            var defer = $q.defer();

            activityService.getActivityVhr(filter, forceReload)
                .then(function (data) {
                    Lazy(data.results)
                        .each((item) => {
                            item.ActivityXVhrModeTranslated = vm.translate(item.ActivityXVhrMode);
                            item.ActivityXVhrStatusTranslated = vm.translate(item.ActivityXVhrStatus);
                        });

                    vm.currentActivityVhrs = data.results;

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.currentActivityVhrs
                    }

                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        // get reference of modifyAction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parentm = vm.modifyAction;
        vm.modifyAction = function () {
            parentm().then(
                //enable disable button new & delete because is custom behavior
                //generally saveResult eneble or disable only save button 
                function (saveResult) {
                    if (vm.viewContext.isEditingState) {
                        ribbon.setRibbonStatusButton(ribbon.Buttons.New, true);
                        ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, true);
                    } else {
                        ribbon.setRibbonStatusButton(ribbon.Buttons.New, false);
                        ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                    }

                    ribbon.refreshStatusButtons();
                }
            );
        };

        function addMagicBucketRequest() {
            var bucketAction = Lazy(magicBucketActionEnum).findWhere({ name: 'BucketXActivities' });

            if (!vm.currentMagicBucketRequest) {
                vm.currentMagicBucketRequest =
                    activityService.dataContext.magicBucketRequests.add({
                        MagicBucketActionId: bucketAction.value,
                        BucketId: virtualHumanResourceId,
                        VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId,
                        ModuleTargetId: moduleTargetId
                    });
            }
        }

        function setBatchContext(magicBucketRequest) {
            if (magicBucketRequest.IsBatch) {
                var actionType = Lazy(magicBucketActionEnum).findWhere({ value: magicBucketRequest.MagicBucketActionId.toString() });
                var actionTypeName = actionType ? actionType.name : '';

                contextService.addBatchRequest({
                    requestId: magicBucketRequest.BatchRequestId,
                    requestName: vm.translate('MagicBucketRequest' + actionTypeName + 'BatchName'),
                    requestStatus: 'inserted',
                    onCompletedAutoAction: null,    // is a function called on completed batch that must return a promise (with jobResult) managed in appPanel
                    detailAction: null             // is a function called from appPanel.cshml that links to batch request detail
                });
            }
        }
    }

    bucketToActivitiesDetail.$inject = injectParams;
    bucketToActivitiesDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('bucketToActivitiesDetail', bucketToActivitiesDetail);
});