﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$injector', '$scope', '$timeout', 'vhreventService', 'audService', 'utilService', 'navigationService', 'checkStateChangeService', 'contextService', 'cmsService', 'notificationService'];
    function surveysList($q, $injector, $scope, $timeout, vhreventService, audService, utilService, navigationService, checkStateChangeService, contextService, cmsService, notificationService) {
        var vm = this;
        nbsVm.call(vm, vhreventService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return gridResult.data; });
        checkStateChangeService.checkFormOnStateChange($scope, vhreventService);

        var ribbon = new NbsAudRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab({ showGroupArchive: true });
        ribbon.addPrintReportTab();
        ribbon.replaceSectionWith(ribbon.SECTIONKEY.home_new, ribbon.GetAuditNewSection());
        ribbon.replaceSectionWith(ribbon.SECTIONKEY.home_manage, ribbon.GetAuditManageSection());
        ribbon.addTab(ribbon.GetAuditViewsTab());
        ribbon.addTab(ribbon.GetAuditManageTab());
        ribbon.setUnderBreakpointSmall();
        vm.ribbonDatacontext = ribbon;

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var viewKind = navigationService.stateParams().viewKind;
        var vhrEventTypeCode = navigationService.stateParams().vhrEventTypeCode;
        var vhrEventFixedTypeCode = navigationService.stateParams().vhrEventFixedTypeCode;
        var vhrEventKindEnum = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventKind, null, vm.translate).obj.normal.name_value;
        var surveyCreationRequestType = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyCreationRequestType, null, vm.translate).obj.normal.name_value;
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
        var surveyCreationPatternObj = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyCreationPattern, null, vm.translate).obj.normal.name_value;
        var vhrEventLifeStatusKey = navigationService.stateParams().vhrEventLifeStatusKey;
        var currentSurveyBarFilter = null;
        var canCreate = vm.havePermission(Nembus.Common.Library.auth.Permission['AUD_AUDIT_CREATE_ALL'], contextService.virtualContext);
        var surveyLifeStatusEnumObj = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyLifeStatus, null, vm.translate).obj.normal.name_value;
        var canManageExtendedSurveyLifeStatuses = vm.havePermission(Nembus.Common.Library.auth.Permission['AUD_AUDIT_MANAGE_LIFESTATUS_EXTENDED'], contextService.virtualContext)
            && Nembus.Aud.Library.SSV.CanManageSurveyVhrEventLifeStatus();
        var vhrEventShuffleActionObj = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventShuffleAction, null, vm.translate).obj.normal.name_value;
        var vhrEventMassivelyUpdateRequestType = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventMassivelyUpdateRequestType, null, vm.translate).obj.normal.name_value;

        var canChangeSurveyWorkflowStatus = Nembus.Aud.Library.SSV.CanManageSurveyWorkflow()
            && vm.havePermission(Nembus.Common.Library.auth.Permission['AUD_AUDIT_WORKFLOW_CHANGESTATUS'], contextService.virtualContext);

        //#region Toolbar
        var toolbar = new NbsToolbar(vm.translate);
        var btnCompile = toolbar.getButton();
        btnCompile.CssClass = "nbs-toolbar-button-compile";
        btnCompile.Title = "Compile";
        btnCompile.OnClick = () => { vm.compileAction() };
        toolbar.addButtonByType(toolbar.BUTTONTYPE.Back, () => { vm.backAction(); });
        toolbar.addButtonByType(toolbar.BUTTONTYPE.Pencil, () => { vm.modifyAction(); });
        toolbar.addButton(btnCompile)
        toolbar.addButtonByType(toolbar.BUTTONTYPE.Pdf, () => { vm.exportReportToPdfAction(); });
        toolbar.setBreakpointUIEnabled();
        toolbar.setHideUpper(toolbar.BREAKPOINTUI.sm);
        vm.toolbarDatacontext = toolbar;
        //#endregion 

        // set grid component datacontext
        var gridUIkey = null;
        if (vhrEventLifeStatusKey === 'ondemand') {
            gridUIkey = 'ondemand';
        } else {
            gridUIkey = 'list';
        }

        var nbsGridDatacontext = new NbsAudGrid(vm.translate);
        nbsGridDatacontext.configureGrid('survey', gridUIkey, null);
        nbsGridDatacontext.setIsMultiselect();
        nbsGridDatacontext.viewCustomBar();
        nbsGridDatacontext.setGridIsRenderedInlineBlock();
        nbsGridDatacontext.setDatasource([], false); // fake datasource, only to create grid
        vm.nbsGridDatacontext = nbsGridDatacontext;

        //#region set ribbon fn navigations
        var navParams = { 'appStoreCode': appStoreCode, 'vhrEventLifeStatusKey': vhrEventLifeStatusKey, 'viewKind': viewKind, 'vhrEventFixedTypeCode': vhrEventFixedTypeCode };
        vm.onRibbonGrid = () => { ribbon.onRibbonGrid_Survey(navParams); };
        vm.onRibbonCalendar = () => { ribbon.onRibbonCalendar_Survey(navParams); };
        vm.onRibbonStream = () => { ribbon.onRibbonStream_Survey(navParams); };
        vm.onRibbonBoard = () => { ribbon.onRibbonBoard_Survey(navParams); };
        vm.onRibbonTimeline = () => { ribbon.onRibbonTimeline_Survey(navParams); };
        vm.onRibbonTimelineTarget = () => { ribbon.onRibbonTimelineTarget_Survey(navParams); };
        vm.onRibbonTimelineAssignment = () => { ribbon.onRibbonTimelineTargetAssignment_Survey(navParams); };
        //#endregion set ribbon fn navigations

        //#region set ribbon enabled buttons
        ribbon.setRibbonStatusButton(ribbon.Buttons.NewAudit, canCreate);
        ribbon.setRibbonStatusButton(ribbon.Buttons.ViewStream, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.Board, vhrEventLifeStatusKey !== 'ondemand');
        ribbon.setRibbonStatusButton(ribbon.Buttons.ViewCalendar, vhrEventLifeStatusKey !== 'ondemand');
        ribbon.setRibbonStatusButton(ribbon.Buttons.Timeline, vhrEventLifeStatusKey !== 'ondemand');
        ribbon.setRibbonStatusButton(ribbon.Buttons.TimelineTarget, vhrEventLifeStatusKey !== 'ondemand');
        ribbon.setRibbonStatusButton(ribbon.Buttons.TimelineAssignment, vhrEventLifeStatusKey !== 'ondemand');
        ribbon.refreshStatusButtons();
        //#endregion set ribbon enabled buttons


        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.surveydetail", { 'surveyId': args.data.SurveyId, 'vhrEventId': args.data.VhrEventId });
        };

        var canCompile = false;
        var canUpdate = false;
        var canView = false;
        var gridResult = {};
        vm.gridSelection = function (args) {
            $timeout(() => {
                gridResult = args;

                canCompile = false;
                canUpdate = false;
                canView = false;
                if (gridResult.isSingleSelection) {
                    var cSurvey = Lazy(gridResult.data).first();
                    canCompile = Nembus.Aud.Library.CanCompileSurvey(contextService, audService, cSurvey);
                    canUpdate = vm.havePermission(Nembus.Common.Library.auth.Permission['AUD_AUDIT_UPDATE_ALL'], contextService.virtualContext);
                    canView = Nembus.Aud.Library.CanViewSurvey(cSurvey, audService);
                }

                var canDelete = canDeleteSurveys(gridResult.data);
                ribbon.setRibbonStatusButton(ribbon.Buttons.Compile, canCompile && gridResult.isSingleSelection);
                ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, gridResult.isSingleSelection && canCreate);
                ribbon.setRibbonStatusButton(ribbon.Buttons.View, canView && gridResult.isSingleSelection);
                ribbon.setRibbonStatusButton(ribbon.Buttons.Modify, gridResult.isSingleSelection && canUpdate);
                ribbon.setRibbonStatusButton(ribbon.Buttons.Archive, gridResult.hasSelection && vhrEventLifeStatusKey !== 'archived' && canUpdate);
                ribbon.setRibbonStatusButton(ribbon.Buttons.Extract, gridResult.hasSelection && vhrEventLifeStatusKey === 'archived' && canUpdate);
                ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, gridResult.hasSelection && canDelete);
                ribbon.setRibbonStatusButton(ribbon.Buttons.WarningModify, gridResult.hasSelection);
                ribbon.setRibbonStatusButton(ribbon.Buttons.WarningDelete, gridResult.hasSelection);
                ribbon.setRibbonStatusButton(ribbon.Buttons.WarningInfo, gridResult.isSingleSelection);
                ribbon.setRibbonStatusButton(ribbon.Buttons.SendNotification, gridResult.hasSelection);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventLifeStatusApprove, gridResult.hasSelection && canManageExtendedSurveyLifeStatuses);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventLifeStatusModify, gridResult.hasSelection && canManageExtendedSurveyLifeStatuses);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventLifeStatusInfo, gridResult.isSingleSelection && canManageExtendedSurveyLifeStatuses);
                ribbon.setActionWorkflowButtons(gridResult.data, canChangeSurveyWorkflowStatus);
                ribbon.refreshStatusButtons();
            });
        };

        // hook from gridbar
        var ejDataManager = null;
        var currentGridFilter = null;
        vm.surveyBarOnChanges = function (result) {
            // save in view current filter passed by survey bar
            currentSurveyBarFilter = result;
            currentGridFilter = parseFromBarFilter(currentSurveyBarFilter);

            if (ejDataManager === null) {
                utilService.getGenericElements({ GenericElementCode: 'Configurations.Surveys.LifeStatusColors' }, false)
                    .then((data) => {
                        var ge = data.results[0].PairValuesArray;
                        var statusColorObj = Nembus.Common.Library.parser.objFromList(ge, 'key', 'value');

                        var supportData = { isMySurveys: false, translate: vm.translate, contextService: contextService, statusColorObj: statusColorObj };
                        ejDataManager = audService.getSurveyDataManager(supportData, currentGridFilter, ejDataManager);
                        nbsGridDatacontext.setDatasource(ejDataManager, true);
                        refreshGridDatasource(currentGridFilter);
                    });
            } else {
                refreshGridDatasource(currentGridFilter)
            }
        };

        function canDeleteSurveys(surveys) {
            if (!Lazy(surveys).some()) {
                return false;
            }

            var result = true;
            Lazy(surveys)
                .each((survey) => {
                    var filter = { moduleTargetId: survey.ModuleTargetId, groupId: survey.VhrEventGroupId, typeId: survey.VhrEventTypeId };
                    var canDeleteCurrent = vm.checkProcessPermissionCode(Nembus.Common.Library.auth.Permission['AUD_AUDIT_DELETE_ALL'], filter, contextService.virtualContext)
                    result = result && canDeleteCurrent;
                });

            return result;
        }

        function refreshGridDatasource(currentGridFilter) {
            $timeout(() => {
                vm.forceDatamanagerFilter = {
                    refreshKey: new Date().getTime(),
                    filter: currentGridFilter
                }
            });
        }

        function openNotificationAssignmentModal(surveyIds, appuserIds, drbacUserGroupIds) {
            var navParams = {
                notificationDefinitionId: 'AUD-ASSIGNED',
                viewRoles: false,
                viewUserGroups: true,
                viewUsers: true,
                viewAssignmentDescription: true,
                appUserIds: appuserIds,
                drbacUserGroupIds: drbacUserGroupIds
            };

            navigationService
                .navToSendNotificationModal(vm.suspendDirty, navParams)
                .then(function (modalResult) {
                    //enable dirty flag
                    vm.suspendDirty(false);

                    if (modalResult.dialogResult === navigationService.navigationActionTypes.modalOk) {
                        var notificationRequest = notificationService.dataContext.notificationRequests.add({});
                        notificationRequest.IsCustomNotificationTarget = true;
                        notificationRequest.NotificationInstanceId = modalResult.data.notificationInstanceId;
                        notificationRequest.ManualUserIdsSerialized = Lazy(modalResult.data.appUserIds).some() ? JSON.stringify(modalResult.data.appUserIds) : null;
                        notificationRequest.ManualUserGroupIdsSerialized = Lazy(modalResult.data.drbacUserGroupIds).some() ? JSON.stringify(modalResult.data.drbacUserGroupIds) : null;
                        notificationRequest.ManualRoleIdsSerialized = Lazy(modalResult.data.drbacRoleIds).some() ? JSON.stringify(modalResult.data.drbacRoleIds) : null;
                        var surveyFiltertoSerialize = {};
                        surveyFiltertoSerialize.survey = JSON.stringify({ SurveysId: surveyIds, ModuleTargetId: moduleTargetId });
                        notificationRequest.Filters = JSON.stringify(surveyFiltertoSerialize);

                        if (modalResult.data.assignmentDescription) {
                            notificationRequest.ExtraKeys = JSON.stringify({ NotificationAssignmentDescription: modalResult.data.assignmentDescription });
                        }
                        else {
                            notificationRequest.ExtraKeys = JSON.stringify({ NotificationAssignmentDescription: "" });
                        }

                        notificationRequest.VirtualCompanyId = virtualCompanyId;

                        notificationService.saveChanges()
                            .then(function () {
                                //if (notificationRequest.NotificationRequestStatus === notificationRequestStatus.SuccessfullyDone) {
                                navigationService.customMessageModal(vm.translate('OperationSubmittedWithoutError'));
                                //}
                            });
                    }
                });
        }

        vm.modalOk = function (modalInstance) {
            vm[modalInstance].close();
        };

        vm.modalCancel = function (modalInstance) {
            vm[modalInstance].dismiss();
        };

        function getArraySelectedVhrEventsId() { return Lazy(gridResult.data).pluck('VhrEventId').toArray(); }

        vm.createGridEvent = function (result) {
            vm.surveyBarDataContext = {
                vhrEventLifeStatusKey: vhrEventLifeStatusKey,
                commonBarDatasource: result.barDatasource,
                appStoreBw: appStoreBw,
                moduleTargetId: moduleTargetId,
                defaultKeys: {
                    columnsKey: vhrEventLifeStatusKey, sortingKey: vhrEventLifeStatusKey
                }
            };
        };

        vm.gridUiOnChanges = function (result) {
            vm.refreshGridUiComponent = {
                refreshKey: new Date().getTime(),
                uiData: result
            }
        }

        //#region Ribbon actions
        vm.sendMailNotification = function () {
            var surveys = gridResult.data;
            if (!Lazy(surveys).some()) {
                navigationService.selectAtLeastOneItemMessageModal();
                return;
            }

            var surveyIds = Lazy(surveys).pluck('SurveyId').toArray();
            if (surveyIds.length > 1) {
                openNotificationAssignmentModal(surveyIds, [], []);
            } else {
                var survey = Lazy(surveys).first();
                var pAppUsersList = JSON.parse(Nembus.Common.Library.converter.decodeHtml(survey.UsersAssignedSerialized));
                var pUserGroupsList = JSON.parse(Nembus.Common.Library.converter.decodeHtml(survey.UserGroupsAssignedSerialized));
                var pAppUserIds = Lazy(pAppUsersList).pluck('Value').toArray();
                var pUserGroupsId = Lazy(pUserGroupsList).pluck('Value').toArray();

                // open modal
                openNotificationAssignmentModal(surveyIds, pAppUserIds, pUserGroupsId);

            }
        };

        vm.refreshAction = function () {
            $timeout(() => {
                vm.forceGridRefresh = new Date().getTime();
            });
        };

        vm.deleteAction = function () {
            var canDelete = canDeleteSurveys(gridResult.data);
            if (!canDelete) {
                navigationService.operationNotPermittedModal();
                return;
            }

            var reportedAsFinishedWillBeDeleted = false;

            Lazy(gridResult.data)
                .each((cSurvey) => {
                    reportedAsFinishedWillBeDeleted = Lazy(Nembus.Aud.Library.GetSurveyLifeStatusListIds(audService, true)).contains(cSurvey.SurveyLifeStatusId);
                });

            if (reportedAsFinishedWillBeDeleted) {
                var msg = vm.translate("DeleteSurveyReportedAsFinished") + '. ' + vm.translate("Continue");
                navigationService.confirmModal(msg)
                    .then(function (result) {
                        if (result === true) {
                            deleteSurveys(gridResult.data);
                        };
                    });
            } else {
                deleteSurveys(gridResult.data);
            }
        };

        function deleteSurveys(surveys) {
            var surveyIds = Lazy(surveys).pluck('SurveyId').toArray();

            vm.surveyCreationRequest = audService.dataContext.surveyCreationRequests.add({
                SurveyCreationRequestId: NbsGuidManager.newGuid(),
                SurveyCreationRequestCode: 'delete-survey',
                SurveyCreationRequestName: 'delete-survey',
                SurveyIdSerialized: JSON.stringify(surveyIds),
                ModuleTargetId: moduleTargetId,
                SurveyCreationRequestTypeId: surveyCreationRequestType.DeleteSurvey,
                VirtualCompanyId: virtualCompanyId
            });

            var batchName = {};
            batchName.BatchRequestName = vm.translate('SurveyCreationRequestDeleteSurveyBatchName');
            batchName.BatchRequestDescription = batchName.BatchRequestName;
            vm.surveyCreationRequest.BatchRequestName = JSON.stringify(batchName);

            audService.saveChanges()
                .then(function (result) {
                    if (vm.surveyCreationRequest.IsBatch) {
                        contextService.addBatchRequest({
                            requestId: vm.surveyCreationRequest.BatchRequestId,
                            requestName: vm.surveyCreationRequest.BatchRequestName,
                            requestStatus: 'inserted'
                        });
                    } else {
                        vm.refreshAction();
                    }
                });
        }

        vm.copyAction = function () {
            var surveyDtos = gridResult.data;
            if (surveyDtos.length === 0) {
                navigationService.selectOneItemMessageModal();
                return;
            } else if (surveyDtos.length > 1) {
                navigationService.selectOnlyOneItemMessageModal();
                return;
            }


            var surveyDto = Lazy(surveyDtos).first();
            var services = { navigationService: navigationService, audService: audService, cmsService: cmsService }
            var wzNbsManager = new NbsAudWizardManager($q, vm.translate, services, NbsGuidManager);
            var config = new wzNbsManager.WzConfig_AddSurveyCreationRequest(null);
            config.defaultData.SurveyIdSerialized = JSON.stringify([surveyDto.SurveyId]);
            config.defaultData.SurveyCreationRequestTypeId = surveyCreationRequestType.CloneSurvey;
            config.defaultData.SurveyCreationRequestCode = surveyDto.SurveyCode;
            config.defaultData.SurveyCreationRequestName = surveyDto.SurveyName;
            config.defaultData.SurveyCreationRequestDescription = surveyDto.SurveyDescription;
            config.defaultData.SurveyTemplateId = 0;
            config.defaultData.ModuleTargetId = surveyDto.ModuleTargetId;
            config.defaultData.VhrEventTypeId = surveyDto.VhrEventTypeId;
            config.defaultData.VhrEventGroupId = surveyDto.VhrEventGroupId;
            config.defaultData.ValidationSchemaId = surveyDto.ValidationSchemaId;
            config.defaultData.SurveyCreationRequestCompileTypeId = surveyCreationPatternObj.Single;
            config.defaultData.CopyOptionsCheckedFromSource = false;
            config.defaultData.CopyOptionValueFromSource = false;
            config.defaultData.CopyOptionWeightFromSource = false;
            config.defaultData.CopyOptionChoiceFromSource = false;
            config.defaultData.ValidationSchemaId = surveyDto.ValidationSchemaId;
            wzNbsManager.setWzConfig(config);

            wzNbsManager.open()
                .then(function (result) {
                    // result contains VhrEventManualRequest (not in context breeze)
                    var surveyCreationRequestResult = result;
                    surveyCreationRequestResult.VirtualCompanyId = virtualCompanyId;
                    var surveyCreationRequest = audService.dataContext.surveyCreationRequests.add(surveyCreationRequestResult);

                    var batchName = {};
                    batchName.BatchRequestName = vm.translate('SurveyCreationRequestCopySurveyBatchName');
                    batchName.BatchRequestDescription = batchName.BatchRequestName;
                    surveyCreationRequest.BatchRequestName = JSON.stringify(batchName);

                    audService.saveChanges()
                        .then(function (result) {
                            if (surveyCreationRequest.IsBatch) {
                                contextService.addBatchRequest({
                                    requestId: surveyCreationRequest.BatchRequestId,
                                    requestName: surveyCreationRequest.BatchRequestName,
                                    requestStatus: 'inserted'
                                });
                            }

                            vm.refreshAction();
                        })
                        .catch(function (error) {
                            // error
                            vm.setErrorMsg(error);
                            vhreventService.rejectChanges();
                        });
                });
        };

        vm.archiveAction = function () {
            var surveys = gridResult.data;
            if (surveys.length === 0) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        var surveysId = JSON.stringify(gridResult.dataIds);
                        vm.surveyCreationRequest = audService.dataContext.surveyCreationRequests.add({
                            SurveyCreationRequestId: NbsGuidManager.newGuid(),
                            SurveyCreationRequestCode: 'archive-survey',
                            SurveyCreationRequestName: 'archive-survey',
                            SurveyIdSerialized: surveysId,
                            ModuleTargetId: moduleTargetId,
                            SurveyCreationRequestTypeId: surveyCreationRequestType.ArchiveSurvey,
                            VirtualCompanyId: virtualCompanyId
                        });
                        var batchName = {};
                        batchName.BatchRequestName = vm.translate('SurveyCreationRequestArchiveSurveyBatchName');
                        batchName.BatchRequestDescription = batchName.BatchRequestName;
                        vm.surveyCreationRequest.BatchRequestName = JSON.stringify(batchName);
                        audService.saveChanges()
                            .then(function (result) {
                                if (vm.surveyCreationRequest.IsBatch) {
                                    contextService.addBatchRequest({
                                        requestId: vm.surveyCreationRequest.BatchRequestId,
                                        requestName: vm.surveyCreationRequest.BatchRequestName,
                                        requestStatus: 'inserted'
                                    });
                                } else {
                                    vm.refreshAction();
                                }
                            });
                    }
                })
                .catch(function (reason) {
                });
        };

        vm.extractAction = function () {
            var surveys = gridResult.data;
            if (surveys.length === 0) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        var surveysId = JSON.stringify(gridResult.dataIds);
                        vm.surveyCreationRequest = audService.dataContext.surveyCreationRequests.add({
                            SurveyCreationRequestId: NbsGuidManager.newGuid(),
                            SurveyCreationRequestCode: 'extract-survey',
                            SurveyCreationRequestName: 'extract-survey',
                            SurveyIdSerialized: surveysId,
                            ModuleTargetId: moduleTargetId,
                            SurveyCreationRequestTypeId: surveyCreationRequestType.ExtractSurvey,
                            VirtualCompanyId: virtualCompanyId
                        });
                        var batchName = {};
                        batchName.BatchRequestName = vm.translate('SurveyCreationRequestExtractSurveyBatchName');
                        batchName.BatchRequestDescription = batchName.BatchRequestName;
                        vm.surveyCreationRequest.BatchRequestName = JSON.stringify(batchName);

                        audService.saveChanges()
                            .then(function (result) {
                                if (vm.surveyCreationRequest.IsBatch) {
                                    contextService.addBatchRequest({
                                        requestId: vm.surveyCreationRequest.BatchRequestId,
                                        requestName: vm.surveyCreationRequest.BatchRequestName,
                                        requestStatus: 'inserted'
                                    });
                                } else {
                                    vm.refreshAction();
                                }
                            });
                    }
                })
                .catch(function (reason) {
                });
        };

        vm.viewAction = function () {
            if (!canView) {
                navigationService.operationNotPermittedModal();
                return;
            }

            vm.compileAction();
        };

        vm.modifyAction = function () {
            var surveyDtos = gridResult.data;

            if (surveyDtos.length === 0) {
                navigationService.selectOneItemMessageModal();
                return;
            } else if (surveyDtos.length > 1) {
                navigationService.selectOnlyOneItemMessageModal();
                return;
            } else {
                var surveyId = Lazy(surveyDtos).first().SurveyId;
                var vhrEventId = Lazy(surveyDtos).first().VhrEventId;
                navigationService.navigateTo("app.nav.surveydetail", { 'surveyId': surveyId, 'vhrEventId': vhrEventId });
            }
        };

        vm.compileAction = function () {
            var surveyDtos = gridResult.data;

            if (surveyDtos.length === 0) {
                navigationService.selectOneItemMessageModal();
                return;
            } else if (surveyDtos.length > 1) {
                navigationService.selectOnlyOneItemMessageModal();
                return;
            }

            if (!canCompile && !canView) {
                navigationService.operationNotPermittedModal();
                return;
            }

            var surveySelected = Lazy(surveyDtos).first();

            if (surveySelected.SurveyCreationPatternId === surveyCreationPatternObj.OnDemand) {
                // before create a clone of selected survey and then compile it
                var filter = { SurveyId: surveySelected.SurveyId };
                audService.getSurveyOnDemandClone(filter, true)
                    .then(function (data) {
                        var surveyCloned = Lazy(data.results).first();
                        navigationService.navigateToNewWindow("app.navlight.surveycompile", { 'surveyId': surveyCloned.SurveyId, 'appStoreCode': appStoreCode });
                    });
            } else {
                navigationService.navigateToNewWindow("app.navlight.surveycompile", { 'surveyId': surveySelected.SurveyId, 'appStoreCode': appStoreCode });
            }
        };

        // open add new auditrequest view
        vm.newAction = function () {
            navigationService.navigateTo("app.nav.surveycreationrequestdetail", { 'surveyId': null, 'appStoreCode': appStoreCode, 'viewKind': viewKind });
        };

        vm.updateLabelAction = function () {
            var selectedIds = [];

            getVhrEventLabels(false, appStoreBw)
                .then(function (results) {
                    var params = new NbsGenericListModalParamObject('VhrEventLabel', vm.translate('ActionPanelUpdateLabel'));
                    params.selectedIds = selectedIds;

                    navigationService.navToGenericListModal(vm.suspendDirty, params, results)
                        .then(function (result) {
                            if (result.hasSelection) {
                                var vhrEventMassivelyUpdateRequest = vhreventService.dataContext.vhrEventMassivelyUpdateRequests.add({
                                    VhrEventLabelsIdSerialized: JSON.stringify(result.selectedValues),
                                    VhrEventMassivelyUpdateRequestTypeId: vhrEventMassivelyUpdateRequestType.UpdateLabel,
                                    VhrEventsIdSerialized: JSON.stringify(getArraySelectedVhrEventsId()),
                                    AppStoreBw: appStoreBw,
                                    VirtualCompanyId: virtualCompanyId,
                                    ModuleTargetId: moduleTargetId
                                });
                                submitVhrEventMassivelyUpdateRequest(vhrEventMassivelyUpdateRequest);
                            }

                        });
                });
        };

        vm.infoLabelAction = function () {
            var selectedIds = [];
            if (gridResult.isSingleSelection) {
                var vhrEventSelected = Lazy(gridResult.data).first();
                selectedIds = Lazy(vhrEventSelected.VhrEventLabelsUI).pluck('IntValue').toArray();
            }

            getVhrEventLabels(false, appStoreBw)
                .then(function (results) {
                    var params = new NbsGenericListModalParamObject('VhrEventLabel', vm.translate('ActionPanelInfoLabel'));
                    params.readonly = true;
                    params.selectedIds = selectedIds;
                    navigationService.navToGenericListModal(vm.suspendDirty, params, results);
                });
        };

        vm.deleteLabelAction = function () {
            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        var vhrEventMassivelyUpdateRequest = vhreventService.dataContext.vhrEventMassivelyUpdateRequests.add({
                            VhrEventMassivelyUpdateRequestTypeId: vhrEventMassivelyUpdateRequestType.DeleteLabel,
                            VhrEventsIdSerialized: JSON.stringify(getArraySelectedVhrEventsId()),
                            AppStoreBw: appStoreBw,
                            VirtualCompanyId: virtualCompanyId,
                            ModuleTargetId: moduleTargetId
                        });

                        submitVhrEventMassivelyUpdateRequest(vhrEventMassivelyUpdateRequest);
                    }
                })
                .catch(function (reason) {
                });
        };

        vm.vhrEventLifeStatusApproveAction = function () {
            var vhrEventIds = getArraySelectedVhrEventsId();
            if (!Lazy(vhrEventIds).some()) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        var vhrEventsSerialized = JSON.stringify(vhrEventIds);
                        updateSurveyLifeStatusBulk(vhrEventsSerialized, moduleTargetId, surveyLifeStatusEnumObj.Approved, null);
                    }
                })
        };

        vm.vhrEventLifeStatusUpdateAction = function () {
            var vhrEventIds = getArraySelectedVhrEventsId();
            if (!Lazy(vhrEventIds).some()) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            var parentParams = { viewKind: 'modify', modalKey: 'modify', vhrEventsId: getArraySelectedVhrEventsId(), moduleTargetId: moduleTargetId };
            navigationService.navToModal(vm.suspendDirty, 'app.nav.surveychangelifestatusdetail-modify', parentParams)
                .then(function (result) {
                    if (result.data) {
                        updateSurveyLifeStatusBulk(result.data.VhrEventsSerialized, result.data.ModuleTargetId, result.data.SurveyLifeStatusId, result.data.SurveyLifeStatusChangeNote);
                    }
                });
        };

        vm.vhrEventLifeStatusInfoAction = function () {
            var vhrEventIds = getArraySelectedVhrEventsId();
            if (!Lazy(vhrEventIds).some()) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            var parentParams = { viewKind: 'info', modalKey: 'info', vhrEventsId: getArraySelectedVhrEventsId(), moduleTargetId: moduleTargetId };
            navigationService.navToModal(vm.suspendDirty, 'app.nav.surveychangelifestatusdetail-info', parentParams)
        };

        vm.workflowModifyAction = function () {
            var vhrEventIds = getArraySelectedVhrEventsId();
            if (!Lazy(vhrEventIds).some()) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            navigationService.navToVhrEventWorkflowModifyMode(vm.suspendDirty, { appStoreCode: appStoreCode })
                .then(function (result) {
                    if (result.data) {
                        var vhrEventIds = getArraySelectedVhrEventsId();
                        updateSurveyWorkflowStatusBulk(vhrEventIds, moduleTargetId, result.data.WorkflowStatusId, result.data.WorkflowStatusNote, vhrEventShuffleActionObj.ChangeWorkflowStatus);
                    }
                });
        };

        vm.workflowDeleteAction = function () {
            var vhrEventIds = getArraySelectedVhrEventsId();
            if (!Lazy(vhrEventIds).some()) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        var vhrEventIds = getArraySelectedVhrEventsId();
                        updateSurveyWorkflowStatusBulk(vhrEventIds, moduleTargetId, null, null, vhrEventShuffleActionObj.DeleteWorkflowStatus);
                    }
                })
                .catch(function (reason) {
                });
        };

        vm.workflowInfoAction = function () {
            if (!gridResult.isSingleSelection) {
                return;
            }

            navigationService.navToVhrEventWorkflowHistoryMode(vm.suspendDirty, { VhrEventId: gridResult.data[0].VhrEventId, appStoreCode: appStoreCode });
        };

        function getVhrEventLabels(forceReload, appStoreBw) {
            var filter = { AppStoreBw: appStoreBw };
            var defer = $q.defer();

            vhreventService.getVhrEventLabels(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        // START: functions used to manage vhrEventMassivelyUpdateRequest
        function submitVhrEventMassivelyUpdateRequest(request) {
            vm.submitAction()
                .then(function (saveResult) {
                    if (request.IsBatch) {
                        contextService.addBatchRequest({
                            requestId: request.BatchRequestId,
                            requestName: request.BatchRequestName,
                            requestStatus: 'inserted'
                        });
                    }

                    vm.refreshAction();
                }).catch(function (error) {
                    vm.removeAlertFromList(true);
                    vm.setErrorMsg(error);
                });
        }
        // END

        //Http Post
        function updateSurveyLifeStatusBulk(vhrEventsIdSerialized, moduleTargetId, surveyLifeStatus, changeNote) {
            vm.removeAlerts();

            var surveyShuffleDto = audService.dataContext.surveyShuffleDtos.add({
                SurveyShuffleId: NbsGuidManager.newGuid(),
                SurveyLifeStatus: surveyLifeStatus,
                SurveyLifeStatusChangeNote: changeNote,
                ModuleTargetId: moduleTargetId,
                VhrEventsIdSerialized: vhrEventsIdSerialized
            });

            audService.saveChanges()
                .then(function () {
                    var resultErrors = JSON.parse(Nembus.Common.Library.converter.decodeHtml(surveyShuffleDto.ResultErrorsSerialized));
                    if (Lazy(Object.keys(resultErrors)).some()) {
                        vm.setErrorMsg(({ isMessageVisible: true, message: vm.translate('ChangeStatusNotPermitted') }));
                        return;
                    }

                    vm.refreshAction();
                });
        }

        //Http Post
        function updateSurveyWorkflowStatusBulk(vhrEventsId, moduleTargetId, workflowStatusId, workflowStatusNote, vhrEventShuffleAction) {
            vm.removeAlerts();

            var vhreventShuffleDto = vhreventService.dataContext.vhrEventShuffleDto.add();
            vhreventShuffleDto.VhrEventShuffleAction = vhrEventShuffleAction;
            if (vhrEventShuffleAction === vhrEventShuffleActionObj.DeleteWorkflowStatus) {
                vhreventShuffleDto.WorkflowStatusId = 0;
                vhreventShuffleDto.VhrEventWorkflowStatusChangeNote = null;
            } else {
                vhreventShuffleDto.WorkflowStatusId = workflowStatusId;
                vhreventShuffleDto.VhrEventWorkflowStatusChangeNote = workflowStatusNote;
            }
            vhreventShuffleDto.VhrEventBillingStatus = 0;
            vhreventShuffleDto.VhrEventLifeStatus = 0;
            vhreventShuffleDto.ModuleTargetId = moduleTargetId;
            vhreventShuffleDto.VhrEventsIdSerialized = JSON.stringify(vhrEventsId);

            vhreventService.saveChanges()
                .then(function () {
                    vm.refreshAction();
                });
        }

        //#region Override of base methods to add verify on selection record
        function canPrintReport() {
            var result = false;
            var surveyDtos = gridResult.data;
            if (surveyDtos.length === 0) {
                navigationService.selectOneItemMessageModal();
            } else if (surveyDtos.length > 1) {
                navigationService.selectOnlyOneItemMessageModal();
            } else {
                result = true;
            }

            return result;
        }

        vm.exportReportToPdfAction = function () { pExportReport('pdf'); };
        vm.exportReportToWordAction = function () { pExportReport('openword'); };
        vm.exportReportToXmlAction = function () { pExportReport('xml'); };
        vm.exportReportToExcelAction = function () { pExportReport('openexcel'); };
        vm.exportReportToHtmlAction = function () { pExportReport('html'); };

        function pExportReport(format) {
            if (canPrintReport()) {
                vm.getReportsForCmsView(cmsService)
                    .then(function () {
                        var cEntity = Lazy(gridResult.data).first();
                        var currentReportDefinition = vm.currentReportData.reportDefinition;
                        var currentConcatName = cEntity.SurveyCode + ' - ' + cEntity.SurveyName;

                        if (currentReportDefinition) {
                            currentConcatName = currentReportDefinition.ReportDefinitionReferenceCode + ' - ' + currentConcatName;
                        }

                        var reportArgs = {
                            Ds1_Arg1: {                                         //SurveyFilter
                                SurveyId: cEntity.SurveyId
                            },
                            Ds1_Arg2: {},                                       //SurveyVhrEventFilter
                            Ds1_Arg3: {                                         //VhrEventFilter
                                ModuleTargetId: moduleTargetId
                            },
                            Ds1_Arg4: {},                                       //SurveySectionFilter
                            Ds1_Arg5: {},                                       //SurveyQuestionFilter
                            Ds1_Arg6: {},
                            Ds1_Arg7: {},                                       //VirtualHrFilter
                            Ds1_Arg8: false,                                    //isConfiguration
                            Ds1_Arg9: {                                         //surveyAnalysisFilter
                                SurveyId: cEntity.SurveyId
                            },
                        };

                        vm.setReportData({ reportArgs: reportArgs, replaceName: currentConcatName });

                        vm.exportReport(format);
                    });
            }
        }
        //#endregion overload of base methods to add verify on selection record
        //#region Filters for datasources
        function parseFromBarFilter(surveyBarFilter) {
            // surveyFilter
            surveyBarFilter.surveyFilter.IsArchived = vhrEventLifeStatusKey === 'archived' ? true : false;
            switch (vhrEventLifeStatusKey) {
                case 'tocompile':
                    surveyBarFilter.surveyFilter.IsCompleted = false;
                    break;
                case 'compiled':
                    surveyBarFilter.surveyFilter.IsCompleted = true;
                    break;
                default:
            }
            // surveyVhrEventFilter
            if (vhrEventLifeStatusKey === 'ondemand') {
                surveyBarFilter.surveyVhrEventFilter.SurveyCreationPatternId = surveyCreationPatternObj.OnDemand;
            } else {
                surveyBarFilter.surveyVhrEventFilter.ExcludeOnDemand = true;
            }
            // vhrEventFilter
            surveyBarFilter.vhrEventFilter.AppStoreBw = appStoreBw;
            surveyBarFilter.vhrEventFilter.VhrEventTypeCode = vhrEventTypeCode;
            surveyBarFilter.vhrEventFilter.VhrEventFixedTypeCode = vhrEventFixedTypeCode;
            surveyBarFilter.vhrEventFilter.VhrEventKind = vhrEventKindEnum.Data;
            surveyBarFilter.vhrEventFilter.ModuleTargetId = moduleTargetId;

            return surveyBarFilter;
        }
        //#endregion

    }

    surveysList.$inject = injectParams;
    surveysList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('surveysList', surveysList);
});