﻿'use strict';
define([], function () {

    var injectParams = ['$timeout'];
    var nbsDatepickerComponent = function ($timeout) {

        var vm = this;
        vm.ngDatePickerId = 'nbs_dpid_' + NbsGuidManager.newGuid().replace('-', '');
        vm.privateDate = null;

        vm.$onInit = function () {
            // wrapper (nbs-vm) function for datepicker open status
            vm.getDpIsOpen = vm.uiContext.getDpIsOpen;
            vm.setDpIsOpen = vm.uiContext.setDpIsOpen;
        };

        vm.$onChanges = function (changes) {
            if (changes.nbsDataSelected) {
                $timeout(function () {
                    vm.privateDate = moment(changes.nbsDataSelected.currentValue).toDate();
                }, 0);
            }
        };

        vm.onDateChange = function () {
            vm.onChange({ date: vm.privateDate, custom: null });
        };        
    };

    nbsDatepickerComponent.$inject = injectParams;

    angular.module('nbs.rds.module').component('nbsDatepicker', {
        templateUrl: '/rds/common/components/nbsDatepickerComponent/_?c=' + window.codeCache(),
        controller: nbsDatepickerComponent,
        controllerAs: 'vm',
        bindings: {
            onChange: '&',
            nbsDataSelected: "<",
            uiContext: "<",
            ddLabel: '<',
            isVisible: "<",
        }
    });
});

