﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'hrbucketService', 'navigationService', 'contextService', 'formSummaryService'];
    var bucketFtc = function ($q, $scope, $timeout, $injector, hrbucketService, navigationService, contextService, formSummaryService) {

        var vm = this;
        nbsVm.call(vm, hrbucketService, navigationService, contextService, $q, $injector, $timeout);
        
        var appStoreCodeFtc = navigationService.stateParams().appStoreCodeFtc;
        var bucketClassification = navigationService.stateParams().bucketClassification;
        var cmsView = 'bucketftc-_';
        var virtualHumanResourceId = navigationService.stateParams().virtualHumanResourceId;
        var hrMetadataId = null;
        var viewModeFtcPar = navigationService.stateParams().viewMode;
        if (!viewModeFtcPar) {
            viewModeFtcPar = '_';
        }

        appStoreCodeFtc = appStoreCodeFtc ? appStoreCodeFtc : "";
        vm.resourceType = navigationService.stateParams().resourceType;
        
        var state = {
            generality: 'generality', categories: 'categories', modules: 'modules', boms: 'boms', vhrEvents: 'vhrEvents', hierarchies: 'hierarchies',
            audits: 'audits', auditsToCompile: 'auditsToCompile', auditsCompiled: 'auditsCompiled', auditsOndemand: 'auditsOndemand', acts: 'acts',
            actsAll: 'actsAll', actsToExecute: 'actsToExecute', actsExecuted: 'actsExecuted', references: 'references'
        };

        var fastTab = new NbsAccordion();
        fastTab.AddFastTab(state.generality, "Generality");
        fastTab.AddFastTab(state.categories, "Categories", vm.resourceType != 'DataCenter');
        fastTab.AddFastTab(state.modules, "Modules", vm.resourceType != 'DataCenter');
        fastTab.AddFastTab(state.boms, "Boms", vm.resourceType != 'DataCenter');
        fastTab.AddFastTab(state.vhrEvents, "VhrEvents", vm.resourceType != 'DataCenter');
        fastTab.AddFastTab(state.audits, "Audits", vm.resourceType != 'DataCenter');
        fastTab.AddLink(state.auditsCompiled, state.audits, "AuditsCompiled");
        fastTab.AddLink(state.auditsToCompile, state.audits, "AuditsToCompile");
        fastTab.AddLink(state.auditsOndemand, state.audits, "AuditsOndemand");
        fastTab.AddFastTab(state.acts, "Actions", vm.resourceType != 'DataCenter');
        fastTab.AddLink(state.actsAll, state.acts, "ActionsAll");
        fastTab.AddLink(state.actsToExecute, state.acts, "ActionsToExecute");
        fastTab.AddLink(state.actsExecuted, state.acts, "ActionsExecuted");
        fastTab.AddFastTab(state.hierarchies, "Hierarchies");
        fastTab.AddFastTab(state.references, "References");
        vm.fastTabDatacontext = fastTab;

        vm.ftcOnClick = function (event) {
            navigateTo(event.id);
        }

        // raised from detail view
        vm.ftcOnAddEntity = function (entity) {
            $timeout(() => {
                virtualHumanResourceId = entity.VirtualHumanResourceId;
                hrMetadataId = entity.HrMetadataId;

                vm.fastTabsAreDisabled = false;
            });
        }

        if (virtualHumanResourceId) {
            hrbucketService.getHrBucket({ VirtualHumanResourceId: virtualHumanResourceId }, true)
                .then(function (data) {
                    var hrBucket = data.results[0];
                    hrMetadataId = hrBucket.HrMetadataId;

                    $timeout(() => {
                        vm.fastTabsAreDisabled = false;
                    });

                    // configure form summary items
                    formSummaryService.clearDatasource();
                    formSummaryService.push({ key: vm.translateCmsView(cmsView, 'HrBucketName', 'HrBucketGenerality'), value: hrBucket.HrBucketGenerality.HrBucketName });
                    formSummaryService.push({ key: vm.translateCmsView(cmsView, 'HrBucketCode', 'HrBucketGenerality'), value: hrBucket.HrBucketGenerality.HrBucketCode });

                    goToDefaultFastTab();
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

        } else {
            goToDefaultFastTab();

            $timeout(() => {
                vm.fastTabsAreDisabled = true;
            });
        }

        function navigateTo (accordionId) {
            if (!accordionId) {
                return;
            }

            var navigationState = null;
            var navigationStateParams = null;
            switch (accordionId) {
                case state.generality:
                    navigationState = 'app.nav.bucketftc.bucketdetail-' + viewModeFtcPar;
                    navigationStateParams = { 'virtualHumanResourceId': virtualHumanResourceId, 'appStoreCode': appStoreCodeFtc, 'resourceType': vm.resourceType, 'bucketClassification': bucketClassification };
                    break;
                case state.categories:
                    navigationState = 'app.nav.bucketftc.buckettocategoriesdetail';
                    navigationStateParams = { 'hrMetadataId': hrMetadataId, 'appStoreCode': appStoreCodeFtc, 'virtualHumanResourceId': virtualHumanResourceId };
                    break;
                case state.modules:
                    navigationState = 'app.nav.bucketftc.buckettomodulesdetail';
                    navigationStateParams = { 'entityName': 'HrBucket', 'virtualHumanResourceId': virtualHumanResourceId };
                    break;
                case state.boms:
                    navigationState = 'app.nav.bucketftc.buckettoactivitiesdetail';
                    navigationStateParams = { 'virtualHumanResourceId': virtualHumanResourceId, 'appStoreCode': appStoreCodeFtc };
                    break;
                case state.vhrEvents:
                    navigationState = 'app.nav.bucketftc.vhreventsvhrlist-ats';
                    navigationStateParams = { 'wzVhrEntityType': 'bucket', 'vhrEventTypeCode': null, 'vhrEventFixedTypeCode': null, 'appStoreCode': 'ats', 'virtualHumanResourceId': virtualHumanResourceId };
                    break;
                case state.hierarchies:
                    navigationState = 'app.nav.bucketftc.buckettohierarchiesdetail';
                    navigationStateParams = { 'hrMetadataId': hrMetadataId, 'virtualHumanResourceId': virtualHumanResourceId, 'appStoreCode': appStoreCodeFtc };
                    break;
                case state.auditsToCompile:
                    navigationState = 'app.nav.bucketftc.surveysvhrlist-tocompile';
                    navigationStateParams = { 'virtualHumanResourceId': virtualHumanResourceId, 'appStoreCode': 'aud', 'vhrEventLifeStatusKey': 'tocompile' };
                    break;
                case state.auditsCompiled:
                    navigationState = 'app.nav.bucketftc.surveysvhrlist-compiled';
                    navigationStateParams = { 'virtualHumanResourceId': virtualHumanResourceId, 'appStoreCode': 'aud', 'vhrEventLifeStatusKey': 'compiled' };
                    break;
                case state.auditsOndemand:
                    navigationState = 'app.nav.bucketftc.surveysvhrlist-ondemand';
                    navigationStateParams = { 'virtualHumanResourceId': virtualHumanResourceId, 'appStoreCode': 'aud', 'vhrEventLifeStatusKey': 'ondemand' };
                    break;
                case state.actsAll:
                    navigationState = 'app.nav.bucketftc.actsvhrlist-all';
                    navigationStateParams = { 'viewKind': 'all', 'appStoreCode': appStoreCodeFtc, 'actTemplateCode': 'aud' };
                    break;
                case state.actsToExecute:
                    navigationState = 'app.nav.bucketftc.actsvhrlist-toexecute';
                    navigationStateParams = { 'viewKind': 'toexecute', 'appStoreCode': appStoreCodeFtc, 'actTemplateCode': 'aud' };
                    break;
                case state.actsExecuted:
                    navigationState = 'app.nav.bucketftc.actsvhrlist-executed';
                    navigationStateParams = { 'viewKind': 'executed', 'appStoreCode': appStoreCodeFtc, 'actTemplateCode': 'aud' };
                    break;
                case state.references:
                    navigationState = 'app.nav.bucketftc.vhrreferencesdetail-bucket';
                    navigationStateParams = { 'virtualHumanResourceId': virtualHumanResourceId, 'appStoreCode': appStoreCodeFtc, 'vhrType': 'bucket' };
                    break;
            }

            // call navigation state
            if (navigationState && navigationStateParams) {
                navigationService.navigateTo(navigationState, navigationStateParams);
            }
        };

        function goToDefaultFastTab() {
            // go to default page only if is called ftc directly
            if (navigationService.currentState().endsWith('.bucketftc')) {
                navigateTo(state.generality);
            }
        }
    };

    bucketFtc.$inject = injectParams;
    bucketFtc.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('bucketFtc', bucketFtc);
});