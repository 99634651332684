﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'workflowService', 'actService', 'reportService', 'navigationService', 'checkStateChangeService', 'contextService', 'utilService'];
    var actsExportReportForm = function ($q, $scope, $timeout, $injector, foundationService, workflowService, actService, reportService, navigationService, checkStateChangeService, contextService, utilService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        var checkStateChangeParams = { dirtyFlagIsDisabled: vm.isDirtyDisabled };
        checkStateChangeService.checkFormOnStateChange($scope, foundationService, checkStateChangeParams);

        var exportCode = navigationService.stateParams().exportCode;
        var actPriorityEnumParsed = Nembus.Common.Library.util.parseEnum(actService.dataContext.enumTypes.ActPriority, 'Act', vm.translate);
        var expressionTypeEnum = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.ExpressionType, null, vm.translate).obj.normal.name_value;
        var parentScope = $scope.$parent.vm;

        // all form items are enabled
        vm.setIsEditingReport();

        //#region Multiselect with grid selection
        var selectionParams = { destModalKey: 'vhr', entityMode: 'Target', translateKey: 'Target', isMultiselect: true };
        vm.nbsSelectionGridWithModalTarget = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        var dsCallback = function () { return foundationService.getVhrTypes(false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrType', viewTitle: vm.translate('TargetDto.TargetTypeId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrType = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        dsCallback = function () { return foundationService.getVhrGroups(false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrGroup', viewTitle: vm.translate('TargetDto.TargetGroupId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrGroup = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        selectionParams = { destModalKey: 'category', systemCategoryCode: 'ACT', categoryType: 'user', isAutocheck: false, viewKind: 'all', expandTree: true };
        vm.nbsSelectionTreeviewWithModalCategory = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        vm.nbsActionDrbacModalObj = new NbsAssigneeSelectorModal({
            translator: vm.translate,
            suspendDirty: vm.suspendDirty,
            navigationService: navigationService,
            contextUserForSelection: null,
            getServerVarFn: Nembus.Common.Library.server.getServerVar
        });
        vm.nbsActionDrbacModalObj.setVisibilityServerVar('ssv_core_act_assignee_visibility_selection');

        vm.actPriorities = actPriorityEnumParsed.list.translated;
        vm.workflowStatusKinds = Nembus.Common.Library.util.parseEnum(workflowService.dataContext.enumTypes.WorkflowStatusKind, 'WorkflowStatus', vm.translate).list.translated;

        // posted filter
        vm.actsExportReportFormClientData = {};

        // set title of current controller in report definition detail view
        navigationService.setControllerTitle();

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            
            $q.all(funcsPromises)
                .then(function (results) {
                    reportService.currentReport.prepareReportFilterCallback = setQueryFilter;
                });
        }

        function setQueryFilter() {
            var queryFilter = { // ActExportFilter
                actFilter: {
                    ActStartDate1Expression: expressionTypeEnum.GreaterThanOrEqual,
                    ActStartDate2Expression: expressionTypeEnum.LessThanOrEqual,
                    ActExpireDate1Expression: expressionTypeEnum.GreaterThanOrEqual,
                    ActExpireDate2Expression: expressionTypeEnum.LessThanOrEqual,
                    ActExecutionDate1Expression: expressionTypeEnum.GreaterThanOrEqual,
                    ActExecutionDate2Expression: expressionTypeEnum.LessThanOrEqual,
                    HaveToExpandVhrsWithAssetTree: true,
                    HaveToExpandCategories: true
                },
                exportCode: exportCode
            };

            queryFilter.actFilter.ActStartDate1 = vm.actsExportReportFormClientData.ActStartDate1;
            queryFilter.actFilter.ActStartDate2 = vm.actsExportReportFormClientData.ActStartDate2;
            queryFilter.actFilter.ActExpireDate1 = vm.actsExportReportFormClientData.ActExpireDate1;
            queryFilter.actFilter.ActExpireDate2 = vm.actsExportReportFormClientData.ActExpireDate2;
            queryFilter.actFilter.ActExecutionDate1 = vm.actsExportReportFormClientData.ActExecutionDate1;
            queryFilter.actFilter.ActExecutionDate2 = vm.actsExportReportFormClientData.ActExecutionDate2;
            queryFilter.actFilter.ActTargetIds = vm.nbsSelectionGridWithModalTarget.selectedIds;
            queryFilter.actFilter.CategoriesId = vm.nbsSelectionTreeviewWithModalCategory.selectedIds;
            queryFilter.actFilter.ActWorkflowStatusKindId = vm.actsExportReportFormClientData.ActWorkflowStatusKindId;
            queryFilter.actFilter.ActPriorityId = vm.actsExportReportFormClientData.ActPriorityId;
            queryFilter.actFilter.VhrGroupIds = vm.nbsSelectionGridWithModalVhrGroup.selectedIds;
            queryFilter.actFilter.VhrTypeIds = vm.nbsSelectionGridWithModalVhrType.selectedIds;

            queryFilter.actFilter.AppUserAssignedIds = vm.nbsActionDrbacModalObj.appUserAreSelected()
                ? vm.nbsActionDrbacModalObj.appUserMultiselectSelectedIds()
                : null;
            queryFilter.actFilter.UserGroupAssignedIds = vm.nbsActionDrbacModalObj.drbacUserGroupAreSelected()
                ? vm.nbsActionDrbacModalObj.drbacUserGroupMultiselectSelectedIds()
                : null;

            parentScope.setIntegrationQueryFilter(queryFilter);
        }
    };

    actsExportReportForm.$inject = injectParams;
    actsExportReportForm.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('actsExportReportForm', actsExportReportForm);
});
