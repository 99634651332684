/*
* Common library - tool function
*/
import * as $ from 'jquery';
import * as moment from 'moment';

// @ts-ignore
var Lazy = require("lazy.js");

export class NbsCommonUI {

    // function that can be called by angular template in grid and 
    // retrieve controller to call
    static callControllerFromTemplate = function (element, controllerMethod) {
        if (!controllerMethod) {
            throw new Error('callControllerFromTemplate: controllerMethod mandatory');
        }

        if (!element.id) {
            throw new Error('callControllerFromTemplate: id <tag> mandatory');
        }

        var currentElement = document.getElementById(element.id);
        // @ts-ignore
        var elementScope = angular.element(currentElement).scope();
        if (elementScope) {
            if (elementScope.vm && elementScope.vm[controllerMethod]) {
                elementScope.vm[controllerMethod](element);
            } else if (elementScope.$parent && elementScope.$parent.vm && elementScope.$parent.vm[controllerMethod]) {
                elementScope.$parent.vm[controllerMethod](element);
            }
        }
    }

    static addLoadingSilent = function (filter) {
        if (filter) {
            filter.sxb = 'x';
            return filter;
        }
        return { xsb: 'x' };
    }

    //'xs' : 480,     // extra small
    //'sm' : 768,     // small
    //'md' : 992,     // medium
    //'lg' : 1200     // large
    static isUnderBreakpoint_md = function () {
        // @ts-ignore
        return Metronic.getViewPort().width <= Metronic.getResponsiveBreakpoint('md');
    }

    static isUnderBreakpoint_xs = function () {
        // @ts-ignore
        return Metronic.getViewPort().width <= Metronic.getResponsiveBreakpoint('xs');
    }

    static isUnderBreakpoint_sm = function () {
        // @ts-ignore
        return Metronic.getViewPort().width <= Metronic.getResponsiveBreakpoint('sm');
    }

    static isUnderBreakpoint_lg = function () {
        // @ts-ignore
        return Metronic.getViewPort().width <= Metronic.getResponsiveBreakpoint('lg');
    }

    static useListView = function () {
        var useListView = false;
        if (NbsCommonUI.isUnderBreakpoint_xs()) {
            useListView = true;
        }
        if (NbsCommonUI.isUnderBreakpoint_sm()) {
            useListView = true;
        }
        if (NbsCommonUI.isUnderBreakpoint_md()) {
            useListView = true;
        }
        return useListView;
    }

    static sidebarToggle = function () {
        if (NbsCommonUI.isUnderBreakpoint_md()) {
            $('.menu-toggler.responsive-toggler').click();
        }
        else {
            $('.menu-toggler.sidebar-toggler').click();
        }
    }

    static offlineModalOpen: boolean = false;
    static modalOffline = function (modalData, $uibModal, defer) {
        if (NbsCommonUI.offlineModalOpen) {
            return;
        }
        var ModalInstanceCtrl = function ($scope, $uibModalInstance) {
            $scope.ok = function () {
                //$uibModalInstance.close();
                $uibModalInstance.dismiss();
                NbsCommonUI.offlineModalOpen = false;
            };

            $scope.cancel = function () {
                $uibModalInstance.dismiss();
                NbsCommonUI.offlineModalOpen = false;
            };
        };

        NbsCommonUI.offlineModalOpen = true;
        var modalInstance = $uibModal.open({
            template: offlineTemplate(modalData),
            controller: ModalInstanceCtrl,
            backdrop: 'static'
        });

        modalInstance.result.then(function () {
            defer.resolve(true);
        }, function (error) {
            defer.resolve(false);
        });

        function offlineTemplate(modalData) {
            if (modalData.title === undefined) {
                modalData.title = 'Errore';
            }
            if (modalData.message === undefined) {
                modalData.message = 'Il sistema è offline. Riprovare più tardi';
            }
            var modalHtml = '<div class="modal-header" draggable><h3 class="modal-title">' + modalData.title + '</h3></div>';
            modalHtml += '<div class="modal-body">' + modalData.message + '</div>';
            modalHtml += '<div class="modal-footer"><button class="btn btn-primary" ng-click="ok()">Chiudi</button></div>';
            return modalHtml;
        };

        return defer.promise;
    }

}

export class NbsCommonConverterTimeSpan {
    static jsonToString = function (source) {
        //example: 
        //input: PT5H15M
        //output: '05:15'
        if (!source) {
            source = 'PT0H0M0S';
        }

        source = source.replace('PT', '');

        var hours;
        var minutes;
        var seconds;
        var hindex = source.indexOf('H');
        var mindex = source.indexOf('M');
        var sindex = source.indexOf('S');

        //set hours
        hours = hindex > 0 ? source.substring(0, hindex) : '0';
        hours = Number(hours) < 10 ? '0' + hours : hours;

        //set minutes
        if (mindex < 0) {
            minutes = '00';
        } else {
            if (hindex < 0) {
                minutes = source.substring(0, mindex);
            } else {
                minutes = source.substring(hindex + 1, mindex);
            }
            minutes = Number(minutes) < 10 ? '0' + minutes : minutes;
        }

        //set seconds
        if (sindex < 0) {
            seconds = '00';
        } else {
            if (mindex < 0) {
                if (hindex < 0) {
                    seconds = source.substring(0, sindex);
                } else {
                    seconds = source.substring(hindex + 1, sindex);
                }
            } else {
                seconds = source.substring(mindex + 1, sindex);
            }

            seconds = Number(seconds) < 10 ? '0' + seconds : seconds;
        }

        return hours + ':' + minutes + ':' + seconds;
    }

    static jsonToDate = function (source, destDate) {
        if (!source) {
            source = 'PT0H0M';
        }

        //input: PT5H15M
        source = source.replace('PT', '');

        var hours;
        var hindex = source.indexOf('H');
        var mindex = source.indexOf('M');
        var sindex = source.indexOf('S');

        var date = new Date();
        if (destDate) {
            date = new Date(destDate);
        }

        //set hours
        hours = hindex > 0
            ? date.setHours(Number(source.substring(0, hindex)))
            : date.setHours(0);

        //set minutes
        if (mindex < 0) {
            date.setMinutes(0);
        } else {
            if (hindex < 0) {
                date.setMinutes(Number(source.substring(0, mindex)));
            } else {
                date.setMinutes(Number(source.substring(hindex + 1, mindex)));
            }
        }

        //set seconds
        if (sindex < 0) {
            date.setSeconds(0);
        } else {
            if (mindex < 0) {
                if (hindex < 0) {
                    date.setSeconds(Number(source.substring(0, sindex)));
                } else {
                    date.setSeconds(Number(source.substring(hindex + 1, sindex)));
                }
            } else {
                date.setSeconds(Number(source.substring(mindex + 1, sindex)));
            }
        }

        return date;
    }

    static jsonToMinutes = function (source) {
        //input: PT5H15M
        source = source.replace('PT', '');

        var hindex = source.indexOf('H');
        var mindex = source.indexOf('M');

        //hours and minutes
        var hours = hindex > 0 ? Number(source.substring(0, hindex)) : 0;
        var minutes = mindex > 0 ? Number(source.substring(hindex + 1, mindex)) : 0;

        //total minutes
        var total = hours * 60;
        total += minutes;

        return total;
    }

    static jsonToHour = function (source) {
        //input: PT5H15M
        source = source.replace('PT', '');
        var hindex = source.indexOf('H');
        var hours = hindex > 0 ? Number(source.substring(0, hindex)) : 0;
        return hours;
    }

    static jsonToMinute = function (source) {
        //input: PT5H15M
        source = source.replace('PT', '');
        var hindex = source.indexOf('H');
        var mindex = source.indexOf('M');
        var minutes = mindex > 0 ? Number(source.substring(hindex + 1, mindex)) : 0;
        return minutes;
    }

    static jsonToSecond = function (source) {
        //input: PT5H15M20S
        source = source.replace('PT', '');
        var hindex = source.indexOf('H');
        var mindex = source.indexOf('M');
        var sindex = source.indexOf('S');
        var seconds = 0;
        if (mindex > 0) {
            seconds = sindex > 0 ? Number(source.substring(mindex + 1, sindex)) : 0;
        } else {
            if (hindex > 0) {
                seconds = sindex > 0 ? Number(source.substring(hindex + 1, sindex)) : 0;
            } else {
                seconds = sindex > 0 ? Number(source.substring(0, sindex)) : 0;
            }
        }

        return seconds;
    }

    static dateToJson = function (source, ignoreTimeZone) {
        if (!source)
            return '';

        var d = new Date(source);

        if (ignoreTimeZone === true) {
            d.setTime(d.getTime() + d.getTimezoneOffset() * 60000);
        }


        if (!isNaN(d.valueOf())) {

            if (d.getMinutes() === 0) {
                return 'PT' + d.getHours() + 'H' + d.getSeconds() + 'S';
            } else if (d.getSeconds() === 0) {
                return 'PT' + d.getHours() + 'H' + d.getMinutes() + 'M';
            } else {
                return 'PT' + d.getHours() + 'H' + d.getMinutes() + 'M' + d.getSeconds() + 'S';
            }

        } else {
            return '';
        }
    }

    static stringToJson = function (source) {
        // source hh:mm:ss
        var timeSplit = source.split(':');

        if (timeSplit.length === 3) {
            return 'PT' + timeSplit[0] + 'H' + timeSplit[1] + 'M' + timeSplit[2] + 'S';
        } if (timeSplit.length === 2) {
            return 'PT' + timeSplit[0] + 'H' + timeSplit[1] + 'M';
        } if (timeSplit.length === 1) {
            return 'PT' + timeSplit[0] + 'H';
        }
    }


}

export class NbsCommonConverterDate {

    static getNowDate = function () {
        var date = new Date(Date.now());
        date.setMilliseconds(0);
        return date;
    }

    static formatDate = function (source, cultureInfo, ignoreTimeZone) {
        if (!source) {
            return '';
        }

        var src = null;
        if (cultureInfo.formatDate.toLowerCase() === "dd/mm/yyyy" && isNaN(Date.parse(source))) {

            var sourceArray = source.split('/');
            src = new Date(sourceArray[1] + '/' + sourceArray[0] + '/' + sourceArray[2]);
        } else {
            src = new Date(source);
        }

        if (ignoreTimeZone === true) {
            src.setTime(src.getTime() + src.getTimezoneOffset() * 60000);
        }

        if (cultureInfo && cultureInfo.moment.formatDate) {
            return moment(src).format(cultureInfo.moment.formatDate);
        }
        return moment(src).format("DD/MM/YYYY");
    }

    static formatTime = function (source, cultureInfo, ignoreTimeZone) {
        if (!source) {
            return '';
        }
        var src = new Date(source);
        if (ignoreTimeZone !== null && ignoreTimeZone === true) {
            src.setTime(src.getTime() + src.getTimezoneOffset() * 60000);
        }

        if (cultureInfo && cultureInfo.formatTime) {
            return moment(src).format(cultureInfo.formatTime);
        }
        return moment(src).format(cultureInfo.formatTime);
    }

    static formatFullTime = function (source, cultureInfo, ignoreTimeZone) {
        if (!source) {
            return '';
        }
        var src = new Date(source);
        if (ignoreTimeZone !== null && ignoreTimeZone === true) {
            src.setTime(src.getTime() + src.getTimezoneOffset() * 60000);
        }

        if (cultureInfo && cultureInfo.formatTime) {
            return moment(src).format(cultureInfo.formatFullTime);
        }
        return moment(src).format(cultureInfo.formatFullTime);
    }

    static formatTimePicker = function (source, cultureInfo, ignoreTimeZone) {
        if (!source) {
            return '';
        }
        var src = new Date(source);
        if (ignoreTimeZone !== null && ignoreTimeZone === true) {
            src.setTime(src.getTime() + src.getTimezoneOffset() * 60000);
        }

        return 'PT' + moment(src).format('HH') + 'H' + moment(src).format('mm') + 'M' + moment(src).format('ss') + 'S';
    }

    static formatDateTime = function (source, cultureInfo, ignoreTimeZone, overrideFormatString?) {
        if (!source) {
            return '';
        }
        var src = new Date(source);
        if (ignoreTimeZone !== null && ignoreTimeZone === true) {
            src.setTime(src.getTime() + src.getTimezoneOffset() * 60000);
        }

        if (!overrideFormatString) {
            if (cultureInfo && cultureInfo.moment.formatDateTime) {
                return moment(src).format(cultureInfo.moment.formatDateTime);
            }
            return moment(src).format("DD/MM/YYYY HH:mm:ss");
        } else {
            return moment(src).format(overrideFormatString);
        }
    }

    static formatDateTimeUtcToLocal = function (source, cultureInfo) {
        if (!source) {
            return null;
        }

        // @ts-ignore
        return NbsCommonConverterDate.formatDateTime(source, cultureInfo, true) + ' (Lt:' + NbsCommonConverterDate.formatTime(fieldValue, cultureInfo, false) + ")";
    }

    static formatDateTimeLocalToUtc = function (source, cultureInfo) {
        if (!source) {
            return null;
        }

        return NbsCommonConverterDate.formatDateTime(source, cultureInfo, false) + ' (Utc:' + NbsCommonConverterDate.formatTime(source, cultureInfo, true) + ")";
    }

    //convert datetime to shor in UI exactly how is write into DB
    static toIgnoreTimeZone = function (source) {
        if (!source) {
            return;
        }

        var src = new Date(source);
        src.setTime(src.getTime() + src.getTimezoneOffset() * 60000);
        return src;
    }

    static localAsUtc = function (source) {
        if (!source) {
            return;
        }

        var src = new Date(source);
        src.setTime(src.getTime() - src.getTimezoneOffset() * 60000);
        return src;
    }

    // convert range date in
    // hours:minute:seconds => string format
    static rangeToDurationString = function (startDate, startTime, endDate, endTime) {
        var jsonFormatDiff;
        var msecDiff: any = 0;

        if (startDate && startTime && endDate && endTime) {
            var calcStart = new Date(
                startDate.getFullYear()
                , startDate.getMonth()
                , startDate.getDate()
                , NbsCommonConverterTimeSpan.jsonToHour(startTime)
                , NbsCommonConverterTimeSpan.jsonToMinute(startTime)
                , NbsCommonConverterTimeSpan.jsonToSecond(startTime)
            );
            var calcEnd = new Date(
                endDate.getFullYear()
                , endDate.getMonth()
                , endDate.getDate()
                , NbsCommonConverterTimeSpan.jsonToHour(endTime)
                , NbsCommonConverterTimeSpan.jsonToMinute(endTime)
                , NbsCommonConverterTimeSpan.jsonToSecond(endTime)
            );
            if (calcStart && calcEnd) {
                msecDiff = calcEnd.getTime() - calcStart.getTime();
            }
        }

        if (msecDiff > 0) {
            var hh = Math.floor(msecDiff / 1000 / 60 / 60);
            msecDiff -= hh * 1000 * 60 * 60;
            var mm = Math.floor(msecDiff / 1000 / 60);
            msecDiff -= mm * 1000 * 60;
            var ss = Math.floor(msecDiff / 1000);
            msecDiff -= ss * 1000;
            jsonFormatDiff = 'PT' + hh + 'H' + mm + 'M' + ss + 'S';
        } else {
            jsonFormatDiff = 'PT0H0M0S';
        }

        return NbsCommonConverterTimeSpan.jsonToString(jsonFormatDiff);
    }

    static convertTimeToString = function (unitId, quota) {
        var jsonFormatDiff;
        var seconds = 0;

        if (unitId === 7) { // seconds
            seconds = quota;

            if (seconds > 0) {
                var hh = Math.floor(seconds / 60 / 60);
                seconds -= hh * 60 * 60;
                var mm = Math.floor(seconds / 60);
                seconds -= mm * 60;
                var ss = Math.floor(seconds);
                seconds -= ss;

                jsonFormatDiff = 'PT' + hh + 'H' + mm + 'M' + ss + 'S';
            } else {
                jsonFormatDiff = 'PT0H0M0S';
            }
        } else {
            throw new Error('NbsCommonConverterDate.convertTimeToString: unitId not managed');
        }

        return NbsCommonConverterTimeSpan.jsonToString(jsonFormatDiff);
    }

}


export class NbsCommonConverterGrid {

    static formatBoolean = function (value, translator) {
        if (value !== true && value !== false) {
            throw new Error('formatBoolean value error');
        }

        if (value) {
            return '<input type="checkbox" disabled="disabled" nbs_checkbox_export_attribute value="' + translator('Yes') + '" checked="checked">';
        } else {
            return '<input type="checkbox" disabled="disabled" nbs_checkbox_export_attribute value="' + translator('No') + '">';
        }
    }

    static formatDecimalStyle = function (args) {
        // @ts-ignore
        args.cell.style.textAlign = ej.TextAlign.Left;
    }

}

export class NbsCommonConverterNumber {

    static formatNumber = function (source, locale, decimalDigits?) {
        if (decimalDigits === null || decimalDigits === undefined) {
            decimalDigits = 2;
        }
        // @ts-ignore
        return accounting.formatNumber(source, decimalDigits, locale.NUMBER_FORMATS.GROUP_SEP, locale.NUMBER_FORMATS.DECIMAL_SEP);
    }

    static formatDecimal = function (number, locale) {
        if (Object.prototype.toString.call(number) === "[object Number]") {
            return NbsCommonConverterNumber.formatNumber(number, locale);
        } else {
            var n = number.replace(',', '.');
            return NbsCommonConverterNumber.formatNumber(n, locale);
        }
    }

    static formatDecimalPercent = function (number, locale) {
        return NbsCommonConverterNumber.formatDecimal(number, locale) + ' %';
    }

}

export class NbsCommonConverter {

    static timespan = {};
    static date = {};
    static number = {};
    static grid = {};


    static decodeHtml = function (html) {
        if (!html) {
            return '';
        }
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }

    //htmldecode+json.parse for fields serializaed with json
    static JsonFieldParse = function (sourceTxt) {
        if (!sourceTxt) {
            return;
        }
        return JSON.parse(NbsCommonConverter.decodeHtml(sourceTxt));

    }

    static streamToDownload = function (filename, content, contentType) {
        if (!contentType) contentType = 'application/octet-stream';
        var a = document.createElement('a');
        var blob = new Blob([content], { 'type': contentType });
        invokeSaveAsDialog(blob, filename);
        //a.href = window.URL.createObjectURL(blob);
        //a.download = filename;
        //a.click();

        /**
         * @param {Blob} file - File or Blob object. This parameter is required.
         * @param {string} fileName - Optional file name e.g. "image.png"
         */
        function invokeSaveAsDialog(file, fileName) {
            if (!file) {
                throw 'Blob object is required.';
            }

            if (!file.type) {
                file.type = 'video/webm';
            }

            var fileExtension = file.type.split('/')[1];

            if (fileName && fileName.indexOf('.') !== -1) {
                var splitted = fileName.split('.');
                fileName = splitted[0];
                fileExtension = splitted[1];
            }

            var fileFullName = (fileName || (Math.round(Math.random() * 9999999999) + 888888888)) + '.' + fileExtension;

            // @ts-ignore            
            if (typeof navigator.msSaveOrOpenBlob !== 'undefined') {
                // @ts-ignore
                return navigator.msSaveOrOpenBlob(file, fileFullName);
                // @ts-ignore
            } else if (typeof navigator.msSaveBlob !== 'undefined') {
                // @ts-ignore
                return navigator.msSaveBlob(file, fileFullName);
            }

            var hyperlink = document.createElement('a');
            hyperlink.href = URL.createObjectURL(file);
            hyperlink.target = '_blank';
            hyperlink.download = fileFullName;

            // @ts-ignore
            if (!!navigator.mozGetUserMedia) {
                hyperlink.onclick = function () {
                    (document.body || document.documentElement).removeChild(hyperlink);
                };
                (document.body || document.documentElement).appendChild(hyperlink);
            }

            var evt = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true
            });

            hyperlink.dispatchEvent(evt);
            // @ts-ignore
            if (!navigator.mozGetUserMedia) {
                URL.revokeObjectURL(hyperlink.href);
            }
        }
    }

    static stringToDownload = function (filename, text) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

}

export class NbsCommonModal {
    //parentState app.nav.invoiceregisterftc-invoice
    static addChildModalRoute = function (modalRouteParams) {
        var pName = '/{parentParams}';
        var modalkey = modalRouteParams.modalkey ? modalRouteParams.modalkey : 'modal';
        var modalContentState = modalRouteParams.parentState + modalkey;
        modalRouteParams.stateProvider
            .state(modalContentState, modalRouteParams.routeObj.resolve({
                ngUrl: '/' + modalContentState,
                //services: modalRouteParams.services,
                ngParamsName: pName,
                baseName: modalRouteParams.targetBaseName,//'rds/cmsdocs/viewPageElementsList',
                //regionName: 'modalMainRegion@',
                isModalContainer: true,
                parentState: modalRouteParams.parentState,
                viewMode: modalRouteParams.viewMode,
                ignoreDirtyFlag: true //navigation to modal have to ignore dirty flag because page behind (modal opener) is not abandoned
            }));
    }

}

export class NbsCommonReport {
    //outputFormat: 'pdf','html','xml','word'
    ////args { Ds1_Arg1: arg1, Ds1_Arg2:arg2 ... , DsN_ArgM }
    static getReportViewTitle = function (reportDefinition) {
        if (!reportDefinition) {
            return '';
        }

        var title = reportDefinition.ReportDefinitionReferenceCode;
        if (title) {
            title += ' - ' + reportDefinition.ReportDefinitionName;
        } else {
            title = reportDefinition.ReportDefinitionName;
        }

        return title;
    }

    static parseReportRequest = function (reportRequestData, reportDefinition, args, outputFormat) {
        if (!(outputFormat === 'pdf' || outputFormat === 'html' || outputFormat === 'xml' || outputFormat === 'word')) {
            outputFormat === 'pdf';
        }
        reportRequestData.DueDate = Date.now();
        reportRequestData.OutputFormat = outputFormat;
        reportRequestData.ReportDefinitionId = reportDefinition.ReportDefinitionId;
        reportRequestData.ReportDefinitionCode = reportDefinition.ReportDefinitionCode;
        var reportArgKeys = Lazy(args).keys().toArray();
        var filters = {};
        for (let i = 0; i < reportArgKeys.length; i++) {
            filters[reportArgKeys[i]] = JSON.stringify(args[reportArgKeys[i]]);
        }
        //var arg1 = JSON.stringify({ InvoiceId: currentInvoiceId });
        //requestData.Filters = { Ds1_Arg1: arg1 };
        //requestData.FiltersSerialized = JSON.stringify({ Ds1_Arg1: arg1 });
        //reportRequestData.Filters = filters;
        reportRequestData.FiltersSerialized = JSON.stringify(filters);
        return reportRequestData;
    }
}

export class NbsCommonServer {

    static variables = [];

    static getServerVar = function (varname) {
        // @ts-ignore
        var v = Lazy(NbsCommonServer.variables).findWhere({ VariableCode: varname });
        if (v) {
            return v.VariableValue;
        }
        return '';
    }
    static getServerVarBool = function (varname) {
        if (NbsCommonServer.getServerVar(varname) === 'true') {
            return true;
        }
        return false;
    }

    static getServerVarFull = function (varname) {
        return Lazy(NbsCommonServer.variables).findWhere({ VariableCode: varname });
    }

    static parseSsvWeightValueList = function (mode, translator) {
        // mode => 'value' or 'weight'
        var result = [];

        var ssv_var = null;
        if (mode === 'value') {
            ssv_var = NbsCommonServer.getServerVarFull('ssv_audit_survey_optionvalue_range_rule');
        } else if (mode === 'weight') {
            ssv_var = NbsCommonServer.getServerVarFull('ssv_audit_survey_optionweight_range_rule');
        } else {
            throw new Error('NbsCommonServer.parseSsvWeightValueList: mode not implemented');
            return result;
        }

        if (!ssv_var) {
            return result;
        }

        Lazy(ssv_var.DefinedValues.split(','))
            .each((item) => {
                // item => e.g. 'optionvalue_1_2,optionvalue_1_3,optionvalue_1_4,optionvalue_1_5,optionvalue_1_10,optionvalue_1_20'
                //              'optionweight_1_2,optionweight_1_3,optionweight_1_4,optionweight_1_5,optionweight_1_10,optionweight_1_20'
                var key = item;
                var name = translator('ssv_' + key + '_selection');
                var minKey = Number(key.split('_')[1]);
                var maxKey = Number(key.split('_')[2]);
                var data = getWeightValueData(minKey, maxKey);

                var resultItem =
                {
                    key: key,
                    isDefaultSsv: key === ssv_var.VariableValue,
                    value: JSON.stringify([minKey, maxKey]),
                    minValue: minKey,
                    maxValue: maxKey,
                    name: name,
                    data: data
                };

                result.push(resultItem);
            });

        function getWeightValueData(min, max) {
            if ((min === null || min == undefined)
                || (max === null || max == undefined)
                || isNaN(min)
                || isNaN(max)
                || min > max) {

                throw new Error('getDropDownsData: min or max error');
                return;
            }

            var result = [];

            if (min === max) {
                result.push({ value: min, name: min });
                return result;
            }

            for (var i = min; i <= max; i++) {
                result.push({ value: i, name: i });
            }

            return result;
        }

        return result;
    }

}

export class NbsCommonUtil {

    static height: string;
    static width: string;

    static convertImgToDataURLviaCanvas = function (url, callback, outputFormat) {
        var img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = function () {
            var canvas: any = document.createElement('CANVAS');
            var ctx = canvas.getContext('2d');
            var dataURL;
            canvas.height = NbsCommonUtil.height;
            canvas.width = NbsCommonUtil.width;
            ctx.drawImage(this, 0, 0);
            dataURL = canvas.toDataURL(outputFormat);
            callback(dataURL);
            canvas = null;
        };
        img.src = url;
    }

    static getReportHtmlToDocHeaderByVirtualCompany = function (virtualCompany, reportDef, qq, contextService, fileTransferService) {
        var result: any = {};
        var defer = qq.defer();

        if (reportDef.PrintHeaderRowText) {
            result.reportHeaderDescription = virtualCompany.VirtualCompanyReportHeader;
            result.reportHeaderRowText1 = virtualCompany.VirtualCompanyName;
            result.reportHeaderRowText2 = "Data di report: " + NbsCommonConverterDate.formatDateTime(moment().toDate(), contextService.virtualContext.cultureInfo, false);
            result.reportHeaderRowText3 = "Codice report: " + reportDef.ReportDefinitionReferenceCode;
        }

        result.srcHeaderImage = null;

        if (virtualCompany && virtualCompany.VirtualCompanyImageId) {
            var myUri = fileTransferService.uriByGuid({ entityGuid: virtualCompany.VirtualCompanyImageId });

            fileTransferService.downloadRemoteFileHiddenByGuid({ entityGuid: virtualCompany.VirtualCompanyImageId })
                .then(function (res) {
                    NbsCommonUtil.convertImgToDataURLviaCanvas(myUri, function (r) {
                        result.srcHeaderImage = r;
                        defer.resolve(result);
                    }, null);
                });
        } else {
            defer.resolve(result);
        }

        return defer.promise;
    }

    static getReportHtmlToDocHeaderByCompany = function (company, reportDef, qq, contextService, fileTransferService) {
        var result: any = {};
        var defer = qq.defer();

        result.reportHeaderDescription = '';
        result.reportHeaderRowText1 = '';
        result.reportHeaderRowText2 = "Data di report: " + NbsCommonConverterDate.formatDateTime(moment().toDate(), contextService.virtualContext.cultureInfo, false);
        result.reportHeaderRowText3 = "Codice report: " + reportDef.ReportDefinitionReferenceCode;
        result.srcHeaderImage = null;

        if (company && company.CompanyImageId) {
            var myUri = fileTransferService.uriByGuid({ entityGuid: company.CompanyImageId });

            fileTransferService.downloadRemoteFileHiddenByGuid({ entityGuid: company.CompanyImageId })
                .then(function (res) {
                    NbsCommonUtil.convertImgToDataURLviaCanvas(myUri, function (r) {
                        result.srcHeaderImage = r;
                        defer.resolve(result);
                    }, null);
                });
        } else {
            defer.resolve(result);
        }

        return defer.promise;
    }

    static getRangeString = function (contextService, dateFrom, dateTo) {
        var periodString = '';

        if (dateFrom || dateTo) {
            if (dateFrom && !dateTo) {
                periodString += ' >= ' + NbsCommonConverterDate.formatDate(dateFrom, contextService.virtualContext.cultureInfo, true);
            } else if (dateTo && !dateFrom) {
                periodString += ' <= ' + NbsCommonConverterDate.formatDate(dateTo, contextService.virtualContext.cultureInfo, true);
            } else {
                periodString += NbsCommonConverterDate.formatDate(dateFrom, contextService.virtualContext.cultureInfo, true);
                periodString += ' - ';
                periodString += NbsCommonConverterDate.formatDate(dateTo, contextService.virtualContext.cultureInfo, true);
            }
        }

        return periodString;
    }

    static addDisplayNameToList = function (list, sPattern, propsToReplace, propUI) {
        if (!sPattern) {
            return list;
        }

        var res: any;
        // @ts-ignore
        Lazy(list).each((item) => {
            var tmpPattern = sPattern;
            // @ts-ignore
            Lazy(propsToReplace).each((property) => {
                res = tmpPattern.replace('@@' + property, item[property]);
                tmpPattern = res;
            });

            item[propUI] = res;
        });

        return list;
    }

    static addDisplayNameToM2MUsersList = function (users) {
        return NbsCommonUtil.addDisplayNameToList(users, '@@AppUserLastName @@AppUserFirstName', ['AppUserLastName', 'AppUserFirstName'], 'DisplayNameUI');
    }

    static addDisplayNameToUsersList = function (users) {
        return NbsCommonUtil.addDisplayNameToList(users, '@@LastName @@FirstName', ['LastName', 'FirstName'], 'DisplayNameUI');
    }

    static addDisplayNameToManagementContractsList = function (managementContracts) {
        return NbsCommonUtil.addDisplayNameToList(managementContracts, '@@ManagementContractName (@@ManagementContractCode)', ['ManagementContractName', 'ManagementContractCode'], 'ManagementContractNameUI');
    }

    static addDisplayNameToNotificationDefinitionsList = function (notificationDefinitions) {
        return NbsCommonUtil.addDisplayNameToList(notificationDefinitions, '@@NotificationDefinitionId - @@NotificationDefinitionName', ['NotificationDefinitionId', 'NotificationDefinitionName'], 'NotificationDefinitionNameUI');
    }

    static addDisplayNameToNotificationInstancesList = function (notificationDefinitions) {
        return NbsCommonUtil.addDisplayNameToList(notificationDefinitions, '@@NotificationInstanceCode - @@NotificationInstanceName', ['NotificationInstanceCode', 'NotificationInstanceName'], 'NotificationInstanceNameUI');
    }

    static addDisplayNameToTriggerInstancesList = function (triggerInstances) {
        return NbsCommonUtil.addDisplayNameToList(triggerInstances, '@@TriggerInstanceCode - @@TriggerInstanceName', ['TriggerInstanceCode', 'TriggerInstanceName'], 'TriggerInstanceNameUI');
    }

    static copyTextToClipboard(text) {
        // copy message text to clipboard
        var tmpTextarea = document.createElement("textarea");
        document.body.appendChild(tmpTextarea);
        tmpTextarea.value = text; //set textarea text
        tmpTextarea.select(); //select textarea text
        document.execCommand("copy");
        document.body.removeChild(tmpTextarea);
    }

    static getSelectableYears() {
        // @ts-ignore
        var currentYear = moment().year();
        var startYear = currentYear - 8;
        var endYear = currentYear + 5;
        var result = [];
        for (var year = startYear; year <= endYear; year++) {
            result.push({ value: year, name: year });
        }
        return result;
    }

    static getPercent(currentNumber, totNumber, decimals) {
        if (currentNumber === null || currentNumber === undefined || isNaN(currentNumber)) {
            currentNumber = 0;
        }

        if (totNumber === null || totNumber === undefined || isNaN(totNumber)) {
            totNumber = 0;
        }

        var result = totNumber > 0 ? currentNumber * 100 / totNumber : 0;
        if (decimals !== null && decimals !== undefined && !isNaN(decimals)) {
            return Number(result.toFixed(4));
        } else {
            return result;
        }
    }

    static getArithmeticResult(listValues, arithmeticCode) {
        // listValues => array of int value 
        // arithmeticId => type of calculation
        var result = 0;

        //if (Lazy(listValues).some()) {
        //    switch (arithmeticCode) {
        //        case 'mid':
        //            result = Lazy(listValues).sum() / Lazy(listValues).length();
        //            break;
        //        case 'max':
        //            result = Lazy(listValues).max();
        //            break;
        //        case 'min':
        //            result = Lazy(listValues).min();
        //            break;
        //        case 'sum':
        //            result = Lazy(listValues).sum();
        //            break;
        //    }
        //}

        return result;
    }

    static getReportTimelineRangeDate(month, year, isFirstPart) {
        var from = null;
        var to = null;

        if (isFirstPart === true) {
            // 1...15 current month
            from = new Date(year, month, 1, 0, 0, 0);
            to = new Date(year, month, 15, 0, 0, 0);
        } else if (isFirstPart === false) {
            // 16...end current month
            from = new Date(year, month, 16, 0, 0, 0);
            to = new Date(year, month + 1, 0, 0, 0, 0);
        } else if (isFirstPart === null || isFirstPart === undefined) {
            // all year
            from = new Date(year, 0, 1, 0, 0, 0);
            to = new Date(year, 11, 31, 0, 0, 0);
        }

        return {
            // @ts-ignore
            from: NbsCommonConverterDate.localAsUtc(from),
            // @ts-ignore
            to: NbsCommonConverterDate.localAsUtc(to)
        };
    }

    static parseEnum(enumerator, entityKey, translator) {
        var result = {
            list: {
                normal: [],
                translated: []
            },
            obj: {
                normal: { value_name: {}, name_value: {} },
                translated: { value_name: {}, name_value: {} }
            }
        };

        if (!enumerator || enumerator === '[]') {
            return result;
        }

        var items = [];
        if (!Array.isArray(enumerator)) {
            items.push({ value: Number(enumerator.value), name: enumerator.name });
        } else {
            // @ts-ignore
            items = Lazy(enumerator).toArray();
        }

        // @ts-ignore
        Lazy(items)
            .each((e) => {
                var item: any = { value: Number(e.value), name: e.name };

                if (translator) {
                    item.translation = entityKey ? translator(entityKey + '.' + e.name) : translator(e.name);
                }
                else {
                    item.translation = entityKey ? entityKey + '.' + e.name : e.name;
                }

                result.obj.normal.value_name[item.value] = item.name;
                result.obj.normal.name_value[item.name] = item.value;

                result.obj.translated.value_name[item.value] = item.translation;
                result.obj.translated.name_value[item.translation] = item.value;

                result.list.normal.push({ value: item.value, name: item.name });
                result.list.translated.push({ value: item.value, name: item.translation });
            });

        return result;
    }

    static getJoinedArrayValues(arrayList) {
        var result = null;

        //Lazy(arrayList)
        //    .each((array) => {
        //        if (array && Lazy(array).some()) {
        //            result = Lazy(result)
        //                .union(Lazy(array))
        //                .uniq()
        //                .toArray();
        //        }
        //    });

        return result;
    }

    static getCategoryTextPath = function (categoryId, categoriesTextPaths) {
        var categoryTextPathDto = Lazy(categoriesTextPaths).findWhere({ CategoryId: categoryId });
        if (categoryTextPathDto) {
            return categoryTextPathDto.CategoryTextPath;
        } else {
            return null;
        }
    }

    static addCategoryTextPath = function (categories, categoriesTextPaths) {
        Lazy(categories)
            .each((category) => {
                category.CategoryTextPath = NbsCommonUtil.getCategoryTextPath(category.CategoryId, categoriesTextPaths);
            });
    }

    static parseCategoryDtoForMultiSelectNew = function (categories, categoryFilter) {
        var categoryKinds = { folder: 1, category: 2 };
        if (!categoryFilter) {
            categoryFilter = { CategoryKindId: categoryKinds.category };
        }

        return Lazy(categories)
            .where(categoryFilter)
            .map((c) => {
                return {
                    id: c.CategoryId,
                    code: c.CategoryCode,
                    name: c.CategoryTextPath
                };
            }).toArray();
    }

}

export class NbsCommonComponentBarDataRange {

    static getStartDate = function () {
        var now = new Date();
        var startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        return startDate;
    }

    static getTimelineDatarange = function (key, asUtc?) {
        var result: any = {};
        var startDate = null;
        var range = getTimeBydataRange(key, startDate);

        result.dataRange = key;
        result.mode = 'dataRange';
        result.dateFrom = range.dateFrom;
        result.dateTo = range.dateTo;

        if (asUtc === null || asUtc === undefined) {
            asUtc = true;
        }

        if (asUtc === true) {
            result.dateFrom = NbsCommonConverterDate.localAsUtc(result.dateFrom);
            result.dateTo = NbsCommonConverterDate.localAsUtc(result.dateTo);
        }

        //d+-n => now +/- n days e.g. d+8 d-3 etc
        //FD+-n => n+1 => tomorrow
        //w+-n => now +/- n weeks e.g. w+8 w-3 etc
        //M+-n => now +/- n months e.g. M+8 M-3 etc
        //FM+-n => first day of current month +/- n months e.g. FM+8 FM-3 etc
        function getTimeBydataRange(dataRangeKey, startDate) {
            if (!dataRangeKey) {
                return {
                    dateFrom: null,
                    dateTo: null
                };
            }

            if (!startDate) {
                startDate = NbsCommonComponentBarDataRange.getStartDate();
            }

            var numOfUnits;
            var units;
            if (dataRangeKey.indexOf('+') > 0) {
                numOfUnits = Number(dataRangeKey.split('+')[1]);
                units = dataRangeKey.split('+')[0];
            }
            else if (dataRangeKey.indexOf('-') > 0) {
                numOfUnits = -Number(dataRangeKey.split('-')[1]);
                units = dataRangeKey.split('-')[0];
            }
            else {
                units = 'd';
                numOfUnits = 0;
            }

            var dateFrom = null;
            var dateTo = null;

            if (units === 'd') {
                if (numOfUnits >= 0) { // d+0 => Today
                    dateFrom = moment(startDate).startOf('day').toDate();
                    dateTo = moment(startDate).add(numOfUnits, 'days').startOf('day').toDate();
                } else {
                    dateFrom = moment(startDate).add(numOfUnits, 'days').startOf('day').toDate();
                    dateTo = moment(startDate).startOf('day').toDate();
                }
            } else if (units === 'FD') {
                dateFrom = moment(startDate).add(numOfUnits, 'days').startOf('day').toDate();
                dateTo = moment(startDate).add(numOfUnits, 'days').toDate();
            }
            else if (units === 'Fw') {
                // from Mon to Sun
                dateFrom = moment(startDate).add(numOfUnits, 'weeks').startOf('isoWeek').toDate();
                dateTo = moment(dateFrom).add(1, 'weeks').toDate();
                dateTo = moment(dateTo).add(-1, 'days').toDate(); // remove last day
            }
            else if (units === 'FM') {
                dateFrom = moment(startDate).add(numOfUnits, 'months').startOf('month').toDate();
                dateTo = moment(dateFrom).add(1, 'months').toDate();
                dateTo = moment(dateTo).add(-1, 'days').toDate(); // remove last day
            }
            else if (units === 'FY') {
                dateFrom = moment(startDate).add(numOfUnits, 'years').startOf('year').toDate();
                dateTo = moment(dateFrom).add(1, 'years').toDate();
                dateTo = moment(dateTo).add(-1, 'days').toDate(); // remove last day
            }

            return {
                dateFrom: dateFrom,
                dateTo: dateTo
            };
        };

        return result;
    }

    static getDataRangeFromUiDataParam = function (uiDataParam) {
        if (uiDataParam.mode === 'dataRange') {
            return {
                dateFrom: NbsCommonComponentBarDataRange.getTimelineDatarange(uiDataParam.dataRange).dateFrom,
                dateTo: NbsCommonComponentBarDataRange.getTimelineDatarange(uiDataParam.dataRange).dateTo
            }
        } else if (uiDataParam.mode === 'customDate') {
            return {
                dateFrom: uiDataParam.dateFrom,
                dateTo: uiDataParam.dateTo
            }
        }

        return null;
    }

}

export class NbsCommonComponent {
    static assignee = {};

    static getStatus = function (filterCode, filterList) {
        var componentStatus = {
            isVisible: false,
            isReadonly: true
        };
        if (Lazy(filterList).some() && filterCode) {
            var componentFilter = Lazy(filterList).findWhere({ code: filterCode });
            if (componentFilter) {
                componentStatus.isVisible = componentFilter.canHide ? componentFilter.visible : true;
                componentStatus.isReadonly = componentFilter.readonly;
            }
        }

        return componentStatus;
    }

    static getDataContextCommonGridBar = function (uiData) {
        return {
            sorting: {
                columns: uiData.sorting.columns,
                selected: uiData.sorting.selected,
                direction: uiData.sorting.direction
            },
            grouping: {
                columns: uiData.grouping.columns,
                selected: uiData.grouping.selected
            },
            columns: {
                columns: uiData.columns.columns,
                selected: uiData.columns.selected
            },
            filters: {
                freeText: uiData.filters.freeText,
                filterList: uiData.filterList
            },

            // default data
            startData: uiData.startData
        };
    }

    static getDataContextViewTitle = function (uiData) {
        return {
            filters: uiData.filters,
            columns: uiData.columns,
            grouping: uiData.grouping,
            sorting: uiData.sorting,
            filterList: uiData.filterList,
            startData: uiData.startData //{filters,columns,grouping,sorting}
        };
    }

    static CleanAllFilterPropertiesEnum = function (filter) {
        Lazy(Object.keys(filter))
            .each((pName) => {
                // key: the name of the object key
                // index: the ordinal position of the key within the object 
                if (filter[pName] instanceof Array && filter[pName].length === 0) {
                    delete filter[pName];
                }

                if (filter[pName] === null || filter[pName] === undefined) {
                    delete filter[pName];
                }
            });
    }

}

export class NbsCommonAppModel {

    static cmsDataCompleteCodeObject: any;
    static cmsDataShortCodeObject: any;

}

export class NbsCommonCms {

    static cmsViewData = function (cmsViewCode) {
        if (!cmsViewCode || !NbsCommonAppModel.cmsDataCompleteCodeObject) {
            return null;
        }

        return NbsCommonAppModel.cmsDataShortCodeObject[cmsViewCode];
    }

    static cmsGlobalData = function () {
        if (NbsCommonLibrary.cms_cmsDataGlobal) {
            return NbsCommonLibrary.cms_cmsDataGlobal;
        }

        NbsCommonLibrary.cms_cmsDataGlobal = NbsCommonAppModel.cmsDataShortCodeObject['Global'];
        return NbsCommonLibrary.cms_cmsDataGlobal;
    }

    //used for n labels in same view
    static translateCmsViewLabelData = function (cmsViewData, key) {
        if (!NbsCommonAppModel) {
            return key;
        }

        if (Object.keys(NbsCommonAppModel.cmsDataCompleteCodeObject).length <= 0) {
            return key;
        }

        if (cmsViewData && cmsViewData.CmsViewThesaurus && cmsViewData.CmsViewThesaurus[key]) {
            return cmsViewData.CmsViewThesaurus[key];
        }

        var globalData = NbsCommonCms.cmsGlobalData();
        if (globalData && globalData.CmsViewThesaurus && globalData.CmsViewThesaurus[key]) {
            return globalData.CmsViewThesaurus[key];
        }
        return key;
    }

    static viewTitle = function (cmsViewCode) {
        var viewData = NbsCommonCms.cmsViewData(cmsViewCode);
        if (viewData && viewData.ViewTitle) {
            return NbsCommonCms.translateCmsViewLabelData(viewData, viewData.ViewTitle);
        }
        return '';
    }

    static parseCmsViewCode = function (viewCode) {
        while (viewCode.indexOf('.') > 0) {
            viewCode = viewCode.substring(viewCode.indexOf('.') + 1, viewCode.length);
        }
        return viewCode;
    }

    static setViewTitleByCode = function (cmsViewCode) {
        document.title = NbsCommonCms.viewTitle(cmsViewCode);
    }

    static setViewTitle = function (title) {
        document.title = title;
    }

    static translate = function (cmsViewCode, key, entityName) {
        if (!NbsCommonAppModel.cmsDataCompleteCodeObject) {
            return key;
        }

        if (NbsCommonAppModel.cmsDataCompleteCodeObject && Object.keys(NbsCommonAppModel.cmsDataCompleteCodeObject).length <= 0) {
            return key;
        }

        var globalData = NbsCommonCms.cmsGlobalData();
        if (key && key.length > 3 && key.substring(0, 3) === 'bz-') {
            //validation fields cache
            if (NbsCommonLibrary.cms_translateValidation[key]) {
                return NbsCommonLibrary.cms_translateValidation[key];
            }
            if (globalData && globalData.CmsViewThesaurus && globalData.CmsViewThesaurus[key]) {
                var translate = globalData.CmsViewThesaurus[key];
                if (translate) {
                    NbsCommonLibrary.cms_translateValidation[key] = translate;
                    return translate;
                }
            }
        }
        key = entityName ? entityName + '.' + key : key;

        var cmsViewdata = NbsCommonAppModel.cmsDataShortCodeObject[cmsViewCode];
        if (cmsViewdata && cmsViewdata.CmsViewThesaurus && cmsViewdata.CmsViewThesaurus[key]) {
            return cmsViewdata.CmsViewThesaurus[key];
        }

        globalData = NbsCommonCms.cmsGlobalData();
        if (globalData && globalData.CmsViewThesaurus && globalData.CmsViewThesaurus[key]) {
            return globalData.CmsViewThesaurus[key];
        }
        return key;
    }

    //get translate from global thesaurus only
    static globalTranslate = function (key) {
        if (!NbsCommonAppModel) {
            return key;
        }

        if (NbsCommonAppModel == null || NbsCommonAppModel.cmsDataCompleteCodeObject == null) {
            return key;
        }
        if (Object.keys(NbsCommonAppModel.cmsDataCompleteCodeObject).length <= 0) {
            return key;
        }
        //
        var globalData = NbsCommonCms.cmsGlobalData();
        if (globalData && globalData.CmsViewThesaurus && globalData.CmsViewThesaurus[key]) {
            return globalData.CmsViewThesaurus[key];
        }
        return key;
    }

    static publicTranslateLanguage = function (key) {
        var language = navigator.language;
        if (!language || language !== 'it-IT') {
            language = 'en-US';
        }
        return NbsCommonCms.publicTranslate(key, language);
    }

    static publicTranslate = function (key, language) {
        //TODO MAYBE REMOVE COMMENTS
        //var publicThesaurus = window.cmsPublicData[language];
        //if (!publicThesaurus) {
        //    return key;
        //}
        ////
        //if (publicThesaurus[key]) {
        //    return publicThesaurus[key]['WordValue'];
        //}
        return key;
    }

    static globalTranslateStartWith = function (key) {
        if (!NbsCommonAppModel) {
            return key;
        }

        if (Object.keys(NbsCommonAppModel.cmsDataCompleteCodeObject).length <= 0) {
            return key;
        }
        //
        var globalData = NbsCommonCms.cmsGlobalData();

        if (globalData && globalData.CmsViewThesaurus) {
            // @ts-ignore
            return Lazy(Object.keys(globalData.CmsViewThesaurus)).filter((e) => { return e.startsWith(key); }).toArray();
        }
        return [];
    }


    static getWordKeyFromThesaurus = function (cmsThesaurusDb, key, languageId) {
        // if there are more than one records, with findWhere were returned the first
        var cmsThesaurus = null;
        if (languageId) {
            cmsThesaurus = Lazy(cmsThesaurusDb).findWhere({ WordKey: key, LanguageId: languageId });
        } else {
            cmsThesaurus = Lazy(cmsThesaurusDb).findWhere({ WordKey: key });
        }

        return cmsThesaurus ? cmsThesaurus.WordValue : key;
    }

    static setTheme = function (theme) {
        if (!theme
            || theme === '') {
            return;
        }

        for (let i = 0, link_tag = document.getElementsByTagName("link"); i < link_tag.length; i++) {
            if (link_tag[i].rel.indexOf("stylesheet") !== -1
                && link_tag[i].href.indexOf("nembus/themes/") !== -1) {
                var start = link_tag[i].href.indexOf("/themes/");
                var end = link_tag[i].href.indexOf(".css", start + 1);
                var token = link_tag[i].href.substring(start + 8, end);
                link_tag[i].href = link_tag[i].href.replace(token, theme);
            }
            //set_cookie(style_cookie_name, css_title,
            //  style_cookie_duration);
        }
    }

}

export class NbsCommonParser {
    //Parser cron expression from/to object
    static Cron = function (translator) {
        var days = [
            { value: 'SUN', name: translator('Sunday') },
            { value: 'MON', name: translator('Monday') },
            { value: 'TUE', name: translator('Tuesday') },
            { value: 'WED', name: translator('Wednesday') },
            { value: 'THU', name: translator('Thursday') },
            { value: 'FRI', name: translator('Friday') },
            { value: 'SAT', name: translator('Saturday') }
        ];
        var months = [
            { value: 1, name: translator('January') },
            { value: 2, name: translator('February') },
            { value: 3, name: translator('March') },
            { value: 4, name: translator('April') },
            { value: 5, name: translator('May') },
            { value: 6, name: translator('June') },
            { value: 7, name: translator('July') },
            { value: 8, name: translator('August') },
            { value: 9, name: translator('September') },
            { value: 10, name: translator('October') },
            { value: 11, name: translator('November') },
            { value: 12, name: translator('December') }
        ];
        var weekNumberList = [
            { value: 1, name: translator('CronFirst') },
            { value: 2, name: translator('CronSecond') },
            { value: 3, name: translator('CronThird') },
            { value: 4, name: translator('CronFourth') }
        ];
        var dayNumberList = [];
        for (var i = 1; i < 32; i++) { dayNumberList.push({ value: i, name: i }); }


        var CronObject = function () {

            return {
                IsDaily: false,
                Daily_NumberOfDays: 0,

                // weekly section
                IsWeekly: false,
                Weekly_NumberOfWeeks: 0,
                Weekly_DayCode: null,

                // monthly section
                IsMonthly: false,

                // bool for checkbox (must start with 'IsEvery'
                IsEveryMonth: false,
                IsEveryDayMonth: false,
                IsEveryDayEveryMonth: false,
                IsEveryWeekNumberDayMonth: false,
                IsEveryWeekNumberDayEveryMonth: false,


                Monthly_EveryMonth_NumberOfMonths: 0,
                Monthly_EveryDayMonth_DayNumber: 0,
                Monthly_EveryDayMonth_MonthNumber: 0,
                Monthly_EveryDayEveryMonth_DayNumber: 0,
                Monthly_EveryDayEveryMonth_NumberOfMonths: 0,
                Monthly_EveryWeekNumberDayMonth_WeekNumber: 0,
                Monthly_EveryWeekNumberDayMonth_DayCode: null,
                Monthly_EveryWeekNumberDayMonth_MonthNumber: 0,
                Monthly_EveryWeekNumberDayEveryMonth_WeekNumber: 0,
                Monthly_EveryWeekNumberDayEveryMonth_DayCode: null,
                Monthly_EveryWeekNumberDayEveryMonth_NumberOfMonths: 0,

                // data 
                DayList: days,
                MonthList: months,
                DayNumberList: dayNumberList,
                WeekNumberList: weekNumberList,

                //Missing
                TabIndexSelected: 0,
                OnTabSelect: function (indexTab) { },

                getDayName: function (dayCode) {
                    var day = Lazy(days).findWhere({ value: dayCode });
                    if (day) {
                        return day.name;
                    } else {
                        return '';
                    }
                },
                getWeekNumberName: function (weekNumber) {
                    var week = Lazy(weekNumberList).findWhere({ value: Number(weekNumber) });
                    if (week) {
                        return week.name;
                    } else {
                        return '';
                    }
                },
                getMonthName: function (monthNumber) {
                    var month = Lazy(months).findWhere({ value: Number(monthNumber) });
                    if (month) {
                        return month.name;
                    } else {
                        return '';
                    }
                },

                // functions
                getCronLabel: function () {
                    var label = null;
                    var labelPattern = null;

                    if (this.IsDaily) {
                        // Daily section
                        // 0 0 0 1/9 * ? * -> ogni 9 giorni
                        // CronLabelPatternDaily: Ogni [NumberOfDays] giorno(i)
                        labelPattern = translator('CronLabelPatternDaily');
                        label = labelPattern.replaceAll('[NumberOfDays]', this.Daily_NumberOfDays.toString());
                    } else if (this.IsWeekly) {
                        // Weekly section
                        // 0 0 0 ? * FRI/3 *  -> ogni venerdì ogni tre settimane
                        // CronLabelPatternWeekly: Ogni [NumberOfWeeks] [NameOfDay]
                        labelPattern = translator('CronLabelPatternWeekly');
                        label = labelPattern
                            .replaceAll('[NumberOfWeeks]', this.Weekly_NumberOfWeeks.toString())
                            .replaceAll('[NameOfDay]', this.getDayName(this.Weekly_DayCode));
                    } else if (this.IsMonthly) {
                        // Monthly section
                        if (this.IsEveryMonth) {
                            // 0 0 0 ? 1/24 ? * -> ogni 24 mesi
                            // CronLabelPatternMonthlyEveryMonth: "Ogni [NumberOfMonths] mese(i)
                            labelPattern = translator('CronLabelPatternMonthlyEveryMonth');
                            label = labelPattern.replace('[NumberOfMonths]', this.Monthly_EveryMonth_NumberOfMonths.toString());

                        } else if (this.IsEveryDayMonth) {
                            // 0 0 0 16 4 ? * -> ogni 16 aprile
                            // CronLabelPatternMonthlyEveryDayMonth: Ogni giorno [DayNumber] del mese di [MonthName]
                            labelPattern = translator('CronLabelPatternMonthlyEveryDayMonth');
                            label = labelPattern
                                .replaceAll('[DayNumber]', this.Monthly_EveryDayMonth_DayNumber.toString())
                                .replaceAll('[MonthName]', this.getMonthName(this.Monthly_EveryDayMonth_MonthNumber));

                        } else if (this.IsEveryDayEveryMonth) {
                            // 0 0 0 8 1/3 ? * -> ogni giorno 8 ogni 3 mesi
                            // CronLabelPatternMonthlyEveryDayEveryMonth: Ogni giorno [DayNumber] ogni [NumberOfMonths] mese(i)
                            labelPattern = translator('CronLabelPatternMonthlyEveryDayEveryMonth');
                            label = labelPattern
                                .replaceAll('[DayNumber]', this.Monthly_EveryDayEveryMonth_DayNumber.toString())
                                .replaceAll('[NumberOfMonths]', this.Monthly_EveryDayEveryMonth_NumberOfMonths);

                        } else if (this.IsEveryWeekNumberDayMonth) {
                            // 0 0 0 ? 6 FRI#2 * -> ogni secondo venerdì di giugno
                            // CronLabelPatternMonthlyEveryWeekNumberDayMonth: Ogni [WeekNumberName] [DayName] del mese di [MonthName]
                            labelPattern = translator('CronLabelPatternMonthlyEveryWeekNumberDayMonth');
                            label = labelPattern
                                .replaceAll('[WeekNumberName]', this.getWeekNumberName(this.Monthly_EveryWeekNumberDayMonth_WeekNumber))
                                .replaceAll('[DayName]', this.getDayName(this.Monthly_EveryWeekNumberDayMonth_DayCode))
                                .replaceAll('[MonthName]', this.getMonthName(this.Monthly_EveryWeekNumberDayMonth_MonthNumber));

                        } else if (this.IsEveryWeekNumberDayEveryMonth) {
                            // 0 0 0 ? 1/6 FRI#2 * -> ogni secondo venerdì ogni 6 mesi
                            // CronLabelPatternMonthlyIsEveryWeekNumberDayEveryMonth: Ogni [WeekNumberName] [DayName] ogni [NumberOfMonths] mese(i)
                            labelPattern = translator('CronLabelPatternMonthlyIsEveryWeekNumberDayEveryMonth');
                            label = labelPattern
                                .replaceAll('[WeekNumberName]', this.getWeekNumberName(this.Monthly_EveryWeekNumberDayEveryMonth_WeekNumber))
                                .replaceAll('[DayName]', this.getDayName(this.Monthly_EveryWeekNumberDayEveryMonth_DayCode))
                                .replaceAll('[NumberOfMonths]', this.Monthly_EveryWeekNumberDayEveryMonth_NumberOfMonths);
                        }
                    }

                    if (label) {
                        label = label.toLowerCase().capitalizeFirstLetter();
                    }

                    return label;
                },
                getCronExpression: function () {
                    var sec = '0';
                    var min = '0';
                    var hou = '0';
                    var dOM = '?';
                    var mon = '*';
                    var dOW = '?';
                    var yea = '*';

                    if (this.IsDaily) {
                        // Daily section
                        // 0 0 0 1/9 * ? * -> ogni 9 giorni
                        dOM = '1/' + this.Daily_NumberOfDays;
                    } else if (this.IsWeekly) {
                        // Weekly section
                        // 0 0 0 ? * FRI/3 *  -> ogni venerdì ogni tre settimane
                        dOW = this.Weekly_DayCode + '/' + this.Weekly_NumberOfWeeks;

                    } else if (this.IsMonthly) {
                        // Monthly section
                        if (this.IsEveryMonth) {
                            // 0 0 0 ? 1/24 ? * -> ogni 24 mesi
                            mon = '1/' + this.Monthly_EveryMonth_NumberOfMonths;
                        } else if (this.IsEveryWeekNumberDayMonth) {
                            // 0 0 0 ? 6 FRI#2 * -> ogni secondo venerdì di giugno
                            mon = this.Monthly_EveryWeekNumberDayMonth_MonthNumber;
                            dOW = this.Monthly_EveryWeekNumberDayMonth_DayCode + '#' + this.Monthly_EveryWeekNumberDayMonth_WeekNumber;
                        } else if (this.IsEveryWeekNumberDayEveryMonth) {
                            // 0 0 0 ? 1/6 FRI#2 * -> ogni secondo venerdì ogni 6 mesi
                            mon = '1/' + this.Monthly_EveryWeekNumberDayEveryMonth_NumberOfMonths;
                            dOW = this.Monthly_EveryWeekNumberDayEveryMonth_DayCode + '#' + this.Monthly_EveryWeekNumberDayEveryMonth_WeekNumber;
                        } else if (this.IsEveryDayEveryMonth) {
                            // 0 0 0 8 1/3 ? * -> ogni ottavo giorno del mese ogni 3 mesi
                            dOM = this.Monthly_EveryDayEveryMonth_DayNumber;
                            mon = '1/' + this.Monthly_EveryDayEveryMonth_NumberOfMonths;
                        } else if (this.IsEveryDayMonth) {
                            // 0 0 0 16 4 ? * -> ogni 16 aprile
                            dOM = this.Monthly_EveryDayMonth_DayNumber;
                            mon = this.Monthly_EveryDayMonth_MonthNumber;
                        }
                    }

                    var cronExpressionArray = [sec, min, hou, dOM, mon, dOW, yea];
                    var cronExpression = cronExpressionArray.join(' ');
                    return cronExpression;
                },
                getErrorKey: function () {
                    var errorKey = null;
                    var currentCronExpression = this.getCronExpression();
                    if (currentCronExpression) {
                        if (cronFields(currentCronExpression).length !== 7) {
                            errorKey = 'CronError';
                        }
                    }

                    if (!this.IsDaily && !this.IsWeekly && !this.IsMonthly) {
                        errorKey = 'CronErrorSectionSelection';

                    } else if (this.IsDaily && this.Daily_NumberOfDays <= 0) {
                        errorKey = 'CronErrorInsertData';

                    } else if (this.IsWeekly && (this.Weekly_NumberOfWeeks <= 0 || this.Weekly_DayCode === null || this.Weekly_DayCode === undefined)) {
                        errorKey = 'CronErrorInsertData';

                    } else if (this.IsMonthly) {
                        if (!this.IsEveryMonth && !this.IsEveryDayMonth && !this.IsEveryDayEveryMonth &&
                            !this.IsEveryWeekNumberDayMonth && !this.IsEveryWeekNumberDayEveryMonth) {
                            errorKey = 'CronErrorInsertData';

                        } else if (this.IsEveryMonth && this.Monthly_EveryMonth_NumberOfMonths <= 0) {
                            errorKey = 'CronErrorInsertData';

                        } else if (this.IsEveryDayMonth && (this.Monthly_EveryDayMonth_DayNumber <= 0 || this.Monthly_EveryDayMonth_MonthNumber <= 0)) {
                            errorKey = 'CronErrorInsertData';

                        } else if (this.IsEveryDayEveryMonth && (this.Monthly_EveryDayEveryMonth_DayNumber <= 0 || this.Monthly_EveryDayEveryMonth_NumberOfMonths <= 0)) {
                            errorKey = 'CronErrorInsertData';

                        } else if (this.IsEveryWeekNumberDayMonth &&
                            (this.Monthly_EveryWeekNumberDayMonth_DayCode === null
                                || this.Monthly_EveryWeekNumberDayMonth_DayCode === undefined
                                || this.Monthly_EveryWeekNumberDayMonth_MonthNumber <= 0
                                || this.Monthly_EveryWeekNumberDayMonth_WeekNumber <= 0)) {

                            errorKey = 'CronErrorInsertData';

                        } else if (this.IsEveryWeekNumberDayEveryMonth &&
                            (this.Monthly_EveryWeekNumberDayEveryMonth_DayCode === null
                                || this.Monthly_EveryWeekNumberDayEveryMonth_DayCode === undefined
                                || this.Monthly_EveryWeekNumberDayEveryMonth_NumberOfMonths <= 0
                                || this.Monthly_EveryWeekNumberDayEveryMonth_WeekNumber <= 0)) {

                            errorKey = 'CronErrorInsertData';
                        }
                    }

                    return errorKey;
                },
                hasError: function () {
                    var errorKey = this.getErrorKey();
                    return errorKey ? true : false;
                },
                errorMessage: function () {
                    var errorKey = this.getErrorKey();
                    return errorKey ? translator(errorKey) : false;
                }
            };
        };

        var cronFields = function (cronString) {
            if (cronString) {
                return cronString.split(' ');
            } else {
                return [];
            }
        };
        var dayOfMonth = function (cronString) {
            return cronString ? cronFields(cronString)[3] : '?';
        };
        var month = function (cronString) {
            return cronString ? cronFields(cronString)[4] : '*';
        };
        var dayOfWeek = function (cronString) {
            return cronString ? cronFields(cronString)[5] : '?';
        };
        var isDaily = function (cronString) {
            return dayOfMonth(cronString).indexOf('/') > 0;
        };
        var isWeekly = function (cronString) {
            return month(cronString) === '*' && dayOfWeek(cronString) !== '?';
        };
        var isMonthly = function (cronString) {
            return month(cronString) !== '*';
        };

        //Returns object binded to modal UI for connection string 
        this.getCronObject = function (cronString) {
            var cronObject = CronObject();


            // patch because new version of uib-tabset not binds active property on single tabh
            // but ha index property on tab container
            cronObject.OnTabSelect = function (indexTab) {
                if (indexTab === 0) {
                    cronObject.IsDaily = true;
                    cronObject.IsWeekly = false;
                    cronObject.IsMonthly = false;
                } else if (indexTab === 1) {
                    cronObject.IsDaily = false;
                    cronObject.IsWeekly = true;
                    cronObject.IsMonthly = false;
                } else if (indexTab === 2) {
                    cronObject.IsDaily = false;
                    cronObject.IsWeekly = false;
                    cronObject.IsMonthly = true;
                }
            };

            if (!cronString) {
                cronObject.TabIndexSelected = 0;
                cronObject.IsDaily = true;
                return cronObject;
            }

            cronObject.IsDaily = isDaily(cronString);
            cronObject.IsWeekly = isWeekly(cronString);
            cronObject.IsMonthly = isMonthly(cronString);



            if (cronObject.IsDaily) {
                cronObject.TabIndexSelected = 0;

                // Daily section
                // 0 0 0 1/9 * ? * -> ogni 9 giorni
                cronObject.Daily_NumberOfDays = Number(Lazy(dayOfMonth(cronString).split('/')).last());
            } else if (cronObject.IsWeekly) {
                cronObject.TabIndexSelected = 1;

                // Weekly section
                // 0 0 0 ? * FRI/3 *  -> ogni venerdì ogni tre settimane
                if (dayOfWeek(cronString).indexOf('/') > 0) {
                    cronObject.Weekly_NumberOfWeeks = Number(Lazy(dayOfWeek(cronString).split('/')).last());
                    cronObject.Weekly_DayCode = Lazy(dayOfWeek(cronString).split('/')).first();
                }
            } else if (cronObject.IsMonthly) {
                cronObject.TabIndexSelected = 2;

                // Monthly section
                if (dayOfWeek(cronString).indexOf('#') > 0) {
                    if (month(cronString).indexOf('/') > 0) {
                        // 0 0 0 ? 1/6 FRI#2 * -> ogni secondo venerdì ogni 6 mesi
                        cronObject.IsEveryWeekNumberDayEveryMonth = true;
                        cronObject.Monthly_EveryWeekNumberDayEveryMonth_NumberOfMonths = Number(Lazy(month(cronString).split('/')).last());
                        cronObject.Monthly_EveryWeekNumberDayEveryMonth_WeekNumber = Number(Lazy(dayOfWeek(cronString).split('#')).last());
                        cronObject.Monthly_EveryWeekNumberDayEveryMonth_DayCode = Lazy(dayOfWeek(cronString).split('#')).first();
                    } else {
                        // 0 0 0 ? 6 FRI#2 * -> ogni secondo venerdì di giugno
                        cronObject.IsEveryWeekNumberDayMonth = true;
                        cronObject.Monthly_EveryWeekNumberDayMonth_MonthNumber = Number(month(cronString));
                        cronObject.Monthly_EveryWeekNumberDayMonth_WeekNumber = Number(Lazy(dayOfWeek(cronString).split('#')).last());
                        cronObject.Monthly_EveryWeekNumberDayMonth_DayCode = Lazy(dayOfWeek(cronString).split('#')).first();
                    }
                } else if (month(cronString).indexOf('/') > 0) {
                    if (dayOfMonth(cronString) === '?') {
                        // 0 0 0 ? 1/24 ? * -> ogni 24 mesi
                        cronObject.IsEveryMonth = true;
                        cronObject.Monthly_EveryMonth_NumberOfMonths = Number(Lazy(month(cronString).split('/')).last());
                    } else {
                        // 0 0 0 8 1/3 ? * -> ogni ottavo giorno del mese ogni 3 mesi
                        cronObject.IsEveryDayEveryMonth = true;
                        cronObject.Monthly_EveryDayEveryMonth_DayNumber = Number(dayOfMonth(cronString));
                        cronObject.Monthly_EveryDayEveryMonth_NumberOfMonths = Number(Lazy(month(cronString).split('/')).last());
                    }
                } else {
                    // 0 0 0 16 4 ? *
                    cronObject.IsEveryDayMonth = true;
                    cronObject.Monthly_EveryDayMonth_DayNumber = Number(dayOfMonth(cronString));
                    cronObject.Monthly_EveryDayMonth_MonthNumber = Number(month(cronString));
                }
            }

            return cronObject;
        };
    }

    //Add CronExpressionLabel to list of cron exrpession objects
    static AddCronExpressionLabelToList = function (list, cronExpressionProperty, translator) {
        // @ts-ignore
        Lazy(list)
            .each((item) => {
                var cronParser = new NbsCommonParser.Cron(translator);
                if (item[cronExpressionProperty]) {
                    var cronObject = cronParser.getCronObject(item[cronExpressionProperty]);
                    item[cronExpressionProperty + 'Label'] = cronObject.getCronLabel();
                } else {
                    item[cronExpressionProperty + 'Label'] = null;
                }
            });
    }

    static objFromList = function (arrayList, propertyName, propertyValue) {
        var obj = {};

        if (!propertyName || !propertyValue || !Array.isArray(arrayList)) {
            return obj;
        }
        // @ts-ignore
        Lazy(arrayList)
            .each((item) => {
                obj[item[propertyName]] = item[propertyValue];
            });

        return obj;
    }

    static assetTreeForTreeview = function (assetTrees) {
        // @ts-ignore
        return Lazy(assetTrees)
            .map((item) => {
                return {
                    FieldId: item.VirtualHumanResourceId,
                    FieldParentId: item.ParentId !== null && item.ParentId !== undefined ? item.ParentId : null,
                    FieldCode: item.VirtualCode,
                    FieldText: item.VirtualCode + ' - ' + item.VirtualName,
                    FieldIsExpanded: true
                }
            }).toArray();
    }

}

export class NbsCommonLibrary {

    static cms_cmsDataGlobal = null;
    static cms_translateValidation = {};

    static auth: NbsCommonAuth;
    static ui: NbsCommonUtil;
    static util: any;
    static cms: any;
    static converter: any;
    static parser: any;
    static modal: any;
    static report: any;
    static schedule: any;
    static entity: any;
    static validators: NbsCommonValidators;
    static routes: any;

    static $sxb = NbsCommonUI.addLoadingSilent;

    static test() {
        console.log("NBsCommonLib Test");
    }

}

export class NbsCommonValidators {

    static requiredFieldValidator(value, context) {
        if (value === null || value === undefined || value === '') {
            return false;
        } else {
            return true;
        }
    }

    static emailFieldValidator(value, context) {
        if (value === null || value === undefined || value === '') {
            return false;
        } else {
            return value.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        }
    }

    static emailNullableFieldValidator(value, context) {
        if (value === null || value === undefined || value === '') {
            return true;
        } else {
            return value.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        }
    }

    static requiredFieldDateValidator(value, context) {
        if (value === null) {
            return false;
        } else {
            return true;
        }
    }


    static requiredFieldSerializedValidator(value, context) {
        if (value === null || value === undefined || value === '') {
            return false;
        } else {
            var selected = [];
            if (value) {
                // @ts-ignore
                selected = JSON.parse(NbsCommonConverter.decodeHtml(value));
            }
            // @ts-ignore
            if (Lazy(selected).some()) {
                return true;
            } else {
                return false;
            }
        }
    }


}


export class NbsCommonEntity {

    // Support to reject entities
    static rejectChanges(entities) {
        // @ts-ignore
        Lazy(entities)
            .each((entity) => {
                if (entity && !entity.entityAspect.entityState.isDetached()) {
                    entity.entityAspect.rejectChanges();
                }
            });
    }

}

export class NbsCommonAuth {

    static Permission: any;

    static refreshTokenCheckInterval: 300000;
    static refreshTokenMargin: 7;

    //code: e.g. 'COMPILE'
    static checkProcessPermission(lib, virtualContext, code, moduleTargetId, typeId, groupId) {
        return NbsCommonAuth.checkProcessPermissionCode(
            { code: lib.Permission[code].code, value: lib.Permission[code].value },
            { moduleTargetId: moduleTargetId, typeId: typeId, groupId: groupId },
            virtualContext) > 0;
    }

    static isProcessEnabled(virtualContext, moduleTargetId) {
        // @ts-ignore
        return virtualContext.TargetModuleWithProcessEnabled && Lazy(virtualContext.TargetModuleWithProcessEnabled).contains(moduleTargetId);
    }

    //permission: {code:'X_XXX',value:4}
    //filter: {moduleTargetId:1,typeId:38,groupId:133}
    //PermissionBw = CRUD => 1+2+4+8 custom action 16 32 etc....
    static checkProcessPermissionCode(permission, filter, virtualContext) {
        if (!filter.moduleTargetId) {
            console.log('module target not set');
            return -1;
        }
        if (!filter.typeId) {
            console.log('typeId not set');
            return -1;
        }
        if (!filter.groupId) {
            console.log('groupId not set');
            return -1;
        }
        if (virtualContext.IsUa) {
            return permission.value;
        }
        //check if process are enabled for moduleTargetId
        //TEST NbsCommonAuth.checkProcessPermissionCode({ code: 'TEC_COMPROC_TC', value: 4 }, { moduleTargetId: 1, typeId: 20, groupId: 133 }, contextService.virtualContext);
        //var permissionValue = 0;
        //if (virtualContext.TargetModuleWithProcessEnabled && Lazy(virtualContext.TargetModuleWithProcessEnabled).contains(filter.moduleTargetId)) {
        //    //process is enalbed for this moduleTarget
        //    let currentProcessesInModule = Lazy(virtualContext.ProcessPermissions).where({ ModuleTargetId: filter.moduleTargetId }).toArray();

        //    var relatedProcess = Lazy(currentProcessesInModule).filter((item) => {
        //        var res = item.ProcessActivityMatcher.VhrEventGroupsId.length === 0 && Lazy(item.ProcessActivityMatcher.VhrEventTypesId).contains(filter.typeId);
        //        res = res || item.ProcessActivityMatcher.VhrEventTypesId.length === 0 && Lazy(item.ProcessActivityMatcher.VhrEventGroupsId).contains(filter.groupId);
        //        res = res || Lazy(item.ProcessActivityMatcher.VhrEventTypesId).contains(filter.typeId) && Lazy(item.ProcessActivityMatcher.VhrEventGroupsId).contains(filter.groupId);
        //        return res;
        //    }).toArray();
        //    if (relatedProcess.length > 1) {
        //        alert("process error! more than 1");
        //    }
        //    else if (relatedProcess.length === 1) {
        //        //var relatedProces = relatedProcess[0];
        //        permissionValue = NbsCommonAuth.checkSingleProcessPermission(relatedProcess[0], permission, virtualContext);
        //        return permissionValue & permission.value;
        //    }
        //    //not have permission because permission is enabled for current module target but user not have relatedProcess
        //    return 0;

        //}
        //else {
        //    //check in global process because processes is not enabled for this module target
        //    return NbsCommonAuth.checkSingleProcessPermission(virtualContext.GlobalProcessPermissions, permission, virtualContext);
        //}
    }

    static checkSingleProcessPermission(process, permission, virtualContext) {
        if (!permission) {
            return 0;
        }

        if (virtualContext.IsUa) {
            return permission.value;
        }
        if (permission.code === "X_AUTHUSER") {
            return {
                verified: true,
                permissionCodeNotSet: false
            };
        }
        if (process && process.CurrentPermissions.hasOwnProperty(permission.code)) {
            return process.CurrentPermissions[permission.code];
        }
        return 0;
    }

    //permission: {code:'X_XXX',value:4}
    //PermissionBw = CRUD => 1+2+4+8 custom action 16 32 etc....
    static checkGlobalPermissionCode(permission, virtualContext) {
        return NbsCommonAuth.checkSingleProcessPermission(virtualContext.GlobalProcessPermissions, permission, virtualContext);
    }

    static checkViewAuth(viewData, virtualContext) {
        if (!virtualContext ||
            (virtualContext && !virtualContext.hasOwnProperty('IsUa'))) {
            //when browser refresh, context  may not yet be loaded
            //return authorized.. server perfeorm same control...
            return {
                verified: true,
                permissionCodeNotSet: false
            };
        }

        if (!viewData) {
            //some view e.g. apphome, not exists in cmsview data... add it
            //at moment skip auth control
            return {
                verified: true,
                permissionCodeNotSet: false
            };
        }
        if (virtualContext.IsUa) {
            return {
                verified: true,
                permissionCodeNotSet: false
            };
        }
        if (viewData.PermissionCode === "X_AUTHUSER") {
            return {
                verified: true,
                permissionCodeNotSet: false
            };
        }

        if (viewData.PermissionCode === "X_NOTSET") {
            return {
                verified: true,
                permissionCodeNotSet: true
            };
        }

        if (viewData && viewData.IsUaRequired) {
            return {
                verified: virtualContext.IsUa,
                permissionCodeNotSet: false
            };
        }
        if (viewData && viewData.IsSaRequired) {
            return {
                verified: virtualContext.IsSa || virtualContext.IsUa,
                permissionCodeNotSet: false
            };
        }
        var permissionToCheck = viewData.PermissionCode;
        var permSplit = permissionToCheck.split('_');
        //viewData.PermissionCode.indexOf('_VIEW_') < 0 => compatibility refactor permision name convention
        //permission with _VIEW_ not have to split
        if (permSplit.length > 3 && (viewData.PermissionCode.indexOf('_VIEW_') < 0 && viewData.PermissionCode.indexOf('_MENU_') < 0)) {
            permissionToCheck = permissionToCheck.substring(0, viewData.PermissionCode.lastIndexOf('_'));
        }
        if (virtualContext
            && virtualContext.GlobalProcessPermissions
            && virtualContext.GlobalProcessPermissions.CurrentPermissions
            && virtualContext.GlobalProcessPermissions.CurrentPermissions.hasOwnProperty(permissionToCheck)) {
            return {
                verified: true,
                permissionCodeNotSet: false
            };
        }
        return {
            verified: false,
            permissionCodeNotSet: false
        };
    }

    static isPublicRouteState(stateName) {
        return stateName === 'auth.login'
            || stateName === 'auth.loginnormal'
            || stateName === 'auth.testidp'
            || stateName === 'auth.publicmobileappconfig'
            || stateName === 'auth.oidcresponse'
            || stateName === 'auth.resetpassword'
            || stateName === 'auth.logoutmessage'
    }

    static isSsoIgnoreRouteState(stateName) {
        return stateName === 'auth.oidcresponse'
            || stateName === 'auth.loginnormal'
            || stateName === 'auth.testidp'
            || stateName === 'auth.publicmobileappconfig'
            || stateName === 'auth.recoverpassword'
            || stateName === 'auth.resetpassword'
            || stateName === 'auth.logoutmessage'
    }

}

export class NbsCommonSchedule {

    static getFilterDayRange(date) {
        if (!date) {
            return {
                dateFrom: null,
                dateTo: null
            };
        }

        var dFrom = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        var dTo = new Date(date.getFullYear(), date.getMonth(), date.getDate());

        return {
            dateFrom: dFrom,
            dateTo: dTo
        };
    }

    static getFilterMonthRange(date) {
        if (!date) {
            return {
                dateFrom: null,
                dateTo: null
            };
        }
        // @ts-ignore
        var lastDayInMonth = moment(date).endOf('month');
        var dFrom = new Date(date.getFullYear(), date.getMonth(), 1);
        var dTo = new Date(date.getFullYear(), date.getMonth(), lastDayInMonth.date());

        return {
            dateFrom: dFrom,
            dateTo: dTo
        };
    }

}




////add replaceAll to string in javascript
//String.prototype.replaceAll(target, replacement) {
//    return this.split(target).join(replacement);
//};

//String.prototype.htmlEscape() {
//    return NbsCommonConverter.decodeHtml(this);
//};

//String.prototype.capitalizeFirstLetter() {
//    return this.charAt(0).toUpperCase() + this.slice(1);
//};


// NO REMOVE
// Added properties into imported modules
class NbsPermission {
    constructor() {

    }
};


//#region Classes

class NbsAssigneeSelectorModal {
    onSelectFnCallback: any;
    pLabelsList: any;
    itemType: any;
    statusUI: any;

    NbsLabelListDatasource: any;
    pServerVarCode: any;
    pModalParams: any;

    getServerVarFn: any;
    translator: any;
    navigationService: any;
    suspendDirty: any;
    contextUserForSelection: any;

    labelsListSelectedItems: any;
    constructor(params) {
        this.NbsLabelListDatasource = class {

            selectedItems: any;
            dataSource: any;
            constructor() {
                this.selectedItems = [];
                this.dataSource = [];
            }

            getDatasource(groupCode) {
                var code = groupCode ? groupCode : '';
                return Lazy(this.dataSource).where({ groupCode: code }).toArray();
            };

            selected(groupCode) {
                var code = groupCode ? groupCode : '';
                return Lazy(this.selectedItems).where({ groupCode: code }).toArray();
            };

            getSelectedIds(groupCode) {
                return Lazy(this.getSelectedItems(groupCode)).pluck('id').toArray();
            };

            getSelectedItems(groupCode) {
                var selected = this.selected(groupCode);
                return Lazy(selected).toArray();
            };

            setSelected(groupCode, ids) {
                groupCode = groupCode || '';

                this.selectedItems = Lazy(this.selectedItems).reject({ groupCode: groupCode }).toArray();

                var filteredDs = Lazy(this.dataSource).filter((i) => { return i.groupCode === groupCode && Lazy(ids).contains(i.id); }).toArray();
                Lazy(filteredDs)
                    .each((item) => {
                        this.selectedItems.push(item);
                    });
            };

            setSelectedItems(items, params) {
                this.selectedItems = Lazy(this.selectedItems).reject({ groupCode: params.groupCode }).toArray();

                for (var i = 0; i < items.length; i++) {
                    var item = items[i];
                    this.selectedItems.push({
                        id: item[params.propertyValue],
                        name: item[params.propertyName],
                        groupCode: params.groupCode,
                        groupName: params.groupName,
                        hasGroupCode: params.groupCode ? true : false,

                        canDeleteItem: true
                    });
                };
            };

            deleteOnclick(item) {
                this.selectedItems = Lazy(this.selectedItems).reject({ groupCode: item.groupCode, id: item.id }).toArray();
            };

            addData(dataList, params) {
                if (!params.groupCode) {
                    params.groupCode = '';
                }

                for (var i = 0; i < dataList.length; i++) {
                    var item = dataList[i];
                    if (!Lazy(this.dataSource).where({ groupCode: params.groupCode, id: params.propertyValue }).some()) {
                        var toAddItem = {
                            id: item[params.propertyValue],
                            name: item[params.propertyName],

                            groupCode: params.groupCode,
                            groupName: params.groupName,
                            hasGroupCode: params.groupCode ? true : false,

                            canDeleteItem: true
                        };

                        this.dataSource.push(toAddItem);
                    }
                };
            };
        }

        this.pServerVarCode = null;
        this.pModalParams = {
            selectedAppUserIds: [],
            selectedDrbacRoleIds: [],
            selectedDrbacUserGroupIds: []
        };
        this.itemType = { AppUser: 'AppUser', DrbacUserGroup: 'DrbacUserGroup', DrbacRole: 'DrbacRole' };
        this.pLabelsList = new this.NbsLabelListDatasource();

        this.getServerVarFn = params.getServerVarFn;
        this.translator = params.translator;
        this.navigationService = params.navigationService;
        this.suspendDirty = params.suspendDirty;
        this.contextUserForSelection = params.contextUserForSelection === true ? true : false;

        this.statusUI = {
            usersAreVisible: false,
            userGroupsAreVisible: false,
            rolesAreVisible: false,
            usersAreEnabled: true,
            userGroupsAreEnabled: true,
            rolesAreEnabled: true
        };

        this.labelsListSelectedItems = [];
        this.onSelectFnCallback = null;

    }

    setOnSelectFnCallback(fn) {
        this.onSelectFnCallback = fn;
    };

    raiseOnSelectFnCallback() {
        if (typeof this.onSelectFnCallback === 'function') {
            this.onSelectFnCallback();
        }
    };

    labelsListDeleteOnclick(item) {
        this.pLabelsList.deleteOnclick(item);
        this.refreshLabelsListSelectedItems();
        this.raiseOnSelectFnCallback();
    };

    appUserMultiselectAddData(data, propertyValue, propertyName) {
        var params = { groupCode: this.itemType.AppUser, groupName: this.translator('User'), propertyValue: propertyValue, propertyName: propertyName };
        this.pLabelsList.addData(data, params);
    };

    drbacUserGroupMultiselectAddData(data, propertyValue, propertyName) {
        var params = { groupCode: this.itemType.DrbacUserGroup, groupName: this.translator('UserGroup'), propertyValue: propertyValue, propertyName: propertyName };
        this.pLabelsList.addData(data, params);
    };

    drbacRoleMultiselectAddData(data, propertyValue, propertyName) {
        var params = { groupCode: this.itemType.DrbacRole, groupName: this.translator('Role'), propertyValue: propertyValue, propertyName: propertyName };
        this.pLabelsList.addData(data, params);
    };

    appUserMultiselectSelectedIds() { return this.pLabelsList.getSelectedIds(this.itemType.AppUser); };
    drbacUserGroupMultiselectSelectedIds() { return this.pLabelsList.getSelectedIds(this.itemType.DrbacUserGroup); };
    drbacRoleMultiselectSelectedIds() { return this.pLabelsList.getSelectedIds(this.itemType.DrbacRole); };

    appUserMultiselectSelectedItems() { return this.pLabelsList.getSelectedItems(this.itemType.AppUser); };
    drbacUserGroupMultiselectSelectedItems() { return this.pLabelsList.getSelectedItems(this.itemType.DrbacUserGroup); };
    drbacRoleMultiselectSelectedItems() { return this.pLabelsList.getSelectedItems(this.itemType.DrbacRole); };

    drbacUserGroupAreSelected() { return Lazy(this.drbacUserGroupMultiselectSelectedIds()).some(); };
    drbacRoleAreSelected() { return Lazy(this.drbacRoleMultiselectSelectedIds()).some(); };
    appUserAreSelected() { return Lazy(this.appUserMultiselectSelectedIds()).some(); };

    clear() {
        this.setAppUsersSelected([]);
        this.setDrbacUserGroupsSelected([]);
        this.setDrbacRolesSelected([]);
    }

    // set selection by ids
    setAppUsersSelected(ids) {
        this.pLabelsList.setSelected(this.itemType.AppUser, ids);
        this.refreshLabelsListSelectedItems();
    };

    setDrbacUserGroupsSelected(ids) {
        this.pLabelsList.setSelected(this.itemType.DrbacUserGroup, ids);
        this.refreshLabelsListSelectedItems();
    };

    setDrbacRolesSelected(ids) {
        this.pLabelsList.setSelected(this.itemType.DrbacRole, ids);
        this.refreshLabelsListSelectedItems();
    };

    // set selection by items
    setAppUserItemsSelected(items, propertyValue, propertyName) {
        var params = { groupCode: this.itemType.AppUser, groupName: this.translator('User'), propertyValue: propertyValue, propertyName: propertyName };
        this.pLabelsList.setSelectedItems(items, params);
        this.refreshLabelsListSelectedItems();
    };

    setDrbacUserGroupItemsSelected(items, propertyValue, propertyName) {
        var params = { groupCode: this.itemType.DrbacUserGroup, groupName: this.translator('UserGroup'), propertyValue: propertyValue, propertyName: propertyName };
        this.pLabelsList.setSelectedItems(items, params);
        this.refreshLabelsListSelectedItems();
    };

    setDrbacRoleItemsSelected(items, propertyValue, propertyName) {
        var params = { groupCode: this.itemType.DrbacRole, groupName: this.translator('Role'), propertyValue: propertyValue, propertyName: propertyName };
        this.pLabelsList.setSelectedItems(items, params);
        this.refreshLabelsListSelectedItems();
    };

    viewUsers() { this.statusUI.usersAreVisible = true; };
    viewUserGroups() { this.statusUI.userGroupsAreVisible = true; };
    viewRoles() { this.statusUI.rolesAreVisible = true; };
    hideUsers() { this.statusUI.usersAreVisible = false; };
    hideUserGroups() { this.statusUI.userGroupsAreVisible = false; };
    hideRoles() { this.statusUI.rolesAreVisible = false; };
    disableUsers() { this.statusUI.usersAreEnabled = false; };
    disableUserGroups() { this.statusUI.userGroupsAreEnabled = false; };
    disableRoles() { this.statusUI.rolesAreEnabled = false; };

    setVisibilityServerVar(serverVarCode) {
        // ssv_core_act_assignee_visibility_selection, ...
        // _serverVar => E.g. 'users-usergroups-roles'
        if (!serverVarCode) {
            return;
        }

        this.pServerVarCode = serverVarCode;

        var serverVar = this.getServerVarFn(this.pServerVarCode);
        if (serverVar) {
            var serverVarKeys = serverVar.split('-');
            this.statusUI.usersAreVisible = Lazy(serverVarKeys).contains('users');
            this.statusUI.userGroupsAreVisible = Lazy(serverVarKeys).contains('usergroups');
            this.statusUI.rolesAreEnabled = Lazy(serverVarKeys).contains('roles');
        }
    };

    setStatusByActionPermissions(actionPermissions) {
        if (!this.pServerVarCode) {
            this.statusUI.usersAreVisible = actionPermissions.canViewActAssignmentUsers;
            this.statusUI.userGroupsAreVisible = actionPermissions.canViewActAssignmentUserGroups;
        } else {
            if (this.statusUI.usersAreVisible) {
                this.statusUI.usersAreVisible = actionPermissions.canViewActAssignmentUsers;
            }
            if (this.statusUI.userGroupsAreVisible) {
                this.statusUI.userGroupsAreVisible = actionPermissions.canViewActAssignmentUserGroups;
            }
        }

        if (!actionPermissions.canModifyActAssignmentUsers) {
            this.disableUsers();
        }
        if (!actionPermissions.canModifyActAssignmentUserGroups) {
            this.disableUserGroups();
        }
    };

    refreshLabelsListSelectedItems() {
        var users = this.appUserMultiselectSelectedItems();
        var userGroups = this.drbacUserGroupMultiselectSelectedItems();
        var roles = this.drbacRoleMultiselectSelectedItems();

        // set an order for selected items
        this.labelsListSelectedItems = [];

        var userGroupsSorted = Lazy(userGroups).sortBy((item) => { return item.name; }).toArray();
        for (var i = 0; i < userGroupsSorted.length; i++) {
            var item = userGroupsSorted[i];
            item.canDeleteItem = this.statusUI.userGroupsAreEnabled;
            this.labelsListSelectedItems.push(item);
        };

        var usersSorted = Lazy(users).sortBy((item) => { return item.name; }).toArray();
        for (var i = 0; i < usersSorted.length; i++) {
            var item = usersSorted[i];
            item.canDeleteItem = this.statusUI.usersAreEnabled;
            this.labelsListSelectedItems.push(item);
        };

        var rolesSorted = Lazy(roles).sortBy((item) => { return item.name; }).toArray();
        for (var i = 0; i < rolesSorted.length; i++) {
            var item = rolesSorted[i];
            item.canDeleteItem = this.statusUI.rolesAreEnabled;
            this.labelsListSelectedItems.push(item);
        };
    }

    // open modal of selection roles, users, user groups
    manageOnclick() {
        var me = this;
        me.pModalParams.viewUsers = me.statusUI.usersAreVisible;
        me.pModalParams.viewUserGroups = me.statusUI.userGroupsAreVisible;
        me.pModalParams.viewRoles = me.statusUI.rolesAreVisible;
        me.pModalParams.disableUsers = !me.statusUI.usersAreEnabled;
        me.pModalParams.disableUserGroups = !me.statusUI.userGroupsAreEnabled;
        me.pModalParams.disableRoles = !me.statusUI.rolesAreEnabled;
        me.pModalParams.selectedAppUserIds = me.appUserMultiselectSelectedIds();
        me.pModalParams.selectedDrbacRoleIds = me.drbacRoleMultiselectSelectedIds();
        me.pModalParams.selectedDrbacUserGroupIds = me.drbacUserGroupMultiselectSelectedIds();
        me.pModalParams.selectedAppUsers = me.appUserMultiselectSelectedItems();
        me.pModalParams.selectedDrbacRoles = me.drbacRoleMultiselectSelectedItems();
        me.pModalParams.selectedDrbacUserGroups = me.drbacUserGroupMultiselectSelectedItems();
        me.pModalParams.contextUserForSelection = me.contextUserForSelection;

        var ds = {
            appUsers: me.pLabelsList.getDatasource(me.itemType.AppUser),
            drbacRoles: me.pLabelsList.getDatasource(me.itemType.DrbacRole),
            drbacUserGroups: me.pLabelsList.getDatasource(me.itemType.DrbacUserGroup),
        };

        me.navigationService
            .navToAssignmentModal(me.suspendDirty, me.pModalParams, ds)
            .then(function (modalResult) {
                if (modalResult.dialogResult === me.navigationService.navigationActionTypes.modalOk) {
                    me.setAppUserItemsSelected(modalResult.data.selectedAppUsers, 'fId', 'fName');
                    me.setDrbacUserGroupItemsSelected(modalResult.data.selectedDrbacUserGroups, 'fId', 'fName');
                    me.setDrbacRoleItemsSelected(modalResult.data.selectedDrbacRoles, 'fId', 'fName');

                    me.refreshLabelsListSelectedItems();
                    me.raiseOnSelectFnCallback();
                }
            });
    };
}

class NbsDropDownCustom {
    pValue: any;
    pText: any;
    pDisplayText: any;
    pFieldDisplayText: any;
    pFieldValue: any;
    pFieldText: any;
    datasource: any;
    selectedItem: any;
    selectedValue: any;
    onChangeFnCallback: any;
    hasEmptyValue: any;
    constructor(addEmptyValue) {
        this.pValue = 'nbs_dd_value';
        this.pText = 'nbs_dd_text';
        this.pDisplayText = 'nbs_dd_displaytext';
        this.pFieldDisplayText = 'name';
        this.pFieldValue = 'value';
        this.pFieldText = 'name';

        this.datasource = [];
        this.selectedItem = null;
        this.selectedValue = null;
        this.onChangeFnCallback = null;
        this.hasEmptyValue = addEmptyValue === true ? true : false;
    }

    setDatasource(ds, fieldValue, fieldText, fieldDisplayText) {
        if (fieldValue) { this.pFieldValue = fieldValue; }
        if (fieldText) { this.pFieldText = fieldText; }
        if (fieldDisplayText) {
            this.pFieldDisplayText = fieldDisplayText;
        } else {
            this.pFieldDisplayText = this.pFieldText;
        }

        for (var i = 0; i < ds.length; i++) {
            var item = ds[i];
            item[this.pValue] = item[this.pFieldValue];
            item[this.pText] = item[this.pFieldText];
            item[this.pDisplayText] = item[this.pFieldDisplayText];
        };

        if (this.hasEmptyValue) {
            var emptyItem: any = {};
            emptyItem.isEmptyItem = true;
            emptyItem[this.pValue] = '';
            emptyItem[this.pText] = '';
            emptyItem[this.pDisplayText] = '';
            ds.push(emptyItem);
        }

        this.datasource = ds;
    };

    setOnchangeFnCallback(fn) {
        this.onChangeFnCallback = fn;
    };

    onChange() {
        this.refreshSelectedValue();

        if (this.onChangeFnCallback) {
            var selItem = null;
            if (this.selectedItem
                && !this.selectedItem.isEmptyItem) {

                selItem = this.selectedItem;
            }

            this.onChangeFnCallback(selItem);
        }
    };

    refreshSelectedValue() {
        this.selectedValue = this.selectedItem ? this.selectedItem[this.pValue] : null;
    }

    setSelected(item) {
        this.selectedItem = null;

        if (!item || !item[this.pFieldValue]) {
            return;
        }

        item[this.pValue] = item[this.pFieldValue];
        item[this.pText] = item[this.pFieldText];
        item[this.pDisplayText] = item[this.pFieldDisplayText];
        // @ts-ignore
        var pItem = Lazy(this.datasource)
            .filter((e) => {
                return e[this.pValue] === item[this.pValue] && e[this.pText] === item[this.pText];
            }).first();

        if (!pItem) {
            this.datasource.push(item);
            this.selectedItem = item;
        } else {
            this.selectedItem = pItem;
        }

        this.refreshSelectedValue();
    };
}

export class NbsSelectionModal {
    modalParams: any;
    suspendDirty: any;
    stateTo: any;
    modalDs: any;
    openGenericGrid: any;
    passSelectedIds: any;
    navigationService: any;

    viewDeleteButton: any;
    viewAddButton: any;
    selectedItems: any;
    selectedIds: any;
    selectedCodes: any;
    deleteItemButtonIsVisible: any;
    onSelectFnCallback: any;
    onDeleteFnCallback: any;
    viewGenericSelectionMessage: any;
    viewId: any;
    viewCode: any;
    viewName: any;

    constructor(suspendDirty, navService, modalParams) {
        if (!suspendDirty) {
            throw new Error('NbsSelectionModal: suspendDirty mandatory');
        }
        if (!navService) {
            throw new Error('NbsSelectionModal: navService mandatory');
        }

        this.modalParams = modalParams ? modalParams : {}
        this.suspendDirty = suspendDirty;
        this.stateTo = null;
        this.modalDs = this.modalParams.ds;
        this.openGenericGrid = false;
        this.passSelectedIds = false;
        this.modalParams.ds = [];
        this.modalParams.modalKey = this.modalParams.destModalKey ? this.modalParams.destModalKey.toLowerCase() : 'genericgrid';
        this.navigationService = navService;

        switch (this.modalParams.modalKey) {
            case 'vhr':
                this.stateTo = 'app.nav.vhrselectionmodal';
                this.passSelectedIds = true;
                break;
            case 'category': this.stateTo = 'app.nav.categoriesselection'; break;
            case 'activity': this.stateTo = 'app.nav.activitieslistmodal'; break;
            case 'genericgrid':
                this.openGenericGrid = true;
                break;

            default:
                this.stateTo = this.modalParams.stateTo;
        }

        this.modalParams.isMultiselect = this.modalParams.isMultiselect === null || this.modalParams.isMultiselect === undefined ? true : this.modalParams.isMultiselect;
        this.modalParams.isAutocheck = this.modalParams.isAutocheck === null || this.modalParams.isAutocheck === undefined ? true : this.modalParams.isAutocheck;

        this.viewDeleteButton = true;
        this.viewAddButton = true;
        this.selectedItems = [];                      // visible in UI as selected items
        this.selectedIds = null;                      // confirmed in modal
        this.selectedCodes = null;                    // confirmed in modal
        this.deleteItemButtonIsVisible = true;        // binded UxHelper
        this.onSelectFnCallback = null;
        this.onDeleteFnCallback = null;
        this.viewGenericSelectionMessage = false;
        this.viewId = false;
        this.viewCode = true;
        this.viewName = true;
    }

    setOnSelectFnCallback(fn) {
        this.onSelectFnCallback = fn;
    };

    raiseOnSelectFnCallback() {
        if (typeof this.onSelectFnCallback === 'function') {
            this.onSelectFnCallback();
        }
    };

    setOnDeleteFnCallback(fn) {
        this.onDeleteFnCallback = fn;
    };

    raiseOnDeleteFnCallback() {
        if (typeof this.onDeleteFnCallback === 'function') {
            this.onDeleteFnCallback();
        }
    };

    setGenericSelectionMessage(message) {
        this.viewGenericSelectionMessage = true;
    };

    addOnclick = function () {
        var me = this;

        // pass selected items id to modal
        if (me.passSelectedIds) {
            me.modalParams.selectedIds = me.selectedIds;
        } else {
            me.modalParams.selectedIds = [];
        }

        if (me.openGenericGrid) {
            var genericModalParams = new NbsGenericListModalParamObject(me.modalParams.modalMode, me.modalParams.viewTitle);
            if (Lazy(me.modalParams.selectedIds).some()) {
                genericModalParams.selectedIds = me.modalParams.selectedIds;
            }
            genericModalParams.isMultiselect = me.modalParams.isMultiselect;

            me.navigationService.navToGenericListModal(me.suspendDirty, genericModalParams, me.modalDs)
                .then(function (result) {
                    me.setSelectedItems(result.data);
                });
        } else {
            me.navigationService.navToModal(me.suspendDirty, me.stateTo, me.modalParams, me.modalDs)
                .then(function (result) {
                    me.setSelectedItems(result.data);
                });
        }
    };

    clear(item) {
        this.selectedItems = [];
        this.pMultiselectRefreshSelectedIds();
        this.raiseOnDeleteFnCallback();
    };

    deleteOnclick(item) {
        this.selectedItems = Lazy(this.selectedItems).reject({ nbs_id: item.nbs_id }).toArray();
        this.pMultiselectRefreshSelectedIds();
        this.raiseOnDeleteFnCallback();
    };

    setSelectedItems(items) {
        if (!items) { items = []; }

        if (this.passSelectedIds) {
            this.selectedItems = [];
        }

        var mappedItems = Lazy(items)
            .map((item) => {
                return {
                    nbs_id: item.nbs_id,
                    nbs_code: item.nbs_code,
                    nbs_name: item.nbs_name,
                    item: item
                };
            }).toArray();

        for (var i = 0; i < mappedItems.length; i++) {
            var item = mappedItems[i];

            if (!this.modalParams.isMultiselect) {
                this.selectedItems = [];
            }

            var toSelectItems = Lazy(this.selectedItems).findWhere({ nbs_id: item.nbs_id });
            if (!toSelectItems) {
                this.selectedItems.push(item);
            }
        };

        this.pMultiselectRefreshSelectedIds();
    };

    pMultiselectRefreshSelectedIds() {
        this.selectedIds = Lazy(this.selectedItems).some() ? Lazy(this.selectedItems).pluck('nbs_id').toArray() : null;
        this.selectedCodes = Lazy(this.selectedItems).some() ? Lazy(this.selectedItems).pluck('nbs_code').toArray() : null;
        this.raiseOnSelectFnCallback();
    }
}

export class NbsGenericListModalParamObject {
    gridColId: any;
    gridColCode: any;
    gridColName: any;
    gridColIdIsVisible: any;
    gridColCodeIsVisible: any;
    gridColNameIsVisible: any;
    viewTitle: any;
    isMultiselect: any;
    readonly: any;
    selectedIds: any;

    gridSortField: any;
    gridSortMode: any;

    constructor(modalEntity, modalTitle) {
        this.gridColId = null;
        this.gridColCode = null;
        this.gridColName = null;
        this.gridColIdIsVisible = true;
        this.gridColCodeIsVisible = true;
        this.gridColNameIsVisible = true;
        this.viewTitle = modalTitle;
        this.isMultiselect = false;
        this.readonly = false;
        this.selectedIds = [];

        // switch multiselect
        switch (modalEntity) {
            case 'VhrEventLabel':
                this.isMultiselect = true;
                break;
        }

        // switch visibility
        switch (modalEntity) {
            case 'SupplierDto':
            case 'InvoiceRegister':
                this.gridColIdIsVisible = false;
                break;
            case 'CmsViewDto':
                this.gridColIdIsVisible = false;
                this.gridColCodeIsVisible = false;
                break;
            case 'Address':
            case 'VhrEventLifeStatus':
            case 'NembusEnum':
                this.gridColCodeIsVisible = false;
                break;
        }

        // switch grid column names
        switch (modalEntity) {
            case 'WorkCenter':
                this.gridColId = 'VirtualWorkCenterId';
                this.gridColCode = 'WorkCenterCode';
                this.gridColName = 'WorkCenterName';
                break;
            case 'HrBucket':
                this.gridColId = 'VirtualHumanResourceId';
                this.gridColCode = 'VirtualCode';
                this.gridColName = 'VirtualName';
                break;
            case 'Supplier':
                this.gridColId = 'SupplierId';
                this.gridColCode = 'SupplierCodeForUI';
                this.gridColName = 'SupplierNameForUI';
                break;
            case 'SupplierDto':
                this.gridColId = 'SupplierId';
                this.gridColCode = 'SupplierCode';
                this.gridColName = 'SupplierName';
                break;
            case 'ManagementContract':
                this.gridColId = 'ManagementContractId';
                this.gridColCode = 'ManagementContractCode';
                this.gridColName = 'ManagementContractName';
                this.gridSortField = 'ManagementContractId';
                this.gridSortMode = 'desc';
                break;
            case 'CmsViewDto':
                this.gridColId = 'CmsViewId';
                this.gridColCode = '';
                this.gridColName = 'CmsViewTitleTranslation';
                break;
            case 'VhrEventLifeStatus':
            case 'NembusEnum':
                this.gridColId = 'value';
                this.gridColCode = '';
                this.gridColName = 'name';
                break;
            default:
                if (!modalEntity) {
                    modalEntity = '';
                }

                this.gridColId = modalEntity + 'Id';
                this.gridColCode = modalEntity + 'Code';
                this.gridColName = modalEntity + 'Name';
                break;
        }
    }
}
export class NbsFsDtoData {
    private fsType = { folder: 1, file: 2 };
    private fsMediaType = { image: 'image', video: 'video', document: 'document', all: 'all' };
    private extensionImageList = ['jpg', 'jpeg', 'gif', 'png', 'bmp'];
    private extensionVideoList = ['mp4', 'avi', 'ogv', 'mov'];
    private fsdtos = [];
    private files = [];
    private folders = [];
    private rootNode = null;
    private thumbnailOptions = {
        maxHeight: 100,
        maxWidth: 200,
        fileNameCharacters: 10
    };
    private FSFOLDERROOTNAME = 'root';

    thumbnailDatasource: any;
    carouselDatasource: any;
    viewCarousel: any;
    treeviewDatasource: any;
    fileTransferService: any;
    virtualContext: any;
    translator: any;
    thumbnailDatasourceIsGrouped: any;
    formatDateTimeFn: any;
    nbsGuidManager: any;

    constructor(params) {
        if (!params.fileTransferService) {
            throw new Error('NbsFsDtoData: fileTransferService mandatory');
        }
        if (!params.virtualContext) {
            throw new Error('NbsFsDtoData: virtualContext mandatory');
        }
        if (!params.formatDateTimeFn) {
            throw new Error('NbsFsDtoData: formatDateTimeFn mandatory');
        }

        this.thumbnailDatasource = [];
        this.carouselDatasource = [];
        this.viewCarousel = false;
        this.treeviewDatasource = [];
        this.fileTransferService = params.fileTransferService;
        this.virtualContext = params.virtualContext;
        this.translator = params.translator;
        this.thumbnailDatasourceIsGrouped = params.thumbnailDatasourceIsGrouped;
        this.formatDateTimeFn = params.formatDateTimeFn;
        this.nbsGuidManager = params.nbsGuidManager;
    }

    getFsFolderRootName() {
        return this.FSFOLDERROOTNAME;
    };

    // public functions
    setFsDtos(fsDtoList, setAuthorizationClasses) {
        if (!setAuthorizationClasses) {
            setAuthorizationClasses = false;
        } else {
            setAuthorizationClasses = true;
        }

        for (var i = 0; i < fsDtoList.length; i++) {
            var fsDto = fsDtoList[i];
            fsDto.nbsExtraData = {};
            fsDto.nbsExtraData.CreationDateUI = this.formatDateTimeFn(fsDto.CreationDate, null, false);

            this.parseIcoData(fsDto);
            this.parseIcoAuthorization(fsDto, setAuthorizationClasses);
        };

        this.fsdtos = fsDtoList;
        this.rootNode = Lazy(this.fsdtos).findWhere({ Depth: 0, FsName: this.FSFOLDERROOTNAME });
        this.folders = Lazy(this.fsdtos).where({ FsTypeId: this.fsType.folder }).toArray();
        this.files = Lazy(this.fsdtos).where({ FsTypeId: this.fsType.file }).toArray();

        // set datasources
        this.setThumbnailDatasource();
        //setCarouselDatasource();
        this.setTreeviewDatasource();
    };

    getFsDtos() {
        return this.fsdtos;
    };

    getFiles() {
        return this.files;
    };

    getFolders() {
        return this.folders;
    };

    getFolderFiles(folder) {
        return Lazy(this.files)
            .where({
                ParentFsDtoId: folder.FsDtoId
            }).toArray();
    };

    getRootNode() {
        return this.rootNode;
    };

    hasRootNode() {
        return this.rootNode ? true : false;
    };

    getNewRootNode() {
        return {
            Depth: 0,
            FsDtoId: this.nbsGuidManager.newGuid(),
            ParentFsDtoId: this.nbsGuidManager.emptyGuid(),		// do not change, this means that is root folder (UtilController)
            FsName: this.FSFOLDERROOTNAME,
            FsTypeId: this.fsType.folder,
            VirtualCompanyId: this.virtualContext.CurrentVirtualCompanyId
        };
    };

    //#region private methods
    private setThumbnailDatasource() {
        this.thumbnailDatasource = [];
        var index = 0;

        if (this.thumbnailDatasourceIsGrouped) {
            this.thumbnailDatasource.push({ id: this.fsMediaType.document, name: this.translator('Documents'), files: [] });
            this.thumbnailDatasource.push({ id: this.fsMediaType.image, name: this.translator('Images'), files: [] });
            this.thumbnailDatasource.push({ id: this.fsMediaType.video, name: this.translator('Videos'), files: [] });
        } else {
            this.thumbnailDatasource.push({ id: this.fsMediaType.all, name: this.translator('All'), files: [] });
        }

        for (var i = 0; i < this.files.length; i++) {
            var file = this.files[i];
            file.nbsExtraData.completeFileUrl = this.getCompleteFileUrl(file);
            file.nbsExtraData.mediaType = this.getMediaTypeByFsDto(file);
            file.nbsExtraData.displayFileNameParsed = file.DisplayFileName.length > this.thumbnailOptions.fileNameCharacters ? file.DisplayFileName.substring(0, 10) + '...' : file.DisplayFileName;
            file.nbsExtraData.currentIndex = index;

            if (this.thumbnailDatasourceIsGrouped) {
                switch (file.nbsExtraData.mediaType) {
                    case this.fsMediaType.image:
                        Lazy(this.thumbnailDatasource).findWhere({ id: this.fsMediaType.image }).files.push(file);
                        break;
                    case this.fsMediaType.video:
                        Lazy(this.thumbnailDatasource).findWhere({ id: this.fsMediaType.video }).files.push(file);
                        break;
                    case this.fsMediaType.document:
                        Lazy(this.thumbnailDatasource).findWhere({ id: this.fsMediaType.document }).files.push(file);
                        break;
                    default:
                }
            } else {
                Lazy(this.thumbnailDatasource).findWhere({ id: this.fsMediaType.all }).files.push(file);
            }

            index++;
        };
    }

    private getCompleteFileUrl(file) {
        if (!file.completeFileUrl) {
            return this.fileTransferService.uriByGuid({ entityGuid: file.FsDtoId, imageMaxWidth: 200, imageMaxHeight: 200 });
        } else {
            return file.completeFileUrl;
        }
    }

    private getMediaTypeByFsDto(file) {
        var extension = this.getExtension(file);
        var mediaType = null;

        if (Lazy(this.extensionImageList).contains(extension)) {
            mediaType = this.fsMediaType.image;
        } else if (Lazy(this.extensionVideoList).contains(extension)) {
            mediaType = this.fsMediaType.video;
        } else {
            mediaType = this.fsMediaType.document;
        }

        return mediaType;
    }

    private getExtension(file) {
        var extension = file.FsExtension ? file.FsExtension.replace('.', '') : null;
        if (!extension) {
            if (file.DisplayFileName && file.DisplayFileName.lastIndexOf('.') >= 0) {
                extension = file.DisplayFileName.substr(file.DisplayFileName.lastIndexOf('.'), file.DisplayFileName.length - 1);
                extension = extension.replace('.', '');
            }
        }

        if (extension) {
            extension = extension.toLowerCase();
        }

        return extension;
    }

    private setTreeviewDatasource() {
        var datasource = Lazy(this.fsdtos).toArray();
        Lazy(datasource)
            .each((item) => {
                item.expanded = true;
            });

        this.treeviewDatasource = datasource;
    }

    private parseIcoData(fsDto) {
        var extension = this.getExtension(fsDto);
        if (extension) {
            extension = extension.toLowerCase();
        }

        switch (extension) {
            case 'doc':
            case 'docx':
                fsDto.nbsExtraData.icoClass = 'fal fa-file-word';
                fsDto.nbsExtraData.icoTitle = 'File word';
                break;
            case 'xls':
            case 'xlsx':
                fsDto.nbsExtraData.icoClass = 'fal fa-file-excel';
                fsDto.nbsExtraData.icoTitle = 'File excel';
                break;
            case 'pdf':
                fsDto.nbsExtraData.icoClass = 'fal fa-file-pdf';
                fsDto.nbsExtraData.icoTitle = 'File pdf';
                break;
            case 'txt':
                fsDto.nbsExtraData.icoClass = 'fal fa-file-alt';
                fsDto.nbsExtraData.icoTitle = 'File testo';
                break;
            case 'jpg':
            case 'jpeg':
            case 'gif':
            case 'png':
            case 'bmp':
                fsDto.nbsExtraData.icoClass = 'fal fa-file-image';
                fsDto.nbsExtraData.icoTitle = 'File immagine';
                break;
            case 'avi':
                fsDto.nbsExtraData.icoClass = 'fal fa-file-video';
                fsDto.nbsExtraData.icoTitle = 'File video';
                break;
            default:
                fsDto.nbsExtraData.icoClass = 'fal fa-file';  // default icon
                break;

        }
    }

    private parseIcoAuthorization(fsDto, setAuthorizationClasses) {
        // private/public icons
        fsDto.nbsExtraData.icoFdmAuthIsVisible = setAuthorizationClasses;
        if (setAuthorizationClasses) {
            if (fsDto.AuthCode === NbsCommonAuth.Permission['FMDPRIVATE'].code) {
                fsDto.nbsExtraData.classFdmAuthCode = 'fa fa-lock fmdprivate';
                fsDto.nbsExtraData.titleFdmAuthCode = this.translator('NotShared');
            }
            if (fsDto.AuthCode === NbsCommonAuth.Permission['FMDPUBLIC'].code) {
                fsDto.nbsExtraData.classFdmAuthCode = 'fa fa-unlock fmdpublic';
                fsDto.nbsExtraData.titleFdmAuthCode = this.translator('Shared');
            }
        }
    }
    //#endregion
}

export class NbsRichTextEditor {
    width: any;
    minWidth: any;
    isResponsive: any;
    showFooter: any;
    showHtmlSource: any;
    toolbarOverflowMode: any;
    showFontOption: any;
    showClearAll: any;
    rteContentToPaste: any;
    nbsGuidManager: any;

    tools: any;
    constructor(nbsGuidManager) {
        this.width = "100%";
        this.minWidth = "150px";
        this.isResponsive = true;
        this.showFooter = true;
        this.showHtmlSource = true;
        this.toolbarOverflowMode = "inline";
        this.showFontOption = true;
        this.showClearAll = true;
        this.rteContentToPaste = null;
        this.nbsGuidManager = nbsGuidManager;

        this.tools = {
            lists: ["unorderedList", "orderedList"],
            doAction: ["undo", "redo"],
            indenting: ["outdent", "indent"],
            clear: ["clearFormat", "clearAll"],
            links: ["createLink", "removeLink"],
            images: [],
            media: [],
            tables: ["createTable", "addRowAbove", "addRowBelow", "addColumnLeft", "addColumnRight", "deleteRow", "deleteColumn", "deleteTable"],
            effects: [], //["superscript", "subscript"],
            casing: ["upperCase", "lowerCase"],
            view: ["fullScreen", "zoomIn", "zoomOut"],
            print: ["print"]
            //customUnorderedList: [{
            //	name: "unOrderInsert",
            //	tooltip: "Custom UnOrderList",
            //	css: "e-rte-toolbar-icon e-rte-unlistitems customUnOrder",
            //	text: "Smiley",
            //	listImage: "url('../content/images/rte/Smiley-GIF.gif')"
            //}],
            //customOrderedList: [{
            //	name: "orderInsert",
            //	tooltip: "Custom OrderList",
            //	css: "e-rte-toolbar-icon e-rte-listitems customOrder",
            //	text: "Lower-Greek",
            //	listStyle: "lower-greek"
            //}]		
        };
    }

    setContentToPaste(content) {
        // guid is used to force watch by directive 
        // is removed in nbs-directive.js
        this.rteContentToPaste = this.nbsGuidManager.newGuid() + '<b>' + content + '</b>';
    };

    enableToolbarImages(status) {
        var stat = Boolean(status);
        if (stat === false) {
            this.tools.images = [];
        } else {
            this.tools.images = ["image"];
        }
    };

    enableToolbarMedia(status) {
        var stat = Boolean(status);
        if (stat === false) {
            this.tools.media = [];
        } else {
            this.tools.media = ["video"];
        }
    };
}


export class NbsCmsViewUserSettingDataParser {
    constructor() {
    }

    /// this method parse cmsViewUserSetting to local filter for fill nembus forms
    static parseToLocalFilters_ReportFilter(cmsViewUserSetting, decodeHtmlFn) {
        var result = {};

        if (!cmsViewUserSetting) {
            return result;
        }

        if (!cmsViewUserSetting.CmsViewUserSettingData) {
            return result;
        }

        var settingDataJSONParsed = JSON.parse(decodeHtmlFn(cmsViewUserSetting.CmsViewUserSettingData));
        Lazy(Object.keys(settingDataJSONParsed))
            .each((filterKey) => {
                var currentSettingFilters = settingDataJSONParsed[filterKey];
                var type = currentSettingFilters.Type;

                result[filterKey] = { Type: type };

                switch (type) {
                    case 'datarange':
                        var filter = Lazy(currentSettingFilters.Values).first();
                        var dateFrom = filter.Value1;
                        var dateTo = filter.Value2;
                        result[filterKey].Value1 = new Date(dateFrom);
                        result[filterKey].Value2 = new Date(dateTo);
                        break;
                    case 'selectionmodal':
                        result[filterKey].Value = Lazy(currentSettingFilters.Values)
                            .map((value) => { return { nbs_id: value.Value, nbs_code: value.Code, nbs_name: value.Text } })
                            .toArray();
                        break;
                    case 'boolean':
                        var filter = Lazy(currentSettingFilters.Values).first();
                        result[filterKey].Value = filter.Value;
                        break;
                    case 'objectlist':
                        var filter = Lazy(currentSettingFilters.Values).first();
                        result[filterKey].Value = filter.Value;
                        break;
                    case 'multiselect':
                        result[filterKey].Value = Lazy(currentSettingFilters.Values)
                            .map((value) => { return { nbs_id: value.Value, nbs_code: value.Code, nbs_name: value.Text } })
                            .toArray();
                        break;
                    case 'intervalrange':
                        var filter = Lazy(currentSettingFilters.Values).first();
                        result[filterKey].UnitId = filter.UnitId;
                        result[filterKey].ExpressionLeft = filter.ExpressionLeft;
                        result[filterKey].QuotaLeft = filter.QuotaLeft;
                        result[filterKey].ExpressionRight = filter.ExpressionRight;
                        result[filterKey].QuotaRight = filter.QuotaRight;
                        break;
                    case 'authselector':
                        result[filterKey].AppUsers = Lazy(currentSettingFilters.Values)
                            .filter((item) => { return item.Type === 'AppUser' })
                            .map((value) => { return { nbs_id: value.Value, nbs_code: value.Code, nbs_name: value.Text2 } })
                            .toArray();
                        result[filterKey].DrbacUserGroups = Lazy(currentSettingFilters.Values)
                            .filter((item) => { return item.Type === 'DrbacUserGroup' })
                            .map((value) => { return { nbs_id: value.Value, nbs_code: value.Code, nbs_name: value.Text2 } })
                            .toArray();
                        result[filterKey].DrbacRoles = Lazy(currentSettingFilters.Values)
                            .filter((item) => { return item.Type === 'DrbacRole' })
                            .map((value) => { return { nbs_id: value.Value, nbs_code: value.Code, nbs_name: value.Text2 } })
                            .toArray();
                        break;
                    case 'numbermatrix':
                        throw new Error('CmsViewUserSettingDataParser ' + type + ' not managed too');
                        break;
                }
            });

        return result;
    }

    /// this method parse reportFilterData to cmsViewUserSettingData before it is saved to DB
    static getCmsViewUserSettingData_ReportFilter(reportFilterData) {
        if (!reportFilterData) {
            return;
        }

        if (!Lazy(reportFilterData.Datas).some()) {
            return;
        }

        var cmsViewUserSettingData = {};
        Lazy(reportFilterData.Datas)
            .each((filterData) => {
                if (filterData.Key && filterData.Type) {
                    var filterKey = filterData.Key;
                    var type = filterData.Type;

                    cmsViewUserSettingData[filterKey] = { Type: type, Values: filterData.Values };
                }

            });

        return cmsViewUserSettingData;
    }

    static getCmsViewUserSettingData_GridFilter() {
        throw new Error('CmsViewUserSettingData getCmsViewUserSettingData_GridFilter not implemented');
    }
}

export class NbsLabelList {
    translator: any;
    llDataSource: any;
    constructor(translator) {
        if (!translator) {
            translator = function (text) {
                return text;
            };
        }

        this.translator = translator;
        this.llDataSource = [];
    }

    getDataSource() {
        return this.llDataSource;
    };

    addData(source, nameFieldValue, nameFieldLabel) {
        var newSource = Lazy(source)
            .map((i) => { return { llValue: i[nameFieldValue], llLabel: this.translator(i[nameFieldLabel]) }; })
            .toArray();

        this.llDataSource = Lazy(this.llDataSource).concat(newSource).toArray();
    };
}

export class NbsCheckboxList {
    translator: any;
    datasource: any;
    selected: any;
    onChangeFn: any;

    onChangeFnCallback: any;
    constructor(translator) {
        if (!translator) {
            translator = function (s) {
                return s;
            };
        }

        this.translator = translator;
        this.datasource = [];
        this.selected = [];
        this.onChangeFnCallback = null;
    }

    clearSelection() {
        this.selected = [];
    };

    setSelected(values) {
        Lazy(values)
            .each((id) => {
                // only if value is in datasource
                if (Lazy(this.datasource).where({ value: id }).some()) {
                    var idx = this.selected.indexOf(id);
                    if (idx < 0) {
                        this.selected.push(id);
                    }
                }
            });
    };

    toggleSelection(value) {
        var idx = this.selected.indexOf(value);

        if (idx > -1) {
            // Is currently selected
            this.selected.splice(idx, 1);
        } else {
            // Is newly selected
            this.selected.push(value);
        }

        if (this.onChangeFnCallback !== null) {
            this.onChangeFnCallback(this.selected);
        }
    };

    setData(source, fieldValue, fieldLabel) {
        var ds = Lazy(source)
            .map((i) => { return { value: i[fieldValue], label: this.translator(i[fieldLabel]) }; })
            .toArray();

        this.datasource = Lazy(ds).toArray();
        this.clearSelection();
    };
}

export class NbsRadioList {
    translator: any;
    rlDataSource: any;
    selected: any;
    constructor(translator) {
        if (!translator) {
            translator = function (s) {
                return s;
            };
        }

        this.translator = translator;
        this.rlDataSource = [];
        this.selected = null;
    }

    getDataSource() {
        return this.rlDataSource;
    };

    addData(source, nameFieldValue, nameFieldLabel) {
        var newSource = Lazy(source)
            .map((i) => { return { rlValue: i[nameFieldValue], rlLabel: this.translator(i[nameFieldLabel]), rlSelected: 'false' }; })
            .toArray();

        this.rlDataSource = Lazy(this.rlDataSource).concat(newSource).toArray();
    };
}

export class NbsRadioListBoolean extends NbsRadioList {
    constructor(translator) {
        super(translator);

        var ds = [
            { value: true, label: translator('Yes') },
            { value: false, label: translator('No') },
            { value: null, label: translator('All') }
        ];
        this.addData(ds, 'value', 'label');
    }
}

export class NbsMultiselect {
    previousSelected: any;
    pFieldId: any;
    pFieldName: any;
    pFieldOrder: any;
    pFieldExtra: any;
    isSingleSelect: any;
    ngDisabled: any;
    msDataSource: any;
    watermarktext: any;
    selected: any;
    selectedIds: any;
    onChangeFnCallback: any;

    constructor(translator, isSingleSelect?) {
        if (!translator) {
            translator = function (s) {
                return s;
            };
        }

        this.previousSelected = [];
        this.pFieldId = 'id';
        this.pFieldName = 'name';
        this.pFieldOrder = 'name';
        this.pFieldExtra = 'extra';
        this.isSingleSelect = isSingleSelect === true ? true : false;
        this.ngDisabled = false;
        this.msDataSource = [];
        this.watermarktext = translator('ComboSelectOneItem');
        this.selected = [];
        this.selectedIds = [];
        this.onChangeFnCallback = null;
    }

    clearDatasource() {
        this.msDataSource = [];
        this.selected = [];
        this.selectedIds = [];
        this.refreshSelectedIds(true);
    };

    clearSelection() {
        this.selected = [];
        this.selectedIds = [];
        this.refreshSelectedIds(true);
    };

    setOnchangeFnCallback(fn) {
        this.onChangeFnCallback = fn;
    };

    setSelected(ids, raiseCallbackFn) {
        if (ids !== null
            && ids !== undefined
            && Lazy(ids).length() > 1
            && this.isSingleSelect) {

            throw new Error('multiselect is configured single select');
        }

        if (Lazy(ids).some()) {
            this.selected = [];

            for (var i = 0; i < ids.length; i++) {
                var id = ids[i];
                var item = Lazy(this.msDataSource).findWhere({ fId: id });
                if (item) {
                    this.selected.push(item);

                    if (this.isSingleSelect) {
                        this.previousSelected = item;
                    }
                }
            };
        } else {
            this.selected = [];
        }

        this.refreshSelectedIds(raiseCallbackFn);
    };

    setSelectedSingleCustom(item) {
        if (item) {
            var pItem = Lazy(this.msDataSource)
                .filter((e) => {
                    return e.fId === item.fId && e.fName === item.fName;
                }).first();

            if (!pItem) {
                this.msDataSource.push(item);
                this.selected = [item];

                if (this.isSingleSelect) {
                    this.previousSelected = item;
                }
            } else {
                this.selected = [pItem];

                if (this.isSingleSelect) {
                    this.previousSelected = pItem;
                }
            }

        } else {
            this.selected = [];
        }

        this.refreshSelectedIds(true);
    };

    onchange() {
        if (this.isSingleSelect) {
            if (Lazy(this.selected).length() === 1) {
                this.previousSelected = Lazy(this.selected).first();
            } else {
                this.selected = Lazy(this.selected).reject({ fId: this.previousSelected.fId }).toArray();
                this.previousSelected = Lazy(this.selected).first();
            }
        }

        this.refreshSelectedIds(true);
    };

    getDataSource() {
        return this.msDataSource;
    };

    addData(source, nameFieldId, nameFieldName, nameFieldOrder, nameFieldExtra) {
        if (nameFieldId) { this.pFieldId = nameFieldId; }
        if (nameFieldName) { this.pFieldName = nameFieldName; }
        if (nameFieldOrder) {
            this.pFieldOrder = nameFieldOrder;
        }
        else {
            this.pFieldOrder = this.pFieldName;
        }
        if (nameFieldExtra) { this.pFieldExtra = nameFieldExtra; }

        var newSource = Lazy(source)
            .map((i) => { return { fId: i[this.pFieldId], fName: i[this.pFieldName], fOrder: i[this.pFieldOrder], fExtra: i[this.pFieldExtra] }; })
            .toArray();

        this.msDataSource = Lazy(this.msDataSource).concat(newSource).toArray();
    };

    refreshSelectedIds(raiseCallbackFn) {
        this.selectedIds = Lazy(this.selected).pluck('fId').toArray();

        if (raiseCallbackFn === null || raiseCallbackFn === undefined || raiseCallbackFn === true) {
            this.callBackOnchange();
        }
    }

    callBackOnchange() {
        if (typeof this.onChangeFnCallback === 'function') {
            this.onChangeFnCallback(this.selected);
        }
    }
}

export class NbsAssigneeSelector {
    MODEUI: any;
    viewRoles: any;
    viewUsers: any;
    viewUserGroups: any;
    userDisabled: any;
    userGroupDisabled: any;
    roleDisabled: any;
    viewModeUI: any;
    drbacRoleMultiselect: any;
    drbacUserGroupMultiselect: any;
    appUserMultiselect: any;
    constructor(translator) {
        this.MODEUI = {
            LABELS_ON_TOP: 'LABELS_ON_TOP',
            LABELS_ON_ROW: 'LABELS_ON_ROW'
        };

        // visibility on UI
        this.viewRoles = false;
        this.viewUsers = false;
        this.viewUserGroups = false;

        // enabling
        this.userDisabled = false;
        this.userGroupDisabled = false;
        this.roleDisabled = false;

        this.viewModeUI = this.MODEUI.LABELS_ON_ROW;           // default UI mode view

        // dropdowns
        this.drbacRoleMultiselect = new NbsMultiselect(translator);
        this.drbacUserGroupMultiselect = new NbsMultiselect(translator);
        this.appUserMultiselect = new NbsMultiselect(translator);
    }

    setUserDisabled(disabled) {
        this.userDisabled = disabled;
    };

    setUserGroupDisabled(disabled) {
        this.userGroupDisabled = disabled;
    };

    setRoleDisabled(disabled) {
        this.roleDisabled = disabled;
    };

    appUserMultiselectAddData(data, propertyValue, propertyName) {
        this.appUserMultiselect.addData(data, propertyValue, propertyName);
    };

    drbacUserGroupMultiselectAddData(data, propertyValue, propertyName) {
        this.drbacUserGroupMultiselect.addData(data, propertyValue, propertyName);
    };

    drbacRoleMultiselectAddData(data, propertyValue, propertyName) {
        this.drbacRoleMultiselect.addData(data, propertyValue, propertyName);
    };

    setLabelsOnTop() {
        this.viewModeUI = this.MODEUI.LABELS_ON_TOP;
    };

    setLabelsOnRow() {
        this.viewModeUI = this.MODEUI.LABELS_ON_ROW;
    };

    setDrbacUserGroupsSelected(ids) {
        if (Lazy(ids).some()) {
            this.drbacUserGroupMultiselect.setSelected(ids);
        }
    };

    setDrbacRolesSelected(ids) {
        if (Lazy(ids).some()) {
            this.drbacRoleMultiselect.setSelected(ids);
        }
    };

    setAppUsersSelected(ids) {
        if (Lazy(ids).some()) {
            this.appUserMultiselect.setSelected(ids);
        }
    };
}

export class NbsReportFilterData {
    formatDateFn: any;
    cultureInfo: any;
    translator: any;

    Datas: any;
    constructor(translator, cultureInfo, formatDateFn) {
        if (!cultureInfo) {
            throw new Error('NbsReportFilterData: cultureInfo mandatory');
        }
        if (!formatDateFn) {
            throw new Error('NbsReportFilterData: formatDateFn mandatory');
        }

        if (!translator) {
            translator = function (text) {
                return text;
            };
        }

        this.formatDateFn = formatDateFn;
        this.cultureInfo = cultureInfo;
        this.translator = translator;
        // array { Key: string, Label: string, Values: List<string> }
        this.Datas = [];
    }

    // E.g. Dal 01/01/2020 al 20/02/2020
    setDataByRangeDate(key, labelKey, date1, date2, ignoreTimezone) {
        if (ignoreTimezone === null || ignoreTimezone === undefined) {
            ignoreTimezone = true;
        }

        var value = null;
        var data = { Type: 'datarange', Key: key, Label: this.translator(labelKey), Values: [] };

        if (date1 || date2) {
            value = '';
            if (date1) {
                value += this.translator('From') + ' ' + this.formatDateFn(date1, this.cultureInfo, ignoreTimezone);
            }

            if (date2) {
                if (date1) {
                    value += ' ' + this.translator('To').toLowerCase();
                } else {
                    value += this.translator('UpTo');
                }
                value += ' ' + this.formatDateFn(date2, this.cultureInfo, ignoreTimezone);
            }
        }

        if (value) {
            data.Values.push({ Text: value, Value1: date1, Value2: date2 });
        }

        this.setData(data);
    };

    setDataBySelectionModal(key, labelKey, selectionModalObj) {
        var data = { Type: 'selectionmodal', Key: key, Label: this.translator(labelKey), Values: [] };

        if (selectionModalObj) {
            var items = selectionModalObj.selectedItems;
            Lazy(items)
                .each((item) => {
                    if (item) {
                        var text = '';
                        if (selectionModalObj.viewId) {
                            text += item.nbs_id + ' - ';
                        }
                        if (selectionModalObj.viewCode) {
                            text += item.nbs_code + ' ';
                        }
                        if (selectionModalObj.viewName) {
                            text += item.nbs_name + ' ';
                        }

                        data.Values.push({ Text: text, Value: item.nbs_id, Code: item.nbs_code });
                    }
                });
        }

        this.setData(data);
    };

    setDataByNumberMatrix(key, labelKey, numberMatrixObj) {
        var data = { Type: 'numbermatrix', Key: key, Label: this.translator(labelKey), Values: [] };

        if (numberMatrixObj) {
            Lazy(numberMatrixObj.rows)
                .each((row) => {
                    var text = null;
                    var value = null;
                    Lazy(row.cols)
                        .each((col) => {
                            if (text) {
                                if (col.value !== null && col.value !== undefined) {
                                    text += ' - ' + col.label + ': ' + col.value;
                                }
                            } else {
                                if (col.value !== null && col.value !== undefined) {
                                    text = col.label + ': ' + col.value;
                                }
                            }
                        });

                    data.Values.push({ Text: text, Value: null });
                });
        }

        this.setData(data);
    };

    setDataByMultiselect(key, labelKey, multiselectObj) {
        var data = { Type: 'multiselect', Key: key, Label: this.translator(labelKey), Values: [] };

        if (multiselectObj) {
            var items = multiselectObj.selected;
            Lazy(items)
                .each((item) => {
                    data.Values.push({ Text: item.fName, Value: item.fId, Code: item.fCode });
                });
        }

        this.setData(data);
    };

    setDataByIntervalRange(key, labelKey, intervalObj, unitId, expressionLeftId, quotaLeft, expressionRightId, quotaRight) {
        var data = { Type: 'intervalrange', Key: key, Label: this.translator(labelKey), Values: [] };
        var pattern = null;

        var unitIdPattern = null;
        var expressionLeftPattern = null;
        var quotaLeftPattern = null;
        var expressionRightPattern = null;
        var quotaRightPattern = null;

        if (unitId !== null && unitId !== undefined && unitId !== 0) {
            var expression = Lazy(intervalObj.dateIntervalDs).findWhere({ value: unitId });
            if (expression) {
                unitIdPattern = expression.name;
            }
        }

        if (unitIdPattern === null) {
            return;
        }

        if (expressionLeftId !== null && expressionLeftId !== undefined && expressionLeftId !== 0) {
            var expressionLeft = Lazy(intervalObj.expressionTypeLeftDs).findWhere({ value: expressionLeftId });
            if (expressionLeft) {
                expressionLeftPattern = expressionLeft.name;
            }
        }
        if (quotaLeft !== null && quotaLeft !== undefined) {
            quotaLeftPattern = quotaLeft;
        }

        if (expressionRightId !== null && expressionRightId !== undefined && expressionRightId !== 0) {
            var expressionRight = Lazy(intervalObj.expressionTypeRightDs).findWhere({ value: expressionRightId });
            if (expressionRight) {
                expressionRightPattern = expressionRight.name;
            }
        }
        if (quotaRight !== null && quotaRight !== undefined) {
            quotaRightPattern = quotaRight;
        }

        if (expressionLeftPattern !== null && quotaLeftPattern !== null && expressionRightPattern !== null && quotaRightPattern !== null) {
            pattern = expressionLeftPattern + ' ' + quotaLeftPattern + ' e ' + expressionRightPattern + ' ' + quotaRightPattern;
        } else {
            if (expressionLeftPattern !== null && quotaLeftPattern !== null) {
                pattern = expressionLeftPattern + ' ' + quotaLeftPattern;
            } else if (expressionRightPattern !== null && quotaRightPattern !== null) {
                pattern = expressionRightPattern + ' ' + quotaRightPattern;
            }
        }

        if (pattern !== null) {
            pattern += ' (' + unitIdPattern + ')';

            data.Values.push({
                Text: pattern,
                UnitId: unitId,
                ExpressionLeft: expressionLeftId,
                QuotaLeft: quotaLeft,
                ExpressionRight: expressionRightId,
                QuotaRight: quotaRight
            });

            this.setData(data);
        }
    };

    setDataByDrbacAuthSelectorModal(key, labelKey, drbacAuthSelectorModal) {
        var data = { Type: 'authselector', Key: key, Label: this.translator(labelKey), Values: [] };

        if (drbacAuthSelectorModal) {
            var userGroups = drbacAuthSelectorModal.drbacUserGroupMultiselectSelectedItems();
            var users = drbacAuthSelectorModal.appUserMultiselectSelectedItems();
            var roles = drbacAuthSelectorModal.drbacRoleMultiselectSelectedItems();

            Lazy(userGroups).each((item) => { data.Values.push({ Text: item.groupName + ' - ' + item.name, Text2: item.name, Value: item.id, Type: drbacAuthSelectorModal.itemType.DrbacUserGroup }); });
            Lazy(users).each((item) => { data.Values.push({ Text: item.groupName + ' - ' + item.name, Text2: item.name, Value: item.id, Type: drbacAuthSelectorModal.itemType.AppUser }); });
            Lazy(roles).each((item) => { data.Values.push({ Text: item.groupName + ' - ' + item.name, Text2: item.name, Value: item.id, Type: drbacAuthSelectorModal.itemType.DrbacRole }); });
        }

        this.setData(data);
    };

    setDataByObjectList(key, labelKey, ids, items, propertyId, propertyTexts) {
        var data = { Type: 'objectlist', Key: key, Label: this.translator(labelKey), Values: [] };

        var tmpIds = [];
        Lazy(ids)
            .each((id) => {
                if (id !== null && id !== undefined) {
                    tmpIds.push(id);
                }
            });

        if (Lazy(tmpIds).some()) {
            Lazy(items)
                .filter((i) => { return Lazy(tmpIds).contains(i[propertyId]); })
                .each((item) => {
                    if (item) {
                        var text = item[propertyTexts[0]];
                        var value = item[propertyId];
                        if (text) {
                            data.Values.push({ Text: text, Value: value });
                        }
                    }
                });
        }

        this.setData(data);
    };

    setDataByBoolean(key, labelKey, boolValue) {
        var data = { Type: 'boolean', Key: key, Label: this.translator(labelKey), Values: [] };

        if (boolValue === true) {
            data.Values.push({ Text: this.translator('Yes'), Value: true });
        }
        if (boolValue === false) {
            data.Values.push({ Text: this.translator('No'), Value: false });
        }

        this.setData(data);
    };

    setDataByString(key, labelKey, stringValue) {
        var data = { Type: 'string', Key: key, Label: this.translator(labelKey), Values: [] };

        if (stringValue) {
            data.Values.push({ Text: stringValue, Value: stringValue });
        }

        this.setData(data);
    };

    setData(data) {
        if (!data.Key) {
            throw new Error('NbsReportFilterData: Key mandatory');
        }
        if (!data.Label) {
            throw new Error('NbsReportFilterData: Label mandatory');
        }

        if (!Lazy(data.Values).some()) {
            this.Datas = Lazy(this.Datas).reject({ Key: data.Key }).toArray();
            return;
        }

        var cData = Lazy(this.Datas).findWhere({ Key: data.Key });
        if (!cData) {
            cData = data;
        } else {
            cData.Label = data.Label;
            cData.Values = data.Values;
        }

        this.Datas.push(cData);
    }
}




export class NbsBoard {
    TYPE: any;
    indexCol: any;
    cols: any;
    boardType: any;
    dueDateFilters: any;
    columnFilters: any;
    nbsGuidManager: any;
    localAsUtcFn: any;
    boardGridDataManager: any;
    boardFilter: any;
    gridsReady: any;
    refreshScopeFn: any;
    columns: any;
    canNavigate: any;
    indexColSrc: any;
    indexColDest: any;

    onBoardReady: any;
    onDropAction: any;

    NbsBoardColumnFilter: any;

    constructor(nCols, params) {
        if (!params.nbsGuidManager) {
            throw new Error('NbsBoard: nbsGuidManager mandatory');
        }
        if (!params.localAsUtcFn) {
            throw new Error('NbsBoard: localAsUtcFn mandatory');
        }

        this.TYPE = { DEFAULT: 0, DUEDATE: 1 };
        this.indexCol = 0;
        this.cols = !nCols ? 0 : nCols;
        this.boardType = Lazy(params.dueDateFilters).some() ? this.TYPE.DUEDATE : this.TYPE.DEFAULT;
        this.dueDateFilters = params.dueDateFilters;
        this.columnFilters = params.columnFilters;
        this.nbsGuidManager = params.nbsGuidManager;
        this.localAsUtcFn = params.localAsUtcFn;
        this.boardGridDataManager = null;
        this.boardFilter = {};
        this.gridsReady = [];
        this.refreshScopeFn = null;
        this.columns = [];
        this.canNavigate = false;
        this.indexColSrc = null;
        this.indexColDest = null;


        // events
        this.onBoardReady = null;
        this.onDropAction = null;

        this.NbsBoardColumnFilter = class {
            filter: any;
            field: any;
            text: any;
            isDueDate: any;

            value: any;
            defaultValue: any;
            constructor(params) {
                this.filter = params.filter;
                this.field = params.field;
                this.text = params.text;
                this.isDueDate = !params.isDueDate ? false : true;

                // @ts-ignore
                if (this.isDueDate && !Object.prototype.toString.call(params.value) === "[object Date]") {
                    console.log('NbsBoardColumnFilter: error');
                }

                this.value = params.value;
                this.defaultValue = params.defaultValue;
            }
        }

        // init board
        this.createColumns();
        this.setLockableColumns();
        this.setColumnFilters();
        this.setNavigable();
    }

    setRefreshScopeFn(fn) {
        this.refreshScopeFn = fn;
    };

    onGridCreated(col) {
        this.gridsReady.push(col.index);
        if (this.gridsReady.length === this.columns.length) {
            if (this.onBoardReady) {
                this.onBoardReady();
            }
        }
    };

    setBoardFilter(filter) {
        this.boardFilter = filter;
        this.refreshGridDatasources();
    };

    setGridDataManager(ds) {
        this.boardGridDataManager = ds;
    };

    setGridDatacontext(dataContext) {
        if (!dataContext.events) {
            dataContext.events = {};
        }

        dataContext.events.rowDragStart = this.rowDragStart.bind(this);
        dataContext.events.rowDrop = this.rowDrop.bind(this);

        Lazy(this.columns)
            .each((col) => {
                col.gridDatacontext = dataContext;
            });
    };

    refreshGridDatasources() {
        for (var i = 0; i < this.columns.length; i++) {
            var col = this.columns[i];
            this.refreshGridDatasource(col);
        }
        this.refresh();
    };

    refreshGridDatasource(col) {
        var colFilters = col.filters || [];

        for (var i = 0; i < colFilters.length; i++) {
            var colFilter = colFilters[i];
            if (colFilter.filter) {
                this.boardFilter[colFilter.filter][colFilter.field] = this.getColFilter(col)[colFilter.field];
            } else {
                this.boardFilter[colFilter.field] = this.getColFilter(col)[colFilter.field];
            }
        };

        var boardFilter = JSON.parse(JSON.stringify(this.boardFilter));
        if (this.boardGridDataManager) {
            var gridObj = this.boardGridDataManager(boardFilter, gridObj);
            col.gridDatasource.dataManager = gridObj.dmgrid;
        }
        col.gridFilter = { filter: boardFilter };
        col.refreshGrid = new Date();

        this.refresh();
    };

    navigateForward() {
        if (this.boardType === this.TYPE.DUEDATE) {
            this.navigateDueDate(1);
        } else {
            this.indexCol++;

            if (this.indexCol > this.columnFilters.length - 1) {
                this.indexCol = 0;
            }

            this.setColumnFilters();
        }

        this.refreshGridDatasources();
    };

    navigateBackward() {
        if (this.boardType === this.TYPE.DUEDATE) {
            this.navigateDueDate(-1);
        } else {
            this.indexCol--;

            if (this.indexCol < 0) {
                this.indexCol = this.columnFilters.length - 1;
            }

            this.setColumnFilters();
        }

        this.refreshGridDatasources();
    };

    getColFilter(col) {
        return this.getColumnFilters(col);
    };

    setColsFilterValue(col, fields, value) {
        Lazy(col.filters)
            .each((filter) => {
                if (Lazy(fields).contains(filter.field)) {
                    filter.value = value;
                }
            });

        this.refreshGridDatasource(col);
    };

    setColsFilterBoolValueToggle(col, fields) {
        Lazy(col.filters)
            .each((filter) => {
                if (Lazy(fields).contains(filter.field)) {
                    filter.value = !filter.value;
                }
            });

        this.refreshGridDatasource(col);
    };

    getLocalNewDate(date?) {
        if (!date) {
            date = new Date();
        }

        var newDate = date;
        newDate.setHours(0);
        newDate.setMinutes(0);
        newDate.setSeconds(0);
        newDate.setMilliseconds(0);
        newDate = this.localAsUtcFn(newDate);
        return newDate;
    }

    refresh() {
        if (this.refreshScopeFn) {
            this.refreshScopeFn();
        }
    }

    rowDragStart(args) {
        this.indexColSrc = $(args.target).parents("div[nbs-name='grid']").attr('nbs-ncol');
        this.indexColSrc = Number(this.indexColSrc);
    }

    rowDrop(args) {
        // clear UI from fucking shit template dropped
        $(document).find('.e-cloneproperties.e-draganddrop').remove();

        this.indexColDest = $(args.target).parents("div[nbs-name='grid']").attr('nbs-ncol');
        this.indexColDest = Number(this.indexColDest);

        if (this.indexColSrc === this.indexColDest) {
            args.cancel = true;
        } else {
            if (this.onDropAction) {
                if (!Lazy(args.data).some()
                    || this.indexColSrc === null
                    || this.indexColSrc === undefined
                    || !this.indexColDest === null
                    || !this.indexColDest === undefined) {

                    throw new Error('nbsboard rowDrop error');
                }

                this.onDropAction(args.data, this.indexColSrc, this.indexColDest);
            }
        }
    }

    setLockableColumns() {
        Lazy(this.columns).first().isLockable = true;
        Lazy(this.columns).last().isLockable = true;
    }

    navigateDueDate(nDays) {
        // old version (only move dates and not filters)
        for (var i = 0; i < this.columns.length; i++) {
            var col = this.columns[i];
            if (!col.isLocked) {
                var dueDateFilters = Lazy(col.filters).where({ isDueDate: true }).toArray();

                for (var p = 0; p < dueDateFilters.length; p++) {
                    var dueDateFilter = dueDateFilters[p];
                    var newDate = this.getLocalNewDate(dueDateFilter.value);
                    dueDateFilter.value = moment(newDate).add(nDays, 'days').toDate();
                }
            }
        };
    }

    setNavigable() {
        this.canNavigate = this.cols < this.columnFilters.length || this.boardType === this.TYPE.DUEDATE;
    }

    getColumnFilters(col) {
        var filter = {};
        Lazy(col.filters)
            .each((cFilter) => {
                filter[cFilter.field] = cFilter.value;
            });
        return filter;
    }

    createColumns() {
        var width = Number(100 / this.cols) + '%';
        for (var nCol = 0; nCol < this.cols; nCol++) {
            if (this.boardType === this.TYPE.DUEDATE) {
                var cDate = this.getLocalNewDate();
                cDate = moment(cDate).add(nCol, 'days').toDate();

                // no use of Lazy for undefined this
                for (var i = 0; i < this.dueDateFilters.length; i++) {
                    var dueDateFilter = this.dueDateFilters[i];
                    var boardColumn = new this.NbsBoardColumnFilter(dueDateFilter);
                    if (!this.columnFilters[nCol]) {
                        this.columnFilters[nCol] = [];
                    }
                    var filterParams = { filter: boardColumn.filter, field: boardColumn.field, value: cDate, isDueDate: true };
                    this.columnFilters[nCol].push(new this.NbsBoardColumnFilter(filterParams));
                }
            }

            this.columns.push({
                guid: "guid_" + this.nbsGuidManager.newGuidReplaced(),
                index: nCol,
                width: width,
                isLocked: false,
                isLockable: false,
                gridDatasource: { dataManager: null },
                gridDatacontext: null,
                gridFilter: null,
                refreshGrid: null,

                filters: this.columnFilters
            });
        }
    }

    setColumnFilters() {
        var cursor = this.indexCol;
        for (var colIndex = 0; colIndex <= this.columns.length - 1; colIndex++) {
            if (!this.columns[colIndex].isLocked) {
                var tmp = this.indexCol + colIndex;
                if (tmp > this.columnFilters.length - 1) {
                    cursor = tmp - this.columnFilters.length;
                } else {
                    cursor = tmp;
                }

                for (var i = 0; i < this.columnFilters[cursor].length; i++) {
                    this.columnFilters[cursor][i] = new this.NbsBoardColumnFilter(this.columnFilters[cursor][i]);
                }

                this.columns[colIndex].filters = this.columnFilters[cursor];
            }
        }
    }
}


export class NbsToolbar {
    Button: any;

    BREAKPOINTUI: any;
    BUTTONTYPE: any;
    breakpointUIEnabled: any;
    hideUpper: any;
    hideUnder: any;
    buttons: any;
    isVisible: any;
    translator: any;
    constructor(translator) {
        this.Button = class {
            Title: any;
            CssClass: any;
            OnClick: any;
            constructor(title, cssclass, onclick) {
                if (title) {
                    this.Title = title;
                } else {
                    this.Title = '';
                }

                this.CssClass = cssclass;
                this.OnClick = onclick;
            }
        }

        if (!translator) {
            translator = function (text) {
                return text;
            };
        }

        this.BREAKPOINTUI = { none: 'none', xs: 'xs', sm: 'sm', md: 'md', lg: 'lg' };
        this.BUTTONTYPE = { Back: 'Back', New: 'New', Print: 'Print', Pdf: 'Pdf', Pencil: 'Pencil', Save: 'Save' };
        this.breakpointUIEnabled = false;
        this.hideUpper = this.BREAKPOINTUI.none;
        this.hideUnder = this.BREAKPOINTUI.none;
        this.buttons = [];
        this.isVisible = false;
        this.translator = translator;
    }

    setHideUpper(breakPointUi) {
        this.hideUpper = breakPointUi;
        this.refreshVisibility();
    }

    setHideUnder(breakPointUi) {
        this.hideUnder = breakPointUi;
        this.refreshVisibility();
    }

    setBreakpointUIEnabled() {
        this.breakpointUIEnabled = true;
        this.refreshVisibility();
    }

    addButtonByType(type, onClickCallback) {
        var button = null;

        switch (type) {
            case this.BUTTONTYPE.Back: button = new this.Button(this.translator('Back'), 'nbs-toolbar-button-arrow-left'); break;
            case this.BUTTONTYPE.New: button = new this.Button(null, 'nbs-toolbar-button-document-new'); break;
            case this.BUTTONTYPE.Print: button = new this.Button(this.translator('Print'), 'nbs-toolbar-button-document-print'); break;
            case this.BUTTONTYPE.Pdf: button = new this.Button(this.translator('Pdf'), 'nbs-toolbar-button-document-pdf'); break;
            case this.BUTTONTYPE.Pencil: button = new this.Button(this.translator('Modify'), 'nbs-toolbar-button-pencil'); break;
        }

        if (onClickCallback) {
            button.OnClick = onClickCallback;
        } else {
            button.OnClick = () => { };
        }

        this.buttons.push(button);
    }

    setDocumentationToolbar(params) {
        /*
            backOnClick: () => { history.back(); },
            saveOnClick: () => { vm.submitAction(); },
            deleteOnClick: () => { vm.deleteAction(); },
            cloneOnClick: () => { vm.cloneAction(); },
            refreshOnClick: () => { vm.refreshAction(); },
            publishOnClick: () => { vm.writeAction(); },
            downloadOnClick: () => { vm.getDocumentationCmsViewXmlData(); },
         */
        this.buttons.push(new this.Button(this.translator('Back'), 'ToolbarItems e-back-img-toolbar', params.backOnClick));
        this.buttons.push(new this.Button(this.translator('Save'), 'ToolbarItems e-save-img-toolbar', params.saveOnClick));
        this.buttons.push(new this.Button(this.translator('Delete'), 'ToolbarItems e-delete-img-toolbar', params.deleteOnClick));
        this.buttons.push(new this.Button(this.translator('Clone'), 'ToolbarItems e-clone-img-toolbar', params.cloneOnClick));
        this.buttons.push(new this.Button(this.translator('Refresh'), 'ToolbarItems e-refresh-img-toolbar', params.refreshOnClick));
        this.buttons.push(new this.Button(this.translator('Publish'), 'ToolbarItems e-write-img-toolbar', params.publishOnClick));
        this.buttons.push(new this.Button(this.translator('Download'), 'ToolbarItems e-download-img-toolbar', params.downloadOnClick));
        this.isVisible = true;
    }

    getButton() {
        return new this.Button(null, null, null, null);
    }

    addButton(button) {
        this.buttons.push(button);
    }

    refreshVisibility() {
        if (this.breakpointUIEnabled) {
            if (this.hideUnder != this.BREAKPOINTUI.none) {
                this.isVisible = !NbsCommonUI["isUnderBreakpoint_" + this.hideUnder]();
            }
            else if (this.hideUpper != this.BREAKPOINTUI.none) {
                this.isVisible = NbsCommonUI["isUnderBreakpoint_" + this.hideUpper]();
            }
        }
    }
}

class NbsRibbon {
    Tab: any;
    HomeTab: any;
    DocTab: any;
    SearchTab: any;
    PrintTab: any;
    Button: any;
    Section: any;

    tmpStatusButtons: any;
    statusButtons: any;
    viewContextIsLocal: any;

    Buttons: any;

    navigationService: any;
    viewMode: any;

    isMa: any;

    SECTIONKEY: any;
    BREAKPOINTUI: any;
    buttonsMode: any;

    hasPrintTab: any;
    tabIndexSelected: any;
    isHidden: any;
    tabs: any;
    isUnderFTC: any;
    constructor(navigationService, virtualContext, buttonsMode) {
        if (!navigationService) {
            throw new Error('NbsRibbon: navigationService mandatory');
        }
        if (!virtualContext) {
            throw new Error('NbsRibbon: virtualContext mandatory');
        }

        this.Tab = class {
            Text: any;
            Id: any;
            IsDocTab: any;
            IsTabHome: any;
            Sections: any;
            Order: any;
            constructor(name, sections) {
                this.Text = name == null ? "" : name;
                this.Id = name == null ? "" : name;
                this.IsDocTab = false;
                this.IsTabHome = false;
                this.Sections = sections;
                this.Order = 0;
            }
        }

        this.HomeTab = class extends this.Tab {
            constructor(sections) {
                super("ActionPanelTabHome", sections);

                this.Order = -1;
                this.IsTabHome = true;
            }
        }

        this.DocTab = class extends this.Tab {
            constructor(sections) {
                super("DocumentationManage", sections)

                this.IsDocTab = true;
                this.Order = 1000;
            }
        }

        this.SearchTab = class extends this.Tab {
            constructor(sections) {
                super("ActionPanelTabSearch", sections)

                this.Order = 800;
            }
        }

        this.PrintTab = class extends this.Tab {
            constructor(name, sections) {
                if (name == null) {
                    name = "ActionPanelTabPrint";
                }

                super(name, sections)

                this.Order = 900;
            }
        }

        this.Button = class {
            Id: any;
            Text: any;
            ContentType: any;
            PrefixIcon: any;
            Action: any;
            AlignType: any;
            constructor(id, text, prefixIcon, action) {
                this.Id = id;
                this.Text = text;
                // @ts-ignore
                this.ContentType = ej.ContentType.TextAndImage;
                this.PrefixIcon = prefixIcon;
                this.Action = action;
                // @ts-ignore
                this.AlignType = ej.TextAlign.Left;
            }
        }

        this.Section = class {
            Group: any;

            Key: any;
            Text: any;
            AlignType: any;
            Groups: any;
            constructor(name, key, nGroups) {
                this.Group = class {
                    Width: any;
                    Height: any;
                    IsBigButton: any;
                    Buttons: any;
                    constructor() {
                        this.Width = 60;
                        this.Height = 71;
                        this.IsBigButton = true;
                        this.Buttons = [];
                    }
                }

                if (!nGroups) {
                    nGroups = 0;
                }

                this.Key = key;
                this.Text = name == null ? "" : name;
                // @ts-ignore
                this.AlignType = ej.Ribbon.AlignType.Columns;
                this.Groups = [];

                if (nGroups > 0) {
                    for (var i = 0; i < nGroups; i++) {
                        this.Groups.push(new this.Group());
                    }
                }
            }
        }

        // used to refresh status buttons on ribbon
        this.tmpStatusButtons = []
        this.statusButtons = []
        this.viewContextIsLocal = false;

        //#region BUTTONS
        this.Buttons = {
            Doc: new this.Button('doc-element-edit', 'ActionEditDocElement', 'e-doc-element-edit-img', 'editDocElementAction'),
            Back: new this.Button('back', 'ActionPanelBack', 'e-back-img', 'backAction'),
            Running: new this.Button('running', 'EnableTask', 'e-process-debug_run-img', 'enableAction'),
            Suspend: new this.Button('suspend', 'SuspendTask', 'e-process-debug_pause-img', 'suspendAction'),
            New: new this.Button('new', 'ActionPanelNew', 'e-document-new-img', 'newAction'),
            NewTask: new this.Button('new-task', 'ActionPanelNew', 'e-task-new-img', 'newAction'),
            NewTemplate: new this.Button('new-template', 'ActionPanelNewTemplate', 'e-document-new-img', 'newAction'),
            NewFolder: new this.Button('folder-new', 'ActionPanelFolder', 'e-folder_open-new-img', 'newFolderAction'),
            NewTarget: new this.Button('new-target', 'ActionPanelNewTarget', 'e-document-new-img', 'newAction'),
            PublishSchema: new this.Button('publish-schema', 'ActionPanelPublish', 'e-publish-schema-img', 'publishAction'),
            Active: new this.Button('active', 'ActionPanelActive', 'e-publish-schema-img', 'activeAction'),
            ResetPasswordLocal: new this.Button('reset-password', 'ActionPanelResetPassword', 'e-formkey-img', 'resetPasswordAction'),
            ProgrammaticAccess: new this.Button('reset-programmatic', 'ActionPanelResetProgrammaticAccess', 'e-formkey-img', 'programmaticAccessAction'),
            SsoPasswordRecovery: new this.Button('sso-reset-password', 'ActionPanelResetPassword', 'e-formkey-img', 'ssoResetPasswordAction'),
            SsoUserSyncro: new this.Button('sso-syncro-user', 'ActionPanelSsoSyncroUser', 'e-syncro-users-img', 'ssoSyncroUser'),
            SupportUserSyncro: new this.Button('support-syncro-user', 'ActionPanelSupportSyncroUser', 'e-syncro-users-img', 'supportSyncroUser'),
            RenewSecretCode: new this.Button('renew-secret-code', 'ActionPanelRenewCode', 'e-qrcodekey-img', 'renewSecretCodeAction'),
            RunMetric: new this.Button('run-metric', 'ActionPanelRun', 'e-gears-img', 'runMetricAction'),
            RunScheduling: new this.Button('run-scheduling', 'ActionPanelRun', 'e-gears-img', 'runAction'),
            AddScheduledTask: new this.Button('add-scheduledtask', 'ActionPanelScheduledTask', 'e-process-delay-img', 'scheduledTaskAction'),
            AddDefault: new this.Button('add-default', 'ActionPanelAddDefault', 'e-addcategorydefault-img', 'addDefaultAction'),
            RemoveDefault: new this.Button('remove-default', 'ActionPanelRemoveDefault', 'e-removecategorydefault-img', 'removeDefaultAction'),
            Sync: new this.Button('refresh-update', 'Update', 'e-sync-img', 'syncAction'),
            Send: new this.Button('send-tracy', 'Send', 'e-sync-img', 'sendAction'),
            AssociationStatusUpdate: new this.Button('association-status-update', 'ActionPanelAssociationStatusUpdate', 'e-association-status-update-img', 'associationStatusUpdateAction'),
            AssociationStatusInfo: new this.Button('association-status-info', 'ActionPanelAssociationStatusInfo', 'e-association-status-info-img', 'associationStatusInfoAction'),
            NewAccountLocal: new this.Button('new-account', 'ActionPanelFirstAccess', 'e-formnew-img', 'newAccountAction'),
            Comment: new this.Button('comments', 'ActionPanelNote', 'e-comment-img', 'attachNoteAction'),
            Refresh: new this.Button('refresh', 'ActionPanelRefresh', 'e-reload_rotate-img', 'refreshAction'),
            Print: new this.Button('print', 'ActionPanelPrint', 'e-print-img', 'window.print'),
            ExportExcel: new this.Button('export-excel', 'ActionPanelExportExcel', 'e-export_to_excel-img', 'exportExcelAction'),
            ExportIntegrationExcel: new this.Button('export-excel-integration', 'ActionPanelExportExcel', 'e-export_to_excel_integration-img', 'exportExcelAction'),
            ExportWord: new this.Button('export-word', 'ActionPanelExportWord', 'e-export_to_word-img', 'exportWordAction'),
            Archive: new this.Button('archive', 'ActionPanelArchive', 'e-archive-img', 'archiveAction'),
            Extract: new this.Button('extract', 'ActionPanelExtract', 'e-extract-img', 'extractAction'),
            View: new this.Button('view', 'ActionPanelView', 'e-view-img', 'viewAction'),
            Modify: new this.Button('modify', 'ActionPanelModify', 'e-tool_pencil-img', 'modifyAction'),
            Modify2: new this.Button('modify-2', 'ActionPanelModify', 'e-tool_pencil-img', 'modifyAction'),
            Delete: new this.Button('delete', 'ActionPanelDelete', 'e-trash_bin-img', 'deleteAction'),
            ModifyAll: new this.Button('edit-all', 'ActionPanelModifyAll', 'e-documents-edit-img', 'modifyAllAction'),
            Copy: new this.Button('copy', 'ActionPanelClone', 'e-clipboard_copy-img', 'copyAction'),
            OpenDetail: new this.Button('open-detail', 'ActionPanelOpen', 'e-open-img', 'openDetailAction'),
            Save: new this.Button('save', 'ActionPanelSave', 'e-save-img', 'submitAction'),
            Save2: new this.Button('save-2', 'ActionPanelSave', 'e-save-img', 'submitAction'),
            ChangeDate: new this.Button('set-date', 'ActionPanelSetDate', 'e-set-date-img', 'setDateAction'),
            SendMail: new this.Button('send-mail', 'ActionPanelSendMail', 'e-send-mail-img', 'sendMailAction'),
            SendNotification: new this.Button('send-notification', 'ActionPanelSendNotification', 'e-send-notification-img', 'sendMailNotification'),
            InvoiceRegister: new this.Button('invoice-register-selection', 'ActionPanelInvoiceRegisterSelection', 'e-doc-element-edit-img', 'invoiceRegisterSelectionAction'),
            ViewList: new this.Button('views-grid', 'ActionPanelViewsGrid', 'e-action-views-grid-img', 'onRibbonGrid'),
            ViewCalendar: new this.Button('views-calendar', 'ActionPanelViewsCalendar', 'e-action-views-calendar-img', 'onRibbonCalendar'),
            ViewStream: new this.Button('views-stream', 'ActionPanelViewsStream', 'e-action-views-stream-img', 'onRibbonStream'),
            ViewTree: new this.Button('views-tree', 'ActionPanelViewsTree', 'e-action-views-tree-img', 'onRibbonTree'),
            Board: new this.Button('views-board', 'ActionPanelViewsBoard', 'e-action-views-board-img', 'onRibbonBoard'),
            BoardStatus: new this.Button('views-boardstatus', 'ActionPanelViewsBoardStatus', null, 'onRibbonBoardStatus'),
            BoardWorkflow: new this.Button('views-boardworkflow', 'ActionPanelViewsBoardWorkflow', null, 'onRibbonBoardWorkflow'),
            BoardApproval: new this.Button('views-boardapproval', 'ActionPanelViewsBoardApproval', null, 'onRibbonBoardApproval'),
            Timeline: new this.Button('views-timeline', 'ActionPanelViewsTimeline', 'e-action-views-timeline-img', 'onRibbonTimeline'),
            TimelineAssignment: new this.Button('views-timelineassignment', 'ActionPanelViewsTimelineAssignment', null, 'onRibbonTimelineAssignment'),
            TimelineTarget: new this.Button('views-timelinetarget', 'ActionPanelViewsTimelineTarget', null, 'onRibbonTimelineTarget'),
            Search: new this.Button('search', 'ActionPanelSearch', 'e-search-img', 'searchAction'),
            AddFilter: new this.Button('add-filter', 'ActionPanelAddFilter', 'e-add-filter-img', 'addFilterAction'),
            RemoveFilter: new this.Button('delete-filter', 'ActionPanelDeleteFilter', 'e-delete-filter-img', 'deleteFilterAction'),
            ResetFilter: new this.Button('reset', 'ActionPanelReset', 'e-reset-img', 'resetAction'),
            PrintReportPdf: new this.Button('export-report-pdf', 'ActionPanelExportReportToPdf', 'e-export-pdf-img', 'exportReportToPdfAction'),
            PrintReportHtml: new this.Button('export-report-html', 'ActionPanelExportReportToHtml', 'e-export-html-img', 'exportReportToHtmlAction'),
            PrintReport: new this.Button('print-report', 'ActionPanelPrintReport', 'e-print-img', 'printReportAction'),
            PrintReportWord: new this.Button('export-report-word', 'ActionPanelExportReportToWord', 'e-export-word-small-img', 'exportReportToWordAction'),
            PrintReportExcel: new this.Button('export-report-excel', 'ActionPanelExportReportToExcel', 'e-export-excel-small-img', 'exportReportToExcelAction'),
            PrintReportXml: new this.Button('export-report-xml', 'ActionPanelExportReportToXml', 'e-export-xml-small-img', 'exportReportToXmlAction'),
            WarningModify: new this.Button('warning-update', 'ActionPanelUpdateLabel', 'e-label-edit-img', 'updateLabelAction'),
            WarningDelete: new this.Button('warning-delete', 'ActionPanelDeleteLabel', 'e-label-delete-img', 'deleteLabelAction'),
            WarningInfo: new this.Button('warning-info', 'ActionPanelInfoLabel', 'e-label-info-img', 'infoLabelAction'),
            WorkflowStatusExecute: new this.Button('workflow-status-execute', 'ActionPanelWorkflowExecute', 'e-workflow-status-execute-img', 'workflowExecuteAction'),
            WorkflowStatusModify: new this.Button('workflow-status-modify', 'ActionPanelVhrEventApprovalModify', 'e-workflow-status-modify-img', 'workflowModifyAction'),
            WorkflowStatusDelete: new this.Button('workflow-status-delete', 'ActionPanelDeleteLabel', 'e-workflow-status-delete-img', 'workflowDeleteAction'),
            WorkflowStatusInfo: new this.Button('workflow-status-info', 'ActionPanelVhrEventApprovalInfo', 'e-workflow-status-info-img', 'workflowInfoAction'),
            VhrEventLifeStatusApprove: new this.Button('vhrevent-status-approve', 'ActionPanelVhrEventLifeStatusApprove', 'e-vhrevent-status-approve-img', 'vhrEventLifeStatusApproveAction'),
            VhrEventLifeStatusMultiApprove: new this.Button('vhrevent-status-approve-multi', 'ActionPanelVhrEventLifeStatusApproveMulti', 'e-vhrevent-status-approve-multi-img', 'vhrEventLifeStatusApproveMultiAction'),
            VhrEventLifeStatusModify: new this.Button('vhrevent-status-update', 'ActionPanelVhrEventLifeStatusUpdate', 'e-vhrevent-status-update-img', 'vhrEventLifeStatusUpdateAction'),
            VhrEventLifeStatusInfo: new this.Button('vhrevent-status-info', 'ActionPanelVhrEventLifeStatusInfo', 'e-vhrevent-status-info-img', 'vhrEventLifeStatusInfoAction'),
            VhrEventFailureLifeStatusComplete: new this.Button('vhrevent-status-complete', 'ActionPanelVhrEventLifeStatusComplete', 'e-vhrevent-failure-status-complete-img', 'vhrEventLifeStatusCompleteAction'),
            VhrEventFailureLifeStatusClose: new this.Button('vhrevent-status-close', 'ActionPanelVhrEventLifeStatusClose', 'e-vhrevent-failure-status-close-img', 'vhrEventLifeStatusCloseAction'),
            VhrEventAssignmentExecute: new this.Button('assignment-execute', 'ActionPanelAssignmentExecute', 'e-vhrevent-execution-approve-img', 'vhrEventAssignmentExecute'),
            VhrEventAssignmentModify: new this.Button('assignment-modify', 'ActionPanelAssignmentModify', 'e-vhrevent-execution-modify-img', 'vhrEventAssignmentModify'),
            VhrEventAssignmentInfo: new this.Button('assignment-info', 'ActionPanelAssignmentInfo', 'e-vhrevent-execution-info-img', 'vhrEventAssignmentInfo'),
            VhrEventExecutionApprove: new this.Button('vhrevent-execution-approve', 'ActionPanelVhrEventExecutionApprove', 'e-vhrevent-execution-approve-img', 'vhrEventExecutionApproveAction'),
            VhrEventExecutionModify: new this.Button('vhrevent-execution-modify', 'ActionPanelVhrEventExecutionModify', 'e-vhrevent-execution-modify-img', 'vhrEventExecutionModifyAction'),
            VhrEventExecutionInfo: new this.Button('vhrevent-execution-info', 'ActionPanelVhrEventExecutionInfo', 'e-vhrevent-execution-info-img', 'vhrEventExecutionInfoAction'),
            VhrEventApprovalAuthorize: new this.Button('vhrevent-approval-authorize', 'ActionPanelVhrEventApprovalAuthorize', 'e-vhrevent-approval-authorize-img', 'vhrEventApprovalAuthorizeAction'),
            VhrEventApprovalModify: new this.Button('vhrevent-approval-modify', 'ActionPanelVhrEventApprovalModify', 'e-vhrevent-approval-modify-img', 'vhrEventApprovalModifyAction'),
            VhrEventApprovalInfo: new this.Button('vhrevent-approval-info', 'ActionPanelVhrEventApprovalInfo', 'e-vhrevent-approval-info-img', 'vhrEventApprovalInfoAction'),
            VhrEventCustomerApprovalAuthorize: new this.Button('vhrevent-customer-approval-authorize', 'ActionPanelVhrEventApprovalAuthorize', 'e-vhrevent-approval-authorize-img', 'vhrEventCustomerApprovalAuthorizeAction'),
            VhrEventCustomerApprovalModify: new this.Button('vhrevent-customer-approval-modify', 'ActionPanelVhrEventApprovalModify', 'e-vhrevent-approval-modify-img', 'vhrEventCustomerApprovalModifyAction'),
            VhrEventCustomerApprovalInfo: new this.Button('vhrevent-customer-approval-info', 'ActionPanelVhrEventApprovalInfo', 'e-vhrevent-approval-info-img', 'vhrEventCustomerApprovalInfoAction'),
            BillingLifeStatusModify: new this.Button('billing-status-update', 'ActionPanelBillingStatusUpdate', 'e-billing-status-update-img', 'billingStatusUpdateAction'),
            BillingLifeStatusInfo: new this.Button('billing-status-info', 'ActionPanelBillingStatusInfo', 'e-vhrevent-status-info-img', 'billingStatusInfoAction'),
            InvoiceStatusClose: new this.Button('invoice-status-close', 'ActionPanelInvoiceStatusClose', 'e-invoice-status-close-img', 'invoiceStatusCloseAction'),
            InvoiceStatusUpdate: new this.Button('invoice-status-update', 'ActionPanelInvoiceStatusUpdate', 'e-invoice-status-update-img', 'invoiceStatusUpdateAction'),
            InvoiceStatusInfo: new this.Button('invoice-status-info', 'ActionPanelInvoiceStatusInfo', 'e-invoice-status-info-img', 'invoiceStatusInfoAction'),
            Share: new this.Button('share', 'ActionPanelShare', 'e-share-img', 'shareAction'),
            SetDefault: new this.Button('set-default', 'ActionPanelReset', 'e-setdefault-img', 'setDefaultAction'),
            JobRunning: new this.Button('job-running', 'ActionPanelRunning', 'e-process-debug_run-img', 'runningAction'),
            JobSuspend: new this.Button('job-suspend', 'ActionPanelSuspend', 'e-process-debug_pause-img', 'suspendAction'),
            JobHelo: new this.Button('job-helo', 'ActionPanelHelo', 'e-process-properties-img', 'heloAction'),
            RemoteHelo: new this.Button('job-remoteHelo', 'ActionPanelRemoteHelo', 'e-process-properties-img', 'remoteHeloAction'),
            JobStopCheck: new this.Button('job-stopcheckresponse', 'ActionPanelStopCheckResponse', 'e-process-debug_stop-img', 'stopCheckResponseAction'),
            PlaceHolder: new this.Button('placeholder-add', 'ActionPanelAddPlaceholder', 'e-placeholder-add-img', 'addPlaceholderAction'),
        };
        //#endregion

        this.navigationService = navigationService;
        this.viewMode = this.navigationService.currentViewMode();

        if (!virtualContext) {
            this.isMa = false;
        } else {
            this.isMa = virtualContext.IsMa === true ? true : false;
        }
        this.SECTIONKEY = { home_navigation: 'home_navigation', home_new: 'home_new', home_list: 'home_list', home_manage: 'home_manage' };
        this.BREAKPOINTUI = { none: 'none', xs: 'xs', sm: 'sm', md: 'md', lg: 'lg' };
        this.buttonsMode = buttonsMode;

        this.hasPrintTab = false;
        this.tabIndexSelected = 0;
        this.isHidden = false;
        this.tabs = [];
        this.isUnderFTC = false;

        if (this.isMa) {
            // tab documentation
            var section = new this.Section("ActionPanelDocumentationElement", null, 1);
            // @ts-ignore
            section.AlignType = ej.Ribbon.AlignType.Rows;
            section.Groups[0].Buttons = [this.Buttons.Doc];
            this.tabs.push(new this.DocTab([section]));
        }
    }

    setActionWorkflowButtons = function (selection, canChangeSurveyWorkflowStatus) {
        this.setRibbonStatusButton(this.Buttons.WorkflowStatusDelete, false);
        this.setRibbonStatusButton(this.Buttons.WorkflowStatusModify, false);
        this.setRibbonStatusButton(this.Buttons.WorkflowStatusInfo, false);

        if (Lazy(selection).some()) {
            if (canChangeSurveyWorkflowStatus) {
                this.setRibbonStatusButton(this.Buttons.WorkflowStatusDelete, Lazy(selection).length() > 0);
                this.setRibbonStatusButton(this.Buttons.WorkflowStatusModify, Lazy(selection).length() > 0);
                this.setRibbonStatusButton(this.Buttons.WorkflowStatusInfo, Lazy(selection).length() === 1);
            }
        }
    }

    getButtonByName = function (buttonName) {
        if (!buttonName) {
            return;
        }

        var button = this.Buttons[buttonName];
        if (!button) {
            console.log('getButtonByName: buttonName not found', buttonName);
        }

        return button;
    }

    disableAllRibbonStatusButtons = function () {
        var exceptdIds = [];
        exceptdIds.push(this.Buttons.Print.Id);
        exceptdIds.push(this.Buttons.Back.Id);
        exceptdIds.push(this.Buttons.Doc.Id);

        Lazy(this.Buttons)
            .each((button) => {
                if (button) {
                    if (!Lazy(exceptdIds).contains(button.Id)) {
                        this.setRibbonStatusButton(button, false);
                    } else {
                        this.setRibbonStatusButton(button, true);
                    }
                }
            });
    };

    setRibbonStatusButtonByName = function (buttonName, status) {
        if (!buttonName) {
            return;
        }

        var button = this.getButtonByName(buttonName);
        this.setRibbonStatusButton(button, status);
    }

    setRibbonStatusButton = function (button, status) {
        if (!button) {
            return;
        }

        var isBtnModify = button.Id === this.Buttons.Modify.Id;
        var isBtnSave = button.Id === this.Buttons.Save.Id;


        /*
         * status
         *  true => enabled
         *  false => disabled
        */
        status = status === true ? true : false;

        var all = Lazy(this.tmpStatusButtons).toArray();

        var currButton = Lazy(all).findWhere({ key: button.Id });
        if (!currButton) {
            all.push({ isEnable: status, key: button.Id });
        } else {
            currButton.isEnable = status;
        }

        if (isBtnModify) {
            var currButton = Lazy(all).findWhere({ key: this.Buttons.Modify2.Id });
            if (!currButton) {
                all.push({ isEnable: status, key: this.Buttons.Modify2.Id });
            } else {
                currButton.isEnable = status;
            }
        } else if (isBtnSave) {
            var currButton = Lazy(all).findWhere({ key: this.Buttons.Save2.Id });
            if (!currButton) {
                all.push({ isEnable: status, key: this.Buttons.Save2.Id });
            } else {
                currButton.isEnable = status;
            }
        }

        this.tmpStatusButtons = all;
    };

    refreshStatusButtons(angularTimeout) {
        if (angularTimeout) {
            angularTimeout(() => {
                this.statusButtons = this.tmpStatusButtons;
            });
        } else {
            this.statusButtons = this.tmpStatusButtons;
        }

    }

    //#region ribbon view navigation (views buttons)
    onRibbonGrid_Asset = function (navParams) {
        var stateTo = '';
        switch (navParams.sourceEntityNavCode) {
            case 'TreAssetDto': stateTo = 'app.nav.treassetslist'; break;
            case 'VirtualEquipment': stateTo = 'app.nav.assetslist'; break;
            case 'AssetTree': stateTo = 'app.nav.assetslist'; break;
        }

        if (this.viewMode !== '_') {
            stateTo += '-' + this.viewMode;
        }

        this.navigationService.navigateTo(stateTo, navParams);
    };
    onRibbonStream_Asset = function (navParams) {
        var stateTo = '';
        switch (navParams.sourceEntityNavCode) {
            case 'TreAssetDto': stateTo = 'app.nav.assetsstream'; break;
            case 'VirtualEquipment': stateTo = 'app.nav.assetsstream'; break;
            case 'AssetTree': stateTo = 'app.nav.assetsstream'; break;
        }

        if (this.viewMode !== '_') {
            stateTo += '-' + this.viewMode;
        }

        this.navigationService.navigateTo(stateTo, navParams);
    };
    onRibbonTree_Asset = function (navParams) {
        var stateTo = 'app.nav.assetstree';
        if (this.viewMode !== '_') {
            stateTo += '-' + this.viewMode;
        }

        this.navigationService.navigateTo(stateTo, navParams);
    };
    onRibbonGrid_Act = function (navParams) {
        var stateTo = 'app.nav.actslist';
        if (this.viewMode !== '_') {
            stateTo += '-' + this.viewMode;
        }
        this.navigationService.navigateTo(stateTo, navParams);
    };
    onRibbonStream_Act = function (navParams) {
        var stateTo = 'app.nav.actstream';
        if (this.viewMode !== '_') {
            stateTo += '-' + this.viewMode;
        }
        this.navigationService.navigateTo(stateTo, navParams);
    };
    onRibbonBoardWorkflow_Act = function (navParams) {
        var stateTo = 'app.nav.actboardworkflow';
        if (this.viewMode !== '_') {
            stateTo += '-' + this.viewMode;
        }
        this.navigationService.navigateTo(stateTo, navParams);
    };
    onRibbonBoard_Act = function (navParams) {
        var stateTo = 'app.nav.actboardduedate';
        if (this.viewMode !== '_') {
            stateTo += '-' + this.viewMode;
        }
        this.navigationService.navigateTo(stateTo, navParams);
    };
    onRibbonGrid_MyAct = function (navParams) {
        var stateTo = 'app.nav.myactslist';
        if (this.viewMode !== '_') {
            stateTo += '-' + this.viewMode;
        }
        this.navigationService.navigateTo(stateTo, navParams);
    };
    onRibbonStream_MyAct = function (navParams) {
        var stateTo = 'app.nav.myactstream';
        if (this.viewMode !== '_') {
            stateTo += '-' + this.viewMode;
        }
        this.navigationService.navigateTo(stateTo, navParams);
    };
    onRibbonBoardWorkflow_MyAct = function (navParams) {
        var stateTo = 'app.nav.myactboardworkflow';
        if (this.viewMode !== '_') {
            stateTo += '-' + this.viewMode;
        }
        this.navigationService.navigateTo(stateTo, navParams);
    };
    onRibbonBoard_MyAct = function (navParams) {
        var stateTo = 'app.nav.myactboardduedate';
        if (this.viewMode !== '_') {
            stateTo += '-' + this.viewMode;
        }
        this.navigationService.navigateTo(stateTo, navParams);
    };
    onRibbonGrid_VhrEvent = function (navParams) {
        this.navigationService.navigateTo("app.nav.vhreventslist-" + this.viewMode, navParams);
    };
    onRibbonBoardStatus_VhrEvent = function (navParams) {
        this.navigationService.navigateTo("app.nav.vhreventsboardlifestatus-" + this.viewMode, navParams);
    };
    onRibbonStream_VhrEvent = function (navParams) {
        this.navigationService.navigateTo("app.nav.vhreventsstream-" + this.viewMode, navParams);
    };
    onRibbonBoard_VhrEvent = function (navParams) {
        this.navigationService.navigateTo("app.nav.vhreventsboardduedate-" + this.viewMode, navParams);
    };
    onRibbonBoardApproval_VhrEvent = function (navParams) {
        this.navigationService.navigateTo("app.nav.vhreventsboardapproval-" + this.viewMode, navParams);
    };
    //#endregion

    //#region methods
    setUnderBreakpointSmall() {
        this.isHidden = NbsCommonUI["isUnderBreakpoint_" + this.BREAKPOINTUI.sm]();
    }

    addTab(tab) {
        if (!tab) {
            return;
        }

        this.tabs.push(tab);
    }

    addHomeTab(params) {
        if (!params) {
            params = {};
        }

        this.addTab(this.GetHomeTab(params));
    }

    addSearchTab() {
        this.addTab(this.GetSearchTab());
    }

    addPrintReportTab(printReportTabName) {
        if (!printReportTabName) { printReportTabName = null; }
        this.addTab(this.GetPrintReportTab(printReportTabName));
        this.hasPrintTab = true;
    }

    addHomeSection(section) {
        var tabHome = Lazy(this.tabs).findWhere({ IsTabHome: true });
        if (tabHome) {
            tabHome.Sections.push(section);
        }
    }

    replaceSectionWith(sectionKey, newSection) {
        Lazy(this.tabs)
            .each((tab) => {
                var sections = [];
                Lazy(tab.Sections)
                    .each((section) => {
                        if (section.Key === sectionKey) {
                            sections.push(newSection);
                        } else {
                            sections.push(section);
                        }
                    });

                tab.Sections = sections;
            });
    }
    //#endregion

    //#region TABS
    GetHomeTab(params) {
        var sections = [];

        if (!params.showGroupArchive) { params.showGroupArchive = false; }
        if (!params.showGroupReports) { params.showGroupReports = false; }
        if (!params.showGroupSearch) { params.showGroupSearch = false; }
        if (params.showGroupNew === null || params.showGroupNew === undefined) { params.showGroupNew = true; }
        if (params.showGroupManage === null || params.showGroupManage === undefined) { params.showGroupManage = true; }
        if (params.showGroupPanel === null || params.showGroupPanel === undefined) { params.showGroupPanel = true; }
        if (params.showGroupNavigation === null || params.showGroupNavigation === undefined) { params.showGroupNavigation = true; }

        if (params.showGroupNavigation) { sections.push(this.GetNavigationSection()); }
        if (params.showGroupNew) { sections.push(this.GetNewSection()); }
        if (params.showGroupManage) { sections.push(this.GetManageSection()); }
        if (params.showGroupArchive) { sections.push(this.GetArchiveSection()); }
        if (params.showGroupPanel) { sections.push(this.GetListSection()); }
        if (params.showGroupSearch) { sections.push(this.GetSearchSection()); }
        if (params.showGroupReports) { sections.push(this.GetPrintReportSection()); }

        // returns home tab
        return new this.HomeTab(sections);
    }
    GetVhrEventViewsTab() { return new this.Tab("ActionPanelTabViews", [this.GetViewsModeSection(), this.GetVhrEventBoardSection()]); }
    GetAssetViewsTab() { return new this.Tab("ActionPanelTabViews", [this.GetAssetViewsModeSection()]); }
    GetScheduledTaskTab() { return new this.Tab("ActionPanelTabManage", [this.GetScheduledTaskSection()]); }
    GetActViewsTab() { return new this.Tab("ActionPanelTabViews", [this.GetViewsModeSection(), this.GetActBoardSection()]); }
    GetSearchTab() { return new this.SearchTab([this.GetSearchSection()]); }
    GetPrintReportTab(name) { return new this.PrintTab(name, [this.GetPrintReportSection()]); }
    //#endregion

    //#region SECTIONS
    GetTargetNewSection() {
        var section = new this.Section("ActionPanelGroupNew", null, 1);
        // @ts-ignore
        section.AlignType = ej.Ribbon.AlignType.Rows;
        section.Groups[0].Buttons = [this.Buttons.NewTarget, this.Buttons.NewFolder];
        return section;
    }
    GetNewSection() {
        var section = new this.Section("ActionPanelGroupNew", this.SECTIONKEY.home_new, 1);
        // @ts-ignore
        section.AlignType = ej.Ribbon.AlignType.Rows;
        section.Groups[0].Buttons = [this.Buttons.New, this.Buttons.NewFolder];
        return section;
    }
    GetActVhrNewSection() {
        var section = new this.Section("ActionPanelGroupNew", null, 1);
        // @ts-ignore
        section.AlignType = ej.Ribbon.AlignType.Rows;
        section.Groups[0].Buttons = [this.Buttons.NewTask];
        return section;
    }
    GetActNewSection() {
        var section = new this.Section("ActionPanelGroupNew", null, 1);
        // @ts-ignore
        section.AlignType = ej.Ribbon.AlignType.Rows;
        section.Groups[0].Buttons = [this.Buttons.NewTask, this.Buttons.NewFolder];
        return section;
    }
    GetCategoryDefaultSection() {
        var section = new this.Section("ActionPanelGroupDefault", null, 1);
        // @ts-ignore
        section.AlignType = ej.Ribbon.AlignType.Rows;
        section.Groups[0].Buttons = [this.Buttons.AddDefault, this.Buttons.RemoveDefault];
        return section;
    }
    GetAccessKeySection() {
        var section = new this.Section("ActionPanelGroupProgrammaticUser", null, 1);
        // @ts-ignore
        section.AlignType = ej.Ribbon.AlignType.Rows;
        section.Groups[0].Width = 75;
        section.Groups[0].Buttons = [this.Buttons.ProgrammaticAccess];
        return section;
    }
    GetLocalUserSection() {
        var section = new this.Section("ActionPanelGroupLocalUser", null, 1);
        // @ts-ignore
        section.AlignType = ej.Ribbon.AlignType.Rows;
        section.Groups[0].Buttons = [this.Buttons.NewAccountLocal, this.Buttons.ResetPasswordLocal, this.Buttons.ProgrammaticAccess];
        section.Groups[0].Width = 75;
        return section;
    }
    GetSecureCodeSection() {
        var section = new this.Section("ActionPanelGroupSecureCode", null, 1);
        // @ts-ignore
        section.AlignType = ej.Ribbon.AlignType.Rows;
        section.Groups[0].Buttons = [this.Buttons.RenewSecretCode];
        return section;
    }
    GetIdpSection() {
        var section = new this.Section("ActionPanelGroupAs", null, 1);
        // @ts-ignore
        section.AlignType = ej.Ribbon.AlignType.Rows;
        section.Groups[0].Buttons = [this.Buttons.SsoPasswordRecovery, this.Buttons.SsoUserSyncro];
        return section;
    }
    GetSupportSection() {
        var section = new this.Section("ActionPanelGroupSupport", null, 1);
        // @ts-ignore
        section.AlignType = ej.Ribbon.AlignType.Rows;
        section.Groups[0].Buttons = [this.Buttons.SupportUserSyncro];
        return section;
    }
    GetSchedulingSection() {
        var section = new this.Section("ActionPanelGroupScheduling", null, 2);
        section.Groups[0].Buttons = [this.Buttons.RunScheduling];
        section.Groups[1].Buttons = [this.Buttons.AddScheduledTask];
        return section;
    }
    GetMetricSection() {
        var section = new this.Section("ActionPanelGroupMetric", null, 1);
        // @ts-ignore
        section.AlignType = ej.Ribbon.AlignType.Rows;
        section.Groups[0].Buttons = [this.Buttons.RunMetric];
        return section;
    }
    GetAssociationStatusSection() {
        var section = new this.Section("ActionPanelGroupAssociationStatus", null, 2);
        section.Groups[0].Buttons = [this.Buttons.AssociationStatusUpdate];
        section.Groups[1].Buttons = [this.Buttons.AssociationStatusInfo];
        section.Groups[1].IsBigButton = false;
        section.Groups[1].Width = 120;
        return section;
    }
    GetSyncCustomerSection() {
        var section = new this.Section("Update", null, 2);
        section.Groups[0].Buttons = [this.Buttons.Sync];
        section.Groups[1].Buttons = [this.Buttons.Send];
        return section;
    }
    GetNavigationSection() {
        var section = new this.Section("ActionPanelGroupNavigation", this.SECTIONKEY.home_navigation, 1);
        // @ts-ignore
        section.AlignType = ej.Ribbon.AlignType.Rows;
        section.Groups[0].Buttons = [this.Buttons.Back];
        return section;
    }
    GetCommentSection() {
        var section = new this.Section("ActionPanelGroupNotes", null, 1);
        // @ts-ignore
        section.AlignType = ej.Ribbon.AlignType.Rows;
        section.Groups[0].Buttons = [this.Buttons.Comment];
        return section;
    }
    GetActManageSection() {
        var section = new this.Section("ActionPanelGroupManage", null, 2);
        section.Groups[0].Buttons = [this.Buttons.Modify2];
        section.Groups[1].Buttons = [this.Buttons.Save2];
        section.Groups[1].IsBigButton = false;
        section.Groups[1].Width = 120;
        return section;
    }
    GetListSection() {
        var section = new this.Section("ActionPanelGroupPanel", this.SECTIONKEY.home_list, 1);
        // @ts-ignore
        section.AlignType = ej.Ribbon.AlignType.Rows;
        section.Groups[0].Buttons = [this.Buttons.Refresh, this.Buttons.Print, this.Buttons.ExportExcel, this.Buttons.ExportWord];
        return section;
    }
    GetExportExcelIntegrationSection() {
        var section = new this.Section("ActionPanelGroupExport", null, 1);
        // @ts-ignore
        section.AlignType = ej.Ribbon.AlignType.Rows;
        section.Groups[0].Buttons = [this.Buttons.ExportIntegrationExcel];
        return section;
    }
    GetArchiveSection() {
        var section = new this.Section("ActionPanelGroupArchive", null, 1);
        // @ts-ignore
        section.AlignType = ej.Ribbon.AlignType.Rows;
        section.Groups[0].Buttons = [this.Buttons.Archive, this.Buttons.Extract];
        return section;
    }
    GetManageSection() {
        var section = new this.Section("ActionPanelGroupManage", this.SECTIONKEY.home_manage, 3);
        section.Groups[0].Buttons = [this.Buttons.Modify];
        section.Groups[1].Buttons = [this.Buttons.Delete];
        section.Groups[2].Buttons = [this.Buttons.OpenDetail, this.Buttons.Copy, this.Buttons.Save];
        section.Groups[2].Width = 120;
        section.Groups[2].IsBigButton = false;
        return section;
    }
    GetViewsModeSection() {
        var section = new this.Section("ActionPanelGroupViews", null, 3);
        section.Groups[0].Buttons = [this.Buttons.ViewList];
        section.Groups[1].Buttons = [this.Buttons.ViewCalendar];
        section.Groups[1].Width = 70;
        section.Groups[2].Buttons = [this.Buttons.ViewStream];
        return section;
    }
    GetAssetViewsModeSection() {
        var section = new this.Section("ActionPanelGroupViews", null, 3);
        section.Groups[0].Buttons = [this.Buttons.ViewList];
        section.Groups[1].Buttons = [this.Buttons.ViewStream];
        section.Groups[2].Buttons = [this.Buttons.ViewTree];
        section.Groups[2].Width = 70;
        return section;
    }
    GetVhrEventBoardSection() {
        var section = new this.Section("ActionPanelGroupBoard", null, 2);
        section.Groups[0].Buttons = [this.Buttons.Board];
        section.Groups[1].Buttons = [this.Buttons.BoardStatus, this.Buttons.BoardApproval];
        section.Groups[1].IsBigButton = false;
        section.Groups[1].Width = 120;
        return section;
    }
    GetActBoardSection() {
        var section = new this.Section("ActionPanelGroupBoard", null, 2);
        section.Groups[0].Buttons = [this.Buttons.Board];
        section.Groups[1].Buttons = [this.Buttons.BoardWorkflow];
        section.Groups[1].Width = 120;
        section.Groups[1].IsBigButton = false;
        return section;
    }
    GetSearchSection() {
        var section = new this.Section("ActionPanelGroupSearch", null, 3);
        section.Groups[0].Buttons = [this.Buttons.Search];
        section.Groups[1].Buttons = [this.Buttons.AddFilter];
        section.Groups[2].Buttons = [this.Buttons.RemoveFilter, this.Buttons.ResetFilter];
        section.Groups[2].Width = 120;
        section.Groups[2].IsBigButton = false;
        return section;
    }
    GetPrintReportSection() {
        var section = new this.Section("ActionPanelGroupReport", null, 4);
        section.Groups[0].Buttons = [this.Buttons.PrintReportPdf];
        section.Groups[1].Buttons = [this.Buttons.PrintReportHtml];
        section.Groups[2].Buttons = [this.Buttons.PrintReport];
        section.Groups[3].Buttons = [this.Buttons.PrintReportWord, this.Buttons.PrintReportExcel, this.Buttons.PrintReportXml];
        section.Groups[3].Width = 120;
        section.Groups[3].IsBigButton = false;
        return section;
    }
    GetWarningsSection() {
        var section = new this.Section("ActionPanelGroupWarnings", null, 2);
        section.Groups[0].Buttons = [this.Buttons.WarningModify];
        section.Groups[1].Buttons = [this.Buttons.WarningDelete, this.Buttons.WarningInfo];
        section.Groups[1].Width = 120;
        section.Groups[1].IsBigButton = false;
        return section;
    }
    GetActWorkflowsSection() {
        var section = new this.Section("ActionPanelGroupWorkflowsStatuses", null, 3);
        section.Groups[0].Buttons = [this.Buttons.WorkflowStatusExecute];
        section.Groups[1].Buttons = [this.Buttons.WorkflowStatusModify];
        section.Groups[2].Buttons = [this.Buttons.WorkflowStatusInfo];
        section.Groups[2].Width = 120;
        section.Groups[2].IsBigButton = false;
        return section;
    }
    GetVhrEventFailuresLifeStatusSection() {
        var section = new this.Section("ActionPanelGroupVhrEventsLifeStatus", null, 3);
        section.Groups[0].Buttons = [this.Buttons.VhrEventFailureLifeStatusComplete];
        section.Groups[1].Buttons = [this.Buttons.VhrEventLifeStatusModify];
        section.Groups[2].Buttons = [this.Buttons.VhrEventFailureLifeStatusClose, this.Buttons.VhrEventLifeStatusInfo];
        section.Groups[2].Width = 120;
        section.Groups[2].IsBigButton = false;
        return section;
    }
    GetScheduledTaskSection() {
        var section = new this.Section("ActionPanelGroupCommand", null, 2);
        section.Groups[0].Buttons = [this.Buttons.Running];
        section.Groups[0].Width = 80;
        section.Groups[1].Buttons = [this.Buttons.Suspend];
        section.Groups[1].Width = 90;
        return section;
    }
    GetVhrEventWorkflowsSection() {
        var section = new this.Section("ActionPanelGroupWorkflowsStatuses", null, 2);
        section.Groups[0].Buttons = [this.Buttons.WorkflowStatusModify];
        section.Groups[1].Buttons = [this.Buttons.WorkflowStatusDelete, this.Buttons.WorkflowStatusInfo];
        section.Groups[1].Width = 120;
        section.Groups[1].IsBigButton = false;
        return section;
    }
    GetPublishSchemaSection() {
        var section = new this.Section("ActionPanelPublish", null, 1);
        section.Groups[0].Buttons = [this.Buttons.PublishSchema];
        return section;
    }
    GetActiveSection() {
        var section = new this.Section("ActionPanelActive", null, 1);
        section.Groups[0].Buttons = [this.Buttons.Active];
        return section;
    }
    GetVhrEventsDateSection() {
        var section = new this.Section("ActionPanelGroupDate", null, 1);
        // @ts-ignore
        section.AlignType = ej.Ribbon.AlignType.Rows;
        section.Groups[0].Buttons = [this.Buttons.ChangeDate];
        return section;
    }
    GetSendMailSection() {
        var section = new this.Section("ActionPanelGroupActions", null, 1);
        section.Groups[0].Buttons = [this.Buttons.SendMail];
        return section;
    }
    GetSendNotificationSection() {
        var section = new this.Section("ActionPanelGroupNotifications", null, 1);
        section.Groups[0].Buttons = [this.Buttons.SendNotification];
        return section;
    }
    GetJobsSection() {
        var section = new this.Section("ActionPanelGroupCommand", null, 5);
        section.Groups[0].Buttons = [this.Buttons.JobRunning];
        section.Groups[1].Buttons = [this.Buttons.JobSuspend];
        section.Groups[2].Buttons = [this.Buttons.JobHelo];
        section.Groups[3].Buttons = [this.Buttons.RemoteHelo];
        section.Groups[4].Buttons = [this.Buttons.JobStopCheck];
        return section;
    }
    GetPlaceholderSection() {
        var section = new this.Section("ActionPanelGroupPlaceholder", null, 1);
        // @ts-ignore
        section.AlignType = ej.Ribbon.AlignType.Rows;
        section.Groups[0].Buttons = [this.Buttons.PlaceHolder];
        return section;
    }
    GetVhrEventsLifeStatusSection() {
        var section = new this.Section("ActionPanelGroupVhrEventsLifeStatus", null, 4);
        section.Groups[0].Buttons = [this.Buttons.VhrEventLifeStatusApprove];
        section.Groups[1].Buttons = [this.Buttons.VhrEventLifeStatusMultiApprove];
        section.Groups[2].Buttons = [this.Buttons.VhrEventLifeStatusModify];
        section.Groups[3].Buttons = [this.Buttons.VhrEventLifeStatusInfo];
        section.Groups[3].Width = 120;
        section.Groups[3].IsBigButton = false;
        return section;
    }
    GetVhrEventAssignmentsSection() {
        var section = new this.Section("ActionPanelGroupAssignment", null, 3);
        section.Groups[0].Buttons = [this.Buttons.VhrEventAssignmentExecute];
        section.Groups[1].Buttons = [this.Buttons.VhrEventAssignmentModify];
        section.Groups[2].Buttons = [this.Buttons.VhrEventAssignmentInfo];
        section.Groups[2].Width = 120;
        section.Groups[2].IsBigButton = false;
        return section;
    }
    GetVhrEventsExecutionSection() {
        var section = new this.Section("ActionPanelGroupVhrEventsExecution", null, 3);
        section.Groups[0].Buttons = [this.Buttons.VhrEventExecutionApprove];
        section.Groups[1].Buttons = [this.Buttons.VhrEventExecutionModify];
        section.Groups[2].Buttons = [this.Buttons.VhrEventExecutionInfo];
        section.Groups[2].Width = 120;
        section.Groups[2].IsBigButton = false;
        return section;
    }
    GetVhrEventsApprovalSection() {
        var section = new this.Section("ActionPanelGroupVhrEventsApproval", null, 3);
        section.Groups[0].Buttons = [this.Buttons.VhrEventApprovalAuthorize];
        section.Groups[1].Buttons = [this.Buttons.VhrEventApprovalModify];
        section.Groups[2].Buttons = [this.Buttons.VhrEventApprovalInfo];
        section.Groups[2].Width = 120;
        section.Groups[2].IsBigButton = false;
        return section;
    }
    GetVhrEventsCustomerApprovalSection() {
        var section = new this.Section("ActionPanelGroupVhrEventsCustomerApproval", null, 3);
        section.Groups[0].Buttons = [this.Buttons.VhrEventCustomerApprovalAuthorize];
        section.Groups[1].Buttons = [this.Buttons.VhrEventCustomerApprovalModify];
        section.Groups[2].Buttons = [this.Buttons.VhrEventCustomerApprovalInfo];
        section.Groups[2].Width = 120;
        section.Groups[2].IsBigButton = false;
        return section;
    }
    GetBillingLifeStatusSection() {
        var section = new this.Section("ActionPanelGroupBillingStatus", null, 2);
        section.Groups[0].Buttons = [this.Buttons.BillingLifeStatusModify];
        section.Groups[1].Buttons = [this.Buttons.BillingLifeStatusInfo];
        section.Groups[1].Width = 120;
        section.Groups[1].IsBigButton = false;
        return section;
    }
    GetInvoiceStatusSection() {
        var section = new this.Section("ActionPanelGroupInvoiceStatus", null, 3);
        section.Groups[0].Buttons = [this.Buttons.InvoiceStatusClose];
        section.Groups[1].Buttons = [this.Buttons.InvoiceStatusUpdate];
        section.Groups[2].Buttons = [this.Buttons.InvoiceStatusInfo];
        section.Groups[2].Width = 120;
        section.Groups[2].IsBigButton = false;
        return section;
    }
    GetInvoicRegisterSection() {
        var section = new this.Section("ActionPanelGroupInvoiceRegisterSelection", null, 1);
        // @ts-ignore
        section.AlignType = ej.Ribbon.AlignType.Rows;
        section.Groups[0].Buttons = [this.Buttons.InvoiceRegister];
        return section;
    }
    GetSharingSection() {
        var section = new this.Section("ActionPanelGroupVirtualContext", null, 1);
        // @ts-ignore
        section.AlignType = ej.Ribbon.AlignType.Rows;
        section.Groups[0].Buttons = [this.Buttons.Share];
        return section;
    }
    GetSetDefaultSection() {
        var section = new this.Section("ActionPanelGroupDefault", null, 1);
        // @ts-ignore
        section.AlignType = ej.Ribbon.AlignType.Rows;
        section.Groups[0].Buttons = [this.Buttons.SetDefault];
        return section;
    }
    GetModifyAllSection() {
        var section = new this.Section("ActionPanelGroupModify", null, 1);
        // @ts-ignore
        section.AlignType = ej.Ribbon.AlignType.Rows;
        section.Groups[0].Buttons = [this.Buttons.ModifyAll];
        section.Groups[0].Width = 90;
        return section;
    }
    //#endregion    
}

class NbsIntervalRange {
    expressionTypeLeftDs: any;
    expressionTypeRightDs: any;
    dateIntervalDs: any;
    constructor(params) {
        if (!params) {
            throw new Error('NbsIntervalRange: params mandatory');
        }
        if (!params.expressionTypeEnumParsed) {
            throw new Error('NbsIntervalRange: expressionTypeEnumParsed mandatory');
        }
        if (!params.dateIntervalEnumParsed) {
            throw new Error('NbsIntervalRange: dateIntervalEnum mandatory');
        }

        var expressionTypeEnumParsed = params.expressionTypeEnumParsed;
        var dateIntervalEnumParsed = params.dateIntervalEnumParsed;

        this.expressionTypeLeftDs = Lazy(expressionTypeEnumParsed.list.translated)
            .filter((i) => {
                return i.value === expressionTypeEnumParsed.obj.normal.name_value.GreaterThan
                    || i.value === expressionTypeEnumParsed.obj.normal.name_value.GreaterThanOrEqual;
            })
            .toArray();

        this.expressionTypeRightDs = Lazy(expressionTypeEnumParsed.list.translated)
            .filter((i) => {
                return i.value === expressionTypeEnumParsed.obj.normal.name_value.LessThan
                    || i.value === expressionTypeEnumParsed.obj.normal.name_value.LessThanOrEqual;
            })
            .toArray();


        this.dateIntervalDs = Lazy(dateIntervalEnumParsed.list.translated).toArray();
    }
}


export class NbsUiOption {
    optionOrder: any;
    optionValue: any;
    optionName: any;
    optionCode: any;
    optionDescription: any;
    optionDisplayName: any;
    optionKind: any;
    optionStyle: any;
    optionIconCode: any;
    optionVisible: any;
    optionReadonly: any;
    parentOptionValue: any;
    hasChildren: any;
    isChecked: any;
    canCheck: any;
    isEnabled: any;
    isEnabledFilter: any;
    source: any;
    groupCode: any;
    isFolderChecked: any;
    canDeleted: any;
    canCheckedFolder: any;
    selectionOrder: any;

    constructor(obj?) {
        obj = obj || {};

        this.optionOrder = obj.optionOrder === undefined || obj.optionOrder === null ? 0 : obj.optionOrder;
        this.optionValue = obj.optionValue === undefined || obj.optionValue === null ? null : obj.optionValue;
        this.optionName = obj.optionName === undefined || obj.optionName === null ? null : obj.optionName;
        this.optionCode = obj.optionCode === undefined || obj.optionCode === null ? null : obj.optionCode;
        this.optionDescription = obj.optionDescription === undefined || obj.optionDescription === null ? null : obj.optionDescription;
        this.optionDisplayName = obj.optionDisplayName === undefined || obj.optionDisplayName === null ? null : obj.optionDisplayName;
        this.optionKind = obj.optionKind === undefined || obj.optionKind === null ? null : obj.optionKind;
        this.optionStyle = obj.optionStyle === undefined || obj.optionStyle === null ? null : obj.optionStyle;
        this.optionIconCode = obj.optionIconCode === undefined || obj.optionIconCode === null ? null : obj.optionIconCode;
        this.optionVisible = obj.optionVisible === undefined || obj.optionVisible === null ? true : obj.optionVisible;
        this.optionReadonly = obj.optionReadonly === undefined || obj.optionReadonly === null ? false : obj.optionReadonly;
        this.parentOptionValue = obj.parentOptionValue === undefined || obj.parentOptionValue === null ? null : obj.parentOptionValue;
        this.hasChildren = obj.hasChildren === undefined || obj.hasChildren === null ? false : obj.hasChildren;
        this.isChecked = obj.isChecked === undefined || obj.isChecked === null ? false : obj.isChecked;
        this.canCheck = obj.canCheck === undefined || obj.canCheck === null ? false : obj.canCheck;
        this.isEnabled = obj.isEnabled === undefined || obj.isEnabled === null ? false : obj.isEnabled;
        this.isEnabledFilter = obj.isEnabledFilter === undefined || obj.isEnabledFilter === null ? null : obj.isEnabledFilter;  // if setted (true or false) active (enabled/disabled action buttons in nbsDdSearchMsComponent)
        this.source = obj.source === undefined || obj.source === null ? null : obj.source;
        this.groupCode = obj.groupCode === undefined || obj.groupCode === null ? null : obj.groupCode;
        this.isFolderChecked = obj.isFolderChecked === undefined || obj.isFolderChecked === null ? false : obj.isFolderChecked;
        this.canDeleted = obj.canDeleted === undefined || obj.canDeleted === null ? false : obj.canDeleted;
        this.canCheckedFolder = obj.canCheckedFolder === undefined || obj.canCheckedFolder === null ? false : obj.canCheckedFolder;
        this.selectionOrder = obj.selectionOrder === undefined || obj.selectionOrder === null ? null : obj.selectionOrder;
    }
}

export class NbsUiOptionParser {
    translator: any;
    optionkey: any;
    constructor(translator) {
        this.translator = translator;
        this.optionkey = {
            Vhr: { optionValue: 'VirtualHumanResourceId', optionCode: 'VirtualCode', optionName: 'VirtualName', optionDisplayName: 'VirtualCode+VirtualName' },
            Category: { optionValue: 'CategoryId', optionCode: 'CategoryCode', optionName: 'CategoryName', optionDisplayName: 'CategoryCode+CategoryName' },
            VhrTarget: { optionValue: 'VirtualHumanResourceId', optionCode: 'TargetCode', optionName: 'TargetName', optionDescription: 'TargetDescription', optionDisplayName: 'TargetName' },
            AppUserDto: { optionValue: 'UserPersonId', optionName: 'DisplayNameUI', optionDisplayName: 'DisplayNameUI' },
            GenericElement: { optionValue: 'key', optionName: 'value', optionDisplayName: 'value' },
            VhrType: { optionValue: 'VhrTypeId', optionCode: 'VhrTypeCode', optionName: 'VhrTypeName', optionDescription: 'VhrTypeDescription', optionDisplayName: 'VhrTypeName' },
            VhrGroup: { optionValue: 'VhrGroupId', optionCode: 'VhrGroupCode', optionName: 'VhrGroupName', optionDescription: 'VhrGroupDescription', optionDisplayName: 'VhrGroupName' },
            VhrEventType: { optionValue: 'VhrEventTypeId', optionCode: 'VhrEventTypeId', optionName: 'VhrEventTypeName', optionDescription: 'VhrEventTypeDescription', optionDisplayName: 'VhrEventTypeName' },
            VhrEventGroup: { optionValue: 'VhrEventGroupId', optionCode: 'VhrEventGroupId', optionName: 'VhrEventGroupName', optionDescription: 'VhrEventGroupDescription', optionDisplayName: 'VhrEventGroupName' },
            DrbacRole: { optionValue: 'RoleId', optionName: 'RoleName', optionDisplayName: 'RoleName' },
            UserProfile: { optionValue: 'UserProfileId', optionName: 'UserProfileName', optionDisplayName: 'UserProfileName' },
            DrbacUserGroup: { optionValue: 'DrbacUserGroupId', optionName: 'DrbacUserGroupName', optionDisplayName: 'DrbacUserGroupName' },
            VirtualCompany: { optionValue: 'VirtualCompanyId', optionCode: 'VirtualCompanyId', optionName: 'VirtualCompanyName', optionDisplayName: 'VirtualCompanyName' },
            ActActionType: { optionValue: 'ActActionTypeId', optionName: 'ActActionTypeName', optionDisplayName: 'ActActionTypeName' },
            ActActionGroup: { optionValue: 'ActActionGroupId', optionName: 'ActActionGroupName', optionDisplayName: 'ActActionGroupName' },
            VhrEventLabel: { optionValue: 'VhrEventLabelId', optionCode: 'VhrEventLabelId', optionName: 'VhrEventLabelName', optionDescription: 'VhrEventLabelDescription', optionDisplayName: 'VhrEventLabelName' },
            WorkCenter: { optionValue: 'VirtualWorkCenterId', optionName: 'WorkCenterName', optionDisplayName: 'WorkCenterName' },
            WorkflowStatus: { optionValue: 'WorkflowStatusId', optionName: 'WorkflowStatusName', optionDisplayName: 'WorkflowStatusName' },
            JobRequest: { optionValue: 'SchedulingId', optionName: 'SchedulingName', optionDisplayName: 'SchedulingName' },
            ModuleTarget: { optionValue: 'ModuleTargetId', optionCode: 'ModuleTargetCode', optionName: 'ModuleTargetName', optionDisplayName: 'ModuleTargetName' },
        }
    }

    getTimelineDropdown() {
        var items = this.getTimeline();
        return Lazy(items).filter((item) => { return item.optionStyle !== 'section'; }).toArray();
    }

    getTimeline() {
        var items = [];

        // ### DAILY GROUP ###
        items.push(new NbsUiOption({ optionName: "Daily", optionCode: "", optionDisplayName: this.translator('Daily'), optionStyle: "section", optionOrder: '01.01.00' }));
        items.push(new NbsUiOption({ optionName: "Today", optionCode: "tl-day-today", optionDisplayName: this.translator('Today'), optionStyle: "subsection", optionOrder: '01.01.01', optionValue: 'd+0' }));
        items.push(new NbsUiOption({ optionName: "Yesterday", optionCode: "tl-day-yestarday", optionDisplayName: this.translator('Yesterday'), optionStyle: "subsection", optionOrder: '01.01.02', optionValue: 'FD-1' }));
        items.push(new NbsUiOption({ optionName: "Tomorrow", optionCode: "tl-day-tomorrow", optionDisplayName: this.translator('Tomorrow'), optionStyle: "subsection", optionOrder: '01.01.03', optionValue: 'FD+1' }));
        items.push(new NbsUiOption({ optionName: "Next2day", optionCode: "tl-day-next2day", optionDisplayName: this.translator('Next2day'), optionStyle: "subsection", optionOrder: '01.01.04', optionValue: 'd+2' }));
        items.push(new NbsUiOption({ optionName: "Next3day", optionCode: "tl-day-next3day", optionDisplayName: this.translator('Next3day'), optionStyle: "subsection", optionOrder: '01.01.05', optionValue: 'd+3' }));
        // ### WEEKLY GROUP ###
        items.push(new NbsUiOption({ optionName: "Weekly", optionCode: "", optionDisplayName: this.translator('Weekly'), optionStyle: "section", optionOrder: '01.02.00' }));
        items.push(new NbsUiOption({ optionName: "Next7day", optionCode: "tl-week-next7day", optionDisplayName: this.translator('Next7day'), optionStyle: "subsection", optionOrder: "01.02.01", optionValue: 'd+7' }));
        items.push(new NbsUiOption({ optionName: "Last7day", optionCode: "tl-week-last7day", optionDisplayName: this.translator('Last7day'), optionStyle: "subsection", optionOrder: "01.02.02", optionValue: 'd-7' }));
        items.push(new NbsUiOption({ optionName: "Currentweek7days", optionCode: "tl-week-currentweek7days", optionDisplayName: this.translator('Currentweek7days'), optionStyle: "subsection", optionOrder: "01.02.03", optionValue: 'Fw+0' }));
        items.push(new NbsUiOption({ optionName: "Previousweek7days", optionCode: "tl-week-previousweek7days", optionDisplayName: this.translator('Previousweek7days'), optionStyle: "subsection", optionOrder: "01.02.04", optionValue: 'Fw-1' }));
        // ### MONTHLY GROUP ###
        items.push(new NbsUiOption({ optionName: "Monthly", optionCode: "", optionDisplayName: this.translator('Monthly'), optionStyle: "section", optionOrder: "01.03.00" }));
        items.push(new NbsUiOption({ optionName: "Currentmonth", optionCode: "tl-month-currentmonth", optionDisplayName: this.translator('Currentmonth'), optionStyle: "subsection", optionOrder: "01.03.01", optionValue: 'FM+0' }));
        items.push(new NbsUiOption({ optionName: "Previousmonth", optionCode: "tl-month-previousmonth", optionDisplayName: this.translator('Previousmonth'), optionStyle: "subsection", optionOrder: "01.03.02", optionValue: 'FM-1' }));
        items.push(new NbsUiOption({ optionName: "Next30days", optionCode: "tl-month-next30days", optionDisplayName: this.translator('Next30days'), optionStyle: "subsection", optionOrder: "01.03.03", optionValue: 'd+30' }));
        items.push(new NbsUiOption({ optionName: "Previous30days", optionCode: "tl-month-previous30days", optionDisplayName: this.translator('Previous30days'), optionStyle: "subsection", optionOrder: "01.03.04", optionValue: 'd-30' }));
        // ### YEARLY GROUP ###
        items.push(new NbsUiOption({ optionName: "Yearly", optionCode: "", optionDisplayName: this.translator('Yearly'), optionStyle: "section", optionOrder: "01.04.00" }));
        items.push(new NbsUiOption({ optionName: "Currentyear", optionCode: "tl-year-currentyear", optionDisplayName: this.translator('Currentyear'), optionStyle: "subsection", optionOrder: "01.04.01", optionValue: 'FY+0' }));
        items.push(new NbsUiOption({ optionName: "Previousyear", optionCode: "tl-year-previousyear", optionDisplayName: this.translator('Previousyear'), optionStyle: "subsection", optionOrder: "01.04.02", optionValue: 'FY-1' }));
        items.push(new NbsUiOption({ optionName: "Next365days", optionCode: "tl-year-next365days", optionDisplayName: this.translator('Next365days'), optionStyle: "subsection", optionOrder: "01.04.03", optionValue: 'd+365' }));
        items.push(new NbsUiOption({ optionName: "Previous365days", optionCode: "tl-year-previous365days", optionDisplayName: this.translator('Previous365days'), optionStyle: "subsection", optionOrder: "01.04.04", optionValue: 'd-365' }));

        return items;
    }

    getUiOptions(data, mapNbsUiOptionFields) {
        if (!Lazy(data).some()) {
            return [];
        }

        mapNbsUiOptionFields = mapNbsUiOptionFields || {};

        var arrMappedFields = Object.keys(mapNbsUiOptionFields);
        if (!Lazy(arrMappedFields).some()) {
            return data;
        }

        var SEPARATOR = ' - ';
        var option = null;
        var result = [];
        Lazy(data)
            .each((item) => {
                option = new NbsUiOption();

                Lazy(arrMappedFields)
                    .each((mapField) => {
                        var mappedFieldValue = mapNbsUiOptionFields[mapField];
                        if (mappedFieldValue.indexOf('+') >= 0) {
                            // mapField: E.g. VirtualCompanyCode+VirtualCompanyName+.....
                            var fields = mappedFieldValue.split('+');

                            var index = 0
                            Lazy(fields)
                                .each((field) => {
                                    if (index === 0) {
                                        option[mapField] = item[field] || '';
                                    } else {
                                        option[mapField] += SEPARATOR + (item[field] || '');
                                    }
                                    index++;
                                });

                        } else {
                            // mapField: E.g. VirtualCompanyCode
                            option[mapField] = item[mapNbsUiOptionFields[mapField]];
                        }
                    });

                result.push(option);
            });

        return result;
    }

    getUiOptionsByEnum(data) {
        if (!Lazy(data).some()) {
            return [];
        }

        var result = this.getUiOptions(data, { optionValue: 'value', optionName: 'name', optionDisplayName: 'name' });
        return result;
    }

    getAssignment(users, userGroups, roles) {
        //appusers: [],
        //drbacusergroups: [],
        //drbacroles: []
        var result = {
            viewAppUsers: false,
            viewDrbacUserGroups: false,
            viewDrbacRoles: false,
            appusers: [],
            drbacusergroups: [],
            drbacroles: []
        };
        result.viewAppUsers = users ? true : false;
        result.viewDrbacUserGroups = userGroups ? true : false;
        result.viewDrbacRoles = roles ? true : false;

        if (result.viewAppUsers) {
            var parsedusers = NbsCommonUtil.addDisplayNameToUsersList(users);
            result.appusers = this.getUiOptions(parsedusers, { isEnabledFilter: 'IsEnabled', optionValue: 'AppUserId', optionName: 'DisplayNameUI', optionDisplayName: 'DisplayNameUI' });
        }
        if (result.viewDrbacUserGroups) {
            result.drbacusergroups = this.getUiOptions(userGroups, { optionValue: 'DrbacUserGroupId', optionCode: 'DrbacUserGroupCode', optionName: 'DrbacUserGroupName', optionDisplayName: 'DrbacUserGroupName' });
        }
        if (result.viewDrbacRoles) {
            result.drbacroles = this.getUiOptions(roles, { optionValue: 'RoleId', /*optionCode: 'DrbacRoleCode', */ optionName: 'RoleName', optionDisplayName: 'RoleName' });
        }
        return result;
    }

    getUiOptionsByKey(data, key) {
        if (!Lazy(data).some()) {
            return [];
        }

        if (!key) {
            throw new Error('getUiOptionsByKey: key mandatory');
        }

        var params = this.optionkey[key];
        var result = this.getUiOptions(data, params);

        return result;
    }
}


export class NbsUiData {
    arrayCodes: any;
    startData: any;
    constructor() {
        this.arrayCodes = [];
        this.startData = {
            versionCode: '',
            filters: { freeText: { text: "" } },
            filterList: [],     // list of filters setted as visible (checked)
            columns: {
                columns: [],
                selected: []
            },
            grouping: {
                columns: [],
                selected: []
            },
            sorting: {
                selected: [],
                direction: null       // 'ascending', 'descending'
            }
        };
    }

    setFilters(filters) {
        this.startData.filters = JSON.parse(JSON.stringify(filters));
        if (!this.startData.filters.freeText) {
            this.startData.filters.freeText = { text: "" };
        }

        this.arrayCodes.push(this.startData.filters.freeText.text);
        this.refreshVersionCode();
    };

    setFilter(key, filter) {
        this.startData.filters[key] = filter;
        if (!this.startData.filters.freeText) {
            this.startData.filters.freeText = { text: "" };
        }
        this.arrayCodes.push(key);
        this.refreshVersionCode();
    };

    setColumns(cols) {
        var tmpcols = cols || [];

        for (var i = 0; i < tmpcols.length; i++) {
            var col = tmpcols[i];
            var uiOption = new NbsUiOption({ optionValue: col });
            this.startData.columns.selected.push(uiOption);
            this.arrayCodes.push(col);
        }

        this.refreshVersionCode();
    };

    setGroupingColumns(cols) {
        var tmpcols = cols || [];
        this.startData.grouping.columns = tmpcols;

        for (var i = 0; i < tmpcols.length; i++) {
            var col = tmpcols[i];
            this.arrayCodes.push(col.optionValue);
        };

        this.refreshVersionCode();
    };

    setGroupingSelected(cols) {
        var tmpcols = cols || [];
        this.startData.grouping.selected = tmpcols;

        for (var i = 0; i < tmpcols.length; i++) {
            var col = tmpcols[i];
            this.arrayCodes.push(col.optionValue);
        };

        this.refreshVersionCode();
    };

    setFilterList(cols) {
        var tmpcols = cols || [];
        this.startData.filterList = tmpcols;

        for (var i = 0; i < tmpcols.length; i++) {
            var col = tmpcols[i];
            this.arrayCodes.push(col.optionValue);
        };

        this.refreshVersionCode();
    };

    setSortingField(field, descending) {
        if (field) {
            var uiOption = new NbsUiOption({ optionValue: field });
            this.startData.sorting.selected = [uiOption];
            this.arrayCodes.push(field);
        }

        if (descending === true) {
            this.startData.sorting.direction = 'descending';
        } else {
            // default
            this.startData.sorting.direction = 'ascending';
        }

        this.arrayCodes.push(this.startData.sorting.direction);
        this.refreshVersionCode();
    };

    refreshVersionCode() {
        this.startData.versionCode = btoa(Lazy(this.arrayCodes).join(','));
    }
}

export class NbsUiDataFilter {
    code: any;
    name: any;
    canHide: any;
    visible: any;
    readonly: any;
    constructor(options) {
        if (!options) {
            options = {
                code: null,
                name: null,
                canHide: true,
                visible: false,
                readonly: false
            }
        }

        this.code = options.code ? options.code : null;
        this.name = options.name ? options.name : null;
        this.canHide = options.canHide !== undefined ? options.canHide : true;
        this.visible = options.visible !== undefined ? options.visible : false;
        this.readonly = options.readonly !== undefined ? options.readonly : false;
    }
}

export class NbsAccordion {
    Id: any;
    FastTabs: any;
    CloseOthers: any;
    constructor() {
        this.Id = 'accordionFtc';
        this.FastTabs = [];
        this.CloseOthers = true;
    }

    AddFastTab(fastTabId, text, ngIf) {
        if (ngIf === null || ngIf === undefined) {
            ngIf = true;
        } else {
            if (ngIf === true) {
                ngIf = true;
            } else {
                ngIf = false;
            }
        }

        this.FastTabs.push({
            Id: fastTabId,
            Text: text,
            NgIf: ngIf,
            HasLink: false,
            IsFastTab: true,
            Links: []
        });
    }

    AddLink(linkId, fastTabId, text, ngIf) {
        if (ngIf === null || ngIf === undefined) {
            ngIf = true;
        } else {
            if (ngIf === true) {
                ngIf = true;
            } else {
                ngIf = false;
            }
        }

        var fastTab = Lazy(this.FastTabs).findWhere({ Id: fastTabId });
        if (fastTab) {
            fastTab.HasLink = true;
            fastTab.Links.push({
                Id: linkId,
                Text: text,
                NgIf: ngIf,
                IsLink: true,
            });
        }
    }
}

export class NbsViewContext {
    states: any;
    state: any;
    isLocked: any;
    isViewingState: any;
    isAddingState: any;
    isEditingState: any;

    constructor() {
        this.states = { isViewing: 1, isAdding: 2, isEditing: 3 };
        this.state = this.states.isViewing;
        this.isLocked = true;
        this.isViewingState = false;
        this.isAddingState = false;
        this.isEditingState = false;
    }
}

export class NbsQrCode {
    constructor() {
    }

    assetDs(uriQrCodeApiService, assetDto, width, height) {
        return this.googleQrCodeDs({
            uriQrCodeApiService: uriQrCodeApiService
            , data: { As: assetDto.RegistrationNumber, AsId: assetDto.VirtualHumanResourceId, Vc: assetDto.VirtualCompanyId }
            , width: width
            , height: height
        });
    };

    bucketDs(uriQrCodeApiService, bucket, width, height) {
        return this.googleQrCodeDs({
            uriQrCodeApiService: uriQrCodeApiService,
            data: { Bu: bucket.VirtualCode, BuId: bucket.VirtualHumanResourceId, Vc: bucket.VirtualCompanyId },
            width: width,
            height: height
        });
    };

    mobileAppConfigDs(uriQrCodeApiService, data, width, height) {
        return this.googleQrCodeDs({
            uriQrCodeApiService: uriQrCodeApiService,
            data: data,
            width: width,
            height: height
        });
    };

    googleQrCodeDs(qrCodedataSource) {
        return {
            uri: googleQrCodeApiUri(qrCodedataSource),
            text: JSON.stringify(qrCodedataSource.data),
            height: qrCodedataSource.height
        };

        function googleQrCodeApiUri(qrCodedataSource) {
            var sHeight = qrCodedataSource.height ? qrCodedataSource.height.toString() : '200';
            var sWidth = qrCodedataSource.width ? qrCodedataSource.width.toString() : '200';
            if (typeof qrCodedataSource.data == 'string') {
                var sChartData = encodeHtml(qrCodedataSource.data);
            }
            else {
                var sChartData = encodeHtml(JSON.stringify(qrCodedataSource.data));
            }

            var uriApi = qrCodedataSource.uriQrCodeApiService;
            if (!uriApi) {
                // default service
                uriApi = 'https://chart.googleapis.com/chart?cht=qr&chl=[qrCodeData]&chs=[qrCodeWidth]x[qrCodeHeight]&chld=L|0';
            }
            else {
                uriApi = NbsCommonConverter.decodeHtml(uriApi);
            }

            uriApi = uriApi.replace('[qrCodeWidth]', sWidth);
            uriApi = uriApi.replace('[qrCodeHeight]', sHeight);
            uriApi = uriApi.replace('[qrCodeData]', sChartData);

            return uriApi;
        };

        function encodeHtml(sourceTxt) {
            if (!sourceTxt) {
                return;
            }
            var elem = document.createElement("textarea");
            elem.textContent = sourceTxt;
            return elem.innerHTML;
        };
    };
}

class NbsSearchForm {
    translator: any;
    _kind: any;
    _searchType: any;
    _nodes: any;
    _filters: any;
    _forms: any;
    _fIdAct: any;
    _fIdTarget: any;
    _fIdEvent: any;
    _fIdPerson: any;
    _fIdJobData: any;
    _fIdAsset: any;
    _fIdAssetTree: any;
    _fIdClass: any;
    _fIdBom: any;
    _fIdFilebox: any;
    _filterNodesRoot: any;
    _filterNodesAct: any;
    _filterNodesTarget: any;
    _filterNodesEvent: any;
    _filterNodesVhr: any;
    _filterNodesJobData: any;
    _filterNodesAsset: any;
    _filterNodesAssetTree: any;
    _filterNodesClassification: any;
    _filterNodesBom: any;
    _filterNodesHrBucket: any;
    _filterNodesFileBox: any;
    _filterNodesFileMetadata: any;
    constructor(translator) {
        this.translator = translator;
        this._kind = { FOLDER: 1, DATA: 2 };
        this._searchType = { AVATAR: 'AVATAR', ASSET: 'ASSET', EVENTAVATAR: 'EVENTAVATAR', EVENTASSET: 'EVENTASSET', FILEBOX: 'FILEBOX', ACT: 'ACT', TARGET: 'TARGET', ASSETTREE: 'ASSETTREE' };
        this._nodes = { ROOT: 'ROOT', EVENT: 'EVENT', VHR: 'VHR', CLASS: 'CLASS', BOM: 'BOM', JOBDATA: 'JOBDATA', ASSET: 'ASSET', HRBUCKET: 'HRBUCKET', FILEBOX: 'FILEBOX', FILEMETADATA: 'FILEMETADATA', ACT: 'ACT', TARGET: 'TARGET', ASSETTREE: 'ASSETTREE' };
        this._filters = {
            ACT: "currentActFilter",
            TARGET: "currentTargetFilter",
            ACTIVITY: "currentActivityVhrCoreFilter",
            PERSON: "currentVirtualPersonFilter",
            EVENT: "currentVhrEventFilter",
            ASSET: "currentVirtualEquipmentFilter",
            ASSETTREE: "currentAssetTreeFilter",
            FILEBOX: "currentFileBoxFilter",
            FILEMETADATA: "currentFilemetadataFilter",
            VIRTUALHR: "currentVirtualHrFilter"
        };
        this._forms = {
            ACT: "formAct",
            TARGET: "formTarget",
            EVENT: "formVhrEvent",
            VHR: "formVirtualHumanResource",
            CLASS: "formClassification",
            JOBDATA: "formJobData",
            HRBUCKET: "formHrBucket",
            ASSET: "formVirtualEquipment",
            ASSETTREE: "formAssetTree",
            FILEBOX: "formFileBox",
            FILEMETADATA: "formFilemetadata"
        };

        this._fIdAct = {
            ACT_ActStartDate: this._filters.ACT + '.ActStartDate',
            ACT_ActExpireDate: this._filters.ACT + '.ActExpireDate',
            ACT_ActExecutionDate: this._filters.ACT + '.ActExecutionDate',
            ACT_ActWorkflowStatusKindId: this._filters.ACT + '.ActWorkflowStatusKindId',
            ACT_ActPriorityId: this._filters.ACT + '.ActPriorityId',
            ACT_ActTargetIds: this._filters.ACT + '.ActTargetIds',
            ACT_CategoriesId: this._filters.ACT + '.CategoriesId',
            ACT_DrbacAuthIds: this._filters.ACT + '.DrbacAuthIds',
            ACT_ActAuthorId: this._filters.ACT + '.ActAuthorId',
            ACT_VhrGroupIds: this._filters.ACT + '.VhrGroupIds',
            ACT_VhrTypeIds: this._filters.ACT + '.VhrTypeIds',
            ACT_ActSourceObjectId: this._filters.ACT + '.ActSourceObjectId',
            ACT_ActSourceObjectCode: this._filters.ACT + '.ActSourceObjectCode'
        };
        this._fIdTarget = {
            TARGET_TargetCode: this._filters.TARGET + '.TargetCode',
            TARGET_TargetName: this._filters.TARGET + '.TargetName',
            TARGET_TargetDescription: this._filters.TARGET + '.TargetDescription',
            TARGET_VhrGroupsId: this._filters.TARGET + '.VhrGroupsId',
            TARGET_VhrTypesId: this._filters.TARGET + '.VhrTypesId'
        };
        this._fIdEvent = {
            EVENT_VhrEventName: this._filters.EVENT + '.VhrEventName',
            EVENT_StartDate: this._filters.EVENT + '.StartDate',
            EVENT_VhrEventLifeStatus: this._filters.EVENT + '.VhrEventLifeStatusId',
            EVENT_VhrEventBillingStatus: this._filters.EVENT + '.VhrEventBillingStatusId',
            EVENT_VhrEventTypesId: this._filters.EVENT + '.VhrEventTypesId',
            EVENT_VhrEventGroupsId: this._filters.EVENT + '.VhrEventGroupsId',
            EVENT_VhrEventLabelsId: this._filters.EVENT + '.VhrEventLabelsId',
            EVENT_WorkCentersId: this._filters.EVENT + '.WorkCentersId',
            EVENT_IsApproved: this._filters.EVENT + '.IsApproved',
            EVENT_IsCustomerApproval: this._filters.EVENT + '.IsCustomerApproval',
            EVENT_IsExecuted: this._filters.EVENT + '.IsExecuted'
        };
        this._fIdPerson = {
            PERSON_FirstName: this._filters.PERSON + '.FirstName',
            PERSON_LastName: this._filters.PERSON + '.LastName',
            PERSON_RegistrationNumber: this._filters.PERSON + '.RegistrationNumber',
            PERSON_RegistrationNumberExt: this._filters.PERSON + '.RegistrationNumberExt',
            PERSON_TaxIdentificationNumber: this._filters.PERSON + '.TaxIdentificationNumber',
            PERSON_SocialSecurityNumber: this._filters.PERSON + '.SocialSecurityNumber',
            PERSON_InsuranceCode: this._filters.PERSON + '.InsuranceCode',
            PERSON_NationalInsuranceNumber: this._filters.PERSON + '.NationalInsuranceNumber',
            PERSON_Dob: this._filters.PERSON + '.Dob'
        };
        this._fIdJobData = {
            PERSON_CcDefinedId: this._filters.PERSON + '.CcDefinedId',
            PERSON_CcUsedId: this._filters.PERSON + '.CcUsedId',
            PERSON_BuDefinedId: this._filters.PERSON + '.BuDefinedId',
            PERSON_BuUsedId: this._filters.PERSON + '.BuUsedId',
            PERSON_PlaceDefinedId: this._filters.PERSON + '.PlaceDefinedId',
            PERSON_PlaceUsedId: this._filters.PERSON + '.PlaceUsedId',
            PERSON_CompaniesUsedId: this._filters.PERSON + '.CompaniesUsedId',
            PERSON_JobRoleId: this._filters.PERSON + '.JobRoleId',
            PERSON_LegalQualificationId: this._filters.PERSON + '.LegalQualificationId',
            PERSON_BusinessRoleId: this._filters.PERSON + '.BusinessRoleId',
            PERSON_SafetyRoleId: this._filters.PERSON + '.SafetyRoleId'
        };
        this._fIdAsset = {
            ASSET_VirtualHumanResourcesId: this._filters.ASSET + '.AssetTreeIds',
            ASSET_RegistrationNumber: this._filters.ASSET + '.RegistrationNumber',
            ASSET_EquipmentCode: this._filters.ASSET + '.EquipmentCode',
            ASSET_EquipmentName: this._filters.ASSET + '.EquipmentName',
            ASSET_EquipmentDescription: this._filters.ASSET + '.EquipmentDescription',
            ASSET_SerialNumber: this._filters.ASSET + '.SerialNumber',
            ASSET_Producer: this._filters.ASSET + '.Producer',
            ASSET_Model: this._filters.ASSET + '.Model'
        };
        this._fIdAssetTree = {
            ASSETTREE_VirtualHumanResourcesId: this._filters.ASSETTREE + '.VirtualHumanResourcesId',
            ASSETTREE_VirtualEquipmentRegistrationNumber: this._filters.ASSETTREE + '.VirtualEquipmentRegistrationNumber',
            ASSETTREE_VirtualCode: this._filters.ASSETTREE + '.VirtualCode',
            ASSETTREE_VirtualName: this._filters.ASSETTREE + '.VirtualName',
            ASSETTREE_VirtualDescription: this._filters.ASSETTREE + '.VirtualDescription',
            ASSETTREE_VirtualEquipmentSerialNumber: this._filters.ASSETTREE + '.VirtualEquipmentSerialNumber',
            ASSETTREE_VirtualEquipmentProducer: this._filters.ASSETTREE + '.VirtualEquipmentProducer',
            ASSETTREE_VirtualEquipmentModel: this._filters.ASSETTREE + '.VirtualEquipmentModel'
        };
        this._fIdClass = {
            VIRTUALHR_InheritedCategoriesId: this._filters.VIRTUALHR + '.InheritedCategoriesId',
            VIRTUALHR_CategoriesId: this._filters.VIRTUALHR + '.CategoriesId',
            VIRTUALHR_LegalEntityIds: this._filters.VIRTUALHR + '.LegalEntityIds',
            VIRTUALHR_CostCenterIds: this._filters.VIRTUALHR + '.CostCenterIds',
            VIRTUALHR_BusinessUnitIds: this._filters.VIRTUALHR + '.BusinessUnitIds',
            VIRTUALHR_SectorIds: this._filters.VIRTUALHR + '.SectorIds',
            VIRTUALHR_PlantIds: this._filters.VIRTUALHR + '.PlantIds',
            VIRTUALHR_IsPlantAssociated: this._filters.VIRTUALHR + '.IsPlantAssociated',
            //VIRTUALHR_HomogeneousGroupIds: this._filters.VIRTUALHR + '.HomogeneousGroupIds'
        };
        this._fIdBom = {
            ACTIVITY_ActivitiesTemplatesId: this._filters.ACTIVITY + '.ActivitiesTemplatesId',
            ACTIVITY_ActivityIds: this._filters.ACTIVITY + '.ActivityIds'
        };
        this._fIdFilebox = {
            FILEBOX_FileBoxCode: this._filters.FILEBOX + '.FileBoxCode',
            FILEBOX_FileBoxName: this._filters.FILEBOX + '.FileBoxName',
            FILEBOX_FileBoxYear: this._filters.FILEBOX + '.FileBoxYear',
            FILEMETADATA_CategoriesId: this._filters.FILEMETADATA + '.CategoriesId'
        };

        this._filterNodesRoot = [{ FieldId: this._nodes.ROOT, FieldKindId: this._kind.FOLDER, FieldText: 'Filters', FieldParentId: null }];
        this._filterNodesAct = [
            { FieldId: this._nodes.ACT, FieldKindId: this._kind.FOLDER, FieldText: 'Act.Act', FieldParentId: this._nodes.ROOT },
            { FieldId: this._fIdAct.ACT_ActStartDate, FieldKindId: this._kind.DATA, FieldText: 'Act.ActStartDate', FieldParentId: this._nodes.ACT, FormOwner: this._forms.ACT },
            { FieldId: this._fIdAct.ACT_ActExpireDate, FieldKindId: this._kind.DATA, FieldText: 'Act.ActExpireDate', FieldParentId: this._nodes.ACT, FormOwner: this._forms.ACT },
            { FieldId: this._fIdAct.ACT_ActExecutionDate, FieldKindId: this._kind.DATA, FieldText: 'Act.ActExecutionDate', FieldParentId: this._nodes.ACT, FormOwner: this._forms.ACT },
            { FieldId: this._fIdAct.ACT_ActWorkflowStatusKindId, FieldKindId: this._kind.DATA, FieldText: 'Act.ActWorkflowStatusKindId', FieldParentId: this._nodes.ACT, FormOwner: this._forms.ACT },
            { FieldId: this._fIdAct.ACT_ActPriorityId, FieldKindId: this._kind.DATA, FieldText: 'Act.ActPriorityId', FieldParentId: this._nodes.ACT, FormOwner: this._forms.ACT },
            { FieldId: this._fIdAct.ACT_ActTargetIds, FieldKindId: this._kind.DATA, FieldText: 'Act.ActTarget', FieldParentId: this._nodes.ACT, FormOwner: this._forms.ACT },
            { FieldId: this._fIdAct.ACT_ActAuthorId, FieldKindId: this._kind.DATA, FieldText: 'Act.ActAuthor', FieldParentId: this._nodes.ACT, FormOwner: this._forms.ACT },
            { FieldId: this._fIdAct.ACT_CategoriesId, FieldKindId: this._kind.DATA, FieldText: 'Act.Categories', FieldParentId: this._nodes.ACT, FormOwner: this._forms.ACT },
            { FieldId: this._fIdAct.ACT_DrbacAuthIds, FieldKindId: this._kind.DATA, FieldText: 'Assignee', FieldParentId: this._nodes.ACT, FormOwner: this._forms.ACT },
            { FieldId: this._fIdAct.ACT_VhrGroupIds, FieldKindId: this._kind.DATA, FieldText: 'TargetDto.TargetGroupId', FieldParentId: this._nodes.ACT, FormOwner: this._forms.ACT },
            { FieldId: this._fIdAct.ACT_VhrTypeIds, FieldKindId: this._kind.DATA, FieldText: 'TargetDto.TargetTypeId', FieldParentId: this._nodes.ACT, FormOwner: this._forms.ACT },
            { FieldId: this._fIdAct.ACT_ActSourceObjectId, FieldKindId: this._kind.DATA, FieldText: 'Act.ActSourceObjectId', FieldParentId: this._nodes.ACT, FormOwner: this._forms.ACT },
            { FieldId: this._fIdAct.ACT_ActSourceObjectCode, FieldKindId: this._kind.DATA, FieldText: 'Act.ActSourceObjectCode', FieldParentId: this._nodes.ACT, FormOwner: this._forms.ACT }
        ];
        this._filterNodesTarget = [
            { FieldId: this._nodes.TARGET, FieldKindId: this._kind.FOLDER, FieldText: 'TargetDto.TargetDto', FieldParentId: this._nodes.ROOT },
            { FieldId: this._fIdTarget.TARGET_TargetCode, FieldKindId: this._kind.DATA, FieldText: 'TargetDto.TargetCode', FieldParentId: this._nodes.TARGET, FormOwner: this._forms.TARGET },
            { FieldId: this._fIdTarget.TARGET_TargetName, FieldKindId: this._kind.DATA, FieldText: 'TargetDto.TargetName', FieldParentId: this._nodes.TARGET, FormOwner: this._forms.TARGET },
            { FieldId: this._fIdTarget.TARGET_VhrGroupsId, FieldKindId: this._kind.DATA, FieldText: 'TargetDto.TargetGroupId', FieldParentId: this._nodes.TARGET, FormOwner: this._forms.TARGET },
            { FieldId: this._fIdTarget.TARGET_VhrTypesId, FieldKindId: this._kind.DATA, FieldText: 'TargetDto.TargetTypeId', FieldParentId: this._nodes.TARGET, FormOwner: this._forms.TARGET }
        ];
        this._filterNodesEvent = [
            { FieldId: this._nodes.EVENT, FieldKindId: this._kind.FOLDER, FieldText: 'VhrEvent.VhrEvent', FieldParentId: this._nodes.ROOT },
            { FieldId: this._fIdEvent.EVENT_VhrEventName, FieldKindId: this._kind.DATA, FieldText: 'VhrEvent.VhrEventName', FieldParentId: this._nodes.EVENT, FormOwner: this._forms.EVENT },
            { FieldId: this._fIdEvent.EVENT_StartDate, FieldKindId: this._kind.DATA, FieldText: 'VhrEvent.VhrEventStartDate', FieldParentId: this._nodes.EVENT, FormOwner: this._forms.EVENT },
            { FieldId: this._fIdEvent.EVENT_VhrEventLifeStatus, FieldKindId: this._kind.DATA, FieldText: 'VhrEvent.VhrEventLifeStatus', FieldParentId: this._nodes.EVENT, FormOwner: this._forms.EVENT },
            { FieldId: this._fIdEvent.EVENT_VhrEventBillingStatus, FieldKindId: this._kind.DATA, FieldText: 'VhrEvent.VhrEventBillingStatusId', FieldParentId: this._nodes.EVENT, FormOwner: this._forms.EVENT },
            { FieldId: this._fIdEvent.EVENT_VhrEventTypesId, FieldKindId: this._kind.DATA, FieldText: 'VhrEvent.VhrEventType', FieldParentId: this._nodes.EVENT, FormOwner: this._forms.EVENT },
            { FieldId: this._fIdEvent.EVENT_VhrEventGroupsId, FieldKindId: this._kind.DATA, FieldText: 'VhrEvent.VhrEventGroup', FieldParentId: this._nodes.EVENT, FormOwner: this._forms.EVENT },
            { FieldId: this._fIdEvent.EVENT_VhrEventLabelsId, FieldKindId: this._kind.DATA, FieldText: 'VhrEvent.VhrEventLabels', FieldParentId: this._nodes.EVENT, FormOwner: this._forms.EVENT },
            { FieldId: this._fIdEvent.EVENT_WorkCentersId, FieldKindId: this._kind.DATA, FieldText: 'VhrEvent.WorkCenterName', FieldParentId: this._nodes.EVENT, FormOwner: this._forms.EVENT },
            { FieldId: this._fIdEvent.EVENT_IsExecuted, FieldKindId: this._kind.DATA, FieldText: 'VhrEvent.IsExecuted', FieldParentId: this._nodes.EVENT, FormOwner: this._forms.EVENT },
            { FieldId: this._fIdEvent.EVENT_IsApproved, FieldKindId: this._kind.DATA, FieldText: 'VhrEvent.IsApproved', FieldParentId: this._nodes.EVENT, FormOwner: this._forms.EVENT },
            { FieldId: this._fIdEvent.EVENT_IsCustomerApproval, FieldKindId: this._kind.DATA, FieldText: 'VhrEvent.IsCustomerApproval', FieldParentId: this._nodes.EVENT, FormOwner: this._forms.EVENT }
        ];
        this._filterNodesVhr = [
            { FieldId: this._nodes.VHR, FieldKindId: this._kind.FOLDER, FieldText: 'PersonGenerality.PersonGenerality', FieldParentId: this._nodes.ROOT },
            { FieldId: this._fIdPerson.PERSON_FirstName, FieldKindId: this._kind.DATA, FieldText: 'PersonGenerality.FirstName', FieldParentId: this._nodes.VHR, FormOwner: this._forms.VHR },
            { FieldId: this._fIdPerson.PERSON_LastName, FieldKindId: this._kind.DATA, FieldText: 'PersonGenerality.LastName', FieldParentId: this._nodes.VHR, FormOwner: this._forms.VHR },
            { FieldId: this._fIdPerson.PERSON_RegistrationNumber, FieldKindId: this._kind.DATA, FieldText: 'PersonGenerality.RegistrationNumber', FieldParentId: this._nodes.VHR, FormOwner: this._forms.VHR },
            { FieldId: this._fIdPerson.PERSON_RegistrationNumberExt, FieldKindId: this._kind.DATA, FieldText: 'PersonGenerality.RegistrationNumberExt', FieldParentId: this._nodes.VHR, FormOwner: this._forms.VHR },
            { FieldId: this._fIdPerson.PERSON_TaxIdentificationNumber, FieldKindId: this._kind.DATA, FieldText: 'PersonGenerality.TaxIdentificationNumber', FieldParentId: this._nodes.VHR, FormOwner: this._forms.VHR },
            { FieldId: this._fIdPerson.PERSON_SocialSecurityNumber, FieldKindId: this._kind.DATA, FieldText: 'PersonGenerality.SocialSecurityNumber', FieldParentId: this._nodes.VHR, FormOwner: this._forms.VHR },
            { FieldId: this._fIdPerson.PERSON_InsuranceCode, FieldKindId: this._kind.DATA, FieldText: 'PersonGenerality.InsuranceCode', FieldParentId: this._nodes.VHR, FormOwner: this._forms.VHR },
            { FieldId: this._fIdPerson.PERSON_NationalInsuranceNumber, FieldKindId: this._kind.DATA, FieldText: 'PersonGenerality.NationalInsuranceNumber', FieldParentId: this._nodes.VHR, FormOwner: this._forms.VHR },
            { FieldId: this._fIdPerson.PERSON_Dob, FieldKindId: this._kind.DATA, FieldText: 'PersonGenerality.Dob', FieldParentId: this._nodes.VHR, FormOwner: this._forms.VHR }
        ];
        this._filterNodesJobData = [
            { FieldId: this._nodes.JOBDATA, FieldKindId: this._kind.FOLDER, FieldText: 'PersonJobData.PersonJobData', FieldParentId: this._nodes.ROOT },
            { FieldId: this._fIdJobData.PERSON_CcDefinedId, FieldKindId: this._kind.DATA, FieldText: 'PersonJobData.CcDefinedId', FieldParentId: this._nodes.JOBDATA, FormOwner: this._forms.JOBDATA },
            { FieldId: this._fIdJobData.PERSON_CcUsedId, FieldKindId: this._kind.DATA, FieldText: 'PersonJobData.CcUsedId', FieldParentId: this._nodes.JOBDATA, FormOwner: this._forms.JOBDATA },
            { FieldId: this._fIdJobData.PERSON_BuDefinedId, FieldKindId: this._kind.DATA, FieldText: 'PersonJobData.BuDefinedId', FieldParentId: this._nodes.JOBDATA, FormOwner: this._forms.JOBDATA },
            { FieldId: this._fIdJobData.PERSON_BuUsedId, FieldKindId: this._kind.DATA, FieldText: 'PersonJobData.BuUsedId', FieldParentId: this._nodes.JOBDATA, FormOwner: this._forms.JOBDATA },
            { FieldId: this._fIdJobData.PERSON_PlaceDefinedId, FieldKindId: this._kind.DATA, FieldText: 'PersonJobData.PlaceDefinedId', FieldParentId: this._nodes.JOBDATA, FormOwner: this._forms.JOBDATA },
            { FieldId: this._fIdJobData.PERSON_PlaceUsedId, FieldKindId: this._kind.DATA, FieldText: 'PersonJobData.PlaceUsedId', FieldParentId: this._nodes.JOBDATA, FormOwner: this._forms.JOBDATA },
            { FieldId: this._fIdJobData.PERSON_CompaniesUsedId, FieldKindId: this._kind.DATA, FieldText: 'PersonJobData.CompanyUsedId', FieldParentId: this._nodes.JOBDATA, FormOwner: this._forms.JOBDATA },
            { FieldId: this._fIdJobData.PERSON_JobRoleId, FieldKindId: this._kind.DATA, FieldText: 'PersonJobData.JobRole', FieldParentId: this._nodes.JOBDATA, FormOwner: this._forms.JOBDATA },
            { FieldId: this._fIdJobData.PERSON_LegalQualificationId, FieldKindId: this._kind.DATA, FieldText: 'PersonJobData.LegalQualification', FieldParentId: this._nodes.JOBDATA, FormOwner: this._forms.JOBDATA },
            { FieldId: this._fIdJobData.PERSON_BusinessRoleId, FieldKindId: this._kind.DATA, FieldText: 'PersonJobData.BusinessRole', FieldParentId: this._nodes.JOBDATA, FormOwner: this._forms.JOBDATA },
            { FieldId: this._fIdJobData.PERSON_SafetyRoleId, FieldKindId: this._kind.DATA, FieldText: 'PersonJobData.SafetyRole', FieldParentId: this._nodes.JOBDATA, FormOwner: this._forms.JOBDATA }
        ];
        this._filterNodesAsset = [
            { FieldId: this._fIdAsset.ASSET_VirtualHumanResourcesId, FieldKindId: this._kind.DATA, FieldText: 'Asset', FieldParentId: this._nodes.ASSET, FormOwner: this._forms.ASSET },
            { FieldId: this._nodes.ASSET, FieldKindId: this._kind.FOLDER, FieldText: 'EquipmentGenerality.EquipmentGenerality', FieldParentId: this._nodes.ROOT },
            { FieldId: this._fIdAsset.ASSET_EquipmentCode, FieldKindId: this._kind.DATA, FieldText: 'EquipmentGenerality.EquipmentCode', FieldParentId: this._nodes.ASSET, FormOwner: this._forms.ASSET },
            { FieldId: this._fIdAsset.ASSET_EquipmentName, FieldKindId: this._kind.DATA, FieldText: 'EquipmentGenerality.EquipmentName', FieldParentId: this._nodes.ASSET, FormOwner: this._forms.ASSET },
            { FieldId: this._fIdAsset.ASSET_EquipmentDescription, FieldKindId: this._kind.DATA, FieldText: 'EquipmentGenerality.EquipmentDescription', FieldParentId: this._nodes.ASSET, FormOwner: this._forms.ASSET },
            { FieldId: this._fIdAsset.ASSET_SerialNumber, FieldKindId: this._kind.DATA, FieldText: 'EquipmentGenerality.SerialNumber', FieldParentId: this._nodes.ASSET, FormOwner: this._forms.ASSET },
            { FieldId: this._fIdAsset.ASSET_Producer, FieldKindId: this._kind.DATA, FieldText: 'EquipmentGenerality.Producer', FieldParentId: this._nodes.ASSET, FormOwner: this._forms.ASSET },
            { FieldId: this._fIdAsset.ASSET_Model, FieldKindId: this._kind.DATA, FieldText: 'EquipmentGenerality.Model', FieldParentId: this._nodes.ASSET, FormOwner: this._forms.ASSET },
            { FieldId: this._fIdAsset.ASSET_RegistrationNumber, FieldKindId: this._kind.DATA, FieldText: 'EquipmentGenerality.RegistrationNumber', FieldParentId: this._nodes.ASSET, FormOwner: this._forms.ASSET }
        ];
        this._filterNodesAssetTree = [
            { FieldId: this._nodes.ASSETTREE, FieldKindId: this._kind.FOLDER, FieldText: 'EquipmentGenerality.EquipmentGenerality', FieldParentId: this._nodes.ROOT },
            { FieldId: this._fIdAssetTree.ASSETTREE_VirtualCode, FieldKindId: this._kind.DATA, FieldText: 'AssetTreeDto.VirtualCode', FieldParentId: this._nodes.ASSETTREE, FormOwner: this._forms.ASSETTREE },
            { FieldId: this._fIdAssetTree.ASSETTREE_VirtualName, FieldKindId: this._kind.DATA, FieldText: 'AssetTreeDto.VirtualName', FieldParentId: this._nodes.ASSETTREE, FormOwner: this._forms.ASSETTREE },
            { FieldId: this._fIdAssetTree.ASSETTREE_VirtualDescription, FieldKindId: this._kind.DATA, FieldText: 'AssetTreeDto.VirtualDescription', FieldParentId: this._nodes.ASSETTREE, FormOwner: this._forms.ASSETTREE },
            { FieldId: this._fIdAssetTree.ASSETTREE_VirtualEquipmentSerialNumber, FieldKindId: this._kind.DATA, FieldText: 'AssetTreeDto.VirtualEquipmentSerialNumber', FieldParentId: this._nodes.ASSET, FormOwner: this._forms.ASSETTREE },
            { FieldId: this._fIdAssetTree.ASSETTREE_VirtualEquipmentProducer, FieldKindId: this._kind.DATA, FieldText: 'AssetTreeDto.VirtualEquipmentProducer', FieldParentId: this._nodes.ASSETTREE, FormOwner: this._forms.ASSETTREE },
            { FieldId: this._fIdAssetTree.ASSETTREE_VirtualEquipmentModel, FieldKindId: this._kind.DATA, FieldText: 'AssetTreeDto.VirtualEquipmentModel', FieldParentId: this._nodes.ASSETTREE, FormOwner: this._forms.ASSETTREE },
            { FieldId: this._fIdAssetTree.ASSETTREE_VirtualEquipmentRegistrationNumber, FieldKindId: this._kind.DATA, FieldText: 'AssetTreeDto.VirtualEquipmentRegistrationNumber', FieldParentId: this._nodes.ASSETTREE, FormOwner: this._forms.ASSETTREE },
            { FieldId: this._fIdAssetTree.ASSETTREE_VirtualHumanResourcesId, FieldKindId: this._kind.DATA, FieldText: 'VirtualHumanResource.Asset', FieldParentId: this._nodes.ASSETTREE, FormOwner: this._forms.ASSETTREE }
        ];
        this._filterNodesClassification = [
            { FieldId: this._nodes.CLASS, FieldKindId: this._kind.FOLDER, FieldText: 'SearchClassification', FieldParentId: this._nodes.ROOT },
            { FieldId: this._fIdClass.VIRTUALHR_InheritedCategoriesId, FieldKindId: this._kind.DATA, FieldText: 'SearchHazards', FieldParentId: this._nodes.CLASS, FormOwner: this._forms.CLASS },
            { FieldId: this._fIdClass.VIRTUALHR_CategoriesId, FieldKindId: this._kind.DATA, FieldText: 'Categories', FieldParentId: this._nodes.CLASS, FormOwner: this._forms.CLASS }
        ];
        this._filterNodesBom = [
            { FieldId: this._nodes.BOM, FieldKindId: this._kind.FOLDER, FieldText: 'Boms', FieldParentId: this._nodes.ROOT },
            { FieldId: this._fIdBom.ACTIVITY_ActivityIds, FieldKindId: this._kind.DATA, FieldText: 'Boms', FieldParentId: this._nodes.BOM, FormOwner: this._forms.CLASS }
        ];
        this._filterNodesHrBucket = [
            { FieldId: this._nodes.HRBUCKET, FieldKindId: this._kind.FOLDER, FieldText: 'ProductiveUnits', FieldParentId: this._nodes.ROOT },
            { FieldId: this._fIdClass.VIRTUALHR_LegalEntityIds, FieldKindId: this._kind.DATA, FieldText: 'LegalEntities', FieldParentId: this._nodes.HRBUCKET, FormOwner: this._forms.HRBUCKET },
            { FieldId: this._fIdClass.VIRTUALHR_CostCenterIds, FieldKindId: this._kind.DATA, FieldText: 'CostCenters', FieldParentId: this._nodes.HRBUCKET, FormOwner: this._forms.HRBUCKET },
            { FieldId: this._fIdClass.VIRTUALHR_BusinessUnitIds, FieldKindId: this._kind.DATA, FieldText: 'BusinessUnits', FieldParentId: this._nodes.HRBUCKET, FormOwner: this._forms.HRBUCKET },
            { FieldId: this._fIdClass.VIRTUALHR_SectorIds, FieldKindId: this._kind.DATA, FieldText: 'Sectors', FieldParentId: this._nodes.HRBUCKET, FormOwner: this._forms.HRBUCKET },
            { FieldId: this._fIdClass.VIRTUALHR_PlantIds, FieldKindId: this._kind.DATA, FieldText: 'Plants', FieldParentId: this._nodes.HRBUCKET, FormOwner: this._forms.HRBUCKET },
            { FieldId: this._fIdClass.VIRTUALHR_IsPlantAssociated, FieldKindId: this._kind.DATA, FieldText: 'PlantAssociation', FieldParentId: this._nodes.HRBUCKET, FormOwner: this._forms.HRBUCKET }
            //{ FieldId: this._fIdClass.VIRTUALHR_HomogeneousGroupIds, FieldKindId: this._kind.DATA, FieldText: 'HomogeneousGroups', FieldParentId: this._nodes.HRBUCKET, FormOwner: this._forms.HRBUCKET }
        ];
        this._filterNodesFileBox = [
            { FieldId: this._nodes.FILEBOX, FieldKindId: this._kind.FOLDER, FieldText: 'FileBox.FileBox', FieldParentId: this._nodes.ROOT },
            { FieldId: this._fIdFilebox.FILEBOX_FileBoxCode, FieldKindId: this._kind.DATA, FieldText: 'FileBox.FileCode', FieldParentId: this._nodes.FILEBOX, FormOwner: this._forms.FILEBOX },
            { FieldId: this._fIdFilebox.FILEBOX_FileBoxName, FieldKindId: this._kind.DATA, FieldText: 'FileBox.FileBoxName', FieldParentId: this._nodes.FILEBOX, FormOwner: this._forms.FILEBOX },
            { FieldId: this._fIdFilebox.FILEBOX_FileBoxYear, FieldKindId: this._kind.DATA, FieldText: 'FileBox.FileBoxYear', FieldParentId: this._nodes.FILEBOX, FormOwner: this._forms.FILEBOX }
        ];
        this._filterNodesFileMetadata = [
            { FieldId: this._nodes.FILEMETADATA, FieldKindId: this._kind.FOLDER, FieldText: 'FileMetadata.FileMetadata', FieldParentId: this._nodes.ROOT },
            { FieldId: this._fIdFilebox.FILEMETADATA_CategoriesId, FieldKindId: this._kind.DATA, FieldText: 'Categories', FieldParentId: this._nodes.FILEMETADATA, FormOwner: this._forms.FILEMETADATA }
        ];
    }

    searchFormDataBase(constants, nodes, translator) {
        var _constants = constants;
        var _nodes = nodes;
        var _controller = null;
        var _filterNodesProp = 'filterNodes';
        var _fieldsVisibilityProp = 'fieldsVisibility';
        var _modalSelectionFilterProp = 'modalSelectionFilter';

        Lazy(_nodes)
            .each((fn) => {
                if (translator) {
                    fn.FieldText = translator(fn.FieldText);
                }
            });

        function init(controller, nodesToSkip) {
            if (!controller) {
                throw new Error('searchFormDataBase controller is mandatory');
            }

            _controller = controller;

            initFilters();
            var nodes = getFilterNodes(nodesToSkip);
            resetDisabledValuesFilterNodes();
            resetFormVisibilityObj();
            initFormVisibilityObj();

            _controller[_filterNodesProp] = nodes;
            _controller[_fieldsVisibilityProp] = getVisibleFieldsObj();
        }

        function removeFilters(nodesToSkip) {
            var nodes = getFilterNodes(nodesToSkip);
            resetDisabledValuesFilterNodes();
            resetFormVisibilityObj();
            initFormVisibilityObj();

            _controller[_filterNodesProp] = nodes;
            _controller[_fieldsVisibilityProp] = getVisibleFieldsObj();
        }

        function resetDisabledValuesFilterNodes() {
            Lazy(_nodes)
                .where({ Visible: false })
                .each((node) => {
                    if (node.FormOwner) {
                        var filterName = getFilterNameByFieldId(node.FieldId);
                        var fieldName = getFieldNameByFieldId(node.FieldId);

                        if (filterName && fieldName) {
                            _controller[filterName][fieldName] = null;
                        }
                    }
                });
        }

        function getFilterNodes(nodesToSkip) {
            // automatically expands treeview 
            Lazy(_nodes).each((node) => { node.FieldIsChecked = false, node.FieldIsExpanded = true; });

            // set visible and disabled fields
            Lazy(_nodes)
                .each((node) => {
                    node.Visible = Lazy(_constants.VisibleFieldsDefault).contains(node.FieldId);
                    node.Enabled = true;
                });

            var result = Lazy(_nodes).toArray();
            if (Lazy(nodesToSkip).some()) {
                Lazy(result)
                    .each((filterNode) => {
                        if (Lazy(nodesToSkip).contains(getFieldNameByFieldId(filterNode.FieldId))) {
                            result = Lazy(result).reject({ FieldId: filterNode.FieldId }).toArray();
                        }
                    });
            }

            return result;
        }

        function getVisibleFieldsObj() {
            var obj = {};
            Lazy(_nodes)
                .each((node) => {
                    if (node.FormOwner) {
                        obj[node.FieldId] = {};
                        obj[node.FieldId].Visible = node.Visible;
                        obj[node.FieldId].FormOwner = node.FormOwner ? node.FormOwner : '';
                    }
                });
            return obj;
        }

        function VisibleFormByName(formName, filterNodes) {
            return Lazy(filterNodes)
                .where({ FormOwner: formName })
                .where({ Visible: true })
                .some();
        }

        function initFilters() {
            Lazy(_constants.Filters)
                .each((filter) => {
                    Lazy(_constants.FieldIds)
                        .each((fieldId) => {
                            _controller[filter] = {};
                        });
                });
        }

        function addFilters(navigationService, translator, $scope) {
            // checkbox reset selection
            Lazy(_controller[_filterNodesProp]).each((f) => { f.FieldIsChecked = false; });

            _controller[_modalSelectionFilterProp] = new NbsSelectionFilterModal(navigationService, translator('Filters'), { scope: $scope }, () => {
                if (_controller[_modalSelectionFilterProp].filtersSelected) {
                    Lazy(Object.keys(_controller[_fieldsVisibilityProp]))
                        .each((f) => {
                            if (Lazy(_controller[_modalSelectionFilterProp].filtersSelected).contains(f)) {
                                Lazy(_controller[_filterNodesProp]).findWhere({ FieldId: f }).Visible = true;
                            }
                        });

                    _controller[_fieldsVisibilityProp] = getVisibleFieldsObj();
                    initFormVisibilityObj();
                }
            });

            _controller[_modalSelectionFilterProp].datasource = _controller[_filterNodesProp];
        }

        function NbsSelectionFilterModal(navigationService, title, modalOptions, okCallbackFn) {
            var me = this;
            me.filtersSelected = [];

            modalOptions.templateUrl = 'filtersTemplate';

            me.instance = navigationService.modal().open(modalOptions);
            me.instance.result.then(function () { okCallbackFn(); }, null);
            me.title = title;
            me.datasource = [];
            me.ok = function () { me.instance.close(); };
            me.cancel = function () { me.instance.dismiss(); };
            me.filterOnNodeCheck = function (args) {
                me.filtersSelected = Lazy(me.filtersSelected).union(Lazy(args.currentCheckedNodes).pluck('id')).toArray();
            };
            me.filterOnNodeUncheck = function (args) {
                var uncheckedIds = Lazy(args.currentUncheckedNodes).pluck('id').toArray();
                me.filtersSelected = Lazy(me.filtersSelected).reject(function (id) { return Lazy(uncheckedIds).contains(id); }).toArray();
            };
        }

        function resetFilters() {
            Lazy(_constants.FieldIds)
                .each((fieldId) => {
                    if (Lazy(fieldId).contains('.')) {
                        var filterName = fieldId.split('.')[0];
                        var fieldName = fieldId.split('.')[1];

                        if (_controller[filterName]) {
                            _controller[filterName][fieldName] = null;
                        }
                    }

                });
        }

        function getFieldNameByFieldId(fieldId) {
            fieldId = fieldId.toString();
            return fieldId.split('.')[1];
        }

        function getFilterNameByFieldId(fieldId) {
            fieldId = fieldId.toString();
            return fieldId.split('.')[0];
        }

        function resetFormVisibilityObj() {
            Lazy(Object.keys(_constants.Forms))
                .each((prop) => {
                    _controller[_constants.Forms[prop]] = { Visible: false };
                });
        }

        function initFormVisibilityObj() {
            Lazy(Object.keys(_constants.Forms))
                .each((prop) => {
                    _controller[_constants.Forms[prop]] = { Visible: VisibleFormByName(_constants.Forms[prop], _nodes) };
                });
        }

        return {
            init: init,
            addFilters: addFilters,
            removeFilters: removeFilters,
            resetFilters: resetFilters,
            getFieldNameByFieldId: getFieldNameByFieldId,
            getFilterNameByFieldId: getFilterNameByFieldId,
            getFilterNodes: getFilterNodes,

            Const: _constants
        };
    };

    cleanAllFilterProperties(filter) {
        Lazy(Object.keys(filter))
            .each((pName) => {
                // key: the name of the object key
                // index: the ordinal position of the key within the object 
                if (filter[pName] instanceof Array && filter[pName].length === 0) {
                    delete filter[pName];
                }

                if (filter[pName] === null || filter[pName] === undefined) {
                    delete filter[pName];
                }
            });
    };

    // support private function
    getMergedObject(objSrc, obj) {
        Lazy(Object.keys(obj))
            .each((p) => {
                objSrc[p] = obj[p];
            });
    }

    // support private function
    getObjByConstantValues(objSrc, values) {
        var result = {};

        Lazy(Object.keys(objSrc))
            .each((p) => {
                if (Lazy(values).contains(objSrc[p])) {
                    result[p] = objSrc[p];
                }
            });

        return result;
    }

    getNodesObj(searchType) {
        var values = [];
        switch (searchType) {
            case this._searchType.ACT:
                values = [this._nodes.ROOT, this._nodes.ACT];
                break;
            case this._searchType.TARGET:
                values = [this._nodes.ROOT, this._nodes.TARGET];
                break;
            case this._searchType.ASSET:
                values = [this._nodes.ROOT, this._nodes.EVENT, this._nodes.CLASS, this._nodes.BOM, this._nodes.ASSET, this._nodes.HRBUCKET];
                break;
            case this._searchType.ASSETTREE:
                values = [this._nodes.ROOT, this._nodes.EVENT, this._nodes.CLASS, this._nodes.BOM, this._nodes.ASSETTREE, this._nodes.HRBUCKET];
                break;
            case this._searchType.AVATAR:
                values = [this._nodes.ROOT, this._nodes.EVENT, this._nodes.CLASS, this._nodes.BOM];
                break;
            case this._searchType.EVENTAVATAR:
                values = [this._nodes.ROOT, this._nodes.EVENT, this._nodes.VHR, this._nodes.CLASS, this._nodes.JOBDATA, this._nodes.HRBUCKET];
                break;
            case this._searchType.EVENTASSET:
                values = [this._nodes.ROOT, this._nodes.EVENT, this._nodes.CLASS, this._nodes.ASSET];
                break;
            case this._searchType.FILEBOX:
                values = [this._nodes.ROOT, this._nodes.FILEBOX, this._nodes.FILEMETADATA];
                break;
        }

        return this.getObjByConstantValues(this._nodes, values);
    }

    getFiltersObj(searchType) {
        var values = [];
        switch (searchType) {
            case this._searchType.ACT:
                values = [this._filters.ACT];
                break;
            case this._searchType.TARGET:
                values = [this._filters.TARGET];
                break;
            case this._searchType.ASSET:
                values = [this._filters.EVENT, this._filters.ACTIVITY, this._filters.PERSON, this._filters.ASSET, this._filters.VIRTUALHR];
                break;
            case this._searchType.ASSETTREE:
                values = [this._filters.EVENT, this._filters.ACTIVITY, this._filters.PERSON, this._filters.ASSETTREE, this._filters.VIRTUALHR];
                break;
            case this._searchType.AVATAR:
                values = [this._filters.EVENT, this._filters.ACTIVITY, this._filters.PERSON, this._filters.VIRTUALHR];
                break;
            case this._searchType.EVENTAVATAR:
                values = [this._filters.EVENT, this._filters.ACTIVITY, this._filters.PERSON, this._filters.VIRTUALHR];
                break;
            case this._searchType.EVENTASSET:
                values = [this._filters.EVENT, this._filters.ACTIVITY, this._filters.ASSET, this._filters.VIRTUALHR];
                break;
            case this._searchType.FILEBOX:
                values = [this._filters.FILEBOX, this._filters.FILEMETADATA];
                break;
        }

        return this.getObjByConstantValues(this._filters, values);
    }

    getFormsObj(searchType) {
        var values = [];
        switch (searchType) {
            case this._searchType.ACT:
                values = [this._forms.ACT];
                break;
            case this._searchType.TARGET:
                values = [this._forms.TARGET];
                break;
            case this._searchType.ASSET:
                values = [this._forms.EVENT, this._forms.ASSET, this._forms.HRBUCKET, this._forms.CLASS];
                break;
            case this._searchType.ASSETTREE:
                values = [this._forms.EVENT, this._forms.ASSETTREE, this._forms.HRBUCKET, this._forms.CLASS];
                break;
            case this._searchType.AVATAR:
                values = [this._forms.EVENT, this._forms.JOBDATA, this._forms.HRBUCKET, this._forms.CLASS, this._forms.VHR];
                break;
            case this._searchType.EVENTAVATAR:
                values = [this._forms.EVENT, this._forms.JOBDATA, this._forms.HRBUCKET, this._forms.CLASS, this._forms.VHR];
                break;
            case this._searchType.EVENTASSET:
                values = [this._forms.EVENT, this._forms.ASSET, this._forms.CLASS];
                break;
            case this._searchType.FILEBOX:
                values = [this._forms.FILEBOX, this._forms.FILEMETADATA];
                break;
        }

        return this.getObjByConstantValues(this._forms, values);
    }

    getFieldIdsObj(searchType) {
        var result = {};
        switch (searchType) {
            case this._searchType.ACT:
                this.getMergedObject(result, this._fIdAct);
                break;
            case this._searchType.TARGET:
                this.getMergedObject(result, this._fIdTarget);
                break;
            case this._searchType.ASSET:
                this.getMergedObject(result, this._fIdEvent);
                this.getMergedObject(result, this._fIdAsset);
                this.getMergedObject(result, this._fIdClass);
                this.getMergedObject(result, this._fIdJobData);
                this.getMergedObject(result, this._fIdBom);
                break;
            case this._searchType.ASSETTREE:
                this.getMergedObject(result, this._fIdEvent);
                this.getMergedObject(result, this._fIdAssetTree);
                this.getMergedObject(result, this._fIdClass);
                this.getMergedObject(result, this._fIdJobData);
                this.getMergedObject(result, this._fIdBom);
                break;
            case this._searchType.AVATAR:
                this.getMergedObject(result, this._fIdEvent);
                this.getMergedObject(result, this._fIdPerson);
                this.getMergedObject(result, this._fIdClass);
                this.getMergedObject(result, this._fIdJobData);
                this.getMergedObject(result, this._fIdBom);
                break;
            case this._searchType.EVENTAVATAR:
                this.getMergedObject(result, this._fIdEvent);
                this.getMergedObject(result, this._fIdPerson);
                this.getMergedObject(result, this._fIdJobData);
                this.getMergedObject(result, this._fIdClass);
                this.getMergedObject(result, this._fIdBom);
                break;
            case this._searchType.EVENTASSET:
                this.getMergedObject(result, this._fIdEvent);
                this.getMergedObject(result, this._fIdAsset);
                this.getMergedObject(result, this._fIdJobData);
                this.getMergedObject(result, this._fIdClass);
                this.getMergedObject(result, this._fIdBom);
                break;
            case this._searchType.FILEBOX:
                this.getMergedObject(result, this._fIdFilebox);
                break;
        }

        return result;
    }

    getVisibleFieldArray(searchType) {
        var result = [];
        switch (searchType) {
            case this._searchType.ACT:
                result = [
                    this._fIdAct.ACT_ActStartDate,
                    this._fIdAct.ACT_ActExpireDate,
                    this._fIdAct.ACT_ActExecutionDate
                ];
                break;
            case this._searchType.TARGET:
                result = [
                    this._fIdTarget.TARGET_TargetCode,
                    this._fIdTarget.TARGET_TargetName,
                    this._fIdClass.VIRTUALHR_CategoriesId
                ];
                break;
            case this._searchType.ASSET:
                result = [
                    this._fIdAsset.ASSET_VirtualHumanResourcesId,
                    this._fIdAsset.ASSET_EquipmentCode,
                    this._fIdAsset.ASSET_EquipmentName,
                    this._fIdAsset.ASSET_RegistrationNumber,
                    this._fIdBom.ACTIVITY_ActivityIds,
                    this._fIdClass.VIRTUALHR_PlantIds
                ];
                break;
            case this._searchType.ASSETTREE:
                result = [
                    this._fIdAssetTree.ASSETTREE_VirtualHumanResourcesId,
                    this._fIdAssetTree.ASSETTREE_VirtualCode,
                    this._fIdAssetTree.ASSETTREE_VirtualName,
                    this._fIdAssetTree.ASSETTREE_VirtualEquipmentRegistrationNumber,
                    this._fIdBom.ACTIVITY_ActivityIds,
                    this._fIdClass.VIRTUALHR_PlantIds
                ];
                break;
            case this._searchType.AVATAR:
                result = [
                    this._fIdEvent.EVENT_VhrEventBillingStatus,
                    this._fIdPerson.PERSON_FirstName,
                    this._fIdPerson.PERSON_LastName,
                    this._fIdPerson.PERSON_RegistrationNumber,
                    this._fIdPerson.PERSON_RegistrationNumberExt,
                    this._fIdPerson.PERSON_TaxIdentificationNumber,
                    this._fIdJobData.PERSON_JobRoleId,
                    this._fIdJobData.PERSON_LegalQualificationId,
                    this._fIdJobData.PERSON_BusinessRoleId,
                    this._fIdJobData.PERSON_SafetyRoleId,
                    this._fIdBom.ACTIVITY_ActivityIds,
                    this._fIdClass.VIRTUALHR_CategoriesId
                ];
                break;
            case this._searchType.EVENTAVATAR:
                result = [
                    this._fIdEvent.EVENT_VhrEventBillingStatus,
                    this._fIdPerson.PERSON_FirstName,
                    this._fIdPerson.PERSON_LastName,
                    this._fIdPerson.PERSON_RegistrationNumber,
                    this._fIdPerson.PERSON_RegistrationNumberExt,
                    this._fIdPerson.PERSON_TaxIdentificationNumber,
                    this._fIdJobData.PERSON_JobRoleId,
                    this._fIdJobData.PERSON_LegalQualificationId,
                    this._fIdJobData.PERSON_BusinessRoleId,
                    this._fIdJobData.PERSON_SafetyRoleId,
                    this._fIdBom.ACTIVITY_ActivityIds,
                    this._fIdClass.VIRTUALHR_CategoriesId
                ];
                break;
            case this._searchType.EVENTASSET:
                result = [
                    this._fIdEvent.EVENT_StartDate,
                    this._fIdEvent.EVENT_VhrEventTypesId,
                    this._fIdEvent.EVENT_VhrEventGroupsId,
                    this._fIdEvent.EVENT_IsExecuted,
                    this._fIdEvent.EVENT_IsApproved,
                    this._fIdEvent.EVENT_IsCustomerApproval,
                    this._fIdEvent.EVENT_WorkCentersId,
                    this._fIdEvent.EVENT_VhrEventBillingStatus,
                    this._fIdAsset.ASSET_VirtualHumanResourcesId,
                    this._fIdAsset.ASSET_RegistrationNumber,
                    this._fIdBom.ACTIVITY_ActivityIds,
                    this._fIdClass.VIRTUALHR_CategoriesId
                ];
                break;
            case this._searchType.FILEBOX:
                result = [this._fIdFilebox.FILEBOX_FileBoxName, this._fIdFilebox.FILEBOX_FileBoxCode, this._fIdFilebox.FILEBOX_FileBoxName, this._fIdFilebox.FILEBOX_FileBoxYear];
                break;
        }


        return result;
    }

    getFilterNodesArray(searchType) {
        var result = this._filterNodesRoot;

        switch (searchType) {
            case this._searchType.ACT:
                result = Lazy(result)
                    .union(this._filterNodesAct)
                    .toArray();
                break;
            case this._searchType.TARGET:
                result = Lazy(result)
                    .union(this._filterNodesTarget)
                    .toArray();
                break;
            case this._searchType.ASSET:
                result = Lazy(result)
                    .union(this._filterNodesAsset)
                    .union(this._filterNodesEvent)
                    .union(this._filterNodesBom)
                    .union(this._filterNodesClassification)
                    .union(this._filterNodesHrBucket)
                    .toArray();
                break;
            case this._searchType.ASSETTREE:
                result = Lazy(result)
                    .union(this._filterNodesAssetTree)
                    .union(this._filterNodesEvent)
                    .union(this._filterNodesBom)
                    .union(this._filterNodesClassification)
                    .union(this._filterNodesHrBucket)
                    .toArray();
                break;
            case this._searchType.AVATAR:
                result = Lazy(result)
                    .union(this._filterNodesVhr)
                    .union(this._filterNodesJobData)
                    // event filter has moduletargetid mandatory (process)
                    // do separated searchs for different module target
                    //.union(this._filterNodesEvent)
                    .union(this._filterNodesHrBucket)
                    .union(this._filterNodesBom)
                    .union(this._filterNodesClassification)
                    .toArray();
                break;
            case this._searchType.EVENTAVATAR:
                result = Lazy(result)
                    .union(this._filterNodesEvent)
                    .union(this._filterNodesBom)
                    .union(this._filterNodesClassification)
                    .union(this._filterNodesHrBucket)
                    .union(this._filterNodesVhr)
                    .union(this._filterNodesJobData)
                    .toArray();
                break;
            case this._searchType.EVENTASSET:
                result = Lazy(result)
                    .union(this._filterNodesEvent)
                    .union(this._filterNodesBom)
                    .union(this._filterNodesClassification)
                    .union(this._filterNodesAsset)
                    .toArray();

                break;
            case this._searchType.FILEBOX:
                result = Lazy(result)
                    .union(this._filterNodesFileBox)
                    .union(this._filterNodesFileMetadata)
                    .toArray();
                break;
        }
        return result;
    }

    // returns all data for search form
    pData(searchType) {
        return {
            Constants: {
                Nodes: this.getNodesObj(searchType),
                Filters: this.getFiltersObj(searchType),
                Forms: this.getFormsObj(searchType),
                FieldIds: this.getFieldIdsObj(searchType),
                VisibleFieldsDefault: this.getVisibleFieldArray(searchType)
            },
            FilterNodes: this.getFilterNodesArray(searchType)
        };
    }

    ParseVirtualPersonFilter(virtualPersonFilter, virtualHrFilter, appStoreBw) {
        if (!virtualPersonFilter) virtualPersonFilter = {};
        if (!virtualHrFilter) virtualHrFilter = {};

        var filter: any = {};
        filter.HaveToExpandCategories = true;
        Lazy(Object.keys(virtualPersonFilter))
            .each((p) => {
                filter[p] = virtualPersonFilter[p] !== null && virtualPersonFilter[p] !== undefined ? virtualPersonFilter[p] : null;
            });
        Lazy(Object.keys(virtualHrFilter))
            .each((p) => {
                filter[p] = virtualHrFilter[p] !== null && virtualHrFilter[p] !== undefined ? virtualHrFilter[p] : null;
            });

        filter.AppStoreBw = appStoreBw;

        // virtualPersonFilter
        filter.CompaniesUsedId = Lazy(virtualPersonFilter.CompaniesUsedId).toArray();
        filter.CompaniesDefinedId = Lazy(virtualPersonFilter.CompaniesDefinedId).toArray();
        filter.JobRoleId = Lazy(virtualPersonFilter.JobRoleId).toArray();
        filter.LegalQualificationId = Lazy(virtualPersonFilter.LegalQualificationId).toArray();
        filter.BusinessRoleId = Lazy(virtualPersonFilter.BusinessRoleId).toArray();
        filter.SafetyRoleId = Lazy(virtualPersonFilter.SafetyRoleId).toArray();
        filter.CcDefinedId = Lazy(virtualPersonFilter.CcDefinedId).toArray();
        filter.CcUsedId = Lazy(virtualPersonFilter.CcUsedId).toArray();
        filter.BuDefinedId = Lazy(virtualPersonFilter.BuDefinedId).toArray();
        filter.BuUsedId = Lazy(virtualPersonFilter.BuUsedId).toArray();
        filter.PlaceDefinedId = Lazy(virtualPersonFilter.PlaceDefinedId).toArray();
        filter.PlaceUsedId = Lazy(virtualPersonFilter.PlaceUsedId).toArray();

        // HrBucketIds is a group of ids (businessunit, sector, ....)
        filter.HrBucketIds = Lazy(virtualHrFilter.LegalEntityIds)
            .union(Lazy(virtualHrFilter.CostCenterIds))
            .union(Lazy(virtualHrFilter.BusinessUnitIds))
            .union(Lazy(virtualHrFilter.SectorIds))
            .union(Lazy(virtualHrFilter.PlantIds))
            .union(Lazy(virtualHrFilter.HomogeneousGroupIds))
            .uniq()
            .toArray();

        // virtualHrFilter
        filter.CategoriesId = Lazy(virtualHrFilter.CategoriesId).toArray();
        filter.InheritedCategoriesId = Lazy(virtualHrFilter.InheritedCategoriesId).toArray();

        this.cleanAllFilterProperties(filter);
        return filter;
    }
    ParseVhrEventFilter(vhrEventFilter, appStoreBw, vhrEventKindEnum, expressionTypeEnum) {
        if (!vhrEventFilter) vhrEventFilter = {};

        vhrEventFilter.AppStoreBw = appStoreBw;
        vhrEventFilter.VhrEventKind = vhrEventKindEnum.Data; //exclude Container
        vhrEventFilter.StartDate1Expression = expressionTypeEnum.GreaterThanOrEqual;
        vhrEventFilter.StartDate2Expression = expressionTypeEnum.LessThanOrEqual;
        vhrEventFilter.VhrEventLifeStatusExpression = expressionTypeEnum.Equal;

        vhrEventFilter.VhrEventGroupsId = Lazy(vhrEventFilter.VhrEventGroupsId).toArray();
        vhrEventFilter.VhrEventLabelsId = Lazy(vhrEventFilter.VhrEventLabelsId).toArray();
        vhrEventFilter.VhrEventTypesId = Lazy(vhrEventFilter.VhrEventTypesId).toArray();
        vhrEventFilter.WorkCentersId = Lazy(vhrEventFilter.WorkCentersId).toArray();
        vhrEventFilter.VirtualHumanResourcesId = Lazy(vhrEventFilter.VirtualHumanResourcesId).toArray();

        this.cleanAllFilterProperties(vhrEventFilter);

        return vhrEventFilter;
    }
    ParseActivityVhrCoreFilter(activityVhrCoreFilter, appStoreBw) {
        if (!activityVhrCoreFilter) activityVhrCoreFilter = {};

        activityVhrCoreFilter.ActivityIds = Lazy(activityVhrCoreFilter.ActivityIds).toArray();

        this.cleanAllFilterProperties(activityVhrCoreFilter);
        return activityVhrCoreFilter;
    }
    ParseVirtualEquipmentFilter(virtualEquipmentFilter, virtualHrFilter, appStoreBw) {
        if (!virtualEquipmentFilter) virtualEquipmentFilter = {};
        if (!virtualHrFilter) virtualHrFilter = {};

        var filter: any = {};
        filter.HaveToExpandCategories = true;
        filter.HaveToExpandVhrsWithAssetTree = true;
        Lazy(Object.keys(virtualEquipmentFilter))
            .each((p) => {
                filter[p] = virtualEquipmentFilter[p] !== null && virtualEquipmentFilter[p] !== undefined ? virtualEquipmentFilter[p] : null;
            });
        Lazy(Object.keys(virtualHrFilter))
            .each((p) => {
                filter[p] = virtualHrFilter[p] !== null && virtualHrFilter[p] !== undefined ? virtualHrFilter[p] : null;
            });

        filter.AppStoreBw = appStoreBw;

        // virtualHrFilter
        filter.CategoriesId = Lazy(virtualHrFilter.CategoriesId).toArray();

        // HrBucketIds is a group of ids (businessunit, sector, ....)

        filter.HrBucketIds = Lazy(virtualHrFilter.LegalEntityIds)
            .union(Lazy(virtualHrFilter.CostCenterIds))
            .union(Lazy(virtualHrFilter.BusinessUnitIds))
            .union(Lazy(virtualHrFilter.SectorIds))
            .union(Lazy(virtualHrFilter.PlantIds))
            .union(Lazy(virtualHrFilter.HomogeneousGroupIds))
            .uniq()
            .toArray();

        this.cleanAllFilterProperties(filter);
        return filter;
    }
    ParseAssetTreeFilter(assetTreeFilter, virtualHrFilter, appStoreBw) {
        if (!assetTreeFilter) assetTreeFilter = {};
        if (!virtualHrFilter) virtualHrFilter = {};

        var filter: any = {};
        filter.HaveToExpandCategories = true;
        filter.HaveToExpandVhrsWithAssetTree = true;
        filter.AssetTreeEntityType = 'VirtualEquipment';
        Lazy(Object.keys(assetTreeFilter))
            .each((p) => {
                filter[p] = assetTreeFilter[p] !== null && assetTreeFilter[p] !== undefined ? assetTreeFilter[p] : null;
            });
        Lazy(Object.keys(virtualHrFilter))
            .each((p) => {
                filter[p] = virtualHrFilter[p] !== null && virtualHrFilter[p] !== undefined ? virtualHrFilter[p] : null;
            });

        filter.AppStoreBw = appStoreBw;

        // virtualHrFilter
        filter.CategoriesId = Lazy(virtualHrFilter.CategoriesId).toArray();

        // HrBucketIds is a group of ids (businessunit, sector, ....)

        filter.HrBucketIds = Lazy(virtualHrFilter.LegalEntityIds)
            .union(Lazy(virtualHrFilter.CostCenterIds))
            .union(Lazy(virtualHrFilter.BusinessUnitIds))
            .union(Lazy(virtualHrFilter.SectorIds))
            .union(Lazy(virtualHrFilter.PlantIds))
            .union(Lazy(virtualHrFilter.HomogeneousGroupIds))
            .uniq()
            .toArray();

        this.cleanAllFilterProperties(filter);
        return filter;
    }
    ParseFileBoxFilter(fileBoxFilter, appStoreBw, expressionTypeEnum, fileBoxTag) {
        if (!fileBoxFilter) fileBoxFilter = {};
        var filter: any = { FileBoxTag: fileBoxTag };

        Lazy(Object.keys(fileBoxFilter))
            .each((p) => {
                filter[p] = fileBoxFilter[p] !== null && fileBoxFilter[p] !== undefined ? fileBoxFilter[p] : null;
            });

        //filter.AppStoreBw = appStoreBw;
        filter.StartDate1Expression = expressionTypeEnum.GreaterThanOrEqual;
        filter.StartDate2Expression = expressionTypeEnum.LessThanOrEqual;

        return filter;
    }
    ParseFileMetadataFilter(fileMetadataFilter, appStoreBw) {
        if (!fileMetadataFilter) fileMetadataFilter = {};
        var filter: any = {};

        Lazy(Object.keys(fileMetadataFilter))
            .each((p) => {
                filter[p] = fileMetadataFilter[p] !== null && fileMetadataFilter[p] !== undefined ? fileMetadataFilter[p] : null;
            });

        filter.CategoriesId = Lazy(fileMetadataFilter.CategoriesId).toArray();

        this.cleanAllFilterProperties(filter);
        return filter;
    }
    ParseActFilter(actFilter) {
        if (!actFilter) actFilter = {};

        actFilter.ActTargetIds = Lazy(actFilter.ActTargetIds).toArray();
        actFilter.CategoriesId = Lazy(actFilter.CategoriesId).toArray();

        this.cleanAllFilterProperties(actFilter);
        return actFilter;
    }
    ParseTargetFilter(targetFilter, appStoreBw) {
        if (!targetFilter) targetFilter = {};
        this.cleanAllFilterProperties(targetFilter);
        return targetFilter;
    }

    SearchAssetTreesFormData() {
        var data = this.pData(this._searchType.ASSETTREE);
        var searchFormDataBase = this.searchFormDataBase(data.Constants, data.FilterNodes, this.translator);
        return searchFormDataBase;
    }
    SearchAvatarsFormData() {
        var data = this.pData(this._searchType.AVATAR);
        var searchFormDataBase = this.searchFormDataBase(data.Constants, data.FilterNodes, this.translator);
        return searchFormDataBase;
    }
    SearchVhrEventAvatarsFormData(vhrEventTypeCode) {
        var data: any = this.pData(this._searchType.EVENTAVATAR);

        if (vhrEventTypeCode) {
            if (!Lazy(data.Constants.VisibleFieldsDefault).contains(data.Constants.FieldIds.EVENT_VhrEventTypesId)) {
                data.Constants.VisibleFieldsDefault.push(data.Constants.FieldIds.EVENT_VhrEventTypesId);
            }
        }

        var searchFormDataBase = this.searchFormDataBase(data.Constants, data.FilterNodes, this.translator);
        return searchFormDataBase;
    }
    SearchVhrEventAssetsFormData(vhrEventTypeCode, isCustomer) {
        var data: any = this.pData(this._searchType.EVENTASSET);

        if (isCustomer) {
            var FilterNodes = Lazy(data.FilterNodes).reject({ FieldId: 'currentVhrEventFilter.IsApproved' }).toArray();
        }

        if (vhrEventTypeCode) {
            if (!Lazy(data.Constants.VisibleFieldsDefault).contains(data.Constants.FieldIds.EVENT_VhrEventTypesId)) {
                data.Constants.VisibleFieldsDefault.push(data.Constants.FieldIds.EVENT_VhrEventTypesId);
            }
        }

        var searchFormDataBase = this.searchFormDataBase(data.Constants, data.FilterNodes, this.translator);
        return searchFormDataBase;
    }
    SearchFileboxesFormData() {
        var data = this.pData(this._searchType.FILEBOX);
        var searchFormDataBase = this.searchFormDataBase(data.Constants, data.FilterNodes, this.translator);
        return searchFormDataBase;
    }
    SearchActsFormData() {
        var data = this.pData(this._searchType.ACT);
        var searchFormDataBase = this.searchFormDataBase(data.Constants, data.FilterNodes, this.translator);
        return searchFormDataBase;
    }
    SearchTargetsFormData() {
        var data = this.pData(this._searchType.TARGET);
        var searchFormDataBase = this.searchFormDataBase(data.Constants, data.FilterNodes, this.translator);
        return searchFormDataBase;
    }
}

export class NbsNumberMatrix {
    Col: any;
    Row: any;

    cols: any;
    rows: any;
    nCols: any;
    constructor() {
        this.Col = class {
            index: any;
            watermark: any;
            label: any;
            value: any;
            constructor(label, watermark, index) {
                this.index = index; //this.cols.length;
                this.watermark = watermark ? watermark : '';
                this.label = label;
                this.value = null;
            }
        }

        this.Row = class {
            index: any;
            cols: any;
            constructor(cols, rowsLength) {
                this.index = rowsLength;
                this.cols = [];

                Lazy(cols)
                    .sortBy((col) => { return col.index; }, false) // ascending
                    .each((col) => {
                        this.cols.push({ index: col.index, label: col.label, watermark: col.watermark, value: null });
                    });
            }
        }

        this.cols = [];
        this.rows = [];
        this.nCols = 0;
    }

    addRow() {
        if (!Lazy(this.cols).some()) {
            throw new Error('NgNumberMatrix: cols not setted');
        }

        var row = new this.Row(this.cols, this.rows.length);
        this.rows.push(row);
    };

    deleteRow(rowIndex) {
        this.rows = Lazy(this.rows)
            .reject({ index: rowIndex })
            .toArray();

        // refresh rows index
        var index = -1;
        Lazy(this.rows)
            .each((row) => {
                index++;
                row.index = index;
            });
    };

    addCol(colLabel, watermark) {
        var col = new this.Col(colLabel, watermark, this.cols.length);
        this.cols.push(col);
        this.nCols = this.cols.length;
    };

    getValuesByCol(colIndex, withNullValue) {
        var values = [];
        Lazy(this.rows)
            .each((row) => {
                var col = Lazy(row.cols).findWhere({ index: colIndex });
                if (col) {
                    if (withNullValue) {
                        values.push(col.value);
                    }
                    else {
                        if (col.value !== null && col.value !== undefined) {
                            values.push(col.value);
                        }
                    }
                }
            });

        return values;
    };
}

export class NbsActPermissionManager {
    translator: any;
    havePermissionFn: any;
    actServiceEnumTypes: any;
    virtualContext: any;
    useAddPermissionToModifyPostDefined: any;
    assignmentControlBypassed: any;
    parseEnumFn: any;
    actTypeEnumParsed: any;
    actTypeEnum: any;
    workflowStatusKindEnumParsed: any;
    workflowStatusKindEnum: any;
    validationSchema: any;
    permissionObj: any;

    constructor(params) {
        this.translator = params.translator;
        this.havePermissionFn = params.havePermissionFn;
        this.actServiceEnumTypes = params.actServiceEnumTypes;
        this.virtualContext = params.virtualContext;
        this.useAddPermissionToModifyPostDefined = false;
        this.assignmentControlBypassed = false;
        this.parseEnumFn = params.parseEnumFn;
        this.actTypeEnumParsed = this.parseEnumFn(this.actServiceEnumTypes.ActType, null, this.translator);
        this.actTypeEnum = this.actTypeEnumParsed.obj.normal.name_value;
        this.workflowStatusKindEnumParsed = this.parseEnumFn(this.actServiceEnumTypes.WorkflowStatusKind, null, this.translator);
        this.workflowStatusKindEnum = this.workflowStatusKindEnumParsed.obj.normal.name_value;
        this.validationSchema = params.validationSchema;
        this.permissionObj = params.permissionObj;
    }

    // this method is used when compiling survey
    // in compiling if an user has permission to add an action, then has permission to modify all fields and delete the same
    useAddPermissionForPostDefined() {
        this.useAddPermissionToModifyPostDefined = true;
    };

    byPassAssignmentControl() {
        this.assignmentControlBypassed = true;
    };

    getPermissionByActList(acts) {
        var permObjList = [];
        Lazy(acts)
            .each((act) => {
                var currentPermObj = this.getPermissionByAct(act);
                permObjList.push(currentPermObj);
            });

        var objPerm = this.getPermissionByList(permObjList);
        objPerm = this.parsePermissionsWithValidationSchema(objPerm);

        return objPerm;
    };

    getPermissionByList(permObjList) {
        if (!permObjList) {
            permObjList = [];
        }

        if (!Lazy(permObjList).some()) {
            return this.getPermissionObj(false);
        }

        var objAndable = this.getPermissionObj(true);

        Lazy(permObjList)
            .each((item) => {
                Lazy(Object.keys(item))
                    .each((p) => {
                        objAndable[p] = objAndable[p] && item[p];
                    });
            });

        return objAndable;
    }

    getPermissionByAct(act) {
        var objPerm = this.getPermissionObj(false);

        if (act && this.actTypeEnum) {
            objPerm.canManageAct = this.havePermissionFn(this.permissionObj['MANAGEACT'], this.virtualContext);

            if (act.ActWorkflowStatusKindId === this.workflowStatusKindEnum.Executed) {
                objPerm.canModifyAct = this.havePermissionFn(this.permissionObj['UPDATEACTEXECUTED'], this.virtualContext);
            }
            else {
                objPerm.canModifyAct = true;
            }

            if (!this.assignmentControlBypassed) {
                if (objPerm.canModifyAct) {
                    objPerm.isAssignedCurrentUser = this.isAssignedCurrentUser(act, this.virtualContext.appuserid, this.virtualContext.drbacusergroupids);
                    if (!objPerm.isAssignedCurrentUser && !objPerm.canManageAct) {
                        objPerm.canModifyAct = false;
                    }
                }
            }

            var parsedObjPerm = null;
            if (act.ActTypeId === this.actTypeEnum.Manually) {

                parsedObjPerm = this.getPermissionAct_Manually(objPerm);
            } else if (act.ActTypeId === this.actTypeEnum.PreDefined) {

                parsedObjPerm = this.getPermissionAct_PreDefined(objPerm);
            } else if (act.ActTypeId === this.actTypeEnum.PostDefined) {

                parsedObjPerm = this.getPermissionAct_PostDefined(objPerm);
            }

            parsedObjPerm = this.parsePermissionsWithValidationSchema(parsedObjPerm);

            return parsedObjPerm;
        } else {
            objPerm = this.parsePermissionsWithValidationSchema(objPerm);

            return objPerm;
        }
    };

    isAssignedCurrentUser(iEntityUsersAssignable, contextAppUserId, contextDrbacUserGroupIds) {
        // iEntityUsersAssignable must have fields
        //      UsersAssignedSerialized
        //      UserGroupsAssignedSerialized
        var isAssigned = false;

        // verify if act is assigned by user
        if (iEntityUsersAssignable.UsersAssignedSerialized) {
            var actUsersAssigned = JSON.parse(NbsCommonConverter.decodeHtml(iEntityUsersAssignable.UsersAssignedSerialized));
            if (actUsersAssigned) {
                var actUserAssignedIds = Lazy(actUsersAssigned).pluck('Value').map((item) => { return Number(item); }).toArray();

                if (Lazy(actUserAssignedIds).contains(contextAppUserId)) {
                    isAssigned = true;
                }
            }
        }

        // verify if act is assigned by group
        if (iEntityUsersAssignable.UserGroupsAssignedSerialized) {
            var actUserGroupsAssigned = JSON.parse(NbsCommonConverter.decodeHtml(iEntityUsersAssignable.UserGroupsAssignedSerialized));
            if (actUserGroupsAssigned) {
                var actUserGroupsAssignedIds = Lazy(actUserGroupsAssigned).pluck('Value').map((item) => { return Number(item); }).toArray();

                Lazy(actUserGroupsAssignedIds)
                    .each((userGroupAssignedId) => {
                        if (Lazy(contextDrbacUserGroupIds).contains(userGroupAssignedId)) {
                            isAssigned = true;
                        }
                    });
            }
        }

        return isAssigned;
    };

    getPermissionAct_Manually(objPerm) {
        objPerm.canAddAct = this.havePermissionFn(this.permissionObj['ADDACT'], this.virtualContext);
        objPerm.canManageAct = this.havePermissionFn(this.permissionObj['MANAGEACT'], this.virtualContext);
        objPerm.canDeleteAct = this.havePermissionFn(this.permissionObj['DELETEACT'], this.virtualContext) && objPerm.canModifyAct;

        objPerm.canModifyActQuestionOption = false;
        objPerm.canModifyActDescription = this.havePermissionFn(this.permissionObj['UPDACTDESCRIPTION'], this.virtualContext) && objPerm.canModifyAct;
        objPerm.canModifyActActionGroup = objPerm.canModifyAct;
        objPerm.canModifyActActionType = objPerm.canModifyAct;
        objPerm.canModifyActCategory = this.havePermissionFn(this.permissionObj['UPDACTCATEGORY'], this.virtualContext) && objPerm.canModifyAct;
        objPerm.canModifyActPriority = this.havePermissionFn(this.permissionObj['UPDACTPRIORITY'], this.virtualContext) && objPerm.canModifyAct;
        objPerm.canModifyActExpireDate = this.havePermissionFn(this.permissionObj['UPDACTEXPIRE'], this.virtualContext) && objPerm.canModifyAct;
        objPerm.canModifyActExpireDateQuota = objPerm.canModifyAct;
        objPerm.canModifyActWkfStatus = this.havePermissionFn(this.permissionObj['UPDACTWKFSTATUS'], this.virtualContext) && objPerm.canModifyAct;
        objPerm.canModifyActAssignment = this.havePermissionFn(this.permissionObj['UPDACTASSIGNMENT'], this.virtualContext) && objPerm.canModifyAct;
        objPerm.canModifyActAssignmentUsers = this.havePermissionFn(this.permissionObj['UPDACTASSIGNMENT'], this.virtualContext) && objPerm.canModifyAct;
        objPerm.canModifyActAssignmentUserGroups = this.havePermissionFn(this.permissionObj['UPDACTASSIGNMENT'], this.virtualContext) && objPerm.canModifyAct;

        return objPerm;
    }

    getPermissionAct_PreDefined(objPerm) {
        objPerm.canAddAct = this.havePermissionFn(this.permissionObj['ADDACT'], this.virtualContext);
        objPerm.canManageAct = this.havePermissionFn(this.permissionObj['MANAGEACT'], this.virtualContext);
        objPerm.canDeleteAct = this.havePermissionFn(this.permissionObj['DELETEACT'], this.virtualContext) && objPerm.canModifyAct;

        objPerm.canModifyActQuestionOption = this.havePermissionFn(this.permissionObj['UPDAUDACTQUESTIONOPTIONPRE'], this.virtualContext) && objPerm.canModifyAct;
        objPerm.canModifyActDescription = this.havePermissionFn(this.permissionObj['UPDACTDESCRIPTIONPRE'], this.virtualContext) && objPerm.canModifyAct;
        objPerm.canModifyActActionGroup = objPerm.canModifyAct;
        objPerm.canModifyActActionType = objPerm.canModifyAct;
        objPerm.canModifyActCategory = this.havePermissionFn(this.permissionObj['UPDACTCATEGORYPRE'], this.virtualContext) && objPerm.canModifyAct;
        objPerm.canModifyActPriority = this.havePermissionFn(this.permissionObj['UPDACTPRIORITYPRE'], this.virtualContext) && objPerm.canModifyAct;
        objPerm.canModifyActExpireDate = this.havePermissionFn(this.permissionObj['UPDACTEXPIREPRE'], this.virtualContext) && objPerm.canModifyAct;
        objPerm.canModifyActExpireDateQuota = objPerm.canModifyAct;
        objPerm.canModifyActWkfStatus = this.havePermissionFn(this.permissionObj['UPDACTWKFSTATUSPRE'], this.virtualContext) && objPerm.canModifyAct;
        objPerm.canModifyActAssignment = this.havePermissionFn(this.permissionObj['UPDACTASSIGNMENTPRE'], this.virtualContext) && objPerm.canModifyAct;
        objPerm.canModifyActAssignmentUsers = this.havePermissionFn(this.permissionObj['UPDACTASSIGNMENTPRE'], this.virtualContext) && objPerm.canModifyAct;
        objPerm.canModifyActAssignmentUserGroups = this.havePermissionFn(this.permissionObj['UPDACTASSIGNMENTPRE'], this.virtualContext) && objPerm.canModifyAct;
        return objPerm;
    }

    getPermissionAct_PostDefined(objPerm) {
        objPerm.canAddAct = this.havePermissionFn(this.permissionObj['ADDACT'], this.virtualContext);
        objPerm.canManageAct = this.havePermissionFn(this.permissionObj['MANAGEACT'], this.virtualContext);

        if (this.useAddPermissionToModifyPostDefined) {
            // at moment TRUE when compile survey
            objPerm.canModifyAct = objPerm.canAddAct;
            objPerm.canDeleteAct = objPerm.canAddAct;

            objPerm.canModifyActQuestionOption = objPerm.canAddAct;
            objPerm.canModifyActDescription = objPerm.canAddAct;
            objPerm.canModifyActActionGroup = objPerm.canAddAct;
            objPerm.canModifyActActionType = objPerm.canAddAct;
            objPerm.canModifyActCategory = objPerm.canAddAct;
            objPerm.canModifyActPriority = objPerm.canAddAct;
            objPerm.canModifyActExpireDate = objPerm.canAddAct;
            objPerm.canModifyActExpireDateQuota = objPerm.canAddAct;
            objPerm.canModifyActWkfStatus = objPerm.canAddAct;
            objPerm.canModifyActAssignment = objPerm.canAddAct;
            objPerm.canModifyActAssignmentUsers = objPerm.canAddAct;
            objPerm.canModifyActAssignmentUserGroups = objPerm.canAddAct;
        } else {
            //objPerm.canModifyAct = objPerm.canModifyAct;
            objPerm.canDeleteAct = this.havePermissionFn(this.permissionObj['DELETEACT'], this.virtualContext) && objPerm.canModifyAct;

            objPerm.canModifyActQuestionOption = this.havePermissionFn(this.permissionObj['UPDAUDACTQUESTIONOPTIONPOST'], this.virtualContext) && objPerm.canModifyAct;
            objPerm.canModifyActDescription = this.havePermissionFn(this.permissionObj['UPDACTDESCRIPTIONPOST'], this.virtualContext) && objPerm.canModifyAct;
            objPerm.canModifyActActionGroup = objPerm.canModifyAct;
            objPerm.canModifyActActionType = objPerm.canModifyAct;
            objPerm.canModifyActCategory = this.havePermissionFn(this.permissionObj['UPDACTCATEGORYPOST'], this.virtualContext) && objPerm.canModifyAct;
            objPerm.canModifyActPriority = this.havePermissionFn(this.permissionObj['UPDACTPRIORITYPOST'], this.virtualContext) && objPerm.canModifyAct;
            objPerm.canModifyActExpireDate = this.havePermissionFn(this.permissionObj['UPDACTEXPIREPOST'], this.virtualContext) && objPerm.canModifyAct;
            objPerm.canModifyActExpireDateQuota = objPerm.canModifyAct;
            objPerm.canModifyActWkfStatus = this.havePermissionFn(this.permissionObj['UPDACTWKFSTATUSPOST'], this.virtualContext) && objPerm.canModifyAct;
            objPerm.canModifyActAssignment = this.havePermissionFn(this.permissionObj['UPDACTASSIGNMENTPOST'], this.virtualContext) && objPerm.canModifyAct;
            objPerm.canModifyActAssignmentUsers = this.havePermissionFn(this.permissionObj['UPDACTASSIGNMENTPOST'], this.virtualContext) && objPerm.canModifyAct;
            objPerm.canModifyActAssignmentUserGroups = this.havePermissionFn(this.permissionObj['UPDACTASSIGNMENTPOST'], this.virtualContext) && objPerm.canModifyAct;
        }

        return objPerm;
    }

    parsePermissionsWithValidationSchema(objPerm) {
        if (this.validationSchema) {
            if (objPerm.canModifyActActionGroup === true) {
                objPerm.canModifyActActionGroup = this.validationSchema.Enabling.ActionGroup;
            }
            if (objPerm.canModifyActActionType === true) {
                objPerm.canModifyActActionType = this.validationSchema.Enabling.ActionType;
            }
            if (objPerm.canModifyActDescription === true) {
                objPerm.canModifyActDescription = this.validationSchema.Enabling.Description;
            }
            if (objPerm.canModifyActExpireDate === true) {
                objPerm.canModifyActExpireDate = this.validationSchema.Enabling.ExpireDate;
            }
            if (objPerm.canModifyActPriority === true) {
                objPerm.canModifyActPriority = this.validationSchema.Enabling.Priority;
            }
            if (objPerm.canModifyActCategory === true) {
                objPerm.canModifyActCategory = this.validationSchema.Enabling.Category;
            }
            if (objPerm.canModifyActAssignmentUserGroups === true) {
                objPerm.canModifyActAssignmentUserGroups = this.validationSchema.Enabling.UserGroupAssigned;
            }
            if (objPerm.canModifyActAssignmentUsers === true) {
                objPerm.canModifyActAssignmentUsers = this.validationSchema.Enabling.UserAssigned;
            }

            // visibility
            objPerm.canViewActCategory = this.validationSchema.Visibility.Category;
            objPerm.canViewActExpireDate = this.validationSchema.Visibility.ExpireDate;
            objPerm.canViewActDescription = this.validationSchema.Visibility.Description;
            objPerm.canViewActAssignmentUsers = this.validationSchema.Visibility.UserAssigned;
            objPerm.canViewActAssignmentUserGroups = this.validationSchema.Visibility.UserGroupAssigned;
            objPerm.canViewActActionGroup = this.validationSchema.Visibility.ActionGroup;
            objPerm.canViewActActionType = this.validationSchema.Visibility.ActionType;
            objPerm.canViewActPriority = this.validationSchema.Visibility.Priority;

            // validating
            objPerm.validateActCategory = this.validationSchema.Validation.Category;
            objPerm.validateActExpireDate = this.validationSchema.Validation.ExpireDate;
            objPerm.validateActDescription = this.validationSchema.Validation.Description;
            objPerm.validateActAssignmentUsers = this.validationSchema.Validation.UserAssigned;
            objPerm.validateActAssignmentUserGroups = this.validationSchema.Validation.UserGroupAssigned;
        }

        return objPerm;
    }

    getPermissionObj(defaultBool) {
        return {
            canAddAct: defaultBool,
            canManageAct: defaultBool,
            canDeleteAct: defaultBool,
            canModifyAct: defaultBool,

            // enabling 
            canModifyActDescription: defaultBool,
            canModifyActActionGroup: defaultBool,
            canModifyActActionType: defaultBool,
            canModifyActCategory: defaultBool,
            canModifyActPriority: defaultBool,
            canModifyActExpireDate: defaultBool,
            canModifyActExpireDateQuota: defaultBool,
            canModifyActWkfStatus: defaultBool,
            canModifyActAssignment: defaultBool,
            canModifyActAssignmentUsers: defaultBool,
            canModifyActAssignmentUserGroups: defaultBool,
            canModifyActQuestionOption: defaultBool,

            // visibility
            canViewActCategory: defaultBool,
            canViewActExpireDate: defaultBool,
            canViewActDescription: defaultBool,
            canViewActAssignmentUsers: defaultBool,
            canViewActAssignmentUserGroups: defaultBool,
            canViewActActionGroup: defaultBool,
            canViewActActionType: defaultBool,
            canViewActPriority: defaultBool,

            // validating
            validateActCategory: defaultBool,
            validateActExpireDate: defaultBool,
            validateActDescription: defaultBool,
            validateActAssignmentUsers: defaultBool,
            validateActAssignmentUserGroups: defaultBool,

            isAssignedCurrentUser: defaultBool
        };
    }

}

class NbsGuidManager {
    constructor() {
    }

    static newGuid() {
        function _p8(s?) {
            var p = (Math.random().toString(16) + "000000000").substr(2, 8);
            return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
        }
        return _p8() + _p8(true) + _p8(true) + _p8();
    }

    static newGuidReplaced() {
        var newGuid = this.newGuid();
        return newGuid.replace("-", "");
    }

    static emptyGuid() {
        return '00000000-0000-0000-0000-000000000000';
    }
}

class NbsWizardManager {
    WzCollector_VhrEventManualWizard: any;
    WzCollector_ScheduledTaskWizard: any;
    WzResult_ScheduledTask: any;
    WzResult_VhrEventManualRequest: any;
    WzConfig_AddScheduledTask: any;
    WzConfig_AddDependentActivity: any;
    WzConfig_AddVhrEventManual: any;
    WzConfig_UpdateVhrEventManual: any;

    nbsGuidManager: any;
    services: any;
    translator: any;
    SKIPPEDBYDEFAULT: any;
    defer: any;
    isFirstStep: any;
    stepsToSkip: any;
    currentStep: any;

    wzConfig: any;
    navigationService: any;

    collectDataFn: any;
    initCollectionDataFn: any;
    getResultDataFn: any;

    step: any;
    constructor($q, translator, services, nbsGuidManager) {
        if (!translator) {
            translator = function (text) {
                return text;
            };
        }

        if (!services) {
            throw new Error('NbsWizardManager: services mandatory');
        }

        if (!services) {
            throw new Error('NbsWizardManager: nbsGuidManager mandatory');
        }

        // #region Wizard Collectors
        this.WzCollector_VhrEventManualWizard = class {
            Code: any;
            Name: any;
            Description: any;
            ExternalCode: any;
            ModuleTargetId: any;
            WorkCenterId: any;
            VhrEventTemplateId: any;
            VhrEventTypeId: any;
            VhrEventGroupId: any;
            RecurrenceStartDate: any;
            RecurrenceEndDate: any;
            EventStartTime: any;
            EventEndTime: any;
            IsRecurrent: any;
            VhrEventTransactionCode: any;
            VirtualHumanResources: any;
            VhrEventManualRequestTypeId: any;
            VhrEvents: any;
            ActivityId: any;

            constructor(wzConfig) {
                this.Code = wzConfig.defaultData.Code ? wzConfig.defaultData.Code : null;
                this.Name = wzConfig.defaultData.Name ? wzConfig.defaultData.Name : null;
                this.Description = wzConfig.defaultData.Description ? wzConfig.defaultData.Description : null;
                this.ExternalCode = wzConfig.defaultData.ExternalCode ? wzConfig.defaultData.ExternalCode : null;
                this.ModuleTargetId = wzConfig.defaultData.ModuleTargetId ? wzConfig.defaultData.ModuleTargetId : null;
                this.WorkCenterId = wzConfig.defaultData.WorkCenterId ? wzConfig.defaultData.WorkCenterId : null;
                this.VhrEventTemplateId = wzConfig.defaultData.VhrEventTemplateId ? wzConfig.defaultData.VhrEventTemplateId : null;
                this.VhrEventTypeId = wzConfig.defaultData.VhrEventTypeId ? wzConfig.defaultData.VhrEventTypeId : null;
                this.VhrEventGroupId = wzConfig.defaultData.VhrEventGroupId ? wzConfig.defaultData.VhrEventGroupId : null;
                this.RecurrenceStartDate = wzConfig.defaultData.RecurrenceStartDate ? wzConfig.defaultData.RecurrenceStartDate : null;
                this.RecurrenceEndDate = wzConfig.defaultData.RecurrenceEndDate ? wzConfig.defaultData.RecurrenceEndDate : null;
                this.EventStartTime = wzConfig.defaultData.EventStartTime ? wzConfig.defaultData.EventStartTime : "PT0H0M";
                this.EventEndTime = wzConfig.defaultData.EventEndTime ? wzConfig.defaultData.EventEndTime : "PT0H0M";
                this.IsRecurrent = wzConfig.defaultData.IsRecurrent ? wzConfig.defaultData.IsRecurrent : false;
                this.VhrEventTransactionCode = wzConfig.defaultData.VhrEventTransactionCode ? wzConfig.defaultData.VhrEventTransactionCode : null;
                this.VirtualHumanResources = wzConfig.defaultData.VirtualHumanResources ? wzConfig.defaultData.VirtualHumanResources : null;
                this.VhrEventManualRequestTypeId = wzConfig.defaultData.VhrEventManualRequestTypeId ? wzConfig.defaultData.VhrEventManualRequestTypeId : null;
                this.VhrEvents = wzConfig.defaultData.VhrEvents ? wzConfig.defaultData.VhrEvents : null;
                this.ActivityId = wzConfig.defaultData.ActivityId ? wzConfig.defaultData.ActivityId : null;
            }
        }
        this.WzCollector_ScheduledTaskWizard = class {
            Code: any;
            Name: any;
            Time: any;
            constructor(wzConfig) {
                this.Code = wzConfig.defaultData.Code ? wzConfig.defaultData.Code : null;
                this.Name = wzConfig.defaultData.Name ? wzConfig.defaultData.Name : null;
                this.Time = wzConfig.defaultData.Time ? wzConfig.defaultData.Time : "PT0H0M";
            }
        }
        // #endregion

        // #region Wiward Results
        this.WzResult_ScheduledTask = class {
            Code: any;
            Name: any;
            Time: any;
            CronExpression: any;
            constructor(wzConfig, services) {
                this.Code = services.utilService.scheduledTaskCollector.Code;
                this.Name = services.utilService.scheduledTaskCollector.Name;
                this.Time = services.utilService.scheduledTaskCollector.Time;
                this.CronExpression = services.utilService.scheduledTaskCollector.CronExpression;
            }
        }
        this.WzResult_VhrEventManualRequest = class {
            VhrEventManualRequestId: any;

            VhrEventManualRequestCode: any;
            VhrEventManualRequestName: any;
            VhrEventManualRequestExternalCode: any;
            VhrEventManualRequestDescription: any;
            ModuleTargetId: any;
            VhrEventTemplateId: any;
            VhrEventGroupId: any;
            VhrEventTypeId: any;
            WorkCenterId: any;

            IsRecurrent: any;
            CronExpression: any;
            VhrEventTransactionCode: any;
            VhrEventManualRequestTypeId: any;
            VhrEventCreationSourceRequest: any;

            irtualHumanResources: any;
            hrEvents: any;
            VirtualHumanResourcesSerialized: any;
            VhrEventsSerialized: any;


            RecurrenceStartDate: any;
            EventStartTime: any;
            RecurrenceEndDate: any;
            EventEndTime: any;

            VhrEventCreationArchetype: any;

            ActivityId: any;
            BatchRequestName: any;

            step: any;
            constructor(wzConfig, translator, services, nbsGuidManager) {
                this.VhrEventManualRequestId = nbsGuidManager.newGuid();

                this.VhrEventManualRequestCode = services.vhreventService.vhrEventManualCollector.Code;
                this.VhrEventManualRequestName = services.vhreventService.vhrEventManualCollector.Name;
                this.VhrEventManualRequestExternalCode = services.vhreventService.vhrEventManualCollector.ExternalCode;
                this.VhrEventManualRequestDescription = services.vhreventService.vhrEventManualCollector.Description;
                this.ModuleTargetId = services.vhreventService.vhrEventManualCollector.ModuleTargetId;
                this.VhrEventTemplateId = services.vhreventService.vhrEventManualCollector.VhrEventTemplateId;
                this.VhrEventGroupId = services.vhreventService.vhrEventManualCollector.VhrEventGroupId;
                this.VhrEventTypeId = services.vhreventService.vhrEventManualCollector.VhrEventTypeId;
                this.WorkCenterId = services.vhreventService.vhrEventManualCollector.WorkCenterId;

                this.IsRecurrent = services.vhreventService.vhrEventManualCollector.IsRecurrent;
                this.CronExpression = services.vhreventService.vhrEventManualCollector.CronExpression;
                this.VhrEventTransactionCode = services.vhreventService.vhrEventManualCollector.VhrEventTransactionCode;
                this.VhrEventManualRequestTypeId = services.vhreventService.vhrEventManualCollector.VhrEventManualRequestTypeId;
                this.VhrEventCreationSourceRequest = 0;

                var virtualHumanResources = Lazy(services.vhreventService.vhrEventManualCollector.VirtualHumanResources).pluck('VirtualHumanResourceId').toArray();
                var vhrEvents = Lazy(services.vhreventService.vhrEventManualCollector.VhrEvents).pluck('VhrEventId').toArray();
                this.VirtualHumanResourcesSerialized = JSON.stringify(virtualHumanResources);
                this.VhrEventsSerialized = JSON.stringify(vhrEvents);

                // datetime
                this.RecurrenceStartDate = services.vhreventService.vhrEventManualCollector.RecurrenceStartDate;
                this.EventStartTime = services.vhreventService.vhrEventManualCollector.EventStartTime;
                this.RecurrenceEndDate = services.vhreventService.vhrEventManualCollector.RecurrenceEndDate;
                this.EventEndTime = services.vhreventService.vhrEventManualCollector.EventEndTime;

                this.VhrEventCreationArchetype = services.vhreventService.vhrEventManualCollector.IsRecurrent
                    ? wzConfig.supportData.vhrEventCreationArchetype.ManualRecurring
                    : wzConfig.supportData.vhrEventCreationArchetype.ManualSpot;



                this.ActivityId = services.vhreventService.vhrEventManualCollector.ActivityId;

                /*
                 * vhrEventManualRequestType
                 * Copy: 3
                 * Modify: 2
                 * New: 1
                 * NewByActivity: 4
                 * NewByActivityContainer: 5
                 * None: 0
                */
                if (this.VhrEventManualRequestTypeId === 3) { // Copy
                    this.BatchRequestName = translator('Copy') + ' - ' + this.VhrEventManualRequestName;
                } else if (this.VhrEventManualRequestTypeId === 2) { // Modify
                    this.BatchRequestName = translator('Modify') + ' - ' + this.VhrEventManualRequestName;
                } else if (this.VhrEventManualRequestTypeId === 1 || this.VhrEventManualRequestTypeId === 4 || this.VhrEventManualRequestTypeId === 5) { // New
                    this.BatchRequestName = translator('New') + ' - ' + this.VhrEventManualRequestName;
                } else {
                    this.BatchRequestName = translator('VhrEventManualRequestBatchName')
                }
            }
        }
        // #endregion

        // #region Wiward Configs
        this.WzConfig_AddScheduledTask = class {
            stepDetail: any;
            stepCron: any;
            stepSummary: any;
            stepsToSkip: any;
            supportData: any;
            defaultData: any;
            steps: any;
            constructor(viewMode) {
                // in manual thesaurus add 'WzBeardcrumbStep_' + step name translations
                this.stepDetail = 'scheduledTaskStepDetail';
                this.stepCron = 'cronExpressionDetail';
                this.stepSummary = 'scheduledTaskStepSummary';

                // do not remove    
                this.stepsToSkip = [];

                this.supportData = null;                                      // enums, ...

                // used to add default properties to use in wizard (DO NOT REMOVE)
                // managed in initCollectionData, to start with predefined properties
                this.defaultData = {};

                // mandatory: used to navigate wizard and to add route at runtime
                this.steps = [
                    { order: 1, name: this.stepDetail, isVisible: true, baseName: 'rds/jobs/' + this.stepDetail, viewMode: viewMode, modalKey: this.stepDetail },
                    { order: 2, name: this.stepCron, isVisible: true, params: { cronstring: null }, baseName: 'rds/common/' + this.stepCron, viewMode: viewMode, modalKey: this.stepCron },
                    { order: 3, name: this.stepSummary, isVisible: true, baseName: 'rds/jobs/' + this.stepSummary, viewMode: viewMode, modalKey: this.stepSummary }
                ];
            }
        }
        this.WzConfig_AddDependentActivity = class {
            stepActivity: any;
            stepInfo: any;
            stepSummary: any;
            stepsToSkip: any;
            supportData: any;
            defaultData: any;
            steps: any;
            constructor(viewMode) {
                // in manual thesaurus add 'WzBeardcrumbStep_' + step name translations
                this.stepActivity = 'wzAddActivityStepActivity';
                this.stepInfo = 'wzAddActivityStepInfo';
                this.stepSummary = 'wzAddActivityStepSummary';

                // do not remove    
                this.stepsToSkip = [];

                this.supportData = null;                                      // enums, ...

                // used to add default properties to use in wizard (DO NOT REMOVE)
                // managed in initCollectionData, to start with predefined properties
                this.defaultData = {};

                // mandatory: used to navigate wizard and to add route at runtime
                this.steps = [
                    { order: 1, name: this.stepActivity, isVisible: true, baseName: 'rds/activities/' + this.stepActivity, viewMode: viewMode, modalKey: this.stepActivity },
                    { order: 2, name: this.stepInfo, isVisible: true, params: { cronstring: null }, baseName: 'rds/activities/' + this.stepInfo, viewMode: viewMode, modalKey: this.stepInfo },
                    { order: 3, name: this.stepSummary, isVisible: true, baseName: 'rds/activities/' + this.stepSummary, viewMode: viewMode, modalKey: this.stepSummary }
                ];
            }
        }
        this.WzConfig_AddVhrEventManual = class {
            stepSwitch: any;
            stepActivity: any;
            stepDetail: any;
            stepCron: any;
            stepVhr: any;
            stepSummary: any;
            stepsToSkip: any;
            vhrEntityType: any;
            supportData: any;
            defaultData: any;
            steps: any;
            removeStep: any;
            constructor() {
                // in manual thesaurus add 'WzBeardcrumbStep_' + step name translations
                this.stepSwitch = 'vhrEventsManualStepSwitchFromActivity';
                this.stepActivity = 'vhrEventsManualStepActivitySelection';
                this.stepDetail = 'vhrEventsManualStepEventDetail';
                this.stepCron = 'cronExpressionDetail';
                this.stepVhr = 'vhrEventsManualStepVhr';
                this.stepSummary = 'vhrEventsManualStepSummary';

                // on layout step is disabled   
                this.stepsToSkip = [];

                // do not remove, used in this wizard to view virtual human resources list in stepVhr
                this.vhrEntityType = null;

                this.supportData = null;                                      // enums, ...

                // used to add default properties to use in wizard (DO NOT REMOVE)
                // managed in initCollectionData, to start with predefined properties
                this.defaultData = {};

                // mandatory: used to navigate wizard and to add route at runtime
                this.steps = [
                    { order: 1, name: this.stepSwitch, isVisible: true, baseName: 'rds/vhreventsmanual/' + this.stepSwitch, viewMode: '_', modalKey: this.stepSwitch },
                    { order: 2, name: this.stepActivity, isVisible: true, baseName: 'rds/vhreventsmanual/' + this.stepActivity, viewMode: '_', modalKey: this.stepActivity },
                    { order: 3, name: this.stepDetail, isVisible: true, baseName: 'rds/vhreventsmanual/' + this.stepDetail, viewMode: '_', modalKey: this.stepDetail },
                    { order: 4, name: this.stepCron, isVisible: true, params: { cronstring: null }, baseName: 'rds/common/' + this.stepCron, viewMode: 'vhreventmanual', modalKey: this.stepCron },
                    { order: 5, name: this.stepVhr, isVisible: true, baseName: 'rds/vhreventsmanual/' + this.stepVhr, viewMode: '_', modalKey: this.stepVhr },
                    { order: 6, name: this.stepSummary, isVisible: true, baseName: 'rds/vhreventsmanual/' + this.stepSummary, viewMode: '_', modalKey: this.stepSummary }
                ];

                // used to eventually remove step before launch the wizard
                this.removeStep = function (stepName) {
                    this.steps = Lazy(this.steps).reject({ name: stepName });
                };
            }
        }
        this.WzConfig_UpdateVhrEventManual = class {
            stepSwitch: any;
            stepDetail: any;
            stepCron: any;
            stepSummary: any;
            stepsToSkip: any;
            vhrEventTransactionCode: any;
            supportData: any;
            defaultData: any;
            steps: any;
            constructor(services) {
                // in manual thesaurus add 'WzBeardcrumbStep_' + step name translations
                this.stepSwitch = 'vhrEventsManualStepUpdateSwitch';
                this.stepDetail = 'vhrEventsManualStepUpdateEventDetail';
                this.stepCron = 'cronExpressionDetail';
                this.stepSummary = 'vhrEventsManualStepUpdateSummary';

                // do not remove    
                this.stepsToSkip = [];

                // mandatory in update vhreventmanual 
                this.vhrEventTransactionCode = null;

                this.supportData = null;                                      // enums, ...

                // used to add default properties to use in wizard (DO NOT REMOVE)
                // managed in initCollectionData, to start with predefined properties
                this.defaultData = {};

                // mandatory: used to navigate wizard and to add route at runtime
                this.steps = [
                    { order: 1, name: this.stepSwitch, isVisible: true, baseName: 'rds/vhreventsmanual/' + this.stepSwitch, viewMode: '_', modalKey: this.stepSwitch },
                    { order: 2, name: this.stepDetail, isVisible: true, baseName: 'rds/vhreventsmanual/' + this.stepDetail, viewMode: '_', modalKey: this.stepDetail },
                    { order: 3, name: this.stepCron, isVisible: true, params: { cronstring: null }, baseName: 'rds/common/' + this.stepCron, viewMode: 'vhreventmanualupdate', modalKey: this.stepCron },
                    { order: 4, name: this.stepSummary, isVisible: true, baseName: 'rds/vhreventsmanual/' + this.stepSummary, viewMode: '_', modalKey: this.stepSummary }
                ];
            }
        }
        // #endregion

        this.nbsGuidManager = nbsGuidManager;
        this.services = services;
        this.translator = translator;
        this.SKIPPEDBYDEFAULT = 'stepSkippedOnStart';
        this.defer = $q.defer();
        this.isFirstStep = true;
        this.stepsToSkip = [];
        this.currentStep = null;

        this.wzConfig = null;
        this.navigationService = null;

        this.collectDataFn = () => { throw new Error('collectDataFn method not implemented'); };
        this.initCollectionDataFn = () => { throw new Error('initCollectionDataFn method not implemented'); };
        this.getResultDataFn = () => { throw new Error('getResultDataFn method not implemented'); };
    }


    // add routes to router ui states to permitt wiizard navigation
    wzAddChildModalRouterUIState(baseName, viewMode, modalKey, services) {
        var routeName = this.wzConfig.parentState + modalKey;
        var state = this.navigationService.getUIRouterStateModal({ modalKey: modalKey, parentState: this.wzConfig.parentState, viewMode: viewMode, baseName: baseName, services: services });
        this.services.cmsService.addRouterUIStateResolve(routeName, state);
    }

    // used to set navigation buttons visibility in wizard steps
    initStepsParentParams() {
        var first = 1;
        var lastIndex = Lazy(this.wzConfig.steps).toArray().length;
        var stepNumber = 0;

        Lazy(this.wzConfig.steps)
            .each((step) => {
                stepNumber++;

                if (!step.params) {
                    step.params = {};
                }

                step.isLastStep = stepNumber === lastIndex;

                step.params.modalKey = step.name;                       // do not remove
                step.params.cancelBtnIsVisible = true;                  // is always possible exit from wizard
                step.params.okBtnIsVisible = false;                     // hide ok button in cron expression (shared view)
                step.params.endBtnIsVisible = stepNumber === lastIndex;
                step.params.nextBtnIsVisible = stepNumber !== lastIndex;
                step.params.prevBtnIsVisible = stepNumber !== first || step.prevBtnIsVisible;
            });
    }

    getStepByName(stepName) {
        return Lazy(this.wzConfig.steps).findWhere({ name: stepName });
    }

    //Function used to Sort a list by order field
    sortByOrder(step) {
        return step.order;
    };


    // search step in list of skipped steps and returns boolean
    isStepToSkip(step) {
        //stepsToSkip: [{ name: stepName, source: source }]
        var stepInSkipList = Lazy(this.stepsToSkip).findWhere({ name: step.name });
        if (stepInSkipList) {
            return true;
        } else {
            return false;
        }
    }

    removeStepsSkippedByCurrentStep(step) {
        this.stepsToSkip = Lazy(this.stepsToSkip)
            .filter((stepSkipped) => {
                return stepSkipped.source !== step.name || stepSkipped.source === this.SKIPPEDBYDEFAULT;
            }).toArray();
    }

    setNextStep() {
        // stepsToSkip: array of step names
        var current = this.getStepByName(this.currentStep.name);
        var nextStep = Lazy(this.wzConfig.steps)
            .sortBy(this.sortByOrder)    //ascending
            .filter((step) => { return step.order > current.order; })
            .first();

        if (this.isStepToSkip(nextStep)) {
            this.currentStep = nextStep;
            this.setNextStep();
        } else {
            this.currentStep = nextStep;
        }
    }

    // manages the step skipped too
    setPreviousStep() {
        var current = this.getStepByName(this.currentStep.name);
        var prevStep = Lazy(this.wzConfig.steps)
            .sortBy(this.sortByOrder, true) //descending
            .filter((step) => { return step.order < current.order; })
            .first();

        if (this.isStepToSkip(prevStep)) {
            this.currentStep = prevStep;
            this.setPreviousStep();
        } else {
            this.removeStepsSkippedByCurrentStep(prevStep);
            this.currentStep = prevStep;
        }
    }

    getBeardCrumbInformation(currentStep) {
        //'nbs-wzd-active': item.isActive == true,
        //'nbs-wzd-disabled': item.isDisabled == true,
        //'nbs-wzd-inactive': item.isActive != true,
        //'nbs-wzd-laststep': item.isLastStep == true,
        //'nbs-wzd-warning': item.isWarning == true,
        //'nbs-wzd-danger': item.isDanger == true,

        var beardCrumbInfo: any = {};
        beardCrumbInfo.items = Lazy(this.wzConfig.steps)
            .sortBy(this.sortByOrder)
            .map((item) => {
                var itemStyle = 'nbs-wzd-inactive';
                var isDisabled = Lazy(this.stepsToSkip).where({ order: item.order }).some();

                if (item.order === currentStep.order) {
                    itemStyle = 'nbs-wzd-active';
                } else if (isDisabled) {
                    itemStyle = 'nbs-wzd-disabled';
                }

                if (item.isLastStep) {
                    itemStyle += ' nbs-wzd-laststep';
                }

                return {
                    title: 'WzBeardcrumbStep_' + item.name,
                    style: itemStyle
                };
            }).toArray();

        //Lazy(beardCrumbInfo.items).each((bcItem) => {
        //    bcItem.isDisabled = Lazy(this.stepsToSkip).where({ order: bcItem.order }).some();
        //});

        beardCrumbInfo.isVisible = currentStep.isVisible;
        return beardCrumbInfo;
    }

    addStepToSkip(stepToSkipName, stepSourceName?) {
        if (!stepSourceName) stepSourceName = this.SKIPPEDBYDEFAULT;
        var stepToSkip = this.getStepByName(stepToSkipName);
        if (stepToSkip) {
            this.stepsToSkip.push({ order: stepToSkip.order, name: stepToSkip.name, source: stepSourceName });
        }
    }

    setWzConfig(wzConfig) {
        this.wzConfig = wzConfig;
        // navigation service
        this.navigationService = this.services.navigationService;
        this.wzConfig.parentState = this.navigationService.currentState();

        // add routes to ui router at runtime
        Lazy(this.wzConfig.steps)
            .each((step) => {
                this.wzAddChildModalRouterUIState(step.baseName, step.viewMode, step.modalKey, step.services);
            });

        // steps to skip by configuration
        Lazy(this.wzConfig.stepsToSkip)
            .each((stepToSkipName) => {
                this.addStepToSkip(stepToSkipName);
            });

        this.step = Lazy(this.wzConfig.steps).sortBy(this.sortByOrder).first();
        this.currentStep = this.step;

        if (this.wzConfig instanceof this.WzConfig_AddScheduledTask) {
            configAddScheduledTask(this);
        } else if (this.wzConfig instanceof this.WzConfig_AddDependentActivity) {
            configAddDependentActivity(this)
        } else if (this.wzConfig instanceof this.WzConfig_AddVhrEventManual) {
            configAddVhrEventManual(this)
        } else if (this.wzConfig instanceof this.WzConfig_UpdateVhrEventManual) {
            configUpdateVhrEventManual(this)
        }

        function configUpdateVhrEventManual(wzInstance) {
            wzInstance.collectDataFn = function (modalResult, currentStep) {
                if (!wzInstance.services.vhreventService.vhrEventManualCollector) {
                    wzInstance.services.vhreventService.vhrEventManualCollector = {};
                }

                switch (modalResult.sourceParams.modalKey) {
                    case 'vhrEventsManualStepUpdateSwitch':
                        wzInstance.services.vhreventService.vhrEventManualCollector.Code = 'mode-transaction-code';
                        wzInstance.services.vhreventService.vhrEventManualCollector.Name = 'mode-transaction-code';
                        wzInstance.services.vhreventService.vhrEventManualCollector.IsRecurrent = modalResult.data.IsRecurrent;
                        break;

                    case 'vhrEventsManualStepUpdateEventDetail':
                        wzInstance.services.vhreventService.vhrEventManualCollector.RecurrenceStartDate = modalResult.data.RecurrenceStartDate;
                        wzInstance.services.vhreventService.vhrEventManualCollector.EventStartTime = modalResult.data.EventStartTime;
                        wzInstance.services.vhreventService.vhrEventManualCollector.RecurrenceEndDate = modalResult.data.RecurrenceEndDate;
                        wzInstance.services.vhreventService.vhrEventManualCollector.EventEndTime = modalResult.data.EventEndTime;
                        break;

                    case 'cronExpressionDetail':
                        wzInstance.services.vhreventService.vhrEventManualCollector.CronExpression = modalResult.data.getCronExpression();
                        wzInstance.services.vhreventService.vhrEventManualCollector.CronExpressionLabel = modalResult.data.getCronLabel();

                        currentStep.params.cronstring = wzInstance.services.vhreventService.vhrEventManualCollector.CronExpression;
                        break;

                    case 'vhrEventsManualStepUpdateSummary':
                        // nothing to store 
                        break;

                    default:
                        throw 'An error has occurred';
                }
            };

            wzInstance.getResultDataFn = function () {
                return new wzInstance.WzResult_VhrEventManualRequest(wzInstance.wzConfig, wzInstance.translator, wzInstance.services, wzInstance.nbsGuidManager);
            };

            wzInstance.initCollectionDataFn = function () {
                wzInstance.services.vhreventService.vhrEventManualCollector = new wzInstance.WzCollector_VhrEventManualWizard(wzInstance.wzConfig);

                // others
                wzInstance.services.vhreventService.vhrEventManualCollector.IsVhrEventsSelected = true;
                wzInstance.services.vhreventService.vhrEventManualCollector.VhrEventTransactionCode = wzConfig.defaultData.VhrEventTransactionCode ? wzConfig.defaultData.VhrEventTransactionCode : null;
            };
        }

        function configAddVhrEventManual(wzInstance) {
            wzInstance.collectDataFn = function (modalResult, currentStep) {
                if (!wzInstance.services.vhreventService.vhrEventManualCollector) {
                    wzInstance.services.vhreventService.vhrEventManualCollector = {};
                }

                switch (modalResult.sourceParams.modalKey) {
                    case 'vhrEventsManualStepSwitchFromActivity':
                        wzInstance.services.vhreventService.vhrEventManualCollector.IsEventManualByActivity = modalResult.data.IsEventManualByActivity;
                        wzInstance.services.vhreventService.vhrEventManualCollector.VhrEventManualRequestTypeId = modalResult.data.VhrEventManualRequestTypeId;
                        break;

                    case 'vhrEventsManualStepActivitySelection':
                        wzInstance.services.vhreventService.vhrEventManualCollector.ActivityId = modalResult.data.ActivityId;
                        wzInstance.services.vhreventService.vhrEventManualCollector.Code = modalResult.data.Code;
                        wzInstance.services.vhreventService.vhrEventManualCollector.Name = modalResult.data.Name;
                        wzInstance.services.vhreventService.vhrEventManualCollector.Description = modalResult.data.Description;
                        wzInstance.services.vhreventService.vhrEventManualCollector.ExternalCode = modalResult.data.ExternalCode;
                        wzInstance.services.vhreventService.vhrEventManualCollector.ModuleTargetId = modalResult.data.ModuleTargetId;
                        wzInstance.services.vhreventService.vhrEventManualCollector.VhrEventGroupId = modalResult.data.VhrEventGroupId;
                        wzInstance.services.vhreventService.vhrEventManualCollector.VhrEventTypeId = modalResult.data.VhrEventTypeId;
                        wzInstance.services.vhreventService.vhrEventManualCollector.VhrEventTemplateId = modalResult.data.VhrEventTemplateId;
                        break;

                    case 'vhrEventsManualStepEventDetail':
                        wzInstance.services.vhreventService.vhrEventManualCollector.Code = modalResult.data.Code;
                        wzInstance.services.vhreventService.vhrEventManualCollector.Name = modalResult.data.Name;
                        wzInstance.services.vhreventService.vhrEventManualCollector.Description = modalResult.data.Description;
                        wzInstance.services.vhreventService.vhrEventManualCollector.ExternalCode = modalResult.data.ExternalCode;
                        wzInstance.services.vhreventService.vhrEventManualCollector.ModuleTargetId = modalResult.data.ModuleTargetId;
                        wzInstance.services.vhreventService.vhrEventManualCollector.ModuleTargetName = modalResult.data.ModuleTargetName;
                        wzInstance.services.vhreventService.vhrEventManualCollector.WorkCenterId = modalResult.data.WorkCenterId;
                        wzInstance.services.vhreventService.vhrEventManualCollector.WorkCenterName = modalResult.data.WorkCenterName;
                        wzInstance.services.vhreventService.vhrEventManualCollector.VhrEventGroupId = modalResult.data.VhrEventGroupId;
                        wzInstance.services.vhreventService.vhrEventManualCollector.VhrEventTypeId = modalResult.data.VhrEventTypeId;
                        wzInstance.services.vhreventService.vhrEventManualCollector.VhrEventTemplateId = modalResult.data.VhrEventTemplateId;
                        wzInstance.services.vhreventService.vhrEventManualCollector.VhrEventTemplateName = modalResult.data.VhrEventTemplateName;
                        wzInstance.services.vhreventService.vhrEventManualCollector.RecurrenceStartDate = modalResult.data.RecurrenceStartDate;
                        wzInstance.services.vhreventService.vhrEventManualCollector.EventStartTime = modalResult.data.EventStartTime;
                        wzInstance.services.vhreventService.vhrEventManualCollector.RecurrenceEndDate = modalResult.data.RecurrenceEndDate;
                        wzInstance.services.vhreventService.vhrEventManualCollector.EventEndTime = modalResult.data.EventEndTime;
                        wzInstance.services.vhreventService.vhrEventManualCollector.IsRecurrent = modalResult.data.IsRecurrent;
                        break;

                    case 'cronExpressionDetail':
                        wzInstance.services.vhreventService.vhrEventManualCollector.CronExpression = modalResult.data.getCronExpression();
                        wzInstance.services.vhreventService.vhrEventManualCollector.CronExpressionLabel = modalResult.data.getCronLabel();

                        currentStep.params.cronstring = wzInstance.services.vhreventService.vhrEventManualCollector.CronExpression;
                        break;

                    case 'vhrEventsManualStepVhr':
                        wzInstance.services.vhreventService.vhrEventManualCollector.VirtualHumanResources = modalResult.data.VirtualHumanResources;
                        break;

                    case 'vhrEventsManualStepSummary':
                        // nothing to store 
                        break;

                    default:
                        throw 'An error has occurred';
                }
            };

            wzInstance.getResultDataFn = function () {
                return new wzInstance.WzResult_VhrEventManualRequest(wzInstance.wzConfig, wzInstance.translator, wzInstance.services, wzInstance.nbsGuidManager);
            };

            wzInstance.initCollectionDataFn = function () {
                wzInstance.services.vhreventService.vhrEventManualCollector = new wzInstance.WzCollector_VhrEventManualWizard(wzInstance.wzConfig);

                // others
                wzInstance.services.vhreventService.vhrEventManualCollector.IsEventManualByActivity = true;
                wzInstance.services.vhreventService.vhrEventManualCollector.VhrEntityType = wzInstance.wzConfig.vhrEntityType;
            };
        }

        function configAddDependentActivity(wzInstance) {
            wzInstance.collectDataFn = function (modalResult, currentStep) {
                // data were collected runtime into activityService.wizardDependActivityCollector object
            };
            wzInstance.getResultDataFn = function () {
                return wzInstance.services.activityService.wizardDependActivityCollector;
            };
            wzInstance.initCollectionDataFn = function () {
                wzInstance.services.activityService.setWizardDependActivityCollector();
            };
        }

        function configAddScheduledTask(wzInstance) {
            wzInstance.initCollectionDataFn = () => {
                wzInstance.services.utilService.scheduledTaskCollector = new wzInstance.WzCollector_ScheduledTaskWizard(wzInstance.wzConfig);
            };
            wzInstance.getResultDataFn = () => {
                return new wzInstance.WzResult_ScheduledTask(wzInstance.wzConfig, wzInstance.services);
            };
            wzInstance.collectDataFn = (modalResult, currentStep) => {
                if (!wzInstance.services.utilService.scheduledTaskCollector) {
                    wzInstance.services.utilService.scheduledTaskCollector = {};
                }

                switch (modalResult.sourceParams.modalKey) {
                    case 'scheduledTaskStepDetail':
                        wzInstance.services.utilService.scheduledTaskCollector.Code = modalResult.data.Code;
                        wzInstance.services.utilService.scheduledTaskCollector.Name = modalResult.data.Name;
                        wzInstance.services.utilService.scheduledTaskCollector.Time = modalResult.data.Time;
                        break;

                    case 'cronExpressionDetail':
                        wzInstance.services.utilService.scheduledTaskCollector.CronExpression = modalResult.data.getCronExpression();
                        wzInstance.services.utilService.scheduledTaskCollector.CronExpressionLabel = modalResult.data.getCronLabel();

                        currentStep.params.cronstring = wzInstance.services.utilService.scheduledTaskCollector.CronExpression;
                        break;

                    case 'scheduledTaskStepSummary':
                        // nothing to store 
                        break;

                    default:
                        throw 'An error has occurred';
                }
            };
        }
    }

    // public (start wizard and returns data at the end)
    open() {
        var me = this;

        if (me.isFirstStep) {
            // The wizard start now
            me.initCollectionDataFn();
            me.initStepsParentParams();
            me.isFirstStep = false;
        }

        //if (stepName) {
        //    me.currentStep = me.getStepByName(stepName);
        //}

        // add beardcrumnb information
        var beardCrumbInfo = me.getBeardCrumbInformation(me.currentStep);
        me.currentStep.params.beardCrumbInfo = JSON.stringify(beardCrumbInfo);

        // add information that permit in view to manage some UI (in cronexpression at the moment)
        me.currentStep.params.isWizardMode = true;

        // used in navigation service to create route to call and to pass parentParams to modal
        var navParams = {
            params: me.currentStep.params,
            routeName: me.wzConfig.parentState + me.currentStep.name   // Do not remove (used in modalToWz)
        };

        me.navigationService
            .modalToWz(navParams)
            .then(function (modalResult) {
                //enable dirty flag
                //vm.suspendDirty(false);

                if (modalResult.dialogResult === me.navigationService.navigationActionTypes.modalNext) {
                    me.collectDataFn(modalResult, me.currentStep);
                    Lazy(modalResult.stepsToSkip).each((stepToSkip) => {
                        me.addStepToSkip(stepToSkip, me.currentStep.name);
                    });

                    me.setNextStep();
                    me.open();

                } else if (modalResult.dialogResult === me.navigationService.navigationActionTypes.modalPrevious) {
                    me.setPreviousStep();
                    me.open();

                } else if (modalResult.dialogResult === me.navigationService.navigationActionTypes.modalCancel) {
                    // exit wizard

                } else if (modalResult.dialogResult === me.navigationService.navigationActionTypes.modalEnd) {
                    // resolve the promise returning VhrEventManualRequest object
                    me.defer.resolve(me.getResultDataFn());
                }
            });

        return me.defer.promise;
    };
}

export class NbsGrid {
    Column: any;

    translator: any;
    SORTINGDIRECTION: any;
    GRIDMODE: any;
    DATASOURCETYPE: any;
    SELECTIONBEHAVIOR: any;

    nbs_selectionBehavior: any;
    nbs_mode: any;
    nbs_showCommonBar: any;
    nbs_showCustomBar: any;
    nbs_useListView: any;
    nbs_adaptToPage: any;
    nbs_fieldId: any;
    nbs_defaultSortingField: any;
    nbs_defaultSortingDirection: any;
    nbs_dataSourceType: any;
    nbs_renderIntoUibTab: any;
    nbs_renderInlineBlock: any;
    nbs_isMultiselect: any;
    nbs_queryCellInfo_mapping_formatter: any;
    nbs_isReadonly: any;
    nbs_childGrid: any;
    locale: any;
    allowPaging: any;
    pageSettings: any;
    allowSorting: any;
    allowSelection: any;
    allowScrolling: any;
    scrollSettings: any;
    allowFiltering: any;
    allowSearching: any;
    allowReordering: any;
    allowGrouping: any;
    allowRowDragAndDrop: any;
    isResponsive: any;
    allowResizing: any;
    allowResizeToFit: any;
    allowTextWrap: any;
    enableAltRow: any;
    selectionType: any;
    editSettings: any;
    dataSource: any;
    columns: any;
    selectedIds: any;
    cardColumns: any;
    hasCardColumns: any;
    cardSettings: any;
    openJsRenderTag: any;
    closeJsRenderTag: any;

    templateObject: any;

    nbs_queryCellInfo_mapping_custom_method_formatter: any;

    constructor(translator) {
        this.Column = class {
            type: any;
            field: any;
            width: any;
            header: any;
            templateId: any;
            templateContent: any;
            allowResizing: any;
            allowEditing: any;
            constructor(translator, type, field, header, template, width, allowEditing) {
                this.type = type;
                this.field = field;
                this.width = width;
                this.header = translator ? translator(header) : header;
                this.templateId = null;
                this.templateContent = null;
                this.allowResizing = true;
                this.allowEditing = allowEditing === true ? true : false;

                if (this.type == 'template') {
                    this.templateId = template;
                } else if (this.type == 'templateContent') {
                    this.templateContent = template;
                }
            }
        }

        this.translator = translator;
        this.SORTINGDIRECTION = { Ascending: 'Ascending', Descending: 'Descending' };
        this.GRIDMODE = { Grid: 'grid', Board: 'board', Stream: 'stream', Modal: 'modal' }
        this.DATASOURCETYPE = { Local: 'local', Datamanager: 'datamanager' }
        this.SELECTIONBEHAVIOR = {
            // when search, change page, change sorting selection will be reset
            Default: 'default',
            // when search, change page, change sorting selection will NOT be reset
            KeepSelection: 'keepselection'
        }

        this.nbs_selectionBehavior = this.SELECTIONBEHAVIOR.Default;
        this.nbs_mode = this.GRIDMODE.Grid;
        this.nbs_showCommonBar = false;
        this.nbs_showCustomBar = false;
        this.nbs_useListView = NbsCommonUI.useListView();
        this.nbs_adaptToPage = false;
        this.nbs_fieldId = null;
        this.nbs_defaultSortingField = null;
        this.nbs_defaultSortingDirection = this.SORTINGDIRECTION.Ascending;
        this.nbs_dataSourceType = this.DATASOURCETYPE.Local;
        this.nbs_renderIntoUibTab = false;
        this.nbs_renderInlineBlock = false;
        this.nbs_isMultiselect = false;
        this.nbs_queryCellInfo_mapping_formatter = {};
        this.nbs_isReadonly = false;
        this.nbs_childGrid = null;
        this.locale = 'it-IT';
        this.allowPaging = true;
        this.pageSettings = { pageSize: 20 };
        this.allowSorting = true;
        this.allowSelection = true;
        this.allowScrolling = false;
        this.scrollSettings = {}
        this.allowFiltering = false;
        this.allowSearching = false;
        this.allowReordering = true;
        this.allowGrouping = false;
        this.allowRowDragAndDrop = false;
        this.isResponsive = true;
        this.allowResizing = true;
        this.allowResizeToFit = true;
        this.allowTextWrap = false;
        this.enableAltRow = false;
        this.selectionType = 'single';
        this.editSettings = { allowEditing: false, editMode: 'normal' };
        this.dataSource = [];
        this.columns = [];
        this.selectedIds = [];
        this.cardColumns = [];
        this.hasCardColumns = false;
        this.cardSettings = {
            isEnabled: false,
            gridTemplateContent: null,
            boardTemplateContent: null,
            streamTemplateContent: null
        }
        this.openJsRenderTag = '<script type="text/x-jsrender">';
        this.closeJsRenderTag = '</script>';
        var tempTemplateObject = {
            Assignee: `{{for UserGroupsUI}}
                                    <div class="user-group-15" style="margin-top:5px;"><div style="padding-left:20px;">{{:Name}}</div></div>
                                {{/for}}
                                {{for UsersUI}}
                                    <div class="user-15" style="margin-top:5px;"><div style="padding-left:20px;">{{:Name}}</div></div>
                                {{/for}}`,
            DataCenters: `{{for DataCenterUI}}<div style="padding-top:5px;">{{:Code}} - {{:Name}}</div>{{/for}}`,
            VhrEventExecutor: `{{if ExecutorId > 0}}
                                            {{if ExecutorTypeId == 1}}
                                                <div class="user-15" style="margin-top:5px;"><div style="padding-left:20px;">{{:ExecutorName}}</div></div>
                                            {{/if}}
                                            {{if ExecutorTypeId == 2}}
                                                <div class="user-group-15" style="margin-top:5px;"><div style="padding-left:20px;">{{:ExecutorName}}</div></div>
                                            {{/if}}
                                        {{/if}}`,
            VhrEventApproval: `{{if ApprovalId}}
                                            {{if ApprovalTypeId == 1}}
                                                <div class="user-15" style="margin-top:5px;"><div style="padding-left:20px;">{{:ApprovalName}}</div></div>
                                            {{/if}}
                                            {{if ApprovalTypeId == 2}}
                                                <div class="user-group-15" style="margin-top:5px;"><div style="padding-left:20px;">{{:ApprovalName}}</div></div>
                                            {{/if}}
                                        {{/if}}`,
            VhrEventLabels: `{{for VhrEventLabelsUI}}<div style="padding-top:5px;">{{:Name}}</div>{{/for}}`,
            Categories: `{{for CategoriesUI}}<div style="padding-top:5px;">{{:Name}}</div>{{/for}}`,
            ViewNotification: `{{if NotificationChannelKindName == 'ChannelWebApp'}}
                                            {{if NotificationStatusOriginalName == 'NotificationNew'}}
                                                <span class="fal fa-envelope" style="cursor:pointer;" id="notificationId_{{:NotificationId}}" notificationId="{{:NotificationId}}" onclick="javascript:Nembus.Common.Library.ui.callControllerFromTemplate(this, 'onTemplateElementClick')"></span>
                                            {{/if}}
                                            {{if NotificationStatusOriginalName == 'NotificationArchived'}}
                                                <span class="fal fa-envelope-open" style="cursor:pointer;" id="notificationId_{{:NotificationId}}" notificationId="{{:NotificationId}}" onclick="javascript:Nembus.Common.Library.ui.callControllerFromTemplate(this, 'onTemplateElementClick')"></span>
                                            {{/if}}
                                        {{/if}}
                                        {{if NotificationChannelKindName == 'ChannelEmail'}}
                                            {{if NotificationFileName != null}}
                                                <span class="fal fa-download" style="cursor:pointer;" title="Download" id="notificationId_{{:NotificationId}}" notificationId="{{:NotificationId}}" onclick="javascript:Nembus.Common.Library.ui.callControllerFromTemplate(this, 'onTemplateElementClick')"></span>
                                            {{/if}}
                                        {{/if}}`,
            LegalEntities: `{{for GenericLegalEntityUI}}<div style="padding-top:5px;">{{:Code}} - {{:Name}}</div>{{/for}}`,
            CostCenters: `{{for CostCenterUI}}<div style="padding-top:5px;">{{:Code}} - {{:Name}}</div>{{/for}}`,
            BusinessUnits: `{{for BusinessUnitUI}}<div style="padding-top:5px;">{{:Code}} - {{:Name}}</div>{{/for}}`,
            Sectors: `{{for SectorUI}}<div style="padding-top:5px;">{{:Code}} - {{:Name}}</div>{{/for}}`,
            Plants: `{{for PlantUI}}<div style="padding-top:5px;">{{:Code}} - {{:Name}}</div>{{/for}}`,
            ReadonlyRadioGridSelection: `{{if IsSelected}}
                                                        <img alt="" src="/images/selection/radio-selected.png" style="height:23px;" class="nbs-checkbox-radio-readonly" />
                                                    {{else}}
                                                        <img alt="" src="/images/selection/radio-unselected.png" style="height:23px;" class="nbs-checkbox-radio-readonly" />
                                                    {{/if}}`,
            ReadonlyCheckboxGridSelection: `{{if IsSelected}}
                                                        <img alt="" src="/images/selection/checkbox-selected.png" style="height:23px;" class="nbs-checkbox-radio-readonly" />
                                                    {{else}}
                                                        <img alt="" src="/images/selection/checkbox-unselected.png" style="height:23px;" class="nbs-checkbox-radio-readonly" />
                                                    {{/if}}`,

            // START templates for cards
            AssetTree_GridTemplate: `<div class="container-fluid nbs-grid-card">
                                        <div class="row nbs-card-row">
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div class="row">
                                                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell">Id</div>
                                                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 nbs-card-cell" title="{{:VirtualHumanResourceId}}">{{if AssetTreeEntityType == 'HrBucket'}}<strong>{{:VirtualHumanResourceId}}</strong>{{else}}{{:VirtualHumanResourceId}}{{/if}}</div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell">Codice</div>
                                                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 nbs-card-cell" title="{{:VirtualCode}}">{{if AssetTreeEntityType == 'HrBucket'}}<strong>{{:VirtualCode}}</strong>{{else}}{{:VirtualCode}}{{/if}}</div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell">Nome</div>
                                                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 nbs-card-cell" title="{{:VirtualName}}">{{if AssetTreeEntityType == 'HrBucket'}}<strong>{{:VirtualName}}</strong>{{else}}{{:VirtualName}}{{/if}}</div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell">Matricola</div>
                                                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 nbs-card-cell" title="{{:VirtualEquipmentRegistrationNumber}}">{{if AssetTreeEntityType == 'HrBucket'}}<strong>{{:VirtualEquipmentRegistrationNumber}}</strong>{{else}}{{:VirtualEquipmentRegistrationNumber}}{{/if}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>`,
            AssetTree_BoardTemplate: 'use-grid-template',
            AssetTree_StreamTemplate: 'use-grid-template',

            AppUser_GridTemplate: `<div class="container-fluid nbs-grid-card">
                                    <div class="row nbs-card-row">
                                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <div class="row">
                                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell">{{:XCentralUserId}}</div>
                                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell">{{:LastName}}</div>
                                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell">{{:FirstName}}</div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell nbs-card-title">Abilitato</div>
                                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell nbs-card-title">Utente programmatico</div>
                                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell nbs-card-title"></div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell">{{:cardSupport.translations.enabled}}</div>
                                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell">{{:cardSupport.translations.isProgrammaticAccess}}</div>
                                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>`,
            AppUser_BoardTemplate: 'use-grid-template',
            AppUser_StreamTemplate: 'use-grid-template',

            Notification_GridTemplate: `<div class="nbs-listview-audit-container">
                                            <div class="row nbs-listview-row">
                                                <div class="col-xs-3 col-sm-2 col-md-2 nbs-listview-col ellipsis">
                                                    <span class="listview-item-title">{{:NotificationId}}</span>
                                                </div>
                                                <div class="col-xs-7 col-sm-8 col-md-8 nbs-listview-col">
                                                    <div class="row nbs-listview-row">
                                                        <div class="col-xs-12 nbs-listview-col ellipsis">
                                                            <span class="listview-item-title">{{:NotificationSubject}}</span>
                                                        </div>
                                                    </div>
                                                    <div class="row nbs-listview-row">
                                                        <div class="col-xs-12 nbs-listview-col ellipsis">
                                                            <span class="listview-item-text-color">{{:AddressTo}}</span>
                                                        </div>
                                                    </div>
                                                    <div class="row nbs-listview-row">
                                                        <div class="col-xs-12 nbs-listview-col">
                                                            <span class="listview-item-footer-title">Data creazione</span><br />
                                                            <span class="listview-item-footer-text">{{:cardSupport.CreationDate}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xs-2 col-sm-2 col-md-2 nbs-listview-col">
                                                    <!-- CHANNEL WEB -->
                                                    {{if NotificationChannelKindName == 'ChannelWebApp'}}
                                                    {{if NotificationStatusOriginalName == 'NotificationNew'}}
                                                    <span class="fal fa-envelope" style="cursor:pointer;font-size:20px" id="notificationId_{{:NotificationId}}" notificationId="{{:NotificationId}}" onclick="javascript:Nembus.Common.Library.ui.callControllerFromTemplate(this, 'onTemplateElementClick')"></span>
                                                    {{/if}}
                                                    {{if NotificationStatusOriginalName == 'NotificationArchived'}}
                                                    <span class="fal fa-envelope-open" style="cursor:pointer;font-size:20px" id="notificationId_{{:NotificationId}}" notificationId="{{:NotificationId}}" onclick="javascript:Nembus.Common.Library.ui.callControllerFromTemplate(this, 'onTemplateElementClick')"></span>
                                                    {{/if}}
                                                    {{/if}}

                                                    <!-- CHANNEL EMAIL -->
                                                    {{if NotificationChannelKindName == 'ChannelEmail'}}
                                                    {{if NotificationFileName != null}}
                                                    <span class="fal fa-download" style="cursor:pointer;font-size:20px" title="Download" id="notificationId_{{:NotificationId}}" notificationId="{{:NotificationId}}" onclick="javascript:Nembus.Common.Library.ui.callControllerFromTemplate(this, 'onTemplateElementClick')"></span>
                                                    {{/if}}
                                                    {{/if}}
                                                </div>
                                            </div>
                                        </div>`,
            Notification_BoardTemplate: 'use-grid-template',
            Notification_StreamTemplate: 'use-grid-template',

            Target_GridTemplate: `<div class="container-fluid nbs-grid-card">
                                    <div class="row nbs-card-row">
                                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <div class="row">
                                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell">Id</div>
                                                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 nbs-card-cell" title="{{:VirtualHumanResourceId}}">{{:VirtualHumanResourceId}}</div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell">Codice</div>
                                                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 nbs-card-cell" title="{{:TargetCode}}">{{:TargetCode}}</div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell">Nome</div>
                                                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 nbs-card-cell" title="{{:TargetName}}">{{:TargetName}}</div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell">Gruppo di target</div>
                                                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 nbs-card-cell" title="{{:TargetGroupName}}">{{:TargetGroupName}}</div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell">Tipo di target</div>
                                                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 nbs-card-cell" title="{{:TargetTypeName}}">{{:TargetTypeName}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>`,
            Target_BoardTemplate: 'use-grid-template',
            Target_StreamTemplate: 'use-grid-template',

            BatchRequest_GridTemplate: `<div class="container-fluid nbs-grid-card">
                                            <div class="row nbs-card-row">
                                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                    {{if IsCurrentUserRequest || CurrentUserIsUA}}

                                                    <div class="row">
                                                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell-2 nbs-card-title">Ordine esecuzione</div>
                                                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell-2 nbs-card-title">Data creazione</div>
                                                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell-2 nbs-card-title">Utente</div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell-2">{{:BatchRequestOrderUI}}</div>
                                                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell-2">{{:CreationDateUI}}&nbsp;</div>
                                                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell-2"><span>{{:VirtualHumanResourceName}}</span>&nbsp;</div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell-2 nbs-card-title">Nome richiesta</div>
                                                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell-2 nbs-card-title">Descrizione richiesta</div>
                                                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell-2 nbs-card-title">Id richiesta</div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell-2">{{:BatchRequestName}}&nbsp;</div>
                                                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell-2"><span>{{:BatchRequestExtra}}</span>&nbsp;</div>
                                                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell-2">{{:BatchRequestId}}</div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell-2 nbs-card-title">Stato (% esecuzione)</div>
                                                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell-2 nbs-card-title">Data di processamento</div>
                                                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell-2 nbs-card-title">Contesto virtuale</div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell-2">
                                                            {{if BatchRequestStatusId == '2'}}  <!-- Inserted -->
                                                            <div style="color: red;">{{:BatchRequestStatusName}}</div>
                                                            {{/if}}
                                                            {{if BatchRequestStatusId == '3'}}  <!-- InProgress -->
                                                            <div style="color: orange;">{{:BatchRequestStatusName}} {{if CurrentOperationPercentageComplete}}({{:CurrentOperationPercentageComplete}} %){{/if}}({{:CurrentOperationExecutionIndex}}/{{:TotalOperationCount}})</div>
                                                            {{/if}}
                                                            {{if BatchRequestStatusId == '4'}}  <!-- Solved -->
                                                            <div style="color: green;">{{:BatchRequestStatusName}} {{if CurrentOperationPercentageComplete}}({{:CurrentOperationPercentageComplete}} %){{/if}}({{:CurrentOperationExecutionIndex}}/{{:TotalOperationCount}})</div>
                                                            {{/if}}
                                                            {{if BatchRequestStatusId == '6'}}  <!-- Paused -->
                                                            <div style="color: gray;">{{:BatchRequestStatusName}} {{if CurrentOperationPercentageComplete}}({{:CurrentOperationPercentageComplete}} %){{/if}}({{:CurrentOperationExecutionIndex}}/{{:TotalOperationCount}})</div>
                                                            {{/if}}
                                                        </div>
                                                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell-2">{{:ProcessStartDateUI}}&nbsp;{{:ProcessedDateUI}}&nbsp;</div>
                                                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell-2"><span>{{:VirtualCompanyName}}</span>&nbsp;</div>
                                                    </div>
                                                    {{else}}
                                                    <div class="row">
                                                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 nbs-card-cell-2 nbs-card-title">Ordine esecuzione</div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 nbs-card-cell-2">{{:BatchRequestOrderUI}}</div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell-2 nbs-card-title">Stato (% esecuzione)</div>
                                                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell-2 nbs-card-title">Data creazione</div>
                                                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell-2 nbs-card-title">Data processamento</div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell-2">
                                                            {{if BatchRequestStatusId == '2'}}  <!-- Inserted -->
                                                            <div style="color: red;">{{:BatchRequestStatusName}}</div>
                                                            {{/if}}
                                                            {{if BatchRequestStatusId == '3'}}  <!-- InProgress -->
                                                            <div style="color: orange;">{{:BatchRequestStatusName}} {{if CurrentOperationPercentageComplete}}({{:CurrentOperationPercentageComplete}} %){{/if}}</div>
                                                            {{/if}}
                                                            {{if BatchRequestStatusId == '4'}}  <!-- Solved -->
                                                            <div style="color: green;">{{:BatchRequestStatusName}} {{if CurrentOperationPercentageComplete}}({{:CurrentOperationPercentageComplete}} %){{/if}}</div>
                                                            {{/if}}
                                                            {{if BatchRequestStatusId == '6'}}  <!-- Paused -->
                                                            <div style="color: gray;">{{:BatchRequestStatusName}} {{if CurrentOperationPercentageComplete}}({{:CurrentOperationPercentageComplete}} %){{/if}}({{:CurrentOperationExecutionIndex}}/{{:TotalOperationCount}})</div>
                                                            {{/if}}

                                                        </div>
                                                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell-2">{{:CreationDateUI}}&nbsp;</div>
                                                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell-2">{{:ProcessStartDateUI}}&nbsp;{{:ProcessedDateUI}}&nbsp;</div>
                                                    </div>
                                                    {{/if}}
                                                    {{if HasError}}
                                                    <div class="row">
                                                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell-2 nbs-card-title">Errore</div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell-2">{{:ErrorDescription}}</div>
                                                    </div>
                                                    {{/if}}
                                                </div>
                                            </div>
                                        </div>`,
            BatchRequest_BoardTemplate: 'use-grid-template',
            BatchRequest_StreamTemplate: 'use-grid-template',

            Act_GridTemplate: `<div class="container-fluid nbs-grid-card">
                                    <div class="row nbs-card-row">
                                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <div class="row">
                                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell-2" title="{{:ActId}}">{{:ActId}}</div>
                                                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 nbs-card-cell-2" title="{{:ActDescription}}">{{:ActDescription}}&nbsp;</div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell-2"></div>
                                                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 nbs-card-cell-2 nbs-card-text-color" title="{{:ActCategoryName}}">{{:ActCategoryName}}&nbsp;</div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell-2 nbs-card-title">{{:cardSupport.translations.expire}}</div>
                                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell-2 nbs-card-title">{{:cardSupport.translations.priority}}</div>
                                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell-2 nbs-card-title">{{:cardSupport.translations.status)}}</div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell-2" title="{{:cardSupport.ActExpireDate}}">{{:cardSupport.ActExpireDate}}&nbsp;</div>
                                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell-2">
                                                    {{if ActPriorityId == '1'}} <!-- Low -->
                                                    <span class="nbs-card-marked nbs-card-priority-low">{{:ActPriorityName}}</span>
                                                    {{else}}
                                                    {{if ActPriorityId == '3'}} <!-- High -->
                                                    <span class="nbs-card-marked nbs-card-priority-high">{{:ActPriorityName}}</span>
                                                    {{else}}
                                                    <span class="nbs-card-marked">{{:ActPriorityName}}</span>
                                                    {{/if}}
                                                    {{/if}}
                                                </div>
                                                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell-2">
                                                    {{if ActWorkflowStatusKindId == '1'}} <!-- Low -->
                                                    <div class="nbs-card-cell-workflow-todo">{{:ActWorkflowStatusKindName}}</div>
                                                    {{/if}}
                                                    {{if ActWorkflowStatusKindId == '2'}} <!-- High -->
                                                    <div class="nbs-card-cell-workflow-done">{{:ActWorkflowStatusKindName}}</div>
                                                    {{/if}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>`,
            Act_BoardTemplate: 'use-grid-template',
            Act_StreamTemplate: 'use-grid-template',

            VhrEvent_GridTemplate: `<div class="container-fluid nbs-grid-card">
                                        <div class="row nbs-card-row">
                                            <div class="nbs-card-cell-color" style="background-color: {{:VhrEventLifeStatusColor}};">
                                            </div>
                                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div class="row">
                                                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell" title="{{:cardSupport.VhrEventStartDate}}">{{:cardSupport.VhrEventStartDate}} {{:cardSupport.VhrEventStartTime}}</div>
                                                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 nbs-card-cell" title="{{:VhrEventName}}">{{:VhrEventName}}</div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 nbs-card-cell">Id: {{:VhrEventId}}</div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell" title="{{:ActivityCode}}">{{:ActivityCode}}</div>
                                                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 nbs-card-cell nbs-card-text-color" title="{{:VirtualHumanResourceDisplayName}}">{{:VirtualHumanResourceDisplayName}}</div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell" title="{{:ActivityFrequencyCode}}">{{:ActivityFrequencyCode}}</div>
                                                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 nbs-card-cell" title="{{:WorkCenterName}}">{{:WorkCenterName}}</div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell">{{:cardSupport.translations.approved}}</div>
                                                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell">{{:cardSupport.translations.executed}}</div>
                                                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 nbs-card-cell">{{:cardSupport.translations.customerApproved}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>`,
            VhrEvent_BoardTemplate: 'use-grid-template',
            VhrEvent_StreamTemplate: 'use-grid-template'
        };

        // updated properties to lowercase
        this.parseTemplateObject(tempTemplateObject);
    }

    parseTemplateObject(tempTemplateObject) {
        this.templateObject = this.templateObject || {};

        for (var i = 0; i < Object.keys(tempTemplateObject).length; i++) {
            var p = Object.keys(tempTemplateObject)[i];
            this.templateObject[p.toLowerCase()] = tempTemplateObject[p]
        };
    }

    viewCommonBar() {
        this.nbs_showCommonBar = true;
        this.nbs_showCustomBar = false;
        this.allowSearching = true;
        this.allowGrouping = true;
    }

    viewCustomBar() {
        this.nbs_showCommonBar = false;
        this.nbs_showCustomBar = true;
        this.allowSearching = true;
        this.allowGrouping = true;
    }

    setScrollSettings(height, width) {
        this.scrollSettings.height = height;
        this.scrollSettings.width = width;
    }

    setChildGrid(childGridParams) {
        if (!this.nbs_childGrid) {
            this.nbs_childGrid = {
                dataSource: [],
                queryString: null,
                columns: [],
                allowSorting: false
            }
        }
        this.nbs_childGrid.dataSource = childGridParams.dataSource ? childGridParams.dataSource : [];
        this.nbs_childGrid.queryString = childGridParams.queryString ? childGridParams.queryString : null;
        this.nbs_childGrid.columns = childGridParams.columns ? childGridParams.columns : [];
        this.nbs_childGrid.allowSorting = childGridParams.allowSorting === true ? true : false;
    }

    setChildGridDatasource(dataSourceFn) {
        if (this.nbs_childGrid) {
            this.nbs_childGrid.dataSource = dataSourceFn;
        }
    }

    setAdaptToPage() {
        this.nbs_adaptToPage = true;
        this.allowResizeToFit = false;
    }

    setIsMultiselect() {
        this.nbs_isMultiselect = true;
    }

    setIsReadonly() {
        this.nbs_isReadonly = true;
    }

    setGridIsRenderedInlineBlock() {
        this.nbs_renderInlineBlock = true;
    }

    setGridIsUnderTab() {
        this.nbs_renderIntoUibTab = true;
    }

    setSelectedIds(ids) {
        if (Array.isArray(ids)) {
            this.selectedIds = ids;
        } else {
            this.selectedIds = [];
        }
    }

    setFieldId(fieldId) {
        this.nbs_fieldId = fieldId;
    }

    setPageSize(pageSize) {
        this.pageSettings.pageSize = pageSize;
    }

    setModalMode() {
        this.nbs_mode = this.GRIDMODE.Modal;
        this.nbs_selectionBehavior = this.SELECTIONBEHAVIOR.KeepSelection;
        this.setPageSize(10);
        this.setAdaptToPage();
        this.allowGrouping = false;
    }

    setStreamMode() {
        this.nbs_mode = this.GRIDMODE.Stream;
        this.allowSearching = true;
        this.nbs_childGrid = null;
    }

    setBoardMode() {
        this.nbs_mode = this.GRIDMODE.Board;
        this.allowSearching = true;
        this.nbs_childGrid = null;
        this.allowRowDragAndDrop = true;
        this.selectionType = 'multiple';
    }

    setDatasource(dataSource, isDatamanager) {
        this.dataSource = dataSource;
        if (isDatamanager) {
            this.nbs_dataSourceType = this.DATASOURCETYPE.Datamanager;
        } else {
            this.nbs_dataSourceType = this.DATASOURCETYPE.Local;
        }
    }

    enableCardTemplateContents(key) {
        var gridTemplateContent = this.getTemplateContent(key + '_GridTemplate', false) || '';
        var boardTemplateContent = this.getTemplateContent(key + '_BoardTemplate', false) || '';
        var streamTemplateContent = this.getTemplateContent(key + '_StreamTemplate', false) || '';

        if (boardTemplateContent === 'use-grid-template') {
            boardTemplateContent = gridTemplateContent;
        }

        if (streamTemplateContent === 'use-grid-template') {
            streamTemplateContent = gridTemplateContent;
        } else if (streamTemplateContent === 'use-board-template') {
            streamTemplateContent = boardTemplateContent;
        }

        this.cardSettings.isEnabled = true;
        this.cardSettings.gridTemplateContent = this.openJsRenderTag + gridTemplateContent + this.closeJsRenderTag;
        this.cardSettings.boardTemplateContent = this.openJsRenderTag + boardTemplateContent + this.closeJsRenderTag;
        this.cardSettings.streamTemplateContent = this.openJsRenderTag + streamTemplateContent + this.closeJsRenderTag;
    }

    setSortingAsc(fieldName) {
        this.nbs_defaultSortingField = fieldName
        this.nbs_defaultSortingDirection = this.SORTINGDIRECTION.Ascending;
    }
    setSortingDesc(fieldName) {
        this.nbs_defaultSortingField = fieldName
        this.nbs_defaultSortingDirection = this.SORTINGDIRECTION.Descending;
    }

    addColumnString(field, header, templateId?, width?, allowEditing?) {
        this.addColumn('string', field, header, templateId, width, allowEditing);
    }

    addColumnId(field, header?, templateId?, width?, allowEditing?) {
        if (!header) { header = 'Id'; }
        if (!width) { width = 120; }
        this.addColumn('id', field, header, templateId, width, allowEditing);
    }

    addColumnDate(field, header, templateId?, width?, allowEditing?) {
        this.addColumn('date', field, header, templateId, width, allowEditing);
    }

    addColumnNumber(field, header, templateId?, width?, allowEditing?) {
        this.addColumn('number', field, header, templateId, width, allowEditing);
    }

    addColumnTemplate(field, header, templateId?, width?, allowEditing?) {
        this.addColumn('template', field, header, templateId, width, allowEditing);
    }

    addColumnTemplateContent(field, header, templateContent, width?, allowEditing?) {
        this.addColumn('templateContent', field, header, templateContent, width, allowEditing);
    }

    addColumnDisplayAsCheckbox(field, header, templateId?, width?, allowEditing?) {
        this.addColumn('displayAsCheckbox', field, header, templateId, width, allowEditing);
    }

    addColumn(type, field, header, template, width, allowEditing) {
        this.columns.push(new this.Column(this.translator, type, field, header, template, width, allowEditing));
    }

    configureGrid(entity: string, mode?: string, appStoreCode?: string) {
        switch (entity) {
            case 'activity': this.configActivity(mode); break;
            case 'triggerinstancematch': this.configTriggerInstanceMatch(mode); break;
            case 'assettree': this.configAssetTree(mode); break;
            case 'dependentactivity': this.configDependentActivity(mode); break;
            case 'contextuserdto': this.configContextUserDto(mode); break;
            case 'appuserperson': this.configAppUserPerson(mode); break;
            case 'appuserdto': this.configAppUserDto(mode); break;
            case 'activitypricegroup': this.configActivityPriceGroup(mode); break;
            case 'vhreventlabel': this.configVhrEventLabel(mode); break;
            case 'virtualcompany': this.configVirtualCompany(mode); break;
            case 'vhrevent': this.configVhrEvent(mode, appStoreCode); break;
            case 'act': this.configAct(mode); break;
            case 'drbacprocess': this.configDrbacProcess(mode); break;
            case 'hrbucket': this.configHrBucket(mode); break;
            case 'genericentity': this.configGenericEntity(mode); break;
            case 'drbacpermissiongroup': this.configDrbacPermissionGroup(mode); break;
            case 'drbacpermission': this.configDrbacPermission(mode); break;
            case 'drbacrole': this.configDrbacRole(mode); break;
            case 'drbacschema': this.configDrbacSchema(mode); break;
            case 'drbacusergroup': this.configDrbacUserGroup(mode); break;
            case 'drbacusergroupM2mappuser': this.configDrbacUserGroupM2MAppUser(mode); break;
            case 'drbacprocessconfig': this.configDrbacProcessConfig(mode); break;
            case 'pairvalues': this.configPairValue(mode); break;
            case 'hrsingleXhrbucket': this.configHrSingleXHrBucket(mode); break;
            case 'personjobjournal': this.configPersonJobJournal(mode); break;
            case 'drbacprocessglobal': this.configDrbacProcessGlobal(mode); break;
            case 'userprofile': this.configUserProfile(mode); break;
            case 'category': this.configCategory(mode); break;
            case 'appstore': this.configAppStore(mode); break;
            case 'frequency': this.configFrequencyCode(mode); break;
            case 'masterplan': this.configMasterPlan(mode); break;
            case 'scheduling': this.configScheduling(mode); break;
            case 'schedulinggroup': this.configSchedulingGroup(mode); break;
            case 'actactiongroup': this.configActActionGroup(mode); break;
            case 'viewpageelementdto': this.configViewPageElementDto(mode); break;
            case 'actactiontype': this.configActActionType(mode); break;
            case 'route': this.configRoute(mode); break;
            case 'operation': this.configOperation(mode); break;
            case 'smallactivity': this.configSmallActivity(mode); break;
            case 'schedulingrequest': this.configSchedulingRequest(mode); break;
            case 'schedulingwarning': this.configSchedulingWarning(mode); break;
            case 'serversystemvariable': this.configServerSystemVariable(mode); break;
            case 'filebox': this.configFileBox(mode); break;
            case 'hrhierarchy': this.configHrHierarchy(mode); break;
            case 'bidatadefinitiongroup': this.configBiDataDefinitionGroup(mode); break;
            case 'wday': this.configWDay(mode); break;
            case 'wdayslottemplate': this.configWDaySlotTemplate(mode); break;
            case 'bidatadefinition': this.configBiDataDefinition(mode); break;
            case 'avatar': this.configAvatar(mode); break;
            case 'company': this.configCompany(mode); break;
            case 'cmsstaticcontent': this.configCmsStaticContent(mode); break;
            case 'workflow': this.configWorkflow(mode); break;
            case 'workflowstatus': this.configWorkflowStatus(mode); break;
            case 'authaudit': this.configAuthAudit(mode); break;
            case 'actchangehistory': this.configActChangeHistory(mode); break;
            case 'integrationfileobserver': this.configIntegrationFileObserver(mode); break;
            case 'integrationfileobserverjournal': this.configIntegrationFileObserverJournal(mode); break;
            case 'address': this.configAddress(mode); break;
            case 'fileshufflerequest': this.configFileShuffleRequest(mode); break;
            case 'batchrequest': this.configBatchRequest(mode); break;
            case 'fileshufflejournal': this.configFileShuffleJournal(mode); break;
            case 'fileshuffledefinition': this.configFileShuffleDefinition(mode); break;
            case 'integrationdefinition': this.configIntegrationDefinition(mode); break;
            case 'hrbucketgenerality': this.configHrBucketGenerality(mode); break;
            case 'notificationdefinition': this.configNotificationDefinition(mode); break;
            case 'notificationinstance': this.configNotificationInstance(mode); break;
            case 'notificationschema': this.configNotificationSchema(mode); break;
            case 'triggerinstance': this.configTriggerInstance(mode); break;
            case 'triggerfired': this.configTriggerFired(mode); break;
            case 'validationschemainstance': this.configValidationSchemaInstance(mode); break;
            case 'integrationrequest': this.configIntegrationRequest(mode); break;
            case 'vhreventtype': this.configVhrEventType(mode); break;
            case 'vhreventgroup': this.configVhrEventGroup(mode); break;
            case 'bomroutemapping': this.configBomRouteMapping(mode); break;
            case 'virtualworkcenterescape': this.configVirtualWorkCenterEscape(mode); break;
            case 'weektemplateescape': this.configWWeekTemplateEscape(mode); break;
            case 'holiday': this.configHoliday(mode); break;
            case 'bankholiday': this.configBankHoliday(mode); break;
            case 'calendartemplate': this.configWCalendarTemplate(mode); break;
            case 'calendar': this.configWCalendar(mode); break;
            case 'workcentergroup': this.configWorkCenterGroup(mode); break;
            case 'workcentergenerality': this.configWorkCenterGenerality(mode); break;
            case 'workcenter': this.configWorkCenter(mode); break;
            case 'fdticket': this.configFdTicket(mode); break;
            case 'execprocess': this.configExecProcess(mode); break;
            case 'contact': this.configContact(mode); break;
            case 'scheduledtask': this.configScheduledTask(mode); break;
            case 'scheduledtaskhistory': this.configScheduledTaskHistory(mode); break;
            case 'activityprice': this.configActivityPrice(mode); break;
            case 'metricinstance': this.configMetricInstance(mode); break;
            case 'metricspecialization': this.configMetricSpecializationDto(mode); break;
            case 'metrickpidefinition': this.configMetricKpiDefinition(mode); break;
            case 'vhreventhistory': this.configVhrEventHistory(mode); break;
            case 'vhrgroup': this.configVhrGroup(mode); break;
            case 'vhrtype': this.configVhrType(mode); break;
            case 'target': this.configTarget(mode); break;
            case 'quartzjob': this.configQuartzJob(mode); break;
            case 'vhreventmanualrequest': this.configVhrEventManualRequest(mode); break;
            case 'notification': this.configNotification(mode); break;
            case 'reportdefinition': this.configReportDefinition(mode); break;
            case 'vhreventappointment': this.configVhrEventAppointment(mode); break;
            case 'virtualhumanresource': this.configVirtualHumanResource(mode); break;
            case 'vhreventmanualwarning': this.configVhrEventManualWarning(mode); break;
            case 'equipmentgenerality': this.configEquipmentGenerality(mode); break;
            case 'fsdto': this.configFsDto(mode); break;
        }
    }

    configEquipmentGenerality(mode) {
        this.setFieldId('VirtualHumanResourceId');
        this.setSortingAsc('EquipmentGenerality.SerialNumber');
        this.addColumnId('VirtualHumanResourceId');
        this.addColumnString('EquipmentGenerality.SerialNumber', 'EquipmentGenerality.SerialNumber');
        this.addColumnString('EquipmentGenerality.EquipmentName', 'EquipmentGenerality.EquipmentName');
        this.addColumnString('EquipmentGenerality.Producer', 'EquipmentGenerality.Producer');
        this.addColumnString('EquipmentGenerality.Model', 'EquipmentGenerality.Model');
        this.addColumnString('EquipmentGenerality.RegistrationNumber', 'EquipmentGenerality.RegistrationNumber');
    }

    configFsDto(mode) {
        this.setFieldId('FsDtoId');
        this.setSortingAsc('FsName');
        this.addColumnString('FsExtension', '', null, 80);
        this.addColumnString('FsName', 'FsFolder.FsFolderName');
        this.addColumnString('Category.CategoryName', 'Category.CategoryName');
    }
    configVhrEventManualWarning(mode) {
        this.setFieldId('VhrEventManualWarningId');
        this.setSortingAsc('ObjectName');
        this.addColumnId('VirtualHumanResourceId');
        this.addColumnString('ObjectName', 'VhrEventManualRequest.ObjectName');
        this.addColumnString('FieldName', 'VhrEventManualRequest.FieldName');
    }
    configVirtualHumanResource(mode) {
        this.setFieldId('VirtualHumanResourceId');
        this.setSortingAsc('VirtualName');
        this.addColumnId('VirtualHumanResourceId');
        this.addColumnString('VirtualName', 'Name');
    }
    configVhrEventAppointment(mode) {
        this.nbs_queryCellInfo_mapping_formatter = {
            StartTime: 'formatTime',
            EndTime: 'formatTime',
        }

        this.setFieldId('Id');
        this.addColumnId('Id');
        this.addColumnString('Subject', 'Subject');
        this.addColumnString('StartTime', 'TimeStart');
        this.addColumnString('EndTime', 'TimeEnd');
    }
    configReportDefinition(mode) {
        switch (mode) {
            case 'report':
                this.setFieldId('ReportDefinitionId');
                this.setSortingAsc('ReportDefinitionReferenceCode');
                this.addColumnString('ReportDefinitionReferenceCode', 'ReportDefinition.ReportDefinitionReferenceCode');
                this.addColumnString('ReportDefinitionName', 'ReportDefinition.ReportDefinitionName');
                this.addColumnString('ReportDefinitionDescription', 'ReportDefinition.ReportDefinitionDescription');
                this.addColumnString('ReportHeaderTypeName', 'ReportDefinition.ReportHeaderType');
                this.addColumnString('ReportEngineTypeName', 'ReportDefinition.ReportEngineType');
                this.addColumnDisplayAsCheckbox('IsSystem', 'ReportDefinition.IsSystem');
                break;
            case 'reportEntity':
                this.setFieldId('ReportDefinitionId');
                this.setSortingAsc('ReportDefinitionReferenceCode');
                this.addColumnString('ReportDefinitionReferenceCode', 'ReportDefinition.ReportDefinitionReferenceCode');
                this.addColumnString('ReportDefinitionName', 'ReportDefinition.ReportDefinitionName');
                this.addColumnString('ReportHeaderTypeName', 'ReportDefinition.ReportHeaderType');
                this.addColumnString('ReportEngineTypeName', 'ReportDefinition.ReportEngineType');
                this.addColumnDisplayAsCheckbox('IsSystem', 'ReportDefinition.IsSystem');
                this.addColumnDisplayAsCheckbox('UseSystemTemplate', 'ReportDefinition.UseSystemTemplate');
                this.addColumnString('ReportDefinitionStatusName', 'ReportDefinition.ReportDefinitionStatus');
                break;
            case 'view':
                this.setFieldId('ReportDefinitionId');
                this.setSortingAsc('ReportDefinitionReferenceCode');
                this.addColumnString('ReportDefinitionReferenceCode', 'ReportDefinition.ReportDefinitionReferenceCode');
                this.addColumnString('ReportDefinitionName', 'ReportDefinition.ReportDefinitionName');
                this.addColumnString('ReportDefinitionDescription', 'ReportDefinition.ReportDefinitionDescription');
                this.addColumnString('ReportHeaderTypeName', 'ReportDefinition.ReportHeaderType');
                break;
        }
    }
    configNotification(mode) {
        this.enableCardTemplateContents('Notification');

        this.nbs_queryCellInfo_mapping_formatter = {
            CreationDate: 'formatDateTime',
        }

        this.setFieldId('NotificationId');
        this.setSortingDesc('NotificationId');
        this.addColumnId('NotificationId');
        this.addColumnString('NotificationSubject', 'Notification.NotificationSubject');
        this.addColumnString('AddressTo', 'Notification.AddressTo');
        this.addColumnString('NotificationStatusName', 'Notification.NotificationStatus');
        this.addColumnDate('CreationDate', 'Notification.CreationDate');
        this.addColumnString('NotificationSourceCode', 'Notification.NotificationSourceCode');
        this.addColumnString('AppUserToId', 'Notification.AppUserToId');

        var colTranslationCode = '';
        if (mode === 'notifications') {
            colTranslationCode = 'Download';
        }
        else if (mode === 'mynotifications') {
            colTranslationCode = 'Preview';
        }

        this.addColumnTemplateContent('Result', colTranslationCode, this.getTemplateContent('ViewNotification'));
    }
    configVhrEventManualRequest(mode) {
        this.nbs_queryCellInfo_mapping_formatter = {
            CreationDate: 'formatDateTimeIgnoreTimeZone',
        }

        this.setFieldId('VhrEventManualRequestId');
        this.setSortingDesc('CreationDate');
        this.addColumnId('VhrEventManualRequestId');
        this.addColumnString('VhrEventManualRequestCode', 'VhrEventManualRequest.VhrEventManualRequestCode');
        this.addColumnString('VhrEventManualRequestExternalCode', 'VhrEventManualRequest.VhrEventManualRequestExternalCode');
        this.addColumnString('VhrEventManualRequestName', 'VhrEventManualRequest.VhrEventManualRequestName');
        this.addColumnString('VhrEventManualRequestStatusName', 'VhrEventManualRequest.VhrEventManualRequestStatus');
        this.addColumnDate('CreationDate', 'VhrEventManualRequest.CreationDate');
    }
    configQuartzJob(mode) {
        this.setFieldId('QuartzJobId');
        this.setSortingAsc('QuartzJobClassName');
        this.addColumnId('QuartzJobId');
        this.addColumnString('QuartzJobClassName', 'QuartzJob.QuartzJobClassName');
        this.addColumnString('QuartzJobStatus', 'QuartzJob.QuartzJobStatus');
        this.addColumnString('LastStartDate', 'QuartzJob.LastStartDate');
        this.addColumnString('LastReadyDate', 'QuartzJob.LastReadyDate');
        this.addColumnString('LastBusyDate', 'QuartzJob.LastBusyDate');
        this.addColumnString('LastSuspendedDate', 'QuartzJob.LastSuspendedDate');
    }
    configTarget(mode) {
        this.enableCardTemplateContents('Target');

        this.setFieldId('VirtualHumanResourceId');
        this.setSortingAsc('TargetCode');
        this.addColumnId('VirtualHumanResourceId');
        this.addColumnString('TargetCode', 'TargetDto.TargetCode');
        this.addColumnString('TargetName', 'TargetDto.TargetName');
        this.addColumnString('TargetDescription', 'TargetDto.TargetDescription');
        this.addColumnString('TargetGroupName', 'TargetDto.TargetGroupId');
        this.addColumnString('TargetTypeName', 'TargetDto.TargetTypeId');
    }
    configVhrGroup(mode) {
        this.setFieldId('VhrGroupId');
        this.setSortingAsc('VhrGroupCode');
        this.addColumnId('VhrGroupId');
        this.addColumnString('VhrGroupCode', 'VhrGroup.VhrGroupCode');
        this.addColumnString('VhrGroupName', 'VhrGroup.VhrGroupName');
        this.addColumnDisplayAsCheckbox('IsSystem', 'VhrGroup.IsSystem');
    }
    configVhrType(mode) {
        this.setFieldId('VhrTypeId');
        this.setSortingAsc('VhrTypeCode');
        this.addColumnId('VhrTypeId');
        this.addColumnString('VhrTypeCode', 'VhrType.VhrTypeCode');
        this.addColumnString('VhrTypeName', 'VhrType.VhrTypeName');
        this.addColumnDisplayAsCheckbox('IsSystem', 'VhrType.IsSystem');
    }
    configVhrEventHistory(mode) {
        this.nbs_queryCellInfo_mapping_formatter = {
            CreationDate: 'formatDateTime',
            PercentageApproved: 'formatDecimal',
            PercentageCustomerApproved: 'formatDecimal',
            PercentageExecuted: 'formatDecimal'
        }

        switch (mode) {
            case 'assignment':
                this.setFieldId('VhrEventEntityHistoryId');
                this.setSortingDesc('CreationDate');
                this.addColumnString('CreatorUserName', 'User');
                this.addColumnTemplate('AppUsersSerialized', 'Users', 'appUsersTemplateId');
                this.addColumnTemplate('DrbacUserGroupsSerialized', 'UserGroups', 'userGroupsTemplateId');
                this.addColumnDate('CreationDate', 'VhrEventEntityHistory.CreationDate');
                break;
            case 'approval':
                this.setFieldId('VhrEventEntityHistoryId');
                this.setSortingDesc('CreationDate');
                this.addColumnTemplate('CreatorUserName', 'VhrEventEntityHistory.ApprovalId', 'vhrEventUserTypeApprovalTemplateId');
                this.addColumnDisplayAsCheckbox('IsApproved', 'VhrEventEntityHistory.IsApproved');
                this.addColumnNumber('PercentageApproved', 'VhrEventEntityHistory.PercentageApproved');
                this.addColumnString('ApprovalNote', 'VhrEventEntityHistory.ApprovalNote');
                this.addColumnDate('CreationDate', 'VhrEventEntityHistory.CreationDate');
                break;
            case 'customerapproval':
                this.setFieldId('VhrEventEntityHistoryId');
                this.setSortingDesc('CreationDate');
                this.addColumnTemplate('CreatorUserName', 'VhrEventEntityHistory.CustomerApprovalId', 'vhrEventUserTypeCustomerApprovalTemplateId');
                this.addColumnDisplayAsCheckbox('IsCustomerApproval', 'VhrEventEntityHistory.IsCustomerApproval');
                this.addColumnNumber('PercentageCustomerApproved', 'VhrEventEntityHistory.PercentageCustomerApproved');
                this.addColumnString('CustomerApprovalNote', 'VhrEventEntityHistory.CustomerApprovalNote');
                this.addColumnDate('CreationDate', 'VhrEventEntityHistory.CreationDate');
                break;
            case 'execution':
                this.setFieldId('VhrEventEntityHistoryId');
                this.setSortingDesc('CreationDate');
                this.addColumnTemplate('CreatorUserName', 'VhrEventEntityHistory.ExecutorId', 'vhrEventUserTypeExecutorTemplateId');
                this.addColumnDisplayAsCheckbox('IsExecuted', 'VhrEventEntityHistory.IsExecuted');
                this.addColumnNumber('PercentageExecuted', 'VhrEventEntityHistory.PercentageExecuted');
                this.addColumnString('ExecutionNote', 'VhrEventEntityHistory.ExecutionNote');
                this.addColumnDate('CreationDate', 'VhrEventEntityHistory.CreationDate');
                break;
            case 'vhreventlifestatus':
                this.setFieldId('VhrEventEntityHistoryId');
                this.setSortingDesc('CreationDate');
                this.addColumnString('VhrEventLifeStatusName', 'VhrEventEntityHistory.VhrEventLifeStatusId');
                this.addColumnString('VhrEventLifeStatusChangeNote', 'VhrEventEntityHistory.VhrEventLifeStatusChangeNote');
                this.addColumnDate('CreationDate', 'VhrEventEntityHistory.CreationDate');
                break;
            case 'workflowstatus':
                this.setFieldId('VhrEventEntityHistoryId');
                this.setSortingDesc('CreationDate');
                this.addColumnString('WorkflowStatusCode', 'VhrEventEntityHistory.WorkflowStatusCode');
                this.addColumnString('WorkflowStatusName', 'VhrEventEntityHistory.WorkflowStatusName');
                this.addColumnString('WorkflowStatusKindName', 'VhrEventEntityHistory.WorkflowStatusKindId')
                this.addColumnString('VhrEventWorkflowStatusChangeNote', 'VhrEventEntityHistory.VhrEventWorkflowStatusChangeNote')
                this.addColumnDate('CreationDate', 'VhrEventEntityHistory.CreationDate');
                break;
        }
    }
    configMetricInstance(mode) {
        this.setFieldId('MetricInstanceId');
        this.setSortingAsc('MetricInstanceCode');
        this.addColumnId('MetricInstanceId');
        this.addColumnString('MetricInstanceCode', 'MetricInstance.MetricInstanceCode');
        this.addColumnString('MetricInstanceName', 'MetricInstance.MetricInstanceName');
        this.addColumnString('MetricDataFrequencyName', 'MetricInstance.MetricDataFrequencyId');
        this.addColumnString('RunMetricCronExpressionLabel', 'MetricInstance.RunMetricCronExpression');
        this.addColumnDate('RunMetricNextRunTime', 'MetricInstance.RunMetricNextRunTime');
        this.addColumnDate('RunMetricLastRunTime', 'MetricInstance.RunMetricLastRunTime');
        this.addColumnDisplayAsCheckbox('RunMetricCronExpression', 'MetricInstance.RunMetricCronExpression');
        this.addColumnDisplayAsCheckbox('IsEnabled', 'MetricInstance.IsEnabled');
    }
    configMetricSpecializationDto(mode) {
        this.setFieldId('MetricSpecializationId');
        this.setSortingAsc('MetricSpecializationCode');
        this.addColumnId('MetricSpecializationId');
        this.addColumnString('MetricSpecializationCode', 'MetricSpecializationDto.MetricSpecializationCode');
        this.addColumnString('MetricSpecializationName', 'MetricSpecializationDto.MetricSpecializationName');
        this.addColumnString('MetricKpiDefinitionCode', 'MetricSpecializationDto.MetricKpiDefinitionCode');
        this.addColumnString('MetricKpiDefinitionName', 'MetricSpecializationDto.MetricKpiDefinitionName');
        this.addColumnDisplayAsCheckbox('IsSystem', 'MetricSpecializationDto.IsSystem');
        this.addColumnDisplayAsCheckbox('IsCustomizable', 'MetricSpecializationDto.IsCustomizable');
    }
    configMetricKpiDefinition(mode) {
        this.setFieldId('MetricKpiDefinitionId');
        this.setSortingAsc('MetricKpiDefinitionCode');
        this.addColumnId('MetricKpiDefinitionId');
        this.addColumnString('MetricKpiDefinitionCode', 'MetricKpiDefinition.MetricKpiDefinitionCode');
        this.addColumnString('MetricKpiDefinitionName', 'MetricKpiDefinition.MetricKpiDefinitionName');
        this.addColumnString('MetricValueUnitName', 'MetricKpiDefinition.MetricValueUnit');
    }
    configActivityPrice(mode) {
        this.nbs_queryCellInfo_mapping_formatter = {
            PurchasePrice: 'formatDecimal',
            PurchaseDiscountAmount: 'formatDecimal',
            PurchaseDiscountPercentage: 'formatDecimal',
            SalePrice: 'formatDecimal',
            SaleDiscountAmount: 'formatDecimal',
            SaleDiscountPercentage: 'formatDecimal',
            CreationDate: 'formatDateTimeIgnoreTimeZone',
            LastChangeDate: 'formatDateTimeIgnoreTimeZone'
        }

        switch (mode) {
            case 'detail':
                this.setFieldId('ActivityPriceId');
                this.setSortingAsc('ActivityCode');
                this.addColumnString('ActivityCode', 'ActivityPrice.ActivityCode');
                this.addColumnString('ActivityPriceName', 'ActivityPrice.ActivityPriceName');
                this.addColumnString('PurchasePrice', 'ActivityPrice.PurchasePrice');
                this.addColumnString('PurchaseDiscountAmount', 'ActivityPrice.PurchaseDiscountAmount');
                this.addColumnString('PurchaseDiscountPercentage', 'ActivityPrice.PurchaseDiscountPercentage');
                this.addColumnString('PurchaseTaxBand.TaxBandName', 'ActivityPrice.PurchaseTaxBand');
                this.addColumnString('SalePrice', 'ActivityPrice.SalePrice');
                this.addColumnString('SaleDiscountAmount', 'ActivityPrice.SaleDiscountAmount');
                this.addColumnString('SaleDiscountPercentage', 'ActivityPrice.SaleDiscountPercentage');
                this.addColumnString('PriceUnitOfMeasureName', 'ActivityPrice.PriceUnitOfMeasure');
                this.addColumnString('SaleTaxBand.TaxBandName', 'ActivityPrice.SaleTaxBand');
                this.addColumnDate('CreationDate', 'CreationDate');
                this.addColumnDate('LastChangeDate', 'LastChangeDate');
                break;
            case 'contract':
                this.setFieldId('ActivityPriceId');
                this.setSortingAsc('ActivityCode');
                this.addColumnString('ActivityCode', 'Activity.ActivityCode');
                this.addColumnString('ActivityFrequencyCode', 'Activity.ActivityFrequencyCode');
                this.addColumnString('ActivityName', 'Activity.ActivityName');
                this.addColumnString('VhrEventTypeName', 'Activity.VhrEventType');
                this.addColumnString('VhrEventGroupName', 'Activity.VhrEventGroup');
                this.addColumnString('SalePrice', 'ActivityPrice.SalePrice');
                break;
        }
    }
    configScheduledTask(mode) {
        this.setFieldId('ScheduledTaskId');
        this.setSortingAsc('ScheduledTaskCode');
        this.addColumnId('ScheduledTaskId');
        this.addColumnString('ScheduledTaskCode', 'ScheduledTask.ScheduledTaskCode');
        this.addColumnString('ScheduledTaskName', 'ScheduledTask.ScheduledTaskName');
        this.addColumnString('CronExpressionLabel', 'ScheduledTask.CronExpression');
        this.addColumnString('ScheduledTaskStatusId', 'ScheduledTask.ScheduledTaskStatus');
        this.addColumnDate('LastRunTime', 'ScheduledTask.LastRunTime');
        this.addColumnDate('NextRunTime', 'ScheduledTask.NextRunTime');
    }
    configScheduledTaskHistory(mode) {
        this.setFieldId('ScheduledTaskHistoryId');
        this.setSortingDesc('ScheduledTaskHistoryId');
        this.addColumnId('ScheduledTaskHistoryId');
        this.addColumnString('ScheduledTaskHistoryCommandName', 'ScheduledTaskHistory.ScheduledTaskHistoryCommand');
        this.addColumnString('ScheduledTaskHistoryTypeName', 'ScheduledTaskHistory.ScheduledTaskHistoryType');
        this.addColumnDate('CreationDate', 'ScheduledTaskHistory.CreationDate');
    }
    configContact(mode) {
        this.setFieldId('ContactId');
        this.setSortingAsc('ContactKindName');
        //this.addColumnId('ContactId');
        this.addColumnString('ContactKindName', 'Contact.ContactKind');
        this.addColumnString('ContactName', 'Contact.ContactName');
        this.addColumnString('ContactNick', 'Contact.ContactNick');
        this.addColumnString('ContactValue', 'Contact.ContactValue');
    }
    configExecProcess(mode) {
        this.setFieldId('ExecProcessId');
        this.setSortingAsc('ExecProcessCode');
        this.addColumnId('ExecProcessId');
        this.addColumnString('ExecProcessCode', 'ExecProcess.ExecProcessCode');
        this.addColumnString('ExecProcessName', 'ExecProcess.ExecProcessName');
        this.addColumnString('ExecProcessDescription', 'ExecProcess.ExecProcessDescription');
        this.addColumnString('ExecPath', 'ExecProcess.ExecPath');
        this.addColumnDisplayAsCheckbox('IsEnabled', 'ExecProcess.IsEnabled');
    }
    configFdTicket(mode) {
        this.nbs_queryCellInfo_mapping_formatter = {
            created_at: 'formatDateTime',
            updated_at: 'formatDateTime'
        }

        this.setFieldId('id');
        //this.setSortingAsc('subject');
        this.addColumnId('id');
        this.addColumnString('subject', 'FdTicket.subject');
        this.addColumnString('statusName', 'FdTicket.status');
        this.addColumnString('created_at', 'FdTicket.created_at');
        this.addColumnString('updated_at', 'FdTicket.updated_at');
    }
    configWorkCenterGenerality(mode) {
        this.nbs_queryCellInfo_mapping_formatter = {
            GroupCapacityQuota: 'formatDecimal'
        }

        this.setFieldId('VirtualWorkCenterId');
        this.setSortingAsc('WorkCenterGenerality.WorkCenterCode');
        this.addColumnId('VirtualWorkCenterId');
        this.addColumnString('WorkCenterGenerality.WorkCenterCode', 'WorkCenterGenerality.WorkCenterCode');
        this.addColumnString('WorkCenterGenerality.WorkCenterName', 'WorkCenterGenerality.WorkCenterName');
    }
    configWorkCenter(mode) {
        this.nbs_queryCellInfo_mapping_formatter = {
            GroupCapacityQuota: 'formatDecimal'
        }

        this.setFieldId('VirtualWorkCenterId');
        this.setSortingAsc('WorkCenterCode');
        this.addColumnId('VirtualWorkCenterId');
        this.addColumnString('WorkCenterCode', 'WorkCenterGenerality.WorkCenterCode');
        this.addColumnString('WorkCenterName', 'WorkCenterGenerality.WorkCenterName');
        this.addColumnString('WorkCenterCapacityQuota', 'WorkCenterGenerality.WorkCenterCapacityQuota');
        this.addColumnString('WorkCenterCapacityUnitName', 'WorkCenterGenerality.WorkCenterCapacityUnit');
        this.addColumnString('WorkCenterCapacityEfficiency', 'WorkCenterGenerality.WorkCenterCapacityEfficiency');
        this.addColumnDisplayAsCheckbox('IsLimitedCapacity', 'WorkCenterGenerality.IsLimitedCapacity');
        this.addColumnString('WCalendarName', 'WCalendar.WCalendar');
        this.addColumnDisplayAsCheckbox('IsWorkCenterEscape', 'WorkCenterGenerality.IsWorkCenterEscape');
    }
    configWorkCenterGroup(mode) {
        this.nbs_queryCellInfo_mapping_formatter = {
            GroupCapacityQuota: 'formatDecimal'
        }

        switch (mode) {
            case 'list':
                this.setFieldId('WorkCenterGroupId');
                this.setSortingAsc('WorkCenterGroupCode');
                this.addColumnId('WorkCenterGroupId');
                this.addColumnString('WorkCenterGroupCode', 'WorkCenterGroup.WorkCenterGroupCode');
                this.addColumnString('WorkCenterGroupName', 'WorkCenterGroup.WorkCenterGroupName');
                this.addColumnString('GroupCapacityQuota', 'WorkCenterGroup.GroupCapacityQuota');
                this.addColumnString('GroupCapacityUnitName', 'WorkCenterGroup.GroupCapacityUnit');
                this.addColumnString('GroupCapacityEfficiency', 'WorkCenterGroup.GroupCapacityEfficiency');
                this.addColumnDisplayAsCheckbox('IsLimitedCapacity', 'WorkCenterGroup.IsLimitedCapacity');
                this.addColumnDisplayAsCheckbox('IsDefault', 'WorkCenterGroup.IsDefault');
                break;
            default:
                break;
        }
    }
    configWCalendar(mode) {
        this.nbs_queryCellInfo_mapping_formatter = {
            StartDate: 'formatDateIgnoreTimeZone',
            SetUntil: 'formatDateIgnoreTimeZone'
        }

        this.setFieldId('WCalendarId');
        this.setSortingAsc('WCalendarCode');
        //this.addColumnId('WCalendarId');
        this.addColumnString('WCalendarCode', 'WCalendarTemplate.WCalendarCode');
        this.addColumnString('WCalendarName', 'WCalendarTemplate.WCalendarName');
        this.addColumnString('WCalendarKindName', 'WCalendarTemplate.WCalendarKind');
        this.addColumnDate('StartDate', 'WCalendarTemplate.StartDate');
        this.addColumnDate('SetUntil', 'WCalendarTemplate.SetUntil');
    }
    configWCalendarTemplate(mode) {
        this.nbs_queryCellInfo_mapping_formatter = {
            StartDate: 'formatDateIgnoreTimeZone',
            SetUntil: 'formatDateIgnoreTimeZone'
        }

        this.setFieldId('WCalendarId');
        this.setSortingAsc('WCalendarCode');
        //this.addColumnId('WCalendarId');
        this.addColumnString('WCalendarCode', 'WCalendarTemplate.WCalendarCode');
        this.addColumnString('WCalendarName', 'WCalendarTemplate.WCalendarName');
        this.addColumnString('WCalendarKindName', 'WCalendarTemplate.WCalendarKind');
        this.addColumnDate('StartDate', 'WCalendarTemplate.StartDate');
        this.addColumnDate('SetUntil', 'WCalendarTemplate.SetUntil');
    }
    configBankHoliday(mode) {
        this.setFieldId('BankHolidayId');
        this.setSortingAsc('BankHolidayName');
        this.addColumnId('BankHolidayId');
        this.addColumnString('BankHolidayName', 'BankHoliday.BankHolidayName');
        this.addColumnString('BankHolidayDescription', 'BankHoliday.BankHolidayDescription');
    }
    configHoliday(mode) {
        this.nbs_queryCellInfo_mapping_formatter = {
            HolidayDate: 'formatDateIgnoreTimeZone'
        }

        //formatDateIgnoreTimeZone
        this.setFieldId('HolidayId');
        this.setSortingAsc('HolidayName');
        //this.addColumnId('HolidayId');
        this.addColumnString('HolidayName', 'Holiday.HolidayName');
        this.addColumnDate('HolidayDate', 'Holiday.HolidayDate');
        this.addColumnDisplayAsCheckbox('IsFixedDay', 'Holiday.IsFixedDay');
    }
    configWWeekTemplateEscape(mode) {
        this.setFieldId('WWeekTemplateId');
        this.setSortingAsc('WWeekCode');
        this.addColumnId('WWeekTemplateId');
        this.addColumnString('WWeekCode', 'WWeekTemplate.WWeekCode');
        this.addColumnString('WWeekName', 'WWeekTemplate.WWeekName');
    }
    configVirtualWorkCenterEscape(mode) {
        this.setFieldId('VirtualWorkCenterEscapeId');
        this.setSortingAsc('VirtualWorkCenterEscapeCode');
        this.addColumnId('VirtualWorkCenterEscapeId');
        this.addColumnString('VirtualWorkCenterEscapeCode', 'VirtualWorkCenterEscape.VirtualWorkCenterEscapeCode');
        this.addColumnString('VirtualWorkCenterEscapeName', 'VirtualWorkCenterEscape.VirtualWorkCenterEscapeName');
        this.addColumnString('VhrEventGroupName', 'VirtualWorkCenterEscape.VhrEventGroup');
        this.addColumnString('VhrEventTypeName', 'VirtualWorkCenterEscape.VhrEventType');
        this.addColumnString('ModuleTargetName', 'VirtualWorkCenterEscape.ModuleTarget');
    }
    configBomRouteMapping(mode) {
        this.setFieldId('BomRouteMappingId');
        this.setSortingAsc('BomRouteMappingCode');
        this.addColumnId('BomRouteMappingId');
        this.addColumnString('BomRouteMappingCode', 'BomRouteMapping.BomRouteMappingCode');
        this.addColumnString('BomRouteMappingName', 'BomRouteMapping.BomRouteMappingName');
        this.addColumnString('VhrEventGroupName', 'BomRouteMapping.VhrEventGroup');
        this.addColumnString('VhrEventTypeName', 'BomRouteMapping.VhrEventType');
        this.addColumnString('ModuleTargetName', 'BomRouteMapping.ModuleTarget');
    }
    configVhrEventType(mode) {
        this.setFieldId('VhrEventTypeId');
        this.setSortingDesc('VhrEventTypeCode');
        this.addColumnId('VhrEventTypeId');
        this.addColumnString('VhrEventTypeCode', 'VhrEventType.VhrEventTypeCode');
        this.addColumnString('VhrEventTypeName', 'VhrEventType.VhrEventTypeName');
        this.addColumnString('ModuleTargetName', 'VhrEventType.ModuleTarget');
    }
    configVhrEventGroup(mode) {
        this.setFieldId('VhrEventGroupId');
        this.setSortingDesc('VhrEventGroupCode');
        this.addColumnId('VhrEventGroupId');
        this.addColumnString('VhrEventGroupCode', 'VhrEventGroup.VhrEventGroupCode');
        this.addColumnString('VhrEventGroupName', 'VhrEventGroup.VhrEventGroupName');
        this.addColumnString('ModuleTargetName', 'VhrEventGroup.ModuleTarget');
    }
    configIntegrationRequest(mode) {
        this.nbs_queryCellInfo_mapping_formatter = {
            CreationDate: 'formatDateTimeLocalToUtc',
            StartDate: 'formatDateTimeLocalToUtc',
            ProcessedDate: 'formatDateTimeLocalToUtc'
        }

        this.setFieldId('IntegrationRequestId');
        this.setSortingDesc('CreationDate');
        //this.addColumnId('IntegrationRequestId');
        this.addColumnString('IntegrationRequestName', 'IntegrationRequest.IntegrationRequestName');
        this.addColumnString('IntegrationRequestStatusName', 'IntegrationRequest.IntegrationRequestStatus');
        this.addColumnString('IntegrationRequestResultName', 'IntegrationRequest.IntegrationRequestResult');
        this.addColumnString('CurrentItem', 'IntegrationRequest.CurrentItem');
        this.addColumnString('TotalItems', 'IntegrationRequest.TotalItems');
        this.addColumnString('ItemsAdded', 'IntegrationRequest.ItemsAdded');
        this.addColumnString('ItemsUpdated', 'IntegrationRequest.ItemsUpdated');
        this.addColumnString('ItemsIgnored', 'IntegrationRequest.ItemsIgnored');
        this.addColumnDate('CreationDate', 'CreationDate');
        this.addColumnDate('StartDate', 'IntegrationRequest.StartDate');
        this.addColumnDate('ProcessedDate', 'IntegrationRequest.ProcessedDate');
    }
    configValidationSchemaInstance(mode) {
        this.setFieldId('ValidationSchemaInstanceId');
        this.setSortingAsc('ValidationSchemaInstanceCode');
        this.addColumnId('ValidationSchemaInstanceId');
        this.addColumnString('ValidationSchemaInstanceCode', 'ValidationSchemaInstance.ValidationSchemaInstanceCode');
        this.addColumnString('ValidationSchemaInstanceName', 'ValidationSchemaInstance.ValidationSchemaInstanceName');
        this.addColumnString('ValidationSchemaDefinition.ValidationSchemaDefinitionName', 'ValidationSchemaInstance.ValidationSchemaDefinitionId');
        this.addColumnString('ValidationSchemaDefinition.ValidationSchemaDefinitionGroup.ValidationSchemaDefinitionGroupName', 'ValidationSchemaDefinition.ValidationSchemaDefinitionGroupId');
        this.addColumnDisplayAsCheckbox('IsSystem', 'ValidationSchemaInstance.IsSystem');
        this.addColumnDisplayAsCheckbox('IsPublish', 'ValidationSchemaInstance.IsPublish');
        this.addColumnDate('PublishDateUI', 'ValidationSchemaInstance.PublishDate');
        this.addColumnDisplayAsCheckbox('IsArchived', 'ValidationSchemaInstance.IsArchived');
    }
    configTriggerInstance(mode) {
        this.setFieldId('TriggerInstanceId');
        this.setSortingAsc('TriggerInstanceCode');
        this.addColumnId('TriggerInstanceId');
        this.addColumnString('TriggerInstanceCode', 'TriggerInstance.TriggerInstanceCode');
        this.addColumnString('TriggerInstanceName', 'TriggerInstance.TriggerInstanceName');
        this.addColumnString('TriggerDefinitionName', 'TriggerDefinition.CmsDomObj');
        this.addColumnString('NotificationInstanceName', 'TriggerInstance.NotificationInstance');
        this.addColumnString('TriggerModeName', 'TriggerInstance.TriggerModeId');
        this.addColumnDisplayAsCheckbox('IsEnable', 'Enabled');
    }
    configTriggerFired(mode) {
        this.setFieldId('TriggerFiredId');
        this.setSortingDesc('CreationDate');
        this.addColumnId('TriggerFiredId');
        this.addColumnString('TriggerInstanceName', 'TriggerFired.TriggerInstanceName');
        this.addColumnString('TriggerFiredStatusName', 'TriggerFired.TriggerFiredStatusId');
        this.addColumnString('TriggerFiredResultName', 'TriggerFired.TriggerFiredResultId');
        this.addColumnDate('CreationDate', 'CreationDate');
    }
    configNotificationSchema(mode) {
        this.setFieldId('NotificationSchemaId');
        this.setSortingAsc('NotificationSchemaCode');
        this.addColumnId('NotificationSchemaId');
        this.addColumnString('NotificationSchemaCode', 'NotificationSchema.NotificationSchemaCode');
        this.addColumnString('NotificationSchemaName', 'NotificationSchema.NotificationSchemaName');
        this.addColumnDisplayAsCheckbox('IsGlobalNotificationSchema', 'NotificationSchema.IsGlobalNotificationSchema');
        this.addColumnDisplayAsCheckbox('IsSystem', 'NotificationSchema.IsSystem');
        this.addColumnDisplayAsCheckbox('IsActive', 'Active');
    }
    configNotificationInstance(mode) {
        this.setFieldId('NotificationInstanceId');
        this.setSortingAsc('NotificationInstanceCode');
        this.addColumnId('NotificationInstanceId');
        this.addColumnString('NotificationInstanceCode', 'NotificationInstance.NotificationInstanceCode');
        this.addColumnString('NotificationInstanceName', 'NotificationInstance.NotificationInstanceName');
        this.addColumnString('NotificationDefinition.NotificationDefinitionName', 'NotificationDefinition.NotificationDefinitionName');
        this.addColumnString('NotificationDefinition.NotificationDefinitionGroup.NotificationGroupDefinitionName', 'NotificationDefinition.NotificationDefinitionGroupId');
        this.addColumnDisplayAsCheckbox('IsSystem', 'NotificationInstance.IsSystem');
    }
    configHrBucketGenerality(mode) {
        this.setFieldId('VirtualHumanResourceId');
        this.setSortingAsc('VirtualCode');
        this.addColumnId('VirtualHumanResourceId');
        this.addColumnString('HrBucketGenerality.HrBucketCode', 'HrBucketGenerality.HrBucketCode');
        this.addColumnString('HrBucketGenerality.HrBucketName', 'HrBucketGenerality.HrBucketName');
        this.addColumnString('HrBucketGenerality.HrBucketDescription', 'HrBucketGenerality.HrBucketDescription');
    }
    configNotificationDefinition(mode) {
        this.setFieldId('NotificationDefinitionId');
        this.setSortingAsc('NotificationDefinitionName');
        this.addColumnId('NotificationDefinitionId');
        this.addColumnString('NotificationDefinitionName', 'NotificationDefinition.NotificationDefinitionName');
        this.addColumnString('NotificationDefinitionGroupName', 'NotificationDefinition.NotificationDefinitionGroupId');
        this.addColumnDisplayAsCheckbox('IsEnabled', 'NotificationDefinition.IsEnabled');
    }
    configBatchRequest(mode) {
        this.enableCardTemplateContents('BatchRequest');

        this.nbs_queryCellInfo_mapping_formatter = {
            CreationDate: 'formatDateTime',
            ProcessStartDate: 'formatDateTime',
            ProcessedDate: 'formatDateTime'
        }

        this.setFieldId('BatchRequestId');
        this.setSortingDesc('CreationDate');
        this.addColumnId('BatchRequestId');
        this.addColumnString('CreationDate', 'BatchRequest.CreationDate');
        this.addColumnString('BatchRequestName', 'BatchRequest.BatchRequestName');
        this.addColumnString('BatchRequestExtra', 'BatchRequest.BatchRequestExtra');
        this.addColumnString('BatchRequestStatusName', 'BatchRequest.BatchRequestStatus');
        this.addColumnString('VirtualHumanResourceName', 'BatchRequest.VirtualHumanResourceId');
        this.addColumnString('VirtualCompanyName', 'BatchRequest.VirtualCompanyId');
        this.addColumnDisplayAsCheckbox('HasError', 'BatchRequest.HasError');
        this.addColumnString('ErrorDescription', 'BatchRequest.ErrorDescription');
        this.addColumnString('ProcessedDate', 'BatchRequest.ProcessedDate');
    }
    configFileShuffleJournal(mode) {
        this.setFieldId('FileShuffleJournalId');
        this.setSortingDesc('FileShuffleJournalId');
        this.addColumnId('FileShuffleJournalId');
        this.addColumnString('WarningMessage', 'FileShuffleJournal.WarningMessage');
    }
    configFileShuffleDefinition(mode) {
        this.setFieldId('FileShuffleDefinitionId');
        this.setSortingAsc('FileShuffleDefinitionCode');
        this.addColumnId('FileShuffleDefinitionId');
        this.addColumnString('FileShuffleDefinitionCode', 'FileShuffleDefinition.FileShuffleDefinitionCode');
        this.addColumnString('FileShuffleDefinitionName', 'FileShuffleDefinition.FileShuffleDefinitionName');
        this.addColumnString('FileShuffleDefinitionDescription', 'FileShuffleDefinition.FileShuffleDefinitionDescription');
        this.addColumnDisplayAsCheckbox('IsMatchFileNameEnabled', 'FileShuffleDefinition.IsMatchFileNameEnabled');
        this.addColumnString('FileNameMatcher', 'FileShuffleDefinition.FileNameMatcher');
        this.addColumnString('FileNameMatcherToken', 'FileShuffleDefinition.FileNameMatcherToken');
        this.addColumnString('SourceFolder', 'FileShuffleDefinition.SourceFolder');
        this.addColumnString('TargetFolder', 'FileShuffleDefinition.TargetFolder');
        this.addColumnString('SourceFolder', 'FileShuffleDefinition.SourceFolder');
        this.addColumnDisplayAsCheckbox('IsEnabled', 'FileShuffleDefinition.IsEnabled');
    }
    configIntegrationDefinition(mode) {
        this.setFieldId('IntegrationTemplateFormatId');
        this.setSortingAsc('IntegrationDefinitionName');
        this.addColumnString('IntegrationDefinitionName', 'IntegrationDefinition.IntegrationDefinitionName');
        this.addColumnString('IntegrationTemplateFormatName', 'IntegrationDefinition.IntegrationTemplateFormatId');
    }
    configIntegrationFileObserverJournal(mode) {
        this.setFieldId('IntegrationFileObserverJournalId');
        this.setSortingDesc('IntegrationFileObserverJournalId');
        this.addColumnId('IntegrationFileObserverJournalId', null, null, '10%');
        this.addColumnString('WarningMessage', 'IntegrationFileObserverJournal.WarningMessage', null, '30%');
        this.addColumnString('SourceFile', 'IntegrationFileObserverJournal.SourceFile', null, '60%');
    }
    configAddress(mode) {
        this.setFieldId('AddressId');
        this.setSortingAsc('AddressKindName');
        this.addColumnString('AddressKindName', 'Address.AddressKindId');
        this.addColumnString('CustomKindCode', 'Address.CustomKindCode');
        this.addColumnString('Recipient', 'Address.Recipient');
        this.addColumnString('DisplayAddress', 'Address.DisplayAddress');
        this.addColumnString('City', 'Address.City');
        this.addColumnString('District', 'Address.District');
        this.addColumnString('ZipCode', 'Address.ZipCode');
        this.addColumnString('CountryName', 'Address.CountryId');
    }
    configFileShuffleRequest(mode) {
        this.setFieldId('FileShuffleRequestId');
        this.setSortingDesc('FileShuffleRequestId');
        this.addColumnId('FileShuffleRequestId');
        this.addColumnString('FileShuffleRequestName', 'FileShuffleRequest.FileShuffleRequestName');
        this.addColumnString('FileShuffleDefinitionName', 'FileShuffleRequest.FileShuffleDefinition');
        this.addColumnString('FileShuffleRequestStatusName', 'FileShuffleRequest.FileShuffleRequestStatus');
        this.addColumnDisplayAsCheckbox('IsBatch', 'FileShuffleRequest.IsBatch');
        this.addColumnString('BatchRequestId', 'FileShuffleRequest.BatchRequestId');
        this.addColumnString('BatchRequestName', 'FileShuffleRequest.BatchRequestName');
        this.addColumnDisplayAsCheckbox('IsScheduledTask', 'FileShuffleRequest.IsScheduledTask');
        this.addColumnString('TaskTime', 'FileShuffleRequest.TaskTime');
    }
    configActChangeHistory(mode) {
        this.setFieldId('ActChangeHistoryId');
        this.setSortingDesc('CreationDateUI');
        //this.addColumnId('ActChangeHistoryId');
        this.addColumnString('ActWorkflowStatusKindName', 'ActChangeHistory.ActWorkflowStatusKindId');
        this.addColumnString('ActExecutionNote', 'ActChangeHistory.ActExecutionNote');
        this.addColumnDate('ActExecutionDateUI', 'ActChangeHistory.ActExecutionDate');
        this.addColumnDate('CreationDateUI', 'ActChangeHistory.CreationDate');
        this.addColumnString('CreatorUserDisplayName', 'ActChangeHistory.CreatorUserId');
    }
    configIntegrationFileObserver(mode) {
        this.setFieldId('IntegrationFileObserverId');
        this.setSortingAsc('IntegrationFileObserverCode');
        this.addColumnId('IntegrationFileObserverId');
        this.addColumnString('IntegrationFileObserverCode', 'IntegrationFileObserver.IntegrationFileObserverCode');
        this.addColumnString('IntegrationFileObserverName', 'IntegrationFileObserver.IntegrationFileObserverName');
        this.addColumnDisplayAsCheckbox('IsMatchFileNameEnabled', 'IntegrationFileObserver.IsMatchFileNameEnabled');
        this.addColumnString('FileNameMatcher', 'IntegrationFileObserver.FileNameMatcher');
        this.addColumnString('FileNameMatcherToken', 'IntegrationFileObserver.FileNameMatcherToken');
        this.addColumnDisplayAsCheckbox('IsEnabled', 'IntegrationFileObserver.IsEnabled');
    }
    configAuthAudit(mode) {
        this.nbs_queryCellInfo_mapping_formatter = {
            AuthTs: 'formatDateTime'
        }

        this.setFieldId('XCentralUserAuditId');
        this.setSortingDesc('XCentralUserAuditId');
        this.addColumnId('XCentralUserAuditId');
        this.addColumnString('UserName', 'AuthAudit.UserName');
        this.addColumnString('AuthTs', 'AuthAudit.AuthTs');
        this.addColumnString('UserAgent', 'AuthAudit.UserAgent');
    }
    configWorkflow(mode) {
        this.setFieldId('WorkflowId');
        this.setSortingAsc('WorkflowCode');
        this.addColumnId('WorkflowId');
        this.addColumnString('WorkflowCode', 'WorkflowDto.WorkflowCode');
        this.addColumnString('WorkflowName', 'WorkflowDto.WorkflowName');
        this.addColumnString('WorkflowDescription', 'WorkflowDto.WorkflowDescription');
        this.addColumnString('ModuleTargetName', 'WorkflowDto.ModuleTargetId');
        this.addColumnDisplayAsCheckbox('IsSystem', 'WorkflowDto.IsSystem');
        this.addColumnDisplayAsCheckbox('IsActive', 'WorkflowDto.IsActive');
    }
    configWorkflowStatus(mode) {
        this.nbs_queryCellInfo_mapping_custom_method_formatter = {
            WorkflowStatusKindName: (args) => {
                if (args.data.WorkflowStatusColor) {
                    args.cell.bgColor = args.data.WorkflowStatusColor;
                }
            }
        }

        this.setFieldId('WorkflowStatusId');
        this.setSortingAsc('WorkflowStatusCode');
        this.addColumnId('WorkflowStatusId');
        this.addColumnString('WorkflowStatusCode', 'WorkflowStatus.WorkflowStatusCode');
        this.addColumnString('WorkflowStatusName', 'WorkflowStatus.WorkflowStatusName');
        this.addColumnString('WorkflowStatusKindName', 'WorkflowStatus.WorkflowStatusKind');
    }
    configCompany(mode) {
        this.setFieldId('CompanyId');
        this.setSortingAsc('CompanyCode');
        this.addColumnId('CompanyId');
        this.addColumnString('CompanyCode', 'Company.CompanyCode');
        this.addColumnString('CompanyName', 'Company.CompanyName');
    }
    configCmsStaticContent(mode) {
        this.setFieldId('CmsStaticContentId');
        this.setSortingAsc('CmsStaticContentCode');
        this.addColumnId('CmsStaticContentId');
        this.addColumnString('CmsStaticContentCode', 'CmsStaticContent.CmsStaticContentCode');
        this.addColumnString('CmsStaticContentName', 'CmsStaticContent.CmsStaticContentName');
    }
    configWDaySlotTemplate(mode) {
        this.nbs_queryCellInfo_mapping_formatter = {
            TimeFrom: 'formatTimeSpan',
            TimeTo: 'formatTimeSpan'
        }

        this.setFieldId('WDaySlotTemplateId');
        this.setSortingAsc('TimeFrom');
        this.addColumnString('TimeFrom', 'WDaySlotTemplate.TimeFrom');
        this.addColumnString('TimeTo', 'WDaySlotTemplate.TimeTo');
        this.addColumnString('Efficiency', 'WDaySlotTemplate.Efficiency');
    }
    configBiDataDefinition(mode) {
        this.setFieldId('BiDataDefinitionId');
        this.setSortingAsc('BiDataDefinitionCode');
        this.addColumnString('BiDataDefinitionCode', 'BiDataDefinition.BiDataDefinitionCode');
        this.addColumnString('BiDataDefinitionName', 'BiDataDefinition.BiDataDefinitionName');
        this.addColumnString('BiDataDefinitionGroupName', 'BiDataDefinition.BiDataDefinitionGroup');
    }
    configAvatar(mode) {
        this.nbs_queryCellInfo_mapping_formatter = {
            'PersonGenerality.Dob': 'formatDateIgnoreTimeZone'
        }

        switch (mode) {
            case 'search':
                this.setFieldId('VirtualHumanResourceId');
                this.setSortingAsc('LastName');
                this.addColumnId('VirtualHumanResourceId');
                this.addColumnString('RegistrationNumber', 'PersonGenerality.RegistrationNumber');
                this.addColumnString('LastName', 'PersonGenerality.LastName');
                this.addColumnString('FirstName', 'PersonGenerality.FirstName');
                this.addColumnString('TaxIdentificationNumber', 'PersonGenerality.TaxIdentificationNumber');
                this.addColumnDate('Dob', 'PersonGenerality.Dob');
                break;
            case 'list':
            case 'wizard':
                this.allowSearching = true;
                this.setFieldId('VirtualHumanResourceId');
                this.setSortingAsc('PersonGenerality.LastName');
                this.addColumnId('VirtualHumanResourceId');
                this.addColumnString('PersonGenerality.RegistrationNumber', 'PersonGenerality.RegistrationNumber');
                this.addColumnString('PersonGenerality.LastName', 'PersonGenerality.LastName');
                this.addColumnString('PersonGenerality.FirstName', 'PersonGenerality.FirstName');
                this.addColumnString('PersonGenerality.TaxIdentificationNumber', 'PersonGenerality.TaxIdentificationNumber');
                this.addColumnDate('PersonGenerality.Dob', 'PersonGenerality.Dob');
                break;
        }
    }
    configRoute(mode) {
        this.setFieldId('RouteId');
        this.setSortingAsc('RouteCode');
        this.addColumnId('RouteId');
        this.addColumnString('RouteCode', 'Route.RouteCode');
        this.addColumnString('RouteName', 'Route.RouteName');
        this.addColumnString('RouteStatusName', 'Route.RouteStatus');
        this.addColumnString('RouteSchedulingTypeName', 'Route.RouteSchedulingType');
        this.addColumnString('CronExpressionLabel', 'Route.CronExpression');
        this.addColumnString('SchedulingGroupName', 'Route.SchedulingGroup');
    }
    configOperation(mode) {
        this.setFieldId('OperationId');
        this.setSortingAsc('OperationCode');
        //this.addColumnId('OperationId');
        this.addColumnString('OperationExecutionNumber', 'Operation.OperationExecutionNumber');
        this.addColumnString('NextOperationExecutionNumber', 'Operation.NextOperationExecutionNumber');
        this.addColumnString('OperationCode', 'Operation.OperationCode');
        this.addColumnString('OperationName', 'Operation.OperationName');
        this.addColumnDisplayAsCheckbox('IsOnceOperation', 'Operation.IsOnceOperation');
        this.addColumnString('OperationEffortQuota', 'Operation.OperationEffortQuota');
        this.addColumnString('OperationEffortUnitName', 'Operation.OperationEffortUnit');
        this.addColumnString('SpanSafetyMargin', 'Operation.SpanSafetyMargin');
        this.addColumnString('SpanSafetyMarginUnitName', 'Operation.SpanSafetyMarginUnit');
        this.addColumnString('SpanBeforeNextOperation', 'Operation.SpanBeforeNextOperation');
        this.addColumnString('SpanBeforeNextOperationUnitName', 'Operation.SpanBeforeNextOperationUnit');
        this.addColumnString('TRepeaterMultiplier', 'Operation.TRepeaterMultiplier');
        this.addColumnString('WorkCenterGroupName', 'Operation.WorkCenterGroup');
        this.addColumnString('WorkCenterName', 'Operation.WorkCenter');
        this.addColumnString('OperationCommunicationModeName', 'Operation.OperationCommunicationMode');
        this.addColumnString('OperationApprovalModeName', 'Operation.OperationApprovalMode');
    }
    configSmallActivity(mode) {
        this.setFieldId('ActivityId');
        this.setSortingAsc('ActivityCode');
        this.addColumnId('ActivityId');
        this.addColumnString('ActivityCode', 'SmallActivity.ActivityCode');
        this.addColumnString('ActivityName', 'SmallActivity.ActivityName');
        this.addColumnDisplayAsCheckbox('IsInCycle', 'SmallActivity.IsInCycle');
    }
    configSchedulingRequest(mode) {
        this.setFieldId('SchedulingRequestId');
        this.setSortingDesc('SchedulingRequestId');
        this.addColumnId('SchedulingRequestId');
        this.addColumnString('SchedulingRequestName', 'SchedulingRequest.SchedulingRequestName');
        this.addColumnString('SchedulingRequestStatusName', 'SchedulingRequest.SchedulingRequestStatus');
        this.addColumnString('StartDate', 'SchedulingRequest.StartDate');
        this.addColumnString('LatenessAvarage', 'SchedulingRequest.LatenessAvarage');
        this.addColumnString('Tardiness', 'SchedulingRequest.Tardiness');
        this.addColumnString('CreationDate', 'SchedulingRequest.CreationDate');
        this.addColumnString('ProcessedDate', 'SchedulingRequest.ProcessedDate');
    }
    configSchedulingWarning(mode) {
        this.setFieldId('SchedulingWarningId');
        this.setSortingAsc('SchedulingWarningId');
        this.addColumnId('SchedulingWarningId', null, null, '30%');
        this.addColumnString('WarningDescription', 'SchedulingWarning.WarningDescription', null, '70%');
    }
    configServerSystemVariable(mode) {
        this.setFieldId('VariableCode');
        this.setSortingAsc('VariableName');
        this.addColumnString('VariableName', 'ServerSystemVariable.VariableName');
        this.addColumnString('VariableDefaultTranslated', 'ServerSystemVariable.VariableDefault');
        this.addColumnString('VariableValueTranslated', 'ServerSystemVariable.VariableValue');
        this.addColumnString('VariableScope', 'ServerSystemVariable.VariableScope');
        //this.addColumnDisplayAsCheckbox('IsUaAdmin', 'ServerSystemVariable.IsUaAdmin');
    }
    configFileBox(mode) {
        this.nbs_queryCellInfo_mapping_formatter = {
            FileBoxFromDate: 'formatDateIgnoreTimeZone',
            FileBoxToDate: 'formatDateIgnoreTimeZone'
        }

        this.setFieldId('FileBoxId');
        this.setSortingDesc('FileBoxToDate');
        this.addColumnId('FileBoxId');
        this.addColumnString('FileBoxCode', 'FileBox.FileBoxCode');
        this.addColumnString('FileBoxName', 'FileBox.FileBoxName');
        this.addColumnDate('FileBoxFromDate', 'FileBox.FileBoxFromDate');
        this.addColumnDate('FileBoxToDate', 'FileBox.FileBoxToDate');
    }
    configHrHierarchy(mode) {
        switch (mode) {
            case 'list':
                this.setFieldId('HrHierarchyId');
                this.setSortingAsc('HrHierarchyCode');
                this.addColumnId('HrHierarchyId');
                this.addColumnString('HrHierarchyCode', 'HrHierarchy.HrHierarchyCode');
                this.addColumnString('HrHierarchyName', 'HrHierarchy.HrHierarchyName');
                this.addColumnString('BucketClassificationName', 'HrHierarchy.BucketClassification');
                this.addColumnDisplayAsCheckbox('IsPublish', 'Published');
                break;
            case 'ftc':
                this.setFieldId('HrHierarchyId');
                this.setSortingAsc('HrHierarchyCode');
                this.addColumnId('HrHierarchyId');
                this.addColumnString('HrHierarchyCode', 'HrHierarchy.HrHierarchyCode');
                this.addColumnString('HrHierarchyName', 'HrHierarchy.HrHierarchyName');
                break;
        }
    }
    configBiDataDefinitionGroup(mode) {
        this.setFieldId('BiDataDefinitionId');
        this.setSortingAsc('BiDataDefinitionGroupName');
        this.addColumnString('BiDataDefinitionGroupName', 'BiDataDefinitionGroup.BiDataDefinitionGroupName');
    }
    configWDay(mode) {
        this.nbs_queryCellInfo_mapping_formatter = {
            WorkingDate: 'formatDateIgnoreTimeZone'
        }

        var childGridParams = {
            queryString: "WDayId",
            columns: [
                { field: "TimeFrom", headerText: this.translator("WDaySlot.TimeFrom"), width: 100 },
                { field: "TimeTo", headerText: this.translator("WDaySlot.TimeTo"), textAlign: "left", width: 100 },
                { field: "Efficiency", headerText: this.translator("WDaySlot.Efficiency"), textAlign: "left", width: 100 }
            ],
            allowSorting: true
        };
        this.setChildGrid(childGridParams);

        switch (mode) {
            case 'normal':
                this.setFieldId('WDayId');
                this.setSortingAsc('WorkingDate');
                this.addColumnString('WorkingDate', 'WDay.WorkingDate');
                this.addColumnString('DayOfWeek', 'Day');
                this.addColumnString('Month', 'Month');
                this.addColumnDisplayAsCheckbox('IsClosed', 'WDay.IsClosed');
                this.addColumnDisplayAsCheckbox('IsAllDayOpen', 'WDay.IsAllDayOpen');
                this.addColumnDisplayAsCheckbox('IsFestivity', 'WDay.IsFestivity');
                this.addColumnString('Note', 'WDay.Note');
                break
            case 'template':
                this.setFieldId('WDayId');
                this.setSortingAsc('WorkingDate');
                this.addColumnString('WorkingDate', 'WDay.WorkingDate');
                this.addColumnString('DayOfWeek', 'Day');
                this.addColumnString('Month', 'Month');
                this.addColumnDisplayAsCheckbox('IsClosed', 'WDay.IsClosed');
                this.addColumnDisplayAsCheckbox('IsAllDayOpen', 'WDay.IsAllDayOpen');
                break
        }
    }
    configSchedulingGroup(mode) {
        this.setFieldId('SchedulingGroupId');
        this.setSortingAsc('SchedulingGroupCode');
        this.addColumnId('SchedulingGroupId');
        this.addColumnString('SchedulingGroupCode', 'SchedulingGroup.SchedulingGroupCode');
        this.addColumnString('SchedulingGroupName', 'SchedulingGroup.SchedulingGroupName');
    }
    configActActionGroup(mode) {
        this.setFieldId('ActActionGroupId');
        this.setSortingAsc('ActActionGroupCode');
        this.addColumnId('ActActionGroupId');
        this.addColumnString('ActActionGroupCode', 'ActActionGroup.ActActionGroupCode');
        this.addColumnString('ActActionGroupName', 'ActActionGroup.ActActionGroupName');
        this.addColumnDisplayAsCheckbox('IsSystem', 'ActActionGroup.IsSystem');
    }
    configViewPageElementDto(mode) {
        this.allowScrolling = true;
        this.allowPaging = false;
        this.allowGrouping = false;
        this.setScrollSettings(380, 850);

        this.nbs_queryCellInfo_mapping_custom_method_formatter = {
            nbsAllCellInfo: function (args) {
                var bgColor = "#ffffff";
                switch (args.data.ViewPageElementTypeId) {
                    case 5: //title
                        break;
                    case 10: //class
                        bgColor = '#99ccff';
                        break;

                }
                switch (args.column.field) {
                    case "ElementDisplayOrder":
                        bgColor = args.data.ShowInDocs ? bgColor : '#ff9933';
                }

                args.cell.bgColor = bgColor;
            }
        }

        this.setFieldId('ViewPageElementId');
        this.setSortingAsc('ElementDisplayOrder');
        this.addColumnString('ElementDisplayOrder', 'ViewPageElementDto.ElementDisplayOrder', null, 100);
        this.addColumnString('WordValue', 'ViewPageElementDto.WordValue', null, 200);
        this.addColumnString('ViewPageElementTypeName', 'ViewPageElementDto.ViewPageElementType', null, 200);
        this.addColumnString('DocWordValueCut', 'ViewPageElementDto.DocWordValue', null, 200);
        this.addColumnDisplayAsCheckbox('IsOnlyForCmsView', 'ViewPageElementDto.IsOnlyForCmsView', null, 100);
        this.addColumnDisplayAsCheckbox('ShowInDocs', 'ViewPageElementDto.ShowInDocs', null, 100);
        this.addColumnString('ElementName', 'ViewPageElementDto.ElementName', null, 100);
        this.addColumnString('ViewPageElementName', 'ViewPageElementDto.ViewPageElementName', null, 100);
    }
    configActActionType(mode) {
        this.setFieldId('ActActionTypeId');
        this.setSortingAsc('ActActionTypeCode');
        this.addColumnId('ActActionTypeId');
        this.addColumnString('ActActionTypeCode', 'ActActionType.ActActionTypeCode');
        this.addColumnString('ActActionTypeName', 'ActActionType.ActActionTypeName');
        this.addColumnDisplayAsCheckbox('IsSystem', 'ActActionType.IsSystem');
    }
    configFrequencyCode(mode) {
        this.setFieldId('FrequencyCodeId');
        this.setSortingAsc('FrequencyCodeCode');
        this.addColumnId('FrequencyCodeId');
        this.addColumnString('FrequencyCodeCode', 'FrequencyCode.FrequencyCodeCode');
        this.addColumnString('FrequencyCodeReference', 'FrequencyCode.FrequencyCodeReference');
        this.addColumnString('FrequencyCodeDisplay', 'FrequencyCode.FrequencyCodeDisplay');
        this.addColumnString('FrequencyCodeName', 'FrequencyCode.FrequencyCodeName');
        this.addColumnString('CronExpressionLabel', 'FrequencyCode.CronExpression');
    }
    configMasterPlan(mode) {
        this.setFieldId('MasterPlanId');
        this.setSortingAsc('MasterPlanCode');
        this.addColumnId('MasterPlanId');
        this.addColumnString('MasterPlanCode', 'MasterPlan.MasterPlanCode');
        this.addColumnString('MasterPlanName', 'MasterPlan.MasterPlanName');
    }
    configScheduling(mode) {
        this.setFieldId('SchedulingId');
        this.setSortingAsc('SchedulingCode');
        this.addColumnId('SchedulingId');
        this.addColumnString('SchedulingCode', 'Scheduling.SchedulingCode');
        this.addColumnString('SchedulingName', 'Scheduling.SchedulingName');
        this.addColumnString('MasterPlanName', 'MasterPlan.MasterPlan');
    }
    configAppStore(mode) {
        this.setFieldId('AppStoreId');
        switch (mode) {
            case 'module':
                //this.setSortingAsc('Bw');
                //this.addColumnId('AppStoreId');
                this.addColumnDisplayAsCheckbox('IsChecked', 'AppStore.Bw');
                this.addColumnString('AppStoreName', 'AppStore.AppStoreName');
                break;
            case 'hrbucket':
                this.setSortingAsc('Bw');
                //this.addColumnId('AppStoreId');
                this.addColumnString('Bw', 'AppStore.Bw');
                this.addColumnDisplayAsCheckbox('IsChecked', 'AppStore.IsChecked');
                break;
        }
    }
    configCategory(mode) {
        this.setFieldId('CategoryId');
        switch (mode) {
            case 'userprofile':
                this.setSortingAsc('CategoryCode');
                this.addColumnId('CategoryId', null, null, '10%');
                this.addColumnString('CategoryCode', 'Category.CategoryCode', null, '20%');
                this.addColumnString('CategoryName', 'Category.CategoryName', null, '55%');
                this.addColumnString('CategoryKindId', 'Category.CategoryKindId', null, '15%');
                break;
            case 'vhrevent':
                this.setSortingAsc('CategoryCode');
                this.addColumnId('CategoryId', null, null, '10%');
                this.addColumnString('CategoryCode', 'Category.CategoryCode', null, '25%');
                this.addColumnString('CategoryName', 'Category.CategoryName', null, '25%');
                this.addColumnString('CategoryTextPath', 'CategoryTextPathDto.CategoryTextPath', null, '50%');
                break;
            case 'hrbucket':
            case 'vhr':
            case 'contract':
            case 'invoiceRegister':
                this.setSortingAsc('CategoryCode');
                this.addColumnId('CategoryId');
                this.addColumnString('CategoryCode', 'Category.CategoryCode');
                this.addColumnString('CategoryName', 'Category.CategoryName');
                this.addColumnString('CategoryTextPath', 'CategoryTextPathDto.CategoryTextPath');
                break;
        }
    }
    configPairValue(mode) {
        this.setFieldId('key');
        this.addColumnString('key', 'Code');
        this.addColumnString('value', 'Name');
    }
    configHrSingleXHrBucket(mode) {
        this.setFieldId('HrBucketId');
        this.setSortingAsc('HrBucketCode');
        this.addColumnId('HrBucketId');
        this.addColumnString('HrBucketCode', 'Code');
        this.addColumnString('HrBucketName', 'Name');
        this.addColumnString('SingleXBucketModeUI', 'HrSingleXHrBucket.SingleXBucketMode');
        this.addColumnString('SingleXBucketStatusUI', 'HrSingleXHrBucket.SingleXBucketStatus');
    }
    configPersonJobJournal(mode) {
        this.setFieldId('PersonJobJournalId');
        this.setSortingDesc('PersonJobJournalId');
        this.addColumnId('PersonJobJournalId');
        this.addColumnDate('HrWorkingJournalDate', 'PersonJobJournal.HrWorkingJournalDate');
        this.addColumnDate('HrWorkingJournalEndDate', 'PersonJobJournal.HrWorkingJournalEndDate');
        this.addColumnDate('HrWorkingJournalText', 'PersonJobJournal.HrWorkingJournalText');
        this.addColumnDate('CreationDate', 'PersonJobJournal.CreationDate');
    }
    configDrbacProcessGlobal(mode) {
        this.setFieldId('DrbacProcessId');
        this.setSortingAsc('DrbacProcessCode');
        this.addColumnId('DrbacProcessId');
        this.addColumnString('DrbacProcessCode', 'DrbacProcess.DrbacProcessCode');
        this.addColumnString('DrbacProcessName', 'DrbacProcess.DrbacProcessName');
        this.addColumnDisplayAsCheckbox('IsSystem', 'DrbacProcess.IsSystem');
        this.addColumnDisplayAsCheckbox('IsActive', 'Active');
    }
    configUserProfile(mode) {
        this.setFieldId('UserProfileId');
        //this.setSortingAsc('DrbacProcessCode');
        this.addColumnId('UserProfileId');
        this.addColumnDisplayAsCheckbox('IsSystemProfile', 'UserProfile.IsSystemProfile');
        this.addColumnString('UserProfileCode', 'UserProfile.UserProfileCode');
        this.addColumnString('UserProfileName', 'UserProfile.UserProfileName');
        this.addColumnString('UserProfileDescription', 'UserProfile.UserProfileDescription');
    }
    configDrbacUserGroupM2MAppUser(mode) {
        this.setFieldId('AppUserId');
        this.setSortingAsc('AppUserLastName');
        this.addColumnId('AppUserId');
        this.addColumnString('AppUserLastName', 'ContextUserDto.LastName');
        this.addColumnString('AppUserFirstName', 'ContextUserDto.FirstName');
    }
    configDrbacProcessConfig(mode) {
        this.setFieldId('DrbacProcessConfigId');
        this.setSortingAsc('DrbacProcessConfigCode');
        this.addColumnId('DrbacProcessConfigId');
        this.addColumnString('DrbacProcessConfigCode', 'DrbacProcessConfig.DrbacProcessConfigCode');
        this.addColumnString('DrbacProcessConfigName', 'DrbacProcessConfig.DrbacProcessConfigName');
        this.addColumnDisplayAsCheckbox('IsProcessesEnabled', 'DrbacProcessConfig.IsProcessesEnabled');
        this.addColumnString('ModuleTargetName', 'DrbacProcessConfig.ModuleTarget');
    }
    configDrbacUserGroup(mode) {
        this.setFieldId('DrbacUserGroupId');
        this.setSortingAsc('DrbacUserGroupCode');
        this.addColumnId('DrbacUserGroupId');
        this.addColumnString('DrbacUserGroupCode', 'DrbacUserGroup.DrbacUserGroupCode');
        this.addColumnString('DrbacUserGroupName', 'DrbacUserGroup.DrbacUserGroupName');
    }
    configDrbacSchema(mode) {
        this.setFieldId('DrbacSchemaId');
        this.setSortingAsc('DrbacSchemaCode');
        this.addColumnId('DrbacSchemaId');
        this.addColumnString('DrbacSchemaCode', 'DrbacSchema.DrbacSchemaCode');
        this.addColumnString('DrbacSchemaName', 'DrbacSchema.DrbacSchemaName');
        this.addColumnDisplayAsCheckbox('IsSystem', 'DrbacSchema.IsSystem');
        this.addColumnDisplayAsCheckbox('IsActive', 'Active');
    }
    configDrbacPermissionGroup(mode) {
        this.setFieldId('DrbacPermissionGroupId');
        this.setSortingAsc('DrbacPermissionGroupName');
        this.addColumnId('DrbacPermissionGroupId');
        this.addColumnString('DrbacPermissionGroupName', 'DrbacPermissionGroup.DrbacPermissionGroupName');
    }
    configDrbacPermission(mode) {
        this.setFieldId('PermissionId');
        //this.setSortingAsc('PermissionName');
        this.addColumnId('PermissionId');
        this.addColumnString('PermissionName', 'DrbacPermission.PermissionName');
        this.addColumnString('DrbacPermissionGroupName', 'DrbacPermission.DrbacPermissionGroup');
    }
    configDrbacRole(mode) {
        this.setFieldId('RoleId');
        //this.setSortingAsc('RoleName');
        this.addColumnId('RoleId');
        this.addColumnString('RoleName', 'DrbacRole.RoleName');
        this.addColumnDisplayAsCheckbox('IsProcessRole', 'DrbacRole.IsProcessRole');
        this.addColumnDisplayAsCheckbox('IsSystem', 'DrbacRole.IsSystem');
    }
    configGenericEntity(params) {
        this.setFieldId(params.fieldId);
        this.nbs_isMultiselect = params.isMultiselect;
        if (params.sortField && params.sortMode) {
            if (params.sortMode === 'desc') {
                this.setSortingDesc(params.sortField);
            } else {
                this.setSortingAsc(params.sortField);
            }
        } else {
            this.setSortingAsc(params.fieldCode);
        }

        this.setSelectedIds(params.selectedIds);

        if (params.readonly) {
            if (this.nbs_isMultiselect) {
                this.addColumnTemplateContent(params.fieldId, ' ', this.getTemplateContent('ReadonlyCheckboxGridSelection'), '20%');
            } else {
                this.addColumnTemplateContent(params.fieldId, ' ', this.getTemplateContent('ReadonlyRadioGridSelection'), '20%');
            }

            this.nbs_isMultiselect = false;
        }

        if (params.viewId) {
            this.addColumnId(params.fieldId, null, null, '20%');
        }
        if (params.viewCode) {
            this.addColumnString(params.fieldCode, 'Code', null, '40%');
        }
        if (params.viewName) {
            this.addColumnString(params.fieldName, 'Name', null, '40%');
        }
    }
    configDrbacProcess(mode) {
        this.setFieldId('DrbacProcessId');
        this.setSortingAsc('DrbacProcessCode');
        this.addColumnId('DrbacProcessId');
        this.addColumnString('DrbacProcessCode', 'DrbacProcess.DrbacProcessCode');
        this.addColumnString('DrbacProcessName', 'DrbacProcess.DrbacProcessName');
        this.addColumnDisplayAsCheckbox('IsSystem', 'DrbacProcess.IsSystem');
        this.addColumnDisplayAsCheckbox('IsEnabled', 'DrbacProcess.IsEnabled');
    }
    configHrBucket(mode) {
        this.setFieldId('VirtualHumanResourceId');
        this.setSortingAsc('VirtualCode');
        this.addColumnId('VirtualHumanResourceId');
        this.addColumnString('VirtualCode', 'HrBucket.VirtualCode');
        this.addColumnString('VirtualName', 'HrBucket.VirtualName');
        this.addColumnString('BucketClassificationName', 'Type');
    }
    configAct(mode) {
        //var actActionGroupVisible = Nembus.Common.Library.server.getServerVarBool('ssv_act_actiongroup_visible_rule');
        //var actActionTypeVisible = Nembus.Common.Library.server.getServerVarBool('ssv_act_actiontype_visible_rule');
        var actActionGroupVisible = true;
        var actActionTypeVisible = true;

        this.setFieldId('ActId');
        this.enableCardTemplateContents('Act');

        this.nbs_queryCellInfo_mapping_formatter = {
            ActExpireDate: 'formatDateIgnoreTimeZone',
            ActExpireDateSort: 'formatDateIgnoreTimeZone',
            ActExecutionDate: 'formatDateIgnoreTimeZone'
        }

        var childGridParams = {
            queryString: "ActId",
            columns: [
                { field: "ActSourceObjectId", headerText: this.translator("SourceEntityId"), width: 50 },
                { field: "ActSourceObjectCode", headerText: this.translator("SourceEntityCode"), width: 100 },
                { field: "ActSourceObjectName", headerText: this.translator("SourceEntityName"), textAlign: "left", width: 150 },
                { field: "ActSourceObjectType", headerText: this.translator("SourceEntityType"), textAlign: "left", width: 80 }
            ],
            allowSorting: true
        };
        this.setChildGrid(childGridParams);

        switch (mode) {
            case 'vhr':
                this.addColumnId('ActId');
                this.addColumnString('ActTargetName', 'Act.ActTarget');
                this.addColumnTemplateContent('Categories', 'Act.Categories', this.getTemplateContent('Categories'));
                this.addColumnString('ActDescription', 'Act.ActDescription');
                this.addColumnDate('ActExpireDate', 'Act.ActExpireDate');
                this.addColumnString('ActPriorityName', 'Act.ActPriority');
                this.addColumnString('ActTypeName', 'Act.ActTypeId');
                if (actActionGroupVisible) {
                    this.addColumnString('ActActionTypeName', 'Act.ActActionType');
                }
                if (actActionTypeVisible) {
                    this.addColumnString('ActActionGroupName', 'Act.ActActionGroup');
                }
                this.addColumnString('ActWorkflowStatusKindName', 'Act.ActWorkflowStatusKind');
                this.addColumnTemplateContent('Assignee', 'ActAssignee', this.getTemplateContent('Assignee'));
                this.addColumnString('ActAuthorName', 'Act.ActAuthor');
                this.addColumnString('ActExecutorName', 'Act.ActExecutor');
                this.addColumnString('ActExecutionNote', 'Act.ActExecutionNote');
                this.addColumnDate('ActExecutionDate', 'Act.ActExecutionDate');
                break;
            default:
                this.addColumnId('ActId');
                this.addColumnString('ActTargetName', 'Act.ActTarget');
                this.addColumnTemplateContent('Categories', 'Act.Categories', this.getTemplateContent('Categories'));
                this.addColumnString('ActDescription', 'Act.ActDescription');
                this.addColumnDate('ActExpireDate', 'Act.ActExpireDate');
                this.addColumnString('ActPriorityName', 'Act.ActPriority');
                this.addColumnString('ActTypeName', 'Act.ActTypeId');
                if (actActionGroupVisible) {
                    this.addColumnString('ActActionTypeName', 'Act.ActActionType');
                }
                if (actActionTypeVisible) {
                    this.addColumnString('ActActionGroupName', 'Act.ActActionGroup');
                }
                this.addColumnString('ActWorkflowStatusKindName', 'Act.ActWorkflowStatusKind');
                this.addColumnTemplateContent('Assignee', 'ActAssignee', this.getTemplateContent('Assignee'));
                this.addColumnString('ActAuthorName', 'Act.ActAuthor');
                this.addColumnString('ActExecutorName', 'Act.ActExecutor');
                this.addColumnString('ActExecutionNote', 'Act.ActExecutionNote');
                this.addColumnDate('ActExecutionDate', 'Act.ActExecutionDate');
                break;
        }
    }
    configVhrEvent(mode, appStoreCode) {
        this.setFieldId('VhrEventId');
        this.enableCardTemplateContents('VhrEvent');

        this.nbs_queryCellInfo_mapping_formatter = {
            VhrEventStartDate: 'formatDateIgnoreTimeZone',
            VhrEventStartTime: 'formatTime'
        }

        this.nbs_queryCellInfo_mapping_custom_method_formatter = {
            VhrEventLifeStatusName: function (args) {
                if (args.data.VhrEventLifeStatusColor) {
                    args.cell.bgColor = args.data.VhrEventLifeStatusColor;
                }
            }
        }

        switch (mode) {
            case 'customer':
                this.addColumnId('VhrEventId');
                this.addColumnString('ActivityCode', 'VhrEvent.ActivityCode');
                if (appStoreCode === 'ims') {
                    this.addColumnString('BomCode', 'VhrEvent.BomCode');
                    this.addColumnString('BomName', 'VhrEvent.BomName');
                }
                this.addColumnDate('VhrEventStartDate', 'VhrEvent.VhrEventStartDate');
                this.addColumnString('VirtualHumanResourceCode', 'VhrEvent.VirtualHumanResourceCode');
                this.addColumnString('VirtualHumanResourceName', 'VhrEvent.VirtualHumanResourceName');
                this.addColumnString('VhrEventName', 'VhrEvent.VhrEventName');
                this.addColumnString('WorkCenterName', 'VhrEvent.WorkCenterName');
                this.addColumnString('VhrEventLifeStatusName', 'VhrEvent.VhrEventLifeStatus');
                this.addColumnString('ActivityFrequencyCode', 'VhrEvent.ActivityFrequencyCode');
                this.addColumnDisplayAsCheckbox('IsExecuted', 'VhrEvent.IsExecuted');
                this.addColumnDisplayAsCheckbox('IsCustomerApproval', 'VhrEvent.IsCustomerApproval');
                this.addColumnTemplateContent('VhrEventLabels', 'VhrEvent.VhrEventLabels', this.getTemplateContent('VhrEventLabels'));
                break;
            case 'vhr':
                this.addColumnId('VhrEventId');
                this.addColumnString('ActivityCode', 'VhrEvent.ActivityCode');
                if (appStoreCode === 'ims') {
                    this.addColumnString('BomCode', 'VhrEvent.BomCode');
                    this.addColumnString('BomName', 'VhrEvent.BomName');
                }
                this.addColumnDate('VhrEventStartDate', 'VhrEvent.VhrEventStartDate');
                this.addColumnDate('VhrEventStartTime', 'TimeStart');
                this.addColumnString('VirtualHumanResourceCode', 'VhrEvent.VirtualHumanResourceCode');
                this.addColumnString('VirtualHumanResourceName', 'VhrEvent.VirtualHumanResourceName');
                this.addColumnString('VhrEventName', 'VhrEvent.VhrEventName');
                this.addColumnString('WorkCenterName', 'VhrEvent.WorkCenterName');
                this.addColumnString('VhrEventLifeStatusName', 'VhrEvent.VhrEventLifeStatus');
                break;
            case 'vhrcustomer':
                this.addColumnId('VhrEventId');
                this.addColumnString('ActivityCode', 'VhrEvent.ActivityCode');
                if (appStoreCode === 'ims') {
                    this.addColumnString('BomCode', 'VhrEvent.BomCode');
                    this.addColumnString('BomName', 'VhrEvent.BomName');
                }
                this.addColumnDate('VhrEventStartDate', 'VhrEvent.VhrEventStartDate');
                this.addColumnString('VirtualHumanResourceCode', 'VhrEvent.VirtualHumanResourceCode');
                this.addColumnString('VirtualHumanResourceName', 'VhrEvent.VirtualHumanResourceName');
                this.addColumnString('VhrEventName', 'VhrEvent.VhrEventName');
                this.addColumnString('WorkCenterName', 'VhrEvent.WorkCenterName');
                this.addColumnString('VhrEventLifeStatusName', 'VhrEvent.VhrEventLifeStatus');
                this.addColumnDisplayAsCheckbox('IsExecuted', 'VhrEvent.IsExecuted');
                this.addColumnDisplayAsCheckbox('IsCustomerApproval', 'VhrEvent.IsCustomerApproval');
                break;
            case 'search':
                this.addColumnId('VhrEventId');
                this.addColumnString('ActivityCode', 'VhrEvent.ActivityCode');
                if (appStoreCode === 'ims') {
                    this.addColumnString('BomCode', 'VhrEvent.BomCode');
                    this.addColumnString('BomName', 'VhrEvent.BomName');
                }
                this.addColumnDate('VhrEventStartDate', 'VhrEvent.VhrEventStartDate');
                this.addColumnDate('VhrEventStartTime', 'TimeStart');
                this.addColumnString('VirtualHumanResourceCode', 'VhrEvent.VirtualHumanResourceCode');
                this.addColumnString('VirtualHumanResourceName', 'VhrEvent.VirtualHumanResourceName');
                this.addColumnString('VhrEventName', 'VhrEvent.VhrEventName');
                this.addColumnString('WorkCenterName', 'VhrEvent.WorkCenterName');
                this.addColumnString('VhrEventLifeStatusName', 'VhrEvent.VhrEventLifeStatus');
                this.addColumnString('VhrEventTypeName', 'VhrEvent.VhrEventType');
                this.addColumnString('VhrEventGroupName', 'VhrEvent.VhrEventGroup');
                this.addColumnString('ActivityFrequencyCode', 'VhrEvent.ActivityFrequencyCode');
                this.addColumnDisplayAsCheckbox('VhrEventCreationTypeFlag', 'VhrEvent.Manual');
                this.addColumnDisplayAsCheckbox('IsExecuted', 'VhrEvent.IsExecuted');
                this.addColumnDisplayAsCheckbox('IsApproved', 'VhrEvent.IsApproved');
                this.addColumnDisplayAsCheckbox('IsCustomerApproval', 'VhrEvent.IsCustomerApproval');
                this.addColumnTemplateContent('VhrEventLabels', 'VhrEvent.VhrEventLabels', this.getTemplateContent('VhrEventLabels'));
                break;
            case 'searchcustomer':
                this.addColumnId('VhrEventId');
                this.addColumnString('ActivityCode', 'VhrEvent.ActivityCode');
                if (appStoreCode === 'ims') {
                    this.addColumnString('BomCode', 'VhrEvent.BomCode');
                    this.addColumnString('BomName', 'VhrEvent.BomName');
                }
                this.addColumnDate('VhrEventStartDate', 'VhrEvent.VhrEventStartDate');
                this.addColumnString('VirtualHumanResourceCode', 'VhrEvent.VirtualHumanResourceCode');
                this.addColumnString('VirtualHumanResourceName', 'VhrEvent.VirtualHumanResourceName');
                this.addColumnString('VhrEventName', 'VhrEvent.VhrEventName');
                this.addColumnString('WorkCenterName', 'VhrEvent.WorkCenterName');
                this.addColumnString('VhrEventLifeStatusName', 'VhrEvent.VhrEventLifeStatus');
                this.addColumnString('ActivityFrequencyCode', 'VhrEvent.ActivityFrequencyCode');
                this.addColumnDisplayAsCheckbox('IsApproved', 'VhrEvent.IsApproved');
                this.addColumnDisplayAsCheckbox('IsCustomerApproval', 'VhrEvent.IsCustomerApproval');
                this.addColumnTemplateContent('VhrEventLabels', 'VhrEvent.VhrEventLabels', this.getTemplateContent('VhrEventLabels'));
                break;
            default:
                this.addColumnId('VhrEventId');
                this.addColumnString('ActivityCode', 'VhrEvent.ActivityCode');
                if (appStoreCode === 'ims') {
                    this.addColumnString('BomCode', 'VhrEvent.BomCode');
                    this.addColumnString('BomName', 'VhrEvent.BomName');
                }
                this.addColumnDate('VhrEventStartDate', 'VhrEvent.VhrEventStartDate');
                this.addColumnDate('VhrEventStartTime', 'TimeStart');
                this.addColumnString('VirtualHumanResourceCode', 'VhrEvent.VirtualHumanResourceCode');
                this.addColumnString('VirtualHumanResourceName', 'VhrEvent.VirtualHumanResourceName');
                this.addColumnString('VhrEventName', 'VhrEvent.VhrEventName');
                this.addColumnString('WorkCenterName', 'VhrEvent.WorkCenterName');
                this.addColumnString('VhrEventLifeStatusName', 'VhrEvent.VhrEventLifeStatus');
                this.addColumnString('VhrEventTypeName', 'VhrEvent.VhrEventType');
                this.addColumnString('VhrEventGroupName', 'VhrEvent.VhrEventGroup');
                this.addColumnString('ActivityFrequencyCode', 'VhrEvent.ActivityFrequencyCode');
                this.addColumnDisplayAsCheckbox('VhrEventCreationTypeFlag', 'VhrEvent.Manual');
                this.addColumnDisplayAsCheckbox('IsExecuted', 'VhrEvent.IsExecuted');
                this.addColumnDisplayAsCheckbox('IsApproved', 'VhrEvent.IsApproved');
                this.addColumnDisplayAsCheckbox('IsCustomerApproval', 'VhrEvent.IsCustomerApproval');
                this.addColumnTemplateContent('VhrEventLabels', 'VhrEvent.VhrEventLabels', this.getTemplateContent('VhrEventLabels'));
                this.addColumnTemplateContent('ExecutorId', 'VhrEvent.ExecutorId', this.getTemplateContent('VhrEventExecutor'));
                if (appStoreCode === 'ims') {
                    this.addColumnString('ExecutionNote', 'VhrEvent.ExecutionNote');
                }
                this.addColumnTemplateContent('ApprovalId', 'VhrEvent.ApprovalId', this.getTemplateContent('VhrEventApproval'));
                break;
        }
    }
    configVirtualCompany(mode) {
        switch (mode) {
            case 'wizard':
            case 'changeVc':
                this.setFieldId('VirtualCompanyId');
                this.setSortingAsc('VirtualCompanyCode');
                this.addColumnId('VirtualCompanyId');
                this.addColumnString('VirtualCompanyCode', 'Code');
                this.addColumnString('VirtualCompanyName', 'Name');
                break
            case 'list':
                this.setFieldId('VirtualCompanyId');
                this.setSortingAsc('VirtualCompanyCode');
                this.addColumnId('VirtualCompanyId');
                this.addColumnString('VirtualCompanyCode', 'VirtualCompany.VirtualCompanyCode');
                this.addColumnString('VirtualCompanyName', 'VirtualCompany.VirtualCompanyName');
                break;
            case 'supplier':
                this.setFieldId('VirtualCompanyId');
                this.setSortingAsc('VirtualCompanyId');
                this.addColumnId('VirtualCompanyId');
                this.addColumnString('VirtualCompanyName', 'VirtualCompany.VirtualCompanyName');
                break;
        }
    }
    configVhrEventLabel(mode) {
        this.setFieldId('VhrEventLabelId');
        this.setSortingAsc('VhrEventLabelCode');
        this.addColumnId('VhrEventLabelId');
        this.addColumnString('VhrEventLabelCode', 'VhrEventLabel.VhrEventLabelCode');
        this.addColumnString('VhrEventLabelName', 'VhrEventLabel.VhrEventLabelName');
        this.addColumnString('ModuleTargetName', 'VhrEventLabel.ModuleTarget');
        this.addColumnDisplayAsCheckbox('IsSystem', 'VhrEventLabel.IsSystem');
    }
    configActivityPriceGroup(mode) {
        this.nbs_queryCellInfo_mapping_formatter = {
            PriceGroupStartDate: 'formatDateIgnoreTimeZone',
            PriceGroupEndDate: 'formatDateIgnoreTimeZone'
        }

        this.setFieldId('ActivityPriceGroupId');
        this.setSortingAsc('ActivityPriceGroupCode');
        this.addColumnId('ActivityPriceGroupId');
        this.addColumnString('ActivityPriceGroupCode', 'ActivityPriceGroup.ActivityPriceGroupCode');
        this.addColumnString('ActivityPriceGroupName', 'ActivityPriceGroup.ActivityPriceGroupName');
        this.addColumnString('PriceGroupStartDate', 'ActivityPriceGroup.PriceGroupStartDate');
        this.addColumnString('PriceGroupEndDate', 'ActivityPriceGroup.PriceGroupEndDate');
    }
    configAppUserDto(mode) {
        this.enableCardTemplateContents('AppUser');

        switch (mode) {
            case 'userprofile':
                this.setFieldId('AppUserId');
                this.addColumnId('AppUserId');
                this.setSortingAsc('Username');
                this.addColumnString('AppUserUsername', 'ContextUserDto.Username');
                this.addColumnString('AppUserFirstName', 'ContextUserDto.FirstName');
                this.addColumnString('AppUserLastName', 'ContextUserDto.LastName');
                break;
            default:
                this.setFieldId('XCentralUserId');
                this.setSortingAsc('Username');
                this.addColumnId('XCentralUserId');
                this.addColumnString('DisplayName', 'ContextUserDto.FirstName');
                this.addColumnString('Username', 'ContextUserDto.Username');
                this.addColumnDisplayAsCheckbox('IsEnabled', 'ContextUserDto.IsEnabled');
                this.addColumnDisplayAsCheckbox('IsProgrammaticAccess', 'ContextUserDto.IsProgrammaticAccess');
                this.addColumnDisplayAsCheckbox('IsSa', 'ContextUserDto.IsSa');
                break;
        }
    }
    configActivity(mode) {
        switch (mode) {
            case 'schedulinggroup':
                this.setFieldId('ActivityId');
                this.setSortingAsc('ActivityCode');
                this.addColumnId('ActivityId');
                this.addColumnString('ActivityCode', 'Activity.ActivityCode');
                this.addColumnString('ActivityName', 'Activity.ActivityName');
                this.addColumnString('ModuleTargetName', 'Activity.ModuleTarget');
                this.addColumnString('SchedulingGroup.SchedulingGroupName', 'Activity.SchedulingGroup');
                break;
            case 'wizard':
            case 'light':
                this.allowSearching = true;
                this.setFieldId('ActivityId');
                this.setSortingAsc('ActivityCode');
                this.addColumnId('ActivityId');
                this.addColumnString('ActivityCode', 'Code');
                this.addColumnString('ActivityName', 'Name');
                break;
            case 'wizard2':
                this.allowSearching = true;
                this.setFieldId('ActivityId');
                this.setSortingAsc('ActivityCode');
                this.addColumnId('ActivityId');
                this.addColumnString('ActivityCode', 'Code');
                this.addColumnString('ActivityName', 'Name');
                this.addColumnString('VhrEventGroupName', 'Activity.VhrEventGroup');
                this.addColumnString('VhrEventTypeName', 'Activity.VhrEventType');
                break;
            case 'bom':
                this.setFieldId('ActivityId');
                this.setSortingAsc('ActivityCode');
                this.addColumnId('ActivityId');
                this.addColumnString('ActivityCode', 'Activity.ActivityCode');
                this.addColumnString('ActivityName', 'Activity.ActivityName');
                this.addColumnNumber('SchedulingGroupName', 'Activity.SchedulingGroup');
                this.addColumnString('ActivityFrequencyCode', 'Activity.ActivityFrequencyCode');
                this.addColumnString('ModuleTargetName', 'Activity.ModuleTarget');
                break;
            case 'subbom':
                this.setFieldId('ActivityId');
                this.setSortingAsc('ActivityCode');
                this.addColumnId('ActivityId');
                this.addColumnString('ActivityCode', 'Activity.ActivityCode');
                this.addColumnString('ActivityName', 'Activity.ActivityName');
                this.addColumnNumber('ActivityEffortQuotaUI', 'Activity.ActivityEffortQuota');
                this.addColumnString('ActivityEffortUnitName', 'Activity.ActivityEffortUnit');
                this.addColumnString('VhrEventGroupName', 'Activity.VhrEventGroup');
                this.addColumnString('VhrEventTypeName', 'Activity.VhrEventType');
                this.addColumnString('ModuleTargetName', 'Activity.ModuleTarget');
                break;
            case 'modal':
                this.allowSearching = true;
                this.setFieldId('ActivityId');
                this.setSortingAsc('ActivityCode');
                this.addColumnId('ActivityId');
                this.addColumnString('ActivityCode', 'Code');
                this.addColumnString('ActivityName', 'Name');
                break;
            case 'vhr':
                this.setFieldId('ActivityId');
                this.setSortingAsc('ActivityCode');
                //this.addColumnId('ActivityId');
                this.addColumnString('ActivityCode', 'Code');
                this.addColumnString('ActivityName', 'Name');
                break;
            case 'activityvhr':
                this.setFieldId('ActivityId');
                this.setSortingAsc('ActivityCode');
                //this.addColumnId('ActivityId');
                this.addColumnString('ActivityCode', 'Code');
                this.addColumnString('ActivityName', 'Name');
                this.addColumnString('ActivityXVhrModeForLayout', 'ActivityVhr.ActivityXVhrMode');
                this.addColumnString('ActivityXVhrStatusForLayout', 'ActivityVhr.ActivityXVhrStatus');
                this.addColumnString('SourceBucketCode', 'ActivityVhr.SourceBucketCode');
                this.addColumnString('SourceBucketName', 'ActivityVhr.SourceBucketName');
                break;
        }
    }
    configTriggerInstanceMatch(mode) {
        switch (mode) {
            case 'notificationSchema':
                this.setFieldId('TriggerInstanceMatchId');
                this.setSortingAsc('PropertyNameTranslated');
                this.addColumnString('PropertyNameTranslated', 'TriggerInstanceMatch.PropertyName');
                this.addColumnString('TriggerMatchTypeTranslated', 'TriggerInstanceMatch.TriggerMatchTypeId');
                this.addColumnString('TriggerPropertyTypeTranslated', 'TriggerInstanceMatch.TriggerPropertyTypeId');
                this.addColumnString('ValueUI', 'TriggerInstanceMatch.Value');
                this.addColumnString('ValueSpanUI', 'TriggerInstanceMatch.ValueSpan');
                break;
            default:
                this.setFieldId('TriggerInstanceMatchId');
                this.setSortingAsc('TriggerInstanceMatchId');
                this.addColumnId('TriggerInstanceMatchId');
                this.addColumnString('PropertyNameTranslated', 'TriggerInstanceMatch.PropertyName');
                this.addColumnString('TriggerMatchTypeTranslated', 'TriggerInstanceMatch.TriggerMatchTypeId');
                this.addColumnString('TriggerPropertyTypeTranslated', 'TriggerInstanceMatch.TriggerPropertyTypeId');
                this.addColumnString('ValueUI', 'TriggerInstanceMatch.Value');
                this.addColumnString('ValueSpanUI', 'TriggerInstanceMatch.ValueSpan');
        }
    }
    configAssetTree(mode) {
        this.nbs_queryCellInfo_mapping_custom_method_formatter = {
            nbsAllCellInfo: function (args) {
                if (args.data.AssetTreeEntityType !== 'VirtualEquipment') {
                    args.cell.innerHTML = '<b>' + args.cell.innerHTML + '</b>';
                }
            }
        }

        switch (mode) {
            case 'modal-assettree':
                this.setFieldId('VirtualHumanResourceId');
                this.addColumnId('VirtualHumanResourceId');
                this.addColumnString('VirtualEquipmentSerialNumber', 'AssetTreeDto.VirtualEquipmentSerialNumber');
                this.addColumnString('VirtualCode', 'AssetTreeDto.VirtualCode');
                this.addColumnString('VirtualName', 'AssetTreeDto.VirtualName');
                this.addColumnString('VirtualEquipmentProducer', 'AssetTreeDto.VirtualEquipmentProducer');
                this.addColumnString('VirtualEquipmentModel', 'AssetTreeDto.VirtualEquipmentModel');
                this.addColumnString('VirtualEquipmentRegistrationNumber', 'AssetTreeDto.VirtualEquipmentRegistrationNumber');
                break;
            case 'modal-target':
                this.setFieldId('VirtualHumanResourceId');
                this.addColumnId('VirtualHumanResourceId');
                this.addColumnString('VirtualCode', 'AssetTreeDto.VirtualCode');
                this.addColumnString('VirtualName', 'AssetTreeDto.VirtualName');
                this.addColumnString('VirtualDescription', 'AssetTreeDto.VirtualDescription');
                this.addColumnString('VhrGroupName', 'AssetTreeDto.VhrGroupName');
                this.addColumnString('VhrTypeName', 'AssetTreeDto.VhrTypeName');
                break;
            case 'list':
                this.enableCardTemplateContents('Assettree');

                this.setFieldId('VirtualHumanResourceId');
                this.setSortingAsc('VirtualCode');
                this.addColumnId('VirtualHumanResourceId');
                this.addColumnString('VirtualCode', 'AssetTreeDto.VirtualCode');
                this.addColumnString('VirtualName', 'AssetTreeDto.VirtualName');
                this.addColumnString('VirtualDescription', 'AssetTreeDto.VirtualDescription');
                this.addColumnString('ResourceTypeName', 'AssetTreeDto.ResourceTypeId');
                this.addColumnString('VirtualEquipmentRegistrationNumber', 'AssetTreeDto.VirtualEquipmentRegistrationNumber');
                this.addColumnString('VhrGroupName', 'AssetTreeDto.VhrGroupName');
                this.addColumnString('VirtualEquipmentSerialNumber', 'AssetTreeDto.VirtualEquipmentSerialNumber');
                this.addColumnString('VirtualEquipmentProducer', 'AssetTreeDto.VirtualEquipmentProducer');
                this.addColumnString('VirtualEquipmentModel', 'AssetTreeDto.VirtualEquipmentModel');
                this.addColumnString('VhrTypeName', 'AssetTreeDto.VhrTypeName');
                this.addColumnTemplateContent('LegalEntities', 'LegalEntities', this.getTemplateContent('LegalEntities'));
                this.addColumnTemplateContent('CostCenters', 'CostCenters', this.getTemplateContent('CostCenters'));
                this.addColumnTemplateContent('BusinessUnits', 'BusinessUnits', this.getTemplateContent('BusinessUnits'));
                this.addColumnTemplateContent('Sectors', 'Sectors', this.getTemplateContent('Sectors'));
                this.addColumnTemplateContent('Plants', 'Plants', this.getTemplateContent('Plants'));
                break;
            case 'profile':
                this.enableCardTemplateContents('Assettree');

                this.setFieldId('VirtualHumanResourceId');
                this.setSortingAsc('VirtualCode');
                this.addColumnId('VirtualHumanResourceId');
                this.addColumnString('VirtualCode', 'AssetTreeDto.VirtualCode');
                this.addColumnString('VirtualName', 'AssetTreeDto.VirtualName');
                this.addColumnString('VirtualDescription', 'AssetTreeDto.VirtualDescription');
                this.addColumnString('ResourceTypeName', 'AssetTreeDto.ResourceTypeId');
                this.addColumnString('VirtualEquipmentRegistrationNumber', 'AssetTreeDto.VirtualDescription');
                this.addColumnString('VirtualDescription', 'AssetTreeDto.VirtualDescription');
                this.addColumnString('VhrGroupName', 'AssetTreeDto.VhrGroupName');
                this.addColumnString('VirtualEquipmentSerialNumber', 'AssetTreeDto.VirtualEquipmentSerialNumber');
                this.addColumnString('VirtualEquipmentProducer', 'AssetTreeDto.VirtualEquipmentProducer');
                this.addColumnString('VirtualEquipmentModel', 'AssetTreeDto.VirtualEquipmentModel');
                this.addColumnString('VhrTypeName', 'AssetTreeDto.VhrTypeName');
                break;
        }
    }
    configDependentActivity(mode) {
        switch (mode) {
            case 'bom':
                this.setFieldId('DependentActivityId');
                this.setSortingAsc('SortIndex');
                this.addColumnString('SortIndex', 'Sorting');
                this.addColumnString('DependentActivityCode', 'Activity.ActivityCode');
                this.addColumnString('DependentActivityName', 'Activity.ActivityName');
                this.addColumnNumber('ActivityEffortQuota', 'Activity.ActivityEffortQuota');
                this.addColumnString('ActivityEffortUnitName', 'Activity.ActivityEffortUnit');
                this.addColumnString('WorkCenterGroupName', 'WorkCenterGroup');
                this.addColumnString('VirtualWorkCenterName', 'WorkCenter');
                this.addColumnDisplayAsCheckbox('DependActivityIsSingle', 'Single');
                this.addColumnDisplayAsCheckbox('DependActivityIsPeriodical', 'Periodical');
                break;
            case 'subbom':
                this.setFieldId('DependentActivityId');
                this.setSortingAsc('SortIndex');
                this.addColumnString('SortIndex', 'Sorting');
                this.addColumnString('DependentActivityCode', 'Activity.ActivityCode');
                this.addColumnString('DependentActivityName', 'Activity.ActivityName');
                break;
        }
    }
    configContextUserDto(mode) {
        this.setFieldId('AppUserId');
        this.setSortingAsc('LastName');
        this.addColumnId('AppUserId');
        this.addColumnString('LastName', 'ContextUserDto.LastName');
        this.addColumnString('FirstName', 'ContextUserDto.FirstName');
    }
    configAppUserPerson(mode) {
        this.setFieldId('UserPersonId');
        this.setSortingAsc('LastName');
        this.addColumnId('UserPersonId');
        this.addColumnString('LastName', 'LastName');
        this.addColumnString('FirstName', 'FirstName');
    }

    getTemplateContent(key, addJsRenderTags?) {
        if (!key) {
            throw new Error('getTemplateContent: key mandatory');
            //return this.openJsRenderTag + this.closeJsRenderTag;
        }
        key = key.toLowerCase();

        var templateContent = this.templateObject[key] || '';
        if (addJsRenderTags === false) {
            return templateContent;
        } else {
            return this.openJsRenderTag + templateContent + this.closeJsRenderTag;
        }

    }
    //#endregion
}

//#endregion

