﻿'use strict';
define([], function () {

    var injectParams = ['$q', '$timeout', 'contextService', 'actService', 'utilService', 'foundationService', 'workflowService', 'drbacService', 'rbacService'];
    var nbsActBarComponent = function ($q, $timeout, contextService, actService, utilService, foundationService, workflowService, drbacService, rbacService) {
        var vm = this;
        var privateUIData = null;
        var defaultKeys;
        var virtualCompanyId = null;
        var pAppUsers = [];
        var pTargets = [];
        var pCategories = [];
        var pDrbacUserGroups = [];
        var privateDatacontext = null;
        var actStatusEnumObj = null;
        var expressionTypeEnum = null;
        var expressionTypeObj = null;
        var uiMode = null;
        var workflowStatusKindId = null;
        var allDataReady = [];
        var nbsUiOptionParser = null;

        var actActionGroupVisible = true;
        var actActionTypeVisible = true;

        vm.privateFormSummary = {
            dataContext: {},
            isVisible: false
        };

        vm.privateComponentsStatus = {};
        vm.nbsDataPrivate = {};

        vm.$onInit = function () {
        };

        vm.$onChanges = function (changes) {
            if (changes.viewElementCode && changes.viewElementCode.currentValue) {
                allDataReady.push('viewElementCode');
            }
            if (changes.nbsDataContext && changes.nbsDataContext.currentValue) {
                allDataReady.push('nbsDataContext');
            }
            if (allDataReady.length === 2) {
                nbsUiOptionParser = new NbsUiOptionParser(vm.uiContext.translate);

                privateDatacontext = changes.nbsDataContext.currentValue;

                // set default selections
                defaultKeys = privateDatacontext.defaultKeys;

                uiMode = privateDatacontext.uiMode;
                vm.privateFormSummary = privateDatacontext.formSummary;

                workflowStatusKindId = privateDatacontext.workflowStatusKindId;
                actStatusEnumObj = Nembus.Common.Library.util.parseEnum(actService.dataContext.enumTypes.ActStatus, null, vm.uiContext.translate).obj.normal.name_value;
                expressionTypeEnum = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.ExpressionType, null, vm.uiContext.translate).obj.normal.name_value;
                expressionTypeObj = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.ExpressionType, null, vm.uiContext.translate).obj.normal.name_value;
                virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;

                // Start of execution workflow of load data bar components 
                initViewTitle();
            }
        };

        function initViewTitle() {
            var cols = ['ActId', 'ActTargetName', 'Categories', 'ActExpireDate', 'ActPriorityName', 'ActTypeName', 'ActWorkflowStatusKindName', 'Assignee'];
            var defaultStartData = new NbsUiData();
            var defaultSelected = Nembus.Common.Library.component.bar.datarange.getTimelineDatarange('FY+0');
            defaultStartData.setFilter('actexpiredatetimeline', defaultSelected);
            defaultStartData.setFilter('actexecutiondatetimeline', defaultSelected);
            defaultStartData.setColumns(cols);
            defaultStartData.setFilterList(getFilterList());

            var sortField = 'ActId';
            var sortDirection = true; // descending
            switch (defaultKeys.sortingKey) {
                case 'toexecute':
                    sortField = 'ActExpireDate';
                    sortDirection = false;
                    break;
                case 'executed':
                    sortField = 'ActExpireDate';
                    sortDirection = true;
                    break;
                case 'all':
                    sortField = 'ActId';
                    sortDirection = true;
                    break;
                case 'myactions':
                    sortField = 'ActExpireDate';
                    sortDirection = false;
                    break;
            }
            defaultStartData.setSortingField(sortField, sortDirection);

            vm.initViewTitle = defaultStartData.startData;
        }

        //#region hook from components
        vm.onViewTitleSelectionEvent = function (result) {
            privateUIData = result.uiData;
            setComponents(privateUIData);

            setBarDatasources()
                .then(function () {
                    barOnChanges(privateUIData);
                });
        };
        vm.onUiChanges = function (result) {
            privateUIData.columns = result.uiData.columns;
            privateUIData.grouping = result.uiData.grouping;
            privateUIData.sorting = result.uiData.sorting;
            privateUIData.filters.freeText = result.uiData.filters.freeText;

            refreshViewTitleDataContext(privateUIData);

            vm.onGridUiChangesEvent({ result: privateUIData });
        };
        vm.onFilterChanges = function (result) {
            privateUIData.filterList = result.filters.filterList;

            refreshViewTitleDataContext(privateUIData);
            setStatusComponents(privateUIData.filterList);

            setBarDatasources()
                .then(function () {
                    barOnChanges(privateUIData);
                });
        };
        vm.onDdActPriorities = function (options) {
            privateUIData.filters.actpriorities = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdActTypes = function (options) {
            privateUIData.filters.acttypes = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdVhrGroups = function (options) {
            privateUIData.filters.vhrgroups = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdVhrTypes = function (options) {
            privateUIData.filters.vhrtypes = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdActActionTypes = function (options) {
            privateUIData.filters.actactiontypes = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdActActionGroups = function (options) {
            privateUIData.filters.actactiongroups = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdWorkflowStatusKinds = function (options) {
            privateUIData.filters.workflowstatuskinds = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDatarangeExpireSelectionEvent = function (timeline) {
            privateUIData.filters.actexpiredatetimeline = timeline;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDatarangeExecutionSelectionEvent = function (timeline) {
            privateUIData.filters.actexecutiondatetimeline = timeline;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdMsAssignee = function (result) {
            privateUIData.filters.assignee = result;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdMsAuthor = function (options) {
            privateUIData.filters.author = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdMsExecutor = function (options) {
            privateUIData.filters.executor = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdMsVhrTargets = function (options) {
            privateUIData.filters.vhrtargets = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdMsCategories = function (options) {
            privateUIData.filters.categories = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onBooleanActExpireDateIncludeNull = function (value) {
            privateUIData.filters.actexpiredateincludenull = value;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };

        //#endregion

        // refresh current preference uiData
        function refreshViewTitleDataContext(uiDataParam) {
            vm.viewTitleDataContext = Nembus.Common.Library.component.getDataContextViewTitle(uiDataParam);
        }

        function setBarDatasources() {
            var defer = $q.defer();
            var funcsPromises = [];

            if (vm.privateComponentsStatus.actpriorities.isVisible) {
                funcsPromises.push(getActPriorities());
            }
            if (vm.privateComponentsStatus.acttypes.isVisible) {
                funcsPromises.push(getActTypes());
            }
            if (vm.privateComponentsStatus.vhrgroups.isVisible) {
                funcsPromises.push(getVhrGroups());
            }
            if (vm.privateComponentsStatus.vhrtypes.isVisible) {
                funcsPromises.push(getVhrTypes());
            }
            if (vm.privateComponentsStatus.actactiontypes.isVisible) {
                funcsPromises.push(getActActionTypes(false));
            }
            if (vm.privateComponentsStatus.actactiongroups.isVisible) {
                funcsPromises.push(getActActionGroups(false));
            }
            if (vm.privateComponentsStatus.workflowstatuskinds.isVisible) {
                funcsPromises.push(getWorkflowStatusKinds());
            }
            if (vm.privateComponentsStatus.assignee.isVisible
                || vm.privateComponentsStatus.author.isVisible
                || vm.privateComponentsStatus.executor.isVisible) {

                funcsPromises.push(getCurrentContextAppUsers(false));
            }
            if (vm.privateComponentsStatus.assignee.isVisible) {
                funcsPromises.push(getDrbacUserGroups(false, virtualCompanyId));
            }
            if (vm.privateComponentsStatus.vhrtargets.isVisible) {
                funcsPromises.push(getTargetDtos(false));
            }
            if (vm.privateComponentsStatus.categories.isVisible) {
                funcsPromises.push(getCategoriesSubTreeOutsideAuthorization(false));
            }

            $q.all(funcsPromises)
                .then(function () {
                    vm.nbsDataPrivate.assignee = nbsUiOptionParser.getAssignment(pAppUsers, pDrbacUserGroups, null);
                    vm.nbsDataPrivate.author = nbsUiOptionParser.getUiOptionsByKey(pAppUsers, 'AppUserDto');
                    vm.nbsDataPrivate.executor = nbsUiOptionParser.getUiOptionsByKey(pAppUsers, 'AppUserDto');
                    vm.nbsDataPrivate.vhrtargets = nbsUiOptionParser.getUiOptionsByKey(pTargets, 'VhrTarget');
                    vm.nbsDataPrivate.categories = nbsUiOptionParser.getUiOptionsByKey(pCategories, 'Category');

                    defer.resolve(true);
                });

            return defer.promise;
        }

        function getFilterList() {
            var uiDataFilters = [];
            uiDataFilters.push(new NbsUiDataFilter({ code: 'actpriorities', name: 'Priority' }));
            uiDataFilters.push(new NbsUiDataFilter({ code: 'acttypes', name: 'Act.ActTypeId' }));
            
            if (actActionTypeVisible) {
                uiDataFilters.push(new NbsUiDataFilter({ code: 'actactiontypes', name: 'Act.ActActionType' }));
            }

            if (actActionGroupVisible) {
                uiDataFilters.push(new NbsUiDataFilter({ code: 'actactiongroups', name: 'Act.ActActionGroup' }));
            }

            if (uiMode === 'all' || uiMode === 'myactions') {
                if (vm.viewElementCode !== 'boardworkflow') {
                    uiDataFilters.push(new NbsUiDataFilter({ code: 'workflowstatuskinds', name: 'Status' }));
                }

                if (vm.viewElementCode !== 'boardduedate') {
                    uiDataFilters.push(new NbsUiDataFilter({ code: 'actexecutiondatetimeline', name: 'Act.ActExecutionDate' }));
                }
            }

            if (vm.viewElementCode !== 'boardduedate') {
                uiDataFilters.push(new NbsUiDataFilter({ code: 'actexpiredatetimeline', name: 'Act.ActExpireDate', canHide: false }));
            }

            uiDataFilters.push(new NbsUiDataFilter({ code: 'assignee', name: 'ActAssignee' }));
            uiDataFilters.push(new NbsUiDataFilter({ code: 'author', name: 'Act.ActAuthor' }));
            uiDataFilters.push(new NbsUiDataFilter({ code: 'executor', name: 'Act.ActExecutor' }));
            uiDataFilters.push(new NbsUiDataFilter({ code: 'vhrtargets', name: 'Targets' }));
            uiDataFilters.push(new NbsUiDataFilter({ code: 'categories', name: 'Category' }));
            uiDataFilters.push(new NbsUiDataFilter({ code: 'vhrgroups', name: 'TargetDto.TargetGroupId' }));
            uiDataFilters.push(new NbsUiDataFilter({ code: 'vhrtypes', name: 'TargetDto.TargetTypeId' }));

            if (vm.viewElementCode !== 'boardduedate') {
                uiDataFilters.push(new NbsUiDataFilter({ code: 'actexpiredateincludenull', name: 'IncludeExpireDateEmpty' }));
            }

            return uiDataFilters;
        }

        function getActPriorities() {
            var defer = $q.defer();
            var actPriorityEnumParsedObj = Nembus.Common.Library.util.parseEnum(actService.dataContext.enumTypes.ActPriority, 'Act', vm.uiContext.translate);
            vm.nbsDataPrivate.actpriorities = nbsUiOptionParser.getUiOptionsByEnum(actPriorityEnumParsedObj.list.translated);
            defer.resolve(true);
            return defer.promise;
        }

        function getActTypes() {
            var defer = $q.defer();
            var actTypeEnumParsedObj = Nembus.Common.Library.util.parseEnum(actService.dataContext.enumTypes.ActType, 'Act', vm.uiContext.translate);
            vm.nbsDataPrivate.acttypes = nbsUiOptionParser.getUiOptionsByEnum(actTypeEnumParsedObj.list.translated);
            defer.resolve(true);
            return defer.promise;
        }

        function getVhrGroups(forceReload) {
            var filter = {};
            var defer = $q.defer();

            foundationService.getVhrGroups(filter, forceReload)
                .then(function (data) {
                    vm.nbsDataPrivate.vhrgroups = nbsUiOptionParser.getUiOptionsByKey(data.results, 'VhrGroup');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrTypes(forceReload) {
            var filter = {};
            var defer = $q.defer();

            foundationService.getVhrTypes(filter, forceReload)
                .then(function (data) {
                    vm.nbsDataPrivate.vhrtypes = nbsUiOptionParser.getUiOptionsByKey(data.results, 'VhrType');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getActActionTypes(forceReload) {
            var defer = $q.defer();
            var filter = {};

            actService.getActActionTypes(filter, forceReload)
                .then(function (data) {
                    vm.nbsDataPrivate.actactiontypes = nbsUiOptionParser.getUiOptionsByKey(data.results, 'ActActionType');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getActActionGroups(forceReload) {
            var defer = $q.defer();
            var filter = {};

            actService.getActActionGroups(filter, forceReload)
                .then(function (data) {
                    vm.nbsDataPrivate.actactiongroups = nbsUiOptionParser.getUiOptionsByKey(data.results, 'ActActionGroup');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getWorkflowStatusKinds() {
            var defer = $q.defer();
            var actStatusEnumParsedObj = Nembus.Common.Library.util.parseEnum(workflowService.dataContext.enumTypes.WorkflowStatusKind, 'WorkflowStatus', vm.uiContext.translate);
            vm.nbsDataPrivate.workflowstatuskinds = nbsUiOptionParser.getUiOptionsByEnum(actStatusEnumParsedObj.list.translated);
            defer.resolve(true);
            return defer.promise;
        }

        function getCurrentContextAppUsers(forceReload) {
            var defer = $q.defer();
            var filter = {};

            rbacService.getCurrentContextAppUsers(filter, forceReload)
                .then(function (data) {
                    pAppUsers = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getDrbacUserGroups(forceReload, virtualCompanyId) {
            var defer = $q.defer();
            var filter = {
                VirtualCompanyId: virtualCompanyId
            };

            drbacService.getDrbacUserGroups(filter, forceReload)
                .then(function (data) {
                    pDrbacUserGroups = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getTargetDtos(forceReload) {
            var vhrStatusEnum = Nembus.Common.Library.util.parseEnum(foundationService.dataContext.enumTypes.VhrStatus, null, vm.uiContext.translate).obj.normal.name_value;

            var filter = {
                AppStoreBw: null,
                VhrStatusId: vhrStatusEnum.Enabled
            };

            var defer = $q.defer();

            foundationService.getTargetDtos(filter, forceReload)
                .then(function (data) {
                    pTargets = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getCategoriesSubTreeOutsideAuthorization(forceReload) {
            var filter = {
                SystemCategoryCode: 'ACT',
                CategoryKindId: foundationService.dataContext.consts.CATEGORYKINDS.category
            };
            var defer = $q.defer();

            foundationService.getCategoriesSubTreeOutsideAuthorization(filter, forceReload)
                .then(function (data) {
                    pCategories = Lazy(data.results)
                        .filter((category) => { return category.CategoryKindId === foundationService.dataContext.consts.CATEGORYKINDS.category; })
                        .toArray();
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function setComponents(uiDataParam) {
            setCommonGridBarDataContext(uiDataParam);
            setSelectionComponents(uiDataParam);
            setStatusComponents(uiDataParam.filterList);
            vm.onGridUiChangesEvent({ result: privateUIData });
        }

        function setCommonGridBarDataContext(uiDataParam) {
            vm.commonGridBarDataContext = Nembus.Common.Library.component.getDataContextCommonGridBar(uiDataParam);
            vm.commonBarDatasource = privateDatacontext.commonBarDatasource
        }

        function setSelectionComponents(uiDataParam) {
            vm.nbsDataPrivate.actexpiredateincludenull = uiDataParam.filters.actexpiredateincludenull;
            vm.nbsDataPrivate.actexpiredatetimelineselected = uiDataParam.filters.actexpiredatetimeline;
            vm.nbsDataPrivate.actexecutiondatetimelineselected = uiDataParam.filters.actexecutiondatetimeline;
            vm.nbsDataPrivate.assigneeselected = uiDataParam.filters.assignee;

            vm.nbsDataPrivate.vhrgroupsselected = uiDataParam.filters.vhrgroups ? uiDataParam.filters.vhrgroups : [];
            vm.nbsDataPrivate.vhrtypesselected = uiDataParam.filters.vhrtypes ? uiDataParam.filters.vhrtypes : [];
            vm.nbsDataPrivate.acttypesselected = uiDataParam.filters.acttypes ? uiDataParam.filters.acttypes : [];
            vm.nbsDataPrivate.actactiontypesselected = uiDataParam.filters.actactiontypes ? uiDataParam.filters.actactiontypes : [];
            vm.nbsDataPrivate.actactiongroupsselected = uiDataParam.filters.actactiongroups ? uiDataParam.filters.actactiongroups : [];
            vm.nbsDataPrivate.actprioritiesselected = uiDataParam.filters.actpriorities ? uiDataParam.filters.actpriorities : [];
            vm.nbsDataPrivate.workflowstatuskindsselected = uiDataParam.filters.workflowstatuskinds ? uiDataParam.filters.workflowstatuskinds : [];
            vm.nbsDataPrivate.vhrtargetsselected = uiDataParam.filters.vhrtargets ? uiDataParam.filters.vhrtargets : [];
            vm.nbsDataPrivate.categoriesselected = uiDataParam.filters.categories ? uiDataParam.filters.categories : [];
            vm.nbsDataPrivate.authorselected = uiDataParam.filters.author ? uiDataParam.filters.author : [];
            vm.nbsDataPrivate.executorselected = uiDataParam.filters.executor ? uiDataParam.filters.executor : [];
        }

        function setStatusComponents(filterList) {
            vm.privateComponentsStatus.actexpiredatetimeline = Nembus.Common.Library.component.getStatus('actexpiredatetimeline', filterList);
            vm.privateComponentsStatus.actexecutiondatetimeline = Nembus.Common.Library.component.getStatus('actexecutiondatetimeline', filterList);
            vm.privateComponentsStatus.actpriorities = Nembus.Common.Library.component.getStatus('actpriorities', filterList);
            vm.privateComponentsStatus.vhrgroups = Nembus.Common.Library.component.getStatus('vhrgroups', filterList);
            vm.privateComponentsStatus.vhrtypes = Nembus.Common.Library.component.getStatus('vhrtypes', filterList);
            vm.privateComponentsStatus.acttypes = Nembus.Common.Library.component.getStatus('acttypes', filterList);
            vm.privateComponentsStatus.actactiontypes = Nembus.Common.Library.component.getStatus('actactiontypes', filterList);
            vm.privateComponentsStatus.actactiongroups = Nembus.Common.Library.component.getStatus('actactiongroups', filterList);
            vm.privateComponentsStatus.workflowstatuskinds = Nembus.Common.Library.component.getStatus('workflowstatuskinds', filterList);
            vm.privateComponentsStatus.assignee = Nembus.Common.Library.component.getStatus('assignee', filterList);
            vm.privateComponentsStatus.vhrtargets = Nembus.Common.Library.component.getStatus('vhrtargets', filterList);
            vm.privateComponentsStatus.categories = Nembus.Common.Library.component.getStatus('categories', filterList);
            vm.privateComponentsStatus.author = Nembus.Common.Library.component.getStatus('author', filterList);
            vm.privateComponentsStatus.executor = Nembus.Common.Library.component.getStatus('executor', filterList);
            vm.privateComponentsStatus.actexpiredateincludenull = Nembus.Common.Library.component.getStatus('actexpiredateincludenull', filterList);
        }

        function barOnChanges(uiDataParam) {
            var selectedAssignedAppUserId = [];
            var selectedAssignedDrbacUserGroupId = [];
            var isNotAssigned = null;
            var selectedVhrTargetsId = [];
            var selectedCategoriesId = [];
            var selectedAuthorId = [];
            var selectedExecutorId = [];
            var selectedActPriorityId = [];
            var selectedActTypeId = [];
            var selectedActActionTypeId = [];
            var selectedActActionGroupId = [];
            var actExpireDateIncludeNull = null;
            var actexpiretimelinefrom = Nembus.Common.Library.component.bar.datarange.getDataRangeFromUiDataParam(uiDataParam.filters.actexpiredatetimeline).dateFrom;
            var actexpiretimelineto = Nembus.Common.Library.component.bar.datarange.getDataRangeFromUiDataParam(uiDataParam.filters.actexpiredatetimeline).dateTo;
            var actexecutiontimelinefrom = Nembus.Common.Library.component.bar.datarange.getDataRangeFromUiDataParam(uiDataParam.filters.actexecutiondatetimeline).dateFrom;
            var actexecutiontimelineto = Nembus.Common.Library.component.bar.datarange.getDataRangeFromUiDataParam(uiDataParam.filters.actexecutiondatetimeline).dateTo;
            var selectedActWorkflowStatusKindId = workflowStatusKindId;
            var selectedVhrGroupId = [];
            var selectedVhrTypeId = [];

            if (uiMode === 'all' || uiMode === 'myactions') {
                selectedActWorkflowStatusKindId = null;
                if (vm.privateComponentsStatus.workflowstatuskinds.isVisible) {
                    selectedActWorkflowStatusKindId = Lazy(uiDataParam.filters.workflowstatuskinds).pluck('optionValue').first();
                }
            }

            if (!vm.privateComponentsStatus.actexecutiondatetimeline.isVisible) {
                actexecutiontimelinefrom = null;
                actexecutiontimelineto = null;
            }

            if (!vm.privateComponentsStatus.actexpiredatetimeline.isVisible) {
                actexpiretimelinefrom = null;
                actexpiretimelineto = null;
            }

            if (vm.privateComponentsStatus.acttypes.isVisible) {
                selectedActTypeId = Lazy(uiDataParam.filters.acttypes).pluck('optionValue').toArray();
            }

            if (vm.privateComponentsStatus.vhrgroups.isVisible) {
                selectedVhrGroupId = Lazy(uiDataParam.filters.vhrgroups).pluck('optionValue').toArray();
            }

            if (vm.privateComponentsStatus.vhrtypes.isVisible) {
                selectedVhrTypeId = Lazy(uiDataParam.filters.vhrtypes).pluck('optionValue').toArray();
            }

            if (vm.privateComponentsStatus.actactiontypes.isVisible) {
                selectedActActionTypeId = Lazy(uiDataParam.filters.actactiontypes).pluck('optionValue').toArray();
            }

            if (vm.privateComponentsStatus.actactiongroups.isVisible) {
                selectedActActionGroupId = Lazy(uiDataParam.filters.actactiongroups).pluck('optionValue').toArray();
            }

            if (vm.privateComponentsStatus.actpriorities.isVisible) {
                selectedActPriorityId = Lazy(uiDataParam.filters.actpriorities).pluck('optionValue').toArray();
            }

            if (vm.privateComponentsStatus.vhrtargets.isVisible) {
                selectedVhrTargetsId = Lazy(uiDataParam.filters.vhrtargets).pluck('optionValue').toArray();
            }

            if (vm.privateComponentsStatus.categories.isVisible) {
                selectedCategoriesId = Lazy(uiDataParam.filters.categories).pluck('optionValue').toArray();
            }

            if (vm.privateComponentsStatus.author.isVisible) {
                selectedAuthorId = Lazy(uiDataParam.filters.author).pluck('optionValue').toArray();
            }

            if (vm.privateComponentsStatus.executor.isVisible) {
                selectedExecutorId = Lazy(uiDataParam.filters.executor).pluck('optionValue').toArray();
            }

            if (vm.privateComponentsStatus.assignee.isVisible) {
                if (uiDataParam.filters.assignee) {
                    if (uiDataParam.filters.assignee.isNotAssigned) {
                        isNotAssigned = true;
                    } else {
                        selectedAssignedAppUserId = Lazy(uiDataParam.filters.assignee.appusers).pluck('optionValue').toArray();
                        selectedAssignedDrbacUserGroupId = Lazy(uiDataParam.filters.assignee.drbacusergroups).pluck('optionValue').toArray();
                    }
                }
            }

            if (vm.privateComponentsStatus.actexpiredateincludenull.isVisible) {
                actExpireDateIncludeNull = uiDataParam.filters.actexpiredateincludenull;
            }

            $timeout(() => {
                vm.onBarChanges({
                    result: {
                        actFilter: {
                            ActExpireDateIncludeNull: actExpireDateIncludeNull,
                            ActAuthorIds: selectedAuthorId,
                            ActExecutorIds: selectedExecutorId,
                            ActTargetIds: selectedVhrTargetsId,
                            CategoriesId: selectedCategoriesId,
                            ActWorkflowStatusKindId: selectedActWorkflowStatusKindId,
                            ActStatusId: actStatusEnumObj.Opened,
                            IsCurrentUserAssigned: uiMode === 'myactions',
                            ActExpireDate1Expression: expressionTypeEnum.GreaterThanOrEqual,
                            ActExpireDate1: actexpiretimelinefrom,
                            ActExpireDate2Expression: expressionTypeEnum.LessThanOrEqual,
                            ActExpireDate2: actexpiretimelineto,
                            ActExecutionDate1Expression: expressionTypeEnum.GreaterThanOrEqual,
                            ActExecutionDate1: actexecutiontimelinefrom,
                            ActExecutionDate2Expression: expressionTypeEnum.LessThanOrEqual,
                            ActExecutionDate2: actexecutiontimelineto,
                            ActPriorityIds: selectedActPriorityId,
                            ActTypeIds: selectedActTypeId,
                            ActActionTypeIds: selectedActActionTypeId,
                            ActActionGroupIds: selectedActActionGroupId,
                            AppUserAssignedIds: selectedAssignedAppUserId,
                            UserGroupAssignedIds: selectedAssignedDrbacUserGroupId,
                            AssignmentExpression: expressionTypeObj.Or,
                            IsNotAssigned: isNotAssigned,
                            VhrGroupIds: selectedVhrGroupId,
                            VhrTypeIds: selectedVhrTypeId
                        }
                    }
                });
            });
        }
        //#endregion 
    };

    nbsActBarComponent.$inject = injectParams;
    angular.module('nbs.rds.module').component('nbsActBar', {
        templateUrl: '/rds/acts/components/nbsActBarComponent/_?c=' + window.codeCache(),
        controller: nbsActBarComponent,
        controllerAs: 'vm',
        bindings: {
            onBarChanges: '&',
            onGridUiChangesEvent: '&',

            viewElementCode: "@",
            nbsDataContext: "<", //e.g. {vhreventtypes:[NbsUiOption,NbsUiOption],vhreventgroups:[],status etc..}
            uiContext: "<"
        }
    });
});

