﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'navigationService', 'checkStateChangeService', 'contextService', 'formSummaryService'];
    var hrPersonJobDataDetail = function ($q, $scope, $timeout, $injector, foundationService, navigationService, checkStateChangeService, contextService, formSummaryService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, foundationService, { dirtyFlagIsSuspended: vm.isDirtySuspended });

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('personjobjournal', null);
        nbsGridDatacontext.allowSearching = false;
        nbsGridDatacontext.allowGrouping = false;
        nbsGridDatacontext.setDatasource([], false); // fake datasource, only to create grid
        vm.nbsGridDatacontext = nbsGridDatacontext;

        var currentPersonJobDataId = navigationService.stateParams().personJobDataId;
        // get array items for form summary
        vm.formSummary = formSummaryService.getDatasource();

        var selectionParams = { destModalKey: 'category', isMultiselect: false, systemCategoryCode: 'BSC.JOBROLES', categoryType: 'user' };
        vm.nbsSelectionTreeviewWithModalCategoryJobRoles = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategoryJobRoles.setOnSelectFnCallback(function () {
            vm.currentHrPersonJobData.JobRoleId = vm.nbsSelectionTreeviewWithModalCategoryJobRoles.selectedIds
                ? vm.nbsSelectionTreeviewWithModalCategoryJobRoles.selectedIds[0]
                : null;
        });

        selectionParams = { destModalKey: 'category', isMultiselect: false, systemCategoryCode: 'BSC.LEGALQUALIFICATIONS', categoryType: 'user' };
        vm.nbsSelectionTreeviewWithModalCategoryLegalQualifications = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategoryLegalQualifications.setOnSelectFnCallback(function () {
            vm.currentHrPersonJobData.LegalQualificationId = vm.nbsSelectionTreeviewWithModalCategoryLegalQualifications.selectedIds
                ? vm.nbsSelectionTreeviewWithModalCategoryLegalQualifications.selectedIds[0]
                : null;
        });

        selectionParams = { destModalKey: 'category', isMultiselect: false, systemCategoryCode: 'BSC.BUSINESSROLES', categoryType: 'user' };
        vm.nbsSelectionTreeviewWithModalCategoryBusinessRoles = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategoryBusinessRoles.setOnSelectFnCallback(function () {
            vm.currentHrPersonJobData.BusinessRoleId = vm.nbsSelectionTreeviewWithModalCategoryBusinessRoles.selectedIds
                ? vm.nbsSelectionTreeviewWithModalCategoryBusinessRoles.selectedIds[0]
                : null;
        });

        selectionParams = { destModalKey: 'category', isMultiselect: false, systemCategoryCode: 'BSC.SAFETYROLES', categoryType: 'user' };
        vm.nbsSelectionTreeviewWithModalCategorySafetyRoles = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategorySafetyRoles.setOnSelectFnCallback(function () {
            vm.currentHrPersonJobData.SafetyRoleId = vm.nbsSelectionTreeviewWithModalCategorySafetyRoles.selectedIds
                ? vm.nbsSelectionTreeviewWithModalCategorySafetyRoles.selectedIds[0]
                : null;
        });

        selectionParams = { destModalKey: 'category', isMultiselect: false, systemCategoryCode: 'BSC.PLACE', categoryType: 'user' };
        vm.nbsSelectionTreeviewWithModalCategoryPlaceDefineds = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategoryPlaceDefineds.setOnSelectFnCallback(function () {
            vm.currentHrPersonJobData.PlaceDefinedId = vm.nbsSelectionTreeviewWithModalCategoryPlaceDefineds.selectedIds
                ? vm.nbsSelectionTreeviewWithModalCategoryPlaceDefineds.selectedIds[0]
                : null;
        });

        selectionParams = { destModalKey: 'category', isMultiselect: false, systemCategoryCode: 'BSC.PLACE', categoryType: 'user' };
        vm.nbsSelectionTreeviewWithModalCategoryPlaceUseds = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategoryPlaceUseds.setOnSelectFnCallback(function () {
            vm.currentHrPersonJobData.PlaceUsedId = vm.nbsSelectionTreeviewWithModalCategoryPlaceUseds.selectedIds
                ? vm.nbsSelectionTreeviewWithModalCategoryPlaceUseds.selectedIds[0]
                : null;
        });

        selectionParams = { destModalKey: 'category', isMultiselect: false, systemCategoryCode: 'BSC.CC', categoryType: 'user' };
        vm.nbsSelectionTreeviewWithModalCategoryCcDefineds = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategoryCcDefineds.setOnSelectFnCallback(function () {
            vm.currentHrPersonJobData.CcDefinedId = vm.nbsSelectionTreeviewWithModalCategoryCcDefineds.selectedIds
                ? vm.nbsSelectionTreeviewWithModalCategoryCcDefineds.selectedIds[0]
                : null;
        });

        selectionParams = { destModalKey: 'category', isMultiselect: false, systemCategoryCode: 'BSC.CC', categoryType: 'user' };
        vm.nbsSelectionTreeviewWithModalCategoryCcUseds = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategoryCcUseds.setOnSelectFnCallback(function () {
            vm.currentHrPersonJobData.CcUsedId = vm.nbsSelectionTreeviewWithModalCategoryCcUseds.selectedIds
                ? vm.nbsSelectionTreeviewWithModalCategoryCcUseds.selectedIds[0]
                : null;
        });

        selectionParams = { destModalKey: 'category', isMultiselect: false, systemCategoryCode: 'BSC.BU', categoryType: 'user' };
        vm.nbsSelectionTreeviewWithModalCategoryBuDefineds = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategoryBuDefineds.setOnSelectFnCallback(function () {
            vm.currentHrPersonJobData.BuDefinedId = vm.nbsSelectionTreeviewWithModalCategoryBuDefineds.selectedIds
                ? vm.nbsSelectionTreeviewWithModalCategoryBuDefineds.selectedIds[0]
                : null;
        });

        selectionParams = { destModalKey: 'category', isMultiselect: false, systemCategoryCode: 'BSC.BU', categoryType: 'user' };
        vm.nbsSelectionTreeviewWithModalCategoryBuUsed = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategoryBuUsed.setOnSelectFnCallback(function () {
            vm.currentHrPersonJobData.BuUsedId = vm.nbsSelectionTreeviewWithModalCategoryBuUsed.selectedIds
                ? vm.nbsSelectionTreeviewWithModalCategoryBuUsed.selectedIds[0]
                : null;
        });

        vm.companies = [];
        vm.hrPersonJobJournals = [];


        vm.queryCellInfo = function (args) {
            switch (args.column.field) {
                case 'HrWorkingJournalDate':
                case 'HrWorkingJournalEndDate':
                    args.cell.innerHTML = Nembus.Common.Library.converter.date.formatDate(args.data[args.column.field], contextService.virtualContext.cultureInfo, true);
                    break
                case 'CreationDate':
                    args.cell.innerHTML = Nembus.Common.Library.converter.date.formatDateTime(args.data[args.column.field], contextService.virtualContext.cultureInfo, true);
                    break
            }
        };

        if (currentPersonJobDataId) {
            getPersonJobData(true, currentPersonJobDataId);
            getPersonJobJournals(true, currentPersonJobDataId);
            getCompanies(true);
        }

        function getCompanies(forceReload) {
            var defer = $q.defer();

            foundationService.getCompanies({}, forceReload)
                .then(function (data) {
                    vm.companies = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getPersonJobData(forceReload, personJobDataId) {
            var filter = { PersonJobDataId: personJobDataId };
            var defer = $q.defer();

            foundationService.getPersonJobData(filter, forceReload)
                .then(function (data) {
                    vm.currentHrPersonJobData = data.results[0];
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getPersonJobJournals(forceReload, personJobDataId) {
            var filter = { PersonJobDataId: personJobDataId };
            var defer = $q.defer();

            foundationService.getPersonJobJournals(filter, forceReload)
                .then(function (data) {
                    vm.hrPersonJobJournals = data.results;
                    if (vm.hrPersonJobJournals.length > 0) {
                        vm.currentPersonJobJournal = Lazy(vm.hrPersonJobJournals)
                            .sortBy((el) => { return el.PersonJobJournalId; }, true)
                            .first();
                    } else {
                        vm.currentPersonJobJournal = foundationService.dataContext.personJobJournal.add({
                            PersonJobDataId: personJobDataId
                        });
                    }

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.hrPersonJobJournals
                    }
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function setPersonJobJournalForAdd() {
            if (vm.currentPersonJobJournal.entityAspect.entityState.isModified()) {
                vm.currentPersonJobJournal.entityAspect.setUnchanged();

                vm.currentPersonJobJournal = foundationService.dataContext.personJobJournal.add({
                    PersonJobDataId: vm.currentPersonJobJournal.PersonJobDataId,
                    HrWorkingJournalDate: vm.currentPersonJobJournal.HrWorkingJournalDate,
                    HrWorkingJournalEndDate: vm.currentPersonJobJournal.HrWorkingJournalEndDate,
                    HrWorkingJournalText: vm.currentPersonJobJournal.HrWorkingJournalText
                });
            }
        }

        var parents = vm.submitAction;
        vm.submitAction = function () {
            setPersonJobJournalForAdd();

            parents().then(
                function (saveResult) {
                    getPersonJobJournals(true, currentPersonJobDataId);
                }
            );
        };

        var parentm = vm.modifyAction;
        vm.modifyAction = function () {
            setPersonJobJournalForAdd();

            parentm().then(
                function (saveResult) {
                    getPersonJobJournals(true, currentPersonJobDataId);
                }
            );
        };
    };

    hrPersonJobDataDetail.$inject = injectParams;
    hrPersonJobDataDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('hrPersonJobDataDetail', hrPersonJobDataDetail);
});