﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'productionService', 'activityService', 'utilService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var routeDetail = function ($q, $scope, $timeout, $injector, productionService, activityService, utilService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, productionService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, productionService, { dirtyFlagIsSuspended: vm.isDirtySuspended });
        
        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var currentRouteId = navigationService.stateParams().routeId;
        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);

        var routeSchedulingTypeEnum = productionService.dataContext.enumTypes.RouteSchedulingType;
        var routeStatusEnum = productionService.dataContext.enumTypes.RouteStatus;
        var effortUnitsEnum = utilService.dataContext.enumTypes.EffortUnit;
        var dateIntervalEnum = utilService.dataContext.enumTypes.DateInterval_dateMode;
        var operationApprovalModeEnum = productionService.dataContext.enumTypes.OperationApprovalMode;
        var operationCommunicationModeEnum = productionService.dataContext.enumTypes.OperationCommunicationMode;

        var routeSchedulingTypes = Nembus.Common.Library.util.parseEnum(routeSchedulingTypeEnum, null, vm.translate);
        var routeStatuses = Nembus.Common.Library.util.parseEnum(routeStatusEnum, null, vm.translate);
        var effortUnitObj = Nembus.Common.Library.util.parseEnum(effortUnitsEnum, null, vm.translate);
        var dateIntervalObj = Nembus.Common.Library.util.parseEnum(dateIntervalEnum, null, vm.translate);
        var operationApprovalModeObj = Nembus.Common.Library.util.parseEnum(operationApprovalModeEnum, null, vm.translate);
        var operationCommunicationModeObj = Nembus.Common.Library.util.parseEnum(operationCommunicationModeEnum, null, vm.translate);

        vm.routeSchedulingTypes = routeSchedulingTypes.list.translated;
        vm.routeStatuses = routeStatuses.list.translated;
        vm.effortUnits = effortUnitObj.list.translated;
        vm.dateIntervalUnits = dateIntervalObj.list.translated;
        vm.operationApprovals = operationApprovalModeObj.list.translated;
        vm.operationCommunications = operationCommunicationModeObj.list.translated;

        vm.effortUnitObjForBind = effortUnitObj.obj.translated.value_name;
        vm.dateIntervalObjForBind = dateIntervalObj.obj.translated.value_name;
        vm.operationApprovalModeObjForBind = operationApprovalModeObj.obj.translated.value_name;
        vm.operationCommunicationModeObjForBind = operationCommunicationModeObj.obj.translated.value_name;

        var currentOperations = [];

        vm.schedulingGroups = [];

        vm.setIsViewing();

        loadData(true);

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('operation', null);
        nbsGridDatacontext.setGridIsRenderedInlineBlock();
        nbsGridDatacontext.setDatasource([], false);
        nbsGridDatacontext.allowPaging = false;
        nbsGridDatacontext.allowSearching = false;
        nbsGridDatacontext.allowGrouping = false;
        vm.nbsGridDatacontext = nbsGridDatacontext;

        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getSchedulingGroups(forceReload, appStoreBw));
            funcsPromises.push(getWorkCenterGroups(forceReload));
            funcsPromises.push(getVirtualWorkCenters(forceReload));
            funcsPromises.push(getRoute(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.schedulingGroups = results[0];

                    vm.workCentersGroups = results[1];
                    vm.workCentersGroupsObjForBind = Nembus.Common.Library.parser.objFromList(vm.workCentersGroups, 'WorkCenterGroupId', 'WorkCenterGroupName');

                    vm.workCenters = results[2];
                    vm.workCentersObjForBind = Nembus.Common.Library.parser.objFromList(vm.workCenters, 'VirtualWorkCenterId', 'WorkCenterName');

                    vm.currentRoute = results[3];

                    getOperations(forceReload)
                        .then((operations) => {
                            currentOperations = operations;

                            vm.forceDatasourceLocal = {
                                refreshkey: new Date().getTime(),
                                dataSource: currentOperations
                            }
                        });
                });
        }

        vm.gridSelection = function (args) {
            vm.operationSelected = args.data;
        };

        vm.rowDblClick = function (args) {
            getOperation();
            getWorkCenterGroupsModal(true);

            vm.modalInstance = navigationService.modal().open(vm.modalOptions({
                windowClass: vm.NBS_MODAL_CLASS_FORM_MD,
                templateUrl: 'operationTemplate',
                scope: $scope
            }));
            vm.modalInstance.result.then(function () {
                //ok
                setCurrentOperation();
            }, function () {
                //dismiss
                productionService.rejectChanges();
            });
            vm.modalInstance.ok = function () {
                if (!vm.currentOperation.entityAspect.hasValidationErrors) {
                    vm.modalInstance.close();
                }
            };
            vm.modalInstance.cancel = function () {
                vm.modalInstance.dismiss();
            };
        };

        vm.cronExpressionOnClick = function () {
            navigationService.navToModal(vm.suspendDirty, 'app.nav.cronexpressiondetail',
                { 'cronstring': vm.currentRoute.CronExpression }
            ).then(function (result) {
                var cronObj = result.data;
                vm.currentRoute.CronExpression = cronObj.getCronExpression();
                vm.currentRoute.CronExpressionLabel = cronObj.getCronLabel();
            });
        };

        function setCurrentOperation() {
            setCurrentOperationProperties(vm.currentOperation);
            currentOperations = productionService.dataContext.operations.get();

            vm.forceDatasourceLocal = {
                refreshkey: new Date().getTime(),
                dataSource: currentOperations
            }
        }

        function setCurrentOperationProperties(item) {
            item.OperationEffortUnitName = vm.effortUnitObjForBind[item.OperationEffortUnitId];
            item.SpanSafetyMarginUnitName = vm.dateIntervalObjForBind[item.SpanSafetyMarginUnitId];
            item.SpanBeforeNextOperationUnitName = vm.dateIntervalObjForBind[item.SpanBeforeNextOperationUnitId];
            item.OperationApprovalModeName = vm.operationApprovalModeObjForBind[item.OperationApprovalModeId];
            item.OperationCommunicationModeName = vm.operationCommunicationModeObjForBind[item.OperationCommunicationModeId];
            item.WorkCenterGroupName = vm.workCentersGroupsObjForBind[item.WorkCenterGroupId];
            item.WorkCenterName = vm.workCentersObjForBind[item.VirtualWorkCenterId];
        }

        function getSchedulingGroups(forceReload, appStoreBw) {
            var filter = { AppStoreBw: appStoreBw };
            var defer = $q.defer();

            activityService.getSchedulingGroups(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getWorkCenterGroups(forceReload) {
            var filter = { WorkCenterGroupSystemCode: 'WKC' };
            var defer = $q.defer();

            productionService.getWorkCenterGroups(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVirtualWorkCenters(forceReload) {
            var filter = {};
            var defer = $q.defer();

            productionService.getVirtualWorkCenters(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getWorkCenterGroupsModal(forceReload) {
            vm.workCentersGroupsModal = Lazy(vm.workCentersGroups)
                .where({
                    GroupCapacityUnitId: vm.currentOperation.OperationEffortUnitId
                }).toArray();
        }

        function getRoute(forceReload) {
            var filter = { RouteId: currentRouteId };
            var defer = $q.defer();

            productionService.getRoute(filter, forceReload)
                .then(function (data) {
                    Nembus.Common.Library.parser.AddCronExpressionLabelToList(data.results, 'CronExpression', vm.translate);
                    defer.resolve(data.results[0]);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getOperations(forceReload) {
            var filter = {
                RouteId: currentRouteId,
                OperationId: null
            };
            var defer = $q.defer();

            productionService.getOperations(filter, forceReload)
                .then(function (data) {
                    Lazy(data.results).each((item) => {
                        setCurrentOperationProperties(item);
                    });

                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getOperation() {
            vm.currentOperation = Lazy(currentOperations).findWhere({ OperationId: vm.operationSelected.OperationId });
        }
    };

    routeDetail.$inject = injectParams;
    routeDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('routeDetail', routeDetail);
});