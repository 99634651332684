﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'reportService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var categoriesListReportForm = function ($q, $scope, $timeout, $injector, foundationService, reportService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        var checkStateChangeParams = { dirtyFlagIsDisabled: vm.isDirtyDisabled };
        checkStateChangeService.checkFormOnStateChange($scope, foundationService, checkStateChangeParams);

        var systemCategoryCode = navigationService.stateParams().systemCategoryCode;
        vm.viewDescriptionCheckbox = navigationService.stateParams().viewDescriptionCheckbox === "true" ? true : false;

        // all form items are enabled
        vm.setIsEditingReport();

        vm.currentFilter = {
            viewCategoryDescription: false
        };

        vm.viewCategoryDescriptionOnChange = function () {
            reportService.currentReport.reportArgs.Ds1_Arg2.CustomKey = vm.currentFilter.viewCategoryDescription ? 'viewCategoryDescription' : null;
        };

        // entity to validate
        vm.categoryReportFormClientData = foundationService.dataContext.categoryReportFormClientDatas.add({
            Id: NbsGuidManager.newGuid()
        });

        // set title of current controller in report definition detail view
        navigationService.setControllerTitle();

        // set report service
        reportService.currentReport.reportArgs = {
            Ds1_Arg1: { CategoryId: null },
            Ds1_Arg2: {//QueryOptions
                CustomKey: null
            }
        };
        reportService.currentReport.reportDefinition = null;

        var selectionParams = { destModalKey: 'category', isMultiselect: false, systemCategoryCode: systemCategoryCode, viewKind: 'folder' };
        vm.nbsSelectionTreeviewWithModalCategory = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategory.setOnSelectFnCallback(function () {
            if (vm.nbsSelectionTreeviewWithModalCategory.selectedIds) {
                reportService.currentReport.reportArgs.Ds1_Arg1.CategoryId = vm.nbsSelectionTreeviewWithModalCategory.selectedIds[0];
            } else {
                reportService.currentReport.reportArgs.Ds1_Arg1.CategoryId = null;
            }

            vm.categoryReportFormClientData.CategoryId = reportService.currentReport.reportArgs.Ds1_Arg1.CategoryId;
        });

        loadData(true);

        function loadData(forceReload) {
            reportService.currentReport.formEntityToValidate = vm.categoryReportFormClientData;
        }        
    };

    categoriesListReportForm.$inject = injectParams;
    categoriesListReportForm.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('categoriesListReportForm', categoriesListReportForm);
});
