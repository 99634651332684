﻿'use strict';
define([], function () {

    var injectParams = ['$q', 'foundationService'];
    var nbsEjTreeViewCategoryComponent = function ($q, foundationService) {

        var vm = this;
        var pDataContext = null;
        var pDatasource = null;
        var pEjTreeView = null;
        var ID = breeze.core.getUuid();
        var pSystemCategoryCode = null;
        var ExpandedNodes = [];
        var pSelectedNode = null;
        var pAllowDragAndDrop = false;
        var categoryIdDragStart = null;
        var categoriesChildren = [];

        vm.$onChanges = function (changes) {
            if (changes.nbsDataContext && changes.nbsDataContext.currentValue) {
                pDataContext = changes.nbsDataContext.currentValue;
                pSystemCategoryCode = pDataContext.systemCategoryCode;
                pAllowDragAndDrop = pDataContext.allowDragAndDrop === true ? true : false;

                loadData()
                    .then(() => {

                    });
            }
            if (changes.nbsRefresh && changes.nbsRefresh.currentValue) {
                loadData()
                    .then(() => {
                        //createTreeView();
                    });
            }
        };

        function createTreeView() {
            var treeView = {
                allowDragAndDrop: pAllowDragAndDrop,
                fields: {
                    dataSource: pDatasource,
                    id: "CategoryId",
                    parentId: "ParentCategoryId",
                    text: "CategoryTextUI",
                    expanded: "IsExpanded",
                    selected: "IsSelected"
                },
                nodeExpand: function (args) {
                    var id = Number(args.id);
                    if (!Lazy(ExpandedNodes).contains(id)) {
                        ExpandedNodes.push(id);
                    }
                },
                nodeCollapse: function (args) {
                    var id = Number(args.id);
                    ExpandedNodes = Lazy(ExpandedNodes).filter((item) => { return item !== id; }).toArray();
                },
                nodeDragStart: function (args) {
                    if (foundationService.hasChanges()) {
                        raiseNodeDragStart(args, true, 'CategoriesPendingChanges');
                        args.cancel = true;
                        return;
                    }

                    categoryIdDragStart = Number(args.target[0].id);
                    categoriesChildren = Lazy(pDatasource).where({ ParentCategoryId: categoryIdDragStart }).toArray();
                    var categoryDragStart = Lazy(pDatasource).findWhere({ CategoryId: categoryIdDragStart });
                    if (!categoryDragStart) {
                        raiseNodeDragStart(args, true, null);
                        args.cancel = true;
                        return;
                    }

                    if (categoryDragStart.IsSystemCategory) {
                        raiseNodeDragStart(args, true, null);
                        args.cancel = true;
                        return;
                    }

                    raiseNodeDragStart(args, false, null);
                },
                nodeDragStop: function (args) {
                    if (foundationService.hasChanges()) {
                        raiseNodeDragStop(args, true, 'CategoriesPendingChanges');
                        args.cancel = true;
                        return;
                    }

                    var categoryIdDragStop = categoryIdDragStop = Number(args.target[0].id);
                    if (categoryIdDragStop === categoryIdDragStart) {
                        raiseNodeDragStop(args, true, null);
                        args.cancel = true;
                        return;
                    }

                    var categoryDragStop = Lazy(pDatasource).findWhere({ CategoryId: categoryIdDragStop });
                    if (!categoryDragStop) {
                        raiseNodeDragStop(args, true, null);
                        args.cancel = true;
                        return;
                    }

                    if (categoryDragStop.CategoryKindId === foundationService.dataContext.consts.CATEGORYKINDS.category) {
                        raiseNodeDragStop(args, true, 'OperationNotPermitted');
                        args.cancel = true;
                        return;
                    }

                    var dragStopIsChildren = Lazy(categoriesChildren).where({ CategoryId: categoryIdDragStop }).some();
                    if (dragStopIsChildren) {
                        raiseNodeDragStop(args, true, 'OperationNotPermitted');
                        args.cancel = true;
                        return;
                    }

                    raiseNodeDragStop(args, false, null, categoryIdDragStart, categoryIdDragStop);                    
                },
                nodeSelect: function (args) {
                    pSelectedNode = Lazy(pDatasource).findWhere({ CategoryId: Number(args.id) });
                    vm.nodeSelect({
                        args: args,
                        node: pSelectedNode
                    });
                }
            };

            var container = $("#ejTreeviewTag");
            $(container).attr('id', ID);
            $("#" + ID).ejTreeView(treeView);
            pEjTreeView = $("#" + ID).data('ejTreeView');
        }

        function raiseNodeDragStart(args, hasError, messageKey, idStart) {
            vm.nodeDragStart({
                args: args,
                data: { hasError: hasError, messageKey: messageKey }
            });
        }

        function raiseNodeDragStop(args, hasError, messageKey, idStart, idStop) {
            vm.nodeDragStop({
                args: args,
                data: { hasError: hasError, messageKey: messageKey, idStart: idStart, idStop: idStop }
            });
        }

        function loadData() {
            var defer = $q.defer();

            foundationService.getCategories({ SystemCategoryCode: pSystemCategoryCode }, true)
                .then(function (data) {
                    pDatasource = data.results;
                    parseDatasource(pDatasource);

                    createTreeView();

                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function parseDatasource(ds) {
            Lazy(ds)
                .each((item) => {
                    item.imageUrl = "/images/TreeView/transparent.gif";

                    if (item.IsPathStart) {
                        item.imageAttribute = { class: 'nbs-treedefault' };
                    } else if (item.CategoryKindId === 1) {
                        if (item.IsSystemCategory) {
                            item.imageAttribute = { class: 'nbs-treefolder-remove' };
                        } else {
                            item.imageAttribute = { class: 'nbs-treefolder' };
                        }
                    } else {
                        if (item.IsSystemCategory) {
                            item.imageAttribute = { class: 'nbs-treefile-remove' };
                        } else {
                            item.imageAttribute = { class: 'nbs-treefile' };
                        }
                    }

                    item.IsSelected = pSelectedNode && item.CategoryId === pSelectedNode.CategoryId;
                    item.IsExpanded = Lazy(ExpandedNodes).contains(item.CategoryId);
                    item.CategoryTextUI = Nembus.Common.Library.converter.decodeHtml(item.CategoryCode + ' - ' + item.CategoryName);
                });
        }
    };

    nbsEjTreeViewCategoryComponent.$inject = injectParams;
    angular.module('nbs.rds.module').component('nbsEjTreeViewCategory', {
        templateUrl: '/rds/common/components/nbsEjTreeViewCategoryComponent/_?c=' + window.codeCache(),
        controller: nbsEjTreeViewCategoryComponent,
        controllerAs: 'vm',
        bindings: {
            nbsDataContext: "<",
            nbsRefresh: "<",
            nodeSelect: "&",
            nodeDragStart: "&",
            nodeDragStop: "&",
            uiContext: "<"
        }
    });
});

