﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'rbacService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var userProfilesList = function ($q, $scope, $timeout, $injector, rbacService, navigationService, checkStateChangeService, contextService) {
        // The controller's API to which the view binds

        var vm = this;
        nbsVm.call(vm, rbacService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return userProfileSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, rbacService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
        var viewKind = navigationService.stateParams().viewKind;
        var appStoreCode = navigationService.stateParams().appStoreCode;
        var userProfileSelected = null;

        vm.currentProfiles = [];

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getUserProfiles(true, virtualCompanyId));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.currentProfiles = results[0];

                    var nbsGridDatacontext = new NbsGrid(vm.translate);
                    nbsGridDatacontext.configureGrid('userprofile', null);
                    nbsGridDatacontext.viewCommonBar();
                    nbsGridDatacontext.setDatasource(vm.currentProfiles, false);
                    vm.nbsGridDatacontext = nbsGridDatacontext;
                });
        }

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.userprofiledetail", { 'userProfileId': args.data.UserProfileId, 'viewKind': viewKind, 'appStoreCode': appStoreCode });
        };

        vm.newAction = function () {
            navigationService.navigateTo("app.nav.userprofiledetail", { 'userProfileId': null, 'viewKind': viewKind, 'appStoreCode': appStoreCode });
        };

        vm.refreshAction = function () {
            getUserProfiles(true, virtualCompanyId)
                .then((results) => {
                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: results
                    }
                });
        };

        vm.gridSelection = function (args) {
            $timeout(() => {
                userProfileSelected = args.data;

                if (userProfileSelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, !userProfileSelected.IsSystemProfile);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                }

                ribbon.refreshStatusButtons();
            });
        };

        vm.deleteAction = function () {
            if (!userProfileSelected) {
                return;
            }

            rbacService.deleteEntity(userProfileSelected);
            vm.submitAction()
                .then(function (data) {
                    vm.refreshAction();
                });
        };

        function getUserProfiles(forceReload, virtualCompanyId) {
            var filter = { VirtualCompanyId: virtualCompanyId };
            var defer = $q.defer();

            rbacService.getUserProfiles(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    userProfilesList.$inject = injectParams;
    userProfilesList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rbac.module').controller('userProfilesList', userProfilesList);
});
