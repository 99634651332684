import { InjectionToken } from "@angular/core";


export const FoundationService = new InjectionToken("FoundationService");

export function foundationServiceFactory(i: any) {
    return i.get("foundationService");
}

export const foundationServiceProvider = {
    provide: FoundationService,
    useFactory: foundationServiceFactory,
    deps: ['$injector']
};
