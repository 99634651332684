﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$injector', '$timeout', 'foundationService', 'navigationService', 'contextService'];
    function vhrSelectionModal($q, $injector, $timeout, foundationService, navigationService, contextService) {
        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);

        // modal parameters
        var pParams = navigationService.getModalData('vhrSelectionModal', vm.viewMode).params;
        pParams = pParams ? pParams : navigationService.stateParams();
        var selectableEntityTypes = pParams.selectableEntities ? pParams.selectableEntities : [];
        if (!Lazy(selectableEntityTypes).some()) {
            selectableEntityTypes = ['VirtualEquipment', 'HrBucket', 'Target'];
        }

        if (pParams.entityMode !== 'AssetTree' && pParams.entityMode !== 'Target') {
            console.error("vhrSelectionModal entity '" + pParams.entityMode.toString() + "' not managed");
            return;
        }

        var currentSelected = [];
        
        switch (pParams.translateKey) {
            case 'VirtualEquipment': vm.modalTitle = vm.translate('VhrSelectionModal.VhrSelectionModalVirtualEquipment'); break;
            case 'AssetTree': vm.modalTitle = vm.translate('VhrSelectionModal.VhrSelectionModalVirtualEquipment'); break;
            case 'Target': vm.modalTitle = vm.translate('VhrSelectionModal.VhrSelectionModalTarget'); break;
        }


        vm.buttonOkDisabled = false;

        // set treegrid component datacontext
        vm.assetTreeGridDataContext = {
            preloadData: true,
            entityMode: pParams.entityMode,
            hasHierarchyConfig: vm.hasHierarchyConfig,
            translateKey: pParams.translateKey,
            selectedIds: pParams.selectedIds,
            viewUiSelector: true,
            initData: {
                grid: {
                    params: { isMultiselect: pParams.isMultiselect },
                },
                modeUi: 'Grid'
            }
        };

        vm.modalOk = function () {
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalOk);
            navigationService.closeCurrentModal(modalResult);
        };

        vm.modalCancel = function () {
            navigationService.closeCurrentModal();
        };

        vm.onGridSelection = function (items) {
            currentSelected = items;

            var isPermittedSelection = true;
            Lazy(currentSelected)
                .each((item) => {
                    if (!Lazy(selectableEntityTypes).contains(item.nbs_discriminator)) {
                        isPermittedSelection = false;
                    }
                });

            $timeout(() => {
                vm.buttonOkDisabled = !isPermittedSelection;
            }, 0);
        };

        function getModalResult(dialogResult) {
            //Lazy(currentSelected)
            //    .each((curritem) => {
            //        item.nbs_id = curritem.nbs_id;
            //        item.nbs_code = curritem.nbs_code;
            //        item.nbs_name = curritem.nbs_name;
            //    });

            var modalResult = new navigationService.modalResult();
            modalResult.data = Lazy(currentSelected).toArray();
            modalResult.selectedValues = Lazy(currentSelected).pluck('nbs_id').toArray();
            modalResult.hasSelection = Lazy(currentSelected).some();
            modalResult.dialogResult = dialogResult;
            return modalResult;
        }
    }

    vhrSelectionModal.$inject = injectParams;
    vhrSelectionModal.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('vhrSelectionModal', vhrSelectionModal);
});
