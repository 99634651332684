﻿'use strict';
define([], function () {

    var injectParams = ['$q', '$timeout', 'reportService'];
    var nbsReportBarComponent = function ($q, $timeout, reportService) {
        var vm = this;
        var privateUIData = null;
        var privateDatacontext = null;
        var defaultKeys = null;
        var UIkey = null;
        var reportHeaderTypeParsed = null;
        var reportEngineTypeParsed = null;
        var reportDefinitionStatusesParsed = null;
        var reportDefinitionDisplayChannel = null;
        var nbsUiOptionParser = null;

        vm.privateFormSummary = {
            dataContext: {},
            isVisible: false
        };

        vm.privateComponentsStatus = {};
        vm.nbsDataPrivate = {};

        vm.$onInit = function () {

        };

        vm.$onChanges = function (changes) {
            if (changes.nbsDataContext && changes.nbsDataContext.currentValue) {
                nbsUiOptionParser = new NbsUiOptionParser(vm.uiContext.translate);

                privateDatacontext = changes.nbsDataContext.currentValue;

                // set default selections
                defaultKeys = privateDatacontext.defaultKeys;

                UIkey = privateDatacontext.UIkey;
                vm.privateFormSummary = privateDatacontext.formSummary;

                reportHeaderTypeParsed = Nembus.Common.Library.util.parseEnum(reportService.dataContext.enumTypes.ReportHeaderType, 'ReportDefinition', vm.uiContext.translate);
                reportEngineTypeParsed = Nembus.Common.Library.util.parseEnum(reportService.dataContext.enumTypes.ReportEngineType, 'ReportDefinition', vm.uiContext.translate);
                reportDefinitionStatusesParsed = Nembus.Common.Library.util.parseEnum(reportService.dataContext.enumTypes.ReportDefinitionStatus, null, vm.uiContext.translate);
                reportDefinitionDisplayChannel = Nembus.Common.Library.util.parseEnum(reportService.dataContext.enumTypes.ReportDefinitionDisplayChannel, null, vm.uiContext.translate);

                // Start of execution workflow of load data bar components
                initViewTitle();
            }
        };

        function initViewTitle() {
            var cols = [];
            var defaultStartData = new NbsUiData();

            defaultStartData.setColumns(cols);
            defaultStartData.setFilterList(getFilterList());

            var sortField = 'ReportDefinitionReferenceCode';
            var sortDirection = false; // descending
            defaultStartData.setSortingField(sortField, sortDirection);

            vm.initViewTitle = defaultStartData.startData;
        }

        //#region hook from components
        vm.onViewTitleSelectionEvent = function (result) {
            privateUIData = result.uiData;
            setComponents(privateUIData);

            setBarDatasources()
                .then(function () {
                    barOnChanges(privateUIData);
                });
        };
        vm.onUiChanges = function (result) {
            privateUIData.columns = result.uiData.columns;
            privateUIData.grouping = result.uiData.grouping;
            privateUIData.sorting = result.uiData.sorting;
            privateUIData.filters.freeText = result.uiData.filters.freeText;

            refreshViewTitleDataContext(privateUIData);

            vm.onGridUiChangesEvent({ result: privateUIData});
        };
        vm.onFilterChanges = function (result) {
            privateUIData.filterList = result.filters.filterList;            

            refreshViewTitleDataContext(privateUIData);
            setStatusComponents(privateUIData.filterList);

            setBarDatasources()
                .then(function () {
                    barOnChanges(privateUIData);
                });
        };
        vm.onBooleanIsSystem = function (value) {
            privateUIData.filters.issystem = value;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdReportHeaderType = function (options) {
            privateUIData.filters.reportheadertypes = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdReportDefinitionStatus = function (options) {
            privateUIData.filters.reportdefinitionstatuses = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        
        // refresh current preference uiData
        function refreshViewTitleDataContext(uiDataParam) {
            vm.viewTitleDataContext = Nembus.Common.Library.component.getDataContextViewTitle(uiDataParam);
        }

        function setBarDatasources() {
            var defer = $q.defer();
            var funcsPromises = [];

            if (vm.privateComponentsStatus.reportheadertypes.isVisible) {
                funcsPromises.push(getReportHeaderTypes());
            }
            if (vm.privateComponentsStatus.reportdefinitionstatuses.isVisible) {
                funcsPromises.push(getReportDefinitionStatuses());
            }

            $q.all(funcsPromises)
                .then(function () {

                    defer.resolve(true);
                });

            return defer.promise;
        }

        function getFilterList() {
            var uiDataFilters = [];
            uiDataFilters.push(new NbsUiDataFilter({ code: 'issystem', name: 'ReportDefinition.IsSystem' }));
            uiDataFilters.push(new NbsUiDataFilter({ code: 'reportheadertypes', name: 'ReportDefinition.ReportHeaderType' }));

            if (UIkey === 'reportEntity') {
                uiDataFilters.push(new NbsUiDataFilter({ code: 'reportdefinitionstatuses', name: 'ReportDefinition.ReportDefinitionStatus' }));
            }

            return uiDataFilters;
        }

        function getReportHeaderTypes() {
            var reportHeaderTypes = [];
            var defer = $q.defer();
            reportHeaderTypes = reportHeaderTypeParsed.list.translated;
            vm.nbsDataPrivate.reportheadertypes = nbsUiOptionParser.getUiOptionsByEnum(reportHeaderTypes);
            defer.resolve(true);
            return defer.promise;
        }

        function getReportDefinitionStatuses() {
            var defer = $q.defer();
            vm.nbsDataPrivate.reportdefinitionstatuses = nbsUiOptionParser.getUiOptionsByEnum(reportDefinitionStatusesParsed.list.translated);
            defer.resolve(true);
            return defer.promise;
        }

        function setComponents(uiDataParam) {
            setCommonGridBarDataContext(uiDataParam);
            setSelectionComponents(uiDataParam);
            setStatusComponents(uiDataParam.filterList);
            vm.onGridUiChangesEvent({ result: privateUIData });
        }

        function setCommonGridBarDataContext(uiDataParam) {
            vm.commonGridBarDataContext = Nembus.Common.Library.component.getDataContextCommonGridBar(uiDataParam);
            vm.commonBarDatasource = privateDatacontext.commonBarDatasource
        }

        function setSelectionComponents(uiDataParam) {
            vm.nbsDataPrivate.issystemchecked = uiDataParam.filters.issystem;
            vm.nbsDataPrivate.reportheadertypesselected = uiDataParam.filters.reportheadertypes ? uiDataParam.filters.reportheadertypes : [];
            vm.nbsDataPrivate.reportdefinitionstatusesselected = uiDataParam.filters.reportdefinitionstatuses ? uiDataParam.filters.reportdefinitionstatuses : [];
        }

        function setStatusComponents(filterList) {
            vm.privateComponentsStatus.issystem = Nembus.Common.Library.component.getStatus('issystem', filterList);
            vm.privateComponentsStatus.reportheadertypes = Nembus.Common.Library.component.getStatus('reportheadertypes', filterList);
            vm.privateComponentsStatus.reportdefinitionstatuses = Nembus.Common.Library.component.getStatus('reportdefinitionstatuses', filterList);
        }

        function barOnChanges(uiDataParam) {
            var isSystem = null;
            var selectedReportHeaderTypeIds = [];
            var selectedReportDefinitionStatusIds = [];

            if (vm.privateComponentsStatus.issystem.isVisible) {
                isSystem = uiDataParam.filters.issystem;
            }
            if (vm.privateComponentsStatus.reportheadertypes.isVisible) {
                selectedReportHeaderTypeIds = Lazy(uiDataParam.filters.reportheadertypes).pluck('optionValue').toArray();
            }
            if (vm.privateComponentsStatus.reportdefinitionstatuses.isVisible) {
                selectedReportDefinitionStatusIds = Lazy(uiDataParam.filters.reportdefinitionstatuses).pluck('optionValue').toArray();
            }

            $timeout(() => {
                vm.onBarChanges({
                    result: {
                        reportDefinitionFilter: {
                            //ReportDefinitionStatusId: reportDefinitionStatusesParsed.obj.normal.name_value.Enabled,
                            ReportDefinitionDisplayChannelId: reportDefinitionDisplayChannel.obj.normal.name_value.ReportList,
                            ReportHeaderTypeIds: selectedReportHeaderTypeIds,
                            ReportDefinitionStatusIds: selectedReportDefinitionStatusIds,
                            IsSystem: isSystem
                        }
                    }
                });
            });
        }
        //#endregion 
    };

    nbsReportBarComponent.$inject = injectParams;
    angular.module('nbs.rds.module').component('nbsReportBar', {
        templateUrl: '/rds/reports/components/nbsReportBarComponent/_?c=' + window.codeCache(),
        controller: nbsReportBarComponent,
        controllerAs: 'vm',
        bindings: {
            onBarChanges: '&',
            onGridUiChangesEvent: '&',

            nbsDataContext: "<", //e.g. {vhreventtypes:[NbsUiOption,NbsUiOption],vhreventgroups:[],status etc..}
            uiContext: "<"
        }
    });
});

