﻿'use strict';
define(['../../common/services/nbs-service'], function (nbsService) {

    var injectParams = ['$http', '$q', '$timeout', 'breezeDataLayer'];

    var rbacService = function ($http, $q, $timeout, breezeDataLayer) {

        var dataLayer = breezeDataLayer.Initialize('rbac', true);
        var manager = dataLayer.GetManager();
        var ejDataManager = dataLayer.GetEjDataManager();
        var entityTypes = {
            LastAccessDto: 'LastAccessDto',
            AppUser: 'AppUser',
            AppUserRequest: 'AppUserRequest',
            UserProfile: 'UserProfile',
            Category: 'Category',
            CategoryDto: 'CategoryDto',
            Company: 'Company',
            CompanyDto: 'CompanyDto',
            VirtualCompany: 'VirtualCompany',
            Language: 'Language',

            DrbacProfileM2MCategoryDto: 'DrbacProfileM2MCategoryDto',

            GlobalUserDto: 'GlobalUserDto',
            ContextUserDto: 'ContextUserDto',

            MyAccountCpDto: 'MyAccountCpDto',
            MyAccountCpDtoClientData: 'MyAccountCpDtoClientData'
        };
        var me = this;

        nbsService.call(this, manager, $q, $timeout);

        var emailRegExpression = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}/;
        var emailRegExprValidator = breeze.Validator.makeRegExpValidator('emailRegExprValidator', emailRegExpression);
        var usernameRegExprValidator = breeze.Validator.makeRegExpValidator('usernameRegExprValidator', /^\S+$/);
        var passwordRegExprValidator = breeze.Validator.makeRegExpValidator("passwordRegExprValidator", /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&=!]).*$/);
        // Register it with the breeze Validator class.
        breeze.Validator.register(emailRegExprValidator);
        breeze.Validator.register(usernameRegExprValidator);
        breeze.Validator.register(passwordRegExprValidator);

        //#region ClientData entities
        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.MyAccountCpDtoClientData,
                dataProperties: {
                    Id: { type: breeze.DataType.Guid },
                    CurrentPassword: { type: breeze.DataType.String },
                    NewPassword: { type: breeze.DataType.String },
                    ConfirmNewPassword: { type: breeze.DataType.String }
                }
            },
            'Nembus.Domain'
        );
        var myAccountCpDtoClientData = manager.metadataStore.getEntityType(entityTypes.MyAccountCpDtoClientData);
        myAccountCpDtoClientData.getProperty("NewPassword").validators.push(passwordRegExprValidator);
        myAccountCpDtoClientData.getProperty("ConfirmNewPassword").validators.push(passwordRegExprValidator);
        myAccountCpDtoClientData.getProperty("CurrentPassword").validators.push(new breeze.Validator("changePasswordCurrentPasswordValidator", Nembus.Common.Library.validators.requiredFieldValidator));
        myAccountCpDtoClientData.getProperty("NewPassword").validators.push(new breeze.Validator("changePasswordNewPasswordValidator", Nembus.Common.Library.validators.requiredFieldValidator));
        myAccountCpDtoClientData.getProperty("ConfirmNewPassword").validators.push(new breeze.Validator("changePasswordConfirmNewPasswordValidator", Nembus.Common.Library.validators.requiredFieldValidator));
        myAccountCpDtoClientData.getProperty("ConfirmNewPassword").validators.push(new breeze.Validator("newPwdConfirmPwdValidator", (value, context) => {
            return context.entity.NewPassword === context.entity.ConfirmNewPassword;
        }));
        //#endregion

        // ADD Custom validator for AppUserRequest
        var appUserRequestType = manager.metadataStore.getEntityType(entityTypes.AppUserRequest);
        var APPUSER_ADD = 1;
        var APPUSER_UPDATECOMMONDATA = 3;
        function isValidEmail(value) {
            var re = emailRegExpression;
            return (re.test(value));
        }
        function appUserRequestContactValueValidatorFn(value, context) {
            // This validator only validates US Zip Codes.
            if (context.entity.IsProgrammaticAccess === false) {
                if (context.entity.AppUserRequestTypeId === APPUSER_UPDATECOMMONDATA || context.entity.AppUserRequestTypeId === APPUSER_ADD) {
                    return isValidEmail(value);
                }
            }
            return true;
        }
        appUserRequestType.getProperty("ContactValue").validators.push(new breeze.Validator("emailAddress", appUserRequestContactValueValidatorFn));
        //appUserRequestType.getProperty("ContactValue").validators.push(emailRegExprValidator);
        appUserRequestType.getProperty("Username").validators.push(usernameRegExprValidator);



        this.dataContext = {
            lastAccessDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.LastAccessDto),
            languages: dataLayer.setContextMethods(manager, nbsService, entityTypes.Language),
            contextUserDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.ContextUserDto),

            appUserRequests: dataLayer.setContextMethods(manager, nbsService, entityTypes.AppUserRequest),
            appUsersCentralUser: dataLayer.setContextMethods(manager, nbsService, entityTypes.AppUser),
            userProfiles: dataLayer.setContextMethods(manager, nbsService, entityTypes.UserProfile),
            categories: dataLayer.setContextMethods(manager, nbsService, entityTypes.Category),
            companies: dataLayer.setContextMethods(manager, nbsService, entityTypes.Company),
            virtualcompanies: dataLayer.setContextMethods(manager, nbsService, entityTypes.VirtualCompany),
            drbacProfileM2MCategoryDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.DrbacProfileM2MCategoryDto),

            myAccountCpDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.MyAccountCpDto),
            myAccountCpDtoClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.MyAccountCpDtoClientData),

            enumTypes: {
                UserLifeStatus: dataLayer.GetEnumType('UserLifeStatus'),
                AppUserRequestType: dataLayer.GetEnumType('AppUserRequestType'),
            }
        };

        this.getLastAccessDto = function (filter, forceReload) {
            var params = { key: filter.XCentralUserId, dataContext: this.dataContext.lastAccessDtos };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "LastAccessDto");
        };

        this.getLanguages = function (filter, forceReload) {
            var params = { entityType: entityTypes.Language, cache: this.dataContext.languages.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "Language");
        };

        this.getCurrentContextAppUsers = function (filter, forceReload) {
            var defer = $q.defer();
            var params = { entityType: entityTypes.ContextUserDto, cache: this.dataContext.contextUserDtos.get() };

            dataLayer.GetDataListPromise(params, filter, forceReload, "CurrentContextAppUsers")
                .then(function (data) {
                    Nembus.Common.Library.util.addDisplayNameToUsersList(data.results);
                    defer.resolve(data);
                });

            return defer.promise;
        };

        this.getCurrentContextAppUsersForSelection = function (filter, forceReload) {
            var defer = $q.defer();
            var params = { entityType: entityTypes.ContextUserDto, cache: this.dataContext.contextUserDtos.get() };

            dataLayer.GetDataListPromise(params, filter, forceReload, "CurrentContextAppUsersForSelection")
                .then(function (data) {
                    Nembus.Common.Library.util.addDisplayNameToUsersList(data.results);
                    defer.resolve(data);
                });

            return defer.promise;
        };


        // categories x profiles
        this.getDrbacProfileM2MCategoryDto = function (filter, forceReload) {
            var params = { entityType: entityTypes.DrbacProfileM2MCategoryDto, cache: this.dataContext.drbacProfileM2MCategoryDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "DrbacProfileM2MCategoryDtos");
        };

        this.getAppUser = function (filter, forceReload) {
            var params = { key: filter.AppUserId, dataContext: this.dataContext.appUsers };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "AppUser");
        };

        this.getAppUserRequests = function (filter, forceReload) {
            var params = { entityType: entityTypes.AppUserRequest, cache: this.dataContext.appUserRequests.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "AppUserRequest");
        };


        this.getUserProfilesCrossContext = function (filter, forceReload) {
            var params = { entityType: entityTypes.UserProfile, cache: this.dataContext.userProfiles.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "UserProfilesCrossContext");
        };
        this.getUserProfiles = function (filter, forceReload) {
            var params = { entityType: entityTypes.UserProfile, cache: this.dataContext.userProfiles.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "UserProfiles");
        };
        this.getUserProfile = function (filter, forceReload) {
            var params = { key: filter.UserProfileId, dataContext: this.dataContext.userProfiles };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "UserProfile");
        };

        this.getVirtualCompaniesForUser = function (filter, forceReload) {
            var params = { entityType: entityTypes.VirtualCompany, cache: this.dataContext.virtualcompanies.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VirtualCompaniesForUser");
        };

        this.createQuery = function (entityName) {
            return dataLayer.CreateQuery(entityName);
        };

        this.handleSaveValidationError = function (error) {
            var message = "Not saved due to validation error";
            try { // fish out the first error
                var firstErr = error.entityErrors[0];
                message += ": " + firstErr.errorMessage;
            } catch (e) { /* eat it for now */ }
            return message;
        };

        this.removePropertyChangeHandler = function (handler) {
            // Actually removes any 'entityChanged' event handler
            return manager.entityChanged.unsubscribe(handler);
        };

        this.changeMyPassword = function (newPassword, currentPassword) {
            var query = dataLayer.InvokeQuery("ChangeMyPassword",
                {
                    CurrentPassword: currentPassword,
                    NewPassword: newPassword
                });
            return manager.executeQuery(query);
        };

        this.renewMyTwoFactorSecretCode = function (currentUserDto) {
            var query = dataLayer.InvokeQuery("RenewMyTwoFactorSecretCode", currentUserDto);
            return manager.executeQuery(query);
        };

        this.renewTwoFactorSecretCode = function (currentUserDto) {
            var query = dataLayer.InvokeQuery("RenewTwoFactorSecretCode", currentUserDto);
            return manager.executeQuery(query);
        };

        this.bindAppUser = function (bindUserDto) {
            var query = dataLayer.InvokeQuery("BindAppUser", bindUserDto);
            return manager.executeQuery(query);
        };

        this.unbindAppUser = function (bindUserDto) {
            var query = dataLayer.InvokeQuery("UnBindAppUser", bindUserDto);
            return manager.executeQuery(query);
        };

        this.adminResetSsoPasswordBulk = function (filter) {
            var query = dataLayer.InvokeQuery("AdminResetSsoPasswordBulk", filter);
            return manager.executeQuery(query);
        };

        this.adminResetPasswordBulk = function (filter) {
            var query = dataLayer.InvokeQuery("AdminResetPasswordBulk", filter);
            return manager.executeQuery(query);
        };

        this.adminResetProgrammaticAccess = function (filter) {
            var query = dataLayer.InvokeQuery("AdminResetProgrammaticAccess", filter);
            return manager.executeQuery(query);
        };

        this.resetMySsoPassword = function (filter) {
            var query = dataLayer.InvokeQuery("AdminResetSsoPasswordBulk", filter);
            return manager.executeQuery(query);
        };

        //new account email
        this.adminNewAccountResetPasswordBulk = function (filter) {
            var query = dataLayer.InvokeQuery("AdminNewAccountResetPasswordBulk", filter);
            return manager.executeQuery(query);
        };

        //verify external useres
        this.ssoVerifyUsersBulk = function (filter) {
            var query = dataLayer.InvokeQuery("SsoVerifyUsersBulk", filter);
            return manager.executeQuery(query);
        };
        this.supportVerifyUsersBulk = function (filter) {
            var query = dataLayer.InvokeQuery("SupportVerifyUsersBulk", filter);
            return manager.executeQuery(query);
        };

        // GlobalUserDto
        this.getGlobalUserDtosPagedDm = function () {
            return ejDataManager.getEjManager('GlobalUserDtosPaged');
        };
        this.getGlobalUserDtosPagedDmQuery = function () {
            var dmObj = {};
            dmObj.dm = ejDataManager.getEjManager('');
            dmObj.query = ej.Query().from('GlobalUserDtosPaged');
            return dmObj;
        };

        // ContextUserDto
        this.getContextUserDtosPagedDm = function () {
            return ejDataManager.getEjManager('ContextUserDtosPaged');
        };
        this.getContextUserDtosPagedDmQuery = function () {
            var dmObj = {};
            dmObj.dm = ejDataManager.getEjManager('');
            dmObj.query = ej.Query().from('ContextUserDtosPaged');
            return dmObj;
        };

        //#region DataManager
        this.getContextUserDataManager = function (supportData, filter, dmObj) {
            if (dmObj) {
                dmObj.complexParams = [{ filter: filter }];
                return dmObj;
            }

            dmObj = this.getContextUserDtosPagedDmQuery();
            dmObj.dmgrid = this.getContextUserDtosPagedDm();
            var ejDataManager = dmObj.dmgrid;

            ejDataManager.complexParams.push({ filter: filter });
            ejDataManager.fieldNameReplacer = {};
            // properties inserte are calculated before query is launched in breezeDataLayer service
            ejDataManager.onWhereCustomPredicate = {};
            ejDataManager.processResponseData = function (data) {
                if (data) {
                    dmObj.dm.currentViewChildGridData = [];

                    data.results = me.getAttachedEntities(data.results);
                    Lazy(data.results)
                        .each((item) => {
                            if (!item.cardSupport) {
                                item.cardSupport = {
                                    translations: {}
                                };
                            }

                            // format custom fields for grid cards
                            item.cardSupport.translations.enabled = item.IsEnabled ? supportData.translate('Yes') : supportData.translate('No');
                            item.cardSupport.translations.isProgrammaticAccess = item.IsProgrammaticAccess ? supportData.translate('Yes') : supportData.translate('No');
                        });
                    return data.results;
                }
            };
            ejDataManager.freeSearchFields = ['DisplayName', 'Username'];
            ejDataManager.freeSearchFieldsNumber = ['XCentralUserId'];

            dmObj.dm.onWhereCustomPredicate = ejDataManager.onWhereCustomPredicate;
            dmObj.dm.fieldNameReplacer = ejDataManager.fieldNameReplacer;
            dmObj.dm.complexParams = ejDataManager.complexParams;
            dmObj.dm.processResponseData = ejDataManager.processResponseData;
            return dmObj;
        }

        this.getGlobalUserDataManager = function (supportData, filter, dmObj) {
            if (dmObj) {
                dmObj.complexParams = [{ filter: filter }];
                return dmObj;
            }

            dmObj = this.getGlobalUserDtosPagedDmQuery();
            dmObj.dmgrid = this.getGlobalUserDtosPagedDm();
            var ejDataManager = dmObj.dmgrid;

            ejDataManager.complexParams.push({ filter: filter });
            ejDataManager.fieldNameReplacer = {};
            // properties inserte are calculated before query is launched in breezeDataLayer service
            ejDataManager.onWhereCustomPredicate = {};
            ejDataManager.processResponseData = function (data) {
                if (data) {
                    dmObj.dm.currentViewChildGridData = [];

                    data.results = me.getAttachedEntities(data.results);
                    Lazy(data.results)
                        .each((item) => {
                            if (!item.cardSupport) {
                                item.cardSupport = {
                                    translations: {}
                                };
                            }

                            // format custom fields for grid cards
                            item.cardSupport.translations.enabled = item.IsEnabled ? supportData.translate('Yes') : supportData.translate('No');
                            item.cardSupport.translations.isProgrammaticAccess = item.IsProgrammaticAccess ? supportData.translate('Yes') : supportData.translate('No');
                        });
                    return data.results;
                }
            };
            ejDataManager.freeSearchFields = ['DisplayName', 'Username'];
            ejDataManager.freeSearchFieldsNumber = ['XCentralUserId'];

            dmObj.dm.onWhereCustomPredicate = ejDataManager.onWhereCustomPredicate;
            dmObj.dm.fieldNameReplacer = ejDataManager.fieldNameReplacer;
            dmObj.dm.complexParams = ejDataManager.complexParams;
            dmObj.dm.processResponseData = ejDataManager.processResponseData;
            return dmObj;
        }
        //#endregion
    };

    rbacService.$inject = injectParams;
    rbacService.prototype = Object.create(nbsService.prototype);
    angular.module('nbs.shared.module').service('rbacService', rbacService);
});