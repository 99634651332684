﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'navigationService', 'checkStateChangeService', 'contextService', 'fdsupportService', 'formSummaryService'];
    var fdTicketDetail = function ($q, $scope, $timeout, $injector, navigationService, checkStateChangeService, contextService, fdsupportService, formSummaryService) {

        var vm = this;
        nbsVm.call(vm, fdsupportService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, fdsupportService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var ticketId = navigationService.stateParams().id;
        vm.conversations = [];
        vm.attachments = [];

        
        var parentSetState = vm.setState;
        vm.conversation = {};
        vm.setState = function (state) {
            parentSetState(state);
            if (vm.viewContext.isEditingState) {
                vm.isNewTicket = false;
                vm.isReplyTicket = true;
                vm.conversation = fdsupportService.dataContext.fdConversations.add({
                    ticket_id: ticketId,
                    user_id: vm.ticket.requester_id
                });
            }
            if (vm.viewContext.isAddingState) {
                vm.isNewTicket = true;
                vm.isReplyTicket = false;

                vm.ticket = fdsupportService.dataContext.fdTickets.add({
 

                });
            }
  
        }

        var parents = vm.submitAction;
        vm.submitAction = function () {

            parents().then(
                function (saveResult) {
                    if (saveResult.entities.length > 0 && vm.viewContext.isAddingState) {
                        navigationService.navigateTo("app.nav.fdticketdetail", { 'id': vm.ticket.id });
                    }
                    else {
                        vm.setIsViewing();

                        vm.isNewTicket = false;
                        vm.isReplyTicket = false;
                        loadData(true);
                    }
                }
            );
        };

        if (ticketId) {
            vm.setIsViewing();

            vm.isNewTicket = false;
            vm.isReplyTicket = false;
            loadData(true);
        }
        else {
            vm.setIsAdding();
            vm.isNewTicket = true;
            vm.isReplyTicket = false;
        }

        function loadData(forceReload) {
            
            var funcsPromises = [];
            funcsPromises.push(getTicket(forceReload, ticketId));
            funcsPromises.push(getConversations(forceReload, ticketId));
            funcsPromises.push(getAttachments(forceReload, ticketId));
            $q.all(funcsPromises)
                .then(function (results) {
                    //getTicket(forceReload, ticketId);
                    //var xx = results;
                    vm.conversations = results[1];
                    vm.attachments = results[2];

                });

        }

        

        function getTicket(forceReload, ticketId) {

            vm.ticket = {};
    
            var filter = {
                TicketId: ticketId,
            };
            var defer = $q.defer();

            fdsupportService.getTicket(filter, true)
                            .then(data => {
                                vm.ticket = data.results[0];
                                vm.ticket.statusName = vm.translate('FdTicket.status_' + vm.ticket.status);
                                vm.ticket.descriptionHtml = Nembus.Common.Library.converter.decodeHtml(vm.ticket.description);
                                vm.ticket.created_at_display = Nembus.Common.Library.converter.date.formatDateTime(vm.ticket.created_at, contextService.virtualContext.cultureInfo, true);
                                defer.resolve(vm.ticket);

                                formSummaryService.clearDatasource();
                                formSummaryService.push({ value: vm.ticket.id });
                                formSummaryService.push({ value: vm.ticket.subject });
                                formSummaryService.push({ value: vm.ticket.statusName });
                                vm.formSummary = formSummaryService.getDatasource();
                            })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });
            return defer.promise;
        }

        function getConversations(forceReload, ticketId) {
            var filter = {
                TicketId: ticketId,
            };
            var defer = $q.defer();


            fdsupportService.getTicketConversations(filter, true)
                            .then(data => {
                                Lazy(data.results).each(item => {
                                    item.updated_at_display = Nembus.Common.Library.converter.date.formatDateTime(item.updated_at, contextService.virtualContext.cultureInfo, true);
                                    item.bodyHtml = Nembus.Common.Library.converter.decodeHtml(item.body);
                                });
                                
                                defer.resolve(data.results);
                            });


            return defer.promise;
        }

        function getAttachments(forceReload, ticketId) {
            var filter = {
                TicketId: ticketId,
            };
            var defer = $q.defer();


            fdsupportService.getAttachments(filter, true)
                            .then(data => {
                                Lazy(data.results).each(item => {
                                    fdsupportService.detachEntity(item);
                                    item.updated_at_display = Nembus.Common.Library.converter.date.formatDateTime(item.updated_at, contextService.virtualContext.cultureInfo, true);
                                    item.bodyHtml = Nembus.Common.Library.converter.decodeHtml(item.body);
                                });
                                Lazy(data.results).each(i => { i.attachment_url = Nembus.Common.Library.converter.decodeHtml(i.attachment_url) })
                                defer.resolve(data.results);
                            });


            return defer.promise;
        }

    };

    fdTicketDetail.$inject = injectParams;
    fdTicketDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('fdTicketDetail', fdTicketDetail);
});