﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'vhreventService', 'utilService', 'activityService', 'productionService', 'navigationService', 'checkStateChangeService', 'contextService', 'cmsService'];
    function vhrEventsSearch($q, $scope, $timeout, $injector, vhreventService, utilService, activityService, productionService, navigationService, checkStateChangeService, contextService, cmsService) {
        var vm = this;
        nbsVm.call(vm, vhreventService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, vhreventService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'search');
        ribbon.addHomeTab();
        ribbon.addSearchTab();
        ribbon.tabIndexSelected = 3
        ribbon.addTab(new ribbon.Tab("ActionPanelTabManage", [ribbon.GetVhrEventsLifeStatusSection(), ribbon.GetVhrEventsDateSection(), ribbon.GetVhrEventsExecutionSection(), ribbon.GetVhrEventsApprovalSection(), ribbon.GetVhrEventsCustomerApprovalSection(), ribbon.GetWarningsSection()]));
        vm.ribbonDatacontext = ribbon;

        vm.setIsEditingSearch();

        var viewKind = navigationService.stateParams().viewKind;
        vm.viewKindIsFull = viewKind === 'full' ? true : false;

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);

        vm.isCustomer = viewKind === 'customer';
        vm.notIsCustomer = !vm.isCustomer;

        ribbon.setRibbonStatusButton(ribbon.Buttons.New, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.AddFilter, vm.notIsCustomer);
        ribbon.setRibbonStatusButton(ribbon.Buttons.RemoveFilter, vm.notIsCustomer);
        ribbon.setRibbonStatusButton(ribbon.Buttons.ResetFilter, vm.notIsCustomer);
        ribbon.refreshStatusButtons();

        // Used to sum appStoreBw related to same TargetEntity
        // E.g. 
        // 'Tec', 'Pulizie tecniche', '536870912', '[\"VirtualEquipment\"]'
        // 'Ims', 'Manutenzioni', '1073741824', '[\"VirtualEquipment\"]'
        //     AppStoreBw for VirtualEquipment is 1610612736 (tec + ims)
        //     Used for ASSETS
        var appStoreBwSum = vm.getAppStoreBwSumByEntity(contextService, 'VirtualEquipment');
        var vhrEventTypeCode = navigationService.stateParams().vhrEventTypeCode;
        var vhrEventFixedTypeCode = navigationService.stateParams().vhrEventFixedTypeCode;
        var activityKindEnum = Nembus.Common.Library.util.parseEnum(activityService.dataContext.enumTypes.ActivityKind, null, vm.translate).obj.normal.name_value;
        var expressionTypeEnum = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.ExpressionType, null, vm.translate).obj.normal.name_value;
        var vhrEventKindEnum = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventKind, null, vm.translate).obj.normal.name_value;
        var appStores = contextService.virtualContext.AppStores;
        var avatar = 'Hrs';
        var asset = 'Ers';
        var activityKindId = activityKindEnum.Bom;
        var resourcesEnabled = [];
        var wzVhrEntityType = navigationService.stateParams().wzVhrEntityType;          //asset, avatar, bucket, ...  
        var vhrEventManualRequestType = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventManualRequestType, null, vm.translate).obj.normal.name_value;
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var searchVhrEventClientData = {};
        var vhrEventLifeStatusEnumParsed = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventLifeStatus, 'VhrEvent', vm.translate);
        var vhrEventLifeStatusEnumObj = vhrEventLifeStatusEnumParsed.obj.normal.name_value;
        var vhrEventBillingStatusEnumParsed = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventBillingStatus, 'VhrEvent', vm.translate);
        var vhrEventCreationArchetypeEnumParsed = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventCreationArchetype, 'VhrEvent', vm.translate);
        var lifeStatusCompletedId = vhrEventLifeStatusEnumParsed.obj.normal.name_value.ReportedAsFinished;
        var viewMode = navigationService.currentViewMode();

        // set grid component datacontext 
        var gridUIkey = 'search';
        if (viewKind === 'customer') {
            gridUIkey = 'searchcustomer';
        }

        // init grid
        var searchMode = 'searchasset';
        switch (vm.appVirtualResourceSelected) {
            case avatar:
                searchMode = 'searchavatar';
                break;
            case asset:
                searchMode = 'searchasset';
                break;
        }

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('vhrevent', gridUIkey, appStoreCode);
        nbsGridDatacontext.setGridIsRenderedInlineBlock();
        nbsGridDatacontext.setIsMultiselect();
        nbsGridDatacontext.allowSearching = false;
        nbsGridDatacontext.allowGrouping = false;
        nbsGridDatacontext.setDatasource([], false); // fake datasource, only to create grid
        vm.nbsGridDatacontext = nbsGridDatacontext;

        // permissions on ribbon tabs
        var vhrEventPermission = {};
        if (appStoreCode === 'ims') {
            vhrEventPermission = Nembus.Ims.Library.auth.GetVhrEventPermission(vm.havePermission, contextService.virtualContext, viewKind);
        } else if (appStoreCode === 'tec') {
            vhrEventPermission = Nembus.Tec.Library.auth.GetVhrEventPermission(vm.havePermission, contextService.virtualContext, viewKind);
        } else if (appStoreCode === 'tes') {
            vhrEventPermission = Nembus.Tes.Library.auth.GetVhrEventPermission(vm.havePermission, contextService.virtualContext, viewKind);
        }


        vm.searchTabIndex = 0;

        var selectionParams = { destModalKey: 'activity', appStoreBw: appStoreBw, activityKindId: activityKindId };
        vm.nbsSelectionGridWithModalActivity = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalActivity.setOnSelectFnCallback(function () {
            vm.currentActivityVhrCoreFilter.ActivityIds = vm.nbsSelectionGridWithModalActivity.selectedIds;
        });

        selectionParams = { destModalKey: 'category', systemCategoryCode: 'ALB_IMP', isAutocheck: false, viewKind: 'all', expandTree: false };
        vm.nbsSelectionTreeviewWithModalCategory = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategory.setOnSelectFnCallback(function () {
            vm.currentVirtualHrFilter.CategoriesId = vm.nbsSelectionTreeviewWithModalCategory.selectedIds;
        });

        selectionParams = { destModalKey: 'category', systemCategoryCode: 'ALB_IMP', isAutocheck: false, viewKind: 'all', expandTree: false };
        vm.nbsSelectionTreeviewWithModalCategoryHazards = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategoryHazards.setOnSelectFnCallback(function () {
            vm.currentVirtualHrFilter.InheritedCategoriesId = vm.nbsSelectionTreeviewWithModalCategoryHazards.selectedIds;
        });

        vm.currentVirtualPersonFilter = {};
        selectionParams = { destModalKey: 'category', systemCategoryCode: 'BSC.JOBROLES', categoryType: 'user', viewTitle: vm.translate('PersonJobData.JobRole') };
        vm.nbsSelectionTreeviewWithModalCategoryJobRoles = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategoryJobRoles.setOnSelectFnCallback(function () {
            vm.currentVirtualPersonFilter.JobRoleId = vm.nbsSelectionTreeviewWithModalCategoryJobRoles.selectedIds;
        });

        selectionParams = { destModalKey: 'category', systemCategoryCode: 'BSC.LEGALQUALIFICATIONS', categoryType: 'user', viewTitle: vm.translate('PersonJobData.LegalQualification') };
        vm.nbsSelectionTreeviewWithModalCategoryLegalQualifications = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategoryLegalQualifications.setOnSelectFnCallback(function () {
            vm.currentVirtualPersonFilter.LegalQualificationId = vm.nbsSelectionTreeviewWithModalCategoryLegalQualifications.selectedIds;
        });

        selectionParams = { destModalKey: 'category', systemCategoryCode: 'BSC.BUSINESSROLES', categoryType: 'user', viewTitle: vm.translate('PersonJobData.BusinessRole') };
        vm.nbsSelectionTreeviewWithModalCategoryBusinessRoles = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategoryBusinessRoles.setOnSelectFnCallback(function () {
            vm.currentVirtualPersonFilter.BusinessRoleId = vm.nbsSelectionTreeviewWithModalCategoryLegalQualifications.selectedIds;
        });

        selectionParams = { destModalKey: 'category', systemCategoryCode: 'BSC.SAFETYROLES', categoryType: 'user', viewTitle: vm.translate('PersonJobData.SafetyRole') };
        vm.nbsSelectionTreeviewWithModalCategorySafetyRoles = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategorySafetyRoles.setOnSelectFnCallback(function () {
            vm.currentVirtualPersonFilter.SafetyRoleId = vm.nbsSelectionTreeviewWithModalCategoryLegalQualifications.selectedIds;
        });

        selectionParams = { destModalKey: 'category', systemCategoryCode: 'BSC.PLACE', categoryType: 'user', viewTitle: vm.translate('PersonJobData.PlaceDefinedId') };
        vm.nbsSelectionTreeviewWithModalCategoryPlaceDefineds = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategoryPlaceDefineds.setOnSelectFnCallback(function () {
            vm.currentVirtualPersonFilter.PlaceDefinedId = vm.nbsSelectionTreeviewWithModalCategoryLegalQualifications.selectedIds;
        });

        selectionParams = { destModalKey: 'category', systemCategoryCode: 'BSC.PLACE', categoryType: 'user', viewTitle: vm.translate('PersonJobData.PlaceUsedId') };
        vm.nbsSelectionTreeviewWithModalCategoryPlaceUseds = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategoryPlaceUseds.setOnSelectFnCallback(function () {
            vm.currentVirtualPersonFilter.PlaceUsedId = vm.nbsSelectionTreeviewWithModalCategoryLegalQualifications.selectedIds;
        });

        selectionParams = { destModalKey: 'category', systemCategoryCode: 'BSC.CC', categoryType: 'user', viewTitle: vm.translate('PersonJobData.CcDefinedId') };
        vm.nbsSelectionTreeviewWithModalCategoryCcDefineds = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategoryCcDefineds.setOnSelectFnCallback(function () {
            vm.currentVirtualPersonFilter.CcDefinedId = vm.nbsSelectionTreeviewWithModalCategoryLegalQualifications.selectedIds;
        });

        selectionParams = { destModalKey: 'category', systemCategoryCode: 'BSC.CC', categoryType: 'user', viewTitle: vm.translate('PersonJobData.CcUsedId') };
        vm.nbsSelectionTreeviewWithModalCategoryCcUseds = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategoryCcUseds.setOnSelectFnCallback(function () {
            vm.currentVirtualPersonFilter.CcUsedId = vm.nbsSelectionTreeviewWithModalCategoryLegalQualifications.selectedIds;
        });

        selectionParams = { destModalKey: 'category', systemCategoryCode: 'BSC.BU', categoryType: 'user', viewTitle: vm.translate('PersonJobData.BuDefinedId') };
        vm.nbsSelectionTreeviewWithModalCategoryBuDefineds = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategoryBuDefineds.setOnSelectFnCallback(function () {
            vm.currentVirtualPersonFilter.BuDefinedId = vm.nbsSelectionTreeviewWithModalCategoryLegalQualifications.selectedIds;
        });

        selectionParams = { destModalKey: 'category', systemCategoryCode: 'BSC.BU', categoryType: 'user', viewTitle: vm.translate('PersonJobData.BuUsedId') };
        vm.nbsSelectionTreeviewWithModalCategoryBuUsed = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategoryBuUsed.setOnSelectFnCallback(function () {
            vm.currentVirtualPersonFilter.BuUsedId = vm.nbsSelectionTreeviewWithModalCategoryLegalQualifications.selectedIds;
        });

        var dsCallback = function () { return vhreventService.getVhrEventTypes({ AppStoreBw: appStoreBw, IsBomType: false }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventType', viewTitle: vm.translate('VhrEvent.VhrEventType'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventType = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalVhrEventType.setOnSelectFnCallback(function () {
            vm.currentVhrEventFilter.VhrEventTypesId = vm.nbsSelectionGridWithModalVhrEventType.selectedIds;
        });

        dsCallback = function () { return vhreventService.getVhrEventGroups({ AppStoreBw: appStoreBw, IsBomGroup: false }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventGroup', viewTitle: vm.translate('VhrEvent.VhrEventGroup'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventGroup = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalVhrEventGroup.setOnSelectFnCallback(function () {
            vm.currentVhrEventFilter.VhrEventGroupsId = vm.nbsSelectionGridWithModalVhrEventGroup.selectedIds;
        });

        dsCallback = function () { return vhreventService.getVhrEventLabels({ AppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventLabel', viewTitle: vm.translate('VhrEvent.VhrEventLabels'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventLabel = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalVhrEventLabel.setOnSelectFnCallback(function () {
            vm.currentVhrEventFilter.VhrEventLabelsId = vm.nbsSelectionGridWithModalVhrEventLabel.selectedIds;
        });

        dsCallback = function () { return productionService.getVirtualWorkCenters({ AppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'WorkCenter', viewTitle: vm.translate('WorkCenter'), ds: dsCallback };
        vm.nbsSelectionGridWithModalWorkCenter = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalWorkCenter.setOnSelectFnCallback(function () {
            vm.currentVhrEventFilter.WorkCentersId = vm.nbsSelectionGridWithModalWorkCenter.selectedIds;
        });

        selectionParams = { destModalKey: 'vhr', entityMode: 'AssetTree', translateKey: 'VirtualEquipment', isMultiselect: true };
        vm.nbsSelectionGridWithModalAssetTree = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalAssetTree.setOnSelectFnCallback(function () {
            // onchange imsasset
            if (!vm.currentVirtualEquipmentFilter) {
                vm.currentVirtualEquipmentFilter = {};
            }
            vm.currentVirtualEquipmentFilter.VirtualHumanResourcesId = [];
            if (vm.nbsSelectionGridWithModalAssetTree.selectedIds) {
                vm.currentVirtualEquipmentFilter.VirtualHumanResourcesId = vm.nbsSelectionGridWithModalAssetTree.selectedIds;
            }
        });

        var nbsSearchForm = new NbsSearchForm(vm.translate);
        var filtersToSkip = [];
        try { resourcesEnabled = contextService.virtualContext.behaviors.search.vhrEvent[appStoreCode].resources; } catch (e) { }
        try { filtersToSkip = contextService.virtualContext.behaviors.search.vhrEvent[appStoreCode].skipFilters; } catch (e) { }

        // creates radio button resources selection
        initVirtualResourceSelection();

        var searchFormData = null;
        switch (vm.appVirtualResourceSelected) {
            case avatar:
                searchFormData = nbsSearchForm.SearchVhrEventAvatarsFormData(vhrEventTypeCode);
                break;
            case asset:
                searchFormData = nbsSearchForm.SearchVhrEventAssetsFormData(vhrEventTypeCode, vm.isCustomer);
                break;
        }
        searchFormData.init(vm, filtersToSkip);

        loadData(false);

        vm.currentVhrEventFilter.StartDate1 = new Date();
        vm.currentVhrEventFilter.StartDate1Expression = expressionTypeEnum.GreaterThanOrEqual;
        vm.currentVhrEventFilter.StartDate2 = new Date();
        vm.currentVhrEventFilter.StartDate2Expression = expressionTypeEnum.LessThanOrEqual;

        function wzVhrEventManualRequest(wzNbsManager) {
            wzNbsManager.open()
                .then(function (result) {
                    // result contains VhrEventManualRequest (not in context breeze)
                    var vhrEventManualRequestResult = result;
                    vhrEventManualRequestResult.VirtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
                    vhrEventManualRequestResult.VhrEventFixedTypeCode = vhrEventFixedTypeCode;
                    var vhrEventManualRequest = vhreventService.dataContext.vhrEventManualRequests.add(vhrEventManualRequestResult);

                    vm.submitAction()
                        .then(function (result) {
                            if (vhrEventManualRequest.IsBatch) {
                                contextService.addBatchRequest({
                                    requestId: vhrEventManualRequest.BatchRequestId,               //only one entity will be submitted
                                    requestName: vhrEventManualRequest.BatchRequestName,
                                    requestStatus: 'inserted',
                                    onCompletedAutoAction: function (request) {
                                        var defer = $q.defer();
                                        getVhrEventManualRequest(true, vhrEventManualRequest.VhrEventManualRequestId)
                                            .then(function () {
                                                var jobResult = vm.getJobStatus();
                                                //public enum VhrEventManualRequestStatus {
                                                //    None = 0,
                                                //    Inserted = 1,
                                                //    SuccessfullyDone = 2,
                                                //    SomeErrors = 3,
                                                //    SomeWarnings = 4,
                                                //    SomeConflict = 5,
                                                //    }
                                                switch (vm.vhrEventManualRequest.VhrEventManualRequestStatusId) {
                                                    case 2: jobResult.jobStatus = 'done'; break;     // SuccessfullyDone
                                                    case 3: jobResult.jobStatus = 'error'; break;     // SomeErrors
                                                    case 4: jobResult.jobStatus = 'warning'; break;     // SomeWarnings
                                                    case 5: jobResult.jobStatus = 'conflict'; break;     // SomeConflict
                                                }
                                                //jobResult.jobMessage = '';  // message before requestName in toolbar item
                                                //jobResult.jobDetailActionLabel = '';// Label of detail item in dropdown

                                                // used in detailAction
                                                request.VhrEventManualRequestId = vm.vhrEventManualRequest.VhrEventManualRequestId;

                                                defer.resolve(jobResult);
                                            });

                                        return defer.promise;
                                    },
                                    detailAction: function (request) {
                                        navigationService.navigateTo("app.nav.vhreventmanualrequestdetail", {
                                            'vhrEventManualRequestId': request.VhrEventManualRequestId,
                                            'appStoreCode': appStoreCode
                                        });
                                    }
                                });
                            }

                            vm.refreshAction();
                        })
                        .catch(function (error) {
                            // error
                            vm.setErrorMsg(error);
                            vhreventService.rejectChanges();
                        });
                });
        }

        function getVhrEventManualRequest(forceReload, vhrEventManualRequestId) {
            var filter = { VhrEventManualRequestId: vhrEventManualRequestId };
            var defer = $q.defer();

            vhreventService.getVhrEventManualRequest(filter, forceReload)
                .then(function (data) {
                    vm.vhrEventManualRequest = data.results[0];
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function wzUpdateVhrEventManualRequest(vhrEventTransactionCode) {
            navigationService.confirmModal(vm.translate("VhrEventManualUpdateSerieAffectedWarning"))
                .then(function (result) {
                    if (result) {
                        var services = { navigationService: navigationService, vhreventService: vhreventService, cmsService: cmsService }
                        var wzNbsManager = new NbsWizardManager($q, vm.translate, services, NbsGuidManager);
                        var config = new wzNbsManager.WzConfig_UpdateVhrEventManual(null);
                        config.supportData = { vhrEventCreationArchetype: vhrEventCreationArchetypeEnumParsed.obj.normal.name_value };
                        config.defaultData.VhrEventTransactionCode = vhrEventTransactionCode;
                        config.defaultData.VhrEventManualRequestTypeId = vhrEventManualRequestType.Modify;

                        // get default data from first VhrEvent that belongs to serie selected (for validation request)
                        var firstVhrEvent = Lazy(gridResult.data).first();
                        config.defaultData.ModuleTargetId = firstVhrEvent.ModuleTargetId;
                        config.defaultData.VhrEventGroupId = firstVhrEvent.VhrEventGroupId;
                        config.defaultData.VhrEventTypeId = firstVhrEvent.VhrEventTypeId;
                        config.defaultData.WorkCenterId = firstVhrEvent.WorkCenterId;
                        config.defaultData.VhrEventTemplateId = 1;        // only for validation
                        config.defaultData.VhrEvents = gridResult.data;
                        wzNbsManager.setWzConfig(config);

                        wzVhrEventManualRequest(wzNbsManager);
                    }
                });
        }

        function initVirtualResourceSelection() {
            vm.appStoresEnabled = [];
            var resourcesContainsAvatar = false;
            var resourcesContainsAsset = false;

            resourcesContainsAvatar = Lazy(resourcesEnabled).contains('Avatar');
            if (resourcesContainsAvatar) {
                var hrs = Lazy(appStores).findWhere({ AppStoreCode: avatar });
                if (hrs) {
                    vm.appStoresEnabled.push({ AppStoreCode: hrs.AppStoreCode, AppStoreName: hrs.AppStoreName });
                    vm.appVirtualResourceSelected = hrs.AppStoreCode;
                }
            }

            resourcesContainsAsset = Lazy(resourcesEnabled).contains('Asset');
            if (resourcesContainsAsset) {
                var tec = Lazy(appStores).findWhere({ AppStoreCode: asset });
                if (tec) {
                    vm.appStoresEnabled.push({ AppStoreCode: tec.AppStoreCode, AppStoreName: tec.AppStoreName });
                    vm.appVirtualResourceSelected = tec.AppStoreCode;
                }
            }

            vm.virtualResourceSelectionIsVisible = vm.appStoresEnabled.length > 1;
        }

        vm.modalInstances = {
            changeState: 'modalInstanceChangeState'
        };

        vm.addFilterAction = function () {
            vm.searchTabIndex = 0;
            searchFormData.addFilters(navigationService, vm.translate, $scope);
        };

        vm.searchAction = function () {
            searchVhrEvents();
        };

        vm.deleteFilterAction = function () {
            $timeout(() => {
                vm.searchTabIndex = 0;
                vm.resetAction();
                searchFormData.removeFilters(filtersToSkip);
            });
        };

        vm.resetAction = function () {
            $timeout(() => {
                vm.searchTabIndex = 0;
                searchFormData.resetFilters();

                vm.nbsSelectionGridWithModalActivity.clear();
                vm.nbsSelectionTreeviewWithModalCategory.clear();
                vm.nbsSelectionTreeviewWithModalCategoryHazards.clear();
                vm.nbsSelectionTreeviewWithModalCategoryJobRoles.clear();
                vm.nbsSelectionTreeviewWithModalCategoryLegalQualifications.clear();
                vm.nbsSelectionTreeviewWithModalCategoryBusinessRoles.clear();
                vm.nbsSelectionTreeviewWithModalCategorySafetyRoles.clear();
                vm.nbsSelectionTreeviewWithModalCategoryPlaceDefineds.clear();
                vm.nbsSelectionTreeviewWithModalCategoryPlaceUseds.clear();
                vm.nbsSelectionTreeviewWithModalCategoryCcDefineds.clear();
                vm.nbsSelectionTreeviewWithModalCategoryCcUseds.clear();
                vm.nbsSelectionTreeviewWithModalCategoryBuDefineds.clear();
                vm.nbsSelectionTreeviewWithModalCategoryBuUsed.clear();
                vm.nbsSelectionGridWithModalVhrEventType.clear();
                vm.nbsSelectionGridWithModalVhrEventGroup.clear();
                vm.nbsSelectionGridWithModalVhrEventLabel.clear();
                vm.nbsSelectionGridWithModalWorkCenter.clear();
                vm.nbsSelectionGridWithModalAssetTree.clear();
            });
        };

        // open wizard to add new manual event
        vm.newAction = function () {
            var services = { navigationService: navigationService, vhreventService: vhreventService, cmsService: cmsService }
            var wzNbsManager = new NbsWizardManager($q, vm.translate, services, NbsGuidManager);
            var config = new wzNbsManager.WzConfig_AddVhrEventManual(null);
            config.supportData = { vhrEventCreationArchetype: vhrEventCreationArchetypeEnumParsed.obj.normal.name_value };
            config.vhrEntityType = wzVhrEntityType;
            config.defaultData.VhrEventManualRequestTypeId = vhrEventManualRequestType.New;
            wzNbsManager.setWzConfig(config);

            wzVhrEventManualRequest(wzNbsManager);
        };

        vm.copyAction = function () {
            if (gridResult.isSingleSelection) {
                var event = Lazy(gridResult.data).first();
                // get activity related to vhrevent
                activityService.getActivities({ ActivitySystemCode: event.ActivitySystemCode }, true)
                    .then(function (data) {
                        var activity = data.results[0];

                        var services = { navigationService: navigationService, vhreventService: vhreventService, cmsService: cmsService }
                        var wzNbsManager = new NbsWizardManager($q, vm.translate, services, NbsGuidManager);
                        var config = new wzNbsManager.WzConfig_AddVhrEventManual(null);
                        config.supportData = { vhrEventCreationArchetype: vhrEventCreationArchetypeEnumParsed.obj.normal.name_value };
                        config.vhrEntityType = wzVhrEntityType;
                        config.defaultData.VhrEventManualRequestTypeId = vhrEventManualRequestType.Copy;

                        config.defaultData.Code = event.ActivityCode;
                        config.defaultData.ExternalCode = event.ActivityExternalCode;
                        config.defaultData.ModuleTargetId = event.ModuleTargetId;
                        config.defaultData.VhrEventTypeId = event.VhrEventTypeId;
                        config.defaultData.VhrEventGroupId = event.VhrEventGroupId;
                        config.defaultData.WorkCenterId = event.WorkCenterId;

                        // fields retrieved by activity related to vhrevent
                        if (activity) {
                            config.defaultData.VhrEventTemplateId = activity.VhrEventTemplateId;
                            config.defaultData.Name = activity.ActivityName;
                            config.defaultData.Description = activity.ActivityDescription;
                            config.defaultData.ActivityId = activity.ActivityId;
                        } else {
                            config.defaultData.Name = event.VhrEventName;
                            config.defaultData.Description = event.VhrEventDescription;
                        }

                        config.defaultData.VhrEvents = gridResult.data;

                        // skip step
                        config.defaultData.VirtualHumanResources = [
                            {
                                VirtualHumanResourceId: event.VirtualHumanResourceId,
                                VirtualName: event.VirtualHumanResourceDisplayName
                            }
                        ];
                        config.stepsToSkip.push('vhrEventsManualStepVhr');

                        // remove steps
                        config.removeStep('vhrEventsManualStepSwitchFromActivity');
                        config.removeStep('vhrEventsManualStepActivitySelection');
                        wzNbsManager.setWzConfig(config);
                        
                        wzVhrEventManualRequest(wzNbsManager);
                    })
                    .catch(function (error) {
                        vm.setErrorMsg(error);
                    });


            } else {
                navigationService.selectOneItemMessageModal();
            }
        };

        vm.vhrEventLifeStatusList = Lazy(vhrEventLifeStatusEnumParsed.list.translated).filter(filterVhrEventLifeStatus).toArray();
        function filterVhrEventLifeStatus(lifeStatus) {
            return lifeStatus.value === vhrEventLifeStatusEnumParsed.obj.normal.name_value.None
                || lifeStatus.value === vhrEventLifeStatusEnumParsed.obj.normal.name_value.Approved
                || lifeStatus.value === vhrEventLifeStatusEnumParsed.obj.normal.name_value.Closed
                || lifeStatus.value === vhrEventLifeStatusEnumParsed.obj.normal.name_value.FinishedAndChecked
                || lifeStatus.value === vhrEventLifeStatusEnumParsed.obj.normal.name_value.ReportedAsFinished
                || lifeStatus.value === vhrEventLifeStatusEnumParsed.obj.normal.name_value.Running;
        }

        //vhrevent billing status drop down
        vm.billingStatusList = Lazy(vhrEventBillingStatusEnumParsed.list.translated).filter(filterBillingStatus).toArray();
        function filterBillingStatus(billingStatus) {
            return billingStatus.value === vhrEventBillingStatusEnumParsed.obj.normal.name_value.None
                || billingStatus.value === vhrEventBillingStatusEnumParsed.obj.normal.name_value.NotApplicable
                || billingStatus.value === vhrEventBillingStatusEnumParsed.obj.normal.name_value.Waiting
                || billingStatus.value === vhrEventBillingStatusEnumParsed.obj.normal.name_value.Billable
                || billingStatus.value === vhrEventBillingStatusEnumParsed.obj.normal.name_value.NotBillable
                || billingStatus.value === vhrEventBillingStatusEnumParsed.obj.normal.name_value.Turnover
                || billingStatus.value === vhrEventBillingStatusEnumParsed.obj.normal.name_value.Suspended;
        }

        function setVhrEventTypesDefault(args) {
            var filter = { AppStoreBw: args[1] };
            var vhrEventTypeCodes = args[2].split(',');
            var filterName = searchFormData.getFilterNameByFieldId(searchFormData.Const.FieldIds.EVENT_VhrEventTypesId);
            var fieldName = searchFormData.getFieldNameByFieldId(searchFormData.Const.FieldIds.EVENT_VhrEventTypesId);
            var defer = $q.defer();

            vhreventService.getVhrEventTypes(filter, args[0])
                .then(function (data) {
                    vm.valuesSelected = [];
                    vm.vhrEventTypes = data.results;

                    if (vm.modal) {
                        //var params = {
                        //    datasource: vm.vhrEventTypes,
                        //    columnId: 'VhrEventTypeId',
                        //    columnCode: 'VhrEventTypeCode',
                        //    columnName: 'VhrEventTypeName',
                        //    inputType: vm.inputType.checkbox,
                        //    selectedIds: null,
                        //    isDisabled: null
                        //};

                        vm.modal.datasource = Lazy(vm.vhrEventTypes)
                            .map((item) => {
                                return {
                                    Id: item.VhrEventTypeId,
                                    Code: item.VhrEventTypeCode,
                                    Name: item.VhrEventTypeName
                                };
                            }).toArray();
                    }

                    vm[filterName][fieldName] = [];
                    Lazy(Lazy(vm.vhrEventTypes)).each((item) => {
                        if (Lazy(vhrEventTypeCodes).contains(item.VhrEventTypeCode)) {
                            vm[filterName][fieldName].push({ code: item.VhrEventTypeId, name: item.VhrEventTypeName });
                        }
                    });

                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function searchVhrEvents() {
            switch (vm.appVirtualResourceSelected) {
                case avatar:
                    // post
                    searchVhrEventsPerson();
                    break;
                case asset:
                    // post
                    searchVhrEventsAsset();
                    break;
            }
        }

        var ejDataManager = null;

        function searchVhrEventsPerson() {
            searchVhrEventClientData.vhrEventFilter = nbsSearchForm.ParseVhrEventFilter(vm.currentVhrEventFilter, appStoreBw, vhrEventKindEnum, expressionTypeEnum);
            searchVhrEventClientData.vhrEventFilter.ModuleTargetId = moduleTargetId;
            searchVhrEventClientData.vhrEventFilter.VhrEventFixedTypeCode = vhrEventFixedTypeCode;
            searchVhrEventClientData.virtualPersonFilter = nbsSearchForm.ParseVirtualPersonFilter(vm.currentVirtualPersonFilter, vm.currentVirtualHrFilter, appStoreBwSum);
            searchVhrEventClientData.activityVhrCoreFilter = nbsSearchForm.ParseActivityVhrCoreFilter(vm.currentActivityVhrCoreFilter, appStoreBw);

            if (ejDataManager === null) {
                utilService.getGenericElements({ GenericElementCode: 'Configurations.VhrEvents.LifeStatusColors' }, false)
                    .then((data) => {
                        var ge = data.results[0].PairValuesArray;
                        var statusColorObj = Nembus.Common.Library.parser.objFromList(ge, 'key', 'value');

                        var supportData = { searchMode: searchMode, translate: vm.translate, contextService: contextService, statusColorObj: statusColorObj };
                        ejDataManager = vhreventService.getVhrEventDataManager(supportData, searchVhrEventClientData, ejDataManager);
                        nbsGridDatacontext.setDatasource(ejDataManager, true);
                        refreshGridDatasource(searchVhrEventClientData);
                    });
            } else {
                refreshGridDatasource(searchVhrEventClientData)
            }
        }

        function searchVhrEventsAsset() {
            searchVhrEventClientData.vhrEventFilter = nbsSearchForm.ParseVhrEventFilter(vm.currentVhrEventFilter, appStoreBw, vhrEventKindEnum, expressionTypeEnum);
            searchVhrEventClientData.vhrEventFilter.ModuleTargetId = moduleTargetId;
            searchVhrEventClientData.vhrEventFilter.VhrEventFixedTypeCode = vhrEventFixedTypeCode;
            searchVhrEventClientData.virtualEquipmentFilter = nbsSearchForm.ParseVirtualEquipmentFilter(vm.currentVirtualEquipmentFilter, vm.currentVirtualHrFilter, appStoreBwSum);
            searchVhrEventClientData.activityVhrCoreFilter = nbsSearchForm.ParseActivityVhrCoreFilter(vm.currentActivityVhrCoreFilter, appStoreBw);

            if (ejDataManager === null) {
                utilService.getGenericElements({ GenericElementCode: 'Configurations.VhrEvents.LifeStatusColors' }, false)
                    .then((data) => {
                        var ge = data.results[0].PairValuesArray;
                        var statusColorObj = Nembus.Common.Library.parser.objFromList(ge, 'key', 'value');

                        var supportData = { searchMode: searchMode, translate: vm.translate, contextService: contextService, statusColorObj: statusColorObj };
                        ejDataManager = vhreventService.getVhrEventDataManager(supportData, searchVhrEventClientData, ejDataManager);
                        nbsGridDatacontext.setDatasource(ejDataManager, true);
                        refreshGridDatasource(searchVhrEventClientData);
                    });
            } else {
                refreshGridDatasource(searchVhrEventClientData)
            }
        }

        function refreshGridDatasource(currentGridFilter) {
            $timeout(() => {
                vm.searchTabIndex = 1;
                setActionButtonsStatus(false);

                vm.forceDatamanagerFilter = {
                    refreshKey: new Date().getTime(),
                    filter: { filter: currentGridFilter }
                }
            });
        }

        // datasource for modal change state
        vm.currentStates = Lazy(vhrEventLifeStatusEnumParsed.list.translated)
            .filter((item) => {
                return item.value === vhrEventLifeStatusEnumObj.ReportedAsFinished
                    || item.value === vhrEventLifeStatusEnumObj.FinishedAndChecked
                    || item.value === vhrEventLifeStatusEnumObj.Closed;
            }).toArray();


        vm.currentVhrEvents = [];

        setActionButtonsStatus(false);


        var gridResult = {};
        vm.gridSelection = function (args) {
            $timeout(() => {
                gridResult = args;

                setActionButtonsStatus(null);
            });
        };

        vm.rowDblClick = function (args) {
            navigationService.navigateToVhrEventDetail({
                AppStoreCode: appStoreCode,
                VhrEventTemplateCode: args.data.VhrEventTemplateCode,
                ViewKind: viewKind,
                VhrEventId: args.data.VhrEventId,
                VirtualHumanResourceId: args.data.VirtualHumanResourceId,
                ViewMode: viewMode
            });
        };

        //Http Post
        function updateVhrEventLifeStatusBulk(vhrEventsId, moduleTargetId, vhreventLifeStatus) {
            vm.removeAlerts();

            var vhrEventShuffleActionObj = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventShuffleAction, null, vm.translate).obj.normal.name_value;
            var vhreventShuffleDto = vhreventService.dataContext.vhrEventShuffleDto.add();
            vhreventShuffleDto.VhrEventShuffleAction = vhrEventShuffleActionObj.ChangeLifeStatus;
            vhreventShuffleDto.VhrEventLifeStatus = vhreventLifeStatus;
            vhreventShuffleDto.VhrEventLifeStatusChangeNote = null;
            vhreventShuffleDto.VhrEventBillingStatus = 0;
            vhreventShuffleDto.ModuleTargetId = moduleTargetId;
            vhreventShuffleDto.VhrEventsIdSerialized = JSON.stringify(vhrEventsId);

            vhreventService.saveChanges()
                .then(function () {
                    var resultErrors = JSON.parse(Nembus.Common.Library.converter.decodeHtml(vhreventShuffleDto.ResultErrorsSerialized));
                    if (Lazy(Object.keys(resultErrors)).some()) {
                        vm.setErrorMsg(({ isMessageVisible: true, message: vm.translate('ChangeStatusNotPermitted') }));
                        return;
                    }

                    vm.refreshAction();
                });
        }
        //#endregion

        vm.refreshAction = function () {
            $timeout(() => {
                vm.searchTabIndex = 1;
                setActionButtonsStatus(false);
                vm.forceGridRefresh = new Date().getTime();
            });
        };

        function changeStartDateVhrEvent() {
            var startDate = Lazy(gridResult.data).first() ? Lazy(gridResult.data).first().VhrEventStartDate : new Date(Date.now());
            var startTime = Nembus.Common.Library.converter.date.formatTimePicker(startDate, contextService.virtualContext.cultureInfo, true);
            var navParams = {
                startDate: startDate,
                startTime: startTime,
                KeepSameTime: true,
                KeepSameWorkCenter: true,
                TargetWorkCenterId: null
            };

            navigationService.navToVhrEventChangeStartDate(vm.suspendDirty, navParams)
                .then(function (modalResult) {
                    //enable dirty flag
                    if (modalResult.dialogResult === navigationService.navigationActionTypes.modalOk) {
                        //-----
                        canChangeTargetVhrEventsStartDate(gridResult.dataIds, modalResult.data.startDate, moduleTargetId)
                            .then(function (result) {
                                if (result.ChangeIsEnabled) {
                                    if (result.ChangeSerie) {
                                        wzUpdateVhrEventManualRequest(result.VhrEventManualRequestId);
                                    } else {
                                        //changeStartDateVhrEvent();
                                        var vhrEventShuffleDto = vhreventService.dataContext.vhrEventShuffleDto.add();
                                        vhrEventShuffleDto.VhrEventShuffleAction = modalResult.data.VhrEventShuffleAction;
                                        vhrEventShuffleDto.StartDate = modalResult.data.startDate;
                                        vhrEventShuffleDto.StartTime = modalResult.data.startTime;
                                        vhrEventShuffleDto.KeepSameTime = modalResult.data.keepSameTime;
                                        vhrEventShuffleDto.KeepSameWorkCenter = modalResult.data.keepSameWorkCenter;
                                        vhrEventShuffleDto.TargetWorkCenterId = modalResult.data.targetWorkCenterId;
                                        vhrEventShuffleDto.VhrEventLifeStatus = 0;
                                        vhrEventShuffleDto.VhrEventBillingStatus = 0;
                                        vhrEventShuffleDto.VhrEventsIdSerialized = JSON.stringify(gridResult.dataIds);
                                        vhrEventShuffleDto.ModuleTargetId = moduleTargetId;

                                        vm.submitAction()
                                            .then(function (result) {
                                                vm.refreshAction();
                                            })
                                            .catch(function (error) {
                                                vm.removeAlertFromList(true);
                                                vm.setErrorMsg(error);
                                            });
                                    }
                                } else {
                                    navigationService.customMessageModal(vm.translate(result.ThesaurusWordKey));
                                }
                            });

                        //----
                    }
                });
        }

        vm.setDateAction = function () {
            changeStartDateVhrEvent();
        };

        vm.sendMailAction = function () {
            openModalSendMail();
        };

        // START: functions used to manage vhrEventMassivelyUpdateRequest
        var vhrEventMassivelyUpdateRequestType = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventMassivelyUpdateRequestType, null, vm.translate).obj.normal.name_value;

        //#region execution
        vm.vhrEventExecutionApproveAction = function () {
            if (gridResult.hasSelection) {
                navigationService.navToVhrEventExecute(vm.suspendDirty)
                    .then(function (modalResult) {
                        if (modalResult.dialogResult === navigationService.navigationActionTypes.modalOk) {
                            modalResult.data.VhrEventsIdSerialized = JSON.stringify(gridResult.dataIds);
                            modalResult.data.AppStoreBw = appStoreBw;
                            modalResult.data.VirtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
                            modalResult.data.ModuleTargetId = moduleTargetId;

                            var vhrEventMassivelyUpdateRequest = vhreventService.dataContext.vhrEventMassivelyUpdateRequests.add(modalResult.data);
                            submitVhrEventMassivelyUpdateRequest(vhrEventMassivelyUpdateRequest);
                        }
                    });
            }
        };
        vm.vhrEventExecutionModifyAction = function () {
            if (gridResult.hasSelection) {
                navigationService.navToVhrEventChangeExecution(vm.suspendDirty)
                    .then(function (modalResult) {
                        if (modalResult.dialogResult === navigationService.navigationActionTypes.modalOk) {
                            modalResult.data.VhrEventsIdSerialized = JSON.stringify(gridResult.dataIds);
                            modalResult.data.AppStoreBw = appStoreBw;
                            modalResult.data.VirtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
                            modalResult.data.ModuleTargetId = moduleTargetId;

                            var vhrEventMassivelyUpdateRequest = vhreventService.dataContext.vhrEventMassivelyUpdateRequests.add(modalResult.data);
                            submitVhrEventMassivelyUpdateRequest(vhrEventMassivelyUpdateRequest);
                        }
                    });
            }
        };
        vm.vhrEventExecutionInfoAction = function () {
            if (gridResult.isSingleSelection) {
                var vhrEventId = Lazy(gridResult.data).first().VhrEventId;
                navigationService.navToVhrEventInfoExecution(vm.suspendDirty, { VhrEventId: vhrEventId });
            }
        };
        //#endregion

        //#region approval
        vm.vhrEventApprovalAuthorizeAction = function () {
            if (gridResult.hasSelection) {
                navigationService.navToVhrEventApprove(vm.suspendDirty)
                    .then(function (modalResult) {
                        if (modalResult.dialogResult === navigationService.navigationActionTypes.modalOk) {
                            modalResult.data.VhrEventsIdSerialized = JSON.stringify(gridResult.dataIds);
                            modalResult.data.AppStoreBw = appStoreBw;
                            modalResult.data.VirtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
                            modalResult.data.ModuleTargetId = moduleTargetId;

                            var vhrEventMassivelyUpdateRequest = vhreventService.dataContext.vhrEventMassivelyUpdateRequests.add(modalResult.data);
                            submitVhrEventMassivelyUpdateRequest(vhrEventMassivelyUpdateRequest);
                        }
                    });
            }
        };
        vm.vhrEventApprovalModifyAction = function () {
            if (gridResult.hasSelection) {
                navigationService.navToVhrEventChangeApproval(vm.suspendDirty)
                    .then(function (modalResult) {
                        //enable dirty flag
                        if (modalResult.dialogResult === navigationService.navigationActionTypes.modalOk) {
                            modalResult.data.VhrEventsIdSerialized = JSON.stringify(gridResult.dataIds);
                            modalResult.data.AppStoreBw = appStoreBw;
                            modalResult.data.VirtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
                            modalResult.data.ModuleTargetId = moduleTargetId;

                            var vhrEventMassivelyUpdateRequest = vhreventService.dataContext.vhrEventMassivelyUpdateRequests.add(modalResult.data);
                            submitVhrEventMassivelyUpdateRequest(vhrEventMassivelyUpdateRequest);
                        }
                    });
            }
        };
        vm.vhrEventApprovalInfoAction = function () {
            if (gridResult.isSingleSelection) {
                var vhrEventId = Lazy(gridResult.data).first().VhrEventId;
                navigationService.navToVhrEventInfoApproval(vm.suspendDirty, { VhrEventId: vhrEventId });
            }
        };
        //#endregion

        //#region customer approval
        vm.vhrEventCustomerApprovalAuthorizeAction = function () {
            if (gridResult.hasSelection) {
                navigationService.navToVhrEventCustomerApprove(vm.suspendDirty)
                    .then(function (modalResult) {
                        if (modalResult.dialogResult === navigationService.navigationActionTypes.modalOk) {
                            modalResult.data.VhrEventsIdSerialized = JSON.stringify(gridResult.dataIds);
                            modalResult.data.AppStoreBw = appStoreBw;
                            modalResult.data.VirtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
                            modalResult.data.ModuleTargetId = moduleTargetId;

                            var vhrEventMassivelyUpdateRequest = vhreventService.dataContext.vhrEventMassivelyUpdateRequests.add(modalResult.data);
                            submitVhrEventMassivelyUpdateRequest(vhrEventMassivelyUpdateRequest);
                        }
                    });
            }
        };
        vm.vhrEventCustomerApprovalModifyAction = function () {
            if (gridResult.hasSelection) {
                navigationService.navToVhrEventChangeCustomerApproval(vm.suspendDirty)
                    .then(function (modalResult) {
                        //enable dirty flag
                        if (modalResult.dialogResult === navigationService.navigationActionTypes.modalOk) {
                            modalResult.data.VhrEventsIdSerialized = JSON.stringify(gridResult.dataIds);
                            modalResult.data.AppStoreBw = appStoreBw;
                            modalResult.data.VirtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
                            modalResult.data.ModuleTargetId = moduleTargetId;

                            var vhrEventMassivelyUpdateRequest = vhreventService.dataContext.vhrEventMassivelyUpdateRequests.add(modalResult.data);
                            submitVhrEventMassivelyUpdateRequest(vhrEventMassivelyUpdateRequest);
                        }
                    });
            }
        };
        vm.vhrEventCustomerApprovalInfoAction = function () {
            if (gridResult.isSingleSelection) {
                var vhrEventId = Lazy(gridResult.data).first().VhrEventId;
                navigationService.navToVhrEventInfoCustomerApproval(vm.suspendDirty, { VhrEventId: vhrEventId });
            }
        };
        //#endregion

        vm.updateLabelAction = function () {
            var selectedIds = [];
            //if (gridResult.isSingleSelection) {
            //    var vhrEventSelected = Lazy(gridResult.data).first();
            //    selectedIds = Lazy(vhrEventSelected.VhrEventLabelsUI).pluck('IntValue').toArray();
            //}

            getVhrEventLabels(false, appStoreBw)
                .then(function (results) {
                    var params = new NbsGenericListModalParamObject('VhrEventLabel', vm.translate('ActionPanelUpdateLabel'));
                    params.selectedIds = selectedIds;

                    navigationService.navToGenericListModal(vm.suspendDirty, params, results)
                        .then(function (result) {
                            if (result.hasSelection) {
                                var vhrEventMassivelyUpdateRequest = vhreventService.dataContext.vhrEventMassivelyUpdateRequests.add({
                                    VhrEventLabelsIdSerialized: JSON.stringify(result.selectedValues),
                                    VhrEventMassivelyUpdateRequestTypeId: vhrEventMassivelyUpdateRequestType.UpdateLabel,
                                    VhrEventsIdSerialized: JSON.stringify(gridResult.dataIds),
                                    AppStoreBw: appStoreBw,
                                    VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId,
                                    ModuleTargetId: moduleTargetId
                                });

                                submitVhrEventMassivelyUpdateRequest(vhrEventMassivelyUpdateRequest);
                            }
                        });
                });
        };

        vm.infoLabelAction = function () {
            var selectedIds = [];
            if (gridResult.isSingleSelection) {
                var vhrEventSelected = Lazy(gridResult.data).first();
                selectedIds = Lazy(vhrEventSelected.VhrEventLabelsUI).pluck('IntValue').toArray();
            }

            getVhrEventLabels(false, appStoreBw)
                .then(function (results) {
                    var params = new NbsGenericListModalParamObject('VhrEventLabel', vm.translate('ActionPanelInfoLabel'));
                    params.readonly = true;
                    params.selectedIds = selectedIds;
                    navigationService.navToGenericListModal(vm.suspendDirty, params, results);
                });
        };

        vm.deleteLabelAction = function () {
            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        var vhrEventMassivelyUpdateRequest = vhreventService.dataContext.vhrEventMassivelyUpdateRequests.add({
                            VhrEventMassivelyUpdateRequestTypeId: vhrEventMassivelyUpdateRequestType.DeleteLabel,
                            VhrEventsIdSerialized: JSON.stringify(gridResult.dataIds),
                            AppStoreBw: appStoreBw,
                            VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId,
                            ModuleTargetId: moduleTargetId
                        });

                        submitVhrEventMassivelyUpdateRequest(vhrEventMassivelyUpdateRequest);
                    }
                });
        };

        function submitVhrEventMassivelyUpdateRequest(request) {
            vm.submitAction()
                .then(function (saveResult) {
                    if (request.IsBatch) {
                        contextService.addBatchRequest({
                            requestId: request.BatchRequestId,
                            requestName: request.BatchRequestName,
                            requestStatus: 'inserted'
                        });
                    } else {
                        vm.refreshAction();
                    }

                }).catch(function (error) {
                    vm.removeAlertFromList(true);
                    vm.setErrorMsg(error);
                });
        }
        // END

        vm.modalOk = function (modalInstance) {
            vm[modalInstance].close();
        };

        vm.modalCancel = function (modalInstance) {
            vm[modalInstance].dismiss();
        };

        vm.vhrEventLifeStatusUpdateAction = function () {
            getChangeTargetVhrEventsLifeStatus(gridResult.dataIds, moduleTargetId)
                .then(function (lifeStatusEnabled) {
                    if (lifeStatusEnabled) {
                        if (Lazy(lifeStatusEnabled).contains(lifeStatusCompletedId)) {
                            openModalChangeState();
                        } else {
                            navigationService.operationNotPermittedModal();
                        }
                    } else {
                        navigationService.operationNotPermittedModal();
                    }
                });
        };

        vm.vhrEventLifeStatusCompleteAction = function () {
            getChangeTargetVhrEventsLifeStatus(gridResult.dataIds, moduleTargetId)
                .then(function (lifeStatusEnabled) {
                    if (lifeStatusEnabled) {
                        if (Lazy(lifeStatusEnabled).contains(lifeStatusCompletedId)) {
                            navigationService.confirmModal(vm.translate("Continue"))
                                .then(function (result) {
                                    if (result) {
                                        if (gridResult.hasSelection && lifeStatusCompletedId) {
                                            updateVhrEventLifeStatusBulk(gridResult.dataIds, moduleTargetId, lifeStatusCompletedId);
                                        }
                                    }
                                });
                        } else {
                            navigationService.operationNotPermittedModal();
                        }
                    } else {
                        navigationService.operationNotPermittedModal();
                    }
                });
        };


        vm.vhrEventLifeStatusInfoAction = function () {
            if (gridResult.isSingleSelection) {
                var vhrEventLifeStatusId = gridResult.data[0].VhrEventLifeStatusId;
                var vhrEventLifeStatus = vhrEventLifeStatusEnumParsed.list.translated;

                var params = new NbsGenericListModalParamObject('NembusEnum', vm.translate('ActionPanelVhrEventLifeStatusInfo'));
                params.readonly = true;
                params.selectedIds = [vhrEventLifeStatusId];
                navigationService.navToGenericListModal(vm.suspendDirty, params, vhrEventLifeStatus);
            }
        };

        function setActionButtonsStatus(isEnabled) {
            if (isEnabled === null) {
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventLifeStatusModify, gridResult.hasSelection && vhrEventPermission.canManageLifeStatus);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventFailureLifeStatusComplete, gridResult.hasSelection && vhrEventPermission.canManageLifeStatus);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventLifeStatusInfo, gridResult.isSingleSelection && vhrEventPermission.canManageLifeStatus);
                ribbon.setRibbonStatusButton(ribbon.Buttons.ChangeDate, gridResult.hasSelection && vhrEventPermission.canManageStartDate);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventExecutionApprove, gridResult.hasSelection && vhrEventPermission.canManageExecution);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventExecutionModify, gridResult.hasSelection && vhrEventPermission.canManageExecution);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventExecutionInfo, gridResult.isSingleSelection && vhrEventPermission.canManageExecution);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventApprovalAuthorize, gridResult.hasSelection && vhrEventPermission.canManageApproval);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventApprovalModify, gridResult.hasSelection && vhrEventPermission.canManageApproval);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventApprovalInfo, gridResult.isSingleSelection && vhrEventPermission.canManageApproval);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventCustomerApprovalAuthorize, gridResult.hasSelection && vhrEventPermission.canManageCustomerApproval);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventCustomerApprovalModify, gridResult.hasSelection && vhrEventPermission.canManageCustomerApproval);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventCustomerApprovalInfo, gridResult.isSingleSelection && vhrEventPermission.canManageCustomerApproval);
                ribbon.setRibbonStatusButton(ribbon.Buttons.WarningModify, gridResult.hasSelection);
                ribbon.setRibbonStatusButton(ribbon.Buttons.WarningDelete, gridResult.hasSelection);
                ribbon.setRibbonStatusButton(ribbon.Buttons.WarningInfo, gridResult.isSingleSelection);
                ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, gridResult.isSingleSelection);
            } else {
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventLifeStatusModify, isEnabled && vhrEventPermission.canManageLifeStatus);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventFailureLifeStatusComplete, isEnabled && vhrEventPermission.canManageLifeStatus);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventLifeStatusInfo, isEnabled && vhrEventPermission.canManageLifeStatus);
                ribbon.setRibbonStatusButton(ribbon.Buttons.ChangeDate, isEnabled && vhrEventPermission.canManageStartDate);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventExecutionApprove, isEnabled && vhrEventPermission.canManageExecution);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventExecutionModify, isEnabled && vhrEventPermission.canManageExecution);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventExecutionInfo, isEnabled && vhrEventPermission.canManageExecution);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventApprovalAuthorize, isEnabled && vhrEventPermission.canManageApproval);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventApprovalModify, isEnabled && vhrEventPermission.canManageApproval);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventApprovalInfo, isEnabled && vhrEventPermission.canManageApproval);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventCustomerApprovalAuthorize, isEnabled && vhrEventPermission.canManageCustomerApproval);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventCustomerApprovalModify, isEnabled && vhrEventPermission.canManageCustomerApproval);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventCustomerApprovalInfo, isEnabled && vhrEventPermission.canManageCustomerApproval);
                ribbon.setRibbonStatusButton(ribbon.Buttons.WarningModify, isEnabled);
                ribbon.setRibbonStatusButton(ribbon.Buttons.WarningDelete, isEnabled);
                ribbon.setRibbonStatusButton(ribbon.Buttons.WarningInfo, isEnabled);
                ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, isEnabled);
            }

            ribbon.refreshStatusButtons();
        }

        function loadData(forceReload) {
            var funcsPromises = [];

            if (vhrEventTypeCode) {
                funcsPromises.push(setVhrEventTypesDefault([forceReload, appStoreBw, vhrEventTypeCode]));
            }

            $q.all(funcsPromises)
                .then(function () {
                    // no other data
                });
        }

        //CoreVhrEventConfigs table
        //change state is enabled only if status is configured in table
        function canChangeState(state) {
            return Lazy(vm.currentLifeStatusesEnabled).contains(Number(state.value));
        }

        function openModalSendMail() {
            //
        }

        function openModalChangeState() {
            var modalDs = Lazy(vm.currentStates).filter(canChangeState).toArray();
            Lazy(modalDs).each((status) => {
                status.selected = false;
            });

            var params = new NbsGenericListModalParamObject('NembusEnum', vm.translate('ActionPanelVhrEventLifeStatusUpdate'));
            navigationService.navToGenericListModal(vm.suspendDirty, params, modalDs)
                .then(function (result) {
                    if (result.hasSelection) {
                        var currentStatusLifeSelectedId = Lazy(result.selectedValues).first();

                        if (gridResult.hasSelection && currentStatusLifeSelectedId) {
                            updateVhrEventLifeStatusBulk(gridResult.dataIds, moduleTargetId, currentStatusLifeSelectedId);
                        }
                    }
                });
        }

        function canChangeTargetVhrEventsStartDate(vhrEventsId, targetDate, moduleTargetId) {
            var deferred = $q.defer();
            var filter = { VhrEventsId: vhrEventsId, ModuleTargetId: moduleTargetId, StartDate2: targetDate };

            vhreventService.canChangeTargetVhrEventsStartDate(filter)
                .then(function (data) {
                    deferred.resolve(data.results[0]);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    $q.reject(error);
                });
            return deferred.promise;
        }

        function getChangeTargetVhrEventsLifeStatus(vhrEventsId, moduleTargetId) {
            var filter = { VhrEventsId: vhrEventsId, ModuleTargetId: moduleTargetId };
            var defer = $q.defer();

            vhreventService.getChangeTargetVhrEventsLifeStatus(filter)
                .then(function (data) {
                    vm.currentLifeStatusesEnabled = data.results;
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrEventLabels(forceReload, appStoreBw) {
            var filter = { AppStoreBw: appStoreBw };
            var defer = $q.defer();

            vhreventService.getVhrEventLabels(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }
    }

    vhrEventsSearch.$inject = injectParams;
    vhrEventsSearch.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('vhrEventsSearch', vhrEventsSearch);
});