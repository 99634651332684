﻿
'use strict';
define(['../../common/services/nbs-service'], function (nbsService) {

	var injectParams = ['$http', '$q', '$timeout', 'breezeDataLayer'];

	var workflowService = function ($http, $q, $timeout,breezeDataLayer) {

		var dataLayer = breezeDataLayer.Initialize('workflow', true);
		var manager = dataLayer.GetManager();

		var entityTypes = {
            Workflow: 'Workflow',
            WorkflowDto: 'WorkflowDto',
            WorkflowStatus: 'WorkflowStatus'
		};

		this.entityTypes = entityTypes;

		nbsService.call(this, manager, $q, $timeout);

        this.dataContext = {
            workflowDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.WorkflowDto),
            workflows: dataLayer.setContextMethods(manager, nbsService, entityTypes.Workflow),
            workflowStatuses: dataLayer.setContextMethods(manager, nbsService, entityTypes.WorkflowStatus),
            
            enumTypes: {
                WorkflowType: dataLayer.GetEnumType('WorkflowType'),                    // static, dynamic, ...
                WorkflowStatusKind: dataLayer.GetEnumType('WorkflowStatusKind')        // toexecute, executed, ...
            },

			constants: { }
		};

        this.createQuery = function (entityName) {
            return dataLayer.CreateQuery(entityName);
        };

		// Workflow
        this.getWorkflow = function (filter, forceReload) {
            var params = { key: filter.WorkflowId, dataContext: this.dataContext.workflows };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "Workflow");
        };

        // WorkflowStatus
        this.getWorkflowStatuses = function (filter, forceReload) {
            var params = { entityType: entityTypes.WorkflowDto, cache: this.dataContext.workflows.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "WorkflowStatus");
        };

        // WorkflowDto
        this.getWorkflowDtos = function (filter, forceReload) {
            var params = { entityType: entityTypes.WorkflowDto, cache: this.dataContext.workflowDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "WorkflowDto");
        };
        
        // current Workflow  active for module target MTACT
        this.getActWorkflowActiveStatuses = function (filter, forceReload) {
            var params = { entityType: entityTypes.WorkflowStatus, cache: this.dataContext.workflowStatuses.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "ActWorkflowStatusActive");
        };

        // current Workflow  active for module target MTACT
        this.getWorkflowActiveStatuses = function (filter, forceReload) {
            var params = { entityType: entityTypes.WorkflowStatus, cache: this.dataContext.workflowStatuses.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "WorkflowStatusActive");
        };

        //Http Post
        this.activeWorkflow = function (filter) {
            var query = dataLayer.InvokeQuery("ActiveWorkflow", filter);
            return manager.executeQuery(query);
        };
	};

    workflowService.$inject = injectParams;
    workflowService.prototype = Object.create(nbsService.prototype);
    angular.module('nbs.rds.module').service('workflowService', workflowService);
});