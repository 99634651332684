﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'navigationService', 'checkStateChangeService', 'vhreventService', 'contextService', 'rbacService', 'drbacService'];
    function vhrEventChangeAssignment($q, $scope, $timeout, $injector, navigationService, checkStateChangeService, vhreventService, contextService, rbacService, drbacService) {

        var vm = this;
        nbsVm.call(vm, vhreventService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, vhreventService);

        var vhrEventEntityHistoryTypeEnum = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventEntityHistoryType, null, null).obj.normal.name_value;
        vm.vhrEventUserTypes = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventUserType, null, vm.translate).list.translated;
        var vhrEventUserTypeObj = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventUserType, null, vm.translate).obj.normal.name_value;
        var vhrEventMassivelyUpdateRequestType = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventMassivelyUpdateRequestType, null, vm.translate).obj.normal.name_value;
        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
        var forceReload = true;

        var pParams = navigationService.stateParamsModal().parentParams;
        pParams = pParams ? pParams : navigationService.stateParams();

        vm.isExecute = pParams.modalKey === 'executeassignment';
        vm.isModify = pParams.modalKey === 'changeassignment';
        vm.isHistory = pParams.modalKey === 'historyassignment';

        // entity used for validation
        vm.modalEntity = vhreventService.dataContext.vhrEventMassivelyUpdateRequestClientDatas.add({
            Id: NbsGuidManager.newGuid(),
            Percentage: 0
        });

        if (vm.isHistory) {
            var nbsGridDatacontext = new NbsGrid(vm.translate);
            nbsGridDatacontext.configureGrid('vhreventhistory', 'assignment');
            nbsGridDatacontext.setDatasource([], false);
            nbsGridDatacontext.setModalMode();
            nbsGridDatacontext.allowSearching = false;
            vm.nbsGridDatacontext = nbsGridDatacontext;

            getVhrEventEntityHistories(forceReload, pParams.VhrEventId, vhrEventEntityHistoryTypeEnum.Assignment)
                .then((history) => {
                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: history
                    }
                });

        } else if (vm.isModify) {
            vm.nbsDrbacAuthSelector = new NbsAssigneeSelector(vm.translate);
            vm.nbsDrbacAuthSelector.viewUserGroups = true;
            vm.nbsDrbacAuthSelector.viewUsers = true;
            vm.nbsDrbacAuthSelector.setLabelsOnRow();

            var funcsPromises = [];
            //if (vm.nbsDrbacAuthSelector.viewRoles) {
            //    funcsPromises.push(getDrbacRoles(forceReload));
            //}
            if (vm.nbsDrbacAuthSelector.viewUserGroups) {
                funcsPromises.push(getDrbacUserGroups(forceReload, virtualCompanyId));
            }
            if (vm.nbsDrbacAuthSelector.viewUsers) {
                funcsPromises.push(getCurrentContextAppUsers(forceReload));
            }

            $q.all(funcsPromises)
                .then(function (results) {
                });
        }


        // submit and close modal
        vm.modalOk = function () {
            $timeout(() => {
                vm.modalEntity.entityAspect.validateEntity();
                if (!vm.modalEntity.entityAspect.hasValidationErrors) {
                    vm.modalEntity.entityAspect.rejectChanges();

                    var modalResult = getModalResult(navigationService.navigationActionTypes.modalOk);
                    navigationService.closeCurrentModal(modalResult);
                }
            });
        };

        // close modal
        vm.modalCancel = function () {
            if (vm.modalEntity && vm.modalEntity.entityAspect) {
                vm.modalEntity.entityAspect.rejectChanges();
            }

            var modalResult = getModalResult(navigationService.navigationActionTypes.modalCancel);
            navigationService.closeCurrentModal(modalResult);
        };

        // not managed too
        function getDrbacRoles(forceReload) {
            var filter = {};
            var defer = $q.defer();

            drbacService.getDrbacRoles(filter, forceReload)
                .then(function (data) {
                    vm.nbsDrbacAuthSelector.drbacRoleMultiselect.addData(data.results, 'RoleId', 'RoleName');
                    vm.nbsDrbacAuthSelector.setDrbacRolesSelected(pParams.selectedDrbacRoleIds);

                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getCurrentContextAppUsers(forceReload) {
            var filter = {};
            var defer = $q.defer();

            rbacService.getCurrentContextAppUsers(filter, forceReload)
                .then(function (data) {
                    vm.nbsDrbacAuthSelector.appUserMultiselect.addData(data.results, 'AppUserId', 'DisplayNameUI');
                    vm.nbsDrbacAuthSelector.setAppUsersSelected(pParams.selectedAppUserIds);
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getDrbacUserGroups(forceReload, virtualCompanyId) {
            var defer = $q.defer();
            var filter = {
                VirtualCompanyId: virtualCompanyId
            };

            drbacService.getDrbacUserGroups(filter, forceReload)
                .then(function (data) {
                    vm.nbsDrbacAuthSelector.drbacUserGroupMultiselect.addData(data.results, 'DrbacUserGroupId', 'DrbacUserGroupName');
                    vm.nbsDrbacAuthSelector.setDrbacUserGroupsSelected(pParams.selectedDrbacUserGroupIds);
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrEventEntityHistories(forceReload, vhrEventId, vhrEventEntityHistoryTypeId) {
            var defer = $q.defer();
            var filter = { VhrEventId: vhrEventId, VhrEventEntityHistoryTypeId: vhrEventEntityHistoryTypeId };

            vhreventService.getVhrEventEntityHistories(filter, forceReload)
                .then(function (data) {
                    Lazy(data.results)
                        .each((item) => {
                            item.UsersUI = item.AppUsersSerialized ? JSON.parse(Nembus.Common.Library.converter.decodeHtml(item.AppUsersSerialized)) : [];
                            item.UserGroupsUI = item.DrbacUserGroupsSerialized ? JSON.parse(Nembus.Common.Library.converter.decodeHtml(item.DrbacUserGroupsSerialized)) : [];
                        });

                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getModalResult(dialogResult) {
            var modalResult = new navigationService.modalResult();

            if (dialogResult === navigationService.navigationActionTypes.modalOk) {
                if (vm.isExecute) {
                    modalResult.data = {
                        VhrEventMassivelyUpdateRequestTypeId: vhrEventMassivelyUpdateRequestType.UpdateAssignment,
                        AppUsersIdSerialized: JSON.stringify([contextService.virtualContext.appuserid]),
                        AppendAssignmentsToExistent: false  // existent assignments were deleted before add current
                    };
                }

                if (vm.isModify) {
                    modalResult.data = {
                        VhrEventMassivelyUpdateRequestTypeId: vhrEventMassivelyUpdateRequestType.UpdateAssignment,
                        AppUsersIdSerialized: JSON.stringify(vm.nbsDrbacAuthSelector.appUserMultiselect.selectedIds),
                        DrbacUserGroupsIdSerialized: JSON.stringify(vm.nbsDrbacAuthSelector.drbacUserGroupMultiselect.selectedIds),
                        AppendAssignmentsToExistent: false  // existent assignments were deleted before add current
                    };
                }
            }

            modalResult.sourceParams = pParams;
            modalResult.dialogResult = dialogResult;
            return modalResult;
        }
    }

    vhrEventChangeAssignment.$inject = injectParams;
    vhrEventChangeAssignment.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('vhrEventChangeAssignment', vhrEventChangeAssignment);
});