﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'utilService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var genericElementsManage = function ($q, $scope, $timeout, $injector, utilService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, utilService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, utilService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var genericElementType = { None: 0, StaticFolder: 1, Data: 2, DynamicFolder: 257 };
        var genericElementSelected = null;
        var genericElementCode = navigationService.stateParams().systemCode;
        var pairValueSelected = null;
        var ACTION = { insert: 1, update: 2 };
        var privateAction = null;

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('pairvalues', null);
        nbsGridDatacontext.setModalMode();
        nbsGridDatacontext.allowSearching = true;
        nbsGridDatacontext.setDatasource([], false); // fake datasource, only to create grid
        vm.nbsGridDatacontext = nbsGridDatacontext;


        ribbon.setRibbonStatusButton(ribbon.Buttons.Modify,false);
        ribbon.setRibbonStatusButton(ribbon.Buttons.NewFolder, false);
        ribbon.setRibbonStatusButton(ribbon.Buttons.New,  false);
        ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
        ribbon.refreshStatusButtons();

        vm.currentGenericElements = [];
        vm.pairValuesList = [];

        getGenericElementsSubTree(false, genericElementCode);

        vm.modifyAction = function () {
            openModalPairValuesList();
        };

        vm.newAction = function () {
            if (!genericElementSelected) {
                return;
            }            

            if (genericElementSelected.canInsertCategory) {
                vm.currentGenericElement = getNewGenericElement(genericElementType.Data);
                openModalGenericElement();
            }
        };

        vm.newFolderAction = function () {
            if (!genericElementSelected) {
                return;
            }            

            if (genericElementSelected.canInsertFolder) {
                vm.currentGenericElement = getNewGenericElement(genericElementType.DynamicFolder);
                openModalGenericElement();
            }
        };

        vm.onNodeSelect = function (args) {
            $timeout(function () {
                var genericElement = Lazy(vm.currentGenericElements).findWhere({ GenericElementId: Number(args.id) });
                genericElementSelected = {
                    genericElement: genericElement,

                    canInsertCategory: genericElement.GenericElementTypeId === genericElementType.DynamicFolder,
                    canInsertFolder: genericElement.GenericElementTypeId === genericElementType.DynamicFolder,
                    canDelete: !genericElement.IsSystem && !currentGenericElementHasChildren(genericElement),
                    canModify: genericElement.GenericElementTypeId === genericElementType.Data
                };
                ribbon.setRibbonStatusButton(ribbon.Buttons.Modify, genericElementSelected.canModify);
                ribbon.setRibbonStatusButton(ribbon.Buttons.NewFolder, genericElementSelected.canInsertFolder);
                ribbon.setRibbonStatusButton(ribbon.Buttons.New,  genericElementSelected.canInsertCategory);
                ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, genericElementSelected.canDelete);
                ribbon.refreshStatusButtons();
            });
        };

        vm.rowSelected = function (args) {
            pairValueSelected = args.data;
        };

        vm.deleteAction = function () {
            if (!genericElementSelected) {
                return;
            }            

            if (genericElementSelected.canDelete) {
                utilService.deleteEntity(genericElementSelected.genericElement);
                vm.submitAction()
                    .then(function () {
                        getGenericElementsSubTree(false, genericElementCode);
                    });
            }
        };

        function openModalPairValuesList() {
            vm.currentGenericElement = genericElementSelected.genericElement;

            // remove reference from PairValuesArray
            vm.pairValuesList = JSON.parse(JSON.stringify(vm.currentGenericElement.PairValuesArray));
            refreshPairValuesGrid();

            vm.modalInstancePairValueList = navigationService.modal().open(vm.modalOptions({
                templateUrl: 'pairValuesListTemplate',
                scope: $scope
            }));
            vm.modalInstancePairValueList.result.then(function () {
                //ok
            }, function () {
                //dismiss
            });
            vm.modalInstancePairValueList.ok = function () {
                vm.currentGenericElement.PairValues = utilService.genericElementsToString(vm.pairValuesList);
                vm.currentGenericElement.PairValuesArray = vm.pairValuesList;

                vm.submitAction()
                    .then(function () {
                        vm.modalInstancePairValueList.close();
                    });
            }
            vm.modalInstancePairValueList.cancel = function () {
                vm.modalInstancePairValueList.dismiss();
            }
            vm.modalInstancePairValueList.delete = function () {
                if (!genericElementSelected.canModify) {
                    navigationService.operationNotPermittedModal();
                    return;
                }

                if (!pairValueSelected) {
                    return;
                }

                vm.pairValuesList = Lazy(vm.pairValuesList).reject({ key: pairValueSelected.key }).toArray();
                refreshPairValuesGrid();

                pairValueSelected = null;
            };
            vm.modalInstancePairValueList.update = function () {
                if (!genericElementSelected.canModify) {
                    navigationService.operationNotPermittedModal();
                    return;
                }

                if (!pairValueSelected) {
                    return;
                }

                privateAction = ACTION.update;
                openModalPairValuesForm();
            };
            vm.modalInstancePairValueList.add = function () {
                if (!genericElementSelected.canModify) {
                    navigationService.operationNotPermittedModal();
                    return;
                }

                privateAction = ACTION.insert;
                openModalPairValuesForm();
            };
        }

        function openModalPairValuesForm() {
            vm.removeAlertFromList(true);

            if (privateAction === ACTION.update) {
                vm.currentPairValue = utilService.dataContext.pairValueClientDatas.add({
                    PairValueCode: pairValueSelected.key,
                    PairValueName: pairValueSelected.value
                });
            } else if (privateAction === ACTION.insert) {
                vm.currentPairValue = utilService.dataContext.pairValueClientDatas.add();
            }

            vm.modalInstancePairValue = navigationService.modal().open(vm.modalOptions({
                windowClass: vm.NBS_MODAL_CLASS_FORM_MD,
                templateUrl: 'pairValueTemplate',
                scope: $scope
            }));
            vm.modalInstancePairValue.result.then(function () {
                //ok
                vm.currentPairValue.entityAspect.setDetached();
                refreshPairValuesGrid();
            }, function () {
                vm.currentPairValue.entityAspect.setDetached();
                refreshPairValuesGrid();
            });
            vm.modalInstancePairValue.ok = function () {
                var error = false;

                vm.currentPairValue.entityAspect.validateEntity();
                if (vm.currentPairValue.entityAspect.hasValidationErrors) {
                    return;
                }

                if (privateAction === ACTION.insert) {
                    // array tah contains current pair value keys
                    var keyValues = Lazy(vm.pairValuesList).map((el) => { return { key: el.key.toLowerCase() } }).pluck("key").toArray();
                    if (!Lazy(keyValues).contains(vm.currentPairValue.PairValueCode.toLowerCase())) {
                        // add NEW pair value code
                        addNewPairValueCodeToList(vm.currentPairValue);
                        refreshPairValuesGrid();
                    } else {
                        error = true;
                    }
                } else if (privateAction === ACTION.update) {
                    pairValueSelected.key = vm.currentPairValue.PairValueCode;
                    pairValueSelected.value = vm.currentPairValue.PairValueName;
                }

                if (error) {
                    vm.alertConfig.isModal = true;
                    vm.alertConfig.isReadonly = true;
                    vm.removeAlertFromList(true);

                    vm.setErrorMsg({
                        message: vm.translate('CreationDeniedEntityCodeAlreadyExists'),
                        isMessageVisible: true
                    });
                } else {
                    vm.modalInstancePairValue.close();
                }
            }
            vm.modalInstancePairValue.cancel = function () {
                vm.modalInstancePairValue.dismiss();
            }
        }

        function openModalGenericElement() {
            vm.modalInstanceGenericElement = navigationService.modal().open(vm.modalOptions({
                windowClass: vm.NBS_MODAL_CLASS_FORM,
                templateUrl: 'genericElementTemplate',
                scope: $scope
            }));
            vm.modalInstanceGenericElement.result.then(function () {
                //nothing to do
            }, function () {
                //dismiss
                utilService.dataContext.genericElements.clearCache();
            });
            vm.modalInstanceGenericElement.ok = function () {
                vm.submitAction()
                    .then(function () {
                        getGenericElementsSubTree(false, genericElementCode)
                            .then(function () {
                                vm.modalInstanceGenericElement.close();
                            });
                    });
            }
            vm.modalInstanceGenericElement.cancel = function () {
                vm.modalInstanceGenericElement.dismiss();
            }
        }

        function refreshPairValuesGrid() {
            vm.forceDatasourceLocal = {
                refreshkey: new Date().getTime(),
                dataSource: vm.pairValuesList
            }
        }

        function currentGenericElementHasChildren(currentGenericElement) {
            var hasChildren = Lazy(vm.currentGenericElements)
                .pluck("ParentGenericElementId")
                .contains(currentGenericElement.GenericElementId);
            return hasChildren;
        }

        function addNewPairValueCodeToList(pairValue) {
            vm.pairValuesList.push({
                key: pairValue.PairValueCode,
                value: pairValue.PairValueName
            });
        }

        function getNewGenericElement(GenericElementTypeId) {
            return utilService.dataContext.genericElements.add({
                IsCrossContext: true,
                Deep: genericElementSelected.genericElement.Deep + 1,
                ParentGenericElementId: genericElementSelected.genericElement.GenericElementId,
                GenericElementTypeId: GenericElementTypeId
            });
        }

        function getGenericElementsSubTree(forceReload, genericElementCode) {
            var filter = { GenericElementCode: genericElementCode };
            var defer = $q.defer();

            utilService.getGenericElementsSubTree(filter, forceReload)
                .then(function (data) {
                    vm.currentGenericElements = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    genericElementsManage.$inject = injectParams;
    genericElementsManage.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('genericElementsManage', genericElementsManage);
});
