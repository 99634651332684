﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'audService', 'reportService', 'navigationService', 'checkStateChangeService', 'contextService', 'utilService'];
    var htmlToPdfSurveyVhrTargetSynthesisReportForm = function ($q, $scope, $timeout, $injector, foundationService, audService, reportService, navigationService, checkStateChangeService, contextService, utilService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        var checkStateChangeParams = { dirtyFlagIsDisabled: vm.isDirtyDisabled };
        checkStateChangeService.checkFormOnStateChange($scope, foundationService, checkStateChangeParams);

        // all form items are enabled
        vm.setIsEditingReport();

        // entity to validate
        //vm.htmlToPdfAudActsKpiClientData = audService.dataContext.htmlToPdfAudActsKpiClientDatas.add({
        //    HtmlToPdfAudActsKpiClientDataId: NbsGuidManager.newGuid()
        //});

        var appStoreCode = 'aud';
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var surveyLifeStatusEnumParsed = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyLifeStatus, 'SurveyDto', vm.translate);
        var surveyLifeStatusObj = surveyLifeStatusEnumParsed.obj.normal.name_value;
        var expressionTypeEnum = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.ExpressionType, null, vm.translate).obj.normal.name_value;

        var dsCallback = function () { return foundationService.getVhrTypes(false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrType', viewTitle: vm.translate('TargetDto.TargetTypeId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrType = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);


        dsCallback = function () { return foundationService.getVhrGroups(false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrGroup', viewTitle: vm.translate('TargetDto.TargetGroupId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrGroup = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        //var priorityObj = Nembus.Common.Library.util.parseEnum(actService.dataContext.enumTypes.ActPriority, null, vm.translate).obj.normal.name_value;
        var addEmptyActionAssignment = false;
        var stepsToRender = [];


        // init filters
        vm.reportFilter = {};
        vm.surveyVhrEventFilter = {
            ExcludeOnDemand: true
        };
        vm.surveyFilter = {};
        vm.vhrEventFilter = { ModuleTargetId: moduleTargetId };
        vm.virtualHrFilter = {};

        // REFERS to 'reportDefinitionHtmlToDocDetail'
        vm.parentScope = $scope.$parent.vm;
        vm.parentScope.ribbonDatacontext.setRibbonStatusButton(vm.parentScope.ribbonDatacontext.Buttons.PrintReportPdf, true);
        vm.parentScope.ribbonDatacontext.refreshStatusButtons();
        vm.parentScope.initReportToDocHeader({ onSearchActionHtmlToDocFn: null, onCreateHtmlFn: createHtmlReport });

        //#region Multiselect with grid selection
        var selectionParams = { destModalKey: 'vhr', entityMode: 'Target', translateKey: 'Target', isMultiselect: true, selectableEntities: ['VirtualEquipment', 'Target'] };
        vm.nbsSelectionGridWithModalTarget = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        vm.nbsActionDrbacModalObj = new NbsAssigneeSelectorModal({
            translator: vm.translate,
            suspendDirty: vm.suspendDirty,
            navigationService: navigationService,
            contextUserForSelection: null,
            getServerVarFn: Nembus.Common.Library.server.getServerVar
        });
        vm.nbsActionDrbacModalObj.viewUsers();
        vm.nbsActionDrbacModalObj.viewUserGroups();

        vm.nbsCompilerDrbacModalObj = new NbsAssigneeSelectorModal({
            translator: vm.translate,
            suspendDirty: vm.suspendDirty,
            navigationService: navigationService,
            contextUserForSelection: null,
            getServerVarFn: Nembus.Common.Library.server.getServerVar
        });
        vm.nbsCompilerDrbacModalObj.viewUsers();

        // set title of current controller in report definition detail view
        navigationService.setControllerTitle();

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getGenericElementsVhrEventStatusColor(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    if (results[0]) {
                        vm.lifeStatusColorsObj = results[0];
                    }
                });
        }

        vm.htmlSteps = {
            SURVEY_ASSIGNMENT: 'surveyassignment-render'
        };

        function createHtmlReport() {
            vm.surveyVhrEventFilter.SurveyCompileDateSystem1Expression = expressionTypeEnum.GreaterThanOrEqual;
            vm.surveyVhrEventFilter.SurveyCompileDateSystem2Expression = expressionTypeEnum.LessThanOrEqual;
            vm.surveyVhrEventFilter.CompilerUserPersonIds = vm.nbsCompilerDrbacModalObj.appUserMultiselectSelectedIds();

            vm.vhrEventFilter.StartDate1Expression = expressionTypeEnum.GreaterThanOrEqual;
            vm.vhrEventFilter.StartDate2Expression = expressionTypeEnum.LessThanOrEqual;
            vm.vhrEventFilter.UserGroupAssignedIds = vm.nbsActionDrbacModalObj.drbacUserGroupMultiselectSelectedIds();
            vm.vhrEventFilter.AppUserAssignedIds = vm.nbsActionDrbacModalObj.appUserMultiselectSelectedIds();


            vm.virtualHrFilter.VhrTypesId = vm.nbsSelectionGridWithModalVhrType.selectedIds ? vm.nbsSelectionGridWithModalVhrType.selectedIds : [];
            vm.virtualHrFilter.VhrGroupsId = vm.nbsSelectionGridWithModalVhrGroup.selectedIds ? vm.nbsSelectionGridWithModalVhrGroup.selectedIds : [];
            vm.virtualHrFilter.VirtualHumanResourcesId = vm.nbsSelectionGridWithModalTarget.selectedIds ? vm.nbsSelectionGridWithModalTarget.selectedIds : [];

            var funcsPromises = [];
            funcsPromises.push(getSurveyDtos(true, vm.surveyFilter, vm.vhrEventFilter, vm.surveyVhrEventFilter, vm.virtualHrFilter));

            $q.all(funcsPromises)
                .then(function (results) {
                    var surveys = results[0];
                    parseReportHtmlDatasource(surveys);
                });
        }

        function getDayDiff(startDate, compareDate) {
            if (!startDate) {
                startDate = moment(Date.Now).toDate();
            }

            var a = moment(startDate);
            var b = moment(compareDate);
            return a.diff(b, 'days');
        }

        // prepare datasource for report in UI (to prepare Html)
        function parseReportHtmlDatasource(surveys) {
            setReportFilterData();

            stepsToRender = [];

            var countSurveys = surveys.length;
            var assignmentTypes = {
                User: 'User',
                UserGroup: 'UserGroup',
                NoAssignment: 'NoAssignment'
            };

            // object binded on UI
            vm.bindObject = {
                auditLifeStatus: {
                    toCompile: 0,
                    compiled: 0,
                    toCompilePercent: 0,
                    compiledPercent: 0
                },
                //priority: {
                //    high: 0,
                //    medium: 0,
                //    low: 0,
                //    highTranslation: priorityObjTranslated[priorityObj.High],
                //    mediumTranslation: priorityObjTranslated[priorityObj.Medium],
                //    lowTranslation: priorityObjTranslated[priorityObj.Low]
                //},
                surveys: {
                    table: {
                        // nuovo
                        createdNotExpired: 0,
                        createdExpiredLess7days: 0,
                        createdExpiredLess30days: 0,
                        createdExpiredLess60days: 0,
                        createdExpiredMore60days: 0,
                        // in corso
                        runningNotExpired: 0,
                        runningExpiredLess7days: 0,
                        runningExpiredLess30days: 0,
                        runningExpiredLess60days: 0,
                        runningExpiredMore60days: 0,
                        // in corso
                        reportedAsFinishedNotExpired: 0,
                        reportedAsFinishedExpiredLess7days: 0,
                        reportedAsFinishedExpiredLess30days: 0,
                        reportedAsFinishedExpiredLess60days: 0,
                        reportedAsFinishedExpiredMore60days: 0
                    },
                    assignments: [],
                    grouped: []
                }
            };

            // workflow status
            vm.bindObject.auditLifeStatus.toCompileCount = Lazy(surveys)
                .filter((survey) => { return survey.SurveyLifeStatusId === surveyLifeStatusObj.Created || survey.SurveyLifeStatusId === surveyLifeStatusObj.Running; })
                .toArray().length;
            vm.bindObject.auditLifeStatus.compiledCount = Lazy(surveys)
                .filter((survey) => { return survey.SurveyLifeStatusId !== surveyLifeStatusObj.Created && survey.SurveyLifeStatusId !== surveyLifeStatusObj.Running; })
                .toArray().length;
            vm.bindObject.auditLifeStatus.toCompilePercent = getPercentValue(vm.bindObject.auditLifeStatus.toCompileCount, countSurveys);
            vm.bindObject.auditLifeStatus.compiledPercent = getPercentValue(vm.bindObject.auditLifeStatus.compiledCount, countSurveys);

            // priority
            //vm.bindObject.priority.highCount = Lazy(actions).where({ ActPriorityId: priorityObj.High }).toArray().length;
            //vm.bindObject.priority.mediumCount = Lazy(actions).where({ ActPriorityId: priorityObj.Medium }).toArray().length;
            //vm.bindObject.priority.lowCount = Lazy(actions).where({ ActPriorityId: priorityObj.Low }).toArray().length;
            //vm.bindObject.priority.highPercent = getPercentValue(vm.bindObject.priority.highCount, countActions);
            //vm.bindObject.priority.mediumPercent = getPercentValue(vm.bindObject.priority.mediumCount, countActions);
            //vm.bindObject.priority.lowPercent = getPercentValue(vm.bindObject.priority.lowCount, countActions);

            // action table data
            Lazy(surveys)
                .each(survey => {
                    if (survey.SurveyExpireDate) {
                        var days = 0;
                        if (survey.SurveyLifeStatusId === surveyLifeStatusObj.Created) {
                            days = getDayDiff(null, survey.SurveyExpireDate);
                            if (days <= 0) {
                                vm.bindObject.surveys.table.createdNotExpired += 1;
                            } else if (days >= 60) {
                                vm.bindObject.surveys.table.createdExpiredMore60days += 1;
                            } else if (days < 7) {
                                vm.bindObject.surveys.table.createdExpiredLess7days += 1;
                            } else if (days < 30) {
                                vm.bindObject.surveys.table.createdExpiredLess30days += 1;
                            } else if (days < 60) {
                                vm.bindObject.surveys.table.createdExpiredLess60days += 1;
                            }
                        } else if (survey.SurveyLifeStatusId === surveyLifeStatusObj.Running) {
                            days = getDayDiff(null, survey.SurveyExpireDate);
                            if (days <= 0) {
                                vm.bindObject.surveys.table.runningNotExpired += 1;
                            } else if (days >= 60) {
                                vm.bindObject.surveys.table.runningExpiredMore60days += 1;
                            } else if (days < 7) {
                                vm.bindObject.surveys.table.runningExpiredLess7days += 1;
                            } else if (days < 30) {
                                vm.bindObject.surveys.table.runningExpiredLess30days += 1;
                            } else if (days < 60) {
                                vm.bindObject.surveys.table.runningExpiredLess60days += 1;
                            }
                        } else if (survey.SurveyLifeStatusId === surveyLifeStatusObj.ReportedAsFinished) {
                            days = getDayDiff(survey.SurveyCompileDateSystem, survey.SurveyExpireDate);
                            if (days <= 0) {
                                vm.bindObject.surveys.table.reportedAsFinishedNotExpired += 1;
                            } else if (days >= 60) {
                                vm.bindObject.surveys.table.reportedAsFinishedExpiredMore60days += 1;
                            } else if (days < 7) {
                                vm.bindObject.surveys.table.reportedAsFinishedExpiredLess7days += 1;
                            } else if (days < 30) {
                                vm.bindObject.surveys.table.reportedAsFinishedExpiredLess30days += 1;
                            } else if (days < 60) {
                                vm.bindObject.surveys.table.reportedAsFinishedExpiredLess60days += 1;
                            }
                        }
                    }
                });

            // assignemnt surveys
            var allAssignmentSurveys = [];
            Lazy(surveys)
                .each((survey) => {
                    var userGroups = JSON.parse(Nembus.Common.Library.converter.decodeHtml(survey.UserGroupsAssignedSerialized));
                    var users = JSON.parse(Nembus.Common.Library.converter.decodeHtml(survey.UsersAssignedSerialized));

                    if (Lazy(userGroups).some() || Lazy(users).some()) {
                        Lazy(userGroups)
                            .each((userGroup) => {
                                userGroup.Kind = assignmentTypes.UserGroup;

                                var existingUserGroup = Lazy(allAssignmentSurveys).findWhere({ Value: userGroup.Value, Kind: userGroup.Kind });
                                if (!existingUserGroup) {
                                    userGroup = setSurveyCounts(userGroup, survey, countSurveys, surveyLifeStatusObj);
                                    allAssignmentSurveys.push(userGroup);
                                } else {
                                    existingUserGroup = setSurveyCounts(existingUserGroup, survey, countSurveys, surveyLifeStatusObj);
                                }
                            });

                        Lazy(users)
                            .each((user) => {
                                user.Kind = assignmentTypes.User;

                                var existingUser = Lazy(allAssignmentSurveys).findWhere({ Value: user.Value, Kind: user.Kind });
                                if (!existingUser) {
                                    user = setSurveyCounts(user, survey, countSurveys, surveyLifeStatusObj);

                                    allAssignmentSurveys.push(user);
                                } else {
                                    existingUser = setSurveyCounts(existingUser, survey, countSurveys, surveyLifeStatusObj);
                                }
                            });
                    } else {
                        if (addEmptyActionAssignment) {
                            var existingNoAssigned = Lazy(allAssignmentSurveys).findWhere({ Kind: assignmentTypes.NoAssignment });
                            if (!existingNoAssigned) {
                                var noAssigned = {
                                    Name: ' - ',
                                    Kind: assignmentTypes.NoAssignment
                                };

                                noAssigned = setSurveyCounts(noAssigned, survey, countSurveys, surveyLifeStatusObj);

                                allAssignmentSurveys.push(noAssigned);
                            } else {
                                existingNoAssigned = setSurveyCounts(existingNoAssigned, survey, countSurveys, surveyLifeStatusObj);
                            }
                        }
                    }
                });

            //// bind to UI
            if (allAssignmentSurveys.length > 0) {
                stepsToRender.push(vm.htmlSteps.SURVEY_ASSIGNMENT);
                vm.bindObject.surveys.assignments = Lazy(allAssignmentSurveys).sortBy((el) => { return el.surveyCounts.percentReportedAsFinished; }, true).toArray();
            }


            // set steps to render before html retrieve from directive
            vm.parentScope.htmlToDocManager.setHtmlStepsToRender(stepsToRender);

            if (!vm.parentScope.htmlToDocManager.hasHtmlStepsToRender()) {
                vm.parentScope.htmlToDocManager.htmlIsRendered();
            }
        }

        function setSurveyCounts(entity, survey, countTotal, surveyLifeStatus) {
            var surveyCounts = {
                count: 0,
                countCreated: 0,
                countRunning: 0,
                countReportedAsFinished: 0,
                percentCreated: 0,
                percentRunning: 0,
                percentReportedAsFinished: 0,
                percentCreatedTotal: 0,
                percentRunningTotal: 0,
                percentReportedAsFinishedTotal: 0
            };

            var stackedBar = {
                barCreatedPercent: 0,
                barRunningPercent: 0,
                barReportedAsFinishedPercent: 0,
                barRemainingPercent: 100
            };

            if (!entity.surveyCounts) {
                entity.surveyCounts = surveyCounts;
            }

            if (!entity.stackedBar) {
                entity.stackedBar = stackedBar;
            }

            entity.surveyCounts.count += 1;

            if (survey.SurveyLifeStatusId === surveyLifeStatus.Created) {
                entity.surveyCounts.countCreated += 1;
            } else if (survey.SurveyLifeStatusId === surveyLifeStatus.Running) {
                entity.surveyCounts.countRunning += 1;
            } else if (survey.SurveyLifeStatusId === surveyLifeStatus.ReportedAsFinished) {
                entity.surveyCounts.countReportedAsFinished += 1;
            }

            entity.surveyCounts.percentTotal = getPercentValue(entity.surveyCounts.count, countTotal);
            entity.surveyCounts.percentCreated = getPercentValue(entity.surveyCounts.countCreated, entity.surveyCounts.count);
            entity.surveyCounts.percentRunning = getPercentValue(entity.surveyCounts.countRunning, entity.surveyCounts.count);
            entity.surveyCounts.percentReportedAsFinished = getPercentValue(entity.surveyCounts.countReportedAsFinished, entity.surveyCounts.count);
            entity.surveyCounts.percentCreatedTotal = getPercentValue(entity.surveyCounts.countRunning, countTotal);
            entity.surveyCounts.percentRunningTotal = getPercentValue(entity.surveyCounts.countCreated, countTotal);
            entity.surveyCounts.percentReportedAsFinishedTotal = getPercentValue(entity.surveyCounts.countReportedAsFinished, countTotal);

            // calculate stacked bar (4 bars percents)
            if (entity.surveyCounts.percentReportedAsFinished > 0) {
                entity.stackedBar.barCreatedPercent = Number(entity.surveyCounts.percentCreated / 100 * entity.surveyCounts.percentReportedAsFinished);
            }
            if (entity.surveyCounts.percentReportedAsFinished > 0) {
                entity.stackedBar.barRunningPercent = Number(entity.surveyCounts.percentRunning / 100 * entity.surveyCounts.percentReportedAsFinished);
            }
            if (entity.surveyCounts.percentReportedAsFinished > 0) {
                entity.stackedBar.barReportedAsFinishedPercent = Number(entity.surveyCounts.percentReportedAsFinished / 100 * entity.surveyCounts.percentReportedAsFinished);
            }
            entity.stackedBar.barRemainingPercent = 100 - entity.stackedBar.barRunningPercent - entity.stackedBar.barCreatedPercent - entity.stackedBar.barReportedAsFinishedPercent;

            return entity;
        }

        function getPercentValue(n, count) {
            var result = count > 0 ? n * 100 / count : 0;
            return Number(result.toFixed(0));
        }

        function getSurveyDtos(forceReload, surveyFilter, vhrEventFilter, surveyVhrEventFilter, virtualHrFilter) {
            var filter = {
                surveyFilter: surveyFilter,
                vhrEventFilter: vhrEventFilter,
                surveyVhrEventFilter: surveyVhrEventFilter,
                surveySectionFilter: {},
                surveyQuestionFilter: {},
                surveyQuestionOptionFilter: {},
                virtualHrFilter: virtualHrFilter
            };
            var defer = $q.defer();

            audService.getSurveyDtos(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getGenericElementsVhrEventStatusColor(forceReload) {
            var filter = { GenericElementCode: 'Configurations.Surveys.LifeStatusColors' };
            var defer = $q.defer();

            utilService.getGenericElements(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results[0].PairValuesObject);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function setReportFilterData() {
            vm.reportFilterData = new NbsReportFilterData(vm.translate, contextService.virtualContext.cultureInfo, Nembus.Common.Library.converter.date.formatDate);

            vm.reportFilterData.setDataBySelectionModal('TargetDto', 'TargetDto.TargetDto', vm.nbsSelectionGridWithModalTarget);
            vm.reportFilterData.setDataBySelectionModal('TargetGroupId', 'TargetDto.TargetGroupId', vm.nbsSelectionGridWithModalVhrGroup);
            vm.reportFilterData.setDataBySelectionModal('TargetTypeId', 'TargetDto.TargetTypeId', vm.nbsSelectionGridWithModalVhrType);

            // filter in report are visualized in current order 
            vm.reportFilterData.setDataByRangeDate('SurveyCompileDate', 'SurveyDto.SurveyCompileDate', vm.surveyVhrEventFilter.SurveyCompileDateSystem1, vm.surveyVhrEventFilter.SurveyCompileDateSystem2);
            vm.reportFilterData.setDataByRangeDate('SurveyExpireDate', 'SurveyDto.SurveyExpireDate', vm.vhrEventFilter.StartDate1, vm.vhrEventFilter.StartDate2);
            vm.reportFilterData.setDataByDrbacAuthSelectorModal('CompilerUserPersonId', 'SurveyVhrEvent.CompilerUserPersonId', vm.nbsCompilerDrbacModalObj);
            vm.reportFilterData.setDataByDrbacAuthSelectorModal('AudAssignee', 'AudAssignee', vm.nbsActionDrbacModalObj);
        }
    };

    htmlToPdfSurveyVhrTargetSynthesisReportForm.$inject = injectParams;
    htmlToPdfSurveyVhrTargetSynthesisReportForm.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('htmlToPdfSurveyVhrTargetSynthesisReportForm', htmlToPdfSurveyVhrTargetSynthesisReportForm);
});
