﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'reportService', 'navigationService', 'checkStateChangeService', 'contextService', 'vhreventService', 'productionService', 'utilService', 'managementService'];
    var vhrEventsListPricedReportForm = function ($q, $scope, $timeout, $injector, foundationService, reportService, navigationService, checkStateChangeService, contextService, vhreventService, productionService, utilService, managementService) {

        var vm = this;
        nbsVm.call(vm, reportService, navigationService, contextService, $q, $injector, $timeout);
        var checkStateChangeParams = { dirtyFlagIsDisabled: vm.isDirtyDisabled };
        checkStateChangeService.checkFormOnStateChange($scope, reportService, checkStateChangeParams);

        var currentSystemCategoryCode = navigationService.stateParams().systemCategoryCode;
        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var expressionTypeEnum = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.ExpressionType, null, vm.translate).obj.normal.name_value;
        var catPathParamSeparator = navigationService.stateParams().catPathParamSeparator;
        var catPathParamTake = navigationService.stateParams().catPathParamTake;
        var catPathParamSkip = navigationService.stateParams().catPathParamSkip;
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);

        // all form items are enabled
        vm.setIsEditingReport();

        var selectionParams = { destModalKey: 'category', systemCategoryCode: currentSystemCategoryCode };
        vm.nbsSelectionTreeviewWithModalCategory = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategory.setOnSelectFnCallback(function () {
            if (vm.nbsSelectionTreeviewWithModalCategory.selectedIds) {
                reportService.currentReport.reportArgs.Ds1_Arg1.CategoriesId = vm.nbsSelectionTreeviewWithModalCategory.selectedIds;
            } else {
                reportService.currentReport.reportArgs.Ds1_Arg1.CategoriesId = null;
            }
        });

        var vhrEventBillingStatusEnumParsed = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventBillingStatus, 'VhrEvent', vm.translate);
        vm.vhrEventBillingStatus = vhrEventBillingStatusEnumParsed.list.translated;
        vm.vhrEventTypes = [];
        vm.vhrEventGroups = [];

        // remove status used for job request
        var vhrEventLifeStatusEnumParsed = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventLifeStatus, 'VhrEvent', vm.translate);
        vm.vhrEventLifeStatus = Lazy(vhrEventLifeStatusEnumParsed.list.translated)
            //.reject({ value: vhrEventLifeStatusEnum.None.toString() })
            .reject({ value: vhrEventLifeStatusEnumParsed.obj.normal.name_value.Created })
            .reject({ value: vhrEventLifeStatusEnumParsed.obj.normal.name_value.Released })
            .reject({ value: vhrEventLifeStatusEnumParsed.obj.normal.name_value.Running })
            .toArray();

        // entity used for client validation
        vm.vhrEventPricedReportFormClientData = reportService.dataContext.vhrEventPricedReportFormClientDatas.add({
            ManagementContractId: null,
            IsCustomer: false
        });

        // posted filter
        vm.currentReportFilter = {};

        // set title of current controller in report definition detail view
        navigationService.setControllerTitle();

        reportService.currentReport.reportArgs = {
            Ds1_Arg1: {//VhrEventFilter
                SystemCategoryCodeRootSubtree: currentSystemCategoryCode,
                AppStoreBw: appStoreBw,
                StartDate1Expression: expressionTypeEnum.GreaterThanOrEqual,
                StartDate2Expression: expressionTypeEnum.LessThanOrEqual,
                VhrEventKind: 'Data',
                GroupedBy: 'activityexternalcode',
                IsGroupedBy: true,
                ModuleTargetId: moduleTargetId,
                WorkCentersId: null,
                IsExecuted: null,
                IsApproved: null
                //VhrEventLifeStatusExpression: expressionTypeEnum.LessThanOrEqual,
                //VhrEventBillingStatusExpression: expressionTypeEnum.LessThanOrEqual,
            },
            Ds1_Arg2: {//ManagementContractFilter
                ManagementContractId: null
            },
            Ds1_Arg3: {//CategoryPathParam
                Separator: catPathParamSeparator,
                Take: catPathParamTake,
                Skip: catPathParamSkip
            }
        };

        loadData(true);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getVhrEventGroups(forceReload, appStoreBw));
            funcsPromises.push(getVhrEventTypes(forceReload, appStoreBw));
            funcsPromises.push(getManagementContracts(forceReload));
            funcsPromises.push(getVirtualWorkCenters(forceReload));

            $q.all(funcsPromises)
                .then(function () {
                    reportService.currentReport.formEntityToValidate = vm.vhrEventPricedReportFormClientData;

                    // bind report object to ui filter
                    vm.currentReportFilter = reportService.currentReport.reportArgs.Ds1_Arg1;
                });
        }

        vm.workCenterOnchange = function () {
            vm.currentReportFilter.WorkCentersId = vm.currentReportFilter.tempWorkCentersId ? [vm.currentReportFilter.tempWorkCentersId] : null;
        };

        vm.vhrEventLifeStatusOnchange = function () {
            if (vm.currentReportFilter.VhrEventLifeStatusId) {
                reportService.currentReport.reportArgs.Ds1_Arg1.VhrEventLifeStatusIds = [vm.currentReportFilter.VhrEventLifeStatusId];
                reportService.currentReport.reportArgs.Ds1_Arg1.VhrEventLifeStatusExpression = expressionTypeEnum.Equal;
            } else {
                reportService.currentReport.reportArgs.Ds1_Arg1.VhrEventLifeStatusIds = null;
                reportService.currentReport.reportArgs.Ds1_Arg1.VhrEventLifeStatusExpression = null;
            }
        };

        vm.vhrEventBillingStatusOnchange = function () {
            if (vm.currentReportFilter.VhrEventBillingStatusId) {
                reportService.currentReport.reportArgs.Ds1_Arg1.VhrEventBillingStatusIds = [vm.currentReportFilter.VhrEventBillingStatusId];
                reportService.currentReport.reportArgs.Ds1_Arg1.VhrEventBillingStatusExpression = expressionTypeEnum.Equal;
            } else {
                reportService.currentReport.reportArgs.Ds1_Arg1.VhrEventBillingStatusIds = null;
                reportService.currentReport.reportArgs.Ds1_Arg1.VhrEventBillingStatusExpression = null;
            }
        };

        vm.vhrEventGroupOnchange = function () {
            reportService.currentReport.reportArgs.Ds1_Arg1.VhrEventGroupsId = vm.currentActivity.VhrEventGroupId ? [vm.currentActivity.VhrEventGroupId] : null;
        };

        vm.vhrEventTypeOnchange = function () {
            reportService.currentReport.reportArgs.Ds1_Arg1.VhrEventTypesId = vm.currentActivity.VhrEventTypeId ? [vm.currentActivity.VhrEventTypeId] : null;
        };

        vm.managementContractsOnchange = function () {
            reportService.currentReport.reportArgs.Ds1_Arg2.ManagementContractId = vm.vhrEventPricedReportFormClientData.ManagementContractId;
        };

        function getVirtualWorkCenters(forceReload) {
            var filter = {};
            var defer = $q.defer();

            productionService.getVirtualWorkCenters(filter, forceReload)
                .then(function (data) {
                    vm.currentWorkCenters = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrEventTypes(forceReload, appStoreBw) {
            var filter = { AppStoreBw: appStoreBw };
            var defer = $q.defer();

            vhreventService.getVhrEventTypes(filter, forceReload)
                .then(function (data) {
                    vm.vhrEventTypes = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrEventGroups(forceReload, appStoreBw) {
            var filter = { AppStoreBw: appStoreBw };
            var defer = $q.defer();

            vhreventService.getVhrEventGroups(filter, forceReload)
                .then(function (data) {
                    vm.vhrEventGroups = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getManagementContracts(forceReload) {
            var defer = $q.defer();

            managementService.getManagementContracts({ IsEnabled: true }, forceReload)
                .then(function (data) {
                    vm.currentManagementContracts = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    vhrEventsListPricedReportForm.$inject = injectParams;
    vhrEventsListPricedReportForm.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('vhrEventsListPricedReportForm', vhrEventsListPricedReportForm);
});
