﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'drbacService', 'utilService', 'vhreventService', 'foundationService', 'reportService', 'navigationService', 'checkStateChangeService', 'contextService', 'audService'];
    var surveysListReportForm = function ($q, $scope, $timeout, $injector, drbacService, utilService, vhreventService, foundationService, reportService, navigationService, checkStateChangeService, contextService, audService) {

        var vm = this;
        nbsVm.call(vm, reportService, navigationService, contextService, $q, $injector, $timeout);
        var checkStateChangeParams = { dirtyFlagIsDisabled: vm.isDirtyDisabled };
        checkStateChangeService.checkFormOnStateChange($scope, reportService, checkStateChangeParams);

        // all form items are enabled
        vm.setIsEditingReport();

        var appStoreCode = 'aud';
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
        var expressionTypeEnum = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.ExpressionType, null, vm.translate).obj.normal.name_value;

        var dsCallback = function () { return vhreventService.getVhrEventTypes({ AppStoreBw: appStoreBw }, false); };
        var selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventType', viewTitle: vm.translate('SurveyDto.VhrEventTypeId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventType = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        dsCallback = function () { return vhreventService.getVhrEventGroups({ AppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventGroup', viewTitle: vm.translate('SurveyDto.VhrEventGroupId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventGroup = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        dsCallback = function () { return foundationService.getVhrTypes(false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrType', viewTitle: vm.translate('TargetDto.TargetTypeId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrType = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        dsCallback = function () { return foundationService.getVhrGroups(false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrGroup', viewTitle: vm.translate('TargetDto.TargetGroupId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrGroup = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        selectionParams = { destModalKey: 'vhr', entityMode: 'Target', translateKey: 'Target', isMultiselect: true };
        vm.nbsSelectionGridWithModalTarget = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        vm.isProcessEnabled = Nembus.Common.Library.auth.isProcessEnabled(contextService.virtualContext, moduleTargetId);

        vm.surveysListReportFormClientData = audService.dataContext.surveyReportClientDatas.add({
            IsProcessEnabled: vm.isProcessEnabled,
            DrbacProcessId: null,
            IsArchived: null
        });


        // set title of current controller in report definition detail view
        navigationService.setControllerTitle();

        loadData(true);
        function loadData(forceReload) {
            var funcsPromises = [];
            if (vm.isProcessEnabled) {
                funcsPromises.push(getDrbacProcesses(forceReload, virtualCompanyId));
            }

            $q.all(funcsPromises)
                .then(function () {
                    reportService.currentReport.formEntityToValidate = vm.surveysListReportFormClientData;
                    reportService.currentReport.prepareReportFilterCallback = setReportArgs;
                });
        }


        vm.drbacProcessOnChange = function () {
            reportService.currentReport.reportArgs.Ds1_Arg2.DrbacProcessId = vm.surveysListReportFormClientData.DrbacProcessId;

            if (!vm.surveysListReportFormClientData.DrbacProcessId) {
                vm.vhrEventTypesAreVisible = false;
                vm.vhrEventGroupsAreVisible = false;
                return;
            }

            // a process is selected
            var forceReload = true;
            vm.vhrEventTypesAreVisible = true;
            vm.vhrEventGroupsAreVisible = true;

            var processSelected = Lazy(vm.drbacProcesses).findWhere({ DrbacProcessId: Number(vm.surveysListReportFormClientData.DrbacProcessId) });
            var vhrEventMatcherId = processSelected.VhrEventMatcherId;

            var funcsPromises = [];
            funcsPromises.push(getVhrEventMatcherTargetActivities_VhrEventTypes(forceReload, vhrEventMatcherId));
            funcsPromises.push(getVhrEventMatcherTargetActivities_VhrEventGroups(forceReload, vhrEventMatcherId));

            $q.all(funcsPromises)
                .then(function () {
                    //var selectedIds = [];

                    //// set types datasource and select ids
                    //vm.vhrEventTypesAcDs = new NbsMultiselect(vm.translate);
                    //vm.vhrEventTypesAcDs.addData(vm.currentVhrEventTypes, 'VhrEventTypeId', 'VhrEventTypeName');
                    //selectedIds = Lazy(vm.currentVhrEventTypes).pluck('VhrEventTypeId').toArray();
                    //vm.vhrEventTypesAcDs.setSelected(selectedIds);

                    //// set groups datasource and select ids
                    //vm.vhrEventGroupsAcDs = new NbsMultiselect(vm.translate);
                    //vm.vhrEventGroupsAcDs.addData(vm.currentVhrEventGroups, 'VhrEventGroupId', 'VhrEventGroupName');
                    //selectedIds = Lazy(vm.currentVhrEventGroups).pluck('VhrEventGroupId').toArray();
                    //vm.vhrEventGroupsAcDs.setSelected(selectedIds);
                });

        };

        //target vhreventstypes at moment binded to hr group
        function getVhrEventMatcherTargetActivities_VhrEventTypes(forceReload, vhrEventMatcherId) {
            var defer = $q.defer();

            vhreventService.getVhrEventMatchersTargetActivities_VhrEventTypes({ VhrEventMatcherId: vhrEventMatcherId }, forceReload)
                .then(function (data) {
                    vm.currentVhrEventTypes = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        //target vhreventgroups at moment binded to hr group
        function getVhrEventMatcherTargetActivities_VhrEventGroups(forceReload, vhrEventMatcherId) {
            var defer = $q.defer();

            vhreventService.getVhrEventMatchersTargetActivities_VhrEventGroups({ VhrEventMatcherId: vhrEventMatcherId }, forceReload)
                .then(function (data) {
                    vm.currentVhrEventGroups = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getDrbacProcesses(forceReload, virtualCompanyId) {
            var filter = {
                VirtualCompanyId: virtualCompanyId,
                IsHidden: false,
                IsGlobalProcess: false,
                IsEnabled: true
            };
            var defer = $q.defer();

            drbacService.getDrbacProcesses(filter, forceReload)
                .then(function (data) {
                    vm.drbacProcesses = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function setReportArgs() {
            var reportFilterData = new NbsReportFilterData(vm.translate, contextService.virtualContext.cultureInfo, Nembus.Common.Library.converter.date.formatDate);
            reportFilterData.setDataByRangeDate('SurveyExpireDate', 'SurveyDto.SurveyExpireDate', vm.surveysListReportFormClientData.SurveyExpireDate1, vm.surveysListReportFormClientData.SurveyExpireDate2);
            reportFilterData.setDataByRangeDate('SurveyCompileDate', 'SurveyDto.SurveyCompileDate', vm.surveysListReportFormClientData.SurveyCompileDateSystem1, vm.surveysListReportFormClientData.SurveyCompileDateSystem2);
            reportFilterData.setDataBySelectionModal('VhrEventGroupId', 'SurveyDto.VhrEventGroupId', vm.nbsSelectionGridWithModalVhrEventGroup);
            reportFilterData.setDataBySelectionModal('VhrEventTypeId', 'SurveyDto.VhrEventTypeId', vm.nbsSelectionGridWithModalVhrEventType);
            reportFilterData.setDataByObjectList('IsArchived', 'SurveyDto.IsArchived', [vm.surveysListReportFormClientData.IsArchived], vm.booleanSelectionForDropDown, 'value', ['name']);
            reportFilterData.setDataBySelectionModal('TargetId', 'SurveyDto.TargetId', vm.nbsSelectionGridWithModalTarget);
            reportFilterData.setDataBySelectionModal('TargetGroupId', 'TargetDto.TargetGroupId', vm.nbsSelectionGridWithModalVhrGroup);
            reportFilterData.setDataBySelectionModal('TargetTypeId', 'TargetDto.TargetTypeId', vm.nbsSelectionGridWithModalVhrType);

            reportService.currentReport.reportArgs = {
                Ds1_Arg1: {     //SurveyFilter
                    SurveyId: null,
                    IsArchived: vm.surveysListReportFormClientData.IsArchived
                },
                Ds1_Arg2: {     //VhrEventFilter
                    ModuleTargetId: moduleTargetId,
                    StartDate1: vm.surveysListReportFormClientData.SurveyExpireDate1,
                    StartDate1Expression: expressionTypeEnum.GreaterThanOrEqual,
                    StartDate2: vm.surveysListReportFormClientData.SurveyExpireDate2,
                    StartDate2Expression: expressionTypeEnum.LessThanOrEqual,
                    HaveToExpandVhrsWithAssetTree: true,
                    VhrEventTypesId: vm.nbsSelectionGridWithModalVhrEventType.selectedIds,
                    VhrEventGroupsId: vm.nbsSelectionGridWithModalVhrEventGroup.selectedIds,
                    VirtualHumanResourcesId: vm.nbsSelectionGridWithModalTarget.selectedIds
                },
                Ds1_Arg3: {     //VirtualHrFilter
                    VhrGroupsId: vm.nbsSelectionGridWithModalVhrGroup.selectedIds,
                    VhrTypesId: vm.nbsSelectionGridWithModalVhrType.selectedIds
                },
                Ds1_Arg4: {     //SurveyVhrEventFilter
                    SurveyCompileDateSystem1: vm.surveysListReportFormClientData.SurveyCompileDateSystem1,
                    SurveyCompileDateSystem1Expression: expressionTypeEnum.GreaterThanOrEqual,
                    SurveyCompileDateSystem2: vm.surveysListReportFormClientData.SurveyCompileDateSystem2,
                    SurveyCompileDateSystem2Expression: expressionTypeEnum.LessThanOrEqual
                },
                Ds1_Arg5: { Datas: reportFilterData.Datas }
            };
        }
    };

    surveysListReportForm.$inject = injectParams;
    surveysListReportForm.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('surveysListReportForm', surveysListReportForm);
});
