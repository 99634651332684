﻿define(function () {

    function nbsVm(currentDataService, currentNavigationService, contextService, $q, $injector, $timeout) {
        var self = this;

        //#region HOOK FROM RIBBON COMPONENT
        self.onRibbonComponentAction = function (args) {
            if (!args) {
                return;
            }

            if (!args.method) {
                return;
            }

            if (args.method.startsWith('window.') || args.method.startsWith('Nembus.')) {
                var fn = eval(args.method);
                if (typeof fn === "function") {
                    fn();
                }
            } else {
                if (self[args.method] && typeof self[args.method] === "function") {
                    self[args.method]();
                }
            }
        }
        //#endregion

        self.currentNavigationService = currentNavigationService;
        self.viewContext = new NbsViewContext();

        self.viewMode = self.currentNavigationService.currentViewMode();
        self.currentState = self.currentNavigationService.currentState();
        self.isDirtySusp = false;
        self.isDirtyDisabled = false;
        self.currentCulture = contextService.virtualContext.CurrentLanguageName;
        self.exportGrid = null;

        self.visualizationKey = '';//in view with "grid","calendar" etc.. set from controller.. when switch visualition mode => set this param
        // Enumerator used in grid to filter custom data
        self.myCmsViewCode = self.currentNavigationService.currentCmsViewCode();
        self.myCmsViewMode = self.currentNavigationService.currentViewMode();
        self.viewTitle = self.currentNavigationService.currentCmsViewTitle();
        self.myState = self.currentNavigationService.currentState();

        self.hasHierarchyConfig = Lazy(contextService.virtualContext.HrHierarchyConfigs)
            .where({ VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId })
            .some();

        //Manage open close datepicker selection date modal
        self.dpIsOpen = {};
        self.getDpIsOpen = function (id, index) {
            if (!index) {
                index = '';
            }

            if (!self.dpIsOpen[id + index]) {
                self.dpIsOpen[id + index] = { isOpen: false };
            }
            return self.dpIsOpen[id + index];
        };
        self.setDpIsOpen = function (id, index) {
            if (!index) {
                index = '';
            }

            self.dpIsOpen[id + index].isOpen = true;
        };

        self.suspendDirty = function (isSuspended) {
            self.isDirtySusp = isSuspended;
        };

        self.isDirtySuspended = function () {
            return self.isDirtySusp;
        };

        //used in component bind '<' when text is required
        self.txtMe = (sourceTxt) => { return sourceTxt; };

        //grid bar component ui context 
        self.uiContext = {
            visualizationKey: self.visualizationKey ? self.visualizationKey : 'grid',
            suspendDirty: self.suspendDirty,
            txtMe: (sourceTxt) => { return sourceTxt; },
            translate: (key) => { return window.$T(self.myCmsViewCode, key); },
            // datepicker
            getDpIsOpen: self.getDpIsOpen,
            setDpIsOpen: self.setDpIsOpen
        };

        // In checkStateChangeService when dirty is disabled, leaving view don't trigger message for entities changed
        // and current changes were rejected (from service passed to checkStateChangeService)
        if (self.currentState) {
            if (self.viewMode !== '_' && self.currentState.toLowerCase().endsWith('reportform' + '-' + self.viewMode)) {
                self.isDirtyDisabled = true;
            } else if (self.viewMode === '_' && self.currentState.toLowerCase().endsWith('reportform')) {
                self.isDirtyDisabled = true;
            }
        }

        // centralized management button visibility in wizards controller
        self.cancelBtnIsVisible = false;
        self.prevBtnIsVisible = false;
        self.nextBtnIsVisible = false;
        self.okBtnIsVisible = false;
        self.endBtnIsVisible = false;
        self.setWizardButtonVisibility = function (pParams, forceVisibility) {
            if (forceVisibility === null || forceVisibility === undefined) {
                self.cancelBtnIsVisible = pParams.cancelBtnIsVisible !== undefined ? pParams.cancelBtnIsVisible : true;
                self.prevBtnIsVisible = pParams.prevBtnIsVisible !== undefined ? pParams.prevBtnIsVisible : false;
                self.nextBtnIsVisible = pParams.nextBtnIsVisible !== undefined ? pParams.nextBtnIsVisible : false;
                self.okBtnIsVisible = pParams.okBtnIsVisible !== undefined ? pParams.okBtnIsVisible : true;
                self.endBtnIsVisible = pParams.endBtnIsVisible !== undefined ? pParams.endBtnIsVisible : true;
            } else {
                self.cancelBtnIsVisible = forceVisibility;
                self.prevBtnIsVisible = forceVisibility;
                self.nextBtnIsVisible = forceVisibility;
                self.okBtnIsVisible = forceVisibility;
                self.endBtnIsVisible = forceVisibility;
            }
        };

        self.months = [
            { value: 0, name: $TG('January') },
            { value: 1, name: $TG('February') },
            { value: 2, name: $TG('March') },
            { value: 3, name: $TG('April') },
            { value: 4, name: $TG('May') },
            { value: 5, name: $TG('June') },
            { value: 6, name: $TG('July') },
            { value: 7, name: $TG('August') },
            { value: 8, name: $TG('September') },
            { value: 9, name: $TG('October') },
            { value: 10, name: $TG('November') },
            { value: 11, name: $TG('December') }
        ];


        // Wrapper to use checkGlobalPermissionCode in controllers and views
        self.checkGlobalPermissionCode = function (permission, virtualContext) {
            return Nembus.Common.Library.auth.checkSingleProcessPermission(virtualContext.GlobalProcessPermissions, permission, virtualContext);
        };

        self.checkPermissionCode = function (permission, virtualContext) {
            var valuePermission = Nembus.Common.Library.auth.checkSingleProcessPermission(virtualContext.GlobalProcessPermissions, permission, virtualContext);
            if (valuePermission <= 0) {
                if (virtualContext.ProcessPermissions && virtualContext.ProcessPermissions.length > 0) {
                    virtualContext.ProcessPermissions.forEach(function (procPermission) {
                        var res = Nembus.Common.Library.auth.checkSingleProcessPermission(procPermission, permission, virtualContext);
                        if (res > 0) {
                            valuePermission = res;
                        }
                    });
                }
            }
            return valuePermission;
        };

        self.havePermission = function (permission, virtualContext) {
            var valuePermission = self.checkPermissionCode(permission, virtualContext);
            return (valuePermission & permission.value) > 0;
        };

        //filter.moduleTargetId
        //filter.groupId
        //filter.typeId
        self.checkProcessPermissionCode = function (permission, filter, virtualContext) {
            if (!filter.moduleTargetId
                || !filter.groupId
                || !filter.typeId) {
                console.log("filter param null error");
                return false;
            }
            var havePermission = Nembus.Common.Library.auth.checkProcessPermissionCode(permission, filter, virtualContext);

            return havePermission > 0;
        };

        self.checkProcessPermissionCodeMessage = function (permission, filter, virtualContext) {
            var deferred = $q.defer();

            if (!self.checkProcessPermissionCode(permission, filter, virtualContext)) {
                //modal al volo ??
                self.currentNavigationService.userNotAuthorizedMessageModal();
                deferred.resolve(false);
            } else {
                deferred.resolve(true);
            }

            return deferred.promise;
        };

        // used in syncfusion grid to format type of data in columns
        self.cultureInfo = {};
        if (contextService.virtualContext && contextService.virtualContext.cultureInfo) {
            self.cultureInfo.ejFormatDate = "{0: " + contextService.virtualContext.cultureInfo.formatDate + "}";
            self.cultureInfo.ejFormatTime = "{0: " + contextService.virtualContext.cultureInfo.formatTime + "}";
            self.cultureInfo.ejFormatDateTime = "{0: " + contextService.virtualContext.cultureInfo.formatDateTime + "}";
        }
        self.isMobileTemplate = currentNavigationService.isMobileTemplate();

        //'xs' : 480,     // extra small
        //'sm' : 768,     // small
        //'md' : 992,     // medium
        //'lg' : 1200     // large
        self.isUnderBreakpoint_xs = Nembus.Common.Library.ui.isUnderBreakpoint_xs();
        self.isUnderBreakpoint_sm = Nembus.Common.Library.ui.isUnderBreakpoint_sm();
        self.isUnderBreakpoint_md = Nembus.Common.Library.ui.isUnderBreakpoint_md();

        //call this function from controller e.g. vm.setItemSelectedFn.call(vm, () => { return itemSelected });
        self.fnItemSelected = null;
        self.setItemSelectedFn = function (fnItemSelected) {
            self.fnItemSelected = fnItemSelected;
        };

        //action called by open detail ribbon bar
        self.openDetailAction = function () {
            if (self.fnItemSelected) {
                var dataSelected = self.fnItemSelected();

                if (Array.isArray(dataSelected)) {
                    if (dataSelected.length === 0) {
                        self.currentNavigationService.selectOneItemMessageModal();
                        return;
                    }
                    if (dataSelected.length > 1) {
                        self.currentNavigationService.selectOnlyOneItemMessageModal();
                        return;
                    }

                    self.rowDblClick({ data: Lazy(dataSelected).first() });
                } else {
                    if (!dataSelected) {
                        self.currentNavigationService.selectOneItemMessageModal();
                        return;
                    }

                    self.rowDblClick({ data: dataSelected });
                }
            }
        };

        self.canCreate = function () {
            var deferred = $q.defer();
            //if (self.authData.create) {
            //	deferred.resolve();
            //}
            //else {
            //	$q.reject(self.authData);
            //}
            deferred.resolve();
            return deferred.promise;
        };
        self.canRead = function () {
            var deferred = $q.defer();
            //if (self.authData.read) {
            //	deferred.resolve();
            //}
            //else {
            //	$q.reject(self.authData);
            //}
            deferred.resolve();
            return deferred.promise;
        };
        self.canUpdate = function () {
            var deferred = $q.defer();
            //if (self.authData.update) {
            //	deferred.resolve();
            //}
            //else {
            //	$q.reject(self.authData);
            //}
            deferred.resolve();
            return deferred.promise;
        };
        self.canDelete = function () {
            var deferred = $q.defer();
            //if (self.authData.delete) {
            //	deferred.resolve();
            //}
            //else {
            //	$q.reject(self.authData);
            //}
            deferred.resolve();
            return deferred.promise;
        };
        self.canCustomAction = function (actionBw) {
            var deferred = $q.defer();
            //if ((self.authData.actionsBw & actionBw) > 0) {
            //	deferred.resolve();
            //}
            //else {
            //	$q.reject(self.authData);
            //}
            deferred.resolve();
            return deferred.promise;
        };

        self.alerts = [];
        self.alertTypes = {
            success: 'success',
            warning: 'warning',
            danger: 'danger'
        };
        self.alertConfig = {
            isModal: false,
            isReadonly: false
        };

        self.appStoreCodeControlType = {
            isCurrentAppStoreCodePresentInAppStoreCodeList: 1,     //DEFAULT
            isAppStoreCodeListPresentInContext: 2
        };

        //constants used to define class for modal template that contains NgGrid
        self.NBS_MODAL_CLASS_FORM_SM = 'nbs-modal-form-sm';     //600px width
        self.NBS_MODAL_CLASS_FORM_MD = 'nbs-modal-form-md';     //800px width
        self.NBS_MODAL_CLASS_FORM_LG = 'nbs-modal-form-lg';     //1000px width

        // custom enums used in controllers
        self.actions = { none: 0, add: 1, remove: 2, update: 3 };
        self.inputType = { checkbox: 'checkbox', radio: 'radio' };

        self.modalOptions = function (options) {
            var optionsReturn = {
                animation: true,
                templateUrl: options.templateUrl ? options.templateUrl : null,
                template: options.template ? options.template : null,
                scope: options.scope ? options.scope : null,
                controller: options.controller ? options.controller : null,
                controllerAs: options.controllerAs ? options.controllerAs : null,
                bindToController: options.bindToController ? options.bindToController : null,
                resolve: options.resolve ? options.resolve : null,
                backdrop: options.backdrop ? options.backdrop : 'static',
                keyboard: options.keyboard ? options.keyboard : false,
                backdropClass: options.backdropClass ? options.backdropClass : null,
                windowClass: options.windowClass ? options.windowClass : null,	// do not modify default value (layout genericgridlist can be damaged)
                windowTemplateUrl: options.windowTemplateUrl ? options.windowTemplateUrl : null,
                size: options.size ? options.size : 'lg',
                openedClass: options.openedClass ? options.openedClass : null
            };

            return optionsReturn;
        };

        // Returns object used to communicate result after batchrequest is executed to appPanel UI
        self.getJobStatus = function () {
            var jobResult = {
                jobStatus: null,                // done, warning, conflict, ....
                jobMessage: null,               // message to add on appLayout toolbar (can be null)
                jobDetailActionLabel: null      // if set, then add dropdown with item (E.g. Dettaglio)
            };

            return jobResult;
        };

        //Set cursor: not-allowed: used in datetimepicker button
        self.cursorForDisabledFormItem = self.viewContext.isLocked ? { 'cursor': 'not-allowed' } : {};

        self.getAppStoreByEntity = function (contextService, targetEntity) {
            var appStoreFiltered = [];
            Lazy(contextService.virtualContext.AppStores)
                .each((appStore) => {
                    targetEntities = Nembus.Common.Library.converter.JsonFieldParse(appStore.TargetEntities);//JSON.parse(appStore.TargetEntities);
                    if (Lazy(targetEntities).contains(targetEntity)) {
                        appStoreFiltered.push(appStore);
                    }
                });
            return appStoreFiltered;
        };

        self.getAppStoreBwSumByEntity = function (contextService, targetEntity) {
            var appStoreFiltered = self.getAppStoreByEntity(contextService, targetEntity);
            return Lazy(appStoreFiltered).pluck('AppStoreBw').sum();
        };

        self.getAppStoreByModuleTargetId = function (contextService, moduleTargetId) {
            var moduleTarget = Lazy(contextService.virtualContext.ModuleTargets).findWhere({ ModuleTargetId: moduleTargetId });
            if (moduleTarget) {
                return moduleTarget.AppStoreBw;
            } else {
                console.error('ModuleTarget not found');
                return 0;
            }
        };

        self.getModuleTargetId = function (contextService, appStoreBw) {
            if (!appStoreBw) {
                console.error('getModuleTargetId: appStoreBw is mandatory');
            }

            var moduleTarget = Lazy(contextService.virtualContext.ModuleTargets).findWhere({ AppStoreBw: appStoreBw });
            if (moduleTarget) {
                return moduleTarget.ModuleTargetId;
            } else {
                console.error('ModuleTarget not found');
                return 0;
            }
        };

        self.getModuleTargetIdByCode = function (contextService, moduleTargetCode) {
            if (!moduleTargetCode) {
                console.error('getModuleTargetIdByCode: moduleTargetCode is mandatory');
            }

            var moduleTarget = Lazy(contextService.virtualContext.ModuleTargets).findWhere({ ModuleTargetCode: moduleTargetCode });
            if (moduleTarget) {
                return moduleTarget.ModuleTargetId;
            } else {
                console.error('ModuleTarget not found');
                return 0;
            }
        };

        self.getAppStoreBitwise = function (virtualContext, appStoreCode) {
            var bw = 0;

            if (!appStoreCode) {
                console.error('getAppStoreBitwise: appStoreCode is mandatory');
            }

            var appStoreCtx = Lazy(virtualContext.AppStores)
                .map((el) => {
                    el.AppStoreCodeLowerCase = el.AppStoreCode.toLowerCase();
                    return el;
                });

            if (appStoreCode.toLowerCase() === 'all') {
                Lazy(appStoreCtx)
                    .each((el) => {
                        bw += el.AppStoreBw;
                    });
            } else {
                var appStoreSearch = Lazy(appStoreCtx).findWhere({ AppStoreCodeLowerCase: appStoreCode.toLowerCase() });
                if (appStoreSearch) {
                    bw = appStoreSearch.AppStoreBw;
                }
            }

            if (bw <= 0) {
                console.error('getAppStoreBitwise: AppStoreBw cannot be zero');
            }

            return bw;
        };

        self.AppStoreAuth = function (contextService, appStoreCodeList, currentAppStoreCode, appStoreCodeControlType) {
            var result = true;

            if (!appStoreCodeControlType) {
                appStoreCodeControlType = self.appStoreCodeControlType.isCurrentAppStoreCodePresentInAppStoreCodeList;
            }

            if (result) {
                var appStoreCtx = Lazy(contextService.virtualContext.AppStores)
                    .map((el) => {
                        el.AppStoreCodeLowerCase = el.AppStoreCode.toLowerCase();
                        return el;
                    });

                var contextAppStoreCodes = appStoreCtx.pluck('AppStoreCodeLowerCase').toArray();
                if (contextAppStoreCodes.length === 0) {
                    result = false;
                }
            }

            if (result) {
                if (!contextService) {
                    result = false;
                }
            }

            if (result) {
                appStoreCodeList = appStoreCodeList.split(',');
                if (appStoreCodeList.length === 0) {
                    result = false;
                }
            }

            if (result) {
                if (currentAppStoreCode) {
                    currentAppStoreCode = currentAppStoreCode.toLowerCase();
                } else {
                    result = false;
                }
            }

            if (result) {
                switch (appStoreCodeControlType) {
                    case self.appStoreCodeControlType.isCurrentAppStoreCodePresentInAppStoreCodeList:   //DEFAULT
                        if (!Lazy(appStoreCodeList).contains(currentAppStoreCode)) {
                            result = false;
                        }
                        break;

                    case self.appStoreCodeControlType.isAppStoreCodeListPresentInContext:
                        Lazy(appStoreCodeList)
                            .each((appStore) => {
                                if (!Lazy(contextAppStoreCodes).contains(appStore)) {
                                    result = false;
                                }
                            });
                        break;
                }
            }

            return result;
        };

        self.translateCmsView = function (cmsView, key, entityName) {
            if (!cmsView) {
                return null;
            } else {
                return window.$T(cmsView, key, entityName);
            }
        };

        self.getReportsForCmsView = function (cmsService, cmsViewCode) {
            var defer = $q.defer();
            var cmsViewCompleteCode;

            if (cmsViewCode) {
                cmsViewCode = cmsViewCode.toLowerCase();
                cmsViewCompleteCode = Nembus.Common.Library.cms.cmsViewData(cmsViewCode).CmsViewCompleteCode;
            } else {
                cmsViewCompleteCode = Nembus.Common.Library.cms.cmsViewData(self.myCmsViewCode).CmsViewCompleteCode;
            }

            var filter = { CmsViewCompleteCode: cmsViewCompleteCode };
            cmsService.getReportDefinitionsForCmsView(filter, false)
                .then(function (data) {
                    self.currentReportData.reportDefinition = data.results[0];
                    defer.resolve(true);
                })
                .catch(function (error) {
                });

            return defer.promise;
        };

        self.whoIam = function () {
            return self.myCmsViewCode;
        };

        self.globalThesaurusObject = Nembus.Common.Library.AppModel.globalThesaurusObject;

        self.translate = function (key, entityName) {
            return window.$T(self.myCmsViewCode, key, entityName);
        };

        self.manageSaveChangesMessage = function (error) {
            error.isMessageVisible = true;

            if (
                !Lazy(error.entityErrors).where({ "isServerError": true }).any()            // No server errors
                &&
                Lazy(error.entityErrors).where({ "isServerError": false }).any()            // Yes client errors
            ) {
                error.isMessageVisible = false;
                return;
            }

            if (error.httpResponse && error.httpResponse.data && error.httpResponse.data.Errors) {
                // Errors list managed by appcontexthelper
                Lazy(error.httpResponse.data.Errors)
                    .each((currentError) => {
                        switch (currentError.Message) {
                            case 'addDuplicateCode':
                                error.message = self.translate('CreationDeniedEntityCodeAlreadyExists');
                                break;
                            case 'deleteForeignKey':
                                error.message = self.translate('DeleteOperationDbValidationFailed');
                                break;
                            case 'CancellationDeniedEntityAssociated':
                                error.message = self.translate('CancellationDeniedEntityAssociated');
                                break;
                            default:
                                error.message = self.translate(currentError.Message);
                        }
                    });
            } else {
                // Errors list not managed
                if (error.message.toLowerCase().indexOf('duplicate entry') >= 0) {
                    error.message = self.translate('CreationDeniedEntityCodeAlreadyExists');
                }
                if (error.message.toLowerCase().indexOf('foreign key') >= 0) {
                    error.message = self.translate('DeleteOperationDbValidationFailed');
                }
            }

            //decode html before output on UI
            error.message = Nembus.Common.Library.converter.decodeHtml(error.message);
        };

        self.managePostMessage = function (error) {
            error.isMessageVisible = true;

            if (error.message.indexOf('Duplicate entry') >= 0) {
                error.message = self.translate('CreationDeniedEntityCodeAlreadyExists');
            }
        };

        self.submitAction = function (removeAlerts) {
            if (removeAlerts === null || removeAlerts === undefined || removeAlerts === true) {
                self.removeAlerts();
            }

            var deferred = $q.defer();

            currentDataService.saveChanges()
                .then(function (saveResult) {
                    self.setSaveSuccessMsg();
                    self.resetAlertConfig();
                    deferred.resolve(saveResult);
                })
                .catch(function (error) {
                    self.manageSaveChangesMessage(error);

                    self.setErrorMsg(error);

                    self.resetAlertConfig();
                    deferred.reject(error);
                });
            return deferred.promise;
        };

        self.modifyAction = function (saveChanges) {
            if (saveChanges === null || saveChanges === undefined || saveChanges === true) {
                saveChanges = true;
            } else {
                saveChanges = false;
            }

            var deferred = null;

            if (self.viewContext.state === self.viewContext.states.isEditing) {
                self.removeAlerts();
                deferred = $q.defer();

                if (!saveChanges) {
                    // emule save changes
                    self.setSaveSuccessMsg();
                    self.setIsViewing();
                    self.resetAlertConfig();
                    deferred.resolve();
                } else {
                    currentDataService.saveChanges()
                        .then(function (saveResult) {
                            self.setSaveSuccessMsg();
                            self.setIsViewing();
                            self.resetAlertConfig();
                            deferred.resolve(saveResult);
                        })
                        .catch(function (error) {
                            self.manageSaveChangesMessage(error);

                            self.setErrorMsg(error);

                            self.resetAlertConfig();
                            deferred.reject(error);
                        });
                }

                return deferred.promise;
            }
            else {
                self.setIsEditing();
                deferred = $q.defer();
                deferred.resolve();
                return deferred.promise;
            }
        };

        // object radio list with 'yes', 'no', 'all' items
        self.booleanForRadioList = new NbsRadioListBoolean(self.translate);

        //returns list o selection for dropdown (emulating boolean)
        self.booleanSelectionForDropDown = [
            { value: true, name: self.translate('Yes') },
            { value: false, name: self.translate('No') }
        ];

        self.resetAlertConfig = function () {
            self.alertConfig.isModal = false;
            self.alertConfig.isReadonly = false;
        };

        self.removeAlerts = function () {
            self.alerts = [];
        };

        self.removeAlertFromList = function (isModal, alertToRemove, alertType) {
            if (alertToRemove) {
                //remove single alert received
                if (!alertToRemove.isReadonly)
                    self.alerts = Lazy(self.alerts)
                        .reject({ $$hashKey: alertToRemove.$$hashKey })
                        .toArray();
            } else {
                self.alerts = Lazy(self.alerts)
                    .where({ isModal: isModal })
                    .where({ isReadonly: false })
                    .reject({ alertType: alertType })
                    .toArray();

                if (alertType) {
                    self.alerts = Lazy(self.alerts)
                        .reject({ alertType: alertType })
                        .toArray();
                }
            }
        };

        self.closeSuccessMsg = function (isModal, alert) {
            self.removeAlertFromList(isModal, alert, self.alertTypes.success);
        };
        self.closeWarningMsg = function (isModal, alert) {
            self.removeAlertFromList(isModal, alert, self.alertTypes.warning);
        };
        self.closeErrorMsg = function (isModal, alert) {
            self.removeAlertFromList(isModal, alert, self.alertTypes.danger);
        };

        self.setSuccessMsg = function (error) {
            self.alerts.push(new Alert(
                error.message,
                self.alertTypes.success,
                self.alertConfig.isModal,
                self.alertConfig.isReadonly
            ));

            self.resetAlertConfig();
        };

        self.setWarningMsg = function (error, isString) {
            var msg = '';
            if (isString === null || isString === undefined || isString === false) {
                msg = error.message;
            }
            else {
                msg = error;
            }
            self.alerts.push(new Alert(
                msg,
                self.alertTypes.warning,
                self.alertConfig.isModal,
                true
            ));

            self.resetAlertConfig();
        };

        self.setErrorMsg = function (error, isString) {
            var tmp = error;
            if (!error.isMessageVisible) {
                return;
            }

            var msg = '';
            if (isString === null || isString === undefined || isString === false) {
                msg = error.message;
            }
            else {
                msg = error;
            }

            self.alerts.push(new Alert(
                msg,
                self.alertTypes.danger,
                self.alertConfig.isModal,
                true
            ));
            self.resetAlertConfig();
        };

        self.setSaveSuccessMsg = function () {
            self.alerts.push(new Alert(
                self.translate('SubmittedSuccesfully'),
                self.alertTypes.success,
                self.alertConfig.isModal,
                self.alertConfig.isReadonly
            ));

            self.resetAlertConfig();
        };

        //states
        self.setState = function (state) {
            $timeout(() => {
                self.viewContext.state = state;
                self.viewContext.isLocked = state === self.viewContext.states.isViewing;
                self.viewContext.notIsLocked = !self.viewContext.isLocked;
                self.viewContext.isViewingState = state === self.viewContext.states.isViewing;
                self.viewContext.isAddingState = state === self.viewContext.states.isAdding;
                self.viewContext.isEditingState = state === self.viewContext.states.isEditing;

                // used into ribbon component
                // if change it then change into component
                self.ribbonContext = {
                    state: self.viewContext.state,
                    isLocked: self.viewContext.isLocked,
                    notIsLocked: self.viewContext.notIsLocked,
                    isViewingState: self.viewContext.isViewingState,
                    isAddingState: self.viewContext.isAddingState,
                    isEditingState: self.viewContext.isEditingState
                }
            });
        };

        // fake to disable form items
        self.setIsEditingReport = function () {
            self.setState(self.viewContext.states.isEditing);
        };
        self.setIsEditingSearch = function () {
            self.setState(self.viewContext.states.isEditing);
        };


        self.setIsSearching = function () {
            self.setState(self.viewContext.states.isEditing);
        };

        self.setIsEditing = function () {
            self.setState(self.viewContext.states.isEditing);
        };

        self.setIsViewing = function () {
            self.setState(self.viewContext.states.isViewing);
        };

        self.setIsAdding = function () {
            self.setState(self.viewContext.states.isAdding);
        };

        //edit page elements (label, description for user manual etc
        self.editDocElementAction = function () {
            self.isDirtySusp = true;
            currentNavigationService
                .navToCurrentModalDoc()
                .then(function (res) {
                    self.isDirtySusp = false;
                });
        };

        //publish thesaurus to files
        self.refreshDocFileAction = function () {
            currentNavigationService.navToCurrentModalDoc();
        };

        self.backAction = function () {
            currentNavigationService.navigateBack();
        };

        self.exportExcelAction = function () {
            if ($timeout) {
                $timeout(() => {
                    self.exportGrid = {
                        refreshkey: new Date().getTime(),
                        format: 'excel'
                    }
                })
            }
        };

        self.exportWordAction = function () {
            if ($timeout) {
                $timeout(() => {
                    self.exportGrid = {
                        refreshkey: new Date().getTime(),
                        format: 'word'
                    }
                })
            }
        };

        //#region Reports actions */
        self.currentReportData = {};
        //reportdata: {reportCode, reportArgs, concatName}

        self.setReportData = function (reportData) {
            if (reportData.reportDefinition) {
                self.currentReportData.reportDefinition = reportData.reportDefinition;
            }

            if (!self.currentReportData.reportDefinition) {
                console.error('report definition not set');
                return;
            }

            self.currentReportData.integrationService = $injector.get('integrationService');
            self.currentReportData.contextService = $injector.get('contextService');
            self.currentReportData.reportArgs = reportData.reportArgs;
            self.currentReportData.concatName = reportData.concatName;
            self.currentReportData.replaceName = reportData.replaceName;
        };

        self.setReportHtmlToDocData = function (reportData) {
            if (reportData.reportDefinition) {
                self.currentReportData.reportDefinition = reportData.reportDefinition;
            }

            self.currentReportData.reportService = $injector.get('reportService');
            self.currentReportData.contextService = $injector.get('contextService');
            self.currentReportData.concatName = reportData.concatName;
            self.currentReportData.replaceName = reportData.replaceName;
            self.currentReportData.reportHtmlRequest = reportData.reportHtmlRequest;
        };

        self.exportReportToPdfAction = function () {
            self.exportReport('pdf');
        };

        self.exportReportToWordAction = function () {
            self.exportReport('openword');
        };

        self.exportReportToXmlAction = function () {
            self.exportReport('xml');
        };

        self.exportReportToExcelAction = function () {
            self.exportReport('openexcel');
        };

        self.exportReportToHtmlAction = function () {
            self.exportReport('html');
        };

        self.exportReport = function (outputFormat) {
            var reportDef = self.currentReportData.reportDefinition;
            if (reportDef.ReportEngineTypeId === 3) // html
            {
                exportReportHtmlToDoc(outputFormat);
            }
            else {
                exportReport(outputFormat);
            }
        };

        // used from controllers to overwrite validation translations
        // E.g. surveycreationrequestdetail controller
        self.overloadTranslateValidation = function (manager, shortName, propertyName, customPropertyTranslate) {
            var entityType = Lazy(manager.metadataStore.getEntityTypes()).filter((item) => {
                return item.shortName === shortName;
            }).first();
            if (entityType) {
                var property = Lazy(entityType.getProperties()).filter((item) => { return item.name == propertyName; }).first();
                if (property) {
                    angular.forEach(property.validators, function (validator) {
                        //var cmsViewData = $CmsViewData(currentNavigationService.currentCmsViewCode());
                        //var cmsGlobal = $CmsGlobalData();
                        validator.context.displayName = function (c) {
                            if (c.propertyName) {
                                return customPropertyTranslate();
                            }
                        };
                    });
                }
            }
        };

        function exportReport(outputFormat) {
            var reportDef = self.currentReportData.reportDefinition;

            var requestData = Nembus.Common.Library.report.parseReportRequest(self.currentReportData.integrationService.dataContext.complexTypes.reportRequestData(),
                reportDef,
                self.currentReportData.reportArgs,
                outputFormat);

            requestData.ReportTitle = self.currentReportData.replaceName ? self.currentReportData.replaceName : self.currentReportData.concatName;

            self.currentReportData.integrationService.genericReportingRequest(requestData)
                .then(function (data) {
                    var res = data.results[0];
                    if (res.HasError) {
                        self.setErrorMsg({
                            isMessageVisible: true,
                            message: res.ExceptionMessage
                        });
                    }
                    else if (res.IsBatch) {
                        self.currentReportData.contextService.addBatchRequest({
                            requestId: res.BatchRequestId,               //only one entity will be submitted
                            requestName: res.ReportTitle ? res.ReportTitle : res.ReportName,
                            requestStatus: 'inserted',
                            onCompletedAutoAction: function (request) {
                                var defer = $q.defer();
                                var filter = { ReportRequestId: res.ReportRequestId };
                                self.currentReportData.integrationService.getReportRequestRenderResult(filter, true)
                                    .then(function (data) {
                                        var jobResult = self.getJobStatus();

                                        self.currentReportData.contextService.addDownloadReportFile({
                                            result: data.results[0],
                                            concatName: self.currentReportData.concatName,
                                            replaceName: self.currentReportData.replaceName
                                        });

                                        defer.resolve(jobResult);
                                    });

                                return defer.promise;
                            }
                            //detailAction: function (request) { },
                        });
                    } else {
                        self.currentReportData.contextService.addDownloadReportFile({
                            result: data.results[0],
                            concatName: self.currentReportData.concatName,
                            replaceName: self.currentReportData.replaceName
                        });
                    }
                })
                .catch(function (error) {
                    var xx = error;
                });
        }

        function exportReportHtmlToDoc() {
            var reportDef = self.currentReportData.reportDefinition;
            var reportHtmlRequest = self.currentReportData.reportHtmlRequest;
            reportHtmlRequest.ReportHtmlRequestId = breeze.core.getUuid();
            reportHtmlRequest.ReportDefinitionCode = reportDef.ReportDefinitionCode;
            reportHtmlRequest.ReportDefinitionName = reportDef.ReportDefinitionName;

            // used a post method to prevent error on big data submit (max size is setted on IIS)
            self.currentReportData.reportService.reportHtmlRequestManage(reportHtmlRequest)
                .then(function (data) {
                    self.currentReportData.contextService.addDownloadReportHtmlToDocFile({
                        result: data.results[0], //object type ReportHtmlRequest
                        concatName: self.currentReportData.concatName,
                        replaceName: self.currentReportData.replaceName
                    });
                });

        }
        //#endregion


        //validation translate
        loadTranslatedValidationMessages(currentDataService);
        function loadTranslatedValidationMessages(svc) {
            if (!svc) {
                return;
            }

            var manager = svc.manager;
            if (!manager) {
                return;
            }

            var currentViewData = $CmsViewData(self.myCmsViewCode);
            if (!currentViewData) {
                return;
            }

            var entityTypes = manager.metadataStore.getEntityTypes();
            var validators = [];
            Lazy(entityTypes)
                .each((entityType) => {
                    // entity validators
                    Lazy(entityType.validators)
                        .each((validator) => {
                            validators.push(validator);
                        })

                    // property validators
                    Lazy(entityType.getProperties())
                        .each((property) => {
                            Lazy(property.validators)
                                .each((validator) => {
                                    validators.push(validator);
                                })
                        });
                });

            translateValidators(currentViewData, validators);
        }

        function translateValidators(cmsViewData, validators) {
            if (!validators || !Lazy(validators).some()) {
                return;
            }

            var notTranslatedValidators = Lazy(validators)
                .filter((validator) => { return !validator.context.messageTranslated; })
                .toArray();

            Lazy(notTranslatedValidators)
                .each((validator) => {
                    var validationTemplateKey = 'bz-validation-' + validator.name;
                    var msgT = Nembus.Common.Library.AppModel.globalThesaurusObject[validationTemplateKey];
                    if (msgT !== validationTemplateKey) {
                        validator.context.messageTemplate = msgT;
                        validator.context.messageTranslated = true;
                    }
                    validator.context.displayName = function (c) {
                        if (c.propertyName) {
                            propKey = c.entity.entityType.shortName + '.' + c.propertyName;
                            var wTranslate = window.$TCmsViewLabelData(cmsViewData, propKey);
                            if (wTranslate === propKey
                                && propKey.substring(propKey.length - 2, propKey.length) === 'Id') {
                                wTranslate = window.$TCmsViewLabelData(cmsViewData, propKey.substring(0, propKey.length - 2));
                            }
                            return wTranslate;
                        }
                    };
                });
        };

        function Alert(message, alertType, isModal, isReadonly) {
            return {
                msg: message,
                alertType: alertType,
                isModal: isModal,
                isReadonly: isReadonly
            };
        }
    }

    return nbsVm;
});