﻿'use strict';
define([], function () {

    var injectParams = [];
    var nbsEjChartComponent = function () {

        var vm = this;
        var pDatacontext = null;
        var ID = breeze.core.getUuid();
        var pEjChart = null;

        vm.$onInit = function () {
            
        };

        vm.$onChanges = function (changes) {
            if (changes.nbsDatacontext && changes.nbsDatacontext.currentValue) {
                pDatacontext = changes.nbsDatacontext.currentValue;
            
                createChart();
            }
            if (changes.nbsRefresh && changes.nbsRefresh.currentValue) {
                if (pEjChart) {
                    pEjChart.refresh();
                }                
            }
        };

        function createChart() {
            var chart = pDatacontext;

            var container = $("#ejChartTag");
            $(container).attr('id', ID);
            $("#" + ID).ejChart(chart);
            pEjChart = $("#" + ID).data('ejChart');
        }
    };

    nbsEjChartComponent.$inject = injectParams;
    angular.module('nbs.rds.module').component('nbsEjChart', {
        templateUrl: '/rds/common/components/nbsEjChartComponent/_?c=' + window.codeCache(),
        controller: nbsEjChartComponent,
        controllerAs: 'vm',
        bindings: {
            nbsDatacontext: "<",
            uiContext: "<"
        }
    });
});

