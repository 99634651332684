﻿'use strict';
define([], function () {

    var injectParams = ['$q', '$timeout', 'contextService'];
    var nbsMetricBarComponent = function ($q, $timeout, contextService) {
        var vm = this;
        var privateDataContext = null;
        var privateUIData = null;
        var defaultKeys;
        var nbsUiOptionParser = null;

        vm.privateComponentsStatus = {};
        vm.nbsDataPrivate = {};

        vm.$onInit = function () {
        };

        vm.$onChanges = function (changes) {
            if (changes.nbsDataContext && changes.nbsDataContext.currentValue) {
                nbsUiOptionParser = new NbsUiOptionParser(vm.uiContext.translate);

                privateDataContext = changes.nbsDataContext.currentValue;
                // set default visible columns list in grid
                defaultKeys = privateDataContext.defaultKeys;

                // Start of execution workflow of load data bar components 
                initViewTitle();
            }
        };

        function initViewTitle() {
            var cols = [];
            var defaultStartData = new NbsUiData();
            defaultStartData.setColumns(cols);
            defaultStartData.setFilterList(getFilterList());
            vm.initViewTitle = defaultStartData.startData;
        }

        //#region hook from components
        vm.onViewTitleSelectionEvent = function (result) {
            privateUIData = JSON.parse(JSON.stringify(result.uiData));
            setComponents(privateUIData);

            setBarDatasources()
                .then(function () {
                    barOnChanges(privateUIData);
                });
        };
        vm.onUiChanges = function (result) {
            privateUIData.columns = result.uiData.columns;
            privateUIData.grouping = result.uiData.grouping;
            privateUIData.sorting = result.uiData.sorting;
            privateUIData.filters.freeText = result.uiData.filters.freeText;

            refreshViewTitleDataContext(privateUIData);

            vm.onGridUiChangesEvent({ result: privateUIData });
        };
        vm.onFilterChanges = function (result) {
            privateUIData.filterList = result.filters.filterList;

            refreshViewTitleDataContext(privateUIData);
            setStatusComponents(privateUIData.filterList);

            setBarDatasources()
                .then(function () {
                    barOnChanges(privateUIData);
                });
        };
        vm.onDdModuletargets = function (options) {
            privateUIData.filters.moduletargets = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        //#endregion

        // refresh current preference uiData
        function refreshViewTitleDataContext(uiDataParam) {
            vm.viewTitleDataContext = Nembus.Common.Library.component.getDataContextViewTitle(uiDataParam);
        }

        function setBarDatasources() {
            var defer = $q.defer();
            var funcsPromises = [];


            if (vm.privateComponentsStatus.moduletargets.isVisible) {
                funcsPromises.push(getModuleTargets());
            }

            $q.all(funcsPromises)
                .then(function () {
                    defer.resolve(true);
                });

            return defer.promise;
        }

        function getFilterList() {
            var uiDataFilters = [];
            uiDataFilters.push(new NbsUiDataFilter({ code: 'moduletargets', name: 'ModuleTarget', canHide: false, visible: true }));
            return uiDataFilters;
        }

        function getModuleTargets() {
            var defer = $q.defer();
            var tempModuleTargetUiOptions = nbsUiOptionParser.getUiOptionsByKey(contextService.virtualContext.ModuleTargets, 'ModuleTarget');
            var failureMT = Lazy(tempModuleTargetUiOptions).findWhere({ optionCode: 'MTFAS' });
            var moduleTargets = [];

            Lazy(tempModuleTargetUiOptions)
                .each((uiOptionsMT) => {
                    if (failureMT) {
                        if (uiOptionsMT.optionCode !== failureMT.optionCode) {
                            if (uiOptionsMT.optionCode === 'MTIMS') {
                                uiOptionsMT.optionValueCustom = [uiOptionsMT.optionValue, failureMT.optionValue]
                                moduleTargets.push(uiOptionsMT);
                            } else {
                                uiOptionsMT.optionValueCustom = [uiOptionsMT.optionValue]
                                moduleTargets.push(uiOptionsMT);
                            }
                        }
                    }
                    else {
                        uiOptionsMT.optionValueCustom = uiOptionsMT.optionValue;
                        moduleTargets.push(uiOptionsMT);
                    }
                });

            vm.nbsDataPrivate.moduletargets = moduleTargets;
            defer.resolve(true);
            return defer.promise;
        }

        function setComponents(uiDataParam) {
            setCommonGridBarDataContext(uiDataParam);
            setSelectionComponents(uiDataParam);
            setStatusComponents(uiDataParam.filterList);
            vm.onGridUiChangesEvent({ result: privateUIData });
        }

        function setCommonGridBarDataContext(uiDataParam) {
            vm.commonGridBarDataContext = Nembus.Common.Library.component.getDataContextCommonGridBar(uiDataParam);
            vm.commonBarDatasource = privateDataContext.commonBarDatasource
        }

        function setSelectionComponents(uiDataParam) {
            vm.nbsDataPrivate.moduletargetsselected = uiDataParam.filters.moduletargets ? uiDataParam.filters.moduletargets : [];
        }

        function setStatusComponents(filterList) {
            vm.privateComponentsStatus.moduletargets = Nembus.Common.Library.component.getStatus('moduletargets', filterList);
        }

        function barOnChanges(uiDataParam) {
            var selectedModuleTargetIds = [];
            if (vm.privateComponentsStatus.moduletargets.isVisible) {
                if (Lazy(uiDataParam.filters.moduletargets).some()) {
                    selectedModuleTargetIds = Lazy(uiDataParam.filters.moduletargets).first().optionValueCustom;
                }                
            }

            var filter = {
                ModuleTargetIds: selectedModuleTargetIds
            };

            $timeout(() => {
                if (privateDataContext.uiMode === 'definition') {
                    vm.onBarChanges({ result: { metricKpiDefinitionFilter: filter } });
                } else if (privateDataContext.uiMode === 'specialization') {
                    vm.onBarChanges({ result: { metricSpecializationFilter: filter } });
                } else if (privateDataContext.uiMode === 'instance') {
                    // at moment not called by instanceslist view
                    vm.onBarChanges({ result: { metricInstanceFilter: filter } });
                }
            });
        }
    };


    nbsMetricBarComponent.$inject = injectParams;
    angular.module('nbs.rds.module').component('nbsMetricBar', {
        templateUrl: '/rds/metrics/components/nbsMetricBarComponent/_?c=' + window.codeCache(),
        controller: nbsMetricBarComponent,
        controllerAs: 'vm',
        bindings: {
            onBarChanges: '&',
            onGridUiChangesEvent: '&',

            nbsDataContext: "<",
            uiContext: "<"
        }
    });
});

