﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['navigationService', '$injector'];
    var authorizationDenied = function (navigationService, $injector) {
        var vm = this;
        var $state;
        var toStateDenied = navigationService.stateParams().toState;
        $state = $state || $injector.get('$state');
        var states = $state.get();
        vm.stateDenied = Lazy(states).findWhere({ name: toStateDenied })
    };

    authorizationDenied.$inject = injectParams;
    authorizationDenied.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rbac.module').controller('authorizationDenied', authorizationDenied);
});