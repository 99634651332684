﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'drbacService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function drbacProcessGlobalList($q, $scope, $timeout, $injector, drbacService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, drbacService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return drbacProcessSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, drbacService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
        var drbacProcessSelected = null;

        ribbon.setRibbonStatusButton(ribbon.Buttons.New, false);
        ribbon.refreshStatusButtons();

        vm.drbacProcesses = [];

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('drbacprocessglobal', null);
        nbsGridDatacontext.viewCommonBar();
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getDrbacConfig(forceReload, virtualCompanyId));
            funcsPromises.push(getDrbacProcessGlobals(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.drbacConfig = results[0];

                    Lazy(results[1])
                        .each((drbacProcessGlobal) => {
                            drbacProcessGlobal.IsActive = drbacProcessGlobal.DrbacProcessId === vm.drbacConfig.GlobalDrbacProcessId;
                        });

                    vm.drbacProcesses = results[1];

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.drbacProcesses
                    }
                });
        }

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.drbacprocessglobaldetail", { 'drbacProcessId': args.data.DrbacProcessId });
        };

        vm.refreshAction = function () {
            loadData(true);
        };

        vm.deleteAction = function () {
            if (!drbacProcessSelected) {
                return;
            }

            drbacService.deleteEntity(drbacProcessSelected);
            vm.submitAction()
                .then(function (saveResult) {
                    vm.refreshAction();
                });
        };

        vm.gridSelection = function (args) {
            $timeout(() => {
                drbacProcessSelected = args.data;

                if (drbacProcessSelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, !drbacProcessSelected.IsSystem);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, true);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, false);
                }

                ribbon.refreshStatusButtons();
            });
        };

        vm.copyAction = function () {
            if (!drbacProcessSelected)
                return;

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        var filter = {
                            DrbacProcessId: drbacProcessSelected.DrbacProcessId,
                            VirtualCompanyId: virtualCompanyId
                        };

                        drbacService.cloneDrbacProcess(filter)
                            .then(function (result) {
                                vm.refreshAction();
                                drbacProcessSelected = null;
                            });
                    }
                });
        };

        function getDrbacConfig(forceReload, virtualCompanyId) {
            var filter = { VirtualCompanyId: virtualCompanyId };
            var defer = $q.defer();

            drbacService.getDrbacConfig(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results[0]);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getDrbacProcessGlobals(forceReload) {
            var defer = $q.defer();

            drbacService.getDrbacProcessGlobals({}, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    }

    drbacProcessGlobalList.$inject = injectParams;
    drbacProcessGlobalList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rbac.module').controller('drbacProcessGlobalList', drbacProcessGlobalList);
});