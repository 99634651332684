﻿'use strict';
//modal params: 
//cronstring: string 
//modal result
//CronObj   ///  cronstring
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$timeout', '$injector', 'navigationService', 'cmstoolService', 'contextService'];
    function bookmarkDetailModal($q, $timeout, $injector, navigationService, cmstoolService, contextService) {

        var vm = this;
        nbsVm.call(vm, cmstoolService, navigationService, contextService, $q, $injector, $timeout);

        var pParams = navigationService.stateParamsModal().parentParams;
        pParams = pParams ? pParams : navigationService.stateParams();

        // used to validate form and to return data to controller that opens modal
        vm.currentBookmark = cmstoolService.dataContext.cmsBookmarkClientDatas.add({
            CmsBookmarkName: pParams.BookmarkName,
        });
        
        // submit and close modal
        vm.modalOk = function () {
            // client data cannot be submitted, is used only for validation
            vm.currentBookmark.entityAspect.setUnchanged();

            var modalResult = getModalResult(navigationService.navigationActionTypes.modalOk);
            vm.currentBookmark.entityAspect.validateEntity();

            if (!vm.currentBookmark.entityAspect.hasValidationErrors) {
                navigationService.closeCurrentModal(modalResult);
            }
        };

        // close modal
        vm.modalCancel = function () {
            // client data cannot be submitted, is used only for validation
            vm.currentBookmark.entityAspect.setUnchanged();

            var modalResult = getModalResult(navigationService.navigationActionTypes.modalCancel);
            navigationService.closeCurrentModal(modalResult);
        };

        function getModalResult(dialogResult) {
            var modalResult = new navigationService.modalResult()
            modalResult.data = vm.currentBookmark.CmsBookmarkName;
            modalResult.sourceParams = pParams;
            modalResult.dialogResult = dialogResult;
            return modalResult;
        }
    };

    bookmarkDetailModal.$inject = injectParams;
    bookmarkDetailModal.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('bookmarkDetailModal', bookmarkDetailModal);
});