﻿'use strict';
define([], function () {

    var injectParams = ['$locale', '$timeout', 'contextService', 'navigationService'];
    var nbsGridComponent = function ($locale, $timeout, contextService, navigationService) {
        var vm = this;
        var pGrid = null;
        var pDataContext = null;
        var pDataJustLoaded = false;
        var pInitSelectionIds = [];
        var pGridSelection_ITEMS = [];
        var pGridSelection_IDS = [];
        var pUIData = null;
        var pConsoleLogEnabled = false;
        var pGridJustCreated = false;
        var pGridJustLoaded = false;
        var nbsUiOptionParser = null;
        var isManuallyAction = false;
        var useListView = Nembus.Common.Library.ui.useListView();

        vm.$onInit = function () {
            // init view title
            var defaultStartData = new NbsUiData();
            vm.initViewTitle = defaultStartData.startData;
        };

        vm.$onChanges = function (changes) {
            if (changes.nbsDataContext && changes.nbsDataContext.currentValue) {
                nbsUiOptionParser = new NbsUiOptionParser(vm.uiContext.translate);

                pDataContext = changes.nbsDataContext.currentValue;

                if (!pDataContext.nbs_fieldId) {
                    console.error('grid component field id is mandatory');
                }

                initStartSelection();

                // bind to cshtml
                vm.showCommonBar = pDataContext.nbs_showCommonBar;

                if (!pGrid) {
                    createEjGrid();

                    //// events
                    if (pDataContext.events) {
                        pGrid.model.rowDrag = pDataContext.events.rowDrag;
                        pGrid.model.rowDragStart = pDataContext.events.rowDragStart;
                        pGrid.model.rowDrop = pDataContext.events.rowDrop;
                    }
                }

                if (pDataContext.nbs_dataSourceType === pDataContext.DATASOURCETYPE.Datamanager
                    && pDataContext.nbs_mode === pDataContext.GRIDMODE.Modal
                ) {
                    refreshGrid();
                }
            }

            if (changes.refreshGridUiComponent && changes.refreshGridUiComponent.currentValue) {
                refreshGridModelFromCommonFilter(changes.refreshGridUiComponent.currentValue.uiData);
            }

            if (changes.forceDatamanagerFilter && changes.forceDatamanagerFilter.currentValue) {
                if (!pGrid) {
                    console.error('nbsDataContext grid component is mandatory')
                }

                resetItemSelection();
                refreshDatamanagerFilter(changes.forceDatamanagerFilter.currentValue.filter);
                //raiseGridSelection('forceDatamanagerFilter');
            }

            if (changes.forceRefresh && changes.forceRefresh.currentValue) {
                if (!pGrid) {
                    console.error('nbsDataContext grid component is mandatory')
                    return;
                }

                resetItemSelection();
                refreshGrid();
                //raiseGridSelection('forceRefresh');
            }

            if (changes.forceDatasourceLocalChildGrid && changes.forceDatasourceLocalChildGrid.currentValue) {
                if (!pGrid) {
                    console.error('nbsDataContext grid component is mandatory')
                    return;
                }

                var detailRowList = pGrid.element.find("tr.e-detailrow");
                var childGrid;
                var detailRow;

                for (var i = 0; i < detailRowList.length; i++) {
                    detailRow = $(detailRowList[i]);
                    childGrid = detailRow.find("div").data("ejGrid");
                    if (childGrid) {
                        childGrid.dataSource(changes.forceDatasourceLocalChildGrid.currentValue.dataSource);
                    }
                }
            }

            if (changes.forceDatasourceLocal && changes.forceDatasourceLocal.currentValue) {
                if (!pGrid) {
                    console.error('nbsDataContext grid component is mandatory')
                    return;
                }

                resetItemSelection();
                pDataContext.dataSource = changes.forceDatasourceLocal.currentValue.dataSource;
                refreshGrid();
                //raiseGridSelection('forceDatasourceLocal');
            }

            if (changes.forceExport) {
                if (changes.forceExport.currentValue) {
                    exportGrid(changes.forceExport.currentValue.format);
                }
            }
        };

        function initStartSelection() {
            var idsInit = pDataContext.selectedIds ? pDataContext.selectedIds : [];
            pInitSelectionIds = idsInit;
            pGridSelection_IDS = idsInit;
            pGridSelection_ITEMS = []

            if (pDataContext.nbs_dataSourceType === pDataContext.DATASOURCETYPE.Local) {
                pGridSelection_ITEMS = Lazy(pDataContext.dataSource)
                    .filter((item) => { return Lazy(idsInit).contains(item[pDataContext.nbs_fieldId]) })
                    .toArray();
            }

            if (Lazy(idsInit).some()) {
                raiseGridSelection('initStartSelection');
            }
        }

        //#region hook from title component
        vm.onViewTitleSelectionEvent = function (result) {
            if (pDataContext.nbs_showCommonBar) {
                $timeout(function () {
                    pUIData = JSON.parse(JSON.stringify(result.uiData));

                    vm.commonBarDataContext = Nembus.Common.Library.component.getDataContextCommonGridBar(pUIData);

                    refreshGridModelFromCommonFilter(pUIData);
                });
            }
        };
        //#endregion

        //#region hook from common bar component
        vm.onUiChanges = function (result) {
            pUIData.columns = result.uiData.columns;
            pUIData.grouping = result.uiData.grouping;
            pUIData.sorting = result.uiData.sorting;
            pUIData.filters.freeText = result.uiData.filters.freeText;

            // send info to title component
            vm.viewTitleDataContext = Nembus.Common.Library.component.getDataContextViewTitle(pUIData);

            refreshGridModelFromCommonFilter(result.uiData);
        };
        //#endregion

        //#region hook from grid template
        vm.onTemplateElementClick = function (element) {
            vm.rowTemplateElementClickEvent({ element: element });
        };
        //#endregion

        /**
        * Returns 
        *   resultData => { data: null, dataIds: [], initialData: { selectionIds: [] } };
        *       
        *       pDataContext.DATASOURCETYPE.Datamanager
        *               multiselect TRUE => data => array of MANUALLY selected objects (if not empty array)
        *               multiselect FALSE => data => MANUALLY selected object (nullable)
        *       pDataContext.DATASOURCETYPE.Local
        *               multiselect TRUE => data => array of selected objects + initial selection (if not empty array)
        *               multiselect FALSE => data => selected object (nullable)
        */
        function raiseGridSelection(source) {
            if (pDataContext.nbs_isReadonly) {
                return;
            }

            if (!source) {
                source = '';
            }

            var resultData = {
                data: pGridSelection_ITEMS,
                dataIds: pGridSelection_IDS,
                isSingleSelection: pGridSelection_IDS.length === 1,
                isMultipleSelection: pGridSelection_IDS.length > 1,
                hasSelection: pGridSelection_IDS.length > 0,
                initialData: {
                    selectionIds: pInitSelectionIds
                }
            };

            // returns only current grid selection
            if (pDataContext.nbs_isMultiselect) {
                if (!Array.isArray(resultData.data)) {
                    log('raiseGridSelection multiselect is not an array');
                    return;
                }
            } else {
                if (Lazy(resultData.data).length() > 1) {
                    log('raiseGridSelection not multiselect error');
                    return;
                } else if (Lazy(resultData.data).length() === 1) {
                    resultData.data = Lazy(resultData.data).first();

                } else {
                    resultData.data = null;
                }
            }

            if (Array.isArray(resultData.data)) {
                log('Sent id (count: ' + Lazy(resultData.data).length() + ') selected list: ' + JSON.stringify(Lazy(pGridSelection_IDS)) + ' source: ' + source);
            } else {
                if (resultData.data) {
                    log('Sent id (count: 1) selected list: ' + JSON.stringify(Lazy(pGridSelection_IDS)) + ' source: ' + source);
                } else {
                    log('Sent id (count: 0) selected list: ' + JSON.stringify(Lazy(pGridSelection_IDS)) + ' source: ' + source);
                }
            }

            // raise selection to grid component caller
            vm.gridSelectionEvent({ args: resultData });
        }

        function refreshGridModelFromCommonFilter(localFilters) {
            var forceRefreshGrid = false;

            // use this order
            // 1) visibility
            // 2) grouping
            // 3) sarching
            // 4) sorting
            var colsNoCheckbox = Lazy(pGrid.model.columns)
                .filter((c) => { return c.type !== 'checkbox'; })
                .toArray();

            var fieldsInGrid = Lazy(colsNoCheckbox).pluck('field').toArray();

            var fieldsVisibleInGrid = Lazy(colsNoCheckbox)
                .filter((c) => { return true && c.visible === true; })
                .pluck('field')
                .toArray();


            if (!pGrid.model.hasCardColumns) {
                //#region visibility
                if (Lazy(localFilters.columns.selected).some()) {
                    var fieldToView = Lazy(localFilters.columns.selected).pluck('optionValue').uniq().toArray();

                    var columnsToHide = Lazy(fieldsVisibleInGrid)
                        .filter((id) => { return !Lazy(fieldToView).contains(id); })            // not hide if must be selected
                        .toArray();

                    fieldToView = Lazy(fieldToView)
                        .filter((id) => { return !Lazy(fieldsVisibleInGrid).contains(id); })  // view if not just visible
                        .filter((id) => { return id !== undefined && id !== null && id !== ''; })
                        .toArray();

                    if (Lazy(columnsToHide).some()) {
                        pGrid.hideColumns(columnsToHide);
                    }
                    if (Lazy(fieldToView).some()) {
                        pGrid.showColumns(fieldToView);
                    }
                }
                //#endregion

                //#region grouping
                var columnsIds = Lazy(localFilters.grouping.selected)
                    .filter((c) => { return Lazy(fieldsInGrid).contains(c.optionValue); })  // only if present in grid
                    .pluck('optionValue')
                    .toArray();

                if (Lazy(pGrid.model.groupSettings.groupedColumns).length() !== Lazy(columnsIds).length()) {
                    forceRefreshGrid = true;
                }
                pGrid.model.groupSettings.groupedColumns = columnsIds;
                //#endregion
            }

            //#region searching
            if (pGrid.model.searchSettings.key !== localFilters.filters.freeText.text) {
                forceRefreshGrid = true;
            }
            pGrid.model.searchSettings.key = localFilters.filters.freeText.text;
            //#endregion

            //#region sorting
            var forceRefresh = setSorting(localFilters);
            if (forceRefresh) {
                forceRefreshGrid = true;
            }
            //#endregion

            if (forceRefreshGrid || !pDataJustLoaded) {
                refreshGrid();
                pDataJustLoaded = true;
            }
        }

        function setSorting(localFilters) {
            var forceRefreshGrid = false;

            var sortColumns = [];
            if (localFilters) {
                sortColumns = Lazy(localFilters.sorting.selected)
                    //.filter((c) => { return Lazy(fieldsInGrid).contains(c.optionValue); })  // only if present in grid
                    .map((c) => { return { field: c.optionValue, direction: localFilters.sorting.direction }; })
                    .toArray();
            }

            if (!Lazy(sortColumns).some()) {
                if (pDataContext.nbs_defaultSortingField) {
                    var sortingDefault = {
                        field: pDataContext.nbs_defaultSortingField,
                        direction: pDataContext.nbs_defaultSortingDirection
                    }
                    sortColumns = [sortingDefault];
                }
            }

            var gridSort = { field: null, direction: null };
            var currSort = { field: null, direction: null };

            if (Lazy(pGrid.model.sortSettings.sortedColumns).some()) {
                gridSort.field = Lazy(pGrid.model.sortSettings.sortedColumns).first().field;
                gridSort.direction = Lazy(pGrid.model.sortSettings.sortedColumns).first().direction;
            };
            if (Lazy(sortColumns).some()) {
                currSort.field = Lazy(sortColumns).first().field;
                currSort.direction = Lazy(sortColumns).first().direction;
            };

            if (gridSort.field !== currSort.field || gridSort.direction !== currSort.direction) {
                forceRefreshGrid = true;
            }

            pGrid.model.sortSettings.sortedColumns = sortColumns;

            return forceRefreshGrid;
        }

        function refreshDatamanagerFilter(filter) {
            var currentDs = pDataContext.dataSource.dmgrid;
            currentDs.complexParams = [filter];
            pGrid.dataSource(currentDs);
        }

        function refreshGrid() {
            if (pDataContext.nbs_dataSourceType === pDataContext.DATASOURCETYPE.Datamanager) {
                // datamanager mode
                if (!pGrid._dataSource() || !Lazy(pGrid._dataSource()).some()) {
                    pGrid.dataSource(pDataContext.dataSource.dmgrid);
                } else {
                    pGrid.refreshData()
                }
            } else if (pDataContext.nbs_dataSourceType === pDataContext.DATASOURCETYPE.Local) {
                // local datasource mode
                pGrid.dataSource(pDataContext.dataSource);
            } else {
                console.error('nbs_dataSourceType not managed: "local" or "datamanager"');
            }
        }

        function setGridSelection(currentViewData) {
            if (!Lazy(currentViewData).some()) {
                return;
            }

            Lazy(currentViewData)
                .each((item) => {
                    if (Lazy(pGridSelection_IDS).contains(item[pDataContext.nbs_fieldId])) {
                        isManuallyAction = false;
                        pGrid.selectRows([currentViewData.indexOf(item)]);
                    }
                });
        }

        function createEjGrid() {
            var ejGrid = new pEjGrid(NbsGuidManager);

            setGridHtml(ejGrid);

            setGridColumns(ejGrid);

            // grid events 
            ejGrid.load = function (args) {
                log('load');
                log('selection behavior: ' + pDataContext.nbs_selectionBehavior);

                pGridJustLoaded = true;

                pGrid = $("#" + ejGrid.gridId).data('ejGrid');
                pGrid.gridId = ejGrid.gridId;
            }
            ejGrid.create = function (args) {
                log('create');

                pGridJustCreated = true;

                cleanHtmlGrid();
                setGridSelection(args.model.currentViewData);

                var barDatasource = {
                    elementType: 'ejGrid',
                    visibilityColumns: getDsVisibleColumns(pGrid),
                    groupingColumns: getDsGroupedColumns(pGrid),
                    sortingColumns: getDsSortingColumns(pGrid)
                };

                if (pDataContext.nbs_showCommonBar) {
                    $timeout(function () {
                        barDatasource.modeUI = 'commongrid';
                        vm.commonBarDatasource = barDatasource;
                    });
                }

                if (pDataContext.nbs_showCustomBar) {
                    barDatasource.modeUI = 'custom';
                }

                if (pDataContext.nbs_mode === pDataContext.GRIDMODE.Stream) {
                    barDatasource.modeUI = 'stream';
                }

                vm.createGridEvent({ result: { gridId: ejGrid.gridId, barDatasource: barDatasource } });
            };
            ejGrid.queryCellInfo = function (args) {
                log('queryCellInfo');

                pOverloadQueryCellInfo(args);
                vm.queryCellInfoEvent({ args: args })
            };
            ejGrid.recordDoubleClick = function (args) {
                log('recordDoubleClick');
                vm.rowDoubleClickEvent({ args: args })
            };
            ejGrid.actionBegin = function (args) {
                log('actionBegin: requestType ' + args.requestType);
            }
            ejGrid.actionComplete = function (args) {
                if (pGridJustLoaded === true && pGridJustCreated === true) {
                    if (args.requestType === 'sorting'
                        || args.requestType === 'paging'
                        || args.requestType === 'searching'
                        || args.requestType === 'refresh') {

                        log('actionComplete: requestType ' + args.requestType);

                        if (pDataContext.nbs_selectionBehavior === pDataContext.SELECTIONBEHAVIOR.Default) {
                            resetItemSelection();
                            raiseGridSelection(args.requestType);
                            pGrid.clearSelection();
                        } else if (pDataContext.nbs_selectionBehavior === pDataContext.SELECTIONBEHAVIOR.KeepSelection) {
                            if (args.requestType === 'paging') {
                                pGrid.clearSelection();
                            }

                            setGridSelection(args.model.currentViewData);
                        }

                        if (args.requestType === 'refresh' || args.requestType === 'paging' || args.requestType === 'searching') {

                            // childgrid datasource
                            if (pDataContext.nbs_dataSourceType === pDataContext.DATASOURCETYPE.Datamanager
                                && pDataContext.nbs_childGrid
                                && pDataContext.dataSource.dm) {

                                if (pDataContext.dataSource.dm.currentViewChildGridData === null
                                    || pDataContext.dataSource.dm.currentViewChildGridData === undefined) {

                                    pGrid.model.childGrid.dataSource = [];
                                } else {
                                    pGrid.model.childGrid.dataSource = pDataContext.dataSource.dm.currentViewChildGridData;
                                }
                            }
                        }
                    }
                }
            };
            ejGrid.rowSelecting = function (args) {
                log('rowSelecting');

                isManuallyAction = false;

                if (args.target) {
                    isManuallyAction = true;
                } else if (Array.isArray(args.data)) {
                    isManuallyAction = true;
                }
            }
            ejGrid.rowSelected = function (args) {
                if (isManuallyAction) {
                    log('rowSelected');

                    if (pDataContext.nbs_isMultiselect) {
                        addItemToSelection(args);
                    } else {
                        resetItemSelection();
                        addItemToSelection(args);
                    }

                    raiseGridSelection('rowSelected');

                    if (!pDataContext.nbs_isReadonly) {
                        vm.rowSelectedEvent({ args: args });
                    }
                }
            };
            ejGrid.rowDeselecting = function (args) {
                if (pDataContext.nbs_isMultiselect) {
                    log('rowDeselecting');

                    isManuallyAction = false;

                    // args.row.length > 1 when is clicked manually checkbox on header
                    if (Array.isArray(args.data) && args.data.length > 1) {
                        isManuallyAction = true;
                    } else if (!args.row.context) {
                        isManuallyAction = true;
                    }
                }
            }
            ejGrid.rowDeselected = function (args) {
                if (pDataContext.nbs_isMultiselect) {
                    if (isManuallyAction) {
                        log('rowDeselected');
                        removeItemFromSelection(args);

                        raiseGridSelection('rowDeselected');

                        if (!pDataContext.nbs_isReadonly) {
                            vm.rowDeselectedEvent({ args: args });
                        }
                    }
                }
            };
            ejGrid.rowDragStart = function (args) {
                vm.rowDragStartEvent({ args: args });
            }
            ejGrid.rowDrop = function (args) {
                vm.rowDropEvent({ args: args });
            }

            // create ejGrid
            $("#" + ejGrid.gridId).ejGrid(ejGrid);
        }

        function cleanHtmlGrid() {
            if (!pDataContext.nbs_showCommonBar
                && !pDataContext.nbs_showCustomBar
                && pDataContext.nbs_mode !== pDataContext.GRIDMODE.Stream
                && pDataContext.nbs_mode !== pDataContext.GRIDMODE.Board) {

                return;
            }

            var gridElement = $('#' + pGrid.gridId);
            if (gridElement) {
                if (pGrid.model.allowGrouping) {
                    // remove drop area 
                    $(gridElement).find('.e-groupdroparea').hide();
                }
                if (pGrid.model.allowSearching) {
                    // remove search textbox
                    $(gridElement).find('.e-gridtoolbar').hide();
                    $(gridElement).find('.e-gridtoolbar').removeClass('e-toolbarspan');
                }

                // hide text search html element because is used search of common grid component
                var gridSearchElement = $('#' + pGrid.gridId + '_SearchBox');
                gridSearchElement.remove();

                //$(gridElement).find('.e-gridcontent').attr('style', 'width: auto;');
            }
        }

        function resetItemSelection() {
            pGridSelection_ITEMS = [];
            pGridSelection_IDS = [];
        }

        function addItemToSelection(args) {
            var selected = args.data;
            var currentViewData = args.model.currentViewData;

            if (Array.isArray(selected)) {
                // if selection is array then master checkbox is clicked
                // only current grid page must be selected
                var selectedIds = Lazy(selected).pluck(pDataContext.nbs_fieldId).toArray();
                var selectionFilterByCurrentPage = Lazy(currentViewData)
                    .filter((currentViewItem) => { return Lazy(selectedIds).contains(currentViewItem[pDataContext.nbs_fieldId]) })
                    .toArray();

                Lazy(selectionFilterByCurrentPage)
                    .each((item) => {
                        // item
                        if (!Lazy(pGridSelection_ITEMS).contains(item)) {
                            pGridSelection_ITEMS.push(item);
                        };
                        // item ID
                        if (!Lazy(pGridSelection_IDS).contains(item[pDataContext.nbs_fieldId])) {
                            pGridSelection_IDS.push(item[pDataContext.nbs_fieldId]);
                        };
                    });
            } else {
                // item
                if (!Lazy(pGridSelection_ITEMS).contains(selected)) {
                    pGridSelection_ITEMS.push(selected);
                };
                // item ID
                if (!Lazy(pGridSelection_IDS).contains(selected[pDataContext.nbs_fieldId])) {
                    pGridSelection_IDS.push(selected[pDataContext.nbs_fieldId]);
                };
            }
        }

        function removeItemFromSelection(args) {
            var deselected = args.data;
            var currentViewData = args.model.currentViewData;

            if (Array.isArray(deselected)) {
                // if deselection is array then master checkbox is clicked
                // only current grid page must be selected
                var deselectedIds = Lazy(deselected).pluck(pDataContext.nbs_fieldId).toArray();
                var deselectionFilterByCurrentPage = Lazy(currentViewData)
                    .filter((currentViewItem) => { return Lazy(deselectedIds).contains(currentViewItem[pDataContext.nbs_fieldId]) })
                    .toArray();

                Lazy(deselectionFilterByCurrentPage)
                    .each((item) => {
                        // item
                        pGridSelection_ITEMS = Lazy(pGridSelection_ITEMS)
                            .filter((curritem) => { return curritem[pDataContext.nbs_fieldId] !== item[pDataContext.nbs_fieldId] })
                            .toArray();

                        // item ID
                        pGridSelection_IDS = Lazy(pGridSelection_IDS).without(item[pDataContext.nbs_fieldId]).toArray();
                    });
            } else {
                // item
                pGridSelection_ITEMS = Lazy(pGridSelection_ITEMS)
                    .filter((curritem) => { return curritem[pDataContext.nbs_fieldId] !== deselected[pDataContext.nbs_fieldId] })
                    .toArray();

                // item ID
                pGridSelection_IDS = Lazy(pGridSelection_IDS).without(deselected[pDataContext.nbs_fieldId]).toArray();
            }
        }

        function log(key, log) {
            if (!pConsoleLogEnabled) {
                return;
            }

            var logMsg = key.toString();
            if (log) {
                logMsg += ': ' + log.toString();
            }
            console.log(logMsg);
        }

        function getDsVisibleColumns(grid) {
            return nbsUiOptionParser.getUiOptions(
                Lazy(grid.model.columns).filter((column) => { return column.type !== 'checkbox'; })
                    .map(mapGridColumnToUiOption)
                    .toArray()
            );
        }

        function getDsGroupedColumns(grid) {
            return nbsUiOptionParser.getUiOptions(
                Lazy(grid.model.columns).filter((column) => { return column.type !== 'template' && column.type !== 'templateContent' && column.type !== 'checkbox' && column.type !== 'boolean'; })
                    .map(mapGridColumnToUiOption)
                    .toArray()
            );
        }

        function getDsSortingColumns(grid) {
            if (grid.model.hasCardColumns) {
                return nbsUiOptionParser.getUiOptions(
                    Lazy(grid.model.cardColumns).filter((column) => { return column.type !== 'template' && column.type !== 'templateContent' && column.type !== 'checkbox' && column.type !== 'boolean'; })
                        .map(mapGridColumnToUiOption)
                        .toArray()
                );
            } else {
                return nbsUiOptionParser.getUiOptions(
                    Lazy(grid.model.columns).filter((column) => { return column.type !== 'template' && column.type !== 'templateContent' && column.type !== 'checkbox' && column.type !== 'boolean'; })
                        .map(mapGridColumnToUiOption)
                        .toArray()
                );
            }
        }

        function mapGridColumnToUiOption(c) {
            return {
                optionValue: c.field,
                optionName: c.headerText,
                optionDisplayName: c.headerText,
                optionVisible: c.type !== 'checkbox'
            };
        }

        function setGridHtml(grid) {
            var gridId = grid.gridId;
            var containerId = 'container_' + gridId;
            var parentContainerId = 'parent_container_' + gridId;

            // set id to container html element
            $('#nbs_container_grid_placeholder').attr('id', containerId);
            // set id to parent container html element
            $('#nbs_parent_container_grid_placeholder').attr('id', parentContainerId);
            // set id to grid html element
            $('#' + containerId).find('div').attr('id', gridId);

            var styleContainer = 'width: 100%; display: inline-block;';
            switch (pDataContext.nbs_mode) {
                case pDataContext.GRIDMODE.Stream:
                case pDataContext.GRIDMODE.Board:
                case pDataContext.GRIDMODE.Modal:
                case pDataContext.GRIDMODE.Grid:
                    styleContainer = 'width: 100%;';
                    break;
            }

            if (pDataContext.nbs_adaptToPage || pDataContext.nbs_useListView || pDataContext.nbs_renderIntoUibTab) {
                styleContainer = 'width: 100%;';
            }

            if (pDataContext.nbs_useListView
                && pDataContext.nbs_mode !== pDataContext.GRIDMODE.Stream
                && pDataContext.nbs_mode !== pDataContext.GRIDMODE.Board) {

                styleContainer = 'width: 100%; margin-left:10px; margin-right:10px;';
            }

            // set style to container html element
            if (styleContainer) {
                $('#' + containerId).attr('style', styleContainer);
            }

            // set style to container parent div html element
            if (pDataContext.nbs_renderIntoUibTab || pDataContext.nbs_renderInlineBlock) {
                $('#' + parentContainerId).attr('style', 'display: inline-block;');
            }

            // set style to grid html element
            if (pDataContext.nbs_useListView
                || pDataContext.nbs_mode === pDataContext.GRIDMODE.Board
                || pDataContext.nbs_mode === pDataContext.GRIDMODE.Stream) {

                $('#' + gridId).attr('class', 'grid-mobile').attr('style', 'width: auto;');// active row background
            }

        }

        function setGridColumns(grid) {
            if (!pDataContext.columns) {
                return;
            }

            var gridColumns = [];
            Lazy(pDataContext.columns)
                .each((column) => {
                    switch (column.type) {
                        case 'id':
                            gridColumns.push(new pEjColumnID(column));
                            break;
                        case 'string':
                            gridColumns.push(new pEjColumn(column));
                            break;
                        case 'date':
                            gridColumns.push(new pEjColumnDate(column));
                            break;
                        case 'number':
                            gridColumns.push(new pEjColumnNumber(column));
                            break;
                        case 'template':
                            gridColumns.push(new pEjColumnTemplate(column));
                            break;
                        case 'templateContent':
                            gridColumns.push(new pEjColumnTemplateContent(column));
                            break;
                        case 'displayAsCheckbox':
                            gridColumns.push(new pEjColumnDisplayAsCheckbox(column));
                            break;
                        default:
                            console.error('Grig component: column type not setted');
                            break;
                    }
                });

            var hasCardTemplateColumn = false;
            if (pDataContext.cardSettings.isEnabled) {
                if (pDataContext.nbs_mode === pDataContext.GRIDMODE.Stream) {
                    grid.columns.push({
                        field: '',
                        header: ' ',
                        template: pDataContext.cardSettings.streamTemplateContent,
                        allowSorting: true,
                        visible: true
                    });
                    hasCardTemplateColumn = true;
                } else if (pDataContext.nbs_mode === pDataContext.GRIDMODE.Board) {
                    grid.columns.push({
                        field: '',
                        header: ' ',
                        template: pDataContext.cardSettings.boardTemplateContent,
                        allowSorting: true,
                        visible: true
                    });
                    hasCardTemplateColumn = true;
                } else if ((pDataContext.nbs_mode === pDataContext.GRIDMODE.Grid) && pDataContext.nbs_useListView) {
                    grid.columns.push({
                        field: '',
                        header: ' ',
                        template: pDataContext.cardSettings.gridTemplateContent,
                        allowSorting: true,
                        visible: true
                    });
                    hasCardTemplateColumn = true;
                }

                if (hasCardTemplateColumn) {
                    grid.hasCardColumns = true;
                    grid.cardColumns = gridColumns;
                }
            }

            if (!hasCardTemplateColumn) {
                if (pDataContext.nbs_isMultiselect) {
                    grid.columns.push(new pEjColumnCheckbox());
                }

                Lazy(gridColumns)
                    .each((column) => {
                        grid.columns.push(column);
                    });


                grid.hasCardColumns = false;
                grid.cardColumns = [];
            }
        }

        //#region EjGrid classes
        class pEjGrid {
            constructor(nbsGuidManager) {
                this.gridId = nbsGuidManager.newGuidReplaced();
                if (pDataContext.nbs_dataSourceType === 'local') {
                    this.dataSource = pDataContext.dataSource;
                } else {
                    this.dataSource = [];
                }

                this.columns = [];

                this.locale = pDataContext.locale;
                this.allowPaging = pDataContext.allowPaging;
                this.pageSettings = pDataContext.pageSettings;
                this.allowSorting = pDataContext.allowSorting;
                this.allowTextWrap = pDataContext.allowTextWrap;
                this.editSettings = pDataContext.editSettings;

                if (pDataContext.nbs_childGrid) {
                    this.childGrid = {
                        dataSource: pDataContext.nbs_childGrid.dataSource,
                        queryString: pDataContext.nbs_childGrid.queryString,
                        columns: pDataContext.nbs_childGrid.columns,
                        allowSorting: pDataContext.nbs_childGrid.allowSorting,

                        recordDoubleClick: function (args) {
                            vm.childGridRecordDoubleClick({ args: args })
                        },
                        rowSelected: function (args) {
                            vm.childGridRowSelected({ args: args })
                        },
                        queryCellInfo: function (args) {
                            log('queryCellInfoChild');
                            vm.childGridQueryCellInfo({ args: args })
                        }
                    };
                }

                if (!pDataContext.nbs_showCommonBar) {
                    if (pDataContext.nbs_defaultSortingField) {
                        var sortingDefault = {
                            field: pDataContext.nbs_defaultSortingField,
                            direction: pDataContext.nbs_defaultSortingDirection
                        }
                        this.sortSettings = { sortedColumns: [sortingDefault] };
                    }
                }

                this.allowSelection = pDataContext.allowSelection;
                this.allowScrolling = pDataContext.allowScrolling;
                if (this.allowScrolling) {
                    this.scrollSettings = pDataContext.scrollSettings;
                }
                this.allowFiltering = pDataContext.allowFiltering;
                if (this.allowFiltering) {
                    this.filterSettings = { enableCaseSensitivity: false, filterType: ej.Grid.FilterType.Menu /*ej.Grid.FilterType.Excel*/ };
                }

                this.allowSearching = pDataContext.allowSearching;
                this.toolbarSettings = { showToolbar: pDataContext.allowSearching, toolbarItems: [ej.Grid.ToolBarItems.Search] };
                this.isResponsive = pDataContext.isResponsive;
                this.allowResizing = pDataContext.allowResizing;
                this.allowResizeToFit = pDataContext.allowResizeToFit;
                this.allowReordering = pDataContext.allowReordering;
                this.allowGrouping = pDataContext.allowGrouping;
                this.allowRowDragAndDrop = pDataContext.allowRowDragAndDrop;
                this.selectionType = pDataContext.selectionType;

                this.cardColumns = [];
                this.hasCardColumns = false;
            }
        }

        class pEjGridColumn {
            constructor(col) {
                this.field = col ? col.field : null;
                this.headerText = col ? col.header : null;
                this.width = col ? col.width : null;
                this.textAlign = ej.TextAlign.Left;
                this.tooltip = '{{:value}}';
                this.clipMode = ej.Grid.ClipMode.EllipsisWithTooltip;
                this.displayAsCheckbox = false;
                this.allowResizing = col ? col.allowResizing : false;
                this.allowEditing = col ? col.allowEditing : false;
            }
        }

        class pEjColumnID extends pEjGridColumn {
            constructor(col) {
                super(col);
            }
        };

        class pEjColumnCheckbox extends pEjGridColumn {
            constructor(col) {
                super(col);

                this.field = '';
                this.textAlign = ej.TextAlign.Center;
                this.type = 'checkbox';
                this.width = 60;
            }
        };

        class pEjColumnDate extends pEjGridColumn {
            constructor(col) {
                super(col);

                this.type = 'date';
            }
        };

        class pEjColumnNumber extends pEjGridColumn {
            constructor(col) {
                super(col);

                this.textAlign = ej.TextAlign.Right;
                this.type = 'number';
            }
        };

        class pEjColumnDisplayAsCheckbox extends pEjGridColumn {
            constructor(col) {
                super(col);

                this.displayAsCheckbox = true;
                this.type = 'boolean';
            }
        };

        class pEjColumn extends pEjGridColumn {
            constructor(col) {
                super(col);
            }
        };

        class pEjColumnTemplate extends pEjGridColumn {
            constructor(col) {
                super(col);

                if (!col.templateId.startsWith('#')) {
                    col.templateId = '#' + col.templateId;
                }
                this.templateID = col.templateId;
                this.tooltip = null;
                this.clipMode = null;
                this.textAlign = null;
                this.allowSorting = false;
                this.displayAsCheckbox = false;
            }
        };

        class pEjColumnTemplateContent extends pEjGridColumn {
            constructor(col) {
                super(col);

                this.template = col.templateContent;
                this.tooltip = null;
                this.clipMode = null;
                this.textAlign = null;
                this.allowSorting = false;
                this.displayAsCheckbox = false;
            }
        };
        //#endregion        

        //#region EjGrid events
        function pOverloadQueryCellInfo(args) {
            if (pGrid.model.hasCardColumns) {
                //auto formatting enabled only for grid
                return;
            }

            var currentFieldName = args.column.field;

            if (pDataContext.nbs_queryCellInfo_mapping_formatter) {
                var formatter_fields = Object.keys(pDataContext.nbs_queryCellInfo_mapping_formatter);
                if (Lazy(formatter_fields).some()) {
                    if (Lazy(formatter_fields).contains(currentFieldName)) {
                        var formatter = pDataContext.nbs_queryCellInfo_mapping_formatter[currentFieldName];
                        var currValue = getCurrentFieldValue(args, currentFieldName);
                        if (currValue !== null && currValue !== undefined) {
                            switch (formatter) {
                                case 'formatBoolean':
                                    args.cell.innerHTML = Nembus.Common.Library.converter.grid.formatBoolean(currValue, vm.uiContext.translate);
                                    break;
                                case 'formatDate':
                                    args.cell.innerHTML = Nembus.Common.Library.converter.date.formatDate(currValue, contextService.virtualContext.cultureInfo, false);
                                    break;
                                case 'formatTime':
                                    args.cell.innerHTML = Nembus.Common.Library.converter.date.formatTime(currValue, contextService.virtualContext.cultureInfo, true);
                                    break;
                                case 'formatDateTime':
                                    args.cell.innerHTML = Nembus.Common.Library.converter.date.formatDateTime(currValue, contextService.virtualContext.cultureInfo, false);
                                    break;
                                case 'formatDateTimeIgnoreTimeZone':
                                    args.cell.innerHTML = Nembus.Common.Library.converter.date.formatDateTime(currValue, contextService.virtualContext.cultureInfo, true);
                                    break;
                                case 'formatDateIgnoreTimeZone':
                                    args.cell.innerHTML = Nembus.Common.Library.converter.date.formatDate(currValue, contextService.virtualContext.cultureInfo, true);
                                    break;
                                case 'formatDateTimeLocalToUtc':
                                    args.cell.innerHTML = Nembus.Common.Library.converter.date.formatDateTimeLocalToUtc(currValue, contextService.virtualContext.cultureInfo);
                                    break;
                                case 'formatTimeSpan':
                                    args.cell.innerHTML = Nembus.Common.Library.converter.timespan.jsonToString(args.cell.innerHTML);
                                    break;
                                case 'formatDecimal':
                                    args.cell.innerHTML = Nembus.Common.Library.converter.number.formatDecimal(currValue, $locale);
                                    break;
                            }
                        }
                    }
                }
            }

            // a custom method like formatter
            if (pDataContext.nbs_queryCellInfo_mapping_custom_method_formatter) {
                var customFormatterFn = null;
                var formatter_method_fields = Object.keys(pDataContext.nbs_queryCellInfo_mapping_custom_method_formatter);
                if (Lazy(formatter_method_fields).some()) {
                    if (Lazy(formatter_method_fields).contains(currentFieldName)) {
                        customFormatterFn = pDataContext.nbs_queryCellInfo_mapping_custom_method_formatter[currentFieldName];
                        if (customFormatterFn) {
                            customFormatterFn(args);
                        }
                    } else {
                        if (Lazy(formatter_method_fields).contains('nbsAllCellInfo')) {
                            customFormatterFn = pDataContext.nbs_queryCellInfo_mapping_custom_method_formatter['nbsAllCellInfo'];
                            if (customFormatterFn) {
                                customFormatterFn(args);
                            }
                        }
                    }
                }
            }
        }

        function getCurrentFieldValue(args, fieldName) {
            var currentFieldValue = null;
            if (Lazy(fieldName).contains('.')) {
                var arrField = fieldName.split('.');
                currentFieldValue = args.data[arrField[0]][arrField[1]];
            } else {
                currentFieldValue = args.data[fieldName];
            }
            return currentFieldValue;
        }
        //#endregion


        function exportGrid(format) {
            // for export
            //vm.uiContext.exportObjGrid.push({ gridCode: 'nbsexport_componentgrid', gridObj: $("#" + ejGrid.gridId) });

            var exportGridData = $("#" + pGrid.gridId);
            var exportGridObj = {};
            exportGridObj.ContentTable = exportGridData.data("ejGrid").getContentTable()[0].outerHTML;
            exportGridObj.HeaderTable = exportGridData.data("ejGrid").getHeaderTable()[0].outerHTML;
            exportGridObj.ExportType = format;
            exportGridObj.ObjectType = 'grid';

            var tmp = exportGridData.data("ejGrid").model.currentViewData; //workaround to avoid circular exception JSON
            exportGridData.data("ejGrid").model.currentViewData = [];

            exportGridData.data("ejGrid").model.currentViewData = tmp;
            navigationService.exportGridData(exportGridObj)
                .then(
                    function (response) {
                        var pgTitle = Nembus.Common.Library.cms.viewTitle(navigationService.currentCmsViewCode());
                        var ext = null;
                        if (format === 'excel') {
                            ext = '.xlsx';
                        }
                        if (format === 'word') {
                            ext = '.docx';
                        }
                        Nembus.Common.Library.converter.streamToDownload(pgTitle + ext, response.buffer.data);
                    })
                .catch(function (error) {
                    var x = error;
                });
        };
    };

    nbsGridComponent.$inject = injectParams;
    angular.module('nbs.rds.module').component('nbsGrid', {
        templateUrl: '/rds/common/components/nbsGridComponent/_?c=' + window.codeCache(),
        controller: nbsGridComponent,
        controllerAs: 'vm',
        bindings: {
            nbsDataContext: "<",
            uiContext: "<",
            // used from excel, word exports
            forceExport: '<',

            // events to caller
            createGridEvent: "&",

            rowSelectedEvent: "&",
            rowDeselectedEvent: "&",
            rowDoubleClickEvent: "&",
            queryCellInfoEvent: "&",
            gridSelectionEvent: "&",

            // childgrid events
            childGridRecordDoubleClick: "&",
            childGridQueryCellInfo: "&",
            childGridRowSelected: "&",

            rowDragStartEvent: "&",
            rowDropEvent: "&",
            rowTemplateElementClickEvent: "&",

            // refresh grid
            forceRefresh: "<",
            forceDatasourceLocal: "<",
            forceDatamanagerFilter: "<",
            forceDatasourceLocalChildGrid: "<",

            refreshGridUiComponent: "<",
        }
    });
});

