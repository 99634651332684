import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppPanelDirective } from './navigation/appPanel';
import { AppHomeDirective } from './navigation/appHome';
import { AppLightPanelDirective } from './navigation/appLightPanel';
import { ModuleHomeDirective } from './navigation/moduleHome';

@NgModule({
    imports: [
        BrowserModule,
        UpgradeModule
    ],
    declarations: [
        AppPanelDirective,
        AppLightPanelDirective,
        AppHomeDirective,
        ModuleHomeDirective
    ],
    entryComponents: [
        AppPanelDirective,
        AppLightPanelDirective,
        AppHomeDirective,
        ModuleHomeDirective
    ],
})

export class ShellModule {

}


// @ts-ignore
angular.module('nbs.shell.module')
    .config(['$stateProvider',

        function ($stateProvider) {
            // @ts-ignore
            var routeObj = routeResolver.routeObj;

            $stateProvider
                .state(routeObj.resolve({ isNg2: true, name: 'app.nav', url: '', template: '<app-panel></app-panel>', regionName: 'appRegion' }))
                .state(routeObj.resolve({ isNg2: true, name: 'app.navlight', url: '', template: '<app-light-panel></app-light-panel>', regionName: 'appRegion' }))

                .state(routeObj.resolve({ isNg2: true, name: 'app.nav.appHome', url: '', template: '<app-home></app-home>' }))
                .state(routeObj.resolve({ isNg2: true, name: 'app.nav.modulehome-tec', url: '/moduleHome-tec', template: '<module-home></module-home>' }))
                .state(routeObj.resolve({ isNg2: true, name: 'app.nav.modulehome-ced', url: '/moduleHome-ced', template: '<module-home></module-home>' }))
                .state(routeObj.resolve({ isNg2: true, name: 'app.nav.modulehome-acc', url: '/moduleHome-acc', template: '<module-home></module-home>' }))
                .state(routeObj.resolve({ isNg2: true, name: 'app.nav.modulehome-ats', url: '/moduleHome-ats', template: '<module-home></module-home>' }))
                .state(routeObj.resolve({ isNg2: true, name: 'app.nav.modulehome-hrs', url: '/moduleHome-hrs', template: '<module-home></module-home>' }))
                .state(routeObj.resolve({ isNg2: true, name: 'app.nav.modulehome-its', url: '/moduleHome-its', template: '<module-home></module-home>' }))
                .state(routeObj.resolve({ isNg2: true, name: 'app.nav.modulehome-ers', url: '/moduleHome-ers', template: '<module-home></module-home>' }))
                .state(routeObj.resolve({ isNg2: true, name: 'app.nav.modulehome-oms', url: '/moduleHome-oms', template: '<module-home></module-home>' }))
                .state(routeObj.resolve({ isNg2: true, name: 'app.nav.modulehome-ets', url: '/moduleHome-ets', template: '<module-home></module-home>' }))
                .state(routeObj.resolve({ isNg2: true, name: 'app.nav.modulehome-jam', url: '/moduleHome-jam', template: '<module-home></module-home>' }))
                .state(routeObj.resolve({ isNg2: true, name: 'app.nav.modulehome-jts', url: '/moduleHome-jts', template: '<module-home></module-home>' }))
                .state(routeObj.resolve({ isNg2: true, name: 'app.nav.modulehome-sam', url: '/moduleHome-sam', template: '<module-home></module-home>' }))
                .state(routeObj.resolve({ isNg2: true, name: 'app.nav.modulehome-srm', url: '/moduleHome-srm', template: '<module-home></module-home>' }))
                .state(routeObj.resolve({ isNg2: true, name: 'app.nav.modulehome-sra', url: '/moduleHome-sra', template: '<module-home></module-home>' }))
                .state(routeObj.resolve({ isNg2: true, name: 'app.nav.modulehome-bsi', url: '/moduleHome-bsi', template: '<module-home></module-home>' }))
                .state(routeObj.resolve({ isNg2: true, name: 'app.nav.modulehome-mpl', url: '/moduleHome-mpl', template: '<module-home></module-home>' }))
                .state(routeObj.resolve({ isNg2: true, name: 'app.nav.modulehome-tes', url: '/moduleHome-tes', template: '<module-home></module-home>' }))
                .state(routeObj.resolve({ isNg2: true, name: 'app.nav.modulehome-ims', url: '/moduleHome-ims', template: '<module-home></module-home>' }))
                .state(routeObj.resolve({ isNg2: true, name: 'app.nav.modulehome-ecc', url: '/moduleHome-ecc', template: '<module-home></module-home>' }))
                .state(routeObj.resolve({ isNg2: true, name: 'app.nav.modulehome-wcs', url: '/moduleHome-wcs', template: '<module-home></module-home>' }))
                .state(routeObj.resolve({ isNg2: true, name: 'app.nav.modulehome-aud', url: '/moduleHome-aud', template: '<module-home></module-home>' }))
                .state(routeObj.resolve({ isNg2: true, name: 'app.nav.modulehome-audtpl', url: '/moduleHome-audtpl', template: '<module-home></module-home>' }))
                .state(routeObj.resolve({ isNg2: true, name: 'app.nav.modulehome-audtgt', url: '/moduleHome-audtgt', template: '<module-home></module-home>' }))
                .state(routeObj.resolve({ isNg2: true, name: 'app.nav.modulehome-act', url: '/moduleHome-act', template: '<module-home></module-home>' }))
        }]);