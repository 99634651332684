﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'navigationService', 'checkStateChangeService', 'contextService', 'formSummaryService'];
    function vhrToCategoriesDetail($q, $scope, $timeout, $injector, foundationService, navigationService, checkStateChangeService, contextService, formSummaryService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, foundationService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var virtualHumanResourceId = navigationService.stateParams().virtualHumanResourceId;
        var hrMetadataId = navigationService.stateParams().hrMetadataId;
        var systemCategoryCode = navigationService.stateParams().systemCategoryCode;
        if (!systemCategoryCode) {
            systemCategoryCode = 'BSC';
        }
        var magicBucketActionEnum = foundationService.dataContext.enumTypes.MagicBucketAction;
        var categoryTextPathDtoParamStartFromEnum = Nembus.Common.Library.util.parseEnum(foundationService.dataContext.enumTypes.CategoryTextPathDtoParamStartFrom, null, vm.translate).obj.normal.name_value;

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('category', 'vhr');
        nbsGridDatacontext.allowPaging = false;
        nbsGridDatacontext.allowSearching = false;
        nbsGridDatacontext.allowGrouping = false;
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getCategoryTextPathDtos(false));
            funcsPromises.push(getHrSingleXCategories(true, hrMetadataId));
            $q.all(funcsPromises)
                .then(function (results) {
                    vm.categoryTextPathDtos = results[0];
                    vm.currentCategories = results[1];

                    Nembus.Common.Library.util.addCategoryTextPath(vm.currentCategories, vm.categoryTextPathDtos);

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.currentCategories
                    }
                });
        }

        vm.Expanded = true;
        ribbon.setRibbonStatusButton(ribbon.Buttons.New,  false);
        ribbon.setRibbonStatusButton(ribbon.Buttons.Modify,true);
        ribbon.refreshStatusButtons();

        // get array items for form summary
        vm.formSummary = formSummaryService.getDatasource();

        vm.currentCategories = [];      // binded to UI
        vm.currentMagicBucketRequest = null; //have to add new Request each submit => synchro hrs/buckets/activities

        // get reference of submitaction present into nbs-vm
        // this allow to manage response and do more in current controller
        vm.parentSubmitAction = vm.submitAction;
        vm.submitAction = function () {
            vm.parentSubmitAction()
                .then(
                    function (saveResult) {
                        setBatchContext(vm.currentMagicBucketRequest);
                        vm.currentMagicBucketRequest = null;
                    }
                );
        };

        vm.deleteAction = function () {
            if (!vm.groupSelected) {
                return;
            }            

            foundationService.deleteEntity(vm.groupSelected);

            addMagicBucketRequest(); //request for fire bucket/hr/activity binding
            //todo remove submit/remove from list
            vm.parentSubmitAction()
                .then(function (saveResult) {
                    vm.currentCategories = Lazy(foundationService.dataContext.hrMetadataM2MCategory.get())
                        .where({ HrMetadataId: Number(hrMetadataId) })
                        .toArray();

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.currentCategories
                    }

                    setBatchContext(vm.currentMagicBucketRequest);
                    vm.currentMagicBucketRequest = null; //have to add new Request each submit 
                });
        };

        vm.gridSelection = function (args) {
            vm.groupSelected = args.data;
        };

        vm.newAction = function () {
            navigationService.navToModal(vm.suspendDirty, 'app.nav.categoriesselection', 
                { systemCategoryCode: systemCategoryCode, categoryType: 'user' }
            ).then(function (result) {
                setCategories(result.data);
            });
        };

        vm.refreshAction = function () {
            loadData(true);
        };

        function setCategories(categories) {
            var targetCategories = Lazy(vm.currentCategories).pluck("CategoryId").toArray();

            Lazy(categories).each((category) => {
                if (!Lazy(targetCategories).contains(category.CategoryId)) {
                    if (category.CategoryKindId === foundationService.dataContext.consts.CATEGORYKINDS.category) {
                        vm.currentCategories.push(mapCategoryToVhrToCategories(category));
                        addMagicBucketRequest(); //request for fire bucket/hr/activity binding
                    }
                }

                Nembus.Common.Library.util.addCategoryTextPath(vm.currentCategories, vm.categoryTextPathDtos);

                vm.forceDatasourceLocal = {
                    refreshkey: new Date().getTime(),
                    dataSource: vm.currentCategories
                }
            });
        }

        function mapCategoryToVhrToCategories(itemToInsert) {
            return foundationService.dataContext.hrMetadataM2MCategory.add({
                CategoryId: itemToInsert.CategoryId,
                CategoryCode: itemToInsert.CategoryCode,
                CategoryName: itemToInsert.CategoryName,
                HrMetadataId: hrMetadataId
            });
        }

        function getHrSingleXCategories(forceReload, hrMetadataId) {
            var filter = {
                HrMetadataId: hrMetadataId,
                VirtualHumanResourceId: virtualHumanResourceId
            };
            var defer = $q.defer();

            foundationService.getHrSingleXCategories(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getCategoryTextPathDtos(forceReload) {
            var filter = {
                StartFrom: categoryTextPathDtoParamStartFromEnum.IsPathStart
            };
            var defer = $q.defer();

            foundationService.getCategoryTextPathDtos(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function addMagicBucketRequest() {
            var bucketAction = Lazy(foundationService.dataContext.enumTypes.MagicBucketAction).findWhere({ name: 'SingleXCategories' });

            if (!vm.currentMagicBucketRequest) {
                vm.currentMagicBucketRequest =
                    foundationService.dataContext.magicBucketRequests.add({
                        MagicBucketActionId: bucketAction.value,
                        SingleId: virtualHumanResourceId,
                        VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId,
                        ModuleTargetId: moduleTargetId
                    });
            }
        }

        function setBatchContext(magicBucketRequest) {
            if (magicBucketRequest.IsBatch) {
                var actionType = Lazy(magicBucketActionEnum).findWhere({ value: magicBucketRequest.MagicBucketActionId.toString() });
                var actionTypeName = actionType ? actionType.name : '';

                contextService.addBatchRequest({
                    requestId: magicBucketRequest.BatchRequestId,
                    requestName: vm.translate('MagicBucketRequest' + actionTypeName + 'BatchName'),
                    requestStatus: 'inserted',
                    onCompletedAutoAction: null,    // is a function called on completed batch that must return a promise (with jobResult) managed in appPanel
                    detailAction: null             // is a function called from appPanel.cshml that links to batch request detail
                });
            }
        }

        // get reference of modifyAction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parentm = vm.modifyAction;
        vm.modifyAction = function () {
            parentm().then(
                //enable disable button new & delete because is custom behavior
                //generally saveResult eneble or disable only save button 
                function (saveResult) {
                    if (vm.viewContext.isEditingState) {
                        ribbon.setRibbonStatusButton(ribbon.Buttons.New,  true);
                        ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, true);
                    } else {
                        ribbon.setRibbonStatusButton(ribbon.Buttons.New,  false);
                        ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                    }

                    ribbon.refreshStatusButtons();
                }
            );
        };
    }

    vhrToCategoriesDetail.$inject = injectParams;
    vhrToCategoriesDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('vhrToCategoriesDetail', vhrToCategoriesDetail);
});