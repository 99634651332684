﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'vhreventService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var vhrEventManualRequestDetail = function ($q, $scope, $timeout, $injector, vhreventService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, vhreventService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, vhreventService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var vhrEventManualRequestId = navigationService.stateParams().vhrEventManualRequestId;
        var vhrEventManualWarningType = vhreventService.dataContext.enumTypes.VhrEventManualWarningType;
        var vhrEventManualWarningTypeObj = Nembus.Common.Library.parser.objFromList(vhrEventManualWarningType, 'name', 'value');
        var vhrEventManualRequestStatusEnumParsed = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventManualRequestStatus, 'VhrEventManualRequest', vm.translate);
        var vhrEventManualRequestStatus = vhrEventManualRequestStatusEnumParsed.list.translated;
        var vhrEventManualRequestStatusObj = vhrEventManualRequestStatusEnumParsed.obj.translated.value_name;

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('vhreventmanualwarning', null);
        nbsGridDatacontext.setDatasource([], false);
        nbsGridDatacontext.allowPaging = false;
        nbsGridDatacontext.allowSearching = false;
        nbsGridDatacontext.allowGrouping = false;
        vm.nbsGridDatacontext = nbsGridDatacontext;

        ribbon.setRibbonStatusButton(ribbon.Buttons.Modify,false);
        ribbon.refreshStatusButtons();

        if (vhrEventManualRequestId) {
            vm.setIsViewing();
        }

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getVhrEventManualRequest(forceReload, vhrEventManualRequestId));

            $q.all(funcsPromises)
                .then(function () {
                    var moduleTargets = Nembus.Rds.Library.Tools.ModuleTargetContext({ ModuleTargetId: vm.currentVhrEventManualRequest.ModuleTargetId }, contextService);
                    vm.moduleTarget = Lazy(moduleTargets).first();

                    loadDetail(forceReload);
                });
        }

        function loadDetail(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getVhrEventTypes(forceReload, vm.currentVhrEventManualRequest.VhrEventTypeId));
            funcsPromises.push(getVhrEventGroups(forceReload, vm.currentVhrEventManualRequest.VhrEventGroupId));
            funcsPromises.push(getVhrEventTemplates(forceReload, vm.currentVhrEventManualRequest.VhrEventTemplateId));
            funcsPromises.push(getVhrEventManualWarnings(forceReload, vhrEventManualRequestId, vhrEventManualWarningTypeObj.Conflict));

            $q.all(funcsPromises)
                .then(function () {
                    setPropertiesName();
                });
        }

        function setPropertiesName() {
            if (vm.moduleTarget) { vm.currentVhrEventManualRequest.ModuleTargetName = vm.moduleTarget.ModuleTargetName; }
            if (vm.vhrEventTemplate) { vm.currentVhrEventManualRequest.VhrEventTemplateName = vm.vhrEventTemplate.VhrEventTemplateName; }
            if (vm.vhrEventType) { vm.currentVhrEventManualRequest.VhrEventTypeName = vm.vhrEventType.VhrEventTypeName; }
            if (vm.vhrEventGroup) { vm.currentVhrEventManualRequest.VhrEventGroupName = vm.vhrEventGroup.VhrEventGroupName; }

            vm.currentVhrEventManualRequest.VhrEventManualRequestStatusName = vhrEventManualRequestStatusObj[vm.currentVhrEventManualRequest.VhrEventManualRequestStatusId];
            vm.currentVhrEventManualRequest.CreationDateForLayout = Nembus.Common.Library.converter.date.formatDateTime(vm.currentVhrEventManualRequest.CreationDate, contextService.virtualContext.cultureInfo, true);
        }

        function getVhrEventManualRequest(forceReload, vhrEventManualRequestId) {
            var filter = { VhrEventManualRequestId: vhrEventManualRequestId };
            var defer = $q.defer();

            vhreventService.getVhrEventManualRequest(filter, forceReload)
                .then(function (data) {
                    vm.currentVhrEventManualRequest = data.results[0];
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrEventManualWarnings(forceReload, vhrEventManualRequestId, vhrEventManualWarningType) {
            var filter = { VhrEventManualRequestId: vhrEventManualRequestId, VhrEventManualWarningType: vhrEventManualWarningType };
            var defer = $q.defer();

            vhreventService.getVhrEventManualWarnings(filter, forceReload)
                .then(function (data) {
                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: data.results
                    }

                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrEventTemplates(forceReload, vhrEventTemplateId) {
            var filter = { VhrEventTemplateId: vhrEventTemplateId };
            var defer = $q.defer();

            vhreventService.getVhrEventTemplates(filter, forceReload)
                .then(function (data) {
                    vm.vhrEventTemplate = data.results[0];
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrEventTypes(forceReload, vhrEventTypeId) {
            var filter = { VhrEventTypeId: vhrEventTypeId };
            var defer = $q.defer();

            vhreventService.getVhrEventTypes(filter, forceReload)
                .then(function (data) {
                    vm.vhrEventType = data.results[0];
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrEventGroups(forceReload, vhrEventGroupId) {
            var filter = { VhrEventGroupId: vhrEventGroupId };
            var defer = $q.defer();

            vhreventService.getVhrEventGroups(filter, forceReload)
                .then(function (data) {
                    vm.vhrEventGroup = data.results[0];
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    vhrEventManualRequestDetail.$inject = injectParams;
    vhrEventManualRequestDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('vhrEventManualRequestDetail', vhrEventManualRequestDetail);
});