﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'vhreventService', 'activityService', 'productionService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var workCenterEscapeDetail = function ($q, $scope, $timeout, $injector, vhreventService, activityService, productionService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, productionService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, productionService, { dirtyFlagIsSuspended: vm.isDirtySuspended });
        
        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var currentWorkCenterEscapeId = navigationService.stateParams().virtualWorkCenterEscapeId;
        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var activityKindEnum = Nembus.Common.Library.util.parseEnum(activityService.dataContext.enumTypes.ActivityKind, null, vm.translate).obj.normal.name_value;
        var activityKindId = activityKindEnum['SubBom'];

        vm.currentWorkCenterEscape = [];
        vm.moduleTargets = [];
        vm.vhrEventTypes = [];
        vm.vhrEventGroups = [];
        vm.currentActivities = [];

        var selectionParams = { destModalKey: 'activity', isMultiselect: false, appStoreBw: appStoreBw, activityKindId: activityKindId };
        vm.nbsSelectionGridWithModalActivity = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalActivity.setOnSelectFnCallback(function () {
            if (vm.nbsSelectionGridWithModalActivity.selectedIds) {
                vm.currentWorkCenterEscape.ActivityCode = vm.nbsSelectionGridWithModalActivity.selectedItems[0].item.ActivityCode;
            } else {
                vm.currentWorkCenterEscape.ActivityCode = null;
            }
        });

        vm.moduleTargets = Nembus.Rds.Library.Tools.ModuleTargetContext({ AppStoreBw: appStoreBw, ModuleTargetTypeCode: 'DEFAULT' }, contextService);
       
        if (currentWorkCenterEscapeId) {
            vm.setIsViewing();
            loadData(true);
        } else {
            vm.setIsAdding();
            vm.currentWorkCenterEscape = productionService.dataContext.virtualWorkCenterEscapes.add({
                VirtualWorkCenterId: null,
                VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId
            });

            getVirtualWorkCenters(true, appStoreBw);            
            getVhrEventGroups(true, appStoreBw);
            getVhrEventTypes(true, appStoreBw);
        }

        // get reference of submitaction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parents = vm.submitAction;
        vm.submitAction = function () {
            parents()
                .then(function (saveResult) {
                    // submit withouth errors
                })
                .catch(function (saveResult) {
                    // client error added in production-service.js
                    var error = Lazy(saveResult.entityErrors)
                        .filter((error) => { return error.errorName.indexOf('isByClassification') === 0; })
                        .first();

                    if (error) {
                        error.isMessageVisible = true;
                        vm.alertConfig.isModal = false;
                        vm.alertConfig.isReadonly = true;
                        vm.removeAlertFromList(true);
                        vm.setErrorMsg({ isMessageVisible: true, message: vm.translate(error.errorMessage) });
                    }
                });
        };

        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getVirtualWorkCenters(forceReload, appStoreBw));
            funcsPromises.push(getVhrEventGroups(forceReload, appStoreBw));
            funcsPromises.push(getVhrEventTypes(forceReload, appStoreBw));
            funcsPromises.push(getActivities(forceReload, appStoreBw, activityKindId));

            $q.all(funcsPromises)
                .then(function () {
                    getVirtualWorkCenterEscape(forceReload, currentWorkCenterEscapeId);
                });
        }

        function getVirtualWorkCenters(forceReload, appStoreBw) {
            var filter = {
                AppStoreBw: appStoreBw,
                IsWorkCenterEscape: true
            };
            var defer = $q.defer();

            productionService.getVirtualWorkCenters(filter, forceReload)
                .then(function (data) {
                    vm.currentWorkCenters = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrEventTypes(forceReload, appStoreBw) {
            var filter = { AppStoreBw: appStoreBw, IsBomType: false };
            var defer = $q.defer();

            vhreventService.getVhrEventTypes(filter, forceReload)
                .then(function (data) {
                    vm.vhrEventTypes = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrEventGroups(forceReload, appStoreBw) {
            var filter = { AppStoreBw: appStoreBw, IsBomGroup: false };
            var defer = $q.defer();

            vhreventService.getVhrEventGroups(filter, forceReload)
                .then(function (data) {
                    vm.vhrEventGroups = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getActivities(forceReload, appStoreBw, activityKindId) {
            var filter = { AppStoreBw: appStoreBw, ActivityKindId: activityKindId };
            var defer = $q.defer();

            activityService.getActivities(filter, forceReload)
                .then(function (data) {
                    vm.currentActivities = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVirtualWorkCenterEscape(forceReload, virtualWorkCenterEscapeId) {
            var filter = { VirtualWorkCenterEscapeId: virtualWorkCenterEscapeId };
            var defer = $q.defer();

            productionService.getVirtualWorkCenterEscape(filter, forceReload)
                .then(function (data) {
                    vm.currentWorkCenterEscape = data.results[0];

                    var activity = Lazy(vm.currentActivities).findWhere({ ActivityCode: vm.currentWorkCenterEscape.ActivityCode });
                    if (activity) {
                        vm.nbsSelectionGridWithModalActivity.setSelectedItems([activity]);
                    }
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    workCenterEscapeDetail.$inject = injectParams;
    workCenterEscapeDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('workCenterEscapeDetail', workCenterEscapeDetail);
});