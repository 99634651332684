﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'hrbucketService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function bucketDetail($q, $scope, $timeout, $injector, foundationService, hrbucketService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, hrbucketService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, hrbucketService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var currentVirtualHumanResourceId = navigationService.stateParams().virtualHumanResourceId;
        var resourceType = navigationService.stateParams().resourceType;
        var bucketClassification = navigationService.stateParams().bucketClassification;
        var resourceTypeEnum = Nembus.Common.Library.util.parseEnum(foundationService.dataContext.enumTypes.ResourceType, null, vm.translate).obj.normal.name_value;
        var resourceTypeId = resourceTypeEnum[resourceType];
        var bucketClassificationEnum = Nembus.Common.Library.util.parseEnum(hrbucketService.dataContext.enumTypes.BucketClassification, null, vm.translate).obj.normal.name_value;
        var bucketClassificationId = bucketClassificationEnum[bucketClassification];
        var vhrStatusEnum = Nembus.Common.Library.util.parseEnum(foundationService.dataContext.enumTypes.VhrStatus, null, vm.translate).obj.normal.name_value;
        var resourcePrototypeEnum = Nembus.Common.Library.util.parseEnum(hrbucketService.dataContext.enumTypes.ResourcePrototype, null, vm.translate).obj.normal.name_value;

        vm.companies = [];

        if (currentVirtualHumanResourceId) {
            vm.setIsViewing();

            loadData(true);
        } else {
            vm.setIsAdding();

            getCompanies(true);

            vm.currentHrBucketGenerality = hrbucketService.dataContext.hrBucketGeneralities.add({});
            vm.currentHrBucket = hrbucketService.dataContext.hrBuckets.add({
                CompanyId: null,
                AppStoreBw: appStoreBw,
                ResourceTypeId: resourceTypeId,
                BucketClassificationId: bucketClassificationId,
                ResourcePrototypeId: resourcePrototypeEnum.Container,
                VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId,
                HrBucketGeneralityId: vm.currentHrBucketGenerality.HrBucketGeneralityId,
                VhrStatusId: vhrStatusEnum.Enabled,
                VhrGroupId: null,
                VhrTypeId: null
            });

            getSystemVhrGroups(true);
            getSystemVhrTypes(true);
        }

        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getCompanies(forceReload));

            $q.all(funcsPromises)
                .then(function () {
                    getHrBucket(forceReload, currentVirtualHumanResourceId);
                });
        }

        function getSystemVhrGroups(forceReload) {
            var filter = {};
            var defer = $q.defer();

            foundationService.getSystemVhrGroups(filter, forceReload)
                .then(function (data) {
                    vm.vhrGroup = data.results[0];

                    vm.currentHrBucket.VhrGroupId = vm.vhrGroup.VhrGroupId;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getSystemVhrTypes(forceReload) {
            var filter = {};
            var defer = $q.defer();

            foundationService.getSystemVhrTypes(filter, forceReload)
                .then(function (data) {
                    vm.vhrType = data.results[0];

                    vm.currentHrBucket.VhrTypeId = vm.vhrType.VhrTypeId;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getHrBucket(forceReload, currentVirtualHumanResourceId) {
            var filter = { VirtualHumanResourceId: currentVirtualHumanResourceId };
            var defer = $q.defer();

            hrbucketService.getHrBucket(filter, forceReload)
                .then(function (data) {
                    vm.currentHrBucket = data.results[0];
                    vm.currentHrBucketGenerality = vm.currentHrBucket.HrBucketGenerality;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getCompanies(forceReload) {
            var defer = $q.defer();

            foundationService.getCompanies({}, forceReload)
                .then(function (data) {
                    vm.companies = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        // get reference of submitaction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parents = vm.submitAction;
        var ftcScope = $scope.$parent.vm;
        vm.submitAction = function () {
            vm.currentHrBucket.VirtualCode = vm.currentHrBucketGenerality.HrBucketCode;
            vm.currentHrBucket.VirtualName = vm.currentHrBucketGenerality.HrBucketName;

            parents().then(
                function (saveResult) {
                    if (saveResult.entities.length > 0 && vm.viewContext.isAddingState && ftcScope.ftcOnAddEntity) {
                        ftcScope.ftcOnAddEntity(vm.currentHrBucket);
                    }
                }
            );
        };
    }

    bucketDetail.$inject = injectParams;
    bucketDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('bucketDetail', bucketDetail);
});