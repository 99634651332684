﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'navigationService', 'dataflowService', 'checkStateChangeService', 'contextService'];
    function fileShuffleRequestsList($q, $scope, $timeout, $injector, navigationService, dataflowService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, dataflowService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return currentShuffleRequestSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, dataflowService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        ribbon.setRibbonStatusButton(ribbon.Buttons.New, false);
        ribbon.refreshStatusButtons();

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getFileShuffleDefinitions(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.currentFileShuffleDefinitionsObj = Nembus.Common.Library.parser.objFromList(results[0], 'FileShuffleDefinitionId', 'FileShuffleDefinitionName');

                    var supportData = { fileShuffleDefinitionsObj: vm.currentFileShuffleDefinitionsObj, translate: vm.translate };
                    var ejDataManager = dataflowService.getFileShuffleRequestDataManager(supportData, {}, ejDataManager);
                    var nbsGridDatacontext = new NbsGrid(vm.translate);
                    nbsGridDatacontext.configureGrid('fileshufflerequest', null);
                    nbsGridDatacontext.viewCommonBar();
                    nbsGridDatacontext.setGridIsRenderedInlineBlock();
                    nbsGridDatacontext.setDatasource(ejDataManager, true);
                    vm.nbsGridDatacontext = nbsGridDatacontext;
                });
        }

        vm.rowDblClick = function (args) {
            openJournalModal(args.data.FileShuffleRequestId);
        };

        var currentShuffleRequestSelected = null;
        vm.gridSelection = function (args) {
            currentShuffleRequestSelected = args.data;
        };

        vm.refreshAction = function () {
            $timeout(() => {
                vm.forceGridRefresh = new Date().getTime();
            });
        };

        vm.queryCellInfo = function (args) {
            switch (args.column.field) {
                case 'IsBatch':
                case 'IsScheduledTask':
                    args.cell.innerHTML = Nembus.Common.Library.converter.grid.formatBoolean(args.data[args.column.field], vm.translate);
                    break;
            }
        };

        function openJournalModal(fileShuffleRequestId) {
            var ejDataManagerJournal = dataflowService.getFileShuffleJournalDataManager({ FileShuffleRequestId: fileShuffleRequestId }, ejDataManagerJournal);
            var nbsGridDatacontextJournal = new NbsGrid(vm.translate);
            nbsGridDatacontextJournal.configureGrid('fileshufflejournal', null);
            nbsGridDatacontextJournal.setModalMode();
            nbsGridDatacontextJournal.setDatasource(ejDataManagerJournal, true);
            vm.nbsGridFileShuffleJournalDatacontext = nbsGridDatacontextJournal;

            vm.modalInstance = navigationService.modal().open(vm.modalOptions({ templateUrl: 'fileShuffleJournalTemplate', scope: $scope }));
            vm.modalInstance.title = vm.translate('FileShuffleJournal.FileShuffleJournal');
            vm.modalInstance.ok = function () { vm.modalInstance.close(); };
            vm.modalInstance.cancel = function () { vm.modalInstance.dismiss(); };
            vm.modalInstance.result.then(function () {
                // onClose
            }, function () {
                // onDismiss
            });
        }

        function getFileShuffleDefinitions(forceReload) {
            var filter = { };
            var defer = $q.defer();

            dataflowService.getFileShuffleDefinitions(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
	}

    fileShuffleRequestsList.$inject = injectParams;
    fileShuffleRequestsList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('fileShuffleRequestsList', fileShuffleRequestsList);
});