// @ts-ignore
var Lazy = require('lazy.js');
import { Directive, ElementRef, Injector } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { NbsVm2 } from '../../common/controllers/nbs-vm2';
import { NbsCommonCms, NbsCommonUtil, NbsGrid } from '../../common/nbs-common.library';

class AppPanelController extends NbsVm2 {
    currentNavigationTree: any = {};
    virtualCompanySelected: any = null;
    notificationStatusEnumParsed: any = null;
    notificationStatusEnumObj: any = null;
    notificationChannelKindEnumParsed: any = null;
    notificationChannelKindEnumObj: any = null;
    moduleNodes: any = null;
    breadCrumbNodes: any = [];
    currentSessionService: any = null;
    nbsGridDatacontext: any = null;
    modalInstance: any = null;

    constructor($q, private $scope, $timeout, $injector, private authenticationService, private cmsService, private cmstoolService, contextService, navigationService, private fileTransferService, currentSessionService, private publicService, private notificationService) {
        super(currentSessionService, $injector)

        this.currentSessionService = currentSessionService;
        this.currentNavigationTree = this.cmsService.currentNavNodes.navigationPanel;
        this.notificationStatusEnumParsed = NbsCommonUtil.parseEnum(this.notificationService.dataContext.enumTypes.NotificationStatus, null, null);
        this.notificationStatusEnumObj = this.notificationStatusEnumParsed.obj.normal.name_value;
        this.notificationChannelKindEnumParsed = NbsCommonUtil.parseEnum(this.notificationService.dataContext.enumTypes.NotificationChannelKind, null, null);
        this.notificationChannelKindEnumObj = this.notificationChannelKindEnumParsed.obj.normal.name_value;

        this.cmsService.refreshBreadCrumb = function () {
            this.breadCrumbNodes = cmsService.currentNavNodes.breadCrumb;
        };

        this.contextService.controllerBatchPollingFunction = this.batchPolling.bind(this);

        this.loadData(true);
    }

    virtualContext = this.contextService.virtualContext;
    maintenanceStatus = this.publicService.maintenanceStatus;
    downloadFiles = this.contextService.downloadFiles;
    batchRequests = this.contextService.batchRequests;
    myNotificationCounter = this.notificationService.myNotificationCounter;

    auditModuleIsPresent = Lazy(this.virtualContext.ModuleTargets).where({ ModuleTargetCode: 'MTAUD' }).some();
    hinfosModuleIsPresent = Lazy(this.virtualContext.ModuleTargets).where({ ModuleTargetCode: 'MTITS' }).some();
    maintenanceModuleIsPresent = Lazy(this.virtualContext.ModuleTargets).where({ ModuleTargetCode: 'MTFAS' }).some();
    //IsLicenseValid = this.virtualContext.IsLicenseValid;

    currentYear = new Date().getFullYear();
    currentTheme: string = '';

    private loadData(forceReload) {
        var me = this;

        var funcsPromises = [];
        funcsPromises.push(this.getMyNotifications(forceReload));
        funcsPromises.push(this.getNavigationModulesFirstLevel());

        this.$q.all(funcsPromises)
            .then((results) => {
                var notifications = results[0];
                if (Lazy(notifications).some()) {
                    this.$timeout(() => {
                        this.myNotificationCounter = Lazy(notifications).length();
                    })
                }

                var modules = results[1];
                Lazy(modules)
                    .each((module) => {
                        module.dataSource = me.currentNavigationTree[module.CmsViewMode];
                        module.isVisible = me.currentNavigationTree[module.CmsViewMode] !== null || me[module.CmsViewMode] !== undefined;
                    });

                me.moduleNodes = modules;
            });
    }

    logout() {
        this.contextService.clearContext();
        this.publicService.getSsoConfig()
            .then(ssoConfig => {
                this.authenticationService.logout()
                    .then((authBehavior) => {
                        if (authBehavior.authMode === 'owc') {
                            this.authenticationService.redirectToRootLocation();
                        }
                        else if (authBehavior.authMode === 'idp') {
                            /*
                            var logouturi = ssoConfig.LogoutUri.replace('[clientId]', ssoConfig.ClientId).replace('[returnTo]', ssoConfig.LogoutPostBackUri);
                            while (logouturi.indexOf("&amp;") >= 0) {
                                logouturi = logouturi.replace('&amp;', '&');
                            }
                            location.href = logouturi;
                            */
                            this.authenticationService.ssoLogout(ssoConfig);
                        }
                        else {
                            this.authenticationService.redirectToRootLocation();
                        }
                    });
            });
    };

    navigateToJobsManage() {
        this.navigationService.navigateTo("app.nav.jobsmanage");
    };

    navigateToDevelopTools(moduleCode) {
        if (!moduleCode) {
            moduleCode = '';
        }

        this.navigationService.navigateTo('app.nav.' + moduleCode + 'developertoolsdetail');
    };

    closeFileDownload() {
        this.contextService.clearDownloadFiles();
    };

    navToBatchRequestsQueue() {
        this.navigationService.navigateTo("app.nav.batchrequestsqueuelist", {});
    };

    manageFileDownload(file, action) {
        //action: inline,asAttach
        //TODO: MANAGE IT
        if (action === 'inline') {
            file.downloadData.asAttach = false;
        }
        else {
            file.downloadData.asAttach = true;
        }
        this.fileTransferService.downloadRemoteFile(file.downloadData);
    };

    closeBatchRequest() {
        this.contextService.clearBatchRequests();
    };

    batchPolling(t) {
        if (!t) {
            t = 5000;
        }
        this.$timeout(this.newFn.bind(this), t);

    };

    newFn() {
        var batchRequestsIdToCheck = this.contextService.getBatchRequestsIdToCheck(this.contextService.batchRequests.requests);
        if (batchRequestsIdToCheck.length > 0) {

            this.contextService.checkBatchRequests(batchRequestsIdToCheck)
                .then((data) => {
                    this.contextService.updateBatchRequestStatus(data.results);
                    if (this.contextService.pollingIsActive()) {
                        this.batchPolling(5000);
                    }
                });
        }
    }

    batchRequestOnCompletedAutoAction(request) {
        if (request.onCompletedAutoAction) {
            // when request.requestStatus == 'completed'
            // auto called in appPanel.cshtml from ng-init of span element
            request.onCompletedAutoAction(request)
                .then((jobResult) => {
                    //jobResult = {
                    //    jobStatus: null,                // done, error, warning, conflict, ....
                    //    jobMessage: null,               // Just translated: message to add on appLayout toolbar (can be null)
                    //    jobDetailActionLabel: null      // Just translated: if set, then add dropdown with item translated (E.g. Dettaglio)
                    //};

                    request.jobResult = jobResult;
                    request.hasDetailAction = typeof request.detailAction === 'function';
                    switch (request.jobResult.jobStatus) {
                        case 'done': request.classButton = 'btn-success'; break;
                        case 'warning': request.classButton = 'btn-warning'; break;
                        case 'conflict': request.classButton = 'btn-warning'; break;
                        case 'error': request.classButton = 'btn-danger'; break;
                        default: request.classButton = 'btn-default';
                    }
                });
        }
    };

    batchRequestDetailAction(request) {
        if (request.detailAction) {
            // eventually navigation to batchrequest detail
            request.detailAction(request);
        }
    };

    openSupport() {
        window.open(this.virtualContext.UriSupport.replace('&amp;', '&'), '_blank');

        //publicService.getSsoConfig()
        //.then(ssoConfig => {
        //if (ssoConfig.SsoEnabled) {
        //    authenticationService.redirectToSsoSupportRequest(ssoConfig);
        //}
        //else {
        //window.open(this.virtualContext.UriSupport.replace('&amp;','&'), '_blank');
        //}
        // });
    };

    openHelp() {
        window.open(this.virtualContext.UriMan, '_blank');
    };

    openAdminHelp() {
        window.open(this.virtualContext.UriAdminMan, '_blank');
    };

    setTheme(theme) {
        this.currentTheme = theme;
    };

    myAccount() {
        this.navigationService.navToMyAccount();
    };

    myNotification() {
        this.navigationService.navToMyNotification();
    };

    myTickets() {
        this.navigationService.navToMyTickets();
    };

    navigationNodeOnClick(args) {
        var currentNavNode = Lazy(this.cmsService.currentNavNodes.allNodes).findWhere({ NavigationNodeId: args.id });
        if (currentNavNode && currentNavNode.NavigationNodeKindId === 2) {
            this.navigationService.navigateTo(currentNavNode.NavigationState, this.castParam(currentNavNode.NavigationParam));
            //if (NbsCommonLibrary.ui.isUnderBreakpoint_md()) {
            //    NbsCommonLibrary.ui.sidebarToggle();
            //}
        }

        this.cmsService.setBreadcrumbTree(currentNavNode.NavigationNodeCode);
        this.cmsService.refreshBreadCrumb();
    };

    goMobileAppConfig() {
        this.navigationService.navigateTo("app.nav.mobileappconfig", {});
    }

    goMyNotifications() {
        this.navigationService.navigateTo("app.nav.mynotificationslist", {});
    };

    onBcNavigation(currentNavNode) {
        if (currentNavNode.NavigationNodeKindId === 2) {
            this.navigationService.navigateTo(currentNavNode.NavigationState, this.castParam(currentNavNode.NavigationParam));
        }
    };

    moduleNavigation(cmsViewMode, navigationParam) {
        this.cmsService.setBreadcrumbTree(cmsViewMode);
        this.breadCrumbNodes = this.cmsService.currentNavNodes.breadCrumb;
        this.navigationService.navigateTo('app.nav.modulehome-' + cmsViewMode, this.castParam(navigationParam));
    };

    gridSelection(args) {
        this.virtualCompanySelected = args.data;
    };

    selectVirtualContext() {
        this.currentSessionService.getAvailableVirtualCompanies()
            .then((data) => {
                this.openModalSelectionVirtualContext(data.results);
            })
            .catch((error) => {
                this.setErrorMsg(error);
            });
    };

    changeVirtualContext(targetVirtualCompany) {
        if (!targetVirtualCompany) {
            return;
        }
        this.authenticationService
            .changeVirtualContext(targetVirtualCompany.VirtualCompanyId)
            .then((response) => {
                this.authenticationService.redirectToRootLocation();
            })
            .catch((err, status, headers, config) => {
                err.isMessageVisible = true;
                err.message = err.error;
                this.setErrorMsg(err);
            });
    };

    bookmarkThisPage() {
        var path = this.navigationService.currentPath(); //e.g. /app/dashboard/dashboardHome
        var pageTitle = NbsCommonCms.viewTitle(this.navigationService.currentCmsViewCode()); //e.g. Dashboard Home
        this.addBookmarkModal(path, pageTitle);
    };

    navToHome() {
        this.navigationService.navToHome();
    };

    modal: any;
    private openModalSelectionVirtualContext(virtualCompanies) {
        this.modal = {
            instanceName: 'modalInstance',
            title: this.translate('VirtualContextSelectionTitle'),
            datasource: virtualCompanies
        };

        var nbsGridDatacontext = new NbsGrid(this.translate.bind(this));
        nbsGridDatacontext.configureGrid('virtualcompany', 'changeVc');
        nbsGridDatacontext.setSelectedIds([this.virtualContext.CurrentVirtualCompanyId]);
        nbsGridDatacontext.setModalMode();
        nbsGridDatacontext.allowSearching = true;
        nbsGridDatacontext.setPageSize(15);
        nbsGridDatacontext.setDatasource(virtualCompanies, false);
        this.nbsGridDatacontext = nbsGridDatacontext;

        this.modalInstance = this.navigationService.modal().open(this.modalOptions({ templateUrl: 'virtualCompaniesTemplate', scope: this.$scope }));
        this.modalInstance.result.then(() => {
            this.changeVirtualContext(this.virtualCompanySelected);
        }, function () { });
        this.modalInstance.ok = () => {
            if (!this.virtualCompanySelected) {
                this.navigationService.selectOneItemMessageModal();
                return;
            }

            if (this.virtualCompanySelected.VirtualCompanyId !== this.virtualContext.CurrentVirtualCompanyId) {
                // modal is closed only if virtualcompany selected is different from current
                this.modalInstance.close();
            }
        };
        this.modalInstance.cancel = () => {
            this.modalInstance.dismiss();
        };
    }

    castParam(strParam) {
        if (strParam) {
            return eval("(" + strParam + ")");
        }
    }

    getMyNotifications(forceReload) {
        var filter = {
            NotificationStatus: this.notificationStatusEnumObj.NotificationNew,
            NotificationChannelKind: this.notificationChannelKindEnumObj.ChannelWebApp
        };
        var defer = this.$q.defer();

        this.notificationService.getMyNotifications(filter, forceReload)
            .then((data) => {
                defer.resolve(data.results);
            })
            .catch((error) => {
                this.setErrorMsg(error);
            });

        return defer.promise;
    }

    getNavigationModulesFirstLevel() {
        var defer = this.$q.defer();

        this.cmsService.getNavigationModulesFirstLevel()
            .then((data) => {
                defer.resolve(data.results);
            })
            .catch((error) => {
                this.setErrorMsg(error);
            });

        return defer.promise;
    }

    addBookmarkModal(bookmarkPath, bookmarkName) {
        this.navigationService
            .navToModal(this.suspendDirty.bind(this), 'app.nav.bookmarkdetailmodal', { BookmarkName: bookmarkName })
            .then((modalResult) => {
                if (modalResult.dialogResult === this.navigationService.navigationActionTypes.modalOk) {
                    //bookmark
                    //have to add server side VirtualHumanResourceId => UserPersonId
                    //set 1 for validation requirements
                    // set server side UserPersonId

                    //var bookmarkDisplayName = modalResult.data.CmsBookmarkName;

                    var cmsBookmark = this.cmstoolService.dataContext.cmsBookmarks.add({
                        BookmarkName: bookmarkName,
                        BookmarkDisplayName: modalResult.data,
                        BookmarkPath: bookmarkPath,
                        VirtualHumanResourceId: 1, // setted server side
                        VirtualCompanyId: this.virtualContext.CurrentVirtualCompanyId
                    });

                    this.cmstoolService.saveChanges()
                        .then((saveResult) => {
                            this.navigationService.customMessageModal('SubmittedSuccesfully');
                        })
                        .catch((error) => {
                            cmsBookmark.entityAspect.setUnchanged();
                            this.navigationService.customMessageModal('SubmittedError');
                        });
                }
            });
    }
}

let AppPanelComponent = {
    selector: "appPanel",
    templateUrl: 'shell/navigation/appPanel/_',
    controller: AppPanelController,
    controllerAs: 'vm'
};

// @ts-ignore
angular.module('nbs.shell.module').component(AppPanelComponent.selector, AppPanelComponent);

//angular.module('nbs.shell.module').component('appPanel', { templateUrl: 'shell/navigation/appPanel/_', controller: function () { } });

@Directive({
    selector: 'app-panel'
})
export class AppPanelDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef, injector: Injector) {
        super('appPanel', elementRef, injector);
    }
}

