﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {
    var injectParams = ['$q', '$injector', '$scope', '$timeout', 'vhreventService', 'audService', 'utilService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function surveyBoardDueDate($q, $injector, $scope, $timeout, vhreventService, audService, utilService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, vhreventService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return currentSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, vhreventService);

        var ribbon = new NbsAudRibbon(navigationService, contextService.virtualContext, 'board');
        ribbon.addHomeTab();
        ribbon.addTab(ribbon.GetAuditViewsTab());
        ribbon.setUnderBreakpointSmall();
        ribbon.tabIndexSelected = 2;
        vm.ribbonDatacontext = ribbon;

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var vhrEventTypeCode = navigationService.stateParams().vhrEventTypeCode;
        var vhrEventFixedTypeCode = navigationService.stateParams().vhrEventFixedTypeCode;
        var vhrEventKindEnum = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventKind, null, vm.translate).obj.normal.name_value;
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var surveyCreationPatternObj = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyCreationPattern, null, vm.translate).obj.normal.name_value;
        var vhrEventLifeStatusKey = navigationService.stateParams().vhrEventLifeStatusKey;
        var viewKind = navigationService.stateParams().viewKind;
        var surveyLifeStatusEnumParsed = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyLifeStatus, 'SurveyDto', vm.translate);
        var surveyLifeStatusEnum = surveyLifeStatusEnumParsed.obj.normal.name_value;
        var vhrEventShuffleActionObj = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventShuffleAction, null, vm.translate).obj.normal.name_value;

        //#region set ribbon fn navigations
        var navParams = { 'appStoreCode': appStoreCode, 'vhrEventLifeStatusKey': vhrEventLifeStatusKey, 'viewKind': viewKind, 'vhrEventFixedTypeCode': vhrEventFixedTypeCode };
        vm.onRibbonGrid = () => { ribbon.onRibbonGrid_Survey(navParams); };
        vm.onRibbonCalendar = () => { ribbon.onRibbonCalendar_Survey(navParams); };
        vm.onRibbonStream = () => { ribbon.onRibbonStream_Survey(navParams); };
        vm.onRibbonBoard = () => { ribbon.onRibbonBoard_Survey(navParams); };
        vm.onRibbonTimeline = () => { ribbon.onRibbonTimeline_Survey(navParams); };
        vm.onRibbonTimelineTarget = () => { ribbon.onRibbonTimelineTarget_Survey(navParams); };
        vm.onRibbonTimelineAssignment = () => { ribbon.onRibbonTimelineTargetAssignment_Survey(navParams); };
        //#endregion set ribbon fn navigations

        //#region set ribbon enabled buttons
        ribbon.setRibbonStatusButton(ribbon.Buttons.ViewStream, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.ViewList, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.ViewCalendar, vhrEventLifeStatusKey !== 'ondemand');
        ribbon.setRibbonStatusButton(ribbon.Buttons.Timeline, vhrEventLifeStatusKey !== 'ondemand');
        ribbon.setRibbonStatusButton(ribbon.Buttons.TimelineTarget, vhrEventLifeStatusKey !== 'ondemand');
        ribbon.setRibbonStatusButton(ribbon.Buttons.TimelineAssignment, vhrEventLifeStatusKey !== 'ondemand');
        ribbon.refreshStatusButtons();
        //#endregion set ribbon enabled buttons

        var nCols = 4; // parametrized
        var boardParams = {
            dueDateFilters: [
                { filter: 'vhrEventFilter', field: 'StartDate1', isDueDate: true },
                { filter: 'vhrEventFilter', field: 'StartDate2', isDueDate: true }
            ],
            columnFilters: [],
            nbsGuidManager: NbsGuidManager,
            localAsUtcFn: Nembus.Common.Library.converter.date.localAsUtc
        };

        var nbsGridDatacontext = new NbsAudGrid(vm.translate);
        nbsGridDatacontext.configureGrid('survey', 'list', null);
        nbsGridDatacontext.setBoardMode();
        nbsGridDatacontext.allowSearching = false;
        nbsGridDatacontext.setDatasource([], false); // fake datasource, only to create grid

        vm.board = new NbsBoard(nCols, boardParams);
        vm.board.setRefreshScopeFn(function () { $timeout(() => { }); });
        vm.board.setGridDatacontext(nbsGridDatacontext);
        vm.board.onBoardReady = function () {
            setBarDatacontext();
        };
        vm.board.onDropAction = function (items, indexColSrc, indexColDest) {
            dropAction(items, indexColSrc, indexColDest);
        };

        // hook from gridbar
        var ejDataManager = null;
        var currentBoardBarFilter = null;
        vm.surveyBarOnChanges = function (result) {
            // save in view current filter passed by survey bar
            currentBoardBarFilter = parseFromBarFilter(result);

            if (ejDataManager === null) {
                utilService.getGenericElements({ GenericElementCode: 'Configurations.Surveys.LifeStatusColors' }, false)
                    .then((data) => {
                        var ge = data.results[0].PairValuesArray;
                        var statusColorObj = Nembus.Common.Library.parser.objFromList(ge, 'key', 'value');

                        var supportData = { isMySurveys: false, translate: vm.translate, contextService: contextService, statusColorObj: statusColorObj };
                        ejDataManager = audService.getSurveyDataManager(supportData, currentBoardBarFilter, ejDataManager);
                        nbsGridDatacontext.setDatasource(ejDataManager, true);

                        vm.board.setBoardFilter(currentBoardBarFilter);
                    });
            } else {
                vm.board.setBoardFilter(currentBoardBarFilter);
            }
        };

        vm.refreshAction = function () {
            vm.board.refreshGridDatasources();
        };

        vm.onChangeDate = function (date, col) {
            vm.board.setColsFilterValue(col, ['StartDate1', 'StartDate2'], date);
        };

        function setBarDatacontext() {
            vm.surveyBarDataContext = {
                vhrEventLifeStatusKey: vhrEventLifeStatusKey,
                commonBarDatasource: { modeUI: 'board' },
                appStoreBw: appStoreBw,
                moduleTargetId: moduleTargetId,
                defaultKeys: {
                    columnsKey: vhrEventLifeStatusKey, sortingKey: null
                }
            };
        }

        vm.gridUiOnChanges = function (result) {
            vm.refreshGridUiComponent = {
                refreshKey: new Date().getTime(),
                uiData: result
            }
        }

        function dropAction(surveys, indexColSrc, indexColDest) {
            var canChangeDate = true;
            Lazy(surveys)
                .each((survey) => {
                    if (survey.SurveyCreationPatternId === surveyCreationPatternObj.OnDemand
                        || survey.SurveyCreationPatternId === surveyCreationPatternObj.InstanceOnDemand) {
                        canChangeDate = false;
                    }
                    if (survey.SurveyLifeStatusId !== surveyLifeStatusEnum.Created) {
                        canChangeDate = false;
                    }
                });

            if (!canChangeDate) {
                navigationService.operationNotPermittedModal();
                return;
            }

            var vhrEventsId = Lazy(surveys).pluck('VhrEventId').toArray();
            var srcCol = vm.board.columns[indexColSrc];
            var destCol = vm.board.columns[indexColDest];
            var newEventDate = vm.board.getColFilter(destCol).StartDate1;

            var filter = { VhrEventsId: vhrEventsId, ModuleTargetId: moduleTargetId, StartDate2: newEventDate };
            vhreventService.canChangeTargetVhrEventsStartDate(filter)
                .then(function (data) {
                    var result = data.results[0];
                    if (!result.ChangeIsEnabled) {
                        navigationService.customMessageModal(vm.translate(result.ThesaurusWordKey));
                        return;
                    }
                    Lazy(surveys)
                        .each((event) => {
                            var vhrEventShuffleDto = vhreventService.dataContext.vhrEventShuffleDto.add();
                            vhrEventShuffleDto.VhrEventShuffleAction = vhrEventShuffleActionObj.ChangeStartDate;
                            vhrEventShuffleDto.StartDate = newEventDate;
                            vhrEventShuffleDto.StartTime = Nembus.Common.Library.converter.timespan.dateToJson(event.SurveyExpireDate);
                            vhrEventShuffleDto.KeepSameTime = true;
                            vhrEventShuffleDto.KeepSameWorkCenter = true;
                            vhrEventShuffleDto.VhrEventLifeStatus = 0;
                            vhrEventShuffleDto.VhrEventBillingStatus = 0;
                            vhrEventShuffleDto.VhrEventsIdSerialized = JSON.stringify([event.VhrEventId]);
                            vhrEventShuffleDto.ModuleTargetId = moduleTargetId;
                        });

                    vm.submitAction()
                        .then(function (result) {
                            vm.board.refreshGridDatasource(srcCol);
                            vm.board.refreshGridDatasource(destCol);
                        })
                        .catch(function (error) {
                            vm.removeAlertFromList(true);
                            vm.setErrorMsg(error);
                        });
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });
        }

        function parseFromBarFilter(surveyBarFilter) {
            // surveyFilter
            surveyBarFilter.surveyFilter.IsArchived = vhrEventLifeStatusKey === 'archived' ? true : false;
            switch (vhrEventLifeStatusKey) {
                case 'tocompile':
                    surveyBarFilter.surveyFilter.IsCompleted = false;
                    break;
                case 'compiled':
                    surveyBarFilter.surveyFilter.IsCompleted = true;
                    break;
                default:
            }
            // surveyVhrEventFilter
            if (vhrEventLifeStatusKey === 'ondemand') {
                surveyBarFilter.surveyVhrEventFilter.SurveyCreationPatternId = surveyCreationPatternObj.OnDemand;
            } else {
                surveyBarFilter.surveyVhrEventFilter.ExcludeOnDemand = true;
            }
            // vhrEventFilter
            surveyBarFilter.vhrEventFilter.AppStoreBw = appStoreBw;
            surveyBarFilter.vhrEventFilter.VhrEventTypeCode = vhrEventTypeCode;
            surveyBarFilter.vhrEventFilter.VhrEventFixedTypeCode = vhrEventFixedTypeCode;
            surveyBarFilter.vhrEventFilter.VhrEventKind = vhrEventKindEnum.Data;
            surveyBarFilter.vhrEventFilter.ModuleTargetId = moduleTargetId;

            return surveyBarFilter;
        }
    }

    surveyBoardDueDate.$inject = injectParams;
    surveyBoardDueDate.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('surveyBoardDueDate', surveyBoardDueDate);
});