﻿'use strict';

define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'vhreventService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function vhrEventsManualStepUpdateSwitch($q, $scope, $timeout, $injector, foundationService, vhreventService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, foundationService);

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);

        var isBom = false;
        var vhrEventKindEnum = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventKind, null, vm.translate).obj.normal.name_value;

        var pParams = navigationService.stateParamsModal().parentParams;
        pParams = pParams ? pParams : navigationService.stateParams();

        // navigation buttons visibility
        vm.setWizardButtonVisibility(pParams);

        // beardcrumb information
        var beardCrumbInfo = JSON.parse(pParams.beardCrumbInfo);
        Lazy(beardCrumbInfo.items).each((item) => { item.title = vm.translate(item.title); })
        vm.beardCrumbInfo = beardCrumbInfo;

        vm.switchClientData = {};
        // refill data form
        if (vhreventService.vhrEventManualCollector) {
            vm.switchClientData.VhrEventTransactionCode = vhreventService.vhrEventManualCollector.VhrEventTransactionCode;
            vm.switchClientData.IsVhrEventsSelected = !vhreventService.vhrEventManualCollector.IsRecurrent;
            vm.switchClientData.IsAllSerie = vhreventService.vhrEventManualCollector.IsRecurrent;
        }

        vm.modalNext = function () {
            // close modal and returns client data object
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalNext);
            navigationService.closeCurrentModal(modalResult);
        };

        vm.modalCancel = function () {
            navigationService.confirmExitWizard()
                            .then(function (result) {
                                if (result) {
                                    vhreventService.rejectChanges();
                                    var modalResult = getModalResult(navigationService.navigationActionTypes.modalCancel);
                                    navigationService.closeCurrentModal(modalResult);
                                }
                            });
        };

        vm.bootstrapSwitchOnChange = function (switchFieldName) {
            switch (switchFieldName) {
                case 'IsVhrEventsSelected':
                    vm.switchClientData.IsAllSerie = !vm.switchClientData.IsVhrEventsSelected;
                    break;
                case 'IsAllSerie':
                    vm.switchClientData.IsVhrEventsSelected = !vm.switchClientData.IsAllSerie;
                    break;
            }
        }

        function getModalResult(dialogResult) {
            var modalResult = new navigationService.modalResult()
            var resultData = {
                VhrEventTransactionCode: vm.switchClientData.VhrEventTransactionCode,
                IsRecurrent: vm.switchClientData.IsAllSerie,
            }

            modalResult.data = resultData;
            modalResult.sourceParams = pParams;

            // Important: permits to skip nexts steps (on next and previous)
            modalResult.stepsToSkip = [];
            if (!vm.switchClientData.IsAllSerie) {
            	modalResult.stepsToSkip.push('cronExpressionDetail');            // skip cron expression
            }

            modalResult.dialogResult = dialogResult;
            return modalResult;
        }
    };

    vhrEventsManualStepUpdateSwitch.$inject = injectParams;
    vhrEventsManualStepUpdateSwitch.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('vhrEventsManualStepUpdateSwitch', vhrEventsManualStepUpdateSwitch);
});