﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'navigationService', 'checkStateChangeService', 'utilService', 'contextService'];
    function assetDetail($q, $scope, $timeout, $injector, foundationService, navigationService, checkStateChangeService, utilService, contextService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, foundationService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        // Used to sum appStoreBw related to same TargetEntity
        // E.g. 
        // 'Tec', 'Pulizie tecniche', '536870912', '[\"VirtualEquipment\"]'
        // 'Ims', 'Manutenzioni', '1073741824', '[\"VirtualEquipment\"]'
        //     AppStoreBw for VirtualEquipment is 1610612736 (tec + ims)
        //     Used for ASSETS
        var appStoreBw = vm.getAppStoreBwSumByEntity(contextService, 'VirtualEquipment');
        var resourceTypeParsedEnum = Nembus.Common.Library.util.parseEnum(foundationService.dataContext.enumTypes.ResourceType, null, vm.translate);
        var vhrStatusParsedEnum = Nembus.Common.Library.util.parseEnum(foundationService.dataContext.enumTypes.VhrStatus, null, vm.translate);
        var resourcePrototypeParsedEnum = Nembus.Common.Library.util.parseEnum(foundationService.dataContext.enumTypes.ResourcePrototype, null, vm.translate);
        var virtualHumanResourceId = navigationService.stateParams().virtualHumanResourceId;
        var appStoreCode = navigationService.stateParams().appStoreCode;
        var viewKind = navigationService.stateParams().viewKind;

        vm.viewNotGeneralTab = false;

        vm.companies = [];

        if (virtualHumanResourceId) {
            vm.setIsViewing();

            vm.viewNotGeneralTab = true;

            loadData(true);
        } else {
            vm.setIsAdding();

            vm.currentEquipmentGenerality = foundationService.dataContext.equipmentGeneralities.add({});
            vm.currentVirtualEquipment = foundationService.dataContext.virtualEquipments.add({
                CompanyId: null,
                AppStoreBw: appStoreBw,
                VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId,
                VhrTypeId: null,
                VhrGroupId: null,
                ResourceTypeId: resourceTypeParsedEnum.obj.normal.name_value.Asset,
                ResourcePrototypeId: resourcePrototypeParsedEnum.obj.normal.name_value.Single,
                VhrStatusId: vhrStatusParsedEnum.obj.normal.name_value.Enabled
            });

            vm.currentVirtualEquipment.EquipmentGeneralityId = vm.currentEquipmentGenerality.EquipmentGeneralityId;

            // manage of passport photo
            vm.photoDatacontext = {
                photoImageId: null
            };

            getCompanies(true);
            getVhrGroups(true);
            getVhrTypes(true);
        }

        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getVirtualEquipment(forceReload, virtualHumanResourceId));           
            funcsPromises.push(getCompanies(false));
            funcsPromises.push(getVhrGroups(true));
            funcsPromises.push(getVhrTypes(true));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.currentVirtualEquipment = results[0];
                    
                    // manage of folders
                    if (!vm.currentVirtualEquipment.FsFolderId) {
                        vm.rootFsDtoId = NbsGuidManager.newGuid();
                        utilService.setFsDtoRootAddFunction({
                            entityService: foundationService,
                            rootFolderKey: vm.rootFsDtoId,
                            fnSetRootFolder: function (folderId) { vm.currentVirtualEquipment.FsFolderId = folderId; }
                        });
                    } else {
                        vm.rootFsDtoId = vm.currentVirtualEquipment.FsFolderId;
                    }

                    // manage of passport photo
                    vm.photoDatacontext = {
                        photoImageId: vm.currentEquipmentGenerality.EquipmentMainImageId
                    };
                });
        }

        vm.onImageChange = function (result) {
            if (result.action === 'upload') {
                vm.currentEquipmentGenerality.TemporaryFileName = result.temporaryFileName;
                vm.currentEquipmentGenerality.TemporaryFileNameAction = 1; // ImageLogoAction.Upload
            }
            if (result.action === 'delete') {
                vm.currentEquipmentGenerality.TemporaryFileName = null;
                vm.currentEquipmentGenerality.TemporaryFileNameAction = 2; // ImageLogoAction.Delete
            }
        };

        vm.onAttachmentsTabSelect = function () {
            // send fsfolderid to media gallery component
            vm.attachmentsDataContext = {
                canShare: false,
                canCategorize: true,
                fsFolderId: vm.rootFsDtoId
            };
        };

        vm.onPlantTreeTabSelect = function () {
            if (vm.hasHierarchyConfig) {
                getAssetTreeAncestors(false, virtualHumanResourceId)
                    .then((assetTreeDtos) => {
                        $timeout(function () {
                            vm.assetTreeDtos = Nembus.Common.Library.parser.assetTreeForTreeview(assetTreeDtos);                            
                        });
                    });
            }            
        };

        function getVirtualEquipment(forceReload, currentVirtualHumanResourceId) {
            var filter = { VirtualHumanResourceId: currentVirtualHumanResourceId };
            var defer = $q.defer();

            foundationService.getVirtualEquipment(filter, forceReload)
                .then(function (data) {
                    var virtualEquipment = data.results[0];

                    if (!virtualEquipment.EquipmentGenerality) {
                        getEquipmentGenerality(forceReload, virtualEquipment.EquipmentGeneralityId)
                            .then(function (equipmentGenerality) {
                                vm.currentEquipmentGenerality = equipmentGenerality;
                                defer.resolve(virtualEquipment);
                            });
                    } else {
                        vm.currentEquipmentGenerality = virtualEquipment.EquipmentGenerality;
                        defer.resolve(virtualEquipment);
                    }                    
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getEquipmentGenerality(forceReload, equipmentGeneralityId) {
            var filter = { EquipmentGeneralityId: equipmentGeneralityId };
            var defer = $q.defer();

            foundationService.getEquipmentGenerality(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results[0]);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getCompanies(forceReload) {
            var defer = $q.defer();

            foundationService.getCompanies({}, forceReload)
                .then(function (data) {
                    vm.companies = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrGroups(forceReload) {
            var filter = { AppStoreBw: appStoreBw };
            var defer = $q.defer();

            foundationService.getVhrGroups(filter, forceReload)
                .then(function (data) {
                    vm.vhrGroups = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrTypes(forceReload) {
            var filter = { AppStoreBw: appStoreBw };
            var defer = $q.defer();

            foundationService.getVhrTypes(filter, forceReload)
                .then(function (data) {
                    vm.vhrTypes = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getAssetTreeAncestors(forceReload, virtualHumanResourceId) {
            var defer = $q.defer();
            var filter = {
                VirtualHumanResourceId: virtualHumanResourceId
            };

            foundationService.getAssetTreeAncestors(filter, forceReload)
                .then(function (data) {                    
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        // get reference of submitaction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parents = vm.submitAction;
        var ftcScope = $scope.$parent.vm;
        vm.submitAction = function () {
            vm.currentVirtualEquipment.VirtualCode = vm.currentEquipmentGenerality.RegistrationNumber;
            vm.currentVirtualEquipment.VirtualName = vm.currentEquipmentGenerality.EquipmentName;

            parents().then(
                function (saveResult) {
                    if (saveResult.entities.length > 0 && vm.viewContext.isAddingState && ftcScope.ftcOnAddEntity) {
                        ftcScope.ftcOnAddEntity(vm.currentVirtualEquipment);
                    }
                }
            );
        };
    }

    assetDetail.$inject = injectParams;
    assetDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('assetDetail', assetDetail);
});