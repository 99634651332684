﻿'use strict';
define(['../../common/services/nbs-service'], function (nbsService) {

    var injectParams = ['$http', '$q', '$timeout', 'breezeDataLayer', 'localStorageService'];

    var publicService = function ($http, $q, $timeout, breezeDataLayer, localStorageService) {

        var dataLayer = breezeDataLayer.Initialize('public', true);
        var lStorageService = localStorageService;
        var manager = dataLayer.GetManager();

        var entityTypes = {
            CaptchaDto: 'CaptchaDto',
            RecoverPassword: 'RecoverPassword'
        };

        var pwdValidator = breeze.Validator.makeRegExpValidator("appUserDtoPasswordRegExpressionValidator", /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^=!]).*$/);


        var dataContext = {
            captcha: {
                get: function () { return manager.getEntities(entityTypes.CaptchaDto); }
                //getByKey: function (key) { return manager.getEntityByKey(entityTypes.CaptchaDto, key); },
                //add: function (entity) { return nbsService.prototype.createEntityStatic(entityTypes.CaptchaDto, entity, manager); },
            },
            recoverPasswords: {
                add: function (entity) { return nbsService.prototype.createEntityStatic(entityTypes.RecoverPassword, entity, manager); },
                clearCache: function () { dataLayer.ClearCachedEntityType(entityTypes.RecoverPassword); }
            }
        };

        //breeze client type
        //client validation
        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.RecoverPassword,
                dataProperties: {
                    recoverPasswordId: { type: breeze.DataType.Guid },
                    username: { type: breeze.DataType.String, isNullable: false },
                    tmpPassword: { type: breeze.DataType.String, isNullable: false },
                    newPassword: { type: breeze.DataType.String, isNullable: false },
                    confirmNewPassword: { type: breeze.DataType.String, isNullable: false },
                    tmpPasswordUid: { type: breeze.DataType.String, isNullable: false }
                }
            },
            'Nembus.Domain'
        );
        function newPwdForgotConfirmPwdFn(entity, context) {
            // This validator only validates US Zip Codes.
            if (entity.getProperty('newPassword') !== entity.getProperty('confirmNewPassword')) {
                return false;
            }
            return true;
        }
        var newPwdForgotConfirmPwdValidator = new breeze.Validator(
            'newPwdForgotConfirmPwdValidator',
            newPwdForgotConfirmPwdFn,
            { messageTemplate: 'ConfirmPassowordNotMatch' });

        var recoverPasswordType = manager.metadataStore.getEntityType(entityTypes.RecoverPassword);

        //entity level validation
        recoverPasswordType.validators.push(newPwdForgotConfirmPwdValidator);
        //property validator
        var attrTmpPassword = recoverPasswordType.getProperty("tmpPassword");
        attrTmpPassword.validators.push(pwdValidator);
        var attrNewPassword = recoverPasswordType.getProperty("newPassword");
        attrNewPassword.validators.push(pwdValidator);
        var attrConfirmNewPassword = recoverPasswordType.getProperty("confirmNewPassword");
        attrConfirmNewPassword.validators.push(pwdValidator);

        var maintenanceStatusDefault = { IsInMaintenance: false, PollingEnabled: true, IsBypassIp: false, MaintenancePollingInterval: 30000 };
        var redirectIsChecked = false;

        var service = {
            getSsoConfig: getSsoConfig,
            getCaptcha: getCaptcha,
            recoverMyPasswordRequest: recoverMyPasswordRequest,
            recoverPassword_UpdatePassword: recoverPassword_UpdatePassword,
            dataContext: dataContext,
            isInMaintenance: isInMaintenance,
            maintenanceStatus: maintenanceStatusDefault,
            haveToRedirect: haveToRedirect,
            redirectIsChecked: redirectIsChecked,
            checkVersionChanged: checkVersionChanged,
        };
        return service;

        function getCaptcha() {
            var query = dataLayer.CreateQuery("Captcha");
            return manager.executeQuery(query);
        }

        function getSsoConfig() {
            var defer = $q.defer();
            if (window.ssoconfig) {
                defer.resolve(window.ssoconfig);
            }
            else {
                var query = dataLayer.CreateQuery("SsoConfig");
                manager.executeQuery(query)
                    .then(result => {
                        window.ssoconfig = result.results[0];
                        defer.resolve(window.ssoconfig);
                    });

            }
            return defer.promise;
        }

        function recoverMyPasswordRequest(captcha) {
            var query = dataLayer.InvokeQuery("RecoverMyPasswordRequest",
                captcha);
            return manager.executeQuery(query);
        }

        function recoverPassword_UpdatePassword(data) {
            var query = dataLayer.InvokeQuery("RecoverPassword_UpdatePassword",
                {
                    Username: data.username,
                    TmpPassword: data.tmpPassword,
                    TmpPasswordUid: data.tmpPasswordUid,
                    NewPassword: data.newPassword,
                    ConfirmNewPassword: data.confirmNewPassword
                });
            return manager.executeQuery(query);
        }

        function isInMaintenance() {
            var query = dataLayer.CreateQuery("IsInMaintenance").withParameters({ filter: { sxb: 'x' } });
            var deferred = $q.defer();
            manager.executeQuery(query)
                .then(function (result) {
                    var maintenanceStatus = JSON.parse(Nembus.Common.Library.converter.decodeHtml(result.results[0]).slice(1, -1));
                    maintenanceStatus.PollingEnabled = maintenanceStatus.PollingEnabled === "True";
                    maintenanceStatus.IsInMaintenance = maintenanceStatus.IsInMaintenance === "True";
                    maintenanceStatus.IsBypassIp = maintenanceStatus.IsBypassIp === "True";
                    maintenanceStatus.MaintenancePollingInterval = Number(maintenanceStatus.MaintenancePollingInterval);

                    lStorageService.set('appversion', maintenanceStatus.WebAppVersion);

                    deferred.resolve(maintenanceStatus);
                })
                .catch(function (error) {
                    $q.reject(error);
                });
            return deferred.promise;

        }

        function checkVersionChanged() {
            if (window.localAppVersion == null) {
                window.localAppVersion = lStorageService.get('appversion');
                return false;
            };

            return window.localAppVersion != lStorageService.get('appversion');
        }

        function haveToRedirect() {
            var query = dataLayer.CreateQuery("HaveToRedirect").withParameters({ filter: { sxb: 'x' } });
            var deferred = $q.defer();
            manager.executeQuery(query)
                .then(function (result) {
                    var redirUri = Nembus.Common.Library.converter.decodeHtml(result.results[0]).slice(1, -1);

                    deferred.resolve(redirUri);
                })
                .catch(function (error) {
                    $q.reject(error);
                });
            return deferred.promise;

        }
    };

    publicService.$inject = injectParams;
    angular.module('nbs.shared.module').service('publicService', publicService);
});