﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$injector', '$timeout', 'activityService', 'bomService', 'utilService', 'navigationService', 'contextService'];
    function activitiesListModal($q, $injector, $timeout, activityService, bomService, utilService, navigationService, contextService) {
        var vm = this;
        nbsVm.call(vm, activityService, navigationService, contextService, $q, $injector, $timeout);
        
        var activityKindEnum = Nembus.Common.Library.util.parseEnum(activityService.dataContext.enumTypes.ActivityKind, null, vm.translate).obj.normal.name_value;
        var effortUnitEnumParsed = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.EffortUnit, null, vm.translate);
        var service = null;

        // modal parameters
        var pParams = navigationService.stateParamsModal().parentParams;
        pParams = pParams ? pParams : navigationService.stateParams();

        vm.currentActivities = [];
        vm.gridIsMultiselect = pParams.isMultiselect;

        var appStoreBw = pParams.appStoreBw;
        var activityKindId = pParams.activityKindId;

        if (activityKindId === activityKindEnum.SubBom) {
            vm.viewTitle = vm.translate('Activities');
            service = activityService;
        } else if (activityKindId === activityKindEnum.Bom) {
            vm.viewTitle = vm.translate('Boms');
            service = bomService;
        }

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('activity', 'modal');
        nbsGridDatacontext.setModalMode();
        if (pParams.isMultiselect === 'true' || pParams.isMultiselect === true) {
            nbsGridDatacontext.setIsMultiselect();
        }
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        loadData(true);
        function loadData(forceReload) {
            var funcsPromises = [];

            $q.all(funcsPromises)
                .then(function () {
                    var filter = {
                        AppStoreBw: appStoreBw,
                        ActivityKindId: activityKindId
                    };
                    var supportData = { effortUnitEnumParsed: effortUnitEnumParsed };
                    var ejDataManager = service.getActivityDataManager(supportData, filter, ejDataManager);
                    nbsGridDatacontext.setDatasource(ejDataManager, true);
                    refreshGridDatasource(filter)
                });
        }

        function refreshGridDatasource(currentGridFilter) {
            $timeout(() => {
                vm.forceDatamanagerFilter = {
                    refreshKey: new Date().getTime(),
                    filter: currentGridFilter
                }
            });
        }

        vm.modalOk = function () {
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalOk);
            navigationService.closeCurrentModal(modalResult);
        };

        vm.modalCancel = function () {
            navigationService.closeCurrentModal();
        };

        var currentSelected = [];
        vm.gridSelection = function (args) {
            if (vm.gridIsMultiselect) {
                currentSelected = args.data;
            } else {
                if (args.data) {
                    currentSelected = [args.data];
                } else {
                    currentSelected = [];
                }
            }
        };

        function getModalResult(dialogResult) {
            Lazy(currentSelected)
                .each((item) => {
                    item.nbs_id = item.ActivityId;
                    item.nbs_code = item.ActivityCode;
                    item.nbs_name = item.ActivityName;
                });

            var modalResult = new navigationService.modalResult();
            modalResult.data = currentSelected;
            modalResult.selectedValues = Lazy(currentSelected).pluck('ActivityId').toArray();
            modalResult.hasSelection = Lazy(currentSelected).some();
            //modalResult.sourceParams = urlParams;
            modalResult.dialogResult = dialogResult;
            return modalResult;
        }
    }

    activitiesListModal.$inject = injectParams;
    activitiesListModal.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('activitiesListModal', activitiesListModal);
});
