﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$injector', '$scope', '$timeout', 'audService', 'vhreventService', 'utilService', 'navigationService', 'checkStateChangeService', 'contextService', 'workflowService'];
    function surveysSearch($q, $injector, $scope, $timeout, audService, vhreventService, utilService, navigationService, checkStateChangeService, contextService, workflowService) {
        var vm = this;
        nbsVm.call(vm, audService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, audService, { dirtyFlagIsSuspended: vm.isDirtySuspended });

        var ribbon = new NbsAudRibbon(navigationService, contextService.virtualContext, 'search');
        ribbon.addHomeTab();
        ribbon.addSearchTab();
        ribbon.tabIndexSelected = 2;
        vm.ribbonDatacontext = ribbon;

        vm.setIsEditingSearch();

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var surveyCreationPatternObj = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyCreationPattern, null, vm.translate).obj.normal.name_value;

        var filtersToSkip = [];
        var nbsAudSearchForm = new NbsAudSearchForm(vm.translate);
        var searchFormData = nbsAudSearchForm.SearchSurveysFormData();
        searchFormData.init(vm, filtersToSkip);

        var selectionParams = {};
        var currentFilterData = {};
        var surveyLifeStatusEnumParsed = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyLifeStatus, 'SurveyDto', vm.translate);
        var expressionTypeEnum = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.ExpressionType, null, vm.translate).obj.normal.name_value;
        var vhrEventKindEnum = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventKind, null, vm.translate).obj.normal.name_value;

        var nbsGridDatacontext = new NbsAudGrid(vm.translate);
        nbsGridDatacontext.configureGrid('survey', 'list', null);
        nbsGridDatacontext.setGridIsRenderedInlineBlock();
        //nbsGridDatacontext.setIsMultiselect();
        nbsGridDatacontext.allowSearching = false;
        nbsGridDatacontext.allowGrouping = false;
        nbsGridDatacontext.setDatasource([], false); // fake datasource, only to create grid
        vm.nbsGridDatacontext = nbsGridDatacontext;

        var dsCallback = function () { return vhreventService.getVhrEventTypes({ AppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventType', viewTitle: vm.translate('SurveyDto.VhrEventTypeId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventType = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalVhrEventType.setOnSelectFnCallback(function () {
            vm.currentVhrEventFilter.VhrEventTypesId = vm.nbsSelectionGridWithModalVhrEventType.selectedIds;
        });

        dsCallback = function () { return vhreventService.getVhrEventGroups({ AppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventGroup', viewTitle: vm.translate('SurveyDto.VhrEventGroupId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventGroup = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalVhrEventGroup.setOnSelectFnCallback(function () {
            vm.currentVhrEventFilter.VhrEventGroupsId = vm.nbsSelectionGridWithModalVhrEventGroup.selectedIds;
        });

        selectionParams = { stateTo: 'app.nav.surveytemplateslistmodal', destModalKey: 'surveyTemplate' };
        vm.nbsSelectionGridWithModalSurveyTemplate = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalSurveyTemplate.setOnSelectFnCallback(function () {
            vm.currentSurveyVhrEventFilter.SurveyTemplatesId = vm.nbsSelectionGridWithModalSurveyTemplate.selectedIds;
        });

        selectionParams = { destModalKey: 'vhr', entityMode: 'Target', translateKey: 'Target', isMultiselect: true };
        vm.nbsSelectionGridWithModalTarget = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalTarget.setOnSelectFnCallback(function () {
            vm.currentVhrEventFilter.VirtualHumanResourcesId = vm.nbsSelectionGridWithModalTarget.selectedIds;
        });

        dsCallback = function () { return vhreventService.getVhrEventLabels({ AppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventLabel', viewTitle: vm.translate('SurveyDto.VhrEventLabels'), isMultiselect: true, ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventLabel = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalVhrEventLabel.setOnSelectFnCallback(function () {
            vm.currentVhrEventFilter.VhrEventLabelsId = vm.nbsSelectionGridWithModalVhrEventLabel.selectedIds;
        });

        selectionParams = { stateTo: 'app.nav.surveyslistmodal', destModalKey: 'survey', isMultiselect: true };
        vm.nbsSelectionGridWithModalSurvey = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalSurvey.setOnSelectFnCallback(function () {
            vm.currentSurveyFilter.SurveyIds = vm.nbsSelectionGridWithModalSurvey.selectedIds;
        });

        loadData(true);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getWorkflowActiveStatuses(false, moduleTargetId));

            $q.all(funcsPromises)
                .then(function () {
                });
        }


        vm.surveyCreationPatternList = [
            { value: surveyCreationPatternObj.InstanceOnDemand, name: vm.translate('OnCall') }
        ];

        vm.searchTabIndex = 0;


        //vhrevent life status drop down
        vm.surveyLifeStatusList = surveyLifeStatusEnumParsed.list.translated;

        vm.addFilterAction = function () {
            vm.searchTabIndex = 0;
            searchFormData.addFilters(navigationService, vm.translate, $scope);
        };

        vm.searchAction = function () {
            // start search
            searchSurveys();
        };

        vm.deleteFilterAction = function () {
            $timeout(() => {
                vm.searchTabIndex = 0;
                vm.resetAction();
                searchFormData.removeFilters(filtersToSkip);
            });
        };

        vm.resetAction = function () {
            $timeout(() => {
                vm.searchTabIndex = 0;
                searchFormData.resetFilters();

                vm.nbsSelectionGridWithModalVhrEventType.clear();
                vm.nbsSelectionGridWithModalVhrEventGroup.clear();
                vm.nbsSelectionGridWithModalSurveyTemplate.clear();
                vm.nbsSelectionGridWithModalTarget.clear();
                vm.nbsSelectionGridWithModalVhrEventLabel.clear();
                vm.nbsSelectionGridWithModalSurvey.clear();
            });
        };

        var ejDataManager = null;
        function searchSurveys() {
            vm.currentVhrEventFilter.VhrEventLifeStatusExpression = expressionTypeEnum.Equal;
            vm.currentVhrEventFilter.HaveToExpandVhrsWithAssetTree = true;
            vm.currentSurveyVhrEventFilter.SurveyCompileDateSystem1Expression = expressionTypeEnum.GreaterThanOrEqual;
            vm.currentSurveyVhrEventFilter.SurveyCompileDateSystem2Expression = expressionTypeEnum.LessThanOrEqual;

            currentFilterData.surveyFilter = nbsAudSearchForm.ParseSurveyFilter(vm.currentSurveyFilter, appStoreBw);
            currentFilterData.surveySectionFilter = nbsAudSearchForm.ParseSurveySectionFilter(vm.currentSurveySectionFilter);
            currentFilterData.surveyQuestionFilter = nbsAudSearchForm.ParseSurveyQuestionFilter(vm.currentSurveyQuestionFilter);
            currentFilterData.surveyQuestionOptionFilter = nbsAudSearchForm.ParseSurveyQuestionOptionFilter(vm.currentSurveyQuerstionOptionFilter);
            currentFilterData.vhrEventFilter = nbsAudSearchForm.ParseVhrEventFilter(vm.currentVhrEventFilter, appStoreBw, vhrEventKindEnum, expressionTypeEnum);
            currentFilterData.surveyVhrEventFilter = nbsAudSearchForm.ParseSurveyVhrEventFilter(vm.currentSurveyVhrEventFilter, appStoreBw);
            currentFilterData.vhrEventFilter.ModuleTargetId = moduleTargetId;
            currentFilterData.surveyVhrEventFilter.ModuleTargetId = moduleTargetId;
            currentFilterData.surveyVhrEventFilter.ExcludeOnDemand = true;

            if (ejDataManager === null) {
                utilService.getGenericElements({ GenericElementCode: 'Configurations.Surveys.LifeStatusColors' }, false)
                    .then((data) => {
                        var ge = data.results[0].PairValuesArray;
                        var statusColorObj = Nembus.Common.Library.parser.objFromList(ge, 'key', 'value');

                        var supportData = { isMySurveys: false, translate: vm.translate, contextService: contextService, statusColorObj: statusColorObj };
                        ejDataManager = audService.getSurveyDataManager(supportData, currentFilterData, ejDataManager);
                        nbsGridDatacontext.setDatasource(ejDataManager, true);
                        refreshGridDatasource(currentFilterData);
                    });
            } else {
                refreshGridDatasource(currentFilterData);
            }            
        }

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.surveydetail", { 'surveyId': args.data.SurveyId, 'vhrEventId': args.data.VhrEventId });
        };

        vm.refreshAction = function () {
            $timeout(() => {
                vm.searchTabIndex = 1;
                vm.forceGridRefresh = new Date().getTime();
            });
        };

        
        function refreshGridDatasource(currentGridFilter) {
            $timeout(() => {
                vm.searchTabIndex = 1;

                vm.forceDatamanagerFilter = {
                    refreshKey: new Date().getTime(),
                    filter: currentGridFilter
                }
            });
        }

        function getWorkflowActiveStatuses(forceReload, moduleTargetId) {
            var defer = $q.defer();

            workflowService.getWorkflowActiveStatuses({ ModuleTargetId: moduleTargetId }, forceReload)
                .then(function (data) {
                    vm.workflowStatusList = data.results;
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }
    }

    surveysSearch.$inject = injectParams;
    surveysSearch.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('surveysSearch', surveysSearch);
});