﻿'use strict';
define([], function () {

	//var injectParams = [];
	function landingPage() {
		//var vm = this;
	}

	//landingPage.$inject = injectParams;
	//landingPage.prototype = Object.create();
    angular.module('nbs.entry.module').controller('landingPage', landingPage);
});


