﻿'use strict';
define([], function () {

    var injectParams = [];
    var nbsFormSummaryComponent = function () {

        var vm = this;
        vm.datasource = [];
        vm.visible = true;

        vm.$onChanges = function (changes) {
            if (changes.nbsDataContext && changes.nbsDataContext.currentValue) {
                vm.datasource = changes.nbsDataContext.currentValue;
            }
            if (changes.showFormSummary) {
                if (changes.showFormSummary.currentValue !== null && changes.showFormSummary.currentValue !== undefined) {
                    vm.visible = changes.showFormSummary.currentValue;
                }                
            }
        };
    };

    nbsFormSummaryComponent.$inject = injectParams;
    angular.module('nbs.rds.module').component('nbsFormSummary', {
        templateUrl: '/rds/common/components/nbsFormSummaryComponent/_?c=' + window.codeCache(),
        controller: nbsFormSummaryComponent,
        controllerAs: 'vm',
        bindings: {
            nbsDataContext: "<", //[{ key:, value:}]
            showFormSummary: "<",
            uiContext: "<"
        }
    });
});

