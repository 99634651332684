﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'drbacService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function drbacPermissionGroupList($q, $scope, $timeout, $injector, drbacService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, drbacService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, drbacService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        ribbon.setRibbonStatusButton(ribbon.Buttons.OpenDetail, false);
        ribbon.setRibbonStatusButton(ribbon.Buttons.New, false);
        ribbon.refreshStatusButtons();

        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;

        vm.drbacPermissionGroups = [];

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getDrbacPermissionGroups(forceReload, virtualCompanyId));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.drbacPermissionGroups = results[0];

                    var nbsGridDatacontext = new NbsGrid(vm.translate);
                    nbsGridDatacontext.configureGrid('drbacpermissiongroup', null);
                    nbsGridDatacontext.viewCommonBar();
                    nbsGridDatacontext.setDatasource(vm.drbacPermissionGroups, false);
                    vm.nbsGridDatacontext = nbsGridDatacontext;
                });
        }

        vm.refreshAction = function () {
            getDrbacPermissionGroups(true, virtualCompanyId)
                .then((results) => {
                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: results
                    }
                });
        };

        function getDrbacPermissionGroups(forceReload, virtualCompanyId) {
            var filter = {
                VirtualCompanyId: virtualCompanyId,
                IsHidden: false,
                IsGlobalProcess: false
            };
            var defer = $q.defer();

            drbacService.getDrbacPermissionGroups(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    }

    drbacPermissionGroupList.$inject = injectParams;
    drbacPermissionGroupList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rbac.module').controller('drbacPermissionGroupList', drbacPermissionGroupList);
});