﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'audService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function surveyTemplateGroupsList($q, $scope, $timeout, $injector, audService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, audService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return surveyTemplateGroupSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, audService);

        var ribbon = new NbsAudRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var surveyTemplateGroupSelected = null;

        getSurveyTemplateGroups(true)
            .then(function (groups) {
                var nbsGridDatacontext = new NbsAudGrid(vm.translate);
                nbsGridDatacontext.configureGrid('surveytemplategroup', null, null);
                nbsGridDatacontext.viewCommonBar();
                nbsGridDatacontext.setDatasource(groups, false);
                vm.nbsGridDatacontext = nbsGridDatacontext;
            });

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.surveytemplategroupdetail", { 'surveyTemplateGroupId': args.data.SurveyTemplateGroupId, 'appStoreCode': appStoreCode });
        };

        vm.newAction = function () {
            navigationService.navigateTo("app.nav.surveytemplategroupdetail", { 'surveyTemplateGroupId': null, 'appStoreCode': appStoreCode });
        };

        vm.refreshAction = function () {
            getSurveyTemplateGroups(true)
                .then(function (groups) {
                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: groups
                    }
                });
        };

        vm.deleteAction = function () {
            if (!surveyTemplateGroupSelected) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            audService.deleteEntity(surveyTemplateGroupSelected);
            vm.submitAction()
                .then(function (saveResult) {
                    vm.refreshAction();
                });
        };

        vm.gridSelection = function (args) {
            $timeout(() => {
                surveyTemplateGroupSelected = args.data;

                if (surveyTemplateGroupSelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, true);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                }

                ribbon.refreshStatusButtons();
            });
        };

        function getSurveyTemplateGroups(forceReload) {
            var filter = {};
            var defer = $q.defer();

            audService.getSurveyTemplateGroups(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    }

    surveyTemplateGroupsList.$inject = injectParams;
    surveyTemplateGroupsList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('surveyTemplateGroupsList', surveyTemplateGroupsList);
});