﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'vhreventService', 'utilService', 'navigationService', 'checkStateChangeService', 'contextService', 'formSummaryService'];
    function vhrEventsStream($q, $scope, $timeout, $injector, vhreventService, utilService, navigationService, checkStateChangeService, contextService, formSummaryService) {
        var vm = this;
        nbsVm.call(vm, vhreventService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return eventsSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, vhreventService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'stream');
        ribbon.addHomeTab();
        ribbon.tabIndexSelected = 2;
        ribbon.addTab(ribbon.GetVhrEventViewsTab());
        vm.ribbonDatacontext = ribbon;

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var vhrEventTypeCode = navigationService.stateParams().vhrEventTypeCode;
        var vhrEventFixedTypeCode = navigationService.stateParams().vhrEventFixedTypeCode;
        var vhrEventKindEnum = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventKind, null, vm.translate).obj.normal.name_value;
        var vhrEventKindId = vhrEventKindEnum.Data;
        var virtualHumanResourceId = navigationService.stateParams().virtualHumanResourceId;
        var viewKind = navigationService.stateParams().viewKind;
        var viewMode = navigationService.currentViewMode();
        var wzVhrEntityType = navigationService.stateParams().wzVhrEntityType;          //asset, avatar, bucket, ...
        var vhrEventLifeStatusId = navigationService.stateParams().vhrEventLifeStatusId;

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('vhrevent', null, appStoreCode);
        nbsGridDatacontext.setStreamMode();
        nbsGridDatacontext.setDatasource([], false); // fake datasource, only to create grid
        vm.nbsGridDatacontext = nbsGridDatacontext;

        vm.viewKindIsFull = viewKind === 'full' ? true : false;

        vm.vhrEventGridDS = { dataManager: null };

        //#region set ribbon fn navigations
        var navParams = {
            'appStoreCode': appStoreCode,
            'vhrEventTypeCode': vhrEventTypeCode,
            'vhrEventFixedTypeCode': vhrEventFixedTypeCode,
            'viewKind': viewKind,
            'wzVhrEntityType': wzVhrEntityType,
            'vhrEventLifeStatusId': vhrEventLifeStatusId
        };
        vm.onRibbonGrid = () => { ribbon.onRibbonGrid_VhrEvent(navParams); };
        vm.onRibbonBoardStatus = () => { ribbon.onRibbonBoardStatus_VhrEvent(navParams); };
        vm.onRibbonStream = () => { ribbon.onRibbonStream_VhrEvent(navParams); };
        vm.onRibbonBoard = () => { ribbon.onRibbonBoard_VhrEvent(navParams); };
        vm.onRibbonBoardApproval = () => { ribbon.onRibbonBoardApproval_VhrEvent(navParams); };
        //#endregion set ribbon fn navigations

        //#region set ribbon enabled buttons
        ribbon.setRibbonStatusButton(ribbon.Buttons.New, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.Board, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.BoardStatus, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.BoardApproval, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.ViewList, true);
        ribbon.refreshStatusButtons();
        //#endregion set ribbon enabled buttons

        viewKind = viewKind ? viewKind : null;
        switch (viewKind) {
            case 'scheduledaily':
                setScheduleDailyLayout();
                break;
            default:
                setDefaultLayout();
        }

        // hook from gridbar
        var ejDataManager = null;
        var currentVhrEventBarFilter = null;
        vm.vhrEventBarOnChanges = function (result) {
            // save in view current filter passed by survey bar
            currentVhrEventBarFilter = result;

            currentVhrEventBarFilter.vhrEventFilter.AppStoreBw = appStoreBw;
            currentVhrEventBarFilter.vhrEventFilter.VhrEventTypeCode = vhrEventTypeCode;
            currentVhrEventBarFilter.vhrEventFilter.VhrEventFixedTypeCode = vhrEventFixedTypeCode;
            currentVhrEventBarFilter.vhrEventFilter.VhrEventKind = vhrEventKindId;
            currentVhrEventBarFilter.vhrEventFilter.VirtualHumanResourceId = virtualHumanResourceId;
            currentVhrEventBarFilter.vhrEventFilter.ModuleTargetId = moduleTargetId;

            if (ejDataManager === null) {
                utilService.getGenericElements({ GenericElementCode: 'Configurations.VhrEvents.LifeStatusColors' }, false)
                    .then((data) => {
                        var ge = data.results[0].PairValuesArray;
                        var statusColorObj = Nembus.Common.Library.parser.objFromList(ge, 'key', 'value');

                        var supportData = { searchMode: 'vhrevents', translate: vm.translate, contextService: contextService, statusColorObj: statusColorObj };
                        ejDataManager = vhreventService.getVhrEventDataManager(supportData, currentVhrEventBarFilter.vhrEventFilter, ejDataManager);
                        nbsGridDatacontext.setDatasource(ejDataManager, true);
                        refreshGridDatasource(currentVhrEventBarFilter.vhrEventFilter);
                    });
            } else {
                refreshGridDatasource(currentVhrEventBarFilter.vhrEventFilter)
            }
        };

        function setScheduleDailyLayout() {
            // set layout visibility
            vm.formSummaryIsVisible = true;

            vm.filterParam = JSON.parse(navigationService.stateParams().filter);
            var date = Nembus.Common.Library.converter.date.formatDate(vm.filterParam.Date, contextService.virtualContext.cultureInfo, true);

            formSummaryService.clearDatasource();
            formSummaryService.push({ key: vm.translate('Date'), value: date });
            formSummaryService.push({ key: vm.translate('WorkCenter'), value: vm.filterParam.WorkCenterName });
            // get array items for form summary
            vm.formSummary = formSummaryService.getDatasource();
        }

        function setDefaultLayout() {
            // set layout visibility
            vm.formSummaryIsVisible = false;
        }

        vm.currentVhrEvents = [];

        var eventsSelected = [];
        vm.gridSelection = function (args) {
            eventsSelected = args.data;

            if (Lazy(eventsSelected).some()) {
                ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, true);
            } else {
                ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, false);
            }

            ribbon.refreshStatusButtons();
        };

        vm.refreshAction = function () {
            $timeout(() => {
                vm.forceGridRefresh = new Date().getTime();
            });
        };

        vm.rowDblClick = function (args) {
            navigationService.navigateToVhrEventDetail({
                AppStoreCode: appStoreCode,
                VhrEventTemplateCode: args.data.VhrEventTemplateCode,
                ViewKind: viewKind,
                VhrEventId: args.data.VhrEventId,
                VirtualHumanResourceId: args.data.VirtualHumanResourceId,
                ViewMode: viewMode
            });
        };

        vm.createGridEvent = function (result) {
            vm.vhrEventBarDataContext = {
                commonBarDatasource: result.barDatasource,
                formSummary: {
                    dataContext: vm.formSummary,
                    isVisible: vm.formSummaryIsVisible
                },
                appStoreBw: appStoreBw,
                appStoreCode: appStoreCode,
                defaultKeys: {
                    columnsKey: null, sortingKey: null
                }
            };
        };

        vm.gridUiOnChanges = function (result) {
            vm.refreshGridUiComponent = {
                refreshKey: new Date().getTime(),
                uiData: result
            }
        }

        function refreshGridDatasource(currentGridFilter) {
            $timeout(() => {
                vm.forceDatamanagerFilter = {
                    refreshKey: new Date().getTime(),
                    filter: currentGridFilter
                }
            });
        }
    }

    vhrEventsStream.$inject = injectParams;
    vhrEventsStream.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('vhrEventsStream', vhrEventsStream);
});