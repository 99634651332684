﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$injector', '$timeout', 'audService', 'navigationService', 'contextService'];
    function surveyTemplatesListModal($q, $injector, $timeout, audService, navigationService, contextService) {
        var vm = this;
        nbsVm.call(vm, audService, navigationService, contextService, $q, $injector, $timeout);
        
        // modal parameters
        var pParams = navigationService.stateParamsModal().parentParams;
        pParams = pParams ? pParams : navigationService.stateParams();

        vm.currentSurveys = [];
        vm.gridIsMultiselect = pParams.isMultiselect;

        vm.currentDatasDm = [];

        loadData(true);
        function loadData(forceReload) {
            var funcsPromises = [];

            $q.all(funcsPromises)
                .then(function () {
                    var filter = {
                        IsArchived: false
                    };
                    var supportData = { translate: vm.translate, contextService: contextService };
                    var ejDataManager = audService.getSurveyTemplateDataManager(supportData, filter, ejDataManager);

                    var nbsGridDatacontext = new NbsAudGrid(vm.translate);
                    nbsGridDatacontext.configureGrid('surveytemplate', 'modal', null);
                    nbsGridDatacontext.setModalMode();
                    if (pParams.isMultiselect === 'true' || pParams.isMultiselect === true) {
                        nbsGridDatacontext.setIsMultiselect();
                    }
                    nbsGridDatacontext.allowSearching = true;
                    nbsGridDatacontext.setDatasource(ejDataManager, true);
                    vm.nbsGridDatacontext = nbsGridDatacontext;
                });
        }

        vm.modalOk = function () {
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalOk);
            navigationService.closeCurrentModal(modalResult);
        };

        vm.modalCancel = function () {
            navigationService.closeCurrentModal();
        };

        var currentSelected = [];
        vm.gridSelection = function (args) {
            if (vm.gridIsMultiselect) {
                currentSelected = args.data;
            } else {
                if (args.data) {
                    currentSelected = [args.data];
                } else {
                    currentSelected = [];
                }
            }
        };

        function getModalResult(dialogResult) {
            Lazy(currentSelected)
                .each((item) => {
                    item.nbs_id = item.SurveyId;
                    item.nbs_code = item.SurveyCode;
                    item.nbs_name = item.SurveyName;
                });

            var modalResult = new navigationService.modalResult();
            modalResult.data = currentSelected;
            modalResult.selectedValues = Lazy(currentSelected).pluck('SurveyId').toArray();
            modalResult.hasSelection = Lazy(currentSelected).some();
            //modalResult.sourceParams = urlParams;
            modalResult.dialogResult = dialogResult;
            return modalResult;
        }        
    }

    surveyTemplatesListModal.$inject = injectParams;
    surveyTemplatesListModal.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('surveyTemplatesListModal', surveyTemplatesListModal);
});
