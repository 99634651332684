﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$injector', '$scope', '$timeout', 'reportService', 'navigationService', 'checkStateChangeService', 'contextService', 'foundationService', 'fileTransferService'];
    function reportDefinitionHtmlToDocDetail($q, $injector, $scope, $timeout, reportService, navigationService, checkStateChangeService, contextService, foundationService, fileTransferService) {

        var vm = this;
        nbsVm.call(vm, reportService, navigationService, contextService, $q, $injector, $timeout);

        var reportHtmlOutputType = Nembus.Common.Library.util.parseEnum(reportService.dataContext.enumTypes.ReportHtmlOutputType, null, vm.translate).obj.normal.name_value;
        var reportDefinitionId = navigationService.stateParams().reportDefinitionId;
        var currentFormat = null;

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, null);
        ribbon.addHomeTab({ showGroupNew: false, showGroupManage: false, showGroupPanel: false, showGroupReports: true, showGroupSearch: true });
        vm.ribbonDatacontext = ribbon;

        vm.onSearchActionHtmlToDocFn = null;
        vm.onCreateHtmlFn = null;
        vm.headerDataSource = null;

        reportService.initCurrentReport();


        vm.initReportToDocHeader = function (params) {
            var defer = $q.defer();

            vm.htmlToDocManager = new NbsReportHtmlToDocManager();
            vm.htmlToDocManager.setHtmlNotVisible();

            vm.onSearchActionHtmlToDocFn = params && params.onSearchActionHtmlToDocFn ? params.onSearchActionHtmlToDocFn : null;
            vm.onCreateHtmlFn = params && params.onCreateHtmlFn ? params.onCreateHtmlFn : null;

            // load data
            getReportDefinitionDto(true, reportDefinitionId)
                .then((def) => {
                    // set current report on service
                    reportService.currentReport.reportDefinition = def;

                    // set current view title
                    vm.viewTitle = Nembus.Common.Library.report.getReportViewTitle(def);

                    if (def.ReportHeaderTypeId === 2) { // VirtualCompany
                        getVirtualCompany(true, contextService.virtualContext.CurrentVirtualCompanyId)
                            .then((vc) => {
                                Nembus.Common.Library.util.getReportHtmlToDocHeaderByVirtualCompany(vc, def, $q, contextService, fileTransferService)
                                    .then(function (result) {
                                        reportService.currentReport.reportHtmlToDocHeader = result;
                                        vm.headerDataSource = reportService.currentReport.reportHtmlToDocHeader;
                                        defer.resolve(true);
                                    });
                            });
                    } else if (def.ReportHeaderTypeId === 1) { // Company
                        // at moment company not managed
                        var company = null;
                        Nembus.Common.Library.util.getReportHtmlToDocHeaderByCompany(company, def, $q, contextService, fileTransferService)
                            .then(function (result) {
                                reportService.currentReport.reportHtmlToDocHeader = result;
                                vm.headerDataSource = reportService.currentReport.reportHtmlToDocHeader;
                                defer.resolve(true);
                            });
                    }
                });

            return defer.promise;
        };

        // take title for current view setted in other controller (reportforms, ...)
        vm.controllersBehaviour = navigationService.controllersBehaviour;

        //#region Search buttons
        vm.searchAction = function () {
            if (!typeof vm.onSearchActionHtmlToDocFn === 'function') {
                console.error('vm.searchAction must be setted from HtmlToDoc controller');
            }

            vm.onSearchActionHtmlToDocFn();
        };
        //#endregion

        //#region Print report buttons
        vm.exportReportToPdfAction = function () {
            callCreateHtml(reportHtmlOutputType.Pdf);
        };

        vm.exportReportToExcelAction = function () {
            callCreateHtml(reportHtmlOutputType.Excel);
        };

        function callCreateHtml(format) {
            currentFormat = format;

            if (!typeof vm.onCreateHtmlFn === 'function') {
                console.error('vm.searchAction must be setted from HtmlToDoc controller');
            }

            vm.onCreateHtmlFn();
        }
        //#endregion

        function getVirtualCompany(forceReload, virtualCompanyId) {
            var filter = { VirtualCompanyId: virtualCompanyId };
            var defer = $q.defer();

            foundationService.getVirtualCompany(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results[0]);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getReportDefinitionDto(forceReload, reportDefinitionId) {
            var filter = { ReportDefinitionId: reportDefinitionId };
            var defer = $q.defer();

            reportService.getReportDefinitionDto(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results[0]);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function entityIsValid(entity) {
            if (entity && entity.entityAspect) {
                entity.entityAspect.validateEntity();
                $timeout(() => { // NO REMOVE
                    // $scope.$apply() is triggered by $timeout 
                });
                return !entity.entityAspect.hasValidationErrors;
            } else {
                return true;
            }
        }

        vm.renderReportHtmlToDoc = (htmlString) => {
            if (entityIsValid(reportService.currentReport.formEntityToValidate)) {
                reportService.currentReport.reportHtmlRequest.ReportHtmlOutputTypeId = currentFormat;
                reportService.currentReport.reportHtmlRequest.HtmlString = htmlString;

                vm.setReportHtmlToDocData({
                    reportDefinition: reportService.currentReport.reportDefinition,
                    reportHtmlRequest: reportService.currentReport.reportHtmlRequest,
                    concatName: reportService.currentReport.concatName,
                    replaceName: reportService.currentReport.reportDefinition.ReportDefinitionReferenceCode + ' - ' + reportService.currentReport.reportDefinition.ReportDefinitionName
                });

                vm.exportReport(currentFormat);
            }
        }

        class NbsReportHtmlToDocManager {
            constructor() {
                this.htmlContainerIsVisible = false;
                this.loadDataFn = null;
                this.reporthtmlrefresh = null;
                this.htmlStepsToRender = [];
                this.htmlStepsRendered = [];
                this.htmlIsVisible = true;
            }

            setHtmlStepsToRender(steps) {
                this.htmlStepsToRender = steps;
            };

            hasHtmlStepsToRender() {
                return this.htmlStepsToRender.length > 0;
            };

            setHtmlNotVisible() {
                this.htmlIsVisible = false;
            };

            htmlStepIsRendered(stepRendered) {
                if (!stepRendered) {
                    console.error('htmlStepIsRendered: step mandatory');
                }

                // add another html step rendered
                this.htmlStepsRendered.push(stepRendered);

                var htmlIsRendered = this.verifyHtmlSteps();
                if (htmlIsRendered) {
                    this.htmlContainerIsVisible = this.htmlIsVisible;

                    // trigger of directive that get html from UI
                    this.reporthtmlrefresh = new Date().getTime();
                } else {
                    // waiting for next step from UI
                }
            };

            htmlIsRendered() {
                this.htmlContainerIsVisible = this.htmlIsVisible;
                // trigger of directive that get html from UI
                this.reporthtmlrefresh = new Date().getTime();
            };

            verifyHtmlSteps() {
                var result = false;

                if (!this.htmlStepsToRender) {
                    this.htmlStepsToRender = [];
                }

                if (!this.htmlStepsRendered) {
                    this.htmlStepsRendered = [];
                }

                if (!Lazy(this.htmlStepsToRender).some() && !Lazy(me.htmlStepsRendered).some()) {
                    result = true;
                }

                if (!result) {
                    var tempRendered = [];
                    Lazy(this.htmlStepsRendered)
                        .each((stepRendered) => {
                            if (Lazy(this.htmlStepsToRender).contains(stepRendered)) {
                                tempRendered.push(stepRendered);
                            }
                        });
                }

                return tempRendered.length === this.htmlStepsToRender.length;
            }
        }
    }

    reportDefinitionHtmlToDocDetail.$inject = injectParams;
    reportDefinitionHtmlToDocDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('reportDefinitionHtmlToDocDetail', reportDefinitionHtmlToDocDetail);
});