﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$timeout', '$injector', 'navigationService', 'audService', 'foundationService', 'contextService', 'rbacService'];
    function copySurveyOptionsDetail($q, $timeout, $injector, navigationService, audService, foundationService, contextService, rbacService) {

        var vm = this;
        nbsVm.call(vm, null, navigationService, contextService, $q, $injector, $timeout);
        
        var pParams = navigationService.stateParamsModal().parentParams;
        pParams = pParams ? pParams : navigationService.stateParams();

        vm.activeTabIndex = 0;
        vm.virtualCompaniesForUser = [];
        var vcSelected = [];

        // SurveyTemplate or eventually Survey
        vm.entityName = pParams.entityName;

        // beardcrumb information (only when view is called in wizard)
        if (pParams.isWizardMode) {
            var beardCrumbInfo = JSON.parse(pParams.beardCrumbInfo);
            Lazy(beardCrumbInfo.items).each((item) => { item.title = vm.translate(item.title); });
            vm.beardCrumbInfo = beardCrumbInfo;

            // navigation buttons visibility
            vm.setWizardButtonVisibility(pParams);
        } else {
            vm.beardCrumbInfo = {};
            vm.beardCrumbInfo.items = [];
            vm.beardCrumbInfo.isVisible = false;

            // navigation buttons visibility
            vm.setWizardButtonVisibility(pParams, false);
            vm.okBtnIsVisible = true;
            vm.cancelBtnIsVisible = true;
        }


        loadData(true);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getValidationSchemaInstancesPublish(forceReload));

            if (vm.entityName === 'SurveyTemplate') {
                funcsPromises.push(getVirtualCompaniesForUser(forceReload, contextService.virtualContext.XCentralUserId));
            }


            $q.all(funcsPromises)
                .then(function (results) {
                    vm.validationSchemas = results[0];

                    if (vm.entityName === 'SurveyTemplate') {
                        var virtualCompanies = results[1];

                        vm.virtualCompaniesForUser = Lazy(virtualCompanies)
                            .map((item) => {
                                return {
                                    VirtualCompanyId: item.VirtualCompanyId,
                                    VirtualCompanyCode: item.VirtualCompanyCode,
                                    VirtualCompanyName: item.VirtualCompanyName
                                };
                            })
                            .toArray();

                        var nbsGridDatacontext = new NbsAudGrid(vm.translate);
                        nbsGridDatacontext.configureGrid('virtualcompany', 'wizard');
                        nbsGridDatacontext.setSelectedIds([contextService.virtualContext.CurrentVirtualCompanyId]);
                        nbsGridDatacontext.setModalMode();
                        nbsGridDatacontext.setIsMultiselect();
                        nbsGridDatacontext.setDatasource(virtualCompanies, false);
                        vm.nbsGridDatacontext = nbsGridDatacontext;

                        //var currVirtualContext = Lazy(vm.virtualCompaniesForUser).findWhere({
                        //    VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId
                        //});

                        //if (currVirtualContext) {
                        //    vcSelected.push(currVirtualContext);
                        //}
                    }
                });
        }

        vm.gridSelection = function (args) {
            vcSelected = args.data;
        };

        // object binded to UI
        vm.copySurveyOptions = {
            CopyOptionsCheckedFromSource: !audService.surveyCreationCollector.CopyOptionsCheckedFromSource ? false : audService.surveyCreationCollector.CopyOptionsCheckedFromSource,
            CopyOptionWeightFromSource: !audService.surveyCreationCollector.CopyOptionWeightFromSource ? false : audService.surveyCreationCollector.CopyOptionWeightFromSource,
            CopyOptionValueFromSource: !audService.surveyCreationCollector.CopyOptionValueFromSource ? false : audService.surveyCreationCollector.CopyOptionValueFromSource,
            CopyOptionChoiceFromSource: !audService.surveyCreationCollector.CopyOptionChoiceFromSource ? false : audService.surveyCreationCollector.CopyOptionChoiceFromSource,
            ValidationSchemaId: !audService.surveyCreationCollector.ValidationSchemaId ? null : audService.surveyCreationCollector.ValidationSchemaId,
            SurveyCreationRequestCompileTypeId: !audService.surveyCreationCollector.SurveyCreationRequestCompileTypeId ? 0 : audService.surveyCreationCollector.SurveyCreationRequestCompileTypeId,
        };

        vm.modalNext = function () {
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalNext);
            navigationService.closeCurrentModal(modalResult);
        };

        vm.modalPrevious = function () {
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalPrevious);
            navigationService.closeCurrentModal(modalResult);
        };

        vm.modalOk = function () {
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalOk);
            navigationService.closeCurrentModal(modalResult);
        };

        vm.modalCancel = function () {
            if (pParams.isWizardMode) {
                navigationService.confirmExitWizard()
                    .then(function (result) {
                        if (result) {
                            navigationService.closeCurrentModal();
                        }
                    });
            } else {
                navigationService.closeCurrentModal();
            }
        };

        function getVirtualCompaniesForUser(forceReload, xcentralUserId) {
            var filter = { XCentralUserId: xcentralUserId };
            var defer = $q.defer();

            rbacService.getVirtualCompaniesForUser(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getValidationSchemaInstancesPublish(forceReload) {
            var filter = {
                EntityName: 'Act'
            };
            var defer = $q.defer();

            foundationService.getValidationSchemaInstancesPublish(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getModalResult(dialogResult) {
            // on modal close is returned object result
            var modalResult = new navigationService.modalResult();
            var vcIds = Lazy(vcSelected).pluck('VirtualCompanyId').toArray();

            if (vm.entityName === 'SurveyTemplate') {
                vm.copySurveyOptions.VirtualCompanyIdsSerialized = JSON.stringify(vcIds);
            }

            vm.copySurveyOptions.entityName = vm.entityName;

            modalResult.data = vm.copySurveyOptions;
            modalResult.sourceParams = pParams;
            modalResult.dialogResult = dialogResult;
            return modalResult;
        }
    }

    copySurveyOptionsDetail.$inject = injectParams;
    copySurveyOptionsDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('copySurveyOptionsDetail', copySurveyOptionsDetail);
});