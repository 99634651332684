﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {
    /// R-245-140-1
    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'vhreventService', 'audService', 'reportService', 'navigationService', 'checkStateChangeService', 'contextService', 'drbacService', 'rbacService', 'utilService'];
    var htmlToPdfSurveysExecutionSyntesisReportForm = function ($q, $scope, $timeout, $injector, foundationService, vhreventService, audService, reportService, navigationService, checkStateChangeService, contextService, drbacService, rbacService, utilService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        var checkStateChangeParams = { dirtyFlagIsDisabled: vm.isDirtyDisabled };
        checkStateChangeService.checkFormOnStateChange($scope, foundationService, checkStateChangeParams);

        // all form items are enabled
        vm.setIsEditingReport();

        var appStoreCode = 'aud';
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var surveyLifeStatusEnumParsed = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyLifeStatus, 'SurveyDto', vm.translate);
        var surveyLifeStatusObj = surveyLifeStatusEnumParsed.obj.normal.name_value;
        var expressionTypeEnum = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.ExpressionType, null, vm.translate).obj.normal.name_value;
        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;

        var dsCallback = function () { return vhreventService.getVhrEventTypes({ AppStoreBw: appStoreBw }, false); };
        var selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventType', viewTitle: vm.translate('SurveyDto.VhrEventTypeId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventType = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalVhrEventType.setOnSelectFnCallback(function () {
            if (vm.nbsSelectionGridWithModalVhrEventType.selectedIds) {
                vm.vhrEventFilter.VhrEventTypesId = vm.nbsSelectionGridWithModalVhrEventType.selectedIds;
            } else {
                vm.vhrEventFilter.VhrEventTypesId = [];
            }
        });

        dsCallback = function () { return vhreventService.getVhrEventGroups({ AppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventGroup', viewTitle: vm.translate('SurveyDto.VhrEventGroupId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventGroup = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalVhrEventGroup.setOnSelectFnCallback(function () {
            if (vm.nbsSelectionGridWithModalVhrEventGroup.selectedIds) {
                vm.vhrEventFilter.VhrEventGroupsId = vm.nbsSelectionGridWithModalVhrEventGroup.selectedIds;
            } else {
                vm.vhrEventFilter.VhrEventGroupsId = [];
            }
        });

        dsCallback = function () { return foundationService.getVhrTypes(false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrType', viewTitle: vm.translate('TargetDto.TargetTypeId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrType = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalVhrType.setOnSelectFnCallback(function () {
            if (vm.nbsSelectionGridWithModalVhrType.selectedIds) {
                vm.virtualHrFilter.VhrTypesId = vm.nbsSelectionGridWithModalVhrType.selectedIds;
            } else {
                vm.virtualHrFilter.VhrTypesId = [];
            }
        });

        dsCallback = function () { return foundationService.getVhrGroups(false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrGroup', viewTitle: vm.translate('TargetDto.TargetGroupId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrGroup = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalVhrGroup.setOnSelectFnCallback(function () {
            if (vm.nbsSelectionGridWithModalVhrGroup.selectedIds) {
                vm.virtualHrFilter.VhrGroupsId = vm.nbsSelectionGridWithModalVhrGroup.selectedIds;
            } else {
                vm.virtualHrFilter.VhrGroupsId = [];
            }
        });

        vm.nbsAuthorDrbacModalObj = new NbsAssigneeSelectorModal({
            translator: vm.translate,
            suspendDirty: vm.suspendDirty,
            navigationService: navigationService,
            contextUserForSelection: null,
            getServerVarFn: Nembus.Common.Library.server.getServerVar
        });
        vm.nbsAuthorDrbacModalObj.viewUsers();
        vm.nbsAuthorDrbacModalObj.setOnSelectFnCallback(function () {
            vm.surveyVhrEventFilter.CompilerUserPersonIds = vm.nbsAuthorDrbacModalObj.appUserMultiselectSelectedIds();
        });

        //var actPriorityEnumParsed = Nembus.Common.Library.util.parseEnum(actService.dataContext.enumTypes.ActPriority, 'Act', vm.translate);
        //var priorityObjTranslated = actPriorityEnumParsed.obj.translated.value_name;

        //var priorityObj = Nembus.Common.Library.util.parseEnum(actService.dataContext.enumTypes.ActPriority, null, vm.translate).obj.normal.name_value;
        var addEmptyActionAssignment = true;
        var stepsToRender = [];

        // init filters
        vm.reportFilter = {};
        vm.surveyVhrEventFilter = {
            SurveyCompileDateSystem1Expression: expressionTypeEnum.GreaterThanOrEqual,
            SurveyCompileDateSystem2Expression: expressionTypeEnum.LessThanOrEqual,
            ExcludeOnDemand: true
        };
        vm.surveyFilter = {
            IsArchived: null
        };
        vm.vhrEventFilter = {
            StartDate1Expression: expressionTypeEnum.GreaterThanOrEqual,
            StartDate2Expression: expressionTypeEnum.LessThanOrEqual,
            ModuleTargetId: moduleTargetId
        };
        vm.virtualHrFilter = {
            HaveToExpandVhrsWithAssetTree: true
        };


        // REFERS to 'reportDefinitionHtmlToDocDetail'
        vm.parentScope = $scope.$parent.vm;
        vm.parentScope.ribbonDatacontext.setRibbonStatusButton(vm.parentScope.ribbonDatacontext.Buttons.PrintReportPdf, true);
        vm.parentScope.ribbonDatacontext.refreshStatusButtons();
        vm.parentScope.initReportToDocHeader({ onSearchActionHtmlToDocFn: null, onCreateHtmlFn: createHtmlReport });

        selectionParams = { destModalKey: 'vhr', entityMode: 'Target', translateKey: 'Target', isMultiselect: true };
        vm.nbsSelectionGridWithModalTarget = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalTarget.setOnSelectFnCallback(function () {
            vm.virtualHrFilter.VirtualHumanResourcesId = [];
            if (vm.nbsSelectionGridWithModalTarget.selectedIds) {
                vm.virtualHrFilter.VirtualHumanResourcesId = vm.nbsSelectionGridWithModalTarget.selectedIds;
            }
        });

        vm.nbsActionDrbacModalObj = new NbsAssigneeSelectorModal({
            translator: vm.translate,
            suspendDirty: vm.suspendDirty,
            navigationService: navigationService,
            contextUserForSelection: null,
            getServerVarFn: Nembus.Common.Library.server.getServerVar
        });
        vm.nbsActionDrbacModalObj.viewUsers();
        vm.nbsActionDrbacModalObj.viewUserGroups();
        vm.nbsActionDrbacModalObj.setOnSelectFnCallback(function () {
            vm.vhrEventFilter.UserGroupAssignedIds = vm.nbsActionDrbacModalObj.drbacUserGroupMultiselectSelectedIds();
            vm.vhrEventFilter.AppUserAssignedIds = vm.nbsActionDrbacModalObj.appUserMultiselectSelectedIds();
        });

        // set title of current controller in report definition detail view
        navigationService.setControllerTitle();

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getGenericElementsVhrEventStatusColor(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.lifeStatusColors = [];
                    if (results[0]) {
                        vm.lifeStatusColorsObj = results[0];
                    }

                    reportService.currentReport.formEntityToValidate = vm.surveysListReportFormClientData;
                });
        }

        vm.htmlSteps = {
            SURVEY_ASSIGNMENT: 'surveyassignment-render'
        };

        function createHtmlReport() {
            var funcsPromises = [];
            funcsPromises.push(getSurveyDtos(true, vm.surveyFilter, vm.vhrEventFilter, vm.surveyVhrEventFilter, vm.virtualHrFilter));

            $q.all(funcsPromises)
                .then(function (results) {
                    var surveys = results[0];
                    parseReportHtmlDatasource(surveys);
                });
        }

        function getDayDiff(startDate, compareDate) {
            if (!startDate) {
                startDate = moment(Date.Now).toDate();
            }

            var a = moment(startDate);
            var b = moment(compareDate);
            return a.diff(b, 'days');
        }

        // prepare datasource for report in UI (to prepare Html)
        function parseReportHtmlDatasource(surveys) {
            setReportFilterData();

            stepsToRender = [];

            var countSurveys = surveys.length;
            var assignmentTypes = {
                User: 'User',
                UserGroup: 'UserGroup',
                NoAssignment: 'NoAssignment'
            };

            // object binded on UI
            vm.bindObject = {
                auditLifeStatus: {
                    toCompile: 0,
                    compiled: 0,
                    toCompilePercent: 0,
                    compiledPercent: 0,
                    createdPercent: 0,
                    runningPercent: 0,
                    reportedAsFinishedPercent: 0
                },
                surveys: {
                    table: {
                        // nuovo
                        createdNotExpired: 0,
                        createdExpiredLess7days: 0,
                        createdExpiredLess30days: 0,
                        createdExpiredLess60days: 0,
                        createdExpiredMore60days: 0,
                        // in corso
                        runningNotExpired: 0,
                        runningExpiredLess7days: 0,
                        runningExpiredLess30days: 0,
                        runningExpiredLess60days: 0,
                        runningExpiredMore60days: 0,
                        // in corso
                        reportedAsFinishedNotExpired: 0,
                        reportedAsFinishedExpiredLess7days: 0,
                        reportedAsFinishedExpiredLess30days: 0,
                        reportedAsFinishedExpiredLess60days: 0,
                        reportedAsFinishedExpiredMore60days: 0
                    },
                    assignments: [],
                    grouped: []
                }
            };

            // workflow status
            vm.bindObject.auditLifeStatus.createdCount = Lazy(surveys)
                .filter((survey) => { return survey.SurveyLifeStatusId === surveyLifeStatusObj.Created; })
                .toArray().length;

            vm.bindObject.auditLifeStatus.runningCount = Lazy(surveys)
                .filter((survey) => { return survey.SurveyLifeStatusId === surveyLifeStatusObj.Running; })
                .toArray().length;

            vm.bindObject.auditLifeStatus.reportedAsFinishedCount = Lazy(surveys)
                .filter((survey) => { return survey.SurveyLifeStatusId === surveyLifeStatusObj.ReportedAsFinished; })
                .toArray().length;

            vm.bindObject.auditLifeStatus.toCompileCount = vm.bindObject.auditLifeStatus.createdCount + vm.bindObject.auditLifeStatus.runningCount;
            vm.bindObject.auditLifeStatus.compiledCount = vm.bindObject.auditLifeStatus.reportedAsFinishedCount;

            vm.bindObject.auditLifeStatus.createdPercent = Nembus.Common.Library.util.getPercent(vm.bindObject.auditLifeStatus.createdCount, countSurveys);
            vm.bindObject.auditLifeStatus.runningPercent = Nembus.Common.Library.util.getPercent(vm.bindObject.auditLifeStatus.runningCount, countSurveys);
            vm.bindObject.auditLifeStatus.reportedAsFinishedPercent = Nembus.Common.Library.util.getPercent(vm.bindObject.auditLifeStatus.reportedAsFinishedCount, countSurveys);

            vm.bindObject.auditLifeStatus.toCompilePercent = Nembus.Common.Library.util.getPercent(vm.bindObject.auditLifeStatus.toCompileCount, countSurveys);
            vm.bindObject.auditLifeStatus.compiledPercent = Nembus.Common.Library.util.getPercent(vm.bindObject.auditLifeStatus.compiledCount, countSurveys);

            // create Pie chart
            createChart(vm.bindObject.auditLifeStatus);

            // action table data
            Lazy(surveys)
                .each(survey => {
                    if (survey.SurveyExpireDate) {
                        var days = 0;
                        if (survey.SurveyLifeStatusId === surveyLifeStatusObj.Created) {
                            days = getDayDiff(null, survey.SurveyExpireDate);
                            if (days <= 0) {
                                vm.bindObject.surveys.table.createdNotExpired += 1;
                            } else if (days >= 60) {
                                vm.bindObject.surveys.table.createdExpiredMore60days += 1;
                            } else if (days < 7) {
                                vm.bindObject.surveys.table.createdExpiredLess7days += 1;
                            } else if (days < 30) {
                                vm.bindObject.surveys.table.createdExpiredLess30days += 1;
                            } else if (days < 60) {
                                vm.bindObject.surveys.table.createdExpiredLess60days += 1;
                            }
                        } else if (survey.SurveyLifeStatusId === surveyLifeStatusObj.Running) {
                            days = getDayDiff(null, survey.SurveyExpireDate);
                            if (days <= 0) {
                                vm.bindObject.surveys.table.runningNotExpired += 1;
                            } else if (days >= 60) {
                                vm.bindObject.surveys.table.runningExpiredMore60days += 1;
                            } else if (days < 7) {
                                vm.bindObject.surveys.table.runningExpiredLess7days += 1;
                            } else if (days < 30) {
                                vm.bindObject.surveys.table.runningExpiredLess30days += 1;
                            } else if (days < 60) {
                                vm.bindObject.surveys.table.runningExpiredLess60days += 1;
                            }
                        } else if (survey.SurveyLifeStatusId === surveyLifeStatusObj.ReportedAsFinished) {
                            days = getDayDiff(survey.SurveyCompileDateSystem, survey.SurveyExpireDate);
                            if (days <= 0) {
                                vm.bindObject.surveys.table.reportedAsFinishedNotExpired += 1;
                            } else if (days >= 60) {
                                vm.bindObject.surveys.table.reportedAsFinishedExpiredMore60days += 1;
                            } else if (days < 7) {
                                vm.bindObject.surveys.table.reportedAsFinishedExpiredLess7days += 1;
                            } else if (days < 30) {
                                vm.bindObject.surveys.table.reportedAsFinishedExpiredLess30days += 1;
                            } else if (days < 60) {
                                vm.bindObject.surveys.table.reportedAsFinishedExpiredLess60days += 1;
                            }
                        }
                    }
                });

            // assignemnt surveys
            var allAssignmentSurveys = [];
            Lazy(surveys)
                .each((survey) => {
                    var userGroups = JSON.parse(Nembus.Common.Library.converter.decodeHtml(survey.UserGroupsAssignedSerialized));
                    var users = JSON.parse(Nembus.Common.Library.converter.decodeHtml(survey.UsersAssignedSerialized));

                    if (Lazy(userGroups).some() || Lazy(users).some()) {
                        Lazy(userGroups)
                            .each((userGroup) => {
                                userGroup.Kind = assignmentTypes.UserGroup;

                                var existingUserGroup = Lazy(allAssignmentSurveys).findWhere({ Value: userGroup.Value, Kind: userGroup.Kind });
                                if (!existingUserGroup) {
                                    userGroup = setSurveyCounts(userGroup, survey, countSurveys, surveyLifeStatusObj);
                                    allAssignmentSurveys.push(userGroup);
                                } else {
                                    existingUserGroup = setSurveyCounts(existingUserGroup, survey, countSurveys, surveyLifeStatusObj);
                                }
                            });

                        Lazy(users)
                            .each((user) => {
                                user.Kind = assignmentTypes.User;

                                var existingUser = Lazy(allAssignmentSurveys).findWhere({ Value: user.Value, Kind: user.Kind });
                                if (!existingUser) {
                                    user = setSurveyCounts(user, survey, countSurveys, surveyLifeStatusObj);

                                    allAssignmentSurveys.push(user);
                                } else {
                                    existingUser = setSurveyCounts(existingUser, survey, countSurveys, surveyLifeStatusObj);
                                }
                            });
                    } else {
                        if (addEmptyActionAssignment) {
                            var existingNoAssigned = Lazy(allAssignmentSurveys).findWhere({ Kind: assignmentTypes.NoAssignment });
                            if (!existingNoAssigned) {
                                var noAssigned = {
                                    Code: null,
                                    ExtraKey: null,
                                    Kind: assignmentTypes.NoAssignment,
                                    Name: ' - ',
                                    Value: null
                                };

                                noAssigned = setSurveyCounts(noAssigned, survey, countSurveys, surveyLifeStatusObj);

                                allAssignmentSurveys.push(noAssigned);
                            } else {
                                existingNoAssigned = setSurveyCounts(existingNoAssigned, survey, countSurveys, surveyLifeStatusObj);
                            }
                        }
                    }
                });

            //// bind to UI
            if (allAssignmentSurveys.length > 0) {
                stepsToRender.push(vm.htmlSteps.SURVEY_ASSIGNMENT);
                vm.bindObject.surveys.assignments = Lazy(allAssignmentSurveys).sortBy((el) => { return el.surveyCounts.percentReportedAsFinished; }, true).toArray();
            }

            // set steps to render before html retrieve from directive
            vm.parentScope.htmlToDocManager.setHtmlStepsToRender(stepsToRender);

            if (!vm.parentScope.htmlToDocManager.hasHtmlStepsToRender()) {
                vm.parentScope.htmlToDocManager.htmlIsRendered();
            }
        }

        function createChart(data) {
            var totalPercent = 100 - data.createdPercent - data.runningPercent - data.reportedAsFinishedPercent;
            var chartObj = {
                //Initializing Series
                series:
                    [
                        {
                            points: [
                                {
                                    x: vm.translate('SurveyDto.Created') + ' (' + data.createdCount.toString() + ')',
                                    y: data.createdPercent,
                                    text: data.createdCount.toString(),
                                    fill: vm.lifeStatusColorsObj.Created
                                },
                                {
                                    x: vm.translate('SurveyDto.Running') + ' (' + data.runningCount.toString() + ')',
                                    y: data.runningPercent,
                                    text: data.runningCount.toString(),
                                    fill: vm.lifeStatusColorsObj.Running
                                },
                                {
                                    x: vm.translate('SurveyDto.ReportedAsFinished') + ' (' + data.reportedAsFinishedCount.toString() + ')',
                                    y: data.reportedAsFinishedPercent,
                                    text: data.reportedAsFinishedCount.toString(),
                                    fill: vm.lifeStatusColorsObj.ReportedAsFinished
                                }
                            ],
                            marker:
                            {
                                dataLabel:
                                {
                                    visible: false,
                                    //shape: 'none',
                                    //connectorLine: { type: 'bezier', color: 'black' },
                                    //font: { size: '15px' },
                                    enableContrastColor: true
                                }
                            },
                            border: { width: 2, color: 'white' },
                            //name: 'Expenses',
                            type: 'pie',
                            enableAnimation: false,
                            //labelPosition: 'outsideExtended',
                            //enableSmartLabels: true,
                            startAngle: 0
                        }
                    ],
                //load: "loadTheme",
                //seriesRendering: "seriesRender",
                //title: { text: vm.translate('Status') },
                isResponsive: true,
                size: { height: "300" },
                legend: { position: "right", visible: true }
            };

            vm.chartobj = chartObj;
        }

        function setReportFilterData() {
            vm.reportFilterData = new NbsReportFilterData(vm.translate, contextService.virtualContext.cultureInfo, Nembus.Common.Library.converter.date.formatDate);

            // filter in report are visualized in current order 
            // Audit
            vm.reportFilterData.setDataByRangeDate('SurveyCompileDate', 'SurveyDto.SurveyCompileDate', vm.surveyVhrEventFilter.SurveyCompileDateSystem1, vm.surveyVhrEventFilter.SurveyCompileDateSystem2);
            vm.reportFilterData.setDataByRangeDate('SurveyExpireDate', 'SurveyDto.SurveyExpireDate', vm.vhrEventFilter.StartDate1, vm.vhrEventFilter.StartDate2);
            vm.reportFilterData.setDataByBoolean('IsArchived', 'SurveyDto.IsArchived', vm.surveyFilter.IsArchived);
            vm.reportFilterData.setDataBySelectionModal('VhrEventGroupId', 'SurveyDto.VhrEventGroupId', vm.nbsSelectionGridWithModalVhrEventGroup);
            vm.reportFilterData.setDataBySelectionModal('VhrEventTypeId', 'SurveyDto.VhrEventTypeId', vm.nbsSelectionGridWithModalVhrEventType);
            vm.reportFilterData.setDataByDrbacAuthSelectorModal('CompilerUserPersonId', 'SurveyVhrEvent.CompilerUserPersonId', vm.nbsAuthorDrbacModalObj);
            vm.reportFilterData.setDataByDrbacAuthSelectorModal('AudAssignee', 'AudAssignee', vm.nbsActionDrbacModalObj);

            // Target
            vm.reportFilterData.setDataBySelectionModal('TargetGroupId', 'TargetDto.TargetGroupId', vm.nbsSelectionGridWithModalVhrGroup);
            vm.reportFilterData.setDataBySelectionModal('TargetTypeId', 'TargetDto.TargetTypeId', vm.nbsSelectionGridWithModalVhrType);
            vm.reportFilterData.setDataBySelectionModal('TargetDto', 'TargetDto.TargetDto', vm.nbsSelectionGridWithModalTarget);
        }

        function setSurveyCounts(entity, survey, countTotal, surveyLifeStatus) {
            var surveyCounts = {
                count: 0,
                countCreated: 0,
                countRunning: 0,
                countReportedAsFinished: 0,
                percentCreated: 0,
                percentRunning: 0,
                percentReportedAsFinished: 0,
                percentCreatedTotal: 0,
                percentRunningTotal: 0,
                percentReportedAsFinishedTotal: 0
            };

            var stackedBar = {
                barCreatedPercent: 0,
                barRunningPercent: 0,
                barReportedAsFinishedPercent: 0,
                barRemainingPercent: 0
            };

            if (!entity.surveyCounts) {
                entity.surveyCounts = surveyCounts;
            }

            if (!entity.stackedBar) {
                entity.stackedBar = stackedBar;
            }

            entity.surveyCounts.count += 1;

            if (survey.SurveyLifeStatusId === surveyLifeStatus.Created) {
                entity.surveyCounts.countCreated += 1;
            } else if (survey.SurveyLifeStatusId === surveyLifeStatus.Running) {
                entity.surveyCounts.countRunning += 1;
            } else if (survey.SurveyLifeStatusId === surveyLifeStatus.ReportedAsFinished) {
                entity.surveyCounts.countReportedAsFinished += 1;
            }

            entity.surveyCounts.percentTotal = Nembus.Common.Library.util.getPercent(entity.surveyCounts.count, countTotal);
            entity.surveyCounts.percentCreated = Nembus.Common.Library.util.getPercent(entity.surveyCounts.countCreated, entity.surveyCounts.count);
            entity.surveyCounts.percentRunning = Nembus.Common.Library.util.getPercent(entity.surveyCounts.countRunning, entity.surveyCounts.count);
            entity.surveyCounts.percentReportedAsFinished = Nembus.Common.Library.util.getPercent(entity.surveyCounts.countReportedAsFinished, entity.surveyCounts.count);
            entity.surveyCounts.percentCreatedTotal = Nembus.Common.Library.util.getPercent(entity.surveyCounts.countRunning, countTotal);
            entity.surveyCounts.percentRunningTotal = Nembus.Common.Library.util.getPercent(entity.surveyCounts.countCreated, countTotal);
            entity.surveyCounts.percentReportedAsFinishedTotal = Nembus.Common.Library.util.getPercent(entity.surveyCounts.countReportedAsFinished, countTotal);

            // calculate stacked bar (4 bars percents)
            //if (entity.surveyCounts.percentReportedAsFinished > 0) {
            //    entity.stackedBar.barCreatedPercent = Number(entity.surveyCounts.percentCreated / 100 * entity.surveyCounts.percentReportedAsFinished);
            //}
            //if (entity.surveyCounts.percentReportedAsFinished > 0) {
            //    entity.stackedBar.barRunningPercent = Number(entity.surveyCounts.percentRunning / 100 * entity.surveyCounts.percentReportedAsFinished);
            //}
            //if (entity.surveyCounts.percentReportedAsFinished > 0) {
            //    entity.stackedBar.barReportedAsFinishedPercent = Number(entity.surveyCounts.percentReportedAsFinished / 100 * entity.surveyCounts.percentReportedAsFinished);
            //}
            //entity.stackedBar.barRemainingPercent = 100 - entity.stackedBar.barRunningPercent - entity.stackedBar.barCreatedPercent - entity.stackedBar.barReportedAsFinishedPercent;

            entity.stackedBar.barCreatedPercent = entity.surveyCounts.percentCreated;
            entity.stackedBar.barRunningPercent = entity.surveyCounts.percentRunning;
            entity.stackedBar.barReportedAsFinishedPercent = entity.surveyCounts.percentReportedAsFinished;

            return entity;
        }

        function getSurveyDtos(forceReload, surveyFilter, vhrEventFilter, surveyVhrEventFilter, virtualHrFilter) {
            var filter = {
                surveyFilter: surveyFilter,
                vhrEventFilter: vhrEventFilter,
                surveyVhrEventFilter: surveyVhrEventFilter,
                surveySectionFilter: {},
                surveyQuestionFilter: {},
                surveyQuestionOptionFilter: {},
                virtualHrFilter: virtualHrFilter
            };
            var defer = $q.defer();

            audService.getSurveyDtos(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getGenericElementsVhrEventStatusColor(forceReload) {
            var filter = { GenericElementCode: 'Configurations.Surveys.LifeStatusColors' };
            var defer = $q.defer();

            utilService.getGenericElements(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results[0].PairValuesObject);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    htmlToPdfSurveysExecutionSyntesisReportForm.$inject = injectParams;
    htmlToPdfSurveysExecutionSyntesisReportForm.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('htmlToPdfSurveysExecutionSyntesisReportForm', htmlToPdfSurveysExecutionSyntesisReportForm);
});
