﻿'use strict';
define([], function () {

    var injectParams = ['$q', '$timeout', 'foundationService'];
    var nbsVhrSelectionComponent = function ($q, $timeout, foundationService) {
        var vm = this;
        var pGrid = null;         // ej objects
        var pTreeGrid = null;
        var pGridFilter = {
            assetTreeFilter: {}
        };
        var pTreeGridFilter = {};
        var pDataContext = null;
        var pTreeGridDM = null;
        var pGridDM = null;
        var pIsMultiselect = false;
        var pResourceTypeEnum = null;
        var pColumnWithCheckbox = 'VirtualCode';
        var pTreeGridEvents = null;
        var pSelectedEntities = []; // VirtualEquipment, HrBucket, TargetDto
        var pEntities = []; // VirtualEquipment, HrBucket, TargetDto
        var ENTITY_HRBUCKET = 'HrBucket';           // can have children
        var ENTITY_ASSET = 'VirtualEquipment';      // can't have children, only parents of type HrBucket
        var pEntityMode = null;
        var pTranslateKey = null;
        var pVhrStatusEnum = null;
        var pPreloadData = false;
        var nbsUiOptionParser = null;

        vm.viewUiSelector = null;
        vm.nbsDataPrivate = {};
        vm.hasHierarchyConfig = false;

        vm.$onChanges = function (changes) {
            if (changes.nbsDataContext && changes.nbsDataContext.currentValue) {
                nbsUiOptionParser = new NbsUiOptionParser(vm.uiContext.translate);

                pDataContext = changes.nbsDataContext.currentValue;

                pTreeGridFilter = {};

                vm.viewUiSelector = pDataContext.viewUiSelector;
                if (pDataContext.initData.grid.params.isMultiselect !== null && pDataContext.initData.grid.params.isMultiselect !== undefined) {
                    pIsMultiselect = pDataContext.initData.grid.params.isMultiselect;
                }

                pTreeGridEvents = pDataContext.initData.treeGrid ? pDataContext.initData.treeGrid.events : {};
                pResourceTypeEnum = Nembus.Common.Library.util.parseEnum(foundationService.dataContext.enumTypes.ResourceType, 'VirtualHumanResource', vm.uiContext.translate);

                vm.modeUI = pDataContext.initData.modeUi;

                pEntityMode = pDataContext.entityMode;
                pTranslateKey = pDataContext.translateKey;
                pVhrStatusEnum = Nembus.Common.Library.util.parseEnum(foundationService.dataContext.enumTypes.VhrStatus, null, vm.uiContext.translate).obj.normal.name_value;
                pPreloadData = pDataContext.preloadData;

                pGridFilter.assetTreeFilter.VhrStatusId = pVhrStatusEnum.Enabled;

                vm.hasHierarchyConfig = pDataContext.hasHierarchyConfig;

                switch (pTranslateKey) {
                    case 'AssetTree':
                        vm.vhrGroupTranslation = vm.uiContext.translate('VhrSelectionModal.VhrSelectionModalTargetGroup');
                        vm.vhrTypeTranslation = vm.uiContext.translate('VhrSelectionModal.VhrSelectionModalTargetType');
                        break;
                    case 'VirtualEquipment':
                        vm.vhrGroupTranslation = vm.uiContext.translate('VhrSelectionModal.VhrSelectionModalVirtualEquipmentGroup');
                        vm.vhrTypeTranslation = vm.uiContext.translate('VhrSelectionModal.VhrSelectionModalVirtualEquipmentType');
                        break;
                    case 'Target':
                        vm.vhrGroupTranslation = vm.uiContext.translate('VhrSelectionModal.VhrSelectionModalTargetGroup');
                        vm.vhrTypeTranslation = vm.uiContext.translate('VhrSelectionModal.VhrSelectionModalTargetType');
                        break;
                }

                loadData(false);
            }
        };

        vm.setGridMode = function (mode) {
            vm.modeUI = mode;
            createEjObj();
        };

        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getVhrTypes(forceReload));
            funcsPromises.push(getVhrGroups(forceReload));
            if (pPreloadData === true) {
                funcsPromises.push(getAssetTrees(forceReload));
            }

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.nbsDataPrivate.vhrtypes = nbsUiOptionParser.getUiOptionsByKey(results[0], 'VhrType');
                    vm.nbsDataPrivate.vhrgroups = nbsUiOptionParser.getUiOptionsByKey(results[1], 'VhrGroup');

                    if (pPreloadData === true) {
                        pEntities = results[2];
                    } else {
                        pEntities = [];
                    }

                    addSelectionArray(pDataContext.selectedIds);

                    raiseResult();
                    createEjObj();
                });
        }

        function getEntitiesMapped(entities) {
            var result = [];

            switch (pEntityMode) {
                case 'AssetTree':
                    Lazy(entities)
                        .each((item) => {
                            item.nbs_id = item.VirtualHumanResourceId;
                            item.nbs_code = item.VirtualCode;
                            item.nbs_name = item.VirtualName;
                            item.nbs_discriminator = item.AssetTreeEntityType;
                        });

                    result = entities;
                    break;
                case 'Target':
                    Lazy(entities)
                        .each((item) => {
                            item.nbs_id = item.VirtualHumanResourceId;
                            item.nbs_code = item.VirtualCode;
                            item.nbs_name = item.VirtualName;
                            item.nbs_discriminator = 'Target';
                        });

                    result = entities;
                    break;
            }

            return result;
        }
        function getAssetTreeChildren(ids, returnOnlyAsset, returnCurrent) {
            var result = [];
            Lazy(ids)
                .each((id) => {
                    var currenAssetTree = Lazy(pEntities).findWhere({ VirtualHumanResourceId: id });
                    if (currenAssetTree) {
                        var children = getAssetTreeSubTree(currenAssetTree, returnOnlyAsset, returnCurrent);
                        Lazy(children)
                            .each((item) => {
                                if (!Lazy(result).where({ VirtualHumanResourceId: item.VirtualHumanResourceId }).some()) {
                                    result.push(item);
                                }
                            });
                    }
                });
            return result;
        }

        function getAssetTreeSubTree(currAssetTree, returnOnlyAsset, returnCurrent) {
            if (!currAssetTree) {
                return [];
            }

            var result = [];
            if (returnCurrent) {
                result.push(currAssetTree);
            }

            if (currAssetTree.ResourceTypeId === pResourceTypeEnum.obj.normal.name_value.Asset) {
                return result;
            }

            var parents = [currAssetTree];
            var descendants = [];
            do {
                var parentIds = Lazy(parents).pluck('VirtualHumanResourceId').toArray();
                descendants = Lazy(pEntities)
                    .filter((item) => { return item.ParentId && Lazy(parentIds).contains(item.ParentId); })
                    .toArray();

                Lazy(descendants).each((item) => { result.push(item); });

                parents = descendants;
            }
            while (Lazy(descendants).some());

            if (!returnOnlyAsset) {
                return result;
            } else {
                return Lazy(result).where({ ResourceTypeId: pResourceTypeEnum.obj.normal.name_value.Asset }).toArray();
            }

        }

        function getVhrTypes(forceReload) {
            var filter = {};
            var defer = $q.defer();

            foundationService.getVhrTypes(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getVhrGroups(forceReload) {
            var filter = {};
            var defer = $q.defer();

            foundationService.getVhrGroups(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        }

        vm.onDdMsVhrTypes = function (options) {
            pGridFilter.assetTreeFilter.VhrTypesId = Lazy(options).pluck('optionValue').toArray();
            refreshGrid();
        };
        vm.onDdMsVhrGroups = function (options) {
            pGridFilter.assetTreeFilter.VhrGroupsId = Lazy(options).pluck('optionValue').toArray();
            refreshGrid();
        };

        function refreshGrid() {
            $timeout(() => {
                vm.forceDatamanagerFilter = {
                    refreshKey: new Date().getTime(),
                    filter: pGridFilter
                }
            });
        }

        function destroyTreeGridObject() {
            var containerName = 'nbs-treegrid-container';
            var gridObj = pTreeGrid;

            if (gridObj && containerName) {
                $('div[nbs-container-name="' + containerName + '"]').attr('id', containerName);
                gridObj.destroy();
            }
        }

        function createEjObj() {
            var widthObj = $('#nbs-grids-container').width();
            $('#nbs-treegrid-container').width(widthObj);

            if (vm.modeUI === 'Grid') {
                if (!pGrid) {
                    switch (pEntityMode) {
                        case 'AssetTree':
                            createGrid('modal-assettree');
                            break;
                        case 'Target':
                            createGrid('modal-target');
                            break;
                    }
                } else {
                    pGrid.refreshContent();
                }
            } else if (vm.modeUI === 'TreeGrid') {
                pTreeGridFilter.IsRoot = true;
                pTreeGridFilter.ParentId = null;

                destroyTreeGridObject();
                $timeout(() => {
                    createTreeGrid('nbs-treegrid-container');
                }, 100);
            }
        }

        function createGrid(gridMode) {
            var nbsGridDatacontext = new NbsGrid(vm.uiContext.translate);
            nbsGridDatacontext.configureGrid('assettree', gridMode);
            nbsGridDatacontext.allowSearching = true;
            if (pIsMultiselect) {
                nbsGridDatacontext.setIsMultiselect();
            }
            nbsGridDatacontext.setModalMode();
            nbsGridDatacontext.selectedIds = Lazy(pDataContext.selectedIds).toArray(); // to remove reference
            nbsGridDatacontext.setDatasource([], false); // fake datasource, only to create grid
            vm.nbsGridDatacontext = nbsGridDatacontext;

            var supportData = { translate: vm.uiContext.translate };
            pGridDM = foundationService.getAssetTreeDataManager(supportData, pGridFilter, pGridDM);
            nbsGridDatacontext.setDatasource(pGridDM, true);
        }

        vm.rowSelected = function (args) {
            if (!pIsMultiselect) {
                resetSelection();
                addSelection(args.data.VirtualHumanResourceId);
            } else {
                if (Array.isArray(args.data)) {
                    var ids = Lazy(args.data).pluck('VirtualHumanResourceId').toArray();
                    addSelectionArray(ids);
                } else {
                    addSelection(args.data.VirtualHumanResourceId);
                }
            }
            raiseResult();
        }

        vm.rowDeselected = function (args) {
            if (!pIsMultiselect) {
                resetSelection();
            } else {
                if (Array.isArray(args.data)) {
                    Lazy(args.data)
                        .each((item) => {
                            removeSelection(item.VirtualHumanResourceId);
                        });
                } else {
                    removeSelection(args.data.VirtualHumanResourceId);
                }
            }
            raiseResult();
        }

        vm.queryCellInfo = function (args) {
            if (args.data.AssetTreeEntityType === ENTITY_HRBUCKET) {
                args.cell.innerHTML = '<b>' + args.cell.innerHTML + '</b>';
            }
        }

        function resetSelection() {
            pSelectedEntities = [];
        }

        function addSelectionArray(vhrIds) {
            Lazy(vhrIds)
                .each((vhrId) => {
                    addSelection(vhrId);
                });
        }

        function addSelection(vhrId) {
            if (!vhrId) {
                return;
            }

            var current = Lazy(pEntities).findWhere({ VirtualHumanResourceId: vhrId });
            if (current && !Lazy(pSelectedEntities).where({ VirtualHumanResourceId: current.VirtualHumanResourceId }).some()) {
                pSelectedEntities.push(current);
            }
        }

        function removeSelection(vhrId) {
            if (!vhrId) {
                return;
            }

            var current = Lazy(pEntities).findWhere({ VirtualHumanResourceId: vhrId });
            if (current) {
                pSelectedEntities = Lazy(pSelectedEntities).filter((item) => { return item.VirtualHumanResourceId !== current.VirtualHumanResourceId; }).toArray();
            }
        }

        function createTreeGrid(containerName) {
            var gridId = "nbstreegrid" + NbsGuidManager.newGuidReplaced();
            var newContainerId = 'nbsGridContainer_' + gridId;

            var ejTreeGrid = {};
            ejTreeGrid.gridId = gridId;
            ejTreeGrid.containerId = newContainerId;
            ejTreeGrid.dataSource = null;
            ejTreeGrid.locale = 'it-IT';
            ejTreeGrid.allowPaging = false;
            ejTreeGrid.allowSorting = true;
            ejTreeGrid.pageSettings = { pageSize: 10 };
            ejTreeGrid.allowSelection = true;
            ejTreeGrid.allowFiltering = false;
            ejTreeGrid.filterSettings = { enableCaseSensitivity: false, filterType: "excel" };
            ejTreeGrid.allowGrouping = false;
            ejTreeGrid.allowSearching = true;
            ejTreeGrid.isResponsive = true;
            ejTreeGrid.adaptToPage = true;
            ejTreeGrid.allowColumnResize = true;
            ejTreeGrid.columns = [];
            ejTreeGrid.enableAltRow = true;
            ejTreeGrid.allowScrolling = true;
            ejTreeGrid.treeColumnIndex = 1;                     // where expand node is visible
            ejTreeGrid.idMapping = "VirtualHumanResourceId";
            ejTreeGrid.parentIdMapping = "ParentId";
            ejTreeGrid.hasChildMapping = "HasChild";
            ejTreeGrid.enableLoadOnDemand = true;
            ejTreeGrid.toolbarSettings = { showToolbar: false, toolbarItems: [ej.Grid.ToolBarItems.Search] };

            // set to container custom id (in case of many grids like boards)
            $('#' + containerName).attr('id', newContainerId);
            $('#' + newContainerId).parent('div').attr('style', 'display:inline-block;');
            $('#' + newContainerId).find('div').attr('id', ejTreeGrid.gridId);

            if (pIsMultiselect === true) {
                ejTreeGrid.selectionSettings = {
                    selectionType: ej.TreeGrid.SelectionType.Checkbox,
                    selectionMode: ej.TreeGrid.SelectionMode.Row,
                    enableSelectAll: false,
                    enableHierarchySelection: false
                };
            }

            var supportData = { translate: vm.uiContext.translate };
            pTreeGridDM = foundationService.getAssetTreegridDataManager(supportData, pTreeGridFilter, pTreeGridDM);
            ejTreeGrid.dataSource = pTreeGridDM.dmgrid;

            // entity grid: AssetTree
            ejTreeGrid.columns.push({ field: 'VirtualHumanResourceId', headerText: 'Id', textAlign: ej.TextAlign.Left, tooltip: '{{:value}}', clipMode: ej.Grid.ClipMode.EllipsisWithTooltip, displayAsCheckbox: false });
            ejTreeGrid.columns.push({ field: 'VirtualCode', headerText: vm.uiContext.translate('AssetTreeDto.VirtualCode'), textAlign: ej.TextAlign.Left, tooltip: '{{:value}}', clipMode: ej.Grid.ClipMode.EllipsisWithTooltip, displayAsCheckbox: false, showCheckbox: pIsMultiselect });
            ejTreeGrid.columns.push({ field: 'VirtualName', headerText: vm.uiContext.translate('AssetTreeDto.VirtualEquipmentProducer'), textAlign: ej.TextAlign.Left, tooltip: '{{:value}}', clipMode: ej.Grid.ClipMode.EllipsisWithTooltip, displayAsCheckbox: false });
            ejTreeGrid.columns.push({ field: 'VirtualEquipmentProducer', headerText: vm.uiContext.translate('AssetTreeDto.VirtualEquipmentProducer'), textAlign: ej.TextAlign.Left, tooltip: '{{:value}}', clipMode: ej.Grid.ClipMode.EllipsisWithTooltip, displayAsCheckbox: false });
            ejTreeGrid.columns.push({ field: 'VirtualEquipmentModel', headerText: vm.uiContext.translate('AssetTreeDto.VirtualEquipmentModel'), textAlign: ej.TextAlign.Left, tooltip: '{{:value}}', clipMode: ej.Grid.ClipMode.EllipsisWithTooltip, displayAsCheckbox: false });
            ejTreeGrid.columns.push({ field: 'ResourceTypeName', headerText: vm.uiContext.translate('AssetTreeDto.ResourceTypeId'), textAlign: ej.TextAlign.Left, tooltip: '{{:value}}', clipMode: ej.Grid.ClipMode.EllipsisWithTooltip, displayAsCheckbox: false });

            // custom settings 
            ejTreeGrid.recordDoubleClick = function (args) {
                if (pTreeGridEvents && pTreeGridEvents.rowDblClick) {
                    pTreeGridEvents.rowDblClick(args);
                }
            };
            ejTreeGrid.expanding = function (args) {
                pTreeGridFilter.IsRoot = null;
                pTreeGridFilter.ResourceTypeId = null;
                pTreeGridFilter.ParentId = args.data.VirtualHumanResourceId;
            };
            ejTreeGrid.queryCellInfo = function (args) {
                if (args.column.field === pColumnWithCheckbox) {
                    if (!Lazy(pSelectedEntities).some()) {
                        return;
                    }

                    var status = getAssetTreeStatusUI(args.data);
                    if (status === 'checked') {
                        $(args.cellElement).find('.e-checkbox').addClass('e-check').addClass('e-checkmark');
                        args.data.checkboxState = 'checked';
                    } else if (status === 'undeterminated') {
                        $(args.cellElement).find('.e-checkbox').addClass('e-stop').removeClass('e-checkmark');
                        args.data.checkboxState = 'undeterminated';
                    }
                }
            };
            ejTreeGrid.rowSelected = function (args) {
                if (!pIsMultiselect) {
                    resetSelection();
                    addSelection(args.data.VirtualHumanResourceId);
                } else {
                    if (args.data.checkboxState === 'checked') {
                        addSelection(args.data.VirtualHumanResourceId);
                    } else {
                        removeSelection(args.data.VirtualHumanResourceId);
                    }
                }
                raiseResult();
            };

            var container = $('#' + ejTreeGrid.containerId);
            $('#' + ejTreeGrid.gridId).width(container.width());
            $("#" + ejTreeGrid.gridId).ejTreeGrid(ejTreeGrid);
            pTreeGrid = $("#" + ejTreeGrid.gridId).data('ejTreeGrid');
        }

        function isVhrSelected(vhr) {
            return Lazy(pSelectedEntities).where({ VirtualHumanResourceId: vhr.VirtualHumanResourceId }).some();
        }

        // returns 'checked', 'undeterminated',...
        function getAssetTreeStatusUI(assetTree) {
            var status = null;

            if (isVhrSelected(assetTree)) {
                // is directly selected
                status = 'checked';
            } else {
                if (assetTree.AssetTreeEntityType === ENTITY_HRBUCKET) {
                    // Bucket
                    var currChildren = Lazy(getAssetTreeChildren([assetTree.VirtualHumanResourceId], false, false)).toArray();
                    if (currChildren.length > 0) {
                        var selectedIds = Lazy(pSelectedEntities).pluck('VirtualHumanResourceId').toArray();
                        var hasChildrenSelected = Lazy(currChildren).filter((child) => { return Lazy(selectedIds).contains(child.VirtualHumanResourceId); }).some();
                        if (hasChildrenSelected) {
                            // contains something selected
                            status = 'undeterminated';
                        }
                    }
                }
            }

            return status;
        }

        function getAssetTrees(forceReload) {
            var filter = {
                VhrStatusId: pVhrStatusEnum.Enabled
            };
            var defer = $q.defer();

            foundationService.getAssetTrees(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        }

        function raiseResult() {
            $timeout(() => {
                var result = getEntitiesMapped(pSelectedEntities);
                vm.nbsGridItemSelectionEvent({ items: result });
            }, 0);
        }
    };

    nbsVhrSelectionComponent.$inject = injectParams;
    angular.module('nbs.rds.module').component('nbsVhrSelection', {
        templateUrl: '/rds/common/components/nbsVhrSelectionComponent/_?c=' + window.codeCache(),
        controller: nbsVhrSelectionComponent,
        controllerAs: 'vm',
        bindings: {
            nbsDataContext: "<",
            uiContext: "<",
            // events
            nbsGridItemSelectionEvent: "&"
        }
    });
});