﻿'use strict';

define([], function () {

    var nbsdirectives = angular.module('nbs.common.directives', ['ui.bootstrap']);

    //login directive for remember auth
    nbsdirectives.directive('nbsRemember', [function () {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                scope.data.remember = true;
            }
        };
    }]);

    //login directive for remember auth
    nbsdirectives.directive('nbsQrcodeDirective', [function () {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                if (attrs && attrs.nbsQrcodeDirective) {
                    var nbsQrcodeData = JSON.parse(attrs.nbsQrcodeDirective);
                    if (nbsQrcodeData.service && nbsQrcodeData.bindProperty && nbsQrcodeData.content) {
                        var controller = scope.vm;
                        var nbsQrCode = new NbsQrCode();
                        controller[nbsQrcodeData.bindProperty] = nbsQrCode.mobileAppConfigDs(nbsQrcodeData.service, nbsQrcodeData.content, 200, 200);
                    }                    
                }
            }
        };
    }]);

    //BOOTSTRAP UI - click with confirmation before perform action
    nbsdirectives.directive('nbsReallyClick', ['$uibModal',
        function ($uibModal) {

            return {
                restrict: 'A',
                scope: {
                    nbsReallyClick: "&",
                    item: "="
                },
                link: function (scope, element, attrs) {
                    element.bind('click', function () {
                        var message = attrs.nbsReallyMessage || "Proseguire ?";

                        /*
                        //This works
                        if (message && confirm(message)) {
                          scope.$apply(attrs.ngReallyClick);
                        }
                        //*/

                        //*This doesn't works
                        var modalHeader = '<div class="modal-header" draggable><h3 class="modal-title">' + $TG('ModalWarning') + '</h3></div>';
                        var modalHtml = modalHeader + '<div class="modal-body">' + message + '</div>';
                        modalHtml += '<div class="modal-footer"><button class="btn btn-primary" ng-click="ok()">' + $TG('ModalButtonOk') + '</button><button class="btn btn-void" ng-click="cancel()">' + $TG('ModalButtonCancel') + '</button></div>';

                        var modalInstance = $uibModal.open({
                            template: modalHtml,
                            controller: 'ModalInstanceCtrl'
                        });

                        modalInstance.result.then(function () {
                            scope.nbsReallyClick({ item: scope.item }); //raise an error : $digest already in progress
                        }, function () {
                            //Modal dismissed
                        });
                        //*/

                    });

                }
            };
        }]);

    //switch application theme runtime
    nbsdirectives.directive('nbsThemes', function () {
        return {
            scope: {
                nbstheme: '=nbstheme'
            },
            restrict: 'AE',
            replace: false,
            template: '',
            link: function (scope, elem, attrs) {
                scope.$watch('nbstheme', function (theme) {
                    if (theme) {
                        Nembus.Common.Library.cms.setTheme(theme);
                    }                    
                });
            }
        };
    });

    //PT12H30M
    //PT9H3M
    nbsdirectives.directive('nbsTimespan', function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            //scope: {
            //    nbsTimespanMode: '=nbsTimespanMode'
            //},
            link: function (scope, element, attr, ngModel) {
                if (element) {
                    var tds = $(element).find('tr.text-center').find('td');
                    if (tds.length > 0) {
                        for (var i = 0; i < tds.length; i++) {
                            if (tds[i].innerHTML === '&nbsp;') {
                                tds[i].innerHTML = '';
                            }
                        }                       
                    }
                }


                function fromUser(date) {
                    if (attr.nbsTimespanMode && attr.nbsTimespanMode.toLowerCase() === 'date') {
                        return Nembus.Common.Library.converter.timespan.dateToJson(date);
                    }
                    else {
                        return Nembus.Common.Library.converter.timespan.stringToJson(date);
                    }

                }

                function toUser(text) {
                    if (attr.nbsTimespanMode && attr.nbsTimespanMode.toLowerCase() === 'date') {
                        return Nembus.Common.Library.converter.timespan.jsonToDate(text);
                    }
                    else {
                        return Nembus.Common.Library.converter.timespan.jsonToString(text);
                    }
                }
                ngModel.$parsers.push(fromUser);
                ngModel.$formatters.push(toUser);
            }
        };
    });

    nbsdirectives.directive('nbsDatepicker', function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attr, ngModel) {
                function fromUser(text) {
                    if (text) {
                        if (Object.prototype.toString.call(text) === '[object Date]') {
                            var date = new Date(Date.UTC(text.getFullYear(), text.getMonth(), text.getDate(), 0, 0, 0));
                            return date;
                        }
                    }
                }

                function toUser(text) {
                    if (text) {
                        if (Object.prototype.toString.call(text) === '[object Date]') {
                            //text.setTime(text.getTime() + text.getTimezoneOffset() * 60000);
                            return text;
                        }
                    }
                }

                ngModel.$parsers.push(fromUser);
                ngModel.$formatters.push(toUser);
            }
        };
    });

    nbsdirectives.directive('nbsEjSplitter', function () {
        return {
            restrict: 'A',
            link: function (scope, element, attr) {
                // Directive for EjSplitter
                // Fixs a bug that hide grid in splitter panel

                $('#' + attr.id).ejSplitter({
                    create: function (args) {
                        setTimeout(function () {
                            $('#' + attr.id).data('ejSplitter').refresh();
                        }, 1);
                    },
                    properties: [{ paneSize: '40%' }, { paneSize: '60%' }]
                });
            }
        };
    });

    nbsdirectives.directive('nbsDecimal', ['$locale', function ($locale) {
        var locale = $locale;
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attr, ngModel) {
                var tmp = '';
                var numDigitsFromUser = 0;

                function fromUser(text) {
                    //var xx = locale.NUMBER_FORMATS.DECIMAL_SEP;
                    //var yy = locale.NUMBER_FORMATS.CURRENCY_SYM;
                    //var zz = locale.NUMBER_FORMATS.GROUP_SEP;
                    //to switch locale dynamically load local js Assets/angular/ngLocale/angular-locale_it-it.js
                    numDigitsFromUser = 0;
                    var digit = 2;
                    //format "d0","d1" etc..where number after d is number of whole 
                    if (attr.nbsDecimal) {
                        digit = parseInt(attr.nbsDecimal.substring(1, attr.nbsDecimal.length));
                    }
                    if (text.indexOf('.') > 0) {
                        tmp = text.replace('.', ',');
                    }
                    else {
                        tmp = text;
                    }

                    if (tmp.indexOf(locale.NUMBER_FORMATS.DECIMAL_SEP) >= 0) {
                        numDigitsFromUser = tmp.length - tmp.indexOf(locale.NUMBER_FORMATS.DECIMAL_SEP) - 1;
                    }

                    var result = text.replace(locale.NUMBER_FORMATS.DECIMAL_SEP, '.');
                    return result;
                }

                function toUser(text) {
                    var digit = 2;
                    
                    //format "d0","d1" etc..where number after d is number of whole 
                    if (attr.nbsDecimal) {
                        digit = parseInt(attr.nbsDecimal.substring(1, attr.nbsDecimal.length));
                    }

                    if (text !== undefined && text !== null) {
                        var nTxt = text.toFixed(numDigitsFromUser);

                        if (nTxt.indexOf('.') > 0) {
                            var nOfDigit = nTxt.length - nTxt.indexOf('.') - 1;
                            if (nOfDigit > digit) {
                                nOfDigit = digit;
                            }
                            if (nOfDigit < 0) {
                                nOfDigit = 0;
                            }
                            return (text.toFixed(nOfDigit) + '').replace('.', locale.NUMBER_FORMATS.DECIMAL_SEP);
                        }
                        else {
                            if (tmp === '') {
                                nTxt = text.toFixed(digit);
                            }
                            else if (tmp.indexOf(locale.NUMBER_FORMATS.DECIMAL_SEP) + 1 === tmp.length) {
                                return nTxt + locale.NUMBER_FORMATS.DECIMAL_SEP;
                            }
                            else if (tmp.indexOf(locale.NUMBER_FORMATS.DECIMAL_SEP) > 0) {
                                var numDigit = tmp.length - tmp.indexOf(locale.NUMBER_FORMATS.DECIMAL_SEP) - 1;
                                if (numDigit > digit) {
                                    numDigit = digit;
                                }
                                nTxt = text.toFixed(numDigit);
                            }

                            return (nTxt + '').replace('.', locale.NUMBER_FORMATS.DECIMAL_SEP);

                        }
                    }
                }
                ngModel.$parsers.push(fromUser);
                ngModel.$formatters.push(toUser);
            }
        };
    }]);

    nbsdirectives.directive('nbsHtmlDecode', function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attr, ngModel) {

                function fromUser(text) {
                    return text;
                }

                function toUser(text) {
                    if (!text) {
                        text = '';
                    }

                    text = text.toString();

                    // in case of span set innerHtml
                    var e = Lazy(element).first();
                    if (e && e.tagName.toLowerCase() === 'span') {
                        e.innerHTML = text;
                    }

                    return Nembus.Common.Library.converter.decodeHtml(text);
                }
                ngModel.$parsers.push(fromUser);
                ngModel.$formatters.push(toUser);
            }
        };
    });

    nbsdirectives.directive('nbsRte', function () {
        return {
            scope: {
                nbsRteContentPaste: '=nbsRteContentPaste'
            },
            restrict: 'AE',
            replace: false,
            template: '',
            link: function (scope, elem, attrs) {
                scope.$watch('nbsRteContentPaste', function (content) {
                    var rte = $("#" + attrs.rtfid).data("ejRTE");
                    if (content) {
                        // content contais a guid used to force watch by directive
                        // must be deleted before insert into RTE
                        content = content.substring(36, content.length);

                        // force focus
                        rte.focus();

                        // insert text into RTE
                        rte.pasteContent(content);
                    }
                });
            }
        };
    });

    nbsdirectives.directive('nbsReportHtml', function () {
        return {
            scope: {
                nbsreporthtmlrefresh: '=nbsreporthtmlrefresh'
            },
            restrict: 'AE',
            replace: false,
            template: '',
            link: function (scope, elem, attrs) {
                scope.$watch('nbsreporthtmlrefresh', function (changedVal) {
                    if (changedVal) {
                        var reportHtmlElement = elem[0];
                        var reportHtmlStyleElement = $(elem[0]).find('style');

                        if (reportHtmlStyleElement) {
                            var reportHtmlCss = Lazy(document.styleSheets).filter((stylesheet) => {
                                return stylesheet.href && stylesheet.href.indexOf('reporthtml.css') >= 0;
                            }).first();

                            if (reportHtmlCss) {
                                // inject style into html for report document
                                $(reportHtmlStyleElement).html(Lazy(reportHtmlCss.cssRules).pluck('cssText').join('\n'));
                            }
                        }

                        // reference to nbs-vm controller
                        setTimeout(function () {
                            var htmlString = $(reportHtmlElement).html();
                            scope.$parent.$parent.vm.renderReportHtmlToDoc(htmlString);
                        }, 200);
                    }
                });
            }
        };
    });

    nbsdirectives.directive('nbsEjcolorpicker', function () {
        return {
            scope: {
                nbsColorpickerDisabled: '=nbsColorpickerDisabled'
            },
            restrict: 'AE',
            replace: false,
            template: '',
            link: function (scope, elem, attrs) {
                scope.$watch('nbsColorpickerDisabled', function (isLocked) {
                    // e-disable angular directive of ejColorpicker doesn't works
                    // at moment a workaround is to remove button for change color if element is locked
                    var parentSpan = $("#" + attrs.id).closest('span')[0];
                    if (isLocked === true) {
                        $(parentSpan).find('span.e-select').hide();
                    } else {
                        $(parentSpan).find('span.e-select').show();
                    }
                });
            }
        };
    });

    nbsdirectives.directive('draggable', ['$document', function ($document) {
        return function (scope, element, attr) {
            var welement = $(element).closest("div.modal-content");
            var welementHeader = $(welement).children(".modal-header");

            var startX = 0, startY = 0, x = 0, y = 0;
            welement.css({
                position: 'relative',
                //cursor: 'pointer',
                display: 'block'
            });

            welementHeader.css({
                cursor: 'pointer'
            });

            welement.on('mousedown', function (event) {
                // Prevent default dragging of selected content
                if (event.target.className.indexOf("modal-header") < 0
                    && event.target.className.indexOf("modal-title") < 0) {
                    return;
                }
                event.preventDefault();
                startX = event.screenX - x;
                startY = event.screenY - y;
                $document.on('mousemove', mousemove);
                $document.on('mouseup', mouseup);
            });

            function mousemove(event) {

                y = event.screenY - startY;
                x = event.screenX - startX;
                welement.css({
                    top: y + 'px',
                    left: x + 'px'
                });
            }

            function mouseup() {
                $document.off('mousemove', mousemove);
                $document.off('mouseup', mouseup);
            }
        };
    }]);

    nbsdirectives.directive('bootstrapSwitch', function () {
        return {
            restrict: 'A',
            require: '?ngModel',
            link: function (scope, element, attrs, ngModel) {
                if (element) {
                    if (attrs.onText && scope && scope.vm) {
                        if (scope.vm.uiContext) {
                            element.attr('data-on-text', scope.vm.uiContext.translate(attrs.onText));
                        } else {
                            element.attr('data-on-text', scope.vm.translate(attrs.onText));
                        }
                    }
                    if (attrs.offText && scope && scope.vm) {
                        if (scope.vm.uiContext) {
                            element.attr('data-off-text', scope.vm.uiContext.translate(attrs.offText));
                        } else {
                            element.attr('data-off-text', scope.vm.translate(attrs.offText));
                        }
                    }                    
                }

                // create switch by checkbox element
                element.bootstrapSwitch();
                
                element.on('switchChange.bootstrapSwitch', function (event, state) {
                    if (ngModel) {
                        scope.$apply(function () {
                            ngModel.$setViewValue(state);
                        });
                    }
                });

                scope.$watch(attrs.ngModel, function (newValue, oldValue) {
                    if (newValue) {
                        if ($(element).attr('disabled')) {
                            element.bootstrapSwitch('disabled', false);
                            element.bootstrapSwitch('state', true, true);
                            element.bootstrapSwitch('disabled', true);
                        }
                        else {
                            element.bootstrapSwitch('state', true, true);
                        }

                    } else {
                        element.bootstrapSwitch('state', false, true);
                    }
                });

                scope.$watch(attrs.switchActive, function (newValue) {
                    element.bootstrapSwitch('disabled', newValue);
                });
            }
        };
    });

    nbsdirectives.directive('bootstrapSwitchComponent', function () {
        return {
            restrict: 'A',
            require: '?ngModel',
            link: function (scope, element, attrs, ngModel) {
                //// create switch by checkbox element
                element.bootstrapSwitch();

                element.on('switchChange.bootstrapSwitch', function (event, state) {
                    if (ngModel) {
                        scope.$apply(function () {
                            ngModel.$setViewValue(state);
                        });
                    }
                });

                scope.$watch(attrs.ngModel, function (newValue, oldValue) {
                    if (newValue) {
                        if ($(element).attr('disabled')) {
                            element.bootstrapSwitch('state', false, true);
                        }
                        else {
                            element.bootstrapSwitch('state', true, true);
                        }

                    } else {
                        element.bootstrapSwitch('state', false, true);
                    }
                });

                scope.$watch(attrs.switchActive, function (newValue) {
                    element.bootstrapSwitch('disabled', newValue);
                });
            }
        };
    });

    nbsdirectives.directive('nbsEjScheduler', function () {
        return {
            scope: {
                nbsschedulerrefresh: '=nbsschedulerrefresh',
                nbsschedulerrefreshresources: '=nbsschedulerrefreshresources'
            },
            restrict: 'AE',
            link: function (scope, elem, attrs) {
                scope.$watch('nbsschedulerrefreshresources', function (jsonResources) {
                    if (jsonResources) {
                        var scheduler = $("#" + attrs.id).data("ejSchedule");
                        $("#" + attrs.id).data('ejSchedule').model.resources = JSON.parse(jsonResources);
                        scheduler.refresh();
                    }
                });
                scope.$watch('nbsschedulerrefresh', function (changedVal) {
                    if (changedVal) {
                        var scheduler = $("#" + attrs.id).data("ejSchedule");
                        scheduler.refresh();
                    }
                });
            }
        };
    });

    return nbsdirectives;
});
