'use strict';

define([], function () {

    angular.module('nbs.plupload.directive', [])
        .directive('nbsPlUpload', ['$parse', 'fileTransferService', '$uibModal', function ($parse, fileTransferService, $uibModal) {
            return {
                restrict: 'A',
                scope: {
                    'plProgressModel': '=',
                    'plFilesModel': '=',
                    'plFiltersModel': '=',
                    'plMultiParamsModel': '=',
                    'plInstance': '=',
                    'plResize': '=',
                    'onFileUploaded': '='
                },
                link: function (scope, iElement, iAttrs) {

                    scope.randomString = function (len, charSet) {
                        charSet = charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                        var randomString = '';
                        for (var i = 0; i < len; i++) {
                            var randomPoz = Math.floor(Math.random() * charSet.length);
                            randomString += charSet.substring(randomPoz, randomPoz + 1);
                        }
                        return randomString;
                    };

                    if (!iAttrs.id) {
                        var randomValue = scope.randomString(5);
                        iAttrs.$set('id', randomValue);
                    }
                    if (!iAttrs.plAutoUpload) {
                        iAttrs.$set('plAutoUpload', 'true');
                    }
                    if (!iAttrs.plMaxFileSize) {
                        iAttrs.$set('plMaxFileSize', '100mb');
                    }

                    if (typeof scope.plFiltersModel === "undefined") {
                        scope.filters = [{ title: "Image files", extensions: "jpg,jpeg,gif,png,svg,txt,rdl,rdlc,pdf,doc,docx,xls,xlsx,ppt,pptx,mp4,mov,ogv,json,avi,rpt" }];
                    } else {
                        scope.filters = scope.plFiltersModel;
                    }

                    var uploadParams = {};
                    if (scope.plResize) {
                        uploadParams.resize = scope.plResize;
                    }

                    var options = {
                        runtimes: 'html5,html4',
                        browse_button: iAttrs.id,
                        multi_selection: true,
                        max_file_size: iAttrs.plMaxFileSize,
                        url: fileTransferService.uriUpload(uploadParams),
                        filters: scope.filters,
                        chunk_size: '64kb',
                        multipart: true,
                        multipart_params: {},
                        drop_element: "uploader-container"
                    };

                    var uploader = new plupload.Uploader(options);

                    uploader.init();

                    uploader.bind('Error', function (up, err) {
                        if (iAttrs.onFileError) {
                            scope.$parent.$apply(onFileError);
                        }
                        up.settings.multipart_params = {};
                        if (scope.uploadModel) {
                            scope.uploadModel.files = [];
                        }
                        if (scope.uploadModel.modalInstance) {
                            scope.uploadModel.modalInstance.close();
                        }
                        alert("Cannot upload, error: " + err.message + (err.file ? ", File: " + err.file.name : "") + "");
                        up.refresh(); // Reposition Flash/Silverlight
                    });

                    scope.uploadModel = {};
                    scope.uploadModel.sum = 0;
                    scope.uploadModel.uploadProgress = 0;
                    scope.uploadModel.files = scope.plFilesModel;
                    scope.uploadModel.onFileUploaded = scope.onFileUploaded;
                    //scope.uploadModel.modalInstance = scope.plFilesModel;

                    uploader.bind('FilesAdded', function (up, files) {
                        //uploader.start();
                        scope.sum = 0;
                        scope.$apply(function () {
                            if (iAttrs.plFilesModel) {
                                angular.forEach(files, function (file, key) {
                                    scope.plFilesModel.push(file);
                                });
                            }

                            if (iAttrs.onFileAdded) {
                                scope.$parent.$eval(iAttrs.onFileAdded);
                            }
                        });

                        if (iAttrs.plAutoUpload === "true") {
                            var ModalInstanceCtrl = function ($scope, $uibModalInstance) {
                                $scope.ok = function () {
                                    $uibModalInstance.close();
                                };

                                $scope.cancel = function () {
                                    $uibModalInstance.dismiss();
                                };
                            };
                            var modalHtml = '<div class="modal-body"><uib-progressbar value="uploadModel.uploadProgress" animate="false" max="100"  class="progress-striped active">{{uploadModel.uploadProgress}}%</uib-progressbar></div>';
                            modalHtml += '<div class="modal-footer"><button class="btn btn-void" ng-click="cancel()">' + $TG('ModalButtonCancel') + '</button></div>';

                            var modalInstance = $uibModal.open({
                                scope: scope,
                                template: modalHtml,
                                controller: ModalInstanceCtrl
                            });
                            scope.uploadModel.modalInstance = modalInstance;
                            uploader.start();
                        }
                    });

                    uploader.bind("BeforeUpload", function (up, file) {
                        //params = up.settings.multipart_params;

                        var settings = up.settings;
                        var params = up.settings.multipart_params;
                        params['filemap_' + file.name] = file.id;
                    });

                    uploader.bind('UploadProgress', function (up, file) {
                        scope.uploadModel.sum = 0;
                        if (scope.uploadModel.files) {
                            angular.forEach(scope.uploadModel.files, function (file, key) {
                                scope.uploadModel.sum = scope.uploadModel.sum + file.percent;
                            });
                            if (scope.uploadModel.files.length === 0) {
                                scope.uploadModel.uploadProgress = 0;
                            }
                            else {
                                scope.uploadModel.uploadProgress = scope.uploadModel.sum / scope.uploadModel.files.length;
                            }

                            scope.$apply();
                        } else {
                            scope.uploadModel.uploadProgress = file.percent;
                            scope.$apply();
                        }
                    });

                    uploader.bind('FileUploaded', function (up, file, res) {
                        if (scope.uploadModel.files) {
                            scope.uploadModel.allUploaded = true;
                            angular.forEach(scope.uploadModel.files, function (file, key) {
                                if (file.percent !== 100)
                                    scope.uploadModel.allUploaded = false;
                            });
                        }
                        if (scope.uploadModel.onFileUploaded) {
                            scope.uploadModel.onFileUploaded(file, res);
                        }
                        if (scope.uploadModel.allUploaded) {
                            scope.$apply(function () {
                                scope.uploadModel.modalInstance.close();
                                up.settings.multipart_params = {};
                                //scope.uploadModel.files = [];
                            });
                        }
                    });

                }
            };
        }]);
});
