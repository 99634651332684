﻿
'use strict';
define(['../../common/services/nbs-service'], function (nbsService) {

    var injectParams = ['$http', '$q', '$timeout', 'breezeDataLayer'];

    var audService = function ($http, $q, $timeout, breezeDataLayer) {

        var dataLayer = breezeDataLayer.Initialize('aud', true);
        var manager = dataLayer.GetManager();
        var ejDataManager = dataLayer.GetEjDataManager();
        var entityTypes = {
            SurveyCreationRequest: 'SurveyCreationRequest',			// used to create audit events
            SurveyTemplate: 'SurveyTemplate',
            SurveyQuestion: 'SurveyQuestion',
            SurveyQuestionOption: 'SurveyQuestionOption',
            SurveySection: 'SurveySection',
            SurveyOptionChoice: 'SurveyOptionChoice',
            SurveyOptionChoiceGroup: 'SurveyOptionChoiceGroup',
            SurveyAnswerSummary: 'SurveyAnswerSummary',

            SurveyDto: 'SurveyDto',
            SurveyCompileDto: 'SurveyCompileDto',
            SurveyRecurrenceDto: 'SurveyRecurrenceDto',
            SurveyQuestionOptionDto: 'SurveyQuestionOptionDto',
            SurveyShuffleDto: 'SurveyShuffleDto',

            ActDto: 'ActDto',
            SurveyQuestionOptionM2MActDto: 'SurveyQuestionOptionM2MActDto',
            SurveyM2MActDto: 'SurveyM2MActDto',
            AudActM2MCategoryDto: 'AudActM2MCategoryDto',
            VhrEventM2MAppUserDto: 'VhrEventM2MAppUserDto',
            VhrEventM2MDrbacUserGroupDto: 'VhrEventM2MDrbacUserGroupDto',

            SurveyTemplateGroup: 'SurveyTemplateGroup',
            SurveyTemplateType: 'SurveyTemplateType',

            // Analysis
            SurveyAnalysis: 'SurveyAnalysis',
            SurveyAnalysisDefinition: 'SurveyAnalysisDefinition',

            // Actions
            SurveyAction: 'SurveyAction',
            SurveyActionDto: 'SurveyActionDto',

            AudActCreationRequest: 'AudActCreationRequest',

            // wizard
            SurveyCreationClientData: 'SurveyCreationClientData',

            // client validations
            SurveyReportClientData: 'SurveyReportClientData',
            SurveyReportFormClientData: 'SurveyReportFormClientData',
            HtmlToPdfSurveyQuestionOptionDistributionClientData: 'HtmlToPdfSurveyQuestionOptionDistributionClientData',
            HtmlToPdfSurveyQuestionOptionDistributionTemporalyClientData: 'HtmlToPdfSurveyQuestionOptionDistributionTemporalyClientData',
            HtmlToPdfSurveyVhrTargetKpiClientData: 'HtmlToPdfSurveyVhrTargetKpiClientData',
            HtmlToPdfSurveyKpiClientData: 'HtmlToPdfSurveyKpiClientData',
            HtmlToPdfSurveySectionKpiClientData: 'HtmlToPdfSurveySectionKpiClientData',
            HtmlToExcelSurveyGanttClientData: 'HtmlToExcelSurveyGanttClientData',
            HtmlToPdfAudActsKpiClientData: 'HtmlToPdfAudActsKpiClientData',
            VhrTargetSurveyKpiReportFormClientData: 'VhrTargetSurveyKpiReportFormClientData',

            FsDto: 'FsDto'
        };
        var me = this;
        //#region Client validations 

        function pTransformEnumInObj(enumerator) {
            Lazy(enumerator)
                .each((item) => {
                    item.value = Number(item.value);
                });

            return Nembus.Common.Library.parser.objFromList(enumerator, 'name', 'value');
        }

        // validation functions
        function sectionQuestionIdValidationFn(value, context) { if (context.entity.SectionQuestionId === null) { return false; } else { return true; } }


        //add some custom validations to SurveyCreationRequest entity
        var surveyCreationRequestClientData = manager.metadataStore.getEntityType(entityTypes.SurveyCreationRequest);
        function surveyCreationRequestVhrTargetIdValidationFn(value, context) {
            var surveyCreationRequestType = pTransformEnumInObj(dataLayer.GetEnumType('SurveyCreationRequestType'));
            return context.entity.SurveyCreationRequestTypeId === surveyCreationRequestType.NewWithTargetsSelected && !context.entity.VirtualHumanResourcesSerialized ? false : true;
        }
        function surveyCreationRequestTargetCodeValidationFn(value, context) {
            var surveyCreationRequestType = pTransformEnumInObj(dataLayer.GetEnumType('SurveyCreationRequestType'));
            return context.entity.SurveyCreationRequestTypeId === surveyCreationRequestType.NewWithTargetFilled && !context.entity.SurveyCreationRequestTargetCode ? false : true;
        }
        function surveyCreationRequestTargetNameValidationFn(value, context) {
            var surveyCreationRequestType = pTransformEnumInObj(dataLayer.GetEnumType('SurveyCreationRequestType'));
            return context.entity.SurveyCreationRequestTypeId === surveyCreationRequestType.NewWithTargetFilled && !context.entity.SurveyCreationRequestTargetName ? false : true;
        }
        function surveyCreationRequestVhrEventTypeValidationFn(value, context) {
            // 1 => NewWithTargetFilled
            // 2 => NewWithTargetsSelected
            var surveyCreationRequestType = pTransformEnumInObj(dataLayer.GetEnumType('SurveyCreationRequestType'));
            if ((context.entity.SurveyCreationRequestTypeId === surveyCreationRequestType.NewWithTargetFilled && !context.entity.VhrEventTypeId)
                ||
                (context.entity.SurveyCreationRequestTypeId === surveyCreationRequestType.NewWithTargetsSelected && !context.entity.VhrEventTypeId)) {
                return false;
            } else {
                return true;
            }
        }
        function surveyCreationRequestVhrEventGroupValidationFn(value, context) {
            // 1 => NewWithTargetFilled
            // 2 => NewWithTargetsSelected
            var surveyCreationRequestType = pTransformEnumInObj(dataLayer.GetEnumType('SurveyCreationRequestType'));
            if ((context.entity.SurveyCreationRequestTypeId === surveyCreationRequestType.NewWithTargetFilled && !context.entity.VhrEventGroupId)
                ||
                (context.entity.SurveyCreationRequestTypeId === surveyCreationRequestType.NewWithTargetsSelected && !context.entity.VhrEventGroupId)) {
                return false;
            } else {
                return true;
            }
        }
        function surveyCreationRequestSurveyTemplateIdValidationFn(value, context) {
            return context.entity.SurveyTemplateIdSerialized === null
                && (context.entity.SurveyCreationRequestTypeId === 1 // NewWithTargetFilled
                    || context.entity.SurveyCreationRequestTypeId === 2) // NewWithTargetsSelected
                ? false : true;
        }
        function surveyCreationRequestCronExpressionValidationFn(value, context) {
            var patternRecurrentId = 2;

            return context.entity.SurveyCreationRequestCompileTypeId === patternRecurrentId && !context.entity.CronExpression
                ? false
                : true;
        }
        function surveyCreationClientDataEndDateValidationFn(value, context) {
            var patternRecurrentId = 2;
            if (context.entity.SurveyCreationRequestCompileTypeId === patternRecurrentId) {
                if (!context.entity.RecurrenceEndDate) { return false; } else { return true; }
            }
            return true;
        }
        function surveyCreationClientDataStartDateValidationFn(value, context) {
            var patternSingleId = 1;
            var patternRecurrentId = 2;
            if (context.entity.SurveyCreationRequestCompileTypeId === patternSingleId
                || context.entity.SurveyCreationRequestCompileTypeId === patternRecurrentId) {

                if (context.entity.RecurrenceStartDate === null) { return false; } else { return true; }
            }
            return true;
        }

        surveyCreationRequestClientData.getProperty("VirtualHumanResourcesSerialized").validators.push(new breeze.Validator('targetIdValidator', surveyCreationRequestVhrTargetIdValidationFn));
        surveyCreationRequestClientData.getProperty("SurveyTemplateIdSerialized").validators.push(new breeze.Validator('surveyTemplateIdValidator', surveyCreationRequestSurveyTemplateIdValidationFn));
        surveyCreationRequestClientData.getProperty("SurveyCreationRequestTargetCode").validators.push(new breeze.Validator('targetCodeValidator', surveyCreationRequestTargetCodeValidationFn));
        surveyCreationRequestClientData.getProperty("SurveyCreationRequestTargetName").validators.push(new breeze.Validator('targetNameValidator', surveyCreationRequestTargetNameValidationFn));
        surveyCreationRequestClientData.getProperty("VhrEventTypeId").validators.push(new breeze.Validator('surveyTypeValidator', surveyCreationRequestVhrEventTypeValidationFn));
        surveyCreationRequestClientData.getProperty("VhrEventGroupId").validators.push(new breeze.Validator('surveyGroupValidator', surveyCreationRequestVhrEventGroupValidationFn));
        surveyCreationRequestClientData.getProperty("CronExpression").validators.push(new breeze.Validator('cronExpressionValidator', surveyCreationRequestCronExpressionValidationFn));
        surveyCreationRequestClientData.getProperty("RecurrenceEndDate").validators.push(new breeze.Validator('endDateValidator', surveyCreationClientDataEndDateValidationFn));
        surveyCreationRequestClientData.getProperty("RecurrenceStartDate").validators.push(new breeze.Validator('startDateValidator', surveyCreationClientDataStartDateValidationFn));


        //HtmlToPdfSurveyVhrTargetKpiClientData
        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.HtmlToPdfSurveyVhrTargetKpiClientData,
                dataProperties: {
                    HtmlToPdfSurveyVhrTargetKpiClientDataId: { type: breeze.DataType.Guid },
                    SurveyCompileDateSystem1: { type: breeze.DataType.DateTime },
                    SurveyCompileDateSystem2: { type: breeze.DataType.DateTime },
                    SurveyAnalysisDefinitionId: { type: breeze.DataType.String }
                }
            },
            'Aud.Domain'
        );

        var htmlToPdfSurveyVhrTargetKpiClientData = manager.metadataStore.getEntityType(entityTypes.HtmlToPdfSurveyVhrTargetKpiClientData);
        htmlToPdfSurveyVhrTargetKpiClientData.getProperty("SurveyCompileDateSystem1").validators.push(new breeze.Validator('startDateValidator', Nembus.Common.Library.validators.requiredFieldDateValidator));
        htmlToPdfSurveyVhrTargetKpiClientData.getProperty("SurveyCompileDateSystem2").validators.push(new breeze.Validator('endDateValidator', Nembus.Common.Library.validators.requiredFieldDateValidator));
        htmlToPdfSurveyVhrTargetKpiClientData.getProperty("SurveyAnalysisDefinitionId").validators.push(new breeze.Validator('kpiValidator', Nembus.Common.Library.validators.requiredFieldValidator));

        //HtmlToPdfSurveyKpiClientData
        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.HtmlToPdfSurveyKpiClientData,
                dataProperties: {
                    Id: { type: breeze.DataType.Guid },
                    SurveyCompileDateSystem1: { type: breeze.DataType.DateTime },
                    SurveyCompileDateSystem2: { type: breeze.DataType.DateTime },
                    SurveyAnalysisDefinitionId: { type: breeze.DataType.String }
                }
            },
            'Aud.Domain'
        );

        var htmlToPdfSurveyKpiClientData = manager.metadataStore.getEntityType(entityTypes.HtmlToPdfSurveyKpiClientData);
        htmlToPdfSurveyKpiClientData.getProperty("SurveyCompileDateSystem1").validators.push(new breeze.Validator('startDateValidator', Nembus.Common.Library.validators.requiredFieldDateValidator));
        htmlToPdfSurveyKpiClientData.getProperty("SurveyCompileDateSystem2").validators.push(new breeze.Validator('endDateValidator', Nembus.Common.Library.validators.requiredFieldDateValidator));
        htmlToPdfSurveyKpiClientData.getProperty("SurveyAnalysisDefinitionId").validators.push(new breeze.Validator('kpiValidator', Nembus.Common.Library.validators.requiredFieldValidator));


        //HtmlToPdfSurveySectionKpiClientData
        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.HtmlToPdfSurveySectionKpiClientData,
                dataProperties: {
                    Id: { type: breeze.DataType.Guid },
                    SurveyId: { type: breeze.DataType.Int32 },
                    ChartTypeId: { type: breeze.DataType.String },
                    SurveyAnalysisDefinitionId: { type: breeze.DataType.String }
                }
            },
            'Aud.Domain'
        );

        var htmlToPdfSurveySectionKpiClientData = manager.metadataStore.getEntityType(entityTypes.HtmlToPdfSurveySectionKpiClientData);
        htmlToPdfSurveySectionKpiClientData.getProperty("SurveyId").validators.push(new breeze.Validator('surveyIdValidator', Nembus.Common.Library.validators.requiredFieldValidator));
        htmlToPdfSurveySectionKpiClientData.getProperty("ChartTypeId").validators.push(new breeze.Validator('chartTypeIdValidator', Nembus.Common.Library.validators.requiredFieldValidator));
        htmlToPdfSurveySectionKpiClientData.getProperty("SurveyAnalysisDefinitionId").validators.push(new breeze.Validator('kpiValidator', Nembus.Common.Library.validators.requiredFieldValidator));



        //HtmlToPdfSurveyQuestionOptionDistributionClientData
        breezeDataLayer.addType(manager.metadataStore,
            {
                name: entityTypes.HtmlToPdfSurveyQuestionOptionDistributionClientData,
                dataProperties: {
                    Id: { type: breeze.DataType.Guid },
                    SurveyCompileDateSystem1: { type: breeze.DataType.DateTime },
                    SurveyCompileDateSystem2: { type: breeze.DataType.DateTime },
                    SectionQuestionId: { type: breeze.DataType.String }
                }
            }, 'Aud.Domain'
        );

        var htmlToPdfSurveyQuestionOptionDistributionClientData = manager.metadataStore.getEntityType(entityTypes.HtmlToPdfSurveyQuestionOptionDistributionClientData);
        htmlToPdfSurveyQuestionOptionDistributionClientData.getProperty("SurveyCompileDateSystem1").validators.push(new breeze.Validator('startDateValidator', Nembus.Common.Library.validators.requiredFieldDateValidator));
        htmlToPdfSurveyQuestionOptionDistributionClientData.getProperty("SurveyCompileDateSystem2").validators.push(new breeze.Validator('endDateValidator', Nembus.Common.Library.validators.requiredFieldDateValidator));
        htmlToPdfSurveyQuestionOptionDistributionClientData.getProperty("SectionQuestionId").validators.push(new breeze.Validator('sectionQuestionValidator', sectionQuestionIdValidationFn));

        //HtmlToPdfSurveyQuestionOptionDistributionTemporalyClientData
        breezeDataLayer.addType(manager.metadataStore,
            {
                name: entityTypes.HtmlToPdfSurveyQuestionOptionDistributionTemporalyClientData,
                dataProperties: {
                    Id: { type: breeze.DataType.Guid },
                    SurveyCompileDateSystem1: { type: breeze.DataType.DateTime },
                    SurveyCompileDateSystem2: { type: breeze.DataType.DateTime }
                }
            }, 'Aud.Domain'
        );

        var htmlToPdfSurveyQuestionOptionDistributionTemporalyClientData = manager.metadataStore.getEntityType(entityTypes.HtmlToPdfSurveyQuestionOptionDistributionTemporalyClientData);
        htmlToPdfSurveyQuestionOptionDistributionTemporalyClientData.getProperty("SurveyCompileDateSystem1").validators.push(new breeze.Validator('startDateValidator', Nembus.Common.Library.validators.requiredFieldDateValidator));
        htmlToPdfSurveyQuestionOptionDistributionTemporalyClientData.getProperty("SurveyCompileDateSystem2").validators.push(new breeze.Validator('endDateValidator', Nembus.Common.Library.validators.requiredFieldDateValidator));


        //HtmlToPdfAudActsKpiClientData
        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.HtmlToPdfAudActsKpiClientData,
                dataProperties: {
                    HtmlToPdfAudActsKpiClientDataId: { type: breeze.DataType.Guid }
                }
            },
            'Aud.Domain'
        );


        //HtmlToExcelSurveyGanttClientData
        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.HtmlToExcelSurveyGanttClientData,
                dataProperties: {
                    HtmlToExcelSurveyGanttClientDataId: { type: breeze.DataType.Guid },
                    StartDate1: { type: breeze.DataType.DateTime },
                    StartDate2: { type: breeze.DataType.DateTime }
                }
            },
            'Aud.Domain'
        );

        var htmlToExcelSurveyGanttClientData = manager.metadataStore.getEntityType(entityTypes.HtmlToExcelSurveyGanttClientData);
        htmlToExcelSurveyGanttClientData.getProperty("StartDate1").validators.push(new breeze.Validator('startDateValidator', Nembus.Common.Library.validators.requiredFieldDateValidator));
        htmlToExcelSurveyGanttClientData.getProperty("StartDate2").validators.push(new breeze.Validator('endDateValidator', Nembus.Common.Library.validators.requiredFieldDateValidator));

        //SurveyReportFormClientData
        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.SurveyReportFormClientData,
                dataProperties: {
                    Id: { type: breeze.DataType.Guid },
                    SurveyId: { type: breeze.DataType.Int32 }
                }
            },
            'Aud.Domain'
        );

        function surveyReportFormClientDataSurveyIdValidationFn(value, context) { if (!context.entity.SurveyId) { return false; } else { return true; } }
        var surveyReportFormClientData = manager.metadataStore.getEntityType(entityTypes.SurveyReportFormClientData);
        surveyReportFormClientData.getProperty("SurveyId").validators.push(new breeze.Validator('selectAlmostOneItemValidator', surveyReportFormClientDataSurveyIdValidationFn));

        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.SurveyReportClientData,
                dataProperties: {
                    Id: { type: breeze.DataType.Guid },
                    SurveyExpireDate1: { type: breeze.DataType.DateTime },
                    SurveyExpireDate2: { type: breeze.DataType.DateTime },
                    IsProcessEnabled: { type: breeze.DataType.Boolean },
                    DrbacProcessId: { type: breeze.DataType.Int32 }
                }
            }, 'Nembus.Domain'
        );
        var surveyReportClientData = manager.metadataStore.getEntityType(entityTypes.SurveyReportClientData);
        surveyReportClientData.getProperty("SurveyExpireDate1").validators.push(new breeze.Validator("expireValidator", Nembus.Common.Library.validators.requiredFieldValidator));
        surveyReportClientData.getProperty("SurveyExpireDate2").validators.push(new breeze.Validator("expireValidator", Nembus.Common.Library.validators.requiredFieldValidator));
        surveyReportClientData.getProperty("DrbacProcessId").validators.push( // is required
            new breeze.Validator("drbacProcessValidator", function (value, context) {
                return context.entity.IsProcessEnabled && !context.entity.DrbacProcessId ? false : true;
            })
        );

        //VhrTargetSurveyKpiReportFormClientData
        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.VhrTargetSurveyKpiReportFormClientData,
                dataProperties: {
                    Id: { type: breeze.DataType.Guid },
                    SurveyCompileDateSystem1: { type: breeze.DataType.DateTime },
                    SurveyCompileDateSystem2: { type: breeze.DataType.DateTime },
                    SurveyAnalysisDefinitionId: { type: breeze.DataType.String }
                }
            },
            'Aud.Domain'
        );

        var vhrTargetSurveyKpiReportFormClientData = manager.metadataStore.getEntityType(entityTypes.VhrTargetSurveyKpiReportFormClientData);
        vhrTargetSurveyKpiReportFormClientData.getProperty("SurveyAnalysisDefinitionId").validators.push(new breeze.Validator('selectAlmostOneItemValidator', Nembus.Common.Library.validators.requiredFieldValidator));

        // add client validation based on schema validation related to survey/surveyTemplate
        this.customizeSurveyActionDtoValidation = function (validationSchema, addActionToQuestionOption) {
            if (!validationSchema) {
                return;
            }

            var surveyActionDto = manager.metadataStore.getEntityType(entityTypes.SurveyActionDto);
            var surveyActionType = Nembus.Common.Library.util.parseEnum(this.dataContext.enumTypes.SurveyActionType, null, null).obj.normal.name_value;

            // Question options validation
            // SurveyQuestionOptionsIdSerialized (NOT managed in validationSchema)
            // It depends on Act surveyActionType
            var fieldName = 'SurveyQuestionOptionsIdSerialized';
            var validationKey = 'optionValidator';
            if (!Lazy(surveyActionDto.getProperty(fieldName).validators).where({ name: validationKey }).some()) {
                surveyActionDto.getProperty(fieldName).validators.push(new breeze.Validator(validationKey, (value, context) => {
                    var optionSelected = [];
                    if (context.entity.SurveyQuestionOptionsIdSerialized) {
                        optionSelected = JSON.parse(context.entity.SurveyQuestionOptionsIdSerialized);
                    }

                    if (context.entity.SurveyActionTypeId === surveyActionType.SurveyQuestionOption && addActionToQuestionOption) {
                        if (Lazy(optionSelected).some()) {
                            return true;
                        } else {
                            return false;
                        }
                    }

                    return true;
                }));
            }

            Nembus.Rds.Library.ParseActValidationSchemaValidation(validationSchema);

            var validationsList = [
                { toValidate: validationSchema.Validation.Category, fieldName: 'CategoriesIdsSerialized', valName: 'categoryValidator', valFn: Nembus.Common.Library.validators.requiredFieldSerializedValidator },
                { toValidate: validationSchema.Validation.Description, fieldName: 'ActDescription', valName: 'descriptionValidator', valFn: Nembus.Common.Library.validators.requiredFieldValidator },
                { toValidate: validationSchema.Validation.UserAssigned, fieldName: 'AppUserIdSerialized', valName: 'userValidator', valFn: Nembus.Common.Library.validators.requiredFieldSerializedValidator },
                { toValidate: validationSchema.Validation.UserGroupAssigned, fieldName: 'DrbacUserGroupIdSerialized', valName: 'userGroupValidator', valFn: Nembus.Common.Library.validators.requiredFieldSerializedValidator },
                { toValidate: validationSchema.Validation.ExpireDate, fieldName: 'ActExpireDateQuota', valName: 'expireValidator', valFn: Nembus.Common.Library.validators.requiredFieldValidator },
                { toValidate: validationSchema.Validation.ExpireDate, fieldName: 'ActExpireDate', valName: 'expireValidator', valFn: Nembus.Common.Library.validators.requiredFieldValidator }
            ];

            Lazy(validationsList)
                .each((item) => {
                    if (item.toValidate) {
                        // add validation
                        if (!Lazy(surveyActionDto.getProperty(item.fieldName).validators).where({ name: item.valName }).some()) {
                            surveyActionDto.getProperty(item.fieldName).validators.push(new breeze.Validator(item.valName, item.valFn));
                        }
                    } else {
                        // remove validation
                        surveyActionDto.getProperty(item.fieldName).validators = Lazy(surveyActionDto.getProperty(item.fieldName).validators)
                            .filter((validator) => { return validator.name !== item.valName; }).toArray();
                    }
                });
        };

        // add client validation based on schema validation related to AudActCreationRequest (new action in audit)
        this.customizeAudActCreationRequestValidation = function (validationSchema) {
            if (!validationSchema) {
                return;
            }

            var audActCreationRequest = manager.metadataStore.getEntityType(entityTypes.AudActCreationRequest);

            Nembus.Rds.Library.ParseActValidationSchemaValidation(validationSchema);

            var validationsList = [
                { toValidate: validationSchema.Validation.Category, fieldName: 'CategoriesIdSerialized', valName: 'categoryValidator', valFn: Nembus.Common.Library.validators.requiredFieldSerializedValidator },
                { toValidate: validationSchema.Validation.Description, fieldName: 'ActDescription', valName: 'descriptionValidator', valFn: Nembus.Common.Library.validators.requiredFieldValidator },
                { toValidate: validationSchema.Validation.UserAssigned, fieldName: 'UsersAssignedIdSerialized', valName: 'userValidator', valFn: Nembus.Common.Library.validators.requiredFieldSerializedValidator },
                { toValidate: validationSchema.Validation.UserGroupAssigned, fieldName: 'UserGroupsAssignedIdSerialized', valName: 'userGroupValidator', valFn: Nembus.Common.Library.validators.requiredFieldSerializedValidator },
                { toValidate: validationSchema.Validation.ExpireDate, fieldName: 'ActExpireDate', valName: 'expireValidator', valFn: Nembus.Common.Library.validators.requiredFieldValidator }
            ];

            Lazy(validationsList)
                .each((item) => {
                    if (item.toValidate) {
                        // add validation
                        if (!Lazy(audActCreationRequest.getProperty(item.fieldName).validators).where({ name: item.valName }).some()) {
                            audActCreationRequest.getProperty(item.fieldName).validators.push(new breeze.Validator(item.valName, item.valFn));
                        }
                    } else {
                        // remove validation
                        audActCreationRequest.getProperty(item.fieldName).validators = Lazy(audActCreationRequest.getProperty(item.fieldName).validators)
                            .filter((validator) => { return validator.name !== item.valName; }).toArray();
                    }
                });
        };

        nbsService.call(this, manager, $q, $timeout);

        this.dataContext = {
            actDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.ActDto),
            surveyActions: dataLayer.setContextMethods(manager, nbsService, entityTypes.SurveyAction),
            surveyM2MActDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.SurveyM2MActDto),
            surveyQuestionOptionM2MActDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.SurveyQuestionOptionM2MActDto),
            surveyActionDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.SurveyActionDto),
            audActCreationRequests: dataLayer.setContextMethods(manager, nbsService, entityTypes.AudActCreationRequest),
            audActM2MCategoryDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.AudActM2MCategoryDto),
            surveyVhrEventM2MAppUserDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.VhrEventM2MAppUserDto),
            surveyVhrEventM2MDrbacUserGroupDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.VhrEventM2MDrbacUserGroupDto),
            surveyAnswerSummaries: dataLayer.setContextMethods(manager, nbsService, entityTypes.SurveyAnswerSummary),
            surveyCreationRequests: dataLayer.setContextMethods(manager, nbsService, entityTypes.SurveyCreationRequest),
            surveyDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.SurveyDto),
            surveyCompileDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.SurveyCompileDto),
            surveyRecurrenceDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.SurveyRecurrenceDto),
            surveyTemplateGroups: dataLayer.setContextMethods(manager, nbsService, entityTypes.SurveyTemplateGroup),
            surveyTemplateTypes: dataLayer.setContextMethods(manager, nbsService, entityTypes.SurveyTemplateType),
            surveyQuestionOptionDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.SurveyQuestionOptionDto),
            surveyTemplates: dataLayer.setContextMethods(manager, nbsService, entityTypes.SurveyTemplate),
            surveyQuestions: dataLayer.setContextMethods(manager, nbsService, entityTypes.SurveyQuestion),
            surveyQuestionOptions: dataLayer.setContextMethods(manager, nbsService, entityTypes.SurveyQuestionOption),
            surveySections: dataLayer.setContextMethods(manager, nbsService, entityTypes.SurveySection),
            surveyOptionChoices: dataLayer.setContextMethods(manager, nbsService, entityTypes.SurveyOptionChoice),
            surveyOptionChoiceGroups: dataLayer.setContextMethods(manager, nbsService, entityTypes.SurveyOptionChoiceGroup),
            fsDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.FsDto),
            surveyAnalysis: dataLayer.setContextMethods(manager, nbsService, entityTypes.SurveyAnalysis),
            surveyAnalysisDefinitions: dataLayer.setContextMethods(manager, nbsService, entityTypes.SurveyAnalysisDefinition),
            surveyShuffleDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.SurveyShuffleDto),

            // client entities
            //surveyCreationClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.SurveyCreationClientData),
            htmlToPdfSurveyVhrTargetKpiClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.HtmlToPdfSurveyVhrTargetKpiClientData),
            htmlToPdfSurveyKpiClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.HtmlToPdfSurveyKpiClientData),
            htmlToPdfSurveySectionKpiClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.HtmlToPdfSurveySectionKpiClientData),
            htmlToPdfSurveyQuestionOptionDistributionClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.HtmlToPdfSurveyQuestionOptionDistributionClientData),
            htmlToPdfSurveyQuestionOptionDistributionTemporalyClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.HtmlToPdfSurveyQuestionOptionDistributionTemporalyClientData),
            htmlToExcelSurveyGanttClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.HtmlToExcelSurveyGanttClientData),
            htmlToPdfAudActsKpiClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.HtmlToPdfSurveyKpiClientData),
            surveyReportFormClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.SurveyReportFormClientData),
            vhrTargetSurveyKpiReportFormClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.VhrTargetSurveyKpiReportFormClientData),
            surveyReportClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.SurveyReportClientData),

            enumTypes: {
                SurveyCreationRequestStatus: dataLayer.GetEnumType('SurveyCreationRequestStatus'),
                SurveyCreationRequestType: dataLayer.GetEnumType('SurveyCreationRequestType'),
                SurveyInputType: Lazy(dataLayer.GetEnumType('SurveyInputType')).reject({ value: "0" }).toArray(),		     // "None" item removed
                SurveyActionType: dataLayer.GetEnumType('SurveyActionType'),
                SurveyCreationPattern: dataLayer.GetEnumType('SurveyCreationPattern'),
                SurveyCreationAssignmentType: dataLayer.GetEnumType('SurveyCreationAssignmentType'),
                SurveyAnalysisGroupMode: dataLayer.GetEnumType('SurveyAnalysisGroupMode'),
                SurveyLifeStatus: getSurveyLifeStatusEnum(),
                SurveyLifeStatus_Full: dataLayer.GetEnumType('SurveyLifeStatus'),
                SurveyLifeStatus_OnlyExtended: getSurveyLifeStatusEnum_OnlyExtended(),
                VhrEventShuffleAction: dataLayer.GetEnumType('VhrEventShuffleAction'),

                ValidationSchemaFieldKind: dataLayer.GetEnumType('ValidationSchemaFieldKind'),
                ExpressionType: dataLayer.GetEnumType('ExpressionType')
            }
        };

        function getSurveyLifeStatusEnum() {
            var viewAuditExtendedStatuses = Nembus.Aud.Library.SSV.CanManageSurveyVhrEventLifeStatus();
            if (viewAuditExtendedStatuses) {
                return dataLayer.GetEnumType('SurveyLifeStatus');
            } else {
                return Lazy(dataLayer.GetEnumType('SurveyLifeStatus'))
                    .filter((item) => { return item.value === '10' || item.value === '40' || item.value === '50'; })
                    .toArray();
            }
        }

        function getSurveyLifeStatusEnum_OnlyExtended() {
            // return only extended statuses
            return Lazy(getSurveyLifeStatusEnum())
                .filter((item) => { return item.value === '30' || item.value === '60' || item.value === '1000'; })
                .toArray();
        }

        this.createQuery = function (entityName) {
            return dataLayer.CreateQuery(entityName);
        };

        // SurveyActions
        this.getSurveyActions = function (filter, forceReload) {
            var params = { entityType: entityTypes.SurveyAction, cache: this.dataContext.surveyActions.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "SurveyActions");
        };
        this.getSurveyAction = function (filter, forceReload) {
            var params = { key: filter.SurveActionId, dataContext: this.dataContext.surveyActions };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "SurveyActions");
        };

        // SurveyTemplateGroup
        this.getSurveyTemplateGroups = function (filter, forceReload) {
            var params = { entityType: entityTypes.SurveyTemplateGroup, cache: this.dataContext.surveyTemplateGroups.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "SurveyTemplateGroups");
        };
        this.getSurveyTemplateGroup = function (filter, forceReload) {
            var params = { key: filter.SurveTemplateGroupId, dataContext: this.dataContext.surveyTemplateGroups };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "SurveyTemplateGroups");
        };

        // SurveyTemplateType
        this.getSurveyTemplateTypes = function (filter, forceReload) {
            var params = { entityType: entityTypes.SurveyTemplateType, cache: this.dataContext.surveyTemplateTypes.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "SurveyTemplateTypes");
        };
        this.getSurveyTemplateType = function (filter, forceReload) {
            var params = { key: filter.SurveTemplateTypeId, dataContext: this.dataContext.surveyTemplateTypes };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "SurveyTemplateTypes");
        };

        // Survey creation requests
        this.getSurveyCreationRequest = function (filter, forceReload) {
            var params = { entityType: entityTypes.SurveyCreationRequest, cache: this.dataContext.surveyCreationRequests.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "SurveyCreationRequests");
        };


        // Survey actions
        this.getSurveyActionDtos = function (filter, forceReload) {
            var params = { entityType: entityTypes.SurveyActionDto, cache: this.dataContext.surveyActionDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "SurveyActionDtos");
        };

        // Survey attachments
        this.getSurveyAttachments = function (filter, forceReload) {
            var params = { entityType: entityTypes.FsDto, cache: this.dataContext.fsDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "SurveyAttachments");
        };

        // Survey documents
        this.getSurveyDocuments = function (filter, forceReload) {
            var params = { entityType: entityTypes.FsDto, cache: this.dataContext.fsDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "SurveyDocuments");
        };
        this.getSurveyTemplateDocuments = function (filter, forceReload) {
            var params = { entityType: entityTypes.FsDto, cache: this.dataContext.fsDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "SurveyTemplateDocuments");
        };

        // action x categories
        // action x categories => Survey
        this.getSurveyAudActM2MCategoryDto = function (filter, forceReload) {
            var params = { entityType: entityTypes.AudActM2MCategoryDto, cache: this.dataContext.audActM2MCategoryDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "SurveyAudActM2MCategoryDto");
        };
        // action x categories => Survey Template
        this.getSurveyTemplateAudActM2MCategoryDto = function (filter, forceReload) {
            var params = { entityType: entityTypes.AudActM2MCategoryDto, cache: this.dataContext.audActM2MCategoryDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "SurveyTemplateAudActM2MCategoryDto");
        };

        // surveyvhrevent x user groups
        this.getSurveyVhrEventM2MDrbacUserGroupDto = function (filter, forceReload) {
            var params = { entityType: entityTypes.VhrEventM2MDrbacUserGroupDto, cache: this.dataContext.surveyVhrEventM2MDrbacUserGroupDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "SurveyVhrEventM2MDrbacUserGroupDto");
        };

        // surveyvhrevent x users
        this.getSurveyVhrEventM2MAppUserDto = function (filter, forceReload) {
            var params = { entityType: entityTypes.VhrEventM2MAppUserDto, cache: this.dataContext.surveyVhrEventM2MAppUserDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "SurveyVhrEventM2MAppUserDto");
        };

        // survey DTO
        this.getSurveyDto = function (filter, forceReload) {
            var params = { key: filter.SurveyId, dataContext: this.dataContext.surveyDtos };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "SurveyDto");
        };
        this.getSurveyDtos = function (filter, forceReload) {
            var params = { entityType: entityTypes.SurveyDto, cache: this.dataContext.surveyDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "SurveyDtos");
        };
        this.getMySurveyDtos = function (filter, forceReload) {
            var params = { entityType: entityTypes.SurveyDto, cache: this.dataContext.surveyDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "MySurveyDtos");
        };
        this.getSurveyRecurrenceDtos = function (filter, forceReload) {
            var params = { entityType: entityTypes.SurveyRecurrenceDto, cache: this.dataContext.surveyRecurrenceDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "SurveyRecurrenceDtos");
        };
        //used when is compiling OnDemand survey
        this.getSurveyOnDemandClone = function (filter, forceReload) {
            var params = { key: filter.SurveyId, dataContext: this.dataContext.surveyDtos };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "SurveyOnDemandClone");
        };
        //auto clone survey on demand by email link
        this.getSurveyOnDemandOtatClone = function (filter, forceReload) {
            var params = { key: filter.SurveyId, dataContext: this.dataContext.surveyDtos };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "SurveyOnDemandOtatClone");
        };



        this.searchActDtos = function (filter, forceReload) {
            var params = { entityType: entityTypes.ActDto, cache: this.dataContext.actDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "SearchActDtos");
        };

        //#region Post data for survey compilation
        // survey question option DTO (post data in compilation)
        this.getSurveyQuestionOptionDto = function (filter, forceReload) {
            var params = { key: filter.SurveyQuestionOptionId, dataContext: this.dataContext.surveyQuestionOptionDtos };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "SurveyQuestionOptionDtos");
        };
        this.getSurveyQuestionOptionDtos = function (filter, forceReload) {
            var params = { entityType: entityTypes.SurveyQuestionOptionDto, cache: this.dataContext.surveyQuestionOptionDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "SurveyQuestionOptionDtos");
        };
        // surveyCompile DTO (post data in compilation)
        this.getSurveyCompileDto = function (filter, forceReload) {
            var params = { key: filter.SurveyId, dataContext: this.dataContext.surveyDtos };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "SurveyCompileDto");
        };
        //#endregion

        //#region Paged
        // SurveyTemplate
        this.getSurveyTemplateDtosPagedDm = function () {
            return ejDataManager.getEjManager('SurveyTemplateDtosPaged');
        };
        this.getSurveyTemplateDtosPagedDmQuery = function () {
            var dmObj = {};
            dmObj.dm = ejDataManager.getEjManager('');
            dmObj.query = ej.Query().from('SurveyTemplateDtosPaged');
            return dmObj;
        };

        // Survey
        this.getSurveysPagedDm = function () {
            return ejDataManager.getEjManager('SurveysDtoPaged');
        };
        this.getSurveysPagedDmQuery = function () {
            var dmObj = {};
            dmObj.dm = ejDataManager.getEjManager('');
            dmObj.query = ej.Query().from('SurveysDtoPaged');
            return dmObj;
        };

        //My survey
        this.getMySurveysPagedDm = function () {
            return ejDataManager.getEjManager('MySurveysDtoPaged');
        };
        this.getMySurveysPagedDmQuery = function () {
            var dmObj = {};
            dmObj.dm = ejDataManager.getEjManager('');
            dmObj.query = ej.Query().from('MySurveysDtoPaged');
            return dmObj;
        };

        // acts paged (with audit filters)
        this.getSearchActsPagedDm = function () {
            return ejDataManager.getEjManager('SearchActDtosPaged');
        };
        this.getSearchActsPagedDmQuery = function () {
            var dmObj = {};
            dmObj.dm = ejDataManager.getEjManager('');
            dmObj.query = ej.Query().from('SearchActDtosPaged');
            return dmObj;
        };

        // ActDto (dto)
        this.getActDtos = function (filter, forceReload) {
            var params = { entityType: entityTypes.ActDto, cache: this.dataContext.actDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "ActDtos");
        };
        this.getActDto = function (filter, forceReload) {
            var params = { key: filter.ActId, dataContext: this.dataContext.actDtos };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "ActDto");
        };

        // surveytemplate entity
        this.getSurveyTemplate = function (filter, forceReload) {
            var params = { key: filter.SurveyId, dataContext: this.dataContext.surveyTemplates };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "SurveyTemplates");
        };
        this.getSurveyTemplates = function (filter, forceReload) {
            var params = { entityType: entityTypes.SurveyTemplate, cache: this.dataContext.surveyTemplates.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "SurveyTemplates");
        };

        // surveysection entity
        this.getSurveySection = function (filter, forceReload) {
            var params = { key: filter.SurveySectionId, dataContext: this.dataContext.surveySections };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "SurveySections");
        };
        this.getSurveySections = function (filter, forceReload) {
            var params = { entityType: entityTypes.SurveySection, cache: this.dataContext.surveySections.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "SurveySections");
        };

        // surveysection entity
        this.getSurveyTemplateSection = function (filter, forceReload) {
            var params = { key: filter.SurveySectionId, dataContext: this.dataContext.surveySections };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "SurveyTemplateSections");
        };
        this.getSurveyTemplateSections = function (filter, forceReload) {
            var params = { entityType: entityTypes.SurveySection, cache: this.dataContext.surveySections.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "SurveyTemplateSections");
        };

        // surveyquestion entity
        this.getSurveyQuestion = function (filter, forceReload) {
            var params = { key: filter.SurveyQuestionId, dataContext: this.dataContext.surveyQuestions };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "SurveyQuestions");
        };
        this.getSurveyQuestions = function (filter, forceReload) {
            var params = { entityType: entityTypes.SurveyQuestion, cache: this.dataContext.surveyQuestions.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "SurveyQuestions");
        };

        this.getSurveyTemplateQuestion = function (filter, forceReload) {
            var params = { key: filter.SurveyQuestionId, dataContext: this.dataContext.surveyQuestions };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "SurveyTemplateQuestions");
        };
        this.getSurveyTemplateQuestions = function (filter, forceReload) {
            var params = { entityType: entityTypes.SurveyQuestion, cache: this.dataContext.surveyQuestions.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "SurveyTemplateQuestions");
        };

        // surveyanswersummary entity
        this.getSurveyAnswerSummariesBySurveyId = function (surveyId, forceReload) {
            var filter = { SurveyId: surveyId };
            var params = { entityType: entityTypes.SurveyAnswerSummary, cache: this.dataContext.surveyAnswerSummaries.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "SurveyAnswerSummariesBySurveyId");
        };
        this.getSurveyTemplateAnswerSummariesBySurveyId = function (surveyId, forceReload) {
            var filter = { SurveyId: surveyId };
            var params = { entityType: entityTypes.SurveyAnswerSummary, cache: this.dataContext.surveyAnswerSummaries.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "SurveyTemplateAnswerSummariesBySurveyId");
        };

        // surveyquestionoption entity
        this.getSurveyQuestionOption = function (filter, forceReload) {
            var params = { key: filter.SurveyQuestionOptionId, dataContext: this.dataContext.surveyQuestionOptions };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "SurveyQuestionOptions");
        };
        this.getSurveyQuestionOptions = function (filter, forceReload) {
            var params = { entityType: entityTypes.SurveyQuestionOption, cache: this.dataContext.surveyQuestionOptions.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "SurveyQuestionOptions");
        };

        this.getSurveyTemplateQuestionOption = function (filter, forceReload) {
            var params = { key: filter.SurveyQuestionOptionId, dataContext: this.dataContext.surveyQuestionOptions };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "SurveyTemplateQuestionOptions");
        };
        this.getSurveyTemplateQuestionOptions = function (filter, forceReload) {
            var params = { entityType: entityTypes.SurveyQuestionOption, cache: this.dataContext.surveyQuestionOptions.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "SurveyTemplateQuestionOptions");
        };

        // surveyoptionchoice entity
        this.getSurveyOptionChoice = function (filter, forceReload) {
            var params = { key: filter.SurveyOptionChoiceId, dataContext: this.dataContext.surveyOptionChoices };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "SurveyOptionChoices");
        };
        this.getSurveyOptionChoices = function (filter, forceReload) {
            var params = { entityType: entityTypes.SurveyOptionChoice, cache: this.dataContext.surveyOptionChoices.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "SurveyOptionChoices");
        };
        this.getSurveyOptionChoicesBySurveyTemplateId = function (filter, forceReload) {
            var params = { entityType: entityTypes.SurveyOptionChoice, cache: this.dataContext.surveyOptionChoices.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "SurveyOptionChoicesBySurveyTemplateId");
        };

        // surveyoptionchoicegroup entity
        this.getSurveyOptionChoiceGroup = function (filter, forceReload) {
            var params = { key: filter.SurveyOptionChoiceGroupId, dataContext: this.dataContext.surveyOptionChoiceGroups };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "SurveyOptionChoiceGroups");
        };
        this.getSurveyOptionChoiceGroups = function (filter, forceReload) {
            var params = { entityType: entityTypes.SurveyOptionChoiceGroup, cache: this.dataContext.surveyOptionChoiceGroups.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "SurveyOptionChoiceGroups");
        };

        // SurveyAnalysis
        this.getSurveyAnalysis = function (filter, forceReload) {
            var params = { entityType: entityTypes.SurveyAnalysis, cache: this.dataContext.surveyAnalysis.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "SurveyAnalysis");
        };

        // SurveyAnalysisDefinition
        this.getSurveyAnalysisDefinitions = function (filter, forceReload) {
            var params = { entityType: entityTypes.SurveyAnalysisDefinition, cache: this.dataContext.surveyAnalysisDefinitions.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "SurveyAnalysisDefinitions");
        };

        //#region HttpPost Methods
        this.updateSurveyTemplateToSurvey = function (filter) {
            var query = dataLayer.InvokeQuery("UpdateSurveyTemplateToSurvey", filter);
            return manager.executeQuery(query);
        };

        ////Http Post
        //this.updateSurveyLifeStatusBulk = function (vhrEventsId, moduleTargetId, surveyLifeStatus) {
        //    var objPost = { VhrEventsIdSerialized: JSON.stringify(vhrEventsId), ModuleTargetId: moduleTargetId, SurveyLifeStatus: surveyLifeStatus };

        //    var query = dataLayer.InvokeQuery("UpdateSurveyLifeStatusBulk", objPost);
        //    return manager.executeQuery(query);
        //};

        this.searchSurveys = function (filter) {
            var query = dataLayer.InvokeQuery("SearchSurveys", filter);
            return manager.executeQuery(query);
        };

        this.searchSurveyTemplates = function (filter) {
            var query = dataLayer.InvokeQuery("SearchSurveyTemplates", filter);
            return manager.executeQuery(query);
        };

        this.deleteSurveyOptionChoiceGroup = function (surveyOptionChoiceGroupId) {
            var objPost = { SurveyOptionChoiceGroupId: surveyOptionChoiceGroupId };

            var query = dataLayer.InvokeQuery("DeleteSurveyOptionChoiceGroup", objPost);
            return manager.executeQuery(query);
        };

        this.synchroSurveyAnalysis = function (filter) {
            var query = dataLayer.InvokeQuery("SynchroSurveyAnalysis", filter);
            return manager.executeQuery(query);
        };

        this.repairActionGrouped = function (vhrEventFilter) {
            var filter = { vhrEventFilter: vhrEventFilter };
            var query = dataLayer.InvokeQuery("RepairActionGrouped", filter);
            return manager.executeQuery(query);
        };

        this.getSurveySectionsPostMethod = function (filter) {
            var query = dataLayer.InvokeQuery("SurveySectionsPostMethod", filter);
            return manager.executeQuery(query);
        };

        this.cloneSurveyQuestion = function (filter) {
            var query = dataLayer.InvokeQuery("CloneSurveyQuestion", filter);
            return manager.executeQuery(query);
        };

        this.getSurveyQuestionsPostMethod = function (filter) {
            var query = dataLayer.InvokeQuery("SurveyQuestionsPostMethod", filter);
            return manager.executeQuery(query);
        };

        this.getSurveyQuestionOptionsPostMethod = function (filter) {
            var query = dataLayer.InvokeQuery("SurveyQuestionOptionsPostMethod", filter);
            return manager.executeQuery(query);
        };

        this.syncroActSourceObjects = function () {
            var filter = {};
            var query = dataLayer.InvokeQuery("SynchroActSourceObjects", filter);
            return manager.executeQuery(query);
        };
        //#endregion

        //cross controller (through wizard steps of vhrevents manual)
        this.surveyCreationClientData = null;                   // used to validate step event detail in insert SurveyCreationRequest wizard
        this.surveyCreationCollector = {};                      // used to mantains data collection in insert/update SurveyCreationRequest wizard

        // cross controller
        // this parameter is used to filter surveyslist passing by processlist view (combined with view parameter 'viewKind')
        this.SurveyProcessSelected = null;


        //#region DataManager
        this.getSurveyTemplateDataManager = function (supportData, filter, dmObj) {
            if (dmObj) {
                dmObj.complexParams = [{ filter: filter }];
                return dmObj;
            }

            dmObj = me.getSurveyTemplateDtosPagedDmQuery();
            dmObj.dmgrid = me.getSurveyTemplateDtosPagedDm();

            var ejDataManager = dmObj.dmgrid;
            ejDataManager.complexParams.push({ filter: filter });
            ejDataManager.fieldNameReplacer = {};
            // properties inserte are calculated before query is launched in breezeDataLayer service
            ejDataManager.onWhereCustomPredicate = {};

            ejDataManager.processResponseData = function (data) {
                if (data) {
                    dmObj.dm.currentViewChildGridData = [];

                    data.results = me.getAttachedEntities(data.results);
                    Lazy(data.results)
                        .each((item) => {
                            if (!item.cardSupport) {
                                item.cardSupport = {
                                    translations: {}
                                };
                            }

                            item.cardSupport.translations.group = supportData.translate('Group');
                            item.cardSupport.translations.type = supportData.translate('Type');
                        });
                    return data.results;
                }
            };

            ejDataManager.freeSearchFields = ['SurveyName', 'SurveyCode', 'SurveyTemplateGroupName', 'SurveyTemplateTypeName'];
            ejDataManager.freeSearchFieldsNumber = ['SurveyId'];

            dmObj.dm.onWhereCustomPredicate = ejDataManager.onWhereCustomPredicate;
            dmObj.dm.fieldNameReplacer = ejDataManager.fieldNameReplacer;
            dmObj.dm.complexParams = ejDataManager.complexParams;
            dmObj.dm.processResponseData = ejDataManager.processResponseData;
            return dmObj;
        }

        this.getSurveyDataManager = function (supportData, filter, dmObj) {
            if (dmObj) {
                dmObj.complexParams = [{ filter: filter }];
                return dmObj;
            }

            var vhrEventLifeStatusEnum = Nembus.Common.Library.util.parseEnum(me.dataContext.enumTypes.SurveyLifeStatus_Full, 'SurveyDto', supportData.translate);
            var expressionTypeEnumParsed = Nembus.Common.Library.util.parseEnum(me.dataContext.enumTypes.ExpressionType, null, supportData.translate);

            if (supportData.isMySurveys === true) {
                dmObj = me.getMySurveysPagedDmQuery();
                dmObj.dmgrid = me.getMySurveysPagedDm();
            } else {
                dmObj = me.getSurveysPagedDmQuery();
                dmObj.dmgrid = me.getSurveysPagedDm();
            }

            var ejDataManager = dmObj.dmgrid;

            ejDataManager.complexParams.push({ filter: filter });
            ejDataManager.fieldNameReplacer = {
                VhrEventLifeStatusName: 'SurveyLifeStatusId',
                SurveyExpireDate_display: 'SurveyExpireDate'
            };
            // properties inserte are calculated before query is launched in breezeDataLayer service
            ejDataManager.onWhereCustomPredicate = {
                VhrEventLifeStatusName: {
                    FieldType: this.FilterMenu.FieldType.Enum,
                    ReplaceFieldNameWith: 'SurveyLifeStatusId',
                    Datasource: vhrEventLifeStatusEnum.list.translated
                },
                SurveyExpireDate_display: {
                    FieldType: this.FilterMenu.FieldType.Date,
                    SupportData: {
                        FilterNameDest: 'filter',
                        FieldNameInFilter: 'SurveyExpireDate',
                        FilterExpression: expressionTypeEnumParsed.obj.normal.name_value
                    }
                },
            };

            ejDataManager.processResponseData = function (data) {
                if (data) {
                    dmObj.dm.currentViewChildGridData = [];

                    data.results = me.getAttachedEntities(data.results);
                    Lazy(data.results)
                        .each((item) => {
                            if (!item.cardSupport) {
                                item.cardSupport = {
                                    translations: {}
                                };
                            }

                            item.VhrEventLifeStatusNameNotTranslated = vhrEventLifeStatusEnum.obj.normal.value_name[item.SurveyLifeStatusId];
                            if (supportData.statusColorObj) {
                                item.VhrEventLifeStatusColor = supportData.statusColorObj[item.VhrEventLifeStatusNameNotTranslated];
                            }
                            item.VhrEventLifeStatusName = vhrEventLifeStatusEnum.obj.translated.value_name[item.SurveyLifeStatusId];
                            item.UsersUI = JSON.parse(Nembus.Common.Library.converter.decodeHtml(item.UsersAssignedSerialized));
                            item.UserGroupsUI = JSON.parse(Nembus.Common.Library.converter.decodeHtml(item.UserGroupsAssignedSerialized));
                            item.VhrEventLabelsUI = JSON.parse(Nembus.Common.Library.converter.decodeHtml(item.VhrEventLabelsSerialized));
                            item.SurveyExpireDate_display = Nembus.Aud.Library.UI.GetSurveyExpireDateParsedString(item, supportData.contextService.virtualContext, supportData.translate);

                            // format custom fields for grid cards
                            item.cardSupport.SurveyCompileDateSystem = Nembus.Common.Library.converter.date.formatDateTime(item.SurveyCompileDateSystem, supportData.contextService.virtualContext.cultureInfo, false);
                            item.cardSupport.SurveyExpireDate = item.SurveyExpireDate_display;
                            item.cardSupport.translations.group = supportData.translate('Group');
                            item.cardSupport.translations.type = supportData.translate('Type');
                            item.cardSupport.translations.status = supportData.translate('Status');
                        });
                    return data.results;
                }
            };

            ejDataManager.freeSearchFields = ['SurveyName', 'SurveyCode', 'TargetName', 'VhrEventTypeName', 'VhrEventGroupName'];
            ejDataManager.freeSearchFieldsNumber = ['SurveyId'];

            dmObj.dm.onWhereCustomPredicate = ejDataManager.onWhereCustomPredicate;
            dmObj.dm.fieldNameReplacer = ejDataManager.fieldNameReplacer;
            dmObj.dm.complexParams = ejDataManager.complexParams;
            dmObj.dm.processResponseData = ejDataManager.processResponseData;
            return dmObj;
        }
        //#endregion
    };

    audService.$inject = injectParams;
    audService.prototype = Object.create(nbsService.prototype);
    angular.module('nbs.aud.module').service('audService', audService);
});