﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'audService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function surveyTemplateTypesList($q, $scope, $timeout, $injector, audService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, audService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return surveyTemplateTypeSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, audService);

        var ribbon = new NbsAudRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var surveyTemplateTypeSelected = null;

        getSurveyTemplateTypes(true)
            .then(function (types) {
                var nbsGridDatacontext = new NbsAudGrid(vm.translate);
                nbsGridDatacontext.configureGrid('surveytemplatetype', null, null);
                nbsGridDatacontext.viewCommonBar();
                nbsGridDatacontext.setDatasource(types, false);
                vm.nbsGridDatacontext = nbsGridDatacontext;
            });

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.surveytemplatetypedetail", { 'surveyTemplateTypeId': args.data.SurveyTemplateTypeId, 'appStoreCode': appStoreCode });
        };

        vm.newAction = function () {
            navigationService.navigateTo("app.nav.surveytemplatetypedetail", { 'surveyTemplateTypeId': null, 'appStoreCode': appStoreCode });
        };

        vm.refreshAction = function () {
            getSurveyTemplateTypes(true)
                .then(function (types) {
                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: types
                    }
                });
        };

        vm.deleteAction = function () {
            if (!surveyTemplateTypeSelected) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            audService.deleteEntity(surveyTemplateTypeSelected);
            vm.submitAction()
                .then(function (saveResult) {
                    vm.refreshAction();
                });
        };

        vm.gridSelection = function (args) {
            $timeout(() => {
                surveyTemplateTypeSelected = args.data;

                if (surveyTemplateTypeSelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, true);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                }

                ribbon.refreshStatusButtons();
            });
        };

        function getSurveyTemplateTypes(forceReload) {
            var filter = {};
            var defer = $q.defer();

            audService.getSurveyTemplateTypes(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    }

    surveyTemplateTypesList.$inject = injectParams;
    surveyTemplateTypesList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('surveyTemplateTypesList', surveyTemplateTypesList);
});