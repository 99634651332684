﻿'use strict';

define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'utilService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function scheduledTaskStepSummary($q, $scope, $timeout, $injector, utilService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, utilService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, utilService);

        //var appStoreCode = navigationService.stateParams().appStoreCode;
        //var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var pParams = navigationService.stateParamsModal().parentParams;
        pParams = pParams ? pParams : navigationService.stateParams();

        // beardcrumb information
        var beardCrumbInfo = JSON.parse(pParams.beardCrumbInfo);
        Lazy(beardCrumbInfo.items).each((item) => { item.title = vm.translate(item.title); })
        vm.beardCrumbInfo = beardCrumbInfo;

        // navigation buttons visibility
        vm.setWizardButtonVisibility(pParams);

        vm.scheduledTaskSummary = utilService.scheduledTaskCollector;

        // formatted data for UI
        vm.scheduledTaskSummary.TaskTimeForLayout = Nembus.Common.Library.converter.timespan.jsonToString(vm.scheduledTaskSummary.Time);
        
        vm.modalEnd = function () {
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalEnd);
            navigationService.closeCurrentModal(modalResult);
        };

        vm.modalPrevious = function () {
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalPrevious);
            navigationService.closeCurrentModal(modalResult);
        };

        vm.modalCancel = function () {
            navigationService.confirmExitWizard()
                            .then(function (result) {
                                if (result) {
                                    var modalResult = getModalResult(navigationService.navigationActionTypes.modalCancel);
                                    navigationService.closeCurrentModal(modalResult);
                                }
                            });
        };

        function getModalResult(dialogResult) {
            var modalResult = new navigationService.modalResult()
            //modalResult.data = ; last step, no data to collect
            modalResult.sourceParams = pParams;
            modalResult.dialogResult = dialogResult;
            return modalResult;
        }
    };

    scheduledTaskStepSummary.$inject = injectParams;
    scheduledTaskStepSummary.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('scheduledTaskStepSummary', scheduledTaskStepSummary);
});