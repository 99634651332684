﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$injector', '$scope', '$timeout', 'reportService', 'integrationService', 'navigationService', 'contextService', 'foundationService'];
    function reportDefinitionDetail($q, $injector, $scope, $timeout, reportService, integrationService, navigationService, contextService, foundationService) {

        var vm = this;
        nbsVm.call(vm, reportService, navigationService, contextService, $q, $injector, $timeout);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, null);
        ribbon.addHomeTab({ showGroupNew: false, showGroupManage: false, showGroupPanel: false, showGroupReports: true });
        vm.ribbonDatacontext = ribbon;

        var reportDefinitionId = navigationService.stateParams().reportDefinitionId;
        var reportEngineTypeEnum = Nembus.Common.Library.util.parseEnum(reportService.dataContext.enumTypes.ReportEngineType, null, null).obj.normal.name_value;
        var canDownload = false;
        var currentIntegrationQueryFilter = {};


        reportService.initCurrentReport();

        if (reportDefinitionId) {
            getReportDefinitionDto(false, reportDefinitionId);
        }


        //#region report filter component
        var currentChildScope = null;
        vm.initCmsviewUsersettingMenu = function (params) {
            currentChildScope = this;

            vm.cmsviewUsersettingDatacontext = {
                settingCode: reportDefinitionId,
                settingDataCallback: params.settingDataCallback
            };
        };
        vm.onCmsViewUserSettingSelection = function (args) {
            currentChildScope.onCmsViewUserSettingSelection(args);
        }
        //#endregion report filter component

        vm.exportReportToPdfAction = function () {
            setReportData('pdf');
        };

        vm.exportReportToWordAction = function () {
            setReportData('openword');
        };

        vm.exportReportToXmlAction = function () {
            setReportData('xml');
        };

        vm.exportReportToExcelAction = function () {
            if (reportService.currentReport.reportDefinition.ReportEngineTypeId === reportEngineTypeEnum.NativeExcel) {
                createIntegrationRequest();
            } else {
                setReportData('openexcel');
            }
        };

        vm.exportReportToHtmlAction = function () {
            setReportData('html');
        };

        vm.setIntegrationQueryFilter = function (filter) {
            currentIntegrationQueryFilter = JSON.stringify(filter);
        };

        function getReportDefinitionDto(forceReload, reportDefinitionId) {
            var filter = { ReportDefinitionId: reportDefinitionId };
            var defer = $q.defer();

            reportService.getReportDefinitionDto(filter, forceReload)
                .then(function (data) {
                    reportService.currentReport.reportDefinition = data.results[0];

                    if (reportService.currentReport.reportDefinition) {
                        vm.viewTitle = Nembus.Common.Library.report.getReportViewTitle(reportService.currentReport.reportDefinition);

                        if (reportService.currentReport.reportDefinition.ReportEngineTypeId === reportEngineTypeEnum.NativeExcel) {
                            // NativeExcel
                            ribbon.setRibbonStatusButton(ribbon.Buttons.PrintReportExcel, true);
                        } else {
                            // AutoTag or Html
                            ribbon.setRibbonStatusButton(ribbon.Buttons.PrintReportExcel, true);
                            ribbon.setRibbonStatusButton(ribbon.Buttons.PrintReportPdf, true);
                            ribbon.setRibbonStatusButton(ribbon.Buttons.PrintReportHtml, true);
                            ribbon.setRibbonStatusButton(ribbon.Buttons.PrintReport, true);
                            ribbon.setRibbonStatusButton(ribbon.Buttons.PrintReportWord, true);
                            ribbon.setRibbonStatusButton(ribbon.Buttons.PrintReportXml, true);
                        }

                        ribbon.refreshStatusButtons();
                    }

                    defer.resolve(true);
                })
                .catch(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getIntegrationDefinitions(forceReload, integrationDefinitionId) {
            var filter = { IntegrationDefinitionId: integrationDefinitionId };
            var defer = $q.defer();

            integrationService.getIntegrationDefinitions(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results[0]);
                })
                .catch(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        }

        function entityIsValid(entity) {
            if (entity && entity.entityAspect) {
                entity.entityAspect.validateEntity();
                $timeout(() => { // NO REMOVE
                    // $scope.$apply() is triggered by $timeout 
                });
                return !entity.entityAspect.hasValidationErrors;
            } else {
                return true;
            }
        }

        function setReportData(format) {
            var continueExecution = true;
            if (reportService.currentReport.isCustomValidatedCallback) {
                continueExecution = reportService.currentReport.isCustomValidatedCallback();
            }

            if (!continueExecution) {
                return;
            }

            if (reportService.currentReport.prepareReportFilterCallback) {
                reportService.currentReport.prepareReportFilterCallback();
            }

            if (entityIsValid(reportService.currentReport.formEntityToValidate)) {
                vm.setReportData({
                    reportDefinition: reportService.currentReport.reportDefinition,
                    reportArgs: reportService.currentReport.reportArgs,
                    concatName: reportService.currentReport.concatName,
                    replaceName: reportService.currentReport.reportDefinition.ReportDefinitionReferenceCode + ' - ' + reportService.currentReport.reportDefinition.ReportDefinitionName
                });

                vm.exportReport(format);
            }
        }

        function integrationRequestAddBatchRequest() {
            contextService.addBatchRequest({
                requestId: vm.currentIntegrationRequest.BatchRequestId,
                requestName: vm.currentIntegrationRequest.BatchRequestName,
                requestStatus: 'inserted'
            });
        }

        function createIntegrationRequest() {
            var continueExecution = true;
            if (reportService.currentReport.isCustomValidatedCallback) {
                continueExecution = reportService.currentReport.isCustomValidatedCallback();
            }

            if (!continueExecution) {
                return;
            }

            if (reportService.currentReport.prepareReportFilterCallback) {
                reportService.currentReport.prepareReportFilterCallback();
            }

            if (entityIsValid(reportService.currentReport.formEntityToValidate)) {
                if (!reportService.currentReport.reportDefinition.IntegrationDefinitionId) {
                    console.error('NativeExcel: report definition IntegrationDefinitionId mandatory');
                } else {
                    getIntegrationDefinitions(false, reportService.currentReport.reportDefinition.IntegrationDefinitionId)
                        .then((integrationDefinition) => {
                            var filename = reportService.currentReport.reportDefinition.ReportDefinitionReferenceCode + ' - ' + reportService.currentReport.reportDefinition.ReportDefinitionName;

                            vm.currentIntegrationRequest = integrationService.dataContext.integrationRequests.add({
                                VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId,
                                IntegrationRequestId: breeze.core.getUuid(),
                                IntegrationRequestDirectionId: Lazy(integrationService.dataContext.enumTypes.IntegrationRequestDirection).findWhere({ name: 'Out' }).value,
                                IntegrationRequestResultId: Lazy(integrationService.dataContext.enumTypes.IntegrationRequestResult).findWhere({ name: 'None' }).value,
                                IntegrationRequestStatusId: Lazy(integrationService.dataContext.enumTypes.IntegrationRequestStatus).findWhere({ name: 'Inserted' }).value,
                                IntegrationDefinitionId: integrationDefinition.IntegrationDefinitionId,
                                IsComparedByCode: false,
                                UseTemplateDefinition: false,
                                IntegrationRequestName: filename,
                                IntegrationTemplateFormatId: integrationDefinition.IntegrationTemplateFormatId,
                                EtlRequired: integrationDefinition.EtlRequired,
                                QueryFilter: currentIntegrationQueryFilter
                            });

                            integrationService.saveChanges()
                                .then((saveResult) => {
                                    if (vm.currentIntegrationRequest.IsBatch) {
                                        integrationRequestAddBatchRequest();
                                    }
                                    else {
                                        canDownload = !vm.currentIntegrationRequest.IntegrationRequestResultId !== 1;

                                        if (canDownload) {
                                            contextService.addDownloadFile({
                                                fileId: vm.currentIntegrationRequest.IntegrationFileId,
                                                fileName: filename,
                                                fileType: 'Excel',
                                                downloadData: {
                                                    entityGuid: vm.currentIntegrationRequest.IntegrationFileId,
                                                    asAttach: false,
                                                    downloadType: "byGuid"
                                                }
                                            });
                                        }
                                    }
                                });
                        });
                }
            }
        }
    }

    reportDefinitionDetail.$inject = injectParams;
    reportDefinitionDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('reportDefinitionDetail', reportDefinitionDetail);
});