﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'utilService', 'navigationService', 'checkStateChangeService', 'contextService', 'formSummaryService'];
    var hrAddressDetail = function ($q, $scope, $timeout, $injector, foundationService, utilService, navigationService, checkStateChangeService, contextService, formSummaryService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, foundationService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        checkStateChangeService.checkFormOnStateChange($scope, foundationService);

        ribbon.setRibbonStatusButton(ribbon.Buttons.Refresh, true);
        ribbon.refreshStatusButtons();
        
        // get array items for form summary
        vm.formSummary = formSummaryService.getDatasource();

        var referral = navigationService.stateParams().referral;
        var addressKindEnumParsed = Nembus.Common.Library.util.parseEnum(foundationService.dataContext.enumTypes.AddressKind, 'Address', vm.translate);
        var addressKindObj = addressKindEnumParsed.obj.normal.name_value;
        var addressKindObjForBind = addressKindEnumParsed.obj.translated.value_name;
        var addressKindProcessed = [];
        var hrContactsId = navigationService.stateParams().hrContactsId;
        var genericElementCode = 'Countries';

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('address', null);
        nbsGridDatacontext.allowPaging = false;
        nbsGridDatacontext.allowSearching = false;
        nbsGridDatacontext.allowGrouping = false;
        nbsGridDatacontext.setAdaptToPage();
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        vm.countries = [];
        vm.currentAddress = null;
        vm.currentHrContactAddresses = [];

        loadAddressKind();
        function loadAddressKind() {
            switch (referral.toLowerCase()) {
                case "company":
                    addressKindProcessed = Lazy(addressKindEnumParsed.list.translated)
                        .filter((item) => {
                            return item.value === addressKindObj.RegisteredOffice
                                || item.value === addressKindObj.Billing
                                || item.value === addressKindObj.AddressCustomType;
                        }).toArray();
                    break;

                case "virtualcompany":
                    addressKindProcessed = Lazy(addressKindEnumParsed.list.translated)
                        .filter((item) => {
                            return item.value === addressKindObj.RegisteredOffice
                                || item.value === addressKindObj.AddressCustomType;
                        }).toArray();
                    break;
                default:
                    addressKindProcessed = Lazy(addressKindEnumParsed.list.translated)
                        .filter((item) => {
                            return item.value === addressKindObj.Home
                                || item.value === addressKindObj.Job
                                || item.value === addressKindObj.Residence
                                || item.value === addressKindObj.RegisteredOffice
                                || item.value === addressKindObj.Shipping
                                || item.value === addressKindObj.AddressCustomType;
                        }).toArray();
                    break;
            }
        }

        if (hrContactsId) {
            vm.addressKind = addressKindProcessed;

            loadData(true);
        }

        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getGenericElements(forceReload, genericElementCode));
            funcsPromises.push(getHrContact(forceReload, hrContactsId));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.countries = results[0];
                    vm.currentHrContactAddresses = results[1];

                    Lazy(vm.currentHrContactAddresses.Addresses)
                        .each((item) => {
                            item.AddressKindName = addressKindObjForBind[item.AddressKindId];
                            var addressCountry = Lazy(vm.countries).findWhere({ value: item.CountryId });
                            item.CountryName = addressCountry ? addressCountry.name : '';
                        });

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.currentHrContactAddresses.Addresses
                    }
                });
        }

        vm.refreshAction = function () {
            loadData(true)
        };

        function getHrContact(forceReload, hrContactsId) {
            var filter = { ContactId: hrContactsId };
            var defer = $q.defer();

            foundationService.getHrContact(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results[0]);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getGenericElements(forceReload, genericElementCode) {
            var filter = { GenericElementCode: genericElementCode };
            var defer = $q.defer();

            utilService.getGenericElements(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results[0].PairValuesDropDown);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        // get reference of modifyAction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parentm = vm.modifyAction;
        vm.modifyAction = function () {
            parentm().then(
                //enable disable button new & delete because is custom behavior
                //generally saveResult eneble or disable only save button 
                function (saveResult) {
                    if (vm.viewContext.isEditingState) {
                        ribbon.setRibbonStatusButton(ribbon.Buttons.New,  true);
                        ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, true);
                    } else {
                        ribbon.setRibbonStatusButton(ribbon.Buttons.New,  false);
                        ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                    }

                    ribbon.refreshStatusButtons();
                }
            );
        };

        vm.modalShow = function (action) {
            vm.modalInstance = navigationService.modal().open(vm.modalOptions({
                windowClass: vm.NBS_MODAL_CLASS_FORM_MD,
                templateUrl: 'addressTemplate',
                scope: $scope
            }));
            vm.modalInstance.result.then(function () {
                vm.currentAddress.AddressKindName = addressKindObjForBind[vm.currentAddress.AddressKindId];
                vm.currentAddress.CountryName = Lazy(vm.Country).findWhere({ value: vm.currentAddress.CountryId }).name;
                vm.currentHrContactAddresses.push(vm.currentAddress);
                vm.refreshAction();
            }, function () {
                //dismiss
                foundationService.rejectChanges();
            });
            vm.modalInstance.ok = function () {
                vm.submitAction()
                    .then(function (data) {
                        vm.refreshAction();
                        vm.modalInstance.close();
                    });
            }
            vm.modalInstance.cancel = function () {
                vm.modalInstance.dismiss();
            }
        };

        vm.newAction = function () {
            vm.currentAddress = foundationService.dataContext.addresses.add({
                HrContactsId: hrContactsId,
                AddressKindId: null,
                CountryId: null
            });

            vm.modalShow();
        };

        vm.deleteAction = function () {
            if (!vm.currentAddress) {
                return;
            }            

            //set entity as deleted
            foundationService.deleteEntity(vm.currentAddress);

            vm.submitAction()
                .then(function (data) {
                    vm.refreshAction();
                    vm.modalInstance.close();
                });
        };

        vm.rowDblClick = function (args) {
            vm.modalShow();
        };

        vm.gridSelection = function (args) {
            vm.currentAddress = args.data;
        };
    };

    hrAddressDetail.$inject = injectParams;
    hrAddressDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('hrAddressDetail', hrAddressDetail);
});