﻿'use strict';
define([], function () {

    var injectParams = ['$timeout'];
    var nbsFasttabComponent = function ($timeout) {

        var vm = this;
        vm.isDisabled = true;

        vm.$onInit = function () {
        };

        vm.$onChanges = function (changes) {
            if (changes.nbsDataContext && changes.nbsDataContext.currentValue) {
                var ftc = changes.nbsDataContext.currentValue;
                ftc.FastTabs = Lazy(ftc.FastTabs).reject({ NgIf: false}).toArray();

                Lazy(ftc.FastTabs)
                    .each((fastTab) => {
                        fastTab.Links = Lazy(fastTab.Links).reject({ NgIf: false }).toArray();
                    });

                vm.ftc = ftc;
            }
            if (changes.disabled && changes.disabled.currentValue) {
                $timeout(() => {
                    vm.isDisabled = changes.disabled.currentValue;
                });                
            }
        };

        vm.onFtcClick = function (item) {
            if (vm.isDisabled) {
                return;
            }

            if (item.IsFastTab) {
                $('#' + vm.ftc.Id).find(".panel-group-selected").removeClass('panel-group-selected');
                $('#' + vm.ftc.Id).find(".panel-link-selected").removeClass('panel-link-selected');
                $('#' + vm.ftc.Id).find("[id='" + item.Id + "']").find(".panel-heading").addClass("panel-group-selected");
            } else if (item.IsLink) {
                $('#' + vm.ftc.Id).find(".panel-group-selected").removeClass('panel-group-selected');
                $('#' + vm.ftc.Id).find(".panel-link-selected").removeClass('panel-link-selected');
                $('#' + vm.ftc.Id).find("[id='" + item.Id + "']").parents("div.panel").find(".panel-heading").addClass("panel-group-selected");
                $('#' + vm.ftc.Id).find("[id='" + item.Id + "']").addClass("panel-link-selected");
            }

            vm.ftcOnclickEvent({ event: { id: item.Id } });
        };
    };

    nbsFasttabComponent.$inject = injectParams;
    angular.module('nbs.rds.module').component('nbsFasttab', {
        templateUrl: '/rds/common/components/nbsFasttabComponent/_?c=' + window.codeCache(),
        controller: nbsFasttabComponent,
        controllerAs: 'vm',
        bindings: {
            nbsDataContext: "<",
            ftcOnclickEvent: '&',
            uiContext: "<",
            isDisabled: "<"
        }
    });
});

