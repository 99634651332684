﻿'use strict';

define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'audService', 'vhreventService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function surveyCreationStepDetail($q, $scope, $timeout, $injector, foundationService, audService, vhreventService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, foundationService);

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);

        var moduleTargets = contextService.virtualContext.ModuleTargets;
        vm.moduleTargetsAll = Lazy(moduleTargets).reject({ ModuleTargetCode: 'MTSEM' }).toArray();
        vm.moduleTargets = vm.moduleTargetsAll;

        vm.surveyCreationPatternObj = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyCreationPattern, null, vm.translate).obj.normal.name_value;

        var radioCompileTypeDatasource = [
            { value: vm.surveyCreationPatternObj.Single, label: 'Single' },
            { value: vm.surveyCreationPatternObj.Recurrent, label: 'Recurrent' },
            { value: vm.surveyCreationPatternObj.OnDemand, label: 'OnCall' }
        ];
        vm.radioCompileTypeList = new NbsRadioList(vm.translate);
        vm.radioCompileTypeList.addData(radioCompileTypeDatasource, 'value', 'label');

        var pParams = navigationService.stateParamsModal().parentParams;
        pParams = pParams ? pParams : navigationService.stateParams();

        // navigation buttons visibility
        vm.setWizardButtonVisibility(pParams);

        // beardcrumb information
        var beardCrumbInfo = JSON.parse(pParams.beardCrumbInfo);
        Lazy(beardCrumbInfo.items).each((item) => { item.title = vm.translate(item.title); });
        vm.beardCrumbInfo = beardCrumbInfo;

        vm.surveyCreationClientData = audService.dataContext.surveyCreationRequests.add({
            SurveyCreationRequestId: NbsGuidManager.newGuid()
        });

        // refill data form
        if (audService.surveyCreationCollector) {
            vm.surveyCreationClientData.SurveyCreationRequestCode = audService.surveyCreationCollector.SurveyCreationRequestCode;
            vm.surveyCreationClientData.SurveyCreationRequestName = audService.surveyCreationCollector.SurveyCreationRequestName;
            vm.surveyCreationClientData.SurveyCreationRequestDescription = audService.surveyCreationCollector.SurveyCreationRequestDescription;
            vm.surveyCreationClientData.ModuleTargetId = audService.surveyCreationCollector.ModuleTargetId;
            vm.surveyCreationClientData.VhrEventTypeId = audService.surveyCreationCollector.VhrEventTypeId;
            vm.surveyCreationClientData.VhrEventGroupId = audService.surveyCreationCollector.VhrEventGroupId;
            vm.surveyCreationClientData.RecurrenceStartDate = audService.surveyCreationCollector.RecurrenceStartDate;
            vm.surveyCreationClientData.RecurrenceEndDate = audService.surveyCreationCollector.RecurrenceEndDate;
            vm.surveyCreationClientData.EventStartTime = "PT0H0M";
            vm.surveyCreationClientData.EventEndTime = "PT0H0M";
            vm.surveyCreationClientData.SurveyIdSerialized = audService.surveyCreationCollector.SurveyIdSerialized;
            vm.surveyCreationClientData.VirtualHumanResourceIds = audService.surveyCreationCollector.VirtualHumanResourceIds;
            vm.surveyCreationClientData.SurveyTemplateIdSerialized = audService.surveyCreationCollector.SurveyTemplateIdSerialized;  // survey template
            vm.surveyCreationClientData.SurveyCreationRequestCompileTypeId = audService.surveyCreationCollector.SurveyCreationRequestCompileTypeId;
            vm.surveyCreationClientData.CopyOptionValueFromSource = audService.surveyCreationCollector.CopyOptionValueFromSource;
            vm.surveyCreationClientData.CopyOptionWeightFromSource = audService.surveyCreationCollector.CopyOptionWeightFromSource;
            vm.surveyCreationClientData.CopyOptionChoiceFromSource = audService.surveyCreationCollector.CopyOptionChoiceFromSource;
            vm.surveyCreationClientData.CopyOptionsCheckedFromSource = audService.surveyCreationCollector.CopyOptionsCheckedFromSource;            
            vm.surveyCreationClientData.ValidationSchemaId = audService.surveyCreationCollector.ValidationSchemaId;
            vm.surveyCreationClientData.SurveyCreationRequestCompileTypeId = audService.surveyCreationCollector.SurveyCreationRequestCompileTypeId;
        }

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getVhrEventTypes(forceReload, moduleTargetId, false));
            funcsPromises.push(getVhrEventGroups(forceReload, moduleTargetId, false));

            $q.all(funcsPromises)
                .then(function () {
                    //
                });
        }

        vm.modalNext = function () {
            vm.surveyCreationClientData.CronExpression = '-';  // only to pass validation


            // if NOT event is recurrent, startdate and enddate must be the same
            switch (vm.surveyCreationClientData.SurveyCreationRequestCompileTypeId) {
                case vm.surveyCreationPatternObj.Single:
                    vm.surveyCreationClientData.RecurrenceEndDate = vm.surveyCreationClientData.RecurrenceStartDate;
                    break;
                case vm.surveyCreationPatternObj.Recurrent:
                    break;
                case vm.surveyCreationPatternObj.OnDemand:
                    vm.surveyCreationClientData.RecurrenceStartDate = moment().toDate();
                    vm.surveyCreationClientData.RecurrenceEndDate = moment().toDate();
                    break;
            }


            //force validation on entity (manual metadata only client)
            vm.surveyCreationClientData.entityAspect.validateEntity();
            if (!vm.surveyCreationClientData.entityAspect.hasValidationErrors) {
                audService.rejectChanges();

                // close modal and returns client data object
                var modalResult = getModalResult(navigationService.navigationActionTypes.modalNext);
                navigationService.closeCurrentModal(modalResult);
            }
        };

        vm.modalPrevious = function () {
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalPrevious);
            audService.rejectChanges();
            navigationService.closeCurrentModal(modalResult);
        };

        vm.modalCancel = function () {
            navigationService.confirmExitWizard()
                .then(function (result) {
                    if (result) {
                        audService.rejectChanges();
                        var modalResult = getModalResult(navigationService.navigationActionTypes.modalCancel);
                        navigationService.closeCurrentModal(modalResult);
                    }
                });
        };

        function getVhrEventTypes(forceReload, moduleTargetId, isBom) {
            var filter = { ModuleTargetId: moduleTargetId, IsBomType: isBom };
            var defer = $q.defer();

            vhreventService.getVhrEventTypes(filter, forceReload)
                .then(function (data) {
                    vm.vhrEventTypes = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrEventGroups(forceReload, moduleTargetId, isBom) {
            var filter = { ModuleTargetId: moduleTargetId, IsBomGroup: isBom };
            var defer = $q.defer();

            vhreventService.getVhrEventGroups(filter, forceReload)
                .then(function (data) {
                    vm.vhrEventGroups = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getModalResult(dialogResult) {
            var modalResult = new navigationService.modalResult();
            var moduleTargetName = vm.surveyCreationClientData.ModuleTargetId
                ? Lazy(vm.moduleTargetsAll).findWhere({ ModuleTargetId: Number(vm.surveyCreationClientData.ModuleTargetId) }).ModuleTargetName
                : null;
            var vhrEventTypeName = vm.surveyCreationClientData.VhrEventTypeId
                ? Lazy(vm.vhrEventTypes).findWhere({ VhrEventTypeId: Number(vm.surveyCreationClientData.VhrEventTypeId) }).VhrEventTypeName
                : null;
            var vhrEventGroupName = vm.surveyCreationClientData.VhrEventGroupId
                ? Lazy(vm.vhrEventGroups).findWhere({ VhrEventGroupId: Number(vm.surveyCreationClientData.VhrEventGroupId) }).VhrEventGroupName
                : null;

            var resultData = {
                SurveyCreationRequestCode: vm.surveyCreationClientData.SurveyCreationRequestCode,
                SurveyCreationRequestName: vm.surveyCreationClientData.SurveyCreationRequestName,
                SurveyCreationRequestDescription: vm.surveyCreationClientData.SurveyCreationRequestDescription,
                ModuleTargetId: vm.surveyCreationClientData.ModuleTargetId,
                ModuleTargetName: moduleTargetName,
                VhrEventGroupId: vm.surveyCreationClientData.VhrEventGroupId,
                VhrEventGroupName: vhrEventGroupName,
                VhrEventTypeId: vm.surveyCreationClientData.VhrEventTypeId,
                VhrEventTypeName: vhrEventTypeName,
                VhrEventTemplateId: vm.surveyCreationClientData.VhrEventTemplateId,
                RecurrenceStartDate: vm.surveyCreationClientData.RecurrenceStartDate,
                EventStartTime: vm.surveyCreationClientData.EventStartTime,
                RecurrenceEndDate: vm.surveyCreationClientData.RecurrenceEndDate,
                EventEndTime: vm.surveyCreationClientData.EventEndTime,
                VirtualHumanResourceIds: vm.surveyCreationClientData.VirtualHumanResourceIds,
                SurveyTemplateIdSerialized: vm.surveyCreationClientData.SurveyTemplateIdSerialized,
                SurveyIdSerialized: vm.surveyCreationClientData.SurveyIdSerialized,
                SurveyCreationRequestCompileTypeId: vm.surveyCreationClientData.SurveyCreationRequestCompileTypeId,
                CopyOptionValueFromSource: vm.surveyCreationClientData.CopyOptionValueFromSource,
                CopyOptionWeightFromSource: vm.surveyCreationClientData.CopyOptionWeightFromSource,
                CopyOptionChoiceFromSource: vm.surveyCreationClientData.CopyOptionChoiceFromSource,
                CopyOptionsCheckedFromSource: vm.surveyCreationClientData.CopyOptionsCheckedFromSource,                
                ValidationSchemaId: vm.surveyCreationClientData.ValidationSchemaId
            };

            modalResult.data = resultData;
            modalResult.sourceParams = pParams;

            // Important: permits to skip nexts steps (on next and previous)
            modalResult.stepsToSkip = [];
            if (vm.surveyCreationClientData.SurveyCreationRequestCompileTypeId !== vm.surveyCreationPatternObj.Recurrent) {
                modalResult.stepsToSkip.push('cronExpressionDetail');            // skip cron expression
            }

            modalResult.dialogResult = dialogResult;
            return modalResult;
        }
    }

    surveyCreationStepDetail.$inject = injectParams;
    surveyCreationStepDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('surveyCreationStepDetail', surveyCreationStepDetail);
});