﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {
    /// R-245-550-1
    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'actService', 'workflowService', 'foundationService', 'audService', 'reportService', 'navigationService', 'checkStateChangeService', 'contextService', 'utilService', 'vhreventService'];
    var htmlToPdfAudActsExecutionSyntesisReportForm = function ($q, $scope, $timeout, $injector, actService, workflowService, foundationService, audService, reportService, navigationService, checkStateChangeService, contextService, utilService, vhreventService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        var checkStateChangeParams = { dirtyFlagIsDisabled: vm.isDirtyDisabled };
        checkStateChangeService.checkFormOnStateChange($scope, foundationService, checkStateChangeParams);

        // all form items are enabled
        vm.setIsEditingReport();

        // entity to validate
        vm.htmlToPdfAudActsKpiClientData = audService.dataContext.htmlToPdfAudActsKpiClientDatas.add({
            HtmlToPdfAudActsKpiClientDataId: NbsGuidManager.newGuid()
        });

        var appStoreCode = 'aud';
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var actStatusEnumObj = Nembus.Common.Library.util.parseEnum(actService.dataContext.enumTypes.ActStatus, null, vm.translate).obj.normal.name_value;
        var expressionTypeEnum = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.ExpressionType, null, vm.translate).obj.normal.name_value;
        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;

        var workflowStatusKindEnumParsed = Nembus.Common.Library.util.parseEnum(workflowService.dataContext.enumTypes.WorkflowStatusKind, 'WorkflowStatus', vm.translate);
        var workflowStatusKindObj = workflowStatusKindEnumParsed.obj.normal.name_value;
        var workflowStatusKindEnumObjTranslated = workflowStatusKindEnumParsed.obj.translated.value_name;

        var actPriorityEnumParsed = Nembus.Common.Library.util.parseEnum(actService.dataContext.enumTypes.ActPriority, 'Act', vm.translate);
        var priorityObjTranslated = actPriorityEnumParsed.obj.translated.value_name;

        var priorityObj = Nembus.Common.Library.util.parseEnum(actService.dataContext.enumTypes.ActPriority, null, vm.translate).obj.normal.name_value;
        var addEmptyActionAssignment = false;

        var dsCallback = function () { return vhreventService.getVhrEventTypes({ AppStoreBw: appStoreBw }, false); };
        var selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventType', viewTitle: vm.translate('SurveyDto.VhrEventTypeId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventType = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalVhrEventType.setOnSelectFnCallback(function () {
            if (vm.nbsSelectionGridWithModalVhrEventType.selectedIds) {
                vm.currentVhrEventFilter.VhrEventTypesId = vm.nbsSelectionGridWithModalVhrEventType.selectedIds;
            } else {
                vm.currentVhrEventFilter.VhrEventTypesId = [];
            }
            setCurrentVhrEventFilter();
        });

        dsCallback = function () { return vhreventService.getVhrEventGroups({ AppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventGroup', viewTitle: vm.translate('SurveyDto.VhrEventGroupId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventGroup = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalVhrEventGroup.setOnSelectFnCallback(function () {
            if (vm.nbsSelectionGridWithModalVhrEventGroup.selectedIds) {
                vm.currentVhrEventFilter.VhrEventGroupsId = vm.nbsSelectionGridWithModalVhrEventGroup.selectedIds;
            } else {
                vm.currentVhrEventFilter.VhrEventGroupsId = [];
            }
            setCurrentVhrEventFilter();
        });

        dsCallback = function () { return foundationService.getVhrTypes(false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrType', viewTitle: vm.translate('TargetDto.TargetTypeId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrType = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalVhrType.setOnSelectFnCallback(function () {
            if (vm.nbsSelectionGridWithModalVhrType.selectedIds) {
                vm.currentActFilter.VhrTypeIds = vm.nbsSelectionGridWithModalVhrType.selectedIds;
            } else {
                vm.currentActFilter.VhrTypeIds = [];
            }
        });

        dsCallback = function () { return foundationService.getVhrGroups(false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrGroup', viewTitle: vm.translate('TargetDto.TargetGroupId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrGroup = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalVhrGroup.setOnSelectFnCallback(function () {
            if (vm.nbsSelectionGridWithModalVhrGroup.selectedIds) {
                vm.currentActFilter.VhrGroupIds = vm.nbsSelectionGridWithModalVhrGroup.selectedIds;
            } else {
                vm.currentActFilter.VhrGroupIds = [];
            }
        });

        vm.nbsAuthorDrbacModalObj = new NbsAssigneeSelectorModal({
            translator: vm.translate,
            suspendDirty: vm.suspendDirty,
            navigationService: navigationService,
            contextUserForSelection: null,
            getServerVarFn: Nembus.Common.Library.server.getServerVar
        });
        vm.nbsAuthorDrbacModalObj.viewUsers();
        vm.nbsAuthorDrbacModalObj.setOnSelectFnCallback(function () {
            vm.currentSurveyVhrEventFilter.CompilerUserPersonIds = vm.nbsAuthorDrbacModalObj.appUserMultiselectSelectedIds();
        });

        vm.nbsExecutorDrbacModalObj = new NbsAssigneeSelectorModal({
            translator: vm.translate,
            suspendDirty: vm.suspendDirty,
            navigationService: navigationService,
            contextUserForSelection: null,
            getServerVarFn: Nembus.Common.Library.server.getServerVar
        });
        vm.nbsExecutorDrbacModalObj.viewUsers();
        vm.nbsExecutorDrbacModalObj.setOnSelectFnCallback(function () {
            vm.currentActFilter.ActExecutorIds = vm.nbsExecutorDrbacModalObj.appUserMultiselectSelectedIds();
        });

        var stepsToRender = [];

        // init filters
        vm.currentActFilter = {
            ActStatusId: actStatusEnumObj.Opened,
            ActExpireDate1Expression: expressionTypeEnum.GreaterThanOrEqual,
            ActExpireDate2Expression: expressionTypeEnum.LessThanOrEqual,
            ActStartDate1Expression: expressionTypeEnum.GreaterThanOrEqual,
            ActStartDate2Expression: expressionTypeEnum.LessThanOrEqual,
            ActExecutionDate1Expression: expressionTypeEnum.GreaterThanOrEqual,
            ActExecutionDate2Expression: expressionTypeEnum.LessThanOrEqual,
            HaveToExpandVhrsWithAssetTree: true,
            HaveToExpandCategories: true
        };
        vm.currentSurveyVhrEventFilter = {};
        vm.currentVhrEventFilter = {};
        vm.currentSurveyActionFilter = {};
        vm.currentVirtualHrFilter = {};

        vm.workflowStatusKinds = workflowStatusKindEnumParsed.list.translated;

        vm.actPriorities = actPriorityEnumParsed.list.translated;

        // REFERS to 'reportDefinitionHtmlToDocDetail'
        vm.parentScope = $scope.$parent.vm;
        vm.parentScope.ribbonDatacontext.setRibbonStatusButton(vm.parentScope.ribbonDatacontext.Buttons.PrintReportPdf, true);
        vm.parentScope.ribbonDatacontext.refreshStatusButtons();
        vm.parentScope.initReportToDocHeader({ onSearchActionHtmlToDocFn: null, onCreateHtmlFn: createHtmlReport });

        var actions = [];

        //#region Multiselect with grid selection
        selectionParams = { stateTo: 'app.nav.surveyslistmodal', destModalKey: 'survey', isMultiselect: true };
        vm.nbsSelectionGridWithModalSurvey = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalSurvey.setOnSelectFnCallback(function () {
            if (vm.nbsSelectionGridWithModalSurvey.selectedIds) {
                vm.currentSurveyActionFilter.SurveyIds = vm.nbsSelectionGridWithModalSurvey.selectedIds;
            } else {
                vm.currentSurveyActionFilter.SurveyIds = [];
            }
        });

        selectionParams = { destModalKey: 'vhr', entityMode: 'Target', translateKey: 'Target', isMultiselect: true };
        vm.nbsSelectionGridWithModalTarget = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalTarget.setOnSelectFnCallback(function () {
            if (vm.nbsSelectionGridWithModalTarget.selectedIds) {
                vm.currentActFilter.ActTargetIds = vm.nbsSelectionGridWithModalTarget.selectedIds;
            } else {
                vm.currentActFilter.ActTargetIds = [];
            }
        });

        selectionParams = { destModalKey: 'category', systemCategoryCode: 'ACT', categoryType: 'user', isAutocheck: false, viewKind: 'all', expandTree: true };
        vm.nbsSelectionTreeviewWithModalCategory = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategory.setOnSelectFnCallback(function () {
            if (vm.nbsSelectionTreeviewWithModalCategory.selectedIds) {
                vm.currentActFilter.CategoriesId = vm.nbsSelectionTreeviewWithModalCategory.selectedIds;
            } else {
                vm.currentActFilter.CategoriesId = [];
            }
        });

        vm.nbsActionDrbacModalObj = new NbsAssigneeSelectorModal({
            translator: vm.translate,
            suspendDirty: vm.suspendDirty,
            navigationService: navigationService,
            contextUserForSelection: null,
            getServerVarFn: Nembus.Common.Library.server.getServerVar
        });
        vm.nbsActionDrbacModalObj.viewUsers();
        vm.nbsActionDrbacModalObj.viewUserGroups();
        vm.nbsActionDrbacModalObj.setOnSelectFnCallback(function () {
            vm.currentActFilter.UserGroupAssignedIds = vm.nbsActionDrbacModalObj.drbacUserGroupMultiselectSelectedIds();
            vm.currentActFilter.AppUserAssignedIds = vm.nbsActionDrbacModalObj.appUserMultiselectSelectedIds();
        });

        // set title of current controller in report definition detail view
        navigationService.setControllerTitle();

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getGenericElementsActPrioritysColor(forceReload));
            funcsPromises.push(getGenericElementsWorkflowStatusColor(forceReload));

            $q.all(funcsPromises)
                .then(function () {
                    // set report options
                });
        }

        vm.htmlSteps = {
            ACTION_GROUPED: 'actiongrouped-render',
            ACTION_ASSIGNMENT: 'actionassignment-render'
        };

        function createHtmlReport() {
            var forceReload = true;
            var funcsPromises = [];
            funcsPromises.push(searchActDtos(forceReload
                , vm.currentActFilter
                , vm.currentSurveyActionFilter
                , vm.currentSurveyVhrEventFilter
                , vm.currentVirtualHrFilter
                , vm.currentVhrEventFilter));

            $q.all(funcsPromises)
                .then(function () {
                    parseReportHtmlDatasource(actions);
                });
        }

        function getDayDiff(startDate, compareDate) {
            if (!startDate) {
                startDate = moment(Date.Now).toDate();
            }

            var a = moment(startDate);
            var b = moment(compareDate);
            return a.diff(b, 'days');
        }

        function setCurrentVhrEventFilter() {
            if (Lazy(vm.nbsSelectionGridWithModalVhrEventType.selectedIds).some() || Lazy(vm.nbsSelectionGridWithModalVhrEventGroup.selectedIds).some()) {
                vm.currentVhrEventFilter.ModuleTargetId = moduleTargetId;
            } else {
                vm.currentVhrEventFilter = {};
            }
        }

        // prepare datasource for report in UI (to prepare Html)
        function parseReportHtmlDatasource(actions) {
            setReportFilterData();

            stepsToRender = [];

            var countActions = actions.length;
            var assignmentTypes = {
                User: 'User',
                UserGroup: 'UserGroup',
                NoAssignment: 'NoAssignment'
            };

            // object binded on UI
            vm.bindObject = {
                workflow: {
                    toExecute: 0,
                    executed: 0,
                    toExecutePercent: 0,
                    executedPercent: 0,
                    toExecuteTranslation: workflowStatusKindEnumObjTranslated[workflowStatusKindObj.ToExecute],
                    executedTranslation: workflowStatusKindEnumObjTranslated[workflowStatusKindObj.Executed]
                },
                priority: {
                    veryHigh: 0,
                    high: 0,
                    medium: 0,
                    low: 0,
                    veryHighTranslation: priorityObjTranslated[priorityObj.VeryHigh],
                    highTranslation: priorityObjTranslated[priorityObj.High],
                    mediumTranslation: priorityObjTranslated[priorityObj.Medium],
                    lowTranslation: priorityObjTranslated[priorityObj.Low]
                },
                actions: {
                    table: {
                        toExecuteNotExpired: 0,
                        toExecuteExpiredLess7days: 0,
                        toExecuteExpiredLess30days: 0,
                        toExecuteExpiredLess60days: 0,
                        toExecuteExpiredMore60days: 0,
                        executedNotExpired: 0,
                        executedExpiredLess7days: 0,
                        executedExpiredLess30days: 0,
                        executedExpiredLess60days: 0,
                        executedExpiredMore60days: 0
                    },
                    assignments: [],
                    grouped: []
                }
            };

            // workflow status
            vm.bindObject.workflow.toExecuteCount = Lazy(actions).where({ ActWorkflowStatusKindId: workflowStatusKindObj.ToExecute }).toArray().length;
            vm.bindObject.workflow.executedCount = Lazy(actions).where({ ActWorkflowStatusKindId: workflowStatusKindObj.Executed }).toArray().length;
            vm.bindObject.workflow.toExecutePercent = Nembus.Common.Library.util.getPercent(vm.bindObject.workflow.toExecuteCount, countActions);
            vm.bindObject.workflow.executedPercent = Nembus.Common.Library.util.getPercent(vm.bindObject.workflow.executedCount, countActions);

            // priority
            vm.bindObject.priority.veryHighCount = Lazy(actions).where({ ActPriorityId: priorityObj.VeryHigh }).toArray().length;
            vm.bindObject.priority.highCount = Lazy(actions).where({ ActPriorityId: priorityObj.High }).toArray().length;
            vm.bindObject.priority.mediumCount = Lazy(actions).where({ ActPriorityId: priorityObj.Medium }).toArray().length;
            vm.bindObject.priority.lowCount = Lazy(actions).where({ ActPriorityId: priorityObj.Low }).toArray().length;
            vm.bindObject.priority.veryHighPercent = Nembus.Common.Library.util.getPercent(vm.bindObject.priority.veryHighCount, countActions);
            vm.bindObject.priority.highPercent = Nembus.Common.Library.util.getPercent(vm.bindObject.priority.highCount, countActions);
            vm.bindObject.priority.mediumPercent = Nembus.Common.Library.util.getPercent(vm.bindObject.priority.mediumCount, countActions);
            vm.bindObject.priority.lowPercent = Nembus.Common.Library.util.getPercent(vm.bindObject.priority.lowCount, countActions);

            // action table data
            Lazy(actions)
                .each(action => {
                    if (action.ActExpireDate) {
                        var days = 0;
                        if (action.ActWorkflowStatusKindId === workflowStatusKindObj.ToExecute) {
                            days = getDayDiff(null, action.ActExpireDate);
                            if (days <= 0) {
                                vm.bindObject.actions.table.toExecuteNotExpired += 1;
                            } else if (days >= 60) {
                                vm.bindObject.actions.table.toExecuteExpiredMore60days += 1;
                            } else if (days < 7) {
                                vm.bindObject.actions.table.toExecuteExpiredLess7days += 1;
                            } else if (days < 30) {
                                vm.bindObject.actions.table.toExecuteExpiredLess30days += 1;
                            } else if (days < 60) {
                                vm.bindObject.actions.table.toExecuteExpiredLess60days += 1;
                            }
                        }
                        if (action.ActWorkflowStatusKindId === workflowStatusKindObj.Executed) {
                            days = getDayDiff(action.ActExecutionDate, action.ActExpireDate);
                            if (days <= 0) {
                                vm.bindObject.actions.table.executedNotExpired += 1;
                            } else if (days >= 60) {
                                vm.bindObject.actions.table.executedExpiredMore60days += 1;
                            } else if (days < 7) {
                                vm.bindObject.actions.table.executedExpiredLess7days += 1;
                            } else if (days < 30) {
                                vm.bindObject.actions.table.executedExpiredLess30days += 1;
                            } else if (days < 60) {
                                vm.bindObject.actions.table.executedExpiredLess60days += 1;
                            }
                        }
                    }
                });

            // assignemnt actions
            var allAssignmentActions = [];
            Lazy(actions)
                .each((action) => {
                    var userGroups = JSON.parse(Nembus.Common.Library.converter.decodeHtml(action.UserGroupsAssignedSerialized));
                    var users = JSON.parse(Nembus.Common.Library.converter.decodeHtml(action.UsersAssignedSerialized));

                    if (Lazy(userGroups).some() || Lazy(users).some()) {
                        Lazy(userGroups)
                            .each((userGroup) => {
                                userGroup.Kind = assignmentTypes.UserGroup;

                                var existingUserGroup = Lazy(allAssignmentActions).findWhere({ Value: userGroup.Value, Kind: userGroup.Kind });
                                if (!existingUserGroup) {
                                    userGroup = setActionCounts(userGroup, action, countActions, workflowStatusKindObj);
                                    allAssignmentActions.push(userGroup);
                                } else {
                                    existingUserGroup = setActionCounts(existingUserGroup, action, countActions, workflowStatusKindObj);
                                }
                            });

                        Lazy(users)
                            .each((user) => {
                                user.Kind = assignmentTypes.User;

                                var existingUser = Lazy(allAssignmentActions).findWhere({ Value: user.Value, Kind: user.Kind });
                                if (!existingUser) {
                                    user = setActionCounts(user, action, countActions, workflowStatusKindObj);

                                    allAssignmentActions.push(user);
                                } else {
                                    existingUser = setActionCounts(existingUser, action, countActions, workflowStatusKindObj);
                                }
                            });
                    } else {
                        if (addEmptyActionAssignment) {
                            var existingNoAssigned = Lazy(allAssignmentActions).findWhere({ Kind: assignmentTypes.NoAssignment });
                            if (!existingNoAssigned) {
                                var noAssigned = {
                                    Name: ' - ',
                                    Kind: assignmentTypes.NoAssignment
                                };

                                noAssigned = setActionCounts(noAssigned, action, countActions, workflowStatusKindObj);

                                allAssignmentActions.push(noAssigned);
                            } else {
                                existingNoAssigned = setActionCounts(existingNoAssigned, action, countActions, workflowStatusKindObj);
                            }
                        }
                    }
                });

            // grouped actions
            var allGroupedActions = [];
            var tempActionsForGrouping = [];
            Lazy(actions)
                .each((action) => {
                    var actionCategories = JSON.parse(Nembus.Common.Library.converter.decodeHtml(action.CategoriesSerialized));
                    if (Lazy(actionCategories).some()) {
                        Lazy(actionCategories)
                            .each((actionCategory) => {
                                tempActionsForGrouping.push({
                                    ActWorkflowStatusKindId: action.ActWorkflowStatusKindId,
                                    CategoryIdForGrouping: actionCategory.Value,
                                    CategoryNameForGrouping: actionCategory.Name
                                });
                            });
                    } else {
                        action.CategoryIdForGrouping = 0;
                        action.CategoryNameForGrouping = '-';
                        tempActionsForGrouping.push({
                            ActWorkflowStatusKindId: null,
                            CategoryIdForGrouping: 0,
                            CategoryNameForGrouping: '-'
                        });
                    }
                });

            var actionGrouped = Lazy(tempActionsForGrouping).groupBy('CategoryIdForGrouping').toArray();
            Lazy(actionGrouped)
                .each((item) => {
                    var count = item[1].length;
                    var countExecuted = Lazy(item[1])
                        .where({ ActWorkflowStatusKindId: workflowStatusKindObj.Executed })
                        .toArray().length;
                    var countToExecute = Lazy(item[1])
                        .where({ ActWorkflowStatusKindId: workflowStatusKindObj.ToExecute })
                        .toArray().length;

                    var percentToExecute = Nembus.Common.Library.util.getPercent(countToExecute, count);
                    var percentExecuted = Nembus.Common.Library.util.getPercent(countExecuted, count);

                    // calculate stacked bar (3 bars percents)
                    var barExecutedPercent = 0;
                    if (percentExecuted > 0) {
                        barExecutedPercent = Number(percentExecuted / 100 * percentExecuted);
                    }

                    var barToExecutePercent = Number(percentExecuted - barExecutedPercent);
                    var barRemainingPercent = 100 - barExecutedPercent - barToExecutePercent;

                    var groupedAction = {
                        Name: Lazy(item[1]).first().CategoryNameForGrouping,
                        count: count,
                        countExecuted: countExecuted,
                        countToExecute: countToExecute,
                        percentToExecute: percentToExecute,
                        percentExecuted: percentExecuted,

                        barExecutedPercent: barExecutedPercent,
                        barToExecutePercent: barToExecutePercent,
                        barRemainingPercent: barRemainingPercent
                    };

                    allGroupedActions.push(groupedAction);
                });

            // bind to UI
            if (allGroupedActions.length > 0) {
                stepsToRender.push(vm.htmlSteps.ACTION_GROUPED);
                vm.bindObject.actions.grouped = Lazy(allGroupedActions).sortBy((el) => { return el.percentExecuted; }, true).toArray();
            }
            if (allAssignmentActions.length > 0) {
                stepsToRender.push(vm.htmlSteps.ACTION_ASSIGNMENT);
                vm.bindObject.actions.assignments = Lazy(allAssignmentActions).sortBy((el) => { return el.actionCounts.percentExecuted; }, true).toArray();
            }


            // set steps to render before html retrieve from directive
            vm.parentScope.htmlToDocManager.setHtmlStepsToRender(stepsToRender);

            if (!vm.parentScope.htmlToDocManager.hasHtmlStepsToRender()) {
                vm.parentScope.htmlToDocManager.htmlIsRendered();
            }
        }

        function setReportFilterData() {
            vm.reportFilterData = new NbsReportFilterData(vm.translate, contextService.virtualContext.cultureInfo, Nembus.Common.Library.converter.date.formatDate);

            // filter in report are visualized in current order 
            vm.reportFilterData.setDataByRangeDate('ActExpireDate', 'Act.ActExpireDate', vm.currentActFilter.ActExpireDate1, vm.currentActFilter.ActExpireDate2);
            vm.reportFilterData.setDataByRangeDate('ActStartDate', 'Act.ActStartDate', vm.currentActFilter.ActStartDate1, vm.currentActFilter.ActStartDate2);
            vm.reportFilterData.setDataBySelectionModal('TargetDto', 'TargetDto.TargetDto', vm.nbsSelectionGridWithModalTarget);
            vm.reportFilterData.setDataBySelectionModal('TargetGroupId', 'TargetDto.TargetGroupId', vm.nbsSelectionGridWithModalVhrGroup);
            vm.reportFilterData.setDataBySelectionModal('TargetTypeId', 'TargetDto.TargetTypeId', vm.nbsSelectionGridWithModalVhrType);
            vm.reportFilterData.setDataBySelectionModal('SurveyDto', 'SurveyDto.SurveyDto', vm.nbsSelectionGridWithModalSurvey);
            vm.reportFilterData.setDataBySelectionModal('VhrEventGroupId', 'SurveyDto.VhrEventGroupId', vm.nbsSelectionGridWithModalVhrEventGroup);
            vm.reportFilterData.setDataBySelectionModal('VhrEventTypeId', 'SurveyDto.VhrEventTypeId', vm.nbsSelectionGridWithModalVhrEventType);
            vm.reportFilterData.setDataByDrbacAuthSelectorModal('CompilerUserPersonId', 'SurveyVhrEvent.CompilerUserPersonId', vm.nbsAuthorDrbacModalObj);
            vm.reportFilterData.setDataByDrbacAuthSelectorModal('ActAssignee', 'ActAssignee', vm.nbsActionDrbacModalObj);
            vm.reportFilterData.setDataBySelectionModal('ActCategory', 'Act.ActCategory', vm.nbsSelectionTreeviewWithModalCategory);
            vm.reportFilterData.setDataByObjectList('ActPriorityId', 'Act.ActPriorityId', [vm.currentActFilter.ActPriorityId], vm.actPriorities, 'value', ['name']);
            vm.reportFilterData.setDataByObjectList('ActWorkflowStatusKindId', 'HtmlToPdfAudActsExecutionSyntesisReportForm.HtmlToPdfAudActsExecutionSyntesisReportFormActsStatus', [vm.currentActFilter.ActWorkflowStatusKindId], vm.workflowStatusKinds, 'value', ['name']);
            vm.reportFilterData.setDataByRangeDate('ActExecutionDate', 'Act.ActExecutionDate', vm.currentActFilter.ActExecutionDate1, vm.currentActFilter.ActExecutionDate2);
            vm.reportFilterData.setDataByDrbacAuthSelectorModal('ActExecutor', 'Act.ActExecutor', vm.nbsExecutorDrbacModalObj);
        }

        function setActionCounts(entity, action, actionCountTotal, workflowStatusKindObj) {
            var actionCounts = {
                count: 0,
                countExecuted: 0,
                countToExecute: 0,
                percentExecuted: 0,
                percentToExecute: 0,
                percentExecutedTotal: 0,
                percentToExecuteTotal: 0
            };

            var stackedBar = {
                barExecutedPercent: 0,
                barToExecutePercent: 0,
                barRemainingPercent: 100
            };

            if (!entity.actionCounts) {
                entity.actionCounts = actionCounts;
            }

            if (!entity.stackedBar) {
                entity.stackedBar = stackedBar;
            }

            entity.actionCounts.count += 1;

            if (action.ActWorkflowStatusKindId === workflowStatusKindObj.ToExecute) {
                entity.actionCounts.countToExecute += 1;
            }

            if (action.ActWorkflowStatusKindId === workflowStatusKindObj.Executed) {
                entity.actionCounts.countExecuted += 1;
            }

            entity.actionCounts.percentTotal = Nembus.Common.Library.util.getPercent(entity.actionCounts.count, actionCountTotal);
            entity.actionCounts.percentToExecute = Nembus.Common.Library.util.getPercent(entity.actionCounts.countToExecute, entity.actionCounts.count);
            entity.actionCounts.percentExecuted = Nembus.Common.Library.util.getPercent(entity.actionCounts.countExecuted, entity.actionCounts.count);
            entity.actionCounts.percentExecutedTotal = Nembus.Common.Library.util.getPercent(entity.actionCounts.countToExecute, actionCountTotal);
            entity.actionCounts.percentToExecuteTotal = Nembus.Common.Library.util.getPercent(entity.actionCounts.countExecuted, actionCountTotal);

            // calculate stacked bar (3 bars percents)
            if (entity.actionCounts.percentExecuted > 0) {
                entity.stackedBar.barExecutedPercent = Number(entity.actionCounts.percentExecuted / 100 * entity.actionCounts.percentExecuted);
            }

            entity.stackedBar.barToExecutePercent = Number(entity.actionCounts.percentExecuted - entity.stackedBar.barExecutedPercent);
            entity.stackedBar.barRemainingPercent = 100 - entity.stackedBar.barExecutedPercent - entity.stackedBar.barToExecutePercent;
            return entity;
        }

        function searchActDtos(forceReload, actFilter, surveyActionFilter, surveyVhrEventFilter, virtualHrFilter, vhrEventFilter) {
            var filter = { // FilterAudActClientData
                ActFilter: actFilter,
                SurveyActionFilter: surveyActionFilter,
                SurveyVhrEventFilter: surveyVhrEventFilter,
                VhrEventFilter: vhrEventFilter,
                VirtualHrFilter: virtualHrFilter
            };
            var defer = $q.defer();

            audService.searchActDtos(filter, forceReload)
                .then(function (data) {
                    actions = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getGenericElementsActPrioritysColor(forceReload) {
            var filter = { GenericElementCode: 'Configurations.Acts.PriorityColors' };
            var defer = $q.defer();

            utilService.getGenericElements(filter, forceReload)
                .then(function (data) {
                    if (!data.results[0]) {
                        vm.actPriorityColors = [];
                    }
                    else {
                        vm.actPriorityColors = data.results[0].PairValuesArray;
                        vm.actPriorityColorsObj = data.results[0].PairValuesObject;
                    }

                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getGenericElementsWorkflowStatusColor(forceReload) {
            var filter = { GenericElementCode: 'Configurations.Workflows.LifeStatusColors' };
            var defer = $q.defer();

            utilService.getGenericElements(filter, forceReload)
                .then(function (data) {
                    if (!data.results[0]) {
                        vm.workflowStatusColors = [];
                    }
                    else {
                        vm.workflowStatusColors = data.results[0].PairValuesArray;
                        vm.workflowStatusColorsObj = data.results[0].PairValuesObject;
                    }
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    htmlToPdfAudActsExecutionSyntesisReportForm.$inject = injectParams;
    htmlToPdfAudActsExecutionSyntesisReportForm.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('htmlToPdfAudActsExecutionSyntesisReportForm', htmlToPdfAudActsExecutionSyntesisReportForm);
});
