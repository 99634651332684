﻿
'use strict';
define(['../../common/services/nbs-service'], function (nbsService) {

    var injectParams = ['$http', '$q', '$timeout', 'breezeDataLayer'];

    var jobService = function ($http, $q, $timeout,breezeDataLayer) {

        var dataLayer = breezeDataLayer.Initialize('job', true);
        var manager = dataLayer.GetManager();

        var entityTypes = {
            QuartzJob: 'QuartzJob',
            QuartzCommand: 'QuartzCommand',
            ExecProcess: 'ExecProcess',
            ExecProcessRequest: 'ExecProcessRequest',
            ExecProcessRequestDto: 'ExecProcessRequestDto',
            ScheduledTaskDto: 'ScheduledTaskDto',
            ScheduledTaskHistory: 'ScheduledTaskHistory'
        };

        nbsService.call(this, manager, $q, $timeout);

        this.dataContext = {
            quartzJobs: dataLayer.setContextMethods(manager, nbsService, entityTypes.QuartzJob),
            quartzCommands: dataLayer.setContextMethods(manager, nbsService, entityTypes.QuartzCommand),
            execProcesses: dataLayer.setContextMethods(manager, nbsService, entityTypes.ExecProcess),
            execProcessRequests: dataLayer.setContextMethods(manager, nbsService, entityTypes.ExecProcessRequest),
            execProcessRequestDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.ExecProcessRequestDto),
            scheduledTasks: dataLayer.setContextMethods(manager, nbsService, entityTypes.ScheduledTaskDto),
            scheduledTaskHistories: dataLayer.setContextMethods(manager, nbsService, entityTypes.ScheduledTaskHistory),

            enumTypes: {
                QuartzJobStatus: dataLayer.GetEnumType('QuartzJobStatus'),
                QuartzJobCommandKind: dataLayer.GetEnumType('QuartzJobCommandKind'),
                QuartzJobCommandStatus: dataLayer.GetEnumType('QuartzJobCommandStatus'),
                QuartzJobCommandType: dataLayer.GetEnumType('QuartzJobCommandType'),
                ExecProcessRequestStatus: dataLayer.GetEnumType('ExecProcessRequestStatus'),
                ScheduledTaskStatus: dataLayer.GetEnumType('ScheduledTaskStatus'),
                ScheduledTaskHistoryType: dataLayer.GetEnumType('ScheduledTaskHistoryType'),
                ScheduledTaskHistoryCommand: dataLayer.GetEnumType('ScheduledTaskHistoryCommand')
            }
        };

        this.createQuery = function (entityName) {
            return dataLayer.CreateQuery(entityName);
        };

        this.getExecProcesses = function (forceReload) {
            var params = { entityType: entityTypes.ExecProcess, cache: this.dataContext.execProcesses.get() };
            return dataLayer.GetDataListPromise(params, null, forceReload, "ExecProcesses");
        };

        this.getQuartzJobs = function (forceReload) {
            var params = { entityType: entityTypes.QuartzJob, cache: this.dataContext.quartzJobs.get() };
            return dataLayer.GetDataListPromise(params, null, forceReload, "QuartzJobs");
        };

        this.getQuartzCommands = function (filter, forceReload) {
            var params = { entityType: entityTypes.QuartzCommand, cache: this.dataContext.quartzCommands.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "QuartzCommands");
        };

        this.getScheduledTask = function (filter, forceReload) {
            var params = { key: filter.ScheduledTaskId, dataContext: this.dataContext.vhrEventTemplates };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "ScheduledTasks");
        };
        this.getScheduledTasks = function (filter, forceReload) {
            var params = { entityType: entityTypes.ScheduledTaskDto, cache: this.dataContext.scheduledTasks.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "ScheduledTasks");
        };

        this.getScheduledTaskHistories = function (filter, forceReload) {
            var params = { entityType: entityTypes.ScheduledTaskHistory, cache: this.dataContext.scheduledTaskHistories.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "ScheduledTaskHistories");
        };
        this.getScheduledTaskHistory = function (filter, forceReload) {
            var params = { key: filter.ScheduledTaskHistoryId, dataContext: this.dataContext.scheduledTaskHistories };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "ScheduledTaskHistories");
        };
    };

    jobService.$inject = injectParams;
    jobService.prototype = Object.create(nbsService.prototype);
    angular.module('nbs.rds.module').service('jobService', jobService);
});