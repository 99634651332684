﻿'use strict';
define([], function () {
    var injectParams = ['$timeout'];
    var nbsEntityJournalNotesComponent = function ($timeout) {
        var vm = this;
        vm.datasource = [];

        vm.entityJournalStatus = {
            CREATED: 0,
            DELETED: 1
        }

        vm.$onInit = function () {
            vm.id = 'entitynotes_' + NbsGuidManager.newGuid().replaceAll('-', '');
        };

        vm.$onChanges = function (changes) {
            if (changes.nbsDatasource && changes.nbsDatasource.currentValue) {
                vm.datasource = Lazy(changes.nbsDatasource.currentValue).where({ EntityJournalStatusId: vm.entityJournalStatus.CREATED }).toArray();
            }
        }

        vm.onAddClick = function () {
            $timeout(() => {
                vm.onAddEvent();
            });
        }

        vm.onDeleteClick = function (item) {
            $timeout(() => {
                vm.onDeleteEvent({ entityJournal: item });
            });
        }
    };

    nbsEntityJournalNotesComponent.$inject = injectParams;
    angular.module('nbs.rds.module').component('nbsEntityJournalNotes', {
        templateUrl: '/rds/common/components/nbsEntityJournalNotesComponent/_?c=' + window.codeCache(),
        controller: nbsEntityJournalNotesComponent,
        controllerAs: 'vm',
        bindings: {
            nbsDatasource: '=',
            nbsMode: '@',
            uiContext: "<",

            onAddEvent: "&",
            onDeleteEvent: "&"
        }
    });
});

