﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'workflowService', 'vhreventService', 'actService', 'reportService', 'navigationService', 'checkStateChangeService', 'contextService', 'utilService'];
    var audActsListReportForm = function ($q, $scope, $timeout, $injector, foundationService, workflowService, vhreventService, actService, reportService, navigationService, checkStateChangeService, contextService, utilService) {

        var vm = this;
        nbsVm.call(vm, reportService, navigationService, contextService, $q, $injector, $timeout);
        var checkStateChangeParams = { dirtyFlagIsDisabled: vm.isDirtyDisabled };
        checkStateChangeService.checkFormOnStateChange($scope, reportService, checkStateChangeParams);

        // all form items are enabled
        vm.setIsEditingReport();

        var appStoreCode = 'aud';
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var expressionTypeEnum = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.ExpressionType, null, vm.translate).obj.normal.name_value;

        var dsCallback = function () { return vhreventService.getVhrEventTypes({ AppStoreBw: appStoreBw }, false); };
        var selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventType', viewTitle: vm.translate('SurveyDto.VhrEventTypeId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventType = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        dsCallback = function () { return vhreventService.getVhrEventGroups({ AppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventGroup', viewTitle: vm.translate('SurveyDto.VhrEventGroupId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventGroup = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        dsCallback = function () { return foundationService.getVhrTypes(false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrType', viewTitle: vm.translate('TargetDto.TargetTypeId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrType = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        dsCallback = function () { return foundationService.getVhrGroups(false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrGroup', viewTitle: vm.translate('TargetDto.TargetGroupId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrGroup = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        vm.nbsActionDrbacModalObj = new NbsAssigneeSelectorModal({
            translator: vm.translate,
            suspendDirty: vm.suspendDirty,
            navigationService: navigationService,
            contextUserForSelection: null,
            getServerVarFn: Nembus.Common.Library.server.getServerVar
        });
        vm.nbsActionDrbacModalObj.setVisibilityServerVar('ssv_core_act_assignee_visibility_selection');

        vm.workflowStatusKinds = Nembus.Common.Library.util.parseEnum(workflowService.dataContext.enumTypes.WorkflowStatusKind, 'WorkflowStatus', vm.translate).list.translated;

        // Grouping selection
        vm.groupingTypes = [];
        vm.groupingTypes.push({ value: 'ActTargetId', name: vm.translate('AudActsListReportForm.AudActsListReportFormTargetGrouping') });
        vm.groupingTypes.push({ value: 'SurveyId', name: vm.translate('AudActsListReportForm.AudActsListReportFormSourceGrouping') });
        vm.groupingTypes.push({ value: 'CategoryId', name: vm.translate('AudActsListReportForm.AudActsListReportFormCategoryGrouping') });

        // Order grouping 
        vm.groupSortingTypes = [];
        vm.groupSortingTypes.push({ value: 'GroupSortedByCode', name: vm.translate('Code') });
        vm.groupSortingTypes.push({ value: 'GroupSortedByName', name: vm.translate('Name') });

        // Sorting selection
        vm.sortingTypes = [];
        vm.sortingTypes.push({ value: 'ActExpireDate', name: vm.translate('AudActsListReportForm.AudActsListReportFormExpireDateSorting') });    // data scadenza
        vm.sortingTypes.push({ value: 'ActStartDate', name: vm.translate('AudActsListReportForm.AudActsListReportFormActStartDateSorting') });  // data rilevamento
        vm.sortingTypes.push({ value: 'ActExecutionDate', name: vm.translate('AudActsListReportForm.AudActsListReportFormActExecutionDateSorting') });    // data chiusura
        vm.sortingTypes.push({ value: 'ActPriorityId', name: vm.translate('AudActsListReportForm.AudActsListReportFormActPrioritySorting') });    // priorità
        vm.sortingTypes.push({ value: 'CategoryCode', name: vm.translate('AudActsListReportForm.AudActsListReportFormCategoryCodeSorting') });   // codice categoria
        vm.sortingTypes.push({ value: 'CategoryName', name: vm.translate('AudActsListReportForm.AudActsListReportFormCategoryNameSorting') });   // nome categoria

        // priorities
        var actPriorityEnumParsed = Nembus.Common.Library.util.parseEnum(actService.dataContext.enumTypes.ActPriority, 'Act', vm.translate);
        vm.actPriorities = actPriorityEnumParsed.list.translated;


        // posted filter
        vm.currentReportFilter = {};

        //#region Multiselect with grid selection
        selectionParams = { stateTo: 'app.nav.surveyslistmodal', destModalKey: 'survey', isMultiselect: true };
        vm.nbsSelectionGridWithModalSurvey = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        selectionParams = { destModalKey: 'vhr', entityMode: 'Target', translateKey: 'Target', isMultiselect: true };
        vm.nbsSelectionGridWithModalTarget = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        selectionParams = { destModalKey: 'category', systemCategoryCode: 'ACT', categoryType: 'user', isAutocheck: false, viewKind: 'all', expandTree: true };
        vm.nbsSelectionTreeviewWithModalCategory = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        //#endregion

        // set title of current controller in report definition detail view
        navigationService.setControllerTitle();

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getActActionGroups(forceReload));
            funcsPromises.push(getActActionTypes(forceReload));

            $q.all(funcsPromises)
                .then(function () {
                    //reportService.currentReport.formEntityToValidate = vm.currentReportFilter;
                    reportService.currentReport.isCustomValidatedCallback = isCustomValidatedCallback;
                    reportService.currentReport.prepareReportFilterCallback = setReportFilterData;
                });
        }

        function getActActionTypes(forceReload) {
            var filter = {};
            var defer = $q.defer();

            actService.getActActionTypes(filter, forceReload)
                .then(function (data) {
                    vm.actActionTypes = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getActActionGroups(forceReload) {
            var filter = {};
            var defer = $q.defer();

            actService.getActActionGroups(filter, forceReload)
                .then(function (data) {
                    vm.actActionGroups = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function isCustomValidatedCallback() {
            vm.removeAlertFromList(true);

            var almostOneRangeDateIsSet = false;
            if (vm.currentReportFilter.ActStartDate1 && vm.currentReportFilter.ActStartDate2) {
                almostOneRangeDateIsSet = true;
            } else if (vm.currentReportFilter.ActExpireDate1 && vm.currentReportFilter.ActExpireDate2) {
                almostOneRangeDateIsSet = true;
            } else if (vm.currentReportFilter.ActExecutionDate1 && vm.currentReportFilter.ActExecutionDate2) {
                almostOneRangeDateIsSet = true;
            }

            if (!almostOneRangeDateIsSet) {
                vm.setErrorMsg({ isMessageVisible: true, message: vm.translate('SetAlmostOneRangeDate') });
                $timeout(() => { /* implicit scope apply; */ });
                return false;
            } else {
                return true;
            }
        }

        function setReportFilterData() {
            var reportFilterData = new NbsReportFilterData(vm.translate, contextService.virtualContext.cultureInfo, Nembus.Common.Library.converter.date.formatDate);

            // filter in report are visualized in current order 
            reportFilterData.setDataByRangeDate('ActStartDate', 'Act.ActStartDate', vm.currentReportFilter.ActStartDate1, vm.currentReportFilter.ActStartDate2);
            reportFilterData.setDataByRangeDate('ActExpireDate', 'Act.ActExpireDate', vm.currentReportFilter.ActExpireDate1, vm.currentReportFilter.ActExpireDate2);
            reportFilterData.setDataByRangeDate('ActExecutionDate', 'Act.ActExecutionDate', vm.currentReportFilter.ActExecutionDate1, vm.currentReportFilter.ActExecutionDate2);
            reportFilterData.setDataBySelectionModal('ActTarget', 'Act.ActTarget', vm.nbsSelectionGridWithModalTarget);
            reportFilterData.setDataBySelectionModal('TargetGroup', 'TargetDto.TargetGroupId', vm.nbsSelectionGridWithModalVhrGroup);
            reportFilterData.setDataBySelectionModal('TargetType', 'TargetDto.TargetTypeId', vm.nbsSelectionGridWithModalVhrType);
            reportFilterData.setDataBySelectionModal('SurveyDto', 'SurveyDto.SurveyDto', vm.nbsSelectionGridWithModalSurvey);
            reportFilterData.setDataBySelectionModal('AuditGroup', 'SurveyDto.VhrEventGroupId', vm.nbsSelectionGridWithModalVhrEventGroup);
            reportFilterData.setDataBySelectionModal('AuditType', 'SurveyDto.VhrEventTypeId', vm.nbsSelectionGridWithModalVhrEventType);
            reportFilterData.setDataBySelectionModal('ActCategory', 'Act.ActCategory', vm.nbsSelectionTreeviewWithModalCategory);
            reportFilterData.setDataByObjectList('ActWorkflowStatusKindId', 'Act.ActWorkflowStatusKindId', [vm.currentReportFilter.workflowStatusKindId], vm.workflowStatusKinds, 'value', ['name']);
            reportFilterData.setDataByObjectList('ActPriorityId', 'Act.ActPriorityId', [vm.currentReportFilter.ActPriorityId], vm.actPriorities, 'value', ['name']);
            reportFilterData.setDataByObjectList('ActActionTypeId', 'Act.ActActionType', [vm.currentReportFilter.ActActionTypeId], vm.actActionTypes, 'ActActionTypeId', ['ActActionTypeName']);
            reportFilterData.setDataByObjectList('ActActionGroupId', 'Act.ActActionGroup', [vm.currentReportFilter.ActActionGroupId], vm.actActionGroups, 'ActActionGroupId', ['ActActionGroupName']);
            reportFilterData.setDataByDrbacAuthSelectorModal('ActAssignee', 'ActAssignee', vm.nbsActionDrbacModalObj);

            // AudActSysInt
            reportService.currentReport.reportArgs = {
                Ds1_Arg1: {//ActFilter
                    ActTargetIds: vm.nbsSelectionGridWithModalTarget.selectedIds,
                    CategoriesId: vm.nbsSelectionTreeviewWithModalCategory.selectedIds,
                    ActWorkflowStatusKindId: vm.currentReportFilter.workflowStatusKindId,
                    ActPriorityId: vm.currentReportFilter.actPriorityId,
                    AssignedUserPersonId: vm.assignedUserPersonId,
                    ActStartDate1: vm.currentReportFilter.ActStartDate1,
                    ActStartDate1Expression: expressionTypeEnum.GreaterThanOrEqual,
                    ActStartDate2: vm.currentReportFilter.ActStartDate2,
                    ActStartDate2Expression: expressionTypeEnum.LessThanOrEqual,
                    ActExpireDate1: vm.currentReportFilter.ActExpireDate1,
                    ActExpireDate1Expression: expressionTypeEnum.GreaterThanOrEqual,
                    ActExpireDate2: vm.currentReportFilter.ActExpireDate2,
                    ActExpireDate2Expression: expressionTypeEnum.LessThanOrEqual,
                    ActExecutionDate1: vm.currentReportFilter.ActExecutionDate1,
                    ActExecutionDate1Expression: expressionTypeEnum.GreaterThanOrEqual,
                    ActExecutionDate2: vm.currentReportFilter.ActExecutionDate2,
                    ActExecutionDate2Expression: expressionTypeEnum.LessThanOrEqual,
                    ActActionTypeId: vm.currentReportFilter.ActActionTypeId,
                    ActActionGroupId: vm.currentReportFilter.ActActionGroupId,
                    AppUserAssignedIds: vm.nbsActionDrbacModalObj.appUserAreSelected() ? vm.nbsActionDrbacModalObj.appUserMultiselectSelectedIds() : null,
                    UserGroupAssignedIds: vm.nbsActionDrbacModalObj.drbacUserGroupAreSelected() ? vm.nbsActionDrbacModalObj.drbacUserGroupMultiselectSelectedIds() : null,
                    HaveToExpandCategories: true
                },
                Ds1_Arg2: {//SurveyActionFilter
                    SurveyIds: vm.nbsSelectionGridWithModalSurvey.selectedIds
                },
                Ds1_Arg3: {//SurveyFilter

                },
                Ds1_Arg4: {//SurveyVhrEventFilter

                },
                Ds1_Arg5: {//VirtualHrFilter
                    VhrGroupsId: vm.nbsSelectionGridWithModalVhrGroup.selectedIds,
                    VhrTypesId: vm.nbsSelectionGridWithModalVhrType.selectedIds
                },
                Ds1_Arg6: {//VhrEventFilter
                    ModuleTargetId: Lazy(vm.nbsSelectionGridWithModalVhrEventGroup.selectedIds).some() || Lazy(vm.nbsSelectionGridWithModalVhrEventGroup.selectedIds).some()
                        ? moduleTargetId
                        : null,
                    VhrEventGroupsId: vm.nbsSelectionGridWithModalVhrEventGroup.selectedIds,
                    VhrEventTypesId: vm.nbsSelectionGridWithModalVhrEventType.selectedIds
                },
                Ds1_Arg7: {//QueryOptions
                    SortedBy: vm.sortingTypeSelected,
                    SortedBy1: vm.groupSortingType1Selected,
                    SortedBy2: vm.groupSortingType2Selected,
                    SortedBy3: vm.groupSortingType3Selected,
                    GroupedBy: null,
                    GroupedBy1: vm.groupingType1Selected,
                    GroupedBy2: vm.groupingType2Selected,
                    GroupedBy3: vm.groupingType3Selected,
                    GroupedTag: null
                },
                Ds1_Arg8: { // ReportFilterData
                    Datas: reportFilterData.Datas
                }
            };
        }
    };

    audActsListReportForm.$inject = injectParams;
    audActsListReportForm.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('audActsListReportForm', audActsListReportForm);
});
