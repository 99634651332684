﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'navigationService', 'integrationService', 'checkStateChangeService', 'contextService'];
    function integrationDefinitionsList($q, $scope, $timeout, $injector, navigationService, integrationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, integrationService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return requestSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, integrationService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var requestSelected = null;

        vm.currentIntegrationDefinitions = [];

        ribbon.setRibbonStatusButton(ribbon.Buttons.New, false);
        ribbon.refreshStatusButtons();

        // translated enums
        var integrationTemplateFormatEnumParsed = Nembus.Common.Library.util.parseEnum(integrationService.dataContext.enumTypes.IntegrationTemplateFormat, 'IntegrationDefinition', vm.translate);
        var integrationTemplateFormatObjForBind = integrationTemplateFormatEnumParsed.obj.translated.value_name;
        var integrationDefinitionType = navigationService.stateParams().integrationDefinitionType;
        var integrationTemplateFormat = navigationService.stateParams().integrationTemplateFormat;
        var viewKind = navigationService.stateParams().viewKind;

        vm.useTemplateDefinition = navigationService.stateParams().useTemplateDefinition;

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('integrationdefinition', null);
        nbsGridDatacontext.viewCommonBar();
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getIntegrationDefinitionDtos(forceReload, vm.useTemplateDefinition));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.currentIntegrationDefinitions = results[0];

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.currentIntegrationDefinitions
                    }
                });
        }


        vm.rowDblClick = function (args) {
            navigationService.navigateTo(args.data.CmsViewRenderNavigationState, {
                'viewKind': viewKind,
                'integrationDefinitionId': args.data.IntegrationDefinitionId,
                'useTemplateDefinition': args.data.UseTemplateDefinition
            });
        };

        vm.gridSelection = function (args) {
            requestSelected = args.data;
        };

        vm.refreshAction = function () {
            loadData(true);
        };

        function getIntegrationDefinitionDtos(forceReload, useTemplateDefinition) {
            var filter = null;
            var defer = $q.defer();

            var directionBw = viewKind === 'import' ? 2 : 1;

            if (useTemplateDefinition) {
                filter = {
                    UseTemplateDefinition: true,
                    DirectionBw: directionBw
                };
            }
            else {
                filter = {
                    IntegrationTemplateFormat: integrationTemplateFormat,
                    IntegrationDefinitionType: integrationDefinitionType,
                    AppStoreBw: null,
                    DirectionBw: directionBw
                };
            }

            integrationService.getIntegrationDefinitionDtos(filter, forceReload)
                .then(function (data) {
                    var defs = data.results;

                    Lazy(defs)
                        .each((def) => {
                            def.IntegrationTemplateFormatName = integrationTemplateFormatObjForBind[def.IntegrationTemplateFormatId];
                        });

                    defer.resolve(defs);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    }

    integrationDefinitionsList.$inject = injectParams;
    integrationDefinitionsList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('integrationDefinitionsList', integrationDefinitionsList);
});