﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'actService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var actValidationSchemaConfigDetail = function ($q, $scope, $timeout, $injector, foundationService, actService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, actService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, actService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        vm.setIsViewing();

        loadData(true);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getValidationSchemaInstancesPublish(forceReload));
            funcsPromises.push(getActValidationSchemaConfig(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.validationSchemas = results[0];
                    vm.validationSchemaConfig = results[1];
                });
        }

        vm.validationSchemaDefaultIdOnChange = function () {
            //vm.validationSchemaConfig.ValidationSchemaDefaultId = vm.validationSchemaDefaultId;
        };

        function getValidationSchemaInstancesPublish(forceReload) {
            var filter = {
                EntityName: 'Act'
            };
            var defer = $q.defer();

            foundationService.getValidationSchemaInstancesPublish(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getActValidationSchemaConfig(forceReload) {
            var filter = {};
            var defer = $q.defer();

            actService.getActValidationSchemaConfig(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results[0]);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    actValidationSchemaConfigDetail.$inject = injectParams;
    actValidationSchemaConfigDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('actValidationSchemaConfigDetail', actValidationSchemaConfigDetail);
});