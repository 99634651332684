﻿'use strict';
define([], function () {
    var injectParams = [];
    var nbsAlertsComponent = function () {
        var vm = this;
        
        vm.$onChanges = function (changes) {
            if (changes.nbsDatasource && changes.nbsDatasource.currentValue) {
                vm.alerts = changes.nbsDatasource.currentValue;
            }

            if (changes.nbsIsModal) {
                vm.isModal = changes.nbsIsModal.currentValue;
                vm.notIsModal = !changes.nbsIsModal.currentValue;
            }
        };

        vm.closeSuccessMsg = function (alert) {
            vm.onCloseSuccess({ isModal: vm.isModal, alert: alert });
        }

        vm.closeErrorMsg = function (alert) {
            vm.onCloseError({ isModal: vm.isModal, alert: {} });
        }
    };

    nbsAlertsComponent.$inject = injectParams;
    angular.module('nbs.rds.module').component('nbsAlerts', {
        templateUrl: '/rds/common/components/nbsAlertsComponent/_?c=' + window.codeCache(),
        controller: nbsAlertsComponent,
        controllerAs: 'vm',
        bindings: {
            nbsDatasource: "<",
            nbsIsModal: "<",

            onCloseError: "&",
            onCloseSuccess: "&"
        }
    });
});

