﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$injector', '$scope', '$timeout', 'vhreventService', 'audService', 'utilService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function surveyStream($q, $injector, $scope, $timeout, vhreventService, audService, utilService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, vhreventService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return currentSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, vhreventService);

        var ribbon = new NbsAudRibbon(navigationService, contextService.virtualContext, 'stream');
        ribbon.addHomeTab();
        ribbon.addTab(ribbon.GetAuditViewsTab());
        ribbon.setUnderBreakpointSmall();
        ribbon.tabIndexSelected = 2;
        vm.ribbonDatacontext = ribbon;

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var vhrEventTypeCode = navigationService.stateParams().vhrEventTypeCode;
        var vhrEventFixedTypeCode = navigationService.stateParams().vhrEventFixedTypeCode;
        var vhrEventKindEnum = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventKind, null, vm.translate).obj.normal.name_value;
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var surveyCreationPatternObj = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyCreationPattern, null, vm.translate).obj.normal.name_value;
        var vhrEventLifeStatusKey = navigationService.stateParams().vhrEventLifeStatusKey;
        var viewKind = navigationService.stateParams().viewKind;
        var currentSelected = null;

        var nbsGridDatacontext = new NbsAudGrid(vm.translate);
        nbsGridDatacontext.configureGrid('survey', 'list', null);
        nbsGridDatacontext.setStreamMode();
        nbsGridDatacontext.setDatasource([], false); // fake datasource, only to create grid
        vm.nbsGridDatacontext = nbsGridDatacontext;


        //#region set ribbon fn navigations
        var navParams = { 'appStoreCode': appStoreCode, 'vhrEventLifeStatusKey': vhrEventLifeStatusKey, 'viewKind': viewKind, 'vhrEventFixedTypeCode': vhrEventFixedTypeCode };
        vm.onRibbonGrid = () => { ribbon.onRibbonGrid_Survey(navParams); };
        vm.onRibbonCalendar = () => { ribbon.onRibbonCalendar_Survey(navParams); };
        vm.onRibbonStream = () => { ribbon.onRibbonStream_Survey(navParams); };
        vm.onRibbonBoard = () => { ribbon.onRibbonBoard_Survey(navParams); };
        vm.onRibbonTimeline = () => { ribbon.onRibbonTimeline_Survey(navParams); };
        vm.onRibbonTimelineTarget = () => { ribbon.onRibbonTimelineTarget_Survey(navParams); };
        vm.onRibbonTimelineAssignment = () => { ribbon.onRibbonTimelineTargetAssignment_Survey(navParams); };
        //#endregion set ribbon fn navigations

        //#region set ribbon enabled buttons
        ribbon.setRibbonStatusButton(ribbon.Buttons.Board, vhrEventLifeStatusKey !== 'ondemand');
        ribbon.setRibbonStatusButton(ribbon.Buttons.ViewList, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.ViewCalendar, vhrEventLifeStatusKey !== 'ondemand');
        ribbon.setRibbonStatusButton(ribbon.Buttons.Timeline, vhrEventLifeStatusKey !== 'ondemand');
        ribbon.setRibbonStatusButton(ribbon.Buttons.TimelineTarget, vhrEventLifeStatusKey !== 'ondemand');
        ribbon.setRibbonStatusButton(ribbon.Buttons.TimelineAssignment, vhrEventLifeStatusKey !== 'ondemand');
        ribbon.refreshStatusButtons();
        //#endregion set ribbon enabled buttons

        vm.gridSelection = function (args) {
            currentSelected = args.data;
        };

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.surveydetail", { 'surveyId': args.data.SurveyId, 'vhrEventId': args.data.VhrEventId });
        };

        var ejDataManager = null;
        var currentGridFilter = null;
        vm.surveyBarOnChanges = function (result) {
            // save in view current filter passed by survey bar
            currentGridFilter = parseFromBarFilter(result);

            if (ejDataManager === null) {
                utilService.getGenericElements({ GenericElementCode: 'Configurations.Surveys.LifeStatusColors' }, false)
                    .then((data) => {
                        var ge = data.results[0].PairValuesArray;
                        var statusColorObj = Nembus.Common.Library.parser.objFromList(ge, 'key', 'value');

                        var supportData = { isMySurveys: false, translate: vm.translate, contextService: contextService, statusColorObj: statusColorObj };
                        ejDataManager = audService.getSurveyDataManager(supportData, currentGridFilter, ejDataManager);
                        nbsGridDatacontext.setDatasource(ejDataManager, true);
                        refreshGridDatasource(currentGridFilter);
                    });
            } else {
                refreshGridDatasource(currentGridFilter)
            }
        };

        vm.createGridEvent = function (result) {
            vm.surveyBarDataContext = {
                vhrEventLifeStatusKey: vhrEventLifeStatusKey,
                commonBarDatasource: result.barDatasource,
                appStoreBw: appStoreBw,
                moduleTargetId: moduleTargetId,
                defaultKeys: {
                    columnsKey: vhrEventLifeStatusKey, sortingKey: null
                }
            };
        };

        vm.gridUiOnChanges = function (result) {
            vm.refreshGridUiComponent = {
                refreshKey: new Date().getTime(),
                uiData: result
            }
        }

        function refreshGridDatasource(currentGridFilter) {
            $timeout(() => {
                vm.forceDatamanagerFilter = {
                    refreshKey: new Date().getTime(),
                    filter: currentGridFilter
                }
            });
        }

        vm.refreshAction = function () {
            $timeout(() => {
                vm.forceGridRefresh = new Date().getTime();
            });
        };

        function parseFromBarFilter(surveyBarFilter) {
            // surveyFilter
            surveyBarFilter.surveyFilter.IsArchived = vhrEventLifeStatusKey === 'archived' ? true : false;
            switch (vhrEventLifeStatusKey) {
                case 'tocompile':
                    surveyBarFilter.surveyFilter.IsCompleted = true;
                    break;
                case 'compiled':
                    surveyBarFilter.surveyFilter.IsCompleted = false;
                    break;
                default:
            }

            // surveyVhrEventFilter
            if (vhrEventLifeStatusKey === 'ondemand') {
                surveyBarFilter.surveyVhrEventFilter.SurveyCreationPatternId = surveyCreationPatternObj.OnDemand;
            } else {
                surveyBarFilter.surveyVhrEventFilter.ExcludeOnDemand = true;
            }
            // vhrEventFilter
            surveyBarFilter.vhrEventFilter.AppStoreBw = appStoreBw;
            surveyBarFilter.vhrEventFilter.VhrEventTypeCode = vhrEventTypeCode;
            surveyBarFilter.vhrEventFilter.VhrEventFixedTypeCode = vhrEventFixedTypeCode;
            surveyBarFilter.vhrEventFilter.VhrEventKind = vhrEventKindEnum.Data;
            surveyBarFilter.vhrEventFilter.ModuleTargetId = moduleTargetId;

            return surveyBarFilter;
        }
        //#endregion
    }

    surveyStream.$inject = injectParams;
    surveyStream.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('surveyStream', surveyStream);
});