﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$injector', '$scope', '$timeout', 'reportService', 'cmsService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function reportDefinitionsForViewList($q, $injector, $scope, $timeout, reportService, cmsService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, reportService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, reportService);

        var currentCmsViewCode = navigationService.stateParams().cmsViewCodeReport;
        var reportHeaderTypeEnum = Nembus.Common.Library.util.parseEnum(reportService.dataContext.enumTypes.ReportHeaderType, null, vm.translate).obj.normal.value_name;
        
        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('reportdefinition', 'view');
        nbsGridDatacontext.allowSearching = true;
        nbsGridDatacontext.setDatasource([], false); // fake datasource, only to create grid
        vm.nbsGridDatacontext = nbsGridDatacontext;

        vm.currentReportDefinition = null;

        vm.reportsForView = [];

        // load report definitions for current view (nbs-vm)
        getReportsForCmsView(cmsService, currentCmsViewCode);

        vm.rowSelection = function (args) {
            vm.currentReportDefinition = args.data;

            reportService.currentReport.reportDefinition = vm.currentReportDefinition;
            reportService.currentReport.formEntityToValidate = null;
            reportService.currentReport.concatName = '';

            vm.setReportData({
                reportCode: null,//null because in this page at moment only 1 report is defined
                reportArgs: reportService.currentReport.reportArgs,
                reportDefinition: vm.currentReportDefinition,
                concatName: ''
            });

            // bind report object to ui filter
            vm.currentReportFilter = reportService.currentReport.reportArgs.Ds1_Arg1;
        };

        vm.refreshAction = function () {
            // load report definitions for current view (nbs-vm)
            getReportsForCmsView(cmsService, currentCmsViewCode);
        };

        // override nbs-vm method
        vm.exportReportToPdfAction = function () {
            if (!vm.currentReportDefinition) {
                return;
            }            
            vm.exportReport('pdf');
        };

        // override nbs-vm method
        vm.exportReportToWordAction = function () {
            if (!vm.currentReportDefinition) {
                return;
            }
            vm.exportReport('openword');
        };

        // override nbs-vm method
        vm.exportReportToXmlAction = function () {
            if (!vm.currentReportDefinition) {
                return;
            }
            vm.exportReport('xml');
        };

        // override nbs-vm method
        vm.exportReportToExcelAction = function () {
            if (!vm.currentReportDefinition) {
                return;
            }
            self.exportReport('openexcel');
        };

        // override nbs-vm method
        vm.exportReportToHtmlAction = function () {
            if (!vm.currentReportDefinition) {
                return;
            }
            vm.exportReport('html');
        };

        function getReportsForCmsView(cmsService, currentCmsViewCode) {
            vm.getReportsForCmsView(cmsService, currentCmsViewCode)
                .then(function () {
                    Lazy(vm.reportsForView).each((item) => {
                        item.ReportHeaderTypeName = vm.translate(reportHeaderTypeEnum[item.ReportHeaderTypeId]);
                    });

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.reportsForView
                    }
                });

        }

        var parentScope = $scope.$parent.vm;
        parentScope.ribbonDatacontext.disableAllRibbonStatusButtons();
        parentScope.ribbonDatacontext.setRibbonStatusButtonByName('Refresh', true);
        parentScope.ribbonDatacontext.refreshStatusButtons();


        //Set action of current controller to use them in parent controller
        navigationService.setParentControllerActions(vm);
    }

    reportDefinitionsForViewList.$inject = injectParams;
    reportDefinitionsForViewList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('reportDefinitionsForViewList', reportDefinitionsForViewList);
});