﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'productionService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var weekTemplateDetail = function ($q, $scope, $timeout, $injector, productionService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, productionService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, productionService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('wdayslottemplate', null);
        nbsGridDatacontext.allowPaging = false;
        nbsGridDatacontext.allowSearching = false;
        nbsGridDatacontext.allowGrouping = false;
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        var daySlotTemplates = [];
        var currentDayOfWeek = null;
        var currentAction = vm.actions.none;
        vm.viewWeekDayTabs = false;
        vm.daySlotModal = null;
        vm.weekTemplate = [];

        ribbon.setRibbonStatusButton(ribbon.Buttons.New,  false);
        ribbon.refreshStatusButtons();

        var currentWWeekTemplateId = navigationService.stateParams().wWeekTemplateId;
        vm.weekDaysObject = Nembus.Common.Library.util.parseEnum(productionService.dataContext.enumTypes.DayOfWeek, null, vm.translate).list.translated;
        var defaultDayValue = vm.weekDaysObject[0].value;   // Sunday

        Lazy(vm.weekDaysObject)
            .each((item) => {
                item.isClosed = false;
                item.isAllDayOpen = false;
            });

        if (currentWWeekTemplateId) {
            vm.setIsViewing();
            loadData(true);
        }
        else {
            vm.setIsAdding();
            vm.weekTemplate = productionService.dataContext.weekTemplates.add({
                ClosedDaysOfWeek: '',
                AllDayOpenDaysOfWeek: '',
                VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId
            });
        }

        vm.tabDayOfWeekOnClick = function (dayOfWeek) {
            currentDayOfWeek = dayOfWeek;

            refreshActionButtons();

            refreshGridCurrentDay();
        };

        vm.onCloseDayOfWeekChange = function () {
            vm.weekDaysObject[currentDayOfWeek.value].isAllDayOpen = false;

            refreshActionButtons();

            if (vm.weekDaysObject[currentDayOfWeek.value].isClosed) {
                resetGridCurrentDaySlots();
            }
        };

        vm.onIsAllDayOpenDayOfWeekChange = function () {
            vm.weekDaysObject[currentDayOfWeek.value].isClosed = false;

            refreshActionButtons();

            if (vm.weekDaysObject[currentDayOfWeek.value].isAllDayOpen) {
                resetGridCurrentDaySlots();
            }
        };

        vm.rowSelected = function (args) {
            vm.daySlotModal = args.data;
        };

        vm.rowDblClick = function (args) {
            currentAction = vm.actions.update;
            openWdaySlotModal();
        };

        vm.newAction = function () {
            if (currentDayOfWeek.isAllDayOpen || currentDayOfWeek.isClosed) {

            }

            currentAction = vm.actions.add;

            vm.daySlotModal = productionService.dataContext.daySlotTemplates.add({
                WWeekTemplateId: currentWWeekTemplateId,
                WDayOfWeekId: Number(currentDayOfWeek.value),
                TimeFrom: 'PT0H0M',
                TimeTo: 'PT0H0M',
                Efficiency: 100
            });

            openWdaySlotModal();
        };

        // get reference of modifyAction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parentm = vm.modifyAction;
        vm.modifyAction = function () {
            parentm().then(
                //enable disable button new & delete because is custom behavior
                //generally saveResult eneble or disable only save button 
                function (saveResult) {
                    refreshActionButtons();
                }
            );
        };

        // get reference of submitaction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parents = vm.submitAction;
        vm.submitAction = function () {
            vm.weekTemplate.ClosedDaysOfWeek = Lazy(vm.weekDaysObject).where({ isClosed: true }).pluck('value').join(',');
            vm.weekTemplate.AllDayOpenDaysOfWeek = Lazy(vm.weekDaysObject).where({ isAllDayOpen: true }).pluck('value').join(',');

            parents().then(
                function (saveResult) {
                    currentWWeekTemplateId = vm.weekTemplate.WWeekTemplateId;
                    vm.viewWeekDayTabs = true;

                    refreshActionButtons();                    
                }
            );
        };

        vm.deleteAction = function () {
            if (!vm.daySlotModal) {
                return;
            }

            deleteDaySlot(vm.daySlotModal);

            refreshGridCurrentDay();
        };

        function refreshActionButtons() {
            $timeout(() => {
                ribbon.setRibbonStatusButton(ribbon.Buttons.New,  vm.viewWeekDayTabs && !vm.viewContext.isLocked);
                ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, vm.viewWeekDayTabs);
                ribbon.refreshStatusButtons();
            });            
        }

        function openWdaySlotModal() {
            vm.modalInstance = navigationService.modal().open(vm.modalOptions({ templateUrl: 'dayOfWeekTemplateTemplate', scope: $scope }));
            vm.modalInstance.result.then(function () {
                //ok
                if (currentAction === vm.actions.add) {
                    daySlotTemplates.push(vm.daySlotModal);
                }

                refreshGridCurrentDay();
            }, function () {
                //dismiss
                vm.daySlotModal.entityAspect.rejectChanges();
            });
            vm.modalInstance.ok = function () {
                if (!vm.daySlotModal.entityAspect.hasValidationErrors) {
                    vm.modalInstance.close();
                }
            };
            vm.modalInstance.cancel = function () {
                vm.modalInstance.dismiss();
            };
        };

        function deleteDaySlot(daySlot) {
            productionService.deleteEntity(daySlot);

            daySlotTemplates = Lazy(daySlotTemplates)
                .reject({ WDaySlotTemplateId: daySlot.WDaySlotTemplateId })
                .toArray();
        }

        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getDaySlotTemplates(forceReload));
            funcsPromises.push(getWeekTemplate(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    // results[0]
                    daySlotTemplates = results[0];
                    refreshGridCurrentDay();                    

                    // results[0]
                    vm.weekTemplate = results[1];

                    Lazy(vm.weekDaysObject).each((item) => {
                        item.isClosed = Lazy(vm.weekTemplate.ClosedDaysOfWeek).contains(Number(item.value));
                        item.isAllDayOpen = Lazy(vm.weekTemplate.AllDayOpenDaysOfWeek).contains(Number(item.value));
                    });

                    vm.viewWeekDayTabs = true;
                });
        }

        function refreshGridCurrentDay() {
            if (!currentDayOfWeek) {
                currentDayOfWeek = defaultDayValue
            }

            vm.forceDatasourceLocal = {
                refreshkey: new Date().getTime(),
                dataSource: Lazy(daySlotTemplates)
                    .where({ WDayOfWeekId: Number(currentDayOfWeek.value) })
                    .toArray()
            }

            refreshActionButtons();
        }

        function resetGridCurrentDaySlots() {
            //var haveToRefresh = false;

            //Lazy(daySlotTemplates)
            //    .where({ WWeekTemplateId: currentWWeekTemplateId, WDayOfWeekId: currentDayOfWeek.value })
            //    .each((daySlot) => {
            //        deleteDaySlot(daySlot);
            //        haveToRefresh = true;
            //    });

            //if (haveToRefresh) {
            //    refreshGridCurrentDay();
            //}
        }

        function getWeekTemplate(forceReload) {
            var filter = { WWeekTemplateId: currentWWeekTemplateId };
            var defer = $q.defer();

            productionService.getWeekTemplate(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results[0]);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getDaySlotTemplates(forceReload) {
            var filter = { WWeekTemplateId: currentWWeekTemplateId };
            var defer = $q.defer();

            productionService.getDaySlotTemplates(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    weekTemplateDetail.$inject = injectParams;
    weekTemplateDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('weekTemplateDetail', weekTemplateDetail);
});