import './app';

import './breeze.metadata';
import './common/services/nbs-service';
import './shared/shared-module';
import './shell/shell-module';

import './shell/shell-library';
import './entry/entry-module';
import './rbac/rbac-module';
import './rbac/rbac-library';
import './rds/rds-module';
import './rds/rds-library';
import './aud/aud-module';
import './aud/aud-library';


import './rds/services/cmstool-service';

//nembus common services
import './common/services/httpBuffer-service';
import './common/services/boot-service';
import './common/services/formSummary-service';

// nemuus common directives
import './common/nbs-directives';
import './common/nbs-plupload-angular-directive';

import './polyfills.ts';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { UpgradeModule } from '@angular/upgrade/static';
import { AppModule } from './app.module';



// Bootstrap using the UpgradeModule
platformBrowserDynamic().bootstrapModule(AppModule).then(platformRef => {
    console.log("Bootstrapping in Hybrid mode with Angular & AngularJS");
    const upgrade = platformRef.injector.get(UpgradeModule) as UpgradeModule;
    upgrade.bootstrap(document.body, ['app']);
})//.catch(err => console.log(err));

