﻿'use strict';

define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$timeout', '$injector', 'navigationService', 'drbacService', 'rbacService', 'notificationService', 'contextService'];
    function sendNotificationModal($q, $timeout, $injector, navigationService, drbacService, rbacService, notificationService, contextService) {

        var vm = this;
        nbsVm.call(vm, null, navigationService, contextService, $q, $injector, $timeout);

        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
        var pParams = navigationService.stateParamsModal().parentParams;
        pParams = pParams ? pParams : navigationService.stateParams();

        var pSelectedDrbacRoles = [];
        var pSelectedDrbacUserGroups = [];
        var pSelectedAppUsers = [];
        var pNotificationDefinitionId = pParams.notificationDefinitionId;

        Lazy(pParams.drbacRoleIds).each((item) => { if (item !== null && item !== undefined) { pSelectedDrbacRoles.push(Number(item)); } });
        Lazy(pParams.drbacUserGroupIds).each((item) => { if (item !== null && item !== undefined) { pSelectedDrbacUserGroups.push(Number(item)); } });
        Lazy(pParams.appUserIds).each((item) => { if (item !== null && item !== undefined) { pSelectedAppUsers.push(Number(item)); } });

        vm.nbsDrbacAuthSelector = new NbsAssigneeSelector(vm.translate);
        vm.nbsDrbacAuthSelector.viewRoles = pParams.viewRoles ? true : false;
        vm.nbsDrbacAuthSelector.viewUserGroups = pParams.viewUserGroups ? true : false;
        vm.nbsDrbacAuthSelector.viewUsers = pParams.viewUsers ? true : false;

        vm.viewAssignmentDescription = pParams.viewAssignmentDescription ? true : false;

        // object binded to UI only for description assignment
        vm.currentForm = {};

        vm.okBtnIsVisible = true;
        vm.cancelBtnIsVisible = true;

        vm.modalOk = function () {
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalOk);
            navigationService.closeCurrentModal(modalResult);
        };

        vm.modalCancel = function () {
            navigationService.closeCurrentModal();
        };

        loadData(false);
        function loadData(forceReload) {
            if (!pNotificationDefinitionId) {
                alert('NotificationDefinitionId is mandatory');
            } else {
                notificationService.getNotificationInstances({ NotificationDefinitionId: pNotificationDefinitionId }, forceReload)
                    .then((data) => {
                        // drop down instances
                        vm.notificationInstances = data.results;
                        vm.currentForm.NotificationInstanceId = Lazy(vm.notificationInstances).some()
                            ? Lazy(vm.notificationInstances).first().NotificationInstanceId
                            : null;

                        // roles
                        if (vm.nbsDrbacAuthSelector.viewRoles) {
                            drbacService.getDrbacRoles({}, forceReload)
                                .then((data) => {
                                    vm.nbsDrbacAuthSelector.drbacRoleMultiselect.addData(data.results, 'RoleId', 'RoleName');
                                    vm.nbsDrbacAuthSelector.setDrbacRolesSelected(pSelectedDrbacRoles);
                                });
                        }

                        // user groups
                        if (vm.nbsDrbacAuthSelector.viewUserGroups) {
                            drbacService.getDrbacUserGroups({ VirtualCompanyId: virtualCompanyId }, forceReload)
                                .then((data) => {
                                    vm.nbsDrbacAuthSelector.drbacUserGroupMultiselect.addData(data.results, 'DrbacUserGroupId', 'DrbacUserGroupName');
                                    vm.nbsDrbacAuthSelector.setDrbacUserGroupsSelected(pSelectedDrbacUserGroups);
                                });
                        }

                        // users
                        if (vm.nbsDrbacAuthSelector.viewUsers) {
                            rbacService.getCurrentContextAppUsers({ }, forceReload)
                                .then((data) => {
                                    vm.nbsDrbacAuthSelector.appUserMultiselect.addData(data.results, 'AppUserId', 'DisplayNameUI');
                                    vm.nbsDrbacAuthSelector.setAppUsersSelected(pSelectedAppUsers);
                                });
                        }
                    });
            }
        }

        function getModalResult(dialogResult) {
            // on modal close is returned object result
            var objectResult = {
                notificationDefinitionId: pNotificationDefinitionId,
                notificationInstanceId: vm.currentForm.NotificationInstanceId,
                assignmentDescription: vm.currentForm.assignmentDescription,
                appUserIds: vm.nbsDrbacAuthSelector.appUserMultiselect.selectedIds,
                drbacUserGroupIds: vm.nbsDrbacAuthSelector.drbacUserGroupMultiselect.selectedIds,
                drbacRoleIds: vm.nbsDrbacAuthSelector.drbacRoleMultiselect.selectedIds
            };

            var modalResult = new navigationService.modalResult();
            modalResult.data = objectResult;
            modalResult.sourceParams = pParams;
            modalResult.dialogResult = dialogResult;
            return modalResult;
        }
    }

    sendNotificationModal.$inject = injectParams;
    sendNotificationModal.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('sendNotificationModal', sendNotificationModal);
});