﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'jobService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function scheduledTaskDetail($q, $scope, $timeout, $injector, jobService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, jobService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, jobService);
        var scheduledTaskId = navigationService.stateParams().scheduledTaskId;

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var scheduledTaskHistoryTypeEnum = Nembus.Common.Library.util.parseEnum(jobService.dataContext.enumTypes.ScheduledTaskHistoryType, 'ScheduledTaskHistory', vm.translate);
        var scheduledTaskHistoryCommandEnum = Nembus.Common.Library.util.parseEnum(jobService.dataContext.enumTypes.ScheduledTaskHistoryCommand, 'ScheduledTaskHistory', vm.translate);
        var scheduledTaskHistoryTypeEnumForBind = scheduledTaskHistoryTypeEnum.obj.translated.value_name;
        var scheduledTaskHistoryCommandEnumForBind = scheduledTaskHistoryCommandEnum.obj.translated.value_name;

        vm.currentScheduledTaskHistories = [];

        ribbon.setRibbonStatusButton(ribbon.Buttons.New,  false);
        ribbon.setRibbonStatusButton(ribbon.Buttons.Modify,true);
        ribbon.refreshStatusButtons();

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('scheduledtaskhistory', null);
        nbsGridDatacontext.setDatasource([], false);
        nbsGridDatacontext.allowPaging = false;
        nbsGridDatacontext.allowSearching = false;
        nbsGridDatacontext.allowGrouping = false;
        vm.nbsGridDatacontext = nbsGridDatacontext;

        loadData(true);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getScheduledTask(forceReload, scheduledTaskId));
            funcsPromises.push(getScheduledTaskHistories(forceReload, scheduledTaskId));

            $q.all(funcsPromises)
                .then(function (results) {
                    Nembus.Common.Library.parser.AddCronExpressionLabelToList(results[0], 'CronExpression', vm.translate);
                    vm.currentScheduledTask = results[0][0];
                    vm.currentScheduledTask.LastRunTimeForUI = Nembus.Common.Library.converter.date.formatDateTime(vm.currentScheduledTask.LastRunTime, contextService.virtualContext.cultureInfo, true);
                    vm.currentScheduledTask.NextRunTimeForUI = Nembus.Common.Library.converter.date.formatDateTime(vm.currentScheduledTask.NextRunTime, contextService.virtualContext.cultureInfo, true);

                    Lazy(results[1])
                        .each((item) => {
                            item.ScheduledTaskHistoryTypeName = scheduledTaskHistoryTypeEnumForBind[item.ScheduledTaskHistoryTypeId];
                            item.ScheduledTaskHistoryCommandName = scheduledTaskHistoryCommandEnumForBind[item.ScheduledTaskHistoryCommandId];
                        });
                    vm.currentScheduledTaskHistories = results[1];

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.currentScheduledTaskHistories
                    }
                });
        }

        vm.queryCellInfo = function (args) {
            switch (args.column.field) {
                case 'CreationDate':
                    args.cell.innerHTML = Nembus.Common.Library.converter.date.formatDateTimeLocalToUtc(args.data[args.column.field], contextService.virtualContext.cultureInfo);
                    break;
            }
        };

        vm.refreshAction = function () {
            loadData(true);
        };

        vm.cronExpressionOnClick = function () {
            navigationService.navToModal(vm.suspendDirty, 'app.nav.cronexpressiondetail',
                { 'cronstring': vm.currentScheduledTask.CronExpression }
            ).then(function (result) {
                var cronObj = result.data;
                vm.currentScheduledTask.CronExpression = cronObj.getCronExpression();
                vm.currentScheduledTask.CronExpressionLabel = cronObj.getCronLabel();
            });
        };

        // get reference of submitaction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parents = vm.submitAction;
        vm.submitAction = function () {
            parents().then(
                function (saveResult) {
                    vm.refreshAction();
                }
            );
        };

        function getScheduledTask(forceReload, scheduledTaskId) {
            var filter = { ScheduledTaskId: scheduledTaskId };
            var defer = $q.defer();

            jobService.getScheduledTask(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getScheduledTaskHistories(forceReload, scheduledTaskId) {
            var filter = { ScheduledTaskId: scheduledTaskId };
            var defer = $q.defer();

            jobService.getScheduledTaskHistories(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    }

    scheduledTaskDetail.$inject = injectParams;
    scheduledTaskDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('scheduledTaskDetail', scheduledTaskDetail);
});