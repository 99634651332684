﻿'use strict';
define(['../../common/services/nbs-service'], function (nbsService) {

    var injectParams = ['$http', '$q', '$timeout', 'breezeDataLayer'];

    var drbacService = function ($http, $q, $timeout,breezeDataLayer) {

        var dataLayer = breezeDataLayer.Initialize('drbac', true);
        var manager = dataLayer.GetManager();

        var entityTypes = {
            DrbacConfig: 'DrbacConfig',
            DrbacProcess: 'DrbacProcess',
            DrbacProcessDto: 'DrbacProcessDto',
            DrbacProcessConfig: 'DrbacProcessConfig',
            DrbacSchema: 'DrbacSchema',
            DrbacPermission: 'DrbacPermission',
            DrbacPermissionGroup: 'DrbacPermissionGroup',
            DrbacSchemaPermission: 'DrbacSchemaPermission',
            DrbacRole: 'DrbacRole',
            DrbacProcessRole: 'DrbacProcessRole',
            DrbacUserGroup: 'DrbacUserGroup',
            DrbacGlobalAppUserSetting: 'DrbacGlobalAppUserSetting',

            // M2M
            DrbacSchemaPermissionM2MDrbacRoleDto: 'DrbacSchemaPermissionM2MDrbacRoleDto',
            DrbacProcessRoleM2MDrbacUserGroupDto: 'DrbacProcessRoleM2MDrbacUserGroupDto',
            DrbacProcessRoleM2MAppUserDto: 'DrbacProcessRoleM2MAppUserDto',
            DrbacUserGroupM2MAppUserDto: 'DrbacUserGroupM2MAppUserDto',
            DrbacUserGroupM2MDrbacProfileDto: 'DrbacUserGroupM2MDrbacProfileDto',
            DrbacAppUserM2MDrbacProfileDto: 'DrbacAppUserM2MDrbacProfileDto'

        };

        nbsService.call(this, manager, $q, $timeout);

        this.dataContext = {
            drbacConfigs: dataLayer.setContextMethods(manager, nbsService, entityTypes.DrbacConfig),
            drbacSchemas: dataLayer.setContextMethods(manager, nbsService, entityTypes.DrbacSchema),
            drbacProcesses: dataLayer.setContextMethods(manager, nbsService, entityTypes.DrbacProcess),
            drbacProcesseDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.DrbacProcessDto),
            drbacProcessGlobals: dataLayer.setContextMethods(manager, nbsService, entityTypes.DrbacProcess),
            drbacProcessConfigs: dataLayer.setContextMethods(manager, nbsService, entityTypes.DrbacProcessConfig),
            drbacPermissions: dataLayer.setContextMethods(manager, nbsService, entityTypes.DrbacPermission),
            drbacPermissionGroups: dataLayer.setContextMethods(manager, nbsService, entityTypes.DrbacPermissionGroup),
            drbacSchemaPermissions: dataLayer.setContextMethods(manager, nbsService, entityTypes.DrbacSchemaPermission),
            drbacRoles: dataLayer.setContextMethods(manager, nbsService, entityTypes.DrbacRole),
            drbacProcessRoles: dataLayer.setContextMethods(manager, nbsService, entityTypes.DrbacProcessRole),
            drbacSchemaPermissionM2MDrbacRoleDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.DrbacSchemaPermissionM2MDrbacRoleDto),
            drbacProcessRoleM2MDrbacUserGroupDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.DrbacProcessRoleM2MDrbacUserGroupDto),
            drbacProcessRoleM2MAppUserDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.DrbacProcessRoleM2MAppUserDto),
            drbacUserGroupM2MAppUserDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.DrbacUserGroupM2MAppUserDto),
            drbacAppUserM2MDrbacProfileDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.DrbacAppUserM2MDrbacProfileDto),
            drbacUserGroupM2MDrbacProfileDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.DrbacUserGroupM2MDrbacProfileDto),
            drbacUserGroups: dataLayer.setContextMethods(manager, nbsService, entityTypes.DrbacUserGroup),
            drbacGlobalAppUserSettings: dataLayer.setContextMethods(manager, nbsService, entityTypes.DrbacGlobalAppUserSetting)
        };

        // DrbacSchemaPermissionM2MDrbacRoleDto
        // parameter mandatory is DrbacSchemaId
        this.getDrbacSchemaPermissionM2MDrbacRoleDtosBySchema = function (filter, forceReload) {
            var params = { entityType: entityTypes.DrbacSchemaPermissionM2MDrbacRoleDto, cache: this.dataContext.drbacSchemaPermissionM2MDrbacRoleDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "DrbacSchemaPermissionM2MDrbacRoleDtoBySchema");
        };

        // DrbacProcessRoleM2MDrbacUserGroupDto
        // parameter mandatory is DrbacProcessId
        this.getDrbacProcessRoleM2MDrbacUserGroupDtosByProcess = function (filter, forceReload) {
            var params = { entityType: entityTypes.DrbacProcessRoleM2MDrbacUserGroupDto, cache: this.dataContext.drbacProcessRoleM2MDrbacUserGroupDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "DrbacProcessRoleM2MDrbacUserGroupDtoByProcess");
        };

        // DrbacProcessRoleM2MAppUserDto
        // parameter mandatory is DrbacProcessId
        this.getDrbacProcessRoleM2MAppUserDtosByProcess = function (filter, forceReload) {
            var params = { entityType: entityTypes.DrbacProcessRoleM2MAppUserDto, cache: this.dataContext.drbacProcessRoleM2MAppUserDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "DrbacProcessRoleM2MAppUserDtoByProcess");
        };

        // DrbacUserGroupM2MDrbacProfileDto
        this.getDrbacUserGroupM2MDrbacProfileDtos = function (filter, forceReload) {
            var params = { entityType: entityTypes.DrbacUserGroupM2MDrbacProfileDto, cache: this.dataContext.drbacUserGroupM2MDrbacProfileDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "DrbacUserGroupM2MDrbacProfileDto");
        };

        // DrbacAppUserM2MDrbacProfileDto
        this.getDrbacAppUserM2MDrbacProfileDtos = function (filter, forceReload) {
            var params = { entityType: entityTypes.DrbacAppUserM2MDrbacProfileDto, cache: this.dataContext.drbacAppUserM2MDrbacProfileDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "DrbacAppUserM2MDrbacProfileDto");
        };

        // DrbacUserGroupM2MAppUserDto
        // parameter mandatory is DrbacUserGroupId
        this.getDrbacUserGroupM2MAppUserDtosByUserGroup = function (filter, forceReload) {
            var params = { entityType: entityTypes.DrbacUserGroupM2MAppUserDto, cache: this.dataContext.drbacUserGroupM2MAppUserDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "DrbacUserGroupM2MAppUserDtoByUserGroup");
        };

        // DrbacProcess/Global
        this.getDrbacProcesses = function (filter, forceReload) {
            var params = { entityType: entityTypes.DrbacProcess, cache: this.dataContext.drbacProcesses.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "DrbacProcesses");
        };
        this.getDrbacProcess = function (filter, forceReload) {
            var params = { key: filter.DrbacProcessId, dataContext: this.dataContext.drbacProcesses };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "DrbacProcesses");
        };
        this.getDrbacProcessGlobals = function (filter, forceReload) {
            var params = { entityType: entityTypes.DrbacProcess, cache: this.dataContext.drbacProcessGlobals.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "DrbacProcessGlobals");
        };
        this.getDrbacProcessGlobal = function (filter, forceReload) {
            var params = { key: filter.DrbacProcessId, dataContext: this.dataContext.drbacProcessGlobals };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "DrbacProcessGlobal");
        };
        this.getDrbacProcessGlobalsCrossContext = function (filter, forceReload) {
            var params = { entityType: entityTypes.DrbacProcess, cache: this.dataContext.drbacProcessGlobals.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "DrbacProcessGlobalCrossContext");
        };


        
        // DrbacConfig
        this.getDrbacConfig = function (filter, forceReload) {
            var params = { key: filter.DrbacConfigId, dataContext: this.dataContext.drbacConfigs };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "DrbacConfigs");
        };

        // DrbacProcessConfig
        this.getDrbacProcessConfigs = function (filter, forceReload) {
            var params = { entityType: entityTypes.DrbacProcessConfig, cache: this.dataContext.drbacProcessConfigs.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "DrbacProcessConfigs");
        };
        this.getDrbacProcessConfig = function (filter, forceReload) {
            var params = { key: filter.DrbacProcessConfigId, dataContext: this.dataContext.drbacProcessConfigs };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "DrbacProcessConfigs");
        };

        // DrbacSchema
        this.getDrbacSchemas = function (filter, forceReload) {
            var params = { entityType: entityTypes.DrbacSchema, cache: this.dataContext.drbacSchemas.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "DrbacSchemas");
        };
        this.getDrbacSchema = function (filter, forceReload) {
            var params = { key: filter.DrbacSchemaId, dataContext: this.dataContext.drbacSchemas };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "DrbacSchemas");
        };

        // DrbacPermission
        this.getDrbacPermissions = function (filter, forceReload) {
            var params = { entityType: entityTypes.DrbacPermission, cache: this.dataContext.drbacPermissions.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "DrbacPermissions");
        };

        // DrbacPermissionGroup
        this.getDrbacPermissionGroups = function (filter, forceReload) {
            var params = { entityType: entityTypes.DrbacPermissionGroup, cache: this.dataContext.drbacPermissionGroups.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "DrbacPermissionGroups");
        };
        this.getDrbacPermissionGroup = function (filter, forceReload) {
            var params = { key: filter.DrbacPermissionGroupId, dataContext: this.dataContext.drbacPermissionGroups };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "DrbacPermissionGroups");
        };

        // DrbacSchemaPermission
        this.getDrbacSchemaPermissions = function (filter, forceReload) {
            var params = { entityType: entityTypes.DrbacSchemaPermission, cache: this.dataContext.drbacSchemaPermissions.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "DrbacSchemaPermissions");
        };

        // DrbacRole
        this.getDrbacRoles = function (filter, forceReload) {
            var params = { entityType: entityTypes.DrbacRole, cache: this.dataContext.drbacRoles.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "DrbacRoles");
        };
        this.getDrbacRole = function (filter, forceReload) {
            var params = { key: filter.RoleId, dataContext: this.dataContext.drbacRoles };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "DrbacRoles");
        };

        //ProcessForEvent
        this.getProcessForEvent = function (filter, forceReload) {
            var params = { key: filter.DrbacProcessId, dataContext: this.dataContext.drbacProcesses };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "ProcessForEvent");
        };
        this.getProcessForEventDto = function (filter, forceReload) {
            var params = { key: filter.DrbacProcessId, dataContext: this.dataContext.drbacProcesseDtos };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "ProcessForEventDto");
        };


        // DrbacProcessRole
        this.getDrbacProcessRoles = function (filter, forceReload) {
            var params = { entityType: entityTypes.DrbacProcessRole, cache: this.dataContext.drbacProcessRoles.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "DrbacProcessRoles");
        };

        // DrbacUserGroup
        this.getDrbacUserGroups = function (filter, forceReload) {
            var params = { entityType: entityTypes.DrbacSchema, cache: this.dataContext.drbacUserGroups.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "DrbacUserGroups");
        };
        this.getDrbacUserGroup = function (filter, forceReload) {
            var params = { key: filter.DrbacUserGroupId, dataContext: this.dataContext.drbacUserGroups };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "DrbacUserGroups");
        };

        this.getDrbacGlobalAppUserSettings = function (filter, forceReload) {
            var params = { entityType: entityTypes.DrbacGlobalAppUserSetting, cache: this.dataContext.drbacGlobalAppUserSettings.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "DrbacGlobalAppUserSettings");
        };

        //Http Post
        this.cloneDrbacSchema = function (filter) {
            var query = dataLayer.InvokeQuery("CloneDrbacSchema", filter);
            return manager.executeQuery(query);
        };

        //Http Post
        this.cloneDrbacProcess = function (filter) {
            var query = dataLayer.InvokeQuery("CloneDrbacProcess", filter);
            return manager.executeQuery(query);
        };

        //Http Post
        this.synchroPermissionAllVirtualContext = function () {
            var query = dataLayer.InvokeQuery("SynchroPermissionAllVirtualContext", {});
            return manager.executeQuery(query);
        };

        //Http Post
        this.setDefaultVirtualCompany = function (filter) {
            var query = dataLayer.InvokeQuery("SetDefaultVirtualCompany", filter);
            return manager.executeQuery(query);
        };        
    };

    drbacService.$inject = injectParams;
    drbacService.prototype = Object.create(nbsService.prototype);
    angular.module('nbs.shared.module').service('drbacService', drbacService);

});