﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function virtualCompanyDetail($q, $scope, $timeout, $injector, foundationService, navigationService, checkStateChangeService, contextService) {
        // ADD: will be posted a VirtualCompanyCreationRequest
        // MODIFY: will be posted a VirtualCompany
        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, foundationService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var currentVirtualCompanyId = navigationService.stateParams().virtualCompanyId;

        if (currentVirtualCompanyId) {
            vm.setIsViewing();
            getVirtualCompany(true);
        }
        else {
            vm.setIsAdding();
            vm.currentVirtualCompany = foundationService.dataContext.virtualCompanyCreationRequests.add({
                VirtualCompanyCreationRequestId: NbsGuidManager.newGuid()
            });

            // manage of passport photo
            vm.photoDatacontext = {
                resizeMode: 'no-resize',
                photoImageId: null
            }; 
        }

        vm.openDefaultCompanyDataModal = function () {
            vm.modalInstanceDefaultCompany = navigationService.modal().open(vm.modalOptions({
                windowClass: vm.NBS_MODAL_CLASS_FORM_MD,
                templateUrl: 'defaultCompanyTemplate',
                scope: $scope
            }));
            vm.modalInstanceDefaultCompany.result.then(function () {
                submit();
            }, function () {
                vm.currentVirtualCompany.CompanyCode = null;
                vm.currentVirtualCompany.CompanyName = null;
            });
        };

        vm.modalOk = function () {
            vm.currentVirtualCompany.entityAspect.validateEntity();
            if (vm.currentVirtualCompany.CompanyCode && vm.currentVirtualCompany.CompanyName) {
                vm.modalInstanceDefaultCompany.close();
            }
        };

        vm.modalCancel = function () {
            vm.modalInstanceDefaultCompany.dismiss();
        };

        var parents = vm.submitAction;
        function submit() {
            parents().then(
                function (saveResult) {
                    if (saveResult.entities.length > 0 && vm.viewContext.isAddingState) {
                        // rejectChanges() only because VirtualCompanyCreationRequest is not really added
                        foundationService.rejectChanges();

                        navigationService.navigateTo("app.nav.virtualcompanyftc", { 'virtualCompanyId': vm.currentVirtualCompany.VirtualCompanyId });
                    }
                });
        }

        vm.submitAction = function () {
            //force validation
            vm.currentVirtualCompany.entityAspect.validateEntity();

            var companyCodeHasValidationError = Lazy(vm.currentVirtualCompany.entityAspect.getValidationErrors()).filter((error) => { return error.property.name === 'CompanyCode'; }).some();
            var companyNameHasValidationError = Lazy(vm.currentVirtualCompany.entityAspect.getValidationErrors()).filter((error) => { return error.property.name === 'CompanyName'; }).some();

            if (vm.currentVirtualCompany.entityAspect.getValidationErrors().length < 3
                && companyCodeHasValidationError
                && companyNameHasValidationError) {

                // remove validation error from company modal layout
                vm.currentVirtualCompany.entityAspect.clearValidationErrors();

                vm.openDefaultCompanyDataModal();
            } else {
                // submit action
                submit();
            }
        };

        vm.onImageChange = function (result) {
            if (result.action === 'upload') {
                vm.currentVirtualCompany.TemporaryFileName = result.temporaryFileName;
                vm.currentVirtualCompany.TemporaryFileNameAction = 1; // ImageLogoAction.Upload
            }
            if (result.action === 'delete') {
                vm.currentVirtualCompany.TemporaryFileName = null;
                vm.currentVirtualCompany.TemporaryFileNameAction = 2; // ImageLogoAction.Delete
            }
        };

        function getVirtualCompany(forceReload) {
            var filter = { VirtualCompanyId: currentVirtualCompanyId };
            var defer = $q.defer();

            foundationService.getVirtualCompany(filter, forceReload)
                .then(function (data) {
                    vm.currentVirtualCompany = data.results[0];

                    // manage of passport photo
                    vm.photoDatacontext = {
                        photoImageId: vm.currentVirtualCompany.VirtualCompanyImageId
                    }; 
                    
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    }

    virtualCompanyDetail.$inject = injectParams;
    virtualCompanyDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('virtualCompanyDetail', virtualCompanyDetail);
});