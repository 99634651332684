﻿
'use strict';
define(['../../common/services/nbs-service'], function (nbsService) {

    var injectParams = ['$http', '$q', '$locale', '$timeout', 'breezeDataLayer'];

    var activityService = function ($http, $q, $locale, $timeout, breezeDataLayer) {

        var dataLayer = breezeDataLayer.Initialize('activity', true);
        var manager = dataLayer.GetManager();
        var ejDataManager = dataLayer.GetEjDataManager();
        var entityTypes = {
            Activity: 'Activity',
            FrequencyCode: 'FrequencyCode',
            DependentActivityPathDto: 'DependentActivityPathDto',
            SchedulingGroup: 'SchedulingGroup',
            ActivityM2MVhrGroup: 'ActivityM2MVhrGroup',
            ActivityVhrDto: 'ActivityVhrDto',
            ActivityClientData: 'ActivityClientData',
            MagicBucketRequest: 'MagicBucketRequest' //also managed in hrbucket-service,foundation-service
        };
        var me = this;
        nbsService.call(this, manager, $q, $timeout);

        //breeze client type
        //client validation
        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.ActivityClientData,
                dataProperties: {
                    ActivityClientDataId: { type: breeze.DataType.Guid },
                    ActivityId: { type: breeze.DataType.Int32 },
                    ActivityKindId: { type: breeze.DataType.Int32 },
                    ActivityCode: { type: breeze.DataType.String },
                    ActivityName: { type: breeze.DataType.String }
                }
            },
            'Nembus.Domain'
        );

        var activityClientData = manager.metadataStore.getEntityType('ActivityClientData');
        activityClientData.getProperty("ActivityCode").validators.push(new breeze.Validator("codeValidator", Nembus.Common.Library.validators.requiredFieldValidator));
        activityClientData.getProperty("ActivityName").validators.push(new breeze.Validator("nameValidator", Nembus.Common.Library.validators.requiredFieldValidator));

        // add client validation valid only for SubBom (Attività)
        this.addClientValidation = function () {
            var activity = manager.metadataStore.getEntityType('Activity');
            activity.getProperty("ActivityEffortQuota").validators.push(new breeze.Validator("activityEffortQuotaValidator", Nembus.Common.Library.validators.requiredFieldValidator));
            activity.getProperty("ActivityEffortUnitId").validators.push(new breeze.Validator("activityEffortUnitIdValidator", Nembus.Common.Library.validators.requiredFieldValidator));
        };

        // remove client validation valid only for SubBom (Attività)
        this.removeClientValidation = function () {
            // remove validators added in bom-service
            var activity = manager.metadataStore.getEntityType('Activity');
            activity.getProperty("SpanValidityQuota").validators = Lazy(activity.getProperty("SpanValidityQuota").validators)
                .filter((validator) => { return validator.name !== "spanValidityQuotaValidator"; })
                .toArray();

            activity.getProperty("SpanValidityUnitId").validators = Lazy(activity.getProperty("SpanValidityUnitId").validators)
                .filter((validator) => { return validator.name !== "spanValidityUnitIdValidator"; })
                .toArray();
        };

        this.dataContext = {
            magicBucketRequests: {
                add: function (entity) { return nbsService.prototype.createEntityStatic(entityTypes.MagicBucketRequest, entity, manager); }
            },

            ActivityClientData: dataLayer.setContextMethods(manager, nbsService, entityTypes.ActivityClientData),
            activities: dataLayer.setContextMethods(manager, nbsService, entityTypes.Activity),
            frequencyCodes: dataLayer.setContextMethods(manager, nbsService, entityTypes.FrequencyCode),
            dependActivities: dataLayer.setContextMethods(manager, nbsService, entityTypes.DependentActivityPathDto),
            schedulingGroups: dataLayer.setContextMethods(manager, nbsService, entityTypes.SchedulingGroup),
            activityVhrs: dataLayer.setContextMethods(manager, nbsService, entityTypes.ActivityVhrDto),

            //Returns a list of object with name, value properties
            enumTypes: {
                ActivityKind: dataLayer.GetEnumType('ActivityKind'),                    //used always like object
                //ActivityInvoice: dataLayer.GetEnumType('ActivityInvoice'),
                ActivityXVhrStatus: Lazy(dataLayer.GetEnumType('ActivityXVhrStatus')).reject({ value: "0" }).toArray(),     // "None" item removed
                ActivityXVhrMode: Lazy(dataLayer.GetEnumType('ActivityXVhrMode')).reject({ value: "0" }).toArray(),     // "None" item removed
                RouteSchedulingType: dataLayer.GetEnumType('RouteSchedulingType'),
                MagicBucketAction: dataLayer.GetEnumType('MagicBucketAction')
            }
        };

        this.createQuery = function (entityName) {
            return dataLayer.CreateQuery(entityName);
        };

        this.getSchedulingGroups = function (filter, forceReload) {
            var params = { entityType: entityTypes.SchedulingGroup, cache: this.dataContext.schedulingGroups.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "SchedulingGroups");
        };
        this.getSchedulingGroup = function (filter, forceReload) {
            var params = { key: filter.SchedulingGroupId, dataContext: this.dataContext.schedulingGroups };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "SchedulingGroups");
        };

        this.getDependentActivityPaths = function (filter, forceReload) {
            // this clear cache and force reload from db
            forceReload = true;

            var params = { entityType: entityTypes.DependentActivityPathDto, cache: this.dataContext.dependActivities.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "DependentActivityPaths");
        };
        this.getDependentActivityPath = function (filter, forceReload) {
            var params = { key: filter.ActivityId, dataContext: this.dataContext.dependActivities };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "DependentActivityPaths");
        };

        this.getActivities = function (filter, forceReload) {
            var params = { entityType: entityTypes.Activity, cache: this.dataContext.activities.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "Activities");
        };
        this.getActivity = function (filter, forceReload) {
            var params = { key: filter.ActivityId, dataContext: this.dataContext.activities };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "Activities");
        };
        
        this.getFrequencyCodes = function (filter, forceReload) {
            var params = { entityType: entityTypes.FrequencyCode, cache: this.dataContext.frequencyCodes.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "FrequencyCodes");
        };
        this.getFrequencyCode = function (filter, forceReload) {
            var params = { key: filter.FrequencyCodeId, dataContext: this.dataContext.frequencyCodes };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "FrequencyCodes");
        };

        this.getHrGroupM2MActivities = function (filter, forceReload) {
            var params = { entityType: entityTypes.ActivityM2MVhrGroup, cache: this.dataContext.activityM2MVhrGroups.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "ActivityM2MVhrGroup");
        };

        this.getActivityVhr = function (filter, forceReload) {
            var params = { entityType: entityTypes.ActivityVhrDto, cache: this.dataContext.activityVhrs.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "ActivityVhr");
        };

        // Activities paged
        this.getActivitiesPagedDm = function () {
            return ejDataManager.getEjManager('ActivityDtosPaged');
        };
        this.getActivitiesPagedDmQuery = function () {
            var dmObj = {};
            dmObj.dm = ejDataManager.getEjManager('');
            dmObj.query = ej.Query().from('ActivityDtosPaged');
            return dmObj;
        };

        //Http Post
        this.cloneActivity = function (activityClientData) {
            var query = dataLayer.InvokeQuery("CloneActivity", activityClientData);
            return manager.executeQuery(query);
        };

        //#region Wizard
        //cross controller (through wizard steps of add dependent activity)
        function WizardDependActivityCollector() {
            var me = this;
            me.activityIds = [];
            me.dependActivityIsSingle = false;
            me.dependActivityIsPeriodical = true;
            me.activityPathClassificationBw = 0;
            me.workCenterGroupId = null;
            me.workCenterGroupName = null;
            me.workCenterId = null;
            me.workCenterName = null;
        }
        this.setWizardDependActivityCollector = function () {
            this.wizardDependActivityCollector = new WizardDependActivityCollector();
        }
        this.wizardDependActivityCollector = null;
        //#endregion

        //#region DataManager
        this.getActivityDataManager = function (supportData, filter, dmObj) {
            if (dmObj) {
                dmObj.complexParams = [{ filter: filter }];
                return dmObj;
            }

            dmObj = this.getActivitiesPagedDmQuery();
            dmObj.dmgrid = this.getActivitiesPagedDm();
            var ejDataManager = dmObj.dmgrid;

            ejDataManager.complexParams.push({ filter: filter });
            ejDataManager.fieldNameReplacer = {
                ActivityEffortUnitName: 'ActivityEffortUnitId',
                ActivityEffortQuotaUI: 'ActivityEffortQuota'
            };
            // properties inserte are calculated before query is launched in breezeDataLayer service
            ejDataManager.onWhereCustomPredicate = {
                ActivityEffortUnitName: {
                    FieldType: this.FilterMenu.FieldType.Enum,
                    ReplaceFieldNameWith: 'ActivityEffortUnitId',
                    Datasource: supportData.effortUnitEnumParsed.list.translated
                }
            };
            ejDataManager.processResponseData = function (data) {
                if (data) {
                    dmObj.dm.currentViewChildGridData = [];

                    data.results = me.getAttachedEntities(data.results);
                    Lazy(data.results)
                        .each((item) => {
                            item.ActivityEffortUnitName = supportData.effortUnitEnumParsed.obj.translated.value_name[item.ActivityEffortUnitId];
                            if (item.ActivityEffortQuota !== null && item.ActivityEffortQuota !== undefined) {
                                item.ActivityEffortQuotaUI = Nembus.Common.Library.converter.number.formatDecimal(item.ActivityEffortQuota, $locale);
                            }

                        });

                    return data.results;
                }
            };
            ejDataManager.freeSearchFields = ['ActivityCode', 'ActivityName', 'VhrEventTypeName', 'VhrEventGroupName', 'SchedulingGroupName', 'ModuleTargetName'];
            ejDataManager.freeSearchFieldsNumber = ['ActivityId'];
            
            dmObj.dm.onWhereCustomPredicate = ejDataManager.onWhereCustomPredicate;
            dmObj.dm.fieldNameReplacer = ejDataManager.fieldNameReplacer;
            dmObj.dm.complexParams = ejDataManager.complexParams;
            dmObj.dm.processResponseData = ejDataManager.processResponseData;
            return dmObj;
        }
        //#endregion
    };

    activityService.$inject = injectParams;
    activityService.prototype = Object.create(nbsService.prototype);
    angular.module('nbs.rds.module').service('activityService', activityService);
});