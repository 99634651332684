﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'drbacService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function drbacRoleList($q, $scope, $timeout, $injector, drbacService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, drbacService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return drbacRoleSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, drbacService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
        var drbacRoleSelected = null;

        vm.drbacRoles = [];

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getDrbacRoles(forceReload, virtualCompanyId));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.drbacRoles = results[0];

                    var nbsGridDatacontext = new NbsGrid(vm.translate);
                    nbsGridDatacontext.configureGrid('drbacrole', null);
                    nbsGridDatacontext.viewCommonBar();
                    nbsGridDatacontext.setDatasource(vm.drbacRoles, false);
                    vm.nbsGridDatacontext = nbsGridDatacontext;
                });
        }

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.drbacroledetail", { 'drbacRoleId': args.data.RoleId });
        };

        vm.newAction = function () {
            navigationService.navigateTo("app.nav.drbacroledetail", { 'drbacRoleId': null });
        };

        vm.refreshAction = function () {
            getDrbacRoles(true, virtualCompanyId)
                .then((results) => {
                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: results
                    }
                });
        };

        vm.deleteAction = function () {
            if (!drbacRoleSelected)
                return;

            drbacService.deleteEntity(drbacRoleSelected);
            vm.submitAction()
                .then(function (saveResult) {
                    vm.refreshAction();
                });
        };

        vm.gridSelection = function (args) {
            $timeout(() => {
                drbacRoleSelected = args.data;

                if (drbacRoleSelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, !drbacRoleSelected.IsSystem);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                }

                ribbon.refreshStatusButtons();
            });
        };

        function getDrbacRoles(forceReload, virtualCompanyId) {
            var filter = { VirtualCompanyId: virtualCompanyId };
            var defer = $q.defer();

            drbacService.getDrbacRoles(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    }

    drbacRoleList.$inject = injectParams;
    drbacRoleList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rbac.module').controller('drbacRoleList', drbacRoleList);
});