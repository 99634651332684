﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'metricService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function metricInstancesList($q, $scope, $timeout, $injector, metricService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, metricService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return vm.metricInstanceSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, metricService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        ribbon.addTab(new ribbon.Tab("ActionPanelTabManage", [ribbon.GetMetricSection()]));
        vm.ribbonDatacontext = ribbon;

        var metricDataRequestStatusParsedEnum = Nembus.Common.Library.util.parseEnum(metricService.dataContext.enumTypes.MetricDataRequestStatus, null, vm.translate);
        var metricDataRequestStatusObj = metricDataRequestStatusParsedEnum.obj.normal.name_value;
        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var viewMode = navigationService.currentViewMode();
        var navViewMode = viewMode !== '_' ? '-' + viewMode : '';

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('metricinstance', null);
        //nbsGridDatacontext.viewCustomBar();
        nbsGridDatacontext.viewCommonBar();
        nbsGridDatacontext.setAdaptToPage();
        nbsGridDatacontext.setDatasource([], true); // fake datasource, only to create grid
        vm.nbsGridDatacontext = nbsGridDatacontext;

        var ejDataManager = null;
        var metricInstanceFilter = { ModuleTargetId: moduleTargetId };
        var supportData = { translate: vm.translate, contextService: contextService };
        ejDataManager = metricService.getMetricInstanceManager(supportData, metricInstanceFilter, ejDataManager);
        nbsGridDatacontext.setDatasource(ejDataManager, true);
        refreshGridDatasource(metricInstanceFilter);

        //// hook from gridbar
        //var ejDataManager = null;
        //var metricInstanceFilter = null;
        //vm.metricGridBarOnChanges = function (result) {
        //    metricInstanceFilter = result.metricInstanceFilter;

        //    if (ejDataManager === null) {
        //        var supportData = { translate: vm.translate, contextService: contextService };
        //        ejDataManager = metricService.getMetricInstanceManager(supportData, metricInstanceFilter, ejDataManager);
        //        nbsGridDatacontext.setDatasource(ejDataManager, true);
        //        refreshGridDatasource(metricInstanceFilter);
        //    } else {
        //        refreshGridDatasource(metricInstanceFilter)
        //    }
        //};

        function refreshGridDatasource(filter) {
            $timeout(() => {
                vm.forceDatamanagerFilter = {
                    refreshKey: new Date().getTime(),
                    filter: filter
                }
            });
        }

        vm.refreshAction = function () {
            $timeout(() => {
                vm.forceGridRefresh = new Date().getTime();
            });
        };

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.metricinstancedetail" + navViewMode, { 'metricInstanceId': args.data.MetricInstanceId, 'appStoreCode': appStoreCode });
        };

        vm.newAction = function () {
            navigationService.navigateTo("app.nav.metricinstancedetail" + navViewMode, { 'metricInstanceId': null, 'appStoreCode': appStoreCode });
        };

        vm.gridSelection = function (args) {
            vm.metricInstanceSelected = args.data;

            $timeout(() => {
                if (vm.metricInstanceSelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, true);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, true);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.RunMetric, true);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, false);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.RunMetric, false);
                }

                ribbon.refreshStatusButtons();
            });
        };

        vm.queryCellInfo = function (args) {
            switch (args.column.field) {
                case 'RunMetricNextRunTime':
                case 'RunMetricLastRunTime':
                    args.cell.innerHTML = Nembus.Common.Library.converter.date.formatDateTime(args.data[args.column.field], contextService.virtualContext.cultureInfo, false);
                    break;
            }
        };

        vm.deleteAction = function () {
            if (!vm.metricInstanceSelected) {
                return;
            }

            metricService.deleteEntity(vm.metricInstanceSelected);
            vm.submitAction()
                .then(function (saveResult) {
                    vm.refreshAction();
                });
        };

        vm.runMetricAction = function () {
            if (!vm.metricInstanceSelected) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            vm.metricDataRequest = metricService.dataContext.metricDataRequests.add({
                MetricDataRequestStatusId: metricDataRequestStatusObj.Inserted,
                MetricInstanceId: vm.metricInstanceSelected.MetricInstanceId,
                StartDate: null,
                EndDate: null
            });

            vm.modalInstance = navigationService.modal().open(vm.modalOptions({ templateUrl: 'nbsSelectionStartDateModalTemplate', scope: $scope }));
            vm.modalInstance.result.then(function () { }, function () { });
            vm.modalInstance.ok = function () {
                vm.submitAction()
                    .then(function (saveResult) {
                        if (vm.metricDataRequest.IsBatch) {
                            contextService.addBatchRequest({
                                requestId: vm.metricDataRequest.BatchRequestId,
                                requestName: vm.metricInstanceSelected.MetricInstanceCode + ' ' + vm.metricInstanceSelected.MetricInstanceName,
                                requestStatus: 'inserted',
                                onCompletedAutoAction: null,    // is a function called on completed batch that must return a promise (with jobResult) managed in appPanel
                                detailAction: null             // is a function called from appPanel.cshml that links to batch request detail
                            });
                        } else {
                            vm.refreshAction();
                        }

                        vm.modalInstance.close();
                    });
            };
            vm.modalInstance.cancel = function () {
                vm.metricDataRequest.entityAspect.rejectChanges();
                vm.modalInstance.dismiss();
            };
        };

        vm.copyAction = function () {
            if (!vm.metricInstanceSelected) {
                return;
            }

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        var filter = {
                            MetricInstanceId: vm.metricInstanceSelected.MetricInstanceId
                        };

                        metricService.cloneMetricInstance(filter)
                            .then(function (result) {
                                vm.refreshAction();
                            });
                    }
                });
        };

        vm.createGridEvent = function (result) {
            vm.metricGridBarDataContext = {
                commonBarDatasource: result.barDatasource,
                uiMode: 'instance',
                defaultKeys: {
                    columnsKey: null, sortingKey: null
                }
            };
        };

        vm.gridUiOnChanges = function (result) {
            vm.refreshGridUiComponent = {
                refreshKey: new Date().getTime(),
                uiData: result
            }
        }
    }

    metricInstancesList.$inject = injectParams;
    metricInstancesList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('metricInstancesList', metricInstancesList);
});