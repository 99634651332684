﻿
'use strict';
define(['../../common/services/nbs-service'], function (nbsService) {

    var injectParams = ['$http', '$q', '$timeout', 'breezeDataLayer'];

    var reportService = function ($http, $q, $timeout, breezeDataLayer) {

        var dataLayer = breezeDataLayer.Initialize('report', true);
        var manager = dataLayer.GetManager();
        var ejDataManager = dataLayer.GetEjDataManager();
        var entityTypes = {
            ReportTemplate: 'ReportTemplate',
            ReportDefinition: 'ReportDefinition',
            ReportDefinitionDto: 'ReportDefinitionDto',

            InvoiceSummaryReportFormClientData: 'InvoiceSummaryReportFormClientData',
            InvoiceSummaryGroupedReportFormClientData: 'InvoiceSummaryGroupedReportFormClientData',
            VhrEventPricedReportFormClientData: 'VhrEventPricedReportFormClientData',
            JobRequestPricedReportFormClientData: 'JobRequestPricedReportFormClientData',
            InvoiceListAccountingSummaryReportFormClientData: 'InvoiceListAccountingSummaryReportFormClientData',
            InvoiceListAccountingSummaryPreFilterInvoiceReportFormClientData: 'InvoiceListAccountingSummaryPreFilterInvoiceReportFormClientData',
            InvoiceListAccountingSummaryPreFilterInvoice2ReportFormClientData: 'InvoiceListAccountingSummaryPreFilterInvoice2ReportFormClientData',
            VirtualEquipmentsVhrEventListReportFormClientData: 'VirtualEquipmentsVhrEventListReportFormClientData',
            AuthLogReportFormClientData: 'AuthLogReportFormClientData',
            ReportEntityDefinitionClientData: 'ReportEntityDefinitionClientData',

            R_710_400_1_ClientData: 'R_710_400_1_ClientData',

            ReportHtmlRequest: 'ReportHtmlRequest'
        };
        var me = this;

        nbsService.call(this, manager, $q, $timeout);

        //#region **** breeze client type validation
        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.VhrEventPricedReportFormClientData,
                dataProperties: {
                    Id: { type: breeze.DataType.Guid },
                    ManagementContractId: { type: breeze.DataType.Int32 },
                    IsCustomer: { type: breeze.DataType.Boolean }
                }
            }, 'Nembus.Domain'
        );
        var vhrEventPricedReportFormClientData = manager.metadataStore.getEntityType(entityTypes.VhrEventPricedReportFormClientData);
        vhrEventPricedReportFormClientData.getProperty("ManagementContractId").validators.push(new breeze.Validator("managementContractIdValidator", Nembus.Common.Library.validators.requiredFieldValidator));

        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.JobRequestPricedReportFormClientData,
                dataProperties: {
                    Id: { type: breeze.DataType.Guid },
                    ManagementContractId: { type: breeze.DataType.Int32 },
                    StartDate1: { type: breeze.DataType.DateTime },
                    StartDate2: { type: breeze.DataType.DateTime }
                }
            }, 'Nembus.Domain'
        );
        var jobRequestPricedReportFormClientData = manager.metadataStore.getEntityType(entityTypes.JobRequestPricedReportFormClientData);
        jobRequestPricedReportFormClientData.getProperty("ManagementContractId").validators.push(new breeze.Validator("managementContractIdValidator", Nembus.Common.Library.validators.requiredFieldValidator));
        jobRequestPricedReportFormClientData.getProperty("StartDate1").validators.push(new breeze.Validator("fromDateValidator", Nembus.Common.Library.validators.requiredFieldValidator));
        jobRequestPricedReportFormClientData.getProperty("StartDate2").validators.push(new breeze.Validator("toDateValidator", Nembus.Common.Library.validators.requiredFieldValidator));

        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.InvoiceSummaryReportFormClientData,
                dataProperties: {
                    Id: { type: breeze.DataType.Guid },
                    SearchMode: { type: breeze.DataType.String },
                    SearchForRegister: { type: breeze.DataType.Boolean },
                    InvoiceRegisterId: { type: breeze.DataType.Int32 },
                    InvoiceRegisterIdSerialized: { type: breeze.DataType.String },
                    SupplierIdSerialized: { type: breeze.DataType.String }

                }
            }, 'Nembus.Domain'
        );
        var invoiceSummaryReportFormClientData = manager.metadataStore.getEntityType(entityTypes.InvoiceSummaryReportFormClientData);
        invoiceSummaryReportFormClientData.getProperty("InvoiceRegisterId").validators.push(
            new breeze.Validator("invoiceRegisterValidator", function (value, context) {
                if (context.entity.SearchMode === 'single-register' && !context.entity.InvoiceRegisterId) {
                    return false;
                } else {
                    return true;
                }
            })
        );
        invoiceSummaryReportFormClientData.getProperty("InvoiceRegisterIdSerialized").validators.push(
            new breeze.Validator("invoiceRegisterValidator", function (value, context) {
                if (context.entity.SearchMode === 'multiple-register' && context.entity.SearchForRegister && !context.entity.InvoiceRegisterIdSerialized) {
                    return false;
                } else {
                    return true;
                }
            })
        );
        invoiceSummaryReportFormClientData.getProperty("SupplierIdSerialized").validators.push(
            new breeze.Validator("supplierValidator", function (value, context) {
                if (context.entity.SearchMode === 'multiple-register' && !context.entity.SearchForRegister && !context.entity.SupplierIdSerialized) {
                    return false;
                } else {
                    return true;
                }
            })
        );

        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.InvoiceSummaryGroupedReportFormClientData,
                dataProperties: {
                    Id: { type: breeze.DataType.Guid },
                    InvoiceDate1: { type: breeze.DataType.DateTime },
                    InvoiceDate2: { type: breeze.DataType.DateTime },
                    GroupedBy: { type: breeze.DataType.String },
                    InvoiceStatus: { type: breeze.DataType.Int32 }
                }
            }, 'Nembus.Domain'
        );
        var invoiceSummaryGroupedReportFormClientData = manager.metadataStore.getEntityType(entityTypes.InvoiceSummaryGroupedReportFormClientData);
        invoiceSummaryGroupedReportFormClientData.getProperty("InvoiceDate1").validators.push(new breeze.Validator("startDateValidator", Nembus.Common.Library.validators.requiredFieldValidator));
        invoiceSummaryGroupedReportFormClientData.getProperty("InvoiceDate2").validators.push(new breeze.Validator("endDateValidator", Nembus.Common.Library.validators.requiredFieldValidator));
        invoiceSummaryGroupedReportFormClientData.getProperty("GroupedBy").validators.push(new breeze.Validator("groupingValidator", Nembus.Common.Library.validators.requiredFieldValidator));
        invoiceSummaryGroupedReportFormClientData.getProperty("InvoiceStatus").validators.push(new breeze.Validator("groupingValidator", Nembus.Common.Library.validators.requiredFieldValidator));

        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.InvoiceListAccountingSummaryReportFormClientData,
                dataProperties: {
                    Id: { type: breeze.DataType.Guid },
                    StartDate: { type: breeze.DataType.DateTime },
                    EndDate: { type: breeze.DataType.DateTime },
                    AccountTypeId: { type: breeze.DataType.String }
                }
            }, 'Nembus.Domain'
        );
        var invoiceListAccountingSummaryReportFormClientData = manager.metadataStore.getEntityType(entityTypes.InvoiceListAccountingSummaryReportFormClientData);
        invoiceListAccountingSummaryReportFormClientData.getProperty("StartDate").validators.push(new breeze.Validator("startDatePeriodValidator", Nembus.Common.Library.validators.requiredFieldValidator));
        invoiceListAccountingSummaryReportFormClientData.getProperty("EndDate").validators.push(new breeze.Validator("endDatePeriodValidator", Nembus.Common.Library.validators.requiredFieldValidator));
        invoiceListAccountingSummaryReportFormClientData.getProperty("AccountTypeId").validators.push(new breeze.Validator("groupingValidator", Nembus.Common.Library.validators.requiredFieldValidator));

        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.InvoiceListAccountingSummaryPreFilterInvoiceReportFormClientData,
                dataProperties: {
                    Id: { type: breeze.DataType.Guid },
                    StartDateInvoice: { type: breeze.DataType.DateTime },
                    EndDateInvoice: { type: breeze.DataType.DateTime },
                    StartDateCompetence: { type: breeze.DataType.DateTime },
                    EndDateCompetence: { type: breeze.DataType.DateTime },
                    AccountTypeId: { type: breeze.DataType.String }
                }
            }, 'Nembus.Domain'
        );
        var invoiceListAccountingSummaryPreFilterInvoiceReportFormClientData = manager.metadataStore.getEntityType(entityTypes.InvoiceListAccountingSummaryPreFilterInvoiceReportFormClientData);
        invoiceListAccountingSummaryPreFilterInvoiceReportFormClientData.getProperty("StartDateInvoice").validators.push(new breeze.Validator("startDatePeriodValidator", Nembus.Common.Library.validators.requiredFieldValidator));
        invoiceListAccountingSummaryPreFilterInvoiceReportFormClientData.getProperty("EndDateInvoice").validators.push(new breeze.Validator("endDatePeriodValidator", Nembus.Common.Library.validators.requiredFieldValidator));
        invoiceListAccountingSummaryPreFilterInvoiceReportFormClientData.getProperty("StartDateCompetence").validators.push(new breeze.Validator("startDatePeriodValidator", Nembus.Common.Library.validators.requiredFieldValidator));
        invoiceListAccountingSummaryPreFilterInvoiceReportFormClientData.getProperty("EndDateCompetence").validators.push(new breeze.Validator("endDatePeriodValidator", Nembus.Common.Library.validators.requiredFieldValidator));

        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.InvoiceListAccountingSummaryPreFilterInvoice2ReportFormClientData,
                dataProperties: {
                    Id: { type: breeze.DataType.Guid },
                    StartDateInvoice: { type: breeze.DataType.DateTime },
                    EndDateInvoice: { type: breeze.DataType.DateTime },
                    StartDateCompetence: { type: breeze.DataType.DateTime },
                    EndDateCompetence: { type: breeze.DataType.DateTime },
                    UseFilterInvoice: { type: breeze.DataType.Boolean },
                    UseFilterCompetence: { type: breeze.DataType.Boolean },
                    GroupId1: { type: breeze.DataType.String },
                    GroupId2: { type: breeze.DataType.String }
                }
            }, 'Nembus.Domain'
        );
        var invoiceListAccountingSummaryPreFilterInvoice2ReportFormClientData = manager.metadataStore.getEntityType(entityTypes.InvoiceListAccountingSummaryPreFilterInvoice2ReportFormClientData);
        invoiceListAccountingSummaryPreFilterInvoice2ReportFormClientData.getProperty("StartDateInvoice").validators.push( // is required
            new breeze.Validator("startDatePeriodValidator", function (value, context) {
                if (context.entity.UseFilterInvoice) {
                    return value ? true : false;
                }

                return true;
            })
        );
        invoiceListAccountingSummaryPreFilterInvoice2ReportFormClientData.getProperty("EndDateInvoice").validators.push( // is required
            new breeze.Validator("endDatePeriodValidator", function (value, context) {
                if (context.entity.UseFilterInvoice) {
                    return value ? true : false;
                }

                return true;
            })
        );
        invoiceListAccountingSummaryPreFilterInvoice2ReportFormClientData.getProperty("StartDateCompetence").validators.push( // is required
            new breeze.Validator("startDatePeriodValidator", function (value, context) {
                if (context.entity.UseFilterCompetence) {
                    return value ? true : false;
                }

                return true;
            })
        );
        invoiceListAccountingSummaryPreFilterInvoice2ReportFormClientData.getProperty("EndDateCompetence").validators.push( // is required
            new breeze.Validator("endDatePeriodValidator", function (value, context) {
                if (context.entity.UseFilterCompetence) {
                    return value ? true : false;
                }

                return true;
            })
        );
        invoiceListAccountingSummaryPreFilterInvoice2ReportFormClientData.getProperty("GroupId1").validators.push(new breeze.Validator("groupingValidator", Nembus.Common.Library.validators.requiredFieldValidator));
        invoiceListAccountingSummaryPreFilterInvoice2ReportFormClientData.getProperty("GroupId2").validators.push(new breeze.Validator("groupingValidator", Nembus.Common.Library.validators.requiredFieldValidator));

        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.VirtualEquipmentsVhrEventListReportFormClientData,
                dataProperties: {
                    Id: { type: breeze.DataType.Guid },
                    StartDate1: { type: breeze.DataType.DateTime },
                    StartDate2: { type: breeze.DataType.DateTime }
                }
            }, 'Nembus.Domain'
        );
        var virtualEquipmentsVhrEventListReportFormClientData = manager.metadataStore.getEntityType(entityTypes.VirtualEquipmentsVhrEventListReportFormClientData);
        virtualEquipmentsVhrEventListReportFormClientData.getProperty("StartDate1").validators.push(new breeze.Validator("fromDateValidator", Nembus.Common.Library.validators.requiredFieldValidator));
        virtualEquipmentsVhrEventListReportFormClientData.getProperty("StartDate2").validators.push(new breeze.Validator("toDateValidator", Nembus.Common.Library.validators.requiredFieldValidator));

        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.AuthLogReportFormClientData,
                dataProperties: {
                    Id: { type: breeze.DataType.Guid },
                    AuthTsDate1: { type: breeze.DataType.DateTime },
                    AuthTsDate2: { type: breeze.DataType.DateTime }
                }
            }, 'Nembus.Domain'
        );
        var authLogReportFormClientData = manager.metadataStore.getEntityType(entityTypes.AuthLogReportFormClientData);
        authLogReportFormClientData.getProperty("AuthTsDate1").validators.push(new breeze.Validator("fromDateValidator", Nembus.Common.Library.validators.requiredFieldValidator));
        authLogReportFormClientData.getProperty("AuthTsDate2").validators.push(new breeze.Validator("toDateValidator", Nembus.Common.Library.validators.requiredFieldValidator));

        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.ReportEntityDefinitionClientData,
                dataProperties: {
                    Id: { type: breeze.DataType.Guid },
                    ReportDefinitionId: { type: breeze.DataType.String },
                    ReportDefinitionReferenceCode: { type: breeze.DataType.String },
                    ReportDefinitionName: { type: breeze.DataType.String }
                }
            }, 'Nembus.Domain'
        );
        var reportEntityDefinitionClientData = manager.metadataStore.getEntityType(entityTypes.ReportEntityDefinitionClientData);
        reportEntityDefinitionClientData.getProperty("ReportDefinitionReferenceCode").validators.push(new breeze.Validator("codeValidator", Nembus.Common.Library.validators.requiredFieldValidator));
        reportEntityDefinitionClientData.getProperty("ReportDefinitionName").validators.push(new breeze.Validator("nameValidator", Nembus.Common.Library.validators.requiredFieldValidator));



        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.R_710_400_1_ClientData,
                dataProperties: {
                    Id: { type: breeze.DataType.Guid },
                    VirtualHumanResourcesId: { type: breeze.DataType.String }
                }
            }, 'Nembus.Domain'
        );
        var r_710_400_1_ClientData = manager.metadataStore.getEntityType(entityTypes.R_710_400_1_ClientData);
        r_710_400_1_ClientData.getProperty("VirtualHumanResourcesId").validators.push(new breeze.Validator("assetValidator", Nembus.Common.Library.validators.requiredFieldValidator));
        
        //#endregion ****


        this.dataContext = {
            invoiceSummaryReportFormClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.InvoiceSummaryReportFormClientData),
            invoiceSummaryGroupedReportFormClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.InvoiceSummaryGroupedReportFormClientData),
            vhrEventPricedReportFormClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.VhrEventPricedReportFormClientData),
            jobRequestPricedReportFormClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.JobRequestPricedReportFormClientData),
            invoiceListAccountingSummaryReportFormClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.InvoiceListAccountingSummaryReportFormClientData),
            invoiceListAccountingSummaryPreFilterInvoiceReportFormClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.InvoiceListAccountingSummaryPreFilterInvoiceReportFormClientData),
            invoiceListAccountingSummaryPreFilterInvoice2ReportFormClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.InvoiceListAccountingSummaryPreFilterInvoice2ReportFormClientData),
            virtualEquipmentsVhrEventListReportFormClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.VirtualEquipmentsVhrEventListReportFormClientData),
            authLogReportFormClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.AuthLogReportFormClientData),
            reportEntityDefinitionClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.ReportEntityDefinitionClientData),
            r_710_400_1_ClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.R_710_400_1_ClientData),

            reportHtmlRequests: dataLayer.setContextMethods(manager, nbsService, entityTypes.ReportHtmlRequest),
            reportTemplates: {
                get: function () { return manager.getEntities(entityTypes.ReportTemplate); },
                getByKey: function (key) { return manager.getEntityByKey(entityTypes.ReportTemplate, key); },
                add: function (entity) { return nbsService.prototype.createEntityStatic(entityTypes.ReportTemplate, entity, manager); }
            },
            reportDefinitions: {
                get: function () { return manager.getEntities(entityTypes.ReportDefinition); },
                getByKey: function (key) { return manager.getEntityByKey(entityTypes.ReportDefinition, key); },
                add: function (entity) { return nbsService.prototype.createEntityStatic(entityTypes.ReportDefinition, entity, manager); }
            },
            reportDefinitionDtos: {
                get: function () { return manager.getEntities(entityTypes.ReportDefinitionDto); },
                getByKey: function (key) { return manager.getEntityByKey(entityTypes.ReportDefinitionDto, key); },
                add: function (entity) { return nbsService.prototype.createEntityStatic(entityTypes.ReportDefinitionDto, entity, manager); }
            },
            enumTypes: {
                ReportHeaderType: dataLayer.GetEnumType('ReportHeaderType'),
                ReportEngineType: dataLayer.GetEnumType('ReportEngineType'),
                ReportDefinitionStatus: dataLayer.GetEnumType('ReportDefinitionStatus'),
                ReportTemplateSourceType: dataLayer.GetEnumType('ReportTemplateSourceType'),
                ReportTemplateFileType: dataLayer.GetEnumType('ReportTemplateFileType'),
                ReportHtmlPdfPageSize: dataLayer.GetEnumType('ReportHtmlPdfPageSize'),
                ReportHtmlPdfPageOrientation: dataLayer.GetEnumType('ReportHtmlPdfPageOrientation'),
                ReportHtmlOutputType: dataLayer.GetEnumType('ReportHtmlOutputType'),
                ReportDefinitionDisplayChannel: dataLayer.GetEnumType('ReportDefinitionDisplayChannel')
            }
        };

        this.createQuery = function (entityName) {
            return dataLayer.CreateQuery(entityName);
        };

        // ReportDefinitionDto
        this.getReportDefinitionDto = function (filter, forceReload) {
            var params = { key: filter.ReportDefinitionCode, dataContext: this.dataContext.reportDefinitionDtos };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "ReportDefinitionDtos");
        };

        // ReportDefinition
        this.getReportDefinition = function (filter, forceReload) {
            var params = { key: filter.ReportDefinitionCode, dataContext: this.dataContext.reportDefinitions };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "ReportDefinitions");
        };

        // ReportTemplate
        this.getReportTemplates = function (filter, forceReload) {
            var params = { entityType: entityTypes.ReportTemplate, cache: this.dataContext.reportTemplates.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "ReportTemplates");
        };
        this.getReportTemplate = function (filter, forceReload) {
            var params = { key: filter.TemplateCode, dataContext: this.dataContext.reportTemplates };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "ReportTemplates");
        };

        //#region Paged methods
        this.getReportDefinitionDtosPagedDm = function () {
            return ejDataManager.getEjManager('ReportDefinitionDtosPaged');
        };
        this.getReportDefinitionDtosPagedDmQuery = function () {
            var dmObj = {};
            dmObj.dm = ejDataManager.getEjManager('');
            dmObj.query = ej.Query().from('ReportDefinitionDtosPaged');
            return dmObj;
        };
        //#endregion

        this.initCurrentReport = function () {
            //cross controller 
            this.currentReport = {};
            this.currentReport.reportArgs = {};
            this.currentReport.reportDefinition = null;
            this.currentReport.concatName = '';
            this.currentReport.formEntityToValidate = null;
            this.currentReport.reportFilterDataCallback = null;

            // used when report definition engine is 'Html'
            this.currentReport.reportHtmlRequest = {};
        };
        this.initCurrentReport();

        //#region Http Post
        this.reportHtmlRequestManage = function (filter) {
            var query = dataLayer.InvokeQuery("ReportHtmlRequestManage", filter);
            return manager.executeQuery(query);
        };

        this.uploadReportTemplateCustom = function (filter) {
            var query = dataLayer.InvokeQuery("UploadReportTemplateCustom", filter);
            return manager.executeQuery(query);
        };

        this.uploadReportTemplateSystem = function (filter) {
            var query = dataLayer.InvokeQuery("UploadReportTemplateSystem", filter);
            return manager.executeQuery(query);
        };

        this.cloneReportDefinition = function (filter) {
            var query = dataLayer.InvokeQuery("CloneReportDefinition", filter);
            return manager.executeQuery(query);
        };

        this.deleteReportDefinition = function (filter) {
            var query = dataLayer.InvokeQuery("DeleteReportDefinition", filter);
            return manager.executeQuery(query);
        };
        //#endregion        

        //#region DataManager
        this.getReportDataManager = function (supportData, filter, dmObj) {
            if (dmObj) {
                dmObj.complexParams = [{ filter: filter }];
                return dmObj;
            }

            var reportHeaderTypeParsed = Nembus.Common.Library.util.parseEnum(me.dataContext.enumTypes.ReportHeaderType, null, supportData.translate);
            var reportEngineTypeParsed = Nembus.Common.Library.util.parseEnum(me.dataContext.enumTypes.ReportEngineType, 'ReportDefinition', supportData.translate);
            var reportDefinitionStatusParsed = Nembus.Common.Library.util.parseEnum(me.dataContext.enumTypes.ReportDefinitionStatus, 'ReportDefinition', supportData.translate);

            dmObj = me.getReportDefinitionDtosPagedDmQuery();
            dmObj.dmgrid = me.getReportDefinitionDtosPagedDm();

            var ejDataManager = dmObj.dmgrid;

            ejDataManager.complexParams.push({ filter: filter });
            ejDataManager.fieldNameReplacer = {
                ReportHeaderTypeName: 'ReportHeaderTypeId',
                ReportEngineTypeName: 'ReportEngineTypeId',
            };
            // properties inserte are calculated before query is launched in breezeDataLayer service
            ejDataManager.onWhereCustomPredicate = {
                ReportHeaderTypeName: {
                    FieldType: this.FilterMenu.FieldType.Enum,
                    ReplaceFieldNameWith: 'ReportHeaderTypeId',
                    Datasource: Lazy(me.dataContext.enumTypes.ReportHeaderType).map((item) => { return { value: item.value, name: supportData.translate('ReportDefinition.' + item.name) }; }).toArray()
                },
                ReportEngineTypeName: {
                    FieldType: this.FilterMenu.FieldType.Enum,
                    ReplaceFieldNameWith: 'ReportEngineTypeId',
                    Datasource: Lazy(me.dataContext.enumTypes.ReportEngineType).map((item) => { return { value: item.value, name: supportData.translate('ReportDefinition.' + item.name) }; }).toArray()
                },
            };

            ejDataManager.processResponseData = function (data) {
                if (data) {
                    data.results = me.getAttachedEntities(data.results);
                    Lazy(data.results).each((item) => {
                        item.ReportHeaderTypeName = reportHeaderTypeParsed.obj.translated.value_name[item.ReportHeaderTypeId];
                        item.ReportEngineTypeName = reportEngineTypeParsed.obj.translated.value_name[item.ReportEngineTypeId];
                        item.ReportDefinitionStatusName = reportDefinitionStatusParsed.obj.translated.value_name[item.ReportDefinitionStatusId];
                    });
                    return data.results;
                }
            };

            ejDataManager.freeSearchFields = ['ReportDefinitionReferenceCode', 'ReportDefinitionName', 'ReportDefinitionDescription'];
            //ejDataManager.freeSearchFieldsNumber = ['ReportDefinitionReferenceCode'];

            dmObj.dm.onWhereCustomPredicate = ejDataManager.onWhereCustomPredicate;
            dmObj.dm.fieldNameReplacer = ejDataManager.fieldNameReplacer;
            dmObj.dm.complexParams = ejDataManager.complexParams;
            dmObj.dm.processResponseData = ejDataManager.processResponseData;
            return dmObj;
        }
        //#endregion
    };

    reportService.$inject = injectParams;
    reportService.prototype = Object.create(nbsService.prototype);
    angular.module('nbs.shared.module').service('reportService', reportService);
});