﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'audService', 'reportService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var htmlToPdfSurveySectionKpiReportForm = function ($q, $scope, $timeout, $injector, audService, reportService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, audService, navigationService, contextService, $q, $injector, $timeout);
        var checkStateChangeParams = { dirtyFlagIsDisabled: vm.isDirtyDisabled };
        checkStateChangeService.checkFormOnStateChange($scope, audService, checkStateChangeParams);

        // all form items are enabled
        vm.setIsEditingReport();

        var stepsToRender = [];
        var surveyLifeStatusEnumObj = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyLifeStatus, null, vm.translate).obj.normal.name_value;
        var selectionParams = { stateTo: 'app.nav.surveyslistmodal', destModalKey: 'survey', isMultiselect: false, dataKey: 'surveycompleted', filter: { VhrEventLifeStatusIds: [surveyLifeStatusEnumObj.ReportedAsFinished] } };
        vm.nbsSelectionGridWithModalSurvey = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalSurvey.setOnSelectFnCallback(function () {
            vm.currentForm.SurveyId = null;
            vm.currentForm.Survey = null;
            if (Lazy(vm.nbsSelectionGridWithModalSurvey.selectedIds).some()) {
                vm.currentForm.SurveyId = Lazy(vm.nbsSelectionGridWithModalSurvey.selectedIds).first();
                vm.currentForm.Survey = Lazy(vm.nbsSelectionGridWithModalSurvey.selectedItems).first().item;
            }
        });

        vm.currentForm = audService.dataContext.htmlToPdfSurveySectionKpiClientDatas.add({
            Id: NbsGuidManager.newGuid(),
            SurveyAnalysisDefinitionId: null,
            SurveyId: null
        });

        // prepare datasource for report in UI (to prepare Html)
        vm.htmlSteps = {
            CHART: 'chart-render',
            SECTIONS: 'sections-render'
        };

        
        // REFERS to 'reportDefinitionHtmlToDocDetail'
        vm.parentScope = $scope.$parent.vm;
        vm.parentScope.ribbonDatacontext.setRibbonStatusButton(vm.parentScope.ribbonDatacontext.Buttons.PrintReportPdf, true);
        vm.parentScope.ribbonDatacontext.refreshStatusButtons();
        vm.parentScope.initReportToDocHeader({ onSearchActionHtmlToDocFn: null, onCreateHtmlFn: createHtmlReport });


        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getSurveyAnalysisDefinitions(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {

                    // 1) SurveyQuestionOption_CompletionPercentRequired => Indice completamento
                    // 2) SurveyQuestionOption_ActiveSumWeight => Somma pesi risposte attive
                    // 3) SurveyQuestionOption_ActiveSumValue => Somma gravità risposte attive
                    // 4) SurveyQuestionOption_ActiveWeightedSumWeightValue => Somma dei valori ponderati Pesi x Gravità
                    // 5) SurveyQuestionOption_NormalizedIndexSumWeight => Indice normalizzato della sommatoria dei Pesi 
                    // 6) SurveyQuestionOption_NormalizedIndexSumValue => Indice normalizzato della sommatoria delle Gravità
                    // 7) SurveyQuestionOption_NormalizedIndexSumWeightXValue => Indice normalizzato della sommatoria ponderata Pesi x Gravità
                    // 8) SurveyQuestionOption_ActiveAverageSumWeight => Media aritmetica dei Pesi 
                    // 9) SurveyQuestionOption_ActiveAverageSumValue =>Media aritmetica delle Gravità
                    // 10) SurveyQuestionOption_ActiveAverageSumWeightXValue => Media aritmetica dei Pesi x Gravità
                    // 11) SurveyQuestionOption_ActiveWeightedAverageSumWeightXValue => Media ponderata dei Pesi x Gravità
                    vm.surveyAnalysisDefinitions = Lazy(results[0])
                        .filter((surveyAnalysisDefinition) => {
                            return surveyAnalysisDefinition.SurveyAnalysisDefinitionId === 'SurveyQuestionOption_ActiveSumWeight'
                                || surveyAnalysisDefinition.SurveyAnalysisDefinitionId === 'SurveyQuestionOption_ActiveSumValue'
                                || surveyAnalysisDefinition.SurveyAnalysisDefinitionId === 'SurveyQuestionOption_ActiveWeightedSumWeightValue'
                                || surveyAnalysisDefinition.SurveyAnalysisDefinitionId === 'SurveyQuestionOption_NormalizedIndexSumWeight'
                                || surveyAnalysisDefinition.SurveyAnalysisDefinitionId === 'SurveyQuestionOption_NormalizedIndexSumValue'
                                || surveyAnalysisDefinition.SurveyAnalysisDefinitionId === 'SurveyQuestionOption_NormalizedIndexSumWeightXValue'
                                || surveyAnalysisDefinition.SurveyAnalysisDefinitionId === 'SurveyQuestionOption_ActiveAverageSumWeight'
                                || surveyAnalysisDefinition.SurveyAnalysisDefinitionId === 'SurveyQuestionOption_ActiveAverageSumValue'
                                || surveyAnalysisDefinition.SurveyAnalysisDefinitionId === 'SurveyQuestionOption_ActiveAverageSumWeightXValue'
                                || surveyAnalysisDefinition.SurveyAnalysisDefinitionId === 'SurveyQuestionOption_ActiveWeightedAverageSumWeightXValue'
                                ;
                        }).toArray();

                    // set chart type datasource for dropdown
                    vm.chartTypes = [
                        { value: 'radar', name: vm.translate('HtmlToPdfSurveySectionKpiReportForm.HtmlToPdfSurveySectionKpiReportFormChartTypeRadar') },
                        { value: 'line', name: vm.translate('HtmlToPdfSurveySectionKpiReportForm.HtmlToPdfSurveySectionKpiReportFormChartTypeLine') },
                        { value: 'bar', name: vm.translate('HtmlToPdfSurveySectionKpiReportForm.HtmlToPdfSurveySectionKpiReportFormChartTypeHystogram') }
                    ];
                });
        }

        function createHtmlReport() {
            vm.currentForm.entityAspect.validateEntity();
            $timeout(() => { /* implicit scope apply; */ });

            if (!vm.currentForm.entityAspect.hasValidationErrors) {
                // filter i a list of surveys selected from results grid
                var surveyId = vm.currentForm.SurveyId;
                var survey = vm.currentForm.Survey;
                var surveyAnalysisDefinitionId = vm.currentForm.SurveyAnalysisDefinitionId;

                survey.SurveyExpireDateUI = Nembus.Common.Library.converter.date.formatDate(survey.SurveyExpireDate, contextService.virtualContext.cultureInfo, true);
                survey.SurveyCompileDateSystemUI = Nembus.Common.Library.converter.date.formatDateTime(survey.SurveyCompileDateSystem, contextService.virtualContext.cultureInfo, true);

                var funcsPromises = [];
                funcsPromises.push(getSurveyAnalysis(true, surveyAnalysisDefinitionId, surveyId));
                funcsPromises.push(getSurveySections(true, surveyId));

                $q.all(funcsPromises)
                    .then(function (results) {
                        vm.surveyAnalysis = results[0];
                        vm.surveySections = results[1];


                        parseReportHtmlDatasource(survey, vm.surveySections, vm.surveyAnalysis);
                    });
            }
        }

        function parseReportHtmlDatasource(survey, sections, analysis) {
            stepsToRender = [];

            // object binded on UI
            var kpiName = Lazy(vm.surveyAnalysisDefinitions)
                .findWhere({ SurveyAnalysisDefinitionId: vm.currentForm.SurveyAnalysisDefinitionId })
                .SurveyAnalysisDefinitionName;

            var mappedSections = Lazy(sections)
                .map((item) => {
                    var sectionKpi = 0;
                    var sectionAnalysis = Lazy(analysis).findWhere({ SurveySectionId: item.SurveySectionId });
                    if (sectionAnalysis) {
                        sectionKpi = sectionAnalysis.SurveyAnalysisValue;
                    }

                    return {
                        SectionOrder: item.SurveySectionOrder,
                        SectionTitle: item.SurveySectionTitle,
                        SectionKpi: sectionKpi
                    };
                })
                .toArray();

            vm.totalKpi = Lazy(mappedSections).pluck('SectionKpi').sum();
            createChart(vm.currentForm.ChartTypeId, mappedSections);

            vm.bindObject = {
                survey: survey,
                sections: mappedSections,
                kpiName: kpiName
            };

            if (sections.length > 0) {
                stepsToRender.push(vm.htmlSteps.SECTIONS);
            }

            // set steps to render before html retrieve from directive
            vm.parentScope.htmlToDocManager.setHtmlStepsToRender(stepsToRender);

            if (!vm.parentScope.htmlToDocManager.hasHtmlStepsToRender()) {
                vm.parentScope.htmlToDocManager.htmlIsRendered();
            }
        }

        function createChart(chartType, sections) {
            var points = Lazy(sections)
                .sortBy((item) => { return item.SectionOrder; }, chartType !== 'line')
                .map((item) => {
                    return {
                        x: item.SectionTitle,
                        y: item.SectionKpi
                    };
                })
                .toArray();

            var maxValue = Lazy(sections).pluck('SectionKpi').max();

            vm.chartobj = {
                primaryYAxis:
                {
                    range: { min: 0, max: maxValue },
                    labelFormat: "{value}"
                },

                //Initializing Common Properties for all the series
                commonSeriesOptions:
                {
                    type: chartType,
                    enableAnimation: false,
                    tooltip: { visible: false }
                },

                //Initializing Series
                series:
                    [
                        {
                            points: points,
                            marker:
                            {
                                enableWrap: true,
                                visible: chartType !== 'bar',
                                dataLabel:
                                {
                                    visible: true,
                                    textPosition: "bottom"
                                }
                            }
                        }
                    ],
                isResponsive: false,
                load: "loadTheme",
                legend: { visible: false }
            };
        }

        function getSurveyAnalysisDefinitions(forceReload) {
            var filter = {};
            var defer = $q.defer();

            audService.getSurveyAnalysisDefinitions(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getSurveyAnalysis(forceReload, surveyAnalysisDefinitionId, surveyId) {
            var filter = {
                SurveyAnalysisDefinitionId: surveyAnalysisDefinitionId,
                SurveyAnalysisGroupModeId: 2, // SurveySection
                SurveyId: surveyId
            };
            var defer = $q.defer();

            audService.getSurveyAnalysis(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getSurveySections(forceReload, surveyId) {
            var defer = $q.defer();
            var filter = {
                surveyFilter: { SurveyId: surveyId }
            };

            audService.getSurveySections(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }
    };

    htmlToPdfSurveySectionKpiReportForm.$inject = injectParams;
    htmlToPdfSurveySectionKpiReportForm.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('htmlToPdfSurveySectionKpiReportForm', htmlToPdfSurveySectionKpiReportForm);
});
