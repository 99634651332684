﻿'use strict';
define([], function () {

    var injectParams = ['$q', '$scope', '$timeout', 'cmsService', 'contextService', 'navigationService'];
    var nbsViewTitleComponent = function ($q, $scope, $timeout, cmsService, contextService, navigationService) {
        var vm = this;
        var privateUiDataSelected = null;
        var privateUiDataList = [];
        var privateUiDataDefault = null;
        var privateUiDataDefaultStartData = null;
        var isDefaultViewAutosaving = Nembus.Common.Library.server.getServerVarBool('ssv_core_autosaving_view_default');

        vm.$onInit = function () {
            vm.isVisible = true;
            $('#nbs-id-view-title-static').remove();
        };

        vm.$onChanges = function (changes) {
            if (changes.nbsViewTitleInit && changes.nbsViewTitleInit.currentValue) {
                // START LOADING UI DATA WITH DEFAULT DATA
                privateUiDataDefaultStartData = changes.nbsViewTitleInit.currentValue;
                setViewTitleMenu();
            }
            if (changes.nbsDataContext && changes.nbsDataContext.currentValue) {
                // REFRESHING UIDATA FILTERS BY EXTERNAL COMPONENTS
                refreshPrivateUiData(changes.nbsDataContext.currentValue);

                // auto saving only if changing filters on default uiData
                if (isUidatasDefault(privateUiDataSelected) && isDefaultViewAutosaving) {
                    saveUiData(privateUiDataSelected, false);
                }
            }
        };

        vm.onUiDataSelectionEvent = function (options) {
            // this menu is single selection
            var item = Lazy(options).first();

            // clicked selected item, no action needed
            if (privateUiDataSelected.uiDataId === item.optionValue) {
                return;
            }

            switch (item.optionValue) {
                case 'nbs-manageuidata-item':
                    openManageUiDataModal();
                    break;
                case 'nbs-save-item':
                    if (isUidatasDefault(privateUiDataSelected)) {
                        saveAsUiData(privateUiDataSelected);
                    } else {
                        saveUiData(privateUiDataSelected, true);
                    }
                    break;
                case 'nbs-saveas-item':
                    saveAsUiData(privateUiDataSelected);
                    break;
                case 'nbs-reset-filter-to-default':
                    resetUiDataDefault();
                    break;
                default:
                    var uiDataselected = Lazy(privateUiDataList).findWhere({ uiDataId: item.optionValue });
                    uiDataselected = JSON.parse(JSON.stringify(uiDataselected));

                    putUiDataPreference(uiDataselected)
                        .then(function () {
                            sendUiDataResult(uiDataselected);
                        });
            }
        };

        // event raised from manage modal UiData form
        vm.onModalUiDataDelete = function (uiData) {
            vm.modalUiDatas = Lazy(vm.modalUiDatas).reject({ uiDataId: uiData.uiDataId }).toArray();
            Nembus.Rds.Library.SetArrayOrder(vm.modalUiDatas, 'uiDataOrder');
        };

        //#region Sortable events
        // Set order on stop event (dragNdrop)
        // https://github.com/angular-ui/ui-sortable
        vm.uiDataSortableParams = {
            update: function (e, ui) {
            },
            stop: function (e, ui) {
                Nembus.Rds.Library.SetArrayOrder(vm.modalUiDatas, 'uiDataOrder');
            }
        };
        //#endregion

        // reset uidatadefault to start filters and select default view
        function resetUiDataDefault() {
            navigationService.confirmModal(vm.uiContext.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        cmsService.clearUiDataDefault({
                            cmsviewcode: navigationService.currentCmsViewCode(),
                            datatype: vm.uiContext.visualizationKey
                        }).then((uiDataDefault) => {
                            // save to preference
                            putUiDataPreference(uiDataDefault)
                                .then(function () {
                                    var defaultOption = Lazy(vm.localDatasource).findWhere({ optionValue: uiDataDefault.uiDataId });
                                    vm.localDatasourceSelected = [defaultOption];

                                    // send filters to components
                                    sendUiDataResult(uiDataDefault);
                                });
                        });
                    }
                });
        }

        function refreshPrivateUiData(uiDataParam) {
            privateUiDataSelected.filters = uiDataParam.filters;
            privateUiDataSelected.startData = uiDataParam.startData;
            privateUiDataSelected.columns = uiDataParam.columns;
            privateUiDataSelected.grouping = uiDataParam.grouping;
            privateUiDataSelected.sorting = uiDataParam.sorting;
            privateUiDataSelected.filterList = uiDataParam.filterList;

            refreshPrivateUiDataList();
        }

        function refreshPrivateUiDataList() {
            privateUiDataList = Lazy(privateUiDataList).reject({ uiDataId: privateUiDataSelected.uiDataId }).toArray();
            privateUiDataList.push(privateUiDataSelected);
        }

        function openManageUiDataModal() {
            vm.manageUiDataModalHasValidationError = false;

            vm.modalUiDatas = Lazy(privateUiDataList)
                .filter((uiData) => { return !isUidatasDefault(uiData); })
                .map((uiData) => { return JSON.parse(JSON.stringify(uiData)); })
                .sortBy((uiData) => { return uiData.uiDataOrder; })
                .toArray();

            vm.modalInstance = navigationService.modal().open({ templateUrl: 'manageUiDataModal', size: 'lg', scope: $scope });
            vm.modalInstance.ok = function () {
                Lazy(vm.modalUiDatas)
                    .each((uiData) => {
                        if (!uiData.value.dataname) {
                            vm.manageUiDataModalHasValidationError = true;
                        }
                    });

                // if validation error than stop 
                if (vm.manageUiDataModalHasValidationError) {
                    return;
                }

                var deletePromises = [];
                var savePromises = [];
                Lazy(privateUiDataList)
                    .filter((uiData) => { return !isUidatasDefault(uiData); })
                    .each((uiData) => {
                        var uiDataInModal = Lazy(vm.modalUiDatas).findWhere({ uiDataId: uiData.uiDataId });

                        if (!uiDataInModal) {
                            deletePromises.push(deleteUiData(uiData));
                        } else {
                            savePromises.push(putUiData(uiDataInModal));
                        }
                    });

                $q.all(deletePromises)
                    .then(function () {
                        $q.all(savePromises)
                            .then(function () {
                                setViewTitleMenu()
                                    .then(function () {
                                        vm.modalInstance.close();
                                    });
                            });
                    });
            };
            vm.modalInstance.cancel = function () {
                vm.modalInstance.dismiss();
            };
            vm.modalInstance.result.then(function () {
                // onClose
            }, function () {
                // onDismiss
            });
        }

        function setMenuData(menuUiDatas) {
            var defer = $q.defer();

            vm.localDatasource = [];
            var menuOptions = [];

            // ### Save option ###
            menuOptions.push(new NbsUiOption({ optionValue: "nbs-save-item", optionDisplayName: vm.uiContext.translate('Save'), optionStyle: "action", optionOrder: -100 }));
            // ### SaveAs option ###
            menuOptions.push(new NbsUiOption({ optionValue: "nbs-saveas-item", optionDisplayName: vm.uiContext.translate('SaveAs'), optionStyle: "action", optionOrder: -90 }));
            // ### Manage uiData ###
            menuOptions.push(new NbsUiOption({ optionValue: "nbs-manageuidata-item", optionDisplayName: vm.uiContext.translate('Manage'), optionStyle: "action", optionOrder: -80 }));
            // ### reset auto saved defautl ###
            menuOptions.push(new NbsUiOption({ optionValue: "nbs-reset-filter-to-default", optionDisplayName: vm.uiContext.translate('Reset'), optionStyle: "action", optionOrder: -70 }));

            if (Lazy(menuUiDatas).filter((uiData) => { return !isUidatasDefault(uiData); }).some()) {
                // add my views section style
                menuOptions.push(new NbsUiOption({ optionDisplayName: vm.uiContext.translate('MyViews'), optionStyle: "section", optionOrder: -60 }));

                Lazy(menuUiDatas)
                    .each((uiData) => {
                        if (!isUidatasDefault(uiData)) {
                            menuOptions.push(new NbsUiOption({ optionValue: uiData.uiDataId, optionDisplayName: uiData.value.dataname, optionStyle: "subsection", optionOrder: uiData.uiDataOrder }));
                        }
                    });
            }

            // add my views section style
            menuOptions.push(new NbsUiOption({ optionDisplayName: vm.uiContext.translate('Default'), optionStyle: "section", optionOrder: 100 }));
            // add item current page (DEFAULT)
            menuOptions.push(new NbsUiOption({ optionValue: privateUiDataDefault.uiDataId, optionDisplayName: privateUiDataDefault.value.dataname, optionOrder: 150 }));

            // bind menu items to component
            vm.localDatasource = menuOptions;
            //#endregion

            //selected Item
            vm.localDatasourceSelected = null;

            Lazy(vm.localDatasource)
                .each((menuOption) => {
                    if (menuOption.optionValue === privateUiDataSelected.uiDataId) {
                        var selected = new NbsUiOption({ optionValue: privateUiDataSelected.uiDataId });

                        $timeout(() => {
                            vm.localDatasourceSelected = [selected];
                        });
                    }
                });

            // send onstart selection to source component
            var selectedUiData = Lazy(menuUiDatas).findWhere({ uiDataId: privateUiDataSelected.uiDataId });

            defer.resolve(selectedUiData);
            return defer.promise;
        }
        function setViewTitleMenu() {
            var defer = $q.defer();

            getUiDataAll()
                .then(function (uiDatas) {
                    privateUiDataList = uiDatas;
                    privateUiDataDefault = Lazy(privateUiDataList).filter((uiData) => { return isUidatasDefault(uiData); }).first();

                    getUiDataPreference()
                        .then(function (uiDataPreference) {
                            privateUiDataSelected = JSON.parse(JSON.stringify(uiDataPreference));

                            setMenuData(privateUiDataList)
                                .then(function (result) {
                                    sendUiDataResult(result);
                                });

                            defer.resolve();
                        });
                });

            return defer.promise;
        }

        function getUiDataAll() {
            var defer = $q.defer();
            var params = {
                cmsviewcode: navigationService.currentCmsViewCode(),
                datatype: vm.uiContext.visualizationKey,
                usercode: contextService.virtualContext.UserGuid,
                vc: contextService.virtualContext.CurrentVirtualCompanyId
            };

            cmsService.getUiDataAll(params, privateUiDataDefaultStartData)
                .then(res => {
                    defer.resolve(res);
                })
                .catch(reason => {
                    defer.reject(reason);
                });

            return defer.promise;
        }

        function getUiDataPreference() {
            var defer = $q.defer();

            cmsService.getUiDataPreference({
                cmsviewcode: navigationService.currentCmsViewCode(),
                datatype: vm.uiContext.visualizationKey,
                usercode: contextService.virtualContext.UserGuid,
                vc: contextService.virtualContext.CurrentVirtualCompanyId
            })
                .then(res => {
                    defer.resolve(res);
                })
                .catch(reason => {
                    defer.reject(reason);
                });

            return defer.promise;
        }
        function putUiDataPreference(uiData) {
            var defer = $q.defer();
            var pParam = {
                cmsviewcode: navigationService.currentCmsViewCode(),
                datatype: vm.uiContext.visualizationKey,
                usercode: contextService.virtualContext.UserGuid,
                vc: contextService.virtualContext.CurrentVirtualCompanyId
            };

            // set current uiData as preference in idbindex
            cmsService.putUiDataPreference(pParam, uiData)
                .then(function (result) {
                    privateUiDataSelected = uiData;
                    refreshPrivateUiDataList();
                    defer.resolve();
                })
                .catch(reason => {
                    defer.reject(reason);
                });

            return defer.promise;
        }
        function putUiData(uiData) {
            var defer = $q.defer();

            // set current uiData as preference in idbindex
            cmsService.putUiData(uiData)
                .then(function (result) {
                    defer.resolve();
                })
                .catch(reason => {
                    defer.reject(reason);
                });

            return defer.promise;
        }
        function deleteUiData(uiData) {
            var defer = $q.defer();

            if (privateUiDataSelected.uiDataId === uiData.uiDataId) {
                // i'm removing selected uiData
                // delete uidata
                cmsService.deleteUiData(uiData)
                    .then(function (result) {
                        privateUiDataList = Lazy(privateUiDataList).reject({ uiDataId: uiData.uiDataId }).toArray();

                        // set uidata default preference
                        putUiDataPreference(privateUiDataDefault)
                            .then(function (result) {
                                setViewTitleMenu()
                                    .then(function () {
                                        defer.resolve();
                                    });
                            });

                    });
            } else {
                cmsService.deleteUiData(uiData)
                    .then(function (result) {
                        privateUiDataList = Lazy(privateUiDataList).reject({ uiDataId: uiData.uiDataId }).toArray();

                        setViewTitleMenu()
                            .then(function () {
                                defer.resolve();
                            });
                    });
            }

            return defer.promise;
        }
        function saveUiData(uiData, confirmModal) {
            cmsService.putUiData(uiData)
                .then(function (result) {
                    privateUiDataList = Lazy(privateUiDataList).reject({ uiDataId: uiData.uiDataId }).toArray();
                    privateUiDataList.push(uiData);
                    if (confirmModal) {
                        navigationService.customMessageModal(vm.uiContext.translate('ViewSavedSuccessfully'));
                    }
                });
        }

        function saveAsUiData(uiData) {
            // validation error message is hidden
            vm.modalUiData = cmsService.dataContext.uiDataClientDatas.add();
            vm.addUiDataInstance = navigationService.modal().open({ templateUrl: 'addUIDataTemplate', scope: $scope });
            vm.addUiDataInstance.ok = function () {
                vm.modalUiData.entityAspect.validateEntity();
                if (vm.modalUiData.entityAspect.hasValidationErrors) {
                    return;
                }

                // create new uiData
                var newUiData = cmsService.createNewUiData({
                    dataname: vm.modalUiData.UiDataViewName,
                    cmsviewcode: navigationService.currentCmsViewCode(),
                    datatype: vm.uiContext.visualizationKey,
                    usercode: contextService.virtualContext.UserGuid,
                    vc: contextService.virtualContext.CurrentVirtualCompanyId
                });
                newUiData.filters = uiData.filters;
                newUiData.columns = uiData.columns;
                newUiData.grouping = uiData.grouping;
                newUiData.sorting = uiData.sorting;
                newUiData.filterList = uiData.filterList;
                newUiData.uiDataOrder = getUiDataOrder(privateUiDataList);
                newUiData.startData = null;

                cmsService.putUiData(newUiData)
                    .then(res => {
                        putUiDataPreference(newUiData)
                            .then(res => {
                                setViewTitleMenu()
                                    .then(function () {
                                        // reject changes
                                        vm.modalUiData.entityAspect.rejectChanges();

                                        // close modal
                                        vm.addUiDataInstance.close();
                                    });
                            });
                    });
            };
            vm.addUiDataInstance.cancel = function () {
                // reject changes
                vm.modalUiData.entityAspect.rejectChanges();

                vm.addUiDataInstance.dismiss();
            };
            vm.addUiDataInstance.result.then(function () {
                // ok
            }, function () {
                // cancel
            });
        }
        function getUiDataOrder(privateUiDataList) {
            var order = Lazy(privateUiDataList).pluck('uiDataOrder').max();
            if (!order) {
                return 1;
            } else {
                order++;
                return order;
            }
        }

        function sendUiDataResult(uiData) {
            $timeout(() => {
                vm.nbsViewTitleSelectionChangesEvent({
                    result: {
                        uiData: JSON.parse(JSON.stringify(uiData))
                    }
                });
            }, 0);
        }

        function isUidatasDefault(uiData) {
            return uiData.value.usercode === 'default';
        };

    };

    nbsViewTitleComponent.$inject = injectParams;

    angular.module('nbs.rds.module').component('nbsViewTitle', {
        templateUrl: '/rds/common/components/nbsViewTitleComponent/_?c=' + window.codeCache(),
        controller: nbsViewTitleComponent,
        controllerAs: 'vm',
        bindings: {
            nbsViewTitleSelectionChangesEvent: '&',
            nbsDataContext: '<',
            nbsViewTitleInit: '<',
            uiContext: "<"
        }
    });
});

