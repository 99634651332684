﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'activityService', 'navigationService', 'checkStateChangeService', 'contextService', 'formSummaryService'];
    function vhrToActivities($q, $scope, $timeout, $injector, activityService, navigationService, checkStateChangeService, contextService, formSummaryService) {

        var vm = this;
        nbsVm.call(vm, activityService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, activityService, { dirtyFlagIsSuspended: vm.isDirtySuspended });

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        ribbon.addTab(new ribbon.Tab("ActionPanelTabManage", [ribbon.GetAssociationStatusSection()]));
        vm.ribbonDatacontext = ribbon;

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var virtualHumanResourceId = navigationService.stateParams().virtualHumanResourceId;
        var activityKindEnum = Nembus.Common.Library.util.parseEnum(activityService.dataContext.enumTypes.ActivityKind, null, vm.translate).obj.normal.name_value;

        var activityXVhrStatusEnumParsed = Nembus.Common.Library.util.parseEnum(activityService.dataContext.enumTypes.ActivityXVhrStatus, 'ActivityVhr', vm.translate);
        vm.activityXVhrStatusList = activityXVhrStatusEnumParsed.list.translated;
        vm.activityXVhrStatusObjForBind = activityXVhrStatusEnumParsed.obj.translated.value_name;

        var activityXVhrModeEnumParsed = Nembus.Common.Library.util.parseEnum(activityService.dataContext.enumTypes.ActivityXVhrMode, 'ActivityVhr', vm.translate);
        vm.activityXVhrModeObjForBind = activityXVhrModeEnumParsed.obj.translated.value_name;

        ribbon.setRibbonStatusButton(ribbon.Buttons.New, false);
        ribbon.setRibbonStatusButton(ribbon.Buttons.Modify, true);
        ribbon.refreshStatusButtons();

        // get array items for form summary
        vm.formSummary = formSummaryService.getDatasource();
        vm.currentMagicBucketRequest = null; //submit his entity when Bucket X Categories change 
        vm.currentActivityVhrs = [];
        vm.currentBoms = [];

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('activity', 'activityvhr');
        nbsGridDatacontext.setDatasource([], false);
        nbsGridDatacontext.setAdaptToPage();
        nbsGridDatacontext.allowGrouping = false;
        nbsGridDatacontext.allowSearching = false;
        vm.nbsGridDatacontext = nbsGridDatacontext;

        vm.deleteAction = function () {
            if (!vm.vhrActivitySelected) {
                return;
            }

            // set vhrActivity deleted
            activityService.deleteEntity(vm.vhrActivitySelected);

            // update mode description for layout with Cancelled translation
            var activityXVhrStatusCancelled = Lazy(vm.activityXVhrStatusList).findWhere({ value: 20 });
            vm.vhrActivitySelected.ActivityXVhrStatusForLayout = activityXVhrStatusCancelled.name;

            vm.forceDatasourceLocal = {
                refreshkey: new Date().getTime(),
                dataSource: vm.currentActivityVhrs
            }
        };

        vm.associationStatusUpdateAction = function () {
            openModalChangeAssociationState();
        };

        vm.associationStatusInfoAction = function () {
            openModalInfoAssociationState();
        };

        vm.gridSelection = function (args) {
            $timeout(() => {
                vm.singleXHrBucketSelected = args.data;

                if (vm.singleXHrBucketSelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.AssociationStatusUpdate, true);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.AssociationStatusInfo, true);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.AssociationStatusUpdate, false);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.AssociationStatusInfo, false);
                }

                ribbon.refreshStatusButtons();
            });
        };


        function openModalChangeAssociationState() {
            if (!vm.vhrActivitySelected) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            var params = new NbsGenericListModalParamObject('VhrEventLifeStatus', vm.translate('ActionPanelVhrEventLifeStatusInfo'));
            navigationService.navToGenericListModal(vm.suspendDirty, params, vm.activityXVhrStatusList)
                .then(function (result) {
                    if (result.hasSelection) {
                        var activityXVhrStatusSelected = Lazy(result.data).first();

                        if (activityXVhrStatusSelected && vm.vhrActivitySelected) {
                            vm.vhrActivitySelected.ActivityXVhrStatusId = activityXVhrStatusSelected.value;
                            //vm.vhrActivitySelected.ActivityXVhrStatus = activityXVhrStatusSelected.name;
                            vm.vhrActivitySelected.ActivityXVhrStatusForLayout = activityXVhrStatusSelected.name;
                            vm.vhrActivitySelected.ActivityXVhrModeId = 20;
                            vm.vhrActivitySelected.ActivityXVhrMode = 'Manually';
                        }

                        vm.forceDatasourceLocal = {
                            refreshkey: new Date().getTime(),
                            dataSource: vm.currentActivityVhrs
                        }
                    }
                });
        }

        function openModalInfoAssociationState() {
            if (!vm.vhrActivitySelected) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            var params = new NbsGenericListModalParamObject('VhrEventLifeStatus', vm.translate('ActionPanelVhrEventLifeStatusInfo'));
            params.readonly = true;
            params.selectedIds = [vm.vhrActivitySelected.ActivityXVhrStatusId.toString()];
            navigationService.navToGenericListModal(vm.suspendDirty, params, vm.activityXVhrStatusList);
        }

        vm.rowSelected = function (args) {
            vm.vhrActivitySelected = args.data;
        };

        vm.newAction = function () {
            navigationService.navToModal(vm.suspendDirty, 'app.nav.activitieslistmodal',
                { isMultiselect: true, appStoreBw: null, activityKindId: activityKindEnum.Bom }
            ).then(function (result) {
                setBoms(result.data);
            });
        };

        vm.refreshAction = function () {
            getActivityVhr(true, virtualHumanResourceId);
        };

        function setBoms(activities) {
            var targetBoms = Lazy(vm.currentActivityVhrs).pluck("ActivityId").toArray();

            Lazy(activities).each((activity) => {
                if (!Lazy(targetBoms).contains(activity.ActivityId)) {
                    var activityVhrDto = mapBomToVhrActivity(activity);
                    activityVhrDto.ActivityXVhrStatusForLayout = vm.translate("Associated");
                    activityVhrDto.ActivityXVhrModeForLayout = vm.translate("Manually");

                    vm.currentActivityVhrs.push(activityVhrDto);
                }
            });

            vm.forceDatasourceLocal = {
                refreshkey: new Date().getTime(),
                dataSource: vm.currentActivityVhrs
            }
        }

        function mapBomToVhrActivity(itemToInsert) {
            return activityService.dataContext.activityVhrs.add({
                ActivityCode: itemToInsert.ActivityCode,
                ActivityId: itemToInsert.ActivityId,
                ActivityName: itemToInsert.ActivityName,
                ActivityXVhrStatusId: 10,
                ActivityXVhrStatus: "Associated",
                AppStoreBw: appStoreBw,
                ActivityXVhrModeId: 20,
                ActivityXVhrMode: "Manually",
                IsToAdd: false,
                VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId,
                VirtualHumanResourceId: virtualHumanResourceId
            });
        }


        loadData(true);
        function loadData(forceReload) {
            getActivityVhr(forceReload, virtualHumanResourceId);
        }

        function getActivityVhr(forceReload, virtualHumanResourceId) {
            var filter = { VirtualHumanResourceId: virtualHumanResourceId };
            var defer = $q.defer();

            activityService.getActivityVhr(filter, forceReload)
                .then(function (data) {
                    Lazy(data.results).each((item) => {
                        item.ActivityXVhrModeForLayout = vm.activityXVhrModeObjForBind[item.ActivityXVhrModeId];
                        item.ActivityXVhrStatusForLayout = vm.activityXVhrStatusObjForBind[item.ActivityXVhrStatusId];
                    });

                    vm.currentActivityVhrs = data.results;

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.currentActivityVhrs
                    }

                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        vm.modalOk = function (modalInstance) {
            vm[modalInstance].close();
        };

        vm.modalCancel = function (modalInstance) {
            vm[modalInstance].dismiss();
        };

        // get reference of submitaction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parentSubmitAction = vm.submitAction;
        vm.submitAction = function () {
            parentSubmitAction().then(
                function (saveResult) {
                    getActivityVhr(true, virtualHumanResourceId);
                    vm.refreshAction();
                }
            );
        };

        // get reference of modifyAction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parentModifyAction = vm.modifyAction;
        vm.modifyAction = function () {
            parentModifyAction().then(
                function (saveResult) {
                    if (vm.viewContext.isEditingState) {
                        ribbon.setRibbonStatusButton(ribbon.Buttons.New, true);
                        ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, true);
                        ribbon.setRibbonStatusButton(ribbon.Buttons.AssociationStatusUpdate, true);
                        ribbon.setRibbonStatusButton(ribbon.Buttons.AssociationStatusInfo, true);
                    } else {
                        ribbon.setRibbonStatusButton(ribbon.Buttons.New, false);
                        ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                        ribbon.setRibbonStatusButton(ribbon.Buttons.AssociationStatusUpdate, false);
                        ribbon.setRibbonStatusButton(ribbon.Buttons.AssociationStatusInfo, false);

                        vm.refreshAction();
                    }

                    ribbon.refreshStatusButtons();
                }
            );
        };
    }

    vhrToActivities.$inject = injectParams;
    vhrToActivities.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('vhrToActivities', vhrToActivities);
});