﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'audService', 'drbacService', 'navigationService', 'checkStateChangeService', 'contextService', 'formSummaryService'];
    function audProcessList($q, $scope, $timeout, $injector, audService, drbacService, navigationService, checkStateChangeService, contextService, formSummaryService) {

        var vm = this;
        nbsVm.call(vm, drbacService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return currentSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, drbacService);

        var ribbon = new NbsAudRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
        var currentSelected = null;

        ribbon.setRibbonStatusButton(ribbon.Buttons.New, false);
        ribbon.refreshStatusButtons();
        
        getDrbacProcesses(true, virtualCompanyId)
            .then((results) => {
                var nbsGridDatacontext = new NbsAudGrid(vm.translate);
                nbsGridDatacontext.configureGrid('drbacprocess', null);
                nbsGridDatacontext.viewCommonBar();
                nbsGridDatacontext.setDatasource(results, false);
                vm.nbsGridDatacontext = nbsGridDatacontext;
            });

        vm.rowDblClick = function (args) {            
        };

        vm.refreshAction = function () {
            getDrbacProcesses(true, virtualCompanyId)
                .then((results) => {
                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: results
                    }
                });
        };

        vm.deleteAction = function () {
            if (!currentSelected) {
                return;
            }

            drbacService.deleteEntity(currentSelected);
            vm.submitAction()
                .then(function (saveResult) {
                    vm.refreshAction()
                });
        };

        vm.gridSelection = function (args) {
            $timeout(() => {
                currentSelected = args.data;

                ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, args.data !== null);

                if (currentSelected) {
                    // DrbacProcess cannot be deleted if IsSystem
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, !currentSelected.IsSystem);
                }

                ribbon.refreshStatusButtons();
            });
        };

        vm.copyAction = function () {
            if (!currentSelected)
                return;

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        var filter = {
                            DrbacProcessId: currentSelected.DrbacProcessId,
                            VirtualCompanyId: virtualCompanyId
                        };

                        drbacService.cloneDrbacProcess(filter)
                            .then(function (result) {
                                vm.refreshAction();
                            });
                    }
                });
        };

        function getDrbacProcesses(forceReload, virtualCompanyId) {
            var filter = {
                VirtualCompanyId: virtualCompanyId,
                IsHidden: false,
                IsGlobalProcess: false
            };
            var defer = $q.defer();

            drbacService.getDrbacProcesses(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    }

    audProcessList.$inject = injectParams;
    audProcessList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('audProcessList', audProcessList);
});