﻿'use strict';
define([], function () {

    var injectParams = ['$timeout'];
    var nbsDdTimeComponent = function ($timeout) {

        var vm = this;
        var timelineSelected = null;
        vm.actionCode = '';

        vm.ngDatePickerFromId = 'nbs_dpid_' + NbsGuidManager.newGuid().replace('-', '');
        vm.ngDatePickerToId = 'nbs_dpid_' + NbsGuidManager.newGuid().replace('-', '');
        vm.localDataSourceTimeSelected = [];
        vm.localCustomDateDs = { fromDate: null, toDate: null };
        vm.isDataRange = false;
        vm.isCustomDate = false;

        vm.$onInit = function () {
            vm.localDataSourceTime = getLocalDatasource();

            // wrapper (nbs-vm) function for datepicker open status
            vm.getDpIsOpen = vm.uiContext.getDpIsOpen;
            vm.setDpIsOpen = vm.uiContext.setDpIsOpen;
        };

        vm.$onChanges = function (changes) {
            if (changes.nbsDataSelected && changes.nbsDataSelected.currentValue) {
                // E.g. Nembus.Common.Library.component.bar.datarange.getTimeline
                //{ mode: 'custom or datarange', dataRange: [optionValue of dropdown], dateFrom: [date], dateTo: [date] }


                $timeout(function () {
                    timelineSelected = changes.nbsDataSelected.currentValue;
                    if (timelineSelected.mode === 'customDate') {
                        vm.localDataSourceTimeSelected = [];
                        vm.localCustomDateDs.fromDate = moment(timelineSelected.dateFrom).toDate();
                        vm.localCustomDateDs.toDate = moment(timelineSelected.dateTo).toDate();

                        vm.isDataRange = false;
                        vm.isCustomDate = true;
                    } else {
                        vm.localDataSourceTimeSelected = getDropdownSelected(timelineSelected.dataRange);
                        vm.isCustomDate = false;
                        vm.isDataRange = true;
                    }

                    setChangeBtnTitle();
                }, 0);
            }

            if (changes.removeSelectionEnabled) {
                if (changes.removeSelectionEnabled.currentValue === true || changes.removeSelectionEnabled.currentValue === 'true') {
                    vm.actionCode = 'remove-selection';
                }
            }
        };

        vm.onDatarangeChange = function (options) {
            if (vm.removeSelectionEnabled === 'true' && !Lazy(options).some()) {
                timelineSelected.mode = null;
                timelineSelected.dataRange = null;
                timelineSelected.dateFrom = null;
                timelineSelected.dateTo = null;
            } else {
                // Single selection in time component
                var selectedOption = Lazy(options).first();
                var range = Nembus.Common.Library.component.bar.datarange.getTimelineDatarange(selectedOption.optionValue);
                timelineSelected.mode = range.mode;
                timelineSelected.dataRange = range.dataRange;
                timelineSelected.dateFrom = range.dateFrom;
                timelineSelected.dateTo = range.dateTo;
            }

            vm.datarangeChangesEvent({ timeline: timelineSelected });
        };

        vm.onCustomDateChange = function () {
            //if (!vm.localCustomDateDs.fromDate) {
            //    vm.localCustomDateDs.fromDate = moment().toDate();
            //}

            //if (!vm.localCustomDateDs.toDate) {
            //    vm.localCustomDateDs.toDate = moment().toDate();
            //}

            if (vm.localCustomDateDs.fromDate && vm.localCustomDateDs.toDate) {
                var range = getTimelineCustomDate(vm.localCustomDateDs.fromDate, vm.localCustomDateDs.toDate);
                timelineSelected.mode = range.mode;
                timelineSelected.dateFrom = range.dateFrom;
                timelineSelected.dateTo = range.dateTo;

                vm.datarangeChangesEvent({ timeline: timelineSelected });
            }
        };

        // Switch selection dropdown/datepicker
        vm.selectionModeOnChange = function () {
            vm.isDataRange = !vm.isDataRange;
            vm.isCustomDate = !vm.isCustomDate;

            if (vm.isCustomDate) {
                vm.localCustomDateDs.fromDate = null;
                vm.localCustomDateDs.toDate = null;
                //vm.onCustomDateChange();
            }

            if (vm.isDataRange) {
                vm.localDataSourceTimeSelected = getDropdownSelected(timelineSelected.dataRange);
                vm.onDatarangeChange(vm.localDataSourceTimeSelected);
            }

            setChangeBtnTitle();
        };

        function getTimelineCustomDate(fromDate, toDate) {
            return {
                dataRange: null,
                mode: 'customDate',
                dateFrom: fromDate,
                dateTo: toDate
            };
        };

        function setChangeBtnTitle() {
            if (vm.isCustomDate) {
                vm.changeBtnTitle = vm.uiContext.translate('Seleziona timeline');
            } else {
                vm.changeBtnTitle = vm.uiContext.translate('Seleziona date custom');
            }
        }

        function getDropdownSelected(value) {
            if (!value) {
                return [];
            }

            var uiOptionSelected = Lazy(vm.localDataSourceTime).findWhere({ optionValue: value });
            if (uiOptionSelected) {
                return [uiOptionSelected];
            } else {
                return [];
            }
        }

        function getLocalDatasource() {
            var nbsUiOptionParser = new NbsUiOptionParser(vm.uiContext.translate);
            return nbsUiOptionParser.getTimeline();
        }
    };

    nbsDdTimeComponent.$inject = injectParams;
    var routeObj = routeResolver.routeObj;
    angular.module('nbs.rds.module').component('nbsDdTime', {
        //templateUrl: routeObj.templateUrlBuilder('/rds/common/components/nbsDdTimeComponent/_', window.codeCache()), 
        templateUrl: '/rds/common/components/nbsDdTimeComponent/_?c=' + window.codeCache(),  
        controller: nbsDdTimeComponent,
        controllerAs: 'vm',
        bindings: {
            datarangeChangesEvent: '&',
            nbsDataSelected: "<",
            uiContext: "<",
            ddLabel: '<',
            removeSelectionEnabled: "<",
            isVisible: "<"
        }
    });
});
