﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$injector', '$timeout', 'foundationService', 'vhreventService', 'audService', 'utilService', 'rbacService', 'navigationService', 'contextService'];
    function surveysListModal($q, $injector, $timeout, foundationService, vhreventService, audService, utilService, rbacService, navigationService, contextService) {
        var vm = this;
        nbsVm.call(vm, audService, navigationService, contextService, $q, $injector, $timeout);
       
        var appStoreCode = 'aud';
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var vhrEventKindEnum = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventKind, null, vm.translate).obj.normal.name_value;
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var expressionTypeEnum = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.ExpressionType, null, vm.translate).obj.normal.name_value;
        var vhrStatusEnum = Nembus.Common.Library.util.parseEnum(foundationService.dataContext.enumTypes.VhrStatus, null, vm.translate).obj.normal.name_value;
        var pParams = navigationService.stateParamsModal().parentParams;
        var nbsUiOptionParser = new NbsUiOptionParser(vm.translate);

        vm.gridIsVisible = false;
        vm.currentDatasDm = [];

        // at moment only lifestatus is managed
        var defaultFilter = pParams.filter ? pParams.filter : {};

        // defaults
        vm.nbsDataPrivate = {
            vhreventtypes: [],
            vhreventtypesselected: [],
            vhreventgroups: [],
            vhreventgroupsselected: [],
            vhrtypes: [],
            vhrtypesselected: [],
            vhrgroups: [],
            vhrgroupsselected: [],
            authorselected: [],
            vhrtargets: [],
            vhreventlifestatusesselected: Lazy(defaultFilter.VhrEventLifeStatusIds).some()
                ? Lazy(defaultFilter.VhrEventLifeStatusIds).map((item) => { return { optionValue: item }; }).toArray()
                : [],
            vhreventstartdatetimelineselected: Nembus.Common.Library.component.bar.datarange.getTimelineDatarange('FY+0'),
            surveycompiledatetimelineselected: Nembus.Common.Library.component.bar.datarange.getTimelineDatarange(null)
        };

        var currentGridFilter = getFilterForGrid(defaultFilter);

        // modal parameters
        pParams = pParams ? pParams : navigationService.stateParams();

        vm.gridIsMultiselect = pParams.isMultiselect;


        vm.modalOk = function () {
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalOk);
            navigationService.closeCurrentModal(modalResult);
        };

        vm.modalCancel = function () {
            navigationService.closeCurrentModal();
        };

        var currentSelected = [];
        vm.gridSelection = function (args) {
            if (vm.gridIsMultiselect) {
                currentSelected = args.data;
            } else {
                if (args.data) {
                    currentSelected = [args.data];
                } else {
                    currentSelected = [];
                }
            }
        };

        vm.onDdMsVhrEventTypes = function (options) {
            currentGridFilter.vhrEventFilter.VhrEventTypesId = Lazy(options).pluck('optionValue').toArray();
            refreshGridDatasource(true);
        };
        vm.onDdMsVhrEventGroups = function (options) {
            currentGridFilter.vhrEventFilter.VhrEventGroupsId = Lazy(options).pluck('optionValue').toArray();
            refreshGridDatasource(true);
        };
        vm.onDdMsVhrTypes = function (options) {
            currentGridFilter.virtualHrFilter.VhrTypesId = Lazy(options).pluck('optionValue').toArray();
            refreshGridDatasource(true);
        };
        vm.onDdMsVhrGroups = function (options) {
            currentGridFilter.virtualHrFilter.VhrGroupsId = Lazy(options).pluck('optionValue').toArray();
            refreshGridDatasource(true);
        };
        vm.onStartDateDatarangeSelectionEvent = function (timeline) {
            currentGridFilter.vhrEventFilter.StartDate1 = timeline.dateFrom;
            currentGridFilter.vhrEventFilter.StartDate1Expression = expressionTypeEnum.GreaterThanOrEqual;
            currentGridFilter.vhrEventFilter.StartDate2 = timeline.dateTo;
            currentGridFilter.vhrEventFilter.StartDate2Expression = expressionTypeEnum.LessThanOrEqual;
            refreshGridDatasource(true);
        };
        vm.onCompileDateDatarangeSelectionEvent = function (timeline) {
            currentGridFilter.surveyVhrEventFilter.SurveyCompileDateSystem1 = timeline.dateFrom;
            currentGridFilter.surveyVhrEventFilter.SurveyCompileDateSystem1Expression = expressionTypeEnum.GreaterThanOrEqual;
            currentGridFilter.surveyVhrEventFilter.SurveyCompileDateSystem2 = timeline.dateTo;
            currentGridFilter.surveyVhrEventFilter.SurveyCompileDateSystem2Expression = expressionTypeEnum.LessThanOrEqual;
            refreshGridDatasource(true);
        };
        vm.onDdVhrEventLifeStatus = function (options) {
            currentGridFilter.vhrEventFilter.VhrEventLifeStatusIds = Lazy(options).pluck('optionValue').toArray();
            refreshGridDatasource(true);
        };
        vm.onDdAuthor = function (options) {
            currentGridFilter.surveyVhrEventFilter.CompilerUserPersonIds = Lazy(options).pluck('optionValue').toArray();
            refreshGridDatasource(true);
        };
        vm.onDdMsVhrTargets = function (options) {
            currentGridFilter.vhrEventFilter.VirtualHumanResourcesId = Lazy(options).pluck('optionValue').toArray();
            refreshGridDatasource(true);
        };

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getVhrEventTypes(forceReload, appStoreBw));
            funcsPromises.push(getVhrEventGroups(forceReload, appStoreBw));
            funcsPromises.push(getVhrTypes(forceReload));
            funcsPromises.push(getVhrGroups(forceReload));
            funcsPromises.push(getLifeStatuses());
            funcsPromises.push(getCurrentContextAppUsers(forceReload));
            funcsPromises.push(getTargetDtos(forceReload));

            $q.all(funcsPromises)
                .then(function () {
                    vm.gridIsVisible = true;

                    utilService.getGenericElements({ GenericElementCode: 'Configurations.Surveys.LifeStatusColors' }, false)
                        .then((data) => {
                            var ge = data.results[0].PairValuesArray;
                            var statusColorObj = Nembus.Common.Library.parser.objFromList(ge, 'key', 'value');

                            var filter = getFilterForGrid(defaultFilter);
                            var supportData = { isMySurveys: false, translate: vm.translate, contextService: contextService, statusColorObj: statusColorObj };
                            var ejDataManager = audService.getSurveyDataManager(supportData, filter, ejDataManager);

                            var nbsGridDatacontext = new NbsAudGrid(vm.translate);
                            nbsGridDatacontext.configureGrid('survey', 'modal', null);
                            nbsGridDatacontext.setModalMode();
                            if (pParams.isMultiselect === 'true' || pParams.isMultiselect === true) {
                                nbsGridDatacontext.setIsMultiselect();
                            }
                            nbsGridDatacontext.setDatasource(ejDataManager, true);
                            vm.nbsGridDatacontext = nbsGridDatacontext;
                        });

                    //refreshGridDatasource(false);
                });
        }

        function getLifeStatuses() {
            var defer = $q.defer();
            var surveyLifeStatusEnumParsed = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyLifeStatus, 'SurveyDto', vm.translate);
            vm.nbsDataPrivate.vhreventlifestatuses = nbsUiOptionParser.getUiOptionsByEnum(surveyLifeStatusEnumParsed.list.translated);
            defer.resolve(true);
            return defer.promise;
        }

        function getCurrentContextAppUsers(forceReload) {
            var defer = $q.defer();
            var filter = {};

            rbacService.getCurrentContextAppUsers(filter, forceReload)
                .then(function (data) {
                    vm.nbsDataPrivate.author = nbsUiOptionParser.getUiOptionsByKey(data.results, 'AppUserDto');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getTargetDtos(forceReload) {
            var filter = {
                AppStoreBw: null,
                VhrStatusId: vhrStatusEnum.Enabled
            };

            var defer = $q.defer();

            foundationService.getTargetDtos(filter, forceReload)
                .then(function (data) {
                    vm.nbsDataPrivate.vhrtargets = nbsUiOptionParser.getUiOptionsByKey(data.results, 'VhrTarget');

                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getVhrEventTypes(forceReload, appStoreBw) {
            var filter = { AppStoreBw: appStoreBw };
            var defer = $q.defer();

            vhreventService.getVhrEventTypes(filter, forceReload)
                .then(function (data) {
                    vm.nbsDataPrivate.vhreventtypes = nbsUiOptionParser.getUiOptionsByKey(data.results, 'VhrEventType');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrEventGroups(forceReload, appStoreBw) {
            var filter = { AppStoreBw: appStoreBw };
            var defer = $q.defer();

            vhreventService.getVhrEventGroups(filter, forceReload)
                .then(function (data) {
                    vm.nbsDataPrivate.vhreventgroups = nbsUiOptionParser.getUiOptionsByKey(data.results, 'VhrEventGroup');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrTypes(forceReload) {
            var filter = {};
            var defer = $q.defer();

            foundationService.getVhrTypes(filter, forceReload)
                .then(function (data) {
                    vm.nbsDataPrivate.vhrtypes = nbsUiOptionParser.getUiOptionsByKey(data.results, 'VhrType');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getVhrGroups(forceReload) {
            var filter = {};
            var defer = $q.defer();

            foundationService.getVhrGroups(filter, forceReload)
                .then(function (data) {
                    vm.nbsDataPrivate.vhrgroups = nbsUiOptionParser.getUiOptionsByKey(data.results, 'VhrGroup');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        }

        function refreshGridDatasource(forceRefresh) {
            vm.forceDatamanagerFilter = {
                refreshKey: new Date().getTime(),
                filter: currentGridFilter
            }
        }

        function getModalResult(dialogResult) {
            Lazy(currentSelected)
                .each((item) => {
                    item.nbs_id = item.SurveyId;
                    item.nbs_code = item.SurveyCode;
                    item.nbs_name = item.SurveyName;
                });

            var modalResult = new navigationService.modalResult();
            modalResult.data = currentSelected;
            modalResult.selectedValues = Lazy(currentSelected).pluck('SurveyId').toArray();
            modalResult.hasSelection = Lazy(currentSelected).some();
            //modalResult.sourceParams = urlParams;
            modalResult.dialogResult = dialogResult;
            return modalResult;
        }

        function getFilterForGrid(defaultFilter) {
            var filter = {
                surveyFilter: {},
                surveyVhrEventFilter: {},
                vhrEventFilter: {},
                virtualHrFilter: {}
            };

            //surveyFilter
            filter.surveyFilter.IsArchived = null;
            if (pParams.dataKey === 'surveycompleted') {
                filter.surveyFilter.IsCompleted = true;
            }

            //vhrEventFilter
            filter.vhrEventFilter.AppStoreBw = appStoreBw;
            filter.vhrEventFilter.VhrEventKind = vhrEventKindEnum.Data;
            filter.vhrEventFilter.ModuleTargetId = moduleTargetId;
            filter.vhrEventFilter.VhrEventLifeStatusExpression = expressionTypeEnum.Equal;
            filter.vhrEventFilter.StartDate1 = vm.nbsDataPrivate.vhreventstartdatetimelineselected.dateFrom;
            filter.vhrEventFilter.StartDate1Expression = expressionTypeEnum.GreaterThanOrEqual;
            filter.vhrEventFilter.StartDate2 = vm.nbsDataPrivate.vhreventstartdatetimelineselected.dateTo;
            filter.vhrEventFilter.StartDate2Expression = expressionTypeEnum.LessThanOrEqual;
            if (Lazy(defaultFilter.VhrEventLifeStatusIds).some()) {
                filter.vhrEventFilter.VhrEventLifeStatusIds = defaultFilter.VhrEventLifeStatusIds;
            }

            //surveyVhrEventFilter
            filter.surveyVhrEventFilter.SurveyCreationPatternId = null;
            filter.surveyVhrEventFilter.ExcludeOnDemand = true;
            filter.surveyVhrEventFilter.SurveyCompileDateSystem1 = vm.nbsDataPrivate.surveycompiledatetimelineselected.dateFrom;
            filter.surveyVhrEventFilter.SurveyCompileDateSystem1Expression = expressionTypeEnum.GreaterThanOrEqual;
            filter.surveyVhrEventFilter.SurveyCompileDateSystem2 = vm.nbsDataPrivate.surveycompiledatetimelineselected.dateTo;
            filter.surveyVhrEventFilter.SurveyCompileDateSystem2Expression = expressionTypeEnum.LessThanOrEqual;

            return filter;
        }
    }

    surveysListModal.$inject = injectParams;
    surveysListModal.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('surveysListModal', surveysListModal);
});
