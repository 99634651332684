﻿'use strict';

define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'utilService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function scheduledTaskStepDetail($q, $scope, $timeout, $injector, utilService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, utilService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, utilService);

        //var appStoreCode = navigationService.stateParams().appStoreCode;
        //var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var pParams = navigationService.stateParamsModal().parentParams;
        pParams = pParams ? pParams : navigationService.stateParams();

        // navigation buttons visibility
        vm.setWizardButtonVisibility(pParams);

        // beardcrumb information
        var beardCrumbInfo = JSON.parse(pParams.beardCrumbInfo);
        Lazy(beardCrumbInfo.items).each((item) => { item.title = vm.translate(item.title);})
        vm.beardCrumbInfo = beardCrumbInfo;

        vm.scheduledTaskClientData = utilService.dataContext.scheduledTaskClientDatas.add({
            ScheduledTaskClientDataId: NbsGuidManager.newGuid(),
        });

        // refill data form
        if (utilService.scheduledTaskCollector) {
            vm.scheduledTaskClientData.Code = utilService.scheduledTaskCollector.Code;
            vm.scheduledTaskClientData.Name = utilService.scheduledTaskCollector.Name;
            vm.scheduledTaskClientData.Time = utilService.scheduledTaskCollector.Time;
        }

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = []
            
            $q.all(funcsPromises)
                .then(function () {
                    //
                });
        }

        vm.modalNext = function () {
            //force validation on entity (manual metadata only client)
            vm.scheduledTaskClientData.entityAspect.validateEntity();
            if (!vm.scheduledTaskClientData.entityAspect.hasValidationErrors) {
                utilService.rejectChanges();

                // close modal and returns client data object
                var modalResult = getModalResult(navigationService.navigationActionTypes.modalNext);
                navigationService.closeCurrentModal(modalResult);
            }
        };

        vm.modalCancel = function () {
            navigationService.confirmExitWizard()
                            .then(function (result) {
                                if (result) {
                                    utilService.rejectChanges();
                                    var modalResult = getModalResult(navigationService.navigationActionTypes.modalCancel);
                                    navigationService.closeCurrentModal(modalResult);
                                }
                            });            
        };

        function getModalResult(dialogResult) {
            var modalResult = new navigationService.modalResult()

            var resultData = {
                Code: vm.scheduledTaskClientData.Code,
                Name: vm.scheduledTaskClientData.Name,
                Time: vm.scheduledTaskClientData.Time,
            }

            modalResult.data = resultData;
            modalResult.sourceParams = pParams;
            modalResult.dialogResult = dialogResult;
            return modalResult;
        }
    };

    scheduledTaskStepDetail.$inject = injectParams;
    scheduledTaskStepDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('scheduledTaskStepDetail', scheduledTaskStepDetail);
});