﻿'use strict';
define([], function () {

    var injectParams = ['$q', '$timeout'];

    function DataServiceException(message) {
        this.message = message;
        this.name = "DataServiceException";
    }

    function encodeParams(obj) {
        var query = '';
        var key, subValue, innerObj, fullSubName;

        for (var name in obj) {
            var value = obj[name];

            if (value instanceof Array) {
                for (var i = 0; i < value.length; ++i) {
                    subValue = value[i];
                    fullSubName = name + '[' + i + ']';
                    innerObj = {};
                    innerObj[fullSubName] = subValue;
                    query += encodeParams(innerObj) + '&';
                }
            } else if (value && value.toISOString) { // a feature of Date-like things
                query += encodeURIComponent(name) + '=' + encodeURIComponent(value.toISOString()) + '&';
            } else if (value instanceof Object) {
                for (var subName in value) {
                    subValue = value[subName];
                    fullSubName = name + '[' + subName + ']';
                    innerObj = {};
                    innerObj[fullSubName] = subValue;
                    query += encodeParams(innerObj) + '&';
                }
            } else if (value === null) {
                query += encodeURIComponent(name) + '=&';
            } else if (value !== undefined) {
                query += encodeURIComponent(name) + '=' + encodeURIComponent(value) + '&';
            }
        }

        return query.length ? query.substr(0, query.length - 1) : query;
    }

    var breezeEjAdaptor = new ej.WebApiAdaptor().extend({
        processResponse: function (data, ds, query, xhr, request, changes) {
            if (ds.processResponseData) {
                var res = ds.processResponseData({ results: data.Items });
                if (res) {
                    data.Items = res;
                }
            }

            var obj = ej.WebApiAdaptor.prototype.processResponse(data, ds, query, xhr, request, changes);
            return obj;
        },
        processQuery: function (dm, query, hierarchyFilters) {
            //replace sort fields
            var sortFieldAny = [];

            function filterEnumList(enumerator, filter, operator) {
                var result = [];

                filter = filter.toString().toLowerCase();

                // caseSensitiveSearch is setted false (nbs-directive.js) then is all changed in lowercase
                switch (operator) {
                    case ej.FilterOperators.equal:
                        result = Lazy(enumerator).filter((item) => { return item.name.toLowerCase() === filter; }).toArray();
                        break;
                    case ej.FilterOperators.notEqual:
                        result = Lazy(enumerator).reject((item) => { return item.name.toLowerCase() === filter; }).toArray();
                        break;
                    case ej.FilterOperators.startsWith:
                        result = Lazy(enumerator).filter((item) => { return item.name.toLowerCase().startsWith(filter); }).toArray();
                        break;
                    case ej.FilterOperators.endsWith:
                        result = Lazy(enumerator).filter((item) => { return item.name.toLowerCase().endsWith(filter); }).toArray();
                        break;
                    case ej.FilterOperators.contains:
                        result = Lazy(enumerator).filter((item) => { return item.name.toLowerCase().indexOf(filter) >= 0; }).toArray();
                        break;
                    default:
                        // default contains
                        result = Lazy(enumerator).filter((item) => { return item.name.toLowerCase().indexOf(filter) >= 0; }).toArray();
                }

                return result;
            }

            function getCustomPredicates(currentPredicate) {
                var predicatesToApply = null;

                var objFieldData = dm.onWhereCustomPredicate[currentPredicate.field];
                if (objFieldData.FieldType === 'Enum') {
                    // objFieldData example (type ENUM)
                    //{
                    //  ReplaceFieldNameWith: 'VhrEventLifeStatusId',
                    //  FieldType: 'Enum',
                    //  Datasource: Lazy(vhrEventLifeStatus).map((status) => { return { value: status.value, name: status.nameTranslated } }).toArray(),
                    //}

                    currentPredicate.field = objFieldData.ReplaceFieldNameWith;

                    var matchingList = filterEnumList(objFieldData.Datasource, currentPredicate.value, currentPredicate.operator);
                    if (matchingList.length > 0) {
                        Lazy(matchingList)
                            .each((item) => {
                                var newPredicate = ej.Predicate(currentPredicate.field, ej.FilterOperators.equal, Number(item.value), true);
                                predicatesToApply = predicatesToApply
                                    ? predicatesToApply.or(newPredicate)
                                    : ej.Predicate.or(newPredicate);
                            });
                    } else {
                        var newPredicate = ej.Predicate(currentPredicate.field, ej.FilterOperators.equal, -1, true);
                        predicatesToApply = predicatesToApply
                            ? predicatesToApply.and(newPredicate)
                            : ej.Predicate.and(newPredicate);
                    }
                }

                if (objFieldData.FieldType === 'Date') {
                    // This field type were skipped in queries

                    // objFieldData example (type DATE)
                    //{
                    //  FieldType: vm.FilterMenu.FieldType.Date,
                    //  SupportData: {
                    //      FilterNameDest: 'filter',
                    //      FieldNameInFilter: 'VhrEventStartDate_display',
                    //      FilterExpressionEnum: utilService.dataContext.enumTypes.ExpressionType
                    //  }
                    //}
                    var filter = null;
                    if (dm.complexParams) {
                        Lazy(dm.complexParams)
                            .each((f) => {
                                if (f[objFieldData.SupportData.FilterNameDest]) {
                                    filter = f[objFieldData.SupportData.FilterNameDest];
                                }
                            });
                    }

                    if (filter) {
                        var xxx = currentPredicate;
                        var date = null;
                        if (!currentPredicate.value) {
                            date = Lazy(currentPredicate.predicates).first().value;
                        } else {
                            date = currentPredicate.value;
                        }

                        filter[objFieldData.SupportData.FieldNameInFilter] = currentPredicate.value;
                        var expressionObj = objFieldData.SupportData.FilterExpression;
                        var expressionFilter = objFieldData.SupportData.FieldNameInFilter + 'Expression';
                        filter[expressionFilter] = expressionFilter.Equal;
                    }
                }

                return predicatesToApply;
            }

            function setResultPredicates(currentPredicate) {
                if (dm.onWhereCustomPredicate.hasOwnProperty(currentPredicate.field)) {
                    //predicate is calculated custom
                    var calculatedPredicates = getCustomPredicates(currentPredicate);
                    if (calculatedPredicates) {
                        resultPredicates = resultPredicates
                            ? resultPredicates.and(calculatedPredicates)
                            : ej.Predicate.and(calculatedPredicates);
                    }
                } else {
                    //use currentPredicate because is not managed custom
                    resultPredicates = resultPredicates
                        ? resultPredicates.and(currentPredicate)
                        : ej.Predicate.and(currentPredicate);
                }
            }

            function setPredicates(currentPredicate) {
                if (currentPredicate.field) {
                    // one predicate
                    setResultPredicates(currentPredicate);
                } else {
                    Lazy(currentPredicate.predicates)
                        .each((subPredicate) => {
                            if (subPredicate.predicates && subPredicate.predicates.length > 0) {
                                setPredicates(subPredicate);
                            } else {
                                setResultPredicates(subPredicate);
                            }
                        });
                }

                //return ej.Query().where(
            }

            var resultPredicates;
            var isOnSearch = false;
            var cSearchKey = null;
            Lazy(query.queries)
                .each((currQuery) => {
                    if (currQuery.fn === 'onSortBy') {
                        //for (var prop in dm.fieldNameReplacer) {
                        if (dm.fieldNameReplacer.hasOwnProperty(currQuery.e.fieldName)) {
                            currQuery.e.fieldName = dm.fieldNameReplacer[currQuery.e.fieldName];
                        }
                        //}
                        if (Lazy(sortFieldAny).contains(currQuery.e.fieldName)) {
                            currQuery.isDuplicate = true;
                        } else {
                            sortFieldAny.push(currQuery.e.fieldName);
                        }
                    }
                    if (currQuery.fn === 'onWhere') {
                        setPredicates(currQuery.e);
                    }
                    if (currQuery.fn === 'onSearch') {
                        currQuery.e.fieldNames = dm.freeSearchFields;
                        isOnSearch = true;
                        cSearchKey = currQuery.e.searchKey;
                    }
                });

            // query.search(200, 'SurveyId', 'equal')
            if (isOnSearch) {
                if (cSearchKey) {
                    var ignoreCase = true;
                    var ignoreAccent = false;
                    query.queries = Lazy(query.queries).reject({ fn: 'onSearch' }).toArray();
                    query.queries = Lazy(query.queries).reject({ fn: 'onWhere' }).toArray();

                    var numberWherePredicate = null;
                    if (!isNaN(cSearchKey)) {
                        Lazy(dm.freeSearchFieldsNumber)
                            .each((field) => {
                                if (Number(cSearchKey) < 2147483648) {
                                    if (!numberWherePredicate) {
                                        numberWherePredicate = new ej.Predicate(field, ej.FilterOperators.equal, Number(cSearchKey), ignoreCase, ignoreAccent);
                                    } else {
                                        numberWherePredicate = orWherePredicate.or(new ej.Predicate(field, ej.FilterOperators.equal, Number(cSearchKey), ignoreCase, ignoreAccent));
                                    }
                                }
                            });
                    }

                    var fieldWherePredicate = null;
                    Lazy(dm.freeSearchFields)
                        .each((field) => {
                            var cPredicate = new ej.Predicate(field, ej.FilterOperators.contains, cSearchKey, ignoreCase, ignoreAccent).and(new ej.Predicate(field, ej.FilterOperators.notEqual, null, ignoreCase, ignoreAccent));

                            if (!fieldWherePredicate) {
                                fieldWherePredicate = cPredicate;
                            } else {
                                fieldWherePredicate = fieldWherePredicate.or(cPredicate);
                            }
                        });

                    var wherePredicate = numberWherePredicate;
                    if (!wherePredicate) {
                        if (fieldWherePredicate) {
                            wherePredicate = fieldWherePredicate;
                        }
                    } else {
                        if (fieldWherePredicate) {
                            wherePredicate = wherePredicate.or(fieldWherePredicate);
                        }
                    }

                    if (wherePredicate) {
                        query.where(wherePredicate);
                    }
                }
            }

            if (resultPredicates) {
                var onWhereQuery = Lazy(query.queries).findWhere({ fn: 'onWhere' });
                if (onWhereQuery) {
                    // Replace onWhere predicates with calculated predicates using 'onWhereCustomPredicate' setted by controller
                    onWhereQuery.e = resultPredicates;
                }
            } else {
                if (!isOnSearch) {
                    query.queries = Lazy(query.queries).reject({ fn: 'onWhere' }).toArray();
                }
            }


            //clear order by fields duplicates
            var i = 0;
            while (i < query.queries.length) {
                if (query.queries[i].isDuplicate) {
                    query.queries.splice(i, 1);
                } else {
                    i++;
                }
            }

            var obj = ej.WebApiAdaptor.prototype.processQuery(dm, query, hierarchyFilters);
            //fix ie

            var url = obj.url;
            url = url.replace('$top=12&', '$top=0&');

            var urlApi = '';
            var querystring = '';

            if (url.indexOf('?') >= 0) {
                urlApi = url.split("?")[0];
                querystring = url.split("?")[1];
            } else {
                urlApi = url;  // no querystring
            }

            var fixQuerystring = '';
            if (querystring) {
                var querystringArray = querystring.split("&");
                var skip = Lazy(querystringArray).find(function (x) { return x.indexOf('$skip') >= 0; });
                var top = Lazy(querystringArray).find(function (x) { return x.indexOf('$top') >= 0; });
                var select = Lazy(querystringArray).find(function (x) { return x.indexOf('$select') >= 0; });

                for (var index = 0; index < querystringArray.length; index++) {
                    if (!(querystringArray[index].indexOf('$skip') >= 0
                        || querystringArray[index].indexOf('$top') >= 0)) {

                        if (index === 0) {
                            fixQuerystring += querystringArray[index];
                        } else {
                            fixQuerystring += '&' + querystringArray[index];
                        }
                    }
                }

                if (skip) {
                    fixQuerystring += "&" + skip;
                }
                if (top) {
                    fixQuerystring += "&" + top;
                }
            }

            obj.url = urlApi + '?' + fixQuerystring;
            //end fix
            if (dm.complexParams && dm.complexParams.length > 0) {
                obj.url = obj.url + '&' + encodeParams(dm.complexParams[0]);//todo: loop for more than one param
            }

            // onSelect is added when ExcelFilter textbox changes (ExcelFilter not used too)
            //              removed because is launched a server query that returns all records from db (autocomplete syncfusion behaviour)
            // onSelect is added from treegrid expanding event            
            if (Lazy(query.queries).where({ fn: 'onSelect' }).some()) {
                //obj.url = null;   // => original
                obj.url = obj.url.replace(select, '');
            }

            return obj;
        },
        beforeSend: function (dm, request, settings) {
            var authData = JSON.parse(window.localStorage.getItem('ls.authorizationData'));
            request.setRequestHeader("Authorization", "Bearer " + authData.access_token);

            if (settings.url.endsWith(this.options.batch) && settings.type.toLowerCase() === "post") {
                request.setRequestHeader("Accept", oData.multipartAccept);
                request.setRequestHeader("DataServiceVersion", "2.0");
                request.overrideMimeType("text/plain; charset=x-user-defined");
            }

            if (!dm.dataSource.crossDomain) {
                request.setRequestHeader("DataServiceVersion", "2.0");
                request.setRequestHeader("MaxDataServiceVersion", "2.0");
            }

            settings.error = function (response, errorType, exception) {
                if (response.status === 401) {
                    //refresh token
                }
            };
        }
    });

    function breezeEjDataManager(servicePath) {
        //"/breeze/vhrevent/VhrEventsPaged"
        //self = this;
        var _servicePath = servicePath;

        return {
            getEjManager: getEjManager
        };

        //return dataManager;
        function getEjManager(serviceName) {
            var dataManager = ej.DataManager({
                url: _servicePath + '/' + serviceName,
                crossDomain: true,
                adaptor: new breezeEjAdaptor()

            });
            //array used in adaptor to add breeze complex params
            //as params for webapi (generally filters) e.g. {filter:{VirtualCompanyId:1,vhrEventCode:'XX'}}
            dataManager.complexParams = [];
            dataManager.freeSearchFields = [];
            dataManager.freeSearchFieldsNumber = []; // insert here field names for data type number
            dataManager.fieldNameReplacer = null;

            return dataManager;
        }
    }

    //overload ej.data.js
    //parseJson: function (jsonText) {
    //in order to parse Date in the same manner as breeze
    var customNbsEjOverloadParse = {
        parseJson: function (jsonText) {
            var type = typeof jsonText;
            if (type === "string") {
                jsonText = JSON.parse(jsonText, customNbsEjOverloadParse.jsonReviver);
            } else if (jsonText instanceof Array) {
                customNbsEjOverloadParse.iterateAndReviveArray(jsonText);
            } else if (type === "object")
                customNbsEjOverloadParse.iterateAndReviveJson(jsonText);
            return jsonText;
        },
        iterateAndReviveArray: function (array) {
            for (var i = 0; i < array.length; i++) {
                if (typeof array[i] === "object")
                    customNbsEjOverloadParse.iterateAndReviveJson(array[i]);
                else if (typeof array[i] === "string" && !/^[\s]*\[|^[\s]*\{|\"/g.test(array[i]))
                    array[i] = customNbsEjOverloadParse.jsonReviver("", array[i]);
                else
                    array[i] = customNbsEjOverloadParse.parseJson(array[i]);
            }
        },
        iterateAndReviveJson: function (json) {
            var value;

            for (var prop in json) {
                if (prop.startsWith("__"))
                    continue;

                value = json[prop];
                if (typeof value === "object") {
                    if (value instanceof Array)
                        customNbsEjOverloadParse.iterateAndReviveArray(value);
                    else
                        customNbsEjOverloadParse.iterateAndReviveJson(value);
                } else
                    json[prop] = customNbsEjOverloadParse.jsonReviver(prop, value);
            }
        },
        jsonReviver: function (field, value) {
            var s = value;
            if (typeof value === "string") {
                var ms = /^\/Date\(([+-]?[0-9]+)([+-][0-9]{4})?\)\/$/.exec(value);
                if (ms)
                    return customNbsEjOverloadParse.replacer(new Date(parseInt(ms[1])));
                else if (/^(\d{4}\-\d\d\-\d\d([tT][\d:\.]*){1})([zZ]|([+\-])(\d\d):?(\d\d))?$/.test(value)) {
                    //value = customNbsEjOverloadParse.replacer(new Date(value));
                    value = moment.utc(value).toDate();
                    if (isNaN(value)) {
                        var a = s.split(/[^0-9]/);
                        value = customNbsEjOverloadParse.replacer(new Date(a[0], a[1] - 1, a[2], a[3], a[4], a[5]));
                    }
                }
            }

            return value;
        },
        isJson: function (jsonData) {
            if (typeof jsonData[0] === "string")
                return jsonData;
            return ej.parseJSON(jsonData);
        },
        isGuid: function (value) {
            var regex = /[A-Fa-f0-9]{8}(?:-[A-Fa-f0-9]{4}){3}-[A-Fa-f0-9]{12}/i;
            var match = regex.exec(value);
            return match !== null;
        },
        replacer: function (value) {

            if (ej.isPlainObject(value))
                return customNbsEjOverloadParse.jsonReplacer(value);

            if (value instanceof Array)
                return customNbsEjOverloadParse.arrayReplacer(value);

            if (value instanceof Date)
                return customNbsEjOverloadParse.jsonReplacer({ val: value }).val;

            return value;
        },
        jsonReplacer: function (val) {
            var value;
            for (var prop in val) {
                value = val[prop];

                if (!(value instanceof Date))
                    continue;
                val[prop] = new Date(+value + (ej.serverTimezoneOffset * 60 * 60 * 1000));
            }

            return val;
        },
        arrayReplacer: function (val) {

            for (var i = 0; i < val.length; i++) {
                if (ej.isPlainObject(val[i]))
                    val[i] = customNbsEjOverloadParse.jsonReplacer(val[i]);
                else if (val[i] instanceof Date)
                    val[i] = customNbsEjOverloadParse.jsonReplacer({ date: val[i] }).date;
            }

            return val;
        }
    };

    ej.parseJSON = customNbsEjOverloadParse.parseJson;


    function breezeDataLayer($q, $timeout) {
        var that = this;
        var __manager;
        var __servicePath;
        //var __ejmanager;
        var __baseHref;
        var __initialized = false;

        function getServiceRootFromPage() {
            /*
            if (__baseHref) return __baseHref; // get once
     
            var base = document.getElementsByTagName("base")[0];
            var baseHref = base.getAttributeNode("href");
            if (baseHref) {
                __baseHref = baseHref.value;
     
                return __baseHref;
            }
            return '';
            */
            return '';
        }

        function initialize(servicePath, newInstance) {

            if (!servicePath.startsWith('breeze/')) {
                servicePath = 'breeze/' + servicePath;
            }

            if (newInstance === true) {
                var bm = breezeDataLayer($q, $timeout);
                bm.Initialize(servicePath, false);
                return bm;
            }

            if (__initialized) {
                //logger.error('You must request a new instance, this has already been initialized');
                throw new DataServiceException('You must request a new instance, this has already been initialized');
                //return null;
            }

            if (servicePath.substring(0, 4) !== 'http') {
                if (servicePath.substring(0, 1) !== '/') {
                    servicePath = getServiceRootFromPage() + servicePath;
                }
            }
            //var jraConfig = {
            //    name: "nbsBreeze",
            //    visitNode: function (node, parseContext, nodeContext) {
            //        var n = node;
            //        return node;
            //    }
            //};
            //var myJsonResultsAdapter = new breeze.JsonResultsAdapter(jraConfig);

            // define the Breeze `DataService` for this app
            var dataService = new breeze.DataService({
                serviceName: servicePath,
                hasServerMetadata: false  // don't ask the server for metadata
                //jsonResultsAdapter: myJsonResultsAdapter
            });

            // create the metadataStore 
            var metadataStore = new breeze.MetadataStore({
                //namingConvention: camelCaseConvention // if you use this convention
            });

            // initialize it from the application's metadata variable
            metadataStore.importMetadata(Nembus.Common.Library.AppModel.metadata);



            __manager = new breeze.EntityManager({
                dataService: dataService,
                metadataStore: metadataStore
            });
            __servicePath = servicePath;

            __initialized = true;
            return getInstance();
        }


        function getInstance() {
            return {
                Initialize: initialize,
                GetManager: getManager,
                InvokeQuery: invokeQuery,
                CreateQuery: createQuery,
                GetMetaData: getMetaData,
                GetEnumTypes: getEnumTypes,
                GetEnumType: getEnumType,
                GetServiceRootFromPage: getServiceRootFromPage,
                IsInitialized: isInitialized,
                ClearCachedEntityType: clearCachedEntityType,
                //ClearCachedAll: clearCachedAll,
                GetDataDetailPromise: getDataDetailPromise,
                GetDataListPromise: getDataListPromise,
                addType: addType,
                detachEntity: detachEntity,
                GetEjDataManager: getEjDataManager,
                setContextMethods: setContextMethods,
                /*
                getEntitiesAttached: getEntitiesAttached,
                getEntitiesAttachedExplicitType: getEntitiesAttachedExplicitType
                */
            };
        }

        /*
        function getEntitiesAttached(entities, entityType, entityIdProperty) {
            var attachedEntities = [];
            Lazy(entities)
                .each((cEntity) => {
                    var entityAttached = __manager.getEntityByKey(entityType, cEntity[entityIdProperty]);
                    if (!entityAttached) {
                        entityAttached = __manager.createEntity(entityType, cEntity, breeze.EntityState.Unchanged);
                    }
    
                    if (entityAttached) {
                        attachedEntities.push(entityAttached);
                    }
                });
    
            return attachedEntities;
        }
        */

        //used when source object is plain javascript with NO $type property
        /*
        function getEntitiesAttachedExplicitType(entities, entityType) {
            var attachedEntities = [];
            Lazy(entities)
                .each((cEntity) => {
                    var entityAttached = __manager.createEntity(entityType, cEntity, breeze.EntityState.Unchanged, breeze.MergeStrategy.PreserveChanges);
                    attachedEntities.push(entityAttached);
                });
    
            return attachedEntities;
        }
        */

        //used when source object is plain javascript with $type property (e.g. parsed from breeze json api response)
        //e.g. ejgrid datasource from breeze api
        /*
        function getEntitiesAttached(entities) {
            var attachedEntities = [];
            if (entities && entities.length > 0 && entities[0].$type && entities[0].$type.indexOf(',') > 0) {
                var entityType = Lazy(entities[0].$type.split(',')[0].split('.')).last();
            }
            else {
                return attachedEntities;
            }
            Lazy(entities)
                .each((cEntity) => {
                    var entityAttached = __manager.createEntity(entityType, cEntity, breeze.EntityState.Unchanged, breeze.MergeStrategy.PreserveChanges);
                    attachedEntities.push(entityAttached);
                });
    
            return attachedEntities;
        }
        */

        function setContextMethods(manager, nbsService, entityType) {
            return {
                get: function () { return manager.getEntities(entityType); },
                getByKey: function (key) { return manager.getEntityByKey(entityType, key); },
                add: function (entity) { return nbsService.prototype.createEntityStatic(entityType, entity, manager); },
                clearCache: function () { clearCachedEntityType(entityType); }
            };
        }

        function isInitialized() {
            return __initialized;
        }


        function getManager() {
            if (!__initialized) {
                throw new DataServiceException('You must initialize the Data Layer.');
            }

            return __manager;
        }

        function getEnumTypes() {
            return JSON.parse(Nembus.Common.Library.AppModel.metadata).schema.enumType;
        }

        function clearCachedEntityType(entityType) {
            if (!entityType) {
                return;
            }
            var cachedEntities = __manager.getEntities(entityType); // all invoices in cache
            // Todo: this should be a function of the Breeze EntityManager itself
            cachedEntities.forEach(function (entity) { __manager.detachEntity(entity); });
        }

        function detachEntity(entity) {
            __manager.detachEntity(entity);
        }

        //function clearCachedAll() {
        //    __manager.clear();
        //}

        function getDataDetailPromise(params, filter, forceReload, webapi) {
            params.forceReload = forceReload;
            params.webapi = webapi;
            var deferred = $q.defer();

            getDataDetailToProcess(params, filter)
                .then(function (data) {
                    if (data.results.length !== 1) {
                        console.log("Error occurred in detail : ", data.query.resourceName);
                        //throw new Error("Error occurred in detail")
                        deferred.reject("Error occurred in detail");
                    } else {
                        deferred.resolve(data);
                        //data.results = Lazy(data.results).first();
                    }

                    //return data;
                })
                .catch(function (error) {
                    var tmpError = error + '';
                    if (tmpError.indexOf("ajaxImpl.ajax is not a function") > 0) {
                        //workaround catch error when offline
                        //
                        deferred.reject({ isMessageVisible: true, message: 'offline' });
                    }
                    else {
                        deferred.reject(error);
                    }
                });

            return deferred.promise;
        }

        function getDataListPromise(params, filter, forceReload, webapi) {
            params.forceReload = forceReload;
            params.queryFilter = filter;
            params.webapi = webapi;
            var deferred = $q.defer();


            getDataListToProcess(params, filter)
                .then(function (data) {
                    deferred.resolve(data);
                })
                .catch(function (error) {
                    var tmpError = error + '';
                    if (tmpError.indexOf("ajaxImpl.ajax is not a function") > 0) {
                        //workaround catch error when offline
                        //
                        deferred.reject({ isMessageVisible: true, message: 'offline' });
                    }
                    else {
                        deferred.reject(error);
                    }
                });

            return deferred.promise;
        }

        function getDataDetailToProcess(params, filter) {

            if (isDataDetailFromDb(params)) {
                var query = null;
                if (filter) {
                    query = createQuery(params.webapi).withParameters({ filter: filter });
                } else {
                    query = createQuery(params.webapi);
                }

                return __manager.executeQuery(query);
            } else {
                return returnCachedData([params.entityInCache]);
            }
        }

        function getDataListToProcess(params, filter) {
            if (isDataListFromDb(params)) {
                var data = null;
                clearCachedEntityType(params.entityType);

                var query = null;
                if (filter) {
                    if (Object.prototype.toString.call(filter) === '[object Array]') {
                        data = {};
                        Lazy(filter)
                            .each((o) => {
                                data[o.filterName] = o.filterData;
                            });
                    } else {
                        data = { filter: filter };
                    }
                    query = createQuery(params.webapi).withParameters(data);
                } else {
                    query = createQuery(params.webapi);
                }

                return __manager.executeQuery(query);
            } else {
                return returnCachedData(params.cache);
            }
        }

        function returnCachedData(values) {
            var deferred = $q.defer();
            deferred.resolve({ results: values });
            return deferred.promise;
        }

        function isDataListFromDb(params) {
            var result = false;

            // if force 'reload = true' get data from db
            params.forceReload = params.forceReload === undefined || params.forceReload === null ? true : params.forceReload;

            var isLastQueryForCurrentEntity = isLastQueryFilter(params.entityType, params.queryFilter);

            if (params.forceReload || !isLastQueryForCurrentEntity) {
                result = true;
            } else {
                if (params.cache.length === 0) {
                    result = true;
                } else {
                    result = false;
                }
            }

            return result;
        }

        var queryFilterArray = [];
        function isLastQueryFilter(keyEntity, queryFilter) {
            queryFilter = !queryFilter ? '' : queryFilter;

            var entity = Lazy(queryFilterArray).findWhere({ key: keyEntity });
            var queryFilterSerialized = JSON.stringify(queryFilter);

            if (!entity) {
                queryFilterArray.push({ key: keyEntity, queryFilterSerialized: queryFilterSerialized });
            } else {
                if (entity.queryFilterSerialized === queryFilterSerialized) {
                    return true;
                } else {
                    entity.queryFilterSerialized = queryFilterSerialized;
                }
            }

            return false;
        }

        function isDataDetailFromDb(params) {
            // if force 'reload = true' get data from db
            params.forceReload = params.forceReload === undefined || params.forceReload === null ? true : params.forceReload;

            if (!params.forceReload)
                params.entityInCache = params.dataContext.getByKey(params.key);

            if (params.forceReload || !params.entityInCache) {
                return true;
            } else {
                return false;
            }
        }

        function getEnumType(typeName) {
            var enumTypes = getEnumTypes();
            var enumType = '[]';

            for (var i = 0; i < enumTypes.length; i++) {
                if (enumTypes[i].name === typeName) {
                    enumType = enumTypes[i].member;
                }
            }

            return enumType;
        }

        function getMetaData() {
            if (!__initialized) {
                throw new DataServiceException('You must initialize the Data Layer.');
            }

            return __manager.fetchMetadata();
        }

        function createQuery(entityType) {
            if (!__initialized) {
                throw new DataServiceException('You must initialize the Data Layer.');
            }

            var query = breeze.EntityQuery.from(entityType);

            return query;
        }

        function invokeQuery(entityType, data) {

            if (!__initialized) {
                throw new DataServiceException('You must initialize the Data Layer.');
            }

            breeze.ajaxpost();
            var query = breeze.EntityQuery.from(entityType, data)
                .withParameters(
                    {
                        $method: 'POST',
                        $data: data
                    });

            return query;
        }

        function addType(metadataStore, type, domain) {

            let dataProperties = {};
            let firstDataProperty = {};

            //var typenameId = typeDef.name.toLowerCase() + 'id';

            //let count = 0;
            for (let xx of Object.getOwnPropertyNames(type.dataProperties)) {
                dataProperties[xx] = {};
                dataProperties[xx].dataType = type.dataProperties[xx].type;


                //if (count == 0) {
                //    dataProperties[xx].isPartOfKey = true;
                //    firstDataProperty = dataProperties[xx];
                //}
                //count++;
            }

            let finalType = {
                shortName: type.name,
                dataProperties: dataProperties,
                namespace: domain,
                autoGeneratedKeyType: breeze.AutoGeneratedKeyType.Identity
            };

            findEntityKey(finalType);


            let entityType = new breeze.EntityType(finalType);
            metadataStore.addEntityType(entityType);
            //var helper = new breeze.config.MetadataHelper(domain, breeze.AutoGeneratedKeyType.Identity);

            //helper.addTypeToStore(metadataStore, type);
        }

        function findEntityKey(typeDef) {
            var dps = typeDef.dataProperties;
            var typenameId = typeDef.shortName.toLowerCase() + 'id';
            for (var key in dps) {
                var prop = dps[key];
                if (prop.isPartOfKey) { // found a key part; stop analysis 
                    return key;
                }
                // if type were Person, would look for 'id' or 'personid'
                if (prop.isPartOfKey == null) {
                    // isPartOfKey is null or undefined; is it a candidate?
                    var keyLc = key.toLowerCase();
                    if (keyLc === 'id' || keyLc === typenameId) {
                        // infer this property is the key; stop further analysis
                        prop.isPartOfKey = true;
                        return key;
                    }
                }
            }
            return null;
        }

        function getEjDataManager() {
            return new breezeEjDataManager(__servicePath);
        }

        return getInstance();
    }
    breezeDataLayer.$inject = injectParams;
    angular.module('nbs.shared.module').service('breezeDataLayer', breezeDataLayer);

});