﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'dataflowService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function fileShuffleDefinitionDetail($q, $scope, $timeout, $injector, dataflowService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, dataflowService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, dataflowService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var fileConnectionTypesEnumParsed = Nembus.Common.Library.util.parseEnum(dataflowService.dataContext.enumTypes.FileConnectionType, null, vm.translate);
        var fileActionsEnumParsed = Nembus.Common.Library.util.parseEnum(dataflowService.dataContext.enumTypes.FileAction, null, vm.translate);
        var fileShuffleDefinitionId = navigationService.stateParams().fileShuffleDefinitionId;

        // TODO: manage crud actions in dataflows controller
        ribbon.setRibbonStatusButton(ribbon.Buttons.Modify, false);
        ribbon.refreshStatusButtons();

        vm.fileConnectionTypes = fileConnectionTypesEnumParsed.list.translated;
        vm.fileActions = fileActionsEnumParsed.list.translated;

        if (fileShuffleDefinitionId) {
            vm.setIsViewing();

            getFileShuffleDefinition(true);
        }
        else {
            vm.setIsAdding();
            vm.fileShuffleDefinition = dataflowService.dataContext.fileShuffleDefinitions.add({
                VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId
            });
        }

        // get reference of submitaction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parents = vm.submitAction;
        vm.submitAction = function () {
            parents()
                .then(() => {
                    vm.IsSaved = true;
                });
        }

        function getFileShuffleDefinition(forceReload) {
            var filter = { FileShuffleDefinitionId: fileShuffleDefinitionId };
            var defer = $q.defer();

            dataflowService.getFileShuffleDefinition(filter, forceReload)
                .then(function (data) {
                    vm.fileShuffleDefinition = data.results[0];
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    fileShuffleDefinitionDetail.$inject = injectParams;
    fileShuffleDefinitionDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('fileShuffleDefinitionDetail', fileShuffleDefinitionDetail);
});