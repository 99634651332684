﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'biService', 'utilService', 'cmsService', 'checkStateChangeService', 'navigationService', 'contextService'];
    var biDataDefinitionGroupsList = function ($q, $scope, $timeout, $injector, biService, utilService, cmsService, checkStateChangeService, navigationService, contextService) {
        var vm = this;
        nbsVm.call(vm, biService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return biDataDefinitionGroupSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, biService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        ribbon.addTab(new ribbon.Tab("ActionPanelTabManage", [ribbon.GetSchedulingSection()]));
        vm.ribbonDatacontext = ribbon;

        var biDataDefinitionGroupSelected = null;

        // at moment not exists detail view
        ribbon.setRibbonStatusButton(ribbon.Buttons.New, false);
        ribbon.refreshStatusButtons();
        
        vm.currentBiDataDefinitionGroups = [];

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('bidatadefinitiongroup', null);
        nbsGridDatacontext.viewCommonBar();
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getBiDataDefinitionGroups(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.currentBiDataDefinitionGroups = results[0];

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.currentBiDataDefinitionGroups
                    }
                });
        }

        vm.gridSelection = function (args) {
            $timeout(() => {
                biDataDefinitionGroupSelected = args.data;

                if (biDataDefinitionGroupSelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.RunScheduling, true);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.AddScheduledTask, true);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.RunScheduling, false);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.AddScheduledTask, false);
                }

                ribbon.refreshStatusButtons();
            });
        };

        vm.refreshAction = function () {
            loadData(true);
        };

        function getBiDataDefinitionGroups(forceReload) {
            var defer = $q.defer();

            biService.getBiDataDefinitionGroups({}, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        vm.runAction = function () {
            if (!biDataDefinitionGroupSelected) {
                return;
            }

            vm.biDataRequest = biService.dataContext.biDataRequests.add({
                BiDataDefinitionGroupId: biDataDefinitionGroupSelected.BiDataDefinitionGroupId,
                BiSourceGroupName: biDataDefinitionGroupSelected.BiDataDefinitionGroupName,
                BiDate: new Date(Date.now()),
                VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId
            });
            vm.modalInstance = navigationService.modal().open(vm.modalOptions({
                windowClass: vm.NBS_MODAL_CLASS_FORM_MD,
                templateUrl: 'biRunTemplate',
                scope: $scope
            }));
            vm.modalInstance.result.then(function () {
                vm.submitAction()
                    .then(function (saveResult) {
                        //var test = saveResult;
                        if (vm.biDataRequest.IsBatch) {
                            contextService.addBatchRequest({
                                requestId: vm.biDataRequest.BatchRequestId,
                                requestName: vm.biDataRequest.BatchRequestName,
                                requestStatus: 'inserted'
                            });
                        }
                    })
                    .catch(function (error) {

                        vm.setErrorMsg(error);
                    });
            }, function () {
                //dismiss
                biService.rejectChanges();
            });
        };

        vm.modalRunOk = function () {
            vm.modalInstance.close();
        };

        vm.modalRunCancel = function () {
            vm.modalInstance.dismiss();
        };

        vm.scheduledTaskAction = function () {
            if (!biDataDefinitionGroupSelected) {
                return;
            }

            var services = { navigationService: navigationService, utilService: utilService, cmsService: cmsService };
            var wzNbsManager = new NbsWizardManager($q, vm.translate, services, NbsGuidManager);
            var config = new wzNbsManager.WzConfig_AddScheduledTask('bidatarequest');
            config.defaultData.Code = biDataDefinitionGroupSelected.BiDataDefinitionGroupCode;
            config.defaultData.Name = biDataDefinitionGroupSelected.BiDataDefinitionGroupName;
            wzNbsManager.setWzConfig(config);

            wzNbsManager.open()
                .then(function (result) {
                    var scheduledTaskResult = result;

                    var biDataRequest = biService.dataContext.biDataRequests.add({
                        BiDataDefinitionGroupId: biDataDefinitionGroupSelected.BiDataDefinitionGroupId,
                        BiDate: new Date(Date.now()),
                        VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId
                    });

                    biDataRequest.SchedulingRequestCode = scheduledTaskResult.Code;
                    biDataRequest.BiSourceGroupName = scheduledTaskResult.Name;
                    var dateForUtcTime = moment(Nembus.Common.Library.converter.timespan.jsonToDate(scheduledTaskResult.Time)).add(-moment().utcOffset(), 'minutes').toDate();
                    biDataRequest.TaskTime = Nembus.Common.Library.converter.timespan.dateToJson(dateForUtcTime); //SET UTC TIME //scheduledTaskResult.Time; 
                    biDataRequest.TaskCronExpression = scheduledTaskResult.CronExpression;
                    biDataRequest.IsScheduledTask = true;

                    vm.submitAction()
                        .then(function (result) {
                            // success
                        }).catch(function (error) {
                            // error
                            vm.setErrorMsg(error);
                            //sflService.rejectChanges();
                        });
                });
        };
    };

    biDataDefinitionGroupsList.$inject = injectParams;
    biDataDefinitionGroupsList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('biDataDefinitionGroupsList', biDataDefinitionGroupsList);
});