﻿'use strict';
define([], function () {

    var injectParams = ['$q', '$injector', 'contextService', 'cmsService', 'fileTransferService'];

    function bootService($q, $injector, contextService, cmsService, fileTransferService) {
        var $http;
        var service = {
            checkContext: checkContext
        };
        return service;

        function checkContext(forceReload) {
            var defer = $q.defer();
            contextService.getCurrentVirtualContext(forceReload)
                .then(function (result) {
                    var funcsLocalPromises = [];
                    var funcsPromises = [];
                    //add html cache language
                    window.nbs_languageCache = result.CurrentLanguageId;
                    //contextService.persistContext();
                    //check navigation node cache
                    //funcsLocalPromises.push(contextService.getNavNodesLocal());
                    funcsLocalPromises.push(getNavNodes());
                    funcsLocalPromises.push(contextService.getCmsClientDataLocal());

                    $q.all(funcsLocalPromises)
                        .then(function (localPromiseResults) {
                            var localNavNodes = localPromiseResults[0];
                            var localCmsClientData = localPromiseResults[1];
                            //if (localNavNodes) {
                            //cmsService.setNodesString(localNavNodes);
                            //cmsService.manageNavNodes(cmsService.dataContext.navigationNodes.get());
                            //}
                            //else {
                                //funcsPromises.push(getNavNodes());
                            //}
                            if (localCmsClientData) {
                                contextService.setAppCmsClientData(localCmsClientData);
                            }
                            else {
                                funcsPromises.push(downloadCmsData());
                            }
                            if (funcsPromises.length > 0) {
                                $q.all(funcsPromises)
                                    .then(function () {
                                        defer.resolve();
                                    })
									.catch(function (error) {
										console.log(error);
										defer.reject(error);
										//authenticationService.redirectToLogin();
										//alert("todo context error")
									});
                            }
                            else {
                                defer.resolve();
                            }
                        });
                })
                .catch(function (error) {
                    console.log(error);
                    defer.reject(error);
                    //authenticationService.redirectToLogin();
                    //alert("todo context error")
                });

            return defer.promise;
        }
        function getNavNodes() {
            var defer = $q.defer();
            cmsService.getNavigationNodes()
                .then(function (data) {
                    //contextService.setNavNodesLocal(cmsService.getNodesString(data.results));
                    cmsService.manageNavNodes(data.results);
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    console.log('source:authInterceptorService - cmsService.getNavigationNodes() error:' + error);
                    defer.reject(error);
                });
            return defer.promise;
        }
        function downloadCmsData() {
            var defer = $q.defer();
            fileTransferService.downloadCmsClientDataFile()
                .then(function (response) {
                    contextService.setAppCmsClientData(response.data);
                    contextService.setCmsClientDataLocal(response.data)
                        .then(function (result) {
                            defer.resolve();
                        });                    
                })
                .catch(function (error) {
                    //alert(error);
                    console.log('source:authInterceptorService - fileTransferService.downloadCmsClientDataFile() error:' + error);
                    defer.reject(error);
                });
            return defer.promise;
        }
    }

    bootService.$inject = injectParams;
    angular.module('app').service('bootService', bootService);

});