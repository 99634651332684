﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'productionService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function calendarTemplatesList($q, $scope, $timeout, $injector, productionService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, productionService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return calendarTemplateSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, productionService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var calendarTemplateSelected = null;
        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var wCalendarParsedEnum = Nembus.Common.Library.util.parseEnum(productionService.dataContext.enumTypes.WCalendarKind, 'WCalendar', vm.translate);

        vm.calendarTemplates = [];

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('calendartemplate', null);
        nbsGridDatacontext.viewCommonBar();
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getCalendarTemplates(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    Lazy(results[0])
                        .each((item) => {
                            item.WCalendarKindName = wCalendarParsedEnum.obj.translated.value_name[item.WCalendarKindId];
                        });

                    vm.calendarTemplates = results[0];

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.calendarTemplates
                    }
                });
        }

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.calendartemplatedetail", { 'calendarId': args.data.WCalendarId });
        };

        vm.newAction = function () {
            navigationService.navigateTo("app.nav.calendartemplatedetail", { 'calendarId': null });
        };

        vm.refreshAction = function () {
            loadData(true);
        };

        vm.deleteAction = function () {
            if (!calendarTemplateSelected) {
                return;
            }

            productionService.deleteEntity(calendarTemplateSelected);
            vm.submitAction()
                .then(function (saveResult) {
                    vm.refreshAction();
                });
        };

        vm.gridSelection = function (args) {
            $timeout(() => {
                calendarTemplateSelected = args.data;

                if (calendarTemplateSelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, true);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                }

                ribbon.refreshStatusButtons();
            });
        };

        function getCalendarTemplates(forceReload) {
            var filter = { AppStoreBw: appStoreBw };
            var defer = $q.defer();

            productionService.getCalendarTemplates(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    }

    calendarTemplatesList.$inject = injectParams;
    calendarTemplatesList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('calendarTemplatesList', calendarTemplatesList);
});