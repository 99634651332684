﻿'use strict';

define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'activityService', 'utilService', 'navigationService', 'contextService'];
    function wzAddActivityStepActivity($q, $scope, $timeout, $injector, activityService, utilService, navigationService, contextService) {
        var vm = this;
        nbsVm.call(vm, activityService, navigationService, contextService, $q, $injector, $timeout);

        var viewKind = navigationService.stateParams().viewKind;
        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var activityKindEnum = Nembus.Common.Library.util.parseEnum(activityService.dataContext.enumTypes.ActivityKind, null, vm.translate).obj.normal.name_value;
        var activityKindId = activityKindEnum[viewKind];

        var pParams = navigationService.stateParamsModal().parentParams;
        pParams = pParams ? pParams : navigationService.stateParams();

        vm.gridIsMultiselect = true;

        // navigation buttons visibility
        vm.setWizardButtonVisibility(pParams);

        // beardcrumb information
        var beardCrumbInfo = JSON.parse(pParams.beardCrumbInfo);
        Lazy(beardCrumbInfo.items)
            .each((item) => {
                item.title = vm.translate(item.title);
            })
        vm.beardCrumbInfo = beardCrumbInfo;

        var effortUnitEnumParsed = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.EffortUnit, null, vm.translate);

        vm.currentDatasDm = [];

        loadData(false);
        function loadData(forceReload) {
            var supportData = { effortUnitEnumParsed: effortUnitEnumParsed };
            var ejDataManager = activityService.getActivityDataManager(supportData, { AppStoreBw: appStoreBw, ActivityKindId: activityKindId }, ejDataManager);

            var selectedIds = [];
            if (activityService.wizardDependActivityCollector && Lazy(activityService.wizardDependActivityCollector.activityIds).some()) {
                selectedIds = activityService.wizardDependActivityCollector.activityIds;
            }

            var nbsGridDatacontext = new NbsGrid(vm.translate);
            nbsGridDatacontext.configureGrid('activity', 'wizard');
            nbsGridDatacontext.setSelectedIds(selectedIds);
            nbsGridDatacontext.setModalMode();
            nbsGridDatacontext.setIsMultiselect();
            nbsGridDatacontext.setDatasource(ejDataManager, true);
            vm.nbsGridDatacontext = nbsGridDatacontext;
        }

        vm.gridSelection = function (args) {
            activityService.wizardDependActivityCollector.activityIds = args.dataIds;
        };

        vm.modalNext = function () {
            $timeout(() => {
                vm.alertConfig.isModal = true;
                vm.alertConfig.isReadonly = true;
                vm.removeAlertFromList(true);

                if (!Lazy(activityService.wizardDependActivityCollector.activityIds).some()) {
                    vm.setErrorMsg({ isMessageVisible: true, message: vm.translate('SelectAtLeastOne') });
                    return;
                }

                // close modal and returns client data object
                var modalResult = getModalResult(navigationService.navigationActionTypes.modalNext);
                navigationService.closeCurrentModal(modalResult);
            });
        };

        vm.modalCancel = function () {
            navigationService.confirmExitWizard()
                .then(function (result) {
                    if (result) {
                        var modalResult = getModalResult(navigationService.navigationActionTypes.modalCancel);
                        navigationService.closeCurrentModal(modalResult);
                    }
                });
        };

        function getModalResult(dialogResult) {
            var modalResult = new navigationService.modalResult()
            modalResult.data = activityService.wizardDependActivityCollector;
            modalResult.sourceParams = pParams;
            modalResult.dialogResult = dialogResult;
            return modalResult;
        }
    };

    wzAddActivityStepActivity.$inject = injectParams;
    wzAddActivityStepActivity.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('wzAddActivityStepActivity', wzAddActivityStepActivity);
});