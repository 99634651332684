﻿
'use strict';
define(['../../common/services/nbs-service'], function (nbsService) {

    var injectParams = ['$http', '$q', '$timeout', 'breezeDataLayer'];

    var foundationService = function ($http, $q, $timeout, breezeDataLayer) {

        var dataLayer = breezeDataLayer.Initialize('foundation', true);
        var manager = dataLayer.GetManager();
        var ejDataManager = dataLayer.GetEjDataManager();
        var entityTypes = {
            CategoryDto: 'CategoryDto',
            Company: 'Company',
            VirtualCompany: 'VirtualCompany',
            Address: 'Address',
            Contact: 'Contact',
            ModuleTarget: 'ModuleTarget',
            HrLegalGenerality: 'HrLegalGenerality',
            Avatar: 'Avatar',
            PersonGenerality: 'PersonGenerality',
            PersonJobData: 'PersonJobData',
            PersonJobJournal: 'PersonJobJournal',
            HrMetadata: 'HrMetadata',
            VirtualHumanResource: 'VirtualHumanResource',
            VirtualHumanResourceDto: 'VirtualHumanResourceDto',
            HrMetadataM2MCategoryDto: 'HrMetadataM2MCategoryDto',
            HrSingleXHrBucketDto: 'HrSingleXHrBucketDto',
            VirtualEquipment: 'VirtualEquipment',
            VirtualEquipmentDto: 'VirtualEquipmentDto',
            EquipmentGenerality: 'EquipmentGenerality',
            VhrAppStoresDto: 'VhrAppStoresDto',
            FileBox: 'FileBox',
            MagicBucketRequest: 'MagicBucketRequest', //also managed in hrbucket-service,bom-service
            ViewPage: 'ViewPage',
            CmsView: 'CmsView',
            CmsViewDto: 'CmsViewDto',
            CmsViewUserSetting: 'CmsViewUserSetting',
            VirtualCompanyCreationRequest: 'VirtualCompanyCreationRequest',
            Workflow: 'Workflow',
            WorkflowStatus: 'WorkflowStatus',
            TargetDto: 'TargetDto',
            CategoryTextPathDto: 'CategoryTextPathDto',
            CategoryRequest: 'CategoryRequest',
            VhrAccounter: 'VhrAccounter',
            VhrAccounterDto: 'VhrAccounterDto',
            VhrGroup: 'VhrGroup',
            VhrType: 'VhrType',
            AssetTree: 'AssetTree',
            AssetTreeDto: 'AssetTreeDto',
            BatchRequestDto: 'BatchRequestDto',
            ValidationSchemaDefinitionGroup: 'ValidationSchemaDefinitionGroup',
            ValidationSchemaDefinition: 'ValidationSchemaDefinition',
            ValidationSchemaInstance: 'ValidationSchemaInstance',
            ValidationSchemaInstanceField: 'ValidationSchemaInstanceField',
            ValidationSchemaInstanceDto: 'ValidationSchemaInstanceDto',

            // client validation
            CategoryReportFormClientData: 'CategoryReportFormClientData',
            AuthAuditClientData: 'AuthAuditClientData',
            AuthAudit: 'AuthAudit'
        };
        var me = this;

        //CategoryReportFormClientData
        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.CategoryReportFormClientData,
                dataProperties: {
                    Id: { type: breeze.DataType.Guid },
                    CategoryId: { type: breeze.DataType.Int32 }
                }
            },
            'Core.Domain'
        );

        //AuthAuditClientData
        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.AuthAuditClientData,
                dataProperties: {
                    Id: { type: breeze.DataType.Guid },
                    XCentralUserAuditId: { type: breeze.DataType.Int32 },
                    UserName: { type: breeze.DataType.String },
                    AuthTs: { type: breeze.DataType.DateTime },
                    UserAgent: { type: breeze.DataType.String }
                }
            },
            'Global'
        );

        function categoryReportFormClientDataCategoryIdValidationFn(value, context) { if (!context.entity.CategoryId) { return false; } else { return true; } }
        var categoryReportFormClientDataCategoryIdValidator = new breeze.Validator('selectAlmostOneItemValidator', categoryReportFormClientDataCategoryIdValidationFn);

        var categortyReportFormClientData = manager.metadataStore.getEntityType(entityTypes.CategoryReportFormClientData);
        categortyReportFormClientData.getProperty("CategoryId").validators.push(categoryReportFormClientDataCategoryIdValidator);

        //#endregion

        this.entityTypes = entityTypes;

        nbsService.call(this, manager, $q, $timeout);

        this.dataContext = {
            authAudits: dataLayer.setContextMethods(manager, nbsService, entityTypes.AuthAuditClientData),
            batchRequestDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.BatchRequestDto),
            virtualCompanyDefaultCompanyClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.VirtualCompanyDefaultCompanyClientData),
            categoryRequests: dataLayer.setContextMethods(manager, nbsService, entityTypes.CategoryRequest),
            virtualCompanyCreationRequests: dataLayer.setContextMethods(manager, nbsService, entityTypes.VirtualCompanyCreationRequest),
            magicBucketRequests: dataLayer.setContextMethods(manager, nbsService, entityTypes.MagicBucketRequest),
            categoryTextPathDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.CategoryTextPathDto),
            targetDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.TargetDto),
            workflows: dataLayer.setContextMethods(manager, nbsService, entityTypes.Workflow),
            workflowStatuses: dataLayer.setContextMethods(manager, nbsService, entityTypes.WorkflowStatus),
            companies: dataLayer.setContextMethods(manager, nbsService, entityTypes.Company),
            vhrAppStores: dataLayer.setContextMethods(manager, nbsService, entityTypes.VhrAppStoresDto),
            virtualCompanies: dataLayer.setContextMethods(manager, nbsService, entityTypes.VirtualCompany),
            categories: dataLayer.setContextMethods(manager, nbsService, entityTypes.CategoryDto),
            categoriesSubTreeFromVhrCats: dataLayer.setContextMethods(manager, nbsService, entityTypes.CategoryDto),
            addresses: dataLayer.setContextMethods(manager, nbsService, entityTypes.Address),
            contacts: dataLayer.setContextMethods(manager, nbsService, entityTypes.Contact),
            hrSingleXHrBucketDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.HrSingleXHrBucketDto),
            moduleTargets: dataLayer.setContextMethods(manager, nbsService, entityTypes.ModuleTarget),
            legalGeneralities: dataLayer.setContextMethods(manager, nbsService, entityTypes.HrLegalGenerality),
            avatars: dataLayer.setContextMethods(manager, nbsService, entityTypes.Avatar),
            searchAvatars: dataLayer.setContextMethods(manager, nbsService, entityTypes.Avatar),
            personGeneralities: dataLayer.setContextMethods(manager, nbsService, entityTypes.PersonGenerality),
            personJobData: dataLayer.setContextMethods(manager, nbsService, entityTypes.PersonJobData),
            personJobJournal: dataLayer.setContextMethods(manager, nbsService, entityTypes.PersonJobJournal),
            hrmetadata: dataLayer.setContextMethods(manager, nbsService, entityTypes.HrMetadata),
            virtualHumanResources: dataLayer.setContextMethods(manager, nbsService, entityTypes.VirtualHumanResource),
            hrMetadataM2MCategory: dataLayer.setContextMethods(manager, nbsService, entityTypes.HrMetadataM2MCategoryDto),
            virtualEquipments: dataLayer.setContextMethods(manager, nbsService, entityTypes.VirtualEquipment),
            virtualEquipmentDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.VirtualEquipmentDto),
            searchVirtualEquipments: dataLayer.setContextMethods(manager, nbsService, entityTypes.VirtualEquipment),
            equipmentGeneralities: dataLayer.setContextMethods(manager, nbsService, entityTypes.EquipmentGenerality),
            fileBoxes: dataLayer.setContextMethods(manager, nbsService, entityTypes.FileBox),
            cmsViews: dataLayer.setContextMethods(manager, nbsService, entityTypes.CmsView),
            cmsViewDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.CmsViewDto),
            cmsViewUserSettings: dataLayer.setContextMethods(manager, nbsService, entityTypes.CmsViewUserSetting),
            viewPages: dataLayer.setContextMethods(manager, nbsService, entityTypes.ViewPage),
            vhrAccounters: dataLayer.setContextMethods(manager, nbsService, entityTypes.VhrAccounter),
            vhrAccounterDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.VhrAccounterDto),
            vhrGroups: dataLayer.setContextMethods(manager, nbsService, entityTypes.VhrGroup),
            vhrTypes: dataLayer.setContextMethods(manager, nbsService, entityTypes.VhrType),
            assetTrees: dataLayer.setContextMethods(manager, nbsService, entityTypes.AssetTree),
            assetTreeDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.AssetTreeDto),
            validationSchemaInstances: dataLayer.setContextMethods(manager, nbsService, entityTypes.ValidationSchemaInstance),
            validationSchemaInstanceDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.ValidationSchemaInstanceDto),
            validationSchemaInstanceFields: dataLayer.setContextMethods(manager, nbsService, entityTypes.ValidationSchemaInstanceField),
            validationSchemaDefinitions: dataLayer.setContextMethods(manager, nbsService, entityTypes.ValidationSchemaDefinition),
            validationSchemaDefinitionGroups: dataLayer.setContextMethods(manager, nbsService, entityTypes.ValidationSchemaDefinitionGroup),

            // client entities
            categoryReportFormClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.CategoryReportFormClientData),


            enumTypes: {
                ContactKind: dataLayer.GetEnumType('ContactKind'),
                AddressKind: dataLayer.GetEnumType('AddressKind'),
                ResourceType: dataLayer.GetEnumType('ResourceType'),
                ResourcePrototype: dataLayer.GetEnumType('ResourcePrototype'),
                HrGroupBehavior: dataLayer.GetEnumType('HrGroupBehavior'),
                FileBoxType: dataLayer.GetEnumType('FileBoxType'),
                MagicBucketAction: dataLayer.GetEnumType('MagicBucketAction'),
                TemplateKind: Lazy(dataLayer.GetEnumType('TemplateKind')).reject({ value: "0" }).toArray(),     // "None" item removed
                //BiSpan: Lazy(dataLayer.GetEnumType('BiSpan')).reject({ value: "0" }).toArray(),     // "All" item removed
                BiSpan: dataLayer.GetEnumType('BiSpan'),
                CategoryRequestStatus: dataLayer.GetEnumType('CategoryRequestStatus'),
                CategoryRequestType: dataLayer.GetEnumType('CategoryRequestType'),
                CategoryTextPathDtoParamStartFrom: dataLayer.GetEnumType('CategoryTextPathDtoParamStartFrom'),
                VhrStatus: dataLayer.GetEnumType('VhrStatus'),
                ValidationSchemaFieldKind: dataLayer.GetEnumType('ValidationSchemaFieldKind'),
                BucketClassification: dataLayer.GetEnumType('BucketClassification'),
                CustomerStorageType: dataLayer.GetEnumType('CustomerStorageType'),
                BatchRequestStatus: Lazy(dataLayer.GetEnumType('BatchRequestStatus')).reject({ value: "0" }).toArray(),
                BatchRequestType: dataLayer.GetEnumType('BatchRequestType'),
                ExpressionType: dataLayer.GetEnumType('ExpressionType'),
                VhrAccounterKind: Lazy(dataLayer.GetEnumType('VhrAccounterKind')).reject({ value: "0" }).toArray(),
                CategoryKind: dataLayer.GetEnumType('CategoryKind'),
                CmsViewUserSettingType: dataLayer.GetEnumType('CmsViewUserSettingType')
            },

            consts: {
                CATEGORYKINDS: { folder: 1, category: 2 }
            }
        };

        this.createQuery = function (entityName) {
            return dataLayer.CreateQuery(entityName);
        };

        // CategoryRequest
        this.getCategoryRequest = function (filter, forceReload) {
            var params = { entityType: entityTypes.CategoryRequest, cache: this.dataContext.categoryRequests.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "CategoryRequest");
        };

        this.getCategoriesSubTreeOutsideAuthorization = function (filter, forceReload) {
            var params = { entityType: entityTypes.CategoryDto, cache: this.dataContext.categories.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "CategoriesSubTreeOutsideAuthorization");
        };

        this.getCategoriesSubTreeFromVhrCats = function (filter, forceReload) {
            var params = { entityType: entityTypes.CategoryDto, cache: this.dataContext.categoriesSubTreeFromVhrCats.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "CategoriesSubTreeFromVhrCats");
        };

        // VhrAccounter
        this.getVhrAccounters = function (filter, forceReload) {
            var params = { entityType: entityTypes.VhrAccounter, cache: this.dataContext.vhrAccounters.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VhrAccounter");
        };

        // VhrAccounterDto
        this.getVhrAccounterDtos = function (filter, forceReload) {
            var params = { entityType: entityTypes.VhrAccounterDto, cache: this.dataContext.vhrAccounterDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VhrAccounterDto");
        };

        // VhrGroup
        this.getSystemVhrGroups = function (filter, forceReload) {
            var params = { entityType: entityTypes.VhrGroup, cache: this.dataContext.vhrGroups.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VhrGroupSystem");
        };
        this.getVhrGroups = function (filter, forceReload) {
            var params = { entityType: entityTypes.VhrGroup, cache: this.dataContext.vhrGroups.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VhrGroup");
        };
        this.getVhrGroup = function (filter, forceReload) {
            var params = { key: filter.VhrGroupId, dataContext: this.dataContext.vhrGroups };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "VhrGroup");
        };

        // VhrType
        this.getSystemVhrTypes = function (filter, forceReload) {
            var params = { entityType: entityTypes.VhrType, cache: this.dataContext.vhrTypes.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VhrTypeSystem");
        };
        this.getVhrTypes = function (filter, forceReload) {
            var params = { entityType: entityTypes.VhrType, cache: this.dataContext.vhrTypes.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VhrType");
        };
        this.getVhrType = function (filter, forceReload) {
            var params = { key: filter.VhrTypeId, dataContext: this.dataContext.vhrTypes };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "VhrType");
        };

        // VirtualEquipmentDto
        this.getVirtualEquipmentDtos = function (filter, forceReload) {
            var params = { entityType: entityTypes.VirtualEquipmentDto, cache: this.dataContext.virtualEquipmentDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VirtualEquipmentDtos");
        };
        this.getVirtualEquipmentDto = function (filter, forceReload) {
            var params = { key: filter.VirtualHumanResourceId, dataContext: this.dataContext.virtualEquipmentDtos };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "VirtualEquipmentDtos");
        };

        // Workflow
        this.getWorkflow = function (filter, forceReload) {
            var params = { key: filter.WorkflowId, dataContext: this.dataContext.workflows };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "Workflow");
        };

        // Categories
        this.getHrBucketXCategories = function (filter, forceReload) {
            var params = { entityType: entityTypes.HrMetadataM2MCategoryDto, cache: this.dataContext.hrMetadataM2MCategory.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "HrBucketXCategories");
        };

        this.getHrSingleXCategories = function (filter, forceReload) {
            var params = { entityType: entityTypes.HrMetadataM2MCategoryDto, cache: this.dataContext.hrMetadataM2MCategory.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "HrSingleXCategories");
        };

        // HrSingleXHrBucket
        this.getHrSingleXHrBuckets = function (filter, forceReload) {
            var params = { entityType: entityTypes.HrSingleXHrBucketDto, cache: this.dataContext.hrSingleXHrBucketDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "HrSingleXHrBuckets");
        };

        // target dto
        this.getTargetDto = function (filter, forceReload) {
            var params = { key: filter.VirtualHumanResourceId, dataContext: this.dataContext.targetDtos };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "TargetDto");
        };
        this.getTargetDtos = function (filter, forceReload) {
            var params = { entityType: entityTypes.TargetDto, cache: this.dataContext.targetDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "TargetDtos");
        };

        this.getCategoryTextPathDtos = function (filter, forceReload) {
            var params = { entityType: entityTypes.CategoryTextPathDto, cache: this.dataContext.categoryTextPathDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "CategoryTextPathDto");
        };

        this.getPersonJobData = function (filter, forceReload) {
            var params = { key: filter.PersonJobData, dataContext: this.dataContext.personJobData };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "PersonJobData");
        };

        this.getAvatar = function (filter, forceReload) {
            var params = { key: filter.VirtualHumanResourceId, dataContext: this.dataContext.avatars };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "Avatars");
        };

        this.getAvatars = function (filter, forceReload) {
            var params = { entityType: entityTypes.Avatar, cache: this.dataContext.avatars.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "Avatars");
        };

        this.getPersonJobJournals = function (filter, forceReload) {
            var params = { entityType: entityTypes.PersonJobJournal, cache: this.dataContext.personJobJournal.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "PersonJobJournal");
        };

        this.getHrGroupM2MCategories = function (filter, forceReload) {
            var params = { entityType: entityTypes.HrGroupM2MCategoryDto, cache: this.dataContext.groupM2MCategories.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "HrGroupM2MCategories");
        };

        this.getHrGroupSynchro = function (filter, forceReload) {
            var params = { key: filter.HrGroupId, dataContext: this.dataContext.groupSynchroes };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "HrGroupSynchro");
        };

        this.getHrGroups = function (filter, forceReload) {
            var params = { entityType: entityTypes.HrGroup, cache: this.dataContext.groups.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "HrGroups");
        };

        this.getHrGroup = function (filter, forceReload) {
            var params = { key: filter.HrGroupId, dataContext: this.dataContext.groups };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "HrGroups");
        };

        this.getHrGroupGroups = function (filter, forceReload) {
            var params = { entityType: entityTypes.HrGroupGroup, cache: this.dataContext.groupGroups.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "HrGroupGroups");
        };

        this.getHrGroupGroup = function (filter, forceReload) {
            var params = { key: filter.HrGroupGroupId, dataContext: this.dataContext.groupGroups };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "HrGroupGroups");
        };

        this.getHrGroupsTargetActivities = function (filter, forceReload) {
            var params = { entityType: entityTypes.HrGroupM2MTargetActivityDto, cache: this.dataContext.hrGroupM2MTargetActivityDto.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "HrGroupM2MTargetActivity");
        };

        this.getHrGroupM2MTargetDto = function (filter, forceReload) {
            var params = { entityType: entityTypes.HrGroupM2MTargetDto, cache: this.dataContext.hrGroupM2MTargetDto.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "HrGroupM2MTargetDto");
        };

        this.getHrGroupsTargetActivities_VhrEventTypes = function (filter, forceReload) {
            var params = { entityType: entityTypes.HrGroupM2MTargetVhrEventTypeDto, cache: this.dataContext.hrGroupM2MTargetVhrEventTypeDto.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "HrGroupM2MTargetVhrEventTypeDto");
        };

        this.getHrGroupsTargetActivities_VhrEventGroups = function (filter, forceReload) {
            var params = { entityType: entityTypes.HrGroupM2MTargetVhrEventGroupDto, cache: this.dataContext.hrGroupM2MTargetVhrEventGroupDto.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "HrGroupM2MTargetVhrEventGroupDto");
        };


        this.getHrContact = function (filter, forceReload) {
            var params = { key: filter.ContactId, dataContext: this.dataContext.contacts };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "HrContact");
        };

        this.getHrLegalGenerality = function (filter, forceReload) {
            var params = { key: filter.LegalGeneralityId, dataContext: this.dataContext.legalGeneralities };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "HrLegalGenerality");
        };

        this.getCategories = function (filter, forceReload) {
            var params = { entityType: entityTypes.CategoryDto, cache: this.dataContext.categories.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "Category");
        };

        this.getCompanies = function (filter, forceReload) {
            var params = { entityType: entityTypes.Company, cache: this.dataContext.companies.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "Company");
        };

        this.getCompany = function (filter, forceReload) {
            var params = { key: filter.CompanyId, dataContext: this.dataContext.companies };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "Company");
        };

        this.getVirtualCompanies = function (filter, forceReload) {
            var params = { entityType: entityTypes.VirtualCompany, cache: this.dataContext.virtualCompanies.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VirtualCompany");
        };
        this.getVirtualCompany = function (filter, forceReload) {
            var params = { key: filter.VirtualCompanyId, dataContext: this.dataContext.virtualCompanies };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "VirtualCompany");
        };

        this.getVirtualEquipment = function (filter, forceReload) {
            var params = { key: filter.VirtualHumanResourceId, dataContext: this.dataContext.virtualEquipments };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "VirtualEquipment");
        };
        this.getVirtualEquipments = function (filter, forceReload) {
            var params = { entityType: entityTypes.VirtualEquipment, cache: this.dataContext.virtualEquipments.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VirtualEquipments");
        };

        this.getEquipmentGenerality = function (filter, forceReload) {
            var params = { key: filter.EquipmentGeneralityId, dataContext: this.dataContext.equipmentGeneralities };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "EquipmentGenerality");
        };
        this.getEquipmentGeneralities = function (filter, forceReload) {
            var params = { entityType: entityTypes.EquipmentGenerality, cache: this.dataContext.equipmentGeneralities.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "EquipmentGenerality");
        };

        this.getVhrAppStores = function (filter, forceReload) {
            var params = { entityType: entityTypes.VhrAppStoresDto, cache: this.dataContext.vhrAppStores.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VhrAppStore");
        };

        this.getFileBox = function (filter, forceReload) {
            var params = { key: filter.FileBoxId, dataContext: this.dataContext.fileBoxes };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "FileBox");
        };
        this.getFileBoxes = function (filter, forceReload) {
            var params = { entityType: entityTypes.FileBox, cache: this.dataContext.fileBoxes.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "FileBox");
        };

        //ViewPage
        this.getViewPages = function (filter, forceReload) {
            var params = { entityType: entityTypes.ViewPage, cache: this.dataContext.viewPages.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "ViewPage");
        };

        //CmsView
        this.getCmsViews = function (filter, forceReload) {
            var params = { entityType: entityTypes.CmsView, cache: this.dataContext.cmsViews.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "CmsView");
        };

        //CmsViewDto
        this.getCmsViewDtos = function (filter, forceReload) {
            var params = { entityType: entityTypes.CmsViewDto, cache: this.dataContext.cmsViewDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "CmsViewDto");
        };

        //CmsViewUserSetting
        this.getCmsViewUserSettings = function (filter, forceReload) {
            var params = { entityType: entityTypes.CmsViewUserSetting, cache: this.dataContext.cmsViewUserSettings.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "CmsViewUserSettings");
        };

        // AssetTree retrieved from view CoreAssetTrees
        this.getAssetTree = function (filter, forceReload) {
            var params = { key: filter.VirtualHumanResourceId, dataContext: this.dataContext.assetTrees };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "AssetTree");
        };
        this.getAssetTrees = function (filter, forceReload) {
            var params = { entityType: entityTypes.AssetTree, cache: this.dataContext.assetTrees.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "AssetTree");
        };

        this.getAssetTreeAncestors = function (filter, forceReload) {
            var params = { entityType: entityTypes.AssetTreeDto, cache: this.dataContext.assetTreeDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "AssetTreeAncestor");
        };

        // ValidationSchemaInstance
        this.getValidationSchemaInstance = function (filter, forceReload) {
            var params = { key: filter.ValidationSchemaInstanceId, dataContext: this.dataContext.validationSchemaInstances };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "ValidationSchemaInstance");
        };
        this.getValidationSchemaInstances = function (filter, forceReload) {
            var params = { entityType: entityTypes.ValidationSchemaInstance, cache: this.dataContext.validationSchemaInstances.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "ValidationSchemaInstance");
        };
        this.getValidationSchemaInstancePublish = function (filter, forceReload) {
            var params = { key: filter.ValidationSchemaInstanceId, dataContext: this.dataContext.validationSchemaInstances };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "ValidationSchemaInstancePublish");
        };
        this.getValidationSchemaInstancesPublish = function (filter, forceReload) {
            var params = { entityType: entityTypes.ValidationSchemaInstance, cache: this.dataContext.validationSchemaInstances.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "ValidationSchemaInstancePublish");
        };

        // ValidationSchemaInstanceField
        this.getValidationSchemaInstanceField = function (filter, forceReload) {
            var params = { key: filter.ValidationSchemaInstanceFieldId, dataContext: this.dataContext.validationSchemaInstanceFields };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "ValidationSchemaInstanceField");
        };
        this.getValidationSchemaInstanceFields = function (filter, forceReload) {
            var params = { entityType: entityTypes.ValidationSchemaInstanceField, cache: this.dataContext.validationSchemaInstances.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "ValidationSchemaInstanceField");
        };

        // c# code add "IsPublish = true" to filter
        this.getValidationSchemaInstanceDto = function (validationSchemaInstanceId, forceReload) {
            forceReload = true;

            var defer = $q.defer();
            var filter = { ValidationSchemaInstanceId: validationSchemaInstanceId };
            var params = { entityType: entityTypes.ValidationSchemaInstanceDto, cache: this.dataContext.validationSchemaInstanceDtos };
            var validationSchemaFieldKind = this.dataContext.enumTypes.ValidationSchemaFieldKind;

            var funcsPromises = [];
            funcsPromises.push(dataLayer.GetDataListPromise(params, filter, forceReload, "ValidationSchemaInstanceDto"));

            $q.all(funcsPromises)
                .then(function (data) {
                    var instanceDto = data[0].results[0];
                    var instanceDtoParsed = parseValidationSchemaInstanceDto(instanceDto, validationSchemaFieldKind);
                    defer.resolve(instanceDtoParsed);
                });

            return defer.promise;
        };

        // ValidationSchemaDefinition
        this.getValidationSchemaDefinition = function (filter, forceReload) {
            var params = { key: filter.ValidationSchemaDefinitionId, dataContext: this.dataContext.validationSchemaDefinitions };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "ValidationSchemaDefinition");
        };
        this.getValidationSchemaDefinitions = function (filter, forceReload) {
            var params = { entityType: entityTypes.ValidationSchemaDefinition, cache: this.dataContext.validationSchemaDefinitions.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "ValidationSchemaDefinition");
        };

        // AuthAudit
        this.getAuthAudits = function (filter, forceReload) {
            var params = { entityType: entityTypes.AuthAuditClientData, cache: this.dataContext.authAudits.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "AuthAudit");
        };

        // VirtualHumanResource
        this.getVirtualHumanResourceDto = function (filter, forceReload) {
            var params = { key: filter.VirtualHumanResourceId, dataContext: this.dataContext.virtualHumanResources };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "VirtualHumanResourceDto");
        };

        // ValidationSchemaDefinitionGroup
        this.getValidationSchemaDefinitionGroup = function (filter, forceReload) {
            var params = { key: filter.ValidationSchemaDefinitionGroupId, dataContext: this.dataContext.validationSchemaDefinitionGroups };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "ValidationSchemaDefinitionGroup");
        };
        this.getValidationSchemaDefinitionGroups = function (filter, forceReload) {
            var params = { entityType: entityTypes.ValidationSchemaDefinitionGroup, cache: this.dataContext.validationSchemaDefinitionGroups.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "ValidationSchemaDefinitionGroup");
        };

        //// BatchRequestDto (queue)
        //this.getBatchRequestDtoQueuePagedDm = function () {
        //    return ejDataManager.getEjManager('BatchRequestDtoQueuePaged');
        //};
        //this.getBatchRequestDtoQueuePagedDmQuery = function () {
        //    var dmObj = {};
        //    dmObj.dm = ejDataManager.getEjManager('');
        //    dmObj.query = ej.Query().from('BatchRequestDtoQueuePaged');
        //    return dmObj;
        //};

        // BatchRequestDto
        this.getBatchRequestDtoPagedDm = function () {
            return ejDataManager.getEjManager('BatchRequestDtoPaged');
        };
        this.getBatchRequestDtoPagedDmQuery = function () {
            var dmObj = {};
            dmObj.dm = ejDataManager.getEjManager('');
            dmObj.query = ej.Query().from('BatchRequestDtoPaged');
            return dmObj;
        };

        // AssetTree
        this.getAssetTreesPagedDm = function () {
            return ejDataManager.getEjManager('AssetTreePaged');
        };
        this.getAssetTreesPagedDmQuery = function () {
            var dmObj = {};
            dmObj.dm = ejDataManager.getEjManager('');
            dmObj.query = ej.Query().from('AssetTreePaged');
            return dmObj;
        };

        // AssetTreeGrid
        this.getAssetTreeGridsPagedDm = function () {
            return ejDataManager.getEjManager('AssetTreeGridPaged');
        };
        this.getAssetTreeGridsPagedDmQuery = function () {
            var dmObj = {};
            dmObj.dm = ejDataManager.getEjManager('');
            dmObj.query = ej.Query().from('AssetTreeGridPaged');
            return dmObj;
        };
        //#endregion

        //#region Paged
        // TargetDto
        this.getTargetDtoPagedDm = function () {
            return ejDataManager.getEjManager('TargetDtoPaged');
        };
        this.getTargetDtoPagedDmQuery = function () {
            var dmObj = {};
            dmObj.dm = ejDataManager.getEjManager('');
            dmObj.query = ej.Query().from('TargetDtoPaged');
            return dmObj;
        };

        // #############################################################################
        // VirtualEquipment (USE AssetTree instead of)
        //this.getVirtualEquipmentPagedDm = function () {
        //    return ejDataManager.getEjManager('VirtualEquipmentsPaged');
        //};
        //this.getVirtualEquipmentPagedDmQuery = function () {
        //    var dmObj = {};
        //    dmObj.dm = ejDataManager.getEjManager('');
        //    dmObj.query = ej.Query().from('VirtualEquipmentsPaged');
        //    return dmObj;
        //};

        //this.getSearchVirtualEquipmentsPagedDm = function () {
        //    return ejDataManager.getEjManager('SearchVirtualEquipmentPaged');
        //};
        //this.getSearchVirtualEquipmentsPagedDmQuery = function () {
        //    var dmObj = {};
        //    dmObj.dm = ejDataManager.getEjManager('');
        //    dmObj.query = ej.Query().from('SearchVirtualEquipmentPaged');
        //    return dmObj;
        //};

        //this.getVirtualEquipmentDtoPagedDm = function () {
        //    return ejDataManager.getEjManager('VirtualEquipmentDtosPaged');
        //};
        //this.getVirtualEquipmentDtoPagedDmQuery = function () {
        //    var dmObj = {};
        //    dmObj.dm = ejDataManager.getEjManager('');
        //    dmObj.query = ej.Query().from('VirtualEquipmentDtosPaged');
        //    return dmObj;
        //};
        //#endregion
        // #############################################################################


        //Http Post
        this.searchAvatars = function (filter) {
            var query = dataLayer.InvokeQuery("SearchAvatars", filter);
            return manager.executeQuery(query);
        };

        //Http Post
        this.searchFileBoxes = function (filter) {
            var query = dataLayer.InvokeQuery("SearchFileBoxes", filter);
            return manager.executeQuery(query);
        };

        //Http Post
        this.validationSchemaPublish = function (filter) {
            var query = dataLayer.InvokeQuery("ValidationSchemaPublish", filter);
            return manager.executeQuery(query);
        };

        //Http Post
        this.validationSchemaArchive = function (filter) {
            var query = dataLayer.InvokeQuery("ValidationSchemaArchive", filter);
            return manager.executeQuery(query);
        };

        //Http Post
        this.validationSchemaExtract = function (filter) {
            var query = dataLayer.InvokeQuery("ValidationSchemaExtract", filter);
            return manager.executeQuery(query);
        };

        //Http Post
        this.cloneTarget = function (filter) {
            var query = dataLayer.InvokeQuery("CloneTarget", filter);
            return manager.executeQuery(query);
        };

        //Http Post
        this.cloneVirtualEquipment = function (filter) {
            var query = dataLayer.InvokeQuery("CloneVirtualEquipment", filter);
            return manager.executeQuery(query);
        };

        //Http Post
        this.searchTargets = function (filter) {
            var query = dataLayer.InvokeQuery("SearchTargets", filter);
            return manager.executeQuery(query);
        };

        //Http Post
        this.cloneValidationSchemaInstance = function (filter) {
            var query = dataLayer.InvokeQuery("CloneValidationSchemaInstance", filter);
            return manager.executeQuery(query);
        };

        this.unbindVhr = function (virtualHumanResourceId) {
            var filter = { VirtualHumanResourceId: virtualHumanResourceId };
            var query = dataLayer.InvokeQuery("UnbindVhr", filter);
            return manager.executeQuery(query);
        };

        this.setCategoryPathStart = function (filter) {
            var query = dataLayer.InvokeQuery("SetCategoryPathStart", filter);
            return manager.executeQuery(query);
        };

        this.removeCategoryPathStart = function (filter) {
            var query = dataLayer.InvokeQuery("RemoveCategoryPathStart", filter);
            return manager.executeQuery(query);
        };

        //#region DataManager
        this.getTargetDataManager = function (supportData, filter, dmObj) {
            if (dmObj) {
                dmObj.complexParams = [{ filter: filter }];
                return dmObj;
            }

            dmObj = this.getTargetDtoPagedDmQuery();
            dmObj.dmgrid = this.getTargetDtoPagedDm();
            var ejDataManager = dmObj.dmgrid;

            ejDataManager.complexParams.push({ filter: filter });
            ejDataManager.fieldNameReplacer = {};
            // properties inserte are calculated before query is launched in breezeDataLayer service
            ejDataManager.onWhereCustomPredicate = {};
            ejDataManager.processResponseData = function (data) {
                if (data) {
                    dmObj.dm.currentViewChildGridData = [];

                    data.results = me.getAttachedEntities(data.results);
                    return data.results;
                }
            };
            ejDataManager.freeSearchFields = ['TargetCode', 'TargetName', 'TargetTypeName', 'TargetGroupName'];
            ejDataManager.freeSearchFieldsNumber = ['VirtualHumanResourceId'];

            dmObj.dm.onWhereCustomPredicate = ejDataManager.onWhereCustomPredicate;
            dmObj.dm.fieldNameReplacer = ejDataManager.fieldNameReplacer;
            dmObj.dm.complexParams = ejDataManager.complexParams;
            dmObj.dm.processResponseData = ejDataManager.processResponseData;
            return dmObj;
        }

        this.getAssetTreeDataManager = function (supportData, filter, dmObj) {
            if (dmObj) {
                dmObj.complexParams = [{ filter: filter }];
                return dmObj;
            }

            var resourceTypeEnumParsed = Nembus.Common.Library.util.parseEnum(me.dataContext.enumTypes.ResourceType, 'VirtualHumanResource', supportData.translate);
            var bucketClassificationEnum = Nembus.Common.Library.util.parseEnum(me.dataContext.enumTypes.BucketClassification, null, supportData.translate).obj.normal.name_value;

            dmObj = this.getAssetTreesPagedDmQuery();
            dmObj.dmgrid = this.getAssetTreesPagedDm();
            var ejDataManager = dmObj.dmgrid;

            ejDataManager.complexParams.push({ filter: filter });
            ejDataManager.fieldNameReplacer = {
                ResourceTypeName: 'ResourceTypeId',
            };
            // properties inserte are calculated before query is launched in breezeDataLayer service
            ejDataManager.onWhereCustomPredicate = {
                ResourceTypeName: {
                    FieldType: this.FilterMenu.FieldType.Enum,
                    ReplaceFieldNameWith: 'ResourceTypeId',
                    Datasource: resourceTypeEnumParsed.list.translated
                },
            };
            ejDataManager.processResponseData = function (data) {
                if (data) {
                    data.results = me.getAttachedEntities(data.results);
                    Lazy(data.results)
                        .each((item) => {
                            item.ResourceTypeName = resourceTypeEnumParsed.obj.translated.value_name[item.ResourceTypeId];
                            item.HrBucketUI = JSON.parse(Nembus.Common.Library.converter.decodeHtml(item.HrBucketsSerialized));
                            item.GenericLegalEntityUI = Lazy(item.HrBucketUI).where({ ExtraKey: bucketClassificationEnum.GenericLegalEntity.toString() }).toArray();
                            item.CostCenterUI = Lazy(item.HrBucketUI).where({ ExtraKey: bucketClassificationEnum.CostCenter.toString() }).toArray();
                            item.BusinessUnitUI = Lazy(item.HrBucketUI).where({ ExtraKey: bucketClassificationEnum.BusinessUnit.toString() }).toArray();
                            item.SectorUI = Lazy(item.HrBucketUI).where({ ExtraKey: bucketClassificationEnum.Sector.toString() }).toArray();
                            item.PlantUI = Lazy(item.HrBucketUI).where({ ExtraKey: bucketClassificationEnum.Plant.toString() }).toArray();
                        });
                    return data.results;
                }
            };
            ejDataManager.freeSearchFields = ['VirtualCode', 'VirtualName', 'VirtualEquipmentRegistrationNumber', 'VhrGroupName', 'VirtualEquipmentSerialNumber', 'VirtualEquipmentProducer', 'VirtualEquipmentModel', 'VhrTypeName'];
            ejDataManager.freeSearchFieldsNumber = ['VirtualHumanResourceId'];

            dmObj.dm.onWhereCustomPredicate = ejDataManager.onWhereCustomPredicate;
            dmObj.dm.fieldNameReplacer = ejDataManager.fieldNameReplacer;
            dmObj.dm.complexParams = ejDataManager.complexParams;
            dmObj.dm.processResponseData = ejDataManager.processResponseData;
            return dmObj;
        }

        this.getAssetTreegridDataManager = function (supportData, filter, dmObj) {
            if (dmObj) {
                dmObj.dmgrid.complexParams = [{ filter: filter }];
                return dmObj;
            }

            var resourceTypeEnum = Nembus.Common.Library.util.parseEnum(this.dataContext.enumTypes.ResourceType, 'VirtualHumanResource', supportData.translate);

            dmObj = this.getAssetTreeGridsPagedDmQuery();
            dmObj.dmgrid = this.getAssetTreeGridsPagedDm();
            var ejDataManager = dmObj.dmgrid;

            //add webapi params 
            ejDataManager.complexParams.push({ filter });

            ejDataManager.fieldNameReplacer = {};
            ejDataManager.onWhereCustomPredicate = {};
            ejDataManager.processResponseData = function (data) {
                Lazy(data.results)
                    .each((item) => {
                        item.ResourceTypeName = resourceTypeEnum.obj.translated.value_name[item.ResourceTypeId];
                    });
                return data.results;
            };
            ejDataManager.freeSearchFields = ['VirtualEquipmentSerialNumber', 'VirtualCode', 'VirtualName', 'VirtualEquipmentProducer', 'VirtualEquipmentModel', 'VirtualEquipmentRegistrationNumber'];
            ejDataManager.freeSearchFieldsNumber = ['VirtualHumanResourceId'];

            dmObj.dm.onWhereCustomPredicate = ejDataManager.onWhereCustomPredicate;
            dmObj.dm.fieldNameReplacer = ejDataManager.fieldNameReplacer;
            dmObj.dm.complexParams = ejDataManager.complexParams;
            dmObj.dm.processResponseData = ejDataManager.processResponseData;
            return dmObj;
        }

        this.getBatchRequestDataManager = function (supportData, filter, dmObj) {
            if (dmObj) {
                dmObj.complexParams = [{ filter: filter }];
                return dmObj;
            }

            var batchRequestStatusEnumParsed = Nembus.Common.Library.util.parseEnum(me.dataContext.enumTypes.BatchRequestStatus, 'BatchRequest', supportData.translate);

            dmObj = this.getBatchRequestDtoPagedDmQuery();
            dmObj.dmgrid = this.getBatchRequestDtoPagedDm();
            var ejDataManager = dmObj.dmgrid;

            ejDataManager.complexParams.push({ filter: filter });
            ejDataManager.fieldNameReplacer = {
                BatchRequestStatusName: 'BatchRequestStatusId'
            };
            // properties inserte are calculated before query is launched in breezeDataLayer service
            ejDataManager.onWhereCustomPredicate = {
                BatchRequestStatusName: {
                    FieldType: this.FilterMenu.FieldType.Enum,
                    ReplaceFieldNameWith: 'BatchRequestStatusId',
                    Datasource: batchRequestStatusEnumParsed.list.translated
                }
            };
            ejDataManager.processResponseData = function (data) {
                if (data) {
                    var batchRequests = Lazy(data.results)
                        .sortBy((item) => { return item.CreationDate; }, false)
                        .toArray();

                    var sort = 0;
                    Lazy(batchRequests)
                        .each((item) => {
                            if (item.BatchRequestStatusId === batchRequestStatusEnumParsed.obj.normal.name_value.Inserted) {
                                sort++;
                                item.BatchRequestOrderUI = sort;
                            } else {
                                item.BatchRequestOrderUI = '-';
                            }

                            item.BatchRequestStatusName = batchRequestStatusEnumParsed.obj.translated.value_name[item.BatchRequestStatusId];
                            item.CurrentUserIsUA = supportData.contextService.virtualContext.IsUa;

                            item.CreationDateUI = Nembus.Common.Library.converter.date.formatDateTime(item.CreationDate, supportData.contextService.virtualContext.cultureInfo, false);
                            item.ProcessStartDateUI = Nembus.Common.Library.converter.date.formatDateTime(item.ProcessStartDate, supportData.contextService.virtualContext.cultureInfo, false);
                            item.ProcessedDateUI = Nembus.Common.Library.converter.date.formatDateTime(item.ProcessedDate, supportData.contextService.virtualContext.cultureInfo, false);
                        });
                    return batchRequests;
                }
            };
            ejDataManager.freeSearchFields = ['BatchRequestName', 'VirtualHumanResourceName', 'VirtualCompanyName', 'ErrorDescription'];
            ejDataManager.freeSearchFieldsNumber = ['VirtualHumanResourceId'];

            dmObj.dm.onWhereCustomPredicate = ejDataManager.onWhereCustomPredicate;
            dmObj.dm.fieldNameReplacer = ejDataManager.fieldNameReplacer;
            dmObj.dm.complexParams = ejDataManager.complexParams;
            dmObj.dm.processResponseData = ejDataManager.processResponseData;
            return dmObj;
        }
        //#endregion

        function parseValidationSchemaInstanceDto(instanceDto, fieldKind) {
            // instanceDto {} => ValidationSchemaInstance
            // fieldKind (enum) => ValidationSchemaFieldKind
            var instanceRes = {
                AppStoreBw: instanceDto.AppStoreBw,
                CreationDate: instanceDto.CreationDate,
                FieldSerialized: instanceDto.FieldSerialized,
                IsPublish: instanceDto.IsPublish,
                IsSystem: instanceDto.IsSystem,
                LastChangeDate: instanceDto.LastChangeDate,
                PublishDate: instanceDto.PublishDate,
                ValidationSchemaDefinitionCode: instanceDto.ValidationSchemaDefinitionCode,
                ValidationSchemaDefinitionEntity: instanceDto.ValidationSchemaDefinitionEntity,
                ValidationSchemaDefinitionId: instanceDto.ValidationSchemaDefinitionId,
                ValidationSchemaDefinitionName: instanceDto.ValidationSchemaDefinitionName,
                ValidationSchemaInstanceCode: instanceDto.ValidationSchemaInstanceCode,
                ValidationSchemaInstanceId: instanceDto.ValidationSchemaInstanceId,
                ValidationSchemaInstanceName: instanceDto.ValidationSchemaInstanceName
            };

            var fields = JSON.parse(Nembus.Common.Library.converter.decodeHtml(instanceRes.FieldSerialized));

            Lazy(fieldKind)
                .each((item) => {
                    instanceRes[item.name] = {};

                    Lazy(fields)
                        .where({ ValidationSchemaFieldKindId: Number(item.value) })
                        .each((field) => {
                            var propertyValue = null;
                            if (field.PropertyType === 'System.Boolean') {
                                propertyValue = field.PropertyValue === 'true';
                            } else {
                                propertyValue = field.PropertyValue;
                            }

                            instanceRes[item.name][field.PropertyName] = propertyValue;
                        });
                });

            return instanceRes;
        };
    };

    foundationService.$inject = injectParams;
    foundationService.prototype = Object.create(nbsService.prototype);
    angular.module('nbs.shared.module').service('foundationService', foundationService);
});