﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$timeout', '$injector', 'metricService', 'foundationService', 'authenticationService', 'drbacService', 'rbacService', 'cmsService', 'navigationService', 'contextService'];
    function developerToolsDetail($q, $timeout, $injector, metricService, foundationService, authenticationService, drbacService, rbacService, cmsService, navigationService, contextService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        
        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext);
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        vm.auditSectionIsVisible = Lazy(contextService.virtualContext.ModuleTargets).where({ ModuleTargetCode: 'MTAUD' }).some();

        vm.synchroPermissionAllVirtualContext = function () {
            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        drbacService.synchroPermissionAllVirtualContext();
                    }
                });
        };

        // Add thesaurus wordkey not presents for languages (using default)
        vm.synchroThesaurus = function () {
            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        cmsService.synchroThesaurus();
                    }
                });

        };

        // Add (if not exists) notification templates for new languages (taking default template from instances)
        vm.synchroNotificationTemplates = function () {
            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        cmsService.synchroNotificationTemplates();
                    }
                });
        };

        vm.ssoSyncroUser = function () {
            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        rbacService.ssoVerifyUsersBulk();
                    }
                });
        };

        vm.supportSyncroUser = function () {
            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        rbacService.supportVerifyUsersBulk();
                    }
                });
        };

        vm.refreshToken = function () {
            authenticationService.refreshToken()
                .then(function () {

                    // call `$urlRouter.sync()` to get the route processed again
                    $urlRouter.sync();
                });
        };

        vm.updateAllXsdFiles = function () {
            cmsService.updateAllXsdFiles()
                .then(function () {

                    // call `$urlRouter.sync()` to get the route processed again
                    $urlRouter.sync();
                });
        };

        vm.persistNavigationModules = function () {
            cmsService.persistNavigationModules()
                .then(function () {
                    
                });
        };

        
        vm.synchroMetricSpecializationAllVirtualContext = function () {
            metricService.synchroMetricSpecializationAllVirtualContext()
                .then(function () {

                });
        };

        vm.setMetricDataUniqueKey = function () {
            metricService.setMetricDataUniqueKey()
                .then(function () {

                });
        };
        
    }

    developerToolsDetail.$inject = injectParams;
    developerToolsDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('developerToolsDetail', developerToolsDetail);
});