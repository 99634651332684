﻿
'use strict';
define(['../../common/services/nbs-service'], function (nbsService) {

    var injectParams = ['$http', '$q', '$timeout', 'breezeDataLayer'];

    var cmsDocService = function ($http, $q, $timeout, breezeDataLayer) {

        var dataLayer = breezeDataLayer.Initialize('cmsdoc', true);
        var manager = dataLayer.GetManager();

        var entityTypes = {
            ViewPageElementDto: 'ViewPageElementDto'
        };

        nbsService.call(this, manager, $q, $timeout);

        this.dataContext = {
            viewPageElements: {
                get: function () { return manager.getEntities(entityTypes.ViewPageElementDto); },
                getByKey: function (key) { return manager.getEntityByKey(entityTypes.ViewPageElementDto, key); }
            },
            enumTypes: {
                ViewPageElementType: dataLayer.GetEnumType('ViewPageElementType')
            }       
        };

        this.createQuery = function (entityName) {
            return dataLayer.CreateQuery(entityName);
        };

        this.getViewPageElements = function (filter, forceReload) {
            var params = { entityType: entityTypes.ViewPageElementDto, cache: this.dataContext.viewPageElements.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "ViewPageElementDto");
        };

        this.getViewPageElement = function (filter, forceReload) {
            var params = { key: filter.ViewPageElementId, dataContext: this.dataContext.viewPageElements };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "ViewPageElementDto");
        };

        //Http Post
        this.generateDocumentationFiles = function (languageId) {
            var query = dataLayer.InvokeQuery("GenerateDocumentationFiles", languageId);
            return manager.executeQuery(query);
        };
        this.getDocumentationCmsViewXmlData = function (filter) {
            var defer = $q.defer();
            var query = dataLayer.InvokeQuery("GetDocumentationCmsViewXmlData", filter);
            manager.executeQuery(query)
                .then(function (response) {
                var res = {};
                res.buffer = {data:response.results[0]};
                defer.resolve(res);
            })
                .catch(function (data, status, headers, config) {
                    return $q.reject(data);
                });
            return defer.promise;
        };
    };

    cmsDocService.$inject = injectParams;
    cmsDocService.prototype = Object.create(nbsService.prototype);
    angular.module('nbs.rds.module').service('cmsDocService', cmsDocService);
});