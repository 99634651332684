﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'utilService', 'vhreventService', 'foundationService', 'audService', 'reportService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var surveyKpiReportForm = function ($q, $scope, $timeout, $injector, utilService, vhreventService, foundationService, audService, reportService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, audService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, audService, { dirtyFlagIsDisabled: vm.isDirtyDisabled });

        // all form items are enabled
        vm.setIsEditingReport();

        var appStoreCode = 'aud';
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var expressionTypeEnum = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.ExpressionType, null, vm.translate).obj.normal.name_value;
        var ejDataManager = null;
        var selectedSurveys = [];

        var dsCallback = function () { return vhreventService.getVhrEventTypes({ AppStoreBw: appStoreBw }, false); };
        var selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventType', viewTitle: vm.translate('SurveyDto.VhrEventTypeId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventType = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalVhrEventType.setOnSelectFnCallback(refreshGridDatasource);

        dsCallback = function () { return vhreventService.getVhrEventGroups({ AppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventGroup', viewTitle: vm.translate('SurveyDto.VhrEventGroupId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventGroup = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalVhrEventGroup.setOnSelectFnCallback(refreshGridDatasource);

        dsCallback = function () { return foundationService.getVhrTypes(false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrType', viewTitle: vm.translate('TargetDto.TargetTypeId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrType = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalVhrType.setOnSelectFnCallback(refreshGridDatasource);

        dsCallback = function () { return foundationService.getVhrGroups(false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrGroup', viewTitle: vm.translate('TargetDto.TargetGroupId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrGroup = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalVhrGroup.setOnSelectFnCallback(refreshGridDatasource);

        vm.nbsAuthorDrbacModalObj = new NbsAssigneeSelectorModal({
            translator: vm.translate,
            suspendDirty: vm.suspendDirty,
            navigationService: navigationService,
            contextUserForSelection: null,
            getServerVarFn: Nembus.Common.Library.server.getServerVar
        });
        vm.nbsAuthorDrbacModalObj.viewUsers();
        vm.nbsAuthorDrbacModalObj.setOnSelectFnCallback(refreshGridDatasource);

        selectionParams = { destModalKey: 'vhr', entityMode: 'Target', translateKey: 'Target', isMultiselect: true };
        vm.nbsSelectionGridWithModalTarget = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalTarget.setOnSelectFnCallback(refreshGridDatasource);

        vm.sortFields = [];
        vm.sortFields.push({ value: 'SurveyAnalysisValue', name: vm.translate('Kpi') });
        vm.sortFields.push({ value: 'SurveyCompileDate', name: vm.translate('SurveyCompileDate') });
        vm.sortFields.push({ value: 'SurveyName', name: vm.translate('SurveyName') });

        vm.sortTypes = [];
        vm.sortTypes.push({ value: false, name: vm.translate('Ascending') });
        vm.sortTypes.push({ value: true, name: vm.translate('Descending') });

        // set title of current controller in report definition detail view
        navigationService.setControllerTitle();

        var currDate = new Date();
        var firstMonthDay = new Date(currDate.getFullYear(), currDate.getMonth(), 1);
        var lastMonthDay = new Date(currDate.getFullYear(), currDate.getMonth() + 1, 0);

        // defaults
        vm.currentForm = audService.dataContext.htmlToPdfSurveyKpiClientDatas.add({
            Id: NbsGuidManager.newGuid(),
            SurveyAnalysisDefinitionId: null, //'SurveyQuestionOption_CompletionPercentRequired',
            SurveyCompileDateSystem1: firstMonthDay,
            SurveyCompileDateSystem2: lastMonthDay,
            IsArchived: null
        });
        // defaults
        vm.currentForm.SortFieldId = 'SurveyAnalysisValue';
        vm.currentForm.SortTypeId = false;

        vm.gridSelection = function (args) {
            selectedSurveys = args.data;
        }

        var nbsGridDatacontext = new NbsAudGrid(vm.translate);
        nbsGridDatacontext.configureGrid('survey', 'surveykpireportform', null);
        nbsGridDatacontext.setAdaptToPage();
        nbsGridDatacontext.setIsMultiselect();
        nbsGridDatacontext.setDatasource([], false); // fake datasource, only to create grid
        vm.nbsGridDatacontext = nbsGridDatacontext;

        loadData(true);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getSurveyAnalysisDefinitions(forceReload));

            $q.all(funcsPromises)
                .then(function () {
                    reportService.currentReport.formEntityToValidate = vm.currentForm;
                    reportService.currentReport.prepareReportFilterCallback = setReportFilterData;

                    refreshGridDatasource();
                });
        }

        vm.filterOnChange = function () {
            refreshGridDatasource();
        }

        function refreshGridDatasource() {
            var filter = { //FilterSurveyClientData
                surveyFilter: {
                    IsArchived: vm.currentForm.IsArchived,
                    IsCompleted: true
                },
                surveySectionFilter: {},
                vhrEventFilter: {
                    ModuleTargetId: moduleTargetId,
                    VhrEventTypesId: Lazy(vm.nbsSelectionGridWithModalVhrEventType.selectedIds).some() ? vm.nbsSelectionGridWithModalVhrEventType.selectedIds : [],
                    VhrEventGroupsId: Lazy(vm.nbsSelectionGridWithModalVhrEventGroup.selectedIds).some() ? vm.nbsSelectionGridWithModalVhrEventGroup.selectedIds : [],
                    VirtualHumanResourcesId: Lazy(vm.nbsSelectionGridWithModalTarget.selectedIds).some() ? vm.nbsSelectionGridWithModalTarget.selectedIds : [],
                    HaveToExpandVhrsWithAssetTree: true
                },
                surveyVhrEventFilter: {
                    CompilerUserPersonIds: vm.nbsAuthorDrbacModalObj.appUserAreSelected() ? vm.nbsAuthorDrbacModalObj.appUserMultiselectSelectedIds() : [],
                    SurveyCompileDateSystem1: vm.currentForm.SurveyCompileDateSystem1,
                    SurveyCompileDateSystem1Expression: expressionTypeEnum.GreaterThanOrEqual,
                    SurveyCompileDateSystem2: vm.currentForm.SurveyCompileDateSystem2,
                    SurveyCompileDateSystem2Expression: expressionTypeEnum.LessThanOrEqual
                },
                virtualHrFilter: {
                    VhrGroupsId: Lazy(vm.nbsSelectionGridWithModalVhrGroup.selectedIds).some() ? vm.nbsSelectionGridWithModalVhrGroup.selectedIds : [],
                    VhrTypesId: Lazy(vm.nbsSelectionGridWithModalVhrType.selectedIds).some() ? vm.nbsSelectionGridWithModalVhrType.selectedIds : [],
                },
                surveyQuestionFilter: {},
                surveyQuestionOptionFilter: {}
            };

            if (ejDataManager === null) {
                utilService.getGenericElements({ GenericElementCode: 'Configurations.Surveys.LifeStatusColors' }, false)
                    .then((data) => {
                        var ge = data.results[0].PairValuesArray;
                        var statusColorObj = Nembus.Common.Library.parser.objFromList(ge, 'key', 'value');

                        var supportData = { isMySurveys: false, translate: vm.translate, contextService: contextService, statusColorObj: statusColorObj };
                        ejDataManager = audService.getSurveyDataManager(supportData, filter, ejDataManager);
                        nbsGridDatacontext.setDatasource(ejDataManager, true);

                        $timeout(() => {
                            vm.forceDatamanagerFilter = {
                                refreshKey: new Date().getTime(),
                                filter: filter
                            }
                        });
                    });
            } else {
                $timeout(() => {
                    vm.forceDatamanagerFilter = {
                        refreshKey: new Date().getTime(),
                        filter: filter
                    }
                });
            }
        }

        function getSurveyAnalysisDefinitions(forceReload) {
            // 1) SurveyQuestionOption_CompletionPercentRequired => Indice completamento
            // 2) SurveyQuestionOption_NormalizedIndexSumWeight => Indice normalizzato della sommatoria dei Pesi 
            // 3) SurveyQuestionOption_NormalizedIndexSumValue => Indice normalizzato della sommatoria delle Gravità
            // 4) SurveyQuestionOption_NormalizedIndexSumWeightXValue => Indice normalizzato della sommatoria ponderata Pesi x Gravità

            var filter = {
                SurveyAnalysisDefinitionIds: [
                    'SurveyQuestionOption_CompletionPercentRequired',
                    'SurveyQuestionOption_NormalizedIndexSumWeight',
                    'SurveyQuestionOption_NormalizedIndexSumValue',
                    'SurveyQuestionOption_NormalizedIndexSumWeightXValue',
                ]
            };
            var defer = $q.defer();

            audService.getSurveyAnalysisDefinitions(filter, forceReload)
                .then(function (data) {
                    vm.surveyAnalysisDefinitions = Lazy(data.results).sortBy((item) => { return item.SurveyAnalysisDefinitionName }).toArray();
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function canPrintReport() {
            var result = false;
            if (selectedSurveys.length === 0) {
                navigationService.selectOneItemMessageModal();
            } else {
                result = true;
            }

            return result;
        }

        function setReportFilterData() {
            if (canPrintReport()) {
                var surveysId = Lazy(selectedSurveys).pluck('SurveyId').toArray();

                var reportFilterData = new NbsReportFilterData(vm.translate, contextService.virtualContext.cultureInfo, Nembus.Common.Library.converter.date.formatDate);
                reportFilterData.setDataByObjectList('SurveyDto', 'SurveyDto', surveysId, selectedSurveys, 'SurveyId', ['SurveyName']);

                reportService.currentReport.reportArgs = {
                    Ds1_Arg1: 'surveykpi-section-normalized',
                    Ds1_Arg2: { //SurveyFilter
                    },
                    Ds1_Arg3: { // VhrEventFilter
                        ModuleTargetId: moduleTargetId,
                    },
                    Ds1_Arg4: { // SurveyAnalysisFilter
                        SurveyIds: surveysId,
                        SurveyAnalysisDefinitionId: vm.currentForm.SurveyAnalysisDefinitionId
                    },
                    Ds1_Arg5: { // SurveyVhrEventFilter                        
                    },
                    Ds1_Arg6: { // VirtualHrFilter                        
                    },
                    Ds1_Arg7: { // ReportFilterData
                        Datas: reportFilterData.Datas
                    },
                    Ds1_Arg8: { // QueryOptions
                        SortedBy: vm.currentForm.SortFieldId,
                        SortedByDescending: vm.currentForm.SortTypeId
                    }
                };
            }
        }
    };

    surveyKpiReportForm.$inject = injectParams;
    surveyKpiReportForm.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('surveyKpiReportForm', surveyKpiReportForm);
});
