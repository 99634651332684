﻿'use strict';

define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'audService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function surveyCreationStepSummary($q, $scope, $timeout, $injector, audService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, audService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, audService);

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var pParams = navigationService.stateParamsModal().parentParams;
        pParams = pParams ? pParams : navigationService.stateParams();

        // beardcrumb information
        var beardCrumbInfo = JSON.parse(pParams.beardCrumbInfo);
        Lazy(beardCrumbInfo.items).each((item) => { item.title = vm.translate(item.title); });
        vm.beardCrumbInfo = beardCrumbInfo;

        vm.surveyCreationPatternObj = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyCreationPattern, null, vm.translate).obj.normal.name_value;
        var radioCompileTypeDatasource = [
            { value: vm.surveyCreationPatternObj.Single, label: 'Single' },
            { value: vm.surveyCreationPatternObj.Recurrent, label: 'Recurrent' },
            { value: vm.surveyCreationPatternObj.OnDemand, label: 'OnCall' }
        ];
        vm.radioCompileTypeList = new NbsRadioList(vm.translate);
        vm.radioCompileTypeList.addData(radioCompileTypeDatasource, 'value', 'label');

        // navigation buttons visibility
        vm.setWizardButtonVisibility(pParams);

        vm.surveyCreationSummary = audService.surveyCreationCollector;
        // formatted data for UI
        vm.surveyCreationSummary.StartDateForLayout = Nembus.Common.Library.converter.date.formatDate(vm.surveyCreationSummary.RecurrenceStartDate, contextService.virtualContext.cultureInfo, true);
        vm.surveyCreationSummary.EndDateForLayout = Nembus.Common.Library.converter.date.formatDate(vm.surveyCreationSummary.RecurrenceEndDate, contextService.virtualContext.cultureInfo, true);
        vm.surveyCreationSummary.StartTimeForLayout = Nembus.Common.Library.converter.timespan.jsonToString(vm.surveyCreationSummary.EventStartTime);
        vm.surveyCreationSummary.EndTimeForLayout = Nembus.Common.Library.converter.timespan.jsonToString(vm.surveyCreationSummary.EventEndTime);

        vm.modalEnd = function () {
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalEnd);
            navigationService.closeCurrentModal(modalResult);
        };

        vm.modalPrevious = function () {
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalPrevious);
            navigationService.closeCurrentModal(modalResult);
        };

        vm.modalCancel = function () {
            navigationService.confirmExitWizard()
                            .then(function (result) {
                                if (result) {
                                    var modalResult = getModalResult(navigationService.navigationActionTypes.modalCancel);
                                    navigationService.closeCurrentModal(modalResult);
                                }
                            });
        };

        function getModalResult(dialogResult) {
            var modalResult = new navigationService.modalResult();
            //modalResult.data = ; last step, no data to collect
            modalResult.sourceParams = pParams;
            modalResult.dialogResult = dialogResult;
            return modalResult;
        }
    }

    surveyCreationStepSummary.$inject = injectParams;
    surveyCreationStepSummary.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('surveyCreationStepSummary', surveyCreationStepSummary);
});