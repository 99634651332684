﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'reportService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var vhrTargetsListReportForm = function ($q, $scope, $timeout, $injector, foundationService, reportService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        var checkStateChangeParams = { dirtyFlagIsDisabled: vm.isDirtyDisabled };
        checkStateChangeService.checkFormOnStateChange($scope, foundationService, checkStateChangeParams);

        // all form items are enabled
        vm.setIsEditingReport();


        var dsCallback = function () { return foundationService.getVhrTypes(false); };
        var selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrType', viewTitle: vm.translate('TargetDto.TargetTypeId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrType = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalVhrType.setOnSelectFnCallback(function () {
            reportService.currentReport.reportArgs.Ds1_Arg1.VhrTypesId = vm.nbsSelectionGridWithModalVhrType.selectedIds;
        });

        dsCallback = function () { return foundationService.getVhrGroups(false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrGroup', viewTitle: vm.translate('TargetDto.TargetGroupId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrGroup = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalVhrGroup.setOnSelectFnCallback(function () {
            reportService.currentReport.reportArgs.Ds1_Arg1.VhrGroupsId = vm.nbsSelectionGridWithModalVhrGroup.selectedIds;            
        });

        // Grouping selection
        vm.groupingTypes = [];
        //vm.groupingTypes.push({ value: 'ParentCategoryId', name: vm.translate('Category.Category') });
        vm.groupingTypes.push({ value: 'TargetGroupId', name: vm.translate('TargetDto.TargetGroupId') });
        vm.groupingTypes.push({ value: 'TargetTypeId', name: vm.translate('TargetDto.TargetTypeId') });

        // Sorting selection
        vm.sortingTypes = [];
        vm.sortingTypes.push({ value: 'TargetCode', name: vm.translate('Code') });
        vm.sortingTypes.push({ value: 'TargetName', name: vm.translate('Name') });

        // posted filter
        vm.currentReportFilter = {};

        //#region Multiselect with grid selection
        selectionParams = { destModalKey: 'category', systemCategoryCode: 'BSC' };
        vm.nbsSelectionTreeviewWithModalCategory = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategory.setOnSelectFnCallback(function () {
            reportService.currentReport.reportArgs.Ds1_Arg1.CategoriesId = vm.nbsSelectionTreeviewWithModalCategory.selectedIds;
        });
        //#endregion

        // set title of current controller in report definition detail view
        navigationService.setControllerTitle();

        reportService.currentReport.reportArgs = {
            Ds1_Arg1: {//TargetFilter
                TargetCode: null,
                TargetName: null,
                TargetDescription: null,
                CompaniesId: null,
                CategoriesId: null,
                VhrGroupsId: null,
                VhrTypesId: null
            },
            Ds1_Arg2: {//CategoryPathParam
                SurveyIds: null
            },
            Ds1_Arg3: {//QueryOptions
                GroupedBy: null,
                SortedBy: null
            }
        };

        loadData(true);
        function loadData(forceReload) {
            var funcsPromises = [];
            
            $q.all(funcsPromises)
                .then(function (results) {
                    reportService.currentReport.formEntityToValidate = null;

                    // bind report object to ui filter
                    vm.currentReportFilter = reportService.currentReport.reportArgs.Ds1_Arg1;
                });
        }

        //#region onChange methods (FILTER)
        vm.targetGroupOnChange = function () {
            reportService.currentReport.reportArgs.Ds1_Arg1.VhrGroupId = vm.currentReportFilter.TargetGroupId;
        };
        vm.targetTypeOnChange = function () {
            reportService.currentReport.reportArgs.Ds1_Arg1.VhrTypeId = vm.currentReportFilter.TargetTypeId;
        };
        //#endregion

        // #region onChange methods (GROUPING)
        vm.groupingTypeOnChange = function () {
            reportService.currentReport.reportArgs.Ds1_Arg3.GroupedBy = vm.groupingType1Selected;
        };
        // #endregion

        // #region onChange methods (SORTING)
        vm.sortingTypeOnChange = function () {
            reportService.currentReport.reportArgs.Ds1_Arg3.SortedBy = vm.sortingTypeSelected;
        };
        // #endregion             
    };

    vhrTargetsListReportForm.$inject = injectParams;
    vhrTargetsListReportForm.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('vhrTargetsListReportForm', vhrTargetsListReportForm);
});
