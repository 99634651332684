﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'metricService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var metricSpecializationDetail = function ($q, $scope, $timeout, $injector, metricService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, metricService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, metricService, { dirtyFlagIsSuspended: vm.isDirtySuspended });

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        ribbon.setRibbonStatusButton(ribbon.Buttons.Modify,false);
        ribbon.refreshStatusButtons();

        var metricSpecializationId = navigationService.stateParams().metricSpecializationId;
        
        loadData(true);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getMetricKpiDefinitions(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.metricKpiDefinitions = results[0];

                    if (metricSpecializationId) {
                        vm.setIsViewing();
                        
                        var funcsPromisesSub = [];
                        funcsPromisesSub.push(getMetricSpecialization(forceReload, metricSpecializationId));
                        $q.all(funcsPromisesSub)
                            .then(function (subresults) {
                                vm.currentMetricSpecialization = subresults[0][0];

                                ribbon.setRibbonStatusButton(ribbon.Buttons.Modify, !vm.currentMetricSpecialization.IsSystem);
                                ribbon.refreshStatusButtons();

                                setStopValuesSelection();
                            });
                    } else {
                        vm.setIsAdding();

                        vm.currentMetricSpecialization = metricService.dataContext.metricSpecializations.add({
                            IsSystem: false,
                            MetricKpiDefinitionId: null,
                            VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId
                        });
                    }
                });
        }

        function getMetricSpecialization(forceReload, metricSpecializationId) {
            var filter = {
                MetricSpecializationId: metricSpecializationId
            };
            var defer = $q.defer();

            metricService.getMetricSpecialization(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getMetricKpiDefinitions(forceReload) {
            var filter = {};
            var defer = $q.defer();

            metricService.getMetricKpiDefinitions(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    metricSpecializationDetail.$inject = injectParams;
    metricSpecializationDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('metricSpecializationDetail', metricSpecializationDetail);
});