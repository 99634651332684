﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$locale', '$timeout', '$injector', 'productionService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var calendarTemplateDetail = function ($q, $scope, $locale, $timeout, $injector, productionService, navigationService, checkStateChangeService, contextService) {
        var locale = $locale;
        var vm = this;
        nbsVm.call(vm, productionService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, productionService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        vm.dayOfWeekEnum = Nembus.Common.Library.util.parseEnum(productionService.dataContext.enumTypes.DayOfWeek, null, vm.translate).list.translated;

        var currentCalendarTemplateId = navigationService.stateParams().calendarId;
        var wCalendarParsedEnum = Nembus.Common.Library.util.parseEnum(productionService.dataContext.enumTypes.WCalendarKind, 'WCalendar', vm.translate);
        vm.wCalendarKinds = wCalendarParsedEnum.list.translated;

        vm.currentCalendarTemplate = [];
        vm.currentCalendarDays = [];
        vm.currentInterval = {};
        vm.currentCalendarDaySlots = [];
        vm.currentBankHolidays = [];

        // defaults
        var isClosed = null;
        var isAllDayOpen = null;
        var calendarDateFrom = null;
        var calendarDateTo = null;
        vm.nbsDataPrivate = {
            calendartimelineselected: Nembus.Common.Library.component.bar.datarange.getTimelineDatarange('FY+0'),
            wdayisclosedchecked: isClosed,
            wdayisalldayopenchecked: isAllDayOpen
        };
        calendarDateFrom = vm.nbsDataPrivate.calendartimelineselected.dateFrom;
        calendarDateTo = vm.nbsDataPrivate.calendartimelineselected.dateTo;


        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('wday', 'template');
        nbsGridDatacontext.setAdaptToPage();
        nbsGridDatacontext.allowPaging = true;
        nbsGridDatacontext.allowSearching = false;
        nbsGridDatacontext.allowGrouping = false;
        nbsGridDatacontext.setDatasource([], false);
        nbsGridDatacontext.setChildGridDatasource(function () { return vm.currentCalendarDaySlots });
        vm.nbsGridDatacontext = nbsGridDatacontext;

        vm.currentCalendarTemplateIsInserted = false;
        if (currentCalendarTemplateId) {
            vm.setIsViewing();

            loadData(true);
            vm.currentCalendarTemplateIsInserted = true;
        } else {
            vm.setIsAdding();

            getBankHolidays(true);

            vm.currentCalendarTemplate = productionService.dataContext.calendarTemplates.add({
                BankHolidayId: null,
                WCalendarStatusId: 1, //Boh
                WCalendarKindId: null, //Boh
                VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId
            });
        }

        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getCalendarDaySlots(forceReload));
            funcsPromises.push(getCalendarDays(forceReload));
            funcsPromises.push(getBankHolidays(forceReload));
            funcsPromises.push(getCalendarTemplate(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.currentCalendarDays
                    }
                });
        }

        vm.onCalendarDatarangeSelectionEvent = function (timeline) {
            calendarDateFrom = timeline.dateFrom;
            calendarDateTo = timeline.dateTo;
            loadData(true);
        }

        vm.onBooleanWDayIsClosed = function (value) {
            isClosed = value
            loadData(true);
        };

        vm.onBooleanWDayIsAllDayOpen = function (value) {
            isAllDayOpen = value
            loadData(true);
        };

        ribbon.setRibbonStatusButton(ribbon.Buttons.Refresh, true);
        ribbon.refreshStatusButtons();

        vm.isClosedOnChange = function () {
            if (vm.currentCalendarDay.IsClosed) {
                vm.currentCalendarDay.IsAllDayOpen = false;
            }            
        }

        vm.isAllDayOpenOnChange = function () {
            if (vm.currentCalendarDay.IsAllDayOpen) {
                vm.currentCalendarDay.IsClosed = false;
            }
        }

        var currentAction = vm.actions.none;

        //Child grid events
        vm.childGridRecordDoubleClick = function (args) {
            vm.currentCalendarDaySlot = args.data;
            currentAction = vm.actions.update;

            vm.openModalWDaySlot();
        };

        vm.childGridQueryCellInfo = function (args) {
            switch (args.column.field) {
                case 'TimeFrom':
                case 'TimeTo':
                    args.cell.innerHTML = Nembus.Common.Library.converter.timespan.jsonToString(args.cell.innerHTML);
                    break;
            }
        };

        vm.rowDblClick = function (args) {
            vm.openModalWDay(args);
        };

        vm.openModalWDay = function (args) {
            vm.currentCalendarDay = args.data;

            vm.modalInstance = navigationService.modal().open(vm.modalOptions({
                windowClass: vm.NBS_MODAL_CLASS_FORM,
                templateUrl: 'calendarDayTemplate',
                scope: $scope
            }));
            vm.modalInstance.result.then(function () {
                //ok
                vm.forceDatasourceLocal = {
                    refreshkey: new Date().getTime(),
                    dataSource: vm.currentCalendarDays
                }
            }, function () {
                //dismiss
                productionService.rejectChanges();
            });
            vm.modalInstance.ok = function () {
                vm.modalInstance.close();
            }
            vm.modalInstance.cancel = function () {
                vm.modalInstance.dismiss();
            }
        };

        vm.rowSelected = function (args) {
            vm.currentDay = args.data;
        };

        vm.childGridRowSelected = function (args) {
            vm.currentCalendarDaySlot = args.data;
        };

        vm.newAction = function () {
            if (vm.currentDay) {
                currentAction = vm.actions.add;

                vm.currentCalendarDaySlot = productionService.dataContext.calendarDaySlots.add({
                    Efficiency: 100,
                    TimeFrom: 'PT0H0M',
                    TimeTo: 'PT0H0M',
                    WDayId: vm.currentDay.WDayId
                });

                vm.openModalWDaySlot();
            }
        };

        //timepicker settings
        vm.openModalWDaySlot = function () {
            vm.modalInstance = navigationService.modal().open(vm.modalOptions({ templateUrl: 'calendarDaySlotTemplate', scope: $scope }));
            vm.modalInstance.result.then(function () {
                //ok
                setCurrentCalendarDaySlots();
            }, function () {
                //dismiss
                productionService.rejectChanges();
            });
            vm.modalInstance.ok = function () {
                if (!vm.currentCalendarDaySlot.entityAspect.hasValidationErrors) {
                    vm.modalInstance.close();
                }
            }
            vm.modalInstance.cancel = function () {
                vm.modalInstance.dismiss();
            }
        };

        vm.openModalInterval = function () {
            getWeekTemplates(true)
                .then(function () {
                    var lastDay = vm.currentCalendarTemplate.SetUntil
                        ? vm.currentCalendarTemplate.SetUntil
                        : new Date(Date.now());

                    var oneMoreDayFromLast = new Date(lastDay);
                    var twoMoreDayFromLast = new Date(lastDay);
                    oneMoreDayFromLast.setDate(lastDay.getDate() + 1);
                    twoMoreDayFromLast.setDate(lastDay.getDate() + 2);

                    vm.currentInterval = productionService.dataContext.CurrentIntervalClientData.add({
                        selectedId: null,
                        DateFrom: oneMoreDayFromLast,
                        DateTo: twoMoreDayFromLast
                    });

                    vm.modalInstance = navigationService.modal().open(vm.modalOptions({ templateUrl: 'intervalTemplate', scope: $scope }));
                    vm.modalInstance.result.then(function () {
                        //ok
                        setCurrentCalendarDaySlots();
                        productionService.dataContext.CurrentIntervalClientData.clearCache();
                    }, function () {
                        //dismiss
                        productionService.dataContext.CurrentIntervalClientData.clearCache();
                        productionService.rejectChanges();
                    });
                    vm.modalInstance.ok = function () {
                        //force validation on PairValueClientData entity (manual metadata only client)
                        vm.currentInterval.entityAspect.validateEntity();

                        if (!vm.currentInterval.entityAspect.hasValidationErrors) {
                            vm.removeAlerts();

                            var filter = {
                                from: vm.currentInterval.DateFrom,
                                to: vm.currentInterval.DateTo,
                                calendarId: currentCalendarTemplateId,
                                weekTemplateId: Number(vm.currentInterval.selectedId)
                            };

                            productionService.composeCalendarTemplate(filter)
                                .then(function (result) {
                                    vm.modalInstance.close();
                                    loadData(true);
                                })
                                .catch(function (error) {
                                    vm.setErrorMsg(error);
                                });
                        }
                    }
                    vm.modalInstance.cancel = function () {
                        vm.modalInstance.dismiss();
                    }
                });
        };

        // get reference of modifyAction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parentm = vm.modifyAction;
        vm.modifyAction = function () {
            parentm().then(
                //enable disable button new & delete because is custom behavior
                //generally saveResult eneble or disable only save button 
                function (saveResult) {
                    currentCalendarTemplateId = vm.currentCalendarTemplate.WCalendarId;

                    vm.currentCalendarTemplateIsInserted = buttonsAreVisible();

                    ribbon.setRibbonStatusButton(ribbon.Buttons.New,  vm.currentCalendarTemplateIsInserted);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, vm.currentCalendarTemplateIsInserted);
                    ribbon.refreshStatusButtons();
                }
            );
        };

        // get reference of submitaction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parents = vm.submitAction;
        vm.submitAction = function () {
            parents().then(
                function (saveResult) {
                    currentCalendarTemplateId = vm.currentCalendarTemplate.WCalendarId;

                    vm.currentCalendarTemplateIsInserted = buttonsAreVisible();

                    ribbon.setRibbonStatusButton(ribbon.Buttons.New,  vm.currentCalendarTemplateIsInserted);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, vm.currentCalendarTemplateIsInserted);
                    ribbon.refreshStatusButtons();
                }
            );
        };

        vm.refreshAction = function () {
            loadData(true);
        };

        vm.deleteAction = function () {
            if (!vm.currentCalendarDaySlot) {
                return;
            }

            productionService.deleteEntity(vm.currentCalendarDaySlot);
            vm.currentCalendarDaySlots = Lazy(vm.currentCalendarDaySlots)
                .reject({ WDaySlotId: vm.currentCalendarDaySlot.WDaySlotId })
                .toArray();

            vm.forceDatasourceLocalChildGrid = {
                refreshkey: new Date().getTime(),
                dataSource: vm.currentCalendarDaySlots
            }
        };

        function buttonsAreVisible() {
            return vm.currentCalendarTemplate.WCalendarId > 0;
        }

        function setCurrentCalendarDaySlots() {
            if (currentAction === vm.actions.add) {
                vm.currentCalendarDaySlots.push(vm.currentCalendarDaySlot);
            }

            vm.forceDatasourceLocalChildGrid = {
                refreshkey: new Date().getTime(),
                dataSource: vm.currentCalendarDaySlots
            }
        }

        function getCalendarTemplate(forceReload) {
            var filter = { WCalendarId: currentCalendarTemplateId };
            var defer = $q.defer();

            productionService.getCalendarTemplate(filter, forceReload)
                .then(function (data) {
                    vm.currentCalendarTemplate = data.results[0];
                    defer.resolve(data.results[0]);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getCalendarDays(forceReload) {
            var filter = {
                WCalendarId: currentCalendarTemplateId,
                DateFrom: calendarDateFrom,
                DateTo: calendarDateTo,
                IsClosed: isClosed,
                IsAllDayOpen: isAllDayOpen
            };
            var defer = $q.defer();

            productionService.getCalendarDays(filter, forceReload)
                .then(function (data) {
                    Lazy(data.results).each((item) => {
                        item.DayOfWeek = getDayOfWeek(item.WorkingDate);
                        item.Month = getMonth(item.WorkingDate);
                    });

                    vm.currentCalendarDays = data.results;
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function isValidDate(date) {
            if (Object.prototype.toString.call(date) !== "[object Date]") {
                return false;
            }

            return !isNaN(date.getTime());
        }

        function getDayOfWeek(date) {
            if (!isValidDate(date)) {
                return '';
            }

            return Lazy(vm.dayOfWeekEnum).findWhere({ value: date.getDay() }).name;
        }

        function getMonth(date) {
            if (!isValidDate(date)) {
                return '';
            }

            return locale.DATETIME_FORMATS.STANDALONEMONTH[date.getMonth()];
        }

        function getCalendarDaySlots(forceReload) {
            var filter = {
                WCalendarId: currentCalendarTemplateId,
                IsClosed: isClosed,
                IsAllDayOpen: isAllDayOpen
            };
            var defer = $q.defer();

            productionService.getCalendarDaySlots(filter, forceReload)
                .then(function (data) {
                    vm.currentCalendarDaySlots = data.results;
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getBankHolidays(forceReload) {
            var defer = $q.defer();
            productionService.getBankHolidays({}, forceReload)
                .then(function (data) {
                    vm.currentBankHolidays = data.results;
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getWeekTemplates(forceReload) {
            var defer = $q.defer();
            productionService.getWeekTemplates({}, forceReload)
                .then(function (data) {
                    vm.currentWeekTemplates = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    calendarTemplateDetail.$inject = injectParams;
    calendarTemplateDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('calendarTemplateDetail', calendarTemplateDetail);
});