﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$injector', 'foundationService', 'navigationService', 'contextService'];
    function genericListModal($q, $injector, foundationService, navigationService, contextService) {
        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, null);

        // modal parameters
        var urlParams = navigationService.stateParamsModal().parentParams;
        var pDs = navigationService.getModalData('genericListModal', vm.viewMode).ds;
        var pSelectedIds = urlParams.selectedIds ? urlParams.selectedIds : [];
        var pFieldId = urlParams.gridColId;
        var pFieldCode = urlParams.gridColCode;
        var pFieldName = urlParams.gridColName;

        var pSortField = urlParams.gridSortField;
        var pSortMode = urlParams.gridSortMode;

        // bind to UI
        vm.viewTitle = urlParams.viewTitle;
        var pViewColId = urlParams.gridColIdIsVisible;
        var pViewColCode = urlParams.gridColCodeIsVisible;
        var pViewColName = urlParams.gridColNameIsVisible;
        var pIsMultiselect = urlParams.isMultiselect;
        var pIsRadonly = urlParams.readonly;
        vm.gridIsReadonly = urlParams.readonly;

        // set generic grid datasource
        getDatasource(pDs);

        vm.modalOk = function () {
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalOk);
            navigationService.closeCurrentModal(modalResult);
        };

        vm.modalCancel = function () {
            navigationService.closeCurrentModal();
        };

        var currentSelected = [];
        vm.gridSelection = function (args) {
            if (pIsMultiselect) {
                currentSelected = args.data;
            } else {
                if (args.data) {
                    currentSelected = [args.data];
                } else {
                    currentSelected = [];
                }
            }
        }

        function getDatasource(modalParam) {
            var data = [];
            if (typeof modalParam === 'function') {
                modalParam().then(function (data) {
                    setDatasource(data.results);
                });
            } else {
                if (modalParam) {
                    data = modalParam;
                } else {
                    data = [];
                }
                setDatasource(data);
            }

        }

        function setDatasource(data) {
            var ds = mapGridSelection(data, pFieldId, pSelectedIds);
            var genericGridParams = {
                fieldId: pFieldId,
                fieldCode: pFieldCode,
                fieldName: pFieldName,
                viewId: pViewColId,
                viewCode: pViewColCode,
                viewName: pViewColName,
                isMultiselect: pIsMultiselect,
                selectedIds: pSelectedIds,
                readonly: pIsRadonly,
                sortField: pSortField,
                sortMode: pSortMode
            };
            var nbsGridDatacontext = new NbsGrid(vm.translate);
            nbsGridDatacontext.configureGrid('genericentity', genericGridParams);
            nbsGridDatacontext.allowSearching = true;
            nbsGridDatacontext.setModalMode();
            nbsGridDatacontext.setDatasource(ds, false);
            vm.nbsGridDatacontext = nbsGridDatacontext;
        }

        function mapGridSelection(datasource, fieldId, selectedIds) {
            if (selectedIds === null || selectedIds === undefined) {
                selectedIds = [];
            }

            return Lazy(datasource)
                .map((item) => {
                    item.IsSelected = Lazy(selectedIds).contains(item[fieldId]);
                    return item;
                })
                .toArray();
        };

        function getModalResult(dialogResult) {
            Lazy(currentSelected)
                .each((item) => {
                    item.nbs_id = item[pFieldId];
                    item.nbs_code = item[pFieldCode];
                    item.nbs_name = item[pFieldName];
                });

            var modalResult = new navigationService.modalResult();
            modalResult.data = currentSelected;
            modalResult.selectedValues = Lazy(currentSelected).pluck(pFieldId).toArray();
            modalResult.hasSelection = Lazy(currentSelected).some();
            modalResult.sourceParams = urlParams;
            modalResult.dialogResult = dialogResult;
            return modalResult;
        }
    }

    genericListModal.$inject = injectParams;
    genericListModal.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('genericListModal', genericListModal);
});
