﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'drbacService', 'audService', 'reportService', 'navigationService', 'checkStateChangeService', 'vhreventService', 'contextService', 'utilService'];
    var htmlToExcelSurveyGanttReportForm = function ($q, $scope, $timeout, $injector, foundationService, drbacService, audService, reportService, navigationService, checkStateChangeService, vhreventService, contextService, utilService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        var checkStateChangeParams = { dirtyFlagIsDisabled: vm.isDirtyDisabled };
        checkStateChangeService.checkFormOnStateChange($scope, foundationService, checkStateChangeParams);

        // all form items are enabled
        vm.setIsEditingReport();

        var appStoreCode = 'aud';
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
        var expressionTypeEnum = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.ExpressionType, null, vm.translate).obj.normal.name_value;
        var surveyLifeStatusEnumParsed = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyLifeStatus, 'SurveyDto', vm.translate);
        var surveyLifeStatusEnumObj = surveyLifeStatusEnumParsed.obj.normal.name_value;
        var surveyLifeStatusEnumObjTranslated = surveyLifeStatusEnumParsed.obj.translated.value_name;

        var dsCallback = function () { return vhreventService.getVhrEventTypes({ AppStoreBw: appStoreBw }, false); };
        var selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventType', viewTitle: vm.translate('SurveyDto.VhrEventTypeId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventType = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        dsCallback = function () { return vhreventService.getVhrEventGroups({ AppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventGroup', viewTitle: vm.translate('SurveyDto.VhrEventGroupId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventGroup = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        vm.statusRendering = {
            row: false,
            columns: false
        };

        vm.htmlToExcelSurveyGanttClientData = audService.dataContext.htmlToExcelSurveyGanttClientDatas.add({
            HtmlToExcelSurveyGanttClientDataId: NbsGuidManager.newGuid(),
            IsArchived: null
        });

        var processSelected = null;
        vm.isProcessEnabled = Nembus.Common.Library.auth.isProcessEnabled(contextService.virtualContext, moduleTargetId);

        vm.parentScope = $scope.$parent.vm;
        vm.parentScope.ribbonDatacontext.setRibbonStatusButton(vm.parentScope.ribbonDatacontext.Buttons.PrintReportExcel, true);
        vm.parentScope.ribbonDatacontext.refreshStatusButtons();


        // REFERS to 'reportDefinitionHtmlToDocDetail'
        vm.parentScope.initReportToDocHeader({ onSearchActionHtmlToDocFn: null, onCreateHtmlFn: createHtmlReport });

        vm.vhrEventGroups = [];
        vm.vhrEventTypes = [];

        // set title of current controller in report definition detail view
        navigationService.setControllerTitle();

        loadData(true);
        function loadData(forceReload) {
            var funcsPromises = [];

            if (vm.isProcessEnabled) {
                funcsPromises.push(getDrbacProcesses(forceReload, virtualCompanyId));
            }

            $q.all(funcsPromises)
                .then(function () {
                });
        }

        // init bindobject
        vm.bindObject = {
            expireDatesColumns: [],
            surveys: [],
            filters: {}
        };

        function createHtmlReport() {
            vm.htmlToExcelSurveyGanttClientData.entityAspect.validateEntity();
            if (vm.htmlToExcelSurveyGanttClientData.entityAspect.hasValidationErrors) {
                $timeout(() => { /* implicit scope apply; */ });
                return;
            }

            // reset results on UI 
            vm.bindObject.expireDatesColumns = [];
            vm.bindObject.surveys = [];

            var filter = {
                surveyFilter: {
                    IsArchived: vm.htmlToExcelSurveyGanttClientData.IsArchived
                },
                surveySectionFilter: {},
                surveyQuestionFilter: {},
                surveyQuestionOptionFilter: {},
                surveyVhrEventFilter: {
                    ExcludeOnDemand: true
                },
                vhrEventFilter: {
                    StartDate1: vm.htmlToExcelSurveyGanttClientData.StartDate1,
                    StartDate1Expression: expressionTypeEnum.GreaterThanOrEqual,
                    StartDate2: vm.htmlToExcelSurveyGanttClientData.StartDate2,
                    StartDate2Expression: expressionTypeEnum.LessThanOrEqual,
                    DrbacProcessId: vm.htmlToExcelSurveyGanttClientData.DrbacProcessId,
                    ModuleTargetId: moduleTargetId
                }
            };

            filter.vhrEventFilter.VhrEventTypesId = vm.nbsSelectionGridWithModalVhrEventType.selectedIds;
            filter.vhrEventFilter.VhrEventGroupsId = vm.nbsSelectionGridWithModalVhrEventGroup.selectedIds;

            audService.searchSurveys(filter)
                .then(function (data) {
                    parseReportHtmlDatasource(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });
        }

        // prepare datasource for report in UI (to prepare Html)
        vm.htmlSteps = { SURVEYSLIST: 'surveys-render', DATECOLUMNSLIST: 'datecolumns-render' };
        function parseReportHtmlDatasource(surveys) {
            // translations UI
            vm.bindObject.translations = {};
            vm.bindObject.translations.created = surveyLifeStatusEnumObjTranslated[surveyLifeStatusEnumObj.Created];
            vm.bindObject.translations.createdId = surveyLifeStatusEnumObj.Created;
            vm.bindObject.translations.running = surveyLifeStatusEnumObjTranslated[surveyLifeStatusEnumObj.Running];
            vm.bindObject.translations.runningId = surveyLifeStatusEnumObj.Running;
            vm.bindObject.translations.reportedAsFinished = surveyLifeStatusEnumObjTranslated[surveyLifeStatusEnumObj.ReportedAsFinished];
            vm.bindObject.translations.reportedAsFinishedId = surveyLifeStatusEnumObj.ReportedAsFinished;

            // set filter vhreventypes description
            var vhrEventTypeNames = [];
            vhrEventTypeNames = Lazy(vm.nbsSelectionGridWithModalVhrEventType.selectedItems).pluck('nbs_name').toArray();
            vm.bindObject.filters.VhrEventTypeName = vhrEventTypeNames.join(',');

            // set filter vhrevengroups description
            var vhrEventGroupNames = [];
            vhrEventGroupNames = Lazy(vm.nbsSelectionGridWithModalVhrEventGroup.selectedItems).pluck('nbs_name').toArray();
            vm.bindObject.filters.VhrEventGroupName = vhrEventGroupNames.join(',');

            // set filter process description
            if (vm.isProcessEnabled && processSelected) {
                vm.bindObject.filters.ProcessName = processSelected.DrbacProcessName;
            }

            // set filter range date description
            vm.bindObject.filters.PeriodRange = Nembus.Common.Library.util.getRangeString(contextService, vm.htmlToExcelSurveyGanttClientData.StartDate1, vm.htmlToExcelSurveyGanttClientData.StartDate2);

            var dynamicSurveyDateColumns = [];

            Lazy(surveys)
                .each((survey) => {
                    var dateString = Nembus.Common.Library.converter.date.formatDate(survey.SurveyExpireDate, contextService.virtualContext.cultureInfo, true);
                    survey.SurveyExpireDateKey = dateString.replaceAll('/', '');
                    survey[survey.SurveyExpireDateKey] = {};
                });

            //// if range in not valid then use date of results
            if (dynamicSurveyDateColumns.length === 0) {
                Lazy(surveys)
                    .each((survey) => {
                        addColumnDate(survey.SurveyExpireDate, dynamicSurveyDateColumns);
                    });
            }

            Lazy(surveys)
                .each((survey) => {
                    Lazy(dynamicSurveyDateColumns)
                        .each((expireDate) => {
                            if (survey.SurveyExpireDateKey === expireDate.DateKey) {
                                survey[expireDate.DateKey] = { SurveyLifeStatusId: survey.SurveyLifeStatusId };
                            }
                        });
                });

            // bind results on UI
            vm.bindObject.surveys = Lazy(surveys).sortBy((el) => { return el.SurveyCode; }, false).toArray();
            vm.bindObject.expireDatesColumns = Lazy(dynamicSurveyDateColumns).sortBy((el) => { return el.Date; }, false).toArray();

            var stepsToRender = [];
            if (vm.bindObject.surveys.length > 0) {
                stepsToRender.push(vm.htmlSteps.SURVEYSLIST);
            }

            if (dynamicSurveyDateColumns.length > 0) {
                stepsToRender.push(vm.htmlSteps.DATECOLUMNSLIST);
            }

            vm.parentScope.htmlToDocManager.setHtmlStepsToRender(stepsToRender);

            if (!vm.parentScope.htmlToDocManager.hasHtmlStepsToRender()) {
                vm.parentScope.htmlToDocManager.htmlIsRendered();
            }
        }

        vm.drbacProcessOnChange = function () {
            var funcsPromises = [];
            if (!vm.htmlToExcelSurveyGanttClientData.DrbacProcessId) {
                //// set types datasource and select ids
                //vm.vhrEventTypesAcDs = new NbsMultiselect(vm.translate);
                //vm.vhrEventTypesAcDs.addData(vm.vhrEventTypes, 'VhrEventTypeId', 'VhrEventTypeName');
                //vm.vhrEventTypesAcDs.clearSelection();

                //// set groups datasource and select ids
                //vm.vhrEventGroupsAcDs = new NbsMultiselect(vm.translate);
                //vm.vhrEventGroupsAcDs.addData(vm.vhrEventGroups, 'VhrEventGroupId', 'VhrEventGroupName');
                //vm.vhrEventGroupsAcDs.clearSelection();
            } else {
                processSelected = Lazy(vm.drbacProcesses).findWhere({ DrbacProcessId: Number(vm.htmlToExcelSurveyGanttClientData.DrbacProcessId) });
                if (processSelected) {
                    var vhrEventMatcherId = processSelected.VhrEventMatcherId;

                    funcsPromises = [];
                    funcsPromises.push(getVhrEventMatcherTargetActivities_VhrEventTypes(true, vhrEventMatcherId));
                    funcsPromises.push(getVhrEventMatcherTargetActivities_VhrEventGroups(true, vhrEventMatcherId));

                    $q.all(funcsPromises)
                        .then(function () {
                            //var selectedIds = [];

                            //// set types datasource and select ids
                            //vm.vhrEventTypesAcDs = new NbsMultiselect(vm.translate);
                            //vm.vhrEventTypesAcDs.addData(vm.processVhrEventTypes, 'VhrEventTypeId', 'VhrEventTypeName');
                            //selectedIds = Lazy(vm.processVhrEventTypes).pluck('VhrEventTypeId').toArray();
                            //vm.vhrEventTypesAcDs.setSelected(selectedIds);

                            //// set groups datasource and select ids
                            //vm.vhrEventGroupsAcDs = new NbsMultiselect(vm.translate);
                            //vm.vhrEventGroupsAcDs.addData(vm.processVhrEventGroups, 'VhrEventGroupId', 'VhrEventGroupName');
                            //selectedIds = Lazy(vm.processVhrEventGroups).pluck('VhrEventGroupId').toArray();
                            //vm.vhrEventGroupsAcDs.setSelected(selectedIds);
                        });
                }
            }
        };

        function addColumnDate(date, arrayList) {
            if (!arrayList) {
                arrayList = [];
            }

            var dateString = Nembus.Common.Library.converter.date.formatDate(date, contextService.virtualContext.cultureInfo, true);
            var dateKey = dateString.replaceAll('/', '');

            if (!Lazy(arrayList).where({ DateKey: dateKey }).some()) {
                arrayList.push({
                    DateKey: dateKey,
                    DateUI: dateString,
                    Date: date
                });
            }
        }

        function getVhrEventMatcherTargetActivities_VhrEventTypes(forceReload, vhrEventMatcherId) {
            var defer = $q.defer();

            vhreventService.getVhrEventMatchersTargetActivities_VhrEventTypes({ VhrEventMatcherId: vhrEventMatcherId }, forceReload)
                .then(function (data) {
                    vm.processVhrEventTypes = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrEventMatcherTargetActivities_VhrEventGroups(forceReload, vhrEventMatcherId) {
            var defer = $q.defer();

            vhreventService.getVhrEventMatchersTargetActivities_VhrEventGroups({ VhrEventMatcherId: vhrEventMatcherId }, forceReload)
                .then(function (data) {
                    vm.processVhrEventGroups = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getDrbacProcesses(forceReload, virtualCompanyId) {
            var filter = {
                VirtualCompanyId: virtualCompanyId,
                IsHidden: false,
                IsGlobalProcess: false,
                IsEnabled: true
            };
            var defer = $q.defer();

            drbacService.getDrbacProcesses(filter, forceReload)
                .then(function (data) {
                    vm.drbacProcesses = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    htmlToExcelSurveyGanttReportForm.$inject = injectParams;
    htmlToExcelSurveyGanttReportForm.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('htmlToExcelSurveyGanttReportForm', htmlToExcelSurveyGanttReportForm);
});
