﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'reportService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var authAuditListReportForm = function ($q, $scope, $timeout, $injector, reportService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, reportService, navigationService, contextService, $q, $injector, $timeout);
        var checkStateChangeParams = { dirtyFlagIsDisabled: vm.isDirtyDisabled };
        checkStateChangeService.checkFormOnStateChange($scope, reportService, checkStateChangeParams);

        // all form items are enabled
        vm.setIsEditingReport();

        // set title of current controller in report definition detail view
        navigationService.setControllerTitle();

        // validated entity
        vm.authLogReportFormClientData = reportService.dataContext.authLogReportFormClientDatas.add({});

        vm.currentReportFilter = {};
        reportService.currentReport.reportArgs = {
            // AuthAuditFilter
            Ds1_Arg1: {
                ServerHostName: document.location.hostname,
                CustomerCode: contextService.virtualContext.CurrentCustomerCode,
                CustomerAppCode: contextService.virtualContext.CurrentCustomerAppCode,
                UserName: vm.currentReportFilter.UserName,
                AuthTsDate1: null,
                AuthTsDate2: null
            }
        };
        // bind report object to ui filter
        vm.currentReportFilter = reportService.currentReport.reportArgs.Ds1_Arg1;


        reportService.currentReport.formEntityToValidate = vm.authLogReportFormClientData;

        
        vm.authTsDate1OnChange = function () {
            reportService.currentReport.reportArgs.Ds1_Arg1.AuthTsDate1 = vm.authLogReportFormClientData.AuthTsDate1;
        };

        vm.authTsDate2OnChange = function () {
            reportService.currentReport.reportArgs.Ds1_Arg1.AuthTsDate2 = vm.authLogReportFormClientData.AuthTsDate2;
        };

    };

    authAuditListReportForm.$inject = injectParams;
    authAuditListReportForm.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rbac.module').controller('authAuditListReportForm', authAuditListReportForm);
});
