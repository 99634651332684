﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'actService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function actActionTypesList($q, $scope, $timeout, $injector, actService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, actService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return actActionTypeSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, actService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var actActionTypeSelected = null;

        vm.currentActActionTypes = [];

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.actactiontypedetail", { 'actActionTypeId': args.data.ActActionTypeId });
        };

        vm.newAction = function () {
            navigationService.navigateTo("app.nav.actactiontypedetail", { 'actActionTypeId': null });
        };

        vm.refreshAction = function () {
            getActActionTypes(true)
                .then((results) => {
                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: results
                    }
                });
        };

        vm.deleteAction = function () {
            if (!actActionTypeSelected) {
                return;
            }

            actService.deleteEntity(actActionTypeSelected);
            vm.submitAction()
                .then(function (saveResult) {
                    vm.refreshAction();
                });
        };

        vm.gridSelection = function (args) {
            actActionTypeSelected = args.data;

            $timeout(() => {
                if (actActionTypeSelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, !actActionTypeSelected.IsSystem);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                }

                ribbon.refreshStatusButtons();
            });
        };

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getActActionTypes(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.currentActActionTypes = results[0];

                    var nbsGridDatacontext = new NbsGrid(vm.translate);
                    nbsGridDatacontext.configureGrid('actactiontype', null);
                    nbsGridDatacontext.viewCommonBar();
                    nbsGridDatacontext.setDatasource(vm.currentActActionTypes, false);
                    vm.nbsGridDatacontext = nbsGridDatacontext;
                });
        }

        function getActActionTypes(forceReload) {
            var filter = {};
            var defer = $q.defer();

            actService.getActActionTypes(filter, forceReload)
                .then(function (data) {                    
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    }

    actActionTypesList.$inject = injectParams;
    actActionTypesList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('actActionTypesList', actActionTypesList);
});