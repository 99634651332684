﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'audService', 'foundationService', 'vhreventService', 'rbacService', 'reportService', 'navigationService', 'checkStateChangeService', 'contextService', 'utilService'];
    var vhrTargetsSurveyKpiListReportForm = function ($q, $scope, $timeout, $injector, audService, foundationService, vhreventService, rbacService, reportService, navigationService, checkStateChangeService, contextService, utilService) {

        var vm = this;
        nbsVm.call(vm, audService, navigationService, contextService, $q, $injector, $timeout);
        var checkStateChangeParams = { dirtyFlagIsDisabled: vm.isDirtyDisabled };
        checkStateChangeService.checkFormOnStateChange($scope, audService, checkStateChangeParams);

        // all form items are enabled
        vm.setIsEditingReport();

        var appStoreCode = 'aud';
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
        var surveyLifeStatusEnumParsed = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyLifeStatus, 'SurveyDto', vm.translate);
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var expressionTypeEnum = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.ExpressionType, null, vm.translate).obj.normal.name_value;

        var dsCallback = function () { return vhreventService.getVhrEventTypes({ AppStoreBw: appStoreBw }, false); };
        var selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventType', viewTitle: vm.translate('SurveyDto.VhrEventTypeId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventType = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        dsCallback = function () { return vhreventService.getVhrEventGroups({ AppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventGroup', viewTitle: vm.translate('SurveyDto.VhrEventGroupId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventGroup = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        dsCallback = function () { return foundationService.getVhrTypes(false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrType', viewTitle: vm.translate('TargetDto.TargetTypeId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrType = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        dsCallback = function () { return foundationService.getVhrGroups(false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrGroup', viewTitle: vm.translate('TargetDto.TargetGroupId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrGroup = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        selectionParams = { destModalKey: 'vhr', entityMode: 'Target', translateKey: 'Target', isMultiselect: true, selectableEntities: ['VirtualEquipment', 'Target'] };
        vm.nbsSelectionGridWithModalTarget = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        selectionParams = { stateTo: 'app.nav.surveyslistmodal', destModalKey: 'survey', isMultiselect: true };
        vm.nbsSelectionGridWithModalSurvey = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        vm.nbsAuthorDrbacModalObj = new NbsAssigneeSelectorModal({
            translator: vm.translate,
            suspendDirty: vm.suspendDirty,
            navigationService: navigationService,
            contextUserForSelection: null,
            getServerVarFn: Nembus.Common.Library.server.getServerVar
        });
        vm.nbsAuthorDrbacModalObj.viewUsers();

        vm.surveyLifeStatusList = surveyLifeStatusEnumParsed.list.translated;

        // Grouping selection
        vm.groupingTypes = [];
        //vm.groupingTypes.push({ value: 'ParentCategoryId', name: vm.translate('Category.Category') });
        vm.groupingTypes.push({ value: 'TargetGroupId', name: vm.translate('TargetDto.TargetGroupId') });
        vm.groupingTypes.push({ value: 'TargetTypeId', name: vm.translate('TargetDto.TargetTypeId') });

        // Sorting target selection
        vm.sortingTypes = [];
        vm.sortingTypes.push({ value: 'TargetSerial', name: vm.translate('Code') });
        vm.sortingTypes.push({ value: 'TargetName', name: vm.translate('Name') });

        // Sorting survey selection
        vm.sortingSurveyTypes = [];
        vm.sortingSurveyTypes.push({ value: 'SurveyCode', name: vm.translate('Code') });
        vm.sortingSurveyTypes.push({ value: 'SurveyName', name: vm.translate('Name') });

        // set title of current controller in report definition detail view
        navigationService.setControllerTitle();

        vm.currentReportFilter = audService.dataContext.vhrTargetSurveyKpiReportFormClientDatas.add({
            Id: NbsGuidManager.newGuid(),
            SurveyAnalysisDefinitionId: null
        });
        vm.currentReportFilter.SurveySortedBy = 'SurveyCode';
        vm.currentReportFilter.TargetSortedBy = 'TargetSerial';
        vm.currentReportFilter.IsArchived = null;

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getSurveyAnalysisDefinitions(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.surveyAnalysisDefinitions = results[0];

                    reportService.currentReport.formEntityToValidate = vm.currentReportFilter;
                    reportService.currentReport.prepareReportFilterCallback = setReportFilterData;
                });
        }

        function getSurveyAnalysisDefinitions(forceReload) {
            // 1) SurveyQuestionOption_CompletionPercentRequired => Indice completamento
            // 2) SurveyQuestionOption_ActiveSumWeight => Somma pesi risposte attive
            // 3) SurveyQuestionOption_ActiveSumValue => Somma gravità risposte attive
            // 4) SurveyQuestionOption_ActiveWeightedSumWeightValue => Somma dei valori ponderati Pesi x Gravità
            // 5) SurveyQuestionOption_NormalizedIndexSumWeight => Indice normalizzato della sommatoria dei Pesi 
            // 6) SurveyQuestionOption_NormalizedIndexSumValue => Indice normalizzato della sommatoria delle Gravità
            // 7) SurveyQuestionOption_NormalizedIndexSumWeightXValue => Indice normalizzato della sommatoria ponderata Pesi x Gravità
            // 8) SurveyQuestionOption_ActiveAverageSumWeight => Media aritmetica dei Pesi 
            // 9) SurveyQuestionOption_ActiveAverageSumValue =>Media aritmetica delle Gravità
            // 10) SurveyQuestionOption_ActiveAverageSumWeightXValue => Media aritmetica dei Pesi x Gravità
            // 11) SurveyQuestionOption_ActiveWeightedAverageSumWeightXValue => Media ponderata dei Pesi x Gravità
            var filter = {
                SurveyAnalysisDefinitionIds: [
                    'SurveyQuestionOption_CompletionPercentRequired',
                    'SurveyQuestionOption_ActiveSumWeight',
                    'SurveyQuestionOption_ActiveSumValue',
                    'SurveyQuestionOption_ActiveWeightedSumWeightValue',
                    'SurveyQuestionOption_NormalizedIndexSumWeight',
                    'SurveyQuestionOption_NormalizedIndexSumValue',
                    'SurveyQuestionOption_NormalizedIndexSumWeightXValue',
                    'SurveyQuestionOption_ActiveAverageSumWeight',
                    'SurveyQuestionOption_ActiveAverageSumValue',
                    'SurveyQuestionOption_ActiveAverageSumWeightXValue',
                    'SurveyQuestionOption_ActiveWeightedAverageSumWeightXValue'
                ]
            };
            var defer = $q.defer();

            audService.getSurveyAnalysisDefinitions(filter, forceReload)
                .then(function (data) {
                    vm.surveyAnalysisDefinitions = Lazy(data.results).sortBy((item) => { return item.SurveyAnalysisDefinitionName }).toArray();
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function setReportFilterData() {
            var reportFilterData = new NbsReportFilterData(vm.translate, contextService.virtualContext.cultureInfo, Nembus.Common.Library.converter.date.formatDate);

            // Target filters
            reportFilterData.setDataBySelectionModal('TargetDto', 'TargetDto.TargetDto', vm.nbsSelectionGridWithModalTarget);
            reportFilterData.setDataBySelectionModal('TargetGroupId', 'TargetDto.TargetGroupId', vm.nbsSelectionGridWithModalVhrGroup);
            reportFilterData.setDataBySelectionModal('TargetTypeId', 'TargetDto.TargetTypeId', vm.nbsSelectionGridWithModalVhrType);

            var sortingTarget = vm.translate('Sorting') + ' ' + vm.translate('TargetDto.TargetDto');
            var groupingTarget = vm.translate('Grouping') + ' ' + vm.translate('TargetDto.TargetDto');
            reportFilterData.setDataByObjectList('SortingTarget', sortingTarget, [vm.currentReportFilter.TargetSortedBy], vm.sortingTypes, 'value', ['name']);
            reportFilterData.setDataByObjectList('GroupingTarget', groupingTarget, [vm.currentReportFilter.TargetGroupedBy], vm.groupingTypes, 'value', ['name']);

            // Audit filters
            reportFilterData.setDataByRangeDate('SurveyExpireDate', 'SurveyDto.SurveyExpireDate', vm.currentReportFilter.SurveyExpireDate1, vm.currentReportFilter.SurveyExpireDate2);
            reportFilterData.setDataByRangeDate('SurveyCompileDate', 'SurveyDto.SurveyCompileDate', vm.currentReportFilter.SurveyCompileDateSystem1, vm.currentReportFilter.SurveyCompileDateSystem2);
            reportFilterData.setDataBySelectionModal('SurveyDto', 'SurveyDto.SurveyDto', vm.nbsSelectionGridWithModalSurvey);
            reportFilterData.setDataBySelectionModal('VhrEventGroupId', 'SurveyDto.VhrEventGroupId', vm.nbsSelectionGridWithModalVhrEventGroup);
            reportFilterData.setDataBySelectionModal('VhrEventTypeId', 'SurveyDto.VhrEventTypeId', vm.nbsSelectionGridWithModalVhrEventType);
            reportFilterData.setDataByBoolean('IsArchived', 'SurveyDto.IsArchived', vm.currentReportFilter.IsArchived);
            reportFilterData.setDataByObjectList('SurveyLifeStatusId', 'SurveyDto.SurveyLifeStatusId', [vm.currentReportFilter.SurveyLifeStatusId], vm.surveyLifeStatusList, 'value', ['name']);
            reportFilterData.setDataByDrbacAuthSelectorModal('Compiler', 'SurveyVhrEvent.Compiler', vm.nbsAuthorDrbacModalObj);
            reportFilterData.setDataByObjectList('KPI', 'KPI', [vm.currentReportFilter.SurveyAnalysisDefinitionId], vm.surveyAnalysisDefinitions, 'SurveyAnalysisDefinitionId', ['SurveyAnalysisDefinitionName']);
            var sortingAudit = vm.translate('Sorting') + ' ' + vm.translate('SurveyDto.SurveyDto');
            reportFilterData.setDataByObjectList('SortingAudit', sortingAudit, [vm.currentReportFilter.SurveySortedBy], vm.sortingSurveyTypes, 'value', ['name']);


            // VhrTargetSurveySysInt
            reportService.currentReport.reportArgs = {
                Ds1_Arg1: {//TargetFilter
                    VhrGroupsId: vm.nbsSelectionGridWithModalVhrGroup.selectedIds,
                    VhrTypesId: vm.nbsSelectionGridWithModalVhrType.selectedIds,
                    VirtualHumanResourcesId: vm.nbsSelectionGridWithModalTarget.selectedIds
                },
                Ds1_Arg2: {//SurveyFilter
                    SurveyIds: vm.nbsSelectionGridWithModalSurvey.selectedIds,
                    IsArchived: vm.currentReportFilter.IsArchived
                },
                Ds1_Arg3: {//VhrEventFilter
                    StartDate1: vm.currentReportFilter.SurveyExpireDate1,
                    StartDate1Expression: expressionTypeEnum.GreaterThanOrEqual,
                    StartDate2: vm.currentReportFilter.SurveyExpireDate2,
                    StartDate2Expression: expressionTypeEnum.LessThanOrEqual,
                    VhrEventLifeStatus: vm.currentReportFilter.SurveyLifeStatusId,
                    VhrEventLifeStatusExpression: expressionTypeEnum.Equal,
                    VhrEventTypesId: vm.nbsSelectionGridWithModalVhrEventType.selectedIds,
                    VhrEventGroupsId: vm.nbsSelectionGridWithModalVhrEventGroup.selectedIds,
                    ModuleTargetId: moduleTargetId
                },
                Ds1_Arg4: {//SurveyVhrEventFilter
                    SurveyCompileDateSystem1: vm.currentReportFilter.SurveyCompileDateSystem1,
                    SurveyCompileDateSystem1Expression: expressionTypeEnum.GreaterThanOrEqual,
                    SurveyCompileDateSystem2: vm.currentReportFilter.SurveyCompileDateSystem2,
                    SurveyCompileDateSystem2Expression: expressionTypeEnum.LessThanOrEqual,
                    CompilerUserPersonIds: vm.nbsAuthorDrbacModalObj.appUserAreSelected() ? vm.nbsAuthorDrbacModalObj.appUserMultiselectSelectedIds() : null,
                    ExcludeOnDemand: true
                },
                Ds1_Arg5: {//SurveyAnalysisFilter
                    SurveyAnalysisDefinitionId: vm.currentReportFilter.SurveyAnalysisDefinitionId
                },
                Ds1_Arg6: {//CategoryPathParam
                    Separator: null
                },
                Ds1_Arg7: {//ReportFilterData
                    Datas: reportFilterData.Datas
                },
                Ds1_Arg8: {//QueryOptions
                    GroupedBy: vm.currentReportFilter.TargetGroupedBy,
                    SortedBy: vm.currentReportFilter.TargetSortedBy,
                    SortedBy1: vm.currentReportFilter.SurveySortedBy
                }
            };
        }
    };

    vhrTargetsSurveyKpiListReportForm.$inject = injectParams;
    vhrTargetsSurveyKpiListReportForm.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('vhrTargetsSurveyKpiListReportForm', vhrTargetsSurveyKpiListReportForm);
});
