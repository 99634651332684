﻿'use strict';

define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function assetsTree($q, $scope, $timeout, $injector, foundationService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, foundationService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'treeview');
        ribbon.addHomeTab();
        ribbon.addTab(ribbon.GetAssetViewsTab());
        ribbon.tabIndexSelected = 2;
        vm.ribbonDatacontext = ribbon;

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var sourceEntityNavCode = navigationService.stateParams().sourceEntityNavCode;
        var viewKind = navigationService.stateParams().viewKind;
        
        //#region set ribbon fn navigations
        var navParams = { 'viewKind': viewKind, 'appStoreCode': appStoreCode, 'sourceEntityNavCode': sourceEntityNavCode };
        vm.onRibbonGrid = () => { ribbon.onRibbonGrid_Asset(navParams); };
        vm.onRibbonStream = () => { ribbon.onRibbonStream_Asset(navParams); };
        //#endregion set ribbon fn navigations

        //#region set ribbon enabled buttons
        ribbon.setRibbonStatusButton(ribbon.Buttons.ViewList, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.ViewStream, true);
        ribbon.refreshStatusButtons();
        //#endregion set ribbon enabled buttons


        vm.assetTreeGridDataContext = {
            preloadData: false,
            selectedIds: [],
            viewUiSelector: false,
            initData: {
                grid: {
                    filter: {},
                    params: { isMultiselect: false },
                    events: {
                        rowDblClick: function (args) {
                            navigationService.navigateToAssetTreeDetail(args.data, { appStoreCode: appStoreCode, viewKind: viewKind, viewMode: vm.viewMode });
                        }
                    }
                },
                treeGrid: {
                    events: {
                        rowDblClick: function (args) {
                            navigationService.navigateToAssetTreeDetail(args.data, { appStoreCode: appStoreCode, viewKind: viewKind, viewMode: vm.viewMode });
                        }
                    }
                },
                modeUi: 'TreeGrid'
            }
        };
    }

    assetsTree.$inject = injectParams;
    assetsTree.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('assetsTree', assetsTree);
});