﻿'use strict';
define([], function () {
    var injectParams = [];
    var nbsHtmltodocMasterpageComponent = function () {
        var vm = this;
        vm.headerDatasource = null;
        vm.reportFilterData = null;

        vm.$onChanges = function (changes) {
            if (changes.nbsHeaderDatasource && changes.nbsHeaderDatasource.currentValue) {
                vm.headerDatasource = changes.nbsHeaderDatasource.currentValue;
            }
            if (changes.nbsReportFilterData && changes.nbsReportFilterData.currentValue) {
                vm.reportFilterData = changes.nbsReportFilterData.currentValue;
            }            
        };
    };

    nbsHtmltodocMasterpageComponent.$inject = injectParams;
    angular.module('nbs.rds.module').component('nbsHtmltodocMasterpage', {
        templateUrl: '/rds/common/components/nbsHtmltodocMasterpageComponent/_?c=' + window.codeCache(),
        controller: nbsHtmltodocMasterpageComponent,
        controllerAs: 'vm',
        bindings: {
            nbsHeaderDatasource: "<",
            nbsReportFilterData: "<",
            nbsViewFilters: "@",
            uiContext: "<"
        }
    });
});

