﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$injector', '$timeout', 'rbacService', 'navigationService', 'contextService'];
    function appUsersListModal($q, $injector, $timeout, rbacService, navigationService, contextService) {
        var vm = this;
        nbsVm.call(vm, rbacService, navigationService, contextService, $q, $injector, $timeout);
        
        // modal parameters
        var pParams = navigationService.stateParamsModal().parentParams;
        pParams = pParams ? pParams : navigationService.stateParams();

        vm.gridIsMultiselect = pParams.isMultiselect;

        loadData();
        function loadData() {
            var filter = { appUserFilter: { XCentralUserId: null }, drbacGlobalAppUserSettingFilter: { IsHiddenUser: null, IsServiceUser: null } };
            var supportData = { translate: vm.translate, contextService: contextService };
            var ejDataManager = rbacService.getContextUserDataManager(supportData, filter, ejDataManager);

            var nbsGridDatacontext = new NbsGrid(vm.translate);
            nbsGridDatacontext.configureGrid('contextuserdto');
            nbsGridDatacontext.setModalMode();
            nbsGridDatacontext.allowSearching = true;
            if (pParams.isMultiselect === 'true' || pParams.isMultiselect === true) {
                nbsGridDatacontext.setIsMultiselect();
            }
            nbsGridDatacontext.setDatasource(ejDataManager, true);
            vm.nbsGridDatacontext = nbsGridDatacontext;
        }

        vm.modalOk = function () {
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalOk);
            navigationService.closeCurrentModal(modalResult);
        };

        vm.modalCancel = function () {
            navigationService.closeCurrentModal();
        };

        var currentSelected = [];
        vm.gridSelection = function (args) {
            if (vm.gridIsMultiselect) {
                currentSelected = args.data;
            } else {
                if (args.data) {
                    currentSelected = [args.data];
                } else {
                    currentSelected = [];
                }
            }
        }

        function getModalResult(dialogResult) {
            Lazy(currentSelected)
                .each((item) => {
                    item.nbs_id = item.AppUserId;
                    item.nbs_code = null;
                    item.nbs_name = null;
                });

            var modalResult = new navigationService.modalResult();
            modalResult.data = currentSelected;
            modalResult.selectedValues = Lazy(currentSelected).pluck('AppUserId').toArray();
            modalResult.hasSelection = Lazy(currentSelected).some();
            //modalResult.sourceParams = urlParams;
            modalResult.dialogResult = dialogResult;
            return modalResult;
        }
    }

    appUsersListModal.$inject = injectParams;
    appUsersListModal.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rbac.module').controller('appUsersListModal', appUsersListModal);
});
