import { Inject, Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { AuthenticationService } from '../services/ajs-upgraded.providers';
import { BusyService } from '../services/busy.service';
import { error } from '@angular/compiler-cli/src/transformers/util';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {

    constructor(@Inject(AuthenticationService) private authenticationService, private busyService: BusyService) { }

    intercept(originalRequest: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        this.busyService.addBusy();
        if (originalRequest.url.startsWith("breeze/")) {
            var authData = this.authenticationService.getAuthData();
            if (authData) {
                var request = originalRequest.clone({
                    setHeaders: {
                        Authorization: "Bearer " + authData.access_token,
                        nbs: "3"
                    }
                });

                return next.handle(request)
                    .pipe(
                        tap({
                            next: (event) => {
                                if (event instanceof HttpResponse) {
                                    this.onComplete();
                                }
                            }, error: (error: HttpErrorResponse) => {
                                this.onComplete();
                                throw error;
                            }
                        })
                );

            }
        }

        return next.handle(originalRequest)
            .pipe(
                tap({
                    next: (event) => {
                        if (event instanceof HttpResponse) {
                            this.onComplete();
                        }
                    }, error: (error: HttpErrorResponse) => {
                        this.onComplete();
                        throw error;
                    }
                })
            );
    }


    private onComplete() {
        this.busyService.subBusy();
    }

}

