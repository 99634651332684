﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'jobService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function jobsManage($q, $scope, $timeout, $injector, jobService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, jobService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, jobService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        ribbon.addTab(new ribbon.Tab("ActionPanelTabManage", [ribbon.GetJobsSection()]));
        vm.ribbonDatacontext = ribbon;

        var quartzJobStatusParsedEnum = Nembus.Common.Library.util.parseEnum(jobService.dataContext.enumTypes.QuartzJobStatus, 'QuartzJob', vm.translate);
        var quartzJobStatusParsedEnumObj = quartzJobStatusParsedEnum.obj.translated.value_name;
        vm.quartzJobStatusEnumRev = quartzJobStatusParsedEnum.obj.normal.name_value;
        vm.quartzJobCommandKindEnumRev = Nembus.Common.Library.util.parseEnum(jobService.dataContext.enumTypes.QuartzJobCommandKind, null, vm.translate).obj.normal.name_value;
        vm.quartzJobCommandTypeEnumRev = Nembus.Common.Library.util.parseEnum(jobService.dataContext.enumTypes.QuartzJobCommandType, null, vm.translate).obj.normal.name_value;
        vm.quartzJobCommandStatusEnumRev = Nembus.Common.Library.util.parseEnum(jobService.dataContext.enumTypes.QuartzJobCommandStatus, null, vm.translate).obj.normal.name_value;

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('quartzjob', 'report');
        nbsGridDatacontext.viewCommonBar();
        nbsGridDatacontext.setDatasource([], false); // fake datasource, only to create grid
        vm.nbsGridDatacontext = nbsGridDatacontext;

        ribbon.setRibbonStatusButton(ribbon.Buttons.New, false);
        ribbon.setRibbonStatusButton(ribbon.Buttons.OpenDetail, false);
        ribbon.setRibbonStatusButton(ribbon.Buttons.JobRunning, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.JobSuspend, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.JobHelo, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.RemoteHelo, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.JobStopCheck, true);
        ribbon.refreshStatusButtons();

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getQuartzJobs(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: results[0]
                    }
                });
        }

        vm.commandMonitor = "$:>";

        function writeMonitor(line) {
            vm.commandMonitor += "<br>$:>" + line;
        }

        function clearMonitor() {
            vm.commandMonitor = "<br>$:>";
        }

        vm.refreshAction = function () {
            loadData(true);
        };

        vm.gridSelection = function (args) {
            vm.quartzJobSelected = args.data;
        };

        function AddCommand(commandType) {
            vm.commandCheckIsStopped = false;
            if (vm.commandCheckIsRunning) {
                writeMonitor("Command already sent");
                return;
            }

            if (!vm.quartzJobSelected) {
                writeMonitor("Select one job please");
                return;
            }

            clearMonitor();
            var command = getCommandInstance(commandType);
            vm.currentQuartzCommand = jobService.dataContext
                .quartzCommands
                .add(command);
            writeMonitor("command: " + commandType + " on " + vm.quartzJobSelected.QuartzJobClassName + " #" + command.QuartzCommandCode);
            vm.commandCheckIsRunning = true;
            vm.submitAction(command);
        }

        vm.runningAction = function () {
            $timeout(() => {
                AddCommand("Run");
            });
        };

        vm.suspendAction = function () {
            $timeout(() => {
                AddCommand("Suspend");
            });
        };

        vm.heloAction = function () {
            $timeout(() => {
                AddCommand("Helo");
            });
        };

        vm.remoteHeloAction = function () {
            $timeout(() => {
                AddCommand("RemoteHelo");
            });
        };

        vm.stopCheckResponseAction = function () {
            vm.commandCheckIsStopped = true;
            vm.commandCheckIsRunning = false;
            writeMonitor("stop check response");
        };

        function getCommandInstance(commandType) {
            return {
                QuartzJobClassName: vm.quartzJobSelected.QuartzJobClassName,
                QuartzJobCommandKind: "Command",
                QuartzJobCommandKindId: vm.quartzJobCommandKindEnumRev["Command"],
                QuartzJobCommandType: commandType,
                QuartzJobCommandTypeId: vm.quartzJobCommandTypeEnumRev[commandType],
                QuartzJobCommandStatus: "Inserted",
                QuartzJobCommandStatusId: vm.quartzJobCommandStatusEnumRev["Inserted"],
                QuartzCommandCode: NbsGuidManager.newGuid(),
                CommandSourceName: contextService.virtualContext.CurrentUserDisplayName
            };
        }

        function getQuartzJobs(forceReload) {
            var defer = $q.defer();

            jobService.getQuartzJobs(forceReload)
                .then(function (data) {
                    Lazy(data.results)
                        .each((item) => {
                            item.QuartzJobStatus = quartzJobStatusParsedEnumObj[item.QuartzJobStatusId];
                        });

                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        vm.checkCommandResponse = function (quartzCommandCode, t) {
            if (!t) {
                t = 1500;
            }
            $timeout(function () {

                var maxCheck = 30;
                if (vm.commandToCheck.numOfCheck <= maxCheck
                    && !vm.commandToCheck.response
                    && !vm.commandCheckIsStopped) {

                    writeMonitor("Check command response. Attempt n: " + vm.commandToCheck.numOfCheck);
                    vm.commandToCheck.numOfCheck++;
                    jobService.getQuartzCommands({ QuartzCommandCode: quartzCommandCode }, true)
                        .then(function (data) {
                            var response = Lazy(data.results).findWhere({ QuartzJobCommandKindId: vm.quartzJobCommandKindEnumRev['Response'] });
                            if (response) {
                                vm.commandToCheck.response = response;
                                var objResult = Nembus.Common.Library.converter.JsonFieldParse(response.QuartzCommandText);
                                for (var property in objResult) {
                                    if (objResult.hasOwnProperty(property)) {
                                        writeMonitor(property + ': ' + objResult[property]);
                                    }
                                }
                                vm.refreshAction();
                                vm.commandCheckIsRunning = false;
                            }

                            else {
                                vm.checkCommandResponse(quartzCommandCode, t);
                            }

                        }).catch(function (error) {
                            vm.setErrorMsg(error);
                        });
                }
            }, t);
        };

        // get reference of submitaction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parents = vm.submitAction;
        vm.submitAction = function (quartzCommand) {
            parents().then(
                function (saveResult) {

                    vm.commandToCheck = {
                        QuartzCommand: quartzCommand,
                        numOfCheck: 1
                    };
                    vm.checkCommandResponse(quartzCommand.QuartzCommandCode);
                }
            );
        };

        vm.queryCellInfo = function (args) {
            var $element = $(args.cell);
            switch (args.column.field) {
                case "QuartzJobStatus":
                    var color;
                    switch (args.data.QuartzJobStatusId) {
                        case vm.quartzJobStatusEnumRev["JobReady"]: color = '#95E495'; break;
                        case vm.quartzJobStatusEnumRev["JobBusy"]: color = '#FFCC33'; break;
                        case vm.quartzJobStatusEnumRev["JobSuspended"]: color = '#FF8000'; break;
                    }

                    if (color) {
                        $element.css("background-color", color);
                    }
                    break;

                case 'LastStartDate':
                case 'LastReadyDate':
                case 'LastBusyDate':
                case 'LastSuspendedDate':
                    args.cell.innerHTML = Nembus.Common.Library.converter.date.formatDateTimeLocalToUtc(args.data[args.column.field], contextService.virtualContext.cultureInfo);
                    break;
            }
        };
    }

    jobsManage.$inject = injectParams;
    jobsManage.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('jobsManage', jobsManage);
});