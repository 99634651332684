﻿'use strict';

define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$injector', '$timeout', 'foundationService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function assetsList($q, $scope, $injector, $timeout, foundationService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return assetTreeSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, foundationService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        ribbon.addTab(ribbon.GetAssetViewsTab());
        vm.ribbonDatacontext = ribbon;

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var bucketClassificationId = navigationService.stateParams().BucketClassificationId;
        var assetTreeEntityType = navigationService.stateParams().AssetTreeEntityType;
        var viewKind = navigationService.stateParams().viewKind;
        var assetTreeSelected = null;
        var vhrStatusEnum = Nembus.Common.Library.util.parseEnum(foundationService.dataContext.enumTypes.VhrStatus, null, vm.translate).obj.normal.name_value;

        //#region set ribbon fn navigations
        var navParams = { 'viewKind': viewKind, 'appStoreCode': appStoreCode, 'sourceEntityNavCode': 'AssetTree' };        
        vm.onRibbonStream = () => { ribbon.onRibbonStream_Asset(navParams); };
        vm.onRibbonTree = () => { ribbon.onRibbonTree_Asset(navParams); };
        //#endregion set ribbon fn navigations

        //#region set ribbon enabled buttons
        ribbon.setRibbonStatusButton(ribbon.Buttons.ViewStream, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.ViewTree, true);
        ribbon.refreshStatusButtons();
        //#endregion set ribbon enabled buttons

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('assettree', 'list');
        nbsGridDatacontext.viewCustomBar();
        nbsGridDatacontext.setGridIsRenderedInlineBlock();
        nbsGridDatacontext.setDatasource([], false); // fake datasource, only to create grid
        vm.nbsGridDatacontext = nbsGridDatacontext;

        // hook from gridbar
        var ejDataManager = null;
        var currentAssetTreeFilter = null;
        var currentGridFilter = null;
        vm.assettreeGridBarOnChanges = function (result) {
            currentAssetTreeFilter = result.assetTreeFilter;
            currentAssetTreeFilter.AppStoreBw = null;
            currentAssetTreeFilter.VhrStatusId = vhrStatusEnum.Enabled;
            currentAssetTreeFilter.AssetTreeEntityType = assetTreeEntityType
            currentAssetTreeFilter.BucketClassificationId = bucketClassificationId
            currentGridFilter = { assetTreeFilter: currentAssetTreeFilter };

            if (ejDataManager === null) {
                var supportData = { translate: vm.translate };
                ejDataManager = foundationService.getAssetTreeDataManager(supportData, currentGridFilter, ejDataManager);
                nbsGridDatacontext.setDatasource(ejDataManager, true);
                refreshGridDatasource(currentGridFilter);
            } else {
                refreshGridDatasource(currentGridFilter)
            }
        };

        vm.rowDblClick = function (args) {
            navigationService.navigateToAssetTreeDetail(args.data, { appStoreCode: appStoreCode, viewKind: viewKind, viewMode: vm.viewMode });
        };

        vm.newAction = function () {
            // at moment new VirtualEquipment
            navigationService.navigateTo("app.nav.assetftc", { 'virtualHumanResourceId': null, 'appStoreCodeFtc': appStoreCode, 'viewKindFtc': viewKind, 'viewMode': vm.viewMode });
        };

        vm.refreshAction = function () {
            $timeout(() => {
                vm.forceGridRefresh = new Date().getTime();
            });
        };

        vm.gridSelection = function (args) {
            assetTreeSelected = args.data;

            $timeout(() => {
                if (assetTreeSelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, true);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, false);
                }

                ribbon.refreshStatusButtons();
            });
        };

        vm.copyAction = function () {
            if (!assetTreeSelected) {
                return;
            }

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        var filter = {
                            VirtualHumanResourceId: assetTreeSelected.VirtualHumanResourceId
                        };

                        foundationService.cloneVirtualEquipment(filter)
                            .then(function (result) {
                                vm.refreshAction();
                            });
                    }
                });
        };

        vm.createGridEvent = function (result) {
            vm.assettreeGridBarDataContext = {
                commonBarDatasource: result.barDatasource,
                defaultKeys: {
                    columnsKey: null, sortingKey: null
                }
            };
        };

        vm.gridUiOnChanges = function (result) {
            vm.refreshGridUiComponent = {
                refreshKey: new Date().getTime(),
                uiData: result
            }
        }

        function refreshGridDatasource(currentGridFilter) {
            $timeout(() => {
                vm.forceDatamanagerFilter = {
                    refreshKey: new Date().getTime(),
                    filter: currentGridFilter
                }
            });
        }
    }

    assetsList.$inject = injectParams;
    assetsList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('assetsList', assetsList);
});