﻿'use strict';
define([], function () {

    var injectParams = ['$timeout'];
    var nbsDdComponent = function ($timeout) {

        var vm = this;
        var actionDatasourceKind = null;

        vm.optionsSelected = [];

        vm.$onInit = function () {
            vm.parsedWatermark = vm.nbsDdWatermark ? vm.nbsDdWatermark : '';
        };

        vm.$onChanges = function (changes) {
            if (changes.nbsDataContext && changes.nbsDataContext.currentValue) {
                if (!vm.nbsDdSortField) {
                    vm.nbsDdSortField = 'optionOrder';
                }

                var ds = changes.nbsDataContext.currentValue;
                vm.localDataSourceDd = Lazy(ds).sortBy((uiOption) => { return uiOption[vm.nbsDdSortField]; }).toArray();
            }


            // label visibility
            if (changes.nbsDdShowLabel && changes.nbsDdShowLabel.currentValue) {
                vm.nbsDdShowLabel = changes.nbsDdShowLabel.currentValue === 'true' || changes.nbsDdShowLabel.currentValue === true ? true : false;
            }

            // caret visibility
            if (changes.nbsDdShowCaret && changes.nbsDdShowCaret.currentValue) {
                vm.nbsDdShowCaret = changes.nbsDdShowCaret.currentValue === 'true' || changes.nbsDdShowCaret.currentValue === true ? true : false;
            }

            // icon visibility
            if (changes.nbsDdShowIcon && changes.nbsDdShowIcon.currentValue) {
                vm.nbsDdShowIcon = changes.nbsDdShowIcon.currentValue === 'true' || changes.nbsDdShowIcon.currentValue === true ? true : false;
            }

            // icon visibility
            if (changes.nbsDdShowSelected && changes.nbsDdShowSelected.currentValue) {
                vm.nbsDdShowSelected = changes.nbsDdShowSelected.currentValue === 'true' || changes.nbsDdShowSelected.currentValue === true ? true : false;
            }

            if (changes.nbsDdActionsCode && changes.nbsDdActionsCode.currentValue) {
                switch (changes.nbsDdActionsCode.currentValue) {
                    case 'remove-selection':
                        actionDatasourceKind = 'remove-selection';

                        vm.actionDatasource = [new NbsUiOption({ canCheck: false, optionDisplayName: vm.uiContext.translate('RemoveSelection') })];
                        break;
                    case 'isenabled-boolean-filter':
                        actionDatasourceKind = 'isenabled-boolean-filter';

                        vm.actionDatasource = [
                            new NbsUiOption({ canCheck: true, isChecked: true, optionCode: 'enabled', optionDisplayName: vm.uiContext.translate('Enabled') }),
                            new NbsUiOption({ canCheck: true, isChecked: true, optionCode: 'disabled', optionDisplayName: vm.uiContext.translate('Disabled') })
                        ];
                        break;
                }
            }

            if (changes.nbsDataSelected) {
                var selected = [];
                if (changes.nbsDataSelected.currentValue) {
                    selected = changes.nbsDataSelected.currentValue;
                }

                $timeout(() => {
                    bindSelectedToComponentUI(selected);
                }, 0);
            }
        };
        vm.onItemDelete = function (item) {
            vm.itemDeleteEvent({ option: item });
        };

        vm.clearSelection = function () {
            Lazy(vm.localDataSourceDd)
                .each((cItem) => {
                    cItem.isChecked = false;
                });

            vm.optionsSelected = [];
            setSelectionStyle(vm.optionsSelected);
            sendSelection();
        };

        vm.onItemClick = function (item) {
            if (item.optionStyle === 'divider' || item.optionStyle === 'section') {
                return;
            }

            if (item.optionStyle === 'action') {
                vm.optionsSelected = [item];
            } else {
                if (vm.nbsDdSelectionMode === 'single') {
                    setSelectionSingleMode(item);
                } else if (vm.nbsDdSelectionMode === 'multi') {
                    setSelectionMultiMode(item);
                }
            }

            if (item.optionStyle !== 'action') {
                setSelectionStyle(vm.optionsSelected);
            }

            sendSelection();
        };

        vm.onItemActionClick = function (item) {
            if (item.canCheck) {
                item.isChecked = !item.isChecked;
            }

            if (actionDatasourceKind === 'remove-selection') {
                vm.clearSelection();
                return;
            }

            actionSendSelection();
        };

        function setSelectionSingleMode(item) {
            Lazy(vm.localDataSourceDd).each(cItem => {
                cItem.isChecked = false;
            });
            item.selectionOrder = 1;
            item.isChecked = true;
            vm.optionsSelected = [item];
        }

        function setSelectionMultiMode(item) {

            if (item.isChecked) {
                // removing check
                item.isChecked = false;
                item.selectionOrder = null;

                var order = 0;
                Lazy(vm.localDataSourceDd)
                    .sortBy(uiOption => { return uiOption.selectionOrder; })
                    .each(uiOption => {
                        if (uiOption.selectionOrder) {
                            order++;
                            uiOption.selectionOrder = order;
                        }
                    });


            } else {
                // adding check
                var countSchecked = Lazy(vm.localDataSourceDd).where({ isChecked: true }).toArray().length;
                item.isChecked = true;
                item.selectionOrder = countSchecked + 1;
            }

            setSelectedOptions();
        }

        function refreshLocalDatasourceOrder() {
            var order = 0;
            Lazy(vm.localDataSourceDd)
                .sortBy(uiOption => { return uiOption.selectionOrder; })
                .each(uiOption => {
                    if (uiOption.selectionOrder) {
                        order++;
                        uiOption.selectionOrder = order;
                    }
                });
        }

        // raise selected items (UiOptions)
        function sendSelection() {
            $timeout(() => {
                vm.selectionChangesEvent({ options: vm.optionsSelected });
            }, 0);
        }

        function actionSendSelection() {
            $timeout(() => {
                vm.actionChangesEvent({
                    result: {
                        actionKind: actionDatasourceKind,
                        actions: vm.actionDatasource
                    }
                });
            }, 0);
        }

        function bindSelectedToComponentUI(selected) {
            // if in UiData is present an option removed, then i add them
            Lazy(selected)
                .each((cSelected) => {
                    if (!Lazy(vm.localDataSourceDd).findWhere({ optionValue: cSelected.optionValue })) {
                        vm.localDataSourceDd.push(cSelected);
                    }
                });

            var tempDs = Lazy(vm.localDataSourceDd).toArray();
            var selectedValues = Lazy(selected).pluck('optionValue').toArray();

            vm.localDataSourceDd = [];

            Lazy(tempDs)
                .each((item) => {
                    item.isChecked = Lazy(selectedValues).contains(item.optionValue);
                    if (!item.isChecked) {
                        item.selectionOrder = null;
                    }
                    vm.localDataSourceDd.push(item);
                });

            refreshLocalDatasourceOrder();

            setSelectedOptions();

            setSelectionStyle(vm.optionsSelected);
        }

        function setSelectedOptions() {
            vm.optionsSelected = Lazy(vm.localDataSourceDd)
                .sortBy(uiOption => { return uiOption.selectionOrder; })
                .where({ isChecked: true })
                .toArray();
        }

        function setSelectionStyle(selected) {
            vm.ddSelectedClass = Lazy(selected).some()
                ? 'nbs-dd-watermark-selected'
                : '';

            var selectedText = null;
            if (vm.nbsDdShowSelected) {
                selectedText = Lazy(selected)
                    .filter((o) => { return o.optionStyle !== 'action'; })
                    .pluck('optionDisplayName')
                    .toArray()
                    .join(',');
            }

            if (selectedText) {
                vm.parsedWatermark = selectedText;
            } else {
                vm.parsedWatermark = vm.nbsDdWatermark;
            }
        }
    };

    nbsDdComponent.$inject = injectParams;
    angular.module('nbs.rds.module').component('nbsDd', {
        templateUrl: '/rds/common/components/nbsDdComponent/_?c=' + window.codeCache(),
        controller: nbsDdComponent,
        controllerAs: 'vm',
        bindings: {
            selectionChangesEvent: '&',
            actionChangesEvent: '&',
            itemDeleteEvent: '&',

            nbsDataContext: "<", //[NbsUiOption,NbsUiOption]
            nbsDataSelected: "<",
            nbsDdSelectionMode: '<',    //multi (checkbox), single (no checkbox)
            nbsDdShowIcon: '<',         // true, false
            nbsDdIconCode: '<',         // code to select icon on UI
            nbsDdShowLabel: '<',        // true, false
            nbsDdLabel: '<',            // label value
            nbsDdShowSelected: '<',     // true, false
            nbsDdShowCaret: '<',        // true, false
            nbsDdWatermark: '<',        // visible when no item selected
            nbsDdSortField: '@',        // 'optionOrder,...'

            nbsDdActionsCode: '<',      // true, false

            isVisible: '<',
            uiContext: "<"
        }
    });
});
