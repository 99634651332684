﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'drbacService', 'navigationService', 'checkStateChangeService', 'contextService', 'formSummaryService'];
    function drbacUserToUserGroups($q, $scope, $timeout, $injector, drbacService, navigationService, checkStateChangeService, contextService, formSummaryService) {

        var vm = this;
        nbsVm.call(vm, drbacService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, drbacService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var drbacUserGroupId = navigationService.stateParams().drbacUserGroupId;
        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
        var userGroupXAppUsersSelected = [];


        ribbon.setRibbonStatusButton(ribbon.Buttons.New, false);
        ribbon.setRibbonStatusButton(ribbon.Buttons.Modify, true);
        ribbon.refreshStatusButtons();

        // get array items for form summary
        vm.formSummary = formSummaryService.getDatasource();

        vm.drbacUserGroupM2MAppUsers = [];

        loadData(true);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getDrbacUserGroupM2MAppUserDtosByUserGroup(forceReload, drbacUserGroupId, virtualCompanyId));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.drbacUserGroupM2MAppUsers = results[0];

                    var nbsGridDatacontext = new NbsGrid(vm.translate);
                    nbsGridDatacontext.configureGrid('drbacusergroupM2mappuser', null);
                    nbsGridDatacontext.allowPaging = false;
                    nbsGridDatacontext.allowSearching = true;
                    nbsGridDatacontext.allowGrouping = false;
                    nbsGridDatacontext.setIsMultiselect();
                    nbsGridDatacontext.setDatasource(vm.drbacUserGroupM2MAppUsers, false);
                    vm.nbsGridDatacontext = nbsGridDatacontext;
                });
        }

        vm.gridSelection = function (args) {
            userGroupXAppUsersSelected = args.data;
        };

        vm.deleteAction = function () {
            if (!Lazy(userGroupXAppUsersSelected).some()) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            Lazy(userGroupXAppUsersSelected)
                .each((m2m) => {
                    drbacService.deleteEntity(m2m);
                });

            // submit + refresh
            vm.submitAction();
        };

        vm.newAction = function () {
            navigationService.navToModal(vm.suspendDirty, 'app.nav.appuserslistmodal', { isMultiselect: true })
                .then(function (result) {
                    Lazy(result.data)
                        .each((appUser) => {
                            if (!Lazy(vm.drbacUserGroupM2MAppUsers).where({ AppUserId: appUser.AppUserId }).some()) {
                                var newM2M = drbacService.dataContext.drbacUserGroupM2MAppUserDtos.add({
                                    DrbacUserGroupId: drbacUserGroupId,
                                    AppUserId: appUser.AppUserId
                                });

                                // add to grid list
                                vm.drbacUserGroupM2MAppUsers.push(newM2M);
                            }
                        });

                    vm.submitAction();
                });
        };

        vm.refreshAction = function () {
            getDrbacUserGroupM2MAppUserDtosByUserGroup(true, drbacUserGroupId, virtualCompanyId)
                .then((results) => {
                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: results
                    }
                });
        };

        // get reference of submitaction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parentSubmitAction = vm.submitAction;
        vm.submitAction = function () {
            parentSubmitAction().then(
                function (saveResult) {
                    vm.refreshAction();
                }
            );
        };

        // get reference of modifyAction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parentModifyAction = vm.modifyAction;
        vm.modifyAction = function () {
            parentModifyAction().then(
                function (saveResult) {
                    if (vm.viewContext.isEditingState) {
                        ribbon.setRibbonStatusButton(ribbon.Buttons.New, true);
                        ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, true);
                    } else {
                        ribbon.setRibbonStatusButton(ribbon.Buttons.New, false);
                        ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);

                        vm.refreshAction();
                    }

                    ribbon.refreshStatusButtons();
                }
            );
        };

        function getDrbacUserGroupM2MAppUserDtosByUserGroup(forceReload, drbacUserGroupId, virtualCompanyId) {
            var filter = {
                VirtualCompanyId: virtualCompanyId,
                DrbacUserGroupId: drbacUserGroupId
            };
            var defer = $q.defer();

            drbacService.getDrbacUserGroupM2MAppUserDtosByUserGroup(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    }

    drbacUserToUserGroups.$inject = injectParams;
    drbacUserToUserGroups.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rbac.module').controller('drbacUserToUserGroups', drbacUserToUserGroups);
});