﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'navigationService', 'checkStateChangeService', 'contextService', 'formSummaryService'];
    var hrLegalGeneralityDetail = function ($q, $scope, $timeout, $injector, foundationService, navigationService, checkStateChangeService, contextService, formSummaryService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, foundationService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        // get array items for form summary
        vm.formSummary = formSummaryService.getDatasource();

        var hrLegalGeneralityId = navigationService.stateParams().hrLegalGeneralityId;
        var filter = { LegalGeneralityId: hrLegalGeneralityId };

        foundationService.getHrLegalGenerality(filter, true)
            .then(function (data) {
                vm.currentHrLegalGenerality = data.results[0];
            })
            .catch(function (error) {
                vm.setErrorMsg(error);
            });
    };

    hrLegalGeneralityDetail.$inject = injectParams;
    hrLegalGeneralityDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('hrLegalGeneralityDetail', hrLegalGeneralityDetail);
});