﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$injector', '$scope', '$timeout', 'foundationService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function batchRequestsList($q, $injector, $scope, $timeout, foundationService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, foundationService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, null);
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('batchrequest', null);
        nbsGridDatacontext.viewCustomBar();
        nbsGridDatacontext.setGridIsRenderedInlineBlock();
        nbsGridDatacontext.setDatasource([], true); // fake datasource, only to create grid
        vm.nbsGridDatacontext = nbsGridDatacontext;

        ribbon.setRibbonStatusButton(ribbon.Buttons.Refresh, true);
        ribbon.refreshStatusButtons();

        // hook from gridbar
        var ejDataManager = null;
        var currentBatchRequestFilter = null;
        vm.batchRequestGridBarOnChanges = function (result) {
            currentBatchRequestFilter = result.batchRequestFilter;

            if (ejDataManager === null) {
                var supportData = { translate: vm.translate, contextService: contextService };
                ejDataManager = foundationService.getBatchRequestDataManager(supportData, currentBatchRequestFilter, ejDataManager);
                nbsGridDatacontext.setDatasource(ejDataManager, true);
                refreshGridDatasource(currentBatchRequestFilter);
            } else {
                refreshGridDatasource(currentBatchRequestFilter)
            }
        };

        function refreshGridDatasource(currentBatchRequestFilter) {
            $timeout(() => {
                vm.forceDatamanagerFilter = {
                    refreshKey: new Date().getTime(),
                    filter: currentBatchRequestFilter
                }
            });
        }

        vm.refreshAction = function () {
            $timeout(() => {
                vm.forceGridRefresh = new Date().getTime();
            });
        };

        vm.createGridEvent = function (result) {
            vm.batchRequestGridBarDataContext = {
                commonBarDatasource: result.barDatasource,
                uiMode: null,
                defaultKeys: {
                    columnsKey: null, sortingKey: null
                }
            };
        };

        vm.gridUiOnChanges = function (result) {
            vm.refreshGridUiComponent = {
                refreshKey: new Date().getTime(),
                uiData: result
            }
        }
    }

    batchRequestsList.$inject = injectParams;
    batchRequestsList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('batchRequestsList', batchRequestsList);
});