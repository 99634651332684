import { Injectable } from "@angular/core";

@Injectable()
export class BusyService {

    isBusy: boolean = false;
    private busyCounter: number = 0;

    constructor() { }

    addBusy() {
        this.busyCounter++;
        this.checkIsBusy();
    }

    subBusy() {
        this.busyCounter--;
        this.checkIsBusy();
    }

    checkIsBusy() {
        this.isBusy = this.busyCounter > 0 ? true : false;
        if (this.isBusy)
            $('#loading').show();
        else
            $('#loading').hide();
    }

}
