﻿'use strict';

define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'vhreventService', 'productionService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function vhrEventsManualStepEventDetail($q, $scope, $timeout, $injector, foundationService, vhreventService, productionService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, foundationService);

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);

        var isBom = false;
        var vhrEventKindEnum = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventKind, null, vm.translate).obj.normal.name_value;

        var pParams = navigationService.stateParamsModal().parentParams;
        pParams = pParams ? pParams : navigationService.stateParams();

        // navigation buttons visibility
        vm.setWizardButtonVisibility(pParams);

        // beardcrumb information
        var beardCrumbInfo = JSON.parse(pParams.beardCrumbInfo);
        Lazy(beardCrumbInfo.items).each((item) => { item.title = vm.translate(item.title); });
        vm.beardCrumbInfo = beardCrumbInfo;

        vm.vhrEventManualWhatClientData = vhreventService.dataContext.vhrEventManualWhatClientDatas.add({
            VhrEventManualWhatClientDataId: NbsGuidManager.newGuid()
        });

        vm.isActivitySelected = false;
        vm.isVhrEventSelected = false;
        if (vhreventService.vhrEventManualCollector.VhrEvents && Lazy(vhreventService.vhrEventManualCollector.VhrEvents).toArray().length === 1) {
            vm.isVhrEventSelected = true;
        }

        if (vhreventService.vhrEventManualCollector.ActivityId) {
            // create event using activity selected by UI and disable UI fields
            vm.isActivitySelected = true;
        }

        // refill data form
        if (vhreventService.vhrEventManualCollector) {
            vm.vhrEventManualWhatClientData.Code = vhreventService.vhrEventManualCollector.Code;
            vm.vhrEventManualWhatClientData.Name = vhreventService.vhrEventManualCollector.Name;
            vm.vhrEventManualWhatClientData.Description = vhreventService.vhrEventManualCollector.Description;
            vm.vhrEventManualWhatClientData.ExternalCode = vhreventService.vhrEventManualCollector.ExternalCode;
            vm.vhrEventManualWhatClientData.ModuleTargetId = vhreventService.vhrEventManualCollector.ModuleTargetId;
            vm.vhrEventManualWhatClientData.VhrEventTypeId = vhreventService.vhrEventManualCollector.VhrEventTypeId;
            vm.vhrEventManualWhatClientData.VhrEventGroupId = vhreventService.vhrEventManualCollector.VhrEventGroupId;
            vm.vhrEventManualWhatClientData.WorkCenterId = vhreventService.vhrEventManualCollector.WorkCenterId;
            vm.vhrEventManualWhatClientData.VhrEventTemplateId = vhreventService.vhrEventManualCollector.VhrEventTemplateId;
            vm.vhrEventManualWhatClientData.RecurrenceStartDate = vhreventService.vhrEventManualCollector.RecurrenceStartDate;
            vm.vhrEventManualWhatClientData.EventStartTime = vhreventService.vhrEventManualCollector.EventStartTime;
            vm.vhrEventManualWhatClientData.RecurrenceEndDate = vhreventService.vhrEventManualCollector.RecurrenceEndDate;
            vm.vhrEventManualWhatClientData.EventEndTime = vhreventService.vhrEventManualCollector.EventEndTime;
            vm.vhrEventManualWhatClientData.IsRecurrent = vhreventService.vhrEventManualCollector.IsRecurrent;
            vm.vhrEventManualWhatClientData.VhrEventIds = vhreventService.vhrEventManualCollector.VhrEventIds;
        }

        var moduleTargets = Nembus.Rds.Library.Tools.ModuleTargetContext({ AppStoreBw: appStoreBw, ModuleTargetTypeCode: 'DEFAULT' }, contextService);
        vm.moduleTargetsAll = Lazy(moduleTargets).reject({ ModuleTargetCode: 'MTSEM' }).toArray();

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            $q.all(funcsPromises)
                .then(function () {
                    if (vm.vhrEventManualWhatClientData.ModuleTargetId !== null
                        && vm.vhrEventManualWhatClientData.ModuleTargetId !== undefined) {

                        loadModuleTargetDependentData(true, vm.vhrEventManualWhatClientData.ModuleTargetId, appStoreBw, isBom);
                    }
                });
        }

        vm.modalNext = function () {
            // if NOT event is recurrent, startdate and enddate must be the same
            if (!vm.vhrEventManualWhatClientData.IsRecurrent) {
                vm.vhrEventManualWhatClientData.RecurrenceEndDate = vm.vhrEventManualWhatClientData.RecurrenceStartDate;
            }

            //force validation on entity (manual metadata only client)
            vm.vhrEventManualWhatClientData.entityAspect.validateEntity();
            if (!vm.vhrEventManualWhatClientData.entityAspect.hasValidationErrors) {
                vhreventService.rejectChanges();

                // close modal and returns client data object
                var modalResult = getModalResult(navigationService.navigationActionTypes.modalNext);
                navigationService.closeCurrentModal(modalResult);
            }
        };

        vm.modalPrevious = function () {
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalPrevious);
            vhreventService.rejectChanges();
            navigationService.closeCurrentModal(modalResult);
        };

        vm.modalCancel = function () {
            navigationService.confirmExitWizard()
                .then(function (result) {
                    if (result) {
                        vhreventService.rejectChanges();
                        var modalResult = getModalResult(navigationService.navigationActionTypes.modalCancel);
                        navigationService.closeCurrentModal(modalResult);
                    }
                });
        };

        vm.moduleTargetsOnChange = function () {
            var appStoreBw = Lazy(vm.moduleTargetsAll).findWhere({ ModuleTargetId: vm.vhrEventManualWhatClientData.ModuleTargetId }).AppStoreBw;
            loadModuleTargetDependentData(true, vm.vhrEventManualWhatClientData.ModuleTargetId, appStoreBw, isBom);
        };

        function loadModuleTargetDependentData(forceReload, moduleTargetId, appStoreBw, isBom) {
            var funcsPromises = [];
            funcsPromises.push(getVhrEventTemplates(forceReload, moduleTargetId, vhrEventKindEnum.Data));
            funcsPromises.push(getVhrEventTypes(forceReload, moduleTargetId, isBom));
            funcsPromises.push(getVhrEventGroups(forceReload, moduleTargetId, isBom));
            funcsPromises.push(getVirtualWorkCenters(forceReload, appStoreBw));

            $q.all(funcsPromises)
                .then(function () {
                    //selectDefaultData(isBom);
                });
        }

        function getVirtualWorkCenters(forceReload, appStoreBw) {
            var filter = { AppStoreBw: appStoreBw };
            var defer = $q.defer();

            productionService.getVirtualWorkCenters(filter, forceReload)
                .then(function (data) {
                    vm.workCenters = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrEventTemplates(forceReload, moduleTargetId, vhrEventKindId) {
            var moduleTargetAppStoreBw = moduleTargetId
                ? Lazy(vm.moduleTargetsAll).findWhere({ ModuleTargetId: Number(moduleTargetId) }).AppStoreBw
                : null;

            var filter = { AppStoreBw: moduleTargetAppStoreBw, VhrEventKindId: vhrEventKindId };
            var defer = $q.defer();

            vhreventService.getVhrEventTemplates(filter, forceReload)
                .then(function (data) {
                    vm.vhrEventTemplates = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrEventTypes(forceReload, moduleTargetId, isBom) {
            var filter = { ModuleTargetId: moduleTargetId, IsBomType: isBom };
            var defer = $q.defer();

            vhreventService.getVhrEventTypes(filter, forceReload)
                .then(function (data) {
                    vm.vhrEventTypes = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrEventGroups(forceReload, moduleTargetId, isBom) {
            var filter = { ModuleTargetId: moduleTargetId, IsBomGroup: isBom };
            var defer = $q.defer();

            vhreventService.getVhrEventGroups(filter, forceReload)
                .then(function (data) {
                    vm.vhrEventGroups = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getModalResult(dialogResult) {
            var modalResult = new navigationService.modalResult();
            var moduleTargetName = vm.vhrEventManualWhatClientData.ModuleTargetId
                ? Lazy(vm.moduleTargetsAll).findWhere({ ModuleTargetId: Number(vm.vhrEventManualWhatClientData.ModuleTargetId) }).ModuleTargetName
                : null;
            var workCenterName = vm.vhrEventManualWhatClientData.WorkCenterId
                ? Lazy(vm.workCenters).findWhere({ VirtualWorkCenterId: Number(vm.vhrEventManualWhatClientData.WorkCenterId) }).WorkCenterName
                : null;
            var vhrEventTemplateName = vm.vhrEventManualWhatClientData.VhrEventTemplateId
                ? Lazy(vm.vhrEventTemplates).findWhere({ VhrEventTemplateId: Number(vm.vhrEventManualWhatClientData.VhrEventTemplateId) }).VhrEventTemplateName
                : null;
            var vhrEventTypeName = vm.vhrEventManualWhatClientData.VhrEventTypeId
                ? Lazy(vm.vhrEventTypes).findWhere({ VhrEventTypeId: Number(vm.vhrEventManualWhatClientData.VhrEventTypeId) }).VhrEventTypeName
                : null;
            var vhrEventGroupName = vm.vhrEventManualWhatClientData.VhrEventGroupId
                ? Lazy(vm.vhrEventGroups).findWhere({ VhrEventGroupId: Number(vm.vhrEventManualWhatClientData.VhrEventGroupId) }).VhrEventGroupName
                : null;

            var resultData = {
                Code: vm.vhrEventManualWhatClientData.Code,
                Name: vm.vhrEventManualWhatClientData.Name,
                Description: vm.vhrEventManualWhatClientData.Description,
                ExternalCode: vm.vhrEventManualWhatClientData.ExternalCode,
                ModuleTargetId: vm.vhrEventManualWhatClientData.ModuleTargetId,
                ModuleTargetName: moduleTargetName,
                VhrEventGroupId: vm.vhrEventManualWhatClientData.VhrEventGroupId,
                VhrEventGroupName: vhrEventGroupName,
                VhrEventTypeId: vm.vhrEventManualWhatClientData.VhrEventTypeId,
                VhrEventTypeName: vhrEventTypeName,
                WorkCenterId: vm.vhrEventManualWhatClientData.WorkCenterId,
                WorkCenterName: workCenterName,
                VhrEventTemplateId: vm.vhrEventManualWhatClientData.VhrEventTemplateId,
                VhrEventTemplateName: vhrEventTemplateName,
                RecurrenceStartDate: vm.vhrEventManualWhatClientData.RecurrenceStartDate,
                EventStartTime: vm.vhrEventManualWhatClientData.EventStartTime,
                RecurrenceEndDate: vm.vhrEventManualWhatClientData.RecurrenceEndDate,
                EventEndTime: vm.vhrEventManualWhatClientData.EventEndTime,
                IsRecurrent: vm.vhrEventManualWhatClientData.IsRecurrent
            };

            modalResult.data = resultData;
            modalResult.sourceParams = pParams;

            // Important: permits to skip nexts steps (on next and previous)
            modalResult.stepsToSkip = [];
            if (!vm.vhrEventManualWhatClientData.IsRecurrent) {
                modalResult.stepsToSkip.push('cronExpressionDetail');            // skip cron expression
            }

            modalResult.dialogResult = dialogResult;
            return modalResult;
        }
    }

    vhrEventsManualStepEventDetail.$inject = injectParams;
    vhrEventsManualStepEventDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('vhrEventsManualStepEventDetail', vhrEventsManualStepEventDetail);
});