﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'audService', 'vhreventService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var surveyTemplateGroupDetail = function ($q, $scope, $timeout, $injector, audService, vhreventService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, audService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, audService);

        var ribbon = new NbsAudRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var currentSurveyTemplateGroupId = navigationService.stateParams().surveyTemplateGroupId;

        if (currentSurveyTemplateGroupId) {
            vm.setIsViewing();

            loadData(true);
        }
        else {
            vm.setIsAdding();
            vm.currentSurveyTemplateGroup = audService.dataContext.surveyTemplateGroups.add({
                VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId
            });
        }

        // get reference of submitaction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parents = vm.submitAction;
        vm.submitAction = function () {
            parents().then(
                function (saveResult) {
                    if (saveResult.entities.length > 0) {

                        if (vm.viewContext.isAddingState) {
                            // add copy for survey
                            vm.currentVhrEventGroup = vhreventService.dataContext.vhrEventGroups.add({
                                VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId,
                                VhrEventGroupCode: vm.currentSurveyTemplateGroup.SurveyTemplateGroupCode,
                                VhrEventGroupName: vm.currentSurveyTemplateGroup.SurveyTemplateGroupName,
                                VhrEventGroupDescription: vm.currentSurveyTemplateGroup.SurveyTemplateGroupDescription,
                                ModuleTargetId: moduleTargetId
                            });

                            // submit copy for survey (VhrEventGroup)
                            vhreventService.saveChanges().then(function () {
                                navigationService.navigateTo("app.nav.surveytemplategroupdetail", { 'surveyTemplateGroupId': vm.currentSurveyTemplateGroup.SurveyTemplateGroupId, 'appStoreCode': appStoreCode });
                            });
                        } else {
                            // add copy for survey
                            vm.currentVhrEventGroup.VhrEventGroupCode = vm.currentSurveyTemplateGroup.SurveyTemplateGroupCode;
                            vm.currentVhrEventGroup.VhrEventGroupName = vm.currentSurveyTemplateGroup.SurveyTemplateGroupName;
                            vm.currentVhrEventGroup.VhrEventGroupDescription = vm.currentSurveyTemplateGroup.SurveyTemplateGroupDescription;

                            // update copy 
                            vhreventService.saveChanges();
                        }
                    }
                }
            );
        };

        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getSurveyTemplateGroup(forceReload, currentSurveyTemplateGroupId));

            $q.all(funcsPromises)
                .then(function () {
                    getVhrEventGroup(forceReload, vm.currentSurveyTemplateGroup.SurveyTemplateGroupCode);
                });
        }

        function getVhrEventGroup(forceReload, vhrEventGroupCode) {
            var filter = { VhrEventGroupCode: vhrEventGroupCode };
            var defer = $q.defer();

            vhreventService.getVhrEventGroup(filter, forceReload)
                .then(function (data) {
                    vm.currentVhrEventGroup = data.results[0];
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getSurveyTemplateGroup(forceReload, surveyTemplateGroupId) {
            var filter = { SurveyTemplateGroupId: surveyTemplateGroupId };
            var defer = $q.defer();

            audService.getSurveyTemplateGroup(filter, forceReload)
                .then(function (data) {
                    vm.currentSurveyTemplateGroup = data.results[0];
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    surveyTemplateGroupDetail.$inject = injectParams;
    surveyTemplateGroupDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('surveyTemplateGroupDetail', surveyTemplateGroupDetail);
});