﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'reportService', 'utilService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function reportEntityDefinitionsList($q, $scope, $timeout, $injector, reportService, utilService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, reportService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return reportSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, reportService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var expressionTypeParsed = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.ExpressionType, null, vm.translate);
        var reportEngineTypeParsed = Nembus.Common.Library.util.parseEnum(reportService.dataContext.enumTypes.ReportEngineType, 'ReportDefinition', vm.translate);
        var reportSelected = null;

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('reportdefinition', 'reportEntity');
        nbsGridDatacontext.viewCustomBar();
        nbsGridDatacontext.setGridIsRenderedInlineBlock();
        nbsGridDatacontext.setDatasource([], false); // fake datasource, only to create grid
        vm.nbsGridDatacontext = nbsGridDatacontext;

        ribbon.setRibbonStatusButton(ribbon.Buttons.New, false);
        ribbon.refreshStatusButtons();

        // hook from gridbar
        var ejDataManager = null;
        var currentGridFilter = null;
        vm.reportBarOnChanges = function (result) {
            // save in view current filter passed by survey bar
            result.reportDefinitionFilter.AppStoreBw = appStoreBw;
            result.reportDefinitionFilter.CmsViewRenderFormIdExpression = expressionTypeParsed.obj.normal.name_value.NotEqual;
            result.reportDefinitionFilter.ReportEngineTypeIds = [reportEngineTypeParsed.obj.normal.name_value.AutoTag, reportEngineTypeParsed.obj.normal.name_value.CrystalReport];
            result.reportDefinitionFilter.IsCurrentContext = true;
            currentGridFilter = result.reportDefinitionFilter;

            if (ejDataManager === null) {
                var supportData = { translate: vm.translate, contextService: contextService };
                ejDataManager = reportService.getReportDataManager(supportData, currentGridFilter, ejDataManager);
                nbsGridDatacontext.setDatasource(ejDataManager, true);
                refreshGridDatasource(currentGridFilter);
            } else {
                refreshGridDatasource(currentGridFilter)
            }
        };

        vm.rowDblClick = function (args) {
            navigationService.navigateTo('app.nav.reportentitydefinitiondetail-ats', { 'reportDefinitionId': args.data.ReportDefinitionId });
        };

        vm.refreshAction = function () {
            $timeout(() => {
                vm.forceGridRefresh = new Date().getTime();
            });
        };

        vm.deleteAction = function () {
            reportService.deleteReportDefinition({ ReportDefinitionId: reportSelected.ReportDefinitionId })
                .then(function (data) {
                    var intResult = Number(data.results[0]);
                    if (intResult === 1) {
                        vm.refreshAction();
                    } else {
                        navigationService.customMessageModal(vm.translate('SubmittedError'));
                    }
                });
        };

        vm.copyAction = function () {
            vm.modalInstance = navigationService.modal().open(vm.modalOptions({
                windowClass: vm.NBS_MODAL_CLASS_FORM_MD,
                templateUrl: 'modalReportTemplate',
                scope: $scope
            }));
            vm.modalInstance.datasource = reportService.dataContext.reportEntityDefinitionClientDatas.add({
                ReportDefinitionId: reportSelected.ReportDefinitionId,
                ReportDefinitionReferenceCode: null,
                ReportDefinitionName: reportSelected.ReportDefinitionName
            });
            vm.modalInstance.result.then(function () {
                // clone action post
                reportService.cloneReportDefinition({
                    ReportDefinitionId: vm.modalInstance.datasource.ReportDefinitionId,
                    ReportDefinitionReferenceCode: vm.modalInstance.datasource.ReportDefinitionReferenceCode,
                    ReportDefinitionName: vm.modalInstance.datasource.ReportDefinitionName
                }).then(function (data) {
                    var intResult = Number(data.results[0]);
                    if (intResult === 1) {
                        vm.modalInstance.datasource.entityAspect.rejectChanges();
                        vm.refreshAction();
                    } else {
                        navigationService.customMessageModal(vm.translate('SubmittedError'));
                    }
                });
            }, function () {
                //dismiss
                vm.modalInstance.datasource.entityAspect.rejectChanges();
            });
            vm.modalInstance.ok = function () {
                vm.modalInstance.datasource.entityAspect.validateEntity();
                if (!vm.modalInstance.datasource.entityAspect.hasValidationErrors) {
                    vm.modalInstance.close();
                }
            }
            vm.modalInstance.cancel = function () {                
                vm.modalInstance.dismiss();
            }
        };

        vm.gridSelection = function (args) {
            reportSelected = args.data;

            $timeout(() => {
                if (reportSelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, true);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, !reportSelected.IsSystem);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, false);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                }

                ribbon.refreshStatusButtons();
            });
        };

        vm.createGridEvent = function (result) {
            vm.reportBarDataContext = {
                commonBarDatasource: result.barDatasource,
                appStoreBw: appStoreBw,
                defaultKeys: {
                    columnsKey: null, sortingKey: null
                },
                UIkey: 'reportEntity'
            };
        };

        vm.gridUiOnChanges = function (result) {
            vm.refreshGridUiComponent = {
                refreshKey: new Date().getTime(),
                uiData: result
            }
        }

        function refreshGridDatasource(currentGridFilter) {
            $timeout(() => {
                vm.forceDatamanagerFilter = {
                    refreshKey: new Date().getTime(),
                    filter: currentGridFilter
                }
            });
        }
    }

    reportEntityDefinitionsList.$inject = injectParams;
    reportEntityDefinitionsList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('reportEntityDefinitionsList', reportEntityDefinitionsList);
});