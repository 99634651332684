// @ts-ignore
var Lazy = require('lazy.js');
import { NbsCommonAppModel, NbsCommonAuth, NbsCommonCms, NbsCommonConverter, NbsCommonReport, NbsCommonUI, NbsRadioListBoolean } from "../nbs-common.library";

export class NbsVm2 {
    private reportService: any = null;
    private integrationService: any = null;

    // currentDataService => current controller service used to post data
    currentDataService: any = null;
    navigationService: any = null;
    contextService: any = null;
    $q: any = null;
    $timeout: any = null;

    viewContext: any = {};
    cancelBtnIsVisible: boolean = false;
    prevBtnIsVisible: boolean = false;
    nextBtnIsVisible: boolean = false;
    okBtnIsVisible: boolean = false;
    endBtnIsVisible: boolean = false;
    isMobileTemplate: any;
    isDirtySusp: boolean = false;
    isDirtyDisabled: boolean = false;
    exportGrid: any = null;
    viewMode: any = null;
    currentState: string = '';
    //in view with "grid","calendar" etc.. set from controller.. when switch visualition mode => set this param
    visualizationKey: string = '';
    currentCulture: string = '';
    myCmsViewCode: string = '';
    myCmsViewMode: string = '';
    viewTitle: string = '';
    myState: string = '';
    hasHierarchyConfig: boolean = false;
    uiContext: any = null;
    cultureInfo: any = {};
    currentReportData: any = {};
    ribbonContext: any = {};
    //protected stateProvider: any = null;

    constructor(currentDataService: any, $injector: any) {
        this.navigationService = $injector.get('navigationService');
        this.contextService = $injector.get('contextService');
        this.currentDataService = currentDataService;
        this.reportService = $injector.get('reportService');
        this.integrationService = $injector.get('integrationService');
        //this.stateProvider = $injector.get('$stateProvider');

        this.$q = $injector.get('$q');
        this.$timeout = $injector.get('$timeout');

        this.viewContext = {
            states: { isViewing: 1, isAdding: 2, isEditing: 3 },
            state: 1,
            isLocked: true,
            isViewingState: false,
            isAddingState: false,
            isEditingState: false,
        };

        this.isMobileTemplate = this.navigationService.isMobileTemplate();
        this.viewMode = this.navigationService.currentViewMode();
        this.currentState = this.navigationService.currentState();

        this.currentCulture = this.contextService.virtualContext.CurrentLanguageName;
        this.myCmsViewCode = this.navigationService.currentCmsViewCode();
        this.myCmsViewMode = this.navigationService.currentViewMode();
        this.viewTitle = this.navigationService.currentCmsViewTitle();
        this.myState = this.navigationService.currentState();

        this.hasHierarchyConfig = Lazy(this.contextService.virtualContext.HrHierarchyConfigs)
            .where({ VirtualCompanyId: this.contextService.virtualContext.CurrentVirtualCompanyId })
            .some();

        this.uiContext = {
            visualizationKey: this.visualizationKey ? this.visualizationKey : 'grid',
            suspendDirty: this.suspendDirty,
            txtMe: (sourceTxt) => { return sourceTxt; },
            // @ts-ignore
            translate: (key) => { return window.$T(this.myCmsViewCode, key); },
            // datepicker
            getDpIsOpen: this.getDpIsOpen,
            setDpIsOpen: this.setDpIsOpen
        };

        // In checkStateChangeService when dirty is disabled, leaving view don't trigger message for entities changed
        // and current changes were rejected (from service passed to checkStateChangeService)
        if (this.currentState) {
            if (this.viewMode !== '_' && this.currentState.toLowerCase().endsWith('reportform' + '-' + this.viewMode)) {
                this.isDirtyDisabled = true;
            } else if (this.viewMode === '_' && this.currentState.toLowerCase().endsWith('reportform')) {
                this.isDirtyDisabled = true;
            }
        }

        // used in syncfusion grid to format type of data in columns
        if (this.contextService.virtualContext && this.contextService.virtualContext.cultureInfo) {
            this.cultureInfo.ejFormatDate = "{0: " + this.contextService.virtualContext.cultureInfo.formatDate + "}";
            this.cultureInfo.ejFormatTime = "{0: " + this.contextService.virtualContext.cultureInfo.formatTime + "}";
            this.cultureInfo.ejFormatDateTime = "{0: " + this.contextService.virtualContext.cultureInfo.formatDateTime + "}";
        }

        this.loadTranslatedValidationMessages(this.currentDataService);
    };

    months = [
        // @ts-ignore
        { value: 0, name: $TG('January') },
        // @ts-ignore
        { value: 1, name: $TG('February') },
        // @ts-ignore
        { value: 2, name: $TG('March') },
        // @ts-ignore
        { value: 3, name: $TG('April') },
        // @ts-ignore
        { value: 4, name: $TG('May') },
        // @ts-ignore
        { value: 5, name: $TG('June') },
        // @ts-ignore
        { value: 6, name: $TG('July') },
        // @ts-ignore
        { value: 7, name: $TG('August') },
        // @ts-ignore
        { value: 8, name: $TG('September') },
        // @ts-ignore
        { value: 9, name: $TG('October') },
        // @ts-ignore
        { value: 10, name: $TG('November') },
        // @ts-ignore
        { value: 11, name: $TG('December') }
    ];

    //#region HOOK FROM RIBBON COMPONENT
    onRibbonComponentAction(args) {
        if (!args) {
            return;
        }

        if (!args.method) {
            return;
        }

        if (args.method.startsWith('window.') || args.method.startsWith('Nembus.')) {
            var fn = eval(args.method);
            if (typeof fn === "function") {
                fn();
            }
        } else {
            if (self[args.method] && typeof self[args.method] === "function") {
                this[args.method]();
            }
        }
    }
    //#endregion

    //Manage open close datepicker selection date modal
    dpIsOpen = {};
    getDpIsOpen(id, index) {
        if (!index) {
            index = '';
        }

        if (!this.dpIsOpen[id + index]) {
            this.dpIsOpen[id + index] = { isOpen: false };
        }
        return this.dpIsOpen[id + index];
    };

    setDpIsOpen(id, index) {
        if (!index) {
            index = '';
        }

        this.dpIsOpen[id + index].isOpen = true;
    };

    suspendDirty(isSuspended) {
        this.isDirtySusp = isSuspended;
    };

    isDirtySuspended() {
        return this.isDirtySusp;
    };

    //used in component bind '<' when text is required
    txtMe(sourceTxt) {
        return sourceTxt;
    };

    // centralized management button visibility in wizards controller
    setWizardButtonVisibility(pParams, forceVisibility) {
        if (forceVisibility === null || forceVisibility === undefined) {
            this.cancelBtnIsVisible = pParams.cancelBtnIsVisible !== undefined ? pParams.cancelBtnIsVisible : true;
            this.prevBtnIsVisible = pParams.prevBtnIsVisible !== undefined ? pParams.prevBtnIsVisible : false;
            this.nextBtnIsVisible = pParams.nextBtnIsVisible !== undefined ? pParams.nextBtnIsVisible : false;
            this.okBtnIsVisible = pParams.okBtnIsVisible !== undefined ? pParams.okBtnIsVisible : true;
            this.endBtnIsVisible = pParams.endBtnIsVisible !== undefined ? pParams.endBtnIsVisible : true;
        } else {
            this.cancelBtnIsVisible = forceVisibility;
            this.prevBtnIsVisible = forceVisibility;
            this.nextBtnIsVisible = forceVisibility;
            this.okBtnIsVisible = forceVisibility;
            this.endBtnIsVisible = forceVisibility;
        }
    };

    // Wrapper to use checkGlobalPermissionCode in controllers and views
    checkGlobalPermissionCode(permission, virtualContext) {
        return NbsCommonAuth.checkSingleProcessPermission(virtualContext.GlobalProcessPermissions, permission, virtualContext);
    };

    checkPermissionCode(permission, virtualContext) {
        var valuePermission = NbsCommonAuth.checkSingleProcessPermission(virtualContext.GlobalProcessPermissions, permission, virtualContext);
        if (valuePermission <= 0) {
            if (virtualContext.ProcessPermissions && virtualContext.ProcessPermissions.length > 0) {
                virtualContext.ProcessPermissions.forEach(function (procPermission) {
                    var res = NbsCommonAuth.checkSingleProcessPermission(procPermission, permission, virtualContext);
                    if (res > 0) {
                        valuePermission = res;
                    }
                });
            }
        }
        return valuePermission;
    };

    havePermission(permission, virtualContext) {
        var valuePermission = this.checkPermissionCode(permission, virtualContext);
        return (valuePermission & permission.value) > 0;
    };

    //filter.moduleTargetId
    //filter.groupId
    //filter.typeId
    checkProcessPermissionCode(permission, filter, virtualContext) {
        if (!filter.moduleTargetId
            || !filter.groupId
            || !filter.typeId) {
            console.log("filter param null error");
            return false;
        }
        var havePermission = NbsCommonAuth.checkProcessPermissionCode(permission, filter, virtualContext);

        return havePermission > 0;
    };

    checkProcessPermissionCodeMessage(permission, filter, virtualContext) {
        var deferred = this.$q.defer();

        if (!this.checkProcessPermissionCode(permission, filter, virtualContext)) {
            //modal al volo ??
            this.navigationService.userNotAuthorizedMessageModal();
            deferred.resolve(false);
        } else {
            deferred.resolve(true);
        }

        return deferred.promise;
    };

    //'xs' : 480,     // extra small
    //'sm' : 768,     // small
    //'md' : 992,     // medium
    //'lg' : 1200     // large
    isUnderBreakpoint_xs = NbsCommonUI.isUnderBreakpoint_xs();
    isUnderBreakpoint_sm = NbsCommonUI.isUnderBreakpoint_sm();
    isUnderBreakpoint_md = NbsCommonUI.isUnderBreakpoint_md();

    //call this function from controller e.g. vm.setItemSelectedFn.call(vm, () => { return itemSelected });
    fnItemSelected = null;
    setItemSelectedFn(fnItemSelected) {
        fnItemSelected = fnItemSelected;
    };

    rowDblClick(args) {
        alert('implement into controller');
    }

    //action called by open detail ribbon bar
    openDetailAction() {
        if (this.fnItemSelected) {
            var dataSelected = this.fnItemSelected();

            if (Array.isArray(dataSelected)) {
                if (dataSelected.length === 0) {
                    this.navigationService.selectOneItemMessageModal();
                    return;
                }
                if (dataSelected.length > 1) {
                    this.navigationService.selectOnlyOneItemMessageModal();
                    return;
                }

                this.rowDblClick({ data: Lazy(dataSelected).first() });
            } else {
                if (!dataSelected) {
                    this.navigationService.selectOneItemMessageModal();
                    return;
                }

                this.rowDblClick({ data: dataSelected });
            }
        }
    };

    canCreate() {
        var deferred = this.$q.defer();
        deferred.resolve();
        return deferred.promise;
    };
    canRead() {
        var deferred = this.$q.defer();
        deferred.resolve();
        return deferred.promise;
    };
    canUpdate() {
        var deferred = this.$q.defer();
        deferred.resolve();
        return deferred.promise;
    };
    canDelete() {
        var deferred = this.$q.defer();
        deferred.resolve();
        return deferred.promise;
    };
    canCustomAction(actionBw) {
        var deferred = this.$q.defer();
        deferred.resolve();
        return deferred.promise;
    };

    alerts = [];
    alertTypes = {
        success: 'success',
        warning: 'warning',
        danger: 'danger'
    };
    alertConfig = {
        isModal: false,
        isReadonly: false
    };

    appStoreCodeControlType = {
        isCurrentAppStoreCodePresentInAppStoreCodeList: 1,     //DEFAULT
        isAppStoreCodeListPresentInContext: 2
    };

    //constants used to define class for modal template that contains NgGrid
    NBS_MODAL_CLASS_FORM_SM = 'nbs-modal-form-sm';     //600px width
    NBS_MODAL_CLASS_FORM_MD = 'nbs-modal-form-md';     //800px width
    NBS_MODAL_CLASS_FORM_LG = 'nbs-modal-form-lg';     //1000px width

    // custom enums used in controllers
    actions = { none: 0, add: 1, remove: 2, update: 3 };
    inputType = { checkbox: 'checkbox', radio: 'radio' };

    modalOptions(options) {
        var optionsReturn = {
            animation: true,
            templateUrl: options.templateUrl ? options.templateUrl : null,
            template: options.template ? options.template : null,
            scope: options.scope ? options.scope : null,
            controller: options.controller ? options.controller : null,
            controllerAs: options.controllerAs ? options.controllerAs : null,
            bindToController: options.bindToController ? options.bindToController : null,
            resolve: options.resolve ? options.resolve : null,
            backdrop: options.backdrop ? options.backdrop : 'static',
            keyboard: options.keyboard ? options.keyboard : false,
            backdropClass: options.backdropClass ? options.backdropClass : null,
            windowClass: options.windowClass ? options.windowClass : null,	// do not modify default value (layout genericgridlist can be damaged)
            windowTemplateUrl: options.windowTemplateUrl ? options.windowTemplateUrl : null,
            size: options.size ? options.size : 'lg',
            openedClass: options.openedClass ? options.openedClass : null
        };

        return optionsReturn;
    };

    // Returns object used to communicate result after batchrequest is executed to appPanel UI
    getJobStatus = function () {
        var jobResult = {
            jobStatus: null,                // done, warning, conflict, ....
            jobMessage: null,               // message to add on appLayout toolbar (can be null)
            jobDetailActionLabel: null      // if set, then add dropdown with item (E.g. Dettaglio)
        };

        return jobResult;
    };

    //Set cursor: not-allowed: used in datetimepicker button
    cursorForDisabledFormItem = this.viewContext.isLocked ? { 'cursor': 'not-allowed' } : {};

    getAppStoreByEntity(contextService, targetEntity) {
        var appStoreFiltered = [];
        Lazy(contextService.virtualContext.AppStores)
            .each((appStore) => {
                var targetEntities = NbsCommonConverter.JsonFieldParse(appStore.TargetEntities);//JSON.parse(appStore.TargetEntities);
                if (Lazy(targetEntities).contains(targetEntity)) {
                    appStoreFiltered.push(appStore);
                }
            });
        return appStoreFiltered;
    };

    getAppStoreBwSumByEntity(contextService, targetEntity) {
        var appStoreFiltered = this.getAppStoreByEntity(contextService, targetEntity);
        return Lazy(appStoreFiltered).pluck('AppStoreBw').sum();
    };

    getAppStoreByModuleTargetId(contextService, moduleTargetId) {
        var moduleTarget = Lazy(contextService.virtualContext.ModuleTargets).findWhere({ ModuleTargetId: moduleTargetId });
        if (moduleTarget) {
            return moduleTarget.AppStoreBw;
        } else {
            console.error('ModuleTarget not found');
            return 0;
        }
    };

    getModuleTargetId(contextService, appStoreBw) {
        if (!appStoreBw) {
            console.error('getModuleTargetId: appStoreBw is mandatory');
        }

        var moduleTarget = Lazy(contextService.virtualContext.ModuleTargets).findWhere({ AppStoreBw: appStoreBw });
        if (moduleTarget) {
            return moduleTarget.ModuleTargetId;
        } else {
            console.error('ModuleTarget not found');
            return 0;
        }
    };

    getModuleTargetIdByCode(contextService, moduleTargetCode) {
        if (!moduleTargetCode) {
            console.error('getModuleTargetIdByCode: moduleTargetCode is mandatory');
        }

        var moduleTarget = Lazy(contextService.virtualContext.ModuleTargets).findWhere({ ModuleTargetCode: moduleTargetCode });
        if (moduleTarget) {
            return moduleTarget.ModuleTargetId;
        } else {
            console.error('ModuleTarget not found');
            return 0;
        }
    };

    getAppStoreBitwise(virtualContext, appStoreCode) {
        var bw = 0;

        if (!appStoreCode) {
            console.error('getAppStoreBitwise: appStoreCode is mandatory');
        }

        var appStoreCtx = Lazy(virtualContext.AppStores)
            .map((el) => {
                el.AppStoreCodeLowerCase = el.AppStoreCode.toLowerCase();
                return el;
            });

        if (appStoreCode.toLowerCase() === 'all') {
            Lazy(appStoreCtx)
                .each((el) => {
                    bw += el.AppStoreBw;
                });
        } else {
            var appStoreSearch = Lazy(appStoreCtx).findWhere({ AppStoreCodeLowerCase: appStoreCode.toLowerCase() });
            if (appStoreSearch) {
                bw = appStoreSearch.AppStoreBw;
            }
        }

        if (bw <= 0) {
            console.error('getAppStoreBitwise: AppStoreBw cannot be zero');
        }

        return bw;
    };

    AppStoreAuth(contextService, appStoreCodeList, currentAppStoreCode, appStoreCodeControlType) {
        var result = true;

        if (!appStoreCodeControlType) {
            appStoreCodeControlType = this.appStoreCodeControlType.isCurrentAppStoreCodePresentInAppStoreCodeList;
        }

        if (result) {
            var appStoreCtx = Lazy(contextService.virtualContext.AppStores)
                .map((el) => {
                    el.AppStoreCodeLowerCase = el.AppStoreCode.toLowerCase();
                    return el;
                });

            var contextAppStoreCodes = appStoreCtx.pluck('AppStoreCodeLowerCase').toArray();
            if (contextAppStoreCodes.length === 0) {
                result = false;
            }
        }

        if (result) {
            if (!contextService) {
                result = false;
            }
        }

        if (result) {
            appStoreCodeList = appStoreCodeList.split(',');
            if (appStoreCodeList.length === 0) {
                result = false;
            }
        }

        if (result) {
            if (currentAppStoreCode) {
                currentAppStoreCode = currentAppStoreCode.toLowerCase();
            } else {
                result = false;
            }
        }

        if (result) {
            switch (appStoreCodeControlType) {
                case this.appStoreCodeControlType.isCurrentAppStoreCodePresentInAppStoreCodeList:   //DEFAULT
                    if (!Lazy(appStoreCodeList).contains(currentAppStoreCode)) {
                        result = false;
                    }
                    break;

                case this.appStoreCodeControlType.isAppStoreCodeListPresentInContext:
                    Lazy(appStoreCodeList)
                        .each((appStore) => {
                            if (!Lazy(contextAppStoreCodes).contains(appStore)) {
                                result = false;
                            }
                        });
                    break;
            }
        }

        return result;
    };

    translateCmsView(cmsView, key, entityName) {
        if (!cmsView) {
            return null;
        } else {
            // @ts-ignore
            return window.$T(cmsView, key, entityName);
        }
    };

    getReportsForCmsView(cmsService, cmsViewCode) {
        var defer = this.$q.defer();
        var cmsViewCompleteCode;

        if (cmsViewCode) {
            cmsViewCode = cmsViewCode.toLowerCase();
            cmsViewCompleteCode = NbsCommonCms.cmsViewData(cmsViewCode).CmsViewCompleteCode;
        } else {
            cmsViewCompleteCode = NbsCommonCms.cmsViewData(this.myCmsViewCode).CmsViewCompleteCode;
        }

        var filter = { CmsViewCompleteCode: cmsViewCompleteCode };
        cmsService.getReportDefinitionsForCmsView(filter, false)
            .then(function (data) {
                this.currentReportData.reportDefinition = data.results[0];
                defer.resolve(true);
            })
            .catch(function (error) {
            });

        return defer.promise;
    };

    // @ts-ignore
    globalThesaurusObject = Nembus.Common.Library.AppModel.globalThesaurusObject;

    translate(key: string, entityName?: string) {
        //@ts-ignore
        return window.$T(this.myCmsViewCode, key, entityName);
    }

    manageSaveChangesMessage(error) {
        error.isMessageVisible = true;

        if (
            !Lazy(error.entityErrors).where({ "isServerError": true }).any()            // No server errors
            &&
            Lazy(error.entityErrors).where({ "isServerError": false }).any()            // Yes client errors
        ) {
            error.isMessageVisible = false;
            return;
        }

        if (error.httpResponse && error.httpResponse.data && error.httpResponse.data.Errors) {
            // Errors list managed by appcontexthelper
            Lazy(error.httpResponse.data.Errors)
                .each((currentError) => {
                    switch (currentError.Message) {
                        case 'addDuplicateCode':
                            error.message = this.translate('CreationDeniedEntityCodeAlreadyExists');
                            break;
                        case 'deleteForeignKey':
                            error.message = this.translate('DeleteOperationDbValidationFailed');
                            break;
                        case 'CancellationDeniedEntityAssociated':
                            error.message = this.translate('CancellationDeniedEntityAssociated');
                            break;
                        default:
                            error.message = this.translate(currentError.Message);
                    }
                });
        } else {
            // Errors list not managed
            if (error.message.toLowerCase().indexOf('duplicate entry') >= 0) {
                error.message = this.translate('CreationDeniedEntityCodeAlreadyExists');
            }
            if (error.message.toLowerCase().indexOf('foreign key') >= 0) {
                error.message = this.translate('DeleteOperationDbValidationFailed');
            }
        }

        //decode html before output on UI
        error.message = NbsCommonConverter.decodeHtml(error.message);
    };

    managePostMessage(error) {
        error.isMessageVisible = true;

        if (error.message.indexOf('Duplicate entry') >= 0) {
            error.message = this.translate('CreationDeniedEntityCodeAlreadyExists');
        }
    };

    submitAction(removeAlerts) {
        if (!this.currentDataService) {
            console.error('NbsVm2: currentDataService is mandatory');
            return;
        }

        if (removeAlerts === null || removeAlerts === undefined || removeAlerts === true) {
            this.removeAlerts();
        }

        var deferred = this.$q.defer();

        this.currentDataService.saveChanges()
            .then(function (saveResult) {
                this.setSaveSuccessMsg();
                this.resetAlertConfig();
                deferred.resolve(saveResult);
            })
            .catch(function (error) {
                this.manageSaveChangesMessage(error);

                this.setErrorMsg(error);

                this.resetAlertConfig();
                deferred.reject(error);
            });
        return deferred.promise;
    };

    modifyAction(saveChanges) {
        if (saveChanges === null || saveChanges === undefined || saveChanges === true) {
            saveChanges = true;
        } else {
            saveChanges = false;
        }

        var deferred = null;

        if (this.viewContext.state === this.viewContext.states.isEditing) {
            this.removeAlerts();
            deferred = this.$q.defer();

            if (!saveChanges) {
                // emule save changes
                this.setSaveSuccessMsg();
                this.setIsViewing();
                this.resetAlertConfig();
                deferred.resolve();
            } else {
                this.currentDataService.saveChanges()
                    .then(function (saveResult) {
                        this.setSaveSuccessMsg();
                        this.setIsViewing();
                        this.resetAlertConfig();
                        deferred.resolve(saveResult);
                    })
                    .catch(function (error) {
                        this.manageSaveChangesMessage(error);

                        this.setErrorMsg(error);

                        this.resetAlertConfig();
                        deferred.reject(error);
                    });
            }

            return deferred.promise;
        }
        else {
            this.setIsEditing();
            deferred = this.$q.defer();
            deferred.resolve();
            return deferred.promise;
        }
    };

    booleanForRadioList = new NbsRadioListBoolean(this.translate.bind(this));

    //returns list o selection for dropdown (emulating boolean)
    booleanSelectionForDropDown = [
        { value: true, name: this.translate('Yes') },
        { value: false, name: this.translate('No') }
    ];

    resetAlertConfig() {
        this.alertConfig.isModal = false;
        this.alertConfig.isReadonly = false;
    };

    removeAlerts() {
        this.alerts = [];
    };

    removeAlertFromList(isModal, alertToRemove, alertType) {
        if (alertToRemove) {
            //remove single alert received
            if (!alertToRemove.isReadonly)
                this.alerts = Lazy(this.alerts)
                    .reject({ $$hashKey: alertToRemove.$$hashKey })
                    .toArray();
        } else {
            this.alerts = Lazy(this.alerts)
                .where({ isModal: isModal })
                .where({ isReadonly: false })
                .reject({ alertType: alertType })
                .toArray();

            if (alertType) {
                this.alerts = Lazy(this.alerts)
                    .reject({ alertType: alertType })
                    .toArray();
            }
        }
    };

    closeSuccessMsg(isModal, alert) {
        this.removeAlertFromList(isModal, alert, this.alertTypes.success);
    };
    closeWarningMsg(isModal, alert) {
        this.removeAlertFromList(isModal, alert, this.alertTypes.warning);
    };
    closeErrorMsg(isModal, alert) {
        this.removeAlertFromList(isModal, alert, this.alertTypes.danger);
    };

    setSuccessMsg(error) {
        this.alerts.push(new Alert(
            error.message,
            this.alertTypes.success,
            this.alertConfig.isModal,
            this.alertConfig.isReadonly
        ));

        this.resetAlertConfig();
    };

    setWarningMsg(error, isString) {
        var msg = '';
        if (isString === null || isString === undefined || isString === false) {
            msg = error.message;
        }
        else {
            msg = error;
        }
        this.alerts.push(new Alert(
            msg,
            this.alertTypes.warning,
            this.alertConfig.isModal,
            true
        ));

        this.resetAlertConfig();
    };

    setErrorMsg(error: any, isString?: boolean) {
        var tmp = error;
        if (!error.isMessageVisible) {
            return;
        }

        var msg = '';
        if (isString === null || isString === undefined || isString === false) {
            msg = error.message;
        }
        else {
            msg = error;
        }

        this.alerts.push(new Alert(
            msg,
            this.alertTypes.danger,
            this.alertConfig.isModal,
            true
        ));
        this.resetAlertConfig();
    };

    setSaveSuccessMsg() {
        this.alerts.push(new Alert(
            this.translate('SubmittedSuccesfully'),
            this.alertTypes.success,
            this.alertConfig.isModal,
            this.alertConfig.isReadonly
        ));

        this.resetAlertConfig();
    };

    //states
    setState(state) {
        this.$timeout(() => {
            this.viewContext.state = state;
            this.viewContext.isLocked = state === this.viewContext.states.isViewing;
            this.viewContext.notIsLocked = !this.viewContext.isLocked;
            this.viewContext.isViewingState = state === this.viewContext.states.isViewing;
            this.viewContext.isAddingState = state === this.viewContext.states.isAdding;
            this.viewContext.isEditingState = state === this.viewContext.states.isEditing;

            // used into ribbon component
            // if change it then change into component
            this.ribbonContext = {
                state: this.viewContext.state,
                isLocked: this.viewContext.isLocked,
                notIsLocked: this.viewContext.notIsLocked,
                isViewingState: this.viewContext.isViewingState,
                isAddingState: this.viewContext.isAddingState,
                isEditingState: this.viewContext.isEditingState
            }
        });
    };

    // fake to disable form items
    setIsEditingReport() {
        this.setState(this.viewContext.states.isEditing);
    };

    setIsEditingSearch() {
        this.setState(this.viewContext.states.isEditing);
    };

    setIsSearching() {
        this.setState(this.viewContext.states.isEditing);
    };

    setIsEditing() {
        this.setState(this.viewContext.states.isEditing);
    };

    setIsViewing() {
        this.setState(this.viewContext.states.isViewing);
    };

    setIsAdding() {
        this.setState(this.viewContext.states.isAdding);
    };

    //edit page elements (label, description for user manual etc
    editDocElementAction() {
        this.isDirtySusp = true;
        this.navigationService.navToCurrentModalDoc()
            .then(function (res) {
                this.isDirtySusp = false;
            });
    };

    //publish thesaurus to files
    refreshDocFileAction() {
        this.navigationService.navToCurrentModalDoc();
    };

    backAction() {
        this.navigationService.navigateBack();
    };

    exportExcelAction() {
        if (this.$timeout) {
            this.$timeout(() => {
                this.exportGrid = {
                    refreshkey: new Date().getTime(),
                    format: 'excel'
                }
            })
        }
    };

    exportWordAction() {
        if (this.$timeout) {
            this.$timeout(() => {
                this.exportGrid = {
                    refreshkey: new Date().getTime(),
                    format: 'word'
                }
            })
        }
    };

    setReportData(reportData) {
        if (reportData.reportDefinition) {
            this.currentReportData.reportDefinition = reportData.reportDefinition;
        }

        if (!this.currentReportData.reportDefinition) {
            console.error('report definition not set');
            return;
        }

        this.currentReportData.integrationService = this.integrationService;
        this.currentReportData.contextService = this.contextService;
        this.currentReportData.reportArgs = reportData.reportArgs;
        this.currentReportData.concatName = reportData.concatName;
        this.currentReportData.replaceName = reportData.replaceName;
    };

    setReportHtmlToDocData(reportData) {
        if (reportData.reportDefinition) {
            this.currentReportData.reportDefinition = reportData.reportDefinition;
        }

        this.currentReportData.reportService = this.reportService
        this.currentReportData.contextService = this.contextService
        this.currentReportData.concatName = reportData.concatName;
        this.currentReportData.replaceName = reportData.replaceName;
        this.currentReportData.reportHtmlRequest = reportData.reportHtmlRequest;
    };

    exportReportToPdfAction() {
        this.exportReport('pdf');
    };

    exportReportToWordAction() {
        this.exportReport('openword');
    };

    exportReportToXmlAction() {
        this.exportReport('xml');
    };

    exportReportToExcelAction() {
        this.exportReport('openexcel');
    };

    exportReportToHtmlAction() {
        this.exportReport('html');
    };

    exportReport(outputFormat) {
        var reportDef = this.currentReportData.reportDefinition;
        if (reportDef.ReportEngineTypeId === 3) // html
        {
            exportReportHtmlToDoc(outputFormat);
        }
        else {
            exportReport(outputFormat);
        }

        function exportReportHtmlToDoc(outputFormat) {
            var reportDef = this.currentReportData.reportDefinition;
            var reportHtmlRequest = this.currentReportData.reportHtmlRequest;
            reportHtmlRequest.ReportHtmlRequestId = breeze.core.getUuid();
            reportHtmlRequest.ReportDefinitionCode = reportDef.ReportDefinitionCode;
            reportHtmlRequest.ReportDefinitionName = reportDef.ReportDefinitionName;

            // used a post method to prevent error on big data submit (max size is setted on IIS)
            this.currentReportData.reportService.reportHtmlRequestManage(reportHtmlRequest)
                .then(function (data) {
                    this.currentReportData.contextService.addDownloadReportHtmlToDocFile({
                        result: data.results[0], //object type ReportHtmlRequest
                        concatName: this.currentReportData.concatName,
                        replaceName: this.currentReportData.replaceName
                    });
                });
        }

        function exportReport(outputFormat) {
            var reportDef = this.currentReportData.reportDefinition;

            var requestData = NbsCommonReport.parseReportRequest(this.currentReportData.integrationService.dataContext.complexTypes.reportRequestData(),
                reportDef,
                this.currentReportData.reportArgs,
                outputFormat);

            requestData.ReportTitle = this.currentReportData.replaceName ? this.currentReportData.replaceName : this.currentReportData.concatName;

            this.currentReportData.integrationService.genericReportingRequest(requestData)
                .then(function (data) {
                    var res = data.results[0];
                    if (res.HasError) {
                        this.setErrorMsg({
                            isMessageVisible: true,
                            message: res.ExceptionMessage
                        });
                    }
                    else if (res.IsBatch) {
                        this.currentReportData.contextService.addBatchRequest({
                            requestId: res.BatchRequestId,               //only one entity will be submitted
                            requestName: res.ReportTitle ? res.ReportTitle : res.ReportName,
                            requestStatus: 'inserted',
                            onCompletedAutoAction: function (request) {
                                var defer = this.$q.defer();
                                var filter = { ReportRequestId: res.ReportRequestId };
                                this.currentReportData.integrationService.getReportRequestRenderResult(filter, true)
                                    .then(function (data) {
                                        var jobResult = this.getJobStatus();

                                        this.currentReportData.contextService.addDownloadReportFile({
                                            result: data.results[0],
                                            concatName: this.currentReportData.concatName,
                                            replaceName: this.currentReportData.replaceName
                                        });

                                        defer.resolve(jobResult);
                                    });

                                return defer.promise;
                            }
                            //detailAction: function (request) { },
                        });
                    } else {
                        this.currentReportData.contextService.addDownloadReportFile({
                            result: data.results[0],
                            concatName: this.currentReportData.concatName,
                            replaceName: this.currentReportData.replaceName
                        });
                    }
                })
                .catch(function (error) {
                    var xx = error;
                });
        }
    };

    // used from controllers to overwrite validation translations
    // E.g. surveycreationrequestdetail controller
    overloadTranslateValidation(manager, shortName, propertyName, customPropertyTranslate) {
        var entityType = Lazy(manager.metadataStore.getEntityTypes()).filter((item) => {
            return item.shortName === shortName;
        }).first();
        if (entityType) {
            var property = Lazy(entityType.getProperties()).filter((item) => { return item.name == propertyName; }).first();
            if (property) {
                // @ts-ignore
                angular.forEach(property.validators, function (validator) {
                    //var cmsViewData = $CmsViewData(currentNavigationService.currentCmsViewCode());
                    //var cmsGlobal = $CmsGlobalData();
                    validator.context.displayName = function (c) {
                        if (c.propertyName) {
                            return customPropertyTranslate();
                        }
                    };
                });
            }
        }
    };

    loadTranslatedValidationMessages(svc) {
        if (!svc) {
            return;
        }

        var manager = svc.manager;
        if (!manager) {
            return;
        }

        //@ts-ignore
        var currentViewData = $CmsViewData(this.myCmsViewCode);
        if (!currentViewData) {
            return;
        }

        var entityTypes = manager.metadataStore.getEntityTypes();
        var validators = [];
        Lazy(entityTypes)
            .each((entityType) => {
                // entity validators
                Lazy(entityType.validators)
                    .each((validator) => {
                        validators.push(validator);
                    })

                // property validators
                Lazy(entityType.getProperties())
                    .each((property) => {
                        Lazy(property.validators)
                            .each((validator) => {
                                validators.push(validator);
                            })
                    });
            });

        this.translateValidators(currentViewData, validators);
    }

    translateValidators(cmsViewData, validators) {
        if (!validators || !Lazy(validators).some()) {
            return;
        }

        var notTranslatedValidators = Lazy(validators)
            .filter((validator) => { return !validator.context.messageTranslated; })
            .toArray();

        Lazy(notTranslatedValidators)
            .each((validator) => {
                var validationTemplateKey = 'bz-validation-' + validator.name;
                //@ts-ignore
                var msgT = Nembus.Common.Library.AppModel.globalThesaurusObject[validationTemplateKey];
                if (msgT !== validationTemplateKey) {
                    validator.context.messageTemplate = msgT;
                    validator.context.messageTranslated = true;
                }
                validator.context.displayName = function (c) {
                    if (c.propertyName) {
                        var propKey = c.entity.entityType.shortName + '.' + c.propertyName;
                        //@ts-ignore
                        var wTranslate = window.$TCmsViewLabelData(cmsViewData, propKey);
                        if (wTranslate === propKey
                            && propKey.substring(propKey.length - 2, propKey.length) === 'Id') {
                            //@ts-ignore
                            wTranslate = window.$TCmsViewLabelData(cmsViewData, propKey.substring(0, propKey.length - 2));
                        }
                        return wTranslate;
                    }
                };
            });
    };
};


class Alert {
    message: string = '';
    alertType: string = '';
    isModal: boolean = false;
    isReadonly: boolean = false;

    constructor(message, alertType, isModal, isReadonly) {
        this.message = message;
        this.alertType = alertType;
        this.isModal = isModal;
        this.isReadonly = isReadonly;
    }
}
