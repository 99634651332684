﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'audService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function surveyTemplatesSearch($q, $scope, $timeout, $injector, audService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, audService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, audService);

        var ribbon = new NbsAudRibbon(navigationService, contextService.virtualContext, 'search');
        ribbon.addHomeTab();
        ribbon.addSearchTab();
        ribbon.tabIndexSelected = 2;
        vm.ribbonDatacontext = ribbon;

        vm.setIsEditingSearch();


        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);

        var filtersToSkip = [];
        var nbsAudSearchForm = new NbsAudSearchForm(vm.translate);
        var searchFormData = nbsAudSearchForm.SearchSurveyTemplatesFormData();
        searchFormData.init(vm, filtersToSkip);

        var selectionParams = {};
        var dsCallback = null;
        //vm.surveyTemplateGroupsObj = Nembus.Common.Library.parser.objFromList(data.results, 'SurveyTemplateGroupId', 'SurveyTemplateGroupName');
        dsCallback = function () { return audService.getSurveyTemplateGroups({ AppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'SurveyTemplateGroup', viewTitle: vm.translate('SurveyTemplate.SurveyTemplateGroup'), ds: dsCallback };
        vm.nbsSelectionGridWithModalSurveyTemplateGroup = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalSurveyTemplateGroup.setOnSelectFnCallback(function () {
            vm.currentSurveyTemplateFilter.SurveyTemplateGroupsId = vm.nbsSelectionGridWithModalSurveyTemplateGroup.selectedIds;
        });

        //vm.surveyTemplateTypesObj = Nembus.Common.Library.parser.objFromList(data.results, 'SurveyTemplateTypeId', 'SurveyTemplateTypeName');
        dsCallback = function () { return audService.getSurveyTemplateTypes({ AppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'SurveyTemplateType', viewTitle: vm.translate('SurveyTemplate.SurveyTemplateType'), ds: dsCallback };
        vm.nbsSelectionGridWithModalSurveyTemplateType = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalSurveyTemplateType.setOnSelectFnCallback(function () {
            vm.currentSurveyTemplateFilter.SurveyTemplateTypesId = vm.nbsSelectionGridWithModalSurveyTemplateType.selectedIds;
        });

        vm.searchTabIndex = 0;

        vm.addFilterAction = function () {
            vm.searchTabIndex = 0;
            searchFormData.addFilters(navigationService, vm.translate, $scope);
        };

        vm.searchAction = function () {
            searchSurveyTemplates(true);
        };

        vm.deleteFilterAction = function () {
            $timeout(() => {
                vm.searchTabIndex = 0;
                vm.resetAction();
                searchFormData.removeFilters(filtersToSkip);
            });
        };

        vm.resetAction = function () {
            $timeout(() => {
                vm.searchTabIndex = 0;
                searchFormData.resetFilters();

                vm.nbsSelectionGridWithModalSurveyTemplateGroup.clear();
                vm.nbsSelectionGridWithModalSurveyTemplateType.clear();
            });
        };

        var nbsGridDatacontext = new NbsAudGrid(vm.translate);
        nbsGridDatacontext.configureGrid('surveytemplate', 'search', null);
        nbsGridDatacontext.setAdaptToPage();
        vm.nbsGridDatacontext = nbsGridDatacontext;

        function searchSurveyTemplates() {
            var clientData = {};
            clientData.surveyFilter = nbsAudSearchForm.ParseSurveyFilter(vm.currentSurveyTemplateFilter, appStoreBw);
            clientData.surveySectionFilter = nbsAudSearchForm.ParseSurveySectionFilter(vm.currentSurveySectionFilter);
            clientData.surveyQuestionFilter = nbsAudSearchForm.ParseSurveyQuestionFilter(vm.currentSurveyQuestionFilter);
            clientData.surveyQuestionOptionFilter = nbsAudSearchForm.ParseSurveyQuestionOptionFilter(vm.currentSurveyQuerstionOptionFilter);

            vm.forceDatasourceLocal = {
                refreshkey: new Date().getTime(),
                dataSource: []
            }

            audService.searchSurveyTemplates(clientData)
                .then(function (data) {
                    //Lazy(data.results)
                    //    .each((item) => {
                    //        //item.SurveyTemplateGroupName = vm.surveyTemplateGroupsObj[item.SurveyTemplateGroupId];
                    //        //item.SurveyTemplateTypeName = vm.surveyTemplateTypesObj[item.SurveyTemplateTypeId];
                    //    });

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: data.results
                    }

                    vm.searchTabIndex = 1;
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });
        }


        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.surveytemplatedetail", { 'surveyId': args.data.SurveyId, 'appStoreCode': appStoreCode });
        };

        vm.refreshAction = function () {
            vm.searchTabIndex = 1;
            searchSurveyTemplates(true);
        };
    }

    surveyTemplatesSearch.$inject = injectParams;
    surveyTemplatesSearch.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('surveyTemplatesSearch', surveyTemplatesSearch);
});