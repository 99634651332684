﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'actService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function actActionGroupsList($q, $scope, $timeout, $injector, actService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, actService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return actActionGroupSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, actService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var actActionGroupSelected = null;
        
        vm.currentActActionGroups = [];

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.actactiongroupdetail", { 'actActionGroupId': args.data.ActActionGroupId });
        };

        vm.newAction = function () {
            navigationService.navigateTo("app.nav.actactiongroupdetail", { 'actActionGroupId': null });
        };

        vm.refreshAction = function () {
            getActActionGroups(true)
                .then((results) => {
                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: results
                    }
                });
        };

        vm.deleteAction = function () {
            if (!actActionGroupSelected) {
                return;
            }            

            actService.deleteEntity(actActionGroupSelected);
            vm.submitAction()
                .then(function (saveResult) {
                    vm.refreshAction();
                });
        };

        vm.gridSelection = function (args) {
            actActionGroupSelected = args.data;

            $timeout(() => {
                if (actActionGroupSelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, !actActionGroupSelected.IsSystem);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                }

                ribbon.refreshStatusButtons();
            });            
        };

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getActActionGroups(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.currentActActionGroups = results[0];

                    var nbsGridDatacontext = new NbsGrid(vm.translate);
                    nbsGridDatacontext.configureGrid('actactiongroup', null);
                    nbsGridDatacontext.viewCommonBar();
                    nbsGridDatacontext.setDatasource(vm.currentActActionGroups, false);
                    vm.nbsGridDatacontext = nbsGridDatacontext;
                });
        }

        function getActActionGroups(forceReload) {
            var filter = {};
            var defer = $q.defer();

            actService.getActActionGroups(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    }

    actActionGroupsList.$inject = injectParams;
    actActionGroupsList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('actActionGroupsList', actActionGroupsList);
});