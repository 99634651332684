﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'navigationService', 'vhreventService', 'productionService', 'checkStateChangeService', 'contextService'];
    function workCenterEscapesList($q, $scope, $timeout, $injector, navigationService, vhreventService, productionService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, productionService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return virtualWorkCenterEscapeSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, productionService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var virtualWorkCenterEscapeSelected = null;

        vm.currentWorkCenterEscapes = [];

        var moduleTargets = Nembus.Rds.Library.Tools.ModuleTargetContext({ AppStoreBw: appStoreBw, ModuleTargetTypeCode: 'DEFAULT' }, contextService);
        vm.moduleTargetObjForBind = Nembus.Common.Library.parser.objFromList(moduleTargets, 'ModuleTargetId', 'ModuleTargetName');

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('virtualworkcenterescape', null);
        nbsGridDatacontext.viewCommonBar();
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getVhrEventGroups(forceReload, appStoreBw));
            funcsPromises.push(getVhrEventTypes(forceReload, appStoreBw));
            funcsPromises.push(getVirtualWorkCenterEscapes(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.vhrEventGroupObjForBind = Nembus.Common.Library.parser.objFromList(results[0], 'VhrEventGroupId', 'VhrEventGroupName');
                    vm.vhrEventTypeObjForBind = Nembus.Common.Library.parser.objFromList(results[1], 'VhrEventTypeId', 'VhrEventTypeName');

                    Lazy(results[2])
                        .each((item) => {
                            item.VhrEventTypeName = vm.vhrEventTypeObjForBind[item.VhrEventTypeId];
                            item.ModuleTargetName = vm.moduleTargetObjForBind[item.ModuleTargetId];
                            item.VhrEventGroupName = vm.vhrEventGroupObjForBind[item.VhrEventGroupId];
                        });

                    vm.currentWorkCenterEscapes = results[2];

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.currentWorkCenterEscapes
                    }
                });
        }

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.workcenterescapedetail-" + vm.viewMode, {
                'virtualWorkCenterEscapeId': args.data.VirtualWorkCenterEscapeId,
                'appStoreCode': appStoreCode
            });
        };

        vm.newAction = function () {
            navigationService.navigateTo("app.nav.workcenterescapedetail-" + vm.viewMode, {
                'virtualWorkCenterEscapeId': null,
                'appStoreCode': appStoreCode
            });
        };

        vm.refreshAction = function () {
            loadData(true);
        };

        vm.deleteAction = function () {
            if (!virtualWorkCenterEscapeSelected) {
                return;
            }            

            productionService.deleteEntity(virtualWorkCenterEscapeSelected);
            vm.submitAction()
                .then(function (saveResult) {
                    vm.refreshAction();
                });
        };

        vm.gridSelection = function (args) {
            $timeout(() => {
                virtualWorkCenterEscapeSelected = args.data;

                if (virtualWorkCenterEscapeSelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, true);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                }

                ribbon.refreshStatusButtons();
            });
        };

        function getVhrEventTypes(forceReload, appStoreBw) {
            var filter = { AppStoreBw: appStoreBw, IsBomType: false };
            var defer = $q.defer();

            vhreventService.getVhrEventTypes(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrEventGroups(forceReload, appStoreBw) {
            var filter = { AppStoreBw: appStoreBw, IsBomGroup: false };
            var defer = $q.defer();

            vhreventService.getVhrEventGroups(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVirtualWorkCenterEscapes(forceReload) {
            var defer = $q.defer();

            productionService.getVirtualWorkCenterEscapes({}, forceReload)
                .then(function (data) {                   
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    }

    workCenterEscapesList.$inject = injectParams;
    workCenterEscapesList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('workCenterEscapesList', workCenterEscapesList);
});