﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'notificationService', 'navigationService', 'checkStateChangeService', 'contextService', 'cmsService', 'triggerService'];
    var notificationSchemaDetail = function ($q, $scope, $timeout, $injector, notificationService, navigationService, checkStateChangeService, contextService, cmsService, triggerService) {

        var vm = this;
        nbsVm.call(vm, notificationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, notificationService, { dirtyFlagIsSuspended: vm.isDirtySuspended });

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var notificationSchemaId = navigationService.stateParams().notificationSchemaId;
        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;

        // grid AND conditions
        var nbsAndConditionGridDatacontext = new NbsGrid(vm.translate);
        nbsAndConditionGridDatacontext.configureGrid('triggerinstancematch', 'notificationSchema');
        nbsAndConditionGridDatacontext.setAdaptToPage();
        nbsAndConditionGridDatacontext.setDatasource([], false);
        nbsAndConditionGridDatacontext.allowPaging = false;
        nbsAndConditionGridDatacontext.allowSearching = false;
        nbsAndConditionGridDatacontext.allowGrouping = false;
        vm.nbsAndConditionGridDatacontext = nbsAndConditionGridDatacontext;

        // grid OR conditions
        var nbsOrConditionGridDatacontext = new NbsGrid(vm.translate);
        nbsOrConditionGridDatacontext.configureGrid('triggerinstancematch', 'notificationSchema');
        nbsOrConditionGridDatacontext.setAdaptToPage();
        nbsOrConditionGridDatacontext.setDatasource([], false);
        nbsOrConditionGridDatacontext.allowPaging = false;
        nbsOrConditionGridDatacontext.allowSearching = false;
        nbsOrConditionGridDatacontext.allowGrouping = false;
        vm.nbsOrConditionGridDatacontext = nbsOrConditionGridDatacontext;

        vm.schemaDatasource = {};

        var instances = [];     // loaded for navigation properties, no remove
        var definitions = [];     // loaded for navigation properties, no remove
        var triggerInstances = [];
        var triggerDefinitions = [];
        var schemaInstances = [];
        var definitionGroups = [];
        var instanceM2MuserGroups = [];
        var instanceM2Musers = [];
        var instanceM2Mroles = [];

        if (notificationSchemaId) {
            vm.setIsViewing();

            loadData(true);
        }
        else {
            // NotificationSchema cannot be added, can be only copied by another one
        }

        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getNotificationSchema(forceReload, notificationSchemaId));
            funcsPromises.push(getNotificationInstances(forceReload));
            funcsPromises.push(getNotificationDefinitions(forceReload));
            funcsPromises.push(getNotificationSchemaInstances(forceReload, notificationSchemaId));
            funcsPromises.push(getNotificationSchemaInstanceM2MDrbacUserGroupDtosBySchema(forceReload, notificationSchemaId));
            funcsPromises.push(getNotificationSchemaInstanceM2MAppUserDtosBySchema(forceReload, notificationSchemaId));
            funcsPromises.push(getNotificationSchemaInstanceM2MDrbacRoleDtosBySchema(forceReload, notificationSchemaId));
            funcsPromises.push(getNotificationDefinitionGroups(forceReload));
            funcsPromises.push(getTriggerInstances(forceReload));
            funcsPromises.push(getTriggerDefinitions(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.schema = results[0];         // binded UI
                    instances = results[1];
                    definitions = results[2];
                    schemaInstances = results[3];
                    instanceM2MuserGroups = results[4];
                    instanceM2Musers = results[5];
                    instanceM2Mroles = results[6];
                    definitionGroups = results[7];

                    Nembus.Common.Library.parser.AddCronExpressionLabelToList(results[11], 'CronExpression', vm.translate);
                    triggerInstances = results[8];

                    triggerDefinitions = results[9];
                    prepareUIData();
                });
        }

        var parents = vm.submitAction;
        vm.submitAction = function () {
            Lazy(schemaInstances).each((schemaInstance) => {
                Nembus.Rds.Library.SetBindingM2MEntitiesInContext(notificationService.dataContext.notificationSchemaInstanceM2MDrbacRoleDtos, 'NotificationSchemaInstanceId', [schemaInstance.NotificationSchemaInstanceId], 'DrbacRoleId', schemaInstance.nbsAssignmentReceiverObj.drbacRoleMultiselectSelectedIds());
                Nembus.Rds.Library.SetBindingM2MEntitiesInContext(notificationService.dataContext.notificationSchemaInstanceM2MDrbacUserGroupDtos, 'NotificationSchemaInstanceId', [schemaInstance.NotificationSchemaInstanceId], 'DrbacUserGroupId', schemaInstance.nbsAssignmentReceiverObj.drbacUserGroupMultiselectSelectedIds());

                Lazy(notificationService.dataContext.notificationSchemaInstanceM2MDrbacUserGroupDtos.get())
                    .each((groupM2M) => {
                        if (groupM2M.VirtualCompanyId === 0 || !groupM2M.VirtualCompanyId) {
                            groupM2M.VirtualCompanyId = virtualCompanyId;
                        }
                    });

                Nembus.Rds.Library.SetBindingM2MEntitiesInContext(notificationService.dataContext.notificationSchemaInstanceM2MAppUserDtos, 'NotificationSchemaInstanceId', [schemaInstance.NotificationSchemaInstanceId], 'AppUserId', schemaInstance.nbsAssignmentReceiverObj.appUserMultiselectSelectedIds());
            });

            parents()
                .then(function () {
                    // nothing to do
                });
        };

        vm.isNotificationEnabledOnChange = function (schemaInstance) {
            if (schemaInstance) {
                var currentInstance = schemaInstance.NotificationInstance;
                var currentDefinition = currentInstance.NotificationDefinition;

                if (currentDefinition && currentDefinition.IsSingleInstance === true) {
                    if (schemaInstance.IsNotificationEnabled === false) {
                        schemaInstance.IsNotificationEnabled === true;
                    }

                    Lazy(schemaInstances)
                        .each((currentSchemaInstance) => {
                            if (currentSchemaInstance.NotificationInstance.NotificationDefinition.NotificationDefinitionId === currentDefinition.NotificationDefinitionId) {
                                if (currentSchemaInstance.NotificationInstance.NotificationInstanceId !== currentInstance.NotificationInstanceId) {
                                    currentSchemaInstance.IsNotificationEnabled = false;
                                }
                            }
                        });
                }
            }
        };

        function prepareUIData() {
            vm.uiData = {
                groups: []
            };

            // prepare notification groups (act, survey, users, ....)
            var groups = definitionGroups.filter(function (item) {
                if (vm.schema.IsGlobalNotificationSchema) {
                    return Lazy(schemaInstances)
                        .filter((schemaInstance) => { return schemaInstance.NotificationInstance.NotificationDefinition.NotificationDefinitionGroupId === item.NotificationDefinitionGroupId; })
                        .some();
                } else {
                    return Lazy(schemaInstances)
                        .filter((schemaInstance) => {
                            return schemaInstance.NotificationInstance.NotificationDefinition.NotificationDefinitionGroupId === item.NotificationDefinitionGroupId
                                && schemaInstance.NotificationInstance.NotificationDefinition.IsProcessNotitication;
                        }).some();
                }
            });

            Lazy(groups).each((group) => {
                var currGroup = { item: group, definitions: [] };

                Lazy(schemaInstances).each((schemaInstance) => {
                    // prepare notification definitions
                    var currDefinition = { item: schemaInstance.NotificationInstance.NotificationDefinition, schemaInstances: [] };
                    var addDefinition = false;

                    if (vm.schema.IsGlobalNotificationSchema) {
                        if (schemaInstance.NotificationInstance.NotificationDefinition.NotificationDefinitionGroupId === currGroup.item.NotificationDefinitionGroupId) {
                            addDefinition = true;
                        }
                    } else {
                        if (schemaInstance.NotificationInstance.NotificationDefinition.NotificationDefinitionGroupId === currGroup.item.NotificationDefinitionGroupId
                            && schemaInstance.NotificationInstance.NotificationDefinition.IsProcessNotitication) {
                            addDefinition = true;
                        }
                    }

                    if (addDefinition) {
                        var defJustAdded = false;
                        if (Lazy(currGroup.definitions)
                            .filter((def) => {
                                return def.item.NotificationDefinitionId === currDefinition.item.NotificationDefinitionId;
                            }).some()) {

                            defJustAdded = true;
                        }

                        if (!defJustAdded) {
                            // prepare notification schema instances
                            currDefinition.schemaInstances = Lazy(schemaInstances)
                                .filter((schemaInstance) => { return schemaInstance.NotificationInstance.NotificationDefinitionId === currDefinition.item.NotificationDefinitionId; })
                                .toArray();

                            Lazy(currDefinition.schemaInstances)
                                .each((schemaInstance) => {

                                    // Assignment modal
                                    schemaInstance.nbsAssignmentReceiverObj = new NbsAssigneeSelectorModal({
                                        translator: vm.translate,
                                        suspendDirty: vm.suspendDirty,
                                        navigationService: navigationService,
                                        contextUserForSelection: null,
                                        getServerVarFn: Nembus.Common.Library.server.getServerVar
                                    });
                                    
                                    // assignment fixed roles
                                    if (schemaInstance.IsGrantedByRoleEnabled) {
                                        schemaInstance.nbsAssignmentReceiverObj.viewRoles();
                                        var roleSel = Lazy(instanceM2Mroles).where({ NotificationSchemaInstanceId: schemaInstance.NotificationSchemaInstanceId }).toArray();
                                        schemaInstance.nbsAssignmentReceiverObj.setDrbacRoleItemsSelected(roleSel, 'DrbacRoleId', 'DrbacRoleName');
                                    }

                                    // assignment fixed groups
                                    if (schemaInstance.IsGrantedByUserGroupEnabled) {
                                        schemaInstance.nbsAssignmentReceiverObj.viewUserGroups();
                                        var groupSel = Lazy(instanceM2MuserGroups).where({ NotificationSchemaInstanceId: schemaInstance.NotificationSchemaInstanceId }).toArray();
                                        schemaInstance.nbsAssignmentReceiverObj.setDrbacUserGroupItemsSelected(groupSel, 'DrbacUserGroupId', 'DrbacUserGroupName');
                                    }

                                    // assignment fixed users
                                    if (schemaInstance.IsGrantedByAppUserEnabled) {
                                        schemaInstance.nbsAssignmentReceiverObj.viewUsers();
                                        var userSel = Lazy(instanceM2Musers).where({ NotificationSchemaInstanceId: schemaInstance.NotificationSchemaInstanceId }).toArray();
                                        schemaInstance.nbsAssignmentReceiverObj.setAppUserItemsSelected(userSel, 'AppUserId', 'DisplayName');
                                    }

                                    // custom key destinations
                                    if (currDefinition.item.NotificationDefinitionGroup.NotificationDestEntityKeys) {
                                        var keysList = JSON.parse(Nembus.Common.Library.converter.decodeHtml(currDefinition.item.NotificationDefinitionGroup.NotificationDestEntityKeys));
                                        var dsKeys = Lazy(keysList).map((item) => {
                                            return {
                                                value: item,
                                                name: vm.translate('NotificationSchemaDetail.NotificationSchemaDetail_' + item)
                                            };
                                        }).toArray();

                                        schemaInstance.nbsNotificationKeysReceiverObj = new NbsCheckboxList(vm.translate);
                                        schemaInstance.nbsNotificationKeysReceiverObj.onChangeFnCallback = function (selected) {
                                            schemaInstance.NotificationDestEntityKeys = JSON.stringify(selected);
                                        };
                                        schemaInstance.nbsNotificationKeysReceiverObj.setData(dsKeys, 'value', 'name');
                                        if (schemaInstance.NotificationDestEntityKeys) {
                                            var selected = JSON.parse(Nembus.Common.Library.converter.decodeHtml(schemaInstance.NotificationDestEntityKeys));
                                            schemaInstance.nbsNotificationKeysReceiverObj.setSelected(selected);
                                        }
                                    }

                                    // trigger instances
                                    var currTriggerInstances = Lazy(triggerInstances).where({ NotificationInstanceId: schemaInstance.NotificationInstanceId }).toArray();
                                    schemaInstance.triggerInstancesLabelListDs = new NbsLabelList();
                                    schemaInstance.triggerInstancesLabelListDs.addData(currTriggerInstances, 'TriggerInstanceId', 'TriggerInstanceNameUI');
                                    schemaInstance.triggerInstancesLabelListDs.onClick = function (id) {
                                        openTriggerInstanceModal(id);
                                    };

                                    // notes
                                    schemaInstance.NoteUI = schemaInstance.IsGrantedByAuthUser ? vm.translate('SystemNotification') : null;
                                });

                            currGroup.definitions.push(currDefinition);
                        }
                    }

                });

                var canViewGroup = Lazy(currGroup.definitions).filter((def) => { return def.item.IsSingleInstance === false; }).some();
                if (canViewGroup) {
                    vm.uiData.groups.push(currGroup);
                }
            });
        }

        function openTriggerInstanceModal(triggerInstanceId) {
            var languageId = contextService.virtualContext.CurrentLanguageId;

            vm.currentTriggerInstance = Lazy(triggerInstances).findWhere({ TriggerInstanceId: triggerInstanceId });
            if (vm.currentTriggerInstance) {
                vm.currentTriggerDefinition = Lazy(triggerDefinitions).findWhere({ TriggerDefinitionId: vm.currentTriggerInstance.TriggerDefinitionId });
                vm.currentNotificationInstance = Lazy(instances).findWhere({ NotificationInstanceId: vm.currentTriggerInstance.NotificationInstanceId });
            }

            var funcsPromises = [];
            funcsPromises.push(getThesaurusCmsDomObjs(false, vm.currentTriggerDefinition.CmsDomObjClassFullName, languageId));
            funcsPromises.push(getTriggerDefinitionProperties(false));

            $q.all(funcsPromises)
                .then(function (results) {
                    var thesaurus = results[0];
                    var defProperties = results[1];

                    getTriggerInstanceMatches(false, triggerInstanceId, thesaurus, languageId)
                        .then((matches) => {
                            Lazy(matches).each((match) => {
                                parseTriggerInstanceMatch(match, vm.currentTriggerDefinition, vm.currentTriggerInstance, defProperties, thesaurus, languageId, triggerService, vm.translate);
                            });

                            // AND conditions
                            vm.forceAndConditionDatasourceLocal = {
                                refreshkey: new Date().getTime(),
                                dataSource: Lazy(matches).where({ IsOrMatch: false }).toArray()
                            }

                            // OR conditions
                            vm.forceOrConditionDatasourceLocal = {
                                refreshkey: new Date().getTime(),
                                dataSource: Lazy(matches).where({ IsOrMatch: true }).toArray()
                            }

                            vm.modal = {
                                instanceName: 'modalInstance',
                                title: vm.translate('TriggerInstance.TriggerInstance'),
                                datasource: null
                            };

                            vm.modalInstance = navigationService.modal().open(vm.modalOptions({ windowClass: vm.NBS_MODAL_CLASS_FORM_MD, templateUrl: 'triggerInstanceTemplate', scope: $scope }));
                            vm.modalInstance.result.then(function () {
                                //ok
                            }, function () {
                                //dismiss
                            });
                            vm.modalInstance.ok = function () { vm.modalInstance.close(); };
                            vm.modalInstance.cancel = function () { vm.modalInstance.dismiss(); };
                        });
                });
        }

        function getTriggerInstanceMatches(forceReload, triggerInstanceId, thesaurus, languageId) {
            var filter = {
                TriggerInstanceId: triggerInstanceId
            };
            var defer = $q.defer();

            triggerService.getTriggerInstanceMatches(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function parseTriggerInstanceMatch(match, triggerDefinition, triggerInstance, defProperties, thesaurus, languageId, triggerService, translator) {
            var triggerPropertyTypeEnum = triggerService.dataContext.enumTypes.TriggerPropertyType;
            var triggerMatchTypeEnum = triggerService.dataContext.enumTypes.TriggerMatchType;
            var triggerMatchTypeObj = Nembus.Common.Library.util.parseEnum(triggerMatchTypeEnum, 'TriggerInstanceMatch', translator);
            var triggerPropertyTypeObj = Nembus.Common.Library.util.parseEnum(triggerPropertyTypeEnum, 'TriggerDefinitionProperty', translator);

            // do not change traslated property names
            // they were returned like these from wizard
            match.TriggerMatchTypeTranslated = triggerMatchTypeObj.obj.translated.value_name[match.TriggerMatchTypeId];
            match.TriggerPropertyTypeTranslated = triggerPropertyTypeObj.obj.translated.value_name[match.TriggerPropertyTypeId];

            var key = triggerDefinition.TriggerDefinitionCode + '.' + match.PropertyName;
            match.PropertyNameTranslated = Nembus.Common.Library.cms.getWordKeyFromThesaurus(thesaurus, key, languageId);

            if (match.TriggerPropertyTypeId === triggerPropertyTypeObj.obj.normal.name_value.StatusProperty
                || match.TriggerPropertyTypeId === triggerPropertyTypeObj.obj.normal.name_value.DatetimeProperty) {

                var currDefProperty = Lazy(defProperties).findWhere({
                    TriggerDefinitionId: triggerInstance.TriggerDefinitionId,
                    PropertyName: match.PropertyName
                });

                if (currDefProperty && currDefProperty.PropertyValues) {
                    match.ValueUI = match.Value;
                    match.ValueSpanUI = match.ValueSpan;

                    var currValueField = 'Value';
                    if (match.TriggerPropertyTypeId === triggerPropertyTypeObj.obj.normal.name_value.DatetimeProperty) {
                        currValueField = 'ValueSpan';
                    }

                    var propertyValuesObj = JSON.parse(Nembus.Common.Library.converter.decodeHtml(currDefProperty.PropertyValues));

                    Lazy(Object.keys(propertyValuesObj)).each((property) => {
                        if (propertyValuesObj[property] === Number(match[currValueField])) {
                            if (currDefProperty.ParentPropertyName === 'global') {
                                match[currValueField + 'UI'] = translator(property);
                            } else {
                                var key = currDefProperty.ParentPropertyName
                                    ? currDefProperty.ParentPropertyName + '.' + property
                                    : property;

                                match[currValueField + 'UI'] = Nembus.Common.Library.cms.getWordKeyFromThesaurus(thesaurus, key, languageId);
                            }
                        }
                    });

                }
            } else if (match.TriggerPropertyTypeId === triggerPropertyTypeObj.obj.normal.name_value.UserIdProperty
                || match.TriggerPropertyTypeId === triggerPropertyTypeObj.obj.normal.name_value.UserGroupIdProperty) {

                match.ValueUI = match.ValueDescription;
            } else {

                match.ValueUI = match.Value;
            }
        }

        function getThesaurusCmsDomObjs(forceReload, cmsDomObjClassFullName, languageId) {
            var filter = {
                ElementGroupCodeUnique: cmsDomObjClassFullName,
                LanguageId: languageId
            };
            var defer = $q.defer();

            cmsService.getThesaurusCmsDomObjs(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getTriggerDefinitionProperties(forceReload) {
            var filter = {};
            var defer = $q.defer();

            triggerService.getTriggerDefinitionProperties(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getNotificationDefinitionGroups(forceReload) {
            var filter = {};
            var defer = $q.defer();

            notificationService.getNotificationDefinitionGroups(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getTriggerInstances(forceReload) {
            var filter = {
            };
            var defer = $q.defer();

            triggerService.getTriggerInstances(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getTriggerDefinitions(forceReload) {
            var filter = {
            };
            var defer = $q.defer();

            triggerService.getTriggerDefinitions(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getNotificationSchemaInstanceM2MDrbacUserGroupDtosBySchema(forceReload, notificationSchemaId) {
            var filter = {
                NotificationSchemaId: notificationSchemaId
            };
            var defer = $q.defer();

            notificationService.getNotificationSchemaInstanceM2MDrbacUserGroupDtosBySchema(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getNotificationSchemaInstanceM2MAppUserDtosBySchema(forceReload, notificationSchemaId) {
            var filter = {
                NotificationSchemaId: notificationSchemaId
            };
            var defer = $q.defer();

            notificationService.getNotificationSchemaInstanceM2MAppUserDtosBySchema(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getNotificationSchemaInstanceM2MDrbacRoleDtosBySchema(forceReload, notificationSchemaId) {
            var filter = {
                NotificationSchemaId: notificationSchemaId
            };
            var defer = $q.defer();

            notificationService.getNotificationSchemaInstanceM2MDrbacRoleDtosBySchema(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getNotificationSchema(forceReload, notificationSchemaId) {
            var filter = {
                NotificationSchemaId: notificationSchemaId
            };
            var defer = $q.defer();

            notificationService.getNotificationSchema(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results[0]);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getNotificationInstances(forceReload) {
            var filter = {
                //
            };
            var defer = $q.defer();

            notificationService.getNotificationInstances(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getNotificationDefinitions(forceReload) {
            var filter = {
                //
            };
            var defer = $q.defer();

            notificationService.getNotificationDefinitions(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getNotificationSchemaInstances(forceReload, notificationSchemaId) {
            var defer = $q.defer();
            var filter = {
                NotificationSchemaId: notificationSchemaId
            };

            notificationService.getNotificationSchemaInstances(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }
    };

    notificationSchemaDetail.$inject = injectParams;
    notificationSchemaDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('notificationSchemaDetail', notificationSchemaDetail);
});