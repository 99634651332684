﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$injector', '$scope', '$timeout', 'foundationService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function validationSchemaInstancesList($q, $injector, $scope, $timeout, foundationService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return validationInstanceSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, foundationService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        ribbon.addTab(new ribbon.Tab("ActionPanelTabManage", [ribbon.GetPublishSchemaSection(), ribbon.GetArchiveSection()]));
        vm.ribbonDatacontext = ribbon;

        ribbon.setRibbonStatusButton(ribbon.Buttons.New,  false);
        ribbon.refreshStatusButtons();

        vm.validationInstances = [];
        var validationInstanceSelected = null;

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('validationschemainstance', null);
        nbsGridDatacontext.viewCommonBar();
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getValidationSchemaInstances(forceReload));
            funcsPromises.push(getValidationSchemaDefinitions(forceReload));
            funcsPromises.push(getValidationSchemaDefinitionGroups(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    Lazy(results[0])
                        .each((item) => {
                            item.PublishDateUI = Nembus.Common.Library.converter.date.formatDateTime(item.PublishDate, contextService.virtualContext.cultureInfo, false);
                        });

                    vm.validationInstances = results[0];

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.validationInstances
                    }
                });
        }

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.validationschemainstancedetail", { 'validationSchemaInstanceId': args.data.ValidationSchemaInstanceId });
        };

        vm.refreshAction = function () {
            loadData(true);
        };

        vm.rowSelected = function (args) {
            $timeout(function () {
                validationInstanceSelected = args.data;

                if (validationInstanceSelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, !validationInstanceSelected.IsSystem && !validationInstanceSelected.IsPublish);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.PublishSchema, !validationInstanceSelected.IsPublish);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Archive, !validationInstanceSelected.IsArchived);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Extract, validationInstanceSelected.IsArchived);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, true);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.PublishSchema, false);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Archive, false);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Extract, false);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, false);
                }

                ribbon.refreshStatusButtons();
            });
        };

        vm.deleteAction = function () {
            if (!validationInstanceSelected || validationInstanceSelected.IsSystem || validationInstanceSelected.IsPublish) {
                return;
            }            

            foundationService.deleteEntity(validationInstanceSelected);
            vm.submitAction()
                .then(function (saveResult) {
                    vm.refreshAction();
                });
        };

        vm.copyAction = function () {
            if (!validationInstanceSelected) {
                return;
            }

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        foundationService.cloneValidationSchemaInstance({ ValidationSchemaInstanceId: validationInstanceSelected.ValidationSchemaInstanceId })
                            .then(function (result) {
                                vm.refreshAction();
                            });
                    }
                });
        };

        vm.publishAction = function () {
            if (!validationInstanceSelected) {
                return;
            }

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        foundationService.validationSchemaPublish({ ValidationSchemaInstanceId: validationInstanceSelected.ValidationSchemaInstanceId })
                            .then((result) => {
                                if (result) {
                                    vm.refreshAction();
                                }
                            });
                    }
                });
        };

        vm.archiveAction = function (fiel) {
            if (!validationInstanceSelected) {
                return;
            }

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        foundationService.validationSchemaArchive({ ValidationSchemaInstanceId: validationInstanceSelected.ValidationSchemaInstanceId })
                            .then((result) => {
                                if (result) {
                                    vm.refreshAction();
                                }
                            });
                    }
                });
        };

        vm.extractAction = function () {
            if (!validationInstanceSelected) {
                return;
            }

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        foundationService.validationSchemaExtract({ ValidationSchemaInstanceId: validationInstanceSelected.ValidationSchemaInstanceId })
                            .then((result) => {
                                if (result) {
                                    vm.refreshAction();
                                }
                            });
                    }
                });
        };

        function getValidationSchemaInstances(forceReload) {
            var filter = {};
            var defer = $q.defer();

            foundationService.getValidationSchemaInstances(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getValidationSchemaDefinitions(forceReload) {
            var filter = {};
            var defer = $q.defer();

            foundationService.getValidationSchemaDefinitions(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getValidationSchemaDefinitionGroups(forceReload) {
            var filter = {};
            var defer = $q.defer();

            foundationService.getValidationSchemaDefinitionGroups(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    }

    validationSchemaInstancesList.$inject = injectParams;
    validationSchemaInstancesList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('validationSchemaInstancesList', validationSchemaInstancesList);
});