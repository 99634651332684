﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'biService', 'checkStateChangeService', 'navigationService', 'contextService'];
    var biDataDefinitionsList = function ($q, $scope, $timeout, $injector, biService, checkStateChangeService, navigationService, contextService) {
        var vm = this;
        nbsVm.call(vm, biService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return biDataDefinitionSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, biService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var biDataDefinitionSelected = null;

        // at moment not exists detail view
        ribbon.setRibbonStatusButton(ribbon.Buttons.New, false);
        ribbon.refreshStatusButtons();

        vm.currentBiDataDefinitions = [];
        vm.currentBiDataDefinitionGroups = [];

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('bidatadefinition', null);
        nbsGridDatacontext.viewCommonBar();
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getBiDataDefinitionGroups(forceReload));
            funcsPromises.push(getBiDataDefinitions(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.currentBiDataDefinitionGroups = results[0];
                    vm.biDataDefinitionGroupObjForBind = Nembus.Common.Library.parser.objFromList(vm.currentBiDataDefinitionGroups, 'BiDataDefinitionGroupId', 'BiDataDefinitionGroupName');

                    vm.currentBiDataDefinitions = results[1];

                    Lazy(vm.currentBiDataDefinitions)
                        .each((item) => {
                            item.BiDataDefinitionGroupName = vm.biDataDefinitionGroupObjForBind[item.BiDataDefinitionGroupId];
                        });

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.currentBiDataDefinitions
                    }
                });
        }

        vm.gridSelection = function (args) {
            biDataDefinitionSelected = args.data;
        };

        vm.refreshAction = function () {
            loadData(true);
        };

        function getBiDataDefinitions(forceReload) {
            var defer = $q.defer();

            biService.getBiDataDefinitions({}, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getBiDataDefinitionGroups(forceReload) {
            var defer = $q.defer();

            biService.getBiDataDefinitionGroups({}, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    biDataDefinitionsList.$inject = injectParams;
    biDataDefinitionsList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('biDataDefinitionsList', biDataDefinitionsList);
});