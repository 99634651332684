﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'navigationService', 'checkStateChangeService', 'contextService', 'formSummaryService'];
    var bucketToModulesDetail = function ($q, $scope, $timeout, $injector, foundationService, navigationService, checkStateChangeService, contextService, formSummaryService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, foundationService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('appstore', 'hrbucket');
        nbsGridDatacontext.allowPaging = false;
        nbsGridDatacontext.allowSearching = false;
        nbsGridDatacontext.allowGrouping = false;
        nbsGridDatacontext.setDatasource([], false); // fake datasource, only to create grid
        vm.nbsGridDatacontext = nbsGridDatacontext;

        ribbon.setRibbonStatusButton(ribbon.Buttons.Refresh, true);

        // get array items for form summary
        vm.formSummary = formSummaryService.getDatasource();
        vm.currentVhrAppStores = [];
        vm.appStoresContext = [];

        var appStoreCheckboxIsDisabled = true;
        var virtualHumanResourceId = navigationService.stateParams().virtualHumanResourceId;
        var entityName = navigationService.stateParams().entityName;

        vm.refreshAction = function () {
            loadData(true);
        };

        if (virtualHumanResourceId) {
            loadData(true);
        }

        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getVhrAppStores(forceReload, virtualHumanResourceId));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.currentVhrAppStores = results[0];

                    getAppStoreContext(appStoreCheckboxIsDisabled, entityName);
                });
        }

        function getVhrAppStores(forceReload, virtualHumanResourceId) {
            var filter = { VirtualHumanResourceId: virtualHumanResourceId };
            var defer = $q.defer();

            foundationService.getVhrAppStores(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results[0]);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getAppStoreContext(appStoreCheckboxIsDisabled, entityName) {
            var appStores = vm.getAppStoreByEntity(contextService, entityName);
            Lazy(appStores)
                .each((appStore) => {
                    appStore.IsChecked = (vm.currentVhrAppStores.AppStoreBw & appStore.AppStoreBw) > 0;
                    appStore.IsDisabled = appStoreCheckboxIsDisabled;
                });
            vm.appStoresContext = appStores;

            vm.forceDatasourceLocal = {
                refreshkey: new Date().getTime(),
                dataSource: vm.appStoresContext
            }
        }

        // get reference of modifyAction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parentm = vm.modifyAction;
        vm.modifyAction = function () {
            parentm().then(
                function (saveResult) {
                    appStoreCheckboxIsDisabled = vm.viewContext.isEditingState ? false : true;
                    getAppStoreContext(appStoreCheckboxIsDisabled, entityName);
                }
            );
        };
    };

    bucketToModulesDetail.$inject = injectParams;
    bucketToModulesDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('bucketToModulesDetail', bucketToModulesDetail);
});