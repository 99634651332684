﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'vhreventService', 'navigationService', 'productionService', 'utilService', 'checkStateChangeService', 'contextService', 'cmsService'];
    function vhrEventsCalendar($q, $scope, $timeout, $injector, vhreventService, navigationService, productionService, utilService, checkStateChangeService, contextService, cmsService) {
        var vm = this;

        nbsVm.call(vm, vhreventService, navigationService, contextService, $q, $injector, $timeout);
        vm.setIsViewing();
        checkStateChangeService.checkFormOnStateChange($scope, vhreventService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var vhrEventKind = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventKind, null, vm.translate).obj.normal.name_value;
        var vhrEventLifeStatusEnumParsed = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventLifeStatus, 'VhrEvent', vm.translate);
        var lifeStatusObj = vhrEventLifeStatusEnumParsed.obj.normal.value_name;
        var lifeStatusTranslatedObj = vhrEventLifeStatusEnumParsed.obj.translated.value_name;
        var expressionTypeEnum = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.ExpressionType, null, vm.translate).obj.normal.name_value;
        var appStoreCode = navigationService.stateParams().appStoreCode;
        var viewType = navigationService.stateParams().viewType;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var vhrEventTypeCode = navigationService.stateParams().vhrEventTypeCode;
        var vhrEventFixedTypeCode = navigationService.stateParams().vhrEventFixedTypeCode;
        var RESOURCEGROUPNAME = 'WkcGroup';
        var wzVhrEntityType = navigationService.stateParams().wzVhrEntityType;          //asset, avatar, bucket, ...
        var vhrEventCreationArchetype = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventCreationArchetype, null, vm.translate).obj.normal.name_value;
        var vhrEventManualRequestType = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventManualRequestType, null, vm.translate).obj.normal.name_value;
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var viewMode = navigationService.currentViewMode();

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('vhreventappointment', null);
        nbsGridDatacontext.setModalMode();
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        vm.modalActions = {
            goAppointmentDetailFromSingleAppointment: 1,
            goAppointmentDetailFromAppointmentGroup: 2,
            goAppointmentDayView: 3,
            goAppointmentChangeDate: 4,
            goAppointmentListView: 5
        };

        ribbon.setRibbonStatusButton(ribbon.Buttons.Modify, false);
        ribbon.setRibbonStatusButton(ribbon.Buttons.Refresh, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.New, true);
        ribbon.refreshStatusButtons();

        // Dropdown visibility
        vm.dropDownsVisible = false;

        // Schedule
        vm.schedulerData = new SchedulerData({
            model: vm,
            propertyCurrentDate: 'currentDate',
            propertyCurrentView: 'currentView'
        },
            vm.translate,
            contextService.virtualContext.cultureInfo);

        // set start resource 
        vm.schedulerData.setResources([], callbackMapResources);
        vm.resources = vm.schedulerData.getResources();
        //vm.nbsschedulerrefreshresources = JSON.stringify(vm.schedulerData.getResources());


        vm.schedulerData.setMaxAppointmentForDay(5);
        vm.schedulerData.enableAppointmentsGrouping(true);
        vm.schedulerData.setMaxAppointmentBeforeGrouping(50);

        // Schedule configuration
        vm.schedulerConfiguration = vm.schedulerData.getConfiguration();
        vm.schedulerConfiguration.orientation = viewType === 'timeline' ? 'horizontal' : 'vertical';

        // used like default start date and time in vhrevent manual wizard
        var defaultStartTime = 'PT08H0M';
        var defaultEndTime = 'PT08H30M';
        var defaultVhrEventStartDateForWizard = null;
        var defaultVhrEventStartTimeForWizard = null;
        var defaultVhrEventEndTimeForWizard = null;

        setDefaultDateTimeForWizard();

        vm.currentDate = moment().toDate();
        vm.currentView = ej.Schedule.CurrentView.Day;

        vm.categorizeSettings = vm.schedulerData.getCategorizeSettings();  //categories => vhrevent status color
        vm.schedulerData.setGroups([RESOURCEGROUPNAME]);
        vm.group = vm.schedulerData.getGroups(); //resources group => at moment only 1 group named "ResourceGroup"
        vm.appointmentSettings = vm.schedulerData.getAppointmentSettings();
        vm.virtualWorkCenterColors = [];


        vm.refreshAction = function () {
            loadEventsAndSchedulerData(true);
        };

        vm.workCenterOnChange = function (dropDownNumber) {
            var wkcIds = Number(vm['workCenterIdSelected' + dropDownNumber]);
            if (dropDownNumber === 1) {
                vm.dropDownIsVisible = true;
            }
            setCalendarResources();
        };


        //START Schedule events
        /*
        * Function called on rendering cell in calendar obj
        * set UI layout of calendar
        */
        vm.navigation = function (args) {

            setDefaultDateTimeForWizard();

            loadEventsAndSchedulerData(false);
        };
        vm.beforeAppointmentRemove = function (args) {
            args.cancel = true;
        };
        vm.schedulerCellHover = function (args) {
            //args.cancel = false;
        };
        vm.schedulerAppointmentHover = function (args) {
            args.cancel = true;
        };
        vm.drag = function (args) {
            //args.cancel = true;
        };
        vm.dragStart = function (args) {
            vm.dragStartAppointment = Lazy(args.appointment).toObject();
            args.cancel = args.appointment.IsGroup;
        };
        vm.dragStop = function (args) {
            var virtualWorkCenterId = args.appointment.ResourceDataId;
            var startDate = args.appointment.StartTime;
            var isGroup = args.appointment.IsGroup;

            // no change made by drag
            args.appointment = vm.dragStartAppointment;

            // changestartdate params
            var params = {
                keepSameTime: isGroup,
                keepSameWorkCenter: args.appointment.ResourceDataId === virtualWorkCenterId
            };
            args.appointment.ResourceDataId = virtualWorkCenterId;
            args.appointment.StartTime = startDate;

            changeVhrEventsStartDate(args.appointment, params);

            args.cancel = true;
        };
        vm.actionBegin = function (args) {
            //var x = args;
        };
        vm.actionComplete = function (args) {
            //var x = args;
        };
        vm.appointmentWindowOpen = function (args) {
            args.cancel = true;
        };
        vm.cellDoubleClick = function () {
            args.cancel = true;
        };
        vm.cellClick = function (args) {
            args.cancel = false;
            // args contains startTime (date and time) of cell selected in every view
            // args contains endTime (date and time) of cell selected in every view
            // Agenda view is omitted
            if (vm.currentView === ej.Schedule.CurrentView.Day
                || ej.Schedule.CurrentView.Week
                || ej.Schedule.CurrentView.Workweek
                || ej.Schedule.CurrentView.Month) {

                defaultVhrEventStartDateForWizard = args.startTime;

                defaultVhrEventStartTimeForWizard = vm.currentView === ej.Schedule.CurrentView.Month
                    ? defaultStartTime
                    : Nembus.Common.Library.converter.timespan.dateToJson(args.startTime);

                defaultVhrEventEndTimeForWizard = vm.currentView === ej.Schedule.CurrentView.Month
                    ? defaultEndTime
                    : Nembus.Common.Library.converter.timespan.dateToJson(args.endTime);
            }
        };
        //END Schedule events

        // START - Modal Appointment OnCLick()
        vm.appointmentClick = function (args) {
            // prevent syncfusion scheduler dialog
            args.cancel = true;

            // is selected from grid list of appointments in group
            vm.currentAppointmentInGroup = null;

            // binded to form in modal
            vm.currentAppointment = args.appointment;

            vm.forceDatasourceLocal = {
                refreshkey: new Date().getTime(),
                dataSource: vm.currentAppointment.AppointmentsInGroup
            }

            if (vm.currentView === ej.Schedule.CurrentView.Day) {
                vm.modalButtonDayViewIsVisible = false;
            } else {
                vm.modalButtonDayViewIsVisible = true;
            }

            vm.appointmentDetailModalTitle = vm.currentAppointment.IsGroup ? vm.translate('EventGroup') : vm.translate('Event');
            vm.modalInstance = navigationService.modal().open(vm.modalOptions({ templateUrl: 'appointmentModalDetail', scope: $scope }));
            vm.modalInstance.result.then(function (action) {
                // action performed in modalOk function because sometimes modal cannot be closed
            }, function () {
                // nothing to do
            });
        };

        // triggered from modal when appointment is GROUPED
        vm.rowDblClick = function (args) {
            navigationService.navigateToVhrEventDetail({
                AppStoreCode: appStoreCode,
                VhrEventTemplateCode: args.data.SourceData.VhrEventTemplateCode,
                ViewKind: 'full',
                VhrEventId: args.data.Id,
                VirtualHumanResourceId: args.data.SourceData.VirtualHumanResourceId,
                ViewMode: viewMode
            });
        };

        // triggered from modal when appointment in group is selected
        vm.gridSelection = function (args) {
            vm.currentAppointmentInGroup = args.data;
        };

        vm.modalOk = function (action) {
            switch (action) {
                case vm.modalActions.goAppointmentDetailFromSingleAppointment:
                    if (vm.currentAppointment) {
                        vm.modalInstance.close(action);

                        navigationService.navigateToVhrEventDetail({
                            AppStoreCode: appStoreCode,
                            VhrEventTemplateCode: vm.currentAppointment.SourceData.VhrEventTemplateCode,
                            ViewKind: 'full',
                            VhrEventId: vm.currentAppointment.Id,
                            VirtualHumanResourceId: vm.currentAppointment.SourceData.VirtualHumanResourceId,
                            ViewMode: viewMode
                        });
                    }
                    break;
                case vm.modalActions.goAppointmentDetailFromAppointmentGroup:
                    if (!vm.currentAppointmentInGroup) {
                        navigationService.selectOneItemMessageModal();
                        return;
                    }

                    vm.modalInstance.close(action);

                    navigationService.navigateToVhrEventDetail({
                        AppStoreCode: appStoreCode,
                        VhrEventTemplateCode: vm.currentAppointmentInGroup.SourceData.VhrEventTemplateCode,
                        ViewKind: 'full',
                        VhrEventId: vm.currentAppointmentInGroup.Id,
                        VirtualHumanResourceId: vm.currentAppointmentInGroup.SourceData.VirtualHumanResourceId
                        , ViewMode: viewMode
                    });

                    break;
                case vm.modalActions.goAppointmentListView:
                    if (vm.currentAppointment) {
                        vm.modalInstance.close(action);

                        var filter = {
                            Date: moment(vm.currentAppointment.StartTime).format('YYYY-MM-DD'),
                            WorkCenterId: vm.currentAppointment.ResourceDataId,
                            WorkCenterName: vm.currentAppointment.ResourceDataName
                        };

                        navigationService.navigateTo("app.nav.vhreventslist-" + viewMode, {
                            'appStoreCode': appStoreCode,
                            'viewKind': 'scheduledaily',
                            'vhrEventTypeCode': vhrEventTypeCode,
                            'vhrEventFixedTypeCode': vhrEventFixedTypeCode,
                            'filter': JSON.stringify(filter)
                        });
                    }
                    break;
                case vm.modalActions.goAppointmentDayView:
                    vm.modalInstance.close(action);
                    vm.currentView = ej.Schedule.CurrentView.Day;
                    vm.currentDate = vm.currentAppointment.StartTime;
                    break;
                case vm.modalActions.goAppointmentChangeDate:
                    vm.modalInstance.close(action);

                    changeVhrEventsStartDate(vm.currentAppointment, {
                        keepSameTime: true,
                        keepSameWorkCenter: true
                    });
                    break;
            }
        };

        vm.modalCancel = function () {
            vm.modalInstance.dismiss();
        };
        // END - Modal Appointment OnCLick()

        function getVhrEventManualRequest(forceReload, vhrEventManualRequestId) {
            var filter = { VhrEventManualRequestId: vhrEventManualRequestId };
            var defer = $q.defer();

            vhreventService.getVhrEventManualRequest(filter, forceReload)
                .then(function (data) {
                    vm.vhrEventManualRequest = data.results[0];
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function wzUpdateVhrEventManualRequest(appointment, vhrEventTransactionCode) {
            navigationService.confirmModal(vm.translate("VhrEventManualUpdateSerieAffectedWarning"))
                .then(function (result) {
                    if (result) {
                        var services = { navigationService: navigationService, vhreventService: vhreventService, cmsService: cmsService }
                        var wzNbsManager = new NbsWizardManager($q, vm.translate, services, NbsGuidManager);
                        var config = new wzNbsManager.WzConfig_UpdateVhrEventManual(null);
                        config.supportData = { vhrEventCreationArchetype: vhrEventCreationArchetype };
                        config.defaultData.VhrEventTransactionCode = vhrEventTransactionCode;
                        config.defaultData.VhrEventManualRequestTypeId = vhrEventManualRequestType.Modify;

                        // get default data from first VhrEvent that belongs to serie selected (for validation request)
                        config.defaultData.ModuleTargetId = appointment.SourceData.ModuleTargetId;
                        config.defaultData.VhrEventGroupId = appointment.SourceData.VhrEventGroupId;
                        config.defaultData.VhrEventTypeId = appointment.SourceData.VhrEventTypeId;
                        config.defaultData.WorkCenterId = appointment.SourceData.VirtualWorkCenterId;
                        config.defaultData.VhrEventTemplateId = 1;        // only for validation
                        config.defaultData.VhrEvents = [appointment.SourceData];
                        wzNbsManager.setWzConfig(config);

                        wzNbsManager.open()
                            .then(function (result) {
                                // result contains VhrEventManualRequest (not in context breeze)
                                var vhrEventManualRequestResult = result;
                                vhrEventManualRequestResult.VirtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
                                vhrEventManualRequestResult.VhrEventFixedTypeCode = vhrEventFixedTypeCode;
                                var vhrEventManualRequest = vhreventService.dataContext.vhrEventManualRequests.add(vhrEventManualRequestResult);

                                vm.submitAction()
                                    .then(function (result) {
                                        if (vhrEventManualRequest.IsBatch) {
                                            contextService.addBatchRequest({
                                                requestId: vhrEventManualRequest.BatchRequestId,               //only one entity will be submitted
                                                requestName: vhrEventManualRequest.BatchRequestName,
                                                requestStatus: 'inserted',
                                                onCompletedAutoAction: function (request) {
                                                    var defer = $q.defer();
                                                    getVhrEventManualRequest(true, vhrEventManualRequest.VhrEventManualRequestId)
                                                        .then(function () {
                                                            var jobResult = vm.getJobStatus();
                                                            //public enum VhrEventManualRequestStatus {
                                                            //    None = 0,
                                                            //    Inserted = 1,
                                                            //    SuccessfullyDone = 2,
                                                            //    SomeErrors = 3,
                                                            //    SomeWarnings = 4,
                                                            //    SomeConflict = 5,
                                                            //    }
                                                            switch (vm.vhrEventManualRequest.VhrEventManualRequestStatusId) {
                                                                case 2: jobResult.jobStatus = 'done'; break;     // SuccessfullyDone
                                                                case 3: jobResult.jobStatus = 'error'; break;     // SomeErrors
                                                                case 4: jobResult.jobStatus = 'warning'; break;     // SomeWarnings
                                                                case 5: jobResult.jobStatus = 'conflict'; break;     // SomeConflict
                                                            }
                                                            //jobResult.jobMessage = '';  // message before requestName in toolbar item
                                                            //jobResult.jobDetailActionLabel = '';// Label of detail item in dropdown

                                                            // used in detailAction
                                                            request.VhrEventManualRequestId = vm.vhrEventManualRequest.VhrEventManualRequestId;

                                                            defer.resolve(jobResult);
                                                        });

                                                    return defer.promise;
                                                },
                                                detailAction: function (request) {
                                                    navigationService.navigateTo("app.nav.vhreventmanualrequestdetail", {
                                                        'vhrEventManualRequestId': request.VhrEventManualRequestId,
                                                        'appStoreCode': appStoreCode
                                                    });
                                                }
                                            });
                                        }

                                        loadEventsAndSchedulerData(true);
                                    })
                                    .catch(function (error) {
                                        // error
                                        vm.setErrorMsg(error);
                                        vhreventService.rejectChanges();
                                    });
                            });
                    }
                });
        }

        // open wizard to add new manual event
        vm.newAction = function () {            
        };

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getVhrEventTypes(forceReload, appStoreBw));
            funcsPromises.push(getEventColors(forceReload));
            funcsPromises.push(getVirtualWorkCenters(forceReload, appStoreBw));
            funcsPromises.push(getVirtualWorkCentersColors(forceReload));

            $q.all(funcsPromises)
                .then(function () {
                    //loadEventsAndSchedulerData(forceReload);
                });
        }

        function changeStartDateVhrEvent(appointment, params) {
            var startTime = Nembus.Common.Library.converter.date.formatTimePicker(appointment.StartTime, contextService.virtualContext.cultureInfo, false);
            var appointmentIds;
            if (appointment.IsGroup) {
                appointmentIds = Lazy(appointment.AppointmentsInGroup).pluck('Id').toArray();
            } else {
                appointmentIds = [appointment.Id];
            }

            var navParams = {
                startDate: appointment.StartTime,
                startTime: startTime,
                KeepSameTime: params.keepSameTime,
                KeepSameWorkCenter: params.keepSameWorkCenter,
                TargetWorkCenterId: appointment.ResourceDataId
            };

            navigationService.navToVhrEventChangeStartDate(vm.suspendDirty, navParams)
                .then(function (modalResult) {
                    //enable dirty flag
                    if (modalResult.dialogResult === navigationService.navigationActionTypes.modalOk) {
                        var vhrEventShuffleDto = vhreventService.dataContext.vhrEventShuffleDto.add();
                        vhrEventShuffleDto.VhrEventShuffleAction = modalResult.data.VhrEventShuffleAction;
                        vhrEventShuffleDto.StartDate = modalResult.data.startDate;
                        vhrEventShuffleDto.StartTime = modalResult.data.startTime;
                        vhrEventShuffleDto.KeepSameTime = modalResult.data.keepSameTime;
                        vhrEventShuffleDto.KeepSameWorkCenter = modalResult.data.keepSameWorkCenter;
                        vhrEventShuffleDto.TargetWorkCenterId = modalResult.data.targetWorkCenterId;
                        vhrEventShuffleDto.VhrEventLifeStatus = 0;
                        vhrEventShuffleDto.VhrEventBillingStatus = 0;
                        vhrEventShuffleDto.VhrEventsIdSerialized = JSON.stringify(appointmentIds);
                        vhrEventShuffleDto.ModuleTargetId = moduleTargetId;

                        vm.submitAction()
                            .then(function (result) {
                                vm.refreshAction();
                            })
                            .catch(function (error) {
                                vm.removeAlertFromList(true);
                                vm.setErrorMsg(error);
                            });
                    }
                });
        }

        function changeVhrEventsStartDate(appointment, params) {
            var appointmentIds = [];
            if (appointment.IsGroup) {
                appointmentIds = Lazy(appointment.AppointmentsInGroup).pluck('Id').toArray();
            } else {
                appointmentIds = [appointment.Id];
            }

            canChangeTargetVhrEventsStartDate(appointmentIds, appointment.StartTime, moduleTargetId)
                .then(function (result) {
                    if (result.ChangeIsEnabled) {
                        if (result.ChangeSerie) {
                            wzUpdateVhrEventManualRequest(appointment, result.VhrEventManualRequestId);
                        } else {
                            changeStartDateVhrEvent(appointment, params);
                        }
                    } else {
                        navigationService.customMessageModal(vm.translate(result.ThesaurusWordKey));
                    }
                });
        }

        // reset of default start date wizard (date and time)
        function setDefaultDateTimeForWizard() {
            defaultVhrEventStartDateForWizard = moment().toDate();
            defaultVhrEventStartTimeForWizard = defaultStartTime;
            defaultVhrEventEndTimeForWizard = defaultEndTime;
        }
        function setCalendarResources() {
            var workCenters = [];
            var wkc1 = Lazy(vm.workCenters).findWhere({ VirtualWorkCenterId: Number(vm.workCenterIdSelected1) });
            var wkc2 = Lazy(vm.workCenters).findWhere({ VirtualWorkCenterId: Number(vm.workCenterIdSelected2) });
            var wkc3 = Lazy(vm.workCenters).findWhere({ VirtualWorkCenterId: Number(vm.workCenterIdSelected3) });

            if (wkc1) {
                wkc1.color = vm.virtualWorkCenterColor1;
                workCenters.push(wkc1);
            }

            if (wkc2) {
                wkc2.color = vm.virtualWorkCenterColor2;
                workCenters.push(wkc2);
            }

            if (wkc3) {
                wkc3.color = vm.virtualWorkCenterColor3;
                workCenters.push(wkc3);
            }

            workCenters = Lazy(workCenters).uniq().toArray();
            vm.schedulerData.setResources(workCenters, callbackMapResources);
            vm.resources = vm.schedulerData.getResources();
            //vm.nbsschedulerrefreshresources = JSON.stringify(vm.schedulerData.getResources());

            loadEventsAndSchedulerData(false);
        }

        function getDaysInMonth(month, year) {
            // Since no month has fewer than 28 days
            var date = new Date(year, month, 1);
            var days = [];
            while (date.getMonth() === month) {
                days.push(new Date(date));
                date.setDate(date.getDate() + 1);
            }
            return days;
        }
        function addBlockout(blockouts, isAllDay, customStyle, resourceId, startTime, endTime) {
            var blockout = vm.schedulerData.getBlockoutObject();
            blockout.isAllDay = isAllDay;
            blockout.customStyle = customStyle;
            blockout.resourceId = resourceId;
            blockout.startTime = startTime;
            blockout.endTime = endTime;
            blockouts.push(blockout);
        }
        function getDateForBlock(date, param) {
            var hours = param.h;
            var minutes = param.m;
            var seconds = param.s;

            if (param.isJson) {
                hours = Nembus.Common.Library.converter.timespan.jsonToHour(param.h);
                minutes = Nembus.Common.Library.converter.timespan.jsonToMinute(param.m);
            }

            seconds = !seconds ? 0 : seconds;
            return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes, seconds);
        }

        /* ####  Callback mapping functions ### */
        function callbackMapCategories(params) {
            var statusColors = params[0];

            var categories = Lazy(statusColors)
                .map((statusColor) => {
                    var category = vm.schedulerData.getCategoryObject();
                    category.text = statusColor.key;
                    category.id = statusColor.key;
                    category.color = statusColor.value;
                    return category;
                }).toArray();

            return categories;
        }
        function callbackMapResources(workcenters) {
            return Lazy(workcenters)
                .map((workcenter) => {
                    var resource = vm.schedulerData.getResourceObject();
                    resource.text = workcenter.WorkCenterName;
                    resource.id = workcenter.VirtualWorkCenterId;
                    resource.calendarId = workcenter.WCalendarId;
                    resource.color = workcenter.color;
                    return resource;
                }).toArray();
        }
        function callbackMapAppointments(params) {
            var vhrEvents = params[0];
            var workCenters = params[1];
            var viewMode = params[2];

            var appointments = Lazy(vhrEvents)
                .map((event) => {
                    var appointment = vm.schedulerData.getAppointmentObject();
                    appointment.Id = event.VhrEventId;
                    if (viewMode.toLowerCase() === 'omsmedicalexams') {
                        appointment.Subject = event.VirtualHumanResourceDisplayName + ' (' + event.VhrEventName + ')';
                    } else {
                        appointment.Subject = event.VhrEventName;
                    }

                    appointment.StartTime = event.VhrEventStartDate;

                    var defaultAppointmentDuration = 30;
                    var minutes = Math.abs(moment(event.VhrEventStartDate).diff(moment(event.VhrEventEndDate), 'minutes'));
                    appointment.EndTime = (minutes < defaultAppointmentDuration)
                        ? new Date(event.VhrEventEndDate.getTime() + (defaultAppointmentDuration * 60000))
                        : event.VhrEventEndDate;

                    // BackGroundColor is used like color in Appointment object
                    var lifeStatusName = lifeStatusObj[event.VhrEventLifeStatusId];
                    var lifeStatusNameTranslated = lifeStatusTranslatedObj[event.VhrEventLifeStatusId];
                    var statusColor = vm.statusColorsObj[lifeStatusName];
                    appointment.BackGroundColor = statusColor ? statusColor.value : 'white';
                    appointment.Categorize = lifeStatusName;
                    appointment.ResourceDataId = event.WorkCenterId;
                    appointment.ResourceDataName = event.WorkCenterName;

                    var startTime = Nembus.Common.Library.converter.date.formatTime(event.VhrEventStartDate, contextService.virtualContext.cultureInfo, true);
                    var endTime = Nembus.Common.Library.converter.date.formatTime(event.VhrEventEndDate, contextService.virtualContext.cultureInfo, true);

                    // Used in appointment TOOLTIP TEMPLATE (cannot be made with object because not readable in template syntax)
                    appointment.Tooltip = {
                        title_VhrEventId: 'Id',
                        VhrEventId: event.VhrEventId,
                        title_VhrEventName: vm.translate('VhrEvent.VhrEventName'),
                        VhrEventName: event.VhrEventName,
                        title_VhrEventStartTime: vm.translate('TimeStart'),
                        VhrEventStartTime: startTime,
                        title_VhrEventEndTime: vm.translate('TimeEnd'),
                        VhrEventEndTime: endTime,
                        title_VhrEventDuration: vm.translate('Duration'),
                        VhrEventDuration: minutes,
                        title_VhrEventWorkCenter: vm.translate('VirtualWorkCenter.VirtualWorkCenter'),
                        VhrEventWorkCenter: event.WorkCenterName,
                        title_VhrEventResource: vm.translate('VhrEvent.VirtualHumanResourceDisplayName'),
                        VhrEventResource: event.VirtualHumanResourceDisplayName,
                        title_VhrEventLifeStatus: vm.translate('VhrEvent.VhrEventLifeStatus'),
                        VhrEventLifeStatus: lifeStatusNameTranslated
                    };

                    // Custom Parsed Fields used in modal launched from appointment onclick
                    appointment.SourceData = {
                        VhrEventType: vm.vhrEventTypesObj[event.VhrEventTypeId],
                        FromTime: startTime,
                        ToTime: endTime,
                        VhrEventTemplateCode: event.VhrEventTemplateCode,
                        VirtualHumanResourceId: event.VirtualHumanResourceId,
                        VirtualWorkCenterId: event.WorkCenterId,
                        ModuleTargetId: event.ModuleTargetId,
                        VhrEventGroupId: event.VhrEventGroupId,
                        VhrEventTypeId: event.VhrEventTypeId
                    };

                    return appointment;
                }).toArray();

            return appointments;
        }
        function callbackMapBlockouts(params) {
            var days = params[0];
            var dayslots = params[1];
            var blockouts = [];
            var blockout = null;
            var daysInCurrentMonth = getDaysInMonth(vm.currentDate.getMonth(), vm.currentDate.getFullYear());

            days = Lazy(days)
                .map((d) => {
                    return {
                        IsClosed: d.IsClosed,
                        IsFestivity: d.IsFestivity,
                        Note: d.Note,
                        WCalendar: d.WCalendar,
                        WCalendarId: d.WCalendarId,
                        WDayId: d.WDayId,
                        WorkingDate: d.WorkingDate,
                        key: moment(d.WorkingDate).format('YYYY-MM-DD')
                    };
                }).toArray();

            dayslots = Lazy(dayslots)
                .map((s) => {
                    return {
                        Efficiency: s.Efficiency,
                        TimeFrom: s.TimeFrom,
                        TimeTo: s.TimeTo,
                        WDayId: s.WDayId,
                        WDaySlotId: s.WDaySlotId,
                        MinFrom: Nembus.Common.Library.converter.timespan.jsonToMinutes(s.TimeFrom),
                        MinTo: Nembus.Common.Library.converter.timespan.jsonToMinutes(s.TimeTo)
                    };
                }).toArray();

            // for each WDay present in DB
            Lazy(days).each((currentDay) => {
                // for each resource (workcenter) relationed with current day calendar
                Lazy(vm.workCenters).where({ WCalendarId: currentDay.WCalendarId }).each((res) => {
                    if (currentDay.IsFestivity) {
                        //FESTIVITY
                        addBlockout(blockouts, true, 'nbs-businessfestivity', res.VirtualWorkCenterId, currentDay.WorkingDate, currentDay.WorkingDate);
                    } else if (currentDay.IsClosed) {
                        // CLOSED DAY
                        addBlockout(blockouts, true, 'nbs-businessclosed', res.VirtualWorkCenterId, currentDay.WorkingDate, currentDay.WorkingDate);
                    } else {
                        // Close all Day in month without calendars
                        Lazy(daysInCurrentMonth).each((date) => {
                            if (!Lazy(days).where({ key: moment(date).format('YYYY-MM-DD'), WCalendarId: res.WCalendarId }).some()) {
                                // CLOSED DAY
                                addBlockout(blockouts, true, 'nbs-businessclosed', res.VirtualWorkCenterId, currentDay.WorkingDate, currentDay.WorkingDate);
                            }
                        });

                        // get WDaySlots for current WDay
                        var currentDaySlots = Lazy(dayslots).where({ WDayId: currentDay.WDayId });
                        var nDaySlots = currentDaySlots.toArray().length;

                        // Sorted by MinFrom ascending
                        var index = 0;
                        var lastTimeTo = null;
                        Lazy(currentDaySlots)
                            .sortBy((ds) => { return ds.MinFrom; }, false)
                            .each((currentDayslot) => {
                                index++;
                                var startTime = null;
                                var endTime = null;

                                if (lastTimeTo && index < nDaySlots) {
                                    // intermediate dayslot
                                    startTime = getDateForBlock(currentDay.WorkingDate, { h: lastTimeTo, m: lastTimeTo, isJson: true });
                                    endTime = getDateForBlock(currentDay.WorkingDate, { h: currentDayslot.TimeFrom, m: currentDayslot.TimeFrom, isJson: true });
                                    addBlockout(blockouts, false, 'nbs-businessclosed', res.VirtualWorkCenterId, startTime, endTime);
                                } else if (lastTimeTo && index === nDaySlots) {
                                    // last dayslot
                                    startTime = getDateForBlock(currentDay.WorkingDate, { h: lastTimeTo, m: lastTimeTo, isJson: true });
                                    endTime = getDateForBlock(currentDay.WorkingDate, { h: currentDayslot.TimeFrom, m: currentDayslot.TimeFrom, isJson: true });
                                    addBlockout(blockouts, false, 'nbs-businessclosed', res.VirtualWorkCenterId, startTime, endTime);

                                    startTime = getDateForBlock(currentDay.WorkingDate, { h: currentDayslot.TimeTo, m: currentDayslot.TimeTo, isJson: true });
                                    endTime = getDateForBlock(currentDay.WorkingDate, { h: 23, m: 59, s: 59, isJson: false });
                                    addBlockout(blockouts, false, 'nbs-businessclosed', res.VirtualWorkCenterId, startTime, endTime);
                                } else if (!lastTimeTo && index === nDaySlots) {
                                    // only one dayslot
                                    startTime = getDateForBlock(currentDay.WorkingDate, { h: 0, m: 0, s: 0, isJson: false });
                                    endTime = getDateForBlock(currentDay.WorkingDate, { h: currentDayslot.TimeFrom, m: currentDayslot.TimeFrom, isJson: true });
                                    addBlockout(blockouts, false, 'nbs-businessclosed', res.VirtualWorkCenterId, startTime, endTime);

                                    startTime = getDateForBlock(currentDay.WorkingDate, { h: currentDayslot.TimeTo, m: currentDayslot.TimeTo, isJson: true });
                                    endTime = getDateForBlock(currentDay.WorkingDate, { h: 23, m: 59, s: 59, isJson: false });
                                    addBlockout(blockouts, false, 'nbs-businessclosed', res.VirtualWorkCenterId, startTime, endTime);
                                } else {
                                    // first dayslot
                                    startTime = getDateForBlock(currentDay.WorkingDate, { h: 0, m: 0, s: 0, isJson: false });
                                    endTime = getDateForBlock(currentDay.WorkingDate, { h: currentDayslot.TimeFrom, m: currentDayslot.TimeFrom, isJson: true });
                                    addBlockout(blockouts, false, 'nbs-businessclosed', res.VirtualWorkCenterId, startTime, endTime);
                                }

                                lastTimeTo = currentDayslot.TimeTo;
                            });
                    }
                });
            });
            return blockouts;
        }
        /* ####  Callback mapping functions ### */

        function loadEventsAndSchedulerData(forceReload) {
            if (forceReload) {
                vm.schedulerData.clear();
            }

            if (vm.schedulerData.resourcesAreSetted()) {
                if (vm.schedulerData.areDataLoaded()) {
                    // returns appoinments just loaded
                    vm.appointmentSettings = vm.schedulerData.getAppointmentSettings();
                    vm.blockoutSettings = vm.schedulerData.getBlockoutSettings();
                } else {
                    // search events and set them like appointments datasource to scheduler
                    var dFilter = new Nembus.Common.Library.schedule.getFilterMonthRange(vm.currentDate);
                    var funcsPromises = [];
                    var workcentersId = vm.schedulerData.getResourcesIdToLoad();
                    funcsPromises.push(getVhrEvents(forceReload, vhrEventTypeCode, vhrEventFixedTypeCode, dFilter, workcentersId, moduleTargetId));

                    $q.all(funcsPromises)
                        .then(function () {
                            var params = [vm.vhrEvents, vm.workCenters, viewMode];
                            var currentWorkCenters = [];
                            vm.schedulerData.setAppointments(params, callbackMapAppointments);
                            vm.appointmentSettings = vm.schedulerData.getAppointmentSettings();

                            Lazy(vm.workCenters).each((workCenter) => {
                                if (Lazy(workcentersId).contains(workCenter.VirtualWorkCenterId)) {
                                    currentWorkCenters.push(workCenter);
                                }
                            });

                            var calendarIds = Lazy(currentWorkCenters).pluck('WCalendarId').uniq().toArray();
                            var funcsDaySlots = [];
                            funcsDaySlots.push(getCalendarDays(forceReload, calendarIds, dFilter));
                            funcsDaySlots.push(getCalendarDaySlots(forceReload, calendarIds, dFilter));
                            $q.all(funcsDaySlots)
                                .then(function () {
                                    var params = [vm.days, vm.dayslots];
                                    vm.schedulerData.setBlockouts(params, callbackMapBlockouts);
                                    vm.blockoutSettings = vm.schedulerData.getBlockoutSettings();

                                    // Refresh method launched to force layout color of calendar
                                    vm.nbsschedulerrefresh = new Date().getTime();
                                });
                        });
                }
            }
        }

        function getVhrEvents(forceReload, vhrEventTypeCode, vhrEventFixedTypeCode, dFilter, workcentersId, moduleTargetId) {
            var filter = {
                StartDate1: dFilter.dateFrom,
                StartDate1Expression: expressionTypeEnum.GreaterThanOrEqual,
                StartDate2: dFilter.dateTo,
                StartDate2Expression: expressionTypeEnum.LessThanOrEqual,
                AppStoreBw: appStoreBw,
                VhrEventKind: vhrEventKind.Data,
                VhrEventTypeCode: vhrEventTypeCode,
                VhrEventFixedTypeCode: vhrEventFixedTypeCode,
                WorkCentersId: workcentersId,
                ModuleTargetId: moduleTargetId
            };
            var defer = $q.defer();

            vhreventService.getVhrEvents(filter, forceReload)
                .then(function (data) {
                    vm.vhrEvents = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrEventTypes(forceReload, appStoreBw) {
            var filter = { AppStoreBw: appStoreBw };
            var defer = $q.defer();

            vhreventService.getVhrEventTypes(filter, forceReload)
                .then(function (data) {
                    vm.vhrEventTypes = data.results;
                    vm.vhrEventTypesObj = Nembus.Common.Library.parser.objFromList(vm.vhrEventTypes, 'VhrEventTypeId', 'VhrEventTypeName');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getEventColors(forceReload) {
            var filter = { GenericElementCode: 'Configurations.VhrEvents.LifeStatusColors' };
            var defer = $q.defer();

            utilService.getGenericElements(filter, forceReload)
                .then(function (data) {
                    vm.statusColors = data.results[0].PairValuesArray;
                    vm.statusColorsObj = data.results[0].PairValuesObject;

                    var params = [vm.statusColors];
                    vm.schedulerData.setCategories(params, callbackMapCategories);
                    vm.categorizeSettings = vm.schedulerData.getCategorizeSettings();
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVirtualWorkCenters(forceReload, appStoreBw) {
            var filter = { AppStoreBw: appStoreBw };
            var defer = $q.defer();

            productionService.getVirtualWorkCenters(filter, forceReload)
                .then(function (data) {
                    vm.workCenters = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVirtualWorkCentersColors(forceReload) {
            var filter = { GenericElementCode: 'Configurations.WorkCenters.WorkCenterColors' };
            var defer = $q.defer();

            utilService.getGenericElements(filter, forceReload)
                .then(function (data) {
                    var virtualWorkCenterColor = data.results[0].PairValuesArray;
                    if (Lazy(virtualWorkCenterColor).length() === 3) {
                        vm.virtualWorkCenterColor1 = Lazy(virtualWorkCenterColor).get(0).value;
                        vm.virtualWorkCenterColor2 = Lazy(virtualWorkCenterColor).get(1).value;
                        vm.virtualWorkCenterColor3 = Lazy(virtualWorkCenterColor).get(2).value;
                    } else {
                        vm.virtualWorkCenterColor1 = 'white';
                        vm.virtualWorkCenterColor2 = 'white';
                        vm.virtualWorkCenterColor3 = 'white';
                    }
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getCalendarDays(forceReload, calendarIds, dFilter) {
            var filter = {
                DateFrom: dFilter.dateFrom,
                DateTo: dFilter.dateTo,
                WCalendarIds: calendarIds
            };
            var defer = $q.defer();

            productionService.getCalendarDays(filter, forceReload)
                .then(function (data) {
                    vm.days = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getCalendarDaySlots(forceReload, calendarIds, dFilter) {
            var filter = {
                DateFrom: dFilter.dateFrom,
                DateTo: dFilter.dateTo,
                WCalendarIds: calendarIds
            };
            var defer = $q.defer();

            productionService.getCalendarDaySlots(filter, forceReload)
                .then(function (data) {
                    vm.dayslots = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function canChangeTargetVhrEventsStartDate(vhrEventsId, targetDate, moduleTargetId) {
            var filter = { VhrEventsId: vhrEventsId, ModuleTargetId: moduleTargetId, StartDate2: targetDate };
            var defer = $q.defer();

            vhreventService.canChangeTargetVhrEventsStartDate(filter)
                .then(function (data) {
                    defer.resolve(data.results[0]);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function SchedulerData(contructorObj, translate, cultureInfo) {
            if (!contructorObj) { console.error('Constructor cannot be undefined') };
            if (!contructorObj.model) { console.error('"model" in constructor is mandatory') };
            if (!contructorObj.propertyCurrentDate) { console.error('"propertyCurrentDate" in constructor is mandatory') };
            if (!contructorObj.propertyCurrentView) { console.error('"propertyCurrentView" in constructor is mandatory') };

            var me = this;
            var KEYFORMATDAY = 'YYYY-MM-DD';
            var KEYFORMATMONTH = 'YYYY-MM';

            var COLOR_CLOSED = '#dbdbdb';
            var COLOR_FESTIVITY = '#fcd9d9';
            var COLOR_OPEN = 'white';
            var GROUPNAME = translate ? translate('Group') : 'Group';

            me.maxAppointmentForDay = 3;
            me.appointmentsGroupingIsEnabled = false;
            me.maxAppointmentBeforeGrouping = 50;

            var currentDate = contructorObj.propertyCurrentDate;
            var currentView = contructorObj.propertyCurrentView;

            me.group = ["Resource"];
            me.configuration = {
                schedulerTimeMode: ej.Schedule.TimeMode.Hour24,
                orientation: ej.Schedule.Orientation.Vertical,
                readonly: false,            // readonly true, disable drag and drop fucking shit 
                allowDragAndDrop: true,
                allowKeyboardNavigation: false,
                appointmentDragArea: false,
                enableAppointmentResize: false,
                showQuickWindow: false,
                showDeleteConfirmationDialog: false,
                showAllDayrow: false,
                showCurrentTimeIndicator: false,
                workWeek: [],
                views: [ej.Schedule.CurrentView.Day, ej.Schedule.CurrentView.Week, ej.Schedule.CurrentView.Month, ej.Schedule.CurrentView.Agenda],
            }
            me.controller = contructorObj.model;
            me.resources = [];
            me.categories = [];
            me.blockouts = [];
            //me.monthKey = [];
            me.appointments = [];

            // dataLoaded = { monthKey: [] } => monthKey is a property that contains an array of workcenters loaded
            me.dataLoaded = {};

            // private schedule objects
            var Blockout = function () {
                var me = this;
                me.id = null;
                me.startTime = null;
                me.endTime = null;
                me.subject = '';
                me.isBlockAppointment = false;
                me.isAllDay = false;
                me.customStyle = null;
            }
            var Resource = function () {
                var me = this;
                me.text = '';
                me.id = 0;
                me.groupId = 1;
                me.color = null;
            }
            var Category = function () {
                var me = this;
                me.text = '';
                me.id = 0;
                me.color = '';
                me.fontColor = 'black';
            }
            var Appointment = function (isGrouped) {
                var me = this;
                // appointment identifier
                me.Id = 0;
                // Subject: description visualized on appointment in scheduler
                me.Subject = null;
                // StartTime: used to positioning appointment in calendar
                me.StartTime = null;
                // EndTime: used to positioning appointment in calendar
                me.EndTime = null;
                me.AllDay = false;
                me.Recurrence = false;
                me.RecurrenceRule = '';
                // Used to manage appointment color
                me.Categorize = null;


                //me.GroupId = 1,					// Foreign key in resource settings to auto filter appointment on resource (group) selection
                me.ResourceDataId = null;		// Foreign key in resource settings to auto filter appointment on resource (workCenter) selection

                // list of appointments in group
                me.AppointmentsInGroup = [];

                if (isGrouped) {
                    me.IsGroup = true;
                } else {
                    me.IsGroup = false;
                }
            }

            var parseAppointments = function () {
                var haveResources = false;
                var currentResources = getResources('Resource').resourceSettings.dataSource;

                if (Lazy(currentResources).some()) {
                    haveResources = true;
                }

                if (!haveResources) {
                    return [];
                }
                else {
                    var keyMonth = getKeyMonth(me.controller[currentDate]);
                    var resourcesId = Lazy(currentResources).pluck('id').uniq().toArray();

                    // appointments filtered by current resources selected
                    var appointmentsToParse = Lazy(me.appointments)
                        .filter((a) => {
                            var isInResource = Lazy(resourcesId).contains(a.ResourceDataId);
                            var isInMonth = (a.keyMonth == keyMonth);
                            return (isInResource && isInMonth)
                        }).map((a) => {
                            // trick to remove reference to breeze metadata and create new array of object
                            return Lazy(a).toObject()
                        }).toArray();

                    if (appointmentsToParse.length < me.maxAppointmentBeforeGroup) {
                        return appointmentsToParse;
                    } else if (me.controller[currentView] == ej.Schedule.CurrentView.Day) {
                        return Lazy(appointmentsToParse).where({ keyDay: moment(me.controller[currentDate]).format(KEYFORMATDAY) }).toArray();
                        //} else if (me.controller[currentView] == ej.Schedule.CurrentView.Agenda) {
                        //	return appointmentsToParse;
                    }

                    var appointmentsParsed = [];
                    var keyDays = Lazy(appointmentsToParse).pluck('keyDay').uniq().toArray();
                    Lazy(resourcesId).each((resourceId) => {
                        Lazy(keyDays).each((keyDay) => {
                            var appointmentsInCurrentDay = Lazy(appointmentsToParse).where({ keyDay: keyDay }).where({ ResourceDataId: resourceId }).toArray();
                            if (Lazy(appointmentsInCurrentDay).some()) {
                                if (me.appointmentsGroupingIsEnabled && appointmentsInCurrentDay.length > me.maxAppointmentForDay) {
                                    var dateStartMin = Lazy(appointmentsInCurrentDay).sortBy(sortByStartDate).first().StartTime;
                                    var dateEndMax = Lazy(appointmentsInCurrentDay).sortBy(sortByEndDate, true).first().EndTime;
                                    var groupId = Lazy(appointmentsInCurrentDay).first().Id;

                                    var appointmentGrouped = new Appointment(true);
                                    appointmentGrouped.Id = groupId;
                                    appointmentGrouped.StartTime = dateStartMin;
                                    appointmentGrouped.EndTime = dateEndMax;
                                    appointmentGrouped.FromTimeGroup = Nembus.Common.Library.converter.date.formatTime(dateStartMin, cultureInfo, false);
                                    appointmentGrouped.ToTimeGroup = Nembus.Common.Library.converter.date.formatTime(dateEndMax, cultureInfo, false);
                                    appointmentGrouped.ResourceDataId = resourceId;
                                    appointmentGrouped.Subject = GROUPNAME + ' (' + appointmentsInCurrentDay.length + ')';
                                    appointmentGrouped.BackgroundColor = 'white';
                                    appointmentGrouped.AppointmentsInGroup = Lazy(appointmentsInCurrentDay).map((a) => { return Lazy(a).toObject(); }).toArray();
                                    appointmentGrouped.ResourceDataName = Lazy(appointmentsInCurrentDay).first().ResourceDataName;

                                    appointmentsParsed.push(appointmentGrouped);
                                } else {
                                    // not grouped
                                    Lazy(appointmentsInCurrentDay).each((appointmentToAdd) => {
                                        appointmentsParsed.push(Lazy(appointmentToAdd).toObject());
                                    });
                                }
                            }
                        });
                    });

                    return appointmentsParsed;
                }

            }

            //Function used to Sort a list by date
            var sortByStartDate = function (appointment) {
                return appointment.StartTime.getTime();
            }

            //Function used to Sort a list by date
            var sortByEndDate = function (appointment) {
                return appointment.EndTime.getTime();
            }
            var getKeyMonth = function (date) {
                return moment(date).format(KEYFORMATMONTH);
            }

            var clear = function () {
                //me.resources = [];
                //me.categories = [];
                //me.monthKey = [];
                me.blockouts = [];
                me.dataLoaded = {};
                me.appointments = [];
            }
            var getConfiguration = function () {
                return me.configuration;
            }
            var getCategoryObject = function () {
                return new Category();
            }
            var getResourceObject = function () {
                return new Resource();
            }
            var getAppointmentObject = function () {
                return new Appointment();
            }
            var getBlockoutObject = function () {
                return new Blockout();
            }
            var setMaxAppointmentForDay = function (n) {
                me.maxAppointmentForDay = n;
            }
            var enableAppointmentsGrouping = function (enabled) {
                me.appointmentsGroupingIsEnabled = enabled;
            }
            var setMaxAppointmentBeforeGrouping = function (n) {
                me.maxAppointmentBeforeGrouping = n;
            }
            var setCategories = function (params, callbackMappingFn) {
                me.categories = callbackMappingFn(params);
            }
            var areDataLoaded = function () {
                var currentResources = getResources('Resource').resourceSettings.dataSource;
                var currentKeyMonth = getKeyMonth(me.controller[currentDate])
                var result = true;

                if (!me.dataLoaded[currentKeyMonth]) {
                    me.dataLoaded[currentKeyMonth] = [];
                }

                Lazy(currentResources).each((currentResource) => {
                    if (!Lazy(me.dataLoaded[currentKeyMonth]).contains(currentResource.id)) {
                        result = false;
                    }
                })

                return result;
            }
            var getResourcesIdToLoad = function () {
                var currentResources = getResources('Resource').resourceSettings.dataSource;
                var currentKeyMonth = getKeyMonth(me.controller[currentDate])
                var result = [];

                if (!me.dataLoaded[currentKeyMonth]) {
                    me.dataLoaded[currentKeyMonth] = [];
                }

                Lazy(currentResources).each((currentResource) => {
                    if (!Lazy(me.dataLoaded[currentKeyMonth]).contains(currentResource.id)) {
                        result.push(currentResource.id);
                    }
                })

                return result;
            }
            var setDataLoaded = function () {
                // dataLoaded = { monthKey: [] } => monthKey is a property that contains an array of workcenters loaded
                // me.dataLoaded = {};

                // update month loaded
                var currentKeyMonth = getKeyMonth(me.controller[currentDate])
                if (!me.dataLoaded[currentKeyMonth]) {
                    me.dataLoaded[currentKeyMonth] = [];
                }

                var currentResources = getResources('Resource').resourceSettings.dataSource;
                Lazy(currentResources).each((currentResource) => {
                    if (!Lazy(me.dataLoaded[currentKeyMonth]).contains(currentResource.id)) {
                        me.dataLoaded[currentKeyMonth].push(currentResource.id);
                    }
                })
            }
            var setAppointments = function (params, callbackMappingFn) {
                // mapping appointments using callback function
                var appointments = callbackMappingFn(params);

                // override mandatory fields
                Lazy(appointments).each((appointment) => {
                    // StartTime, EndTime binded to CALENDAR
                    appointment.StartTime = Nembus.Common.Library.converter.date.toIgnoreTimeZone(appointment.StartTime);
                    appointment.EndTime = Nembus.Common.Library.converter.date.toIgnoreTimeZone(appointment.EndTime);

                    appointment.keyDay = moment(appointment.StartTime).format(KEYFORMATDAY);
                    appointment.keyMonth = moment(appointment.StartTime).format(KEYFORMATMONTH);
                });

                //// update month loaded
                //var keyMonth = getKeyMonth(me.controller[currentDate])
                //me.monthKey = Lazy(me.monthKey).union(keyMonth).toArray();

                // merge appointments with new
                var currentIds = Lazy(appointments).pluck('Id').uniq().toArray();
                Lazy(appointments).each((appointment) => {
                    if (!Lazy(currentIds).contains(appointment.Id)) {
                        me.appointments.push(appointment);
                    } else {
                        me.appointments = Lazy(me.appointments).reject({ Id: appointment.Id }).toArray();
                        me.appointments.push(appointment);
                    }
                });

                // set data loaded for current month and current workcenters
                setDataLoaded();
            }
            var getAppointmentSettings = function () {
                // Only one resource
                // TODO: Consider many resources
                var dataSource;
                if (getResources('Resource') && getResources('Resource').resourceSettings) {
                    dataSource = parseAppointments();
                }
                else {
                    dataSource = [];
                }
                if (!dataSource) {
                    dataSource = [];
                }

                return {
                    dataSource: dataSource,
                    id: "Id",
                    subject: "Subject",
                    startTime: "StartTime",
                    endTime: "EndTime",
                    description: "Description",
                    allDay: "AllDay",
                    //recurrence: "Recurrence",
                    //recurrenceRule: "RecurrenceRule",
                    resourceFields: "ResourceDataId",
                    categorize: "Categorize"
                }
            }
            var getResources = function (name) {
                if (name) {
                    return Lazy(me.resources).where({ name: name }).first();
                } else {
                    return me.resources;
                }

            }
            var resourcesAreSetted = function () {
                return getResources('Resource').resourceSettings.dataSource.length > 0;

                //var result = false;
                //Lazy(currentResources).each((res) => {
                //	if (res.resourceSettings && res.resourceSettings.dataSource.length > 0) {
                //		result = true;
                //	}
                //});

                //return result;
            }
            var setResources = function (resourceList, callbackMappingFn) {
                var dataSource = callbackMappingFn(resourceList);
                //me.resources = Lazy(me.resources).reject({ name: 'Group' }).toArray();
                me.resources = Lazy(me.resources).reject({ name: 'Resource' }).toArray();

                // Group resources
                //me.resources.push({
                //	field: 'GroupId',
                //	title: 'Group',
                //	name: 'Group',	//related with group
                //	allowMultiple: false,
                //	resourceSettings: {
                //		dataSource: [{ text: "ROOM", id: 1, groupId: 1, color: "#cb6bb2" }],
                //		text: "text", id: "id", groupId: "groupId", color: "color"
                //	}
                //});

                // Resources related with group
                me.resources.push({
                    field: "ResourceDataId", //foreign key in appointments list
                    title: 'Title',
                    name: 'Resource', //related with group
                    allowMultiple: false,
                    resourceSettings: {
                        dataSource: dataSource,
                        color: "color",
                        text: "text",
                        id: "id",
                        groupId: "groupId",
                    },
                });
            }
            var setBlockouts = function (params, callbackMappingFn) {
                var blockouts = callbackMappingFn(params);
                Lazy(blockouts).each((blockout) => {
                    blockout.keyBlockStart = blockout.startTime.getTime();
                    blockout.keyBlockEnd = blockout.endTime.getTime();
                    blockout.keyMonth = moment(blockout.startTime).format(KEYFORMATMONTH);

                    if (!Lazy(me.blockouts).where({
                        keyBlockStart: blockout.keyBlockStart,
                        keyBlockEnd: blockout.keyBlockEnd,
                        resourceId: blockout.resourceId
                    }).some()) {

                        blockout.id = me.blockouts.length + 1;
                        me.blockouts.push(blockout);
                    }
                })
            }

            var getBlockoutSettingDatasource = function () {
                var currDate = new Date(me.controller[currentDate]);
                var datePrevMonth = new Date(me.controller[currentDate]);
                var dateNextMonth = new Date(me.controller[currentDate]);
                var resources = getResources('Resource').resourceSettings.dataSource;
                var resourcesId = Lazy(resources).pluck('id').uniq().toArray();

                datePrevMonth.setMonth(currDate.getMonth() - 1);
                dateNextMonth.setMonth(currDate.getMonth() + 1);

                var keyMonth = moment(currDate).format(KEYFORMATMONTH);
                var keyPrevMonth = moment(datePrevMonth).format(KEYFORMATMONTH);
                var keyNextMonth = moment(dateNextMonth).format(KEYFORMATMONTH);

                return Lazy(me.blockouts)
                    .filter((a) => {
                        var isInResource = Lazy(resourcesId).contains(a.resourceId);
                        var isInMonth = (a.keyMonth == keyMonth || a.keyMonth == keyPrevMonth || a.keyMonth == keyNextMonth);
                        return (isInResource && isInMonth)
                    }).toArray();
            }
            var getBlockoutSettings = function () {
                return {
                    enable: true,
                    dataSource: getBlockoutSettingDatasource(),
                    id: "id",
                    startTime: "startTime",
                    endTime: "endTime",
                    subject: "subject",
                    isBlockAppointment: "isBlockAppointment",
                    resourceId: "resourceId",
                    isAllDay: "isAllDay",
                    customStyle: "customStyle"
                };
            }
            var getCategorizeSettings = function () {
                return {
                    enable: true,
                    allowMultiple: false,
                    dataSource: me.categories,
                    text: "text",
                    id: "id",
                    color: "color",
                    fontColor: "fontColor",
                };
            }
            var setGroups = function (arrGroupNames) {
                //me.group = arrGroupNames;
            }
            var getGroups = function () {
                return { resources: me.group };
            }

            // expose functionality
            return {
                clear: clear,

                getConfiguration: getConfiguration,

                getBlockoutObject: getBlockoutObject,
                getCategoryObject: getCategoryObject,
                getResourceObject: getResourceObject,
                getAppointmentObject: getAppointmentObject,

                setBlockouts: setBlockouts,
                getResources: getResources,
                resourcesAreSetted: resourcesAreSetted,
                setResources: setResources,
                setCategories: setCategories,
                setAppointments: setAppointments,

                // settings
                getBlockoutSettings: getBlockoutSettings,
                getCategorizeSettings: getCategorizeSettings,
                getAppointmentSettings: getAppointmentSettings,
                setGroups: setGroups,
                getGroups: getGroups,

                // appointments setting
                setMaxAppointmentForDay: setMaxAppointmentForDay,
                enableAppointmentsGrouping: enableAppointmentsGrouping,
                setMaxAppointmentBeforeGrouping: setMaxAppointmentBeforeGrouping,

                // loading data
                areDataLoaded: areDataLoaded,
                getResourcesIdToLoad: getResourcesIdToLoad,
            }
        }
    }

    vhrEventsCalendar.$inject = injectParams;
    vhrEventsCalendar.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('vhrEventsCalendar', vhrEventsCalendar);
});