﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'audService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var surveyTemplateTypeDetail = function ($q, $scope, $timeout, $injector, audService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, audService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, audService);

        var ribbon = new NbsAudRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        //var appStoreCode = navigationService.stateParams().appStoreCode;
        //var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var currentSurveyTemplateTypeId = navigationService.stateParams().surveyTemplateTypeId;

        if (currentSurveyTemplateTypeId) {
            vm.setIsViewing();

            loadData(true);
        }
        else {
            vm.setIsAdding();
            vm.currentSurveyTemplateType = audService.dataContext.surveyTemplateTypes.add({
                VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId
            });
        }

        function loadData(forceReload) {
            var funcsPromises = [];

            $q.all(funcsPromises)
                .then(function () {
                    getSurveyTemplateType(forceReload, currentSurveyTemplateTypeId);
                });
        }

        function getSurveyTemplateType(forceReload, surveyTemplateTypeId) {
            var filter = { SurveyTemplateTypeId: surveyTemplateTypeId };
            var defer = $q.defer();

            audService.getSurveyTemplateType(filter, forceReload)
                .then(function (data) {
                    vm.currentSurveyTemplateType = data.results[0];
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    surveyTemplateTypeDetail.$inject = injectParams;
    surveyTemplateTypeDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('surveyTemplateTypeDetail', surveyTemplateTypeDetail);
});