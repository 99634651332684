﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$injector', '$scope', '$timeout', 'actService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function actStream($q, $injector, $scope, $timeout, actService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, actService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return actsSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, actService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'stream');
        ribbon.addHomeTab();
        ribbon.addTab(ribbon.GetActViewsTab());
        ribbon.tabIndexSelected = 2;
        vm.ribbonDatacontext = ribbon;

        var viewKind = navigationService.stateParams().viewKind;
        var actPriorityEnumParsed = Nembus.Common.Library.util.parseEnum(actService.dataContext.enumTypes.ActPriority, 'Act', vm.translate);
        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var actFixedCode = navigationService.stateParams().actFixedCode;
        var workflowStatusKindId = navigationService.stateParams().workflowStatusKindId;
        var actsSelected = [];

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('act', null);
        nbsGridDatacontext.setStreamMode();
        nbsGridDatacontext.setDatasource([], false); // fake datasource, only to create grid
        vm.nbsGridDatacontext = nbsGridDatacontext;

        vm.categoriesMultiselectDs = new NbsMultiselect(vm.translate);
        vm.actPrioritiesForDropDown = actPriorityEnumParsed.list.translated;

        //#region set ribbon fn navigations
        var navParams = { 'workflowStatusKindId': workflowStatusKindId, 'viewKind': viewKind, 'appStoreCode': appStoreCode };
        vm.onRibbonGrid = () => { ribbon.onRibbonGrid_Act(navParams); };
        vm.onRibbonBoardWorkflow = () => { ribbon.onRibbonBoardWorkflow_Act(navParams); };
        vm.onRibbonBoard = () => { ribbon.onRibbonBoard_Act(navParams); };
        //#endregion set ribbon fn navigations

        //#region set ribbon enabled buttons
        ribbon.setRibbonStatusButton(ribbon.Buttons.Board, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.BoardWorkflow, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.ViewList, true);
        ribbon.refreshStatusButtons();
        //#endregion set ribbon enabled buttons

        vm.rowDblClick = function (args) {
            navigationService.navigateToActDetail({ act: args.data, appStoreCode: appStoreCode });
        };

        vm.refreshAction = function () {
            $timeout(() => {
                vm.forceGridRefresh = new Date().getTime();
            });
        };

        vm.gridSelection = function (args) {
            actsSelected = args.data;
        };

        // hook from gridbar
        var ejDataManager = null;
        var currentActFilter = null;
        vm.actBarOnChanges = function (result) {
            // refresh of filter parsed  
            currentActFilter = result.actFilter;
            currentActFilter.ActFixedCode = actFixedCode;

            if (ejDataManager === null) {
                var supportData = { isMyActs: false, translate: vm.translate, contextService: contextService };
                ejDataManager = actService.getActDataManager(supportData, currentActFilter, ejDataManager);
                nbsGridDatacontext.setDatasource(ejDataManager, true);
                refreshGridDatasource(currentActFilter);
            } else {
                refreshGridDatasource(currentActFilter)
            }
        };

        function refreshGridDatasource(currentGridFilter) {
            $timeout(() => {
                vm.forceDatamanagerFilter = {
                    refreshKey: new Date().getTime(),
                    filter: currentGridFilter
                }
            });
        }

        vm.createGridEvent = function (result) {
            vm.actBarDataContext = {
                workflowStatusKindId: workflowStatusKindId,
                commonBarDatasource: result.barDatasource,
                uiMode: viewKind,
                appStoreBw: appStoreBw,
                defaultKeys: {
                    columnsKey: viewKind, sortingKey: null
                }
            };
        };

        vm.gridUiOnChanges = function (result) {
            vm.refreshGridUiComponent = {
                refreshKey: new Date().getTime(),
                uiData: result
            }
        }

    }

    actStream.$inject = injectParams;
    actStream.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('actStream', actStream);
});