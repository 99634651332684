﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'cmstoolService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function cmsStaticContentsList($q, $scope, $timeout, $injector, cmstoolService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, cmstoolService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return staticContentSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, cmstoolService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var staticContentSelected = null;

        vm.currentCmsStaticContents = [];

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('cmsstaticcontent', null);
        nbsGridDatacontext.viewCommonBar();
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getCmsStaticContents(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.currentCmsStaticContents = results[0];

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.currentCmsStaticContents
                    }
                });
        }

        vm.rowDblClick = function (args) {
			navigationService.navigateTo("app.nav.cmsstaticcontentdata.cmsstaticcontentdatadetail", { 'cmsStaticContentCode': args.data.CmsStaticContentCode });
        };

        vm.refreshAction = function () {
            loadData(true);
        };

        vm.deleteAction = function () {
            if (!staticContentSelected) {
                return;
            }			

            cmstoolService.deleteEntity(staticContentSelected);
			vm.submitAction()
				.then(function (saveResult) {
                    vm.refreshAction();
				});
        };

        vm.newAction = function () {
			navigationService.navigateTo("app.nav.cmsstaticcontentdata.cmsstaticcontentdatadetail", { 'cmsStaticContentCode': null });
        };

        vm.gridSelection = function (args) {
            $timeout(() => {
                staticContentSelected = args.data;

                if (staticContentSelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, true);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                }

                ribbon.refreshStatusButtons();
            });
        };

        function getCmsStaticContents(forceReload) {
            var defer = $q.defer();

            cmstoolService.getCmsStaticContents({}, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    }

    cmsStaticContentsList.$inject = injectParams;
    cmsStaticContentsList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('cmsStaticContentsList', cmsStaticContentsList);
});