﻿'use strict';

define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'utilService', 'activityService', 'productionService', 'navigationService', 'contextService'];
    function wzAddActivityStepInfo($q, $scope, $timeout, $injector, utilService, activityService, productionService, navigationService, contextService) {
        var vm = this;
        nbsVm.call(vm, activityService, navigationService, contextService, $q, $injector, $timeout);

        //var appStoreCode = navigationService.stateParams().appStoreCode;
        //var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var pParams = navigationService.stateParamsModal().parentParams;
        pParams = pParams ? pParams : navigationService.stateParams();

        // navigation buttons visibility
        vm.setWizardButtonVisibility(pParams);

        // beardcrumb information
        var beardCrumbInfo = JSON.parse(pParams.beardCrumbInfo);
        Lazy(beardCrumbInfo.items)
            .each((item) => {
                item.title = vm.translate(item.title);
            })
        vm.beardCrumbInfo = beardCrumbInfo;

        var routeSchedulingTypeEnumParsed = Nembus.Common.Library.util.parseEnum(activityService.dataContext.enumTypes.RouteSchedulingType, null, vm.translate);
        var routeSchedulingTypeObj = routeSchedulingTypeEnumParsed.obj.normal.name_value;

        vm.currentCollector = activityService.wizardDependActivityCollector;

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = []
            funcsPromises.push(getWorkCenterGroups(forceReload));
            funcsPromises.push(getVirtualWorkCenters(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.workCenterGroups = results[0];
                    vm.workCenters = results[1];
                });
        }

        vm.modalNext = function () {
            $timeout(() => {
                vm.alertConfig.isModal = true;
                vm.alertConfig.isReadonly = true;
                vm.removeAlertFromList(true);

                if (!vm.currentCollector.workCenterGroupId) {
                    vm.setErrorMsg({ isMessageVisible: true, message: vm.translate('Select') + ' ' + vm.translate('WorkCenterGroup') });
                    return;
                }

                var modalResult = getModalResult(navigationService.navigationActionTypes.modalNext);
                navigationService.closeCurrentModal(modalResult);
            });
        };

        vm.modalPrevious = function () {
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalPrevious);
            navigationService.closeCurrentModal(modalResult);
        };

        vm.modalCancel = function () {
            navigationService.confirmExitWizard()
                .then(function (result) {
                    if (result) {
                        utilService.rejectChanges();
                        var modalResult = getModalResult(navigationService.navigationActionTypes.modalCancel);
                        navigationService.closeCurrentModal(modalResult);
                    }
                });
        };

        function setDependentActivityProperties() {
            //activityPathClassificationBw
            vm.currentCollector.activityPathClassificationBw = 0;
            if (vm.currentCollector.dependActivityIsSingle) {
                vm.currentCollector.activityPathClassificationBw += routeSchedulingTypeObj.Once;
            }
            if (vm.currentCollector.dependActivityIsPeriodical) {
                vm.currentCollector.activityPathClassificationBw += routeSchedulingTypeObj.Periodical;
            }

            //workCenterGroupName
            if (vm.currentCollector.workCenterGroupId) {
                vm.currentCollector.workCenterGroupName = Lazy(vm.workCenterGroups).findWhere({ WorkCenterGroupId: vm.currentCollector.workCenterGroupId }).WorkCenterGroupName;
            } else {
                vm.currentCollector.workCenterGroupName = null;
            }

            //workCenterName
            if (vm.currentCollector.workCenterId) {
                vm.currentCollector.workCenterName = Lazy(vm.workCenters).findWhere({ VirtualWorkCenterId: vm.currentCollector.workCenterId }).WorkCenterName;
            } else {
                vm.currentCollector.workCenterName = null;
            }
        };

        function getWorkCenterGroups(forceReload) {
            var filter = { WorkCenterGroupSystemCode: 'WKC' };
            var defer = $q.defer();

            productionService.getWorkCenterGroups(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVirtualWorkCenters(forceReload) {
            var filter = {};
            var defer = $q.defer();

            productionService.getVirtualWorkCenters(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getModalResult(dialogResult) {
            setDependentActivityProperties();
            var modalResult = new navigationService.modalResult()
            modalResult.data = activityService.wizardDependActivityCollector;
            modalResult.sourceParams = pParams;
            modalResult.dialogResult = dialogResult;
            return modalResult;
        }
    };

    wzAddActivityStepInfo.$inject = injectParams;
    wzAddActivityStepInfo.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('wzAddActivityStepInfo', wzAddActivityStepInfo);
});