﻿'use strict';
define([], function () {

    var injectParams = ['$q', '$timeout', 'contextService', 'rbacService', 'drbacService', 'vhreventService', 'audService', 'foundationService', 'workflowService', 'utilService'];
    var nbsSurveyBarComponent = function ($q, $timeout, contextService, rbacService, drbacService, vhreventService, audService, foundationService, workflowService, utilService) {
        var vm = this;
        var privateUIData = null;
        var defaultKeys;
        var privateDatacontext = null;
        var pAppUsers = [];
        var pDrbacUserGroups = [];
        var allDataReady = [];
        var vhrEventLifeStatusKey = null;
        var nbsUiOptionParser = null;

        vm.commonGridBarDataContext = null;
        vm.privateComponentsStatus = {};
        vm.nbsDataPrivate = {};

        vm.$onInit = function () {
        };

        vm.$onChanges = function (changes) {
            if (changes.viewElementCode && changes.viewElementCode.currentValue) {
                allDataReady.push('viewElementCode');
            }
            if (changes.nbsDataContext && changes.nbsDataContext.currentValue) {
                allDataReady.push('nbsDataContext');
                privateDatacontext = changes.nbsDataContext.currentValue;
                vhrEventLifeStatusKey = privateDatacontext.vhrEventLifeStatusKey;
                vm.privateFormSummary = privateDatacontext.formSummary;
            }
            if (allDataReady.length === 2) {
                nbsUiOptionParser = new NbsUiOptionParser(vm.uiContext.translate);

                defaultKeys = privateDatacontext.defaultKeys;

                // Start of execution workflow of load data bar components 
                initViewTitle();
            }
        };

        function initViewTitle() {
            var cols = [];
            switch (defaultKeys.columnsKey) {
                case '':
                case 'ondemand':
                case 'tocompile':
                case 'mysurveys':
                    cols = ['SurveyId', 'SurveyCode', 'SurveyName', 'VhrEventTypeName', 'SurveyExpireDate', 'TargetName', 'VhrEventLifeStatusName', 'SurveyAuthorName', 'Assignee', 'VhrEventLabelName'];
                    break;
                case 'compiled':
                    cols = ['SurveyId', 'SurveyCode', 'SurveyName', 'VhrEventTypeName', 'SurveyExpireDate', 'TargetName', 'VhrEventLifeStatusName', 'SurveyCompileDate', 'SurveyAuthorName', 'Assignee', 'VhrEventLabelName'];
                    break;
                case 'archived':
                    cols = ['SurveyId', 'SurveyCode', 'SurveyName', 'VhrEventTypeName', 'SurveyExpireDate', 'TargetName', 'VhrEventLifeStatusName', 'SurveyCompileDate', 'SurveyAuthorName', 'Assignee', 'IsArchived'];
                    break;
            }

            if (Nembus.Aud.Library.SSV.CanManageSurveyWorkflow()) {
                cols.push('WorkflowStatusName');
            }


            var defaultStartData = new NbsUiData();
            defaultStartData.setFilter('vhreventstartdatetimeline', Nembus.Common.Library.component.bar.datarange.getTimelineDatarange('FY+0'));
            defaultStartData.setFilter('surveycompiledatetimeline', Nembus.Common.Library.component.bar.datarange.getTimelineDatarange('FY+0'));
            defaultStartData.setColumns(cols);
            defaultStartData.setGroupingColumns(getGroupingList());
            defaultStartData.setGroupingSelected(getGroupingList(privateDatacontext.groupingKey));
            defaultStartData.setFilterList(getFilterList());

            var sortField = 'SurveyId';
            var sortDirection = true; // descending
            switch (defaultKeys.sortingKey) {
                case 'tocompile':
                    sortField = 'SurveyExpireDate';
                    sortDirection = false;
                    break;
                case 'compiled':
                    sortField = 'SurveyCompileDate';
                    sortDirection = true;
                    break;
            }
            defaultStartData.setSortingField(sortField, sortDirection);


            vm.initViewTitle = defaultStartData.startData;
        }

        //#region hook from components       
        vm.onViewTitleSelectionEvent = function (result) {
            privateUIData = JSON.parse(JSON.stringify(result.uiData));
            setComponents(privateUIData);

            setBarDatasources()
                .then(function () {
                    barOnChanges(privateUIData);
                });
        };
        vm.onUiChanges = function (result) {
            privateUIData.columns = result.uiData.columns;
            privateUIData.grouping = result.uiData.grouping;
            privateUIData.sorting = result.uiData.sorting;
            privateUIData.filters.freeText = result.uiData.filters.freeText;

            refreshViewTitleDataContext(privateUIData);

            vm.onGridUiChangesEvent({ result: privateUIData });
        };
        vm.onFilterChanges = function (result) {
            privateUIData.filterList = result.filters.filterList;

            refreshViewTitleDataContext(privateUIData);
            setStatusComponents(privateUIData.filterList);

            setBarDatasources()
                .then(function () {
                    barOnChanges(privateUIData);
                });
        };
        vm.onDdMsVhrEventGroups = function (options) {
            privateUIData.filters.vhreventgroups = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdMsVhrEventLabels = function (options) {
            privateUIData.filters.vhreventlabels = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdMsVhrEventTypes = function (options) {
            privateUIData.filters.vhreventtypes = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdMsVhrGroups = function (options) {
            privateUIData.filters.vhrgroups = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdMsVhrTypes = function (options) {
            privateUIData.filters.vhrtypes = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdVhrEventLifeStatus = function (options) {
            privateUIData.filters.vhreventlifestatuses = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdWorkflowStatus = function (options) {
            privateUIData.filters.workflowstatuses = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdCreationPatterns = function (options) {
            privateUIData.filters.creationpatterns = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdAuthor = function (options) {
            privateUIData.filters.author = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onStartDateDatarangeSelectionEvent = function (timeline) {
            privateUIData.filters.vhreventstartdatetimeline = timeline;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onCompileDateDatarangeSelectionEvent = function (timeline) {
            privateUIData.filters.surveycompiledatetimeline = timeline;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdMsVhrTargets = function (options) {
            privateUIData.filters.vhrtargets = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdMsAssignee = function (options) {
            privateUIData.filters.assignee = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        //#endregion

        // refresh current preference uiData
        function refreshViewTitleDataContext(uiDataParam) {
            vm.viewTitleDataContext = Nembus.Common.Library.component.getDataContextViewTitle(uiDataParam);
        }

        function setBarDatasources() {
            var defer = $q.defer();
            var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
            var funcsPromises = [];

            if (vm.privateComponentsStatus.vhreventtypes.isVisible) {
                funcsPromises.push(getVhrEventTypes(false));
            }
            if (vm.privateComponentsStatus.vhreventgroups.isVisible) {
                funcsPromises.push(getVhrEventGroups(false));
            }
            if (vm.privateComponentsStatus.vhreventlabels.isVisible) {
                funcsPromises.push(getVhrEventLabels(false));
            }
            if (vm.privateComponentsStatus.vhrtypes.isVisible) {
                funcsPromises.push(getVhrTypes(false));
            }
            if (vm.privateComponentsStatus.vhrgroups.isVisible) {
                funcsPromises.push(getVhrGroups(false));
            }
            if (vm.privateComponentsStatus.vhreventlifestatuses.isVisible) {
                funcsPromises.push(getLifeStatuses());
            }
            if (vm.privateComponentsStatus.workflowstatuses.isVisible) {
                funcsPromises.push(getWorkflowActiveStatuses(false, privateDatacontext.moduleTargetId));
            }
            if (vm.privateComponentsStatus.creationpatterns.isVisible) {
                funcsPromises.push(getSurveyCreationPatterns());
            }

            if (vm.viewElementCode === 'grid' || vm.viewElementCode === 'stream' || vm.viewElementCode === 'board') {
                if (vm.privateComponentsStatus.vhrtargets.isVisible) {
                    funcsPromises.push(getTargetDtos(false));
                }
                if (vm.privateComponentsStatus.author.isVisible
                    || vm.privateComponentsStatus.assignee.isVisible) {

                    funcsPromises.push(getCurrentContextAppUsers(false));
                }
                if (vm.privateComponentsStatus.assignee.isVisible) {
                    funcsPromises.push(getDrbacUserGroups(false, virtualCompanyId));
                }
            }

            $q.all(funcsPromises)
                .then(function () {
                    vm.nbsDataPrivate.assignee = nbsUiOptionParser.getAssignment(pAppUsers, pDrbacUserGroups, null);
                    vm.nbsDataPrivate.author = nbsUiOptionParser.getUiOptionsByKey(pAppUsers, 'AppUserDto');
                    defer.resolve(true);
                });

            return defer.promise;
        }

        function getFilterList() {
            var uiDataFilters = [];
            uiDataFilters.push(new NbsUiDataFilter({ code: 'vhreventtypes', name: 'SurveyType' }));
            uiDataFilters.push(new NbsUiDataFilter({ code: 'vhreventgroups', name: 'SurveyGroup' }));
            uiDataFilters.push(new NbsUiDataFilter({ code: 'vhreventlabels', name: 'SurveyDto.VhrEventLabels' }));
            uiDataFilters.push(new NbsUiDataFilter({ code: 'vhrtypes', name: 'TargetType' }));
            uiDataFilters.push(new NbsUiDataFilter({ code: 'vhrgroups', name: 'TargetGroup' }));

            var isOndemandFilter = false;
            var isCompiledFilter = false;
            if (vhrEventLifeStatusKey === 'ondemand') {
                isOndemandFilter = true;
            }
            if (vhrEventLifeStatusKey === 'compiled') {
                isCompiledFilter = true;
            }

            if (!isOndemandFilter && !isCompiledFilter) {
                uiDataFilters.push(new NbsUiDataFilter({ code: 'vhreventlifestatuses', name: 'SurveyDto.SurveyLifeStatusId', visible: true }));
            }

            uiDataFilters.push(new NbsUiDataFilter({ code: 'workflowstatuses', name: 'SurveyDto.WorkflowStatusKindId' }));

            if (vm.viewElementCode === 'grid' || vm.viewElementCode === 'stream' || vm.viewElementCode === 'board') {
                if (!isOndemandFilter) {
                    if (vm.viewElementCode === 'grid' || vm.viewElementCode === 'stream') {
                        uiDataFilters.push(new NbsUiDataFilter({ code: 'vhreventstartdatetimeline', name: 'SurveyDto.SurveyExpireDate', visible: true, canHide: false }));
                    }

                    uiDataFilters.push(new NbsUiDataFilter({ code: 'surveycompiledatetimeline', name: 'SurveyDto.SurveyCompileDate' }));
                }

                uiDataFilters.push(new NbsUiDataFilter({ code: 'creationpatterns', name: 'SurveyDto.SurveyCreationPatternId' }));
                uiDataFilters.push(new NbsUiDataFilter({ code: 'vhrtargets', name: 'Targets' }));
                uiDataFilters.push(new NbsUiDataFilter({ code: 'assignee', name: 'AudAssignee' }));
                uiDataFilters.push(new NbsUiDataFilter({ code: 'author', name: 'SurveyDto.SurveyAuthorName' }));
            }

            return uiDataFilters;
        }

        function getGroupingList(confcode) {
            var uiOptions = [];

            if (!confcode || confcode === 'timelinevirtualequipment') {
                uiOptions.push(new NbsUiOption({ optionName: vm.uiContext.translate('Target'), optionCode: "virtualEquipment", optionDisplayName: vm.uiContext.translate('Target'), optionValue: 'virtualEquipment' }));
            }

            if (!confcode || confcode === 'timelineassignment') {
                uiOptions.push(new NbsUiOption({ optionName: vm.uiContext.translate('Assignee'), optionCode: "assignment", optionDisplayName: vm.uiContext.translate('Assignee'), optionValue: 'assignment' }));
            }

            return uiOptions;
        }

        function getCurrentContextAppUsers(forceReload) {
            var defer = $q.defer();
            var filter = {};

            rbacService.getCurrentContextAppUsers(filter, forceReload)
                .then(function (data) {
                    pAppUsers = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getDrbacUserGroups(forceReload, virtualCompanyId) {
            var defer = $q.defer();
            var filter = {
                VirtualCompanyId: virtualCompanyId
            };

            drbacService.getDrbacUserGroups(filter, forceReload)
                .then(function (data) {
                    pDrbacUserGroups = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrEventTypes(forceReload) {
            var filter = { AppStoreBw: privateDatacontext.appStoreBw };
            var defer = $q.defer();

            vhreventService.getVhrEventTypes(filter, forceReload)
                .then(function (data) {
                    vm.nbsDataPrivate.vhreventtypes = nbsUiOptionParser.getUiOptionsByKey(data.results, 'VhrEventType');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getSurveyCreationPatterns() {
            var defer = $q.defer();
            var surveyCreationPatternEnumParsed = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyCreationPattern, 'SurveyDto', vm.uiContext.translate);
            var surveyCreationPatternObj = surveyCreationPatternEnumParsed.obj.normal.name_value;
            var creationPatterns = Lazy(surveyCreationPatternEnumParsed.list.translated).reject({ value: surveyCreationPatternObj.OnDemand }).toArray();

            vm.nbsDataPrivate.creationpatterns = nbsUiOptionParser.getUiOptionsByEnum(creationPatterns);
            defer.resolve(true);
            return defer.promise;
        }

        function getLifeStatuses() {
            var lifeStatuses = [];
            var defer = $q.defer();
            var surveyLifeStatusEnumParsed = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyLifeStatus, 'SurveyDto', vm.uiContext.translate);

            switch (vhrEventLifeStatusKey) {
                case 'ondemand':
                    break;
                case 'tocompile':
                    lifeStatuses = Lazy(surveyLifeStatusEnumParsed.list.translated)
                        .filter((lifeStatus) => {
                            return Lazy(Nembus.Aud.Library.GetSurveyLifeStatusListIds(audService, false)).contains(lifeStatus.value);
                        }).toArray();

                    break;
                case 'compiled':
                    lifeStatuses = Lazy(surveyLifeStatusEnumParsed.list.translated)
                        .filter((lifeStatus) => {
                            return Lazy(Nembus.Aud.Library.GetSurveyLifeStatusListIds(audService, true)).contains(lifeStatus.value);
                        })
                        .toArray();

                    break;

                default:
                    lifeStatuses = surveyLifeStatusEnumParsed.list.translated;
            }

            vm.nbsDataPrivate.vhreventlifestatuses = nbsUiOptionParser.getUiOptionsByEnum(lifeStatuses);
            defer.resolve(true);
            return defer.promise;
        }

        function getVhrEventGroups(forceReload) {
            var filter = { AppStoreBw: privateDatacontext.appStoreBw };
            var defer = $q.defer();

            vhreventService.getVhrEventGroups(filter, forceReload)
                .then(function (data) {
                    vm.nbsDataPrivate.vhreventgroups = nbsUiOptionParser.getUiOptionsByKey(data.results, 'VhrEventGroup');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrEventLabels(forceReload) {
            var filter = { AppStoreBw: privateDatacontext.appStoreBw };
            var defer = $q.defer();

            vhreventService.getVhrEventLabels(filter, forceReload)
                .then(function (data) {
                    vm.nbsDataPrivate.vhreventlabels = nbsUiOptionParser.getUiOptionsByKey(data.results, 'VhrEventLabel');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrTypes(forceReload) {
            var filter = {};
            var defer = $q.defer();

            foundationService.getVhrTypes(filter, forceReload)
                .then(function (data) {
                    vm.nbsDataPrivate.vhrtypes = nbsUiOptionParser.getUiOptionsByKey(data.results, 'VhrType');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getVhrGroups(forceReload) {
            var filter = {};
            var defer = $q.defer();

            foundationService.getVhrGroups(filter, forceReload)
                .then(function (data) {
                    vm.nbsDataPrivate.vhrgroups = nbsUiOptionParser.getUiOptionsByKey(data.results, 'VhrGroup');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getWorkflowActiveStatuses(forceReload, moduleTargetId) {
            var defer = $q.defer();

            workflowService.getWorkflowActiveStatuses({ ModuleTargetId: moduleTargetId }, forceReload)
                .then(function (data) {
                    vm.nbsDataPrivate.workflowstatuses = nbsUiOptionParser.getUiOptionsByKey(data.results, 'WorkflowStatus');
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getTargetDtos(forceReload) {
            var vhrStatusEnum = Nembus.Common.Library.util.parseEnum(foundationService.dataContext.enumTypes.VhrStatus, null, vm.uiContext.translate).obj.normal.name_value;

            var filter = {
                AppStoreBw: null,
                VhrStatusId: vhrStatusEnum.Enabled
            };

            var defer = $q.defer();

            foundationService.getTargetDtos(filter, forceReload)
                .then(function (data) {
                    vm.nbsDataPrivate.vhrtargets = nbsUiOptionParser.getUiOptionsByKey(data.results, 'VhrTarget');

                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function setComponents(uiDataParam) {
            setCommonGridBarDataContext(uiDataParam);
            setSelectionComponents(uiDataParam);
            setStatusComponents(uiDataParam.filterList);
            vm.onGridUiChangesEvent({ result: privateUIData });
        }

        function setCommonGridBarDataContext(uiDataParam) {
            vm.commonGridBarDataContext = Nembus.Common.Library.component.getDataContextCommonGridBar(uiDataParam);
            vm.commonBarDatasource = privateDatacontext.commonBarDatasource
        }

        function setSelectionComponents(uiDataParam) {
            vm.nbsDataPrivate.vhreventgroupsselected = uiDataParam.filters.vhreventgroups ? uiDataParam.filters.vhreventgroups : [];
            vm.nbsDataPrivate.vhreventlabelsselected = uiDataParam.filters.vhreventlabels ? uiDataParam.filters.vhreventlabels : [];
            vm.nbsDataPrivate.vhreventtypesselected = uiDataParam.filters.vhreventtypes ? uiDataParam.filters.vhreventtypes : [];
            vm.nbsDataPrivate.vhrgroupsselected = uiDataParam.filters.vhrgroups ? uiDataParam.filters.vhrgroups : [];
            vm.nbsDataPrivate.vhrtypesselected = uiDataParam.filters.vhrtypes ? uiDataParam.filters.vhrtypes : [];
            vm.nbsDataPrivate.creationpatternsselected = uiDataParam.filters.creationpatterns ? uiDataParam.filters.creationpatterns : [];
            vm.nbsDataPrivate.vhreventlifestatusesselected = uiDataParam.filters.vhreventlifestatuses ? uiDataParam.filters.vhreventlifestatuses : [];
            vm.nbsDataPrivate.workflowstatusesselected = uiDataParam.filters.workflowstatuses ? uiDataParam.filters.workflowstatuses : [];
            vm.nbsDataPrivate.authorselected = uiDataParam.filters.author ? uiDataParam.filters.author : [];
            vm.nbsDataPrivate.vhrtargetsselected = uiDataParam.filters.vhrtargets ? uiDataParam.filters.vhrtargets : [];
            vm.nbsDataPrivate.assigneeselected = uiDataParam.filters.assignee;
            vm.nbsDataPrivate.vhreventstartdatetimelineselected = uiDataParam.filters.vhreventstartdatetimeline;
            vm.nbsDataPrivate.surveycompiledatetimelineselected = uiDataParam.filters.surveycompiledatetimeline;
        }

        function setStatusComponents(filterList) {
            vm.privateComponentsStatus.vhreventgroups = Nembus.Common.Library.component.getStatus('vhreventgroups', filterList);
            vm.privateComponentsStatus.vhreventlabels = Nembus.Common.Library.component.getStatus('vhreventlabels', filterList);
            vm.privateComponentsStatus.vhreventtypes = Nembus.Common.Library.component.getStatus('vhreventtypes', filterList);
            vm.privateComponentsStatus.vhrgroups = Nembus.Common.Library.component.getStatus('vhrgroups', filterList);
            vm.privateComponentsStatus.vhrtypes = Nembus.Common.Library.component.getStatus('vhrtypes', filterList);
            vm.privateComponentsStatus.vhreventlifestatuses = Nembus.Common.Library.component.getStatus('vhreventlifestatuses', filterList);
            vm.privateComponentsStatus.workflowstatuses = Nembus.Common.Library.component.getStatus('workflowstatuses', filterList);
            vm.privateComponentsStatus.creationpatterns = Nembus.Common.Library.component.getStatus('creationpatterns', filterList);
            vm.privateComponentsStatus.author = Nembus.Common.Library.component.getStatus('author', filterList);
            vm.privateComponentsStatus.vhrtargets = Nembus.Common.Library.component.getStatus('vhrtargets', filterList);
            vm.privateComponentsStatus.assignee = Nembus.Common.Library.component.getStatus('assignee', filterList);
            vm.privateComponentsStatus.vhreventstartdatetimeline = Nembus.Common.Library.component.getStatus('vhreventstartdatetimeline', filterList);
            vm.privateComponentsStatus.surveycompiledatetimeline = Nembus.Common.Library.component.getStatus('surveycompiledatetimeline', filterList);
        }

        function barOnChanges(uiDataParam) {
            var selectedVhrEventGroupsId = [];
            var selectedVhrEventLabelsId = [];
            var selectedVhrEventTypesId = [];
            var selectedVhrGroupsId = [];
            var selectedVhrTypesId = [];
            var selectedSurveyCreationPatternId = null;
            var selectedVhrEventLifeStatusIds = [];
            var selectedWorkflowStatusIds = [];
            var selectedVhrTargetsId = [];
            var selectedAssignedAppUserId = [];
            var selectedAssignedDrbacUserGroupId = [];
            var isNotAssigned = null;
            var compilerUserPersonIds = [];
            var vhreventtimelinefrom = null;
            var vhreventtimelineto = null;
            var compiletimelinefrom = null;
            var compiletimelineto = null;
            var scheduleParams = {};
            var expressionTypeObj = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.ExpressionType, null, vm.uiContext.translate).obj.normal.name_value;

            if (vm.viewElementCode === 'grid' || vm.viewElementCode === 'stream' || vm.viewElementCode === 'board') {
                vhreventtimelinefrom = Nembus.Common.Library.component.bar.datarange.getDataRangeFromUiDataParam(uiDataParam.filters.vhreventstartdatetimeline).dateFrom;
                vhreventtimelineto = Nembus.Common.Library.component.bar.datarange.getDataRangeFromUiDataParam(uiDataParam.filters.vhreventstartdatetimeline).dateTo;
                //vhreventtimelinefrom = uiDataParam.filters.vhreventstartdatetimeline.dateFrom;
                //vhreventtimelineto = uiDataParam.filters.vhreventstartdatetimeline.dateTo;

                if (vm.privateComponentsStatus.vhrtargets.isVisible) {
                    selectedVhrTargetsId = Lazy(uiDataParam.filters.vhrtargets).pluck('optionValue').toArray();
                }

                if (vm.privateComponentsStatus.assignee.isVisible) {
                    if (uiDataParam.filters.assignee) {
                        if (uiDataParam.filters.assignee.isNotAssigned) {
                            isNotAssigned = true;
                        } else {
                            selectedAssignedAppUserId = Lazy(uiDataParam.filters.assignee.appusers).pluck('optionValue').toArray();
                            selectedAssignedDrbacUserGroupId = Lazy(uiDataParam.filters.assignee.drbacusergroups).pluck('optionValue').toArray();
                        }
                    }
                }

                if (vm.privateComponentsStatus.surveycompiledatetimeline.isVisible) {
                    compiletimelinefrom = Nembus.Common.Library.component.bar.datarange.getDataRangeFromUiDataParam(uiDataParam.filters.surveycompiledatetimeline).dateFrom;
                    compiletimelineto = Nembus.Common.Library.component.bar.datarange.getDataRangeFromUiDataParam(uiDataParam.filters.surveycompiledatetimeline).dateTo;
                    //compiletimelinefrom = uiDataParam.filters.surveycompiledatetimeline.dateFrom;
                    //compiletimelineto = uiDataParam.filters.surveycompiledatetimeline.dateTo;
                }

                if (vm.privateComponentsStatus.author.isVisible) {
                    compilerUserPersonIds = Lazy(uiDataParam.filters.author).pluck('optionValue').toArray();
                }

                if (vm.privateComponentsStatus.creationpatterns.isVisible) {
                    selectedSurveyCreationPatternId = Lazy(uiDataParam.filters.creationpatterns).pluck('optionValue').first();
                }

            } else {
                scheduleParams.scheduleOrientation = ej.Schedule.Orientation.Horizontal;
                if (vm.viewElementCode === 'calendar') {
                    scheduleParams.scheduleOrientation = ej.Schedule.Orientation.Vertical;
                }

                scheduleParams.selectedGroupings = uiDataParam.grouping.selected;
            }
            if (vm.privateComponentsStatus.vhreventgroups.isVisible) {
                selectedVhrEventGroupsId = Lazy(uiDataParam.filters.vhreventgroups).pluck('optionValue').toArray();
            }
            if (vm.privateComponentsStatus.vhreventlabels.isVisible) {
                selectedVhrEventLabelsId = Lazy(uiDataParam.filters.vhreventlabels).pluck('optionValue').toArray();
            }
            if (vm.privateComponentsStatus.vhreventtypes.isVisible) {
                selectedVhrEventTypesId = Lazy(uiDataParam.filters.vhreventtypes).pluck('optionValue').toArray();
            }
            if (vm.privateComponentsStatus.vhrgroups.isVisible) {
                selectedVhrGroupsId = Lazy(uiDataParam.filters.vhrgroups).pluck('optionValue').toArray();
            }
            if (vm.privateComponentsStatus.vhrtypes.isVisible) {
                selectedVhrTypesId = Lazy(uiDataParam.filters.vhrtypes).pluck('optionValue').toArray();
            }
            if (vm.privateComponentsStatus.vhreventlifestatuses.isVisible) {
                selectedVhrEventLifeStatusIds = Lazy(uiDataParam.filters.vhreventlifestatuses).pluck('optionValue').toArray();
            }
            if (vm.privateComponentsStatus.workflowstatuses.isVisible) {
                selectedWorkflowStatusIds = Lazy(uiDataParam.filters.workflowstatuses).pluck('optionValue').toArray();
            }

            $timeout(() => {
                vm.onBarChanges({
                    result: {
                        surveyFilter: {},
                        vhrEventFilter: {
                            VirtualHumanResourcesId: selectedVhrTargetsId,
                            VhrEventGroupsId: selectedVhrEventGroupsId,
                            VhrEventLabelsId: selectedVhrEventLabelsId,
                            VhrEventTypesId: selectedVhrEventTypesId,
                            VhrEventLifeStatusIds: selectedVhrEventLifeStatusIds,
                            WorkflowStatusIds: selectedWorkflowStatusIds,
                            StartDate1: vhreventtimelinefrom,
                            StartDate1Expression: expressionTypeObj.GreaterThanOrEqual,
                            StartDate2: vhreventtimelineto,
                            StartDate2Expression: expressionTypeObj.LessThanOrEqual,
                            VhrEventLifeStatusExpression: expressionTypeObj.Equal,
                            AppUserAssignedIds: selectedAssignedAppUserId,
                            UserGroupAssignedIds: selectedAssignedDrbacUserGroupId,
                            AssignmentExpression: expressionTypeObj.Or,
                            IsNotAssigned: isNotAssigned
                        },
                        surveyVhrEventFilter: {
                            SurveyCompileDateSystem1: compiletimelinefrom,
                            SurveyCompileDateSystem1Expression: expressionTypeObj.GreaterThanOrEqual,
                            SurveyCompileDateSystem2: compiletimelineto,
                            SurveyCompileDateSystem2Expression: expressionTypeObj.LessThanOrEqual,
                            CompilerUserPersonIds: compilerUserPersonIds,
                            SurveyCreationPatternId: selectedSurveyCreationPatternId
                        },
                        virtualHrFilter: {
                            VhrTypesId: selectedVhrTypesId,
                            VhrGroupsId: selectedVhrGroupsId
                        }
                    },
                    scheduleParams: scheduleParams
                });
            });
        }
        //#endregion 
    };


    nbsSurveyBarComponent.$inject = injectParams;
    angular.module('nbs.aud.module').component('nbsSurveyBar', {
        templateUrl: '/aud/surveys/components/nbsSurveyBarComponent/_?c=' + window.codeCache(),
        controller: nbsSurveyBarComponent,
        controllerAs: 'vm',
        bindings: {
            onBarChanges: '&',
            onGridUiChangesEvent: '&',

            viewElementCode: "@",
            nbsDataContext: "<", //e.g. {vhreventtypes:[NbsUiOption,NbsUiOption],vhreventgroups:[],status etc..}
            uiContext: "<"
        }
    });
});

