﻿'use strict';

define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'activityService', 'navigationService', 'contextService'];
    function wzAddActivityStepSummary($q, $scope, $timeout, $injector, activityService, navigationService, contextService) {
        var vm = this;
        nbsVm.call(vm, activityService, navigationService, contextService, $q, $injector, $timeout);

        var pParams = navigationService.stateParamsModal().parentParams;
        pParams = pParams ? pParams : navigationService.stateParams();

        // beardcrumb information
        var beardCrumbInfo = JSON.parse(pParams.beardCrumbInfo);
        Lazy(beardCrumbInfo.items)
            .each((item) => {
                item.title = vm.translate(item.title);
            })
        vm.beardCrumbInfo = beardCrumbInfo;

        // navigation buttons visibility
        vm.setWizardButtonVisibility(pParams);

        vm.currentCollector = activityService.wizardDependActivityCollector;

        getActivities(true, activityService.wizardDependActivityCollector.activityIds)
            .then((activities) => {
                activityService.wizardDependActivityCollector.activities = activities;

                var nbsGridDatacontext = new NbsGrid(vm.translate);
                nbsGridDatacontext.configureGrid('activity', 'wizard');
                nbsGridDatacontext.setModalMode();
                nbsGridDatacontext.setIsReadonly();
                nbsGridDatacontext.allowSearching = false;
                nbsGridDatacontext.setDatasource(activities, false);
                vm.nbsGridDatacontext = nbsGridDatacontext;
            });

        vm.modalEnd = function () {
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalEnd);
            navigationService.closeCurrentModal(modalResult);
        };

        vm.modalPrevious = function () {
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalPrevious);
            navigationService.closeCurrentModal(modalResult);
        };

        vm.modalCancel = function () {
            navigationService.confirmExitWizard()
                .then(function (result) {
                    if (result) {
                        var modalResult = getModalResult(navigationService.navigationActionTypes.modalCancel);
                        navigationService.closeCurrentModal(modalResult);
                    }
                });
        };

        function getActivities(forceReload, activitiesId) {
            var filter = {
                ActivitiesId: activitiesId
            };
            var defer = $q.defer();

            activityService.getActivities(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }


        function getModalResult(dialogResult) {
            var modalResult = new navigationService.modalResult()
            modalResult.data = activityService.wizardDependActivityCollector;
            modalResult.sourceParams = pParams;
            modalResult.dialogResult = dialogResult;
            return modalResult;
        }
    };

    wzAddActivityStepSummary.$inject = injectParams;
    wzAddActivityStepSummary.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('wzAddActivityStepSummary', wzAddActivityStepSummary);
});