﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$locale', '$scope', '$timeout', '$injector', 'utilService', 'vhreventService', 'foundationService', 'audService', 'reportService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var htmlToPdfSurveyQuestionOptionDistributionReportForm = function ($q, $locale, $scope, $timeout, $injector, utilService, vhreventService, foundationService, audService, reportService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, audService, navigationService, contextService, $q, $injector, $timeout);
        var checkStateChangeParams = { dirtyFlagIsDisabled: vm.isDirtyDisabled };
        checkStateChangeService.checkFormOnStateChange($scope, audService, checkStateChangeParams);

        // all form items are enabled
        vm.setIsEditingReport();

        var appStoreCode = 'aud';
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var stepsToRender = [];
        var surveyLifeStatusEnumObj = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyLifeStatus, null, vm.translate).obj.normal.name_value;
        var expressionTypeEnum = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.ExpressionType, null, vm.translate).obj.normal.name_value;
        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
        var pFilter = {};
        var sections = [];
        var questions = [];
        var questionOptions = [];

        vm.tabs = { form: 0, list: 1 };
        vm.activeTabIndex = 0;

        vm.tabOnSelect = function (tab) {
            switch (tab) {
                case vm.tabs.form:
                    vm.showForm = true;
                    vm.showList = false;
                    break;
                case vm.tabs.list:
                    vm.showForm = false;
                    vm.showList = true;
                    break;
            }

            vm.activeTabIndex = tab;
        };
        vm.tabOnSelect(vm.tabs.form);

        var dsCallback = function () { return vhreventService.getVhrEventTypes({ AppStoreBw: appStoreBw }, false); };
        var selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventType', viewTitle: vm.translate('SurveyDto.VhrEventTypeId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventType = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        dsCallback = function () { return vhreventService.getVhrEventGroups({ AppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventGroup', viewTitle: vm.translate('SurveyDto.VhrEventGroupId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventGroup = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        dsCallback = function () { return foundationService.getVhrTypes(false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrType', viewTitle: vm.translate('TargetDto.TargetTypeId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrType = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        dsCallback = function () { return foundationService.getVhrGroups(false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrGroup', viewTitle: vm.translate('TargetDto.TargetGroupId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrGroup = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        vm.nbsAuthorDrbacModalObj = new NbsAssigneeSelectorModal({
            translator: vm.translate,
            suspendDirty: vm.suspendDirty,
            navigationService: navigationService,
            contextUserForSelection: null,
            getServerVarFn: Nembus.Common.Library.server.getServerVar
        });
        vm.nbsAuthorDrbacModalObj.viewUsers();

        vm.nbsSurveyDrbacModalObj = new NbsAssigneeSelectorModal({
            translator: vm.translate,
            suspendDirty: vm.suspendDirty,
            navigationService: navigationService,
            contextUserForSelection: null,
            getServerVarFn: Nembus.Common.Library.server.getServerVar
        });
        vm.nbsSurveyDrbacModalObj.setVisibilityServerVar('ssv_audit_survey_assignee_visibility_selection');

        vm.bindObject = {
            surveys: [],
            filters: {}   // filters binded on UI form
        };

        vm.refreshBindFilters = function () {
            vm.bindObject.filters.SurveySectionOrders = [];
            vm.bindObject.filters.SurveyQuestionOrders = [];

            vm.currentForm.SectionQuestionId = null;
            if (Lazy(vm.nbsSelectionQuestionOptions.selectedItems).some()) {
                vm.bindObject.filters.SurveySectionOrders = Lazy(vm.nbsSelectionQuestionOptions.selectedItems).pluck('SurveySectionOrder').toArray();
                vm.bindObject.filters.SurveyQuestionOrders = Lazy(vm.nbsSelectionQuestionOptions.selectedItems).pluck('SurveyQuestionOrder').toArray();

                // used only for validation on UI
                vm.currentForm.SectionQuestionId = vm.nbsSelectionQuestionOptions.selectedItems;
            }
        };

        vm.currentForm = audService.dataContext.htmlToPdfSurveyQuestionOptionDistributionClientDatas.add({
            Id: NbsGuidManager.newGuid(),
            IsArchived: null
        });

        
        // REFERS to 'reportDefinitionHtmlToDocDetail'
        vm.parentScope = $scope.$parent.vm;
        vm.parentScope.ribbonDatacontext.setRibbonStatusButton(vm.parentScope.ribbonDatacontext.Buttons.Search, true);
        vm.parentScope.ribbonDatacontext.refreshStatusButtons();
        vm.parentScope.initReportToDocHeader({ onSearchActionHtmlToDocFn: onActionSearch, onCreateHtmlFn: createHtmlReport });

        selectionParams = { destModalKey: 'vhr', entityMode: 'Target', translateKey: 'Target', isMultiselect: true };
        vm.nbsSelectionGridWithModalTarget = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalTarget.setOnSelectFnCallback(vm.refreshBindFilters);

        selectionParams = { stateTo: 'app.nav.surveyslistmodal', destModalKey: 'survey', dataKey: 'surveycompleted', filter: { VhrEventLifeStatusIds: [surveyLifeStatusEnumObj.ReportedAsFinished] } };
        vm.nbsSelectionGridWithModalSurvey = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalSurvey.setOnSelectFnCallback(vm.refreshBindFilters);

        // use of NbsSelectionModal in customized mode (Hibrid with UxHelper NbsSelectionModal)
        vm.nbsSelectionQuestionOptions = new NbsSelectionModal(vm.suspendDirty, navigationService, { destModalKey: null });
        vm.nbsSelectionQuestionOptions.disabled = false;
        vm.nbsSelectionQuestionOptions.addOnclick = function () {
            vm.nbsSelectionQuestionOptions.modalItems = [{ index: 1, SurveySectionOrder: null, SurveyQuestionOrder: null }];

            vm.modalInstance = navigationService.modal().open(vm.modalOptions({
                windowClass: vm.NBS_MODAL_CLASS_FORM_MD,
                templateUrl: 'insertSectionQuestionTemplate',
                scope: $scope
            }));
            vm.modalInstance.result.then(function () {
                //ok
                // vm.nbsSelectionQuestionOptions.selectedItems (UxHelper)
                // vm.nbsSelectionQuestionOptions.modalItems (local controller)
                Lazy(vm.nbsSelectionQuestionOptions.modalItems)
                    .each((modalItem) => {
                        if (modalItem.SurveySectionOrder || modalItem.SurveyQuestionOrder) {
                            var sectionOrder = modalItem.SurveySectionOrder ? modalItem.SurveySectionOrder : '';
                            var questionOrder = modalItem.SurveyQuestionOrder ? modalItem.SurveyQuestionOrder : '';

                            modalItem.nbs_id = sectionOrder + '_' + questionOrder;
                            modalItem.nbs_name = vm.translate('Section') + ':' + sectionOrder + ' / ' + vm.translate('Question') + ':' + questionOrder + '';
                        }
                    });

                Lazy(vm.nbsSelectionQuestionOptions.modalItems)
                    .each((item) => {
                        if (!Lazy(vm.nbsSelectionQuestionOptions.selectedItems).where({ nbs_id: item.nbs_id }).some()) {
                            vm.nbsSelectionQuestionOptions.selectedItems.push(item);
                        }
                    });

                vm.refreshBindFilters();
            }, function () {
                //dismiss
            });
            vm.modalInstance.ok = function () {
                vm.modalInstance.close();
            };
            vm.modalInstance.cancel = function () { vm.modalInstance.dismiss(); };
        };
        vm.nbsSelectionQuestionOptions.setOnDeleteFnCallback(vm.refreshBindFilters);
        vm.nbsSelectionQuestionOptions.addSectionQuestionOnClick = function () {
            //add item
            var cIndex = 0;
            if (Lazy(vm.nbsSelectionQuestionOptions.modalItems).some()) {
                cIndex = Lazy(vm.nbsSelectionQuestionOptions.modalItems).pluck('index').max();
            }
            vm.nbsSelectionQuestionOptions.modalItems.push({ index: cIndex + 1, SurveySectionOrder: null, SurveyQuestionOrder: null });
        };
        vm.nbsSelectionQuestionOptions.deleteSectionQuestionOnClick = function (item) {
            // remove item
            vm.nbsSelectionQuestionOptions.modalItems = Lazy(vm.nbsSelectionQuestionOptions.modalItems).reject({ index: item.index }).toArray();
        };
        
        // set title of current controller in report definition detail view
        navigationService.setControllerTitle();

        vm.gridSelection = function (args) {
            $timeout(() => {
                selectedSurveys = args.data;
                selectedSurveyIds = args.dataIds;

                setPrintButtonStatus();
            });
        }

        vm.surveys = [];

        function setPrintButtonStatus() {
            var selected = Lazy(selectedSurveys).some();
            vm.parentScope.ribbonDatacontext.setRibbonStatusButton(vm.parentScope.ribbonDatacontext.Buttons.PrintReportPdf, selected);
            vm.parentScope.ribbonDatacontext.refreshStatusButtons();
        }

        var selectedSurveyIds = [];
        var selectedSurveys = [];

        var nbsGridDatacontext = new NbsAudGrid(vm.translate);
        nbsGridDatacontext.configureGrid('survey', 'kpireport', null);
        nbsGridDatacontext.setGridIsUnderTab();
        nbsGridDatacontext.setIsMultiselect();
        vm.nbsGridDatacontext = nbsGridDatacontext;

        function onActionSearch() {
            vm.currentForm.entityAspect.validateEntity();
            vm.forceDatasourceLocal = {
                refreshkey: new Date().getTime(),
                dataSource: []
            }

            var defer = $q.defer();
            if (vm.currentForm.entityAspect.hasValidationErrors) {
                $timeout(() => { /* implicit scope apply; */ });
                return defer.promise;
            }

            selectedSurveyIds = [];
            selectedSurveys = [];

            pFilter = {
                surveyFilter: {
                    SurveyId: vm.currentForm.SurveyId,
                    SurveyCode: vm.currentForm.SurveyCode,
                    IsArchived: vm.currentForm.IsArchived,
                    IsCompleted: true
                },
                surveySectionFilter: {},
                vhrEventFilter: {
                    ModuleTargetId: moduleTargetId,
                    VhrEventTypesId: vm.nbsSelectionGridWithModalVhrEventType.selectedIds,
                    VhrEventGroupsId: vm.nbsSelectionGridWithModalVhrEventGroup.selectedIds,
                    VirtualHumanResourcesId: vm.nbsSelectionGridWithModalTarget.selectedIds,
                    HaveToExpandVhrsWithAssetTree: true,
                    AppUserAssignedIds: vm.nbsSurveyDrbacModalObj.appUserAreSelected() ? vm.nbsSurveyDrbacModalObj.appUserMultiselectSelectedIds() : null,
                    UserGroupAssignedIds: vm.nbsSurveyDrbacModalObj.drbacUserGroupAreSelected() ? vm.nbsSurveyDrbacModalObj.drbacUserGroupMultiselectSelectedIds() : null
                },
                surveyVhrEventFilter: {
                    CompilerUserPersonIds: vm.nbsAuthorDrbacModalObj.appUserAreSelected() ? vm.nbsAuthorDrbacModalObj.appUserMultiselectSelectedIds() : null,
                    SurveyIds: vm.nbsSelectionGridWithModalSurvey.selectedIds,
                    SurveyCompileDateSystem1: vm.currentForm.SurveyCompileDateSystem1,
                    SurveyCompileDateSystem1Expression: expressionTypeEnum.GreaterThanOrEqual,
                    SurveyCompileDateSystem2: vm.currentForm.SurveyCompileDateSystem2,
                    SurveyCompileDateSystem2Expression: expressionTypeEnum.LessThanOrEqual
                },
                virtualHrFilter: {
                    VhrGroupsId: vm.nbsSelectionGridWithModalVhrGroup.selectedIds,
                    VhrTypesId: vm.nbsSelectionGridWithModalVhrType.selectedIds
                },
                surveyQuestionFilter: {
                },
                surveyQuestionOptionFilter: {
                }
            };

            // reset result search
            vm.surveys = [];

            searchSurveys(pFilter)
                .then(function (surveys) {
                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: surveys
                    }

                    vm.surveys = surveys;

                    setPrintButtonStatus();

                    vm.tabOnSelect(vm.tabs.list);

                    defer.resolve(null);
                });

            return defer.promise;
        }

        function searchSurveys(filter) {
            var defer = $q.defer();
            audService.searchSurveys(filter)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        loadData(true);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(setLocalDropDowns());
            
            $q.all(funcsPromises)
                .then(function () {
                    vm.currentForm.IsImportant = false;
                    vm.currentForm.GroupingId = 'week';

                    vm.refreshBindFilters();
                });
        }

        function createHtmlReport() {
            vm.alertConfig.isModal = false;
            vm.alertConfig.isReadonly = true;
            vm.removeAlertFromList(true);

            vm.currentForm.entityAspect.validateEntity();

            if (vm.currentForm.entityAspect.hasValidationErrors) {
                vm.setErrorMsg({ isMessageVisible: true, message: vm.translate('ValidationErrorsHaveOccurred') });
                $timeout(() => { /* implicit scope apply; */ });
                return;
            }

            var funcsPromises = [];
            funcsPromises.push(getSurveySectionsPostMethod(selectedSurveyIds));
            funcsPromises.push(getSurveyQuestionsPostMethod(selectedSurveyIds));
            funcsPromises.push(getSurveyQuestionOptionsPostMethod(selectedSurveyIds));

            $q.all(funcsPromises)
                .then(function () {
                    // create a datasource for every SyrveySectionOrder/SyrveyQuestionOrder
                    vm.currentArrayDs = getDsSplittedArray(vm.nbsSelectionQuestionOptions.selectedItems, selectedSurveys, sections, questions, questionOptions);
                    parseReportHtmlDatasource(vm.currentArrayDs);
                });
        }

        function getDsSplittedArray(selectionQuestionOptions, surveys, sections, questions, questionOptions) {
            // group sorting
            var descending = false;
            if (vm.currentForm.SortTypeId) {
                descending = vm.currentForm.SortTypeId === 'Descending';
            }

            var result = [];
            Lazy(selectionQuestionOptions)
                .each((item) => {
                    var currSections = Lazy(sections).where({ SurveySectionOrder: item.SurveySectionOrder }).toArray();
                    var currSectionIds = Lazy(currSections).pluck('SurveySectionId').toArray();
                    var currQuestions = Lazy(questions)
                        .filter((q) => { return Lazy(currSectionIds).contains(q.SurveySectionId); })
                        .where({ SurveyQuestionOrder: item.SurveyQuestionOrder })
                        .toArray();
                    var currQuestionIds = Lazy(currQuestions).pluck('SurveyQuestionId').toArray();
                    var currQuestionOptions = Lazy(questionOptions)
                        .filter((qo) => { return Lazy(currQuestionIds).contains(qo.SurveyQuestionId); })
                        .toArray();

                    var dsChart = Nembus.Aud.Library.HtmlToDocReport.SurveyQuestionOptiondistributionDatasource($locale, surveys, currSections, currQuestions, currQuestionOptions, vm.currentForm.IsImportant, vm.currentForm.GroupingId, vm.currentForm.SortFieldId, descending);

                    result.push({
                        nbs_id: item.nbs_id,
                        nbs_name: item.nbs_name,
                        surveySectionOrder: item.SurveySectionOrder,
                        surveyQuestionOrder: item.SurveyQuestionOrder,
                        sections: currSections,
                        questions: currQuestions,
                        questionOptions: currQuestionOptions,
                        htmlStep: 'tabledatas_' + item.nbs_id,

                        chartId: "guid_" + NbsGuidManager.newGuidReplaced(),
                        chartObj: createColumnChart(dsChart.chartPoints),
                        chartPoints: dsChart.chartPoints,
                        tableDatas: dsChart.tableDatas
                    });
                });

            return result;
        }

        // prepare datasource for report in UI (to prepare Html)
        vm.htmlSteps = {
            // created dinamically
        };

        function parseReportHtmlDatasource(dsArray) {
            setReportFilterData();

            stepsToRender = [];

            Lazy(dsArray)
                .each((cDs) => {
                    if (cDs.tableDatas.length > 0) {
                        stepsToRender.push(cDs.htmlStep);
                    }
                });


            // set steps to render before html retrieve from directive
            vm.parentScope.htmlToDocManager.setHtmlStepsToRender(stepsToRender);

            if (!vm.parentScope.htmlToDocManager.hasHtmlStepsToRender()) {
                vm.parentScope.htmlToDocManager.htmlIsRendered();
            }
        }

        function setReportFilterData() {
            vm.reportFilterData = new NbsReportFilterData(vm.translate, contextService.virtualContext.cultureInfo, Nembus.Common.Library.converter.date.formatDate);

            // filter in report are visualized in current order 
            //Audit
            vm.reportFilterData.setDataByRangeDate('SurveyCompileDateSystem', 'SurveyDto.SurveyCompileDate', vm.currentForm.SurveyCompileDateSystem1, vm.currentForm.SurveyCompileDateSystem2);
            vm.reportFilterData.setDataByString('SurveyCode', 'SurveyDto.SurveyCode', vm.currentForm.SurveyCode);
            vm.reportFilterData.setDataByObjectList('VhrEventGroupId', 'SurveyDto.VhrEventGroupId', [vm.currentForm.VhrEventGroupId], vm.vhrEventGroups, 'VhrEventGroupId', ['VhrEventGroupName']);
            vm.reportFilterData.setDataByObjectList('VhrEventTypeId', 'SurveyDto.VhrEventTypeId', [vm.currentForm.VhrEventTypeId], vm.vhrEventTypes, 'VhrEventTypeId', ['VhrEventTypeName']);
            vm.reportFilterData.setDataByObjectList('TargetGroupId', 'TargetGroup', [vm.currentForm.VhrGroupId], vm.vhrGroups, 'VhrGroupId', ['VhrGroupName']);
            vm.reportFilterData.setDataByObjectList('TargetTypeId', 'TargetType', [vm.currentForm.VhrTypeId], vm.vhrTypes, 'VhrTypeId', ['VhrTypeName']);
            vm.reportFilterData.setDataByBoolean('IsArchived', 'SurveyDto.IsArchived', vm.currentForm.IsArchived);
            vm.reportFilterData.setDataByDrbacAuthSelectorModal('CompilerUserPersonId', 'SurveyVhrEvent.CompilerUserPersonId', vm.nbsAuthorDrbacModalObj);
            vm.reportFilterData.setDataBySelectionModal('TargetId', 'SurveyDto.TargetId', vm.nbsSelectionGridWithModalTarget);
            vm.reportFilterData.setDataByDrbacAuthSelectorModal('AudAssignee', 'AudAssignee', vm.nbsSurveyDrbacModalObj);
            //Report
            vm.reportFilterData.setDataBySelectionModal('Section_Question', 'HtmlToPdfSurveyQuestionOptionDistributionReportForm.HtmlToPdfSurveyQuestionOptionDistributionReportFormSectionQuestion', vm.nbsSelectionQuestionOptions);
            vm.reportFilterData.setDataByObjectList('Grouping', 'Grouping', [vm.currentForm.GroupingId], vm.groupings, 'value', ['name']);
            vm.reportFilterData.setDataByBoolean('IsImportant', 'HtmlToPdfSurveyQuestionOptionDistributionReportForm.HtmlToPdfSurveyQuestionOptionDistributionReportFormQuestionOptionActive', vm.currentForm.IsImportant);
            vm.reportFilterData.setDataByObjectList('Sorting', 'Sorting', [vm.currentForm.SortFieldId], vm.sortFields, 'value', ['name']);
            vm.reportFilterData.setDataByObjectList('SortingTypeId', 'SortingType', [vm.currentForm.SortTypeId], vm.sortTypes, 'value', ['name']);
        }

        function createColumnChart(points) {
            var maxPoint = Lazy(points).pluck('y').max();

            return {
                //Initializing Primary X Axis	
                primaryXAxis: {
                    title: {
                        text: vm.bindObject.filters.GroupingName
                    },
                    valueType: 'category'
                },

                //Initializing Primary Y Axis	
                primaryYAxis: {
                    title: {
                        text: vm.translate('Number')
                    },
                    range: { min: 0, max: maxPoint }
                },

                //Initializing Common Properties for all the series
                commonSeriesOptions:
                {
                    type: 'column',
                    enableAnimation: false,
                    marker: { dataLabel: { visible: true, showEdgeLabels: true } }
                },

                //Initializing Series
                series:
                    [
                        {
                            points: points,
                            fill: "#0288D1"
                        }
                    ],
                load: "loadTheme",
                isResponsive: true,
                title: { text: '' },
                legend: { visible: false }
            };
        }

        function setLocalDropDowns() {
            var defer = $q.defer();

            vm.sortFields = [];
            vm.sortFields.push({ value: 'questionoption', name: vm.translate('QuestionOption') });
            vm.sortFields.push({ value: 'value', name: vm.translate('Value') });

            vm.sortTypes = [];
            vm.sortTypes.push({ value: 'Ascending', name: vm.translate('Ascending') });
            vm.sortTypes.push({ value: 'Descending', name: vm.translate('Descending') });

            vm.groupings = [];
            vm.groupings.push({ value: 'week', name: vm.translate('Week') });
            vm.groupings.push({ value: 'month', name: vm.translate('Month') });
            vm.groupings.push({ value: 'year', name: vm.translate('Year') });

            defer.resolve(true);
        }

        function getSurveySectionsPostMethod(surveysId) {
            var filter = { surveyFilter: { SurveysId: surveysId }, vhrEventFilter: { ModuleTargetId: moduleTargetId } };
            filter.surveySectionFilter = { SurveySectionOrders: vm.bindObject.filters.SurveySectionOrders };
            var defer = $q.defer();

            audService.getSurveySectionsPostMethod(filter)
                .then(function (data) {
                    sections = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getSurveyQuestionsPostMethod(surveysId) {
            var filter = { surveyFilter: { SurveysId: surveysId }, vhrEventFilter: { ModuleTargetId: moduleTargetId } };
            filter.surveyQuestionFilter = { SurveyQuestionOrders: vm.bindObject.filters.SurveyQuestionOrders };
            filter.surveySectionFilter = { SurveySectionOrders: vm.bindObject.filters.SurveySectionOrders };
            var defer = $q.defer();

            audService.getSurveyQuestionsPostMethod(filter)
                .then(function (data) {
                    questions = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getSurveyQuestionOptionsPostMethod(surveysId) {
            var filter = { surveyFilter: { SurveysId: surveysId }, vhrEventFilter: { ModuleTargetId: moduleTargetId } };

            filter.surveySectionFilter = { SurveySectionOrders: vm.bindObject.filters.SurveySectionOrders };
            filter.surveyQuestionFilter = { SurveyQuestionOrders: vm.bindObject.filters.SurveyQuestionOrders };

            if (vm.currentForm.IsImportant !== null && vm.currentForm.IsImportant !== undefined) {
                filter.surveyQuestionOptionFilter = { IsImportant: vm.currentForm.IsImportant };
            }
            var defer = $q.defer();

            audService.getSurveyQuestionOptionsPostMethod(filter)
                .then(function (data) {
                    questionOptions = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }
    };

    htmlToPdfSurveyQuestionOptionDistributionReportForm.$inject = injectParams;
    htmlToPdfSurveyQuestionOptionDistributionReportForm.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('htmlToPdfSurveyQuestionOptionDistributionReportForm', htmlToPdfSurveyQuestionOptionDistributionReportForm);
});
