﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'vhreventService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function vhrEventTypesList($q, $scope, $timeout, $injector, vhreventService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, vhreventService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return vhrEventTypeSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, vhreventService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var vhrEventTypeSelected = null;
        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);

        vm.moduleTargetObjForBind = Nembus.Common.Library.parser.objFromList(contextService.virtualContext.ModuleTargets, 'ModuleTargetId', 'ModuleTargetName');

        vm.currentVhrEventTypes = [];

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('vhreventtype', null);
        nbsGridDatacontext.viewCommonBar();
        nbsGridDatacontext.setGridIsRenderedInlineBlock();
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getVhrEventTypes(forceReload, appStoreBw));

            $q.all(funcsPromises)
                .then(function (results) {
                    Lazy(results[0])
                        .each((item) => {
                            item.ModuleTargetName = vm.moduleTargetObjForBind[item.ModuleTargetId];
                        });

                    vm.currentVhrEventTypes = results[0];

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.currentVhrEventTypes
                    }
                });
        }

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.vhreventtypedetail-" + vm.viewMode, {
                'vhrEventTypeId': args.data.VhrEventTypeId,
                'appStoreCode': appStoreCode
            });
        };

        vm.newAction = function () {
            navigationService.navigateTo("app.nav.vhreventtypedetail-" + vm.viewMode, {
                'vhrEventTypeId': null,
                'appStoreCode': appStoreCode
            });
        };

        vm.refreshAction = function () {
            loadData(true, appStoreBw);
        };

        vm.deleteAction = function () {
            if (!vhrEventTypeSelected) {
                return;
            }

            vhreventService.deleteEntity(vhrEventTypeSelected);
            vm.submitAction()
                .then(function (saveResult) {
                    vm.refreshAction();
                });
        };

        vm.gridSelection = function (args) {
            vhrEventTypeSelected = args.data;

            $timeout(() => {
                if (vhrEventTypeSelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, !vhrEventTypeSelected.IsSystem);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                }

                ribbon.refreshStatusButtons();
            });
        };

        function getVhrEventTypes(forceReload, appStoreBw) {
            var filter = { AppStoreBw: appStoreBw };
            var defer = $q.defer();

            vhreventService.getVhrEventTypes(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    }

    vhrEventTypesList.$inject = injectParams;
    vhrEventTypesList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('vhrEventTypesList', vhrEventTypesList);
});