﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$injector', '$timeout', 'notificationService', 'navigationService', 'contextService', 'fileTransferService'];
    function notificationsList($q, $injector, $timeout, notificationService, navigationService, contextService, fileTransferService) {
        var vm = this;
        nbsVm.call(vm, notificationService, navigationService, contextService, $q, $injector, $timeout);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var notificationChannelKindEnumParsed = Nembus.Common.Library.util.parseEnum(notificationService.dataContext.enumTypes.NotificationChannelKind, 'Notification', vm.translate);
        var notificationChannelKindEnumObj = notificationChannelKindEnumParsed.obj.normal.name_value;

        ribbon.setRibbonStatusButton(ribbon.Buttons.New, false);
        ribbon.refreshStatusButtons();

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('notification', 'notifications');
        nbsGridDatacontext.viewCustomBar();
        nbsGridDatacontext.setDatasource([], false); // fake datasource, only to create grid
        vm.nbsGridDatacontext = nbsGridDatacontext;

        // hook from gridbar
        var ejDataManager = null;
        var currentGridFilter = null;
        vm.notificationBarOnChanges = function (result) {
            currentGridFilter = result.notificationFilter;
            currentGridFilter.NotificationChannelKind = notificationChannelKindEnumObj.ChannelEmail;

            if (ejDataManager === null) {
                var supportData = { isMyNotifications: false, translate: vm.translate, contextService: contextService };
                ejDataManager = notificationService.getNotificationDataManager(supportData, currentGridFilter, ejDataManager);
                nbsGridDatacontext.setDatasource(ejDataManager, true);
                refreshGridDatasource(currentGridFilter);

            } else {
                refreshGridDatasource(currentGridFilter)
            }
        };

        vm.rowTemplateOnClick = function (element) {
            if (element) {
                var notificationId = Number(element.attributes['notificationId'].value);
                if (notificationId) {
                    notificationService.getNotification({ NotificationId: notificationId }, true)
                        .then(function (data) {
                            var currentNotification = data.results[0];

                            fileTransferService.downloadRemoteFileByPath({
                                asAttach: true,
                                filePath: currentNotification.NotificationFileName,
                                displayFileName: getFileNameByPath(currentNotification.NotificationFileName)
                            }).then(function (res) { });
                        });
                }
            }
        };

        vm.createGridEvent = function (result) {
            vm.notificationBarDataContext = {
                commonBarDatasource: result.barDatasource,
                defaultKeys: {
                    columnsKey: null, sortingKey: null
                }
            };
        };

        vm.gridUiOnChanges = function (result) {
            vm.refreshGridUiComponent = {
                refreshKey: new Date().getTime(),
                uiData: result
            }
        }

        vm.refreshAction = function () {
            $timeout(() => {
                vm.forceGridRefresh = new Date().getTime();
            });
        };

        function refreshGridDatasource(currentGridFilter) {
            $timeout(() => {
                vm.forceDatamanagerFilter = {
                    refreshKey: new Date().getTime(),
                    filter: currentGridFilter
                }
            });
        }

        function getFileNameByPath(path) {
            if (!path) {
                return '';
            }

            var lastIndexChar = path.lastIndexOf('\\') + 1;
            if (path.length < lastIndexChar) {
                return '';
            }

            var filename = path.substring(lastIndexChar, path.length);
            return filename;
        };
    }

    notificationsList.$inject = injectParams;
    notificationsList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('notificationsList', notificationsList);
});