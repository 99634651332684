﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'productionService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var bankHolidayDetail = function ($q, $scope, $timeout, $injector, productionService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, productionService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, productionService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var currentBankHolidayId = navigationService.stateParams().bankHolidayId;
        vm.holidaysAreVisible = false;

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('holiday', null);
        nbsGridDatacontext.allowPaging = false;
        nbsGridDatacontext.allowSearching = false;
        nbsGridDatacontext.allowGrouping = false;
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        ribbon.setRibbonStatusButton(ribbon.Buttons.Refresh, true);
        ribbon.refreshStatusButtons();

        vm.currentBankHoliday = [];
        vm.currentHolidays = [];

        var currentAction = vm.actions.none;

        if (currentBankHolidayId) {
            vm.setIsViewing();

            loadData(true);
            vm.holidaysAreVisible = true;
        } else {
            vm.setIsAdding();

            vm.currentBankHoliday = productionService.dataContext.bankHolidays.add({
                VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId
            });
        }

        vm.currentHoliday;

        vm.gridSelection = function (args) {
            vm.currentHoliday = args.data;
        };

        vm.rowDblClick = function (args) {
            currentAction = vm.actions.update;
            vm.openModal();
        };

        vm.refreshAction = function () {
            loadData(true);
        };

        vm.newAction = function () {
            currentAction = vm.actions.add;

            vm.currentHoliday = productionService.dataContext.holidays.add({
                HolidayName: null,
                HolidayDate: null,
                BankHolidayId: currentBankHolidayId,
                VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId
            });

            vm.openModal();
        };

        vm.openModal = function () {
            vm.modalInstance = navigationService.modal().open(vm.modalOptions({
                windowClass: vm.NBS_MODAL_CLASS_FORM_MD,
                templateUrl: 'holidayTemplate',
                scope: $scope
            }));
            vm.modalInstance.result.then(function () {
                //ok
                setCurrentHolidays();
            }, function () {
                //dismiss
                productionService.rejectChanges();
            });
        };

        vm.modalOk = function () {
            vm.currentHoliday.entityAspect.validateEntity();

            if (!vm.currentHoliday.entityAspect.hasValidationErrors) {
                vm.modalInstance.close();
            }
        };

        vm.modalCancel = function () {
            vm.modalInstance.dismiss();
        };

        // get reference of modifyAction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parentm = vm.modifyAction;
        vm.modifyAction = function () {
            parentm().then(
                //enable disable button new & delete because is custom behavior
                //generally saveResult eneble or disable only save button 
                function (saveResult) {
                    currentBankHolidayId = vm.currentBankHoliday.BankHolidayId;

                    vm.holidaysAreVisible = buttonsAreVisible();

                    ribbon.setRibbonStatusButton(ribbon.Buttons.New,  vm.holidaysAreVisible);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, vm.holidaysAreVisible);
                    ribbon.refreshStatusButtons();
                }
            );
        };

        // get reference of submitaction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parents = vm.submitAction;
        vm.submitAction = function () {
            parents().then(
                function (saveResult) {
                    currentBankHolidayId = vm.currentBankHoliday.BankHolidayId;

                    vm.holidaysAreVisible = buttonsAreVisible();

                    ribbon.setRibbonStatusButton(ribbon.Buttons.New,  vm.holidaysAreVisible);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, vm.holidaysAreVisible);
                    ribbon.refreshStatusButtons();
                }
            );
        };

        vm.deleteAction = function () {
            if (!vm.currentHoliday) {
                return;
            }            

            productionService.deleteEntity(vm.currentHoliday);
            
            vm.forceDatasourceLocal = {
                refreshkey: new Date().getTime(),
                dataSource: Lazy(vm.currentHolidays).reject({ HolidayId: vm.currentHoliday.HolidayId }).toArray()
            }
        };

        function buttonsAreVisible() {
            return vm.currentBankHoliday.BankHolidayId > 0;
        }

        function setCurrentHolidays() {
            if (currentAction === vm.actions.add) {
                vm.currentHolidays.push(vm.currentHoliday);
            }

            vm.forceDatasourceLocal = {
                refreshkey: new Date().getTime(),
                dataSource: vm.currentHolidays
            }
        }

        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getHolidays(forceReload));
            funcsPromises.push(getBankHoliday(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.currentHolidays = results[0];
                    vm.currentBankHoliday = results[1];
                    
                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.currentHolidays
                    }
                });
        }

        function getBankHoliday(forceReload) {
            var filter = { BankHolidayId: currentBankHolidayId };
            var defer = $q.defer();

            productionService.getBankHoliday(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results[0]);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getHolidays(forceReload) {
            var filter = { BankHolidayId: currentBankHolidayId };
            var defer = $q.defer();

            productionService.getHolidays(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    bankHolidayDetail.$inject = injectParams;
    bankHolidayDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('bankHolidayDetail', bankHolidayDetail);
});