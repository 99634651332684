﻿'use strict';
//modal params: 
//cronstring: string 
//modal result
//CronObj   ///  cronstring
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'navigationService', 'contextService', 'audService', 'vhreventService'];
    function surveyChangeLifeStatusDetail($q, $scope, $timeout, $injector, navigationService, contextService, audService, vhreventService) {
        var vm = this;
        nbsVm.call(vm, null, navigationService, contextService, $q, $injector, $timeout);
        
        var pParams = navigationService.stateParamsModal().parentParams;
        var surveyLifeStatusFullEnumParsed = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyLifeStatus, 'SurveyDto', vm.translate);
        var surveyLifeStatusFullObj = surveyLifeStatusFullEnumParsed.obj.translated.value_name;
        var surveyLifeStatusEnumParsed = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyLifeStatus_OnlyExtended, 'SurveyDto', vm.translate);
        var surveyLifeStatus = surveyLifeStatusEnumParsed.list.translated;
        var moduleTargetId = pParams.moduleTargetId;          // history mode
        var vhrEventsId = pParams.vhrEventsId;
        var viewKind = pParams.viewKind;
        var lifeStatusesEnabled = [];
        var vhrEventEntityHistoryTypeEnum = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventEntityHistoryType, null, null).obj.normal.name_value;

        vm.isModifyMode = viewKind === 'modify';
        vm.isInfoMode = viewKind === 'info';
        vm.operationPermitted = false;

        loadData(true);
        function loadData(forceReload) {
            var funcsPromises = [];

            if (vm.isModifyMode) {
                // MOFIDY
                funcsPromises.push(getChangeTargetVhrEventsLifeStatus(vhrEventsId, moduleTargetId));
            }
            if (vm.isInfoMode) {
                // INFO
                var vhrEventId = Lazy(vhrEventsId).first();

                var nbsGridDatacontext = new NbsAudGrid(vm.translate);
                nbsGridDatacontext.configureGrid('vhreventhistory', 'vhreventlifestatus');
                nbsGridDatacontext.setDatasource([], false);
                nbsGridDatacontext.setModalMode();
                nbsGridDatacontext.allowPaging = false;
                nbsGridDatacontext.allowSearching = false;
                vm.nbsGridDatacontext = nbsGridDatacontext;

                funcsPromises.push(getVhrEventEntityHistories(forceReload, vhrEventId, vhrEventEntityHistoryTypeEnum.VhrEventLifeStatus));
            }
            
            $q.all(funcsPromises)
                .then(function (results) {
                    if (vm.isModifyMode) {
                        // MOFIDY
                        lifeStatusesEnabled = results[0];
                        vm.surveyLifeStatuses = Lazy(surveyLifeStatus).filter(isLifeStatusVisible).toArray();

                        vm.operationPermitted = Lazy(vm.surveyLifeStatuses).some();
                        if (!vm.operationPermitted) {
                            vm.operationNotPermittedMessage = vm.translate('OperationNotPermitted');
                        }
                    }
                    if (vm.isInfoMode) {
                        var histories = results[0];

                        // INFO
                        Lazy(histories)
                            .each((history) => {
                                history.VhrEventLifeStatusName = surveyLifeStatusFullObj[history.VhrEventLifeStatusId];
                            });

                        vm.forceDatasourceLocal = {
                            refreshkey: new Date().getTime(),
                            dataSource: histories
                        }
                    }
                });
        }

        vm.modalOk = function () {
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalOk);
            var hasErrors = vm.isModifyMode && !vm.modalForm.SurveyLifeStatusId;

            if (!hasErrors) {
                navigationService.closeCurrentModal(modalResult);
            } else {
                vm.alertConfig.isModal = true;
                vm.alertConfig.isReadonly = true;
                vm.removeAlertFromList(true);
                vm.setErrorMsg({ isMessageVisible: true, message: vm.currentCron.errorMessage() });
            }
        };

        vm.modalCancel = function () {
            navigationService.closeCurrentModal();
        };

        function isLifeStatusVisible(state) {
            return Lazy(lifeStatusesEnabled).contains(Number(state.value));
        }

        function getChangeTargetVhrEventsLifeStatus(vhrEventsId, moduleTargetId) {
            var filter = { VhrEventsId: vhrEventsId, ModuleTargetId: moduleTargetId };
            var defer = $q.defer();

            vhreventService.getChangeTargetVhrEventsLifeStatus(filter)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrEventEntityHistories(forceReload, vhrEventId, vhrEventEntityHistoryTypeId) {
            var defer = $q.defer();

            vhreventService.getVhrEventEntityHistories({ VhrEventId: vhrEventId, VhrEventEntityHistoryTypeId: vhrEventEntityHistoryTypeId }, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getModalResult(dialogResult) {
            var modalResult = new navigationService.modalResult();
            modalResult.data = {
                SurveyLifeStatusId: vm.isModifyMode ? vm.modalForm.SurveyLifeStatusId : null,
                SurveyLifeStatusChangeNote: vm.isModifyMode ? vm.modalForm.SurveyLifeStatusChangeNote : null,
                ModuleTargetId: moduleTargetId,
                VhrEventsSerialized: JSON.stringify(vhrEventsId)
            };

            modalResult.sourceParams = pParams;
            modalResult.dialogResult = dialogResult;
            return modalResult;
        }
    }

    surveyChangeLifeStatusDetail.$inject = injectParams;
    surveyChangeLifeStatusDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('surveyChangeLifeStatusDetail', surveyChangeLifeStatusDetail);
});