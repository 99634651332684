﻿'use strict';
define([], function () {

    var injectParams = ['$q', '$scope', '$timeout', 'contextService', 'navigationService', 'foundationService'];
    var nbsCmsviewUsersettingMenuComponent = function ($q, $scope, $timeout, contextService, navigationService, foundationService) {
        var vm = this;
        var pDataContext = null;
        var pSettingList = [];
        var cmsViewUserSettingTypeEnum = null;

        vm.datacontextIsReady = false;
        vm.$onChanges = function (changes) {
            if (changes.nbsDataContext && changes.nbsDataContext.currentValue) {
                vm.datacontextIsReady = true;
                pDataContext = changes.nbsDataContext.currentValue;
                cmsViewUserSettingTypeEnum = Nembus.Common.Library.util.parseEnum(foundationService.dataContext.enumTypes.CmsViewUserSettingType, null, null).obj.normal.name_value;

                getCmsViewUserSettings(true)
                    .then((settingList) => {
                        pSettingList = settingList;

                        setMenuData(pSettingList);
                    });

            }
        };

        vm.onSelectionEvent = function (options) {
            // this menu is single selection
            var item = Lazy(options).first();

            switch (item.optionValue) {
                case 'nbs-manage-item':
                    manageAction();
                    break;
                case 'nbs-saveas-item':
                    saveAsAction();
                    break;
                case 'nbs-reset-item':
                    resetAction();
                    break;
                default:
                    var setting = Lazy(pSettingList).findWhere({ CmsViewUserSettingId: item.optionValue });
                    sendSelectedData(setting);
            }
        };

        function resetAction() {
            // set selected an item that is invisible and not contains filters saved
            vm.settingMenuListSelected = [getDefaultItem()];
            sendSelectedData(null);
        }

        function manageAction() {
            var deletedSettingList = [];

            vm.cmsViewUserSettingManageModal = Lazy(pSettingList)
                .sortBy((setting) => { return setting.CmsViewUserSettingName.toLowerCase() }, false)
                .toArray();

            vm.manageInstance = navigationService.modal().open({ templateUrl: 'manageModal', size: 'md', scope: $scope });
            vm.manageInstance.ok = function () {
                Lazy(deletedSettingList)
                    .each((setting) => {
                        setting.entityAspect.rejectChanges();
                    });

                foundationService.saveChanges()
                    .then(res => {
                        resetAction();
                        setMenuData(vm.cmsViewUserSettingManageModal);
                        vm.manageInstance.close();
                    });
            };
            vm.manageInstance.cancel = function () {
                Lazy(deletedSettingList)
                    .each((setting) => {
                        vm.cmsViewUserSettingManageModal.push(setting);
                    })

                deletedSettingList = [];

                Lazy(vm.cmsViewUserSettingManageModal)
                    .each((setting) => {
                        setting.entityAspect.rejectChanges();
                    })

                vm.manageInstance.dismiss();
            };
            vm.manageInstance.delete = function (setting) {
                // add to deleted list
                deletedSettingList.push(setting);

                // remove entity UI modal list
                vm.cmsViewUserSettingManageModal = Lazy(vm.cmsViewUserSettingManageModal)
                    .reject({ CmsViewUserSettingId: setting.CmsViewUserSettingId })
                    .toArray();
            };
            vm.manageInstance.result.then(function () {
                // onClose
            }, function () {
                // onDismiss
            });
        }

        function getCmsViewUserSettings(forceReload) {
            var filter = {
                CmsViewUserSettingCode: pDataContext.settingCode,
                AppUserId: contextService.virtualContext.appuserid,
                //CmsViewUserSettingTypeId: cmsViewUserSettingTypeEnum.ReportFilter
            };
            var defer = $q.defer();

            foundationService.getCmsViewUserSettings(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function setMenuData(cmsViewUserSettings, selectedId) {
            vm.settingMenuList = [];

            var menuOptions = [];

            // ### SaveAs option ###
            menuOptions.push(new NbsUiOption({ optionValue: "nbs-saveas-item", optionDisplayName: vm.uiContext.translate('SaveAs'), optionStyle: "action", optionOrder: -100 }));
            // ### Manage option ###
            menuOptions.push(new NbsUiOption({ optionValue: "nbs-manage-item", optionDisplayName: vm.uiContext.translate('Manage'), optionStyle: "action", optionOrder: -90 }));
            // ### Default option ### (INVISIBLE)
            var defaultoption = getDefaultItem();
            menuOptions.push(defaultoption);
            // ### Reset option ###
            menuOptions.push(new NbsUiOption({ optionValue: "nbs-reset-item", optionDisplayName: vm.uiContext.translate('CleanFilters'), optionStyle: "action", optionOrder: -70 }));
            // ### My filters ###
            menuOptions.push(new NbsUiOption({ optionDisplayName: vm.uiContext.translate('MyFilters'), optionStyle: "section", optionOrder: -60 }));

            if (Lazy(cmsViewUserSettings).some()) {
                Lazy(cmsViewUserSettings)
                    .sortBy((setting) => { return setting.CmsViewUserSettingName.toLowerCase() }, false)
                    .each((setting) => {
                        var item = new NbsUiOption({ optionValue: setting.CmsViewUserSettingId, optionDisplayName: setting.CmsViewUserSettingName, optionStyle: "subsection" });
                        menuOptions.push(item);

                        if (selectedId && selectedId === setting.CmsViewUserSettingId) {
                            vm.settingMenuListSelected = [item];
                        }
                    })
            }

            // list item
            vm.settingMenuList = menuOptions;

            // selected Item
            if (!selectedId) {
                vm.settingMenuListSelected = [defaultoption];
            }
        }

        function getDefaultItem() {
            return new NbsUiOption({ optionVisible: false, optionValue: "nbs-default-item", optionDisplayName: vm.uiContext.translate('Filters'), optionOrder: -80 });
        }

        function saveAsAction() {
            // validation error message is hidden
            vm.cmsViewUserSettingSaveAsModal = foundationService.dataContext.cmsViewUserSettings.add();
            vm.cmsViewUserSettingSaveAsModal.AppUserId = contextService.virtualContext.appuserid;
            vm.cmsViewUserSettingSaveAsModal.CmsViewUserSettingTypeId = cmsViewUserSettingTypeEnum.ReportFilter;
            vm.cmsViewUserSettingSaveAsModal.CmsViewUserSettingCode = pDataContext.settingCode;
            vm.cmsViewUserSettingSaveAsModal.CmsViewUserSettingData = JSON.stringify(pDataContext.settingDataCallback());

            vm.saveAsInstance = navigationService.modal().open({ templateUrl: 'addTemplate', scope: $scope });
            vm.saveAsInstance.ok = function () {
                vm.cmsViewUserSettingSaveAsModal.entityAspect.validateEntity();
                if (vm.cmsViewUserSettingSaveAsModal.entityAspect.hasValidationErrors) {
                    return;
                }

                foundationService.saveChanges()
                    .then(res => {
                        pSettingList.push(vm.cmsViewUserSettingSaveAsModal);
                        setMenuData(pSettingList, vm.cmsViewUserSettingSaveAsModal.CmsViewUserSettingId);
                        vm.saveAsInstance.close();
                    });
            };
            vm.saveAsInstance.cancel = function () {
                // reject changes
                vm.cmsViewUserSettingSaveAsModal.entityAspect.rejectChanges();
                vm.saveAsInstance.dismiss();
            };
            vm.saveAsInstance.result.then(function () {
                // ok
            }, function () {
                // cancel
            });
        }

        function sendSelectedData(setting) {
            $timeout(() => {
                vm.nbsSelectionEvent({ args: { data: setting } });
            });
        }
    };

    nbsCmsviewUsersettingMenuComponent.$inject = injectParams;
    angular.module('nbs.rds.module').component('nbsCmsviewUsersettingMenu', {
        templateUrl: '/rds/common/components/nbsCmsviewUsersettingMenuComponent/_?c=' + window.codeCache(),
        controller: nbsCmsviewUsersettingMenuComponent,
        controllerAs: 'vm',
        bindings: {
            nbsDataContext: '<',
            uiContext: "<",

            nbsSelectionEvent: '&'
        }
    });
});

