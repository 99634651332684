﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'actService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var actActionGroupDetail = function ($q, $scope, $timeout, $injector, actService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, actService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, actService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var currentActActionGroupId = navigationService.stateParams().actActionGroupId;

        if (currentActActionGroupId) {
            vm.setIsViewing();

            loadData(true);
        }
        else {
            vm.setIsAdding();
            vm.currentActActionGroup = actService.dataContext.actActionGroups.add({
                VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId
            });
        }

        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getActActionGroup(forceReload, currentActActionGroupId));

            $q.all(funcsPromises)
                .then(function () {
                    getActActionGroup(forceReload, vm.currentActActionGroup.ActActionGroupCode);
                });
        }

        function getActActionGroup(forceReload, actActionGroupId) {
            var filter = { ActActionGroupId: actActionGroupId };
            var defer = $q.defer();

            actService.getActActionGroup(filter, forceReload)
                .then(function (data) {
                    vm.currentActActionGroup = data.results[0];
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    actActionGroupDetail.$inject = injectParams;
    actActionGroupDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('actActionGroupDetail', actActionGroupDetail);
});