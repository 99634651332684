﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'hrbucketService', 'navigationService', 'contextService', 'formSummaryService'];
    var avatarFtc = function ($q, $scope, $timeout, $injector, foundationService, hrbucketService, navigationService, contextService, formSummaryService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);

        var referral = 'avatar';
        var appStoreCodeFtc = navigationService.stateParams().appStoreCodeFtc;
        var virtualHumanResourceId = navigationService.stateParams().virtualHumanResourceId;
        var cmsView = 'avatarftc-' + vm.viewMode;
        var bucketClassificationEnum = Nembus.Common.Library.util.parseEnum(hrbucketService.dataContext.enumTypes.BucketClassification, null, vm.translate).obj.normal.name_value;
        var resourceTypeEnum = Nembus.Common.Library.util.parseEnum(hrbucketService.dataContext.enumTypes.ResourceType, null, vm.translate).obj.normal.name_value;
        var hrContactsId = null;
        var personJobDataId = null;
        var hrMetadataId = null;

        var state = {
            generality: 'generality', addresses: 'addresses', work: 'work', productionUnits: 'productionUnits', hrGroups: 'hrGroups',
            legalEntities: 'legalEntities', costCenters: 'costCenters', businessUnits: 'businessUnits', sectors: 'sectors', categories: 'categories',
            medicalProtocolManage: 'medicalProtocolManage', absences: 'absences', medicalExaminations: 'medicalExaminations', examinations: 'examination', training: 'training',
            modules: 'modules'
        };

        var fastTab = new NbsAccordion();
        fastTab.AddFastTab(state.generality, "Generality");
        fastTab.AddFastTab(state.addresses, "Addresses");
        fastTab.AddFastTab(state.work, "WorkingPosition");
        fastTab.AddFastTab(state.productionUnits, "ProductiveUnits");
        fastTab.AddLink(state.legalEntities, state.productionUnits, "LegalEntities");
        fastTab.AddLink(state.costCenters, state.productionUnits, "CostCenters");
        fastTab.AddLink(state.businessUnits, state.productionUnits, "BusinessUnits");
        fastTab.AddLink(state.sectors, state.productionUnits, "Sectors");
        fastTab.AddLink(state.hrGroups, state.productionUnits, "HomogeneousGroups");
        fastTab.AddFastTab(state.categories, "Categories");
        fastTab.AddFastTab(state.modules, "Modules");
        vm.fastTabDatacontext = fastTab;

        vm.ftcOnClick = function (event) {
            navigateTo(event.id);
        }

        // raised from detail view
        vm.ftcOnAddEntity = function (entity) {
            $timeout(() => {
                virtualHumanResourceId = entity.VirtualHumanResourceId;
                hrContactsId = entity.HrContactsId;
                personJobDataId = entity.PersonJobDataId;
                hrMetadataId = entity.HrMetadataId;

                vm.fastTabsAreDisabled = false;
            });
        }

        if (virtualHumanResourceId) {
            foundationService.getAvatar({ VirtualHumanResourceId: virtualHumanResourceId }, false)
                .then(function (data) {
                    var avatar = data.results[0];
                    hrContactsId = avatar.HrContactsId;
                    personJobDataId = avatar.PersonJobDataId;
                    hrMetadataId = avatar.HrMetadataId;

                    $timeout(() => {
                        vm.fastTabsAreDisabled = false;
                    });

                    // configure form summary items
                    formSummaryService.clearDatasource();
                    formSummaryService.push({ key: vm.translateCmsView(cmsView, 'RegistrationNumber', 'PersonGenerality'), value: avatar.PersonGenerality.RegistrationNumber });
                    formSummaryService.push({ key: vm.translateCmsView(cmsView, 'LastName', 'PersonGenerality'), value: avatar.PersonGenerality.LastName });
                    formSummaryService.push({ key: vm.translateCmsView(cmsView, 'FirstName', 'PersonGenerality'), value: avatar.PersonGenerality.FirstName });
                    formSummaryService.push({ key: vm.translateCmsView(cmsView, 'SocialSecurityNumber', 'PersonGenerality'), value: avatar.PersonGenerality.SocialSecurityNumber });

                    goToDefaultFastTab();
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });
        } else {
            goToDefaultFastTab();

            $timeout(() => {
                vm.fastTabsAreDisabled = true;
            });
        }

        function navigateTo(accordionId) {
            if (!accordionId) {
                return;
            }

            var vmCalc = vm.viewMode === '_' ? '' : '-' + vm.viewMode;
            var navigationState = null;
            var navigationStateParams = null;
            switch (accordionId) {
                case state.generality:
                    navigationState = 'app.nav.avatarftc.avatardetail' + vmCalc;
                    navigationStateParams = { 'virtualHumanResourceId': virtualHumanResourceId, 'appStoreCode': appStoreCodeFtc };
                    break;
                case state.addresses:
                    navigationState = 'app.nav.avatarftc.hraddressdetail';
                    navigationStateParams = { 'hrContactsId': hrContactsId, 'referral': referral };
                    break;
                case state.work:
                    navigationState = 'app.nav.avatarftc.hrpersonjobdatadetail';
                    navigationStateParams = { 'personJobDataId': personJobDataId };
                    break;
                case state.hrGroups:
                    navigationState = 'app.nav.avatarftc.hrsingletobucketdetail-hrgroup';
                    navigationStateParams = { 'hrMetadataId': hrMetadataId, 'virtualHumanResourceId': virtualHumanResourceId, 'resourceTypeId': resourceTypeEnum.HrGroup, 'bucketClassificationId': bucketClassificationEnum.GenericHrGroup, 'appStoreCode': appStoreCodeFtc };
                    break;
                case state.legalEntities:
                    navigationState = 'app.nav.avatarftc.hrsingletobucketdetail-legalentity';
                    navigationStateParams = { 'hrMetadataId': hrMetadataId, 'virtualHumanResourceId': virtualHumanResourceId, 'resourceTypeId': resourceTypeEnum.LegalEntity, 'bucketClassificationId': bucketClassificationEnum.GenericLegalEntity, 'appStoreCode': appStoreCodeFtc };
                    break;
                case state.costCenters:
                    navigationState = 'app.nav.avatarftc.hrsingletobucketdetail-costcenter';
                    navigationStateParams = { 'hrMetadataId': hrMetadataId, 'virtualHumanResourceId': virtualHumanResourceId, 'resourceTypeId': resourceTypeEnum.ProductionUnit, 'bucketClassificationId': bucketClassificationEnum.CostCenter, 'appStoreCode': appStoreCodeFtc };
                    break;
                case state.businessUnits:
                    navigationState = 'app.nav.avatarftc.hrsingletobucketdetail-businessunit';
                    navigationStateParams = { 'hrMetadataId': hrMetadataId, 'virtualHumanResourceId': virtualHumanResourceId, 'resourceTypeId': resourceTypeEnum.ProductionUnit, 'bucketClassificationId': bucketClassificationEnum.BusinessUnit, 'appStoreCode': appStoreCodeFtc };
                    break;
                case state.sectors:
                    navigationState = 'app.nav.avatarftc.hrsingletobucketdetail-sector';
                    navigationStateParams = { 'hrMetadataId': hrMetadataId, 'virtualHumanResourceId': virtualHumanResourceId, 'resourceTypeId': resourceTypeEnum.ProductionUnit, 'bucketClassificationId': bucketClassificationEnum.Sector, 'appStoreCode': appStoreCodeFtc };
                    break;
                case state.categories:
                    navigationState = 'app.nav.avatarftc.vhrtocategoriesdetail';
                    navigationStateParams = { 'hrMetadataId': hrMetadataId, 'virtualHumanResourceId': virtualHumanResourceId };
                    break;
                case state.medicalProtocolManage://only if oms module is available
                    navigationState = 'app.nav.avatarftc.vhrtoactivities-oms';
                    navigationStateParams = { 'virtualHumanResourceId': virtualHumanResourceId, 'appStoreCode': appStoreCodeFtc };
                    break;
                case state.absences://only if oms module is available
                    break;
                case state.medicalExaminations://only if oms module is available
                    navigationState = 'app.nav.avatarftc.vhreventsvhrlist-omsmedicalexams';
                    navigationStateParams = { 'wzVhrEntityType': 'avatar', 'vhrEventTypeCode': 'MEDICALEXAM', 'appStoreCode': appStoreCodeFtc, 'virtualHumanResourceId': virtualHumanResourceId };
                    break;
                case state.examinations://only if oms module is available
                    navigationState = 'app.nav.avatarftc.vhreventsvhrlist-omsexaminations';
                    navigationStateParams = { 'vhrEventTypeCode': 'EXAMINATION', 'appStoreCode': appStoreCodeFtc, 'virtualHumanResourceId': virtualHumanResourceId };
                    break;
                case state.training: //only if jts module is available
                    break;
                case state.modules:
                    navigationState = 'app.nav.avatarftc.vhrtomodules-hrs';
                    navigationStateParams = { 'entityName': 'Avatar', 'VirtualHumanResourceId': virtualHumanResourceId };
                    break;
            }

            // call navigation state
            if (navigationState && navigationStateParams) {
                navigationService.navigateTo(navigationState, navigationStateParams);
            }
        };

        function goToDefaultFastTab() {
            // go to default page only if is called ftc directly
            if (navigationService.currentState().endsWith('.avatarftc')) {
                navigateTo(state.generality);
            }
        }
    };

    avatarFtc.$inject = injectParams;
    avatarFtc.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('avatarFtc', avatarFtc);
});