﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'navigationService', 'contextService', 'formSummaryService'];
    var targetFtc = function ($q, $scope, $timeout, $injector, foundationService, navigationService, contextService, formSummaryService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        
        var appStoreCodeFtc = navigationService.stateParams().appStoreCodeFtc;
        appStoreCodeFtc = appStoreCodeFtc ? appStoreCodeFtc : "";
        var cmsView = 'targetftc-_';

        var virtualHumanResourceId = navigationService.stateParams().virtualHumanResourceId;
        var hrMetadataId = null;

        vm.canViewTargetCategories = vm.havePermission(Nembus.Common.Library.auth.Permission['AUD_AUDTARGET_TARGET_VIEW_CATEGORIES'], contextService.virtualContext);
        // audit
        vm.canViewTargetAuditCompiledAll = vm.havePermission(Nembus.Common.Library.auth.Permission['AUD_AUDTARGET_TARGET_VIEW_AUDCOMPILED'], contextService.virtualContext);
        vm.canViewTargetAuditToCompileAll = vm.havePermission(Nembus.Common.Library.auth.Permission['AUD_AUDTARGET_TARGET_VIEW_AUDTOCOMPILE'], contextService.virtualContext);
        vm.canViewTargetAuditOndemandAll = vm.havePermission(Nembus.Common.Library.auth.Permission['AUD_AUDTARGET_TARGET_VIEW_AUDONDEMAND'], contextService.virtualContext);
        vm.canViewTargetAuditCompiledMy = vm.havePermission(Nembus.Common.Library.auth.Permission['AUD_AUDTARGET_TARGET_VIEW_MY_AUDCOMPILED'], contextService.virtualContext);
        vm.canViewTargetAuditToCompileMy = vm.havePermission(Nembus.Common.Library.auth.Permission['AUD_AUDTARGET_TARGET_VIEW_MY_AUDTOCOMPILE'], contextService.virtualContext);
        vm.canViewTargetAuditOndemandMy = vm.havePermission(Nembus.Common.Library.auth.Permission['AUD_AUDTARGET_TARGET_VIEW_MY_AUDONDEMAND'], contextService.virtualContext);
        // acts
        vm.canViewTargetActsAll = vm.havePermission(Nembus.Common.Library.auth.Permission['ACT_MENU_ACTION_PLAN'], contextService.virtualContext);
        vm.canViewTargetActsMy = vm.havePermission(Nembus.Common.Library.auth.Permission['ACT_MENU_ACTION_MYACTION'], contextService.virtualContext);

        var state = {
            generality: 'generality', categories: 'categories', auditsCompiled: 'auditsCompiled', auditsCompiledAll: 'auditsCompiledAll',
            auditsCompiledMy: 'auditsCompiledMy', auditsToCompile: 'auditsToCompile', auditsToCompileAll: 'auditsToCompileAll', auditsToCompileMy: 'auditsToCompileMy',
            auditsOndemand: 'auditsOndemand', auditsOndemandAll: 'auditsOndemandAll', auditsOndemandMy: 'auditsOndemandMy', acts: 'acts',
            actsAll: 'actsAll', actsMy: 'actsMy', actsToExecute: 'actsToExecute', actsExecuted: 'actsExecuted'
        };

        var fastTab = new NbsAccordion();
        fastTab.AddFastTab(state.generality, "Generality");
        fastTab.AddFastTab(state.categories, "Categories", vm.canViewTargetCategories);
        fastTab.AddFastTab(state.auditsCompiled, "AuditsCompiled");
        fastTab.AddLink(state.auditsCompiledAll, state.auditsCompiled, "AuditsCompiledAll", vm.canViewTargetAuditCompiledAll);
        fastTab.AddLink(state.auditsCompiledMy, state.auditsCompiled, "AuditsCompiledMy", vm.canViewTargetAuditCompiledMy);
        fastTab.AddFastTab(state.auditsToCompile, "AuditsToCompile");
        fastTab.AddLink(state.auditsToCompileAll, state.auditsToCompile, "AuditsToCompileAll", vm.canViewTargetAuditToCompileAll);
        fastTab.AddLink(state.auditsToCompileMy, state.auditsToCompile, "AuditsToCompileMy", vm.canViewTargetAuditToCompileMy);
        fastTab.AddFastTab(state.auditsOndemand, "AuditsOndemand");
        fastTab.AddLink(state.auditsOndemandAll, state.auditsOndemand, "AuditsOndemandAll", vm.canViewTargetAuditOndemandAll);
        fastTab.AddLink(state.auditsOndemandMy, state.auditsOndemand, "AuditsOndemandMy", vm.canViewTargetAuditOndemandMy);
        fastTab.AddFastTab(state.acts, "Actions");
        fastTab.AddLink(state.actsAll, state.acts, "ActionsAll", vm.canViewTargetActsAll);
        fastTab.AddLink(state.actsMy, state.acts, "ActionsMy", vm.canViewTargetActsMy);
        fastTab.AddLink(state.actsToExecute, state.acts, "ActionsToExecute", vm.canViewTargetActsAll);
        fastTab.AddLink(state.actsExecuted, state.acts, "ActionsExecuted", vm.canViewTargetActsAll);
        vm.fastTabDatacontext = fastTab;

        vm.ftcOnClick = function (event) {
            navigateTo(event.id);
        }

        // raised from detail view
        vm.ftcOnAddEntity = function (entity) {
            $timeout(() => {
                virtualHumanResourceId = entity.VirtualHumanResourceId;
                hrMetadataId = entity.HrMetadataId;

                vm.fastTabsAreDisabled = false;                
            });
        }

        if (virtualHumanResourceId) {
            foundationService.getTargetDto({ VirtualHumanResourceId: virtualHumanResourceId }, false)
                .then(function (data) {
                    var target = data.results[0];
                    hrMetadataId = target.HrMetadataId;

                    $timeout(() => {
                        vm.fastTabsAreDisabled = false;
                    });

                    // configure form summary items
                    formSummaryService.clearDatasource();
                    formSummaryService.push({ key: vm.translateCmsView(cmsView, 'TargetCode', 'TargetDto'), value: target.TargetCode });
                    formSummaryService.push({ key: vm.translateCmsView(cmsView, 'TargetName', 'TargetFtc'), value: target.TargetName });

                    goToDefaultFastTab();
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });
        } else {
            goToDefaultFastTab();

            $timeout(() => {
                vm.fastTabsAreDisabled = true;
            });
        }

        // navigation from fast tab
        function navigateTo (accordionId) {
            if (!accordionId) {
                return;
            }

            var navigationState = null;
            var navigationStateParams = null;
            switch (accordionId) {
                case state.generality:
                    navigationState = 'app.nav.targetftc.targetdetail';
                    navigationStateParams = { 'virtualHumanResourceId': virtualHumanResourceId, 'appStoreCode': appStoreCodeFtc };
                    break
                case state.categories:
                    navigationState = 'app.nav.targetftc.vhrtocategoriesdetail-target';
                    navigationStateParams = { 'hrMetadataId': hrMetadataId, 'virtualHumanResourceId': virtualHumanResourceId, 'appStoreCode': appStoreCodeFtc };
                    break;
                case state.auditsCompiledAll:
                    navigationState = 'app.nav.targetftc.surveysvhrlist-compiled';
                    navigationStateParams = { 'virtualHumanResourceId': virtualHumanResourceId, 'appStoreCode': appStoreCodeFtc, 'vhrEventLifeStatusKey': 'compiled' };
                    break;
                case state.auditsCompiledMy:
                    navigationState = 'app.nav.targetftc.surveysvhrlist-mycompiled';
                    navigationStateParams = { 'virtualHumanResourceId': virtualHumanResourceId, 'appStoreCode': appStoreCodeFtc, 'vhrEventLifeStatusKey': 'compiled' };
                    break;
                case state.auditsToCompileAll:
                    navigationState = 'app.nav.targetftc.surveysvhrlist-tocompile';
                    navigationStateParams = { 'virtualHumanResourceId': virtualHumanResourceId, 'appStoreCode': appStoreCodeFtc, 'vhrEventLifeStatusKey': 'tocompile' };
                    break;
                case state.auditsToCompileMy:
                    navigationState = 'app.nav.targetftc.surveysvhrlist-mytocompile';
                    navigationStateParams = { 'virtualHumanResourceId': virtualHumanResourceId, 'appStoreCode': appStoreCodeFtc, 'vhrEventLifeStatusKey': 'tocompile' };
                    break;
                case state.auditsOndemandAll:
                    navigationState = 'app.nav.targetftc.surveysvhrlist-ondemand';
                    navigationStateParams = { 'virtualHumanResourceId': virtualHumanResourceId, 'appStoreCode': appStoreCodeFtc, 'vhrEventLifeStatusKey': 'ondemand' };
                    break;
                case state.auditsOndemandMy:
                    navigationState = 'app.nav.targetftc.surveysvhrlist-myondemand';
                    navigationStateParams = { 'virtualHumanResourceId': virtualHumanResourceId, 'appStoreCode': appStoreCodeFtc, 'vhrEventLifeStatusKey': 'ondemand' };
                    break;
                case state.actsAll:
                    navigationState = 'app.nav.targetftc.actsvhrlist-all';
                    navigationStateParams = { 'viewKind': 'all', 'appStoreCode': appStoreCodeFtc, 'actTemplateCode': 'aud' }; 
                    break;
                case state.actsMy:
                    navigationState = 'app.nav.targetftc.actsvhrlist-myall';
                    navigationStateParams = { 'viewKind': 'myall', 'appStoreCode': appStoreCodeFtc, 'actTemplateCode': 'aud' }; 
                    break;
                case state.actsToExecute:
                    navigationState = 'app.nav.targetftc.actsvhrlist-toexecute';
                    navigationStateParams = { 'viewKind': 'toexecute', 'appStoreCode': appStoreCodeFtc, 'actTemplateCode': 'aud' };
                    break;
                case state.actsExecuted:
                    navigationState = 'app.nav.targetftc.actsvhrlist-executed';
                    navigationStateParams = { 'viewKind': 'executed', 'appStoreCode': appStoreCodeFtc, 'actTemplateCode': 'aud' };
                    break;
            }

            // call navigation state
            if (navigationState && navigationStateParams) {
                navigationService.navigateTo(navigationState, navigationStateParams);
            }
        };

        function goToDefaultFastTab() {
            // go to default page only if is called ftc directly
            if (navigationService.currentState().endsWith('.targetftc')) {
                navigateTo(state.generality);
            }
        }        
    };

    targetFtc.$inject = injectParams;
    targetFtc.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('targetFtc', targetFtc);
}); 