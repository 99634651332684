﻿'use strict';

define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'vhreventService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function vhrEventsManualStepSummary($q, $scope, $timeout, $injector, foundationService, vhreventService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, foundationService);

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var pParams = navigationService.stateParamsModal().parentParams;
        pParams = pParams ? pParams : navigationService.stateParams();

        // beardcrumb information
        var beardCrumbInfo = JSON.parse(pParams.beardCrumbInfo);
        Lazy(beardCrumbInfo.items).each((item) => { item.title = vm.translate(item.title); })
        vm.beardCrumbInfo = beardCrumbInfo;

        // navigation buttons visibility
        vm.setWizardButtonVisibility(pParams);

        vm.vhrEventManualSummary = vhreventService.vhrEventManualCollector;
        // formatted data for UI
        vm.vhrEventManualSummary.StartDateForLayout = Nembus.Common.Library.converter.date.formatDate(vm.vhrEventManualSummary.RecurrenceStartDate, contextService.virtualContext.cultureInfo, true);
        vm.vhrEventManualSummary.EndDateForLayout = Nembus.Common.Library.converter.date.formatDate(vm.vhrEventManualSummary.RecurrenceEndDate, contextService.virtualContext.cultureInfo, true);
        vm.vhrEventManualSummary.StartTimeForLayout = Nembus.Common.Library.converter.timespan.jsonToString(vm.vhrEventManualSummary.EventStartTime);
        vm.vhrEventManualSummary.EndTimeForLayout = Nembus.Common.Library.converter.timespan.jsonToString(vm.vhrEventManualSummary.EventEndTime);
       
        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('virtualhumanresource', null);
        nbsGridDatacontext.setDatasource(vm.vhrEventManualSummary.VirtualHumanResources, false);
        nbsGridDatacontext.setModalMode();
        nbsGridDatacontext.allowSearching = false;
        vm.nbsGridDatacontext = nbsGridDatacontext;

        vm.modalEnd = function () {
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalEnd);
            navigationService.closeCurrentModal(modalResult);
        };

        vm.modalPrevious = function () {
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalPrevious);
            navigationService.closeCurrentModal(modalResult);
        };

        vm.modalCancel = function () {
            navigationService.confirmExitWizard()
                            .then(function (result) {
                                if (result) {
                                    var modalResult = getModalResult(navigationService.navigationActionTypes.modalCancel);
                                    navigationService.closeCurrentModal(modalResult);
                                }
                            });
        };

        if (vm.vhrEventManualSummary.IsRecurrent) {
            getVhrEventManualSchedulingVhrEventsCount(vm.vhrEventManualSummary);
        }

        //Http Post
        function getVhrEventManualSchedulingVhrEventsCount(vhrEventManualRequest) {
            vhrEventManualRequest.VirtualHumanResourcesSerialized = JSON.stringify(Lazy(vm.vhrEventManualSummary.VirtualHumanResources).pluck('VirtualHumanResourceId').toArray());
            vhrEventManualRequest.VirtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;

            vhreventService.getVhrEventManualSchedulingVhrEventsCount(vhrEventManualRequest)
                        .then(function (data) {
                            // data.results[0] contains number of vhrevents to insert with current recurrency
                            vm.alertConfig.isModal = true;
                            vm.alertConfig.isReadonly = true;
                            vm.removeAlertFromList(true);
                            var message = vm.translate('VhrEventManualVhrEventsAffectedCount') + ' : ' + data.results[0];
                            vm.setWarningMsg({ message: message });
                        })
                        .catch(function (error) {
                            vm.setErrorMsg(error);
                            vhreventService.rejectChanges();
                        });
        }

        function getModalResult(dialogResult) {
            var modalResult = new navigationService.modalResult()
            //modalResult.data = ; last step, no data to collect
            modalResult.sourceParams = pParams;
            modalResult.dialogResult = dialogResult;
            return modalResult;
        }
    };

    vhrEventsManualStepSummary.$inject = injectParams;
    vhrEventsManualStepSummary.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('vhrEventsManualStepSummary', vhrEventsManualStepSummary);
});