﻿'use strict';
define([], function () {

    var injectParams = ['$q', 'navigationService', 'foundationService'];
    var nbsVhrAccountersComponent = function ($q, navigationService, foundationService) {

        var vm = this;
        var pDataContext = null;
        var pVirtualHumanResourceId = null;
        var pJustLoaded = false;
        var operatorUsersUI = [];
        var operatorGroupsUI = [];
        var responsibleUsersUI = [];
        var responsibleGroupsUI = [];
        var supervisorUsersUI = [];
        var supervisorGroupsUI = [];
        var vhrAccounterKindEnumParsed = null;

        //used by upload
        vm.files = [];

        vm.$onChanges = function (changes) {
            if (changes.nbsDatacontext && changes.nbsDatacontext.currentValue) {
                pDataContext = changes.nbsDatacontext.currentValue;
                pVirtualHumanResourceId = pDataContext.VirtualHumanResourceId;

                vhrAccounterKindEnumParsed = Nembus.Common.Library.util.parseEnum(foundationService.dataContext.enumTypes.VhrAccounterKind, 'VhrAccounter', vm.uiContext.translate);

                if (!pJustLoaded) {
                    //Operator
                    vm.nbsAssignmentOperatorModalObj = new NbsAssigneeSelectorModal({
                        translator: vm.uiContext.translate,
                        suspendDirty: vm.uiContext.suspendDirty,
                        navigationService: navigationService,
                        contextUserForSelection: null,
                        getServerVarFn: Nembus.Common.Library.server.getServerVar
                    });
                    vm.nbsAssignmentOperatorModalObj.viewUserGroups();
                    vm.nbsAssignmentOperatorModalObj.viewUsers();

                    //Responsible
                    vm.nbsAssignmentResponsibleModalObj = new NbsAssigneeSelectorModal({
                        translator: vm.uiContext.translate,
                        suspendDirty: vm.uiContext.suspendDirty,
                        navigationService: navigationService,
                        contextUserForSelection: null,
                        getServerVarFn: Nembus.Common.Library.server.getServerVar
                    });
                    vm.nbsAssignmentResponsibleModalObj.viewUserGroups();
                    vm.nbsAssignmentResponsibleModalObj.viewUsers();

                    //Supervisor
                    vm.nbsAssignmentSupervisorModalObj = new NbsAssigneeSelectorModal({
                        translator: vm.uiContext.translate,
                        suspendDirty: vm.uiContext.suspendDirty,
                        navigationService: navigationService,
                        contextUserForSelection: null,
                        getServerVarFn: Nembus.Common.Library.server.getServerVar
                    });
                    vm.nbsAssignmentSupervisorModalObj.viewUserGroups();
                    vm.nbsAssignmentSupervisorModalObj.viewUsers();

                    loadData(true, pVirtualHumanResourceId);
                }
                
            }

            if (changes.isReadonly) {
                if (changes.isReadonly.currentValue === true) {
                    vm.isLocked = true;
                } else {
                    vm.isLocked = false;
                }
            }

            if (changes.saveAction) {
                if (!pVirtualHumanResourceId) {
                    return;
                }

                if (changes.saveAction && changes.saveAction.currentValue) {
                    prepareForSubmit();

                    foundationService.saveChanges()
                        .then(function () {
                            loadData(true, pVirtualHumanResourceId);
                        });
                }
            }
        };

        function loadData(forceReload, virtualHumanResourceId) {
            var funcsPromises = [];
            funcsPromises.push(getVhrAccounterDtos(forceReload, virtualHumanResourceId));

            $q.all(funcsPromises)
                .then(function (results) {
                    setSelectionUI(results[0]);

                    pJustLoaded = true;
                });
        }

        function prepareForSubmit() {
            // operators
            Lazy(operatorUsersUI).each((userUI) => {
                if (!Lazy(vm.nbsAssignmentOperatorModalObj.appUserMultiselectSelectedItems()).where({ id: userUI.AppUserId }).some()) {
                    removeFromContext(userUI);
                }
            });
            Lazy(operatorGroupsUI).each((groupUI) => {
                if (!Lazy(vm.nbsAssignmentOperatorModalObj.drbacUserGroupMultiselectSelectedItems()).where({ id: groupUI.DrbacUserGroupId }).some()) {
                    removeFromContext(groupUI);
                }
            });
            Lazy(vm.nbsAssignmentOperatorModalObj.appUserMultiselectSelectedItems()).each((user) => {
                if (!Lazy(operatorUsersUI).where({ AppUserId: user.id }).some()) {
                    addToContext(vhrAccounterKindEnumParsed.obj.normal.name_value.Operator, user.id, null);
                }
            });
            Lazy(vm.nbsAssignmentOperatorModalObj.drbacUserGroupMultiselectSelectedItems()).each((group) => {
                if (!Lazy(operatorGroupsUI).where({ DrbacUserGroupId: group.id }).some()) {
                    addToContext(vhrAccounterKindEnumParsed.obj.normal.name_value.Operator, null, group.id);
                }
            });

            // responsibles
            Lazy(responsibleUsersUI).each((userUI) => {
                if (!Lazy(vm.nbsAssignmentResponsibleModalObj.appUserMultiselectSelectedItems()).where({ id: userUI.AppUserId }).some()) {
                    removeFromContext(userUI);
                }
            });
            Lazy(responsibleGroupsUI).each((groupUI) => {
                if (!Lazy(vm.nbsAssignmentResponsibleModalObj.drbacUserGroupMultiselectSelectedItems()).where({ id: groupUI.DrbacUserGroupId }).some()) {
                    removeFromContext(groupUI);
                }
            });
            Lazy(vm.nbsAssignmentResponsibleModalObj.appUserMultiselectSelectedItems()).each((user) => {
                if (!Lazy(responsibleUsersUI).where({ AppUserId: user.id }).some()) {
                    addToContext(vhrAccounterKindEnumParsed.obj.normal.name_value.Responsible, user.id, null);
                }
            });
            Lazy(vm.nbsAssignmentResponsibleModalObj.drbacUserGroupMultiselectSelectedItems()).each((group) => {
                if (!Lazy(responsibleGroupsUI).where({ DrbacUserGroupId: group.id }).some()) {
                    addToContext(vhrAccounterKindEnumParsed.obj.normal.name_value.Responsible, null, group.id);
                }
            });

            // supervisors
            Lazy(supervisorUsersUI).each((userUI) => {
                if (!Lazy(vm.nbsAssignmentSupervisorModalObj.appUserMultiselectSelectedItems()).where({ id: userUI.AppUserId }).some()) {
                    removeFromContext(userUI);
                }
            });
            Lazy(supervisorGroupsUI).each((groupUI) => {
                if (!Lazy(vm.nbsAssignmentSupervisorModalObj.drbacUserGroupMultiselectSelectedItems()).where({ id: groupUI.DrbacUserGroupId }).some()) {
                    removeFromContext(groupUI);
                }
            });
            Lazy(vm.nbsAssignmentSupervisorModalObj.appUserMultiselectSelectedItems()).each((user) => {
                if (!Lazy(supervisorUsersUI).where({ AppUserId: user.id }).some()) {
                    addToContext(vhrAccounterKindEnumParsed.obj.normal.name_value.Supervisor, user.id, null);
                }
            });
            Lazy(vm.nbsAssignmentSupervisorModalObj.drbacUserGroupMultiselectSelectedItems()).each((group) => {
                if (!Lazy(supervisorGroupsUI).where({ DrbacUserGroupId: group.id }).some()) {
                    addToContext(vhrAccounterKindEnumParsed.obj.normal.name_value.Supervisor, null, group.id);
                }
            });
        }

        function removeFromContext(item) {
            foundationService.deleteEntity(item);
        }

        function addToContext(vhrAccounterKindId, appUserId, drbacUserGroupId) {
            foundationService.dataContext.vhrAccounterDtos.add({
                VirtualHumanResourceId: pVirtualHumanResourceId,
                VhrAccounterKindId: vhrAccounterKindId,
                AppUserId: appUserId,
                DrbacUserGroupId: drbacUserGroupId
            })
        }

        function setSelectionUI(vhrAccounters) {
            operatorUsersUI = [];
            operatorGroupsUI = [];
            responsibleUsersUI = [];
            responsibleGroupsUI = [];
            supervisorUsersUI = [];
            supervisorGroupsUI = [];

            var operators = Lazy(vhrAccounters).where({ VhrAccounterKindId: vhrAccounterKindEnumParsed.obj.normal.name_value.Operator }).toArray();
            operatorUsersUI = Lazy(operators).reject({ AppUserId: null }).toArray();
            operatorGroupsUI = Lazy(operators).reject({ DrbacUserGroupId: null }).toArray();
            vm.nbsAssignmentOperatorModalObj.setAppUserItemsSelected(operatorUsersUI, 'AppUserId', 'DisplayNameUI');
            vm.nbsAssignmentOperatorModalObj.setDrbacUserGroupItemsSelected(operatorGroupsUI, 'DrbacUserGroupId', 'DrbacUserGroupName');

            var responsibles = Lazy(vhrAccounters).where({ VhrAccounterKindId: vhrAccounterKindEnumParsed.obj.normal.name_value.Responsible }).toArray();
            responsibleUsersUI = Lazy(responsibles).reject({ AppUserId: null }).toArray();
            responsibleGroupsUI = Lazy(responsibles).reject({ DrbacUserGroupId: null }).toArray();
            vm.nbsAssignmentResponsibleModalObj.setAppUserItemsSelected(responsibleUsersUI, 'AppUserId', 'DisplayNameUI');
            vm.nbsAssignmentResponsibleModalObj.setDrbacUserGroupItemsSelected(responsibleGroupsUI, 'DrbacUserGroupId', 'DrbacUserGroupName');

            var supervisors = Lazy(vhrAccounters).where({ VhrAccounterKindId: vhrAccounterKindEnumParsed.obj.normal.name_value.Supervisor }).toArray();
            supervisorUsersUI = Lazy(supervisors).reject({ AppUserId: null }).toArray();
            supervisorGroupsUI = Lazy(supervisors).reject({ DrbacUserGroupId: null }).toArray();
            vm.nbsAssignmentSupervisorModalObj.setAppUserItemsSelected(supervisorUsersUI, 'AppUserId', 'DisplayNameUI');
            vm.nbsAssignmentSupervisorModalObj.setDrbacUserGroupItemsSelected(supervisorGroupsUI, 'DrbacUserGroupId', 'DrbacUserGroupName');
        }

        function getVhrAccounterDtos(forceReload, virtualHumanResourceId) {
            var filter = {
                VirtualHumanResourceId: virtualHumanResourceId
            };
            var defer = $q.defer();

            foundationService.getVhrAccounterDtos(filter, forceReload)
                .then(function (data) {
                    Lazy(data.results)
                        .each((item) => {
                            item.DisplayNameUI = item.AppUserLastName + ' ' + item.AppUserFirstName;
                        });

                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    nbsVhrAccountersComponent.$inject = injectParams;
    angular.module('nbs.rds.module').component('nbsVhrAccounters', {
        templateUrl: '/rds/common/components/nbsVhrAccountersComponent/_?c=' + window.codeCache(),
        controller: nbsVhrAccountersComponent,
        controllerAs: 'vm',
        bindings: {
            nbsDatacontext: '<',
            nbsSaveAction: '<',
            uiContext: '<',
            saveAction: '<',
            isReadonly: '<'
        }
    });
});

