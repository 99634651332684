﻿'use strict';

define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'vhreventService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function vhrEventsManualStepUpdateEventDetail($q, $scope, $timeout, $injector, foundationService, vhreventService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, foundationService);

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);

        var isBom = false;
        var vhrEventKindEnum = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventKind, null, vm.translate).obj.normal.name_value;

        var pParams = navigationService.stateParamsModal().parentParams;
        pParams = pParams ? pParams : navigationService.stateParams();

        // navigation buttons visibility
        vm.setWizardButtonVisibility(pParams);

        // beardcrumb information
        var beardCrumbInfo = JSON.parse(pParams.beardCrumbInfo);
        Lazy(beardCrumbInfo.items).each((item) => { item.title = vm.translate(item.title); })
        vm.beardCrumbInfo = beardCrumbInfo;

        vm.vhrEventManualUpdateDateClientData = vhreventService.dataContext.vhrEventManualUpdateDateClientDatas.add({
            vhrEventManualUpdateDateClientDataId: NbsGuidManager.newGuid(),
        });

        // refill data form
        if (vhreventService.vhrEventManualCollector) {
            vm.vhrEventManualUpdateDateClientData.IsRecurrent = vhreventService.vhrEventManualCollector.IsRecurrent;
            vm.vhrEventManualUpdateDateClientData.RecurrenceStartDate = vhreventService.vhrEventManualCollector.RecurrenceStartDate;
            vm.vhrEventManualUpdateDateClientData.EventStartTime = vhreventService.vhrEventManualCollector.EventStartTime;
            vm.vhrEventManualUpdateDateClientData.RecurrenceEndDate = vhreventService.vhrEventManualCollector.RecurrenceEndDate;
            vm.vhrEventManualUpdateDateClientData.EventEndTime = vhreventService.vhrEventManualCollector.EventEndTime;
        }

        vm.modalPrevious = function () {
            // close modal and returns client data object
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalPrevious);
            vhreventService.rejectChanges();
            navigationService.closeCurrentModal(modalResult);
        };

        vm.modalNext = function () {
            // if NOT event is recurrent, startdate and enddate must be the same
            if (!vm.vhrEventManualUpdateDateClientData.IsRecurrent) {
                vm.vhrEventManualUpdateDateClientData.RecurrenceEndDate = vm.vhrEventManualUpdateDateClientData.RecurrenceStartDate;
            }

            //force validation on entity (manual metadata only client)
            vm.vhrEventManualUpdateDateClientData.entityAspect.validateEntity();
            if (!vm.vhrEventManualUpdateDateClientData.entityAspect.hasValidationErrors) {
                vhreventService.rejectChanges();

                // close modal and returns client data object
                var modalResult = getModalResult(navigationService.navigationActionTypes.modalNext);
                navigationService.closeCurrentModal(modalResult);
            }
        };

        vm.modalCancel = function () {
            navigationService.confirmExitWizard()
                            .then(function (result) {
                                if (result) {
                                    vhreventService.rejectChanges();
                                    var modalResult = getModalResult(navigationService.navigationActionTypes.modalCancel);
                                    navigationService.closeCurrentModal(modalResult);
                                }
                            });
        };

        function getModalResult(dialogResult) {
            var modalResult = new navigationService.modalResult()
            
            var resultData = {
                RecurrenceStartDate: vm.vhrEventManualUpdateDateClientData.RecurrenceStartDate,
                EventStartTime: vm.vhrEventManualUpdateDateClientData.EventStartTime,
                RecurrenceEndDate: vm.vhrEventManualUpdateDateClientData.RecurrenceEndDate,
                EventEndTime: vm.vhrEventManualUpdateDateClientData.EventEndTime,
                IsRecurrent: vm.vhrEventManualUpdateDateClientData.IsRecurrent,
            }

            modalResult.data = resultData;
            modalResult.sourceParams = pParams;

            modalResult.dialogResult = dialogResult;
            return modalResult;
        }
    };

    vhrEventsManualStepUpdateEventDetail.$inject = injectParams;
    vhrEventsManualStepUpdateEventDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('vhrEventsManualStepUpdateEventDetail', vhrEventsManualStepUpdateEventDetail);
});