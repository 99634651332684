﻿'use strict';
define([], function () {

    var injectParams = [];
    var nbsBooleanComponent = function () {

        var vm = this;
        vm.privateDs = [];
        vm.privateSelected = false;

        vm.$onInit = function () {
            vm.privateDs = getDs();
        };

        vm.$onChanges = function (changes) {
            if (changes.nbsIsChecked) {
                if (changes.nbsIsChecked.currentValue === false) {
                    vm.privateSelected = [getUiOptionNo()];
                } else if (changes.nbsIsChecked.currentValue === true) {
                    vm.privateSelected = [getUiOptionYes()];
                }
            }
        };

        vm.onBooleanSelection = function (options) {
            var isChecked = null;
            if (Lazy(options).some()) {
                var sel = Lazy(options).first();
                isChecked = sel.optionValue;
            }

            vm.nbsBooleanOnchangeEvent({ value: isChecked });
        };

        function getUiOptionYes() {
            return new NbsUiOption({ optionValue: true, optionDisplayName: vm.uiContext.translate('Yes') });
        }

        function getUiOptionNo() {
            return new NbsUiOption({ optionValue: false, optionDisplayName: vm.uiContext.translate('No') });
        }

        function getDs() {
            var ds = [];
            ds.push(getUiOptionYes());
            ds.push(getUiOptionNo());
            return ds;
        }
    };

    nbsBooleanComponent.$inject = injectParams;
    angular.module('nbs.rds.module').component('nbsBoolean', {
        templateUrl: '/rds/common/components/nbsBooleanComponent/_?c=' + window.codeCache(),
        controller: nbsBooleanComponent,
        controllerAs: 'vm',
        bindings: {
            nbsBooleanOnchangeEvent: '&',
            nbsIsChecked: "<",
            uiContext: "<",
            ddLabel: '<',
            isVisible: "<",
        }
    });
});

