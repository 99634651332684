﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'triggerService', 'cmsService', 'navigationService', 'checkStateChangeService', 'contextService', 'notificationService'];
    var triggerInstanceDetail = function ($q, $scope, $timeout, $injector, triggerService, cmsService, navigationService, checkStateChangeService, contextService, notificationService) {

        var vm = this;
        nbsVm.call(vm, triggerService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, triggerService, { dirtyFlagIsSuspended: vm.isDirtySuspended });

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var triggerInstanceId = navigationService.stateParams().triggerInstanceId;
        var triggerMatchTypeEnum = triggerService.dataContext.enumTypes.TriggerMatchType;
        var triggerPropertyTypeEnum = triggerService.dataContext.enumTypes.TriggerPropertyType;
        var triggerModeEnum = triggerService.dataContext.enumTypes.TriggerMode;
        var languageId = contextService.virtualContext.CurrentLanguageId;
        var triggerDefinitionSelected = null;
        var triggerMatchTypeObj = Nembus.Common.Library.util.parseEnum(triggerMatchTypeEnum, 'TriggerInstanceMatch', vm.translate);
        var triggerPropertyTypeObj = Nembus.Common.Library.util.parseEnum(triggerPropertyTypeEnum, 'TriggerDefinitionProperty', vm.translate);

        // grid AND conditions
        var nbsAndConditionGridDatacontext = new NbsGrid(vm.translate);
        nbsAndConditionGridDatacontext.configureGrid('triggerinstancematch', '');
        nbsAndConditionGridDatacontext.setIsMultiselect();
        nbsAndConditionGridDatacontext.setDatasource([], false);
        nbsAndConditionGridDatacontext.allowPaging = false;
        nbsAndConditionGridDatacontext.allowSearching = false;
        nbsAndConditionGridDatacontext.allowGrouping = false;
        vm.nbsAndConditionGridDatacontext = nbsAndConditionGridDatacontext;

        // grid OR conditions
        var nbsOrConditionGridDatacontext = new NbsGrid(vm.translate);
        nbsOrConditionGridDatacontext.configureGrid('triggerinstancematch', '');
        nbsOrConditionGridDatacontext.setIsMultiselect();
        nbsOrConditionGridDatacontext.setDatasource([], false);
        nbsOrConditionGridDatacontext.allowPaging = false;
        nbsOrConditionGridDatacontext.allowSearching = false;
        nbsOrConditionGridDatacontext.allowGrouping = false;
        vm.nbsOrConditionGridDatacontext = nbsOrConditionGridDatacontext;

        var currentTriggerInstanceMatchesAndConditions = [];
        var currentTriggerInstanceMatchesOrConditions = [];
        vm.entityIsAdded = false;
        vm.isDisabledTriggerDefinition = false;

        ribbon.setRibbonStatusButton(ribbon.Buttons.New,  false);
        ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
        ribbon.setRibbonStatusButton(ribbon.Buttons.Refresh, false);
        ribbon.refreshStatusButtons();

        if (triggerInstanceId) {
            vm.setIsViewing();
            vm.entityIsAdded = true;
            vm.isDisabledTriggerDefinition = true;

            loadData(true, triggerInstanceId);
        } else {
            vm.setIsAdding();
            ribbon.setRibbonStatusButton(ribbon.Buttons.New,  false);
            ribbon.setRibbonStatusButton(ribbon.Buttons.Modify,false);
            ribbon.refreshStatusButtons();

            getTriggerDefinitions(true);

            vm.triggerInstance = triggerService.dataContext.triggerInstances.add({
                IsSystem: false,
                IsEnable: false,
                TriggerDefinitionId: null,
                StartDate: null,
                TriggerModeId: 2, //TriggerBackgroundMode
                VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId
            });
        }

        var parents = vm.submitAction;
        vm.submitAction = function () {
            if (!vm.triggerInstance.IsCronExpressionEnabled) {
                vm.triggerInstance.IsReminderEnabled = false;
            }
            vm.triggerInstance.entityAspect.validateEntity();

            parents().then(
                function (saveResult) {
                    if (saveResult.entities.length > 0 && vm.viewContext.isAddingState) {
                        vm.entityIsAdded = true;
                        vm.isDisabledTriggerDefinition = true;
                        triggerInstanceId = vm.triggerInstance.TriggerInstanceId;


                        if (!vm.currentTriggerDefinitionProperties) {
                            getTriggerDefinitionProperties(true, vm.triggerInstance.TriggerInstanceId)
                        }

                        // can add conditions
                        ribbon.setRibbonStatusButton(ribbon.Buttons.New, true);
                        ribbon.setRibbonStatusButton(ribbon.Buttons.Modify, true);
                        ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, !vm.triggerInstance.IsSystem);
                        ribbon.refreshStatusButtons();
                    }

                    getTriggerInstanceMatches(true, triggerInstanceId);                    
                }
            );
        };

        var parentm = vm.modifyAction;
        vm.modifyAction = function () {
            vm.triggerInstance.entityAspect.validateEntity();

            parentm().then(
                //enable disable button new & delete because is custom behavior
                //generally saveResult eneble or disable only save button 
                function (saveResult) {
                    if (vm.viewContext.isEditingState) {
                        ribbon.setRibbonStatusButton(ribbon.Buttons.New,  !vm.triggerInstance.IsSystem);
                        ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, !vm.triggerInstance.IsSystem);
                        ribbon.refreshStatusButtons();
                    }
                }
            );
        };

        vm.newAction = function () {
            navigationService.navToModal(vm.suspendDirty, 'app.nav.triggerinstancematchdetail', { modalMode: 'triggerkey' }, { triggerInstance: vm.triggerInstance })
                .then(function (result) {
                    var condition = result.data;
                    var currentTriggerInstanceMatch = triggerService.dataContext.triggerInstanceMatches.add(condition);
                    currentTriggerInstanceMatch.TriggerMatchTypeTranslated = condition.TriggerMatchTypeTranslated;
                    currentTriggerInstanceMatch.TriggerPropertyTypeTranslated = condition.TriggerPropertyTypeTranslated;
                    currentTriggerInstanceMatch.PropertyNameTranslated = condition.PropertyNameTranslated;

                    parseTriggerInstanceMatch(currentTriggerInstanceMatch);

                    vm.submitAction();
                });
        };

        var conditionsSelected = [];
        var currentOrConditionSelected = [];
        var currentAndConditionSelected = [];
        vm.gridOrConditionSelection = function (args) {
            currentOrConditionSelected = args.data;
            conditionsSelected = Lazy(currentOrConditionSelected).union(currentAndConditionSelected).uniq().toArray();
        }
        vm.gridAndConditionSelection = function (args) {
            currentAndConditionSelected = args.data;
            conditionsSelected = Lazy(currentOrConditionSelected).union(currentAndConditionSelected).uniq().toArray();
        }

        vm.deleteAction = function () {
            if (conditionsSelected.length === 0) {
                return;
            }

            if (vm.triggerInstance.IsEnable) {
                navigationService.confirmModal(vm.translate("TriggerDisabledWarning"))
                    .then(function (result) {
                        if (result) {
                            vm.triggerInstance.IsEnable = false;

                            deleteCondition(conditionsSelected);
                        }
                    });
            } else {
                deleteCondition(conditionsSelected);
            }
        };

        vm.cronExpressionOnClick = function () {
            navigationService.navToModal(vm.suspendDirty, 'app.nav.cronexpressiondetail', { 'cronstring': vm.triggerInstance.CronExpression, modalMode: 'cronkey' })
                .then(function (result) {
                    var cronObj = result.data;
                    vm.triggerInstance.CronExpression = cronObj.getCronExpression();
                    vm.triggerInstance.CronExpressionLabel = cronObj.getCronLabel();
                });
        };

        function deleteCondition(conditionsSelected) {
            var currEntityToDelete = null;

            // conditions 
            Lazy(conditionsSelected)
                .each((triggerInstanceMatch) => {
                    // AND conditions
                    currEntityToDelete = Lazy(currentTriggerInstanceMatchesAndConditions).findWhere({ TriggerInstanceMatchId: triggerInstanceMatch.TriggerInstanceMatchId });
                    if (currEntityToDelete) {
                        currentTriggerInstanceMatchesAndConditions = Lazy(currentTriggerInstanceMatchesAndConditions)
                            .reject({ TriggerInstanceMatchId: currEntityToDelete.TriggerInstanceMatchId })
                            .toArray();

                        vm.forceAndConditionDatasourceLocal = {
                            refreshkey: new Date().getTime(),
                            dataSource: currentTriggerInstanceMatchesAndConditions
                        }

                        triggerService.deleteEntity(currEntityToDelete);
                    }

                    // OR conditions
                    currEntityToDelete = Lazy(currentTriggerInstanceMatchesOrConditions).findWhere({ TriggerInstanceMatchId: triggerInstanceMatch.TriggerInstanceMatchId });
                    if (currEntityToDelete) {
                        currentTriggerInstanceMatchesOrConditions = Lazy(currentTriggerInstanceMatchesOrConditions)
                            .reject({ TriggerInstanceMatchId: currEntityToDelete.TriggerInstanceMatchId })
                            .toArray();

                        vm.forceAndConditionDatasourceLocal = {
                            refreshkey: new Date().getTime(),
                            dataSource: currentTriggerInstanceMatchesOrConditions
                        }

                        triggerService.deleteEntity(currEntityToDelete);
                    }
                });

            vm.submitAction();
        }


        vm.triggerDefinitionOnChange = function () {
            triggerDefinitionSelected = Lazy(vm.triggerDefinitions).findWhere({ TriggerDefinitionId: vm.triggerInstance.TriggerDefinitionId });
            getThesaurusCmsDomObjs(true, triggerDefinitionSelected.CmsDomObjClassFullName, languageId);
            getTriggerInstancesAvailables(true, vm.triggerInstance.TriggerDefinitionId);
        };

        function loadData(forceReload, triggerInstanceId) {
            var funcsPromises = [];
            funcsPromises.push(getTriggerDefinitions(forceReload));
            funcsPromises.push(getTriggerDefinitionProperties(forceReload, triggerInstanceId));
            funcsPromises.push(getTriggerInstance(forceReload, triggerInstanceId));

            $q.all(funcsPromises)
                .then(function () {
                    triggerDefinitionSelected = Lazy(vm.triggerDefinitions).findWhere({ TriggerDefinitionId: vm.triggerInstance.TriggerDefinitionId });

                    var funcsPromisesSub = [];
                    funcsPromisesSub.push(getThesaurusCmsDomObjs(forceReload, triggerDefinitionSelected.CmsDomObjClassFullName, languageId));
                    funcsPromisesSub.push(getTriggerInstancesAvailables(forceReload, vm.triggerInstance.TriggerDefinitionId));

                    $q.all(funcsPromisesSub)
                        .then(function () {
                            getTriggerInstanceMatches(forceReload, triggerInstanceId);
                        });
                });
        }

        function getThesaurusCmsDomObjs(forceReload, cmsDomObjClassFullName, languageId) {
            var filter = {
                ElementGroupCodeUnique: cmsDomObjClassFullName,
                LanguageId: languageId
            };
            var defer = $q.defer();

            cmsService.getThesaurusCmsDomObjs(filter, forceReload)
                .then(function (data) {
                    vm.thesaurus = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getTriggerInstance(forceReload, triggerInstanceId) {
            var filter = {
                TriggerInstanceId: triggerInstanceId
            };
            var defer = $q.defer();

            triggerService.getTriggerInstance(filter, forceReload)
                .then(function (data) {
                    Nembus.Common.Library.parser.AddCronExpressionLabelToList(data.results, 'CronExpression', vm.translate);

                    vm.triggerInstance = data.results[0];

                    ribbon.setRibbonStatusButton(ribbon.Buttons.Modify, !vm.triggerInstance.IsSystem);
                    ribbon.refreshStatusButtons();

                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getTriggerDefinitions(forceReload) {
            var filter = {};
            var defer = $q.defer();

            triggerService.getTriggerDefinitions(filter, forceReload)
                .then(function (data) {
                    vm.triggerDefinitions = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getTriggerDefinitionProperties(forceReload) {
            var filter = {};
            var defer = $q.defer();

            triggerService.getTriggerDefinitionProperties(filter, forceReload)
                .then(function (data) {
                    vm.currentTriggerDefinitionProperties = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getTriggerInstancesAvailables(forceReload, triggerDefinitionId) {
            var filter = { TriggerDefinitionId: triggerDefinitionId };
            var defer = $q.defer();

            notificationService.getTriggerInstancesAvailables(filter, forceReload)
                .then(function (data) {
                    vm.notificationInstances = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getTriggerInstanceMatches(forceReload, triggerInstanceId) {
            var filter = {
                TriggerInstanceId: triggerInstanceId
            };
            var defer = $q.defer();

            triggerService.getTriggerInstanceMatches(filter, forceReload)
                .then(function (data) {
                    Lazy(data.results).each((match) => {
                        parseTriggerInstanceMatch(match);
                    });

                    // binded to grid datasource
                    currentTriggerInstanceMatchesAndConditions = Lazy(data.results).where({ IsOrMatch: false }).toArray();
                    currentTriggerInstanceMatchesOrConditions = Lazy(data.results).where({ IsOrMatch: true }).toArray();

                    vm.forceAndConditionDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: currentTriggerInstanceMatchesAndConditions
                    }

                    vm.forceOrConditionDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: currentTriggerInstanceMatchesOrConditions
                    }

                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function parseTriggerInstanceMatch(match) {

            // do not change traslated property names
            match.TriggerMatchTypeTranslated = triggerMatchTypeObj.obj.translated.value_name[match.TriggerMatchTypeId];
            match.TriggerPropertyTypeTranslated = triggerPropertyTypeObj.obj.translated.value_name[match.TriggerPropertyTypeId];

            var key = triggerDefinitionSelected.TriggerDefinitionCode + '.' + match.PropertyName;
            match.PropertyNameTranslated = Nembus.Common.Library.cms.getWordKeyFromThesaurus(vm.thesaurus, key, languageId);

            if (match.TriggerPropertyTypeId === triggerPropertyTypeObj.obj.normal.name_value.StatusProperty
                || match.TriggerPropertyTypeId === triggerPropertyTypeObj.obj.normal.name_value.DatetimeProperty) {

                var currDefProperty = Lazy(vm.currentTriggerDefinitionProperties).findWhere({
                    TriggerDefinitionId: vm.triggerInstance.TriggerDefinitionId,
                    PropertyName: match.PropertyName
                });

                if (currDefProperty && currDefProperty.PropertyValues) {
                    match.ValueUI = match.Value;
                    match.ValueSpanUI = match.ValueSpan;

                    var currValueField = 'Value';
                    if (match.TriggerPropertyTypeId === triggerPropertyTypeObj.obj.normal.name_value.DatetimeProperty) {
                        currValueField = 'ValueSpan';
                    }

                    var propertyValuesObj = JSON.parse(Nembus.Common.Library.converter.decodeHtml(currDefProperty.PropertyValues));

                    Lazy(Object.keys(propertyValuesObj)).each((property) => {
                        if (propertyValuesObj[property] === Number(match[currValueField])) {
                            if (currDefProperty.ParentPropertyName === 'global') {
                                match[currValueField + 'UI'] = vm.translate(property);
                            } else {
                                var key = currDefProperty.ParentPropertyName
                                    ? currDefProperty.ParentPropertyName + '.' + property
                                    : property;

                                match[currValueField + 'UI'] = Nembus.Common.Library.cms.getWordKeyFromThesaurus(vm.thesaurus, key, languageId);
                            }
                        }
                    });

                }
            } else if (match.TriggerPropertyTypeId === triggerPropertyTypeObj.obj.normal.name_value.UserIdProperty
                || match.TriggerPropertyTypeId === triggerPropertyTypeObj.obj.normal.name_value.UserGroupIdProperty
                || match.TriggerPropertyTypeId === triggerPropertyTypeObj.obj.normal.name_value.BooleanProperty
                || match.TriggerPropertyTypeId === triggerPropertyTypeObj.obj.normal.name_value.WorkflowStatusIdProperty            ) {

                match.ValueUI = match.ValueDescription;
            } else {

                match.ValueUI = match.Value;
            }
        }
    };

    triggerInstanceDetail.$inject = injectParams;
    triggerInstanceDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('triggerInstanceDetail', triggerInstanceDetail);
});
