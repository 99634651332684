﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'metricService', 'productionService', 'utilService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var metricInstanceDetail = function ($q, $scope, $timeout, $injector, metricService, productionService, utilService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, metricService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, metricService, { dirtyFlagIsSuspended: vm.isDirtySuspended });

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var metricDataFrequencyParsedEnum = Nembus.Common.Library.util.parseEnum(metricService.dataContext.enumTypes.MetricDataFrequency, 'MetricInstance', vm.translate);
        var dateIntervalEnum = utilService.dataContext.enumTypes.DateInterval_dateMode;
        var wCalendarParsedEnum = Nembus.Common.Library.util.parseEnum(productionService.dataContext.enumTypes.WCalendarKind, null, vm.translate);
        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);

        vm.metricDataFrequencyDD = metricDataFrequencyParsedEnum.list.translated;
        vm.dateIntervalUnits = Nembus.Common.Library.util.parseEnum(dateIntervalEnum, null, vm.translate).list.translated;

        vm.specializationMultiselectDs = new NbsMultiselect(vm.translate);

        vm.assetsSelected = [];

        var metricInstanceId = navigationService.stateParams().metricInstanceId;
        if (metricInstanceId) {
            vm.setIsViewing();
        }
        else {
            vm.setIsAdding();
        }
        loadData(true);

        var selectionParams = { destModalKey: 'vhr', entityMode: 'AssetTree', translateKey: 'VirtualEquipment', isMultiselect: true };
        vm.nbsSelectionGridWithModalImsAsset = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalImsAsset.setGenericSelectionMessage(vm.translate('ItemsSelected'));
        vm.nbsSelectionGridWithModalImsAsset.setOnSelectFnCallback(function () {
            // onchange imsasset
            vm.assetTreeSelected = [];
            Lazy(vm.nbsSelectionGridWithModalImsAsset.selectedItems)
                .each((assetTree) => {
                    vm.assetTreeSelected.push(assetTree.item);
                });
        });

        vm.cronExpressionOnClick = function () {
            navigationService.navToModal(vm.suspendDirty, 'app.nav.cronexpressiondetail',
                { 'cronstring': vm.currentMetricInstance.RunMetricCronExpression }
            ).then(function (result) {
                vm.currentMetricInstance.RunMetricCronExpression = result.data.getCronExpression();
                vm.currentMetricInstance.RunMetricCronExpressionLabel = result.data.getCronLabel();
            });
        };

        var parents = vm.submitAction;
        vm.submitAction = function () {
            // metricinstance X assets
            Nembus.Rds.Library.SetBindingM2MEntitiesInContext(metricService.dataContext.metricInstanceM2MVhrDtos, 'MetricInstanceId', [Number(vm.currentMetricInstance.MetricInstanceId)], 'VirtualHumanResourceId', Lazy(vm.assetTreeSelected).pluck('nbs_id').toArray());

            Lazy(metricService.dataContext.metricInstanceM2MVhrDtos.get())
                .each((entity) => {
                    if (entity.entityAspect.entityState.isAdded()) {
                        var relAssetTree = Lazy(vm.assetTreeSelected).findWhere({ VirtualHumanResourceId: entity.VirtualHumanResourceId });
                        if (relAssetTree) {
                            entity.ResourceTypeId = relAssetTree.ResourceTypeId;
                            entity.ResourcePrototypeId = relAssetTree.ResourcePrototypeId;
                        }
                    }
                });

            // metricinstance X specializations
            Nembus.Rds.Library.SetBindingM2MEntitiesInContext(metricService.dataContext.metricInstanceM2MMetricSpecializationDtos, 'MetricInstanceId', [Number(vm.currentMetricInstance.MetricInstanceId)], 'MetricSpecializationId', vm.specializationMultiselectDs.selectedIds);

            parents()
                .then(function (result) {
                    metricService.saveChanges()
                        .then(function (result) {
                        });
                });
        };

        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getMetricSpecializations(forceReload, moduleTargetId));
            funcsPromises.push(getCalendars(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.metricSpecializations = results[0];
                    vm.wCalendarsDropDown = results[1];

                    vm.specializationMultiselectDs.addData(vm.metricSpecializations, 'MetricSpecializationId', 'MetricSpecializationName');

                    if (metricInstanceId) {
                        var funcsPromisesSub = [];
                        funcsPromisesSub.push(getMetricInstance(forceReload, metricInstanceId));
                        funcsPromisesSub.push(getMetricInstanceM2MVhrDtos(forceReload, metricInstanceId));
                        funcsPromisesSub.push(getMetricInstanceM2MMetricSpecializationDtos(forceReload, metricInstanceId));

                        $q.all(funcsPromisesSub)
                            .then(function (subresults) {
                                Nembus.Common.Library.parser.AddCronExpressionLabelToList(subresults[0], 'RunMetricCronExpression', vm.translate);
                                vm.currentMetricInstance = subresults[0][0];
                                vm.metricInstanceM2MVhrs = subresults[1];
                                vm.metricInstanceM2MMetricSpecializations = subresults[2];

                                var selectedAssetTrees = Lazy(vm.metricInstanceM2MVhrs).map((assetTree) => {
                                    return {
                                        nbs_id: assetTree.VirtualHumanResourceId,
                                        nbs_code: assetTree.VirtualCode,
                                        nbs_name: assetTree.VirtualName,
                                        ResourceTypeId: assetTree.ResourceTypeId
                                    };
                                }).toArray();

                                vm.nbsSelectionGridWithModalImsAsset.setSelectedItems(selectedAssetTrees);

                                var kpisId = Lazy(vm.metricInstanceM2MMetricSpecializations).pluck('MetricSpecializationId').toArray();
                                vm.specializationMultiselectDs.setSelected(kpisId);
                            });
                    } else {
                        vm.currentMetricInstance = metricService.dataContext.metricInstances.add({
                            StartDate: null,
                            WCalendarId: null,
                            MetricDataFrequencyId: null,
                            SpanCoverageUnitId: null,
                            SpanCoverageQuota: null,
                            VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId
                        });
                    }
                });
        }

        function getMetricInstance(forceReload, metricInstanceId) {
            var filter = { MetricInstanceId: metricInstanceId };
            var defer = $q.defer();

            metricService.getMetricInstance(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getMetricSpecializations(forceReload, moduleTargetId) {
            var filter = { ModuleTargetId: moduleTargetId};
            var defer = $q.defer();

            metricService.getMetricSpecializations(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getCalendars(forceReload) {
            var filter = { WCalendarKindId: wCalendarParsedEnum.obj.normal.name_value.Metric };
            var defer = $q.defer();

            productionService.getCalendars(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getMetricInstanceM2MVhrDtos(forceReload, metricInstanceId) {
            var filter = { MetricInstanceId: metricInstanceId };
            var defer = $q.defer();

            metricService.getMetricInstanceM2MVhrDtos(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getMetricInstanceM2MMetricSpecializationDtos(forceReload, metricInstanceId) {
            var filter = { MetricInstanceId: metricInstanceId };
            var defer = $q.defer();

            metricService.getMetricInstanceM2MMetricSpecializationDtos(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }
    };

    metricInstanceDetail.$inject = injectParams;
    metricInstanceDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('metricInstanceDetail', metricInstanceDetail);
});