﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', 'navigationService', 'contextService', 'actService'];
    function actJournalsModal($q, navigationService, contextService, actService) {

        var vm = this;
        nbsVm.call(vm, actService, navigationService, contextService, $q, null, null);
        
        var modalParams = navigationService.getJournalAttachmentsModalParams();
        var entityId = modalParams.entityId;
        var entityJournalStatusEnum = Nembus.Common.Library.util.parseEnum(actService.dataContext.enumTypes.entityJournalStatus, null, vm.translate).obj.normal.name_value;

        vm.entityJournals = [];

        getActJournalDtos(true, entityId)
            .then((journals) => {
                vm.entityJournals = journals;
            });

        vm.addJournalAction = function () {
            vm.entityJournals.push(actService.dataContext.actJournalDtos.add({
                EntityId: entityId,
                CreatorUserDisplayName: contextService.virtualContext.CurrentUserDisplayName,
                EntityJournalNote: null
            }));
        };

        vm.deleteJournalAction = function (entityJournal) {
            if (entityJournal.entityAspect.entityState.isAdded()) {
                entityJournal.entityAspect.rejectChanges();

                vm.entityJournals = Lazy(vm.entityJournals)
                    .reject({ EntityJournalId: entityJournal.EntityJournalId })
                    .toArray();
            } else {
                entityJournal.EntityJournalStatusId = entityJournalStatusEnum.Deleted; // deleted
            }
        };

        // submit and close modal
        vm.modalOk = function () {
            // save journals
            actService.saveChanges()
                .then(() => {
                    var modalResult = getModalResult(navigationService.navigationActionTypes.modalOk);
                    navigationService.closeCurrentModal(modalResult);
                });
        };

        // close modal
        vm.modalCancel = function () {
            // reject journals
            Lazy(vm.entityJournals)
                .each((item) => {
                    item.entityAspect.rejectChanges();

                    if (item.entityAspect.entityState.isDetached()) {
                        vm.entityJournals = Lazy(vm.entityJournals).reject({ EntityJournalId: item.EntityJournalId }).toArray();
                    }
                });

            var modalResult = getModalResult(navigationService.navigationActionTypes.modalCancel);
            navigationService.closeCurrentModal(modalResult);
        };

        function getActJournalDtos(forceReload, entityId) {
            var filter = { EntityId: entityId };
            var defer = $q.defer();

            actService.getActJournalDtos(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getModalResult(dialogResult) {
            var modalResult = new navigationService.modalResult();
            modalResult.dialogResult = dialogResult;
            return modalResult;
        }
    }

    actJournalsModal.$inject = injectParams;
    actJournalsModal.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('actJournalsModal', actJournalsModal);
});