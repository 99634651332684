﻿
'use strict';
define(['../../common/services/nbs-service'], function (nbsService) {

    var injectParams = ['$http', '$q', '$timeout','breezeDataLayer'];

    var utilService = function ($http, $q, $timeout,breezeDataLayer) {

        var dataLayer = breezeDataLayer.Initialize('util', true);
        var manager = dataLayer.GetManager();

        var entityTypes = {
            PairValueClientData: 'PairValueClientData',
            FsDtoAttachmentClientData: 'FsDtoAttachmentClientData',

            GenericElementDto: 'GenericElementDto',
            FsDto: 'FsDto',
            FsDtoCategorized: 'FsDtoCategorized',
            ServerSystemVariable: 'ServerSystemVariable',
            ScheduledTaskClientData: 'ScheduledTaskClientData',
            FileMetadataPermissionRequest: 'FileMetadataPermissionRequest'
        };

        nbsService.call(this, manager, $q, $timeout);

        // client data validation for ScheduledTaskClientData
        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.ScheduledTaskClientData,
                dataProperties: {
                    ScheduledTaskClientDataId: { type: breeze.DataType.Guid },
                    Code: { type: breeze.DataType.String },
                    Name: { type: breeze.DataType.String },
                    Time: { type: breeze.DataType.String }
                }
            },
            'Nembus.Domain'
        );

        var scheduledTaskClientDataCodeValidator = new breeze.Validator('scheduledTaskClientDataCodeValidator', function (value, context) { if (!context.entity.Code) { return false; } else { return true; } });
        var scheduledTaskClientDataNameValidator = new breeze.Validator('scheduledTaskClientDataNameValidator', function (value, context) { if (!context.entity.Name) { return false; } else { return true; } });
        var scheduledTaskClientDataTimeValidator = new breeze.Validator('scheduledTaskClientDataTimeValidator', function (value, context) { if (!context.entity.Time) { return false; } else { return true; } });

        var scheduledTaskClientData = manager.metadataStore.getEntityType(entityTypes.ScheduledTaskClientData);
        scheduledTaskClientData.getProperty("Code").validators.push(scheduledTaskClientDataCodeValidator);
        scheduledTaskClientData.getProperty("Name").validators.push(scheduledTaskClientDataNameValidator);
        scheduledTaskClientData.getProperty("Time").validators.push(scheduledTaskClientDataTimeValidator);
        // End client data validation for scheduledTaskClientData

        //breeze client type
        //client validation
        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.PairValueClientData,
                dataProperties: {
                    Id: { type: breeze.DataType.Guid },
                    PairValueCode: { type: breeze.DataType.String },
                    PairValueName: { type: breeze.DataType.String }
                }
            }, 'Nembus.Domain'
        );

        var pairValueClientData = manager.metadataStore.getEntityType('PairValueClientData');
        pairValueClientData.getProperty("PairValueCode").validators.push(new breeze.Validator("codeValidator", Nembus.Common.Library.validators.requiredFieldValidator));
        pairValueClientData.getProperty("PairValueName").validators.push(new breeze.Validator("nameValidator", Nembus.Common.Library.validators.requiredFieldValidator));

        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.FsDtoAttachmentClientData,
                dataProperties: {
                    Id: { type: breeze.DataType.Guid },
                    FsCode: { type: breeze.DataType.String },
                    FsName: { type: breeze.DataType.String },
                    FsDescription: { type: breeze.DataType.String }
                }
            }, 'Nembus.Domain'
        );

        var fsDtoAttachmentClientData = manager.metadataStore.getEntityType('FsDtoAttachmentClientData');
        fsDtoAttachmentClientData.getProperty("FsCode").validators.push(new breeze.Validator("codeValidator", Nembus.Common.Library.validators.requiredFieldValidator));
        fsDtoAttachmentClientData.getProperty("FsName").validators.push(new breeze.Validator("nameValidator", function (value, context) {
            if (value === null || value === undefined || value === '' || value === 'root') {
                return false;
            } else {
                return true;
            }
        }));

        this.dataContext = {
            pairValueClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.PairValueClientData),
            fsDtoAttachmentClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.FsDtoAttachmentClientData),


            scheduledTaskClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.ScheduledTaskClientData),
            fileMetadataPermissionRequests: dataLayer.setContextMethods(manager, nbsService, entityTypes.FileMetadataPermissionRequest),
            serverSystemVariables: dataLayer.setContextMethods(manager, nbsService, entityTypes.ServerSystemVariable),
            genericElements: dataLayer.setContextMethods(manager, nbsService, entityTypes.GenericElementDto),
            fsDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.FsDto),
            fsDtoCategorizeds: dataLayer.setContextMethods(manager, nbsService, entityTypes.FsDtoCategorized),

            enumTypes: {
                ExpressionType: dataLayer.GetEnumType('ExpressionType'),    //System.Liq.Expression (GreaterThan, Equal, ...)
                EffortUnit: dataLayer.GetEnumType('EffortUnit'),            //Number, Hour, Kg, Lt, ..
                InvoiceEffortUnit: dataLayer.GetEnumType('InvoiceEffortUnit'),            //Number, Hour, Kg, Lt, ..
                FileMetadataRequestType: dataLayer.GetEnumType('FileMetadataRequestType'),

                DateInterval: dataLayer.GetEnumType('DateInterval'),
                DataStorageUnit: dataLayer.GetEnumType('DataStorageUnit'),

                // day, month, year
                DateInterval_dateMode: Lazy(dataLayer.GetEnumType('DateInterval'))
                    .filter((item) => { return item.value === '1' || item.value === '5' || item.value === '10'; })
                    .toArray(),
                // hour, minute, seconds
                DateInterval_timeMode: Lazy(dataLayer.GetEnumType('DateInterval'))
                    .filter((item) => { return item.value === '3' || item.value === '4' || item.value === '7'; })
                    .toArray()
            }
        };

        this.ssvManagedType = {
            array: 'System.Array',
            string: 'System.String',
            decimal: 'System.Decimal',
            boolean: 'System.Boolean'
        };

        // used to parse SystemServerVariable
        this.parseSystemServerVariable = function (translator, systemServerVariable) {

            if (systemServerVariable.VariableDefault && systemServerVariable.VariableType === this.ssvManagedType.array) {
                systemServerVariable.VariableDefaultTranslated = translator('ssv_' + systemServerVariable.VariableDefault.replaceAll('-', '_') + '_selection');
            } else {
                systemServerVariable.VariableDefaultTranslated = translator(systemServerVariable.VariableDefault);
            }

            if (systemServerVariable.VariableValue && systemServerVariable.VariableType === this.ssvManagedType.array) {
                systemServerVariable.VariableValueTranslated = translator('ssv_' + systemServerVariable.VariableValue.replaceAll('-', '_') + '_selection');
            } else {
                systemServerVariable.VariableValueTranslated = translator(systemServerVariable.VariableValue);
            }

            switch (systemServerVariable.VariableType) {
                case this.ssvManagedType.array:
                    systemServerVariable.DefinedValuesForDropDown = [];
                    if (systemServerVariable.DefinedValues) {
                        Lazy(systemServerVariable.DefinedValues.split(',')).each(
                            function (definedValue) {
                                systemServerVariable.DefinedValuesForDropDown.push({
                                    value: definedValue,
                                    name: translator('ssv_' + definedValue.replaceAll('-', '_') + '_selection')
                                });
                            });
                    }
                    break;
                case this.ssvManagedType.boolean:
                    systemServerVariable.VariableValueToBind = systemServerVariable.VariableValue === 'true' ? true : false;
                    break;

                case this.ssvManagedType.decimal:
                    break;

                case this.ssvManagedType.string:
                    break;
            }
        };

        this.createQuery = function (entityName) {
            return dataLayer.CreateQuery(entityName);
        };

        this.getServerSystemVariables = function (filter, forceReload) {
            var params = { entityType: entityTypes.ServerSystemVariable, cache: this.dataContext.serverSystemVariables.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "ServerSystemVariables");
        };
        this.getServerSystemVariable = function (filter, forceReload) {
            var params = { key: filter.VariableCode, dataContext: this.dataContext.serverSystemVariables };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "ServerSystemVariables");
        };

        this.getGenericElements = function (filter, forceReload) {
            var defer = $q.defer();
            var params = { entityType: entityTypes.GenericElementDto, cache: this.dataContext.genericElements.get() };

            dataLayer.GetDataListPromise(params, filter, forceReload, "GenericElements")
                .then(function (data) {
                    data.results = parseGenericElements(data.results);
                    defer.resolve(data);
                });

            return defer.promise;
        };

        this.getGenericElementsSubTree = function (filter, forceReload) {
            var defer = $q.defer();
            var params = { entityType: entityTypes.GenericElementDto, cache: this.dataContext.genericElements.get() };

            dataLayer.GetDataListPromise(params, filter, forceReload, "GenericElementsSubTree")
                .then(function (data) {
                    data.results = parseGenericElements(data.results);
                    defer.resolve(data);
                });

            return defer.promise;
        };

        this.genericFsDtos = function (filter, forceReload) {
            var params = { entityType: entityTypes.FsDto, cache: this.dataContext.fsDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "FsSubTree");
        };

        this.genericFsDtosNoAuth = function (filter, forceReload) {
            var params = { entityType: entityTypes.FsDto, cache: this.dataContext.fsDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "FsSubTreeNoAuth");
        };

        this.genericFsDtoCategorizeds = function (filter, forceReload) {
            var params = { entityType: entityTypes.FsDtoCategorized, cache: this.dataContext.fsDtoCategorizeds.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "FsSubTreeCategorized");
        };

        this.genericFsDtoCategorizedBypass = function (filter, forceReload) {
            var params = { entityType: entityTypes.FsDtoCategorized, cache: this.dataContext.fsDtoCategorizeds.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "FsSubTreeCategorizedBypass");
        };

        // action to manage fsfolders attachments 
        this.fsFolderActions = [];
        this.setFsDtoRootAddFunction = function (action) {
            var actionAlreadySet = Lazy(this.fsFolderActions).findWhere({ rootFolderKey: action.rootFolderKey });
            if (actionAlreadySet) {
                actionAlreadySet = action;
            }
            else {
                this.fsFolderActions.push(action);
            }
        };
        this.getFsDtoRootAddFunction = function (rootFolderKey) {
            return Lazy(this.fsFolderActions).findWhere({ rootFolderKey: rootFolderKey });
        };
        this.clearFsDtoRootAddFunction = function (rootFolderKey) {
            this.fsFolderActions = Lazy(this.fsFolderActions).reject({ rootFolderKey: rootFolderKey }).toArray();
        };

        /* Tranform PairValues field of CoreGenericElements table to Array */
        function genericElementsToArray(pairValuesString) {
            var pairValuesArray = [];
            var pairValuesObj = Nembus.Common.Library.converter.JsonFieldParse(pairValuesString);//JSON.parse(pairValuesString);

            // iterate throught object properties
            for (var key in pairValuesObj) {
                if (pairValuesObj.hasOwnProperty(key)) {
                    pairValuesArray.push({ key: key, value: pairValuesObj[key] });
                }
            }

            return pairValuesArray;
        }

        function parseGenericElements(genericElements) {
            if (!Lazy(genericElements).some()) {
                return [{
                    PairValuesArray: [],
                    PairValuesObject: {},
                    PairValuesDropDown: []
                }]
            }

            Lazy(genericElements)
                .each((genericElement) => {
                    genericElement.PairValuesArray = genericElementsToArray(genericElement.PairValues);
                    genericElement.PairValuesObject = Nembus.Common.Library.parser.objFromList(genericElement.PairValuesArray, 'key', 'value');
                    genericElement.PairValuesDropDown = Lazy(genericElement.PairValuesArray).map((item) => { return { value: item.key, name: item.value }; }).toArray();
                });

            return genericElements;
        }

        /* Tranform String to PairValues field of CoreGenericElements table */
        this.genericElementsToString = function (pairValuesArray) {
            var pairValuesObj = {};

            Lazy(pairValuesArray)
                .each((item) => {
                    pairValuesObj[item.key] = item.value;
                });

            return JSON.stringify(pairValuesObj);
        };

        //Http Post
        this.setCategoriesToFilemetadata = function (filter) {
            var query = dataLayer.InvokeQuery("SetCategoriesToFilemetadata", filter);
            return manager.executeQuery(query);
        };

        //cross controller (through wizard steps of scheduling request)
        this.scheduledTaskCollector = {};                    // used to collect data for final mapping in insert SchedulingRequest wizard
        this.scheduledTaskClientData = {};                   // used to validate in insert SchedulingRequest wizard
    };

    utilService.$inject = injectParams;
    utilService.prototype = Object.create(nbsService.prototype);
    angular.module('nbs.rds.module').service('utilService', utilService);
});