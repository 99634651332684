﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'vhreventService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function vhrEventManualRequestsList($q, $scope, $timeout, $injector, vhreventService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, vhreventService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return requestSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, vhreventService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var appStoreCode = navigationService.stateParams().appStoreCode;
        
        ribbon.setRibbonStatusButton(ribbon.Buttons.New,  false);
        ribbon.refreshStatusButtons();

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];

            $q.all(funcsPromises)
                .then(function (results) {
                    var supportData = { translate: vm.translate };
                    var ejDataManager = vhreventService.getVhrEventManualRequestDataManager(supportData, {}, ejDataManager);
                    var nbsGridDatacontext = new NbsGrid(vm.translate);
                    nbsGridDatacontext.configureGrid('vhreventmanualrequest', null);
                    nbsGridDatacontext.viewCommonBar();
                    nbsGridDatacontext.setDatasource(ejDataManager, true);
                    vm.nbsGridDatacontext = nbsGridDatacontext;
                });
        }

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.vhreventmanualrequestdetail", { 'vhrEventManualRequestId': args.data.VhrEventManualRequestId, 'appStoreCode': appStoreCode });
        };

        var requestSelected = null;
        vm.gridSelection = function (args) {
            requestSelected = args.data;
        };

        vm.refreshAction = function () {
            $timeout(() => {
                vm.forceGridRefresh = new Date().getTime();
            });
        };
    }

    vhrEventManualRequestsList.$inject = injectParams;
    vhrEventManualRequestsList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('vhrEventManualRequestsList', vhrEventManualRequestsList);
});