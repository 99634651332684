﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'jobService', 'navigationService', 'checkStateChangeService', 'contextService', 'utilService', 'cmsService'];
    function execProcessesList($q, $scope, $timeout, $injector, jobService, navigationService, checkStateChangeService, contextService, utilService, cmsService) {

        var vm = this;
        nbsVm.call(vm, jobService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return execProcessSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, jobService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab({ showGroupArchive: true });
        ribbon.addTab(new ribbon.Tab("ActionPanelTabManage", [ribbon.GetSchedulingSection()]));
        vm.ribbonDatacontext = ribbon;

        var execProcessSelected = null;
        var execProcessRequestStatusParsedEnum = Nembus.Common.Library.util.parseEnum(jobService.dataContext.enumTypes.ExecProcessRequestStatus, null, vm.translate);
        var execProcessRequestStatus = execProcessRequestStatusParsedEnum.obj.normal.name_value;
        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;

        ribbon.setRibbonStatusButton(ribbon.Buttons.New,  false);
        ribbon.setRibbonStatusButton(ribbon.Buttons.RunScheduling, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.AddScheduledTask, true);
        ribbon.refreshStatusButtons();

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('execprocess', null);
        nbsGridDatacontext.viewCommonBar();
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getExecProcesses(false));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.currentExecProcesses = results[0];

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.currentExecProcesses
                    }
                });
        }

        vm.currentExecProcesses = [];

        vm.refreshAction = function () {
            loadData(true);
        };

        vm.gridSelection = function (args) {
            execProcessSelected = args.data;
        };

        vm.runAction = function () {
            if (!execProcessSelected) {
                return;
            }            

            vm.openRunModal();
        };

        vm.openRunModal = function () {
            vm.execProcessRequest = jobService.dataContext.execProcessRequestDtos.add({
                ExecProcessId: execProcessSelected.ExecProcessId,
                ExecProcessRequestName: execProcessSelected.ExecProcessName,
                ExecProcessRequestStatusId: execProcessRequestStatus.Inserted,
                VirtualCompanyId: virtualCompanyId
            });

            vm.modalInstance = navigationService.modal().open(vm.modalOptions({
                windowClass: vm.NBS_MODAL_CLASS_FORM_MD,
                templateUrl: 'execProcessRunTemplate',
                scope: $scope
            }));
            vm.modalInstance.result.then(function () {
                vm.submitAction()
                    .then(function (saveResult) {
                        if (vm.execProcessRequest.IsBatch) {
                            contextService.addBatchRequest({
                                requestId: vm.execProcessRequest.BatchRequestId,
                                requestName: vm.execProcessRequest.BatchRequestName,
                                requestStatus: 'inserted'
                            });
                        }
                    })
                    .catch(function (error) {
                        vm.setErrorMsg(error);
                    });
            }, function () {
                //dismiss
                jobService.rejectChanges();
            });
        };

        vm.scheduledTaskAction = function () {
            if (!execProcessSelected) {
                //navigationService.customMessageModal(vm.translate(''));
            } else {
                var services = { navigationService: navigationService, utilService: utilService, cmsService: cmsService };
                var wzNbsManager = new NbsWizardManager($q, vm.translate, services, NbsGuidManager);
                var config = new wzNbsManager.WzConfig_AddScheduledTask('execprocessrequest');
                config.defaultData.Code = execProcessSelected.ExecProcessCode;
                config.defaultData.Name = execProcessSelected.ExecProcessName;
                wzNbsManager.setWzConfig(config);

                wzNbsManager.open()
                    .then(function (result) {
                        var scheduledTaskResult = result;
                        var execProcessRequestDto = jobService.dataContext.execProcessRequestDtos.add(execProcessSelected);
                        execProcessRequestDto.ExecProcessRequestName = scheduledTaskResult.Name;
                        var dateForUtcTime = moment(Nembus.Common.Library.converter.timespan.jsonToDate(scheduledTaskResult.Time)).add(-moment().utcOffset(), 'minutes').toDate();
                        execProcessRequestDto.TaskTime = Nembus.Common.Library.converter.timespan.dateToJson(dateForUtcTime); //SET UTC TIME //scheduledTaskResult.Time; 
                        execProcessRequestDto.TaskCronExpression = scheduledTaskResult.CronExpression;
                        execProcessRequestDto.IsScheduledTask = true;
                        execProcessRequestDto.VirtualCompanyId = virtualCompanyId;

                        vm.submitAction()
                            .then(function (result) {
                                // success
                            }).catch(function (error) {
                                // error
                                vm.setErrorMsg(error);
                                jobService.rejectChanges();
                            });
                    });
            }
        };

        function getExecProcesses(forceReload) {
            var filter = {};
            var defer = $q.defer();

            jobService.getExecProcesses(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    }

    execProcessesList.$inject = injectParams;
    execProcessesList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('execProcessesList', execProcessesList);
});