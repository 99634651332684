﻿'use strict';
define([], function () {

    var injectParams = ['$q', '$timeout', 'contextService', 'hrbucketService', 'rbacService', 'drbacService', 'foundationService', 'productionService', 'vhreventService', 'utilService'];
    var nbsVhreventBarComponent = function ($q, $timeout, contextService, hrbucketService, rbacService, drbacService, foundationService, productionService, vhreventService, utilService) {
        var vm = this;
        var privateUIData = null;
        var pAppUsers = [];
        var pDrbacUserGroups = [];
        var defaultKeys;
        var privateDatacontext = null;
        var allDataReady = [];
        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
        var bucketClassificationEnum = null;
        var nbsUiOptionParser = null;

        vm.commonGridBarDataContext = null;
        vm.privateComponentsStatus = {};
        vm.nbsDataPrivate = {};
        vm.privateFormSummary = {
            dataContext: {},
            isVisible: false
        };

        vm.$onInit = function () {

        };

        vm.$onChanges = function (changes) {
            if (changes.viewElementCode && changes.viewElementCode.currentValue) {
                allDataReady.push('viewElementCode');
            }
            if (changes.nbsDataContext && changes.nbsDataContext.currentValue) {
                allDataReady.push('nbsDataContext');
                privateDatacontext = changes.nbsDataContext.currentValue;
                bucketClassificationEnum = Nembus.Common.Library.util.parseEnum(hrbucketService.dataContext.enumTypes.BucketClassification, null, vm.uiContext.translate).obj.normal.name_value;

                vm.privateFormSummary = privateDatacontext.formSummary;
            }
            if (allDataReady.length === 2) {
                nbsUiOptionParser = new NbsUiOptionParser(vm.uiContext.translate);

                defaultKeys = privateDatacontext.defaultKeys;

                // Start of execution workflow of load data bar components 
                initViewTitle();
            }
        };

        function initViewTitle() {
            var cols = [];
            if (defaultKeys.columnsKey === 'ims') {
                cols = ['VhrEventId', 'ActivityCode', 'VhrEventStartDate', 'VhrEventStartTime', 'VirtualHumanResourceCode', 'VirtualHumanResourceName', 'VhrEventName', 'WorkCenterName', 'VhrEventLifeStatusName', 'ActivityFrequencyCode', 'VhrEventCreationTypeFlag', 'IsExecuted', 'IsApproved', 'IsCustomerApproval', 'VhrEventLabelName'];
            } else {
                cols = ['VhrEventId', 'ActivityCode', 'VhrEventStartDate', 'VhrEventStartTime', 'VirtualHumanResourceName', 'VhrEventName', 'WorkCenterName', 'VhrEventLifeStatusName', 'ActivityFrequencyCode', 'VhrEventCreationTypeFlag', 'IsExecuted', 'IsApproved', 'IsCustomerApproval', 'VhrEventLabelName'];
            }
            var defaultStartData = new NbsUiData();
            defaultStartData.setFilter('vhreventstartdatetimeline', Nembus.Common.Library.component.bar.datarange.getTimelineDatarange('d+0'));
            defaultStartData.setColumns(cols);
            defaultStartData.setFilterList(getFilterList());

            vm.initViewTitle = defaultStartData.startData;
        }

        //#region hook from components       
        vm.onViewTitleSelectionEvent = function (result) {
            privateUIData = JSON.parse(JSON.stringify(result.uiData));
            setComponents(privateUIData);

            setBarDatasources()
                .then(function () {
                    barOnChanges(privateUIData);
                });
        };
        vm.onUiChanges = function (result) {
            privateUIData.columns = result.uiData.columns;
            privateUIData.grouping = result.uiData.grouping;
            privateUIData.sorting = result.uiData.sorting;
            privateUIData.filters.freeText = result.uiData.filters.freeText;

            refreshViewTitleDataContext(privateUIData);

            vm.onGridUiChangesEvent({ result: privateUIData });
        };
        vm.onFilterChanges = function (result) {
            privateUIData.filterList = result.filters.filterList;

            refreshViewTitleDataContext(privateUIData);
            setStatusComponents(privateUIData.filterList);

            setBarDatasources()
                .then(function () {
                    barOnChanges(privateUIData);
                });
        };
        vm.onDdMsVhrEventGroups = function (options) {
            privateUIData.filters.vhreventgroups = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdMsVhrEventLabels = function (options) {
            privateUIData.filters.vhreventlabels = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdMsVhrEventTypes = function (options) {
            privateUIData.filters.vhreventtypes = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdMsWorkCenters = function (options) {
            privateUIData.filters.workcenters = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdVhrEventLifeStatus = function (options) {
            privateUIData.filters.vhreventlifestatuses = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onBooleanVhrEventIsExecuted = function (value) {
            privateUIData.filters.vhreventexecuted = value;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onBooleanVhrEventIsApproved = function (value) {
            privateUIData.filters.vhreventapproved = value;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onStartDateDatarangeSelectionEvent = function (timeline) {
            privateUIData.filters.vhreventstartdatetimeline = timeline;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdMsVhrEquipments = function (options) {
            privateUIData.filters.vhrequipments = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdMsAssignee = function (options) {
            privateUIData.filters.assignee = options; //Nembus.Common.Library.component.ddsearchms.parseAssigneeUIDataFilter(options);
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdMsExecutor = function (options) {
            privateUIData.filters.executor = options; //Nembus.Common.Library.component.ddsearchms.parseAssigneeUIDataFilter(options);
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdMsApproval = function (options) {
            privateUIData.filters.approval = options; //Nembus.Common.Library.component.ddsearchms.parseAssigneeUIDataFilter(options);
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdMsCustomerApproval = function (options) {
            privateUIData.filters.customerapproval = options; //Nembus.Common.Library.component.ddsearchms.parseAssigneeUIDataFilter(options);
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdMsPlants = function (options) {
            privateUIData.filters.plants = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onBooleanIsPlantAssociated = function (value) {
            privateUIData.filters.isplantassociated = value;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        //#endregion

        // refresh current preference uiData
        function refreshViewTitleDataContext(uiDataParam) {
            vm.viewTitleDataContext = Nembus.Common.Library.component.getDataContextViewTitle(uiDataParam);
        }

        function setBarDatasources() {
            var defer = $q.defer();
            var funcsPromises = [];
            if (vm.privateComponentsStatus.vhreventtypes.isVisible) {
                funcsPromises.push(getVhrEventTypes(false));
            }
            if (vm.privateComponentsStatus.workcenters.isVisible) {
                funcsPromises.push(getVirtualWorkCenters(false));
            }
            if (vm.privateComponentsStatus.vhreventgroups.isVisible) {
                funcsPromises.push(getVhrEventGroups(false));
            }
            if (vm.privateComponentsStatus.vhreventlabels.isVisible) {
                funcsPromises.push(getVhrEventLabels(false));
            }
            if (vm.privateComponentsStatus.vhreventlifestatuses.isVisible) {
                funcsPromises.push(getLifeStatuses());
            }
            if (vm.privateComponentsStatus.vhrequipments.isVisible) {
                funcsPromises.push(getVirtualEquipments(false));
            }
            if (vm.privateComponentsStatus.plants.isVisible) {
                funcsPromises.push(getHrBuckets(false));
            }

            if (vm.viewElementCode === 'grid' || vm.viewElementCode === 'stream' || vm.viewElementCode === 'board') {
                if (vm.privateComponentsStatus.assignee.isVisible
                    || vm.privateComponentsStatus.executor.isVisible
                    || vm.privateComponentsStatus.approval.isVisible
                    || vm.privateComponentsStatus.customerapproval.isVisible
                ) {
                    funcsPromises.push(getCurrentContextAppUsers(false));
                    funcsPromises.push(getDrbacUserGroups(false, virtualCompanyId));
                }
            }

            $q.all(funcsPromises)
                .then(function () {
                    vm.nbsDataPrivate.assignee = nbsUiOptionParser.getAssignment(pAppUsers, pDrbacUserGroups, null);
                    vm.nbsDataPrivate.executor = nbsUiOptionParser.getAssignment(pAppUsers, pDrbacUserGroups, null);
                    vm.nbsDataPrivate.approval = nbsUiOptionParser.getAssignment(pAppUsers, pDrbacUserGroups, null);
                    vm.nbsDataPrivate.customerapproval = nbsUiOptionParser.getAssignment(pAppUsers, pDrbacUserGroups, null);
                    defer.resolve(true);
                });

            return defer.promise;
        }

        function getFilterList() {
            var uiDataFilters = [];
            uiDataFilters.push(new NbsUiDataFilter({ code: 'vhreventtypes', name: 'VhrEvent.VhrEventType' }));
            uiDataFilters.push(new NbsUiDataFilter({ code: 'workcenters', name: 'VhrEvent.WorkCenterName' }));
            uiDataFilters.push(new NbsUiDataFilter({ code: 'vhreventgroups', name: 'VhrEvent.VhrEventGroup' }));
            uiDataFilters.push(new NbsUiDataFilter({ code: 'vhreventlabels', name: 'VhrEvent.VhrEventLabels' }));
            uiDataFilters.push(new NbsUiDataFilter({ code: 'plants', name: 'Plants' }));
            uiDataFilters.push(new NbsUiDataFilter({ code: 'isplantassociated', name: 'PlantAssociation' }));

            if (vm.viewElementCode !== 'boardapproval') {
                uiDataFilters.push(new NbsUiDataFilter({ code: 'vhreventlifestatuses', name: 'VhrEvent.VhrEventLifeStatusId', visible: true }));
            }

            uiDataFilters.push(new NbsUiDataFilter({ code: 'vhreventexecuted', name: 'VhrEvent.IsExecuted' }));
            uiDataFilters.push(new NbsUiDataFilter({ code: 'vhrequipments', name: 'Resource' }));

            if (vm.viewElementCode !== 'boardapproval') {
                uiDataFilters.push(new NbsUiDataFilter({ code: 'vhreventapproved', name: 'VhrEvent.IsApproved' }));
            }

            if (vm.viewElementCode !== 'boardduedate') {
                uiDataFilters.push(new NbsUiDataFilter({ code: 'vhreventstartdatetimeline', name: 'VhrEvent.VhrEventStartDate', visible: true, canHide: false }));
            }

            uiDataFilters.push(new NbsUiDataFilter({ code: 'assignee', name: 'VhrEvent.UserWorkcenter' }));
            uiDataFilters.push(new NbsUiDataFilter({ code: 'executor', name: 'VhrEvent.UserExecutor' }));
            uiDataFilters.push(new NbsUiDataFilter({ code: 'approval', name: 'VhrEvent.UserApproval' }));
            uiDataFilters.push(new NbsUiDataFilter({ code: 'customerapproval', name: 'VhrEvent.UserCustomerApproval' }));

            return uiDataFilters;
        }

        function getHrBuckets(forceReload) {
            var filter = {
                AppStoreBw: null,
                //ResourceTypeId: resourceTypeId,
                BucketClassificationId: bucketClassificationEnum.Plant
            };
            var defer = $q.defer();

            hrbucketService.getHrBuckets(filter, forceReload)
                .then(function (data) {
                    vm.nbsDataPrivate.plants = nbsUiOptionParser.getUiOptionsByKey(data.results, 'Vhr');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getCurrentContextAppUsers(forceReload) {
            var defer = $q.defer();
            var filter = {};

            rbacService.getCurrentContextAppUsers(filter, forceReload)
                .then(function (data) {
                    pAppUsers = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getDrbacUserGroups(forceReload, virtualCompanyId) {
            var defer = $q.defer();
            var filter = {
                VirtualCompanyId: virtualCompanyId
            };

            drbacService.getDrbacUserGroups(filter, forceReload)
                .then(function (data) {
                    pDrbacUserGroups = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVirtualEquipments(forceReload, appStoreBw) {
            var filter = { AppStoreBw: appStoreBw };
            var defer = $q.defer();

            foundationService.getVirtualEquipments(filter, forceReload)
                .then(function (data) {
                    vm.nbsDataPrivate.vhrequipments = nbsUiOptionParser.getUiOptionsByKey(data.results, 'Vhr');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVirtualWorkCenters(forceReload) {
            var filter = {};
            var defer = $q.defer();

            productionService.getVirtualWorkCenters(filter, forceReload)
                .then(function (data) {
                    vm.nbsDataPrivate.workcenters = nbsUiOptionParser.getUiOptionsByKey(data.results, 'WorkCenter');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrEventTypes(forceReload) {
            var filter = { AppStoreBw: privateDatacontext.appStoreBw };
            var defer = $q.defer();

            vhreventService.getVhrEventTypes(filter, forceReload)
                .then(function (data) {
                    vm.nbsDataPrivate.vhreventtypes = nbsUiOptionParser.getUiOptionsByKey(data.results, 'VhrEventType');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getLifeStatuses() {
            var defer = $q.defer();
            var vhrEventLifeStatusEnumParsed = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventLifeStatus, 'VhrEvent', vm.uiContext.translate);
            var lifeStatuses = vhrEventLifeStatusEnumParsed.list.translated;
            vm.nbsDataPrivate.vhreventlifestatuses = nbsUiOptionParser.getUiOptionsByEnum(lifeStatuses);
            defer.resolve(true);
            return defer.promise;
        }

        function getVhrEventGroups(forceReload) {
            var filter = { AppStoreBw: privateDatacontext.appStoreBw };
            var defer = $q.defer();

            vhreventService.getVhrEventGroups(filter, forceReload)
                .then(function (data) {
                    vm.nbsDataPrivate.vhreventgroups = nbsUiOptionParser.getUiOptionsByKey(data.results, 'VhrEventGroup');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrEventLabels(forceReload) {
            var filter = { AppStoreBw: privateDatacontext.appStoreBw };
            var defer = $q.defer();

            vhreventService.getVhrEventLabels(filter, forceReload)
                .then(function (data) {
                    vm.nbsDataPrivate.vhreventlabels = nbsUiOptionParser.getUiOptionsByKey(data.results, 'VhrEventLabel');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function setComponents(uiDataParam) {
            setCommonGridBarDataContext(uiDataParam);
            setSelectionComponents(uiDataParam);
            setStatusComponents(uiDataParam.filterList);
            vm.onGridUiChangesEvent({ result: privateUIData });
        }

        function setCommonGridBarDataContext(uiDataParam) {
            vm.commonGridBarDataContext = Nembus.Common.Library.component.getDataContextCommonGridBar(uiDataParam);
            vm.commonBarDatasource = privateDatacontext.commonBarDatasource
        }

        function setSelectionComponents(uiDataParam) {
            vm.nbsDataPrivate.vhreventexecutedchecked = uiDataParam.filters.vhreventexecuted;
            vm.nbsDataPrivate.vhreventapprovedchecked = uiDataParam.filters.vhreventapproved;
            vm.nbsDataPrivate.vhreventstartdatetimelineselected = uiDataParam.filters.vhreventstartdatetimeline;
            vm.nbsDataPrivate.plantsselected = uiDataParam.filters.plants ? uiDataParam.filters.plants : [];
            vm.nbsDataPrivate.isplantassociatedchecked = uiDataParam.filters.isplantassociated;

            vm.nbsDataPrivate.vhreventgroupsselected = uiDataParam.filters.vhreventgroups ? uiDataParam.filters.vhreventgroups : [];
            vm.nbsDataPrivate.vhreventlabelsselected = uiDataParam.filters.vhreventlabels ? uiDataParam.filters.vhreventlabels : [];
            vm.nbsDataPrivate.vhreventtypesselected = uiDataParam.filters.vhreventtypes ? uiDataParam.filters.vhreventtypes : [];
            vm.nbsDataPrivate.vhreventlifestatusesselected = uiDataParam.filters.vhreventlifestatuses ? uiDataParam.filters.vhreventlifestatuses : [];
            vm.nbsDataPrivate.workcentersselected = uiDataParam.filters.workcenters ? uiDataParam.filters.workcenters : [];
            vm.nbsDataPrivate.vhrequipmentsselected = uiDataParam.filters.vhrequipments ? uiDataParam.filters.vhrequipments : [];
            vm.nbsDataPrivate.assigneeselected = uiDataParam.filters.assignee;
            vm.nbsDataPrivate.executorselected = uiDataParam.filters.executor;
            vm.nbsDataPrivate.approvalselected = uiDataParam.filters.approval;
            vm.nbsDataPrivate.customerapprovalselected = uiDataParam.filters.customerapproval;
        }

        function setStatusComponents(filterList) {
            vm.privateComponentsStatus.vhreventgroups = Nembus.Common.Library.component.getStatus('vhreventgroups', filterList);
            vm.privateComponentsStatus.vhreventlabels = Nembus.Common.Library.component.getStatus('vhreventlabels', filterList);
            vm.privateComponentsStatus.vhreventtypes = Nembus.Common.Library.component.getStatus('vhreventtypes', filterList);
            vm.privateComponentsStatus.workcenters = Nembus.Common.Library.component.getStatus('workcenters', filterList);
            vm.privateComponentsStatus.vhreventlifestatuses = Nembus.Common.Library.component.getStatus('vhreventlifestatuses', filterList);
            vm.privateComponentsStatus.vhreventexecuted = Nembus.Common.Library.component.getStatus('vhreventexecuted', filterList);
            vm.privateComponentsStatus.vhreventapproved = Nembus.Common.Library.component.getStatus('vhreventapproved', filterList);
            vm.privateComponentsStatus.vhreventstartdatetimeline = Nembus.Common.Library.component.getStatus('vhreventstartdatetimeline', filterList);
            vm.privateComponentsStatus.vhrequipments = Nembus.Common.Library.component.getStatus('vhrequipments', filterList);
            vm.privateComponentsStatus.assignee = Nembus.Common.Library.component.getStatus('assignee', filterList);
            vm.privateComponentsStatus.executor = Nembus.Common.Library.component.getStatus('executor', filterList);
            vm.privateComponentsStatus.approval = Nembus.Common.Library.component.getStatus('approval', filterList);
            vm.privateComponentsStatus.customerapproval = Nembus.Common.Library.component.getStatus('customerapproval', filterList);
            vm.privateComponentsStatus.plants = Nembus.Common.Library.component.getStatus('plants', filterList);
            vm.privateComponentsStatus.isplantassociated = Nembus.Common.Library.component.getStatus('isplantassociated', filterList);
        }

        function barOnChanges(uiDataParam) {
            var selectedVhrEventGroupsId = [];
            var selectedVhrEventLabelsId = [];
            var selectedVhrEventTypesId = [];
            var selectedVhrEventLifeStatusIds = [];
            var selectedWorkCenterIds = [];
            var selectedVirtualEquipmentIds = [];
            var selectedAssignedAppUserId = [];
            var selectedAssignedDrbacUserGroupId = [];
            var selectedExecutorAppUserId = [];
            var selectedExecutorDrbacUserGroupId = [];
            var selectedApprovalAppUserId = [];
            var selectedApprovalDrbacUserGroupId = [];
            var selectedCustomerApprovalAppUserId = [];
            var selectedCustomerApprovalDrbacUserGroupId = [];
            var isExecuted = null;
            var isApproved = null;
            var isNotAssigned = null;
            var withoutApprovalId = null;
            var withoutCustomerApprovalId = null;
            var withoutExecutorId = null;
            var selectedPlantsId = [];
            var isPlantAssociated = null;

            var vhreventtimelinefrom = Nembus.Common.Library.component.bar.datarange.getDataRangeFromUiDataParam(uiDataParam.filters.vhreventstartdatetimeline).dateFrom;
            var vhreventtimelineto = Nembus.Common.Library.component.bar.datarange.getDataRangeFromUiDataParam(uiDataParam.filters.vhreventstartdatetimeline).dateTo;
            //var vhreventtimelinefrom = uiDataParam.filters.vhreventstartdatetimeline.dateFrom;
            //var vhreventtimelineto = uiDataParam.filters.vhreventstartdatetimeline.dateTo;
            var expressionTypeObj = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.ExpressionType, null, vm.uiContext.translate).obj.normal.name_value;

            if (vm.privateComponentsStatus.vhrequipments.isVisible) {
                selectedVirtualEquipmentIds = Lazy(uiDataParam.filters.vhrequipments).pluck('optionValue').toArray();
            }
            if (vm.privateComponentsStatus.workcenters.isVisible) {
                selectedWorkCenterIds = Lazy(uiDataParam.filters.workcenters).pluck('optionValue').toArray();
            }
            if (vm.privateComponentsStatus.vhreventgroups.isVisible) {
                selectedVhrEventGroupsId = Lazy(uiDataParam.filters.vhreventgroups).pluck('optionValue').toArray();
            }
            if (vm.privateComponentsStatus.vhreventlabels.isVisible) {
                selectedVhrEventLabelsId = Lazy(uiDataParam.filters.vhreventlabels).pluck('optionValue').toArray();
            }
            if (vm.privateComponentsStatus.vhreventtypes.isVisible) {
                selectedVhrEventTypesId = Lazy(uiDataParam.filters.vhreventtypes).pluck('optionValue').toArray();
            }
            if (vm.privateComponentsStatus.vhreventlifestatuses.isVisible) {
                selectedVhrEventLifeStatusIds = Lazy(uiDataParam.filters.vhreventlifestatuses).pluck('optionValue').toArray();
            }

            if (vm.privateComponentsStatus.vhreventexecuted.isVisible) {
                isExecuted = uiDataParam.filters.vhreventexecuted;
            }

            if (vm.privateComponentsStatus.vhreventapproved.isVisible) {
                isApproved = uiDataParam.filters.vhreventapproved;
            }

            if (vm.privateComponentsStatus.plants.isVisible) {
                selectedPlantsId = Lazy(uiDataParam.filters.plants).pluck('optionValue').toArray();
            }

            if (vm.privateComponentsStatus.isplantassociated.isVisible) {
                isPlantAssociated = uiDataParam.filters.isplantassociated;
            }

            if (vm.privateComponentsStatus.assignee.isVisible) {
                if (uiDataParam.filters.assignee) {
                    if (uiDataParam.filters.assignee.isNotAssigned) {
                        isNotAssigned = true;
                    } else {
                        selectedAssignedAppUserId = Lazy(uiDataParam.filters.assignee.appusers).pluck('optionValue').toArray();
                        selectedAssignedDrbacUserGroupId = Lazy(uiDataParam.filters.assignee.drbacusergroups).pluck('optionValue').toArray();
                    }
                }
            }

            if (vm.privateComponentsStatus.executor.isVisible) {
                if (uiDataParam.filters.executor) {
                    if (uiDataParam.filters.executor.isNotAssigned) {
                        withoutExecutorId = true;
                    } else {
                        selectedExecutorAppUserId = Lazy(uiDataParam.filters.executor.appusers).pluck('optionValue').toArray();
                        selectedExecutorDrbacUserGroupId = Lazy(uiDataParam.filters.executor.drbacusergroups).pluck('optionValue').toArray();
                    }
                }
            }

            if (vm.privateComponentsStatus.approval.isVisible) {
                if (uiDataParam.filters.approval) {
                    if (uiDataParam.filters.approval.isNotAssigned) {
                        withoutApprovalId = true;
                    } else {
                        selectedApprovalAppUserId = Lazy(uiDataParam.filters.approval.appusers).pluck('optionValue').toArray();
                        selectedApprovalDrbacUserGroupId = Lazy(uiDataParam.filters.approval.drbacusergroups).pluck('optionValue').toArray();
                    }
                }
            }

            if (vm.privateComponentsStatus.customerapproval.isVisible) {
                if (uiDataParam.filters.customerapproval) {
                    if (uiDataParam.filters.customerapproval.isNotAssigned) {
                        withoutCustomerApprovalId = true;
                    } else {
                        selectedCustomerApprovalAppUserId = Lazy(uiDataParam.filters.customerapproval.appusers).pluck('optionValue').toArray();
                        selectedCustomerApprovalDrbacUserGroupId = Lazy(uiDataParam.filters.customerapproval.drbacusergroups).pluck('optionValue').toArray();
                    }
                }
            }

            var virtualHumanResourcesId = [];
            var haveToExpandVhrsWithAssetTree = true;
            if (Lazy(selectedVirtualEquipmentIds).some()) {
                haveToExpandVhrsWithAssetTree = false;
                virtualHumanResourcesId = selectedVirtualEquipmentIds;
            } else {
                if (Lazy(selectedPlantsId).some()) {
                    virtualHumanResourcesId = selectedPlantsId;
                }
            }

            $timeout(() => {
                vm.onBarChanges({
                    result: {
                        vhrEventFilter: {
                            IsApproved: isApproved,
                            IsExecuted: isExecuted,
                            WorkCentersId: selectedWorkCenterIds,
                            VhrEventGroupsId: selectedVhrEventGroupsId,
                            VhrEventLabelsId: selectedVhrEventLabelsId,
                            VhrEventTypesId: selectedVhrEventTypesId,
                            VhrEventLifeStatusIds: selectedVhrEventLifeStatusIds,
                            VhrEventLifeStatusExpression: expressionTypeObj.Equal,
                            StartDate1: vhreventtimelinefrom,
                            StartDate1Expression: expressionTypeObj.GreaterThanOrEqual,
                            StartDate2: vhreventtimelineto,
                            StartDate2Expression: expressionTypeObj.LessThanOrEqual,
                            AppUserAssignedIds: selectedAssignedAppUserId,
                            UserGroupAssignedIds: selectedAssignedDrbacUserGroupId,
                            IsNotAssigned: isNotAssigned,

                            UserExecutorIds: selectedExecutorAppUserId,
                            UserGroupExecutorIds: selectedExecutorDrbacUserGroupId,
                            WithoutExecutorId: withoutExecutorId,

                            UserApprovalIds: selectedApprovalAppUserId,
                            UserGroupApprovalIds: selectedApprovalDrbacUserGroupId,
                            WithoutApprovalId: withoutApprovalId,

                            UserCustomerApprovalIds: selectedCustomerApprovalAppUserId,
                            UserGroupCustomerApprovalIds: selectedCustomerApprovalDrbacUserGroupId,
                            WithoutCustomerApprovalId: withoutCustomerApprovalId,

                            IsPlantAssociated: isPlantAssociated,
                            HaveToExpandVhrsWithAssetTree: haveToExpandVhrsWithAssetTree,
                            VirtualHumanResourcesId: virtualHumanResourcesId,
                        }
                    }
                });
            });
        }
    };

    nbsVhreventBarComponent.$inject = injectParams;
    angular.module('nbs.rds.module').component('nbsVhreventBar', {
        templateUrl: '/rds/vhrevents/components/nbsVhreventBarComponent/_?c=' + window.codeCache(),
        controller: nbsVhreventBarComponent,
        controllerAs: 'vm',
        bindings: {
            onBarChanges: '&',
            onGridUiChangesEvent: '&',

            viewElementCode: "@",
            nbsDataContext: "<", //e.g. {vhreventtypes:[NbsUiOption,NbsUiOption],vhreventgroups:[],status etc..}
            uiContext: "<"
        }
    });
});

