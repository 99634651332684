﻿'use strict';
define([], function () {

    var injectParams = ['$q', '$timeout', 'foundationService', 'utilService'];
    var nbsBatchRequestBarComponent = function ($q, $timeout, foundationService, utilService) {
        var vm = this;
        var privateDataContext = null;
        var privateUIData = null;
        var defaultKeys;
        var pBatchRequestStatusEnumParsed = null;
        var nbsUiOptionParser = null;

        vm.privateComponentsStatus = {};
        vm.nbsDataPrivate = {};

        vm.$onInit = function () {
        };

        vm.$onChanges = function (changes) {
            if (changes.nbsDataContext && changes.nbsDataContext.currentValue) {
                nbsUiOptionParser = new NbsUiOptionParser(vm.uiContext.translate);

                privateDataContext = changes.nbsDataContext.currentValue;
                pBatchRequestStatusEnumParsed = Nembus.Common.Library.util.parseEnum(foundationService.dataContext.enumTypes.BatchRequestStatus, 'BatchRequest', vm.uiContext.translate);
                // set default visible columns list in grid
                defaultKeys = privateDataContext.defaultKeys;

                // Start of execution workflow of load data bar components 
                initViewTitle();
            }
        };

        function initViewTitle() {
            var cols = ['BatchRequestId', 'BatchRequestName', 'BatchRequestExtra', 'BatchRequestStatusName', 'CreationDate'];
            var defaultStartData = new NbsUiData();
            defaultStartData.setFilter('creationdatetimeline', Nembus.Common.Library.component.bar.datarange.getTimelineDatarange('d+7'));
            defaultStartData.setColumns(cols);
            defaultStartData.setFilterList(getFilterList());

            if (privateDataContext.uiMode !== 'queue') {
                defaultStartData.setSortingField('CreationDate', false);
            }

            vm.initViewTitle = defaultStartData.startData;
        }

        //#region hook from components
        vm.onViewTitleSelectionEvent = function (result) {
            privateUIData = JSON.parse(JSON.stringify(result.uiData));
            setComponents(privateUIData);

            setBarDatasources()
                .then(function () {
                    barOnChanges(privateUIData);
                });
        };
        vm.onUiChanges = function (result) {
            privateUIData.columns = result.uiData.columns;
            privateUIData.grouping = result.uiData.grouping;
            privateUIData.sorting = result.uiData.sorting;
            privateUIData.filters.freeText = result.uiData.filters.freeText;

            refreshViewTitleDataContext(privateUIData);

            vm.onGridUiChangesEvent({ result: privateUIData });
        };
        vm.onFilterChanges = function (result) {
            privateUIData.filterList = result.filters.filterList;

            refreshViewTitleDataContext(privateUIData);
            setStatusComponents(privateUIData.filterList);

            setBarDatasources()
                .then(function () {
                    barOnChanges(privateUIData);
                });
        };
        vm.onDdBatchRequestStatuses = function (options) {
            privateUIData.filters.batchrequeststatuses = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdBatchRequestModes = function (options) {
            privateUIData.filters.batchrequestmodes = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdVirtualCompanies = function (options) {
            privateUIData.filters.virtualcompanies = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onCreationDateDatarangeSelectionEvent = function (timeline) {
            privateUIData.filters.creationdatetimeline = timeline;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        //#endregion

        // refresh current preference uiData
        function refreshViewTitleDataContext(uiDataParam) {
            vm.viewTitleDataContext = Nembus.Common.Library.component.getDataContextViewTitle(uiDataParam);
        }

        function setBarDatasources() {
            var defer = $q.defer();
            var funcsPromises = [];

            if (privateDataContext.uiMode !== 'queue') {
                if (vm.privateComponentsStatus.batchrequeststatuses.isVisible) {
                    funcsPromises.push(getBatchRequestStatuses(false));
                }

                if (vm.privateComponentsStatus.virtualcompanies.isVisible) {
                    funcsPromises.push(getVirtualCompanies(false));
                }
            } else {
                if (vm.privateComponentsStatus.batchrequestmodes.isVisible) {
                    funcsPromises.push(getBatchRequestModes(false));
                }
            }

            $q.all(funcsPromises)
                .then(function () {
                    defer.resolve(true);
                });

            return defer.promise;
        }

        function getFilterList() {
            var uiDataFilters = [];
            uiDataFilters.push(new NbsUiDataFilter({ code: 'creationdatetimeline', name: 'BatchRequest.CreationDate', canHide: false, visible: true }));

            if (privateDataContext.uiMode !== 'queue') {
                uiDataFilters.push(new NbsUiDataFilter({ code: 'virtualcompanies', name: 'VirtualCompany' }));
                uiDataFilters.push(new NbsUiDataFilter({ code: 'batchrequeststatuses', name: 'Status' }));
            } else {
                uiDataFilters.push(new NbsUiDataFilter({ code: 'batchrequestmodes', name: 'Status', canHide: false, visible: true }));
            }

            return uiDataFilters;
        }

        function getBatchRequestStatuses(forceReload) {
            var defer = $q.defer();
            var filteredStatuses = Lazy(pBatchRequestStatusEnumParsed.list.translated).reject({ value: 5 }).toArray();
            vm.nbsDataPrivate.batchrequeststatuses = nbsUiOptionParser.getUiOptionsByEnum(filteredStatuses);
            defer.resolve(true);
            return defer.promise;
        }

        function getBatchRequestModes(forceReload) {
            var defer = $q.defer();

            vm.nbsDataPrivate.batchrequestmodes = [
                new NbsUiOption({ optionValue: 'active', optionName: 'active', optionDisplayName: vm.uiContext.translate('Active2') }),
                new NbsUiOption({ optionValue: 'all', optionName: 'all', optionDisplayName: vm.uiContext.translate('All') })
            ];

            defer.resolve(true);
            return defer.promise;
        }

        function getVirtualCompanies(forceReload) {
            var filter = {};
            var defer = $q.defer();

            foundationService.getVirtualCompanies(filter, forceReload)
                .then(function (data) {
                    vm.nbsDataPrivate.virtualcompanies = nbsUiOptionParser.getUiOptionsByKey(data.results, 'VirtualCompany');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function setComponents(uiDataParam) {
            setCommonGridBarDataContext(uiDataParam);
            setSelectionComponents(uiDataParam);
            setStatusComponents(uiDataParam.filterList);
            vm.onGridUiChangesEvent({ result: privateUIData });
        }

        function setCommonGridBarDataContext(uiDataParam) {
            vm.commonGridBarDataContext = Nembus.Common.Library.component.getDataContextCommonGridBar(uiDataParam);
            vm.commonBarDatasource = privateDataContext.commonBarDatasource
        }

        function setSelectionComponents(uiDataParam) {
            vm.nbsDataPrivate.batchrequeststatusesselected = uiDataParam.filters.batchrequeststatuses ? uiDataParam.filters.batchrequeststatuses : [];
            vm.nbsDataPrivate.batchrequestmodesselected = uiDataParam.filters.batchrequestmodes ? uiDataParam.filters.batchrequestmodes : [{ optionValue: 'active' }];
            vm.nbsDataPrivate.virtualcompaniesselected = uiDataParam.filters.virtualcompanies ? uiDataParam.filters.virtualcompanies : [];
            vm.nbsDataPrivate.creationdatetimelineselected = uiDataParam.filters.creationdatetimeline;
        }

        function setStatusComponents(filterList) {
            vm.privateComponentsStatus.batchrequeststatuses = Nembus.Common.Library.component.getStatus('batchrequeststatuses', filterList);
            vm.privateComponentsStatus.batchrequestmodes = Nembus.Common.Library.component.getStatus('batchrequestmodes', filterList);
            vm.privateComponentsStatus.virtualcompanies = Nembus.Common.Library.component.getStatus('virtualcompanies', filterList);
            vm.privateComponentsStatus.creationdatetimeline = Nembus.Common.Library.component.getStatus('creationdatetimeline', filterList);
        }

        function barOnChanges(uiDataParam) {
            var expressionTypeObj = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.ExpressionType, null, vm.uiContext.translate).obj.normal.name_value;
            var creationdatetimelinefrom = Nembus.Common.Library.component.bar.datarange.getDataRangeFromUiDataParam(uiDataParam.filters.creationdatetimeline).dateFrom;
            var creationdatetimelineto = Nembus.Common.Library.component.bar.datarange.getDataRangeFromUiDataParam(uiDataParam.filters.creationdatetimeline).dateTo;

            if (privateDataContext.uiMode === 'queue') {
                var filter = {
                    CreationDate1: creationdatetimelinefrom,
                    CreationDate1Expression: expressionTypeObj.GreaterThanOrEqual,
                    CreationDate2: creationdatetimelineto,
                    CreationDate2Expression: expressionTypeObj.LessThanOrEqual,
                };

                var selectedMode = null;
                if (vm.privateComponentsStatus.batchrequestmodes.isVisible) {
                    if (uiDataParam.filters.batchrequestmodes) {
                        selectedMode = Lazy(uiDataParam.filters.batchrequestmodes).pluck('optionValue').first();
                    } else {
                        selectedMode = Lazy(vm.nbsDataPrivate.batchrequestmodesselected).pluck('optionValue').first();
                    }
                }

                if (selectedMode === 'active') {
                    filter.BatchRequestStatusIds = [
                        pBatchRequestStatusEnumParsed.obj.normal.name_value.InProgress,
                        pBatchRequestStatusEnumParsed.obj.normal.name_value.Inserted,
                        pBatchRequestStatusEnumParsed.obj.normal.name_value.Paused
                    ];
                } else {
                    filter.BatchRequestStatusIds = []
                }

                $timeout(() => {
                    vm.onBarChanges({
                        result: {
                            batchRequestFilter: filter
                        }
                    });
                });
            } else {
                var selectedStatusId = vm.privateComponentsStatus.batchrequeststatuses.isVisible
                    ? Lazy(uiDataParam.filters.batchrequeststatuses).pluck('optionValue').first()
                    : null;

                var selectedVirtualCompanyId = vm.privateComponentsStatus.virtualcompanies.isVisible
                    ? Lazy(uiDataParam.filters.virtualcompanies).pluck('optionValue').first()
                    : null;

                $timeout(() => {
                    vm.onBarChanges({
                        result: {
                            batchRequestFilter: {
                                BatchRequestStatus: selectedStatusId,
                                VirtualCompanyId: selectedVirtualCompanyId,
                                CreationDate1: creationdatetimelinefrom,
                                CreationDate1Expression: expressionTypeObj.GreaterThanOrEqual,
                                CreationDate2: creationdatetimelineto,
                                CreationDate2Expression: expressionTypeObj.LessThanOrEqual,
                            }
                        }
                    });
                });
            }


        }
        //#endregion 
    };


    nbsBatchRequestBarComponent.$inject = injectParams;
    angular.module('nbs.rds.module').component('nbsBatchRequestBar', {
        templateUrl: '/rds/batchrequests/components/nbsBatchRequestBarComponent/_?c=' + window.codeCache(),
        controller: nbsBatchRequestBarComponent,
        controllerAs: 'vm',
        bindings: {
            onBarChanges: '&',
            onGridUiChangesEvent: '&',

            nbsDataContext: "<",
            uiContext: "<"
        }
    });
});

