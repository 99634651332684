﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'drbacService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var drbacProcessConfigDetail = function ($q, $scope, $timeout, $injector, drbacService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, drbacService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, drbacService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var drbacProcessConfigId = navigationService.stateParams().drbacProcessConfigId;
        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;

        if (drbacProcessConfigId) {
            vm.setIsViewing();

            loadData(true);
        }
        else {
            // DrbacProcess cannot be added, can be only copied by another one
        }

        function loadData(forceReload) {
            var funcsPromises = []
            funcsPromises.push(getDrbacProcessConfig(forceReload, drbacProcessConfigId, virtualCompanyId));

            $q.all(funcsPromises)
                .then(function () {
                    // nothing to load                                 
                });
        }

        function getDrbacProcessConfig(forceReload, drbacProcessConfigId, virtualCompanyId) {
            var filter = {
                DrbacProcessConfigId: drbacProcessConfigId,
                VirtualCompanyId: virtualCompanyId,
            };
            var defer = $q.defer();

            drbacService.getDrbacProcessConfig(filter, forceReload)
                .then(function (data) {
                    vm.drbacProcessConfig = data.results[0];

                    vm.moduleTargetName = '';
                    var moduleTarget = Lazy(contextService.virtualContext.ModuleTargets).findWhere({ ModuleTargetId: vm.drbacProcessConfig.ModuleTargetId });
                    if (moduleTarget) {
                        vm.drbacProcessConfig.ModuleTargetNameNotInContext = moduleTarget.ModuleTargetName;
                    }
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    drbacProcessConfigDetail.$inject = injectParams;
    drbacProcessConfigDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rbac.module').controller('drbacProcessConfigDetail', drbacProcessConfigDetail);
});