﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'hrbucketService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var hierarchyDetail = function ($q, $scope, $timeout, $injector, hrbucketService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, hrbucketService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, hrbucketService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var hrHierarchyId = navigationService.stateParams().hrHierarchyId;
        var magicBucketActionEnumParsed = Nembus.Common.Library.util.parseEnum(hrbucketService.dataContext.enumTypes.MagicBucketAction, null, vm.translate);

        var bucketClassificationEnumParsed = Nembus.Common.Library.util.parseEnum(hrbucketService.dataContext.enumTypes.BucketClassification, 'HrBucket', vm.translate);
        vm.bucketClassifications = Lazy(bucketClassificationEnumParsed.list.translated).reject({ value: 0 }).toArray();

        var canDeleteHrBucket = true;

        var btnNewIsDisabled = true;
        var btnDeleteIsDisabled = true;
        var tabDetailIsSelected = true;
        var tabHierarchyIsSelected = true;

        ribbon.setRibbonStatusButton(ribbon.Buttons.Refresh, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
        ribbon.setRibbonStatusButton(ribbon.Buttons.New, false);
        ribbon.refreshStatusButtons();

        vm.hrBucketRelationships = [];

        var magicBucketRequest = null; //have to add new Request each submit => synchro hrs/buckets/activities

        if (hrHierarchyId) {
            vm.setIsViewing();

            loadData(true);
            vm.tabHierarchyRelationshipsIsDisabled = false;
        } else {
            vm.tabHierarchyRelationshipsIsDisabled = true;

            vm.setIsAdding();
            vm.hrHierarchy = hrbucketService.dataContext.hrHierarchies.add({
                VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId,
                BucketClassificationId: null
            });
        }

        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getHrHierarchy(forceReload, hrHierarchyId));
            funcsPromises.push(getHrBucketRelationships(forceReload, hrHierarchyId));

            $q.all(funcsPromises)
                .then(function (res) {
                    // nothing to do
                });
        }

        vm.newAction = function () {
            if (!vm.hrBucketSelected && vm.hrBucketRelationships.length > 0) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            var selectedHrIds = Lazy(vm.hrBucketRelationships).pluck('HrId').toArray();
            // open modal to select hrbucket
            navigationService.navToModal(vm.suspendDirty, 'app.nav.hrbucketsmodal', { selectedIds: selectedHrIds, hideSelected: true, BucketClassificationId: vm.hrHierarchy.BucketClassificationId })
                .then(function (result) {
                    if (result.hasSelection) {
                        var hrBucketSelected = Lazy(result.data).first();
                        addHrBucketDto(hrBucketSelected);
                    }
                });
        };

        vm.refreshAction = function () {
            vm.hrBucketSelected = null;

            getHrBucketRelationships(true, hrHierarchyId)
                .then(function () {
                    // refresh treeview content
                    vm.bucketsRefresh = new Date().getTime();
                });
        };

        // get reference of modifyAction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parentm = vm.modifyAction;
        vm.modifyAction = function () {
            parentm()
                .then(() => {
                    btnNewIsDisabled = !btnNewIsDisabled;
                    btnDeleteIsDisabled = !btnDeleteIsDisabled;

                    ribbon.setRibbonStatusButton(ribbon.Buttons.New, !btnNewIsDisabled && tabHierarchyIsSelected);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, !btnDeleteIsDisabled && tabHierarchyIsSelected);
                    ribbon.refreshStatusButtons();
                });
        };

        //delete tree node action
        vm.deleteAction = function () {
            if (!vm.hrBucketSelected) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            if (canDeleteHrBucket) {
                hrbucketService.deleteEntity(vm.hrBucketSelected);
                submit();
            }
        };

        vm.onNodeSelect = function (args) {
            vm.hrBucketSelected = Lazy(vm.hrBucketRelationships).findWhere({ HrId: Number(args.id) });
        };

        // get reference of submitaction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parents = vm.submitAction;
        vm.submitAction = function () {
            parents().then(
                function (saveResult) {
                    hrHierarchyId = vm.hrHierarchy.HrHierarchyId;
                    vm.tabHierarchyRelationshipsIsDisabled = false;
                    btnNewIsDisabled = false;
                    btnDeleteIsDisabled = false;
                }
            );
        };

        vm.tabSelected = function (currentTab) {
            switch (currentTab) {
                case 'tabHierarchyDetail':
                    tabDetailIsSelected = true;
                    tabHierarchyIsSelected = false;
                    break;
                case 'tabHierarchyRelationships':
                    tabDetailIsSelected = false;
                    tabHierarchyIsSelected = true;
                    break;
            }

            ribbon.setRibbonStatusButton(ribbon.Buttons.New, !btnNewIsDisabled && tabHierarchyIsSelected);
            ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, !btnDeleteIsDisabled && tabHierarchyIsSelected);
            ribbon.refreshStatusButtons();
        };

        function submit() {
            addMagicBucketRequest(); //request for fire bucket/hr/activity binding

            parents().then(
                function (saveResult) {
                    setBatchContext(magicBucketRequest);
                    magicBucketRequest = null;

                    vm.refreshAction();
                });
        }

        function addHrBucketDto(hrBucket) {
            if (hrBucket) {
                hrbucketService.rejectChanges();

                hrbucketService.dataContext.hrBucketRelationships.add({
                    HrId: hrBucket.VirtualHumanResourceId,
                    HrHierarchyId: hrHierarchyId,
                    ResourceType: hrBucket.ResourceTypeId,
                    BucketClassification: hrBucket.BucketClassificationId,
                    ResourcePrototype: hrBucket.ResourcePrototypeId,
                    ParentHrId: vm.hrBucketSelected ? vm.hrBucketSelected.HrId : 0,
                    VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId
                });

                submit();
            }
        }

        function getHrBucketRelationships(forceReload, currentHrHierarchyId) {
            var filter = { HrHierarchyId: currentHrHierarchyId };
            var defer = $q.defer();

            hrbucketService.getHrBucketRelationships(filter, forceReload)
                .then(function (data) {
                    vm.hrBucketRelationships = Lazy(data.results)
                        .map((item) => {
                            var itemMapped = item;
                            item.FieldId = item.HrId;
                            item.FieldParentId = item.ParentHrId;
                            item.FieldCode = item.HrCode;
                            item.FieldText = item.HrName;
                            item.FieldIsExpanded = true;
                            return itemMapped;
                        }).toArray();

                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getHrHierarchy(forceReload, currentHrHierarchyId) {
            var filter = { HrHierarchyId: currentHrHierarchyId };
            var defer = $q.defer();

            hrbucketService.getHrHierarchy(filter, forceReload)
                .then(function (data) {
                    vm.hrHierarchy = data.results[0];
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function addMagicBucketRequest() {
            if (!magicBucketRequest) {
                magicBucketRequest = hrbucketService.dataContext.magicBucketRequests.add({
                    MagicBucketActionId: magicBucketActionEnumParsed.obj.normal.name_value.HierarchyXBuckets,
                    HierarchyId: hrHierarchyId,
                    ModuleTargetId: null,
                    VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId
                });
            }
        }

        function setBatchContext(magicBucketRequest) {
            if (magicBucketRequest.IsBatch) {
                var actionTypeName = magicBucketActionEnumParsed.obj.normal.value_name[magicBucketRequest.MagicBucketActionId];
                contextService.addBatchRequest({
                    requestId: magicBucketRequest.BatchRequestId,
                    requestName: vm.translate('MagicBucketRequest' + actionTypeName + 'BatchName'),
                    requestStatus: 'inserted',
                    onCompletedAutoAction: null,    // is a function called on completed batch that must return a promise (with jobResult) managed in appPanel
                    detailAction: null             // is a function called from appPanel.cshml that links to batch request detail
                });
            }
        }
    };

    hierarchyDetail.$inject = injectParams;
    hierarchyDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('hierarchyDetail', hierarchyDetail);
});