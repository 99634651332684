﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'drbacService', 'rbacService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var drbacUserGroupDetail = function ($q, $scope, $timeout, $injector, drbacService, rbacService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, drbacService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, drbacService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var drbacUserGroupId = navigationService.stateParams().drbacUserGroupId;
        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;

        vm.profilesMultiselect = new NbsMultiselect(vm.translate);

        if (drbacUserGroupId) {
            vm.setIsViewing();

            loadData(true);
        }
        else {
            vm.setIsAdding();

            getUserProfiles(true)
                .then((profiles) => {
                    vm.profilesMultiselect.addData(profiles, 'UserProfileId', 'UserProfileName');

                    // add to context
                    vm.drbacUserGroup = drbacService.dataContext.drbacUserGroups.add({
                        VirtualCompanyId: virtualCompanyId
                    });
                });
        }

        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getDrbacUserGroup(forceReload, drbacUserGroupId, virtualCompanyId));
            funcsPromises.push(getUserProfiles(forceReload));
            funcsPromises.push(getDrbacUserGroupM2MDrbacProfileDtos(forceReload, drbacUserGroupId));
            
            $q.all(funcsPromises)
                .then(function (results) {
                    vm.drbacUserGroup = results[0];
                    vm.drbacProfiles = results[1];
                    vm.drbacUserGroupM2MDrbacProfiles = results[2];

                    
                    vm.profilesMultiselect.addData(vm.drbacProfiles, 'UserProfileId', 'UserProfileName');
                    var pProfilesId = Lazy(vm.drbacUserGroupM2MDrbacProfiles).pluck('UserProfileId').toArray();
                    vm.profilesMultiselect.setSelected(pProfilesId);
                });
        }

        function getDrbacUserGroup(forceReload, drbacUserGroupId, virtualCompanyId) {
            var filter = {
                DrbacUserGroupId: drbacUserGroupId,
                VirtualCompanyId: virtualCompanyId
            };
            var defer = $q.defer();

            drbacService.getDrbacUserGroup(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results[0]);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getUserProfiles(forceReload) {
            var filter = { };
            var defer = $q.defer();

            rbacService.getUserProfiles(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getDrbacUserGroupM2MDrbacProfileDtos(forceReload, drbacUserGroupId) {
            var filter = {
                DrbacUserGroupId: drbacUserGroupId
            };
            var defer = $q.defer();

            drbacService.getDrbacUserGroupM2MDrbacProfileDtos(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        var parents = vm.submitAction;
        var ftcScope = $scope.$parent.vm;
        vm.submitAction = function () {
            Nembus.Rds.Library.SetBindingM2MEntitiesInContext(drbacService.dataContext.drbacUserGroupM2MDrbacProfileDtos, 'DrbacUserGroupId', [Number(vm.drbacUserGroup.DrbacUserGroupId)], 'UserProfileId', vm.profilesMultiselect.selectedIds);

            parents().then(
                function (saveResult) {
                    if (saveResult.entities.length > 0 && vm.viewContext.isAddingState && ftcScope.ftcOnAddEntity) {
                        ftcScope.ftcOnAddEntity(vm.drbacUserGroup);
                    }
                }
            );
        };
    };

    drbacUserGroupDetail.$inject = injectParams;
    drbacUserGroupDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rbac.module').controller('drbacUserGroupDetail', drbacUserGroupDetail);
});