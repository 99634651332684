﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$injector', '$scope', '$timeout', 'checkStateChangeService', 'reportService', 'foundationService', 'navigationService', 'contextService', 'rbacService', 'fileTransferService'];
    function reportEntityDefinitionDetail($q, $injector, $scope, $timeout, checkStateChangeService, reportService, foundationService, navigationService, contextService, rbacService, fileTransferService) {

        var vm = this;
        nbsVm.call(vm, reportService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, reportService);
        
        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var reportDefinitionId = navigationService.stateParams().reportDefinitionId;
        var reportDefinitionStatusParsed = Nembus.Common.Library.util.parseEnum(reportService.dataContext.enumTypes.ReportDefinitionStatus, 'ReportDefinition', vm.translate);
        var reportTemplateSourceTypeParsed = Nembus.Common.Library.util.parseEnum(reportService.dataContext.enumTypes.ReportTemplateSourceType, 'ReportTemplate', vm.translate);
        var reportTemplateFileTypeParsed = Nembus.Common.Library.util.parseEnum(reportService.dataContext.enumTypes.ReportTemplateFileType, 'ReportTemplate', vm.translate);
        var currentLanguageSelected = null;
        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
        var appStoreAts = Lazy(contextService.virtualContext.AppStores).findWhere({ AppStoreCode: 'Ats' });
        var currentAppStores = Lazy(contextService.virtualContext.AppStores).reject({ AppStoreCode: 'Ats' }).toArray();

        vm.isInWorkingPhase = false;

        vm.checkboxListManager = new NbsCheckboxList(vm.translate);
        vm.checkboxListManager.setData(currentAppStores, 'AppStoreId', 'AppStoreName');
        vm.checkboxListManager.onChangeFnCallback = function (selected) {
            var bw = appStoreAts.AppStoreBw; // module system administrator is ever included
            bw += Lazy(currentAppStores).filter((appStore) => { return Lazy(selected).contains(appStore.AppStoreId) }).pluck('AppStoreBw').sum()
            vm.currentReportDefinition.AppStoreBw = bw;
        };

        vm.systemTemplate = null;
        vm.customTemplates = {};

        vm.reportDefinitionStatusesDropdown = reportDefinitionStatusParsed.list.translated;
        vm.files = [];
        vm.tabIndexActive = 0;

        vm.onSystemTemplateUploaded = function (file, res) {
            if (!reportDefinitionId) {
                throw new Error('ReportDefinitionId mandatory');
            }

            var dData = { fileId: file.id, displayFileName: file.name };
            var reportTemplateClientData = {
                ReportTemplateFilter: { ReportDefinitionId: reportDefinitionId },
                TemporaryFileName: fileTransferService.temporaryFileNameByFileId(dData),
                DisplayFileName: dData.displayFileName
            };

            vm.removeAlerts();
            reportService.uploadReportTemplateSystem(reportTemplateClientData)
                .then((data) => {
                    if (data.results[0] === '1') {
                        navigationService.customMessageModal(vm.translate('ReportEntityDefinitionDetail.FileUploadedSuccess'));
                    } else {
                        vm.setErrorMsg({ isMessageVisible: true, message: vm.translate('ReportEntityDefinitionDetail.FileUploadedError') });
                    }
                });
        }

        vm.onCustomTemplateUploaded = function (file, res) {
            if (!reportDefinitionId) {
                throw new Error('ReportDefinitionId mandatory');
            }

            var dData = { fileId: file.id, displayFileName: file.name };

            var reportTemplateClientData = {
                ReportTemplateFilter: {
                    ResourceLanguageId: currentLanguageSelected.LanguageId,
                    ReportDefinitionId: reportDefinitionId,
                },
                TemporaryFileName: fileTransferService.temporaryFileNameByFileId(dData),
                DisplayFileName: dData.displayFileName
            };

            vm.removeAlerts();
            reportService.uploadReportTemplateCustom(reportTemplateClientData)
                .then((data) => {
                    if (data.results[0] === '1') {
                        getReportTemplates(true, reportDefinitionId)
                            .then((templates) => {
                                vm.currentReportTemplates = templates;
                                navigationService.customMessageModal(vm.translate('ReportEntityDefinitionDetail.FileUploadedSuccess'));
                                setCustomData();
                            });
                    } else {
                        vm.setErrorMsg({ isMessageVisible: true, message: vm.translate('ReportEntityDefinitionDetail.FileUploadedError') });
                    }
                });
        }

        vm.downloadXsd = function () {
            var xsdFilename = getXsdFileName(vm.systemTemplate.SourceFileName);
            var metaPath = vm.systemTemplate.FileNameFullMetaPath.replace('\\' + vm.systemTemplate.SourceFileName, '');
            var metaPathXSD = metaPath + '\\ds\\' + xsdFilename;

            fileTransferService.downloadRemoteFileByPath({
                filePath: metaPathXSD,
                displayFileName: xsdFilename,
                asAttach: true
            });
        }

        vm.downloadSystemTemplate = function () {
            var template = vm.systemTemplate;
            if (template === null) {
                navigationService.customMessageModal('global.FileNotFound');
                return;
            }

            fileTransferService.downloadRemoteFileByPath({
                filePath: template.FileNameFullMetaPath,
                displayFileName: template.DisplayFileName,
                asAttach: true
            });
        }

        vm.downloadCustomTemplate = function (language) {
            var template = vm.customTemplates[language.LanguageId].template;
            if (template === null) {
                navigationService.customMessageModal('global.FileNotFound');
                return;
            }

            fileTransferService.downloadRemoteFileByPath({
                filePath: template.FileNameFullMetaPath,
                displayFileName: template.DisplayFileName,
                asAttach: true
            });
        }

        vm.setCurrentLanguageSelected = function (language) {
            currentLanguageSelected = language;
        }

        vm.navigateToReport = function () {
            navigationService.navigateTo(vm.currentReportDefinition.CmsViewRenderNavigationState, {
                'appStoreCode': 'all',
                'reportDefinitionId': vm.currentReportDefinition.ReportDefinitionId
            });
        };

        function getXsdFileName(srcFileName) {
            try {
                var re = /(?:\.([^.]+))?$/;
                var ext = re.exec(srcFileName)[1];
                if (ext.length < srcFileName.length) {
                    return srcFileName.substring(0, srcFileName.length - ext.length) + 'xsd';
                } else {
                    return '';
                }
            }
            catch (err) {
                return '';
            }
        }

        loadData(true);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getReportDefinition(forceReload, reportDefinitionId));
            funcsPromises.push(getReportTemplates(forceReload, reportDefinitionId));
            funcsPromises.push(getLanguages(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.currentReportDefinition = results[0];
                    vm.currentReportTemplates = results[1];
                    vm.languages = results[2];

                    vm.isInWorkingPhase = getIsWorkingPhase();

                    var selectedIds = Lazy(currentAppStores).filter((appStore) => { return (vm.currentReportDefinition.AppStoreBw & appStore.AppStoreBw) > 0 }).pluck('AppStoreId').toArray();
                    vm.checkboxListManager.setSelected(selectedIds);

                    getCmsViews(forceReload, vm.currentReportDefinition.CmsViewRenderFormId)
                        .then((cmsView) => {
                            vm.currentReportDefinition.CmsViewRenderNavigationState = cmsView.NavigationState;

                            setSystemData();
                            setCustomData();
                        });

                });
        };

        var parents = vm.submitAction;
        vm.submitAction = function () {
            parents()
                .then(function () {
                    vm.isInWorkingPhase = getIsWorkingPhase();
                });
        };

        function getIsWorkingPhase() {
            return vm.currentReportDefinition.IsWorkingPhase && vm.currentReportDefinition.IsSystem;
        }

        function setSystemData() {
            vm.systemTemplate = getSystemTemplate();
            if (vm.systemTemplate) {
                vm.systemTemplateLabel = vm.systemTemplate.SourceFileName;
                vm.xsdLabel = getXsdFileName(vm.systemTemplate.SourceFileName);
            } else {
                vm.systemTemplateLabel = vm.translate('FileNotFound');
            }
        }

        function setCustomData() {
            vm.customTemplates = {};

            Lazy(vm.languages)
                .each((language) => {
                    var template = getCustomTemplate(language, virtualCompanyId);
                    var lastDate = template.LastChangeDate ? template.LastChangeDate : template.CreationDate;

                    vm.customTemplates[language.LanguageId] = {
                        template: template,
                        lastDate: lastDate,
                        lastTime: Nembus.Common.Library.converter.date.formatTimePicker(lastDate, contextService.virtualContext.cultureInfo, false),
                        fileName: template ? template.SourceFileName : ''
                    }
                });
        }

        function getSystemTemplate() {
            var templates = Lazy(vm.currentReportTemplates)
                .where({
                    ReportTemplateSourceTypeId: reportTemplateSourceTypeParsed.obj.normal.name_value.SystemTemplate,
                    ReportTemplateFileTypeId: reportTemplateFileTypeParsed.obj.normal.name_value.System
                })
                .toArray();

            if (Lazy(templates).length() === 1) {
                return Lazy(templates).first();
            } else {
                return null;
            }
        }

        function getCustomTemplate(language, virtualCompanyId) {
            var templates = Lazy(vm.currentReportTemplates)
                .where({
                    LanguageId: language.LanguageId,
                    VirtualCompanyId: virtualCompanyId,
                    ReportTemplateSourceTypeId: reportTemplateSourceTypeParsed.obj.normal.name_value.CustomTemplate,
                    ReportTemplateFileTypeId: reportTemplateFileTypeParsed.obj.normal.name_value.Custom
                })
                .toArray()

            if (Lazy(templates).length() === 1) {
                return Lazy(templates).first();
            } else {
                return null;
            }
        }

        function getLanguages(forceReload) {
            var filter = {};
            var defer = $q.defer();

            rbacService.getLanguages(forceReload, filter)
                .then(function (data) {
                    defer.resolve(data.results);
                });

            return defer.promise;
        }

        function getReportDefinition(forceReload, reportDefinitionId) {
            var filter = { ReportDefinitionId: reportDefinitionId };
            var defer = $q.defer();

            reportService.getReportDefinition(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results[0]);
                })
                .catch(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getCmsViews(forceReload, cmsViewId) {
            var filter = {
                CmsViewId: cmsViewId,
            };
            var defer = $q.defer();

            foundationService.getCmsViews(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results[0]);
                })
                .catch(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getReportTemplates(forceReload, reportDefinitionId) {
            var filter = { ReportDefinitionId: reportDefinitionId };
            var defer = $q.defer();

            reportService.getReportTemplates(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        }
    }

    reportEntityDefinitionDetail.$inject = injectParams;
    reportEntityDefinitionDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('reportEntityDefinitionDetail', reportEntityDefinitionDetail);
});