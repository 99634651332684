﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'vhreventService', 'activityService', 'utilService', 'foundationService', 'navigationService', 'checkStateChangeService', 'contextService', 'formSummaryService', 'cmsService'];
    function vhrEventsVhrList($q, $scope, $timeout, $injector, vhreventService, activityService, utilService, foundationService, navigationService, checkStateChangeService, contextService, formSummaryService, cmsService) {
        var vm = this;
        nbsVm.call(vm, vhreventService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return gridResult.data; });
        checkStateChangeService.checkFormOnStateChange($scope, vhreventService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        ribbon.addTab(new ribbon.Tab("ActionPanelTabManage", [ribbon.GetVhrEventsLifeStatusSection(), ribbon.GetVhrEventsDateSection(), ribbon.GetVhrEventsExecutionSection(), ribbon.GetVhrEventsApprovalSection(), ribbon.GetVhrEventsCustomerApprovalSection(), ribbon.GetWarningsSection()]));
        vm.ribbonDatacontext = ribbon;

        var appStoreCode = navigationService.stateParams().appStoreCode;

        // viewKind can be : 'full' or 'customer' => change visibility of some columns in grid
        var viewKind = navigationService.stateParams().viewKind;
        vm.viewKindIsFull = viewKind === 'full' ? true : false;

        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var vhrEventTypeCode = navigationService.stateParams().vhrEventTypeCode;
        var vhrEventFixedTypeCode = navigationService.stateParams().vhrEventFixedTypeCode;
        var vhrEventKindEnum = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventKind, null, vm.translate).obj.normal.name_value;
        var vhrEventKindId = vhrEventKindEnum.Data;
        var virtualHumanResourceId = navigationService.stateParams().virtualHumanResourceId;
        var wzVhrEntityType = navigationService.stateParams().wzVhrEntityType;          //asset, avatar, bucket, ...
        var vhrEventCreationArchetype = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventCreationArchetype, null, vm.translate).obj.normal.name_value;
        var vhrEventManualRequestType = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventManualRequestType, null, vm.translate).obj.normal.name_value;
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var vhrEventLifeStatusEnumParsed = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventLifeStatus, 'VhrEvent', vm.translate);
        var vhrEventLifeStatusEnumObj = vhrEventLifeStatusEnumParsed.obj.normal.name_value;
        var lifeStatusCompletedId = vhrEventLifeStatusEnumObj.ReportedAsFinished;
        var viewMode = navigationService.currentViewMode();

        // set grid component datacontext 
        var gridUIkey = 'vhr';
        if (viewKind === 'customer') {
            gridUIkey = 'vhrcustomer';
        }

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('vhrevent', gridUIkey, appStoreCode);
        nbsGridDatacontext.viewCustomBar();
        nbsGridDatacontext.setIsMultiselect();
        nbsGridDatacontext.setAdaptToPage();
        nbsGridDatacontext.setDatasource([], false); // fake datasource, only to create grid
        vm.nbsGridDatacontext = nbsGridDatacontext;

        var vhrEventPermission = {};
        if (appStoreCode === 'ims') {
            vhrEventPermission = Nembus.Ims.Library.auth.GetVhrEventPermission(vm.havePermission, contextService.virtualContext, viewKind);
        } else if (appStoreCode === 'tec') {
            vhrEventPermission = Nembus.Tec.Library.auth.GetVhrEventPermission(vm.havePermission, contextService.virtualContext, viewKind);
        } else if (appStoreCode === 'tes') {
            vhrEventPermission = Nembus.Tes.Library.auth.GetVhrEventPermission(vm.havePermission, contextService.virtualContext, viewKind);
        }


        // hook from gridbar
        var ejDataManager = null;
        var currentVhrEventBarFilter = null;
        vm.vhrEventBarOnChanges = function (result) {
            // save in view current filter passed by survey bar
            currentVhrEventBarFilter = result;

            currentVhrEventBarFilter.vhrEventFilter.VhrEventTypeCode = vhrEventTypeCode;
            currentVhrEventBarFilter.vhrEventFilter.VhrEventFixedTypeCode = vhrEventFixedTypeCode;
            currentVhrEventBarFilter.vhrEventFilter.VhrEventKind = vhrEventKindId;
            currentVhrEventBarFilter.vhrEventFilter.VirtualHumanResourceId = virtualHumanResourceId;
            currentVhrEventBarFilter.vhrEventFilter.ModuleTargetId = moduleTargetId;

            if (ejDataManager === null) {
                utilService.getGenericElements({ GenericElementCode: 'Configurations.VhrEvents.LifeStatusColors' }, false)
                    .then((data) => {
                        var ge = data.results[0].PairValuesArray;
                        var statusColorObj = Nembus.Common.Library.parser.objFromList(ge, 'key', 'value');

                        var supportData = { searchMode: 'vhrevents', translate: vm.translate, contextService: contextService, statusColorObj: statusColorObj };
                        ejDataManager = vhreventService.getVhrEventDataManager(supportData, currentVhrEventBarFilter.vhrEventFilter, ejDataManager);
                        nbsGridDatacontext.setDatasource(ejDataManager, true);
                        refreshGridDatasource(currentVhrEventBarFilter.vhrEventFilter);
                    });
            } else {
                refreshGridDatasource(currentVhrEventBarFilter.vhrEventFilter)
            }
        };

        // get array items for form summary
        vm.formSummary = formSummaryService.getDatasource();

        // datasource for modal change state
        vm.currentStates = Lazy(vhrEventLifeStatusEnumParsed.list.translated)
            .filter((item) => {
                return item.value === vhrEventLifeStatusEnumObj.ReportedAsFinished
                    || item.value === vhrEventLifeStatusEnumObj.FinishedAndChecked
                    || item.value === vhrEventLifeStatusEnumObj.Closed;
            }).toArray();

        vm.currentVhrEvents = [];

        // open wizard to add new manual event
        vm.newAction = function () {
            var funcsPromises = [];

            switch (wzVhrEntityType) {
                case 'asset':
                    funcsPromises.push(getVirtualEquipment(false, virtualHumanResourceId));
                    break;
                case 'avatar':
                    funcsPromises.push(getAvatar(false, virtualHumanResourceId));
                    break;
            }

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.virtualHumanResource = results[0];

                    var services = { navigationService: navigationService, vhreventService: vhreventService, cmsService: cmsService }
                    var wzNbsManager = new NbsWizardManager($q, vm.translate, services, NbsGuidManager);
                    var config = new wzNbsManager.WzConfig_AddVhrEventManual(null);
                    config.supportData = { vhrEventCreationArchetype: vhrEventCreationArchetype };
                    config.vhrEntityType = wzVhrEntityType;
                    config.defaultData.VirtualHumanResources = [vm.virtualHumanResource];
                    config.stepsToSkip.push('vhrEventsManualStepVhr');
                    config.defaultData.VhrEventManualRequestTypeId = vhrEventManualRequestType.New;
                    wzNbsManager.setWzConfig(config);

                    wzVhrEventManualRequest(wzNbsManager);
                });
        };

        vm.copyAction = function () {
            if (gridResult.isSingleSelection) {
                var event = Lazy(gridResult.data).first();
                // get activity related to vhrevent
                activityService.getActivities({ ActivitySystemCode: event.ActivitySystemCode }, true)
                    .then(function (data) {
                        var activity = data.results[0];

                        var services = { navigationService: navigationService, vhreventService: vhreventService, cmsService: cmsService }
                        var wzNbsManager = new NbsWizardManager($q, vm.translate, services, NbsGuidManager);
                        var config = new wzNbsManager.WzConfig_AddVhrEventManual(null);
                        config.supportData = { vhrEventCreationArchetype: vhrEventCreationArchetype };
                        config.vhrEntityType = wzVhrEntityType;
                        config.defaultData.VhrEventManualRequestTypeId = vhrEventManualRequestType.Copy;

                        config.defaultData.Code = event.ActivityCode;
                        config.defaultData.ExternalCode = event.ActivityExternalCode;
                        config.defaultData.ModuleTargetId = event.ModuleTargetId;
                        config.defaultData.VhrEventTypeId = event.VhrEventTypeId;
                        config.defaultData.VhrEventGroupId = event.VhrEventGroupId;
                        config.defaultData.WorkCenterId = event.WorkCenterId;

                        // fields retrieved by activity related to vhrevent
                        if (activity) {
                            config.defaultData.VhrEventTemplateId = activity.VhrEventTemplateId;
                            config.defaultData.Name = activity.ActivityName;
                            config.defaultData.Description = activity.ActivityDescription;
                            config.defaultData.ActivityId = activity.ActivityId;
                        } else {
                            config.defaultData.Name = event.VhrEventName;
                            config.defaultData.Description = event.VhrEventDescription;
                        }

                        config.defaultData.VhrEvents = gridResult.data;

                        // skip step
                        config.defaultData.VirtualHumanResources = [
                            {
                                VirtualHumanResourceId: event.VirtualHumanResourceId,
                                VirtualName: event.VirtualHumanResourceDisplayName
                            }
                        ];
                        config.stepsToSkip.push('vhrEventsManualStepVhr');

                        // remove steps
                        config.removeStep('vhrEventsManualStepSwitchFromActivity');
                        config.removeStep('vhrEventsManualStepActivitySelection');
                        wzNbsManager.setWzConfig(config);


                        wzVhrEventManualRequest(wzNbsManager);
                    })
                    .catch(function (error) {
                        vm.setErrorMsg(error);
                    });


            } else {
                navigationService.selectOneItemMessageModal();
            }
        };

        function wzVhrEventManualRequest(wzNbsManager) {
            wzNbsManager.open()
                .then(function (result) {
                    // result contains VhrEventManualRequest (not in context breeze)
                    var vhrEventManualRequestResult = result;
                    vhrEventManualRequestResult.VirtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
                    vhrEventManualRequestResult.VhrEventFixedTypeCode = vhrEventFixedTypeCode;
                    var vhrEventManualRequest = vhreventService.dataContext.vhrEventManualRequests.add(vhrEventManualRequestResult);

                    vm.submitAction()
                        .then(function (result) {
                            if (vhrEventManualRequest.IsBatch) {
                                contextService.addBatchRequest({
                                    requestId: vhrEventManualRequest.BatchRequestId,               //only one entity will be submitted
                                    requestName: vhrEventManualRequest.BatchRequestName,
                                    requestStatus: 'inserted',
                                    onCompletedAutoAction: function (request) {
                                        var defer = $q.defer();
                                        getVhrEventManualRequest(true, vhrEventManualRequest.VhrEventManualRequestId)
                                            .then(function () {
                                                var jobResult = vm.getJobStatus();
                                                //public enum VhrEventManualRequestStatus {
                                                //    None = 0,
                                                //    Inserted = 1,
                                                //    SuccessfullyDone = 2,
                                                //    SomeErrors = 3,
                                                //    SomeWarnings = 4,
                                                //    SomeConflict = 5,
                                                //    }
                                                switch (vm.vhrEventManualRequest.VhrEventManualRequestStatusId) {
                                                    case 2: jobResult.jobStatus = 'done'; break;     // SuccessfullyDone
                                                    case 3: jobResult.jobStatus = 'error'; break;     // SomeErrors
                                                    case 4: jobResult.jobStatus = 'warning'; break;     // SomeWarnings
                                                    case 5: jobResult.jobStatus = 'conflict'; break;     // SomeConflict
                                                }
                                                //jobResult.jobMessage = '';  // message before requestName in toolbar item
                                                //jobResult.jobDetailActionLabel = '';// Label of detail item in dropdown

                                                // used in detailAction
                                                request.VhrEventManualRequestId = vm.vhrEventManualRequest.VhrEventManualRequestId;

                                                defer.resolve(jobResult);
                                            });

                                        return defer.promise;
                                    },
                                    detailAction: function (request) {
                                        navigationService.navigateTo("app.nav.vhreventmanualrequestdetail", {
                                            'vhrEventManualRequestId': request.VhrEventManualRequestId,
                                            'appStoreCode': appStoreCode
                                        });
                                    }
                                });
                            }

                            vm.refreshAction();
                        })
                        .catch(function (error) {
                            // error
                            vm.setErrorMsg(error);
                            vhreventService.rejectChanges();
                        });
                });
        }

        function getVhrEventManualRequest(forceReload, vhrEventManualRequestId) {
            var filter = { VhrEventManualRequestId: vhrEventManualRequestId };
            var defer = $q.defer();

            vhreventService.getVhrEventManualRequest(filter, forceReload)
                .then(function (data) {
                    vm.vhrEventManualRequest = data.results[0];
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function wzUpdateVhrEventManualRequest(vhrEventTransactionCode) {
            navigationService.confirmModal(vm.translate("VhrEventManualUpdateSerieAffectedWarning"))
                .then(function (result) {
                    if (result) {

                        var services = { navigationService: navigationService, vhreventService: vhreventService, cmsService: cmsService }
                        var wzNbsManager = new NbsWizardManager($q, vm.translate, services, NbsGuidManager);
                        var config = new wzNbsManager.WzConfig_UpdateVhrEventManual(null);
                        config.supportData = { vhrEventCreationArchetype: vhrEventCreationArchetype };
                        config.defaultData.VhrEventTransactionCode = vhrEventTransactionCode;
                        config.defaultData.VhrEventManualRequestTypeId = vhrEventManualRequestType.Modify;
                        // get default data from first VhrEvent that belongs to serie selected (for validation request)
                        var firstVhrEvent = Lazy(gridResult.data).first();
                        config.defaultData.ModuleTargetId = firstVhrEvent.ModuleTargetId;
                        config.defaultData.VhrEventGroupId = firstVhrEvent.VhrEventGroupId;
                        config.defaultData.VhrEventTypeId = firstVhrEvent.VhrEventTypeId;
                        config.defaultData.WorkCenterId = firstVhrEvent.WorkCenterId;
                        config.defaultData.VhrEventTemplateId = 1;        // only for validation
                        config.defaultData.VhrEvents = gridResult.data;
                        wzNbsManager.setWzConfig(config);

                        wzVhrEventManualRequest(wzNbsManager);
                    }
                });
        }

        var gridResult = {};
        vm.gridSelection = function (args) {
            $timeout(() => {
                gridResult = args;

                setActionButtonsStatus(null);
            });
        };

        vm.rowDblClick = function (args) {
            navigationService.navigateToVhrEventDetail({
                AppStoreCode: appStoreCode,
                VhrEventTemplateCode: args.data.VhrEventTemplateCode,
                ViewKind: viewKind,
                VhrEventId: args.data.VhrEventId,
                VirtualHumanResourceId: args.data.VirtualHumanResourceId,
                ViewMode: viewMode
            });
        };

        function refreshGridDatasource(currentGridFilter) {
            $timeout(() => {
                vm.forceDatamanagerFilter = {
                    refreshKey: new Date().getTime(),
                    filter: currentGridFilter
                }
            });
        }

        function getVirtualEquipment(forceReload, currentVirtualHumanResourceId) {
            var filter = { VirtualHumanResourceId: currentVirtualHumanResourceId };
            var defer = $q.defer();

            foundationService.getVirtualEquipment(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results[0]);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getAvatar(forceReload, currentVirtualHumanResourceId) {
            var filter = { VirtualHumanResourceId: currentVirtualHumanResourceId };
            var defer = $q.defer();

            foundationService.getAvatar(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results[0]);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        //Http Post
        function updateVhrEventLifeStatusBulk(vhrEventsId, moduleTargetId, vhreventLifeStatus) {
            vm.removeAlerts();

            var vhrEventShuffleActionObj = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventShuffleAction, null, vm.translate).obj.normal.name_value;
            var vhreventShuffleDto = vhreventService.dataContext.vhrEventShuffleDto.add();
            vhreventShuffleDto.VhrEventShuffleAction = vhrEventShuffleActionObj.ChangeLifeStatus;
            vhreventShuffleDto.VhrEventLifeStatus = vhreventLifeStatus;
            vhreventShuffleDto.VhrEventLifeStatusChangeNote = null;
            vhreventShuffleDto.VhrEventBillingStatus = 0;
            vhreventShuffleDto.ModuleTargetId = moduleTargetId;
            vhreventShuffleDto.VhrEventsIdSerialized = JSON.stringify(vhrEventsId);

            vhreventService.saveChanges()
                .then(function () {
                    var resultErrors = JSON.parse(Nembus.Common.Library.converter.decodeHtml(vhreventShuffleDto.ResultErrorsSerialized));
                    if (Lazy(Object.keys(resultErrors)).some()) {
                        vm.setErrorMsg(({ isMessageVisible: true, message: vm.translate('ChangeStatusNotPermitted') }));
                        return;
                    }

                    vm.refreshAction();
                });
        }
        //#endregion

        vm.refreshAction = function () {
            $timeout(() => {
                setActionButtonsStatus(false);
                vm.forceGridRefresh = new Date().getTime();
            });
        };

        function changeStartDateVhrEvent() {
            var startDate = Lazy(gridResult.data).first() ? Lazy(gridResult.data).first().VhrEventStartDate : new Date(Date.now());
            var startTime = Nembus.Common.Library.converter.date.formatTimePicker(startDate, contextService.virtualContext.cultureInfo, true);
            var navParams = {
                startDate: startDate,
                startTime: startTime,
                KeepSameTime: true,
                KeepSameWorkCenter: true,
                TargetWorkCenterId: null
            };

            navigationService.navToVhrEventChangeStartDate(vm.suspendDirty, navParams)
                .then(function (modalResult) {
                    //enable dirty flag
                    if (modalResult.dialogResult === navigationService.navigationActionTypes.modalOk) {
                        //----
                        canChangeTargetVhrEventsStartDate(gridResult.dataIds, modalResult.data.startDate, moduleTargetId)
                            .then(function (result) {
                                if (result.ChangeIsEnabled) {
                                    if (result.ChangeSerie) {
                                        wzUpdateVhrEventManualRequest(result.VhrEventManualRequestId);
                                    } else {
                                        var vhrEventShuffleDto = vhreventService.dataContext.vhrEventShuffleDto.add();
                                        vhrEventShuffleDto.VhrEventShuffleAction = modalResult.data.VhrEventShuffleAction;
                                        vhrEventShuffleDto.StartDate = modalResult.data.startDate;
                                        vhrEventShuffleDto.StartTime = modalResult.data.startTime;
                                        vhrEventShuffleDto.KeepSameTime = modalResult.data.keepSameTime;
                                        vhrEventShuffleDto.KeepSameWorkCenter = modalResult.data.keepSameWorkCenter;
                                        vhrEventShuffleDto.TargetWorkCenterId = modalResult.data.targetWorkCenterId;
                                        vhrEventShuffleDto.VhrEventLifeStatus = 0;
                                        vhrEventShuffleDto.VhrEventBillingStatus = 0;
                                        vhrEventShuffleDto.VhrEventsIdSerialized = JSON.stringify(gridResult.dataIds);
                                        vhrEventShuffleDto.ModuleTargetId = moduleTargetId;

                                        vm.submitAction()
                                            .then(function (result) {
                                                setActionButtonsStatus(false);
                                            })
                                            .catch(function (error) {
                                                vm.removeAlertFromList(true);
                                                vm.setErrorMsg(error);
                                            });
                                    }
                                } else {
                                    navigationService.customMessageModal(vm.translate(result.ThesaurusWordKey));
                                }
                            });

                        //----
                    }

                    vm.refreshAction();
                });
        }

        vm.setDateAction = function () {
            changeStartDateVhrEvent();
        };

        vm.sendMailAction = function () {
            openModalSendMail();
        };

        // START: functions used to manage vhrEventMassivelyUpdateRequest
        var vhrEventMassivelyUpdateRequestType = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventMassivelyUpdateRequestType, null, vm.translate).obj.normal.name_value;

        //#region execution
        vm.vhrEventExecutionApproveAction = function () {
            if (gridResult.hasSelection) {
                navigationService.navToVhrEventExecute(vm.suspendDirty)
                    .then(function (modalResult) {
                        if (modalResult.dialogResult === navigationService.navigationActionTypes.modalOk) {
                            modalResult.data.VhrEventsIdSerialized = JSON.stringify(gridResult.dataIds);
                            modalResult.data.AppStoreBw = appStoreBw;
                            modalResult.data.VirtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
                            modalResult.data.ModuleTargetId = moduleTargetId;

                            var vhrEventMassivelyUpdateRequest = vhreventService.dataContext.vhrEventMassivelyUpdateRequests.add(modalResult.data);
                            submitVhrEventMassivelyUpdateRequest(vhrEventMassivelyUpdateRequest);
                        }
                    });
            }
        };
        vm.vhrEventExecutionModifyAction = function () {
            if (gridResult.hasSelection) {
                navigationService.navToVhrEventChangeExecution(vm.suspendDirty)
                    .then(function (modalResult) {
                        if (modalResult.dialogResult === navigationService.navigationActionTypes.modalOk) {
                            modalResult.data.VhrEventsIdSerialized = JSON.stringify(gridResult.dataIds);
                            modalResult.data.AppStoreBw = appStoreBw;
                            modalResult.data.VirtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
                            modalResult.data.ModuleTargetId = moduleTargetId;

                            var vhrEventMassivelyUpdateRequest = vhreventService.dataContext.vhrEventMassivelyUpdateRequests.add(modalResult.data);
                            submitVhrEventMassivelyUpdateRequest(vhrEventMassivelyUpdateRequest);
                        }
                    });
            }
        };
        vm.vhrEventExecutionInfoAction = function () {
            if (gridResult.isSingleSelection) {
                var vhrEventId = Lazy(gridResult.data).first().VhrEventId;
                navigationService.navToVhrEventInfoExecution(vm.suspendDirty, { VhrEventId: vhrEventId });
            }
        };
        //#endregion

        //#region approval
        vm.vhrEventApprovalAuthorizeAction = function () {
            if (gridResult.hasSelection) {
                navigationService.navToVhrEventApprove(vm.suspendDirty)
                    .then(function (modalResult) {
                        if (modalResult.dialogResult === navigationService.navigationActionTypes.modalOk) {
                            modalResult.data.VhrEventsIdSerialized = JSON.stringify(gridResult.dataIds);
                            modalResult.data.AppStoreBw = appStoreBw;
                            modalResult.data.VirtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
                            modalResult.data.ModuleTargetId = moduleTargetId;

                            var vhrEventMassivelyUpdateRequest = vhreventService.dataContext.vhrEventMassivelyUpdateRequests.add(modalResult.data);
                            submitVhrEventMassivelyUpdateRequest(vhrEventMassivelyUpdateRequest);
                        }
                    });
            }
        };
        vm.vhrEventApprovalModifyAction = function () {
            if (gridResult.hasSelection) {
                navigationService.navToVhrEventChangeApproval(vm.suspendDirty)
                    .then(function (modalResult) {
                        //enable dirty flag
                        if (modalResult.dialogResult === navigationService.navigationActionTypes.modalOk) {
                            modalResult.data.VhrEventsIdSerialized = JSON.stringify(gridResult.dataIds);
                            modalResult.data.AppStoreBw = appStoreBw;
                            modalResult.data.VirtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
                            modalResult.data.ModuleTargetId = moduleTargetId;

                            var vhrEventMassivelyUpdateRequest = vhreventService.dataContext.vhrEventMassivelyUpdateRequests.add(modalResult.data);
                            submitVhrEventMassivelyUpdateRequest(vhrEventMassivelyUpdateRequest);
                        }
                    });
            }
        };
        vm.vhrEventApprovalInfoAction = function () {
            if (gridResult.isSingleSelection) {
                var vhrEventId = Lazy(gridResult.data).first().VhrEventId;
                navigationService.navToVhrEventInfoApproval(vm.suspendDirty, { VhrEventId: vhrEventId });
            }
        };
        //#endregion

        //#region customer approval
        vm.vhrEventCustomerApprovalAuthorizeAction = function () {
            if (gridResult.hasSelection) {
                navigationService.navToVhrEventCustomerApprove(vm.suspendDirty)
                    .then(function (modalResult) {
                        if (modalResult.dialogResult === navigationService.navigationActionTypes.modalOk) {
                            modalResult.data.VhrEventsIdSerialized = JSON.stringify(gridResult.dataIds);
                            modalResult.data.AppStoreBw = appStoreBw;
                            modalResult.data.VirtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
                            modalResult.data.ModuleTargetId = moduleTargetId;

                            var vhrEventMassivelyUpdateRequest = vhreventService.dataContext.vhrEventMassivelyUpdateRequests.add(modalResult.data);
                            submitVhrEventMassivelyUpdateRequest(vhrEventMassivelyUpdateRequest);
                        }
                    });
            }
        };
        vm.vhrEventCustomerApprovalModifyAction = function () {
            if (gridResult.hasSelection) {
                navigationService.navToVhrEventChangeCustomerApproval(vm.suspendDirty)
                    .then(function (modalResult) {
                        //enable dirty flag
                        if (modalResult.dialogResult === navigationService.navigationActionTypes.modalOk) {
                            modalResult.data.VhrEventsIdSerialized = JSON.stringify(gridResult.dataIds);
                            modalResult.data.AppStoreBw = appStoreBw;
                            modalResult.data.VirtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
                            modalResult.data.ModuleTargetId = moduleTargetId;

                            var vhrEventMassivelyUpdateRequest = vhreventService.dataContext.vhrEventMassivelyUpdateRequests.add(modalResult.data);
                            submitVhrEventMassivelyUpdateRequest(vhrEventMassivelyUpdateRequest);
                        }
                    });
            }
        };
        vm.vhrEventCustomerApprovalInfoAction = function () {
            if (gridResult.isSingleSelection) {
                var vhrEventId = Lazy(gridResult.data).first().VhrEventId;
                navigationService.navToVhrEventInfoCustomerApproval(vm.suspendDirty, { VhrEventId: vhrEventId });
            }
        };
        //#endregion

        vm.updateLabelAction = function () {
            var selectedIds = [];
            //if (gridResult.isSingleSelection) {
            //    var vhrEventSelected = Lazy(gridResult.data).first();
            //    selectedIds = Lazy(vhrEventSelected.VhrEventLabelsUI).pluck('IntValue').toArray();
            //}

            getVhrEventLabels(false, appStoreBw)
                .then(function (results) {
                    var params = new NbsGenericListModalParamObject('VhrEventLabel', vm.translate('ActionPanelUpdateLabel'));
                    params.selectedIds = selectedIds;

                    navigationService.navToGenericListModal(vm.suspendDirty, params, results)
                        .then(function (result) {
                            if (result.hasSelection) {
                                var vhrEventMassivelyUpdateRequest = vhreventService.dataContext.vhrEventMassivelyUpdateRequests.add({
                                    VhrEventLabelsIdSerialized: JSON.stringify(result.selectedValues),
                                    VhrEventMassivelyUpdateRequestTypeId: vhrEventMassivelyUpdateRequestType.UpdateLabel,
                                    VhrEventsIdSerialized: JSON.stringify(gridResult.dataIds),
                                    AppStoreBw: appStoreBw,
                                    VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId,
                                    ModuleTargetId: moduleTargetId
                                });

                                submitVhrEventMassivelyUpdateRequest(vhrEventMassivelyUpdateRequest);
                            }
                        });
                });
        };

        vm.infoLabelAction = function () {
            var selectedIds = [];
            if (gridResult.isSingleSelection) {
                var vhrEventSelected = Lazy(gridResult.data).first();
                selectedIds = Lazy(vhrEventSelected.VhrEventLabelsUI).pluck('IntValue').toArray();
            }

            getVhrEventLabels(false, appStoreBw)
                .then(function (results) {
                    var params = new NbsGenericListModalParamObject('VhrEventLabel', vm.translate('ActionPanelInfoLabel'));
                    params.readonly = true;
                    params.selectedIds = selectedIds;
                    navigationService.navToGenericListModal(vm.suspendDirty, params, results);
                });
        };

        vm.deleteLabelAction = function () {
            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        var vhrEventMassivelyUpdateRequest = vhreventService.dataContext.vhrEventMassivelyUpdateRequests.add({
                            VhrEventMassivelyUpdateRequestTypeId: vhrEventMassivelyUpdateRequestType.DeleteLabel,
                            VhrEventsIdSerialized: JSON.stringify(gridResult.dataIds),
                            AppStoreBw: appStoreBw,
                            VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId,
                            ModuleTargetId: moduleTargetId
                        });

                        submitVhrEventMassivelyUpdateRequest(vhrEventMassivelyUpdateRequest);
                    }
                });
        };

        function submitVhrEventMassivelyUpdateRequest(request) {
            vm.submitAction()
                .then(function (saveResult) {
                    if (request.IsBatch) {
                        contextService.addBatchRequest({
                            requestId: request.BatchRequestId,
                            requestName: request.BatchRequestName,
                            requestStatus: 'inserted'
                        });
                    } else {
                        vm.refreshAction();
                    }

                }).catch(function (error) {
                    vm.removeAlertFromList(true);
                    vm.setErrorMsg(error);
                });
        }
        // END


        vm.vhrEventLifeStatusUpdateAction = function () {
            getChangeTargetVhrEventsLifeStatus(gridResult.dataIds, moduleTargetId)
                .then(function (lifeStatusEnabled) {
                    if (lifeStatusEnabled) {
                        if (Lazy(lifeStatusEnabled).contains(lifeStatusCompletedId)) {
                            openModalChangeState();
                        } else {
                            navigationService.operationNotPermittedModal();
                        }
                    } else {
                        navigationService.operationNotPermittedModal();
                    }
                });
        };

        vm.vhrEventLifeStatusCompleteAction = function () {
            getChangeTargetVhrEventsLifeStatus(gridResult.dataIds, moduleTargetId)
                .then(function (lifeStatusEnabled) {
                    if (lifeStatusEnabled) {
                        if (Lazy(lifeStatusEnabled).contains(lifeStatusCompletedId)) {
                            navigationService.confirmModal(vm.translate("Continue"))
                                .then(function (result) {
                                    if (result) {
                                        if (gridResult.hasSelection && lifeStatusCompletedId) {
                                            updateVhrEventLifeStatusBulk(gridResult.dataIds, moduleTargetId, lifeStatusCompletedId);
                                        }
                                    }
                                });
                        } else {
                            navigationService.operationNotPermittedModal();
                        }
                    } else {
                        navigationService.operationNotPermittedModal();
                    }
                });
        };


        vm.vhrEventLifeStatusInfoAction = function () {
            if (gridResult.isSingleSelection) {
                var vhrEventLifeStatusId = gridResult.data[0].VhrEventLifeStatusId;
                var vhrEventLifeStatus = vhrEventLifeStatusEnumParsed.list.translated;

                var params = new NbsGenericListModalParamObject('NembusEnum', vm.translate('ActionPanelVhrEventLifeStatusInfo'));
                params.readonly = true;
                params.selectedIds = [vhrEventLifeStatusId];
                navigationService.navToGenericListModal(vm.suspendDirty, params, vhrEventLifeStatus);
            }
        };

        vm.createGridEvent = function (result) {
            vm.vhrEventBarDataContext = {
                commonBarDatasource: result.barDatasource,
                formSummary: {
                    dataContext: vm.formSummary,
                    isVisible: true
                },
                appStoreBw: appStoreBw,
                appStoreCode: appStoreCode,
                defaultKeys: {
                    columnsKey: null, sortingKey: null
                }
            };
        };

        vm.gridUiOnChanges = function (result) {
            vm.refreshGridUiComponent = {
                refreshKey: new Date().getTime(),
                uiData: result
            }
        }

        function setActionButtonsStatus(isEnabled) {
            if (isEnabled === null) {
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventLifeStatusModify, gridResult.hasSelection && vhrEventPermission.canManageLifeStatus);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventFailureLifeStatusComplete, gridResult.hasSelection && vhrEventPermission.canManageLifeStatus);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventLifeStatusInfo, gridResult.isSingleSelection && vhrEventPermission.canManageLifeStatus);
                ribbon.setRibbonStatusButton(ribbon.Buttons.ChangeDate, gridResult.hasSelection && vhrEventPermission.canManageStartDate);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventExecutionApprove, gridResult.hasSelection && vhrEventPermission.canManageExecution);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventExecutionModify, gridResult.hasSelection && vhrEventPermission.canManageExecution);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventExecutionInfo, gridResult.isSingleSelection && vhrEventPermission.canManageExecution);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventApprovalAuthorize, gridResult.hasSelection && vhrEventPermission.canManageApproval);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventApprovalModify, gridResult.hasSelection && vhrEventPermission.canManageApproval);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventApprovalInfo, gridResult.isSingleSelection && vhrEventPermission.canManageApproval);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventCustomerApprovalAuthorize, gridResult.hasSelection && vhrEventPermission.canManageCustomerApproval);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventCustomerApprovalModify, gridResult.hasSelection && vhrEventPermission.canManageCustomerApproval);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventCustomerApprovalInfo, gridResult.isSingleSelection && vhrEventPermission.canManageCustomerApproval);
                ribbon.setRibbonStatusButton(ribbon.Buttons.WarningModify, gridResult.hasSelection);
                ribbon.setRibbonStatusButton(ribbon.Buttons.WarningDelete, gridResult.hasSelection);
                ribbon.setRibbonStatusButton(ribbon.Buttons.WarningInfo, gridResult.isSingleSelection);
                ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, gridResult.isSingleSelection);
            } else {
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventLifeStatusModify, isEnabled && vhrEventPermission.canManageLifeStatus);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventFailureLifeStatusComplete, isEnabled && vhrEventPermission.canManageLifeStatus);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventLifeStatusInfo, isEnabled && vhrEventPermission.canManageLifeStatus);
                ribbon.setRibbonStatusButton(ribbon.Buttons.ChangeDate, isEnabled && vhrEventPermission.canManageStartDate);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventExecutionApprove, isEnabled && vhrEventPermission.canManageExecution);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventExecutionModify, isEnabled && vhrEventPermission.canManageExecution);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventExecutionInfo, isEnabled && vhrEventPermission.canManageExecution);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventApprovalAuthorize, isEnabled && vhrEventPermission.canManageApproval);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventApprovalModify, isEnabled && vhrEventPermission.canManageApproval);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventApprovalInfo, isEnabled && vhrEventPermission.canManageApproval);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventCustomerApprovalAuthorize, isEnabled && vhrEventPermission.canManageCustomerApproval);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventCustomerApprovalModify, isEnabled && vhrEventPermission.canManageCustomerApproval);
                ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventCustomerApprovalInfo, isEnabled && vhrEventPermission.canManageCustomerApproval);
                ribbon.setRibbonStatusButton(ribbon.Buttons.WarningModify, isEnabled);
                ribbon.setRibbonStatusButton(ribbon.Buttons.WarningDelete, isEnabled);
                ribbon.setRibbonStatusButton(ribbon.Buttons.WarningInfo, isEnabled);
                ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, isEnabled);
            }

            ribbon.refreshStatusButtons();
        }

        vm.modalOk = function (modalInstance) {
            vm[modalInstance].close();
        };

        vm.modalCancel = function (modalInstance) {
            vm[modalInstance].dismiss();
        };

        /*
        * CoreVhrEventConfigs table
        * change state is enabled only if status is configured in table
        */
        function canChangeState(state) {
            return Lazy(vm.currentLifeStatusesEnabled).contains(Number(state.value));
        }

        function openModalSendMail() {
            //
        }

        function openModalChangeState() {
            var modalDs = Lazy(vm.currentStates).filter(canChangeState).toArray();
            Lazy(modalDs).each((status) => {
                status.selected = false;
            });

            var params = new NbsGenericListModalParamObject('NembusEnum', vm.translate('ActionPanelVhrEventLifeStatusUpdate'));
            navigationService.navToGenericListModal(vm.suspendDirty, params, modalDs)
                .then(function (result) {
                    if (result.hasSelection) {
                        var currentStatusLifeSelectedId = Lazy(result.selectedValues).first();

                        if (gridResult.hasSelection && currentStatusLifeSelectedId) {
                            updateVhrEventLifeStatusBulk(gridResult.dataIds, moduleTargetId, currentStatusLifeSelectedId);
                        }
                    }
                });
        }

        function canChangeTargetVhrEventsStartDate(vhrEventsId, targetDate, moduleTargetId) {
            var filter = { VhrEventsId: vhrEventsId, ModuleTargetId: moduleTargetId, StartDate2: targetDate };
            var defer = $q.defer();

            vhreventService.canChangeTargetVhrEventsStartDate(filter)
                .then(function (data) {
                    defer.resolve(data.results[0]);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getChangeTargetVhrEventsLifeStatus(vhrEventsId, moduleTargetId) {
            var filter = { VhrEventsId: vhrEventsId, ModuleTargetId: moduleTargetId };
            var defer = $q.defer();

            vhreventService.getChangeTargetVhrEventsLifeStatus(filter)
                .then(function (data) {
                    vm.currentLifeStatusesEnabled = data.results;
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrEventLabels(forceReload, appStoreBw) {
            var filter = { AppStoreBw: appStoreBw };
            var defer = $q.defer();

            vhreventService.getVhrEventLabels(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }
    }

    vhrEventsVhrList.$inject = injectParams;
    vhrEventsVhrList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('vhrEventsVhrList', vhrEventsVhrList);
});