﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'reportService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var categoryQrCodeListReportForm = function ($q, $scope, $timeout, $injector, foundationService, reportService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        var checkStateChangeParams = { dirtyFlagIsDisabled: vm.isDirtyDisabled };
        checkStateChangeService.checkFormOnStateChange($scope, foundationService, checkStateChangeParams);

        var currentSystemCategoryCode = 'ALB_IMP'; //navigationService.stateParams().systemCategoryCode;
        var viewMode = navigationService.currentViewMode();

        // all form items are enabled
        vm.setIsEditingReport();

        // entity to validate
        vm.categoryReportFormClientData = foundationService.dataContext.categoryReportFormClientDatas.add({
            Id: NbsGuidManager.newGuid()
        });

        var selectionParams = { destModalKey: 'category', isMultiselect: false, systemCategoryCode: currentSystemCategoryCode, viewKind: 'folder' };
        vm.nbsSelectionTreeviewWithModalCategory = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategory.setOnSelectFnCallback(function () {
            if (vm.nbsSelectionTreeviewWithModalCategory.selectedIds) {
                reportService.currentReport.reportArgs.Ds1_Arg1.CategoryId = vm.nbsSelectionTreeviewWithModalCategory.selectedIds[0];
            } else {
                reportService.currentReport.reportArgs.Ds1_Arg1.CategoryId = null;
            }

            vm.categoryReportFormClientData.CategoryId = reportService.currentReport.reportArgs.Ds1_Arg1.CategoryId;
        });

        // posted filter
        vm.currentReportFilter = {};

        // set title of current controller in report definition detail view
        navigationService.setControllerTitle();

        var customKey = '1';
        if (viewMode === 'a4_2x4') {
            customKey = '2';
        }
        if (viewMode === 'a4_3x8') {
            customKey = '3';
        }

        reportService.currentReport.reportArgs = {
            Ds1_Arg1: {//VirtualEquipmentFilter
                ActTargetIds: null,
                CategoriesId: null
            },
            Ds1_Arg2: {//QueryOptions
                CustomKey: customKey       // in this case, used to set number of columns in report to cycle
            }
        };

        loadData(true);
        function loadData(forceReload) {
            var funcsPromises = [];
            
            $q.all(funcsPromises)
                .then(function () {
                    reportService.currentReport.formEntityToValidate = vm.categoryReportFormClientData;

                    // bind report object to ui filter
                    vm.currentReportFilter = reportService.currentReport.reportArgs.Ds1_Arg1;
                });
        }
    };

    categoryQrCodeListReportForm.$inject = injectParams;
    categoryQrCodeListReportForm.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('categoryQrCodeListReportForm', categoryQrCodeListReportForm);
});
