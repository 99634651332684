﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'audService', 'vhreventService', 'utilService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function surveyCreationRequestDetail($q, $scope, $timeout, $injector, audService, vhreventService, utilService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, audService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, audService, { dirtyFlagIsSuspended: vm.isDirtySuspended });

        var ribbon = new NbsAudRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        // function declared in nbs-vm
        // used to overwrite validation translations
        vm.overloadTranslateValidation(audService.manager, 'SurveyCreationRequest', 'RecurrenceStartDate', function () {
            if (vm.radioCompileTypeList.selected === vm.surveyCreationPatternObj.Recurrent) {
                return vm.translate('SurveyCreationRequest.RecurrenceStartDate');
            }
            return vm.translate('SurveyCreationRequestDetail.SurveyCreationRequestExpirationDate');
        });

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var surveyCreationRequestStatusEnum = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyCreationRequestStatus, null, vm.translate).obj.normal.name_value;
        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
        var dateIntervalsEnumParsed = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.DateInterval_timeMode, null, vm.translate);

        vm.dateIntervals = Lazy(dateIntervalsEnumParsed.list.normal).map((item) => { return { value: item.value, name: vm.translate(item.name) }; }).toArray();
        vm.surveyCreationSchedulingTypeIsDetailed = Nembus.Aud.Library.SSV.IsAuditSchedulingScenarioDetailed();
        vm.surveyCreationRequestTypeEnum = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyCreationRequestType, null, vm.translate).obj.normal.name_value;
        vm.surveyCreationPatternObj = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyCreationPattern, null, vm.translate).obj.normal.name_value;
        vm.surveyCreationAssignmentTypeObj = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyCreationAssignmentType, null, vm.translate).obj.normal.name_value;

        vm.isProcessEnabled = Nembus.Common.Library.auth.isProcessEnabled(contextService.virtualContext, moduleTargetId);

        var radioTargetTypeDatasource = [
            { value: vm.surveyCreationRequestTypeEnum.NewWithTargetsSelected, label: 'Existent' },
            { value: vm.surveyCreationRequestTypeEnum.NewWithTargetFilled, label: 'New' }
        ];
        vm.radioTargetTypeList = new NbsRadioList(vm.translate);
        vm.radioTargetTypeList.addData(radioTargetTypeDatasource, 'value', 'label');

        var radioCompileTypeDatasource = [
            { value: vm.surveyCreationPatternObj.Single, label: 'Single' },
            { value: vm.surveyCreationPatternObj.Recurrent, label: 'Recurrent' },
            { value: vm.surveyCreationPatternObj.OnDemand, label: 'OnCall' }
        ];
        vm.radioCompileTypeList = new NbsRadioList(vm.translate);
        vm.radioCompileTypeList.addData(radioCompileTypeDatasource, 'value', 'label');

        var radioAssignmentTypeDatasource = [
            { value: vm.surveyCreationAssignmentTypeObj.Parallel, label: 'Common' },
            { value: vm.surveyCreationAssignmentTypeObj.Exclusive, label: 'Individual' }
        ];
        vm.radioAssignmentTypeList = new NbsRadioList(vm.translate);
        vm.radioAssignmentTypeList.addData(radioAssignmentTypeDatasource, 'value', 'label');

        //#region Multiselect with grid selection
        var selectionParams = {
            stateTo: 'app.nav.surveytemplateslistmodal',
            destModalKey: 'surveyTemplate', isMultiselect: false
        };
        vm.nbsSelectionGridWithModalSurvey = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalSurvey.setOnSelectFnCallback(function () {
            if (vm.nbsSelectionGridWithModalSurvey.selectedIds) {
                setSurveyTemplateSelected(vm.nbsSelectionGridWithModalSurvey.selectedItems[0].item);
            } else {
                setSurveyTemplateSelected(null);
            }
        });

        selectionParams = { destModalKey: 'vhr', entityMode: 'Target', translateKey: 'Target', isMultiselect: true };
        vm.nbsSelectionGridWithModalTarget = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalTarget.setOnSelectFnCallback(function () {
            vm.surveyCreationRequest.VirtualHumanResourcesSerialized = null;
            if (vm.nbsSelectionGridWithModalTarget.selectedIds) {
                vm.surveyCreationRequest.VirtualHumanResourcesSerialized = JSON.stringify(vm.nbsSelectionGridWithModalTarget.selectedIds);
            }
        });
        //#endregion

        var currentDate = moment().toDate();
        currentDate.setHours(0)
        currentDate.setMinutes(0)
        currentDate.setSeconds(0)
        currentDate.setMilliseconds(0)
        currentDate = Nembus.Common.Library.converter.date.localAsUtc(currentDate);

        vm.setIsAdding();
        vm.surveyCreationRequest = audService.dataContext.surveyCreationRequests.add({
            SurveyCreationRequestId: NbsGuidManager.newGuid(),
            SurveyCreationRequestStatus: surveyCreationRequestStatusEnum.Inserted,
            RecurrenceStartDate: currentDate,
            RecurrenceEndDate: currentDate,
            VhrEventTypeId: null,
            VhrEventGroupId: null,
            VhrEventTemplateId: null,
            VirtualCompanyId: virtualCompanyId,
            SurveyCreationRequestAssignmentTypeId: vm.surveyCreationAssignmentTypeObj.Parallel,
            SurveyCreationRequestTypeId: vm.surveyCreationRequestTypeEnum.NewWithTargetsSelected,
            SurveyCreationRequestCompileTypeId: vm.surveyCreationPatternObj.Single,
            ModuleTargetId: moduleTargetId
        });

        if (vm.surveyCreationSchedulingTypeIsDetailed) {
            vm.surveyCreationRequest.EventStartTime = 'PT8H0M';
            vm.surveyCreationRequest.SurveyDurationQuota = 30;
            vm.surveyCreationRequest.SurveyDurationUnitId = dateIntervalsEnumParsed.obj.normal.name_value.Minute;
        }

        loadData(true);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getVhrEventTypes(false, appStoreBw));
            funcsPromises.push(getVhrEventGroups(false, appStoreBw));
            funcsPromises.push(getSurveyTemplateGroups(false));

            $q.all(funcsPromises)
                .then(function () {
                    // set DrbacUserGroups and AppUser objects for Auditors
                    vm.nbsAssignmentAuditorsDrbacModalObj = new NbsAssigneeSelectorModal({
                        translator: vm.translate,
                        suspendDirty: vm.suspendDirty,
                        navigationService: navigationService,
                        contextUserForSelection: null,
                        getServerVarFn: Nembus.Common.Library.server.getServerVar
                    });
                    vm.nbsAssignmentAuditorsDrbacModalObj.setVisibilityServerVar('ssv_audit_survey_assignee_visibility_selection');
                });
        }

        function getVhrEventTypes(forceReload, appStoreBw) {
            var filter = { AppStoreBw: appStoreBw };
            var defer = $q.defer();

            vhreventService.getVhrEventTypes(filter, forceReload)
                .then(function (data) {
                    vm.vhrEventTypes = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrEventGroups(forceReload, appStoreBw) {
            var filter = { AppStoreBw: appStoreBw };
            var defer = $q.defer();

            vhreventService.getVhrEventGroups(filter, forceReload)
                .then(function (data) {
                    vm.vhrEventGroups = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function setSurveyTemplateSelected(surveyTemplate) {
            vm.surveyCreationRequest.VhrEventGroupId = null;

            if (!surveyTemplate) {
                vm.surveyCreationRequest.SurveyTemplateIdSerialized = null;
            }

            if (surveyTemplate) {
                vm.surveyCreationRequest.SurveyTemplateIdSerialized = JSON.stringify([surveyTemplate.SurveyId]);

                // Find VhrEventGroup by code in vhrEventGroupsList
                var surveyTemplateGroup = Lazy(vm.surveyTemplateGroups).findWhere({ SurveyTemplateGroupId: surveyTemplate.SurveyTemplateGroupId });
                if (surveyTemplateGroup) {
                    var vhrEventGroup = Lazy(vm.vhrEventGroups).findWhere({ VhrEventGroupCode: surveyTemplateGroup.SurveyTemplateGroupCode });
                    if (vhrEventGroup) {
                        vm.surveyCreationRequest.VhrEventGroupId = vhrEventGroup.VhrEventGroupId;
                    }
                }
            }
        }

        function getSurveyTemplateGroups(forceReload) {
            var filter = {};
            var defer = $q.defer();

            audService.getSurveyTemplateGroups(filter, forceReload)
                .then(function (data) {
                    vm.surveyTemplateGroups = data.results;
                    vm.surveyTemplateGroupObjForBind = Nembus.Common.Library.parser.objFromList(vm.surveyTemplateGroups, 'SurveyTemplateGroupId', 'SurveyTemplateGroupName');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        vm.cronExpressionOnClick = function () {
            navigationService.navToModal(vm.suspendDirty, 'app.nav.cronexpressiondetail',
                { 'cronstring': vm.surveyCreationRequest.CronExpression }
            ).then(function (result) {
                var cronObj = result.data;
                vm.surveyCreationRequest.CronExpression = cronObj.getCronExpression();
                vm.surveyCreationRequest.CronExpressionLabel = cronObj.getCronLabel();
            });
        };

        // get reference of submitaction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parents = vm.submitAction;
        vm.submitAction = function () {
            $timeout(() => {
                if (vm.surveyCreationRequest.SurveyCreationRequestCompileTypeId !== vm.surveyCreationPatternObj.Recurrent) {
                    vm.surveyCreationRequest.RecurrenceEndDate = vm.surveyCreationRequest.RecurrenceStartDate;
                }

                vm.surveyCreationRequest.entityAspect.validateEntity();
                if (vm.surveyCreationRequest.entityAspect.hasValidationErrors) {
                    return;
                }

                if (vm.surveyCreationRequest.SurveyCreationRequestTypeId === vm.surveyCreationRequestTypeEnum.NewWithTargetsSelected) {
                    if (!vm.nbsSelectionGridWithModalTarget.selectedIds
                        || vm.nbsSelectionGridWithModalTarget.selectedIds.length === 0) {

                        vm.removeAlertFromList(true);
                        vm.setErrorMsg({ isMessageVisible: true, message: vm.translate('SurveyCreationRequestDetail.SurveyCreationRequestSelectTarget') });
                        return;
                    }
                }

                // set list of user groups selected on request (auditors)
                if (vm.nbsAssignmentAuditorsDrbacModalObj.drbacUserGroupAreSelected()) {
                    vm.surveyCreationRequest.DrbacUserGroupsSerialized = JSON.stringify(vm.nbsAssignmentAuditorsDrbacModalObj.drbacUserGroupMultiselectSelectedIds());
                }

                // set list of user selected on request (auditors)
                if (vm.nbsAssignmentAuditorsDrbacModalObj.appUserAreSelected()) {
                    vm.surveyCreationRequest.AppUsersSerialized = JSON.stringify(vm.nbsAssignmentAuditorsDrbacModalObj.appUserMultiselectSelectedIds());
                }

                var batchName = {};
                batchName.BatchRequestName = vm.translate('SurveyCreationRequestBatchName');
                batchName.BatchRequestDescription = batchName.BatchRequestName;
                //batchName.BatchRequestExtra = vm.translate("ItemsSelected") + ':' + surveysId.length;
                vm.surveyCreationRequest.BatchRequestName = JSON.stringify(batchName);

                parents()
                    .then(function (result) {
                        if (vm.surveyCreationRequest.IsBatch) {
                            contextService.addBatchRequest({
                                requestId: vm.surveyCreationRequest.BatchRequestId,
                                requestName: vm.translate('SurveyCreationRequestBatchName'),
                                requestStatus: 'inserted',
                                onCompletedAutoAction: null,    // is a function called on completed batch that must return a promise (with jobResult) managed in appPanel
                                detailAction: null             // is a function called from appPanel.cshml that links to batch request detail
                            });
                        }

                        // go to surveyslist (audit events)
                        navigationService.navigateTo("app.nav.surveyslist-tocompile", { 'appStoreCode': appStoreCode, 'vhrEventLifeStatusKey': 'tocompile' });
                    })
                    .catch(function (error) {
                        // error
                        vm.setErrorMsg(error);
                        audService.rejectChanges();
                    });
            });            
        };
    }

    surveyCreationRequestDetail.$inject = injectParams;
    surveyCreationRequestDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('surveyCreationRequestDetail', surveyCreationRequestDetail);
});