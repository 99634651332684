﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'triggerService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var triggerFiredsList = function ($q, $scope, $timeout, $injector, triggerService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, triggerService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, triggerService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var triggerFiredStatusEnumParsed = Nembus.Common.Library.util.parseEnum(triggerService.dataContext.enumTypes.TriggerFiredStatus, 'TriggerFired', vm.translate);
        var triggerFiredResultEnumParsed = Nembus.Common.Library.util.parseEnum(triggerService.dataContext.enumTypes.TriggerFiredResult, 'TriggerFired', vm.translate);
        var triggerFiredStatusObj = triggerFiredStatusEnumParsed.obj.translated.value_name;
        var triggerFiredResultObj = triggerFiredResultEnumParsed.obj.translated.value_name;

        vm.currentTriggerFireds = [];

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('triggerfired', null);
        nbsGridDatacontext.viewCommonBar();
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getTriggerInstances(forceReload));
            funcsPromises.push(getTriggerFireds(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.currentTriggerInstanceObj = Nembus.Common.Library.parser.objFromList(results[0], 'TriggerInstanceId', 'TriggerInstanceName');

                    Lazy(results[1])
                        .each((tf) => {
                            tf.TriggerInstanceName = vm.currentTriggerInstanceObj[tf.TriggerInstanceId];
                            tf.TriggerFiredStatusName = triggerFiredStatusObj[tf.TriggerFiredStatusId];
                            tf.TriggerFiredResultName = triggerFiredResultObj[tf.TriggerFiredResultId];
                        });
                    vm.currentTriggerFireds = results[1];

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.currentTriggerFireds
                    }
                });
        }

        vm.refreshAction = function () {
            getTriggerFireds(true);
        };

        vm.queryCellInfo = function (args) {
            switch (args.column.field) {
                case 'CreationDate':
                    args.cell.innerHTML = Nembus.Common.Library.converter.date.formatDateTimeLocalToUtc(args.data[args.column.field], contextService.virtualContext.cultureInfo);
                    break
            }
        };

        function getTriggerInstances(forceReload) {
            var filter = {};
            var defer = $q.defer();

            triggerService.getTriggerInstances(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getTriggerFireds(forceReload) {
            var filter = {};
            var defer = $q.defer();

            triggerService.getTriggerFireds(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    triggerFiredsList.$inject = injectParams;
    triggerFiredsList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('triggerFiredsList', triggerFiredsList);
});
