﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'productionService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function routesList($q, $scope, $timeout, $injector, productionService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, productionService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return routeSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, productionService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var routeSchedulingTypeEnum = productionService.dataContext.enumTypes.RouteSchedulingType;
        var routeStatusEnum = productionService.dataContext.enumTypes.RouteStatus;
        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);

        ribbon.setRibbonStatusButton(ribbon.Buttons.New,  false);
        ribbon.refreshStatusButtons();

        var routeSchedulingTypes = Nembus.Common.Library.util.parseEnum(routeSchedulingTypeEnum, null, vm.translate);
        var routeStatuses = Nembus.Common.Library.util.parseEnum(routeStatusEnum, null, vm.translate);
        vm.routeSchedulingTypeForBind = routeSchedulingTypes.obj.translated.value_name;
        vm.routeStatusForBind = routeStatuses.obj.translated.value_name;

        var routeSelected = null;

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            $q.all(funcsPromises)
                .then(function () {
                    var ejDataManager = getDataManager({ AppStoreBw: appStoreBw }, ejDataManager);

                    var nbsGridDatacontext = new NbsGrid(vm.translate);
                    nbsGridDatacontext.configureGrid('route', null);
                    nbsGridDatacontext.viewCommonBar();
                    nbsGridDatacontext.setDatasource(ejDataManager, true);
                    vm.nbsGridDatacontext = nbsGridDatacontext;

                    vm.currentDatasDm = ejDataManager.dmgrid;
                });
        }

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.routedetail-" + vm.viewMode, { 'routeId': args.data.RouteId, 'appStoreCode': appStoreCode });
        };

        vm.newAction = function () {
            navigationService.navigateTo("app.nav.routedetail-" + vm.viewMode, { 'routeId': null, 'appStoreCode': appStoreCode });
        };

        vm.refreshAction = function () {
            $timeout(() => {
                vm.forceGridRefresh = new Date().getTime();
            });
        };

        vm.gridSelection = function (args) {
            routeSelected = args.data;
        };

        function getDataManager(filter, dmObj) {
            if (dmObj) {
                dmObj.complexParams = [{ filter: filter }];
                return dmObj;

            }
            dmObj = productionService.getRouteDtoPagedDmQuery();
            dmObj.dmgrid = productionService.getRouteDtoPagedDm();
            var ejDataManager = dmObj.dmgrid;

            ejDataManager.complexParams.push({ filter: filter });
            ejDataManager.fieldNameReplacer = {
                RouteStatusName: 'RouteStatusId',
                RouteSchedulingTypeName: 'RouteSchedulingTypeId',
                CronExpressionLabel: 'CronExpression'
            };
            ejDataManager.onWhereCustomPredicate = {
                RouteStatusName: {
                    FieldType: productionService.FilterMenu.FieldType.Enum,
                    ReplaceFieldNameWith: 'RouteStatusId',
                    Datasource: vm.routeStatusForBind
                },
                RouteSchedulingTypeName: {
                    FieldType: productionService.FilterMenu.FieldType.Enum,
                    ReplaceFieldNameWith: 'RouteSchedulingTypeId',
                    Datasource: vm.routeSchedulingTypeForBind
                },
                CronExpressionLabel: {
                    FieldType: productionService.FilterMenu.FieldType.Enum,
                    ReplaceFieldNameWith: 'CronExpression',
                    Datasource: null
                }
            };
            ejDataManager.processResponseData = function (data) {
                if (data) {
                    dmObj.dm.currentViewChildGridData = [];

                    Lazy(data.results).each((item) => {
                        item.RouteSchedulingTypeName = vm.routeSchedulingTypeForBind[item.RouteSchedulingTypeId];
                        item.RouteStatusName = vm.routeStatusForBind[item.RouteStatusId];
                    });
                    Nembus.Common.Library.parser.AddCronExpressionLabelToList(data.results, 'CronExpression', vm.translate);
                }
            };
            ejDataManager.freeSearchFields = ['RouteCode', 'RouteName', 'SchedulingGroupName', 'CronExpression'];
            ejDataManager.freeSearchFieldsNumber = ['RouteId'];
            
            dmObj.dm.onWhereCustomPredicate = ejDataManager.onWhereCustomPredicate;
            dmObj.dm.fieldNameReplacer = ejDataManager.fieldNameReplacer;
            dmObj.dm.complexParams = ejDataManager.complexParams;
            dmObj.dm.processResponseData = ejDataManager.processResponseData;
            return dmObj;
        }
    }

    routesList.$inject = injectParams;
    routesList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('routesList', routesList);
});