// @ts-ignore
var Lazy = require('lazy.js');
import { Directive, ElementRef, Injector } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { NbsVm2 } from '../../common/controllers/nbs-vm2';

class ModuleHomeController extends NbsVm2 {
    currentModule: any;
    isCustomSections: boolean;
    allNavigationNodes: any;
    cmsService: any;
    array: any;

    constructor($injector: any, cmsService: any, navigationService: any) {
        super(cmsService, $injector)

        this.cmsService = cmsService;
        this.currentModule = this.navigationService.currentViewMode();
        this.isCustomSections = this.navigationService.stateParams().isCustomSections === 'true' ? true : false;
        this.allNavigationNodes = cmsService.dataContext.navigationNodes.get();

        if (this.currentModule) {
            var moduleNodes = this.buildTree(Lazy(this.allNavigationNodes).where({ ModuleGroupCode: this.currentModule }).toArray());

            if (this.isCustomSections) {
                moduleNodes = this.getModuleHome(moduleNodes);
            } else {
                moduleNodes = this.getModuleHomeWithAllSections(moduleNodes);
            }

            this.array = this.chunk(moduleNodes, 2);
        }
        else {
            this.array = [];
        }
    }

    navigateTo(currentNavNodeId) {
        var currentNavNode = Lazy(this.allNavigationNodes).findWhere({ NavigationNodeId: currentNavNodeId });
        if (currentNavNode) {
            this.cmsService.setBreadcrumbTree(currentNavNode.NavigationNodeCode);
            this.cmsService.refreshBreadCrumb();
            this.navigationService.navigateTo(currentNavNode.NavigationState, this.castParam(currentNavNode.NavigationParam));
            //if (NbsCommonUI.isUnderBreakpoint_md()) {
            //    NbsCommonUI.sidebarClose();
            //}
        }
    };

    private chunk(arr: any, size: number) {
        var newArr = [];
        for (var i = 0; i < arr.length; i += size) {
            newArr.push(arr.slice(i, i + size));
        }
        return newArr;
    };

    // this function add module home section nodes if are been deleted for NavigationTree
    private getModuleHomeWithAllSections(moduleNodes) {
        //CommonProcedures, ModuleReports, InformationsAndSearching, ModuleSetup, PeriodicProcedures
        var moduleSections = [
            {
                NavigationNodeCode: this.currentModule + '.CommonProcedures',
                // @ts-ignore
                NavigationNodeName: window.$T(this.navigationService.currentCmsViewCode(), 'CommonProcedures'),
                NavigationNodeKind: 129,
                Order: 10
            },
            {
                NavigationNodeCode: this.currentModule + '.ModuleReports',
                // @ts-ignore
                NavigationNodeName: window.$T(this.navigationService.currentCmsViewCode(), 'ModuleReports'),
                NavigationNodeKind: 129,
                Order: 20
            },
            {
                NavigationNodeCode: this.currentModule + '.InformationsAndSearching',
                // @ts-ignore
                NavigationNodeName: window.$T(this.navigationService.currentCmsViewCode(), 'InformationsAndSearching'),
                NavigationNodeKind: 129,
                Order: 30
            },
            {
                NavigationNodeCode: this.currentModule + '.ModuleSetup',
                // @ts-ignore
                NavigationNodeName: window.$T(this.navigationService.currentCmsViewCode(), 'ModuleSetup'),
                NavigationNodeKind: 129,
                Order: 40
            },
            {
                NavigationNodeCode: this.currentModule + '.PeriodicProcedures',
                // @ts-ignore
                NavigationNodeName: window.$T(this.navigationService.currentCmsViewCode(), 'PeriodicProcedures'),
                NavigationNodeKind: 129,
                Order: 50
            }
        ];

        Lazy(moduleSections).each((currentModuleSection) => {
            if (!Lazy(moduleNodes)
                .filter((moduleNode) => {
                    return moduleNode.NavigationNodeCode.toLowerCase() === currentModuleSection.NavigationNodeCode.toLowerCase();
                }).some()) {

                moduleNodes.push(currentModuleSection);
            }
        });

        // sort module nodes by order ascending
        var result = Lazy(moduleNodes).sortBy((node) => { return node.Order; }, false).toArray();
        return result;
    }

    // this function add module home section nodes if are been deleted for NavigationTree
    private getModuleHome(moduleNodes) {
        // sort module nodes by order ascending
        var result = Lazy(moduleNodes).sortBy((node) => { return node.Order; }, false).toArray();
        return result;
    }

    private castParam(strParam) {
        if (strParam)
            return eval("(" + strParam + ")");
    }

    private buildTree(plainNodes) {
        for (var i = 0; i < plainNodes.length; i++) {
            plainNodes[i].children = Lazy(plainNodes).where({ ParentNavigationNodeId: plainNodes[i].NavigationNodeId }).toArray();
        }
        return Lazy(plainNodes).where({ Depth: 2 }).toArray();
    } ì
}

let ModuleHomeComponent = {
    selector: "moduleHome",
    templateUrl: 'shell/navigation/moduleHome/_',
    controller: ModuleHomeController,
    controllerAs: 'vm'
};

// @ts-ignore
angular.module('nbs.shell.module').component(ModuleHomeComponent.selector, ModuleHomeComponent);


@Directive({
    selector: 'module-home'
})
export class ModuleHomeDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef, injector: Injector) {
        super('moduleHome', elementRef, injector);
    }
}

