﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'vhreventService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function vhrEventLabelsList($q, $scope, $timeout, $injector, vhreventService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, vhreventService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return vhrEventLabelSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, vhreventService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var vhrEventLabelSelected = null;

        var moduleTargets = Nembus.Rds.Library.Tools.ModuleTargetContext({ ModuleTargetTypeCode: 'DEFAULT' }, contextService);
        vm.moduleTargetObjForBind = Nembus.Common.Library.parser.objFromList(moduleTargets, 'ModuleTargetId', 'ModuleTargetName');

        getVhrEventLabels(false, appStoreBw)
            .then((labels) => {
                var nbsGridDatacontext = new NbsGrid(vm.translate);
                nbsGridDatacontext.configureGrid('vhreventlabel', '');
                nbsGridDatacontext.viewCommonBar();
                nbsGridDatacontext.setDatasource(labels, false);
                vm.nbsGridDatacontext = nbsGridDatacontext;
            });

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.vhreventlabeldetail-" + vm.viewMode, {
                'vhrEventLabelId': args.data.VhrEventLabelId,
                'appStoreCode': appStoreCode
            });
        };

        vm.newAction = function () {
            navigationService.navigateTo("app.nav.vhreventlabeldetail-" + vm.viewMode, {
                'vhrEventLabelId': null,
                'appStoreCode': appStoreCode
            });
        };

        vm.refreshAction = function () {
            getVhrEventLabels(true, appStoreBw)
                .then((labels) => {
                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: labels
                    }
                });
        };

        vm.deleteAction = function () {
            if (!vhrEventLabelSelected) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            vhreventService.deleteEntity(vhrEventLabelSelected);
            vm.submitAction()
                .then(function (saveResult) {
                    vm.refreshAction();
                });           
        };

        vm.gridSelection = function (args) {
            $timeout(() => {
                vhrEventLabelSelected = args.data;

                if (vhrEventLabelSelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, true);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                }

                ribbon.refreshStatusButtons();
            });
        };

        function getVhrEventLabels(forceReload, appStoreBw) {
            var filter = { AppStoreBw: appStoreBw };
            var defer = $q.defer();

            vhreventService.getVhrEventLabels(filter, forceReload)
                .then(function (data) {
                    Lazy(data.results).each((item) => {
                        item.ModuleTargetName = vm.moduleTargetObjForBind[item.ModuleTargetId];
                    });

                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    }

    vhrEventLabelsList.$inject = injectParams;
    vhrEventLabelsList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('vhrEventLabelsList', vhrEventLabelsList);
});