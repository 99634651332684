﻿//same of changeMyPassword + cancel button
'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {


    var injectParams = ['$scope', '$http', '$q', '$location', '$uibModal', 'localStorageService', 'contextService', 'authenticationService', 'rbacService', 'navigationService'];
    var renewMyTwoFactorSecretCode = function ($scope, $http, $q, $location, $uibModal, localStorageService, contextService, authenticationService, rbacService, navigationService) {

        var vm = this;
        nbsVm.call(vm, rbacService, navigationService, contextService, $q, null, null);

        vm.renewSecretCodeDto = {
            Password: null
        };

        vm.renewMyTwoFactorSecretCode = function (currentUserDto) {
            rbacService.renewMyTwoFactorSecretCode(currentUserDto)
            .then(function (result) {
                if (result.results[0] == '1') {
                    //authenticationService.user.changePasswordNextLogin = false;
                    ////have to refresh token info (change virtualcontext with same context)
                    //authenticationService.changeVirtualContext(contextService.virtualContext.CurrentVirtualCompanyId).then(function () {

                    //    navigationService.navToHome();
                    //});

                }
                else {
                    //$scope.modalErrorMessage = 'error';
                }
            });
        }


  
        vm.showRenewSecretCodeModal = function () {
            vm.modalInstance = navigationService.modal().open(vm.modalOptions({
                windowClass: vm.NBS_MODAL_CLASS_FORM_MD,
                templateUrl: 'requirePasswordTemplate',
                scope: $scope
            }));



            vm.modalInstance.result.then(function (result) {
                vm.renewMyTwoFactorSecretCode(vm.renewSecretCodeDto);
            }, function () {
                //nothing to do 
                //currentUserDto
            });
        }

        vm.modalOk = function () {
            if (vm.renewSecretCodeDto.Password) {
                vm.modalInstance.close({ modalResult: 'ok' });
            }
        };

        vm.modalCancel = function () {
            vm.modalInstance.dismiss();
        }
    };

    renewMyTwoFactorSecretCode.$inject = injectParams;
    renewMyTwoFactorSecretCode.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rbac.module').controller('renewMyTwoFactorSecretCode', renewMyTwoFactorSecretCode);
});