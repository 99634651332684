﻿/*
 *If available, a (authentication) token stored in localStorageService.get('authorizationData') is appended to the headers on every request. 
 *I like to place this code in the application’s run() method
*/

'use strict';

define([], function () {

    var injectParams = ['$q', '$location', '$timeout', 'authenticationService', 'contextService', 'navigationService', '$injector'];

    function interceptToken($q, $location, $timeout, authenticationService, contextService, navigationService, $injector) {

        var authInterceptorServiceFactory = {};

        var _request = function (config) {
            var defer = $q.defer();
    
            //console.log('REQ: '+config.url);
            if (config.url.substring(0, 7) === 'breeze/') {
                if (!navigator.onLine) {
                    return navigationService.offlineModal();
                }
                //add bearer only for webapi request
                //for mvc view use token
                config.headers = config.headers || {};

                var authData = authenticationService.getAuthData();
                if (authData) {
                    config.headers.Authorization = 'Bearer ' + authData.access_token;
                }
            }
            //else {
            //    defer.resolve(config);
            //    $location.path('auth/login');
            //}

            //set timeout to 1000 or something else when customer forget to pay subscription :) :) :)
            //$timeout(function () { deferred.resolve(config); },1000);
            var timeout = 0;
            if (contextService.isVirtualContextAlreadySet()
                && !contextService.virtualContext.IsLicenseValid) {
                timeout = 1500;
            }


            $timeout(function () { defer.resolve(config); }, timeout);
            return defer.promise;
        };
        var _responseError = function (rejection) {

            if (rejection.status === 401) {
                //authenticationService.setRedirAfterLogin($location.$$path);
                authenticationService.logout();
                authenticationService.redirectToLogin();
            }
            else if (rejection.status === 403) {
                //forbidden
                console.log(rejection.status);
                $location.path('/');
            }
            else {
                console.log(rejection.status);
                console.log(rejection.config);
                console.log(rejection.statusText);
            }
            return $q.reject(rejection);
        };

        var _responseOk = function (response) {
            var deferred = $q.defer();
            var currentPath = navigationService.currentPath();
            var responseUrl = response.config.url;
            var haveToCheckContext = false;
            //var $uibModal;
			/*
			if (responseUrl.indexOf('Main/App') >= 0) {
				//check if have to load context / navigation nodes 
				//when request is for App view because is loaded when 
				//perform browser refresh
				haveToCheckContext = true;
			}

			haveToCheckContext = haveToCheckContext && !contextService.loadingVirtualContext();
			*/
			/*
			if (haveToCheckContext) {
				checkContext().then(function () {
					// Asynchronous operation succeeded, modify response accordingly
					deferred.resolve(response);
				}, function () {
					// Asynchronous operation failed, modify response accordingly
					deferred.resolve(response);
				});
			}
			else {
			*/
            deferred.resolve(response);
            //}
            return deferred.promise;
        };

        authInterceptorServiceFactory.request = _request;
        authInterceptorServiceFactory.response = _responseOk;
        authInterceptorServiceFactory.responseError = _responseError;

        $.ajaxPrefilter(function (options, originalOptions, jqXHR) {
            if (!navigator.onLine) {
                return navigationService.offlineModal();
            }

            $('#loading').show();
            jqXHR.setRequestHeader('Authorization', "Bearer " + authenticationService.getAuthData());
            // Don't infinitely recurse
            originalOptions._retry = isNaN(originalOptions._retry)
                ? 4//Common.auth.maxExpiredAuthorizationRetries
                : originalOptions._retry - 1;

            // set up to date authorization header with every request
            //jqXHR.setRequestHeader("Authorization", Common.auth.getAuthorizationHeader());

            // save the original error callback for later
            if (originalOptions.error)
                originalOptions._error = originalOptions.error;

            // overwrite *current request* error callback
            options.error = $.noop();

            // setup our own deferred object to also support promises that are only invoked
            // once all of the retry attempts have been exhausted
            var dfd = $.Deferred();
            jqXHR.done(function () {
                $('#loading').hide();
                dfd.resolve;
            });

            // if the request fails, do something else yet still resolve
            jqXHR.fail(function () {
                var args = Array.prototype.slice.call(arguments);
                $('#loading').hide();
                if (jqXHR.status === 401 && originalOptions._retry > 0) {

                    // refresh the oauth credentials for the next attempt(s)
                    // (will be stored and returned by Common.auth.getAuthorizationHeader())
                    //Common.auth.handleUnauthorized();
                    authenticationService.refreshToken()
                        .then(
                            function () {
                                // retry with our modified
                                $.ajax(originalOptions).then(dfd.resolve, dfd.reject);
                            }
                        )
                        .catch(
                            //else redirect to login
                            function () {
                                authenticationService.logout();
                                if (contextService.isVirtualContextAlreadySet()) {
                                    authenticationService.sessionExpired();
                                }
                                else {
                                    authenticationService.redirectToRootLocation();
                                }
                                if (originalOptions._error)
                                    dfd.fail(originalOptions._error);
                                dfd.rejectWith(jqXHR, args);
                            }
                        );



                } else {
                    // add our _error callback to our promise object
                    if (originalOptions._error)
                        dfd.fail(originalOptions._error);
                    dfd.rejectWith(jqXHR, args);
                }
            });

            // NOW override the jqXHR's promise functions with our deferred
            return dfd.promise(jqXHR);
        });


        return authInterceptorServiceFactory;
    }

    interceptToken.$inject = injectParams;

    angular.module('nbs.shared.module').factory('authInterceptorService', interceptToken);

});
