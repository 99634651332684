﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'vhreventService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var vhrEventLabelDetail = function ($q, $scope, $timeout, $injector, vhreventService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, vhreventService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, vhreventService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var currentVhrEventLabelId = navigationService.stateParams().vhrEventLabelId;

        vm.currentModuleTargets = Nembus.Rds.Library.Tools.ModuleTargetContext({ AppStoreBw: appStoreBw, ModuleTargetTypeCode: 'DEFAULT' }, contextService);

        if (currentVhrEventLabelId) {
            vm.setIsViewing();

            loadData(true);
        }
        else {
            vm.setIsAdding();
            vm.currentVhrEventLabel = vhreventService.dataContext.vhrEventLabels.add({
                VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId,
                ModuleTargetId: null
            });

            var defaultMT = Lazy(vm.currentModuleTargets).first();
            if (defaultMT) {
                vm.currentVhrEventLabel.ModuleTargetId = defaultMT.ModuleTargetId;
            }
        }

        function loadData(forceReload) {
            var funcsPromises = [];

            funcsPromises.push(getVhrEventLabel(forceReload));

            $q.all(funcsPromises)
                .then(function () {
                });
        }

        function getVhrEventLabel(forceReload) {
            var filter = { VhrEventLabelId: currentVhrEventLabelId };
            var defer = $q.defer();

            vhreventService.getVhrEventLabel(filter, forceReload)
                .then(function (data) {
                    vm.currentVhrEventLabel = data.results[0];
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }        
    };

    vhrEventLabelDetail.$inject = injectParams;
    vhrEventLabelDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('vhrEventLabelDetail', vhrEventLabelDetail);
});