﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'hrbucketService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function hierarchiesList($q, $scope, $timeout, $injector, hrbucketService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, hrbucketService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return hierarchySelected; });
        checkStateChangeService.checkFormOnStateChange($scope, hrbucketService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        ribbon.addTab(new ribbon.Tab("ActionPanelTabManage", [ribbon.GetPublishSchemaSection()]));
        vm.ribbonDatacontext = ribbon;

        var hierarchySelected = null;
        var bucketClassificationEnumParsed = Nembus.Common.Library.util.parseEnum(hrbucketService.dataContext.enumTypes.BucketClassification, 'HrBucket', vm.translate);

        vm.hrHierarchies = [];

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.hierarchydetail-" + vm.viewMode, { 'hrHierarchyId': args.data.HrHierarchyId, 'appStoreCode': null });
        };

        vm.gridSelection = function (args) {
            hierarchySelected = args.data;

            $timeout(() => {
                if (hierarchySelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, !hierarchySelected.IsPublish);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.PublishSchema, true);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.PublishSchema, false);
                }

                ribbon.refreshStatusButtons();
            });            
        };

        vm.newAction = function () {
            navigationService.navigateTo("app.nav.hierarchydetail-" + vm.viewMode, { 'hrHierarchyId': null, 'appStoreCode': null });
        };

        vm.refreshAction = function () {
            loadData(true);
        };

        vm.deleteAction = function () {
            if (!hierarchySelected) {
                return;
            }

            hrbucketService.deleteEntity(hierarchySelected);
            vm.submitAction()
                .then(function (saveResult) {
                    vm.refreshAction();
                });
        };

        vm.publishAction = function () {
            if (!hierarchySelected) {
                return;
            }

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        hrbucketService.hrHierarchyPublish({ HrHierarchyId: hierarchySelected.HrHierarchyId })
                            .then(() => {
                                contextService.getCurrentVirtualContext(true)
                                    .then(() => {
                                        vm.refreshAction();
                                    });
                            });
                    }
                });
        };

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('hrhierarchy', 'list');
        nbsGridDatacontext.viewCommonBar();
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        loadData(true);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getHrHierarchies(forceReload));
            funcsPromises.push(getHrHierarchyConfigs(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.hrHierarchies = results[0];
                    vm.hrHierarchyConfigs = results[1];

                    Lazy(vm.hrHierarchies)
                        .each((item) => {
                            item.BucketClassificationName = bucketClassificationEnumParsed.obj.translated.value_name[item.BucketClassificationId];
                            item.IsPublish = Lazy(vm.hrHierarchyConfigs)
                                .where({ BucketClassificationId: item.BucketClassificationId, HrHierarchyId: item.HrHierarchyId })
                                .some();
                        });

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.hrHierarchies
                    }
                });
        }

        function getHrHierarchies(forceReload) {
            var filter = {};
            var defer = $q.defer();

            hrbucketService.getHrHierarchies(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getHrHierarchyConfigs(forceReload) {
            var filter = {};
            var defer = $q.defer();

            hrbucketService.getHrHierarchyConfigs(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    }

    hierarchiesList.$inject = injectParams;
    hierarchiesList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('hierarchiesList', hierarchiesList);
});