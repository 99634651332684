﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'hrbucketService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function bucketsList($q, $scope, $timeout, $injector, foundationService, hrbucketService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return bucketSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, hrbucketService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var bucketSelected = null;
        var appStoreCode = navigationService.stateParams().appStoreCode;
        var resourceType = navigationService.stateParams().resourceType;
        var bucketClassification = navigationService.stateParams().bucketClassification;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);

        var resourceTypeEnum = Nembus.Common.Library.util.parseEnum(foundationService.dataContext.enumTypes.ResourceType, null, vm.translate).obj.normal.name_value;
        var resourceTypeId = resourceTypeEnum[resourceType];
        var bucketClassificationEnum = Nembus.Common.Library.util.parseEnum(hrbucketService.dataContext.enumTypes.BucketClassification, null, vm.translate).obj.normal.name_value;
        var bucketClassificationId = bucketClassificationEnum[bucketClassification];


        vm.currentHrBuckets = [];

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('hrbucketgenerality', null);
        nbsGridDatacontext.viewCommonBar();
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getHrBuckets(false, appStoreBw, resourceTypeId, bucketClassificationId));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.currentHrBuckets = results[0];

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.currentHrBuckets
                    }
                });
        }

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.bucketftc", { 'virtualHumanResourceId': args.data.VirtualHumanResourceId, 'appStoreCodeFtc': appStoreCode, 'viewMode': vm.viewMode, 'resourceType': resourceType, 'bucketClassification': bucketClassification });
        };

        vm.gridSelection = function (args) {
            bucketSelected = args.data;
        };

        vm.newAction = function () {
            navigationService.navigateTo("app.nav.bucketftc", { 'virtualHumanResourceId': null, 'appStoreCodeFtc': appStoreCode, 'viewMode': vm.viewMode, 'resourceType': resourceType, 'bucketClassification': bucketClassification });
        };

        vm.refreshAction = function () {
            loadData(true);
        };

        function getHrBuckets(forceReload, appStoreBw, resourceTypeId, bucketClassificationId) {
            var filter = {
                AppStoreBw: appStoreBw,
                //ResourceTypeId: resourceTypeId,
                BucketClassificationId: bucketClassificationId
            };
            var defer = $q.defer();

            hrbucketService.getHrBuckets(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    }

    bucketsList.$inject = injectParams;
    bucketsList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('bucketsList', bucketsList);
});