﻿'use strict';

define(['../../common/controllers/nbs-vm', '../lib/triggerInstanceConditionManager'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'triggerService', 'cmsService', 'navigationService', 'contextService', 'drbacService', 'rbacService', 'workflowService'];
    function triggerInstanceMatchDetail($q, $scope, $timeout, $injector, triggerService, cmsService, navigationService, contextService, drbacService, rbacService, workflowService) {
        var vm = this;
        nbsVm.call(vm, triggerService, navigationService, contextService, $q, $injector, $timeout);
        
        var userValues = [];
        var userGroupValues = [];
        var booleanValues = [];
        var workflowStatusValues = [];
        var languageId = contextService.virtualContext.CurrentLanguageId;
        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
        var pParams = navigationService.stateParamsModal().parentParams;
        pParams = pParams ? pParams : navigationService.stateParams();

        var modalDs = navigationService.getModalData('triggerInstanceMatchDetail', vm.viewMode).ds;
        var triggerInstance = modalDs.triggerInstance;
        loadData(false, triggerInstance);

        
        var radioConditionTypeDatasource = [
            { value: 'or', label: 'OR' },
            { value: 'and', label: 'AND' }
        ];
        vm.radioConditionTypeList = new NbsRadioList(vm.translate);
        vm.radioConditionTypeList.addData(radioConditionTypeDatasource, 'value', 'label');


        vm.modalOk = function () {
            var manager = vm.triggerConditionManager.getError();
            if (!manager.hasError) {
                // close modal and returns client data object
                var modalResult = getModalResult(navigationService.navigationActionTypes.modalOk);
                navigationService.closeCurrentModal(modalResult);
            } else {
                vm.alertConfig.isModal = true;
                vm.alertConfig.isReadonly = true;
                vm.removeAlertFromList(true);
                vm.setErrorMsg({ isMessageVisible: true, message: manager.message });
            }
        };

        vm.modalCancel = function () {
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalCancel);
            navigationService.closeCurrentModal(modalResult);
        };

        vm.setConditionType = function () {
            var isOrMatch = vm.radioConditionTypeSelected === 'or';
            vm.triggerConditionManager.setIsOrMatch(isOrMatch);
        };

        function initTriggerInstanceConditionManager() {
            // object used to manage trigger condition
            vm.triggerConditionManager = new TriggerInstanceConditionManager({
                translator: vm.translate,

                triggerService: triggerService,
                triggerInstanceId: triggerInstance.TriggerInstanceId,
                triggerDefinitionProperties: vm.currentTriggerDefinitionProperties,
                triggerDefinitionCode: vm.currentTriggerDefinition.TriggerDefinitionCode,

                userValues: userValues,
                userGroupValues: userGroupValues,
                booleanValues: booleanValues,
                workflowStatusValues: workflowStatusValues,

                thesaurus: vm.thesaurus
            });

            vm.setConditionType();
        }

        function loadData(forceReload, triggerInstance) {
            var funcsPromises = [];
            funcsPromises.push(getTriggerDefinition(forceReload, triggerInstance.TriggerDefinitionId));
            funcsPromises.push(getTriggerDefinitionProperties(forceReload, triggerInstance.TriggerDefinitionId));
            funcsPromises.push(getDrbacUserGroups(forceReload));
            funcsPromises.push(getBooleanValues());
            funcsPromises.push(getCurrentContextAppUsers(forceReload));
            funcsPromises.push(getWorkflowActiveStatuses(forceReload));
            
            $q.all(funcsPromises)
                .then(function () {
                    var cmsDomObjClassFullName = vm.currentTriggerDefinition.CmsDomObjClassFullName;
                    getThesaurusCmsDomObjs(forceReload, cmsDomObjClassFullName, languageId)
                        .then(function () {
                            vm.radioConditionTypeSelected = 'and';
                            initTriggerInstanceConditionManager();
                        });
                });
        }

        function getTriggerDefinition(forceReload, triggerDefinitionId) {
            var filter = { TriggerDefinitionId: triggerDefinitionId };
            var defer = $q.defer();

            triggerService.getTriggerDefinition(filter, forceReload)
                .then(function (data) {
                    vm.currentTriggerDefinition = data.results[0];
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getThesaurusCmsDomObjs(forceReload, cmsDomObjClassFullName, languageId) {
            var filter = {
                ElementGroupCodeUnique: cmsDomObjClassFullName,
                LanguageId: languageId
            };
            var defer = $q.defer();

            cmsService.getThesaurusCmsDomObjs(filter, forceReload)
                .then(function (data) {
                    vm.thesaurus = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getTriggerDefinitionProperties(forceReload, triggerDefinitionId) {
            var filter = {
                TriggerDefinitionId: triggerDefinitionId
            };
            var defer = $q.defer();

            triggerService.getTriggerDefinitionProperties(filter, forceReload)
                .then(function (data) {
                    vm.currentTriggerDefinitionProperties = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getBooleanValues() {
            var defer = $q.defer();
            booleanValues = vm.booleanSelectionForDropDown;
            defer.resolve(true);
            return defer.promise;
        }

        function getDrbacUserGroups(forceReload) {
            var filter = {
                VirtualCompanyId: virtualCompanyId
            };
            var defer = $q.defer();

            drbacService.getDrbacUserGroups(filter, forceReload)
                .then(function (data) {
                    userGroupValues = Lazy(data.results).map((item) => {
                        return {
                            value: item.DrbacUserGroupId,
                            name: Nembus.Common.Library.converter.decodeHtml(item.DrbacUserGroupName)
                        };
                    }).toArray();
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getCurrentContextAppUsers(forceReload) {
            var filter = {};
            var defer = $q.defer();

            rbacService.getCurrentContextAppUsers(filter, forceReload)
                .then(function (data) {
                    userValues = Lazy(data.results).map((item) => {
                        return {
                            value: item.AppUserId,
                            name: item.DisplayNameUI
                        };
                    }).toArray();
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getWorkflowActiveStatuses(forceReload) {
            var defer = $q.defer();
            /// TODO: 
            var appStoreCode = 'aud';
            var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
            var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);

            workflowService.getWorkflowActiveStatuses({ ModuleTargetId: moduleTargetId }, forceReload)
                .then(function (data) {
                    workflowStatusValues = Lazy(data.results).map((item) => {
                        return {
                            value: item.WorkflowStatusId,
                            name: Nembus.Common.Library.converter.decodeHtml(item.WorkflowStatusName)
                        };
                    }).toArray();
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getModalResult(dialogResult) {
            var modalResult = new navigationService.modalResult();
            var resultData = vm.triggerConditionManager.condition;

            modalResult.data = resultData;
            modalResult.sourceParams = pParams;
            modalResult.dialogResult = dialogResult;
            return modalResult;
        }
    }

    triggerInstanceMatchDetail.$inject = injectParams;
    triggerInstanceMatchDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('triggerInstanceMatchDetail', triggerInstanceMatchDetail);
});