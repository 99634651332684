﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'productionService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function calendarsList($q, $scope, $timeout, $injector, productionService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, productionService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return vm.calendarSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, productionService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var wCalendarParsedEnum = Nembus.Common.Library.util.parseEnum(productionService.dataContext.enumTypes.WCalendarKind, 'WCalendar', vm.translate);
        
        vm.calendars = [];

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('calendar', null);
        nbsGridDatacontext.viewCommonBar();
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getCalendars(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    Lazy(results[0])
                        .each((item) => {
                            item.WCalendarKindName = wCalendarParsedEnum.obj.translated.value_name[item.WCalendarKindId];
                        });

                    vm.calendars = results[0];

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.calendars
                    }
                });
        }

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.calendardetail-" + vm.viewMode, { 'calendarId': args.data.WCalendarId, 'appStoreCode': appStoreCode });
        };

        vm.newAction = function () {
            navigationService.navigateTo("app.nav.calendardetail-" + vm.viewMode, { 'calendarId': null, 'appStoreCode': appStoreCode });
        };

        vm.refreshAction = function () {
            loadData(true);
        };

        vm.deleteAction = function () {
            if (!vm.calendarSelected) {
                return;
            }
                
            productionService.deleteEntity(vm.calendarSelected);
            vm.submitAction()
                .then(function (saveResult) {
                    vm.refreshAction();
                });
        };

        vm.copyAction = function () {
            if (!vm.calendarSelected) {
                return;
            }            

            vm.openModal();
        };

        vm.openModal = function () {
            vm.modalInstance = navigationService.modal().open(vm.modalOptions({ templateUrl: 'calendarCloneTemplate', scope: $scope }));
            vm.modalInstance.result.then(function () {
                //ok
                productionService.cloneCalendar({ sourceCalendarId: vm.calendarSelected.WCalendarId, targetCalendarName: vm.calendarSelected.targetCalendarName })
                    .then(function (result) {
                        vm.refreshAction();
                    })
                    .catch(function (error) {
                        vm.setErrorMsg(error);
                    });
            }, function () {
                //dismiss
                productionService.rejectChanges();
            });
        };

        vm.modalOk = function () {
            vm.modalInstance.close();
        };

        vm.modalCancel = function () {
            vm.modalInstance.dismiss();
        };

        vm.gridSelection = function (args) {
            $timeout(() => {
                vm.calendarSelected = args.data;

                if (vm.calendarSelected) {
                    vm.calendarSelected.targetCalendarName = "";
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, true);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, true);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, false);
                }

                ribbon.refreshStatusButtons();
            });
        };

        function getCalendars(forceReload) {
            var filter = {};
            var defer = $q.defer();

            productionService.getCalendars(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    }

    calendarsList.$inject = injectParams;
    calendarsList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('calendarsList', calendarsList);
});