﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$injector', '$timeout', 'notificationService', 'navigationService', 'contextService'];
    function notificationInstancesList($q, $injector, $timeout, notificationService, navigationService, contextService) {
        var vm = this;
        nbsVm.call(vm, notificationService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return notificationInstanceSelected; });

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        ribbon.setRibbonStatusButton(ribbon.Buttons.New,  false);
        ribbon.refreshStatusButtons();

        var notificationInstanceSelected = null;

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('notificationinstance', null);
        nbsGridDatacontext.viewCommonBar();
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;


        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            // do not remove
            // definitions and definition groups loaded to permit breeze to fill navigation properties
            funcsPromises.push(getNotificationDefinitions(forceReload));  // no remove
            funcsPromises.push(getNotificationDefinitionGroups(forceReload));  // no remove
            funcsPromises.push(getNotificationInstances(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: results[2]
                    }
                });
        }

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.notificationinstancedetail", { 'notificationInstanceId': args.data.NotificationInstanceId });
        };

        vm.refreshAction = function () {
            getNotificationInstances(true)
                .then((instances) => {
                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: instances
                    }
                });
        };

        vm.gridSelection = function (args) {
            $timeout(() => {
                notificationInstanceSelected = args.data;

                if (notificationInstanceSelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, !notificationInstanceSelected.IsSystem);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, true);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, false);
                }

                ribbon.refreshStatusButtons();
            });
        };

        vm.deleteAction = function () {
            if (!notificationInstanceSelected) {
                return;
            }            

            notificationService.deleteEntity(notificationInstanceSelected);
            vm.submitAction()
                .then(function (saveResult) {
                    loadData(true);
                });
        };

        vm.copyAction = function () {
            if (!notificationInstanceSelected) {
                return;
            }

            if (notificationInstanceSelected.NotificationDefinition.IsSingleInstance) {
                navigationService.operationNotPermittedModal();
                return;
            }

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        var filter = { NotificationInstanceId: notificationInstanceSelected.NotificationInstanceId };

                        notificationService.cloneNotificationInstance(filter)
                            .then(function (result) {
                                vm.refreshAction();
                                notificationInstanceSelected = null;
                            });
                    }
                });
        };

        function getNotificationInstances(forceReload) {
            var filter = {};
            var defer = $q.defer();

            notificationService.getNotificationInstances(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getNotificationDefinitions(forceReload) {
            var filter = {};
            var defer = $q.defer();

            notificationService.getNotificationDefinitions(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getNotificationDefinitionGroups(forceReload) {
            var filter = {};
            var defer = $q.defer();

            notificationService.getNotificationDefinitionGroups(filter, forceReload)
                .then(function (data) {                    
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    }

    notificationInstancesList.$inject = injectParams;
    notificationInstancesList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('notificationInstancesList', notificationInstancesList);
});